import ls from 'Util/localStorage';
import { RU } from 'Constants/general';

// const isProduction = true;
const isProduction = process.env.DEPLOY_ENV === 'production';

const HUBSPOT_SCRIPT_SRC = '//js.hs-scripts.com/7253479.js';

type IdentifyUser = (data: Partial<HubSpotProperties>) => void;
export const identifyUser: IdentifyUser = (data) => {
  if (!(isProduction || data.email?.includes('hs.test'))) return;

  const locale = ls.get('locale');
  const email = ls.get('email');
  if (locale === RU && (!data.region_custom_ || data.region_custom_ === 'region_ruscee')) return;

  const emailValue = data.email || email;

  if (!emailValue) return;

  const formattedData: { [key: string]: any } = {};
  const keys = Object.keys(data) as (keyof Required<HubSpotProperties>)[];

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (data[key] !== undefined) {
      formattedData[key] = data[key];
    }
  }

  const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
  _hsq.push([
    'identify',
    {
      ...formattedData,
      email: emailValue,
    },
  ]);
  _hsq.push(['trackPageView']);
};

type EventType = {
  id: string;
  value?: any;
};

export const trackEvent = (data: EventType) => {
  const locale = ls.get('locale');
  if (locale === RU) return;

  const { id, value } = data;
  const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
  _hsq.push([
    'trackEvent',
    {
      id,
      value,
    },
  ]);
};

export const trackPage = (path: string) => {
  const locale = ls.get('locale');
  if (locale === RU) return;

  const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
  _hsq.push(['setPath', path]);
  _hsq.push(['trackPageView']);
};

export const addHubspotScript = (callback?: () => void, forceLoad?: boolean) => {
  const locale = ls.get('locale');
  if (locale === RU && !forceLoad) return;

  const allScripts = document.getElementsByTagName('script');
  let alreadyLoaded = false;

  for (let i = 0; i < allScripts.length; i++) {
    const src = allScripts[i].src;
    if (src === HUBSPOT_SCRIPT_SRC) {
      alreadyLoaded = true;
    }
  }

  if (alreadyLoaded) {
    if (callback) {
      callback();
    }
    return;
  }

  const gs: ScriptWithCustomEvent = document.createElement('script');
  gs.src = HUBSPOT_SCRIPT_SRC;
  gs.type = 'text/javascript';
  gs.id = 'hs-script-loader';
  gs.async = true;
  gs.defer = true;
  gs.onload = gs.onreadystatechange = function () {
    const rs = this.readyState;
    if (rs && rs !== 'complete') return;
    try {
      if (callback) {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const s = document.getElementsByTagName('script')[0];
  s?.parentNode?.insertBefore(gs, s);
};

interface ScriptWithCustomEvent extends HTMLScriptElement {
  onreadystatechange?: Document['onreadystatechange'];
  readonly readyState?: DocumentReadyState;
}

type HubSpotProperties = {
  firstName: string;
  lastName: string;
  email: string;
  website: string;
  company: string;
  country: any /*@TODO List of countries*/;
  received_recommendation: string;
  google_client_id: string;
  utm_campaign: string;
  utm_source: string;
  utm_medium: string;
  utm_term: string;
  company_role_new:
    | 'CEO/Owner'
    | 'Marketing Executive/CMO'
    | 'Digital Marketing'
    | 'Media Buyer/Growth Marketing/Performance Marketing'
    | 'Influencer Marketing'
    | 'Creative'
    | 'Ops/Assistant/Associate'
    | 'Other'
    | 'Creator'
    | '';
  type_of_business:
    | 'type_of_business_ecomm'
    | 'type_of_business_brand_collective'
    | 'type_of_business_dtc'
    | 'type_of_business_amazon_seller'
    | 'type_of_business_aggregator'
    | 'type_of_business_agency'
    | 'type_of_business_mobile_app'
    | 'type_of_business_digital_service'
    | 'type_of_business_other'
    | '';
  industry_custom_:
    | 'industry_fashion'
    | 'industry_beauty'
    | 'industry_consumer_goods'
    | 'industry_electronics'
    | 'industry_food'
    | 'industry_health'
    | 'industry_home'
    | 'industry_jewelry'
    | 'industry_kids'
    | 'industry_marketing'
    | 'industry_pets'
    | 'industry_services'
    | 'Software'
    | 'industry_sports'
    | 'Supplements'
    | 'industry_other'
    | 'industry_dig_service'
    | 'industry_gaming'
    | 'industry_cbd'
    | 'industry_fintech'
    | '';
  how_are_you_currently_collaborating_with_creators_: Array<
    | 'UGC for FB/IG'
    | 'UGC for TT'
    | 'Whitelisting/Spark Ads'
    | 'Organic for IG/TT'
    | 'Other'
    | 'Сommentary'
    | ''
  >;
  annual_revenue:
    | '<$100,000'
    | '$100,000 - $1,000,000'
    | '$1,000,000 - $10,000,000'
    | '$10,000,000 - $50,000,000'
    | '>$50,000,000'
    | '';
  first_brief_status: 'started' | 'sent_to_moderation' | 'not_started';
  region_custom_: 'region_na' | 'region_ruscee' | 'region_ukwe' | 'region_other' | 'region_cad';
};

export type { HubSpotProperties };

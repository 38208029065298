import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './Creatives.css';

import Text from 'Atoms/Text/Text';
import Creative from 'Molecules/Creative/Creative';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';

const CREATIVES_COUNT = 12;
const FOOTER_OFFSET = 279;

import { Creatives_adminCreatives } from 'GraphTypes/Creatives_adminCreatives.graphql';
import { CreativesFilter } from 'GraphTypes/AdminCreativeLibraryQuery.graphql';

interface Props {
  admin: boolean;
  canCreateFacebookAds?: boolean;
  relay: RelayPaginationProp;
  adminCreatives: Creatives_adminCreatives;
  filter: CreativesFilter;
}

const Creatives: React.FC<Props> = (props) => {
  const { admin, adminCreatives, canCreateFacebookAds, relay } = props;

  const edges = adminCreatives.adminCreatives?.edges || [];

  const createCreatives = () => {
    if (edges?.length === 0) {
      return <Text color="grayDog" msg="general.no_result" style={{ marginTop: '18px' }} />;
    }

    return edges?.map((item) => {
      if (!item?.node) return null;
      const { id } = item.node;

      return (
        <Creative
          key={id}
          admin={admin}
          canCreateFacebookAds={canCreateFacebookAds}
          canChatWithCreators={true}
          creative={item.node}
        />
      );
    });
  };

  const list = createCreatives();

  return (
    <LoaderHandler relay={relay} count={CREATIVES_COUNT} offset={FOOTER_OFFSET}>
      <div className={styles.root}>{list}</div>
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  Creatives,
  {
    adminCreatives: graphql`
      fragment Creatives_adminCreatives on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 12 }
        cursor: { type: "String" }
        filter: { type: "CreativesFilter!" }
      ) {
        adminCreatives(first: $count, after: $cursor, filter: $filter)
          @connection(key: "Creatives_adminCreatives", filters: []) {
          edges {
            node {
              id
              project {
                id
              }
              ...Creative_creative
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.adminCreatives && props.adminCreatives.adminCreatives;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        filter: props.filter,
      };
    },
    query: graphql`
      query CreativesPaginationQuery($count: Int!, $cursor: String, $filter: CreativesFilter!) {
        ...Creatives_adminCreatives @arguments(count: $count, cursor: $cursor, filter: $filter)
      }
    `,
  }
);

import React, { PropsWithChildren } from 'react';

import { FeatureFlagsContext } from './FeatureFlags.Context';
import { FeatureFlagsProvider } from './FeatureFlags.Provider';

const FeatureFlagsContainer: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return <FeatureFlagsProvider context={FeatureFlagsContext}>{children}</FeatureFlagsProvider>;
};

export default FeatureFlagsContainer;
export { FeatureFlagsContext };

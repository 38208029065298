/**
 * @generated SignedSource<<8f4628c0c60b9a7c257662ddd8d9ebbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RejectOfferInput = {
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  id?: string | null | undefined;
  offerId: string;
};
export type RejectOfferMutation$variables = {
  input: RejectOfferInput;
};
export type RejectOfferMutation$data = {
  readonly rejectOffer: {
    readonly project: {
      readonly id: string;
      readonly offer: {
        readonly rejection: {
          readonly comment: string | null | undefined;
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type RejectOfferMutation = {
  response: RejectOfferMutation$data;
  variables: RejectOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferRejection",
  "kind": "LinkedField",
  "name": "rejection",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RejectOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RejectOfferPayload",
        "kind": "LinkedField",
        "name": "rejectOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Offer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RejectOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RejectOfferPayload",
        "kind": "LinkedField",
        "name": "rejectOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Offer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "350a8d54cc18529337c098dc3647d01b",
    "id": null,
    "metadata": {},
    "name": "RejectOfferMutation",
    "operationKind": "mutation",
    "text": "mutation RejectOfferMutation(\n  $input: RejectOfferInput!\n) {\n  rejectOffer(input: $input) {\n    project {\n      id\n      offer {\n        rejection {\n          id\n          comment\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ea52043388a77013dbf44f528a18ac4";

export default node;

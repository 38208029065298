import React, { useCallback, CSSProperties, MouseEventHandler } from 'react';
import cn from 'classnames';

import styles from './Logo.pcss';

import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import logoImg from 'Images/general/logo.svg';

interface Props {
  style?: CSSProperties;
  className?: string;
  withAdminBadge?: boolean;
  onAdminBadgeClick?: MouseEventHandler<HTMLElement>;
}

const Logo: React.FC<Props> = (props) => {
  const { style, className, withAdminBadge, onAdminBadgeClick } = props;

  const handleAdminLabelClick = useCallback<NonNullable<TextProps['handleClick']>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onAdminBadgeClick?.(e);
    },
    [onAdminBadgeClick]
  );

  return (
    <div className={cn(className, styles.root)} style={style}>
      <img src={logoImg} />
      {withAdminBadge && (
        <Text
          type="s"
          msg="header.badge.admin"
          className={styles.adminBadge}
          handleClick={handleAdminLabelClick}
        />
      )}
    </div>
  );
};

export default Logo;

import React, { Component } from 'react';

import Spinner from 'Atoms/Spinner/Spinner';

const FOOTER_OFFSET = 279;

class LoaderHandler extends Component {
  constructor(props) {
    super(props);

    const { startLoadButton } = props;

    this.state = {
      isLoading: false,
      showStartLoadButton: !!startLoadButton,
    };

    this.offset = props.offset || FOOTER_OFFSET;
    this.container = document;
    this.scrollContainer = document.documentElement;

    this.onScroll = this.onScroll.bind(this);
    this.handleStartLoadClick = this.handleStartLoadClick.bind(this);
  }

  onScroll() {
    const { reverse } = this.props;
    const scrollTop = this.scrollContainer.scrollTop;
    const scrollHeight = this.scrollContainer.scrollHeight;
    const clientHeight = this.scrollContainer.clientHeight;

    if (reverse) {
      if (scrollTop < this.offset) {
        this.loadMore();
      }

      return;
    }

    if (scrollTop > (scrollHeight - clientHeight - this.offset)) {
      this.loadMore();
    }
  }

  initSrcollListener() {
    const { ownContainer, containerId, reverse } = this.props;
    if (ownContainer) {
      const container = document.getElementById(containerId);
      this.container = container;
      this.scrollContainer = container;
      if (reverse) {
        this.container.scrollTop = 10000000;
      }
    }

    this.container.addEventListener('scroll', this.onScroll);
  }

  componentDidMount() {
    const { startLoadButton } = this.props;
    if (!startLoadButton) {
      this.initSrcollListener();
    }
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.onScroll);
  }

  loadMore() {
    const { relay, count } = this.props;
    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }
    this.setState({ isLoading: true });
    relay.loadMore(
      count,
      () => {
        this.setState({ isLoading: false });
      });
  }

  createPreloader() {
    const { preloaderStyle } = this.props;

    return (
      <div style={{ textAlign: 'center', width: '40px', margin: '20px auto' }}>
        <Spinner className={preloaderStyle} />
      </div>
    );
  }

  handleStartLoadClick() {
    this.loadMore();
    this.initSrcollListener();
    this.setState({ showStartLoadButton: false });
  }

  render() {
    const { className, relay, children, hideLoader, preloader, reverse, startLoadButton } = this.props;
    const { isLoading, showStartLoadButton } = this.state;
    const showInnerLoading = isLoading && hideLoader;
    const preloaderEl = preloader ? preloader : this.createPreloader();

    const hasMore = relay.hasMore();

    return (
      <div className={className}>
        {reverse && isLoading && !hideLoader && preloaderEl}
        {React.cloneElement(children, { ref: 'container', isLoading: showInnerLoading })}
        {!reverse && isLoading && !hideLoader && preloaderEl}
        {showStartLoadButton && hasMore && <div onClick={this.handleStartLoadClick}>{startLoadButton}</div>}
      </div>
    );
  }
}

export default LoaderHandler;
import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import noop from 'lodash/noop';

import { getFilters, FilterItem } from '../data';

import styles from './PaidSocialLicensesFilters.pcss';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Input from 'Components/ui/Input/Input';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { PaidSocialLicensesOrder } from 'GraphTypes/PaidSocialLicenseListQuery.graphql';
import { PaidSocialLicensesFiltersQuery as QueryType } from 'GraphTypes/PaidSocialLicensesFiltersQuery.graphql';

const msgNameSpace = 'dashboard.advertiser.licenses_section';
const sortByList = [
  {
    id: 'EXPIRES_AT_ASC',
    msg: `${msgNameSpace}.filters.sort_by.expiration.expires_at_asc`,
  },
  {
    id: 'EXPIRES_AT_DESC',
    msg: `${msgNameSpace}.filters.sort_by.expiration.expires_at_desc`,
  },
];

const PaidSocialLicensesFiltersQuery = graphql`
  query PaidSocialLicensesFiltersQuery {
    currentUser {
      organization {
        counters {
          psLicensedCreators
        }
      }
    }
  }
`;

interface Props {
  orderBy?: PaidSocialLicensesOrder;
  filter: FilterItem;
  onOrderChange: (id: PaidSocialLicensesOrder) => void;
  onFiltersChange: (filter: FilterItem) => void;
  onUsernameChange: (value: string) => void;
}

const PaidSocialLicensesFilters: React.FC<Props> = (props) => {
  const { orderBy, onOrderChange, onFiltersChange, onUsernameChange } = props;
  const { active } = props.filter;
  const filters = getFilters();

  const { currentUser } = useLazyLoadQuery<QueryType>(PaidSocialLicensesFiltersQuery, {});
  const hasActiveLicenses = !!currentUser?.organization?.counters?.psLicensedCreators;

  const orderLabel = orderBy
    ? `${msgNameSpace}.filters.sort_by.expiration.${orderBy.toLowerCase()}`
    : `${msgNameSpace}.filters.sort_by`;

  const activeLabel = `${msgNameSpace}.filters.state.${props.filter.id.toLocaleLowerCase()}`;

  return (
    <div className={styles.root}>
      <div className={styles.dropdowns}>
        {hasActiveLicenses && (
          <Dropdown className={styles.dropdown} value={<Text type="md" msg={orderLabel} />}>
            <DropdownGroup
              labelMsg={`${msgNameSpace}.filters.sort_by.expiration`}
              className={styles.sortBy}
              align="left"
            >
              {sortByList.map((item) => {
                const { id, msg } = item;
                return (
                  <DropdownGroupItem
                    handleClick={() => onOrderChange(id as PaidSocialLicensesOrder)}
                    key={id}
                  >
                    <AlterButton
                      fluid
                      msg={msg}
                      iconPosition="right"
                      icon={id === orderBy ? 'Check' : null}
                      onClick={noop}
                    />
                  </DropdownGroupItem>
                );
              })}
            </DropdownGroup>
          </Dropdown>
        )}
        <Dropdown className={styles.dropdown} value={<Text type="md" msg={activeLabel} />}>
          <DropdownGroup
            labelMsg={`${msgNameSpace}.filters.state`}
            className={styles.activeState}
            align="left"
          >
            {filters.map((filter) => {
              const { id, msg } = filter;
              const isActive = filter.id === props.filter.id;
              return (
                <DropdownGroupItem handleClick={() => onFiltersChange(filter)} key={id}>
                  <AlterButton
                    fluid
                    msg={msg}
                    iconPosition="right"
                    icon={isActive ? 'Check' : null}
                    onClick={noop}
                  />
                </DropdownGroupItem>
              );
            })}
          </DropdownGroup>
        </Dropdown>
      </div>
      <Input
        placeholderMsg="dashboard.advertiser.seacrh"
        handleChange={onUsernameChange}
        className={styles.search}
        rightIcon="Search-loop"
      />
    </div>
  );
};

export default PaidSocialLicensesFilters;

import React from 'react';
import classnames from 'classnames';

import styles from './ProjectStatus.css';

import { createSum } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  showComment?: boolean;
  withIcon?: boolean;
  withPrice?: boolean;
  project: any;
}

const ProjectStatus: React.FC<Props> = (props) => {
  const { project, withIcon = true, withPrice } = props;

  const { advertiserStage } = project;

  if (advertiserStage === 'ACCEPTED') return null;

  let data: Data = { tooltipData: { id: '' } };

  switch (advertiserStage) {
    case 'UNSENT': {
      data = {
        msg: 'new_campaign.project_status_unsent',
        color: 'grayDog',
        tooltipData: {
          id: 'status_unsent',
          tooltipMsg: 'tooltip.status_unsent',
        },
      };
      break;
    }
    case 'UNREAD': {
      data = {
        msg: 'new_campaign.project_status_unread',
        color: 'grayDog',
        tooltipData: {
          id: 'status_unread',
          tooltipMsg: 'tooltip.status_unread',
        },
      };
      break;
    }
    case 'READ': {
      data = {
        msg: 'new_campaign.project_status_read',
        color: 'grayDog',
        tooltipData: {
          id: 'status_read',
          tooltipMsg: 'tooltip.status_read',
        },
      };
      break;
    }
    case 'REJECTED': {
      const comment = props?.project?.offer?.rejection?.comment || '';

      data = {
        msg: 'new_campaign.project_status_rejected',
        color: 'red',
        comment,
        tooltipData: {
          id: 'declinedComment',
          tooltipMsg: 'tooltip.status_rejected',
        },
      };
      break;
    }
    case 'ACCEPTED': {
      let acceptedTextData: Partial<Data> = { msg: 'new_campaign.project_status_accepted' };
      if (withPrice) {
        const fee = project.campaign.organization.subscription.transactionFee;
        const actualPrice = project.contentApprovalPrice || project.price;
        const priceValue = createSum(actualPrice * (1 + fee), project.currency);
        acceptedTextData = { text: priceValue };
      }
      data = {
        ...acceptedTextData,
        color: withPrice ? undefined : 'yellow',
        tooltipData: {
          id: 'status_accepted',
          tooltipMsg: 'tooltip.status_accepted',
        },
      };
      break;
    }
    case 'LAUNCHED': {
      data = {
        msg: 'new_campaign.project_status_launched',
        color: 'green',
        tooltipData: {
          id: 'status_launched',
          tooltipMsg: 'tooltip.status_launched',
        },
      };
      break;
    }
    case 'CONTENT_SUBMITTED': {
      data = {
        msg: 'new_campaign.project_status_content_submitted',
        color: 'turquoise',
        tooltipData: {
          id: 'status_review',
          tooltipMsg: 'tooltip.status_content_submitted',
        },
      };
      break;
    }
    case 'PUBLISHING_STARTED': {
      data = {
        msg: 'new_campaign.project_status_publishing_started',
        color: 'pink',
        tooltipData: {
          id: 'status_posting',
          tooltipMsg: 'tooltip.status_publishing_started',
        },
      };
      break;
    }
    case 'COMPLETED': {
      data = {
        msg: 'new_campaign.project_status_completed',
        color: 'base',
        tooltipData: {
          id: 'status_completed',
          tooltipMsg: 'tooltip.status_completed',
        },
      };
      break;
    }
    default: {
      break;
    }
  }

  const text = (
    <Tooltip {...data.tooltipData} place="top">
      <Text weight="500" {...data} />
    </Tooltip>
  );

  const stateStyle = advertiserStage ? styles[advertiserStage.toLowerCase()] : undefined;

  return (
    <div className={classnames(styles.root, stateStyle)}>
      {withIcon && <div className={styles.icon} />}
      <div>
        {text}
        <Text size="sm" color="grayDog" msg="new_campaign.project_status" />
      </div>
    </div>
  );
};

export default ProjectStatus;

type TooltipProps = React.ComponentProps<typeof Tooltip>;
type TextProps = React.ComponentProps<typeof Text>;

type Data = {
  text?: TextProps['text'];
  color?: TextProps['color'];
  comment?: string;
  tooltipData: TooltipProps;
  msg?: string;
};

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AcceptDeadlineChangeRequestInput,
  AcceptDeadlineChangeRequestMutation,
  AcceptDeadlineChangeRequestMutation$data,
} from 'GraphTypes/AcceptDeadlineChangeRequestMutation.graphql';

const mutation = graphql`
  mutation AcceptDeadlineChangeRequestMutation($input: AcceptDeadlineChangeRequestInput!) {
    acceptDeadlineChangeRequest(input: $input) {
      ... on Error {
        message
        type
      }
      ... on AcceptDeadlineChangeRequestPayload {
        project {
          deadline {
            draftDate
          }
        }
      }
      ... on ValidationError {
        message
        type
      }
    }
  }
`;

type fnType = (data: object) => void;

interface ProjectData {
  projectId: string;
}

export default (
  data: AcceptDeadlineChangeRequestInput & ProjectData,
  resolve?: (response: AcceptDeadlineChangeRequestMutation$data) => void,
  reject?: fnType
) => {
  const { deadlineChangeRequestId, projectId } = data;

  const id = uuid();
  const variables = {
    input: {
      id,
      deadlineChangeRequestId,
    },
  };
  commitMutation<AcceptDeadlineChangeRequestMutation>(environment, {
    mutation,
    variables,
    updater: (store, data) => {
      const project = store.get(projectId);
      const result = data?.acceptDeadlineChangeRequest?.project;

      if (result) {
        project?.setValue(null, 'deadlineChangeRequest');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response.acceptDeadlineChangeRequest?.project && resolve) {
        resolve(response);
      } else if (response.acceptDeadlineChangeRequest?.message && reject) {
        reject(response.acceptDeadlineChangeRequest);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

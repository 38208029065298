/**
 * @generated SignedSource<<5d54c10ff57c30e70648aea48e4d9745>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UnknownUserHeaderQuery$variables = Record<PropertyKey, never>;
export type UnknownUserHeaderQuery$data = {
  readonly currentUser: {
    readonly advertiserProfile: {
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    } | null | undefined;
    readonly contractorProfile: {
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UnknownUserHeaderQuery = {
  response: UnknownUserHeaderQuery$data;
  variables: UnknownUserHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnknownUserHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiserProfile",
            "kind": "LinkedField",
            "name": "advertiserProfile",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractorProfile",
            "kind": "LinkedField",
            "name": "contractorProfile",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnknownUserHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiserProfile",
            "kind": "LinkedField",
            "name": "advertiserProfile",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractorProfile",
            "kind": "LinkedField",
            "name": "contractorProfile",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df40d3ff499b6e564b0ecc25136a3320",
    "id": null,
    "metadata": {},
    "name": "UnknownUserHeaderQuery",
    "operationKind": "query",
    "text": "query UnknownUserHeaderQuery {\n  currentUser {\n    advertiserProfile {\n      firstName\n      lastName\n      id\n    }\n    contractorProfile {\n      firstName\n      lastName\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b1ce5d7ac88908bc2d39c1022e36c0f";

export default node;

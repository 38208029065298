import React from 'react';

import styles from './Table.css';
import Bar from './Bar/Bar';
import Row from './Row/Row';

import DraggingHandler from 'Containers/DraggingHandler/DraggingHandler';
import Text from 'Atoms/Text/Text';


const Table = props => {
  const {
    data,
    barStyle,
    list = [],
    children,
    defaultMsg = 'general.no_results_for_request',
    isDraggable,
    dragCallback
  } = props;

  if (list && list.length === 0 && !children) {
    return <Text color="grayDog" msg={defaultMsg} />;
  }

  const rows = list ? list.map((item, index) => {
    return <Row key={index} {...item} />;
  }) : null;

  return (
    <div>
      <Bar items={data} {...props} className={`${styles.bar} ${barStyle}`} />
      {children}
      {
        isDraggable ? (
          <DraggingHandler items={list} dragCallback={dragCallback}>
            <Row />
          </DraggingHandler>
        ) : rows
      }
    </div>
  );
};

export default Table;

import { graphql } from 'react-relay';

import type {
  PerformanceCollaborationsChartQuery as PerformanceCollaborationsChartQueryType,
  PerformanceCollaborationsChartQuery$variables,
  Performance_Granularity,
  PerformanceCollaborationsChartQuery$data,
} from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';

const PerformanceCollaborationsChartQuery = graphql`
  query PerformanceCollaborationsChartQuery(
    $brandId: ID!
    $campaignIds: [ID!]
    $dateFrom: Date!
    $dateTo: Date!
    $granularity: Performance_Granularity!
    $platform: CampaignPlatform
    $campaignType: CampaignType
    $campaignPostingType: CampaignPostingType
    $useCase: CampaignUseCase
  ) {
    performance {
      collaborationCounters(
        brandId: $brandId
        campaignIds: $campaignIds
        dateFrom: $dateFrom
        dateTo: $dateTo
        granularity: $granularity
        platform: $platform
        campaignType: $campaignType
        campaignPostingType: $campaignPostingType
        campaignUseCase: $useCase
      ) {
        edges {
          node {
            dateFrom
            dateTo
            creatorsCount
            completedProjectsCount
            creativeAssetsCount
            publicationsCount
          }
        }
        totalCompletedProjectsCount
        totalCreativeAssetsCount
        totalCreatorsCount
        totalPublicationsCount
      }
    }
  }
`;

export { PerformanceCollaborationsChartQuery };

// types

export type {
  Performance_Granularity,
  PerformanceCollaborationsChartQueryType,
  PerformanceCollaborationsChartQuery$data,
  PerformanceCollaborationsChartQuery$variables,
};

import React from 'react';
import classnames from 'classnames';

import styles from './Bar.css';

import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { ASC, DESC } from 'Constants/general';

type direction = typeof ASC | typeof DESC | undefined;

interface Props {
  orderBy?: string;
  orderDirection: direction;
  onOrderChange: (orderBy?: string, direction?: direction) => void;
  item: {
    id: string;
    orderBy?: string;
    title?: string;
    titleTooltip?: string;
    preferableDirection?: direction;
  };
}

const SortableBarItem: React.FC<Props> = (props) => {
  const { item, orderBy, orderDirection, onOrderChange } = props;
  const { id, title, titleTooltip, orderBy: itemOrderBy, preferableDirection = ASC } = item;

  const otherDirection = preferableDirection === ASC ? DESC : ASC;

  const directions: direction[] = [preferableDirection, otherDirection];

  const isChosen = itemOrderBy === orderBy;
  const color = isChosen ? undefined : 'grayDog';

  const onClick = () => {
    let newDirection;
    let newOrderBy = itemOrderBy;

    if (orderBy !== newOrderBy) {
      onOrderChange(newOrderBy, directions[0]);

      return;
    }

    if (orderDirection === directions[0]) {
      newDirection = directions[1];
    } else if (orderDirection === directions[1]) {
      newOrderBy = undefined;
      newDirection = undefined;
    } else {
      newDirection = directions[0];
    }

    onOrderChange(newOrderBy, newDirection);
  };

  const directionStyle =
    isChosen && orderDirection ? styles[orderDirection.toLowerCase()] : undefined;

  return (
    <div
      className={classnames(directionStyle, {
        [styles.chosen]: isChosen,
        [styles.sortable]: itemOrderBy,
      })}
      onClick={onClick}
    >
      <Tooltip id={`bar-${id}`} tooltipMsg={titleTooltip} place="bottom">
        <Text
          size="sm"
          color={color}
          display="inline"
          msg={`bar.${title}`}
          className={styles.title}
        />
      </Tooltip>
    </div>
  );
};

export default SortableBarItem;

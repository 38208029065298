/**
 * @generated SignedSource<<ae1839d42a9779202fb9d134e55d8667>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FavoritesCreatorsCountQuery$variables = Record<PropertyKey, never>;
export type FavoritesCreatorsCountQuery$data = {
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly churned: boolean;
      readonly favoriteCreators: {
        readonly totalCount: number;
      } | null | undefined;
      readonly newClient: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type FavoritesCreatorsCountQuery = {
  response: FavoritesCreatorsCountQuery$data;
  variables: FavoritesCreatorsCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newClient",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "churned",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CreatorConnection",
  "kind": "LinkedField",
  "name": "favoriteCreators",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FavoritesCreatorsCountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FavoritesCreatorsCountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5af58033ceadca2a61470a672567833",
    "id": null,
    "metadata": {},
    "name": "FavoritesCreatorsCountQuery",
    "operationKind": "query",
    "text": "query FavoritesCreatorsCountQuery {\n  currentUser {\n    admin\n    organization {\n      newClient\n      churned\n      favoriteCreators {\n        totalCount\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bde3a1c5cb51bd62aaaf26b38035c014";

export default node;

import {
  ADD_REFERENCE_TO_QUEUE,
  REMOVE_REFERENCE_TO_QUEUE,
  CLEAR_ALL_REFERENCE_FROM_QUEUE,
} from 'Constants/index';

export interface ActionsDataProps {
  file: any,
}

export interface ActionProps {
  type: string,
  payload: ActionsDataProps
}

export const addReferenceToQueue = (data: ActionsDataProps): ActionProps => ({
  type: ADD_REFERENCE_TO_QUEUE,
  payload: data
});

export const removeReferenceToQueue = (data: ActionsDataProps): ActionProps => ({
  type: REMOVE_REFERENCE_TO_QUEUE,
  payload: data
});

export const clearAllReferenceToQueue = () => ({
  type: CLEAR_ALL_REFERENCE_FROM_QUEUE,
});
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import MessageMedia from '../MessageEvent/MessageAttachments/MessageMedia/MessageMedia';
import SimpleProjectEvent from '../SimpleProjectEvent/SimpleProjectEvent';

import styles from './ContentReviewSubmission.pcss';

import { UserType } from 'GraphTypes/ProjectEventsQuery.graphql';
import { PRODUCT_SEEDING_CONTENT_SUBMISSION } from 'Types/enums';
import { ProjectEventsList_project$data } from 'GraphTypes/ProjectEventsList_project.graphql';
import { ContentReviewSubmission_event$key } from 'GraphTypes/ContentReviewSubmission_event.graphql';

interface Props {
  userType?: UserType;
  event: ContentReviewSubmission_event$key;
}

const ContentReviewSubmission: React.FC<Props> = (props) => {
  const { event, userType } = props;

  const data = useFragment(
    graphql`
      fragment ContentReviewSubmission_event on ProjectEvent {
        __typename
        id
        createdAt
        ... on ContentReviewSubmission {
          reviewFiles: attachments {
            edges {
              node {
                id
                file {
                  ...MessageMedia_file
                }
              }
            }
          }
        }
        ... on ProductSeedingContentSubmission {
          creatives {
            edges {
              node {
                id
                file {
                  ...MessageMedia_file
                }
              }
            }
          }
        }
      }
    `,
    event
  );
  if (!data) {
    return null;
  }
  const createdAt = data.createdAt;
  const isSeedingSubmission = data.__typename === PRODUCT_SEEDING_CONTENT_SUBMISSION;
  const attachments = isSeedingSubmission ? data.creatives?.edges : data.reviewFiles?.edges;

  const userPostfix = userType === 'CONTRACTOR' ? '_creator' : '';

  return (
    <div>
      <SimpleProjectEvent
        createdAt={createdAt}
        titleMsg={
          isSeedingSubmission
            ? `chat.project.system_event.product_seeding_content_submission${userPostfix}`
            : `chat.project.system_event.content_review_submission${userPostfix}`
        }
      />
      <div className={styles.media}>
        <div className={classnames(styles.mediaList)}>
          {attachments?.map((item) => {
            if (!item?.node || !item.node.file) return null;

            const attachmentId = item.node.id;

            return (
              <MessageMedia
                key={attachmentId}
                file={item.node.file}
                isRead={false}
                isOwner={false}
                createdAt={createdAt}
                actionAble={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContentReviewSubmission;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  ResendOfferNotificationsMutation,
  ResendOfferNotificationsMutation$data,
  ResendOfferNotificationsInput,
} from 'GraphTypes/ResendOfferNotificationsMutation.graphql';

const mutation = graphql`
  mutation ResendOfferNotificationsMutation($input: ResendOfferNotificationsInput!) {
    resendOfferNotifications(input: $input) {
      result
    }
  }
`;

type fnType = (data?: object) => void;

export default (
  data: ResendOfferNotificationsInput,
  resolve?: (response: ResendOfferNotificationsMutation$data) => void,
  reject?: fnType
) => {
  const { campaignId } = data;

  const variables = {
    input: {
      campaignId,
    },
  };

  commitMutation<ResendOfferNotificationsMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (response.resendOfferNotifications?.result === 'FAILURE' && reject) {
        reject();

        return;
      }
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

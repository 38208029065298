import React from 'react';

import styles from './ProjectsFilterLoader.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

const ProjectsFilterLoader = () => {
  return (
    <div className={styles.root}>
      <Spinner size="sm" />
    </div>
  );
};

export default ProjectsFilterLoader;

import React, { useMemo } from 'react';
import camelCase from 'lodash/camelCase';
import classnames from 'classnames';

import styles from './AvatarWithBadge.css';

import Avatar from 'Atoms/Avatar/Avatar';
import Icon from 'Components/ui/Icon/Icon';
import { AvatarType } from 'Types/common';
import { isValidIconKey } from 'Icons/utils';
import thumbsUpImg from 'Images/icons/thumbs-up-filled.svg';
import { CreatorBadge } from 'GraphTypes/CreatorProfileQuery.graphql';

type IconProps = React.ComponentProps<typeof Icon>;

interface Props {
  className?: string;
  favorite?: boolean;
  badge?: CreatorBadge | null;
  withIcon?: boolean;
  avaData: AvatarType;
}

const AvatarWithBadge: React.FC<Props> = (props) => {
  const { className, badge, avaData, favorite = false, withIcon = true } = props;

  const { size = 40 } = avaData;

  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  const avaSize = badge ? Number(size) - 8 : size;

  const withBrand = badge ? styles.withBadge : '';

  const iconName = useMemo<IconProps['name'] | null>(() => {
    if (badge === null) return null;
    const name = camelCase(badge);
    return isValidIconKey(name) ? (name as IconProps['name']) : null;
  }, [badge]);

  return (
    <div
      style={sizeStyle}
      className={classnames(styles.root, className, iconName && styles[iconName], withBrand)}
    >
      {/* {badge && <div style={sizeStyle} className={styles.border} />} */}
      <div className={styles.ava}>
        <Avatar {...avaData} size={avaSize} />
      </div>
      {favorite && (
        <div className={styles.favorite}>
          <img src={thumbsUpImg} />
        </div>
      )}
      {/* {badge && withIcon && iconName && <Icon size="sm" name={iconName} className={styles.rightIcon} />} */}
    </div>
  );
};

export default AvatarWithBadge;

import React, { useState, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BrowserView, isTablet } from 'react-device-detect';
import { QueryRenderer, graphql } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import { useDispatch } from 'react-redux';
import { updateIntl } from 'react-intl-redux';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import Help from '../Help/Help';
import Balance from '../Balance/Balance';
import User from '../User/User';

import styles from './AdvertiserHeader.pcss';
import { createMenuList } from './util';

import environment from 'Api/Environment';
import { amplitude } from 'Helpers/amplitude';
import { changeLocalizationData } from 'Util/localizationData';
import { setModal } from 'Actions/Modal.Actions';
import Avatar from 'Atoms/Avatar/Avatar';
import Text from 'Atoms/Text/Text';
import Logo from 'Atoms/Logo/Logo';
import { modalName } from 'Types/modals';
import { DASHBOARD_ROUTE, FREE_PLAN, HOME_ROUTE, BOOK_A_DEMO_LINK_EN } from 'Constants/general';
import { AdvertiserHeaderQuery as QueryType } from 'GraphTypes/AdvertiserHeaderQuery.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { ReferBusiness } from 'Modal/advertiser/ReferBusiness/ReferBusiness';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';

const AdvertiserHeaderQuery = graphql`
  query AdvertiserHeaderQuery {
    currentUser {
      admin
      type
      permissions
      advertiserProfile {
        firstName
        lastName
      }
      organization {
        id
        name
        paying
        newClient
        subscription {
          canCreateDeposits
          canBrowseCreators
          availablePlanIds
          planId
          effectiveLimits {
            maxSeats
          }
        }
        members {
          totalCount
        }
        paymentAccount {
          balance
          currency
        }
      }
    }
    campaigns(stages: [IN_REVIEW, ACTIVE], first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const DemoButton = () => {
  const handleDemoClick = useCallback(() => {
    amplitude.sendEvent({
      id: '178',
      category: 'dashboard_page',
      name: 'book_a_demo_on_dasboard',
      param: {},
    });
    window.open(BOOK_A_DEMO_LINK_EN, '_blank');
  }, []);

  return (
    <AlterButton
      msg="header.book_demo"
      icon="Lamp-spark"
      onClick={handleDemoClick}
      className={cn(styles.demo, styles.headerButton)}
    />
  );
};

const AdvertiserHeader = () => {
  const [isReferBusinessDrawerOpened, setIsReferBusinessDrawerOpened] = useState(false);
  const tracking = useTracking();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout, user } = useContext(Auth0Context);

  const handleReferBusinessDrawerClose = useCallback(() => {
    setIsReferBusinessDrawerOpened(false);
  }, []);

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={AdvertiserHeaderQuery}
      variables={{}}
      render={({ props: queryProps }) => {
        if (!queryProps) return null;

        const currentUser = queryProps.currentUser;

        if (!currentUser) return null;

        const { admin, type: userType } = currentUser;

        const profile = currentUser.advertiserProfile;
        const organization = currentUser.organization;
        const permissions = currentUser.permissions;
        const planId = organization?.subscription?.planId;

        const balance = organization?.paymentAccount?.balance;
        const currency = organization?.paymentAccount?.currency;

        const organizationId = organization?.id;
        const organizationName = organization?.name;
        const newClient = organization?.newClient;
        const paying = organization?.paying;
        const hasFinishedOnboarding = queryProps?.campaigns?.edges
          ? queryProps.campaigns.edges?.length > 0
          : false;

        const menuList = createMenuList({
          admin,
          organization,
          currency,
          permissions,
        });

        const label =
          profile?.firstName && profile.lastName
            ? `${profile.firstName} ${profile.lastName}`
            : user?.email;

        const ava = (
          <Avatar
            size="40"
            letters={label}
            // src={user?.picture}
            dataTest="avatar:header:contextMenu"
          />
        );

        const handleReferalClick = () => {
          amplitude.sendEvent({
            id: '31',
            category: 'dashboard',
            name: 'referral_click',
            param: {},
          });
          setIsReferBusinessDrawerOpened(true);
        };

        const createUserContent = () => {
          return (
            <div className={styles.userContent}>
              <div className={styles.userInfoWrap}>
                <div className={styles.userInfo}>
                  {ava}
                  <div className={styles.names}>
                    <Text
                      weight="500"
                      text={`${profile?.firstName || ''} ${profile?.lastName || ''}`}
                    />
                    <Text size="sm" color="grayDog" text={user?.email} />
                  </div>
                </div>
              </div>
            </div>
          );
        };

        const finalMenuList = () => {
          return menuList.map((item: any) => {
            const { callback } = item;
            if (callback) {
              const onClick = getCallback(item);
              return { ...item, onClick };
            }
            return { ...item };
          });
        };

        const getCallback = (item: any) => {
          const { id, callback } = item;
          switch (callback) {
            case 'logout':
              return () => {
                tracking.trackEvent({
                  element: 'main_menu',
                  event: 'clicked',
                  payload: { value: 'logout' },
                });
                amplitude.sendEvent({ id: 247, category: 'pageview', name: 'sign_out_click' });
                logout();
              };
            case 'lang': {
              return () => {
                const { locale, messages } = changeLocalizationData();
                dispatch(
                  updateIntl({
                    locale,
                    messages,
                  })
                );
              };
            }
            case 'creatives_library': {
              return () => {
                amplitude.sendEvent({
                  id: 242,
                  category: 'pageview',
                  name: 'creatives_library_click',
                });
              };
            }
            case 'invite': {
              return () => {
                amplitude.sendEvent({
                  id: 240,
                  category: 'pageview',
                  name: 'invite_teammates_click',
                });
                dispatch(setModal(modalName.ADD_TEAMMATE, { organizationId, organizationName }));
              };
            }
            case 'transactions': {
              return () => {
                amplitude.sendEvent({
                  id: 'v1-e51',
                  category: 'transaction_item',
                  name: 'transaction_item_click',
                  param: {
                    admin,
                    currency,
                  },
                });
              };
            }
            case 'billing': {
              return () => {
                amplitude.sendEvent({ id: 244, category: 'pageview', name: 'billing_click' });
              };
            }
            case 'refund': {
              return () => {
                amplitude.sendEvent({ id: 245, category: 'pageview', name: 'refund_policy_click' });
              };
            }
            case 'terms': {
              return () => {
                amplitude.sendEvent({
                  id: 246,
                  category: 'pageview',
                  name: 'terms_of_service_click',
                });
              };
            }
            default:
              return () => {
                tracking.trackEvent({
                  element: 'main_menu',
                  event: 'clicked',
                  payload: { value: id },
                });
              };
          }
        };

        const showBookDemo = !admin && !paying && planId === FREE_PLAN;

        const logoLink = newClient ? HOME_ROUTE : DASHBOARD_ROUTE;

        const onLogoClick = () => {
          amplitude.sendEvent({
            id: 237,
            category: 'pageview',
            name: 'logo_insense_click',
            param: {
              referral_url: logoLink,
            },
          });
        };

        return (
          <div className={styles.root}>
            <div className={styles.wrapper}>
              <Link to={logoLink} onClick={onLogoClick}>
                <Logo withAdminBadge={admin} />
              </Link>
            </div>
            <div className={styles.buttonsGroup}>
              {!admin && !isTablet && (
                <BrowserView className={styles.item}>
                  <AlterButton
                    msg={'dashboard.advertiser.navigation.referral.title'}
                    onClick={handleReferalClick}
                    className={styles.headerButton}
                    icon="Gift"
                  />
                </BrowserView>
              )}
              {showBookDemo && (
                <BrowserView>
                  <DemoButton />
                </BrowserView>
              )}
              <BrowserView className={styles.item}>
                <Balance
                  userType={userType}
                  balance={balance}
                  currency={currency}
                  newClient={newClient}
                  classes={{ button: styles.headerButton }}
                />
              </BrowserView>
              {!isTablet && (
                <BrowserView className={styles.item}>
                  <Help classes={{ button: styles.headerButton }} />
                </BrowserView>
              )}
              <User
                label={label || ''}
                menuContent={createUserContent()}
                menuList={finalMenuList()}
              />
            </div>
            <ReferBusiness
              open={isReferBusinessDrawerOpened}
              onClose={handleReferBusinessDrawerClose}
            />
          </div>
        );
      }}
    />
  );
};

export default track()(AdvertiserHeader);

/**
 * @generated SignedSource<<4bc65e7b20fc508b3220b06e70251cea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GmailSettingsForOutreach_campaign$data = {
  readonly organization: {
    readonly gmailAuthorizations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly availableEmails: ReadonlyArray<string> | null | undefined;
          readonly availableNames: ReadonlyArray<string> | null | undefined;
          readonly id: string;
          readonly reauthenticationRequired: boolean;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "GmailSettingsForOutreach_campaign";
};
export type GmailSettingsForOutreach_campaign$key = {
  readonly " $data"?: GmailSettingsForOutreach_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"GmailSettingsForOutreach_campaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GmailSettingsForOutreach_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GmailAuthorizationConnection",
          "kind": "LinkedField",
          "name": "gmailAuthorizations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GmailAuthorizationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GmailAuthorization",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reauthenticationRequired",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "availableEmails",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "availableNames",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "81d9d5504f2c7b007cb6da7627e50440";

export default node;

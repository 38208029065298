import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  UpdateSearchQueryInput,
  UpdateSearchQueryMutation$data,
  UpdateSearchQueryMutation,
} from 'GraphTypes/UpdateSearchQueryMutation.graphql';

const mutation = graphql`
  mutation UpdateSearchQueryMutation($input: UpdateSearchQueryInput!) {
    updateSearchQuery(input: $input) {
      campaign {
        id
        organization {
          customLists {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        searchQuery {
          id
          ageRanges {
            id
            name
          }
          audienceCountryPercentage
          audienceAgeRangePercentage
          audienceGenderPercentage
          onlyCreatorsWorkedWith
          audienceGender {
            id
            name
          }
          audienceCities {
            id
            name
          }
          categories {
            id
            name
          }
          cities {
            id
            name
          }
          countries {
            id
            name
          }
          creatorCountries {
            id
            name
          }
          currency
          creatorEthnicities {
            id
            name
          }
          creatorLanguages {
            id
            name
          }
          creatorAnimals {
            id
            name
          }
          creatorBodyTypes {
            id
            name
          }
          creatorHairTypes {
            id
            name
          }
          audienceEthnicities {
            id
            name
          }
          exclusions {
            advertiserProjectStages
            campaign {
              id
              name
            }
          }
          followersFrom
          followersTo
          realFollowersFrom
          realFollowersTo
          creatorBirthdateFrom
          creatorBirthdateTo
          engagementRateFrom
          engagementRateTo
          viewsPerPostTo
          viewsPerPostFrom
          viewsMedianTo
          viewsMedianFrom
          storiesReachFrom
          storiesReachTo
          postsReachFrom
          postsReachTo
          reelsPlaysMedianFrom
          reelsPlaysMedianTo
          # interests
          audienceInterestPercentage
          campaignsCompletionsCountFrom
          campaignsCompletionsCountTo
          audienceCityPercentage
          customLists {
            id
            name
          }
          creatorGender {
            id
            name
          }
          interests {
            id
            name
          }
          postPriceFrom
          postPriceTo
          tags {
            id
            name
          }
          hashtags
          excludeBadges
          includeAmazonStorefrontLink
          favorite
          excludeHiredMoreThanOnce
          strictlyByPrimaryCategory
          withTiktokShop
        }
      }
    }
  }
`;

const defaultSearchParams = {
  interestsIds: [],
  audienceEthnicityIds: [],
  creatorEthnicityIds: [],
  ageRangesIds: [],
  countryIds: [],
  tagsIds: [],
  citiesIds: [],
  audienceGenderId: null,
  creatorGenderId: null,
  categoriesIds: [],
  followersFrom: null,
  followersTo: null,
  postPriceFrom: null,
  postPriceTo: null,
  creatorBirthdateFrom: null,
  creatorBirthdateTo: null,
  engagementRateFrom: null,
  engagementRateTo: null,
  audienceCitiesIds: [],
  creatorCountryIds: [],
  hashtags: [],
  strictlyByPrimaryCategory: false,
  onlyCreatorsWorkedWith: null,
  customLists: [],
};

type fnType = (data: object) => void;

type Data = {
  reset?: boolean;
};

export default (
  data: UpdateSearchQueryInput & Data,
  resolve?: (response: UpdateSearchQueryMutation$data) => void,
  reject?: fnType
) => {
  const { id, reset } = data;
  const updateData = reset ? { id, ...defaultSearchParams } : data;

  const variables = {
    input: {
      ...updateData,
    },
  };

  commitMutation<UpdateSearchQueryMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  CreateExtraPayoutInput,
  CreateExtraPayoutMutation$data,
  CreateExtraPayoutMutation,
} from 'GraphTypes/CreateExtraPayoutMutation.graphql';

const mutation = graphql`
  mutation CreateExtraPayoutMutation($input: CreateExtraPayoutInput!) {
    createExtraPayout(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateExtraPayoutInput,
  resolve?: (response: CreateExtraPayoutMutation$data) => void,
  reject?: fnType
) => {
  const { amount, projectId, currency } = data;

  const variables = {
    input: {
      amount,
      projectId,
      currency,
    },
  };

  commitMutation<CreateExtraPayoutMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

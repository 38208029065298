import { graphql } from 'react-relay';

import type {
  TransactionsChartQuery as TransactionsChartQueryType,
  TransactionsChartQuery$variables,
  Performance_Granularity,
  TransactionsChartQuery$data,
} from 'GraphTypes/TransactionsChartQuery.graphql';

const TransactionsChartQuery = graphql`
  query TransactionsChartQuery(
    $brandId: ID!
    $campaignIds: [ID!]
    $dateFrom: Date!
    $dateTo: Date!
    $granularity: Performance_Granularity!
    $platform: CampaignPlatform
    $campaignType: CampaignType
    $campaignPostingType: CampaignPostingType
    $useCase: CampaignUseCase
  ) {
    performance {
      paymentTransactionCounters(
        brandId: $brandId
        campaignIds: $campaignIds
        dateFrom: $dateFrom
        dateTo: $dateTo
        granularity: $granularity
        platform: $platform
        campaignType: $campaignType
        campaignPostingType: $campaignPostingType
        campaignUseCase: $useCase
      ) {
        edges {
          node {
            dateFrom
            dateTo
            totalExpenses
            totalPayouts
            totalReimbursements
            totalExtraPayouts
            averagePrice
          }
        }
        totalExpenses
        totalPayouts
        totalReimbursements
        totalExtraPayouts
        totalAveragePrice
      }
    }
  }
`;

export { TransactionsChartQuery };

// types

export type {
  Performance_Granularity,
  TransactionsChartQueryType,
  TransactionsChartQuery$data,
  TransactionsChartQuery$variables,
};

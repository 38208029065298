/**
 * @generated SignedSource<<7e0c54ed2911f970d569043f9fc93672>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InstagramInsightsAuthorizationResult = "FACEBOOK_ERROR" | "NOT_ALL_PERMISSIONS_GRANTED" | "NO_BUSINESS_ACCOUNT" | "SUCCESS" | "%future added value";
export type AuthorizeInstagramInsightsInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  instagramUsername: string;
  socialIdentityId?: string | null | undefined;
};
export type AuthorizeInstagramInsightsMutation$variables = {
  input: AuthorizeInstagramInsightsInput;
};
export type AuthorizeInstagramInsightsMutation$data = {
  readonly authorizeInstagramInsights: {
    readonly result: InstagramInsightsAuthorizationResult;
  } | null | undefined;
};
export type AuthorizeInstagramInsightsMutation = {
  response: AuthorizeInstagramInsightsMutation$data;
  variables: AuthorizeInstagramInsightsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthorizeInstagramInsightsPayload",
    "kind": "LinkedField",
    "name": "authorizeInstagramInsights",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorizeInstagramInsightsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorizeInstagramInsightsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7feaad32ea6f7c2ad1fb8a743a13558f",
    "id": null,
    "metadata": {},
    "name": "AuthorizeInstagramInsightsMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorizeInstagramInsightsMutation(\n  $input: AuthorizeInstagramInsightsInput!\n) {\n  authorizeInstagramInsights(input: $input) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d2fa369bde972f6e841c95955e85fff";

export default node;

import React, { useContext } from 'react';

import styles from './ReactivateSubscriptionButton.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import { useReactivateSubscriptionMutation } from 'Api/mutations/ReactivateSubscription.Mutation';

interface Props {
  subscriptionId: string;
}

const ReactivateSubscriptionButton: React.FC<Props> = (props) => {
  const { subscriptionId } = props;
  const [reactivateSubscription, loading] = useReactivateSubscriptionMutation();
  const handleReactivateClick = () => {
    reactivateSubscription({
      variables: {
        input: {
          subscriptionId,
        },
      },
    });
  };

  return (
    <AlterButton
      textByCenter
      type={'pink'}
      fluid
      loading={loading}
      msg="billing.current_plan.reactivate_plan"
      onClick={handleReactivateClick}
    />
  );
};

export default ReactivateSubscriptionButton;

import {
  ADD_CREATOR_FOR_EXLUSION,
  REMOVE_CREATOR_FOR_EXLUSION,
  ADD_CREATORS_TO_BULK,
  REMOVE_CREATOR_FROM_BULK,
  START_CREATORS_BULK_ADDING,
  TOGGLE_CREATORS_BULK_ADDING,
  CLEAR_BULK_CREATORS_DATA,
  SET_CREATORS_BULK_COUNT,
  STOP_CREATORS_BULK_ADDING,
} from 'Constants/index';

export interface AddCreatorsToBulkType {
  type: string,
  payload: AddCreatorsToBulkPayload,
}

export interface StartCreatorsBulkAddingType {
  type: string,
  payload: StartCreatorsBulkAddingPayload,
}

interface AddCreatorsToBulkPayload {
  creatorIds: string[],
}

interface AddCreatorForExclusionPayload {
  creatorId: string,
  username?: string,
}

interface RemoveCreatorForExclusionPayload {
  creatorId: string,
}

interface RemoveCreatorsBulkAddingPayload {
  creatorId: string,
}

interface StartCreatorsBulkAddingPayload {
  campaignId: string,
}


export const addCreatorsToBulk = (data: AddCreatorsToBulkPayload) => ({
  type: ADD_CREATORS_TO_BULK,
  payload: data,
});

export const addCreatorForExclusion = (data: AddCreatorForExclusionPayload) => ({
  type: ADD_CREATOR_FOR_EXLUSION,
  payload: data,
});

export const removeCreatorForExclusion = (data: RemoveCreatorForExclusionPayload) => ({
  type: REMOVE_CREATOR_FOR_EXLUSION,
  payload: data,
});

export const startCreatorsBulkAdding = (data: StartCreatorsBulkAddingPayload) => ({
  type: START_CREATORS_BULK_ADDING,
  payload: data,
});

export const removeCreatorsBulkAdding = (data: RemoveCreatorsBulkAddingPayload) => ({
  type: REMOVE_CREATOR_FROM_BULK,
  payload: data,
});

export const toggleCreatorsBulkAdding = (data: boolean) => ({
  type: TOGGLE_CREATORS_BULK_ADDING,
  payload: data,
});

export const clearBulkCreatorsData = () => ({
  type: CLEAR_BULK_CREATORS_DATA,
});

export const setCreatorsBulkCount = (data: number) => ({
  type: SET_CREATORS_BULK_COUNT,
  payload: data,
});

export const stopCreatorsBulkAdding = () => ({
  type: STOP_CREATORS_BULK_ADDING,
});

import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './PortfolioManager.css';

import { useUpload, FileType } from 'Hooks/useUpload';
import deletePortfolioItem from 'Mutations/DeletePortfolioItem.Mutation';
import createPortfolioItem from 'Mutations/CreatePortfolioItem.Mutation';
import Field from 'Molecules/Field/Field';
import FilePreview from 'Atoms/FilePreview/FilePreview';
import UploadFile from 'Components/UploadFile/UploadFile';
import Spinner from 'Atoms/Spinner/Spinner';
import { PortfolioManager_creator$key } from 'GraphTypes/PortfolioManager_creator.graphql';

interface Props {
  creator: PortfolioManager_creator$key;
}

const PortfolioManager: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment PortfolioManager_creator on Creator {
        id
        portfolioItems(first: 2147483647) @connection(key: "Profile_portfolioItems") {
          edges {
            node {
              id
              file {
                id
                secureUrl
                thumbnailUrl
                type
                ... on Video {
                  processingStatus
                  transformations {
                    autoUrl
                    collageThumbnailUrl
                  }
                }
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
        }
      }
    `,
    creator
  );
  const {
    id: creatorId,
    portfolioItems: { edges },
  } = data;

  const portfolioItemsList = edges || [];

  const [queue, setQueue] = useState<number[]>([]);

  const { uploadFile } = useUpload();

  const handleMediaUpload = (data: { file: FileType }) => {
    const file = data.file;
    if (!file) return;
    setQueue(queue.slice(1));
    createPortfolioItem({ fileId: file.id, creatorId });
  };

  const handleImageUploaded = (file: File) => {
    setQueue([...queue, Math.random()]);
    uploadFile({
      params: { file, category: 'portfolio_item' },
      onSuccess: handleMediaUpload,
    });
  };

  const handleDeleteClick = (id: string) => {
    deletePortfolioItem({ id, creatorId });
  };

  return (
    <Field title="creator_profile.portfolio" className={styles.root}>
      <div className={styles.content}>
        <div className={styles.uploadFile}>
          <UploadFile
            name="portfolio"
            result="upload"
            types="image/*,video/*"
            onImageUploaded={handleImageUploaded}
          />
        </div>
        {queue.map((item: number) => {
          return (
            <div key={item} className={styles.stub}>
              <Spinner />
            </div>
          );
        })}
        {portfolioItemsList.map((edge) => {
          if (!edge?.node) return null;
          const { id, file } = edge.node;

          return (
            <FilePreview
              key={id}
              id={id}
              file={file}
              onDeleteClick={handleDeleteClick}
              className={styles.item}
            />
          );
        })}
      </div>
    </Field>
  );
};

export default PortfolioManager;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  UpdateProjectMutation,
  UpdateProjectMutation$data,
  UpdateProjectInput,
} from 'GraphTypes/UpdateProjectMutation.graphql';

const mutation = graphql`
  mutation UpdateProjectMutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        id
        comment
        adminComment
        creatorDeclinedToParticipate
        plannedCompletionDate
        plannedPaymentType
        creativeLinks
        campaign {
          projectsSummary: projects(archivedByAdvertiser: false, orderBy: LAST_ADDED) {
            totalCount
            summary {
              currency
            }
          }
        }
        payoutPaymentMethod {
          id
          type
          ... on Payment_Card {
            cardNumber
          }
          ... on Payment_Paypal {
            email
          }
          ... on Payment_Wire {
            accountNumber
          }
          ... on Payment_Gamemoney {
            cardNumber
          }
          ... on Payment_Cardpay {
            cardNumber
          }
          ... on Payment_Zelle {
            email
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateProjectInput,
  resolve?: (response: UpdateProjectMutation$data) => void,
  reject?: fnType
) => {
  const {
    id,
    adminComment,
    comment,
    creatorDeclinedToParticipate,
    plannedCompletionDate,
    plannedPaymentType,
    payoutPaymentMethodId,
    published,
    creativeLinks,
    showcasingPermitted,
  } = data;

  const variables = {
    input: {
      id,
      adminComment,
      comment,
      creatorDeclinedToParticipate,
      plannedCompletionDate,
      plannedPaymentType,
      published,
      showcasingPermitted,
      payoutPaymentMethodId,
      creativeLinks,
    },
  };

  commitMutation<UpdateProjectMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useMemo } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import track, { useTracking } from 'react-tracking';
import classnames from 'classnames';

import styles from './BrandsFilter.pcss';

import environment from 'Api/Environment';
import { BrandsFilterQuery as QueryType } from 'GraphTypes/BrandsFilterQuery.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

const BrandsFilterQuery = graphql`
  query BrandsFilterQuery {
    brands {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

interface Props {
  selectedItems: {
    [key: string]: string;
  }[];
  onAdd: (id?: string, name?: string, check?: boolean) => void;
  className?: string;
}

const BrandsFilter: React.FC<Props> = (props) => {
  const tracking = useTracking();

  const { selectedItems, className, onAdd } = props;

  const label = useMemo(() => {
    const tempList: string[] = [];
    forEach(selectedItems, (item) => {
      const key = Object.keys(item)[0];
      if (key) {
        tempList.push(item[key]);
      }
    });
    return tempList.length ? { text: tempList.join(', ') } : { msg: 'campaigns.filter_all_brands' };
  }, [selectedItems]);

  const isDirty = selectedItems && selectedItems.length > 0;

  const handleClear = () => {
    onAdd();
  };

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={BrandsFilterQuery}
      variables={{}}
      render={({ props: queryProps }) => {
        if (queryProps) {
          const edges = queryProps.brands?.edges;

          const list = edges?.map((edge) => {
            if (!edge?.node) return null;

            const { id, name } = edge.node;
            const active = find(selectedItems, { [id]: name });

            const onClick = () => {
              const check = !active;
              onAdd(id, name || '', check);
              tracking.trackEvent({
                event: 'selected',
                payload: {
                  name,
                  value: !!check,
                },
              });
            };

            return {
              id,
              onClick,
              text: name,
            };
          });

          return (
            <Dropdown
              value={<Text type="md" {...label} className={styles.label} />}
              className={classnames(className, styles.brandSelector, { [styles.dirty]: isDirty })}
            >
              <DropdownGroup label="Brands" className={styles.group}>
                <div className={styles.brandDropdownList}>
                  <DropdownGroupItem handleClick={handleClear}>
                    <AlterButton
                      msg="campaigns.filter_all_brands"
                      fluid
                      iconPosition="right"
                      icon={!selectedItems || selectedItems.length === 0 ? 'Check' : null}
                    />
                  </DropdownGroupItem>
                  {list?.map((item) => {
                    if (!item) return null;
                    return (
                      <DropdownGroupItem key={item.id} handleClick={item?.onClick}>
                        <AlterButton
                          text={item?.text}
                          fluid
                          iconPosition={'right'}
                          icon={
                            selectedItems.some((selectedItem) => !!selectedItem[item.id])
                              ? 'Check'
                              : null
                          }
                        />
                      </DropdownGroupItem>
                    );
                  })}
                </div>
              </DropdownGroup>
            </Dropdown>
          );
        }

        return <div />;
      }}
    />
  );
};

export default track({ element: 'all_brands_filter' })(BrandsFilter);

/**
 * @generated SignedSource<<373b418817472d439f097480c5750e21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Accounting_InvoiceStatus = "ISSUED" | "NOT_ISSUED" | "NOT_REQUIRED" | "%future added value";
export type Accounting_PaymentMethodType = "INVOICE" | "MANUAL" | "PSP" | "PSP_INVOICE" | "%future added value";
export type Accounting_PaymentStatus = "NOT_PAID" | "NOT_REQUIRED" | "PAID" | "REFUNDED" | "%future added value";
export type Accounting_RevenueTransactionType = "ACTIVATION_FEE" | "CREATOR_EXPENSE" | "CUSTOM_SERVICE" | "DEPOSIT" | "FULLY_MANAGED_ZEROING" | "REFERRAL_REWARD" | "REFUND" | "SALES_PEAK_REWARD" | "SUBSCRIPTION" | "%future added value";
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type SubscriptionStatusType = "ADDONS" | "DOWNGRADE" | "INITIAL" | "OTHER" | "RENEW" | "UPGRADE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Transactions_transactions$data = {
  readonly accounting: {
    readonly revenueTransactions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly author: {
            readonly advertiserProfile: {
              readonly firstName: string | null | undefined;
              readonly lastName: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly campaign: {
            readonly id: string;
            readonly name: string | null | undefined;
          } | null | undefined;
          readonly comment: string | null | undefined;
          readonly createdAt: any;
          readonly csmMarkup: number;
          readonly currency: Currency;
          readonly department: {
            readonly name: string;
          } | null | undefined;
          readonly id: string;
          readonly invoiceStatus: Accounting_InvoiceStatus;
          readonly netCost: number;
          readonly organization: {
            readonly id: string;
            readonly name: string;
          };
          readonly paidAt: any | null | undefined;
          readonly paymentMethodType: Accounting_PaymentMethodType;
          readonly paymentStatus: Accounting_PaymentStatus;
          readonly profit: number;
          readonly revenue: number;
          readonly subscriptionAddonItems: ReadonlyArray<{
            readonly addon: {
              readonly id: AddonId;
            };
            readonly quantity: number;
          }> | null | undefined;
          readonly subscriptionPlanId: PlanId | null | undefined;
          readonly subscriptionStatusType: SubscriptionStatusType | null | undefined;
          readonly type: Accounting_RevenueTransactionType | null | undefined;
          readonly verifiedByAdmin: boolean;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
      };
      readonly summary: {
        readonly netCost: number | null | undefined;
        readonly profit: number | null | undefined;
        readonly revenue: number | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TransactionSummary_summary">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Transactions_transactions";
};
export type Transactions_transactions$key = {
  readonly " $data"?: Transactions_transactions$data;
  readonly " $fragmentSpreads": FragmentRefs<"Transactions_transactions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netCost",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profit",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenue",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 15,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateTo"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paidDateFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paidDateTo"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentMethodFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentStatus"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "subscriptionStatusType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "accounting",
          "revenueTransactions"
        ]
      }
    ]
  },
  "name": "Transactions_transactions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Accounting_Query",
      "kind": "LinkedField",
      "name": "accounting",
      "plural": false,
      "selections": [
        {
          "alias": "revenueTransactions",
          "args": null,
          "concreteType": "Accounting_RevenueTransactionConnection",
          "kind": "LinkedField",
          "name": "__Admin_revenueTransactions_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Accounting_RevenueTransactionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Accounting_RevenueTransaction",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "paidAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "paymentMethodType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "author",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AdvertiserProfile",
                          "kind": "LinkedField",
                          "name": "advertiserProfile",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lastName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Department",
                      "kind": "LinkedField",
                      "name": "department",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Organization",
                      "kind": "LinkedField",
                      "name": "organization",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Campaign",
                      "kind": "LinkedField",
                      "name": "campaign",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subscriptionStatusType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddonItem",
                      "kind": "LinkedField",
                      "name": "subscriptionAddonItems",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Addon",
                          "kind": "LinkedField",
                          "name": "addon",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "quantity",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subscriptionPlanId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currency",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "csmMarkup",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "paymentStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoiceStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "verifiedByAdmin",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comment",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Accounting_RevenueTransactionSummary",
              "kind": "LinkedField",
              "name": "summary",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TransactionSummary_summary"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "4865b4dfc8685bb88c1f929d3b417b53";

export default node;

/**
 * @generated SignedSource<<47829f68b7b49520a6c10311141e7dc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BcaPermissionState = "APPROVED" | "CANCELED" | "EXPIRED" | "INVALID" | "PENDING_APPROVAL" | "REVOKED" | "UNKNOWN" | "%future added value";
export type ErrorType = "ALREADY_EXIST_ERROR" | "CREATOR_ALREADY_OWNED_ERROR" | "NOT_CLAIMED" | "NO_CREATOR" | "SIGNIN_ERROR" | "SIGNUP_ERROR" | "VALIDATION_ERROR" | "%future added value";
export type NotFoundErrorType = "NO_ACCOUNT" | "NO_CAMPAIGN" | "NO_CAMPAIGN_SUMMARY" | "NO_CREATOR" | "NO_CREATORS" | "NO_CREATOR_BLOCKING" | "NO_CUSTOM_LIST" | "NO_DEADLINE_CHANGE_REQUEST" | "NO_MEDIAPLAN" | "NO_MESSAGE" | "NO_OFFER" | "NO_ORGANIZATION" | "NO_PAYMENT_ACCOUNT" | "NO_PAYOUT_PRICE" | "NO_PROJECT" | "NO_PROVINCE" | "NO_SHOPIFY_AUTHORIZATION" | "NO_USER" | "%future added value";
export type RevokeBcaPermissionInput = {
  clientMutationId?: string | null | undefined;
  projectId: string;
};
export type RevokeBcaPermissionMutation$variables = {
  input: RevokeBcaPermissionInput;
};
export type RevokeBcaPermissionMutation$data = {
  readonly revokeBcaPermission: {
    readonly __typename: "NotFoundError";
    readonly message: string;
    readonly subtype: NotFoundErrorType;
    readonly type: ErrorType;
  } | {
    readonly __typename: "RevokeBcaPermissionPayload";
    readonly bcaPermission: {
      readonly id: string;
      readonly state: BcaPermissionState;
    } | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type RevokeBcaPermissionMutation = {
  response: RevokeBcaPermissionMutation$data;
  variables: RevokeBcaPermissionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "revokeBcaPermission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BcaPermission",
            "kind": "LinkedField",
            "name": "bcaPermission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "RevokeBcaPermissionPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subtype",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "type": "NotFoundError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RevokeBcaPermissionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RevokeBcaPermissionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "94fa82c0342d2e80bb29e81614663d72",
    "id": null,
    "metadata": {},
    "name": "RevokeBcaPermissionMutation",
    "operationKind": "mutation",
    "text": "mutation RevokeBcaPermissionMutation(\n  $input: RevokeBcaPermissionInput!\n) {\n  revokeBcaPermission(input: $input) {\n    __typename\n    ... on RevokeBcaPermissionPayload {\n      bcaPermission {\n        id\n        state\n      }\n    }\n    ... on NotFoundError {\n      message\n      subtype\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0ab69a3ebeb8c5c4c8c7322dca260cb";

export default node;

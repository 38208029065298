import React, { RefObject } from 'react';

import styles from './PortfolioItemMedia.pcss';

import ImageWithFallback from 'Components/ui/ImageWithFallback/ImageWithFallback';
import ImageWithFallbackBoundary from 'Components/ui/ImageWithFallback/ImageWithFallbackBoundary';

// import HoverVideoPlayer from 'Components/HoverVideoPlayer/HoverVideoPlayer';

type VideoProps = {
  videoUrl?: string | null;
  thumbnailUrl?: string;
  instagramUrl?: string;
  parentRef: RefObject<HTMLElement>;
};

type ImageProps = {
  thumbnailUrl: string;
  instagramUrl?: string;
  onClick: () => void;
};

const PortfolioItemMedia: React.FC<VideoProps | ImageProps> = (props) => {
  // const { videoUrl, thumbnailUrl, parentRef } = props as VideoProps;
  const { instagramUrl, onClick } = props as ImageProps;

  // IG not returned any direct url for video posts
  // if (videoUrl) {
  //   return (
  //     <HoverVideoPlayer
  //       src={videoUrl}
  //       poster={thumbnailUrl}
  //       parentRef={parentRef}
  //     />
  //   );
  // }
  if (instagramUrl) {
    return (
      <div onClick={onClick}>
        <ImageWithFallbackBoundary>
          <ImageWithFallback url={instagramUrl} className={styles.mediaPreview} />
        </ImageWithFallbackBoundary>
      </div>
    );
  }
  return null;
};

export default PortfolioItemMedia;

/**
 * @generated SignedSource<<ff42b3e7ea1155cb7c0111ec1774a271>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeDeadlineAction_project$data = {
  readonly archivation: {
    readonly id: string;
  } | null | undefined;
  readonly deadline: {
    readonly draftDate: any | null | undefined;
  } | null | undefined;
  readonly deadlineChangeRequest: {
    readonly draftDate: any | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "ChangeDeadlineAction_project";
};
export type ChangeDeadlineAction_project$key = {
  readonly " $data"?: ChangeDeadlineAction_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeDeadlineAction_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draftDate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeDeadlineAction_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Deadline",
      "kind": "LinkedField",
      "name": "deadline",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeadlineChangeRequest",
      "kind": "LinkedField",
      "name": "deadlineChangeRequest",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "25c01e3d1abe8182d1bbc49cfed4225d";

export default node;

export const ratings = [{ score: 1 }, { score: 2 }, { score: 3 }, { score: 4 }, { score: 5 }];

export const textualRating = [{ score: 1 }, { score: 4 }, { score: 5 }];

export const getCharacteristic = (highScore) => {
  if (!highScore) {
    return [
      'LOW_CREATIVITY',
      'LOW_TECHNICAL_QUALITY',
      'NOT_FOLLOWING_BRIEF',
      'SLOW_WORK',
      'BAD_COMMUNICATION',
      'BAD_PRICE_QUALITY_RATIO',
    ];
  }

  return [
    'HIGH_CREATIVITY',
    'HIGH_TECHNICAL_QUALITY',
    'FOLLOWING_BRIEF',
    'FAST_WORK',
    'GOOD_COMMUNICATION',
    'GOOD_PRICE_QUALITY_RATIO',
  ];
};

import React, { Suspense, useCallback, useMemo, useState, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { KeyType } from 'react-relay/relay-hooks/helpers';
import classnames from 'classnames';
import { FragmentRefs } from 'relay-runtime';

import CreatorDetails from '../../../../components/CreatorDetails/CreatorDetails';

import styles from './Shipment.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import { Checkbox } from 'Components/ui/Checkbox';
import { Shipment_project$data, Shipment_project$key } from 'GraphTypes/Shipment_project.graphql';
import { amplitude } from 'Helpers/amplitude';
import CreatorChatDrawers from 'AdvertiserPage/Campaign/Creators/CreatorsList/CreatorsListContainer/CreatorCard/CreatorCardControls/CreatorChatDrawers/CreatorChatDrawers';
import { ProjectEdgesWithNonNullableNodes } from 'AdvertiserPage/Creators/tabs/Shipment/Shipments/ShipmentList';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

type OrderStatusType = 'created' | 'notCreated' | 'error' | 'processing';

interface Props {
  project: ProjectEdgesWithNonNullableNodes['node'] & {
    ' $fragmentRefs'?: FragmentRefs<'CreatorChatDrawers_project' | 'Shipment_project'>;
  };
  campaignId?: string;
  disabledShipment?: boolean;
  selected?: boolean;
  onSelectProject: (projectId: string, forceSelected?: boolean) => void;
  onCreateOrder: (projectId: string) => void;
  isAdmin: boolean;
}

const Shipment: React.FC<Props> = (props) => {
  const { project, disabledShipment, selected, isAdmin, campaignId, onSelectProject } = props;

  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const unpackedProject = useFragment<KeyType<Shipment_project$data>>(
    graphql`
      fragment Shipment_project on Project {
        id
        shopifyOrder {
          id
          state
        }
        shippingAddress {
          address1
          address2
          city
          country {
            name
          }
          province {
            name
          }
          phone
          firstName
          lastName
          zip
        }
        creator {
          ownership {
            owner {
              email
            }
          }
          id
          username
          ...CreatorDetails_creator
        }
      }
    `,
    normalizeFragmentRef<Shipment_project$key>(project)
  );

  if (!unpackedProject) return null;
  const { shippingAddress } = unpackedProject;
  const fullAddress = `${shippingAddress?.address1} ${shippingAddress?.address2}, ${
    shippingAddress?.city
  }, ${shippingAddress?.province?.name ? shippingAddress?.province?.name + ', ' : ''}${
    shippingAddress?.zip
  }, ${shippingAddress?.country?.name}`;

  const shipmentReceiverName = `${unpackedProject?.shippingAddress?.firstName} ${unpackedProject?.shippingAddress?.lastName}`;

  const orderStatus = useMemo<OrderStatusType>(() => {
    if (!unpackedProject?.shopifyOrder) {
      return 'notCreated';
    }
    if (unpackedProject?.shopifyOrder?.state === 'PENDING') {
      return 'processing';
    }
    if (unpackedProject?.shopifyOrder?.state === 'SUCCESS') {
      return 'created';
    }
    return 'error';
  }, [unpackedProject?.shopifyOrder]);

  const closeModal = useCallback(() => {
    closeDrawer(`chat-modal-${project.id}`);
  }, []);

  const handleChatClick = () => {
    amplitude.sendEvent<325>({
      id: '325',
      category: 'shipment',
      name: 'chat_click',
      param: { campaign_id: `${campaignId}` },
    });
    openDrawer(`chat-modal-${project.id}`);
  };

  return (
    <div className={styles.root}>
      <Suspense fallback={<div />}>
        <CreatorChatDrawers
          isAdmin={isAdmin}
          projectId={unpackedProject?.id}
          project={project}
          closeModal={closeModal}
        />
      </Suspense>
      <div className={styles.content}>
        <div
          className={classnames(styles.checkboxContainer, {
            [styles.pointer]:
              (orderStatus === 'notCreated' || orderStatus === 'error') && !disabledShipment,
          })}
          onClick={
            (orderStatus === 'notCreated' || orderStatus === 'error') && !disabledShipment
              ? () => onSelectProject(unpackedProject?.id)
              : undefined
          }
        >
          {orderStatus !== 'created' && orderStatus !== 'processing' && (
            <Checkbox disabled={disabledShipment} checked={selected} />
          )}
        </div>
        {!!unpackedProject.creator && (
          <div className={styles.creator}>
            <CreatorDetails
              creator={unpackedProject.creator}
              shipmentReceiverName={shipmentReceiverName}
              shipment
              disableBadge
            />
          </div>
        )}
        <div className={styles.orderStatus}>
          <Text type="label" color={'grey'} msg={'shipment.order_status'} />
          <Text
            msg={`shipment.order.status.${orderStatus}`}
            type="md"
            className={classnames(styles.default, {
              [styles.error]: orderStatus === 'error',
              [styles.notCreated]: orderStatus === 'notCreated',
              [styles.created]: orderStatus === 'created',
            })}
          />
        </div>
        <div className={styles.email}>
          <Text type="label" color={'grey'} msg={'general.email'} />
          <Text text={unpackedProject?.creator?.ownership?.owner?.email} type="md" />
        </div>
        <div className={styles.phone}>
          <Text type="label" color={'grey'} msg={'general.phone'} />
          <Text text={shippingAddress?.phone} type="md" />
        </div>
        <div className={styles.address}>
          <Text type="label" color={'grey'} msg={'general.address'} />
          <Text text={fullAddress} type="md" />
        </div>
        <div className={styles.controls}>
          <AlterButton icon="Chat" onClick={handleChatClick} type="grey" />
        </div>
      </div>
    </div>
  );
};

export default Shipment;

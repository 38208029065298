import React, { useCallback, useState, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useNavigate, useBlocker, BlockerFunction } from 'react-router-dom';
import track, { useTracking } from 'react-tracking';
import compact from 'lodash/compact';

import styles from './Actions.pcss';

import activateCampaign from 'Mutations/ActivateCampaign.Mutation';
import { getCampaignTypeLink } from 'Util/links';
import submitMediaplan from 'Mutations/SubmitMediaplan.Mutation';
import { Actions_campaign$key } from 'GraphTypes/Actions_campaign.graphql';
import { Permission } from 'GraphTypes/MediaplanSectionQuery.graphql';
import Button from 'Atoms/Button/Button';
import IconMenu from 'Molecules/IconMenu/IconMenu';
import icons from 'Icons';
import { BRIEF, LONGLIST, AWAITING_LIST, CREATORS, LONG_TERM } from 'Constants/general';
import ReportButton, { ReportType } from 'Atoms/ReportButton/ReportButton';
import { amplitude } from 'Helpers/amplitude';
import SaveMediaplan from 'Modal/advertiser/SaveMediaplan/SaveMediaplan';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

type BlockedData = {
  isExit?: boolean;
  closeCallback?: () => void;
};

interface Props {
  className: string;
  canImportMediaplan: boolean;
  admin: boolean;
  canManageLonglists: boolean;
  hasSubmission?: boolean;
  campaign: Actions_campaign$key;
  permissions: Permission[];
}

const Actions: React.FC<Props> = (props) => {
  const { className, canImportMediaplan, admin, hasSubmission, campaign } = props;

  const data = useFragment(
    graphql`
      fragment Actions_campaign on Campaign {
        id
        name
        type
        platform
        mediaplanSubmission {
          id
        }
        briefSubmission {
          acceptance {
            id
          }
        }
        projectsCount: projects(archived: false, orderBy: LAST_ADDED) {
          totalCount
        }
        creatorImport {
          id
          completed
        }
      }
    `,
    campaign
  );

  const {
    type,
    id: campaignId,
    name,
    briefSubmission,
    mediaplanSubmission,
    projectsCount,
    creatorImport,
  } = data;

  const navigate = useNavigate();

  const tracking = useTracking();

  const { openDrawer } = useContext(DrawerContext);

  const [downloadFinanceReport, setFinanceReportDownloadStatus] = useState(false);
  const [downloadMediaplanReport, setMediaplanReportDownloadStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blockedData, setBlockedData] = useState<BlockedData>({});

  const projectsCountValue = projectsCount.totalCount;
  const isImportInProgress = !!(creatorImport?.id && !creatorImport.completed);
  const isLongTermCampaign = type === LONG_TERM;

  const blockerFn = useCallback<BlockerFunction>(
    (tx) => {
      if (!name && (Number(projectsCountValue) > 0 || isImportInProgress)) {
        setBlockedData({
          isExit: true,
        });
        openDrawer('save-mediaplan');
        return true;
      }

      return false;
    },
    [name, projectsCountValue, isImportInProgress]
  );

  const navigationBlocker = useBlocker(blockerFn);

  const handleStartCampaignClick = () => {
    tracking.trackEvent({
      event: 'create_campaign',
      payload: {
        type: name ? 'save' : 'start',
      },
    });
    amplitude.sendEvent({
      id: 229,
      category: 'marketplace',
      name: 'create_campaign',
      param: { campaign_id: campaignId },
    });

    if (hasSubmission && name) {
      setLoading(true);
      activateCampaign({ campaignId }, handleMediaplanSubmitted);
      return;
    }
    if (mediaplanSubmission) {
      handleMediaplanSubmitted();
    } else {
      submitMediaplan({ campaignId }, handleMediaplanSubmitted);
    }
  };

  const handleMediaplanSubmitted = () => {
    if (name) {
      let path;
      const currentCampaignId = campaignId;
      if ((window as any).isApproval) {
        path = LONGLIST;
      } else {
        const campaignLink = isLongTermCampaign ? CREATORS : AWAITING_LIST;
        path = briefSubmission ? campaignLink : BRIEF;
      }
      const link = getCampaignTypeLink({ campaignId: currentCampaignId, path });
      navigate(link);
    } else {
      setBlockedData({});
      openDrawer('save-mediaplan');
    }
  };

  const handleDownloadFinanceReport = () => {
    setFinanceReportDownloadStatus(true);
  };

  const handleDownloadMediaplanReport = () => {
    setMediaplanReportDownloadStatus(true);
  };

  const dropDownlist = compact([
    canImportMediaplan || admin
      ? {
          id: 'download',
          isDisabled: !canImportMediaplan || downloadMediaplanReport,
          component: (
            <ReportButton
              type={ReportType.MEDIAPLAN}
              reportData={{ campaignId }}
              onDownload={handleDownloadMediaplanReport}
            />
          ),
          onClick: () => {
            tracking.trackEvent({
              event: 'download',
              payload: {
                enabled: !!canImportMediaplan,
              },
            });
          },
          textData: {
            textData: { msg: 'mediaplan.dropdown.upload_creators_list' },
          },
          tooltipData: canImportMediaplan
            ? undefined
            : {
                place: 'left',
                tooltipMsg: 'tooltip.limit_avaiable',
              },
        }
      : undefined,
    admin
      ? {
          id: 'downloadTemplate',
          isDisabled: !!downloadFinanceReport,
          component: (
            <ReportButton
              type={ReportType.FINANCE}
              reportData={{ campaignId }}
              onDownload={handleDownloadFinanceReport}
            />
          ),
          textData: {
            textData: { msg: 'mediaplan.dropdown.template' },
          },
        }
      : undefined,
  ]);

  const saveTitle = hasSubmission ? 'browse_creators.send_brief' : 'browse_creators.save';

  return (
    <div className={`${className} ${styles.root}`}>
      <div className={`${styles.button} ${styles.saveCampaign}`}>
        <Button
          width="full"
          color="secondary"
          theme="dark"
          loading={loading}
          onClick={handleStartCampaignClick}
          msg={name ? saveTitle : 'browse_creators.start_campaign'}
        />
      </div>
      {dropDownlist.length > 0 && (
        <div className={styles.button}>
          <IconMenu
            btnData={{
              color: 'secondary',
              theme: 'dark',
              icon: icons.more,
            }}
            dropDownData={{
              selfClose: true,
              list: dropDownlist,
              position: 'topRight',
              className: admin ? styles.dropdown : undefined,
            }}
          />
        </div>
      )}
      <SaveMediaplan
        campaignId={campaignId}
        {...blockedData}
        navigationBlocker={navigationBlocker}
      />
    </div>
  );
};

const ActionsWrap = track({ element: 'actions' })(Actions);

export default ActionsWrap;

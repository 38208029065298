import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';
import { parse, ParseResult } from 'papaparse';

import styles from './FailedModeration.css';

import ls from 'Util/localStorage';
import markCreatorAsFailedReview from 'Mutations/MarkCreatorAsFailedReview.Mutation';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import { CreatorReviewReason } from 'GraphTypes/MarkCreatorAsFailedReviewMutation.graphql';

let totalCount = 0;
let restCount = 0;

const FailedModeration = () => {
  const lsEmail = ls.get('email');

  const tracking = useTracking();

  const [loading, setLoading] = useState(false);

  const handleListChange = (files: FileList | null) => {
    setLoading(true);

    if (!files) return;

    parse(files[0], {
      delimiter: 'tab',
      complete: handleParseComplete,
    });
  };

  const handleParseComplete = ({ data, errors }: ParseResult<string>) => {
    if (errors.length !== 0 || data.length === 0) {
      return;
    }

    totalCount = data.length;

    data.forEach((item) => {
      const itemData = item[0].split(',');
      const handleMarkDone = () => {
        tracking.trackEvent({
          section: 'moderation_file',
          element: 'item',
          event: 'loaded',
          payload: { userId: itemData[0], reason: itemData[1], email: lsEmail },
        });
        handleDone();
      };
      markCreatorAsFailedReview(
        {
          creatorId: itemData[0],
          reason: itemData[1] as CreatorReviewReason,
        },
        handleMarkDone,
        handleMarkDone
      );
    });
  };

  const handleDone = () => {
    restCount++;

    if (totalCount === restCount) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Text tag="h2" weight="700" text="List of failed moderation" />
      <div></div>
      <div className={styles.uploadBtn}>
        <Button loading={loading} text="Upload csv" />
        <FileUploader typeList=".csv" className={styles.uploadInput} onChange={handleListChange} />
      </div>

      <div>
        <Text tag="h3" weight="700" text="Example" className={styles.example} />
        <table className={styles.table}>
          <tr>
            <td>05ea0a8d-77bf-499a-bbd6-18727afef883</td>
            <td>CELEBRITY</td>
          </tr>
          <tr>
            <td>b5c5270a-e08c-47cf-9f4e-c1324575fae7</td>
            <td>ADULT_CONTENT</td>
          </tr>
        </table>
        <Text tag="h3" weight="700" text="Reasons:" className={styles.reasons} />
        <ul className={styles.list}>
          <li>ADULT_CONTENT</li>
          <li>BRAND_OR_COMMUNITY</li>
          <li>CELEBRITY</li>
          <li>COMMUNITY</li>
          <li>WRONG_HANDLE</li>
          <li>NUMBER_OF_FOLLOWERS</li>
          <li>LOCATION</li>
          <li>POOR_AUDIENCE_QUALITY</li>
          <li>CONTENT_QUALITY</li>
          <li>PRIVATE_ACCOUNT</li>
          <li>PROHIBITED_CONTENT</li>
          <li>SPECIAL_CATEGORY</li>
          <li>ENGAGEMENT_RATE</li>
          <li>NUMBER_OF_VIEWS</li>
          <li>REAL_FOLLOWERS</li>
          <li>PORTFOLIO_QUALITY</li>
        </ul>
      </div>
    </div>
  );
};

export default track()(FailedModeration);

/**
 * @generated SignedSource<<cf75b47e31b4c97e5e31d8e7e5786345>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PauseSubscriptionInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  limitDays: number;
  subscriptionId: string;
};
export type PauseSubscriptionMutation$variables = {
  input: PauseSubscriptionInput;
};
export type PauseSubscriptionMutation$data = {
  readonly pauseSubscription: {
    readonly advertiserSubscription?: {
      readonly pauseEvent: {
        readonly pausedAt: any;
        readonly resumesAt: any;
      } | null | undefined;
      readonly paused: boolean;
    };
  } | null | undefined;
};
export type PauseSubscriptionMutation = {
  response: PauseSubscriptionMutation$data;
  variables: PauseSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionPauseEvent",
  "kind": "LinkedField",
  "name": "pauseEvent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pausedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resumesAt",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PauseSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "pauseSubscription",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "PauseSubscriptionPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PauseSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "pauseSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "PauseSubscriptionPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4af4d56e3038c049a52ecbcf016d8d56",
    "id": null,
    "metadata": {},
    "name": "PauseSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation PauseSubscriptionMutation(\n  $input: PauseSubscriptionInput!\n) {\n  pauseSubscription(input: $input) {\n    __typename\n    ... on PauseSubscriptionPayload {\n      advertiserSubscription {\n        paused\n        pauseEvent {\n          pausedAt\n          resumesAt\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6d247dfebcbc303e86a998780fd7abf";

export default node;

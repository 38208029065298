import React from 'react';

import styles from './ModerationSettings.css';

export const visibleOpt = [
  { msg: 'creator.is_searchable', value: 'VISIBLE' },
  { msg: 'creator.is_no_searchable', value: 'HIDDEN' },
];

export const employedOpt = [
  { msg: 'creator.has_job', value: 'HAS_JOB' },
  { msg: 'creator.has_no_job', value: 'HAS_NO_JOB' },
];

export const reviewedOpt = [
  { msg: 'creator.is_reviewed', value: 'SUCCESS' },
  { msg: 'creator.failed_review', value: 'FAILURE' },
];

export const hairTypeOpt = [
  { msg: 'creator.hair_type.straight', value: 'STRAIGHT' },
  { msg: 'creator.hair_type.curly', value: 'CURLY' },
];

export const badges = [
  { msg: 'creator.badge.absent', leftIcon: <div className={styles.absentIcon} />, value: 'ABSENT' },
  { msg: 'creator.badge.high_potential', leftIconName: 'highPotential', value: 'HIGH_POTENTIAL', tooltipData: { tooltipMsg: 'creator.badge.high_potential.tooltip.msg', place: 'right' } },
  { msg: 'creator.badge.top_rated', leftIconName: 'topRated', value: 'TOP_RATED', tooltipData: { tooltipMsg: 'creator.badge.top_rated.tooltip.msg', place: 'right' } },
];
import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateReferenceMutation,
  CreateReferenceInput,
  CreateReferenceMutation$data,
} from 'GraphTypes/CreateReferenceMutation.graphql';

const mutation = graphql`
  mutation CreateReferenceMutation($input: CreateReferenceInput!) {
    createReference(input: $input) {
      newReferenceEdge {
        node {
          id
          ...Reference_item
          file {
            url
            thumbnailUrl
            type
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateReferenceInput,
  resolve?: (response: CreateReferenceMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const variables = {
    input: {
      id,
      ...data,
    },
  };

  commitMutation<CreateReferenceMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const creative = store.get(data.briefCreativeId);
      if (!creative) return;

      const references = ConnectionHandler.getConnection(creative, 'Creative_references');

      if (references) {
        const referenceExists = references
          ?.getLinkedRecords('edges')
          ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === id);
        if (referenceExists) return;

        const reference = store
          .getRootField('createReference')
          .getLinkedRecord('newReferenceEdge')
          .getLinkedRecord('node');
        const edge = ConnectionHandler.createEdge(store, references, reference, 'ReferenceEdge');
        ConnectionHandler.insertEdgeAfter(references, edge);
      }

      const lastReferences = ConnectionHandler.getConnection(
        creative,
        'AssetPreview_lastReferences'
      );

      if (lastReferences) {
        const lastReferencesExists = lastReferences
          ?.getLinkedRecords('edges')
          ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === id);
        if (lastReferencesExists) return;

        const reference = store
          .getRootField('createReference')
          .getLinkedRecord('newReferenceEdge')
          .getLinkedRecord('node');
        const edge = ConnectionHandler.createEdge(
          store,
          lastReferences,
          reference,
          'ReferenceEdge'
        );
        ConnectionHandler.insertEdgeAfter(lastReferences, edge);
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<b8a511b6f2a1dd9df49baf02f4c45d89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateMessageInput = {
  attachmentIds?: ReadonlyArray<string> | null | undefined;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  text: string;
};
export type CreateMessageMutation$variables = {
  input: CreateMessageInput;
};
export type CreateMessageMutation$data = {
  readonly createMessage: {
    readonly newEventEdge: {
      readonly node: {
        readonly __typename: string;
        readonly actor: {
          readonly avatarUrl: string | null | undefined;
          readonly name: string | null | undefined;
        } | null | undefined;
        readonly createdAt: any;
        readonly id: string;
        readonly text?: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"MessageAttachments_message">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CreateMessageMutation = {
  response: CreateMessageMutation$data;
  variables: CreateMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postThumbnailUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMessagePayload",
        "kind": "LinkedField",
        "name": "createMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectEventEdge",
            "kind": "LinkedField",
            "name": "newEventEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "actor",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MessageAttachments_message"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "type": "Message",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMessagePayload",
        "kind": "LinkedField",
        "name": "createMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectEventEdge",
            "kind": "LinkedField",
            "name": "newEventEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "actor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isProjectEvent"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v8/*: any*/),
                        "concreteType": "MessageAttachmentConnection",
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageAttachmentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MessageAttachment",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "file",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "type",
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isFile"
                                      },
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "height",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "bytes",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "bcaTaggedUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "bcaTaggingStatus",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "thumbnailUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "secureUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "originalFilename",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "filename",
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "processingStatus",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "VideoTransformation",
                                            "kind": "LinkedField",
                                            "name": "transformations",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "autoUrl",
                                                "storageKey": null
                                              },
                                              (v9/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "Video",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ImageTransformation",
                                            "kind": "LinkedField",
                                            "name": "transformations",
                                            "plural": false,
                                            "selections": [
                                              (v9/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "Image",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "attachments(first:1000)"
                      },
                      {
                        "alias": null,
                        "args": (v8/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "Message_attachments",
                        "kind": "LinkedHandle",
                        "name": "attachments"
                      },
                      (v7/*: any*/)
                    ],
                    "type": "Message",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa2c14342f29ad7af924f9daac8bd98c",
    "id": null,
    "metadata": {},
    "name": "CreateMessageMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMessageMutation(\n  $input: CreateMessageInput!\n) {\n  createMessage(input: $input) {\n    newEventEdge {\n      node {\n        id\n        createdAt\n        __typename\n        actor {\n          __typename\n          name\n          avatarUrl\n          id\n        }\n        ...MessageAttachments_message\n        ... on Message {\n          text\n        }\n      }\n    }\n  }\n}\n\nfragment MessageAttachments_message on ProjectEvent {\n  __isProjectEvent: __typename\n  ... on Message {\n    id\n    attachments(first: 1000) {\n      edges {\n        node {\n          id\n          createdAt\n          file {\n            __typename\n            type\n            ...MessageMedia_file\n            id\n          }\n          ...MessageFile_attachment\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment MessageFile_attachment on MessageAttachment {\n  createdAt\n  file {\n    __typename\n    secureUrl\n    filename\n    originalFilename\n    bytes\n    id\n  }\n}\n\nfragment MessageMedia_file on File {\n  __isFile: __typename\n  id\n  type\n  width\n  height\n  bytes\n  bcaTaggedUrl\n  bcaTaggingStatus\n  thumbnailUrl\n  secureUrl\n  originalFilename\n  filename\n  ... on Video {\n    processingStatus\n    transformations {\n      autoUrl\n      postThumbnailUrl\n    }\n  }\n  ... on Image {\n    transformations {\n      postThumbnailUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "262e5751cd7eca88c0577fc9e44f74fa";

export default node;

import React, { cloneElement, PropsWithChildren, ReactElement, useEffect } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { GraphQLTaggedNode } from 'relay-runtime';

import useDownloadReportSubscription from 'Api/subscriptions/useDownloadReport.Subscription';
import { downloadFile } from 'Util/downloadFile';
import type { ReportButtonQuery } from 'GraphTypes/ReportButtonQuery.graphql';

type ReportDownloadButtonProps = {
  queryReference: any;
  query: GraphQLTaggedNode;
  filename: string;
  onDownload?: () => void;
  onDownloadError?: () => void;
};

const ReportDownloadButton: React.FC<PropsWithChildren<ReportDownloadButtonProps>> = (props) => {
  const { query, queryReference, children, filename, onDownload, onDownloadError } = props;
  useDownloadReportSubscription();

  const data = usePreloadedQuery<ReportButtonQuery>(query, queryReference);

  useEffect(() => {
    if (data?.reporting?.report) {
      const { state, expiresAt, downloadUrl, filename: reportFileName } = data?.reporting?.report;
      const isExpiresAtExists = typeof expiresAt === 'string';
      if (isExpiresAtExists && new Date(expiresAt) < new Date()) {
        onDownloadError?.();
        return;
      }
      switch (state) {
        case 'READY': {
          downloadFile(downloadUrl, reportFileName || filename, onDownload, onDownloadError);
          break;
        }
        case 'FAILED': {
          onDownloadError?.();
          break;
        }
        case 'EXPIRED': {
          onDownloadError?.();
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [data]);

  if (!data) {
    return null;
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (!child) return null;
    return cloneElement(child as ReactElement, {
      state: data?.reporting?.report?.state,
      error: !!queryReference.networkError,
    });
  });

  return <>{childrenWithProps}</>;
};

export default ReportDownloadButton;

import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import pick from 'lodash/pick';

import { items } from './data';
import Comment from './Comment/Comment';
import PaymentStatus from './PaymentStatus/PaymentStatus';
import InvoiceStatus from './InvoiceStatus/InvoiceStatus';
import TransactionSummary from './TransactionSummary/TransactionSummary';
import VerifiedStatus from './VerifiedStatus/VerifiedStatus';
import TransactionType from './TransactionType/TransactionType';
import SubscriptionStatusType from './SubscriptionStatusType/SubscriptionStatusType';

import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Table from 'Containers/TableContainer/TableContainer';
import { createPercent } from 'Util/numberFormatter';
import { DEPOSIT, REFUNDED } from 'Constants/general';

const COUNT = 15;

const transactionData = ['createdAt', 'currency', 'netCost', 'csmMarkup', 'profit', 'revenue'];

const Transactions = (props) => {
  const createDataList = () => {
    const {
      transactions: {
        accounting: { revenueTransactions },
      },
    } = props;

    return revenueTransactions.edges.map((item) => {
      const {
        id,
        campaign,
        organization,
        paidAt,
        type: transactionType,
        paymentStatus,
        invoiceStatus,
        verifiedByAdmin,
        subscriptionPlanId,
        paymentMethodType,
        department,
        subscriptionStatusType,
        subscriptionAddonItems,
        comment,
      } = item.node;
      const itemData = pick(item.node, transactionData);

      const campaignName = campaign ? campaign.name : '—';
      const campaignLink = campaign ? `/campaigns/${campaign.id}` : null;

      const advertiserName = organization.name;
      const advertiserLink = `/admin/organizations/${organization.id}`;

      const paymentStatusElement = (
        <PaymentStatus transactionId={id} status={paymentStatus} paidAt={paidAt} />
      );
      const invoiceStatusElement = <InvoiceStatus transactionId={id} status={invoiceStatus} />;

      const commentElement = <Comment id={id} comment={comment} />;

      const verifiedStatus = (
        <VerifiedStatus revenueTransactionId={id} verifiedByAdmin={verifiedByAdmin} />
      );

      const subscriptionStatusTypeElement = (
        <SubscriptionStatusType subscriptionStatusType={subscriptionStatusType} />
      );

      const paymentMethodTypeMsgNs = 'search_section.form.payment_method';
      const paymentMethodTypeMsg = `${paymentMethodTypeMsgNs}_${paymentMethodType.toLowerCase()}`;

      const { netCost, profit } = itemData;
      const transactionFee =
        transactionType !== DEPOSIT || profit === 0 || netCost === 0
          ? '—'
          : createPercent(profit / netCost);

      const authorName = department && department.name;

      const transactionTypeElement = (
        <TransactionType
          revenueTransactionId={id}
          transactionType={transactionType}
          paymentMethodType={paymentMethodType}
          subscriptionPlanId={subscriptionPlanId}
          subscriptionAddonItems={subscriptionAddonItems}
          planIds={props.planIds}
        />
      );

      const presetStyle = paymentStatus === REFUNDED ? { background: 'red' } : null;

      return {
        ...itemData,
        campaignName,
        campaignLink,
        advertiserName,
        advertiserLink,
        authorName,
        presetStyle,
        transactionFee,
        verifiedStatus,
        transactionTypeElement,
        comment: commentElement,
        paymentMethodType: paymentMethodTypeMsg,
        paymentStatus: paymentStatusElement,
        invoiceStatus: invoiceStatusElement,
        subscriptionStatusType: subscriptionStatusTypeElement,
        hoverResponsive: true,
      };
    });
  };

  const {
    transactions: {
      accounting: {
        revenueTransactions: { summary },
      },
    },
  } = props;
  const { netCost, profit, revenue } = summary;
  const { relay } = props;
  const list = createDataList();
  const containerStyle = netCost === null ? null : { paddingBottom: '68px' };

  return (
    <div>
      <div style={containerStyle}>
        <LoaderHandler relay={relay} count={COUNT}>
          <Table list={list} data={items} />
        </LoaderHandler>
      </div>
      {!!(netCost || profit || revenue) && <TransactionSummary summary={summary} />}
    </div>
  );
};

export default createPaginationContainer(
  Transactions,
  {
    transactions: graphql`
      fragment Transactions_transactions on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 15 }
        cursor: { type: "String" }
        dateFrom: { type: "DateTime" }
        dateTo: { type: "DateTime" }
        paidDateFrom: { type: "DateTime" }
        paidDateTo: { type: "DateTime" }
        paymentMethodFilters: { type: "[RevenueTransactionPaymentMethodFilter!]" }
        paymentStatus: { type: "Accounting_PaymentStatus" }
        type: { type: "Accounting_RevenueTransactionType" }
        organizationIds: { type: "[ID!]" }
        subscriptionStatusType: { type: "SubscriptionStatusType" }
      ) {
        accounting {
          revenueTransactions(
            first: $count
            after: $cursor
            dateFrom: $dateFrom
            dateTo: $dateTo
            paidDateFrom: $paidDateFrom
            paidDateTo: $paidDateTo
            paymentMethodFilters: $paymentMethodFilters
            paymentStatus: $paymentStatus
            type: $type
            currencies: [USD]
            organizationIds: $organizationIds
            subscriptionStatusType: $subscriptionStatusType
          ) @connection(key: "Admin_revenueTransactions", filters: []) {
            edges {
              node {
                id
                createdAt
                paidAt
                paymentMethodType
                author {
                  advertiserProfile {
                    firstName
                    lastName
                  }
                }
                department {
                  name
                }
                organization {
                  id
                  name
                }
                campaign {
                  id
                  name
                }
                subscriptionStatusType
                subscriptionAddonItems {
                  addon {
                    id
                  }
                  quantity
                }
                subscriptionPlanId
                type
                currency
                netCost
                csmMarkup
                profit
                revenue
                paymentStatus
                invoiceStatus
                verifiedByAdmin
                comment
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            summary {
              netCost
              profit
              revenue
              ...TransactionSummary_summary
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return (
        props.transactions &&
        props.transactions.accounting &&
        props.transactions.accounting.revenueTransactions
      );
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
        paidDateFrom: props.paidDateFrom,
        paidDateTo: props.paidDateTo,
        paymentMethodFilters: props.paymentMethodFilters,
        paymentStatus: props.paymentStatus,
        type: props.type,
        organizationIds: props.organizationIds,
        subscriptionStatusType: props.subscriptionStatusType,
      };
    },
    query: graphql`
      query TransactionsPaginationQuery(
        $count: Int!
        $cursor: String
        $dateFrom: DateTime
        $dateTo: DateTime
        $paidDateFrom: DateTime
        $paidDateTo: DateTime
        $paymentMethodFilters: [RevenueTransactionPaymentMethodFilter!]
        $paymentStatus: Accounting_PaymentStatus
        $type: Accounting_RevenueTransactionType
        $organizationIds: [ID!]
        $subscriptionStatusType: SubscriptionStatusType
      ) {
        ...Transactions_transactions
          @arguments(
            count: $count
            cursor: $cursor
            dateFrom: $dateFrom
            dateTo: $dateTo
            paidDateFrom: $paidDateFrom
            paidDateTo: $paidDateTo
            paymentMethodFilters: $paymentMethodFilters
            paymentStatus: $paymentStatus
            type: $type
            organizationIds: $organizationIds
            subscriptionStatusType: $subscriptionStatusType
          )
      }
    `,
  }
);

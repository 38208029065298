import { commitMutation, graphql } from 'react-relay';
import includes from 'lodash/includes';

import environment from 'Api/Environment';
import {
  UpdateContractorProfileInput,
  UpdateContractorProfileMutation$data,
  UpdateContractorProfileMutation,
} from 'GraphTypes/UpdateContractorProfileMutation.graphql';

const mutation = graphql`
  mutation UpdateContractorProfileMutation($input: UpdateContractorProfileInput!) {
    updateContractorProfile(input: $input) {
      clientMutationId
    }
  }
`;
type fnType = (data: object) => void;

export default (
  data: UpdateContractorProfileInput,
  resolve?: (response: UpdateContractorProfileMutation$data) => void,
  reject?: fnType
) => {
  const { id, phoneNumber, firstName, lastName, cityIds, countryId, creatorAgent, languageIds } =
    data;

  const variables = {
    input: {
      id,
      phoneNumber,
      firstName,
      lastName,
      cityIds,
      countryId,
      creatorAgent,
      languageIds,
    },
  };

  commitMutation<UpdateContractorProfileMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const profile = store.get(data.id);

      if (!profile) return;

      const keys = Object.keys(data);

      if (includes(keys, 'languageIds')) {
        const languages = (data.languageIds || [])
          .map((languageId) => store.get(languageId) || null)
          .filter((item) => Boolean(item));
        profile.setLinkedRecords(languages, 'languages');
      }

      if (data.creatorAgent !== undefined) {
        profile.setValue(data.creatorAgent, 'creatorAgent');
      }
    },
    updater: (store) => {
      const profile = store.get(data.id);

      if (!profile) return;

      const keys = Object.keys(data);

      if (includes(keys, 'languageIds')) {
        const languages = (data.languageIds || [])
          .map((languageId) => store.get(languageId) || null)
          .filter((item) => Boolean(item));
        profile.setLinkedRecords(languages, 'languages');
      }

      if (data.creatorAgent !== undefined) {
        profile.setValue(data.creatorAgent, 'creatorAgent');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React from 'react';

import styles from './ProjectsSidebarPlaceholder.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

const ProjectsSidebarPlaceholder = () => {
  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};

export default ProjectsSidebarPlaceholder;

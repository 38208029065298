import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import IconNew from '../Icon/Icon';
import { Color, IconName } from '../types';

import styles from './Tag.pcss';

import Image from 'Components/ui/Image/Image';

interface Props {
  className?: string;
  text?: string;
  msg?: string;
  size?: 'm';
  color?: Color;
  icon?: IconName;
  image?: string;
  needMargin?: boolean;
  hover?: boolean;
  button?: JSX.Element;
  type?: 'badge';
  dataTest?: string;
  iconPosition?: 'left' | 'right';
  buttonPosition?: 'left' | 'right';
}

const Tag: React.FC<Props> = (props) => {
  const {
    className,
    text,
    color,
    msg,
    icon,
    iconPosition = 'left',
    buttonPosition = 'left',
    image,
    button,
    needMargin = true,
    size = '',
    type,
    dataTest,
    hover = true,
  } = props;

  const intl = useIntl();

  const textContent = useMemo<string>(() => {
    if (msg) {
      return intl.formatMessage({ id: msg });
    } else if (text) {
      return text;
    }

    return '';
  }, [text, msg]);

  const classList = classNames(
    className,
    styles.root,
    needMargin ? styles.rootMargin : '',
    styles[color || 'white'],
    styles[size],
    styles[type || ''],
    { [styles.hover]: hover }
  );

  const iconEl = icon && <IconNew size={16} name={icon} className={styles[color || 'grey']} />;

  return (
    <div className={classList} data-test={dataTest}>
      {iconPosition === 'left' && iconEl}
      {image && <Image size={16} src={image} />}
      {buttonPosition === 'left' && button}
      {textContent}
      {iconPosition === 'right' && iconEl}
      {buttonPosition === 'right' && button}
    </div>
  );
};

export default Tag;

import React, { useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import MsgField from '../MsgField/MsgField';
import { AttachContextProvider } from '../MsgField/Attach.Context';

import styles from './ContractorActions.pcss';

import rejectPriceChangeRequest from 'Mutations/RejectPriceChangeRequest.Mutation';
import acceptPriceChangeRequest from 'Mutations/AcceptPriceChangeRequest.Mutation';
import { createSum } from 'Util/numberFormatter';
import Button from 'Atoms/Button/Button';
import Text from 'Atoms/Text/Text';
import writeImg from 'Images/general/emoji_write.png';
import { ContractorActions_project$key } from 'GraphTypes/ContractorActions_project.graphql';

interface Props {
  project: ContractorActions_project$key;
}

const ContractorActions: React.FC<Props> = (props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment ContractorActions_project on Project {
        id
        archivation {
          id
        }
        priceChangeRequest {
          id
          price
          currency
        }
        campaign {
          publishingRequired
        }
        contractorStage
      }
    `,
    project
  );

  const needCheckPostUrls = useMemo(
    () =>
      data?.contractorStage &&
      ['PUBLISHING_STARTED', 'COMPLETED', 'CONTENT_SUBMITTED'].includes(data?.contractorStage) &&
      data?.campaign?.publishingRequired,
    [data?.contractorStage, data?.campaign?.publishingRequired]
  );

  const [processingPriceChange, setProcessingProcessingPriceChange] = useState(false);

  if (!data) {
    return null;
  }

  const projectId = data.id;
  const { priceChangeRequest } = data;

  if (data.archivation?.id) {
    return null;
  }

  if (priceChangeRequest) {
    const { price, currency, id: priceChangeRequestId } = priceChangeRequest;
    const priceText = createSum(price, currency);

    const handlePriceChangeReaction = () => {
      setProcessingProcessingPriceChange(false);
    };

    const handleRejectPriceChange = () => {
      setProcessingProcessingPriceChange(true);
      rejectPriceChangeRequest({ priceChangeRequestId, projectId }, handlePriceChangeReaction);
    };

    const handleAcceptPriceChange = () => {
      setProcessingProcessingPriceChange(true);
      acceptPriceChangeRequest({ priceChangeRequestId, projectId }, handlePriceChangeReaction);
    };

    return (
      <div className={styles.newPrice}>
        <img src={writeImg} className={styles.icon} />
        <Text
          tag="h3"
          weight="500"
          msg="chat.contractor.price_change_request.title"
          values={{ price: priceText }}
        />
        <Text msg="chat.contractor.price_change_request.descr" />
        <div className={styles.buttons}>
          <Button
            color="normal"
            className={styles.button}
            msg="chat.contractor.price_change_request.reject"
            loading={processingPriceChange}
            onClick={handleRejectPriceChange}
          />
          <Button
            color="secondary"
            className={styles.button}
            msg="chat.contractor.price_change_request.accept"
            loading={processingPriceChange}
            onClick={handleAcceptPriceChange}
          />
        </div>
      </div>
    );
  }

  return (
    <AttachContextProvider>
      <MsgField projectId={projectId} needCheckPostUrls={needCheckPostUrls} />
    </AttachContextProvider>
  );
};

export default ContractorActions;

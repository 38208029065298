import React, { useEffect } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import styles from './Billing.pcss';
import CurrentSubscriptionInfo from './CurrentSubscriptionInfo/CurrentSubscriptionInfo';
import SubscriptionLimits from './SubscriptionLimits/SubscriptionLimits';
import BalanceInfo from './BalanceInfo/BalanceInfo';

import Page from 'Templates/Page/Page';
import TextButton from 'Components/ui/TextButton/TextButton';
import { BillingQuery as QueryType } from 'GraphTypes/BillingQuery.graphql';
import { amplitude } from 'Helpers/amplitude';

const BillingQuery = graphql`
  query BillingQuery {
    currentUser {
      organization {
        id
        ...CurrentSubscriptionInfo_organization
        ...SubscriptionLimits_organization
        ...BalanceInfo_organization
      }
    }
  }
`;

const Billing = () => {
  const navigate = useNavigate();
  const { currentUser } = useLazyLoadQuery<QueryType>(BillingQuery, {});
  if (!currentUser || !currentUser.organization) return null;
  const organization = currentUser.organization;

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    amplitude.sendEvent<369>({
      id: '369',
      category: 'billing',
      name: 'pageview',
    });
  }, []);

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <TextButton icon="Arrow-big-left" msg="route.back" onClick={handleBackClick} />
        <div className={styles.items}>
          <CurrentSubscriptionInfo organization={organization} />
          <SubscriptionLimits organization={organization} />
          <BalanceInfo organization={organization} />
        </div>
      </div>
    </Page>
  );
};

export default Billing;

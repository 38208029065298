import React from 'react';
import classnames from 'classnames';

import styles from './ProductVariantsList.pcss';

import { formatCurrency } from 'Util/numberFormatter';
import type { ProductVariant } from 'Components/ProductList/types';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

type Props = {
  className?: string;
  variants: ProductVariant[];
  currencyCode: string;
  creatorsCount: number;
};

const ProductVariantsList: React.FC<Props> = (props) => {
  const { className, variants, currencyCode } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <ul className={styles.list}>
        {variants.map((variant) => {
          return (
            <li key={variant.id}>
              <div className={styles.productVariantCard}>
                <div className={styles.productVariant}>
                  <div className={styles.productVariantImg}>
                    {variant.thumbnailUrl ? (
                      <img src={variant.thumbnailUrl} alt={variant.title} />
                    ) : null}
                    <Icon name="Photo" size={24} className={styles.noImageIcon} />
                  </div>
                  <Text type="md" text={variant.title} className={styles.productVariantTitle} />
                  <Text type="md" text={variant.subtitle} className={styles.productVariantTitle} />
                  <Text
                    type="md"
                    text={formatCurrency(variant.price, currencyCode)}
                    className={classnames(styles.productVariantPrice)}
                  />
                  <Text
                    type="md"
                    text={variant.selectedQuantity}
                    className={classnames(styles.productVariantQuantity)}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProductVariantsList;
export { ProductVariantsList };

// types

export { Props as ProductVariantsListProps };

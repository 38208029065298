/**
 * @generated SignedSource<<adfe22320ebca9eff2e6676bfa0c1e01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartnershipAdsDetails_brief$data = {
  readonly id?: string;
  readonly paidSocial?: boolean;
  readonly paidSocialActivationDays?: number | null | undefined;
  readonly paidSocialBca?: boolean;
  readonly " $fragmentType": "PartnershipAdsDetails_brief";
};
export type PartnershipAdsDetails_brief$key = {
  readonly " $data"?: PartnershipAdsDetails_brief$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartnershipAdsDetails_brief">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartnershipAdsDetails_brief",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paidSocial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paidSocialActivationDays",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paidSocialBca",
          "storageKey": null
        }
      ],
      "type": "V2Brief",
      "abstractKey": null
    }
  ],
  "type": "Brief",
  "abstractKey": "__isBrief"
};

(node as any).hash = "0c29231957c3e3e3d13d39843c1cf751";

export default node;

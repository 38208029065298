/**
 * @generated SignedSource<<79ce7584d3810e154e4d0eecbde61ff8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCustomListInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  name: string;
  organizationId: string;
};
export type CreateCustomListMutation$variables = {
  input: CreateCustomListInput;
};
export type CreateCustomListMutation$data = {
  readonly createCustomList: {
    readonly __typename: "CreateCustomListPayload";
    readonly customList: {
      readonly id: string | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CreateCustomListMutation = {
  response: CreateCustomListMutation$data;
  variables: CreateCustomListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createCustomList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomList",
            "kind": "LinkedField",
            "name": "customList",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CreateCustomListPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCustomListMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCustomListMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea0bec463d7e333c69f7930cebb536a5",
    "id": null,
    "metadata": {},
    "name": "CreateCustomListMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCustomListMutation(\n  $input: CreateCustomListInput!\n) {\n  createCustomList(input: $input) {\n    __typename\n    ... on CreateCustomListPayload {\n      customList {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "badd2a56834f7e824c8c150824611352";

export default node;

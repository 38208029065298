import { useRef, useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { GraphQLTaggedNode, OperationType, Variables } from 'relay-runtime';

const useLazyLoadQueryWithPolling = <K>(
  query: GraphQLTaggedNode,
  variables: Variables,
  pollingTime = 5000
): [K, () => void] => {
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);
  const [pollingData, setPollingData] = useState<{ fetchKey: number } | null>(null);

  const data = useLazyLoadQuery<OperationType>(query, variables, {
    ...pollingData,
    fetchPolicy: 'network-only',
  }) as K;

  const cancelPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }
  };
  useEffect(() => {
    pollingInterval.current = setInterval(() => {
      setPollingData({ fetchKey: new Date().getTime() });
    }, pollingTime);
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, []);

  return [data, cancelPolling];
};

export default useLazyLoadQueryWithPolling;

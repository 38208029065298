import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  UnsubscribeFromEmailNotificationsInput,
  UnsubscribeFromEmailNotificationsMutation$data,
  UnsubscribeFromEmailNotificationsMutation,
} from 'GraphTypes/UnsubscribeFromEmailNotificationsMutation.graphql';

const mutation = graphql`
  mutation UnsubscribeFromEmailNotificationsMutation(
    $input: UnsubscribeFromEmailNotificationsInput!
  ) {
    unsubscribeFromEmailNotifications(input: $input) {
      clientMutationId
      errors {
        type
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UnsubscribeFromEmailNotificationsInput,
  resolve?: (response: UnsubscribeFromEmailNotificationsMutation$data) => void,
  reject?: fnType
) => {
  const { email } = data;
  const variables = {
    input: {
      email: email.trim(),
    },
  };
  commitMutation<UnsubscribeFromEmailNotificationsMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

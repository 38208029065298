import React, { useState, useEffect } from 'react';
import { parse } from 'papaparse';
import { fetchQuery, graphql } from 'relay-runtime';

import styles from './BulkProjectsArchivation.css';

import environment from 'Api/Environment';
import archiveUnlaunchedCreatorProjects from 'Mutations/ArchiveUnlaunchedCreatorProjects.Mutation';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import { ArchiveUnlaunchedCreatorProjectsMutation$data } from 'GraphTypes/ArchiveUnlaunchedCreatorProjectsMutation.graphql';
import { BulkProjectsArchivationQuery } from 'GraphTypes/BulkProjectsArchivationQuery.graphql';

let interval: NodeJS.Timer;
let timeout: NodeJS.Timeout;

const SUCCESS = 'Success!';
const FAIL = 'Fail!';

const BulkProjectsArchivationQuery = graphql`
  query BulkProjectsArchivationQuery {
    currentUser {
      archiveUnlaunchedCreatorProjectsOperation {
        id
        completed
      }
    }
  }
`;

const BulkProjectsArchivation = () => {
  const [loading, setLoading] = useState(false);
  const [archivationRequestId, setArchivationRequestId] = useState<string | undefined>(undefined);
  const [finalResult, setFinalResult] = useState('');

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (!finalResult) return;
    timeout = setTimeout(() => {
      setFinalResult('');
      setLoading(false);
    }, 3000);
  }, [finalResult]);

  const handleListChange = (files: FileList | null) => {
    setLoading(true);

    if (!files) return;

    parse(files[0], {
      delimiter: 'tab',
      complete: handleParseComplete,
    });
  };

  const handleParseComplete = ({ data, errors }: any) => {
    if (errors.length !== 0 || data.length === 0) {
      return;
    }

    const creatorIds = data.map((item) => item[0]);

    archiveUnlaunchedCreatorProjects({ creatorIds }, handleArchivationStart, handleArchivationFail);
  };

  const handleArchivationStart = (result: ArchiveUnlaunchedCreatorProjectsMutation$data) => {
    const completed = result.archiveUnlaunchedCreatorProjects?.operation.completed;
    const newArchivationRequestId = result.archiveUnlaunchedCreatorProjects?.operation.id;

    if (completed) {
      setLoading(false);
      setFinalResult(SUCCESS);
    } else {
      interval = setInterval(() => checkStatus(newArchivationRequestId), 2000);
    }
  };

  const handleArchivationFail = () => {
    setFinalResult(FAIL);
  };

  const checkStatus = (newArchivationRequestId?: string) => {
    fetchQuery<BulkProjectsArchivationQuery>(
      environment,
      BulkProjectsArchivationQuery,
      {}
    ).subscribe({
      next: (result) => {
        const requestId = result.currentUser?.archiveUnlaunchedCreatorProjectsOperation?.id;
        const isCompleted =
          result.currentUser?.archiveUnlaunchedCreatorProjectsOperation?.completed;

        if (requestId === newArchivationRequestId && isCompleted) {
          setLoading(false);
          setFinalResult(SUCCESS);
          clearInterval(interval);
        }
      },
    });
  };

  return (
    <div>
      <Text tag="h2" weight="500" text="Creators list for unonboarding" />
      <div className={styles.uploadBtn}>
        <Button loading={loading} text="Upload csv" />
        <FileUploader typeList=".csv" className={styles.uploadInput} onChange={handleListChange} />
      </div>
      {finalResult && (
        <Text
          weight="500"
          color={finalResult === SUCCESS ? 'green' : 'red'}
          text={finalResult}
          className={styles.result}
        />
      )}
      <div>
        <Text tag="h3" weight="700" text="Example" className={styles.example} />
        <table className={styles.table}>
          <tr>
            <td>05ea0a8d-77bf-499a-bbd6-18727afef883</td>
          </tr>
          <tr>
            <td>b5c5270a-e08c-47cf-9f4e-c1324575fae7</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default BulkProjectsArchivation;

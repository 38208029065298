import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateManualSalesPeakRewardMutation,
  CreateManualSalesPeakRewardInput,
  CreateManualSalesPeakRewardMutation$data,
} from 'GraphTypes/CreateManualSalesPeakRewardMutation.graphql';

const mutation = graphql`
  mutation CreateManualSalesPeakRewardMutation($input: CreateManualSalesPeakRewardInput!) {
    createManualSalesPeakReward(input: $input) {
      organization {
        paymentAccount {
          balance
          currency
        }
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: CreateManualSalesPeakRewardInput,
  resolve?: (response: CreateManualSalesPeakRewardMutation$data) => void,
  reject?: FnType
) => {
  const id = uuid();

  const { amount, campaignId, comment, currency, departmentId, organizationId } = data;

  const variables = {
    input: {
      id,
      amount,
      campaignId,
      comment,
      currency,
      departmentId,
      organizationId,
    },
  };

  commitMutation<CreateManualSalesPeakRewardMutation>(environment, {
    mutation,
    variables,
    // updater: store => {
    //   const account = store.get(paymentAccountId);
    //   if (!account) return;
    //   const balanceValue = Number(balance) + amount;
    //   account.setValue(balanceValue, 'balance');
    // },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

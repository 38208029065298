import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './Brief.pcss';

import { getProjectBriefLink } from 'Util/links';
import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import Button from 'Atoms/Button/Button';
import Icon from 'Components/ui/Icon/Icon';
import { IconName } from 'Components/ui/types';
import { Brief_project$key, ContentType } from 'GraphTypes/Brief_project.graphql';

interface Props {
  className: string;
  project: Brief_project$key;
}

const Brief = (props: Props) => {
  const { className, project } = props;

  const data = useFragment(
    graphql`
      fragment Brief_project on Project {
        id
        brief {
          ... on V2Brief {
            creatives(first: 3) {
              edges {
                node {
                  id
                  contentType
                  contentAmount
                }
              }
              totalCount
            }
          }
        }
      }
    `,
    project
  );

  const id = data?.id;

  const handleOpenClick = () => {
    if (!id) return;
    const briefLink = getProjectBriefLink({ projectId: id });
    window.open(briefLink);
  };

  const createContentItem = (key: string, textProps: TextProps, iconName?: IconName) => {
    return (
      <div key={key} className={styles.contentData}>
        {iconName && <Icon name={iconName} />}
        <Text type="md" {...textProps} />
      </div>
    );
  };

  const list = data?.brief?.creatives?.edges || [];

  const restCount = Number(data?.brief?.creatives?.totalCount) - 3;

  const getIcon = (contentType?: ContentType | null): IconName => {
    switch (contentType) {
      case 'BOOMERANG':
        return 'Format-9-16';

      case 'HORIZONTAL_VIDEO':
        return 'Format-16-9';

      case 'VERTICAL_VIDEO':
        return 'Format-9-16';

      case 'SQUARE_VIDEO':
        return 'Format-1-1';

      case 'IMAGE':
        return 'Picture-image-stars';

      default:
        return 'Picture-image-stars';
    }
  };

  return (
    <div className={`${className} ${styles.root}`}>
      <Text type="md" msg="chat.brief" className={styles.title} />
      <div className={styles.content}>
        <div className={styles.contentData}>
          {list.map((item) => {
            if (!item?.node?.id) return null;
            const iconName = getIcon(item?.node.contentType);
            return createContentItem(
              item?.node.id,
              { text: item?.node.contentAmount || '' },
              iconName
            );
          })}
          {restCount > 0 && createContentItem('add', { text: restCount }, 'Add-plus')}
        </div>
        <Button color="normal" msg="chat.brief_open" onClick={handleOpenClick} />
      </div>
    </div>
  );
};

export default Brief;

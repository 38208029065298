import React, { useState, useEffect, PropsWithChildren } from 'react';
import { RelayPaginationProp } from 'react-relay';

import styles from './LoaderHandler.css';

import Spinner from 'Atoms/Spinner/Spinner';

const FOOTER_OFFSET = 279;

interface Props {
  loadButton?: JSX.Element;
  startLoadButton?: JSX.Element;
  additionalControls?: JSX.Element;
  reverse?: boolean;
  blocked?: boolean;
  offset?: number;
  className?: string;
  preloader?: JSX.Element;
  hideLoader?: boolean;
  count: number;
  containerId?: string;
  relay: RelayPaginationProp;
}

const LoaderHandler: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    className,
    loadButton,
    startLoadButton,
    offset = FOOTER_OFFSET,
    reverse,
    preloader,
    hideLoader,
    children,
    relay,
    count,
    blocked,
    containerId,
    additionalControls,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showStartLoadButton, setShowStartLoadButton] = useState(!!startLoadButton);

  const container = containerId ? document.getElementById(containerId) : document;
  const scrollContainer = containerId
    ? document.getElementById(containerId)
    : document.documentElement;

  useEffect(() => {
    if (!container || blocked) {
      return;
    }
    if (!startLoadButton && !loadButton && !additionalControls) {
      initSrcollListener();
    }

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [container]);

  const initSrcollListener = () => {
    container?.addEventListener('scroll', handleScroll);
  };

  const handleScroll = () => {
    const scrollTop = Number(scrollContainer?.scrollTop);
    const scrollHeight = Number(scrollContainer?.scrollHeight);
    const clientHeight = Number(scrollContainer?.clientHeight);

    if (reverse) {
      if (scrollTop < offset) {
        handleLoadMore();
      }

      return;
    }
    if (scrollTop > scrollHeight - clientHeight - offset) {
      handleLoadMore();
    }
  };

  const handleLoadMore = () => {
    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }
    setLoading(true);
    relay.loadMore(count, () => {
      setLoading(false);
    });
  };

  const handleStartLoadClick = () => {
    handleLoadMore();
    initSrcollListener();
    setShowStartLoadButton(false);
  };

  const handleLoadClick = () => {
    handleLoadMore();
  };

  const preloaderEl = preloader ? preloader : <Spinner className={styles.preloader} />;
  return (
    <div className={className}>
      {reverse && loading && !hideLoader && preloaderEl}
      {children}
      {!reverse && loading && !hideLoader && preloaderEl}
      {showStartLoadButton && relay.hasMore() && (
        <div onClick={handleStartLoadClick} className={styles.startLoadButton}>
          {startLoadButton}
        </div>
      )}
      {loadButton && !loading && relay.hasMore() && (
        <div onClick={handleLoadClick}>{loadButton}</div>
      )}
      {additionalControls &&
        !loading &&
        React.cloneElement(additionalControls, {
          hasMore: relay.hasMore(),
          onClickNext: handleLoadClick,
        })}
    </div>
  );
};

export default LoaderHandler;

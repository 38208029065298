import React, { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';

import PostsList from '../PostsList/PostsList';

import { YOUTUBE, TIKTOK } from 'Constants/general';
import { PopularPosts_creator$key } from 'GraphTypes/PopularPosts_creator.graphql';

interface Props {
  creator?: PopularPosts_creator$key;
  onHideTab: (tab: string) => void;
}

const PopularPosts: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment PopularPosts_creator on Creator {
        type
        profile {
          topPosts {
            link
            thumbnailUrl
            viewCount
            mediaType
            likeCount
            dislikeCount
            commentCount
            description
          }
        }
      }
    `,
    creator
  );
  const topPosts = data?.profile?.topPosts;
  const onHideTab = props.onHideTab;

  useEffect(() => {
    if (!topPosts || topPosts.length === 0) {
      onHideTab('top_posts');
    }
  }, []);

  if (!topPosts || topPosts.length === 0) return null;

  const isYoutube = data?.type === YOUTUBE;
  const isTiktok = data?.type === TIKTOK;

  return (
    <div>
      <PostsList list={topPosts} isYoutube={isYoutube} isTiktok={isTiktok} />
    </div>
  );
};

export default PopularPosts;

import React, { useCallback, useEffect, useState } from 'react';
import { getDate } from 'date-fns';
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker';
import classnames from 'classnames';

import styles from './DatePicker.pcss';
import CustomInput, { CustomInputProps } from './CustomInput/CustomInput';
import CustomHeader from './CustomHeader/CustomHeader';
import CustomContainer from './CustomContainer/CustomContainer';
import CustomDayContent from './CustomDayContent/CustomDayContent';

type Props = {
  className?: string;
  reactDatePickerProps?: Omit<
    Partial<ReactDatePickerProps<string, true>>,
    'renderCustomHeader' | 'customInput'
  >;
  customInputProps?: Partial<CustomInputProps>;
};

const DatePickerRange: React.FC<Props> = (props) => {
  const { className, reactDatePickerProps, customInputProps } = props;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (reactDatePickerProps?.startDate) {
      setStartDate(reactDatePickerProps?.startDate);
    }
    if (reactDatePickerProps?.endDate) {
      setEndDate(reactDatePickerProps?.endDate);
    }
  }, [reactDatePickerProps?.startDate, reactDatePickerProps?.endDate]);

  const handleChange = useCallback(
    (dates: [Date, Date], e: React.SyntheticEvent) => {
      const isRange = Array.isArray(dates);
      if (isRange) {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
      } else {
        setStartDate(dates);
      }
      reactDatePickerProps?.onChange?.(dates, e);
    },
    [reactDatePickerProps?.onChange]
  );

  return (
    <div className={classnames(styles.root, className)}>
      <ReactDatePicker
        dateFormat="MM/dd/yyyy"
        showMonthDropdown
        showYearDropdown
        calendarStartDay={1}
        selectsRange
        {...reactDatePickerProps}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        customInput={<CustomInput {...customInputProps} />}
        renderCustomHeader={(p: any) => <CustomHeader {...p} />}
        calendarContainer={(p: any) => <CustomContainer {...p} />}
        useWeekdaysShort={true}
        weekDayClassName={(date: any) => {
          return styles.weekDayClassName;
        }}
        renderDayContents={(dayOfMonth: any, date: any) => (
          <CustomDayContent dayOfMonth={dayOfMonth} date={date} />
        )}
        dayClassName={(date: any) => (getDate(date) ? styles.customDay : null)}
      />
    </div>
  );
};

export default DatePickerRange;
export type { Props as DatePickerProps };

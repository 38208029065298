import {
  TOGGLE_CREATOR_SHORTLISTED_STATUS,
  SIGNUP_AND_APPLY_PROJECT,
  ADD_OFFER_REACTION_DATA,
  ACCEPT_OFFER,
} from 'Constants';

export const toggleCreatorShortlistedStatus = (campaignId, creatorId, shortlisted, projectId) => ({
  type: TOGGLE_CREATOR_SHORTLISTED_STATUS,
  payload: { campaignId, creatorId, shortlisted, projectId },
});

export const signupAndApplyOffer = (data) => ({
  type: SIGNUP_AND_APPLY_PROJECT,
  payload: data,
});

export const addOfferReactionData = (data) => ({
  type: ADD_OFFER_REACTION_DATA,
  payload: data,
});

export const acceptOffer = (data) => ({
  type: ACCEPT_OFFER,
  payload: data,
});

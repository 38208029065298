import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  MarkInsenseContentReviewAsFailedInput,
  MarkInsenseContentReviewAsFailedMutation,
  MarkInsenseContentReviewAsFailedMutation$data,
} from 'GraphTypes/MarkInsenseContentReviewAsFailedMutation.graphql';

const mutation = graphql`
  mutation MarkInsenseContentReviewAsFailedMutation(
    $input: MarkInsenseContentReviewAsFailedInput!
  ) {
    markInsenseContentReviewAsFailed(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: MarkInsenseContentReviewAsFailedInput,
  resolve?: (response: MarkInsenseContentReviewAsFailedMutation$data) => void,
  reject?: fnType
) => {
  const { projectId, text } = data;
  const variables = {
    input: {
      projectId,
      text,
    },
  };
  commitMutation<MarkInsenseContentReviewAsFailedMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const project = store.get(projectId);
      if (project) {
        project?.setValue(null, 'insenseContentReview');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response.markInsenseContentReviewAsFailed?.clientMutationId && resolve) {
        resolve(response);
      } else if (reject) {
        reject({
          error: 'Unknown error',
        });
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { loadStripe } from '@stripe/stripe-js';

import { SubscriptionParams } from 'AdvertiserPage/BillingPlans/BillingPlans';
import buySubscription from 'Mutations/stripe/BuySubscription.Mutation';
import { BuySubscriptionViaStripeMutation$data } from 'GraphTypes/BuySubscriptionViaStripeMutation.graphql';
import ErrorHandler from 'Util/errorHandler';

const STRIPE_KEY = process.env.STRIPE_KEY;

type ByuPlanParams = {
  params: SubscriptionParams;
  onFailed?: () => void;
};

interface StripeResult {
  buyPlan: (data: ByuPlanParams) => void;
}

type UseStripe = () => StripeResult;
const useStripe: UseStripe = () => {
  const buyPlan = async (data: ByuPlanParams) => {
    const { params, onFailed } = data;
    try {
      const url = new URL(window.location.pathname, window.location.href).href;
      const cancelUrl = url;
      const successUrl = `${url}?planId=${params.planId}&interval=${params.interval}`;
      const stripeData = await new Promise<BuySubscriptionViaStripeMutation$data>(
        (resolve, reject) => {
          buySubscription({ ...params, cancelUrl, successUrl }, resolve, reject);
        }
      );

      const { addonItems } = params;
      if (stripeData && STRIPE_KEY) {
        const sessionId = stripeData.stripe?.buySubscription?.checkoutSession?.stripeId;
        const stripePromise = await loadStripe(STRIPE_KEY);
        sessionStorage.setItem('sub_type', 'new');
        sessionStorage.setItem(
          'sub_prod',
          Number(addonItems?.length) > 0 ? 'plan_with_addons' : 'plan'
        );
        if (stripePromise && sessionId) {
          await stripePromise.redirectToCheckout({ sessionId });
        }
      }
    } catch (error) {
      onFailed?.();
      ErrorHandler.error('Stripe return error after purchase', {
        error,
        params,
      });
    }
  };

  return { buyPlan };
};

export { useStripe };

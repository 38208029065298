import React, { useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { TooltipType } from 'Types/common';
import {
  OrderFilterQuery as QueryType,
  ProjectOrder,
  CampaignPlatform,
} from 'GraphTypes/OrderFilterQuery.graphql';
import { amplitude } from 'Helpers/amplitude';

const OrderFilterQuery = graphql`
  query OrderFilterQuery($id: ID!, $platform: CampaignPlatform) {
    currentUser {
      admin
      organization {
        subscription {
          availableProjectOrders(campaignPlatform: $platform)
          planId
        }
        id
        test
      }
    }
    campaign(id: $id) {
      organization {
        subscription {
          availableProjectOrders(campaignPlatform: $platform)
        }
      }
    }
  }
`;

interface Props {
  campaignId: string;
  isAdminView: boolean;
  currentOrder?: ProjectOrder;
  defaultOrder?: ProjectOrder;
  platform?: CampaignPlatform;
  onOrderChange: (order?: ProjectOrder) => void;
}

const OrderFilter: React.FC<Props> = (props) => {
  const { campaignId, platform, currentOrder, isAdminView, defaultOrder, onOrderChange } = props;

  if (!platform) return null;

  const ordersData = useLazyLoadQuery<QueryType>(OrderFilterQuery, { id: campaignId, platform });

  const creator = ordersData.campaign;
  const user = ordersData.currentUser;
  const currentUser = isAdminView ? user : creator;

  const orderList = currentUser?.organization?.subscription?.availableProjectOrders;

  const orderListValue = useMemo(() => {
    if (!defaultOrder) return orderList;
    const index = orderList?.indexOf(defaultOrder);
    if (!index || index === -1) return orderList;

    const newList = [...orderList];
    newList.splice(index, 1);
    newList.splice(0, 0, defaultOrder);
    return newList;
  }, [orderList, defaultOrder]);

  const list = orderListValue?.map((item) => {
    const onClick = () => {
      onOrderChange(item);
      amplitude.sendEvent({
        id: 226,
        category: 'campaign',
        name: 'use_sorting',
        param: {
          admin: currentUser?.admin,
          isTestOrganization: currentUser?.organization?.test,
          planId: currentUser?.organization?.subscription?.planId,
          applied_filters: item,
        },
      });
    };

    return {
      id: item,
      onClick,
      msg: `campaign.project_order.${item.toLowerCase()}`,
    };
  });

  const tooltipData: TooltipType = {
    id: 'orderBy',
    tooltipMsg: 'creators.filter.orderBy',
    place: 'top',
  };

  const labelMsg = currentOrder === defaultOrder ? 'default' : currentOrder?.toLowerCase();

  return (
    <Dropdown
      icon="Arrows-up-down"
      value={<Text type="md" msg={`campaign.project_order.${labelMsg}`} />}
      tooltipData={tooltipData}
    >
      <DropdownGroup labelMsg="campaign.project_order.title">
        <div>
          {list?.map((item) => {
            if (!item) return null;

            const isActive = item.id === currentOrder ? 'Check' : null;
            const msg = item.id === defaultOrder ? 'campaign.project_order.default' : item.msg;

            return (
              <DropdownGroupItem key={item.id} handleClick={item?.onClick}>
                <AlterButton
                  msg={msg}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );
};

export default OrderFilter;

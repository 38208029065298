import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { graphql, fetchQuery } from 'react-relay';
import xor from 'lodash/xor';

import styles from './CitiesFilter.pcss';

import environment from 'Api/Environment';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import {
  CitiesFilterQuery as QueryType,
  CitiesFilterQuery$data,
} from 'GraphTypes/CitiesFilterQuery.graphql';
import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';

const CitiesFilterQuery = graphql`
  query CitiesFilterQuery($query: String!) {
    cities(query: $query, first: 10) {
      edges {
        node {
          id
          name
          fullName
        }
      }
    }
  }
`;

interface Props {
  selectedCities: string[];
  onCitiesChange: (cities: string[]) => void;
}

const CitiesFilter: React.FC<Props> = (props) => {
  const { selectedCities = [], onCitiesChange } = props;

  const [cities, setCities] = useState<CitiesFilterQuery$data['cities'] | undefined>(undefined);
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    fetchQuery<QueryType>(environment, CitiesFilterQuery, {
      query,
    }).subscribe({
      next: (response) => {
        const newList = response?.cities;
        setCities(newList);
      },
    });
  }, [query]);

  const citiesList = useMemo(() => {
    return cities?.edges?.map((item) => {
      const cityId = item?.node?.id;
      return {
        id: cityId,
        label: item?.node?.name,
        isChecked: cityId ? selectedCities.includes(cityId) : undefined,
      };
    });
  }, [cities, selectedCities]);

  const handleCitiesSelect = useCallback(
    (value: string) => {
      const newCitiesValue = xor(selectedCities, [value]);
      onCitiesChange(newCitiesValue);
    },
    [selectedCities]
  );

  const handleCitiesClear = useCallback(() => {
    onCitiesChange([]);
  }, []);

  const hasSelctedCities = selectedCities.length > 0;

  return (
    <div className={styles.root}>
      <ProgressiveInput
        type="checkboxSelect"
        className={styles.selectCheckbox}
        titleMsg="search_section.form.cities"
        inputProps={{
          items: citiesList ? (citiesList as CheckboxItemType[]) : [],
          bordered: true,
          placeholderMsg: 'search_section.form.cities.placeholder',
          handleSearch: (value) => setQuery(value),
          withSearch: true,
          handleClick: handleCitiesSelect,
          hideByAlphabet: true,
          handleClear: handleCitiesClear,
        }}
        isDirty={hasSelctedCities}
      />
    </div>
  );
};

export default CitiesFilter;

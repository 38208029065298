/**
 * @generated SignedSource<<dab1c1fbd761c13446903bb83e3ec7c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteReferenceInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
};
export type DeleteReferenceMutation$variables = {
  input: DeleteReferenceInput;
};
export type DeleteReferenceMutation$data = {
  readonly deleteReference: {
    readonly briefCreative: {
      readonly id: string;
    };
  } | null | undefined;
};
export type DeleteReferenceMutation = {
  response: DeleteReferenceMutation$data;
  variables: DeleteReferenceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteReferencePayload",
    "kind": "LinkedField",
    "name": "deleteReference",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BriefCreative",
        "kind": "LinkedField",
        "name": "briefCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteReferenceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteReferenceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "867f872e834f4c0061cf27ffe2d42790",
    "id": null,
    "metadata": {},
    "name": "DeleteReferenceMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteReferenceMutation(\n  $input: DeleteReferenceInput!\n) {\n  deleteReference(input: $input) {\n    briefCreative {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c784a2b71f1b3c799162da2b85307025";

export default node;

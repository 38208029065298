import React, { useCallback } from 'react';
import xor from 'lodash/xor';
import debounce from 'lodash/debounce';

import styles from './AdminCreativesFilters.pcss';
import { getFiltersData } from './utils';

import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import TextButton from 'Components/ui/TextButton/TextButton';

export type Filters = {
  adminFavoriteCreatives: boolean | null;
  adminFavoriteCampaigns: boolean | null;
  paidSocial: boolean | null;
  planIds: string[];
  currencies: string[];
  textQuery: string;
};

interface Props {
  filters: Filters;
  onFiltersChange: (filters: Filters) => void;
  onFiltersClear: () => void;
}

const AdminCreativesFilters: React.FC<Props> = (props) => {
  const { filters, onFiltersChange, onFiltersClear } = props;

  const filtersData = getFiltersData(filters);

  const handleSearchInputChange = useCallback(
    debounce((value) => {
      onFiltersChange({ ...filters, textQuery: value });
    }, 500),
    [filters]
  );

  const handleCurrencyChange = useCallback(
    (id: string) => {
      const newCurrenciesValue = xor(filters.currencies || [], [id]);
      onFiltersChange({ ...filters, currencies: newCurrenciesValue });
    },
    [filters]
  );

  const handleParamChange = useCallback(
    (id: string) => {
      const name = id as keyof Filters;
      const value = filters[name] ? null : true;
      onFiltersChange({ ...filters, [name]: value });
    },
    [filters]
  );

  const handleCurrenciesClear = useCallback(() => {
    onFiltersChange({ ...filters, currencies: [] });
  }, [filters]);

  const handleAdditionalFiltersClear = useCallback(() => {
    onFiltersChange({
      ...filters,
      adminFavoriteCampaigns: null,
      adminFavoriteCreatives: null,
      paidSocial: null,
    });
  }, [filters]);

  const hasCurrencies = filters.currencies.length > 0;
  const hasAdditionalFilter = !!(
    filters.adminFavoriteCampaigns ||
    filters.adminFavoriteCreatives ||
    filters.paidSocial
  );

  return (
    <div className={styles.root}>
      <TextButton
        color="grey"
        msg="general.clear_all"
        onClick={onFiltersClear}
        className={styles.clearParams}
      />
      <ProgressiveInput
        type="input"
        inputProps={{
          value: filters.textQuery,
          placeholderMsg: 'advertisers.search_placeholder',
          handleChange: handleSearchInputChange,
          rightIcon: 'Search-loop',
        }}
        className={styles.input}
        isDirty={!!filters.textQuery}
      />
      <ProgressiveInput
        type="checkboxSelect"
        inputProps={{
          items: filtersData.currencies,
          bordered: true,
          placeholderMsg: 'search_section.form.currency',
          handleClick: handleCurrencyChange,
          hideByAlphabet: true,
          handleClear: handleCurrenciesClear,
        }}
        isDirty={hasCurrencies}
      />
      <ProgressiveInput
        type="checkboxSelect"
        inputProps={{
          items: filtersData.additional,
          bordered: true,
          placeholderMsg: 'search_section.form.additional',
          handleClick: handleParamChange,
          hideByAlphabet: true,
          handleClear: handleAdditionalFiltersClear,
        }}
        isDirty={hasAdditionalFilter}
      />
    </div>
  );
};

export default AdminCreativesFilters;

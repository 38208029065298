import React, { PropsWithChildren } from 'react';
import { PrimitiveType } from 'react-intl';

import styles from './Warning.css';

import modsClasses from 'Util/modsClasses.js';
import icons from 'Icons';
import Text from 'Atoms/Text/Text';
import { textSize } from 'Types/common';

const MODS = ['color'];

interface Props {
  className?: string;
  text?: string;
  textSize?: textSize;
  values?: Record<string, PrimitiveType>;
  msg?: string;
  color?: 'red';
  hasIcon?: boolean;
}

const Warning: React.FC<PropsWithChildren<Props>> = (props) => {
  const { text, msg, values, children, textSize = 'sm', hasIcon = true } = props;

  const el =
    text || msg ? (
      <Text align="left" size={textSize} text={text} msg={msg} values={values} />
    ) : (
      children
    );

  const classes = modsClasses(MODS, props, styles);

  const icon = icons.attention;

  return (
    <div className={`${classes} ${styles.root}`}>
      {hasIcon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.content}>{el}</div>
    </div>
  );
};

export default Warning;

import React from 'react';
import classnames from 'classnames';
import { CalendarContainer, CalendarContainerProps } from 'react-datepicker';

import styles from './CustomContainer.pcss';

type Props = CalendarContainerProps;

const CustomContainer: React.FC<Props> = (props) => {
  const { children, className } = props;
  return (
    <CalendarContainer className={classnames(styles.root, className)}>{children}</CalendarContainer>
  );
};

export default CustomContainer;

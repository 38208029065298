import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  BlockCreatorInput,
  BlockCreatorMutation,
  BlockCreatorMutation$data,
} from 'GraphTypes/BlockCreatorMutation.graphql';

const mutation = graphql`
  mutation BlockCreatorMutation($input: BlockCreatorInput!) {
    blockCreator(input: $input) {
      ... on BlockCreatorPayload {
        organization {
          amount: blockedCreators {
            totalCount
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface Data {
  projectId?: string;
}

export default (
  data: BlockCreatorInput & Data,
  resolve?: (response: BlockCreatorMutation$data) => void,
  reject?: fnType
) => {
  const { creatorId, organizationId, projectId } = data;

  const variables = {
    input: {
      creatorId,
      organizationId,
    },
  };
  commitMutation<BlockCreatorMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (projectId) {
        const project = store.get(projectId);
        if (project) {
          project.setValue(true, 'blockedCreator');
        }
      }
    },
    updater: (store) => {
      if (projectId) {
        const project = store.get(projectId);
        if (project) {
          project.setValue(true, 'blockedCreator');
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

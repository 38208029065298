import React from 'react';

import NotIssuedStatus from './NotIssuedStatus/NotIssuedStatus';

import Text from 'Atoms/Text/Text';
import { NOT_REQUIRED, NOT_ISSUED, ISSUED } from 'Constants/general';

interface Props {
  transactionId: string;
  status: string;
}

const InvoiceStatus: React.FC<Props> = (props) => {
  const { status, transactionId } = props;

  const createContent = () => {
    switch (status) {
      case NOT_REQUIRED:
        return <Text color="grayDog" msg="general.n_r" />;
      case ISSUED:
        return <Text msg="general.yes" />;
      case NOT_ISSUED:
        return <NotIssuedStatus transactionId={transactionId} />;
      default:
        return null;
    }
  };

  return <div>{createContent()}</div>;
};

export default InvoiceStatus;

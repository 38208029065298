import fakeStorage from 'Util/fakeStorage';

const isTest = process.env.NODE_ENV === 'test';

const prefix = '__insense__';

function LocalStorage() {
  const ls = isTest ? fakeStorage : localStorage;
  const formatNs = (ns) => (ns ? `.${ns}.` : '');
  const makeLsKey = ({ prefix, namespace, name }) => `${prefix}${formatNs(namespace)}${name}`;

  return {
    set(name, data, namespace = '') {
      if (name && data !== undefined) {
        const lsKey = makeLsKey({ prefix, namespace, name });
        ls.setItem(lsKey, JSON.stringify(data));
      }
    },
    get(name, namespace = '') {
      const lsKey = makeLsKey({ prefix, namespace, name });
      const data = ls.getItem(lsKey);
      try {
        return JSON.parse(data);
      } catch (err) {
        return data;
      }
    },
    clear(name, namespace = '') {
      if (!name) {
        ls.clear();
      } else {
        const lsKey = makeLsKey({ prefix, namespace, name });
        ls.setItem(lsKey, null);
      }
    },
    remove(name, namespace) {
      const lsKey = makeLsKey({ prefix, namespace, name });
      ls.removeItem(lsKey);
    },
  };
}

const lsInstance = LocalStorage();
window.ls = lsInstance;

export default lsInstance;
export { prefix };

import React, { useEffect } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';

import styles from './Results.pcss';
import CampaignCreatives from './CampaignCreatives/CampaignCreatives';

import Spinner from 'Atoms/Spinner/Spinner';
import environment from 'Api/Environment';
import { ResultsQuery as QueryType } from 'GraphTypes/ResultsQuery.graphql';
import { amplitude } from 'Helpers/amplitude';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';

const ResultsQuery = graphql`
  query ResultsQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...CampaignCreatives_campaign
    }
  }
`;

interface Props {
  campaignId: string;
  currentUser: CampaignQuery$data['currentUser'];
  isAdminView: boolean;
  campaignPublishing: NonNullable<CampaignQuery$data['campaign']>['publishingRequired'];
}

const Results: React.FC<Props> = (props) => {
  const { campaignId, currentUser, isAdminView, campaignPublishing } = props;

  const canCreateFacebookAds = Boolean(
    currentUser?.organization?.subscription?.canCreateFacebookAds
  );
  const canChatWithCreators = Boolean(currentUser?.organization?.subscription?.canChatWithCreators);

  useEffect(() => {
    amplitude.sendEvent({
      id: 146,
      name: 'results',
      category: 'pageview',
      param: {
        campaign_id: campaignId,
        organicPosting: campaignPublishing,
      },
    });
  }, []);

  return (
    <div className={styles.root}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={ResultsQuery}
        variables={{ campaignId }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner style={{ margin: '40px auto' }} />;

          const { campaign } = queryProps;

          return (
            <div className={styles.root}>
              <div className={styles.container}>
                <CampaignCreatives
                  campaignId={campaignId}
                  campaign={campaign}
                  admin={isAdminView}
                  canCreateFacebookAds={canCreateFacebookAds}
                  canChatWithCreators={canChatWithCreators}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default track({ page: 'results' }, { dispatchOnMount: true })(Results);

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';

import styles from './CreativeItem.css';
import { formatTypesList, publishingTypeList, musicTypeList, preparednessTypeList } from './util';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { IconName } from 'Components/ui/types';
import FilePreview from 'Atoms/FilePreview/FilePreview';
import { CreativeItem_briefCreative$key } from 'GraphTypes/CreativeItem_briefCreative.graphql';
import { TextType } from 'Types/common';
import { createTextWithLinks, escapeText } from 'Util/textFormatter';

interface Props {
  mobileView?: boolean;
  briefCreative: CreativeItem_briefCreative$key;
  campaignPlatform?: string | null;
}

const CreativeItem: React.FC<Props> = (props) => {
  const { briefCreative, mobileView, campaignPlatform } = props;

  const data = useFragment(
    graphql`
      fragment CreativeItem_briefCreative on BriefCreative {
        mediaType
        contentType
        v2ContentType
        videoDurationMin
        videoDurationMax
        contentFormat
        dos
        donts
        contentMusic
        contentMusicDescription
        technicalRequirements
        postCaption
        contentAmount
        v2ContentPlacement
        publishingRequired
        contentPreparedness
        references(first: 2147483647) @connection(key: "Creative_references") {
          edges {
            node {
              file {
                id
                secureUrl
                ... on Video {
                  transformations {
                    autoUrl
                    collageThumbnailUrl
                  }
                }
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
                type
                originalFilename
                filename
              }
            }
          }
        }
      }
    `,
    briefCreative
  );

  const {
    mediaType,
    contentAmount: count,
    v2ContentType,
    v2ContentPlacement: contentPlacement,
    dos,
    donts,
    contentFormat,
    postCaption,
    publishingRequired,
    videoDurationMin,
    videoDurationMax,
    contentMusic,
    contentMusicDescription,
    contentPreparedness,
  } = data;

  const referencesList = data.references.edges;

  const intl = useIntl();

  const contentFormatData = find(formatTypesList, (item) => item.id === contentFormat);
  const publishingData = find(publishingTypeList, (item) => item.id === publishingRequired);
  const musicData = find(musicTypeList, (item) => item.id === contentMusic);
  const preparednessData = find(preparednessTypeList, (item) => item.id === contentPreparedness);

  const createSection = (title: string, text: string) => {
    return (
      <div className={styles.section}>
        <Text weight="700" msg={`brief_modal.${title}`} className={styles.sectionTitle} />
        <Text>
          <pre
            wrap="white-space"
            dangerouslySetInnerHTML={{ __html: createTextWithLinks(escapeText(text)) }}
          />
        </Text>
      </div>
    );
  };

  const handleReferenceOpen = () => {
    amplitude.sendEvent({ id: '92', category: 'campaign', name: 'creator_references' });
  };

  const createReferences = () => {
    if (!referencesList) return null;

    return referencesList?.map((item) => {
      if (!item || !item.node || !item.node.file) return null;
      const { file } = item.node;
      const { id } = file;

      return (
        <FilePreview
          key={id}
          canOpenPreview={!mobileView}
          file={file}
          canDelete={false}
          className={styles.preview}
          contentClassName={styles.previewContent}
          onPreviewOpen={handleReferenceOpen}
        />
      );
    });
  };

  const createTechnicalItem = (iconName: IconName, titleData: TextType, descrData: TextType) => {
    return (
      <div className={styles.technicalItem}>
        <Icon name={iconName} className={styles.technicalIcon} />
        <div>
          <Text weight="700" {...titleData} />
          <Text {...descrData} />
        </div>
      </div>
    );
  };

  const rootStyles = mobileView ? styles.mobileView : '';

  const mediaTypeText = mediaType
    ? intl.formatMessage({ id: `brief.creative.${mediaType.toLowerCase()}` }, { count })
    : '';
  const contentTypeText = v2ContentType
    ? intl.formatMessage({
        id: `brief_template.creative_asset.content_type.${v2ContentType.toLowerCase()}`,
      })
    : '';
  const videoDurationText =
    videoDurationMin && videoDurationMax
      ? intl.formatMessage(
          { id: 'brief.creative.video_duration' },
          { min: videoDurationMin, max: videoDurationMax }
        )
      : '';

  const titleText =
    mediaType === 'VIDEO'
      ? `${mediaTypeText} ${contentTypeText} ${videoDurationText}`
      : mediaTypeText;

  const contentPlacementValue = contentPlacement || 'FEED';
  const placemenetText = intl.formatMessage({
    id: `brief.creative.placement.${contentPlacementValue.toLowerCase()}`,
  });

  const platformText = campaignPlatform ? capitalize(campaignPlatform) : '';

  const postingText = publishingRequired
    ? intl.formatMessage({ id: 'brief.creative.posting' }, { placement: placemenetText })
    : intl.formatMessage({ id: 'brief.creative.no_posting' });
  const postingContentText = publishingRequired
    ? intl.formatMessage(
        { id: 'brief.creative.posting.descr' },
        { placement: placemenetText, platform: platformText }
      )
    : intl.formatMessage({ id: 'brief.creative.no_posting.descr' });
  const labelStyles = publishingRequired ? styles.posting : styles.notPosting;

  const musicPostfix = contentMusic ? '.on' : '.off';

  const createTechnicalData = () => {
    return (
      <div className={styles.section}>
        <Text weight="700" msg="brief_modal.requirements" className={styles.sectionTitle} />
        <div className={styles.technicalItems}>
          {contentFormatData &&
            createTechnicalItem(
              contentFormatData.iconName,
              { msg: `brief.creative.format.${contentFormat?.toLowerCase()}` },
              { msg: 'brief.creative.format.descr' }
            )}
          {publishingData &&
            createTechnicalItem(
              publishingData.iconName,
              { text: postingText },
              { text: postingContentText }
            )}
          {mediaType === 'VIDEO' &&
            musicData &&
            createTechnicalItem(
              musicData.iconName,
              { msg: `brief.creative.music${musicPostfix}` },
              { msg: `brief.creative.music${musicPostfix}.descr` }
            )}
          {preparednessData &&
            createTechnicalItem(
              preparednessData.iconName,
              { msg: preparednessData.titleMsg },
              { msg: preparednessData.descrMsg }
            )}
        </div>
      </div>
    );
  };
  return (
    <div className={rootStyles}>
      <div className={styles.content}>
        <div className={styles.titleWrap}>
          <Text tag="h2" weight="700" text={titleText} className={styles.title} />
          <Text
            color={publishingRequired ? 'base' : 'grayDog'}
            text={postingText}
            className={classnames(styles.label, labelStyles)}
            display="inline-block"
          />
        </div>
        <Text className={styles.section}>
          <pre
            wrap="white-space"
            dangerouslySetInnerHTML={{ __html: createTextWithLinks(escapeText(dos)) }}
          />
        </Text>
        {contentMusic && contentMusicDescription && createSection('music', contentMusicDescription)}
        {donts && createSection('what_not_do', donts)}
        {postCaption && createSection('caption', postCaption)}
        {createTechnicalData()}
        {referencesList && referencesList.length > 0 && (
          <div>
            <Text weight="700" className={styles.sectionTitle} msg="brief_modal.references" />
            <div>{createReferences()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreativeItem;

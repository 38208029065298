import React from 'react';
import classNames from 'classnames';

import styles from './Controls.css';
import DeleteButton from './DeleteButton/DeleteButton';

interface Props {
  campaignId: string,
  className: string,
  hover: boolean,
}


const Controls: React.FC<Props> = props => {
  const { campaignId, className, hover, ...rest } = props;
  const classes = classNames(className, styles.root, {
    [styles.hover]: hover,
  });

  return (
    <span {...rest} className={classes}>
      <DeleteButton campaignId={campaignId} />
    </span>
  );
};

export default Controls;
/**
 * @generated SignedSource<<795feb0a91f148aeeab3e73ffc6f5afb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ShopifyShopsListQuery$variables = {
  id: string;
};
export type ShopifyShopsListQuery$data = {
  readonly campaign: {
    readonly canUpdateShopifyAuthorization: boolean;
    readonly organization: {
      readonly id: string;
      readonly shopifyAuthorizations: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly reauthenticationRequired: boolean;
            readonly shop: {
              readonly name: string;
            };
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly shopifyAuthorization: {
      readonly id: string;
      readonly reauthenticationRequired: boolean;
      readonly shop: {
        readonly name: string;
        readonly url: string;
      };
    } | null | undefined;
  } | null | undefined;
};
export type ShopifyShopsListQuery = {
  response: ShopifyShopsListQuery$data;
  variables: ShopifyShopsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdateShopifyAuthorization",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reauthenticationRequired",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShopifyShopsListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopifyAuthorization",
            "kind": "LinkedField",
            "name": "shopifyAuthorization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Shopify_Shop",
                "kind": "LinkedField",
                "name": "shop",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopifyAuthorizationConnection",
                "kind": "LinkedField",
                "name": "shopifyAuthorizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShopifyAuthorizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopifyAuthorization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Shopify_Shop",
                            "kind": "LinkedField",
                            "name": "shop",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShopifyShopsListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopifyAuthorization",
            "kind": "LinkedField",
            "name": "shopifyAuthorization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Shopify_Shop",
                "kind": "LinkedField",
                "name": "shop",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopifyAuthorizationConnection",
                "kind": "LinkedField",
                "name": "shopifyAuthorizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShopifyAuthorizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopifyAuthorization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Shopify_Shop",
                            "kind": "LinkedField",
                            "name": "shop",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f3945c30933244d7c31338c32d8eae8",
    "id": null,
    "metadata": {},
    "name": "ShopifyShopsListQuery",
    "operationKind": "query",
    "text": "query ShopifyShopsListQuery(\n  $id: ID!\n) {\n  campaign(id: $id) {\n    canUpdateShopifyAuthorization\n    shopifyAuthorization {\n      reauthenticationRequired\n      shop {\n        name\n        url\n        id\n      }\n      id\n    }\n    organization {\n      id\n      shopifyAuthorizations {\n        edges {\n          node {\n            id\n            reauthenticationRequired\n            shop {\n              name\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "736ab7696445f802fe981e4dffa6b991";

export default node;

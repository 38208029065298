import { commitMutation, graphql } from 'react-relay';
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes';

import {
  UpdateCustomListMutation,
  UpdateCustomListMutation$data,
  UpdateCustomListInput,
  UpdateCustomListMutation$variables,
} from 'GraphTypes/UpdateCustomListMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation UpdateCustomListMutation($input: UpdateCustomListInput!) {
    updateCustomList(input: $input) {
      __typename
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: UpdateCustomListInput,
  resolve?: (response: UpdateCustomListMutation$data) => void,
  reject?: FnType
) => {
  const variables: UpdateCustomListMutation$variables = {
    input: {
      ...data,
    },
  };

  const updater: SelectorStoreUpdater = (store) => {
    const customList = store.get(data.id);
    if (!customList) return;
    customList.setValue(data.name, 'name');
  };

  commitMutation<UpdateCustomListMutation>(environment, {
    mutation,
    variables,
    updater,
    optimisticUpdater: updater,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

export type {
  UpdateCustomListMutation,
  UpdateCustomListMutation$data,
  UpdateCustomListInput,
  UpdateCustomListMutation$variables,
};

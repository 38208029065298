import { TIKTOK_OPEN_API } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import type {
  AuthorizeTiktokAdsInput,
  AuthorizeTiktokAdsMutation$data,
} from 'GraphTypes/AuthorizeTiktokAdsMutation.graphql';
import authorizeTiktokAdsMutation from 'Mutations/AuthorizeTiktokAdsMutation';

const { CONNECT_URL, REDIRECT_URI, APP_ID, PERMISSIONS, RID } = TIKTOK_OPEN_API;

enum PostMessageTypeEnum {
  CreateTikTokProfile = 'createTikTokProfile',
}

type GetAuthUrl = (userNameToCheckAfterAuthorization?: string) => URL;
const getAuthUrl: GetAuthUrl = (userNameToCheckAfterAuthorization) => {
  const url = new URL(CONNECT_URL);
  let state = `redirect_uri=${location.origin}/oauth/tiktok/createProfile`;
  if (userNameToCheckAfterAuthorization) {
    state += `/${userNameToCheckAfterAuthorization}/`;
  }
  url.searchParams.set('client_key', APP_ID);
  url.searchParams.set('redirect_uri', REDIRECT_URI);
  url.searchParams.set('scope', PERMISSIONS);
  url.searchParams.set('rid', RID);
  url.searchParams.set('state', state);
  return url;
};

type UseTikTokOpenApi = () => HookResponse;
const useTikTokOpenApi: UseTikTokOpenApi = () => {
  const authorizeTiktokAds: HookResponse['authorizeTiktokAds'] = (input) => {
    return new Promise((resolve, reject) => {
      authorizeTiktokAdsMutation(input, resolve, reject);
    });
  };

  const connect: HookResponse['connect'] = (userNameToCheckAfterAuthorization) => {
    return new Promise((resolve) => {
      const url = getAuthUrl(userNameToCheckAfterAuthorization);
      amplitude.sendEvent({
        id: '148',
        category: 'creator',
        name: 'web_tt_permissions_requested',
        param: {},
      });
      const handler: EventHandler = (e) => {
        const messageTypes = new Set([PostMessageTypeEnum.CreateTikTokProfile]);
        if (messageTypes.has(e?.data?.type)) {
          window.removeEventListener('message', handler, false);
          resolve(e?.data);
        }
      };
      window.addEventListener('message', handler, false);
      window.open(url, '', 'width=600,height=800');
    });
  };

  const isFullScope: HookResponse['isFullScope'] = (scope) => {
    const permissions = PERMISSIONS.split(',').sort().join(',');
    const _scope = scope.split(',').sort().join(',');
    return permissions === _scope;
  };

  return {
    connect,
    authorizeTiktokAds,
    isFullScope,
  };
};
export { useTikTokOpenApi, PostMessageTypeEnum };

// types

type EventHandler = Parameters<typeof window.addEventListener<'message'>>[1];

type PostMessageData = {
  creatorId?: string;
  error: boolean;
  postMessageType: PostMessageTypeEnum;
  username: string;
};

type HookResponse = {
  connect: (userNameToCheckAfterAuthorization?: string) => Promise<PostMessageData>;
  isFullScope: (scope: string) => boolean;
  authorizeTiktokAds: (input: AuthorizeTiktokAdsInput) => Promise<AuthorizeTiktokAdsMutation$data>;
};

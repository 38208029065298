import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './MessageFile.pcss';

import { cutStringWithExt } from 'Util/textFormatter';
import { timeChatFormat } from 'Util/dateCreator';
import { createFileSize } from 'Util/numberFormatter';
import PopupLink from 'Components/PopupLink/PopupLink';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import IconNew from 'Components/ui/Icon/Icon';
import { MessageFile_attachment$key } from 'GraphTypes/MessageFile_attachment.graphql';

interface Props {
  isOwner: boolean;
  isRead: boolean;
  createdAt: string;
  attachment: MessageFile_attachment$key;
}

const MessageFile: React.FC<Props> = (props) => {
  const { attachment, isOwner, createdAt, isRead } = props;

  const data = useFragment(
    graphql`
      fragment MessageFile_attachment on MessageAttachment {
        createdAt
        file {
          secureUrl
          filename
          originalFilename
          bytes
        }
      }
    `,
    attachment
  );
  if (!data?.file) {
    return null;
  }
  const {
    file: { secureUrl, filename, bytes },
  } = data;

  const classes = classnames(styles.root, { [styles.isOwner]: isOwner });

  if (!filename) {
    return null;
  }

  return (
    <div className={classes}>
      <PopupLink to={secureUrl} className={styles.file}>
        <div className={styles.fileIcon}>
          <IconNew name="Document" />
        </div>
        <div>
          <Text color="creamCan" text={cutStringWithExt(filename, 17)} />
          <Text size="xs" color="grayDog" text={createFileSize(bytes)} />
        </div>
      </PopupLink>
      <footer className={styles.footer}>
        <Text size="sm" color="grayDog" text={timeChatFormat(createdAt)} className={styles.date} />
        {isOwner && <Icon size={16} name={isRead ? 'Check-double' : 'Check'} color="grey" />}
      </footer>
    </div>
  );
};

export default MessageFile;

/**
 * @generated SignedSource<<9e7d1b6cc6e1f38c0e04748844cb2d6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type Accounting_CreateManualTransactionForSubscriptionInput = {
  amount: number;
  billingPeriodEndsAt: any;
  billingPeriodStartsAt: any;
  campaignId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  currency: Currency;
  departmentId?: string | null | undefined;
  id?: string | null | undefined;
  organizationId: string;
  planId?: PlanId | null | undefined;
};
export type CreateManualTransactionForSubscriptionMutation$variables = {
  input: Accounting_CreateManualTransactionForSubscriptionInput;
};
export type CreateManualTransactionForSubscriptionMutation$data = {
  readonly accounting: {
    readonly createManualTransactionForSubscription: {
      readonly clientMutationId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CreateManualTransactionForSubscriptionMutation = {
  response: CreateManualTransactionForSubscriptionMutation$data;
  variables: CreateManualTransactionForSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Accounting_Mutation",
    "kind": "LinkedField",
    "name": "accounting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Accounting_CreateManualTransactionForSubscriptionPayload",
        "kind": "LinkedField",
        "name": "createManualTransactionForSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateManualTransactionForSubscriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateManualTransactionForSubscriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e870bc47bf8eddbc08f3b9b26d2ab29",
    "id": null,
    "metadata": {},
    "name": "CreateManualTransactionForSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateManualTransactionForSubscriptionMutation(\n  $input: Accounting_CreateManualTransactionForSubscriptionInput!\n) {\n  accounting {\n    createManualTransactionForSubscription(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddf266de30a070dd57f370faf655e0f8";

export default node;

type PromisifyMutation = <M extends BaseMutationType>(
  mutation: (
    input: BaseMutationArgsType<M>['input']['input'],
    resolve: BaseMutationArgsType<M>['resolve'],
    reject?: BaseMutationArgsType<M>['reject']
  ) => void
) => (input: M['variables']['input']) => Promise<M['response']>;
const promisifyMutation: PromisifyMutation = (mutation) => {
  return (variables) => {
    return new Promise((resolve, reject) => {
      mutation(variables, resolve, reject);
    });
  };
};

export { promisifyMutation };

// types

type BaseMutationType = {
  readonly response: any;
  readonly variables: {
    readonly input: any;
  };
};

type BaseMutationArgsType<M extends BaseMutationType> = {
  input: M['variables'];
  resolve: (response: M['response']) => void;
  reject?: () => void;
};

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AcceptPriceChangeRequestInput,
  AcceptPriceChangeRequestMutation,
  AcceptPriceChangeRequestMutation$data,
} from 'GraphTypes/AcceptPriceChangeRequestMutation.graphql';

const mutation = graphql`
  mutation AcceptPriceChangeRequestMutation($input: AcceptPriceChangeRequestInput!) {
    acceptPriceChangeRequest(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

interface DataInput {
  projectId?: string;
}

export default (
  data: AcceptPriceChangeRequestInput & DataInput,
  resolve?: (response: AcceptPriceChangeRequestMutation$data) => void,
  reject?: fnType
) => {
  const { priceChangeRequestId } = data;

  const id = uuid();
  const variables = {
    input: {
      id,
      priceChangeRequestId,
    },
  };
  commitMutation<AcceptPriceChangeRequestMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

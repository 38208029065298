import React, { useMemo, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';
import type { KeyType } from 'react-relay/relay-hooks/helpers';

import CreatorDetails from '../../../../components/CreatorDetails/CreatorDetails';
import { Projects } from '../util';

import styles from './Collaboration.pcss';

import { amplitude } from 'Helpers/amplitude';
import { setModal } from 'Actions/Modal.Actions';
import { getCreatorProfileLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { modalName } from 'Types/modals';
import {
  Collaboration_collaboration$key,
  Collaboration_collaboration,
} from 'GraphTypes/Collaboration_collaboration.graphql';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';

interface Props {
  collaboration: Collaboration_collaboration$key;
  onChatClick: (projects: Projects) => void;
}

const Collaboration: React.FC<Props> = (props) => {
  const { collaboration, onChatClick } = props;

  const dispatch = useDispatch();

  const data = useFragment<KeyType<Collaboration_collaboration>>(
    graphql`
      fragment Collaboration_collaboration on Collaboration {
        brands {
          totalCount
        }
        completedProjects {
          edges {
            node {
              id
              creator {
                id
              }
              campaign {
                id
                platform
                brand {
                  logo {
                    ... on Image {
                      transformations {
                        brandLogoUrl
                      }
                    }
                  }
                }
                name
                stage
              }
            }
          }
          totalCount
        }
        notCompletedProjects {
          edges {
            node {
              id
              creator {
                id
              }
              campaign {
                id
                platform
                brand {
                  logo {
                    ... on Image {
                      transformations {
                        brandLogoUrl
                      }
                    }
                  }
                }
                name
                stage
              }
            }
          }
        }
        creator {
          id
          ...CreatorDetails_creator
        }
      }
    `,
    normalizeFragmentRef<Collaboration_collaboration$key>(collaboration)
  );

  if (!data) return null;

  const brandCounts = data?.brands?.totalCount;
  const campaignsCounts = data?.completedProjects?.totalCount;
  const creatorId = data?.creator.id;

  const completedProjectsList = useMemo(() => {
    return data?.completedProjects?.edges?.map((item) => {
      const project = item?.node;
      const projectId = project?.id;
      const campaign = item?.node?.campaign;
      return {
        name: campaign?.name,
        platform: campaign?.platform,
        logo: campaign?.brand?.logo?.transformations?.brandLogoUrl,
        projectId,
        campaignId: campaign?.id,
        campaignStage: campaign?.stage,
        creatorId: project?.creator?.id,
      };
    });
  }, [data.completedProjects?.edges]);

  const notCompletedProjectsList = useMemo(() => {
    return data?.notCompletedProjects?.edges?.map((item) => {
      const project = item?.node;
      const projectId = project?.id;
      const campaign = item?.node?.campaign;
      return {
        name: campaign?.name,
        platform: campaign?.platform,
        logo: campaign?.brand?.logo?.transformations?.brandLogoUrl,
        projectId,
        campaignId: campaign?.id,
        campaignStage: campaign?.stage,
        creatorId: project?.creator?.id,
      };
    });
  }, [data.notCompletedProjects?.edges]);

  const lastCompletedCampaignId = useMemo(() => {
    const list = data?.completedProjects?.edges || [];
    return list && Number(list.length) > 0 ? list[0]?.node?.campaign.id : undefined;
  }, [data?.completedProjects?.edges]);

  const profileLink = getCreatorProfileLink({ creatorId });

  const handleStartNewDeal = useCallback(() => {
    amplitude.sendEvent({
      id: 212,
      category: 'creators_worked',
      name: 'start_deal_click',
      param: { creatorId },
    });
    dispatch(
      setModal(modalName.START_NEW_DEAL, {
        creatorId,
        campaignId: lastCompletedCampaignId,
        source: 'creators_space',
      })
    );
  }, [creatorId]);

  const handleChatClick = useCallback(() => {
    amplitude.sendEvent({
      id: 210,
      category: 'creators_worked',
      name: 'open_chat_click',
      param: { creatorId },
    });
    onChatClick({ notCompletedProjectsList, completedProjectsList });
  }, [creatorId, notCompletedProjectsList, completedProjectsList]);

  const handleProfileClick = useCallback(() => {
    amplitude.sendEvent({
      id: 209,
      category: 'creators_worked',
      name: 'view_profile_click',
      param: { creatorId },
    });
  }, [creatorId]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.creator}>
          <CreatorDetails creator={data.creator} />
        </div>
        <Text
          type="md"
          msg="creators.dashboard.previous_collaborations.brands_count"
          formatValues={{ count: brandCounts ? `${brandCounts}` : '' }}
          className={styles.count}
        />
        <Text
          type="md"
          msg="creators.dashboard.previous_collaborations.campaigns_count"
          formatValues={{ count: campaignsCounts ? `${campaignsCounts}` : '' }}
          className={styles.count}
        />
      </div>
      <div className={styles.controls}>
        <SmartLink path={profileLink} target="_blank">
          <AlterButton
            icon="Profile"
            type="grey"
            msg="creators.dashboard.previous_collaboration.view_profile"
            onClick={handleProfileClick}
          />
        </SmartLink>
        <AlterButton
          icon="Profile"
          type="grey"
          msg="creators.dashboard.previous_collaboration.open_chat"
          onClick={handleChatClick}
        />
        <AlterButton
          icon="Add-plus-circle"
          type="grey"
          msg="creators.dashboard.previous_collaboration.start_new_deal"
          onClick={handleStartNewDeal}
        />
      </div>
    </div>
  );
};

export default Collaboration;

import React, { createContext, useReducer, PropsWithChildren, FC } from 'react';

import { PlanId } from 'GraphTypes/BriefQuery.graphql';

type ProjectInfoContextValue = {
  subscription: {
    planId: PlanId | null;
    maxHiredCreators: number | null;
    maxLicensedCreators: number | null;
    maxBcaConnections: number | null;
  };
  counters: {
    hiredCreators: number | null;
    psLicensedCreators: number | null;
    bcaLicensedCreators: number | null;
  };
  isMaxHiredCreatorsExceeded: boolean;
  isMaxPsLicensedCreatorsExceeded: boolean;
  isMaxBcaConnectionsExceeded: boolean;
};

const initialValue: ProjectInfoContextValue = {
  subscription: {
    planId: null,
    maxHiredCreators: null,
    maxLicensedCreators: null,
    maxBcaConnections: null,
  },
  counters: {
    hiredCreators: null,
    psLicensedCreators: null,
    bcaLicensedCreators: null,
  },
  isMaxHiredCreatorsExceeded: false,
  isMaxPsLicensedCreatorsExceeded: false,
  isMaxBcaConnectionsExceeded: true,
};

const actions = {};

type CampaignReducer = (
  state: ProjectInfoContextValue,
  action: { type: typeof actions; payload: Partial<ProjectInfoContextValue> }
) => ProjectInfoContextValue;
const campaignReducer: CampaignReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ProjectInfoContext = createContext<ProjectInfoContextValue>(initialValue);

type ProjectInfoContextProviderType = FC<
  PropsWithChildren<{
    subscription?: {
      planId?: PlanId;
      maxHiredCreators?: number | null;
      maxLicensedCreators?: number | null;
      maxBcaConnections?: number | null;
    };
    counters?: {
      hiredCreators?: number | null;
      psLicensedCreators?: number | null;
      bcaLicensedCreators?: number | null;
    };
  }>
>;
const ProjectInfoContextProvider: ProjectInfoContextProviderType = (props) => {
  const { children, subscription, counters } = props;
  const {
    maxLicensedCreators = null,
    maxHiredCreators = null,
    maxBcaConnections = null,
  } = subscription || {};
  const {
    psLicensedCreators = null,
    bcaLicensedCreators = null,
    hiredCreators = null,
  } = counters || {};

  const [state] = useReducer<CampaignReducer>(campaignReducer, {
    ...initialValue,
  });

  const value: ProjectInfoContextValue = {
    ...state,
    subscription: {
      ...state.subscription,
      ...subscription,
    },
    counters: {
      ...state.counters,
      ...counters,
    },
    isMaxHiredCreatorsExceeded:
      typeof maxHiredCreators === 'number' && typeof hiredCreators === 'number'
        ? hiredCreators >= maxHiredCreators
        : false,
    isMaxPsLicensedCreatorsExceeded:
      typeof maxLicensedCreators === 'number' && typeof psLicensedCreators === 'number'
        ? psLicensedCreators >= maxLicensedCreators
        : false,
    isMaxBcaConnectionsExceeded:
      typeof maxBcaConnections === 'number' && typeof bcaLicensedCreators === 'number'
        ? bcaLicensedCreators >= maxBcaConnections
        : false,
  };

  return <ProjectInfoContext.Provider value={value}>{children}</ProjectInfoContext.Provider>;
};

export { ProjectInfoContext, ProjectInfoContextProvider };

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  RequestProjectArchivationInput,
  RequestProjectArchivationMutation$data,
  RequestProjectArchivationMutation,
} from 'GraphTypes/RequestProjectArchivationMutation.graphql';

const mutation = graphql`
  mutation RequestProjectArchivationMutation($input: RequestProjectArchivationInput!) {
    requestProjectArchivation(input: $input) {
      project {
        archivation(side: ADVERTISER) {
          id
        }
        archivationByContractor: archivation(side: CONTRACTOR) {
          id
        }
        archivationRequest {
          id
          side
          acceptance {
            id
          }
          rejection {
            id
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: RequestProjectArchivationInput,
  resolve?: (response: RequestProjectArchivationMutation$data) => void,
  reject?: fnType
) => {
  const { projectId, reason, comment, side } = data;
  const id = uuid();
  const variables = {
    input: {
      id,
      projectId,
      reason,
      side,
      comment,
    },
  };
  commitMutation<RequestProjectArchivationMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

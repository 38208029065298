import {
  ADD_SEARCH_PARAMS_DATA,
  SAVING_BRIEF_STATUS,
} from 'Constants';

export const addSearchParamsData = data => ({
  type: ADD_SEARCH_PARAMS_DATA,
  payload: data,
});

export const savingBriefStatus = status => ({
  type: SAVING_BRIEF_STATUS,
  payload: status,
});

/**
 * @generated SignedSource<<2d00515cc52226e3ed68b481e4427d3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AudienceInfo_creator$data = {
  readonly profile: {
    readonly audienceAgeRanges: ReadonlyArray<{
      readonly ageRange: {
        readonly name: string;
      } | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly audienceCities: ReadonlyArray<{
      readonly city: {
        readonly name: string;
      } | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly audienceCountries: ReadonlyArray<{
      readonly country: {
        readonly name: string;
      } | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly audienceEthnicities: ReadonlyArray<{
      readonly ethnicity: {
        readonly name: string;
      } | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly audienceGenders: ReadonlyArray<{
      readonly gender: {
        readonly name: string;
      } | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly audienceInterests: ReadonlyArray<{
      readonly interest: {
        readonly name: string;
      } | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly audienceReachability: {
      readonly easy: number | null | undefined;
      readonly hard: number | null | undefined;
      readonly medium: number | null | undefined;
    } | null | undefined;
    readonly generalStatistic: {
      readonly realFollowers: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly type: CreatorType;
  readonly " $fragmentType": "AudienceInfo_creator";
};
export type AudienceInfo_creator$key = {
  readonly " $data"?: AudienceInfo_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"AudienceInfo_creator">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentage",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AudienceInfo_creator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GeneralCreatorStatistic",
          "kind": "LinkedField",
          "name": "generalStatistic",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "realFollowers",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AudienceGender",
          "kind": "LinkedField",
          "name": "audienceGenders",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Gender",
              "kind": "LinkedField",
              "name": "gender",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AudienceAgeRange",
          "kind": "LinkedField",
          "name": "audienceAgeRanges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AgeRange",
              "kind": "LinkedField",
              "name": "ageRange",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AudienceReachability",
          "kind": "LinkedField",
          "name": "audienceReachability",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "easy",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "medium",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AudienceCity",
          "kind": "LinkedField",
          "name": "audienceCities",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "City",
              "kind": "LinkedField",
              "name": "city",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AudienceCountry",
          "kind": "LinkedField",
          "name": "audienceCountries",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "country",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AudienceEthnicity",
          "kind": "LinkedField",
          "name": "audienceEthnicities",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Ethnicity",
              "kind": "LinkedField",
              "name": "ethnicity",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AudienceInterest",
          "kind": "LinkedField",
          "name": "audienceInterests",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Interest",
              "kind": "LinkedField",
              "name": "interest",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "459c71cd9360a1850d19494179743e39";

export default node;

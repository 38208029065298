import React, { PropsWithChildren, Suspense } from 'react';

import ChartLoading from '../../charts/Chart/ChartLoading';

type Props = {
  titleMsg?: string;
};

const ChartSuspense: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, titleMsg } = props;

  return <Suspense fallback={<ChartLoading titleMsg={titleMsg} />}>{children}</Suspense>;
};

export default ChartSuspense;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  RateCreatorMutation,
  RateCreatorMutation$data,
  RateCreatorInput,
} from 'GraphTypes/RateCreatorMutation.graphql';

const mutation = graphql`
  mutation RateCreatorMutation($input: RateCreatorInput!) {
    rateCreator(input: $input) {
      ratingVote {
        id
        author {
          email
          advertiserProfile {
            firstName
          }
        }
        createdAt
        comment
        score
        characteristics
        project {
          ratingByBrand: creatorRatingVote(side: BRAND) {
            author {
              email
            }
            createdAt
            comment
            score
            characteristics
          }
          ratingByAgency: creatorRatingVote(side: AGENCY) {
            author {
              email
            }
            createdAt
            comment
            score
            characteristics
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: RateCreatorInput,
  resolve?: (response: RateCreatorMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { score, comment, projectId, side, characteristics, contentApprovalCreativeId } = data;

  const variables = {
    input: {
      id,
      projectId,
      score,
      comment,
      side,
      characteristics,
      contentApprovalCreativeId,
    },
  };

  commitMutation<RateCreatorMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      if (contentApprovalCreativeId) {
        const creative = store.get(contentApprovalCreativeId);
        const vote = store.get(id);
        if (!creative || !vote) return;

        creative.setLinkedRecord(vote, 'creatorRatingVote(side:"BRAND")');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useEffect } from 'react';
import track from 'react-tracking';

import styles from './FbAuth.pcss';

import authenticateWithFacebook from 'Mutations/AuthenticateWithFacebook.Mutation';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import { OAUTH } from 'Constants/general';
import Spinner from 'Atoms/Spinner/Spinner';
import Page from 'Templates/Page/Page';
import ErrorHandler from 'Util/errorHandler';
import { AuthenticateWithFacebookMutation$data } from 'GraphTypes/AuthenticateWithFacebookMutation.graphql';

const FbAuth = () => {
  useEffect(() => {
    if (location.pathname === OAUTH) {
      const { state: hashState, access_token, error } = getQueryLocationParams(location.hash);
      const { code, state: searchState } = getQueryLocationParams(location.search);
      const state = hashState || searchState;
      const stateParam = state ? getQueryLocationParams(state as string) : undefined;
      const route = stateParam ? stateParam.route : undefined;

      if (route === '/auth') {
        authenticateWithFacebook(
          { accessToken: (access_token as string) || null, code: (code as string) || null },
          handleSuccess,
          handleFail
        );
      } else {
        window.close();
      }
      return;
    }
  }, []);

  const handleSuccess = (response: AuthenticateWithFacebookMutation$data) => {
    const socialIdentityId = response.authenticateWithFacebook?.socialIdentity?.id;
    if (window.opener?.callSuccess) {
      window.opener.callSuccess(socialIdentityId);
      window.close();
    }
  };

  const handleFail = (error: Error) => {
    ErrorHandler.error('Crash while fb auth', { error });
    window.location.replace('/dashboard');
    if (window.opener && window.opener.callFail) {
      window.opener.callFail(error);
      window.close();
    }
  };

  return (
    <Page className={styles.root}>
      <Spinner />
    </Page>
  );
};

export default track(
  {
    page: 'facebook_auth',
  },
  { dispatchOnMount: true }
)(FbAuth);

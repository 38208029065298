import React from 'react';
import { graphql, useFragment } from 'react-relay';

import SimpleProjectEvent from '../../SimpleProjectEvent/SimpleProjectEvent';

import { createSum } from 'Util/numberFormatter';
import {
  PRICE_CHANGE,
  PRICE_CHANGE_REQUEST,
  PRICE_CHANGE_REQUEST_REJECTION,
  PRICE_CHANGE_REQUEST_ACCEPTANCE,
} from 'Types/enums';
import { PriceChangeEvent_event$key } from 'GraphTypes/PriceChangeEvent_event.graphql';

interface Props {
  userPostfix: string;
  event: PriceChangeEvent_event$key;
}

const PriceChangeEvent: React.FC<Props> = (props) => {
  const { event, userPostfix } = props;

  const data = useFragment(
    graphql`
      fragment PriceChangeEvent_event on ProjectEvent {
        id
        __typename
        createdAt
        ... on PriceChange {
          price
          currency
        }
        ... on PriceChangeRequest {
          requestPrice: price
          requestCurrency: currency
        }
        ... on PriceChangeRequestAcceptance {
          request {
            currency
            price
          }
        }
      }
    `,
    event
  );

  if (!data) {
    return null;
  }

  const typename = data.__typename;
  const createdAt = data.createdAt;

  const getContent = () => {
    switch (typename) {
      case PRICE_CHANGE: {
        const { price, currency } = data;
        const priceValue = currency ? createSum(price, currency) : '';

        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.price_been_chaged${userPostfix}`}
            titleValues={{ price: priceValue }}
          />
        );
      }
      case PRICE_CHANGE_REQUEST: {
        const { requestPrice, requestCurrency } = data;
        const priceValue = requestCurrency ? createSum(requestPrice, requestCurrency) : '';

        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.price_change_request${userPostfix}`}
            titleValues={{ price: priceValue }}
          />
        );
      }

      case PRICE_CHANGE_REQUEST_ACCEPTANCE: {
        const price = data.request?.price;
        const currency = data.request?.currency;
        const priceValue = currency ? createSum(price, currency) : '';

        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.price_change_accept${userPostfix}`}
            titleValues={{ price: priceValue }}
          />
        );
      }

      case PRICE_CHANGE_REQUEST_REJECTION: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.price_change_reject${userPostfix}`}
          />
        );
      }

      default:
        return null;
    }
  };

  return <div>{getContent()}</div>;
};

export default PriceChangeEvent;

import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { fetchQuery, graphql } from 'relay-runtime';
import classnames from 'classnames';

import styles from './ImagesWithFallback.css';

import environment from 'Api/Environment';
import { setModal } from 'Actions/Modal.Actions';
import { MEDIA } from 'Constants/modals';
import { TIKTOK } from 'Constants/general';
import Icon from 'Atoms/Icon/Icon';

const ImagesWithFallbackQuery = graphql`
  query ImagesWithFallbackQuery($url: String!) {
    instagramOembed(instagramPostUrl: $url) {
      thumbnailUrl
    }
  }
`;

const ImagesWithFallback = (props) => {
  const {
    className,
    imageStyle,
    fullScreen,
    defaultItem,
    items: defaultItems,
    limit,
    updateItemThumbnailUrl,
    isStories,
    failCallback,
    type,
    videoIcon,
  } = props;

  const [brokenImagesHash, setBrokenImagesHash] = useState({});

  const dispatch = useDispatch();

  const handleImageError = (id) => {
    setBrokenImagesHash((prev) => {
      return { ...prev, [id]: true };
    });
  };

  const createItem = (item) => {
    const { id, url, videoUrl, thumbnailUrl, instagramUrl } = item;
    const style = { backgroundImage: `url(${thumbnailUrl})` };

    const onError = (el) => {
      if (isStories) {
        failCallback();

        return;
      }

      const target = el.currentTarget;
      const isRetry = target.getAttribute('data-retry') === 'true';

      if (type === TIKTOK || !instagramUrl || isRetry) {
        handleImageError(id);

        return;
      }

      fetchQuery(environment, ImagesWithFallbackQuery, { url: instagramUrl }).subscribe({
        next: (result) => {
          if (result && result.instagramOembed && result.instagramOembed.thumbnailUrl) {
            const mediaUrl = result.instagramOembed.thumbnailUrl;
            target.src = mediaUrl;
            target.setAttribute('data-retry', true);
            const back = document.getElementById(id);
            if (back) {
              back.style.backgroundImage = `url("${mediaUrl}")`;
            }
            if (updateItemThumbnailUrl) {
              updateItemThumbnailUrl(mediaUrl);
            }
          } else {
            handleImageError(id);
          }
        },
        error: () => handleImageError(id),
      });
    };

    const handleItemClick = fullScreen
      ? () => {
          dispatch(setModal(MEDIA, { url, videoUrl, instagramUrl, thumbnailUrl }));
        }
      : undefined;

    return (
      <div
        key={id + thumbnailUrl}
        className={classnames(className, styles.item, {
          [styles.fullScreen]: fullScreen,
          [styles.isStories]: isStories,
        })}
        onClick={handleItemClick}
      >
        <img src={thumbnailUrl} onError={onError} />
        {!isStories && (
          <span id={id} className={`${styles.itemImage} ${imageStyle}`} style={style} />
        )}
        {videoIcon && videoUrl ? (
          <div className={styles.playWrap}>
            <Icon name="play" color="white" size="sm" />
          </div>
        ) : null}
      </div>
    );
  };

  const createDefaults = (amount) => {
    if (!defaultItem) return null;
    const arr = [];
    let i = 0;
    while (i < amount) {
      arr.push(React.cloneElement(defaultItem, { key: i }));
      i++;
    }

    return arr;
  };

  const filteredItems = defaultItems.filter((item) => !brokenImagesHash[item.id]);
  if (filteredItems.length > limit) filteredItems.length = limit;
  const images = filteredItems.map(createItem);
  const outSider = limit > filteredItems.length;

  return (
    <Fragment>
      {images}
      {outSider && createDefaults(limit - filteredItems.length)}
    </Fragment>
  );
};

export default ImagesWithFallback;

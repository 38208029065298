import React from 'react';
import { graphql } from 'react-relay';

import ProjectFilterContainer from '../ProjectFilterContainer/ProjectFilterContainer';

import styles from './OrganizationCampaigns.pcss';
import OrganizationCampaignsList from './OrganizationCampaignsList/OrganizationCampaignsList';

export const OrganizationCampaignsQuery = graphql`
  query OrganizationCampaignsQuery($textQuery: String, $brandIds: [ID!], $organizationId: ID!) {
    organization(id: $organizationId) {
      campaigns(stages: [ACTIVE], textQuery: $textQuery, brandIds: $brandIds) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

interface Props {
  selectedItems: string[];
  brandIds?: string[];
  organizationId?: string;
  onChange: (items: string[]) => void;
}

const OrganizationCampaigns: React.FC<Props> = (props) => {
  const { selectedItems = [], organizationId = '', brandIds, onChange } = props;

  return (
    <ProjectFilterContainer
      selectedItems={selectedItems}
      anchorMsg="projects.all_campaigns"
      onChange={onChange}
      query={OrganizationCampaignsQuery}
      queryParams={{ brandIds, organizationId }}
      listComponent={<OrganizationCampaignsList />}
      className={styles.campaignList}
    ></ProjectFilterContainer>
  );
};

export default OrganizationCampaigns;

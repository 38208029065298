import {
  ADD_SEARCH_PARAMS_DATA,
  CHANGE_PAYOUT_ERRORS_COUNT,
  SAVING_BRIEF_STATUS,
} from 'Constants';

const initialState = {
  searchParamsData: null,
  briefSavingStatus: null,
};

const formatSearchData = (data) => {
  const keys = Object.keys(data);
  const newData = {};
  keys.forEach(key => {
    const list = data[key];
    const items = list.map(item => {
      return { ...item, value: item.id };
    });
    newData[key] = items;
  });

  return newData;
};

const general = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_SEARCH_PARAMS_DATA:
      return { ...state, searchParamsData: formatSearchData(payload) };
    case SAVING_BRIEF_STATUS:
      return { ...state, briefSavingStatus: payload };
    default:
      return state;
  }
};

export default general;

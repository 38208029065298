import { BillingTransactionType } from 'Types/enums';
import { Billing_TransactionType } from 'GraphTypes/TransactionContainer_billingTransactions.graphql';

export type BrandType = {
  id: string;
  name: string | null;
  check: boolean;
};

export type CampaignType = {
  id: string;
  name?: string | null;
};

export type CreatorType = {
  id: string;
  username: string | null;
};

export interface Filters {
  creator?: CreatorType;
  campaigns?: CampaignType[];
  brands?: BrandType[];
  dateFrom?: Date;
  dateTo?: Date;
  types?: Billing_TransactionType[];
}

export const defaultFilters = {
  campaignIds: undefined,
  brandIds: undefined,
  creatorIds: undefined,
  types: undefined,
  dateFrom: undefined,
  dateTo: undefined,
};

export const newAvailableTransactionTypes = [
  {
    msg: 'billing_history.transaction.type.all',
    id: null,
  },
  {
    msg: 'billing_history.transaction.type.subscription',
    id: BillingTransactionType.SUBSCRIPTION,
  },
  {
    msg: 'billing_history.transaction.type.subscription_refund',
    id: BillingTransactionType.SUBSCRIPTION_REFUND,
  },
  { msg: 'billing_history.transaction.type.deposit', id: BillingTransactionType.DEPOSIT },
  { msg: 'billing_history.transaction.type.extra_payout', id: BillingTransactionType.EXTRA_PAYOUT },
  {
    msg: 'billing_history.transaction.type.extra_payout_refund',
    id: BillingTransactionType.EXTRA_PAYOUT_REFUND,
  },
  {
    msg: 'billing_history.transaction.type.deposit_refund',
    id: BillingTransactionType.DEPOSIT_REFUND,
  },
  {
    msg: 'billing_history.transaction.type.transaction_fee',
    id: BillingTransactionType.TRANSACTION_FEE,
  },
  { msg: 'billing_history.transaction.type.reservation', id: BillingTransactionType.RESERVATION },
  {
    msg: 'billing_history.transaction.type.sales_peak_reward',
    id: BillingTransactionType.SALES_PEAK_REWARD,
  },
  {
    msg: 'billing_history.transaction.type.referral_reward',
    id: BillingTransactionType.REFERRAL_REWARD,
  },
  {
    msg: 'billing_history.transaction.type.creator_expense',
    id: BillingTransactionType.CREATOR_EXPENSE,
  },
  {
    msg: 'billing_history.transaction.type.fully_managed_zeroing',
    id: BillingTransactionType.FULLY_MANAGED_ZEROING,
  },
  {
    msg: 'billing_history.transaction.type.reimbursement',
    id: BillingTransactionType.REIMBURSEMENT,
  },
  {
    msg: 'billing_history.transaction.type.reimbursement_refund',
    id: BillingTransactionType.REIMBURSEMENT_REFUND,
  },
];

import React from 'react';

import styles from './DeleteButton.css';

import Icon from 'Components/ui/Icon/Icon';
import removeMonitoredCampaign from 'Mutations/RemoveMonitoredCampaign.Mutation';

interface Props {
  campaignId: string;
}

const DeleteButton: React.FC<Props> = (props) => {
  const { campaignId } = props;

  const handleClick = () => {
    removeMonitoredCampaign({ campaignId });
  };

  return (
    <div className={styles.root} onClick={handleClick}>
      <Icon name="Trash-delele" />
    </div>
  );
};

export default DeleteButton;

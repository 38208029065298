import type { IntlShape } from 'react-intl';

import type { HubSpotProperties } from 'Analytics/engines/Hubspot';

type GetCompanyRoleType = (intl: IntlShape) => Map<string, GetCompanyRoleTypeResultItem>;
const getCompanyRoleType: GetCompanyRoleType = (intl) => {
  return new Map([
    [
      intl.formatMessage({ id: 'signup_page.advertiser.role.items.ceo' }),
      {
        value: intl.formatMessage({ id: 'signup_page.advertiser.role.items.ceo' }),
        label: intl.formatMessage({ id: 'signup_page.advertiser.role.items.ceo' }),
        msg: 'signup_page.advertiser.role.items.ceo',
        hubSpotValue: 'CEO/Owner',
      },
    ],
    [
      intl.formatMessage({ id: 'signup_page.advertiser.role.items.cmo' }),
      {
        value: intl.formatMessage({ id: 'signup_page.advertiser.role.items.cmo' }),
        label: intl.formatMessage({ id: 'signup_page.advertiser.role.items.cmo' }),
        msg: 'signup_page.advertiser.role.items.cmo',
        hubSpotValue: 'Marketing Executive/CMO',
      },
    ],
    [
      intl.formatMessage({
        id: 'signup_page.advertiser.role.items.dig_marketing',
      }),
      {
        value: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.dig_marketing',
        }),
        label: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.dig_marketing',
        }),
        msg: 'signup_page.advertiser.role.items.dig_marketing',
        hubSpotValue: 'Digital Marketing',
      },
    ],
    [
      intl.formatMessage({
        id: 'signup_page.advertiser.role.items.media_buying',
      }),
      {
        value: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.media_buying',
        }),
        label: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.media_buying',
        }),
        msg: 'signup_page.advertiser.role.items.media_buying',
        hubSpotValue: 'Media Buyer/Growth Marketing/Performance Marketing',
      },
    ],
    [
      intl.formatMessage({
        id: 'signup_page.advertiser.role.items.influencer_manager',
      }),
      {
        value: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.influencer_manager',
        }),
        label: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.influencer_manager',
        }),
        msg: 'signup_page.advertiser.role.items.influencer_manager',
        hubSpotValue: 'Influencer Marketing',
      },
    ],
    [
      intl.formatMessage({
        id: 'signup_page.advertiser.role.items.creative_strategist',
      }),
      {
        value: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.creative_strategist',
        }),
        label: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.creative_strategist',
        }),
        msg: 'signup_page.advertiser.role.items.creative_strategist',
        hubSpotValue: 'Creative',
      },
    ],
    [
      intl.formatMessage({
        id: 'signup_page.advertiser.role.items.operations',
      }),
      {
        value: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.operations',
        }),
        label: intl.formatMessage({
          id: 'signup_page.advertiser.role.items.operations',
        }),
        msg: 'signup_page.advertiser.role.items.operations',
        hubSpotValue: 'Ops/Assistant/Associate',
      },
    ],
    [
      intl.formatMessage({ id: 'signup_page.advertiser.role.items.other' }),
      {
        value: intl.formatMessage({ id: 'signup_page.advertiser.role.items.other' }),
        label: intl.formatMessage({ id: 'signup_page.advertiser.role.items.other' }),
        msg: 'signup_page.advertiser.role.items.other',
        hubSpotValue: 'Other',
      },
    ],
  ]);
};

export { getCompanyRoleType };

// types

type GetCompanyRoleTypeResultItem = {
  value: string;
  label: string;
  msg: string;
  hubSpotValue: HubSpotProperties['company_role_new'];
};

export type { GetCompanyRoleTypeResultItem };

/**
 * @generated SignedSource<<1428982606f091df3056c8ccafafc3b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type CreateManualCreatorExpenseInput = {
  amount: number;
  campaignId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  currency: Currency;
  departmentId?: string | null | undefined;
  id?: string | null | undefined;
  organizationId: string;
};
export type CreateManualCreatorExpenseMutation$variables = {
  input: CreateManualCreatorExpenseInput;
};
export type CreateManualCreatorExpenseMutation$data = {
  readonly createManualCreatorExpense: {
    readonly organization: {
      readonly paymentAccount: {
        readonly balance: number;
        readonly currency: Currency;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CreateManualCreatorExpenseMutation = {
  response: CreateManualCreatorExpenseMutation$data;
  variables: CreateManualCreatorExpenseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateManualCreatorExpenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateManualCreatorExpensePayload",
        "kind": "LinkedField",
        "name": "createManualCreatorExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateManualCreatorExpenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateManualCreatorExpensePayload",
        "kind": "LinkedField",
        "name": "createManualCreatorExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "934cae4442881522b7d5339f114b4dc2",
    "id": null,
    "metadata": {},
    "name": "CreateManualCreatorExpenseMutation",
    "operationKind": "mutation",
    "text": "mutation CreateManualCreatorExpenseMutation(\n  $input: CreateManualCreatorExpenseInput!\n) {\n  createManualCreatorExpense(input: $input) {\n    organization {\n      paymentAccount {\n        balance\n        currency\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a86216fb00180fb6facf751c448dc2a";

export default node;

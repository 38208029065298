import React, { useState, Context, PropsWithChildren } from 'react';

import { DrawerContextType } from './Drawer.Context';
import type { DrawerId } from './types';

type Props = {
  context: Context<DrawerContextType>;
};

const DrawerProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, context: Context } = props;
  const [prevDrawerId, setPrevDrawerId] = useState<DrawerId | null>(null);
  const [activeDrawerId, setActiveDrawerId] = useState<DrawerId | null>(null);

  const openDrawer: DrawerContextType['openDrawer'] = (drawerId, showPrevDrawerAfterClose) => {
    setPrevDrawerId(showPrevDrawerAfterClose ? activeDrawerId : null);
    setActiveDrawerId(drawerId);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // @desc Temporary value
  window.openDrawer = openDrawer;

  const closeDrawer: DrawerContextType['closeDrawer'] = (drawerId) => {
    if (activeDrawerId === drawerId) {
      setPrevDrawerId(null);
      setActiveDrawerId(prevDrawerId);
    }
  };

  const contextValue: DrawerContextType = {
    idPrefix: '__Drawer__',
    activeDrawerId,
    openDrawer,
    closeDrawer,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { DrawerProvider };

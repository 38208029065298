import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutation$data,
  Stripe_CreatePaymentIntentInput,
} from 'GraphTypes/CreatePaymentIntentMutation.graphql';

const mutation = graphql`
  mutation CreatePaymentIntentMutation($input: Stripe_CreatePaymentIntentInput!) {
    stripe {
      createPaymentIntent(input: $input) {
        paymentIntent {
          id
          clientSecret
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Stripe_CreatePaymentIntentInput,
  resolve?: (response: CreatePaymentIntentMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<CreatePaymentIntentMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }

      if (resolve && response) resolve(response);
    },
    onError: (error) => {
      if (reject) reject(error);
    },
  });
};

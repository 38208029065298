import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  RemoveCreatorFromCampaignShortlistMutation,
  RemoveCreatorFromCampaignShortlistMutation$data,
  RemoveCreatorFromCampaignShortlistInput,
} from 'GraphTypes/RemoveCreatorFromCampaignShortlistMutation.graphql';

const mutation = graphql`
  mutation RemoveCreatorFromCampaignShortlistMutation(
    $input: RemoveCreatorFromCampaignShortlistInput!
  ) {
    removeCreatorFromCampaignShortlist(input: $input) {
      ... on NotFoundError {
        message
      }
    }
  }
`;

type fnType = (data: object) => void;

type Data = {
  campaignId?: string;
  projectId?: string;
};

export default (
  data: RemoveCreatorFromCampaignShortlistInput & Data,
  resolve?: (response: RemoveCreatorFromCampaignShortlistMutation$data) => void,
  reject?: fnType
) => {
  const { creatorId, campaignId, projectId } = data;

  const id = uuid();

  const variables = {
    input: {
      id,
      creatorId,
      campaignId,
    },
  };

  commitMutation<RemoveCreatorFromCampaignShortlistMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (projectId) {
        const currentProject = store.get(projectId);
        currentProject?.setValue(false, 'shortlisted');
      }

      if (campaignId) {
        const root = store.get(campaignId);
        if (!root) return;

        const mediaplan = ConnectionHandler.getConnection(root, 'Projects_projects');
        if (mediaplan) {
          const mediaplanItem = mediaplan
            ?.getLinkedRecords('edges')
            ?.find(
              (edge) =>
                edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
            );
          if (mediaplanItem) {
            const node = mediaplanItem.getLinkedRecord('node');
            node?.setValue(false, 'shortlisted');
          }
        }

        const projects = ConnectionHandler.getConnection(root, 'Projects_projects');
        if (projects) {
          const projectItem = projects
            ?.getLinkedRecords('edges')
            ?.find(
              (edge) =>
                edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
            );
          if (projectItem) {
            const node = projectItem.getLinkedRecord('node');
            node?.setValue(false, 'shortlisted');
          }
        }
      }
    },
    updater: (store) => {
      if (campaignId) {
        const root = store.get(campaignId);
        if (!root) return;

        const mediaplan = ConnectionHandler.getConnection(root, 'Projects_projects');
        if (mediaplan) {
          const mediaplanItem = mediaplan
            ?.getLinkedRecords('edges')
            ?.find(
              (edge) =>
                edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
            );
          if (mediaplanItem) {
            const node = mediaplanItem.getLinkedRecord('node');
            node?.setValue(false, 'shortlisted');
          }
        }

        const projects = ConnectionHandler.getConnection(root, 'Projects_projects');
        if (projects) {
          const projectItem = projects
            ?.getLinkedRecords('edges')
            ?.find(
              (edge) =>
                edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
            );
          if (projectItem) {
            const node = projectItem.getLinkedRecord('node');
            node?.setValue(false, 'shortlisted');
          }
        }
      }

      if (projectId) {
        const currentProject = store.get(projectId);
        currentProject?.setValue(false, 'shortlisted');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

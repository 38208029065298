export const downloadFile = (url, filename, callback, failCallback) => {
  const request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.responseType = 'blob';
  if (failCallback) {
    request.upload.addEventListener('error', failCallback, false);
  }
  request.onload = () => {
    const blob = request.response;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    if (callback) {
      callback();
    }
  };
  request.send();
};
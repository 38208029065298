import React from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import styles from './CheckoutForm.pcss';

import Button from 'Components/ui/Button/Button';

const options = {
  wallets: {
    applePay: 'never' as const,
    googlePay: 'never' as const,
  },
};

interface Props {
  loading: boolean;
  submitBtnElement?: JSX.Element;
  onPaymentElementReady: () => void;
  onBackBtnClick?: () => void;
  onSubmit: (stripe: Stripe, elements: StripeElements) => void;
}

const CheckoutForm: React.FC<Props> = (props) => {
  const { loading, submitBtnElement, onPaymentElementReady, onBackBtnClick, onSubmit } = props;

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    onSubmit(stripe, elements);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formContent}>
        <PaymentElement onReady={onPaymentElementReady} options={options} />
      </div>

      <div className={styles.buttons}>
        {onBackBtnClick && (
          <Button
            color="white"
            className={styles.control}
            loading={loading}
            msg="payment_method_select_modal.btn.back"
            onClick={onBackBtnClick}
          />
        )}
        {submitBtnElement ? (
          React.cloneElement(submitBtnElement, { loading })
        ) : (
          <Button
            color="black"
            className={styles.control}
            loading={loading}
            msg="payment_method_select_modal.btn.update"
            type="submit"
          />
        )}
      </div>
    </form>
  );
};

export default CheckoutForm;

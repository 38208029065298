/**
 * @generated SignedSource<<cdc793368809a48197a0711b313542dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorBadge = "HIGHLY_RESPONSIVE" | "HIGH_POTENTIAL" | "TOP_RATED" | "UGC_EXPERT" | "%future added value";
export type CreatorReviewReason = "ADULT_CONTENT" | "BRAND_OR_COMMUNITY" | "CELEBRITY" | "COMMUNITY" | "CONTENT_QUALITY" | "ENGAGEMENT_RATE" | "LOCATION" | "NUMBER_OF_FOLLOWERS" | "NUMBER_OF_VIEWS" | "POOR_AUDIENCE_QUALITY" | "PORTFOLIO_QUALITY" | "PRIVATE_ACCOUNT" | "PROHIBITED_CONTENT" | "REAL_FOLLOWERS" | "REMODERATION" | "SPECIAL_CATEGORY" | "WRONG_HANDLE" | "%future added value";
export type CreatorReviewSource = "AUTOMODERATION" | "USER" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type Result = "FAILURE" | "PENDING" | "SUCCESS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ModerationSettings_creator$data = {
  readonly id: string;
  readonly ownership: {
    readonly owner: {
      readonly contractorProfile: {
        readonly firstName: string | null | undefined;
        readonly id: string;
        readonly languages: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }> | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
  readonly profile: {
    readonly adminComment: string | null | undefined;
    readonly animals: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null | undefined;
    readonly badge: CreatorBadge | null | undefined;
    readonly bodyTypes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null | undefined;
    readonly currency: Currency | null | undefined;
    readonly employed: boolean | null | undefined;
    readonly ethnicity: {
      readonly id: string;
    } | null | undefined;
    readonly hairTypes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null | undefined;
    readonly id: string;
    readonly languages: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null | undefined;
    readonly searchable: boolean;
    readonly tags: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  } | null | undefined;
  readonly review: {
    readonly reason: CreatorReviewReason | null | undefined;
    readonly source: CreatorReviewSource | null | undefined;
    readonly type: Result;
  } | null | undefined;
  readonly " $fragmentType": "ModerationSettings_creator";
};
export type ModerationSettings_creator$key = {
  readonly " $data"?: ModerationSettings_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModerationSettings_creator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Language",
  "kind": "LinkedField",
  "name": "languages",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModerationSettings_creator",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "badge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminComment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Animal",
          "kind": "LinkedField",
          "name": "animals",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HairType",
          "kind": "LinkedField",
          "name": "hairTypes",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BodyType",
          "kind": "LinkedField",
          "name": "bodyTypes",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Ethnicity",
          "kind": "LinkedField",
          "name": "ethnicity",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorReview",
      "kind": "LinkedField",
      "name": "review",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "source",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorOwnership",
      "kind": "LinkedField",
      "name": "ownership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContractorProfile",
              "kind": "LinkedField",
              "name": "contractorProfile",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "3bfb614efaf67ef2262877a553dedccc";

export default node;

import queryString from 'query-string';

export const encodeQueryData = data => {
  let ret = [];
  for (let d in data) {
    if (data.hasOwnProperty(d)) {
      ret.push(d + '=' + encodeURIComponent(data[d]));
    }
  }

  return ret.join('&') ? `?${ret.join('&')}` : '';
};

export const getQueryLocationParams = (search = location.search) => {
  let newQuery = search.replace('+', '%2B');
  const parsed = queryString.parse(newQuery);

  return parsed;
};

export default {
  encodeQueryData,
  getQueryLocationParams,
};

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './PortfolioItem.css';

import colors from 'Styles/vars/colors.css';
import Spinner from 'Atoms/Spinner/Spinner';
import { VIDEO } from 'Constants/general';
import { MediaType } from 'Types/modals';
import { PortfolioItem_portfolioItem$key } from 'GraphTypes/PortfolioItem_portfolioItem.graphql';
import Tag from 'Components/ui/Tag/Tag';

const PROCESSED = 'PROCESSED';

interface Props {
  className?: string;
  onClick: (data: MediaType) => void;
  portfolioItem: PortfolioItem_portfolioItem$key;
}

const PortfolioItem: React.FC<Props> = (props) => {
  const { className, onClick, portfolioItem } = props;

  const data = useFragment(
    graphql`
      fragment PortfolioItem_portfolioItem on PortfolioItem {
        file {
          filename
          originalFilename
          id
          secureUrl
          thumbnailUrl
          url
          type
          ... on Video {
            processingStatus
            transformations {
              autoUrl
              collageThumbnailUrl
            }
          }
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
        id
        caption
        portfolioTypes {
          name
        }
        portfolioCategories {
          name
        }
      }
    `,
    portfolioItem
  );

  if (!data || !data.file) return null;

  const { caption, file, portfolioCategories } = data;

  const { secureUrl, type, processingStatus, transformations } = file;

  const handleClick = () => {
    const data =
      type === VIDEO
        ? { videoUrl: transformations?.autoUrl, secureUrl }
        : { thumbnailUrl: secureUrl };
    onClick({ caption, type: 'simple', ...data });
  };

  const src = transformations?.collageThumbnailUrl;

  const processing = type === VIDEO && processingStatus !== PROCESSED;

  const classes = classnames(className, styles.root, {
    [styles.processing]: processing,
  });

  return (
    <div
      className={classes}
      style={{ backgroundImage: `url(${src})` }}
      onClick={processing ? undefined : handleClick}
    >
      {processing && (
        <div className={styles.stub}>
          <Spinner size="sm" color={colors.colorWhite} />
        </div>
      )}
      {!processing && type === VIDEO && <div className={styles.play} />}
      {portfolioCategories?.length ? (
        <div className={styles.itemTag}>
          <Tag text={portfolioCategories[0].name} color="dark" needMargin={false} />
        </div>
      ) : null}
    </div>
  );
};

export default PortfolioItem;

/**
 * @generated SignedSource<<05856c15892a99f238b4dfac2e9a4b2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type Stripe_PaymentIntentPurpose = "ORGANIZATION_DEPOSIT" | "%future added value";
export type Stripe_CreatePaymentIntentInput = {
  amount: number;
  clientMutationId?: string | null | undefined;
  currency: Currency;
  id?: string | null | undefined;
  purpose: Stripe_PaymentIntentPurpose;
};
export type CreatePaymentIntentMutation$variables = {
  input: Stripe_CreatePaymentIntentInput;
};
export type CreatePaymentIntentMutation$data = {
  readonly stripe: {
    readonly createPaymentIntent: {
      readonly paymentIntent: {
        readonly clientSecret: string;
        readonly id: string;
      };
    } | null | undefined;
  } | null | undefined;
};
export type CreatePaymentIntentMutation = {
  response: CreatePaymentIntentMutation$data;
  variables: CreatePaymentIntentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Stripe_Mutation",
    "kind": "LinkedField",
    "name": "stripe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Stripe_CreatePaymentIntentPayload",
        "kind": "LinkedField",
        "name": "createPaymentIntent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Stripe_PaymentIntent",
            "kind": "LinkedField",
            "name": "paymentIntent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientSecret",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePaymentIntentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePaymentIntentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f81248af423d8dbb9fd6af3fa8194cf8",
    "id": null,
    "metadata": {},
    "name": "CreatePaymentIntentMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePaymentIntentMutation(\n  $input: Stripe_CreatePaymentIntentInput!\n) {\n  stripe {\n    createPaymentIntent(input: $input) {\n      paymentIntent {\n        id\n        clientSecret\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64256d74e73167a4fa37fd1d23bf9e33";

export default node;

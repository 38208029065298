import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { useLazyLoadQuery, graphql, fetchQuery } from 'react-relay';

import { useNavigate } from 'react-router-dom';
import find from 'lodash/find';

import styles from './TopupBalanceContent.pcss';
import AmountSelect from './AmountSelect/AmountSelect';

import { Props as DrawerProps } from '../TopupBalance';

import environment from 'Api/Environment';
import { amplitude } from 'Helpers/amplitude';
import createPaymentIntentMutation from 'Mutations/CreatePaymentIntent.Mutation';
import updatePaymentIntentMutation from 'Mutations/UpdatePaymentIntent.Mutation';
import { refundPolicyLink } from 'Util/links';
import { createSum } from 'Util/numberFormatter';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import Button from 'Components/ui/Button/Button';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Icon from 'Components/ui/Icon/Icon';
import PaymentMethodSelect from 'Modal/advertiser/PaymentMethodSelect/PaymentMethodSelect';
import StripePaymentElement from 'Organisms/StripePaymentElement/StripePaymentElement';
import { CreatePaymentIntentMutation$data } from 'GraphTypes/CreatePaymentIntentMutation.graphql';
import { TopupBalanceContentQuery as QueryType } from 'GraphTypes/TopupBalanceContentQuery.graphql';
import {
  USD,
  ORGANIZATION_DEPOSIT,
  MIN_AMOUNT_FOR_TOPUP,
  DEFAULT_TOPUP_AMOUNT,
  MAX_AMOUNT_FOR_TOPUP,
} from 'Constants/general';
import ErrorHandler from 'Util/errorHandler';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

const TopupBalanceContentQuery = graphql`
  query TopupBalanceContentQuery {
    currentUser {
      organization {
        paymentAccount {
          balance
        }
        subscription {
          transactionFee
        }
        stripeCustomer {
          defaultPaymentMethod {
            ... on Stripe_Card {
              id
              stripeId
              brand
              last4
            }
          }
          paymentMethods {
            edges {
              node {
                ... on Stripe_Card {
                  id
                  stripeId
                  brand
                  last4
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  token: string;
  errorMsg?: Error | StripeError;
  paymentMethodId?: string;
  paymentIntent?: PaymentIntent;
}

const TopupBalanceContent: React.FC<Props & DrawerProps> = (props) => {
  const {
    token,
    amount: defaultAmount,
    errorMsg,
    paymentMethodId,
    paymentIntent,
    purpose,
    successCallback,
  } = props;

  const data = useLazyLoadQuery<QueryType>(TopupBalanceContentQuery, {});

  const { closeDrawer } = useContext(DrawerContext);

  const controller = useRef<AbortController>();

  const newAmount =
    props.amount && Number(props.amount) < MIN_AMOUNT_FOR_TOPUP
      ? MIN_AMOUNT_FOR_TOPUP
      : props.amount;

  const stripeCustomer = data?.currentUser?.organization?.stripeCustomer;
  const transactionFee = data?.currentUser?.organization?.subscription?.transactionFee;
  const paymentMethods = stripeCustomer?.paymentMethods?.edges;
  const defaultPaymentMethod = paymentMethods?.find(
    (method) => method?.node?.id === stripeCustomer?.defaultPaymentMethod?.id
  )?.node;

  const stripe = useStripe();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultPaymentIntentId = paymentIntent?.id;

  const firstPaymentMethod = paymentMethods && paymentMethods[0]?.node;
  const method =
    paymentMethods?.find((method) => method?.node?.stripeId === paymentMethodId)?.node ||
    defaultPaymentMethod ||
    firstPaymentMethod;

  const [paymentMethodSelectVisible, setPaymentMethodSelectVisible] = useState<boolean>(false);
  const [isSuccesed, setSuccessed] = useState<boolean>(false);
  const [isDetailsVisible, setDetailsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [adddingNewCard, setAdddingNewCard] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(() => {
    return !newAmount
      ? DEFAULT_TOPUP_AMOUNT
      : Number(newAmount) < MIN_AMOUNT_FOR_TOPUP
      ? MIN_AMOUNT_FOR_TOPUP
      : Number(newAmount);
  });
  const [error, setError] = useState<Error | StripeError | undefined>(errorMsg);
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [paymentIntentId, setPaymentIntentId] = useState<string | undefined>(
    defaultPaymentIntentId
  );

  const customMinLimit = defaultAmount ? Number(defaultAmount) > amount : false;

  const topupDisabled =
    amount < MIN_AMOUNT_FOR_TOPUP || amount > MAX_AMOUNT_FOR_TOPUP || customMinLimit;

  let interval: NodeJS.Timer;

  useEffect(() => {
    createPaymentIntentMutation(
      {
        amount,
        currency: USD,
        purpose: ORGANIZATION_DEPOSIT,
      },
      paymentIntentSuccess
    );

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const paymentIntentSuccess = (paymentIntent: CreatePaymentIntentMutation$data) => {
    const receivedClientSecret =
      paymentIntent.stripe?.createPaymentIntent?.paymentIntent.clientSecret;
    const id = paymentIntent.stripe?.createPaymentIntent?.paymentIntent.id;

    if (receivedClientSecret) setClientSecret(receivedClientSecret);
    if (id) setPaymentIntentId(id);
  };

  const handleEditClick = () => {
    setPaymentMethodSelectVisible(true);
  };

  const handleMethodSelectClose = () => {
    setPaymentMethodSelectVisible(false);
  };

  const handleAmountChange = (value: number) => {
    setAmount(value);
    setLoading(true);
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = new AbortController();

    if (paymentIntentId) {
      updatePaymentIntentMutation(
        { paymentIntentId, amount: value },
        () => {
          setLoading(false);
        },
        (e) => {
          if (!(e instanceof DOMException)) {
            ErrorHandler.warn(
              'Mutation updatePaymentIntent got error',
              { e },
              { type: 'mutation' }
            );
            setLoading(false);
          }
        },
        controller.current?.signal
      );
    }
  };

  const handleTopupClick = () => {
    setLoading(true);
    handleTopup();
  };

  const handleTopup = async (newStripeCard?: string) => {
    const finalCardId = newStripeCard || method?.stripeId;
    if (!stripe || !clientSecret || !finalCardId) return;

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: finalCardId,
    });

    if (error) {
      setError(error);
    }

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      const notCustom = [100, 250, 500, 1000].includes(amount);
      amplitude.sendEvent({
        id: '93',
        category: 'billing',
        name: 'top_up_balance',
        param: { purpose, amount: notCustom ? amount : 'Custom' },
      });
      interval = setInterval(() => getStatus(), 3000);
    }
  };

  const getStatus = () => {
    fetchQuery<QueryType>(environment, TopupBalanceContentQuery, {}).subscribe({
      next: (result) => {
        const balance = result.currentUser?.organization?.paymentAccount?.balance;

        if (balance && balance >= amount) {
          handleTopupUpdated();
        }
      },
    });
  };

  const getNewStripeCardId = (stripeId: string) => {
    fetchQuery<QueryType>(environment, TopupBalanceContentQuery, {}).subscribe({
      next: (result) => {
        const methods = result.currentUser?.organization?.stripeCustomer?.paymentMethods?.edges;

        const newCard = find(methods, (item) => item?.node?.stripeId === stripeId);

        if (newCard?.node?.stripeId) {
          handleTopup(newCard.node?.stripeId);
          if (interval) {
            clearInterval(interval);
          }
        }
      },
    });
  };

  const handleTopupUpdated = () => {
    if (interval) {
      clearInterval(interval);
    }

    setSuccessed(true);

    successCallback?.();
  };

  const handleCloseDrawer = () => {
    closeDrawer(`topup-balance-${purpose?.toLowerCase() || ''}`);
  };

  const getBtnMsg = () => {
    switch (purpose) {
      case 'TOPUP_FOR_EXTRA_PAYMENT':
        return 'topup_balance_modal.top_up_and_pay';
      case 'TOP_UP_FOR_HIRING':
        return 'topup_balance_modal.top_up_and_hire';

      default:
        return 'topup_balance_modal.top_up';
    }
  };

  const topupMsg = getBtnMsg();

  const topupBtn = (
    <Button
      fluid
      color="black"
      className={styles.btn}
      loading={loading}
      disabled={topupDisabled}
      msg={topupMsg}
      onClick={handleTopupClick}
    />
  );

  const handleAddNewMethod = (intent: PaymentIntent) => {
    if (!intent) return;

    setAdddingNewCard(true);

    const { payment_method: stripeId } = intent;

    if (!stripeId) return;

    interval = setInterval(() => getNewStripeCardId(stripeId as string), 3000);
  };

  const handleFail = (error: Error | StripeError) => {
    setError(error);
  };

  const renderPaymentMethod = () => {
    if (adddingNewCard) return <Spinner style={{ margin: '40px auto' }} />;

    if (token && (!method || adddingNewCard)) {
      return (
        <div className={styles.newCardWrap}>
          <StripePaymentElement
            className={styles.paymentForm}
            clientSecret={token}
            confirmPayment={false}
            onSuccess={handleAddNewMethod}
            onFail={handleFail}
            submitBtnElement={React.cloneElement(topupBtn, {
              type: topupDisabled ? undefined : 'submit',
            })}
          />
        </div>
      );
    }

    if (!method && !token) return null;

    return null;
  };

  const fee: number = amount && transactionFee ? amount * transactionFee : 0;

  const handleDetailsShow = () => {
    setDetailsVisible(!isDetailsVisible);
  };

  if (isSuccesed) {
    return (
      <div className={styles.root}>
        <Text type="d2" msg="topup_balance_modal.success.title" className={styles.title} />
        <div className={styles.content}>
          <Text
            type="md"
            msg="topup_balance_modal.success.descr"
            formatValues={{ price: createSum(amount) }}
            className={styles.title}
          />
          <div className={styles.resultContainer}>
            <div className={styles.greenIcon} />
          </div>
        </div>
        <Button color="black" msg="topup_balance_modal.success.ok" onClick={handleCloseDrawer} />
      </div>
    );
  }

  if (!data || !token) return null;

  return (
    <div className={styles.root}>
      {!paymentMethodSelectVisible && (
        <>
          <Text type="d2" msg="topup_balance_modal.title" className={styles.title} />

          <div className={styles.content}>
            <AmountSelect currentAmount={amount} onChange={handleAmountChange} />

            {!isNaN(amount) && (
              <div className={styles.total}>
                <div className={styles.totalParam}>
                  <div>
                    <Text type="h2" msg="topup_balance_modal.total.total" />
                    <div className={styles.detailsControl} onClick={handleDetailsShow}>
                      <Text
                        type="sm"
                        msg={
                          isDetailsVisible
                            ? 'topup_balance_modal.hide_details'
                            : 'topup_balance_modal.show_details'
                        }
                      />
                      <Icon
                        name={isDetailsVisible ? 'Arrow-small-up' : 'Arrow-small-down'}
                        size={16}
                      />
                    </div>
                  </div>
                  <div>
                    <Text
                      type="h2"
                      text={createSum(+amount + fee, USD, 1, '0,0')}
                      className={styles.totalValue}
                    />
                    {method && (
                      <div className={styles.method} onClick={handleEditClick}>
                        <Text type="sm" text={`${method?.brand} •••• ${method?.last4}`} />
                        <Icon size={16} name="Edit" className={styles.edit} />
                      </div>
                    )}
                  </div>
                </div>
                {isDetailsVisible && (
                  <div className={styles.details}>
                    <div className={styles.param}>
                      <Text type="md" msg="topup_balance_modal.total.top_up" />
                      <Text type="md" text={createSum(amount, USD, 1, '0,0')} />
                    </div>
                    <div className={styles.param}>
                      <Text
                        type="md"
                        msg="topup_balance_modal.total.fee"
                        formatValues={{
                          fee: transactionFee ? (transactionFee * 100).toFixed(1) : 0,
                        }}
                      />
                      <Text type="md" text={createSum(fee, USD, 1, '0,0')} />
                    </div>
                    <div className={styles.param}>
                      <Text type="h2" msg="topup_balance_modal.total.total" />
                      <Text type="h2" text={createSum(+amount + fee, USD, 1, '0,0')} />
                    </div>
                  </div>
                )}
              </div>
            )}

            {renderPaymentMethod()}
          </div>

          {error && (
            <div className={styles.error}>
              <Text type="sm" text={error.message || JSON.stringify(error)} />
            </div>
          )}

          <div className={styles.payBtn}>
            {method && React.cloneElement(topupBtn, { onClick: handleTopupClick, loading })}
            <div className={styles.policy}>
              <Text type="sm" msg="topup_balance_modal.policy" />
              <SmartLink path={refundPolicyLink}>
                <Text type="sm" msg="general.refund_policy" className={styles.policyLink} />
              </SmartLink>
            </div>
          </div>
        </>
      )}
      {paymentMethodSelectVisible && (
        <>
          <div className={styles.backButton} onClick={handleMethodSelectClose}>
            <Icon name="Arrow-small-left" />
          </div>
          <PaymentMethodSelect
            onBackClick={handleMethodSelectClose}
            className={styles.paymentSelect}
          />
        </>
      )}
    </div>
  );
};

export default TopupBalanceContent;

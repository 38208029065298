import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import DealActions from '../DealActions/DealActions';

import styles from './ContentReviewStep.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import RequestContentRevision from 'Modal/advertiser/RequestContentRevision/RequestContentRevision';
import markContentReviewAsPassed from 'Mutations/MarkContentReviewAsPassed.Mutation';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import { ContentReviewStep_project$key } from 'GraphTypes/ContentReviewStep_project.graphql';
import { PRODUCT_SEEDING } from 'Constants/general';

interface Props {
  project: ContentReviewStep_project$key;
  postingRequired?: boolean;
  isActive: boolean;
  isPastStep: boolean;
  isContractor: boolean;
}

const ContentReviewStep: React.FC<Props> = (props) => {
  const { project, isActive, isPastStep, postingRequired, isContractor } = props;

  const data = useFragment(
    graphql`
      fragment ContentReviewStep_project on Project {
        id
        contentReview {
          status
        }
        advertiserStage
        campaign {
          publishingRequired
          type
        }
        ...DealActions_project
      }
    `,
    project
  );

  const { openDrawer } = useContext(DrawerContext);

  const {
    id: projectId,
    campaign: { publishingRequired, type: campaignType },
    advertiserStage,
  } = data;

  const isSeedingCampaign = campaignType === PRODUCT_SEEDING;

  const reviewStatus = data?.contentReview?.status;

  let finishDealMsg = postingRequired
    ? 'project.stepper.content_review.approve_posting'
    : 'project.stepper.content_review.approve_pay';

  if (isSeedingCampaign) {
    finishDealMsg = 'project.stepper.content_review.seeding_approve';
  }

  const handleRevisionRequestClick = () => {
    openDrawer(`request-revision-${projectId}`);
  };

  const handleFinishDeal = () => {
    if (publishingRequired) {
      openDrawer(`project-publication-date-${projectId}`);
    }
    markContentReviewAsPassed({ projectId });
  };

  const titleColor = !isActive && !isPastStep ? 'grayDog' : undefined;

  const isRevisionEnabled = !(
    (advertiserStage === 'CONTENT_SUBMITTED' && reviewStatus === 'SUCCESS' && !postingRequired) ||
    isSeedingCampaign
  );

  const additionalElement = isRevisionEnabled ? (
    <Button
      width="full"
      color="normal"
      msg="project.stepper.content_review.request_revision"
      onClick={handleRevisionRequestClick}
    />
  ) : undefined;

  const nextStepBtn = postingRequired ? (
    <Button
      width="full"
      msg="project.stepper.content_review.approve_posting"
      className={styles.btn}
      onClick={handleFinishDeal}
    />
  ) : undefined;

  return (
    <div>
      <Text
        weight="500"
        color={titleColor}
        transform="uppercase"
        msg="project.stepper.content_review.title"
      />
      {isActive && !isContractor && (
        <div className={styles.content}>
          {isSeedingCampaign ? (
            <Text size="sm" msg="project.stepper.content_review.advertiser.seeding_descr" />
          ) : (
            <Text size="sm" msg="project.stepper.content_review.advertiser.descr" />
          )}
          <DealActions
            project={data}
            nextStepBtn={nextStepBtn}
            selectSubmissionFiles={!postingRequired && !isSeedingCampaign}
            finishDealMsg={finishDealMsg}
            finishDealCallback={handleFinishDeal}
            additionalElement={additionalElement}
          />
          {isRevisionEnabled && (
            <Text
              size="sm"
              align="center"
              msg="project.stepper.content_review.request_revision.disclaimer"
              className={styles.disclaimer}
            />
          )}
        </div>
      )}
      <RequestContentRevision projectId={projectId} />
    </div>
  );
};

export default ContentReviewStep;

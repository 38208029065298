import { RUB } from 'Constants/general';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import { NullableType, UnknownObjectType } from 'Types/common';

const escapeDictionary: Record<string, string> = {
  '<': '&lt;',
  '>': '&gt;',
  '&': '&amp;',
  '"': '&#x27;',
};

type NullableStringType = NullableType<string>;

export const createTextWithLinks = (str: NullableStringType) => {
  if (!str) return null;
  const exp = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return str.replace(exp, "<a href='$1' class='link' target='_blank'>$1</a>");
};

export const escapeText = (str: NullableStringType): string | null => {
  if (!str) {
    return null;
  }
  return str.replace(new RegExp(`[${Object.keys(escapeDictionary).join('')}]`, 'g'), (match) => {
    return escapeDictionary[match];
  });
};

export const splitLocation = (location: string) => {
  const list = location.split(',');
  if (list.length === 1) return { main: list[0], additional: '' };
  const additional = `,${list.splice(1, list.length)}`;

  return { main: list[0], additional };
};

export const imgFromUrl = (url: string) => {
  const list = url.split('.');
  const shiftList = list.slice(0, list.length - 1);

  return `${shiftList.join('.')}.jpg`;
};

export const compareObjects = (obj1: UnknownObjectType, obj2: UnknownObjectType) => {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);

  return str1 === str2;
};

export const capitalizeText = (text: string) => {
  const newText = text.toLowerCase();

  return newText[0].toUpperCase() + newText.slice(1);
};

export const createPlanName = (planId: string) => {
  const planValue = planId.split('-');
  const name = planValue[planValue.length - 1];

  return capitalizeText(name);
};

export const createPlanMsg = (planId: string) => {
  const planValue = planId.toLowerCase();

  return `plan.name.${planValue}`;
};

export const cutStringWithExt = (str: string, count: number) => {
  if (!str) return '';
  if (str.length <= count) return str;
  const arr = str.split('.');
  if (arr.length === 1) {
    return arr[0].slice(0, count) + '...';
  }
  const nameArr = arr.slice(0, arr.length - 1);
  const name = nameArr.join('.');
  let shortName = name;
  if (count < name.length) {
    shortName = `${name.slice(0, count - 6)}...${name.slice(name.length - 3)}`;
  }

  return shortName + '.' + arr[arr.length - 1];
};

export const getOuterLink = (url: NullableStringType) => {
  if (!url) return url;

  if (url.startsWith('https://') || url.startsWith('http://')) return url;

  return `http://${url}`;
};

export const getYoutubeVideoCode = (url: NullableStringType) => {
  if (!url) return false;
  const search = url.split('?')[1];
  if (!search) return false;
  const data = getQueryLocationParams(search);
  const { v } = data;

  return v?.toString();
};

export const getCurrencySymbol = (currency?: string | null) => {
  if (!currency) return '';
  return currency === RUB ? '₽' : '$';
};

export const hiddenEmail = (email: string) => {
  const list = email.split('@');

  return `${list[0].charAt(0)}****@${list[1]}`;
};

type BooleanValueType = (value?: string) => boolean | undefined;
export const getBooleanValue: BooleanValueType = (value) => {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }

  return undefined;
};

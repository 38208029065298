import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useFragment } from 'react-relay';

import Field from '../../../components/Field/Field';

import styles from './References.pcss';
import Reference from './Reference/Reference';
import ReferenceLoader from './ReferenceLoader/ReferenceLoader';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import { amplitude } from 'Helpers/amplitude';
import { addReferenceToQueue, removeReferenceToQueue } from 'Actions/References.Actions';
import UploadFile from 'Components/UploadFile';
import { References_briefCreative$key } from 'GraphTypes/References_briefCreative.graphql';

interface Props {
  className?: string;
  campaignId: string;
  briefCreative: References_briefCreative$key;
}

// TODO: Refactor References block

const References: React.FC<Props> = (props) => {
  const { className, campaignId, briefCreative } = props;

  const { addNewReference } = useCampaignActions();

  const data = useFragment(
    graphql`
      fragment References_briefCreative on BriefCreative {
        id
        references(first: 2147483647) @connection(key: "Creative_references", filters: []) {
          edges {
            node {
              id
              ...Reference_item
            }
          }
        }
      }
    `,
    briefCreative
  );

  const { references, id } = data;

  const dispatch = useDispatch();
  const generalQueue = useSelector((state) => state.root.references.queue); // Queue of the references from all creatives in a brief
  const [queue, setQueue] = useState([]);

  // const blockerFn = useCallback(
  //   tx => {
  //     const isNotEmptyQueue = !!(generalQueue.length > 0);
  //     if (isNotEmptyQueue) {
  //       dispatch(setModal(modalName.EXIT_CONFIRMATION, {
  //         location,
  //         modalCustomContent: modalExitConfirmationContent,
  //         actionLeaveCallback: () => { dispatch(clearAllReferenceToQueue()); },
  //         titles: {
  //           popupTitle: { msg: "exit_confirmation_modal.brief.title" },
  //           buttonLeaveTitle: { msg: "exit_confirmation_modal.brief.btn.leave" },
  //           buttonActionTitle: { msg: "exit_confirmation_modal.brief.btn.action" },
  //         }
  //       }));
  //     }
  //     return;
  //   },
  //   [generalQueue]
  // );

  // useBlocker(blockerFn, true);

  // const modalExitConfirmationContent = () => {
  //   return (
  //     <Text
  //       align="center"
  //       msg="exit_confirmation_modal.brief.descr"
  //     />
  //   );
  // };

  const onImageUploaded = (file) => {
    const hasSameFile = queue.findIndex(
      (item) => item.name === file.name && item.size === file.size
    );
    if (hasSameFile !== -1) {
      alert('Duplicate file found');
      return;
    }
    setQueue([...queue, file]);

    const addNewReferenceCallback = () => {
      setQueue(queue.filter((item) => item !== file));
      dispatch(removeReferenceToQueue({ file }));
    };

    addNewReference({ file, briefCreativeId: id, onSuccess: addNewReferenceCallback });

    dispatch(addReferenceToQueue({ file }));

    amplitude.sendEvent({
      id: '86',
      category: 'brief',
      name: 'creative_assets_references_upload',
      param: { campaignId },
    });
  };

  const fileUploader = (
    <li>
      <div className={styles.uploadFile}>
        <UploadFile
          name="reference"
          result="upload"
          types="*"
          onImageUploaded={onImageUploaded}
          loading={generalQueue.length > 0}
        />
      </div>
    </li>
  );

  return (
    <Field
      title="brief_template.creative_asset.references.title"
      description="brief_template.creative_asset.references.descr"
      isOptional={true}
      className={className}
    >
      <div>
        <ul className={styles.content}>
          {fileUploader}
          {references?.edges?.map((item) => {
            if (!item?.node) return null;

            const { node } = item;

            return (
              <li key={node.id}>
                <Reference item={node} briefCreativeId={id} />
              </li>
            );
          })}
          {queue.map((item, idx) => {
            return (
              <li key={item.name + idx}>
                <ReferenceLoader file={item} />
              </li>
            );
          })}
        </ul>
      </div>
    </Field>
  );
};

export default References;

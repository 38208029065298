import React from 'react';

import { SearchParamsQuery$data } from 'GraphTypes/SearchParamsQuery.graphql';
import { ParamsKitElementType } from 'AdvertiserPage/Brief/BriefTemplate/BriefTemplateContent/CreatorsDetails/SearchParams/data';
import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';
import { RadioDropdownItemType } from 'Components/ui/RadioDropdown/RadioDropdown';
import { CreatorFilterQuery$data } from 'GraphTypes/CreatorFilterQuery.graphql';

export const convertFromKitToSelectOptions = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  paramsKit: ParamsKitElementType,
  filterName: keyof ParamsKitElementType,
  firstPopularCount?: number
): CheckboxItemType[] => {
  if (!currentState) {
    return [];
  }
  const currentFilterState = currentState[filterName];
  const filterKit = paramsKit[filterName];
  if (!currentFilterState) {
    if (!filterKit) {
      return [];
    }
    return filterKit?.map((item, index) => ({
      ...item,
      isChecked: false,
      label: item?.name,
      isPopular: firstPopularCount ? index < firstPopularCount : false,
    }));
  }
  const filterCheckedVariants = currentFilterState.reduce((acc, item) => {
    acc[item?.id] = true;
    return acc;
  }, {});
  return filterKit?.map((item, index) => ({
    ...item,
    isChecked: !!filterCheckedVariants[item?.id],
    label: item?.name,
    isPopular: firstPopularCount ? index < firstPopularCount : false,
  }));
};

export const convertFromKitToRadioOptions = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  paramsKit: ParamsKitElementType,
  filterName: keyof ParamsKitElementType,
  withAny?: boolean
): RadioDropdownItemType[] => {
  if (!currentState) {
    return [];
  }
  const currentFilterState = currentState[filterName];
  const filterKit = paramsKit[filterName];
  if (!filterKit) {
    return [];
  }
  if (!currentFilterState) {
    const tempFilterKit = filterKit.map((item) => ({
      ...item,
      isChecked: false,
      value: item?.name,
    }));
    return withAny
      ? [{ value: 'Any', id: null, isChecked: !currentFilterState }, ...tempFilterKit]
      : tempFilterKit;
  }
  const tempFilterKit = filterKit.map((item) => ({
    ...item,
    isChecked: item.id === currentFilterState.id,
    value: item?.name,
  }));
  return withAny ? [{ value: 'Any', id: null, isChecked: false }, ...tempFilterKit] : tempFilterKit;
};

export const convertFromKitToRadioPercentageOptions = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  paramsKit: ParamsKitElementType,
  filterName: keyof ParamsKitElementType
): RadioDropdownItemType[] => {
  const currentFilterState = currentState[filterName];
  const filterKit = paramsKit[filterName];
  if (!currentFilterState) {
    return filterKit.map((item) => ({ ...item, value: item.name, isChecked: false }));
  }
  return filterKit.map((item) => ({
    ...item,
    value: item.name,
    isChecked: item.id === currentFilterState,
  }));
};

export const convertFromCitiesToSelectOptions = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  cities: CreatorFilterQuery$data['cities'],
  filterName: keyof ParamsKitElementType
): CheckboxItemType[] => {
  const currentFilterState = currentState[filterName];

  if (!cities?.edges) {
    return [];
  }
  if (!currentFilterState) {
    return cities?.edges?.map((item) => ({
      ...item?.node,
      isChecked: false,
      label: item?.node?.name,
    }));
  }
  const filterCheckedVariants = currentFilterState.reduce((acc, item) => {
    acc[item?.id] = true;
    return acc;
  }, {});
  return cities?.edges?.map((item) => ({
    ...item?.node,
    isChecked: filterCheckedVariants[item.node.id],
    label: item?.node?.name,
  }));
};

export const convertFromBooleanToSelectOptions = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  paramsKit: ParamsKitElementType,
  filterName: keyof ParamsKitElementType
): CheckboxItemType[] => {
  const currentFilterState = currentState[filterName];
  const filterKit = paramsKit[filterName];
  return filterKit.map((item) => ({
    ...item,
    labelMsg: item?.textData?.textData?.msg,
    isChecked: currentFilterState,
  }));
};

export const convertSelectOptionsToData = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  filterName: keyof ParamsKitElementType,
  paramsKit: ParamsKitElementType,
  newItemId: string
) => {
  const currentFilterState = currentState[filterName];
  const newItem = paramsKit[filterName].filter((item) => item.id === newItemId)[0];
  const hasChecked = currentFilterState.some((item) => item.id === newItemId);
  if (!hasChecked) {
    return {
      [filterName]: [...currentFilterState, newItem],
    };
  }
  return { [filterName]: currentFilterState.filter((item) => item.id !== newItem.id) };
};

export const isSelectFilterDirty = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  filterName: keyof ParamsKitElementType
): boolean => {
  return currentState[filterName]?.length > 0;
};

export const isRadioDirty = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  filterName: keyof ParamsKitElementType
) => !!currentState[filterName];

export const isPrimitiveDirty = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  filterName: keyof ParamsKitElementType
) => !!currentState[filterName];

export const convertRadioOptionsToData = (
  filterName: keyof ParamsKitElementType,
  paramsKit: ParamsKitElementType,
  newItemId: string | null
) => {
  return {
    [filterName]: newItemId
      ? paramsKit[filterName].filter((item) => item.id === newItemId)[0]
      : null,
  };
};

export const convertRadioPercentOptionsToData = (
  filterName: keyof ParamsKitElementType,
  newItemId: string
) => {
  return {
    [filterName]: newItemId,
  };
};

export const convertPrimitiveToData = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  filterName: keyof ParamsKitElementType
): string | number | undefined => currentState[filterName];

export const convertInputToData = (
  newValue: number | string,
  filterName: keyof ParamsKitElementType
) => ({ [filterName]: Number(newValue) });

export const convertBooleanToData = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  filterName: keyof ParamsKitElementType
) => {
  return { [filterName]: currentState[filterName] ? null : true };
};

export const validatePercentInput = (value: number) => value <= 100;

export const convertToCustomListSelectOptions = (
  currentState: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  paramsKit: ParamsKitElementType,
  icon?: React.ReactNode
): CheckboxItemType[] => {
  const arraySelectOptions = convertFromKitToSelectOptions(currentState, paramsKit, 'customLists');
  const booleanSelectOption = convertFromBooleanToSelectOptions(
    currentState,
    paramsKit,
    'favorite'
  );
  booleanSelectOption[0]?.icon = icon;
  return [...booleanSelectOption, ...arraySelectOptions];
};

import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './Plan.pcss';

import { SubscriptionInterval as SubscriptionIntervalType } from 'Types/enums';
import Text from 'Components/ui/Text/Text';
import Toggle from 'Components/ui/Toggle/Toggle';

interface Props {
  interval: SubscriptionIntervalType;
  onPlanIntervalChanged: (interval: SubscriptionIntervalType) => void;
}

const PlanIntervalSwitch: React.FC<Props> = (props) => {
  const { interval, onPlanIntervalChanged } = props;

  const [isAnnually, setAnnuallyInterval] = useState(interval === SubscriptionIntervalType.YEARLY);

  const handleChangeInterval = (value: boolean) => {
    setAnnuallyInterval(value);
    onPlanIntervalChanged(
      isAnnually ? SubscriptionIntervalType.TRIMONTHLY : SubscriptionIntervalType.YEARLY
    );
  };

  return (
    <div className={styles.intervalSwitchContainer}>
      <Text
        type="md"
        msg="billing_plans.plans.pay_quarterly"
        className={classNames({ [styles.activeItem]: !isAnnually })}
      />
      <Toggle on={isAnnually} onChange={handleChangeInterval} bgColor="sand" />
      <Text
        type="md"
        msg="billing_plans.plans.pay_annually"
        className={classNames({ [styles.activeItem]: isAnnually })}
      />
    </div>
  );
};

export default PlanIntervalSwitch;

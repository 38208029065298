import type { CurrentUserData } from 'Types/reducers';
import { UPDATE_CURRENT_USER_DATA, CLEAR_CURRENT_USER_DATA } from 'Constants';

const initialState: CurrentUserData = {
  planId: null,
  id: null,
};

const currentUserData = (state = initialState, action: ActionProps) => {
  const { type } = action;

  switch (type) {

    case UPDATE_CURRENT_USER_DATA: {
      const { payload } = action;
      return {
        ...state,
        planId: payload.planId,
        id: payload.id,
      };
    }

    case CLEAR_CURRENT_USER_DATA: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default currentUserData;

type ActionType = typeof UPDATE_CURRENT_USER_DATA | typeof CLEAR_CURRENT_USER_DATA;
type ActionMap = {
  [UPDATE_CURRENT_USER_DATA]: {
    type: typeof UPDATE_CURRENT_USER_DATA;
    payload: { planId: string; id: string };
  }
  [CLEAR_CURRENT_USER_DATA]: {
    type: typeof CLEAR_CURRENT_USER_DATA;
  }
}
type ActionProps = { type: ActionType } extends { type: infer U }
  ? U extends typeof UPDATE_CURRENT_USER_DATA
    ? ActionMap[typeof UPDATE_CURRENT_USER_DATA]
    : U extends typeof CLEAR_CURRENT_USER_DATA
      ? ActionMap[typeof CLEAR_CURRENT_USER_DATA]
      : never
  : never;

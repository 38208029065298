import React, { useState } from 'react';
import { BlockerFunction, useBlocker } from 'react-router-dom';

import styles from './LeaveWithoutPurchaseDrawer.pcss';

import { amplitude } from 'Helpers/amplitude';
import ls from 'Util/localStorage';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { BOOK_A_DEMO_LINK_EN } from 'Constants/general';

const LS_BIILING_PAGE_CLOSE = 'billing.plan_banner.close';

interface Props {
  purchaseInProgress?: boolean;
}

const LeaveWithoutPurchaseDrawer: React.FC<Props> = (props) => {
  const { purchaseInProgress } = props;
  const closedForever = !!ls.get(LS_BIILING_PAGE_CLOSE);

  const [visible, setVisible] = useState(false);
  const [canLeave, setCanLeave] = useState(closedForever);

  const blockerFn: BlockerFunction = () => {
    if (!canLeave && !purchaseInProgress) {
      setCanLeave(true);
      setVisible(true);
      return true;
    }
    return false;
  };

  useBlocker(blockerFn);

  if (closedForever || !visible) return null;

  const handleCloseButtonClick = () => {
    setVisible(false);
    setCanLeave(true);
    ls.set(LS_BIILING_PAGE_CLOSE, true);
  };

  const handleBookDemoClick = () => {
    amplitude.sendEvent<353>({
      id: '353',
      category: 'billing',
      name: 'book_a_demo_click',
      param: { referral_url: '' },
    });
    setVisible(false);
    setCanLeave(true);
    ls.set(LS_BIILING_PAGE_CLOSE, true);
    window.open(BOOK_A_DEMO_LINK_EN);
  };

  return (
    <div className={styles.content}>
      <Text type="h1" msg="tooltip.plans.title" className={styles.title} />
      <button type="button" className={styles.closeButton} onClick={handleCloseButtonClick}>
        <Icon name="Close-small" className={styles.closeButtonIcon} size={20} />
      </button>
      <Text type="md" msg="tooltip.plans.descr" className={styles.descr} />
      <AlterButton
        msg="tooltip.plans.button"
        className={styles.btn}
        onClick={handleBookDemoClick}
      />
    </div>
  );
};

export default LeaveWithoutPurchaseDrawer;

/**
 * @generated SignedSource<<345f0c2a6dcc7d133cd1f01eab05b5f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type CreatorCharacteristic = "BAD_COMMUNICATION" | "BAD_PRICE_QUALITY_RATIO" | "FAST_WORK" | "FOLLOWING_BRIEF" | "GOOD_COMMUNICATION" | "GOOD_PRICE_QUALITY_RATIO" | "HIGH_CREATIVITY" | "HIGH_TECHNICAL_QUALITY" | "LOW_CREATIVITY" | "LOW_TECHNICAL_QUALITY" | "NOT_FOLLOWING_BRIEF" | "SLOW_WORK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorReviewItem_ratingVote$data = {
  readonly characteristics: ReadonlyArray<CreatorCharacteristic> | null | undefined;
  readonly comment: string | null | undefined;
  readonly createdAt: any;
  readonly id: string;
  readonly project: {
    readonly brand: {
      readonly logo: {
        readonly transformations?: {
          readonly brandLogoUrl: string;
        } | null | undefined;
      } | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined;
    readonly campaign: {
      readonly id: string;
      readonly name: string | null | undefined;
      readonly organization: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly stage: CampaignStage;
    };
    readonly id: string;
  };
  readonly score: number;
  readonly " $fragmentType": "CreatorReviewItem_ratingVote";
};
export type CreatorReviewItem_ratingVote$key = {
  readonly " $data"?: CreatorReviewItem_ratingVote$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorReviewItem_ratingVote">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorReviewItem_ratingVote",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "score",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "characteristics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Brand",
          "kind": "LinkedField",
          "name": "brand",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "logo",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImageTransformation",
                      "kind": "LinkedField",
                      "name": "transformations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "brandLogoUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Image",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RatingVote",
  "abstractKey": null
};
})();

(node as any).hash = "ac811ca1e20d9ead769af1d2379c8863";

export default node;

import React, { useEffect, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import PostsList from '../PostsList/PostsList';

import { TiktokMedias_creator$key } from 'GraphTypes/TiktokMedias_creator.graphql';

interface Props {
  creator: TiktokMedias_creator$key;
  hideTab: (tab: string) => void;
}

const TiktokMedias: React.FC<Props> = (props) => {
  const { creator, hideTab } = props;

  const data = useFragment(
    graphql`
      fragment TiktokMedias_creator on Creator {
        ... on TiktokCreator {
          insightsMedias(first: 30) {
            edges {
              node {
                comments
                thumbnailUrl
                videoViews
                embedUrl
              }
            }
          }
        }
      }
    `,
    creator
  );

  const insightsMedias = data.insightsMedias;
  const insightsMediasCount = useMemo(() => {
    return insightsMedias?.edges?.length;
  }, [insightsMedias?.edges]);

  useEffect(() => {
    if (!insightsMediasCount) {
      hideTab('last_posts');
    }
  }, []);

  const list = useMemo(
    () =>
      insightsMedias?.edges?.map((item) => {
        if (!item?.node) return null;

        const { comments, videoViews, thumbnailUrl, embedUrl } = item.node;

        return {
          commentCount: comments,
          viewCount: videoViews,
          thumbnailUrl,
          mediaType: 'VIDEO',
          link: embedUrl,
        };
      }),
    [insightsMedias?.edges]
  );

  return <PostsList list={list} isTiktok={true} />;
};

export default TiktokMedias;

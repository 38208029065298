export default {
  getItem(key) {
    return this[key];
  },
  setItem(key, value) {
    this[key] = JSON.stringify(value);
  },
  removeItem(key) {
    delete this[key];
  }
};

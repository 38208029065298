import { PropsWithChildren, default as React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

let isInited = false;
let previousLocation = document.referrer;
let currentLocation = '';

const useHistoryReferrer: UseHistoryReferrer = () => {
  const [referrer, setReferrer] = useState<string>(previousLocation);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== currentLocation) {
      previousLocation = isInited ? currentLocation : previousLocation;
      currentLocation = location.pathname;
      isInited = true;
      setReferrer(previousLocation);
    }
  }, [location]);

  return {
    referrer,
  };
};

const HistoryReferrerListener: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  useHistoryReferrer();
  return <>{children}</>;
};

export { useHistoryReferrer, HistoryReferrerListener };

type HookResult = { referrer: string };
type UseHistoryReferrer = () => HookResult;

/**
 * @generated SignedSource<<f86ccefc069db5ce8ee3eae8d3bc4934>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatorsListContainer_campaign$data = {
  readonly projects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly creator: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"CreatorCard_project">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number;
  };
  readonly " $fragmentType": "CreatorsListContainer_campaign";
};
export type CreatorsListContainer_campaign$key = {
  readonly " $data"?: CreatorsListContainer_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorsListContainer_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "archived"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "badges"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "createdByAdmin"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "draftDeadlineIntervals"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "invitedByOrganizationMember"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "outreach"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "publicationDeadlineIntervals"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "screeningQuestionIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shortlisted"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "stages"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "textQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "withPreviousCollaborations"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "projects"
        ]
      }
    ]
  },
  "name": "CreatorsListContainer_campaign",
  "selections": [
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "__Projects_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Project",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "creator",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "organizationId",
                      "variableName": "organizationId"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "CreatorCard_project"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "8515d2e5b2db1a1e1aa8ddd8f4005189";

export default node;

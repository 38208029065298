import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeElements,
  loadStripe,
  SetupIntent,
  PaymentIntent,
  StripeError,
} from '@stripe/stripe-js';
import classnames from 'classnames';

import styles from './StripePaymentElement.pcss';
import CheckoutForm from './CheckoutForm/CheckoutForm';

import colors from 'Styles/vars/colors.css';
import Spinner from 'Atoms/Spinner/Spinner';

const STRIPE_KEY = process.env.STRIPE_KEY || '';
const stripePromise = loadStripe(STRIPE_KEY);

const appearance = {
  variables: {
    spacingGridRow: '20px',
    spacingGridColumn: '20px',
  },
  rules: {
    '.Label': {
      marginBottom: '8px',
      fontSize: '12px',
      color: colors.colorGrayDog,
    },
    '.Input': {
      fontSize: '14px',
    },
    '.Input::placeholder': {
      fontSize: '14px',
      color: colors.colorGrayDog,
    },
  },
};

interface Props {
  clientSecret: string;
  confirmPayment?: boolean; // Indicates whether we should perform "confirmPayment" or "confirmSetup"
  subscribeImmediately?: boolean;
  className?: string;
  submitBtnElement?: JSX.Element;
  onBackBtnClick?: () => void;
  onSuccess: (data: PaymentIntent | SetupIntent, subscribeImmediately?: boolean) => void;
  onFail: (error: Error | StripeError) => void;
}

const StripePaymentElement: React.FC<Props> = (props) => {
  const {
    clientSecret,
    confirmPayment,
    subscribeImmediately,
    className,
    onBackBtnClick,
    onSuccess,
    onFail,
    submitBtnElement,
  } = props;

  const [ready, setReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const options = {
    clientSecret,
    theme: 'stripe',
    appearance,
    wallets: {
      applePay: 'never',
      googlePay: 'never',
    },
    fonts: [
      {
        family: 'Aeroport',
        cssSrc: 'https://fonts.googleapis.com/css?family=Ubuntu:300',
      },
    ],
  };

  const handlePaymentElementReady = () => setReady(true);

  const handleError = (error: StripeError) => {
    setLoading(false);

    onFail(error);
  };

  const handleSubmit = async (stripe: Stripe, elements: StripeElements) => {
    setLoading(true);

    const url = new URL(location.pathname, location.href).href;

    // const billingDetails = {
    //   name: cardName ?? null,
    //   address: {
    //     state: cardState ?? null,
    //     city: cardCity ?? null,
    //     line1: cardLine1 ?? null,
    //     country: cardCountry ?? null,
    //     postal_code: cardPostal ?? null,
    //   }
    // };

    const options = {
      elements: elements,
      confirmParams: {
        return_url: url,
        // payment_method_data: { // Use this for collecting an additional payment data from the user if possible
        //   billing_details
        // }
      },
      redirect: 'if_required',
    };

    if (confirmPayment) {
      const { error, paymentIntent } = await stripe.confirmPayment({
        ...options,
        redirect: 'if_required',
      });

      if (error) {
        handleError(error);

        return;
      }

      if (paymentIntent) onSuccess(paymentIntent, subscribeImmediately);
    } else {
      const { error, setupIntent } = await stripe.confirmSetup({
        ...options,
        redirect: 'if_required',
      });

      if (error) {
        handleError(error);

        return;
      }

      if (setupIntent) onSuccess(setupIntent, subscribeImmediately);
    }
  };

  return (
    <div className={classnames(styles.root, className)}>
      {!ready && <Spinner style={{ margin: '40px auto' }} />}

      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          onPaymentElementReady={handlePaymentElementReady}
          onBackBtnClick={onBackBtnClick}
          onSubmit={handleSubmit}
          loading={loading}
          submitBtnElement={submitBtnElement}
        />
      </Elements>
    </div>
  );
};

export default StripePaymentElement;

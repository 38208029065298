import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateManualRefundInputMutation,
  CreateManualRefundInput,
  CreateManualRefundInputMutation$data,
} from 'GraphTypes/CreateManualRefundInputMutation.graphql';

const mutation = graphql`
  mutation CreateManualRefundInputMutation($input: CreateManualRefundInput!) {
    createManualRefund(input: $input) {
      organization {
        paymentAccount {
          balance
          currency
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateManualRefundInput,
  resolve?: (response: CreateManualRefundInputMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { amount, campaignId, comment, currency, departmentId, organizationId } = data;

  const variables = {
    input: {
      id,
      amount,
      campaignId,
      comment,
      currency,
      departmentId,
      organizationId,
    },
  };

  commitMutation<CreateManualRefundInputMutation>(environment, {
    mutation,
    variables,
    // updater: store => {
    //   const account = store.get(paymentAccountId);
    //   if (!account) return;
    //   const balanceValue = Number(balance) + amount;
    //   account.setValue(balanceValue, 'balance');
    // },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

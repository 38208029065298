import React, { useState, Suspense } from 'react';
import classNames from 'classnames';

import styles from './Limit.pcss';

import { SubscriptionParams } from 'Page/advertiser/BillingPlans/BillingPlans';
import ConfirmNewPlan from 'Modal/advertiser/ConfirmNewPlan/ConfirmNewPlan';
import Text from 'Components/ui/Text/Text';
import AlterButton, { ButtonIcon } from 'Components/ui/AlterButton/AlterButton';
import ChangeAddonsDrawer from 'Page/advertiser/Billing/SubscriptionLimits/ChangeAddonsDrawer/ChangeAddonsDrawer';
import { IconName } from 'Components/ui/types';
import { AddonSettings } from 'Page/advertiser/BillingPlans/data';
import { SubscriptionLimits_organization$data } from 'GraphTypes/SubscriptionLimits_organization.graphql';

interface Props {
  titleMsg: string;
  addons: AddonSettings[];
  totalCount?: number | null;
  maxCount?: number | null;
  type: 'maxActiveBrands' | 'maxSeats' | 'maxBcaConnections';
  onManageClick: () => void;
  disabledManage?: boolean;
  subscriptionData: SubscriptionLimits_organization$data['subscription'];
  defaultLimit?: number | null;
}

const iconManageMap = {
  maxActiveBrands: 'Planet',
  maxSeats: 'Profile',
  maxBcaConnections: 'Paid-ads',
};

const Limit: React.FC<Props> = (props) => {
  const {
    type,
    addons,
    titleMsg,
    totalCount,
    maxCount,
    onManageClick,
    subscriptionData,
    disabledManage = false,
    defaultLimit,
  } = props;

  const [buyPlanDrawerVisible, setBuyPlanDrawerVisible] = useState(false);
  const [paramsForDrawer, setParamsForDrawer] = useState<SubscriptionParams>();

  const counters =
    totalCount === 0 && maxCount === 0
      ? { text: '0' }
      : {
          msg: 'limit.counters',
          formatValues: {
            totalCount: typeof totalCount === 'number' ? totalCount : '',
            maxCount: typeof maxCount === 'number' ? maxCount : '',
          },
        };

  const handleBuyPlanDrawerClose = () => {
    setBuyPlanDrawerVisible(false);
  };

  const handleParamsChange = (params: SubscriptionParams) => {
    setParamsForDrawer(params);
    setBuyPlanDrawerVisible(true);
  };

  return (
    <div className={styles.root}>
      {maxCount === null ? (
        <div className={styles.title}>
          <Text type="md" msg={titleMsg} />
          <Text type="md" msg="limit.unlimited" />
        </div>
      ) : (
        <div className={styles.title}>
          <Text type="md" msg={titleMsg} />
          <Text
            type="md"
            {...counters}
            className={classNames({
              [styles.error]: totalCount && maxCount && totalCount > maxCount,
            })}
          />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <AlterButton
          msg="billing.limits.manage"
          onClick={onManageClick}
          icon={iconManageMap[type] as ButtonIcon}
          fluid
        />
        <ChangeAddonsDrawer
          icon={iconManageMap[type] as IconName}
          type={type}
          addons={addons}
          disabledManage={disabledManage || maxCount === null}
          defaultValue={maxCount === null ? 0 : maxCount}
          defaultLimit={defaultLimit}
          subscriptionData={subscriptionData}
          onParamsChange={handleParamsChange}
        />
      </div>
      {paramsForDrawer && buyPlanDrawerVisible && (
        <Suspense fallback={null}>
          <ConfirmNewPlan
            {...paramsForDrawer}
            opened={buyPlanDrawerVisible}
            onClose={handleBuyPlanDrawerClose}
          />
        </Suspense>
      )}
    </div>
  );
};

export default Limit;

import React from 'react';
import { useDispatch } from 'react-redux';
import track from 'react-tracking';

import { setModal } from 'Actions/Modal.Actions';
import { modalName } from 'Types/modals';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  organizationId?: string;
  organizationName?: string;
}

const TeammatesInvitation: React.FC<Props> = (props) => {
  const { organizationId, organizationName } = props;

  if (!organizationId) {
    return null;
  }

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setModal(modalName.ADD_TEAMMATE, { organizationId, organizationName }));
  };

  return (
    <AlterButton bordered icon="Group-users" msg="advertisers.add_user" onClick={handleClick} />
  );
};

export default track({ element: 'invite_teammate' })(TeammatesInvitation);

import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './Field.css';

import Text from 'Atoms/Text/Text';
import FieldItem from 'Molecules/Field/Field';
import TextButton from 'Atoms/TextButton/TextButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { FieldType, FieldElementType, TextButtonType, SmartLinkType } from 'Types/common';

interface Props {
  title: string;
  description: string;
  descriptionLinkData?: SmartLinkType & TextButtonType;
  isContentVisible?: boolean;
  isOptional?: boolean;
  className?: string;
  rightTitleContent?: React.ReactNode;
}

const Field: React.FC<PropsWithChildren<FieldType & FieldElementType & Props>> = (props) => {
  const {
    title,
    description,
    descriptionLinkData,
    children,
    isContentVisible = true,
    isOptional,
    className,
    rightTitleContent,
    ...rest
  } = props;

  const getLink = () => {
    if (!descriptionLinkData) return null;

    const { msg, ...rest } = descriptionLinkData;

    return (
      <SmartLink {...rest}>
        <TextButton msg={msg} size="sm" display="inline" />
      </SmartLink>
    );
  };

  return (
    <div className={`${styles.root} ${className}`}>
      {rightTitleContent ? (
        <div className={styles.contentContainerWithRight}>
          <div>
            <div className={styles.titleWrap}>
              <Text weight="500" msg={title} className={styles.title} />
              {isOptional && (
                <Text msg="brief_template.field.otional" className={styles.optional} />
              )}
            </div>
            <div className={styles.descr}>
              <Text size="sm" color="grayDog" msg={description} display="inline" />
              {getLink()}
            </div>
          </div>
          {rightTitleContent}
        </div>
      ) : (
        <>
          <div className={styles.titleWrap}>
            <Text weight="500" msg={title} className={styles.title} />
            {isOptional && <Text msg="brief_template.field.otional" className={styles.optional} />}
          </div>
          <div className={styles.descr}>
            <Text size="sm" color="grayDog" msg={description} display="inline" />
            {getLink()}
          </div>
        </>
      )}
      <div className={classnames(styles.content, { [styles.active]: isContentVisible })}>
        {children || <FieldItem {...rest} title={undefined} />}
      </div>
    </div>
  );
};

export default Field;

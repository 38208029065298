import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './CreatorInfo.pcss';

import { createCountriesList } from 'Util/dataFormatter';
import { getCreatorData } from 'Util/creatorData';
import Text from 'Components/ui/Text/Text';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { CreatorInfo_project$key } from 'GraphTypes/CreatorInfo_project.graphql';
import Tag from 'Components/ui/Tag/Tag';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Image, { ImagePlatform } from 'Components/ui/Image/Image';

interface Props {
  project: CreatorInfo_project$key;
  campaignId: string;
}

const CreatorInfo: React.FC<Props> = (props) => {
  const { project: projectValue } = props;

  const project = useFragment(
    graphql`
      fragment CreatorInfo_project on Project {
        id
        outreach
        invitedByOrganizationMember
        creator {
          profile {
            countries {
              name
            }
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
          id
          username
          type
          ... on YoutubeCreator {
            channel {
              name
            }
          }
          ... on TiktokCreator {
            tiktokUser: user {
              name
            }
          }
          ... on InstagramCreator {
            user {
              fullName
            }
          }
        }
      }
    `,
    projectValue
  );

  const creator = project.creator;
  const invitedByOrganizationMember = project.invitedByOrganizationMember;
  const outreach = project.outreach;
  const countries = creator?.profile?.countries;

  if (!creator) return null;

  const { avaSrc, link, username = '—' } = getCreatorData(creator);

  const countriesList = createCountriesList(countries);

  return (
    <div className={styles.root}>
      <Image
        src={avaSrc}
        size={40}
        type="round"
        platform={creator?.type ? (creator?.type as ImagePlatform) : undefined}
      />
      <div>
        <SmartLink path={link} target="_blank">
          <Text type="md" text={username} className={styles.username} />
        </SmartLink>
        <Text type="md" text={countriesList} className={styles.countries} />
      </div>
      {outreach && (
        <Tooltip id={`outreach_label_${link}`} tooltipMsg={'campaign.outreach.tooltip'} place="top">
          <Tag needMargin={false} msg="campaign.outreach" color="stroke" />
        </Tooltip>
      )}
      {invitedByOrganizationMember && !outreach && (
        <Tag
          needMargin={false}
          color="stroke"
          msg="creator_card.invited"
          icon="target-bullseye"
          iconPosition="left"
        />
      )}
    </div>
  );
};

export default CreatorInfo;

import React, { forwardRef } from 'react';
import classnames from 'classnames';

import styles from './CustomInput.pcss';

import Input, { Props as InputProps } from 'Components/ui/Input/Input';

type Props = Omit<Partial<InputProps>, 'inputRef'>;

const CustomInput: React.FC<Partial<InputProps>> = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { className, value, ...rest } = props as Omit<Props, 'children'>;

    return (
      <Input
        bordered
        {...rest}
        className={classnames(styles.root, className, { [styles.active]: !!value })}
        value={value}
        inputRef={ref}
      />
    );
  }
);

export default CustomInput;

// types

export { Props as CustomInputProps };

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import track from 'react-tracking';
import debounce from 'lodash/debounce';

import styles from './TextInput.css';

import { createNumber, createNumberWithDecimal } from 'Util/numberFormatter';
import { DEBOUNCE_WAIT_TIMEOUT } from 'Constants/general.ts';

@injectIntl
class TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value !== null ? props.value : '',
    };

    this.createValue = this.createValue.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onValueChangeDebounced = debounce(this.onValueChange, DEBOUNCE_WAIT_TIMEOUT);
    this.onValueChange = this.onValueChange.bind(this);
  }

  @track((props, state, [value]) => ({
    event: 'typed',
    payload: {
      value,
    },
  }))
  onValueChange(value) {
    const { name, onChange } = this.props;

    onChange({ [name]: value });
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;

    if (nextProps.value !== value && !nextProps.value) {
      this.setState({ value: '' });
    }
  }

  onChange(e) {
    const { decimal, textQuery } = this.props;
    const { value } = e.target;
    let newFormattedValue, newValue;
    if (textQuery) {
      this.setState({ value });
      this.onValueChangeDebounced(value);

      return;
    }
    if (decimal) {
      newValue = value.replace(/[^0-9][,.]/g, '');
      const formatetdValue = newValue.replace(/[,]/g, '.');
      newFormattedValue = parseFloat(formatetdValue, 10) || null;
      this.setState({ value: value !== null ? newValue : '' });
    } else {
      newValue = value.replace(/[^0-9]/g, '');
      newFormattedValue = parseInt(newValue, 10) || null;
      this.setState({ value: parseInt(newValue, 10) || '' });
    }
    this.onValueChangeDebounced(newFormattedValue);
  }

  createValue(value) {
    const { textQuery } = this.props;
    if (!value) return value;
    if (textQuery) {
      return value;
    }
    if (value.endsWith && (value.endsWith(',') || value.endsWith('.'))) {
      const newValue = createNumberWithDecimal(value.slice(0, value.length - 1));

      return newValue + value.charAt(value.length - 1);
    }

    if (value.endsWith && (value.endsWith(',0') || value.endsWith('.0'))) {
      const newValue = createNumberWithDecimal(value.slice(0, value.length - 2));

      return newValue + value.slice(value.length - 2);
    }

    return createNumberWithDecimal(value);
  }

  render() {
    const { className, intl, placeholderMsg } = this.props;

    const { value } = this.state;

    const placeholderText = placeholderMsg && intl.formatMessage({ id: placeholderMsg });

    const hasValue = value !== null && value !== '';
    const currentValue = hasValue ? this.createValue(value) : '';

    return (
      <div className={`${className} ${styles.root}`}>
        <input
          className={styles.input}
          type="text"
          placeholder={placeholderText}
          value={currentValue}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default track((props) => ({
  element: props.name,
}))(TextInput);

import React, { useCallback, useEffect, useState, Suspense, useContext } from 'react';
import cn from 'classnames';
import { useClientQuery, useQueryLoader } from 'react-relay';
import xor from 'lodash/xor';

import type { FilterValuesState } from '../types';
import { FilterCampaignType, FilterPlatformType } from '../types';

import styles from './PerformanceFilters.pcss';
import { PerformanceFiltersQuery, PerformanceFiltersQueryType } from './PerformanceFilters.Query';
import CampaignFilter, { CampaignStub } from './CampaignFilter/CampaignFilter';

import { amplitude } from 'Helpers/amplitude';
import DatePickerRange from 'Components/ui/DatePicker/DatePickerRange';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { createStartOfNewYorkDay } from 'Util/dateCreator';
import { USD } from 'Constants/general';
import type { Performance_Granularity } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';
import { CampaignFilterQuery$data } from 'GraphTypes/CampaignFilterQuery.graphql';
import { useFiltersData } from 'AdvertiserPage/Performance/PerformanceFilters/utils';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { CampaignUseCase } from 'Types/common';

interface Props {
  filterValues: FilterValuesState;
  className?: string;
  finalOrganizationId?: string;
  onFiltersValueStateChange?: (filterValues: FilterValuesState) => void;
}

const PerformanceFilters: React.FC<Props> = (props) => {
  const { className, finalOrganizationId = '', onFiltersValueStateChange } = props;
  const [filterValues, setFilterValues] = useState<FilterValuesState>(props.filterValues);
  const [organization, setOrganization] = useState<CampaignFilterQuery$data['organization']>(null);

  const [, loadQuery] = useQueryLoader<PerformanceFiltersQueryType>(PerformanceFiltersQuery);
  const response = useClientQuery<PerformanceFiltersQueryType>(PerformanceFiltersQuery, {
    organizationId: finalOrganizationId,
  });
  const filtersData = useFiltersData(response, organization, filterValues);
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const hasNewUseCases = hasFeatureFlag('use_cases_5389');

  useEffect(() => {
    loadQuery({ organizationId: finalOrganizationId });
  }, []);

  useEffect(() => {
    onFiltersValueStateChange?.(filterValues);
  }, [filterValues]);

  useEffect(() => {
    setFilterValues((prev) => {
      const checkedBrand = filtersData.brand.find((item) => item.isChecked);
      if (checkedBrand && prev.brand !== checkedBrand.id) {
        return { ...prev, brand: checkedBrand.id };
      }
      return prev;
    });
  }, [filtersData]);

  const sendEvent = (filterName: string, valueName: string) => {
    amplitude.sendEvent<335>({
      id: '335',
      category: 'perfomance',
      name: 'filters_click',
      param: {
        value_name: valueName,
        filter_name: filterName,
      },
    });
  };

  const handleRangeChange = useCallback<NonNullable<ReactDatePickerProps['onChange']>>((date) => {
    if (!date?.[0] || !date?.[1]) return;
    const from = createStartOfNewYorkDay(date?.[0], USD);
    const to = createStartOfNewYorkDay(date?.[1], USD);
    sendEvent('dateRange', from + to);
    setFilterValues((prev) => ({ ...prev, dateRange: [from, to] }));
  }, []);

  const handleBrandChange = useCallback((id: string) => {
    sendEvent('brand', id);
    setFilterValues((prev) => ({ ...prev, brand: id, campaigns: undefined }));
  }, []);

  const handleCampaignChange = useCallback((id: string) => {
    sendEvent('campaign', id);
    setFilterValues((prev) => {
      const newCampaignsValue = xor(prev.campaigns || [], [id]);
      return { ...prev, campaigns: newCampaignsValue };
    });
  }, []);

  const handlePlatformChange = useCallback((id: FilterPlatformType) => {
    sendEvent('platform', id);
    setFilterValues((prev) => ({ ...prev, platform: id }));
  }, []);

  const handleGranularityChange = useCallback((id: Performance_Granularity) => {
    sendEvent('granularity', id);
    setFilterValues((prev) => ({ ...prev, granularity: id }));
  }, []);

  const handleCampaignTypeChange = useCallback((id: FilterCampaignType) => {
    sendEvent('campaignType', id);
    setFilterValues((prev) => ({ ...prev, campaignType: id }));
  }, []);

  const handleUseCaseChange = useCallback((id: CampaignUseCase) => {
    sendEvent('campaignType', id);
    setFilterValues((prev) => ({ ...prev, useCase: id }));
  }, []);

  const handleCampaignListChange = (organization: CampaignFilterQuery$data['organization']) => {
    setOrganization(organization);
  };

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.filters}>
        <ProgressiveInput
          hideTitle
          type="radio"
          className={styles.progressiveInput}
          inputProps={{
            withSearch: true,
            withCounter: true,
            items: filtersData.brand,
            placeholderMsg: 'performance.dashboard.filters.brand',
            handleClick: handleBrandChange,
            bordered: false,
          }}
          isDirty={!!filterValues.brand}
        />
        <Suspense fallback={<CampaignStub />}>
          <CampaignFilter
            organizationId={finalOrganizationId}
            isDirty={!!filterValues.campaigns?.length}
            brandId={filtersData.brand.find((item) => item.isChecked)?.id}
            campaigns={filtersData.campaigns}
            campaignListChange={handleCampaignListChange}
            onChange={handleCampaignChange}
          />
        </Suspense>
        {!hasNewUseCases ? (
          <ProgressiveInput
            hideTitle
            type="radio"
            className={styles.progressiveInput}
            inputProps={{
              items: filtersData.campaignType,
              placeholderMsg: 'performance.dashboard.filters.campaign_type',
              ignoreValue: FilterCampaignType.ALL_TYPES as string,
              withCounter: true,
              handleClick: handleCampaignTypeChange,
              bordered: false,
            }}
            isDirty={
              filterValues.campaignType &&
              filterValues.campaignType !== FilterCampaignType.ALL_TYPES
            }
          />
        ) : (
          <ProgressiveInput
            hideTitle
            type="radio"
            className={styles.progressiveInput}
            inputProps={{
              items: filtersData.useCase,
              placeholderMsg: 'performance.dashboard.filters.campaign_type',
              ignoreValue: 'ALL_TYPES',
              withCounter: true,
              handleClick: handleUseCaseChange,
              bordered: false,
            }}
            isDirty={filterValues.useCase && filterValues.useCase !== 'ALL_TYPES'}
          />
        )}
        <ProgressiveInput
          hideTitle
          type="radio"
          className={styles.progressiveInput}
          inputProps={{
            items: filtersData.platform,
            placeholderMsg: 'performance.dashboard.filters.platform',
            ignoreValue: FilterPlatformType.ALL_PLATFORMS as string,
            withCounter: true,
            handleClick: handlePlatformChange,
            bordered: false,
          }}
          isDirty={
            filterValues.platform && filterValues.platform !== FilterPlatformType.ALL_PLATFORMS
          }
        />
        <ProgressiveInput
          hideTitle
          type="radio"
          className={styles.progressiveInput}
          inputProps={{
            items: filtersData.granularity,
            placeholderMsg: 'performance.dashboard.filters.granularity',
            withCounter: true,
            handleClick: handleGranularityChange,
            bordered: false,
          }}
          isDirty={!!filterValues.granularity}
        />
        <DatePickerRange
          customInputProps={{
            hideCloseIcon: true,
            className: styles.dateRangeInput,
          }}
          reactDatePickerProps={{
            onChange: handleRangeChange,
            startDate: new Date(filterValues.dateRange[0]),
            endDate: new Date(filterValues.dateRange[1]),
          }}
        />
      </div>
    </div>
  );
};

export default PerformanceFilters;

// types

type DatePickerRangeProps = React.ComponentProps<typeof DatePickerRange>;
type ReactDatePickerProps = NonNullable<DatePickerRangeProps['reactDatePickerProps']>;

export type { Props as PerformanceFiltersProps };

import React, { useState, useContext } from 'react';

import styles from './TransactionComment.pcss';

import updateTransaction from 'Mutations/accounting/UpdateTransaction.Mutation';
import updatePaymentTransaction from 'Mutations/UpdatePaymentTransaction.Mutation';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Textarea from 'Components/ui/Textarea/Textarea';
import Button from 'Components/ui/Button/Button';

interface Props {
  id: string;
  customKey: string;
  comment?: string | null;
  isWithdarwal?: boolean;
  callback?: () => void;
}

const TransactionComment: React.FC<Props> = (props) => {
  const { id, comment: defaultComment, isWithdarwal = false, callback, customKey } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(defaultComment);

  const finalKey = customKey || id;

  const handleCommentDone = () => {
    callback?.();
    setLoading(false);
    closeDrawer(`transaction-comment-${finalKey}`);
  };

  const handleCommentSave = () => {
    setLoading(true);
    if (isWithdarwal) {
      updatePaymentTransaction({ id, adminComment: comment }, handleCommentDone);
    } else {
      updateTransaction({ revenueTransactionId: id, comment }, handleCommentDone);
    }
  };

  const handleCommentChange = (newComment: string) => {
    setComment(newComment.trim());
  };

  const actionType = defaultComment ? 'edit' : 'add';
  const titleMsg = `transaction_comment_modal.${actionType}_title`;
  const btnTitle = `transaction_comment_modal.${actionType}_btn`;

  return (
    <Drawer rootKey={`transaction-comment-${finalKey}`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg={titleMsg} className={styles.title} />
        <div className={styles.content}>
          <Textarea
            value={comment || undefined}
            bordered
            handleChange={handleCommentChange}
            className={styles.input}
          />
        </div>
        <Button
          color="black"
          msg={btnTitle}
          loading={loading}
          disabled={!comment}
          onClick={handleCommentSave}
        />
      </div>
    </Drawer>
  );
};

export default TransactionComment;

import { ControlItem } from '../../components/SegmentedControls/SegmentedControls';

interface SparkAdsDay {
  id: number;
  value: number;
  titleMsg: string;
  titleValues: {
    days: number;
  };
}

const sparkAdsDaysList: SparkAdsDay[] = [
  {
    id: 7,
    value: 7,
    titleMsg: 'brief.spark_ads.up_to',
    titleValues: { days: 7 },
  },
  {
    id: 30,
    value: 30,
    titleMsg: 'brief.spark_ads.up_to',
    titleValues: { days: 30 },
  },
  {
    id: 60,
    value: 60,
    titleMsg: 'brief.spark_ads.up_to',
    titleValues: { days: 60 },
  },
  {
    id: 365,
    value: 365,
    titleMsg: 'brief.spark_ads.up_to',
    titleValues: { days: 365 },
  },
];

export const getSparkAdsDaysList = (hideDisabled?: boolean): ControlItem[] => {
  return [
    ...(!hideDisabled
      ? [
          {
            id: 'disabled',
            value: null,
            titleMsg: 'brief.spark_ads.disabled',
            activeColor: 'red',
          },
        ]
      : []),
    ...sparkAdsDaysList,
  ];
};

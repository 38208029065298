import React, { ChangeEvent, useCallback, useMemo, useState, useContext } from 'react';
import classnames from 'classnames';

import styles from './BrandModal.pcss';

import { useUpload, FileType } from 'Hooks/useUpload';
import updateBrand from 'Mutations/UpdateBrand.Mutation';
import createBrand from 'Mutations/CreateBrand.Mutation';
import IconNew from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import Input from 'Components/ui/Input/Input';
import Textarea from 'Components/ui/Textarea/Textarea';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Spinner from 'Atoms/Spinner/Spinner';
import { lightWebsiteUrl } from 'Util/validate';
import { BrandsQuery$data } from 'GraphTypes/BrandsQuery.graphql';
import { CreateBrandMutation$data } from 'GraphTypes/CreateBrandMutation.graphql';
import { UpdateBrandMutation$data } from 'GraphTypes/UpdateBrandMutation.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  id: string;
  brandId?: string;
  logo?: string | null;
  logoId?: string | null;
  name?: string | null;
  websiteUrl?: string | null;
  categoryId?: string;
  categoryName?: string;
  summary?: string | null;
  onBrandSaved?: (id?: string) => void;
  requiredCategory?: boolean;
  brandCategories: BrandsQuery$data['brandCategories'];
  onClose?: () => {};
}

interface BrandCategory {
  id?: string;
  name?: string;
}

const BrandModal: React.FC<Props> = (props) => {
  const {
    id: drawerId,
    brandId,
    brandCategories,
    requiredCategory,
    onClose,
    logo: deafultLogo,
    logoId,
    name: defaultName,
    websiteUrl: defaultWebsiteUrl,
    categoryId: deafultCategoryId,
    categoryName: deafultCategoryName,
    summary: defaultSummary,
    onBrandSaved,
  } = props;

  const { uploadFile } = useUpload();

  const { closeDrawer } = useContext(DrawerContext);

  const isEditing = !!brandId;

  const localePrefix = isEditing ? 'edit' : 'new';

  const [name, setName] = useState(defaultName || '');
  const [websiteUrl, setLink] = useState(defaultWebsiteUrl || '');
  const [summary, setSummary] = useState(defaultSummary || '');
  const [category, setCategory] = useState<BrandCategory | undefined | null>({
    id: deafultCategoryId,
    name: deafultCategoryName,
  });
  const [logo, setLogo] = useState({
    id: logoId,
    src: deafultLogo,
  });
  const [loadingLogo, setLogoLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState<ErrorList>({});
  const [showErrors, setShowErrors] = useState(false);

  const value = useMemo(() => {
    return {
      name,
      websiteUrl,
      summary,
      logoId: logo.id,
      categoryId: category?.id,
    };
  }, [name, websiteUrl, summary, logo, category]);

  const isValid = useMemo(() => {
    const errors = Object.keys(value).reduce((err: ErrorList, valKey: BrandFields) => {
      const tmpErrList = { ...err };
      if (!value[valKey]) {
        tmpErrList[valKey] = true;
      }
      if (valKey === 'logoId') {
        if (!value.logoId) {
          tmpErrList[valKey] = true;
        }
      }
      if (valKey === 'categoryId') {
        if (!value.categoryId) {
          tmpErrList[valKey] = true;
        }
      }
      if (valKey === 'websiteUrl') {
        if (!lightWebsiteUrl(value.websiteUrl)) {
          tmpErrList[valKey] = true;
        }
      }

      return tmpErrList;
    }, {});

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }

    setError({});
    return true;
  }, [value]);

  const handleComplete = () => {
    setLoading(false);
    onClose?.();
    closeDrawer(`edit-brand-modal-${drawerId}`);
  };

  const handleBrandCreate = (data: CreateBrandMutation$data) => {
    onBrandSaved?.(data.createBrand?.brand.id);
    handleComplete();
  };

  const handleBrandUpdate = (data: UpdateBrandMutation$data) => {
    onBrandSaved?.(data.updateBrand?.brand.id);
    handleComplete();
  };

  const handleSaveClick = useCallback(() => {
    if (!isValid) return;
    setLoading(true);
    if (brandId) {
      updateBrand({ ...value, id: brandId, active: true }, handleBrandUpdate);
    } else {
      createBrand({ ...value }, handleBrandCreate);
    }
  }, [value]);

  const handleFileCreated = (data: { file: FileType }) => {
    const file = data.file;
    const url = file?.thumbnailUrl;
    if (url) {
      setLogo({
        id: file.id,
        src: url,
      });
    }

    setLogoLoading(false);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    setLogoLoading(true);
    uploadFile({
      params: { file: files[0], category: 'brand_logo' },
      onSuccess: handleFileCreated,
    });

    if (errors.logoId) {
      const tmpErr = { ...errors };
      delete tmpErr.logoId;
      setError(tmpErr);
    }
  };

  const logoClassList = classnames(styles.logoImgContainer, {
    [styles.error]: !!errors.logoId && showErrors,
  });

  const logoContainer = useMemo(() => {
    if (loadingLogo) {
      return <Spinner size="sm" />;
    } else if (!logo?.src) {
      return (
        <>
          <IconNew name="Add-plus-circle" className={styles.addLogoIcon} />
          <Text type="md" msg="brands.dashboard.new.logoButton" className={styles.addLogoText} />
        </>
      );
    }

    return (
      <>
        <IconNew name="Edit" className={styles.editLogoIcon} />
        <div style={{ backgroundImage: `url(${logo.src})` }} className={styles.logoImg} />
      </>
    );
  }, [loadingLogo, logo?.src]);

  const dropdownLabel = category?.name
    ? { text: category.name }
    : { msg: 'brands.dashboard.edit.placeholder.category' };

  const categoriesDropdown = (
    <Dropdown
      value={<Text type="md" {...dropdownLabel} />}
      className={classnames(styles.dropdown, { [styles.error]: !!errors.categoryId && showErrors })}
    >
      <DropdownGroup className={styles.dropdownGroup}>
        <div>
          {brandCategories?.map((item) => {
            if (!item) return null;

            const isActive = item.id === category?.id ? 'Check' : null;

            const handleCategoryClick = () => {
              setCategory({ ...item });
            };

            return (
              <DropdownGroupItem key={item.id} handleClick={handleCategoryClick}>
                <AlterButton
                  msg={item.name}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );

  const handleSaveWrapClick = () => {
    setShowErrors(true);
  };

  const handleClose = () => {
    setShowErrors(false);
  };

  return (
    <Drawer
      rootKey={`edit-brand-modal-${drawerId}`}
      className={styles.drawer}
      onClose={handleClose}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.brandData}>
          <div>
            <div className={styles.header}>
              <Text type="d2" msg={`brands.dashboard.${localePrefix}.title`} />
              {requiredCategory && (
                <Text
                  type="md"
                  msg="brands.dashboard.edit.category.required"
                  className={styles.errorText}
                />
              )}
            </div>
            <div className={styles.form}>
              <div className={styles.logoContainer}>
                <label htmlFor="logo" className={logoClassList}>
                  {logoContainer}
                </label>
                <input
                  id="logo"
                  className={styles.fileUpload}
                  type="file"
                  multiple={false}
                  accept="image/*"
                  onChange={handleFileUpload}
                />
                <Text
                  type="md"
                  msg={`brands.dashboard.${localePrefix}.logoDescription`}
                  className={styles.logoDescription}
                />
              </div>
              <Input
                labelMsg={`brands.dashboard.${localePrefix}.placeholder.name`}
                placeholderMsg={`brands.dashboard.${localePrefix}.placeholder.name`}
                value={name}
                handleChange={setName}
                bordered
                className={styles.formInput}
                error={!!errors.name && showErrors}
              />
              <Input
                labelMsg={`brands.dashboard.${localePrefix}.placeholder.site`}
                placeholderMsg={`brands.dashboard.${localePrefix}.placeholder.site`}
                value={websiteUrl}
                handleChange={setLink}
                bordered
                className={styles.formInput}
                error={!!errors.websiteUrl && showErrors}
              />
              {categoriesDropdown}
              <Textarea
                labelMsg={`brands.dashboard.${localePrefix}.placeholder.description`}
                placeholderMsg={`brands.dashboard.${localePrefix}.placeholder.description`}
                value={summary}
                handleChange={setSummary}
                bordered
                error={!!errors.summary && showErrors}
                maxLength={500}
                withCounter
                className={styles.summary}
              />
            </div>
          </div>
        </div>
        <div onClick={handleSaveWrapClick}>
          <Button
            color="black"
            msg={`brands.dashboard.${localePrefix}.button`}
            disabled={!isValid}
            loading={loading}
            onClick={handleSaveClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default BrandModal;

export type EditableBrandProps = {
  name: string;
  websiteUrl: string;
  summary: string;
  logoId: string;
  categoryId: string;
};
type BrandFields = keyof EditableBrandProps;
type ErrorList = {
  [field in BrandFields]?: boolean;
};

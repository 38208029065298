import React, { useState } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';

import styles from './AdminAdvertisers.css';
import AdvertisersList from './AdvertisersList/AdvertisersList';
import AdminAdvertiserFilters from './AdminAdvertiserFilters/AdminAdvertiserFilters';

import environment from 'Api/Environment';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';

const AdminAdvertisersQuery = graphql`
  query AdminAdvertisersQuery(
    $textQuery: String
    $paying: Boolean
    $planIds: [PlanId!]
    $currencies: [Currency!]
  ) {
    ...AdvertisersList_organizations
      @arguments(textQuery: $textQuery, paying: $paying, planIds: $planIds, currencies: $currencies)
  }
`;

const defaultState = {
  paying: null,
  planIds: [],
  currencies: [],
};

const AdminAdvertisers = (props) => {
  const { planIds } = props;
  const [filters, setFilters] = useState(defaultState);

  const handleParamsClear = () => {
    setFilters(defaultState);
  };

  const createData = () => {
    const { paying, planIds, currencies } = filters;

    return {
      ...filters,
      paying,
      planIds,
      currencies,
    };
  };

  const formattedParams = createData();

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text type="d2" msg="advertisers.title" className={styles.title} />
        <AdminAdvertiserFilters
          planIds={planIds}
          filters={filters}
          onFiltersChange={setFilters}
          onFiltersClear={handleParamsClear}
        />
        <QueryRenderer
          environment={environment}
          query={AdminAdvertisersQuery}
          variables={{ ...formattedParams }}
          render={({ props }) => {
            if (props) {
              return <AdvertisersList {...formattedParams} organizations={props} />;
            }

            return <Spinner className={styles.preloader} />;
          }}
        />
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'admin_advertisers',
  },
  { dispatchOnMount: true }
)(AdminAdvertisers);

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './SearchResultContent.pcss';
import SearchResultControls from './SearchResultControls/SearchResultControls';
import SearchResultAccounts from './SearchResultAccounts/SearchResultAccounts';
import CreatorMedias from './SearchResultMedias/SearchResultMedias';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Atoms/Icon/Icon';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { SearchResultContent_result$key } from 'GraphTypes/SearchResultContent_result.graphql';
import { INSTAGRAM } from 'Constants/general';

interface Props {
  result: SearchResultContent_result$key;
  creatorType?: CreatorType | null;
  canUsePaidSocial?: boolean | null;
  isAddedToMediaplan: boolean;
  archivation: boolean;
  isHidden: boolean;
  campaignId: string;
  isAdmin: boolean;
  organizationId?: string;
}

const Content: React.FC<Props> = (props) => {
  const {
    result,
    creatorType,
    campaignId,
    isHidden,
    canUsePaidSocial,
    isAddedToMediaplan,
    archivation,
    isAdmin,
    organizationId,
  } = props;

  const data = useFragment(
    graphql`
      fragment SearchResultContent_result on CreatorSearchResult {
        ...SearchResultAccounts_result
        ...SearchResultControls_result
        viewed
        creator {
          type
          id
          profile {
            acceptsBarter
          }
          ...SearchResultMedias_creator
        }
      }
    `,
    result
  );

  const creator = data?.creator;
  const viewed = data?.viewed;

  const currentCreatorType = creator?.type;
  const acceptsBarter = creator?.profile?.acceptsBarter;

  return (
    <div
      className={classnames(styles.root, {
        [styles.addedToMediaplan]: isAddedToMediaplan && !archivation,
        [styles.isHidden]: isHidden,
        [styles.viewed]: viewed,
      })}
    >
      <CreatorMedias className={styles.medias} creator={creator} />
      <SearchResultControls
        result={data}
        creatorType={creatorType}
        isHidden={isHidden}
        campaignId={campaignId}
        loading={false}
        isAdmin={isAdmin}
        organizationId={organizationId}
      />
      <div className={styles.addition}>
        {!(window as any).isApproval &&
          creatorType === INSTAGRAM &&
          currentCreatorType === INSTAGRAM &&
          canUsePaidSocial && <SearchResultAccounts result={data} />}
        {acceptsBarter && !(window as any).isApproval && (
          <Tooltip
            id="acceptsBarter"
            place="bottom"
            tooltipMsg="browse_creators.ready_for_barter.tooltip"
          >
            <div className={styles.barter}>
              <Icon size="sm" color="white" name="barter" />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Content;

/**
 * @generated SignedSource<<7ac8122f1c61afa8edd82c4e9a07d01f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteCreatorsQuery$variables = {
  inviteSuggestionListId: string;
};
export type InviteCreatorsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InviteSuggestionList_inviteSuggestions">;
};
export type InviteCreatorsQuery = {
  response: InviteCreatorsQuery$data;
  variables: InviteCreatorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inviteSuggestionListId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  (v2/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementRate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "followedByCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteCreatorsQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "inviteSuggestionListId",
            "variableName": "inviteSuggestionListId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "InviteSuggestionList_inviteSuggestions"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteCreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "inviteSuggestionListId"
          }
        ],
        "concreteType": "InviteSuggestionList",
        "kind": "LinkedField",
        "name": "inviteSuggestionList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InviteSuggestionConnection",
            "kind": "LinkedField",
            "name": "inviteSuggestions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InviteSuggestionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InviteSuggestion",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "invited",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "creator",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorOwnership",
                            "kind": "LinkedField",
                            "name": "ownership",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "owner",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CreatorRating",
                                    "kind": "LinkedField",
                                    "name": "rating",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "averageScore",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedProjectsCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "side",
                                "value": "AGENCY"
                              }
                            ],
                            "concreteType": "RatingVoteConnection",
                            "kind": "LinkedField",
                            "name": "ratingVotes",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": "ratingVotes(side:\"AGENCY\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Badge",
                                "kind": "LinkedField",
                                "name": "badges",
                                "plural": true,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GeneralCreatorStatistic",
                                "kind": "LinkedField",
                                "name": "generalStatistic",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "profilePictureFile",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ImageTransformation",
                                        "kind": "LinkedField",
                                        "name": "transformations",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "collageThumbnailUrl",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "Image",
                                    "abstractKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "YoutubeChannel",
                                "kind": "LinkedField",
                                "name": "channel",
                                "plural": false,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "YoutubeCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "tiktokUser",
                                "args": null,
                                "concreteType": "TiktokUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "TiktokCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstagramUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "InstagramCreator",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "inviteSuggestions(first:8)"
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "InviteSuggestions_inviteSuggestions",
            "kind": "LinkedHandle",
            "name": "inviteSuggestions"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65cc018856d517162e7330372cf660e6",
    "id": null,
    "metadata": {},
    "name": "InviteCreatorsQuery",
    "operationKind": "query",
    "text": "query InviteCreatorsQuery(\n  $inviteSuggestionListId: ID!\n) {\n  ...InviteSuggestionList_inviteSuggestions_3tT2QB\n}\n\nfragment InviteCreatorCard_inviteSuggestion on InviteSuggestion {\n  invited\n  creator {\n    __typename\n    id\n    type\n    ownership {\n      owner {\n        rating {\n          averageScore\n          id\n        }\n        id\n      }\n      id\n    }\n    completedProjectsCount\n    ratingVotes(side: AGENCY) {\n      totalCount\n    }\n    profile {\n      badges {\n        name\n        id\n      }\n      generalStatistic {\n        engagementRate\n      }\n      profilePictureFile {\n        __typename\n        url\n        ... on Image {\n          transformations {\n            collageThumbnailUrl\n          }\n        }\n        id\n      }\n      id\n    }\n    ... on YoutubeCreator {\n      channel {\n        name\n        id\n      }\n    }\n    ... on TiktokCreator {\n      tiktokUser: user {\n        name\n        engagementRate\n        followedByCount\n        id\n      }\n    }\n    ... on InstagramCreator {\n      username\n      user {\n        engagementRate\n        followedByCount\n        id\n      }\n    }\n  }\n}\n\nfragment InviteSuggestionList_inviteSuggestions_3tT2QB on Query {\n  inviteSuggestionList(id: $inviteSuggestionListId) {\n    inviteSuggestions(first: 8) {\n      edges {\n        node {\n          id\n          ...InviteCreatorCard_inviteSuggestion\n          __typename\n        }\n        cursor\n      }\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "20b6b0e7ecb97d2acbd17c4704c8e82c";

export default node;

import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import BriefContent from '../BriefContent/BriefContent';

import CampaignBriefQuery from './CampaignBrief.Query';

import { CampaignBriefQuery as QueryType } from 'GraphTypes/CampaignBriefQuery.graphql';

interface Props {
  campaignId: string;
}

const CampaignBrief: React.FC<Props> = (props) => {
  const { campaignId } = props;

  const data = useLazyLoadQuery<QueryType>(CampaignBriefQuery, {
    id: campaignId,
  });

  if (!data) return null;
  const { campaign } = data;
  if (!campaign) return null;
  return <BriefContent campaign={campaign} />;
};

export default CampaignBrief;

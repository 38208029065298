import React from 'react';
import classnames from 'classnames';

import styles from './Toggle.pcss';

import Text from 'Atoms/Text/Text';
import { ToggleType } from 'Types/common';
import modsClasses from 'Util/modsClasses';

const MODS = [
  'labelSide', // Caption side of toggle component, it can be: left / right
];

const Toggle: React.FC<ToggleType> = (props) => {
  const { on, msg, className, disabled, onChange } = props;

  const handleClick = () => {
    onChange?.(!on);
  };

  const classes = modsClasses(MODS, props, styles);
  const classNameList = classnames(className, styles.wrapper, classes);

  return (
    <div className={classNameList}>
      {msg && <Text weight="500" msg={msg} className={styles.title} />}
      <div
        className={classnames(styles.root, { [styles.disabled]: disabled, [styles.on]: on })}
        onClick={disabled ? undefined : handleClick}
      >
        <div className={styles.pointer} />
      </div>
    </div>
  );
};

Toggle.defaultProps = {
  className: styles.wrapper,
  labelSide: 'left',
};

export default Toggle;

import React, { useEffect } from 'react';
import { PrimitiveType } from 'react-intl';

import styles from './Notification.css';

import Text from 'Atoms/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';

interface Props {
  id: string;
  text?: string;
  values?: Record<string, PrimitiveType>;
  btnCaption?: string;
  removeDelay?: number;
  onHideClick: (id: string) => void;
  onBtnClick?: () => void;
}

const DEFAULT_TIME = 5000; // ms

const Notification: React.FC<Props> = ({
  id,
  text,
  btnCaption,
  values,
  removeDelay,
  onHideClick,
  onBtnClick,
}) => {
  const time = removeDelay ? removeDelay : DEFAULT_TIME;

  useEffect(() => {
    // Remove notification from store after timer expires
    setTimeout(() => {
      onHideClick(id);
    }, time);
  }, []);

  const handleBtnClick = () => {
    if (onBtnClick) onBtnClick();

    onHideClick(id);
  };

  const handleCloseClick = () => onHideClick(id);

  return (
    <div key={id} className={styles.item}>
      <div className={styles.content}>
        <Text color="white" msg={text} values={values} className={styles.text} />
        {onBtnClick && (
          <TextButton msg="general.undo" className={styles.cancel} onClick={handleBtnClick} />
        )}
      </div>
    </div>
  );
};

export default Notification;

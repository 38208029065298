/**
 * @generated SignedSource<<18636ff1e2c17641fc833ea5ad1c1a98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BcaRequestPermissionsQuery$variables = Record<PropertyKey, never>;
export type BcaRequestPermissionsQuery$data = {
  readonly facebook: {
    readonly instagramBusinessAccounts: ReadonlyArray<{
      readonly id: string;
      readonly profilePictureUrl: string | null | undefined;
      readonly username: string | null | undefined;
    }>;
  } | null | undefined;
};
export type BcaRequestPermissionsQuery = {
  response: BcaRequestPermissionsQuery$data;
  variables: BcaRequestPermissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Facebook_Query",
    "kind": "LinkedField",
    "name": "facebook",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Facebook_InstagramBusinessAccount",
        "kind": "LinkedField",
        "name": "instagramBusinessAccounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profilePictureUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BcaRequestPermissionsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BcaRequestPermissionsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "00877e731aa6b8e56c868b07461e968a",
    "id": null,
    "metadata": {},
    "name": "BcaRequestPermissionsQuery",
    "operationKind": "query",
    "text": "query BcaRequestPermissionsQuery {\n  facebook {\n    instagramBusinessAccounts {\n      id\n      username\n      profilePictureUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b14af652987cd79df239400f0778bf2e";

export default node;

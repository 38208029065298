import React, { useContext, useState } from 'react';

import { FinishDealWrapperContext } from '../FinishDealWrapperContext';

import styles from './PublicationLinkRequest.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import completeProject from 'Mutations/CompleteProject.Mutation';
import ErrorHandler from 'Util/errorHandler';
import createPublicationRequestMutation from 'Api/mutations/CreatePublicationRequest.Mutation';
import Spinner from 'Atoms/Spinner/Spinner';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  projectId: string;
}

const PublicationLinkRequestDrawer: React.FC<Props> = (props) => {
  const { projectId } = props;

  const finishDealContext = useContext(FinishDealWrapperContext);

  const opened = Boolean(finishDealContext?.needPublicationDrawer);
  const isFreeOrganic = Boolean(finishDealContext?.isFreeOrganic);
  const changeDrawerVisible = finishDealContext?.setNeedPublicationDrawerStatus;

  const { openDrawer } = useContext(DrawerContext);

  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);

  const handleRequestClick = () => {
    setLoading(true);
    setError(false);
    createPublicationRequestMutation({ projectId }, handleActionDone, handleActionFailed);
  };

  const handleActionDone = () => {
    setLoading(false);
    setError(false);
    if (!changeDrawerVisible) {
      ErrorHandler.error('No handler for closing need publication drawer');
      return;
    }
    changeDrawerVisible(false);
  };

  const openRatingPopup = () => {
    setLoading(false);
    setError(false);
    openDrawer(`project-creator-rating-${projectId}`);
  };

  const handleActionFailed = () => {
    setLoading(false);
    setError(true);
  };

  const handleSendPayment = () => {
    setLoading(true);
    setError(false);

    completeProject({ projectId }, openRatingPopup, handleActionFailed);
  };

  return (
    <Drawer
      opened={opened}
      onClose={handleActionDone}
      needCloseButton
      className={styles.root}
      rootKey={`publication-link-request-${projectId}`}
    >
      <div className={styles.content}>
        <Text type="d2" msg="publication_link_request_drawer.title" />
        <Text type="md" msg="publication_link_request_drawer.description" />
      </div>
      <div className={styles.buttonContainer}>
        {hasError && <Text msg="general.error" className={styles.errorText} />}
        {loading ? (
          <Spinner className={styles.loader} />
        ) : (
          <>
            <Button
              color="black"
              fluid
              className={styles.mainBtn}
              msg="publication_link_request_drawer.btn.request"
              onClick={handleRequestClick}
            />
            <Button
              color="white"
              fluid
              className={styles.mainBtn}
              msg={
                isFreeOrganic
                  ? 'publication_link_request_drawer.btn.complete_deal'
                  : 'publication_link_request_drawer.btn.send_payment'
              }
              onClick={handleSendPayment}
            />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default PublicationLinkRequestDrawer;

/**
 * @generated SignedSource<<e0dc53840c5e00b95e4a5c0416aabc32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Accounting_RevenueTransactionType = "ACTIVATION_FEE" | "CREATOR_EXPENSE" | "CUSTOM_SERVICE" | "DEPOSIT" | "FULLY_MANAGED_ZEROING" | "REFERRAL_REWARD" | "REFUND" | "SALES_PEAK_REWARD" | "SUBSCRIPTION" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type Accounting_UpdateTransactionInput = {
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  invoiceUrl?: string | null | undefined;
  revenueTransactionId?: string | null | undefined;
  subscriptionPlanId?: PlanId | null | undefined;
  type?: Accounting_RevenueTransactionType | null | undefined;
  verifiedByAdmin?: boolean | null | undefined;
};
export type UpdateTransactionMutation$variables = {
  input: Accounting_UpdateTransactionInput;
};
export type UpdateTransactionMutation$data = {
  readonly accounting: {
    readonly updateTransaction: {
      readonly clientMutationId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateTransactionMutation = {
  response: UpdateTransactionMutation$data;
  variables: UpdateTransactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Accounting_Mutation",
    "kind": "LinkedField",
    "name": "accounting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Accounting_UpdateTransactionPayload",
        "kind": "LinkedField",
        "name": "updateTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTransactionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTransactionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0aea4b6c1ba160e7ebf41ff1c5326784",
    "id": null,
    "metadata": {},
    "name": "UpdateTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTransactionMutation(\n  $input: Accounting_UpdateTransactionInput!\n) {\n  accounting {\n    updateTransaction(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2cb3eec517a62ad09c641c0cde387839";

export default node;

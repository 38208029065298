import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import { updateActiveBrandsCounters } from './UpdateBrand.Mutation';

import environment from 'Api/Environment';
import {
  CreateBrandMutation,
  CreateBrandMutation$data,
  CreateBrandInput,
} from 'GraphTypes/CreateBrandMutation.graphql';

const mutation = graphql`
  mutation CreateBrandMutation($input: CreateBrandInput!) {
    createBrand(input: $input) {
      brand {
        id
        active
        name
        websiteUrl
        logo {
          ... on Image {
            transformations {
              brandLogoUrl
            }
          }
        }
        category {
          id
          name
        }
        summary
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateBrandInput,
  resolve?: (response: CreateBrandMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();
  const variables = {
    input: {
      id,
      ...data,
    },
  };

  commitMutation<CreateBrandMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const storeData = store.getRoot();
      const brands = ConnectionHandler.getConnection(storeData, 'Brands_brands');
      if (!brands) return;
      const brandExists = brands
        ?.getLinkedRecords('edges')
        ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === id);
      if (brandExists) return;

      const brand = store.getRootField('createBrand').getLinkedRecord('brand');
      const edge = ConnectionHandler.createEdge(store, brands, brand, 'BrandConnection');
      ConnectionHandler.insertEdgeAfter(brands, edge);

      const count = brands.getValue('totalCount');
      if (count || count === 0) {
        brands.setValue(Number(count) + 1, 'totalCount');
      }
      updateActiveBrandsCounters(store, true);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

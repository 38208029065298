import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './ReferBusiness.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import { REFER_A_BUSINESS_URL } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';

interface Props {
  open: boolean;
  onClose?: () => void;
}

const ReferBusiness: React.FC<Props> = (props) => {
  const { onClose, open } = props;
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const handleCloseModal = useCallback(() => {
    onClose?.();
    setOpenState(false);
  }, [onClose]);

  const handleButtonClick = useCallback(() => {
    amplitude.sendEvent({
      id: 243,
      category: 'pageview',
      name: 'dashboard_referral_click_sign_up',
    });
    window.open(REFER_A_BUSINESS_URL, '_blank');
  }, []);

  return (
    <Drawer
      opened={openState}
      onClose={handleCloseModal}
      rootKey={'refer-business'}
      className={styles.drawer}
    >
      <div className={styles.content}>
        <div>
          <Text msg="drawer.refer_business.title" type="d2" className={styles.title} />
          <Text
            msg="drawer.refer_business.how_it_works.title"
            className={cx(styles.description, styles.howItWorksTitle)}
            type="h2"
          />
          <Text
            msg="drawer.refer_business.how_it_works.descr"
            className={styles.description}
            type="md"
          />
        </div>
        <AlterButton
          type="black"
          msg="drawer.refer_business.button"
          className={styles.btn}
          onClick={handleButtonClick}
        />
      </div>
    </Drawer>
  );
};

export { ReferBusiness };

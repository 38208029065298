/**
 * @generated SignedSource<<925c1a88feaf931148011b223653f7ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePaymentTransactionInput = {
  adminComment?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  id: string;
};
export type UpdatePaymentTransactionMutation$variables = {
  input: UpdatePaymentTransactionInput;
};
export type UpdatePaymentTransactionMutation$data = {
  readonly updatePaymentTransaction: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type UpdatePaymentTransactionMutation = {
  response: UpdatePaymentTransactionMutation$data;
  variables: UpdatePaymentTransactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePaymentTransactionPayload",
    "kind": "LinkedField",
    "name": "updatePaymentTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePaymentTransactionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePaymentTransactionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bc765f905e3094094d1f9fa5f95f2b96",
    "id": null,
    "metadata": {},
    "name": "UpdatePaymentTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePaymentTransactionMutation(\n  $input: UpdatePaymentTransactionInput!\n) {\n  updatePaymentTransaction(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "a985539380d61203aacaee32acb25a9b";

export default node;

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import track from 'react-tracking';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import styles from './SearchInput.css';

import IconNew from 'Components/ui/Icon/Icon';
import { DEBOUNCE_WAIT_TIMEOUT } from 'Constants/general.ts';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

@injectIntl
@track({ element: 'search_input' })
class SearchInput extends Component {
  constructor() {
    super();

    this.state = {
      textQuery: null,
      isFocused: false,
    };

    this.onChangeQuery = this.onChangeQuery.bind(this);
    this.onChangeTextQuery = this.onChangeTextQuery.bind(this);
    this.onClearClick = this.onClearClick.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.onChangeDebounced = debounce(this.onChangeTextQuery, DEBOUNCE_WAIT_TIMEOUT);
  }

  componentWillReceiveProps(nextProps) {
    if (!Object.prototype.hasOwnProperty.call(nextProps, 'textQuery')) {
      return;
    }
    const { textQuery } = this.state;

    if (nextProps.textQuery !== textQuery && !nextProps.textQuery) {
      this.onClearClick();
    }
  }

  onChange(event) {
    const { value } = event.target;
    const { onChange } = this.props;

    onChange(value);
    this.onValueChangeDebounced(value);
  }

  @track((props, state, [value]) => ({
    event: 'typed',
    payload: {
      value,
    },
  }))
  onChangeTextQuery(value) {
    const { onChange } = this.props;
    onChange(value);
  }

  onChangeQuery(event) {
    const { value } = event.target;
    this.setState({ textQuery: value }, () => {
      const { textQuery } = this.state;
      this.onChangeDebounced(textQuery);
    });
  }

  onClearClick() {
    this.setState({ textQuery: null });
    const { onChange } = this.props;
    onChange(null);
  }

  onFocus() {
    this.setState({ isFocused: true });
  }

  onBlur() {
    this.setState({ isFocused: false });
  }

  render() {
    const { className, focusedStyle, intl, place, placeholder } = this.props;

    const { textQuery, isFocused } = this.state;

    const placeholderText = placeholder ? intl.formatMessage({ id: placeholder }) : '';

    const hasText = !!textQuery;

    return (
      <div
        className={classnames(className, styles.root, styles[place], {
          [styles.hasText]: hasText,
          [focusedStyle]: isFocused || hasText,
          [styles.active]: isFocused || hasText,
        })}
      >
        <input
          type="text"
          placeholder={placeholderText}
          value={textQuery ? textQuery : ''}
          className={styles.input}
          onChange={this.onChangeQuery}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <IconNew name="Search-loop" className={styles.icon} />
        <AlterButton
          type="white"
          hover={false}
          icon="Close-small"
          onClick={this.onClearClick}
          className={styles.clear}
        />
      </div>
    );
  }
}

export default SearchInput;

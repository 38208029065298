import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import styles from './Content.css';

import { StoreState } from 'Types/reducers';

const Content: React.FC = (props) => {
  const { children } = props;

  const isMobileView = useSelector((state: StoreState) => state.root.ui.mobileView);

  return (
    <main id="content" className={classnames(styles.root, { [styles.isMobileView]: isMobileView })}>
      <Suspense fallback={null}>{children}</Suspense>
    </main>
  );
};

export default Content;

import { graphql, useMutation } from 'react-relay';

import { UnsubscribeFromCampaignMutation } from 'GraphTypes/UnsubscribeFromCampaignMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const UnsubscribeFromCampaignQueryMutation = graphql`
  mutation UnsubscribeFromCampaignMutation($input: UnsubscribeFromCampaignInput!) {
    unsubscribeFromCampaign(input: $input) {
      clientMutationId
    }
  }
`;

export const useUnsubscribeFromCampaignQueryMutation: BaseMutation<
  UnsubscribeFromCampaignMutation
> = (config) => {
  const [unsubscribeFromCampaign, loading] = useMutation<UnsubscribeFromCampaignMutation>(
    UnsubscribeFromCampaignQueryMutation
  );

  const unsubscribeFromCampaignCaller: BaseMutationExecFn<UnsubscribeFromCampaignMutation> = (
    execConfig
  ) => {
    unsubscribeFromCampaign({
      ...config,
      ...execConfig,
      optimisticUpdater: (store) => {
        const campaignId = config?.variables.input.campaignId;
        if (!campaignId) return;
        const campaign = store.get(campaignId);
        campaign?.setValue(false, 'userSubscribed');
      },
      updater: (store) => {
        const campaignId = config?.variables.input.campaignId;
        if (!campaignId) return;
        const campaign = store.get(campaignId);
        campaign?.setValue(false, 'userSubscribed');
      },
    });
  };
  return [unsubscribeFromCampaignCaller, loading];
};

import { commitMutation, graphql } from 'react-relay';
import includes from 'lodash/includes';

import environment from 'Api/Environment';
import {
  UpdateCampaignMutation,
  UpdateCampaignMutation$data,
  UpdateCampaignInput,
} from 'GraphTypes/UpdateCampaignMutation.graphql';

const mutation = graphql`
  mutation UpdateCampaignMutation($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      campaign {
        id
        private
        type
        objective
        postingType
        paymentType
        description
        contentCreationType
        platform
        useCase
        brand {
          id
          logo {
            ... on Image {
              transformations {
                brandLogoUrl
                collageThumbnailUrl
              }
            }
          }
          name
          summary
        }
        name
        cover {
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
        adminComment
        plannedAmount
        searchQuery {
          id
        }
        manager {
          id
          advertiserProfile {
            firstName
            lastName
          }
          email
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateCampaignInput,
  resolve?: (response: UpdateCampaignMutation$data) => void,
  reject?: fnType
) => {
  const campaignName = data.name === '' ? null : data.name;
  const variables = {
    input: {
      ...data,
      name: campaignName,
    },
  };

  commitMutation<UpdateCampaignMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const campaign = store.get(data.id);
      if (!campaign) return;

      const keys = Object.keys(data);

      if (includes(keys, 'brandId') && data.brandId) {
        const brand = store.get(data.brandId);
        if (brand) {
          campaign.setLinkedRecord(brand, 'brand');
        }
      }

      if (includes(keys, 'name')) {
        campaign.setValue(data.name, 'name');
      }

      if (includes(keys, 'type')) {
        campaign.setValue(data.type, 'type');
      }
      if (includes(keys, 'useCase')) {
        campaign.setValue(data.useCase, 'useCase');
      }

      if (includes(keys, 'postingType')) {
        campaign.setValue(data.postingType, 'postingType');
      }

      if (includes(keys, 'platform')) {
        campaign.setValue(data.platform, 'platform');
      }

      if (includes(keys, 'contentCreationType')) {
        campaign.setValue(data.contentCreationType, 'contentCreationType');
      }

      if (includes(keys, 'private')) {
        campaign.setValue(data.private, 'private');
      }

      if (includes(keys, 'objective')) {
        campaign.setValue(data.objective, 'objective');
      }

      if (includes(keys, 'plannedAmount')) {
        campaign.setValue(data.plannedAmount, 'plannedAmount');
      }

      if (includes(keys, 'adminComment')) {
        campaign.setValue(data.adminComment, 'adminComment');
      }

      if (includes(keys, 'paymentType')) {
        campaign.setValue(data.paymentType, 'paymentType');
      }

      if (includes(keys, 'description')) {
        campaign.setValue(data.description, 'description');
      }

      if (includes(keys, 'managerId') && data.managerId) {
        const manager = store.get(data.managerId);
        if (manager) {
          campaign.setLinkedRecord(manager, 'manager');
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  CancelCreatorImportMutation,
  CancelCreatorImportMutation$data,
  CancelCreatorImportInput,
} from 'GraphTypes/CancelCreatorImportMutation.graphql';

const mutation = graphql`
  mutation CancelCreatorImportMutation($input: CancelCreatorImportInput!) {
    cancelCreatorImport(input: $input) {
      creatorImport {
        id
        completed
        importedCreators
        totalCreators
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CancelCreatorImportInput,
  resolve?: (response: CancelCreatorImportMutation$data) => void,
  reject?: fnType
) => {
  const { creatorImportId } = data;

  const variables = {
    input: {
      creatorImportId,
    },
  };

  commitMutation<CancelCreatorImportMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import Tabs from 'Molecules/Tabs/Tabs';

const CUSTOM_OPTION = 'Custom';

type AmountsDataArray = [100, 250, 500, 1000, typeof CUSTOM_OPTION];
type AmountsDataValues = AmountsDataArray[keyof AmountsDataArray];

// const amountsData: AmountsDataArray = [100, 250, 500, 1000, CUSTOM_OPTION];

export const amountsData: TabItems = [
  {
    textData: {
      text: '$100',
    },
    id: '100',
  },
  {
    textData: {
      text: '$250',
    },
    id: '250',
  },
  {
    textData: {
      text: '$500',
    },
    id: '500',
  },
  {
    textData: {
      text: '$1.000',
    },
    id: '1000',
  },
  {
    textData: {
      text: CUSTOM_OPTION,
    },
    id: 'Custom',
  },
];

export { CUSTOM_OPTION };
export type { AmountsDataArray, AmountsDataValues };

type TabsProps = React.ComponentProps<typeof Tabs>;
type TabItems = NonNullable<TabsProps['items']>;

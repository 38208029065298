import { TEXT, CUSTOM_ELEMENT } from 'Constants/general';

export const items = [
  {
    id: 'requestId',
    values: {
      element: {
        value: 'requestId',
      },
    },
    title: 'requestId',
    width: '82px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'account',
    values: {
      element: {
        value: 'account',
      },
    },
    title: 'account',
    width: '144px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'profile',
    values: {
      element: {
        value: 'profile',
      },
    },
    title: 'profile',
    width: '146px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'currency',
    values: {
      text: {
        value: 'currency',
      },
    },
    title: 'currency_short',
    width: '32px',
    element: TEXT,
  },
  {
    id: 'amount',
    values: {
      element: {
        value: 'amount',
      },
    },
    title: 'amount',
    width: '72px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'psp',
    values: {
      element: {
        value: 'psp',
      },
    },
    title: 'psp',
    width: '122px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'country',
    values: {
      text: {
        value: 'country',
      },
    },
    title: 'card_country',
    width: '70px',
    element: TEXT,
  },
  {
    id: 'method',
    values: {
      element: {
        value: 'method',
      },
    },
    title: 'payment_method',
    width: '80px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'status',
    values: {
      element: {
        value: 'status',
      },
    },
    title: 'transfer',
    width: '200px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'comment',
    values: {
      element: {
        value: 'comment',
      },
    },
    width: '24px',
    element: CUSTOM_ELEMENT,
  },
];

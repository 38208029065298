import React, { forwardRef } from 'react';
import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import classnames from 'classnames';

import styles from './CustomHeader.pcss';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

type Props = ReactDatePickerCustomHeaderProps;

const CustomHeader: React.FC<Props> = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { increaseMonth, decreaseMonth, changeMonth, changeYear, date } = props;

  const years = range(1970, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentMonth = months[getMonth(date)];
  const currentYear = getYear(date);

  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.monthYearContainer}>
        <Dropdown value={currentMonth} className={styles.dropdown}>
          <DropdownGroup className={styles.dropdownGroup}>
            <div>
              {months?.map((month) => (
                <DropdownGroupItem
                  key={month}
                  handleClick={() => changeMonth(months.indexOf(month))}
                >
                  <AlterButton
                    text={month}
                    fluid
                    iconPosition={'right'}
                    icon={month === currentMonth ? 'Check' : null}
                  />
                </DropdownGroupItem>
              ))}
            </div>
          </DropdownGroup>
        </Dropdown>

        <Dropdown value={currentYear.toString()} className={styles.dropdown}>
          <DropdownGroup className={classnames(styles.dropdownGroup, styles.reversedDropdownGroup)}>
            <div>
              {years?.map((year) => (
                <DropdownGroupItem key={year} handleClick={() => changeYear(year)}>
                  <AlterButton
                    text={year.toString()}
                    fluid
                    iconPosition={'right'}
                    icon={year === currentYear ? 'Check' : null}
                  />
                </DropdownGroupItem>
              ))}
            </div>
          </DropdownGroup>
        </Dropdown>
      </div>

      <div className={styles.changeMonthContainer}>
        <AlterButton onClick={decreaseMonth} icon={'Arrow-small-left'} />
        <AlterButton onClick={increaseMonth} icon={'Arrow-small-right'} />
      </div>
    </div>
  );
});

export default CustomHeader;

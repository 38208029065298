import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, indexedDBLocalPersistence } from 'firebase/auth';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { setCookie } from 'typescript-cookie';

import type { AppStateType } from '.';

import type { CurrentUserQuery$data } from 'GraphTypes/CurrentUserQuery.graphql';
import { ADVERTISER, CONTRACTOR } from 'Constants/general';

const isProd = process.env.DEPLOY_ENV === 'production';

type IsAlreadyRegisteredUser = (currentUser: CurrentUser | null) => boolean;
const isAlreadyRegisteredUser: IsAlreadyRegisteredUser = (currentUser) => {
  if (!currentUser) return false;
  const userTypes = new Set<CurrentUser['type']>([ADVERTISER, CONTRACTOR]);
  return userTypes.has(currentUser.type);
};

class AuthSessionState {
  private static instance: AuthSessionState;

  private static storageKey = 'Auth.Session.State';

  private constructor() {
    // private constructor
  }

  public static getInstance(): AuthSessionState {
    if (!AuthSessionState.instance) {
      AuthSessionState.instance = new AuthSessionState();
    }
    return AuthSessionState.instance;
  }

  public get(): AppStateType | null {
    const value = sessionStorage.getItem(AuthSessionState.storageKey);
    try {
      return JSON.parse(`${value}`);
    } catch (e) {
      return null;
    }
  }

  public set(appState: AppStateType): void {
    try {
      sessionStorage.setItem(AuthSessionState.storageKey, JSON.stringify(appState));
    } catch (e) {
      // catch
    }
  }

  public clear(): void {
    sessionStorage.removeItem(AuthSessionState.storageKey);
  }
}

type WaitUntilUserInitialized = () => Promise<boolean>;
const waitUntilUserInitialized: WaitUntilUserInitialized = () => {
  return new Promise((resolve) => {
    const intervalId = setInterval(async () => {
      const isUserInitialized = !!firebaseClient.authClient.currentUser;
      if (isUserInitialized) {
        clearInterval(intervalId);
        resolve(true);
      }
    }, 10);
  });
};

const authSessionState = AuthSessionState.getInstance();

type CurrentUser = Pick<NonNullable<CurrentUserQuery$data['currentUser']>, 'type'>;

/* Firebase instance */

class FirebaseAuthClient {
  private instance: FirebaseApp;
  private storage: FirebaseStorage;

  public authClient;

  private config = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: isProd ? 'insense-medias' : 'insense-medias-dev',
    messagingSenderId: process.env.FIREBASE_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: 'G-SBER4BP1J3',
  };

  constructor() {
    this.instance = initializeApp(this.config);
    this.authClient = getAuth(this.instance);
    this.authClient.setPersistence(indexedDBLocalPersistence);
    this.storage = getStorage(this.instance);
  }

  public getInstance() {
    return this.instance;
  }

  public getStorage() {
    return this.storage;
  }

  public async getToken(needRefresh = false) {
    const newToken = await this.authClient.currentUser?.getIdToken(needRefresh);
    if (newToken) {
      const domain = window.location.origin.includes('localhost')
        ? 'localhost'
        : process.env.API_HOST.includes('.pro')
          ? '.insense.pro'
          : '.insense.tech';
      setCookie('access-token', newToken, {
        domain,
        secure: true,
        sameSite: 'strict',
      });
    }
    return newToken;
  }
}

const firebaseClient = new FirebaseAuthClient();

export { authSessionState, waitUntilUserInitialized, isAlreadyRegisteredUser, firebaseClient };

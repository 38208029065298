/**
 * @generated SignedSource<<02327b29c02d674dc002b1b717a10dc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductList_query$data = {
  readonly campaign: {
    readonly shopifyAuthorization: {
      readonly shop: {
        readonly currencyCode: string;
        readonly products: {
          readonly __typename: "Shopify_InvalidAuthorizationError";
          readonly message: string;
        } | {
          readonly __typename: "Shopify_ProductsPayload";
          readonly products: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly featuredImageUrl: string | null | undefined;
                readonly id: string;
                readonly title: string;
                readonly variants: ReadonlyArray<{
                  readonly availableForSale: boolean;
                  readonly displayName: string;
                  readonly id: string;
                  readonly imageUrl: string | null | undefined;
                  readonly inventoryQuantity: number | null | undefined;
                  readonly price: any;
                  readonly title: string;
                }>;
              } | null | undefined;
            } | null | undefined> | null | undefined;
            readonly pageInfo: {
              readonly hasNextPage: boolean;
            };
            readonly totalCount: number;
          };
        } | {
          readonly __typename: "ValidationError";
          readonly message: string;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProductList_query";
};
export type ProductList_query$key = {
  readonly " $data"?: ProductList_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductList_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "campaign",
  "shopifyAuthorization",
  "shop",
  "products",
  "products"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "campaignId"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "textQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ProductListPaginationQuery.graphql')
    }
  },
  "name": "ProductList_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "campaignId"
        }
      ],
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopifyAuthorization",
          "kind": "LinkedField",
          "name": "shopifyAuthorization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Shopify_Shop",
              "kind": "LinkedField",
              "name": "shop",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currencyCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "organizationId",
                      "variableName": "organizationId"
                    },
                    {
                      "kind": "Variable",
                      "name": "textQuery",
                      "variableName": "textQuery"
                    }
                  ],
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "products",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": "products",
                          "args": null,
                          "concreteType": "Shopify_ProductConnection",
                          "kind": "LinkedField",
                          "name": "__ProductList_products_connection",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PageInfo",
                              "kind": "LinkedField",
                              "name": "pageInfo",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "hasNextPage",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "endCursor",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Shopify_ProductEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Shopify_Product",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v2/*: any*/),
                                    (v3/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "featuredImageUrl",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Shopify_ProductVariant",
                                      "kind": "LinkedField",
                                      "name": "variants",
                                      "plural": true,
                                      "selections": [
                                        (v2/*: any*/),
                                        (v3/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "price",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "imageUrl",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "displayName",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "availableForSale",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "inventoryQuantity",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    },
                                    (v1/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "cursor",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalCount",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Shopify_ProductsPayload",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v4/*: any*/),
                      "type": "Shopify_InvalidAuthorizationError",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v4/*: any*/),
                      "type": "ValidationError",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a348ae2340174e5db642aa4c5240204b";

export default node;

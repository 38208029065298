import { graphql, useMutation } from 'react-relay';

import { BulkInviteSuggestedCreatorsQuery } from 'GraphTypes/BulkInviteSuggestedCreatorsQuery.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const BulkInviteSuggestedCreatorsQueryMutation = graphql`
  mutation BulkInviteSuggestedCreatorsQuery($input: BulkInviteSuggestedCreatorsInput!) {
    bulkInviteSuggestedCreators(input: $input) {
      ... on EmptyPayload {
        clientMutationId
      }
    }
  }
`;

export const useBulkInviteSuggestedCreatorsQueryMutation: BaseMutation<
  BulkInviteSuggestedCreatorsQuery
> = (config) => {
  const [bulkInviteSuggestedCreatorsQuery, loading] = useMutation<BulkInviteSuggestedCreatorsQuery>(
    BulkInviteSuggestedCreatorsQueryMutation
  );

  const bulkInviteSuggestedCreatorsQueryCaller: BaseMutationExecFn<
    BulkInviteSuggestedCreatorsQuery
  > = (execConfig) => {
    bulkInviteSuggestedCreatorsQuery({
      ...config,
      ...execConfig,
    });
  };
  return [bulkInviteSuggestedCreatorsQueryCaller, loading];
};

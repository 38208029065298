import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes';

import {
  RemoveCreatorFromFavoritesMutation,
  RemoveCreatorFromFavoritesMutation$data,
  RemoveCreatorFromFavoritesInput,
  RemoveCreatorFromFavoritesMutation$variables,
} from 'GraphTypes/RemoveCreatorFromFavoritesMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation RemoveCreatorFromFavoritesMutation($input: RemoveCreatorFromFavoritesInput!) {
    removeCreatorFromFavorites(input: $input) {
      clientMutationId
    }
  }
`;

export default (
  data: RemoveCreatorFromFavoritesInput,
  resolve?: (response: RemoveCreatorFromFavoritesMutation$data) => void,
  reject?: (data: object) => void
) => {
  const { creatorId, organizationId } = data;
  const variables: RemoveCreatorFromFavoritesMutation$variables = {
    input: {
      ...data,
    },
  };

  const updater: SelectorStoreUpdater<RemoveCreatorFromFavoritesMutation$data> = (store) => {
    const updateCampaignPage = async () => {
      const isCampaignPage = window.location.pathname.startsWith('/campaigns');
      if (isCampaignPage) {
        const match = window.location.pathname.match(/^\/campaigns\/([^/]+)/) || [];
        const campaignId = match[1];
        if (!campaignId) return;
        const campaign = store.get(campaignId);
        if (!campaign) return;
        const searchCreatorsConnection = ConnectionHandler.getConnection(
          campaign,
          'SearchResults_searchCreators'
        );
        if (!searchCreatorsConnection) return;
        const edges = searchCreatorsConnection.getLinkedRecords('edges');
        if (!edges) return;
        const nodes = edges.map((edge) => edge.getLinkedRecord('node'));
        const node = nodes.find(
          (node) => node?.getLinkedRecord('creator')?.getValue('id') === creatorId
        );
        if (!node) return;
        node.setValue(false, 'favorite');
      }
    };

    const updateOrganization = async () => {
      const organization = store.get(organizationId);
      if (!organization) return;

      const favoriteCreatorsConnection = ConnectionHandler.getConnection(
        organization,
        'Organization_favoriteCreators'
      );

      if (favoriteCreatorsConnection) {
        const filteredList = favoriteCreatorsConnection
          ?.getLinkedRecords('edges')
          ?.filter((edge) => {
            return edge?.getLinkedRecord('node')?.getValue('id') !== creatorId;
          });
        const totalCount = filteredList?.length || 0;
        favoriteCreatorsConnection?.setLinkedRecords(filteredList, 'edges');
        favoriteCreatorsConnection.setValue(totalCount, 'totalCount');
      }
    };

    const updateCurrentUser = async () => {
      const root = store.getRoot();
      const favoriteCreators = root
        ?.getLinkedRecord('currentUser')
        ?.getLinkedRecord('organization')
        ?.getLinkedRecord('favoriteCreators');

      if (!favoriteCreators) return;
      const totalCount = favoriteCreators?.getValue('totalCount');
      const newTotalCount =
        ((totalCount && Number(totalCount) >= 0
          ? favoriteCreators?.getValue('totalCount')
          : 0) as number) - 1;
      favoriteCreators.setValue(newTotalCount, 'totalCount');
    };

    const updateCreatorById = async () => {
      const creator = store.get(creatorId);
      if (creator) {
        creator.setValue(false, 'favorite');
      }
    };

    updateCampaignPage();
    updateOrganization();
    updateCurrentUser();
    updateCreatorById();
  };

  commitMutation<RemoveCreatorFromFavoritesMutation>(environment, {
    mutation,
    variables,
    updater,
    optimisticUpdater: updater,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

export type {
  RemoveCreatorFromFavoritesMutation,
  RemoveCreatorFromFavoritesMutation$data,
  RemoveCreatorFromFavoritesInput,
  RemoveCreatorFromFavoritesMutation$variables,
};

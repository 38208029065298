import React, { useState } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';

import styles from './AdminCreativeLibrary.pcss';
import Creatives from './Creatives/Creatives';
import AdminCreativesFilters from './AdminCreativesFilters/AdminCreativesFilters';

import environment from 'Api/Environment';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import {
  Currency,
  AdminCreativeLibraryQuery as QueryType,
} from 'GraphTypes/AdminCreativeLibraryQuery.graphql';

const AdminCreativeLibraryQuery = graphql`
  query AdminCreativeLibraryQuery($filter: CreativesFilter!) {
    ...Creatives_adminCreatives @arguments(filter: $filter)
    currentUser {
      organization {
        subscription {
          canCreateFacebookAds
        }
      }
    }
  }
`;

const defaultState = {
  textQuery: '',
  currencies: [],
  adminFavoriteCreatives: null,
  adminFavoriteCampaigns: null,
  paidSocial: null,
};

interface Props {
  currency?: Currency;
}

const AdminCreativeLibrary: React.FC<Props> = (props) => {
  const currency = props.currency;

  const defaultFilter = {
    ...defaultState,
    currencies: currency ? [currency] : [],
  };

  const [filter, setFilter] = useState<any>(defaultFilter);

  const handleClearParams = () => {
    setFilter(defaultState);
  };

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text type="d2" msg="admin_creatives_library.title" className={styles.title} />
        <AdminCreativesFilters
          filters={filter}
          onFiltersChange={setFilter}
          onFiltersClear={handleClearParams}
        />
        <QueryRenderer<QueryType>
          environment={environment}
          query={AdminCreativeLibraryQuery}
          variables={{ filter }}
          render={({ props: queryProps }) => {
            if (!queryProps) return <Spinner style={{ margin: '40px auto' }} />;

            const canCreateFacebookAds =
              queryProps.currentUser?.organization?.subscription?.canCreateFacebookAds;

            return (
              <Creatives
                admin={true}
                filter={filter}
                adminCreatives={queryProps}
                canCreateFacebookAds={canCreateFacebookAds}
              />
            );
          }}
        />
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'admin_creative_library',
  },
  { dispatchOnMount: true }
)(AdminCreativeLibrary);

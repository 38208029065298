/**
 * @generated SignedSource<<6c24aa404e648271b9acb5c9145f48ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
export type InstagramInsightsMediaPlacement = "FEED" | "REEL" | "STORY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Creative_creative$data = {
  readonly adminFavorite: boolean | null | undefined;
  readonly file: {
    readonly height: number | null | undefined;
    readonly id: string;
    readonly secureUrl: string;
    readonly transformations?: {
      readonly autoUrl?: string;
      readonly postThumbnailUrl: string;
    } | null | undefined;
    readonly type: FileType;
    readonly width: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly postPublication: {
    readonly instagramInsightsMedia: {
      readonly commentCount: number | null | undefined;
      readonly engagement: number | null | undefined;
      readonly finalStoryInsightsCollected: boolean | null | undefined;
      readonly impressions: number | null | undefined;
      readonly likeCount: number | null | undefined;
      readonly permalink: string | null | undefined;
      readonly placement: InstagramInsightsMediaPlacement;
      readonly playCount: number | null | undefined;
      readonly reach: number | null | undefined;
      readonly savedCount: number | null | undefined;
      readonly shareCount: number | null | undefined;
    } | null | undefined;
    readonly tiktokInsightsMedia: {
      readonly comments: number | null | undefined;
      readonly embedUrl: string | null | undefined;
      readonly engagementRate: number | null | undefined;
      readonly likes: number | null | undefined;
      readonly shares: number | null | undefined;
      readonly videoViews: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly project: {
    readonly campaign: {
      readonly id: string;
      readonly organization: {
        readonly currency: Currency;
        readonly id: string;
      } | null | undefined;
      readonly stage: CampaignStage;
    };
    readonly creator: {
      readonly id: string;
      readonly profile: {
        readonly profilePictureFile: {
          readonly transformations?: {
            readonly collageThumbnailUrl: string;
          } | null | undefined;
          readonly url: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly type: CreatorType;
      readonly user?: {
        readonly avatar?: {
          readonly secureUrl: string;
        } | null | undefined;
        readonly followedByCount: number | null | undefined;
        readonly name?: string;
        readonly profilePictureUrl: string | null | undefined;
      } | null | undefined;
      readonly username: string;
    } | null | undefined;
    readonly id: string;
    readonly showcasingPermitted: boolean;
  } | null | undefined;
  readonly " $fragmentType": "Creative_creative";
};
export type Creative_creative$key = {
  readonly " $data"?: Creative_creative$data;
  readonly " $fragmentSpreads": FragmentRefs<"Creative_creative">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secureUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postThumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Creative_creative",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostPublication",
      "kind": "LinkedField",
      "name": "postPublication",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstagramInsightsMedia",
          "kind": "LinkedField",
          "name": "instagramInsightsMedia",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "finalStoryInsightsCollected",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "impressions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reach",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "engagement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "likeCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "commentCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "savedCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shareCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "placement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "permalink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "playCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TiktokInsightsMedia",
          "kind": "LinkedField",
          "name": "tiktokInsightsMedia",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "embedUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "engagementRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoViews",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "comments",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "likes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shares",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Image",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "autoUrl",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Video",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showcasingPermitted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CreatorProfile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "profilePictureFile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "collageThumbnailUrl",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramUser",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "followedByCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "InstagramCreator",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TiktokUser",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "avatar",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "TiktokCreator",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Creative",
  "abstractKey": null
};
})();

(node as any).hash = "2655ed200090769d420f6293569c0180";

export default node;

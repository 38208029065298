/**
 * @generated SignedSource<<913b4ae35555b31088339bcd71c994db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR" | "%future added value";
export type RequestProjectArchivationInput = {
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  reason?: string | null | undefined;
  side?: ProjectArchivationSide | null | undefined;
};
export type RequestProjectArchivationMutation$variables = {
  input: RequestProjectArchivationInput;
};
export type RequestProjectArchivationMutation$data = {
  readonly requestProjectArchivation: {
    readonly project: {
      readonly archivation: {
        readonly id: string;
      } | null | undefined;
      readonly archivationByContractor: {
        readonly id: string;
      } | null | undefined;
      readonly archivationRequest: {
        readonly acceptance: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly rejection: {
          readonly id: string;
        } | null | undefined;
        readonly side: ProjectArchivationSide;
      } | null | undefined;
    };
  } | null | undefined;
};
export type RequestProjectArchivationMutation = {
  response: RequestProjectArchivationMutation$data;
  variables: RequestProjectArchivationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "side",
      "value": "ADVERTISER"
    }
  ],
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "archivation(side:\"ADVERTISER\")"
},
v5 = {
  "alias": "archivationByContractor",
  "args": [
    {
      "kind": "Literal",
      "name": "side",
      "value": "CONTRACTOR"
    }
  ],
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "archivation(side:\"CONTRACTOR\")"
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequest",
  "kind": "LinkedField",
  "name": "archivationRequest",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequestAcceptance",
      "kind": "LinkedField",
      "name": "acceptance",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequestRejection",
      "kind": "LinkedField",
      "name": "rejection",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestProjectArchivationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestProjectArchivationPayload",
        "kind": "LinkedField",
        "name": "requestProjectArchivation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestProjectArchivationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestProjectArchivationPayload",
        "kind": "LinkedField",
        "name": "requestProjectArchivation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db9dd4779d488134a9d4082dc682f283",
    "id": null,
    "metadata": {},
    "name": "RequestProjectArchivationMutation",
    "operationKind": "mutation",
    "text": "mutation RequestProjectArchivationMutation(\n  $input: RequestProjectArchivationInput!\n) {\n  requestProjectArchivation(input: $input) {\n    project {\n      archivation(side: ADVERTISER) {\n        id\n      }\n      archivationByContractor: archivation(side: CONTRACTOR) {\n        id\n      }\n      archivationRequest {\n        id\n        side\n        acceptance {\n          id\n        }\n        rejection {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b8486f359cdd0ba98a26a1c7a0c5376";

export default node;

import React, { MouseEvent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './CreatorStatistic.css';
import Info from './Info/Info';
import StatisticContainer from './StatisticContainer/StatisticContainer';

import Spinner from 'Atoms/Spinner/Spinner';
import environment from 'Api/Environment';
import { CreatorStatisticQuery as QueryType } from 'GraphTypes/CreatorStatisticQuery.graphql';

const CreatorStatisticQuery = graphql`
  query CreatorStatisticQuery($creatorId: ID!) {
    creator(id: $creatorId) {
      ...Info_creator
      ...AudienceInfo_creator
      ...CreatorInfoStats_creator
      ...CreatorStatsTabs_creator
      ...StoriesStatistic_creator
      ...PostsStatistic_creator
      ... on InstagramCreator {
        userInsightsData {
          id
        }
      }
      profile {
        currency
      }
    }
    currentUser {
      admin
      organization {
        id
        newClient
        churned
        subscription {
          paused
        }
      }
    }
  }
`;

interface Props {
  creatorId: string;
  price?: number | null;
  onUnlockBannerShow: (e: MouseEvent<HTMLDivElement>) => void;
}

const CreatorStatistic: React.FC<Props> = (props) => {
  const { creatorId, price, onUnlockBannerShow } = props;

  return (
    <div className={styles.root}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={CreatorStatisticQuery}
        variables={{ creatorId }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner className={styles.preloader} />;

          const creator = queryProps.creator;
          const currentUser = queryProps.currentUser;

          if (!creator || !currentUser) return null;

          const admin = currentUser.admin;
          const organizationId = currentUser.organization?.id;
          const isChurned = currentUser.organization?.churned;
          const isNewClient = currentUser.organization?.newClient;
          const isPlanPaused = Boolean(currentUser?.organization?.subscription?.paused);

          const isBlockedView = !admin && (isChurned || isNewClient || isPlanPaused);

          return (
            <div>
              <Info
                admin={admin}
                creator={creator}
                creatorId={creatorId}
                organizationId={organizationId}
                isBlockedView={isBlockedView}
                onUnlockBannerShow={onUnlockBannerShow}
              />
              <StatisticContainer
                creator={creator}
                creatorId={creatorId}
                admin={admin}
                price={price}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default CreatorStatistic;

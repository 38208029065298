/**
 * @generated SignedSource<<4fe7f36033ff064def023720d48823e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkCreatorSearchResultAsViewedInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  creatorId: string;
  id?: string | null | undefined;
};
export type MarkCreatorSearchResultAsViewedMutation$variables = {
  input: MarkCreatorSearchResultAsViewedInput;
};
export type MarkCreatorSearchResultAsViewedMutation$data = {
  readonly markCreatorSearchResultAsViewed: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type MarkCreatorSearchResultAsViewedMutation = {
  response: MarkCreatorSearchResultAsViewedMutation$data;
  variables: MarkCreatorSearchResultAsViewedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkCreatorSearchResultAsViewedPayload",
    "kind": "LinkedField",
    "name": "markCreatorSearchResultAsViewed",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkCreatorSearchResultAsViewedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkCreatorSearchResultAsViewedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b0009dd5c6c419dd96c6867880e23fad",
    "id": null,
    "metadata": {},
    "name": "MarkCreatorSearchResultAsViewedMutation",
    "operationKind": "mutation",
    "text": "mutation MarkCreatorSearchResultAsViewedMutation(\n  $input: MarkCreatorSearchResultAsViewedInput!\n) {\n  markCreatorSearchResultAsViewed(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0a4b2729d9370eb44b43b580e7a760b";

export default node;

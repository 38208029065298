import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import Tabs from 'Molecules/Tabs/Tabs';

const ACTIVE = 'ACTIVE';
const DRAFT = 'DRAFT';
const COMPLETED = 'COMPLETED';

import { AdvertiserCampaignStageFilterQuery as QueryType } from 'GraphTypes/AdvertiserCampaignStageFilterQuery.graphql';

const AdvertiserCampaignStageFilterQuery = graphql`
  query AdvertiserCampaignStageFilterQuery($id: ID!) {
    organization(id: $id) {
      active: campaigns(stages: [ACTIVE, IN_REVIEW]) {
        totalCount
      }
      draft: campaigns(stages: [DRAFT]) {
        totalCount
      }
      completed: campaigns(stages: [COMPLETED]) {
        totalCount
      }
    }
  }
`;

interface Props {
  id: string;
  currentStage?: string;
  onStageChange: (id: string) => void;
}

const AdvertiserCampaignStageFilter: React.FC<Props> = (props) => {
  const { id, currentStage, onStageChange } = props;

  const response = useLazyLoadQuery<QueryType>(AdvertiserCampaignStageFilterQuery, { id });

  if (!response) return null;

  const organization = response.organization;

  const items = [
    {
      id: ACTIVE,
      textData: { msg: 'dashboard.campaigns.filter.stage.active' },
      count: organization?.active?.totalCount,
    },
    {
      id: DRAFT,
      textData: { msg: 'dashboard.campaigns.filter.stage.draft' },
      count: organization?.draft?.totalCount,
    },
    {
      id: COMPLETED,
      textData: { msg: 'dashboard.campaigns.filter.stage.completed' },
      count: organization?.completed?.totalCount,
    },
  ];

  return (
    <Tabs
      items={items}
      currentItemId={currentStage}
      filled={true}
      mute={true}
      onChange={onStageChange}
    />
  );
};

export default AdvertiserCampaignStageFilter;

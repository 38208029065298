import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';

import styles from './MessageMediaControls.pcss';

import { downloadFile } from 'Util/downloadFile';
import TextButton from 'Atoms/TextButton/TextButton';
import Spinner from 'Atoms/Spinner/Spinner';
import ErrorHandler from 'Util/errorHandler';
import { MessageMedia_file$data } from 'GraphTypes/MessageMedia_file.graphql';

interface Props {
  file: MessageMedia_file$data;
}

const MessageMediaControls: React.FC<Props> = (props) => {
  const { file } = props;
  const bcaTaggedUrl = file.bcaTaggedUrl;
  const secureUrl = file.secureUrl;
  const originalFilename = file.originalFilename;

  const [isDownloading, setDowloadingState] = useState(false);

  const tracking = useTracking();

  const handleDownloadFailed = () => {
    ErrorHandler.error('Crash while uploading file from chat', { secureUrl });
  };

  const handleDownloadClick = () => {
    tracking.trackEvent({ event: 'download.click' });
    const finalName = decodeURIComponent(bcaTaggedUrl || secureUrl);
    const filename = originalFilename || finalName?.split('/')?.pop()?.split('?')[0];
    const finalLink = bcaTaggedUrl || secureUrl;
    downloadFile(
      finalLink,
      filename,
      () => {
        setDowloadingState(false);
      },
      handleDownloadFailed
    );
    setDowloadingState(true);
  };

  return (
    <div className={styles.root}>
      <TextButton
        color="normal"
        flex="column"
        icon={isDownloading ? <Spinner size="sm" /> : undefined}
        leftIconName={isDownloading ? undefined : 'download'}
        msg="chat.messages.message.download_original"
        disabled={isDownloading}
        onClick={handleDownloadClick}
        className={styles.downloadBtn}
      />
    </div>
  );
};

const MessageMediaControlsWrap = track({ element: 'media_controls' })(MessageMediaControls);

export default MessageMediaControlsWrap;

import React, { MouseEvent, Suspense } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import CreatorStatistic from './CreatorStatistic/CreatorStatistic';

import environment from 'Api/Environment';
import { MediaplanItemInfoQuery as QueryType } from 'GraphTypes/MediaplanItemInfoQuery.graphql';

const MediaplanItemInfoQuery = graphql`
  query MediaplanItemInfoQuery($id: ID!, $creatorId: ID!) {
    campaign(id: $id) {
      mediaplanItem(creatorId: $creatorId) {
        estimatedPrice
        currency
        project {
          price
        }
      }
    }
  }
`;

interface Props {
  id: string;
  creatorId: string;
  section?: string;
  onUnlockBannerShow: (e: MouseEvent<HTMLDivElement>) => void;
}

const MediaplanItemInfo: React.FC<Props> = (props) => {
  const { id, creatorId, section, onUnlockBannerShow } = props;

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={MediaplanItemInfoQuery}
      variables={{ id, creatorId }}
      render={({ props: queryProps }) => {
        if (!queryProps) return null;

        const price =
          queryProps.campaign?.mediaplanItem?.project?.price ||
          queryProps.campaign?.mediaplanItem?.estimatedPrice;
        const currency = queryProps.campaign?.mediaplanItem?.currency;

        return (
          <Suspense fallback={null}>
            <CreatorStatistic
              price={price}
              currency={currency}
              creatorId={creatorId}
              onUnlockBannerShow={onUnlockBannerShow}
              // section={section}
            />
          </Suspense>
        );
      }}
    />
  );
};

export default MediaplanItemInfo;

import React, { ChangeEvent, FC, useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchQuery } from 'relay-runtime';

import styles from './FirebaseAuth.pcss';
import { AuthFormType, FormErrorType } from './FirebaseAuth';

import {
  LANDING_LINK,
  SIGNUP_COMPLETE_ADVERTISER_ROUTE,
  INSENSE_PRIVACY_POLICY,
} from 'Constants/general';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Logo from 'Atoms/Logo/Logo';
import { CurrentUserQuery } from 'GraphTypes/CurrentUserQuery.graphql';
import environment from 'Api/Environment';
import currentUserQuery from 'Containers/Auth0/Auth0Context/CurrentUser.Query';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { emailValidate, passwordValidate } from 'Util/validate';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';

type Props = {
  changeFormType: (formType: AuthFormType) => void;
};

export const SignUpForm: FC<Props> = (props) => {
  const { changeFormType } = props;
  const { state } = useLocation() as {
    state: {
      isInvite?: string;
      sp?: string;
    };
    search: string;
  };
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState<FormErrorType>({
    email: undefined,
    password: undefined,
  });
  const [loading, setLoadingStatus] = useState(false);

  const handleRegistration = async () => {
    if (loading) return;
    const emailErrors = emailValidate({}, email);
    const passwordErrors = passwordValidate({}, password);
    if (emailErrors.email) {
      setError(emailErrors);
      return;
    }
    if (passwordErrors.password) {
      setError(passwordErrors);
      return;
    }
    setLoadingStatus(true);
    try {
      await createUserWithEmailAndPassword(firebaseClient.authClient, email, password);
      fetchQuery<CurrentUserQuery>(
        environment,
        currentUserQuery,
        {},
        { fetchPolicy: 'network-only' }
      ).subscribe({
        next: () => {
          navigate(
            state?.isInvite ? `${SIGNUP_COMPLETE_ADVERTISER_ROUTE}${state?.sp}` : '/dashboard'
          );
        },
      });
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        setError({ email: 'form.error_user_already_exists' });
      } else if (e.code === 'auth/invalid-email') {
        setError({ email: 'form.error_email_invalid' });
      } else {
        setError({ email: 'general.error' });
      }
    } finally {
      setLoadingStatus(false);
    }
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePolicyClick = () => {
    window.open(INSENSE_PRIVACY_POLICY);
  };

  const handleSignInClick = () => {
    changeFormType(AuthFormType.SIGNIN);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <SmartLink path={LANDING_LINK}>
              <Logo />
            </SmartLink>
          </div>
        </header>
        <Text type="h1" msg={'auth.signup'} />
        <div className={styles.form}>
          <Input
            bordered
            placeholderMsg="form.email"
            type="email"
            id="email"
            autoComplete="email"
            required
            className={styles.input}
            onChange={handleEmailChange}
            error={!!error?.email}
          />
          {!!error?.email && <Text type="s" msg={error.email} className={styles.errorText} />}
          <Input
            bordered
            placeholderMsg="form.password"
            type="password"
            id="new-password"
            autoComplete="new-password"
            required
            className={styles.input}
            onChange={handlePasswordChange}
            maxLength={32}
            error={!!error?.password}
          />
          {!!error?.password && <Text type="s" msg={error.password} className={styles.errorText} />}
          <ButtonPreset>
            <Button
              fluid
              color="black"
              msg={'auth.signup'}
              className={styles.submitButton}
              onClick={handleRegistration}
              loading={loading}
            />
          </ButtonPreset>
          <TextButton
            onClick={handleSignInClick}
            classes={{
              text: styles.textByCenter,
            }}
            msg={'auth.already_have_account'}
          />
        </div>
        <TextButton
          classes={{
            text: styles.textByCenter,
          }}
          msg={'auth.privacy_policy'}
          className={styles.privacy}
          onClick={handlePolicyClick}
        />
      </div>
    </div>
  );
};

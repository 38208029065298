import { graphql } from 'react-relay';

const query = graphql`
  query NavigationQuery {
    currentUser {
      type
      organization {
        campaigns(stages: [ACTIVE, IN_REVIEW, COMPLETED]) {
          totalCount
        }
        projects(
          withUnreadEvents: true
          stages: [ACCEPTED, LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED]
        ) {
          totalCount
        }
      }
    }
  }
`;

export { query };

import { call, put, takeLatest, fork } from 'redux-saga/effects';
import { fetchQuery, graphql } from 'relay-runtime';
import { v4 as uuid } from 'uuid';
import 'Util/sessionStorage';

import { getQueryLocationParams } from 'Util/encodeQueryData';
import environment from 'Api/Environment';
import { ADVERTISER_SIGNUP, INIT_SUBSCRIPTION } from 'Constants';
import { SHOPIFY_OAUTH, UNKNOWN } from 'Constants/general';
import { togglePreloader } from 'Actions/UI.Actions';
import createOrganization from 'Mutations/CreateOrganization.Mutation';
import updateAdvertiserProfile from 'Mutations/UpdateAdvertiserProfile.Mutation';
import assignUserToOrganization from 'Mutations/AssignUserToOrganization.Mutation';
import projectEventCreatedSubscription from 'Subscriptions/ProjectEventCreated.Subscription';
import projectUnreadCountChanged from 'Subscriptions/ProjectUnreadCountChanged.Subscription';
import campaignSummaryUpdated from 'Subscriptions/CampaignSummaryUpdated.Subscription';
import organizationBalanceUpdated from 'Subscriptions/OrganizationBalanceUpdated.Subscription';
import fileProcessingStatusUpdated from 'Subscriptions/FileProcessingStatusUpdated.Subscription';
import fileBcaTaggingStatusUpdated from 'Subscriptions/FileBcaTaggingStatusUpdated.Subscription';
import organizationSubscriptionUpdated from 'Subscriptions/OrganizationSubscriptionUpdated.Subscription';
import ErrorHandler from 'Util/errorHandler';
import { waitUntilUserInitialized } from 'Containers/Auth0/Auth0Context';
import { identify } from 'Util/custify';
import ShopifyOrderStateUpdatedSubscription from 'Api/subscriptions/ShopifyOrderStateUpdated.Subscription';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';

export function* advertiserSignupSaga(action) {
  const {
    userExist,
    email,
    country = null,
    name,
    firstName,
    lastName,
    size,
    websiteUrl,
    verticals,
    source,
    requestedDemo,
    industry,
    advertiserProfileId,
    companyRole,
    description: businessDescription,
    phoneNumber,
    organizationId: defaultoOrganizationId,
    affiliateReferralCode,
    userId,
    onSuccess,
    onOrganizationIdGenerated,
  } = action.payload;

  yield put(togglePreloader(true));

  let profileId;

  const queries = getQueryLocationParams();
  const { referralToken } = queries;

  const advertisingCountryId = country;
  try {
    if (!userExist) {
      setSubscription();
    }

    const currentProfileId = advertiserProfileId || profileId;
    let userOrganization = defaultoOrganizationId;
    if (defaultoOrganizationId) {
      if (userId) {
        const assignedData = yield new Promise((resolve, reject) => {
          assignUserToOrganization(
            { organizationId: defaultoOrganizationId, userId },
            resolve,
            reject
          );
        });
      }
    } else {
      const organizationId = uuid();
      onOrganizationIdGenerated?.(organizationId);
      userOrganization = organizationId;
      const referrerCode = affiliateReferralCode
        ? { tapfiliateReferralCode: affiliateReferralCode }
        : null;
      yield new Promise((resolve, reject) => {
        createOrganization(
          {
            name,
            advertisingCountryId,
            id: organizationId,
            websiteUrl,
            industry,
            size,
            source,
            verticals,
            requestedDemo,
            businessDescription,
            referralToken,
            ...referrerCode,
          },
          resolve,
          reject
        );
      });
    }
    yield new Promise((resolve, reject) => {
      updateAdvertiserProfile(
        { id: currentProfileId, phoneNumber, firstName, lastName, companyRole },
        resolve,
        reject
      );
    });
    identify({
      userId,
      email,
      company_id: userOrganization,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    //yield call(navigate, DASHBOARD_ROUTE);
    yield put(togglePreloader(false));
    //yield delay(500);
  } catch (error) {
    yield put(togglePreloader(false));
    ErrorHandler.error('Crash while signup as advertiser', { email, error });
  }
}

function setSubscription() {
  projectEventCreatedSubscription();
  projectUnreadCountChanged();
  campaignSummaryUpdated();
  organizationBalanceUpdated();
  fileProcessingStatusUpdated();
  fileBcaTaggingStatusUpdated();
  organizationSubscriptionUpdated();
  ShopifyOrderStateUpdatedSubscription();
}

const query = graphql`
  query SessionsSagaQuery {
    currentUser {
      id
      type
      advertiserProfile {
        id
        firstName
      }
    }
  }
`;

function checkUser(resolve, reject) {
  fetchQuery(environment, query, {}).subscribe({
    next: (result) => resolve(result),
    error: (error) => {
      reject(error);
    },
  });
}

function* initSubscription() {
  if (location.pathname === SHOPIFY_OAUTH) {
    return;
  }

  yield waitUntilUserInitialized();
  const isAuthenticated = !!firebaseClient.authClient.currentUser;
  if (isAuthenticated) {
    try {
      const newData = yield new Promise((resolve, reject) => {
        checkUser(resolve, reject);
      });
      const userType = newData?.currentUser?.type;
      if (!userType || userType === UNKNOWN) {
        return;
      }
      setSubscription();
    } catch (e) {
      console.error(e);
    }
  }
}

export default function* watchSessions() {
  yield fork(initSubscription);
  yield takeLatest(INIT_SUBSCRIPTION, initSubscription);
  yield takeLatest(ADVERTISER_SIGNUP, advertiserSignupSaga);
}

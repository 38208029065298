/**
 * @generated SignedSource<<701fadc6110194ed07b63bdf14cea96d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HireStage_project$data = {
  readonly archivationByAdvertiser: {
    readonly id: string;
  } | null | undefined;
  readonly archivationByContractor: {
    readonly id: string;
  } | null | undefined;
  readonly campaign: {
    readonly completion: {
      readonly id: string;
    } | null | undefined;
    readonly organization: {
      readonly paymentAccount: {
        readonly balance: number;
        readonly currency: Currency;
      } | null | undefined;
      readonly subscription: {
        readonly paused: boolean;
        readonly transactionFee: number;
      } | null | undefined;
    } | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
    readonly type: CampaignType | null | undefined;
  };
  readonly creator: {
    readonly channel?: {
      readonly name: string | null | undefined;
      readonly thumbnailUrl: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly paidSocialEnabled?: boolean;
    readonly profile: {
      readonly currency: Currency | null | undefined;
      readonly estimatedPrice: number | null | undefined;
    } | null | undefined;
    readonly type: CreatorType;
    readonly user?: {
      readonly avatar?: {
        readonly secureUrl: string;
      } | null | undefined;
      readonly name?: string;
      readonly profilePictureUrl: string | null | undefined;
    } | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly currency: Currency | null | undefined;
  readonly id: string;
  readonly paidSocial: boolean;
  readonly price: number | null | undefined;
  readonly " $fragmentType": "HireStage_project";
};
export type HireStage_project$key = {
  readonly " $data"?: HireStage_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"HireStage_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HireStage_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "YoutubeChannel",
              "kind": "LinkedField",
              "name": "channel",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnailUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "YoutubeCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TiktokUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "secureUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TiktokCreator",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedPrice",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignCompletion",
          "kind": "LinkedField",
          "name": "completion",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Payment_Account",
              "kind": "LinkedField",
              "name": "paymentAccount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "balance",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AdvertiserSubscription",
              "kind": "LinkedField",
              "name": "subscription",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "transactionFee",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paused",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "archivationByAdvertiser",
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "ADVERTISER"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": "archivation(side:\"ADVERTISER\")"
    },
    {
      "alias": "archivationByContractor",
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "CONTRACTOR"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": "archivation(side:\"CONTRACTOR\")"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "3ebad9841199b7cedd619036665d1261";

export default node;

import React, { useState, useEffect, Suspense } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import classnames from 'classnames';

import styles from './TopupBalanceContent.pcss';

import TopupBalanceContent from './TopupBalanceContent';

import { Props as DrawerProps } from '../TopupBalance';

import createSetupIntentMutation from 'Mutations/CreateSetupIntent.Mutation';
import { CreateSetupIntentMutation$data } from 'GraphTypes/CreateSetupIntentMutation.graphql';
import Spinner from 'Atoms/Spinner/Spinner';

const STRIPE_KEY = process.env.STRIPE_KEY || '';
const stripePromise = loadStripe(STRIPE_KEY);

const TopupBalanceContainer: React.FC<DrawerProps> = (props) => {
  const { purpose, amount, className, successCallback } = props;

  const [token, setToken] = useState<string>();

  useEffect(() => {
    if (!token) createSetupIntentMutation({}, createSetupIntentSuccess);
  }, []);

  const createSetupIntentSuccess = (data: CreateSetupIntentMutation$data) => {
    const clientSecret = data.stripe?.createSetupIntent?.setupIntent?.clientSecret;

    if (clientSecret) setToken(clientSecret);
  };

  return (
    <div className={classnames(className, styles.container)}>
      {token && (
        <Elements stripe={stripePromise} options={{ clientSecret: token }}>
          <Suspense fallback={<Spinner className={styles.preloader} />}>
            <TopupBalanceContent
              token={token}
              amount={amount}
              purpose={purpose}
              successCallback={successCallback}
            />
          </Suspense>
        </Elements>
      )}
      {!token && <Spinner className={styles.preloader} />}
    </div>
  );
};

export default TopupBalanceContainer;

/**
 * @generated SignedSource<<e6ced62d100937bc62bf7a228a5a4a34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OutreachHeaderQuery$variables = {
  batchIds?: ReadonlyArray<string> | null | undefined;
  campaignId: string;
};
export type OutreachHeaderQuery$data = {
  readonly campaign: {
    readonly appliedCount: {
      readonly totalCount: number;
    };
    readonly clickedCount: {
      readonly totalCount: number;
    };
    readonly creatorsCount: {
      readonly totalCount: number;
    };
    readonly enqueuedCount: {
      readonly totalCount: number;
    };
    readonly hiredCount: {
      readonly totalCount: number;
    };
    readonly openedCount: {
      readonly totalCount: number;
    };
    readonly reachedCount: {
      readonly totalCount: number;
    };
    readonly repliedCount: {
      readonly totalCount: number;
    };
  } | null | undefined;
};
export type OutreachHeaderQuery = {
  response: OutreachHeaderQuery$data;
  variables: OutreachHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "batchIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v3 = {
  "kind": "Variable",
  "name": "outreachBatchIds",
  "variableName": "batchIds"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v5 = {
  "alias": "enqueuedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "ENQUEUED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": "reachedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "OUTREACHED",
        "OPENED",
        "CLICKED",
        "APPLIED",
        "HIRED",
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": "openedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "OPENED",
        "CLICKED",
        "APPLIED",
        "HIRED",
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": "clickedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "CLICKED",
        "APPLIED",
        "HIRED",
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": "repliedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": "appliedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "APPLIED",
        "HIRED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": "hiredCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "HIRED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": "creatorsCount",
  "args": [
    (v3/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OutreachHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d076fccba4510751c844ee8474673c85",
    "id": null,
    "metadata": {},
    "name": "OutreachHeaderQuery",
    "operationKind": "query",
    "text": "query OutreachHeaderQuery(\n  $campaignId: ID!\n  $batchIds: [ID!]\n) {\n  campaign(id: $campaignId) {\n    enqueuedCount: projects(outreachBatchIds: $batchIds, outreachStages: [ENQUEUED]) {\n      totalCount\n    }\n    reachedCount: projects(outreachBatchIds: $batchIds, outreachStages: [OUTREACHED, OPENED, CLICKED, APPLIED, HIRED, REPLIED]) {\n      totalCount\n    }\n    openedCount: projects(outreachBatchIds: $batchIds, outreachStages: [OPENED, CLICKED, APPLIED, HIRED, REPLIED]) {\n      totalCount\n    }\n    clickedCount: projects(outreachBatchIds: $batchIds, outreachStages: [CLICKED, APPLIED, HIRED, REPLIED]) {\n      totalCount\n    }\n    repliedCount: projects(outreachBatchIds: $batchIds, outreachStages: [REPLIED]) {\n      totalCount\n    }\n    appliedCount: projects(outreachBatchIds: $batchIds, outreachStages: [APPLIED, HIRED]) {\n      totalCount\n    }\n    hiredCount: projects(outreachBatchIds: $batchIds, outreachStages: [HIRED]) {\n      totalCount\n    }\n    creatorsCount: projects(outreachBatchIds: $batchIds) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8ebb728d357a921c126bb6800ea855e";

export default node;

import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import TooltipInfo from '../TooltipInfo/TooltipInfo';
import type { TooltipInfoProps } from '../TooltipInfo/TooltipInfo';

import styles from './TextWithTaxInfoTooltip.pcss';

type Props = {
  className?: string;
  tooltipInfoProps?: Omit<Partial<TooltipInfoProps>, 'tooltipMsg'>;
  colorTheme?: 'default' | 'sky' | 'pink' | 'dark' | 'sun' | 'ocean';
};

const TextWithTaxInfoTooltip: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, tooltipInfoProps, colorTheme } = props;

  return (
    <div className={classnames(styles.root, className)}>
      {children}
      <TooltipInfo
        {...tooltipInfoProps}
        tooltipMsg="billing_plans.plans.text_with_tax_info_tooltip.tooltip"
        type={colorTheme === 'dark' ? 'light' : undefined}
      />
    </div>
  );
};

export default TextWithTaxInfoTooltip;

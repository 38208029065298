import React, { Component, ReactNode } from 'react';

import IconNew from '../Icon/Icon';

import styles from './ImageWithFallback.pcss';

import ErrorHandler from 'Util/errorHandler';

type Props = {
  children: ReactNode;
};
type State = {
  hasError: boolean;
};

class ImageWithFallbackBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    ErrorHandler.warn('ImageWithFallbackQuery incorrect response', {
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.placeholder}>
          <IconNew name="Warning-error" className={styles.errorIcon} />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ImageWithFallbackBoundary;

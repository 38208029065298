import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose } from 'redux';

import rootSaga from 'Sagas';
import composeMiddlewares from 'Store/composeMiddlewares';

const sagaMiddleware = createSagaMiddleware();
const isDev = process.env.NODE_ENV === 'development';

const enhancers = () => {
  const middlewares = composeMiddlewares([
    sagaMiddleware,
  ], isDev);

  return compose(
    applyMiddleware(...middlewares)
  );
};

export const runSagas = () => {
  sagaMiddleware.run(rootSaga);

  return sagaMiddleware;
};

export default () => {
  return enhancers();
};

import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Filters, CampaignType, CreatorType } from '../data';

import styles from './BillingHistoryFilters.pcss';
import UsernameFilter from './UsernameFilter/UsernameFilter';
import TypesFilter from './TypesFilter/TypesFilter';

import { amplitude } from 'Helpers/amplitude';
import { createEndOfDayV2, createStartOfDayV2, stringToDate } from 'Util/dateCreator';
import DatePicker from 'Components/ui/DatePicker/DatePicker';
import Text from 'Components/ui/Text/Text';
import CampaignsFilter from 'Organisms/CampaignsFilter/CampaignsFilter';
import BrandsFilter from 'Organisms/BrandsFilter/BrandsFilter';
import { Billing_TransactionType } from 'GraphTypes/TransactionContainer_billingTransactions.graphql';

interface Props {
  filters: Filters;
  onFiltersUpdate: (filters: Filters) => void;
}

const BillingHistoryFilters: React.FC<Props> = (props) => {
  const { filters, onFiltersUpdate } = props;

  const { dateFrom, dateTo, campaigns, brands, types, creator } = filters;

  const intl = useIntl();

  const handleTypesChange = (newTypes?: Billing_TransactionType[]) => {
    amplitude.sendEvent({
      id: 168,
      category: 'transaction_page',
      name: 'filter_by_types_choosen',
      param: { choosenPaymentType: newTypes },
    });
    onFiltersUpdate({ types: newTypes });
  };

  const handleCampaignsChange = (newCampaigns?: CampaignType[]) => {
    amplitude.sendEvent({
      id: 172,
      category: 'transaction_page',
      name: 'filter_by_campaigns_choosen',
    });
    onFiltersUpdate({ campaigns: newCampaigns });
  };

  const handleBrandsChange = (id?: string, name?: string, check?: boolean) => {
    if (!id) {
      onFiltersUpdate({ brands: undefined });
    } else if (id && name && check) {
      amplitude.sendEvent({
        id: 171,
        category: 'transaction_page',
        name: 'filter_by_brands_choosen',
      });
      onFiltersUpdate({ brands: [{ id, name, check }] });
    }
  };

  const handleDateFromChange = (dateFrom: Date | null) => {
    if (dateFrom) {
      const date = createStartOfDayV2(dateFrom);
      const dateValue = stringToDate(date);
      onFiltersUpdate({ dateFrom: dateValue });
    } else {
      onFiltersUpdate({ dateFrom: undefined });
    }
  };

  const handleDateToChange = (dateTo: Date | null) => {
    if (dateTo) {
      const date = createEndOfDayV2(dateTo);
      const dateValue = stringToDate(date);
      onFiltersUpdate({ dateTo: dateValue });
    } else {
      onFiltersUpdate({ dateTo: undefined });
    }
  };

  const handleCreatorChange = (data: CreatorType) => {
    amplitude.sendEvent({
      id: 173,
      category: 'transaction_page',
      name: 'filter_by_creators_choosen',
    });
    onFiltersUpdate({ creator: data });
  };

  const handleDateFromClear = () => {
    onFiltersUpdate({ dateFrom: undefined });
  };

  const handleDateToClear = () => {
    onFiltersUpdate({ dateTo: undefined });
  };

  const brandNames = useMemo(() => {
    return (
      brands?.map((item) => {
        return { [item.id]: item.name || '' };
      }) || []
    );
  }, [brands]);

  return (
    <div className={styles.root}>
      <div className={styles.dates}>
        <DatePicker
          inputClassName={styles.date}
          customInputProps={{
            bordered: false,
            rightIcon: 'Calendar-schedule',
          }}
          onResetValue={handleDateFromClear}
          reactDatePickerProps={{
            selected: dateFrom || null,
            isClearable: true,
            placeholderText: intl.formatMessage({ id: 'search_section.form.dateFrom' }),
            onChange: handleDateFromChange,
          }}
        />
        <Text text="—" className={styles.separator} />
        <DatePicker
          inputClassName={styles.date}
          onResetValue={handleDateToClear}
          customInputProps={{
            bordered: false,
            rightIcon: 'Calendar-schedule',
          }}
          reactDatePickerProps={{
            selected: dateTo || null,
            isClearable: true,
            placeholderText: intl.formatMessage({ id: 'search_section.form.dateTo' }),
            onChange: handleDateToChange,
          }}
        />
      </div>
      <TypesFilter selectedItems={types} onTypeSelect={handleTypesChange} />
      <BrandsFilter
        selectedItems={brandNames}
        onAdd={handleBrandsChange}
        className={styles.selector}
      />
      <CampaignsFilter selectedItems={campaigns} onCampaignSelect={handleCampaignsChange} />
      <UsernameFilter creator={creator} onCreatorSelect={handleCreatorChange} />
    </div>
  );
};

export default BillingHistoryFilters;

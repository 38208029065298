import React from 'react';

import styles from './BriefTemplateMobilePreview.css';

import Text from 'Atoms/Text/Text';
import Brief from 'Templates/Brief/Brief';
import frameImg from 'Images/brief/phone_frame.svg';

interface Props {
  campaignId: string,
}



const BriefTemplateMobilePreview: React.FC<Props> = props => {
  const { campaignId } = props;

  return (
    <div className={styles.root}>
      <Text
        msg="brief_template.mobile_preview.title"
        align="center"
        className={styles.title}
      />
      <div className={styles.phone}>
        <img src={frameImg} />
        <div className={styles.content}>
          <Brief campaignId={campaignId} mobileView={true} />
        </div>
      </div>
    </div>
  );
};

export default BriefTemplateMobilePreview;
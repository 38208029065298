import { createStore, combineReducers } from 'redux';
import { intlReducer, updateIntl } from 'react-intl-redux';

import enhancers, { runSagas } from './enhancers';

import { getLocalizationData } from 'Util/localizationData';
import rootReducer from 'Reducers';

const appReducer = () => {
  return combineReducers({
    root: rootReducer,
    intl: intlReducer,
  });
};

export default function configureStore(initialState) {
  const store = createStore(appReducer(), initialState, enhancers());

  const { locale, messages } = getLocalizationData();

  store.dispatch(
    updateIntl({
      locale,
      messages,
    })
  );

  runSagas();

  return store;
}

import React from 'react';
import classnames from 'classnames';

import styles from './Image.pcss';

import { getPlatformIcon } from 'Util/icons';
import Icon from 'Atoms/Icon/Icon';

type ImageType = 'default' | 'round';
export type ImagePlatform =
  | 'INSTAGRAM'
  | 'TIKTOK'
  | 'YOUTUBE'
  | 'FACEBOOK'
  | 'SNAPCHAT'
  | 'OTHER'
  | null;
type ImageSize = number;
type ImageSource = string;

type Props = {
  type?: ImageType;
  platform?: ImagePlatform;
  platformClassName?: string;
  size?: ImageSize;
  src?: ImageSource;
  className?: string;
};

const Image: React.FC<Props> = (props) => {
  const { src, size, type = 'default', platform, platformClassName, className } = props;

  const classNameList = classnames(styles.root, className, {
    [styles.round]: type === 'round',
  });

  const platformClassNameList = classnames(
    styles.platformImg,
    platformClassName,
    platform ? styles[platform] : null
  );

  const platformIcon = getPlatformIcon(platform?.toLowerCase());

  return (
    <div className={classNameList} style={{ ...(size ? { height: size, width: size } : {}) }}>
      <img src={src} />
      {platform && platformIcon && (
        <div className={platformClassNameList}>
          <Icon name={platformIcon} size={'sm'} />
        </div>
      )}
    </div>
  );
};

export default Image;

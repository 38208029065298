/**
 * @generated SignedSource<<4895f1ee2b05ec5189ea1127ce203e5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Accounting_MarkInvoiceAsIssuedInput = {
  clientMutationId?: string | null | undefined;
  revenueTransactionId: string;
};
export type MarkInvoiceAsIssuedMutation$variables = {
  input: Accounting_MarkInvoiceAsIssuedInput;
};
export type MarkInvoiceAsIssuedMutation$data = {
  readonly accounting: {
    readonly markInvoiceAsIssued: {
      readonly clientMutationId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MarkInvoiceAsIssuedMutation = {
  response: MarkInvoiceAsIssuedMutation$data;
  variables: MarkInvoiceAsIssuedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Accounting_Mutation",
    "kind": "LinkedField",
    "name": "accounting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Accounting_MarkInvoiceAsIssuedPayload",
        "kind": "LinkedField",
        "name": "markInvoiceAsIssued",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkInvoiceAsIssuedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkInvoiceAsIssuedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e29a20e15ed91da287c9c24715c77f5c",
    "id": null,
    "metadata": {},
    "name": "MarkInvoiceAsIssuedMutation",
    "operationKind": "mutation",
    "text": "mutation MarkInvoiceAsIssuedMutation(\n  $input: Accounting_MarkInvoiceAsIssuedInput!\n) {\n  accounting {\n    markInvoiceAsIssued(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ebefd2f7bf165c6cbc63a710fd38cb0";

export default node;

import React from 'react';
import classnames from 'classnames';

import styles from '../ShopifyAuthDrawer.pcss';

import Accordion from 'Components/ui/Accordion/Accordion';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Text from 'Components/ui/Text/Text';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Button from 'Components/ui/Button/Button';
import ShopifyAuthInstructionImg_1 from 'Images/modals/shopify-auth-instruction-1.jpg';
import ShopifyAuthInstructionImg_2 from 'Images/modals/shopify-auth-instruction-2.jpg';
import ShopifyAuthInstructionImg_3 from 'Images/modals/shopify-auth-instruction-3.jpg';

type Props = {
  titleMsg: string;
  className?: string;
  onNextButtonClick?: () => void;
};

const CheckYourPermissions: React.FC<Props> = (props) => {
  const { className, titleMsg, onNextButtonClick } = props;

  return (
    <div className={classnames(styles.contentRoot, className)}>
      <TextPreset header={<Text type="d2" msg={titleMsg} />} />
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <Text type="md" msg="shopify_auth_drawer.check_your_permissions.info_content_1" />
        </div>
        <div className={styles.textWrapper}>
          <Text type="md" msg="shopify_auth_drawer.check_your_permissions.info_content_2" />
        </div>
        <div className={styles.textWrapper}>
          <Text type="md" msg="shopify_auth_drawer.check_your_permissions.info_content_3" />
        </div>
        <div className={styles.textWrapperWithoutVerticalPadding}>
          <Accordion
            items={[
              {
                arrowPosition: 'left',
                title: (
                  <Text
                    type="md"
                    msg="shopify_auth_drawer.check_your_permissions.accordion.title"
                  />
                ),
                content: (
                  <div>
                    <Text
                      type="md"
                      msg="shopify_auth_drawer.check_your_permissions.accordion.content"
                    />
                    <br />
                    <div className={styles.textWrapperWithoutHorizontalPadding}>
                      <Text
                        type="md"
                        msg="shopify_auth_drawer.check_your_permissions.instruction.1"
                      />
                    </div>
                    <img
                      src={ShopifyAuthInstructionImg_1}
                      alt="Picture"
                      className={styles.shopifyAuthInstructionImg}
                    />
                    <div className={styles.textWrapperWithoutHorizontalPadding}>
                      <Text
                        type="md"
                        msg="shopify_auth_drawer.check_your_permissions.instruction.2"
                      />
                    </div>
                    <img
                      src={ShopifyAuthInstructionImg_2}
                      alt="Picture"
                      className={styles.shopifyAuthInstructionImg}
                    />
                    <div className={styles.textWrapperWithoutHorizontalPadding}>
                      <Text
                        type="md"
                        msg="shopify_auth_drawer.check_your_permissions.instruction.3"
                      />
                    </div>
                    <img
                      src={ShopifyAuthInstructionImg_3}
                      alt="Picture"
                      className={styles.shopifyAuthInstructionImg}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <ButtonPreset className={styles.buttonWrapper}>
        <Button
          fluid
          color="black"
          dataTest="button:next"
          className={styles.nextButton}
          onClick={onNextButtonClick}
          msg="shopify_auth_drawer.next_button"
        />
      </ButtonPreset>
    </div>
  );
};

export { CheckYourPermissions };

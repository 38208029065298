import React from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import styles from './FilePreview.css';

import { setModal } from 'Actions/Modal.Actions';
import { File } from 'Types/common';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Atoms/Icon/Icon';
import Text from 'Atoms/Text/Text';
import PopupLink from 'Components/PopupLink/PopupLink';
import { IMAGE, VIDEO, OTHER } from 'Constants/general';
import { MEDIA } from 'Constants/modals';

interface Props {
  canDelete?: boolean;
  canOpenPreview?: boolean;
  className?: string;
  contentClassName?: string;
  file?: File | null;
  url?: string | null;
  id: string;
  onDeleteClick?: (id: string) => void;
  onPreviewOpen?: () => void;
}

const FilePreview: React.FC<Props> = (props: Props) => {
  const {
    canDelete = true,
    canOpenPreview = true,
    className,
    contentClassName,
    file,
    url,
    id,
    onDeleteClick,
    onPreviewOpen,
  } = props;

  const dispatch = useDispatch();

  if (!file && !url) {
    return (
      <div className={`${className} ${styles.loading}`}>
        <Spinner color="white" />
      </div>
    );
  }

  const secureUrl = file?.secureUrl || url;
  const thumbnailUrl = file?.transformations?.collageThumbnailUrl || url;
  const videoUrl = file?.transformations?.autoUrl;
  const type = file?.type || IMAGE;
  const filename = file?.originalFilename || file?.filename || '';

  const isVideo = type === VIDEO;
  const isOther = type === OTHER;

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick(id);
    }
  };

  const handlePreviewClick =
    !isOther && canOpenPreview
      ? () => {
          if (onPreviewOpen) {
            onPreviewOpen();
          }
          const mediaData = isVideo ? { videoUrl } : { thumbnailUrl: secureUrl };
          dispatch(setModal(MEDIA, { ...mediaData }));
        }
      : undefined;

  const classes = classnames(className, styles.root, {
    [styles.canDelete]: canDelete,
    [styles.isOther]: isOther,
  });

  return (
    <div className={classes}>
      <div
        onClick={handlePreviewClick}
        className={`${styles.preview} ${contentClassName}`}
        style={isOther ? undefined : { backgroundImage: `url(${thumbnailUrl})` }}
      />
      {isVideo && (
        <div className={styles.video}>
          <Icon color="white" name="play" />
        </div>
      )}
      {isOther && (
        <PopupLink to={secureUrl || ''} download className={styles.document}>
          <Icon name="document" className={styles.documentIcon} />
          {filename && <Text text={filename} />}
        </PopupLink>
      )}
      {canDelete && (
        <div className={styles.deleteIcon} onClick={handleDeleteClick}>
          <Icon color="white" size="sm" name="deleteIconSm" />
        </div>
      )}
    </div>
  );
};

export default FilePreview;

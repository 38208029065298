/**
 * @generated SignedSource<<c48dfc5f28dbc2f5f8c49109224fe3d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateBrandInput = {
  active?: boolean | null | undefined;
  categoryId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  logoId?: string | null | undefined;
  name?: string | null | undefined;
  summary?: string | null | undefined;
  websiteUrl?: string | null | undefined;
};
export type CreateBrandMutation$variables = {
  input: CreateBrandInput;
};
export type CreateBrandMutation$data = {
  readonly createBrand: {
    readonly brand: {
      readonly active: boolean;
      readonly category: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly id: string;
      readonly logo: {
        readonly transformations?: {
          readonly brandLogoUrl: string;
        } | null | undefined;
      } | null | undefined;
      readonly name: string | null | undefined;
      readonly summary: string | null | undefined;
      readonly websiteUrl: string | null | undefined;
    };
  } | null | undefined;
};
export type CreateBrandMutation = {
  response: CreateBrandMutation$data;
  variables: CreateBrandMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "websiteUrl",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandLogoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateBrandMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBrandPayload",
        "kind": "LinkedField",
        "name": "createBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Brand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "logo",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateBrandMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBrandPayload",
        "kind": "LinkedField",
        "name": "createBrand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Brand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "logo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9782bc3a2baa4df629fa0fff3265b898",
    "id": null,
    "metadata": {},
    "name": "CreateBrandMutation",
    "operationKind": "mutation",
    "text": "mutation CreateBrandMutation(\n  $input: CreateBrandInput!\n) {\n  createBrand(input: $input) {\n    brand {\n      id\n      active\n      name\n      websiteUrl\n      logo {\n        __typename\n        ... on Image {\n          transformations {\n            brandLogoUrl\n          }\n        }\n        id\n      }\n      category {\n        id\n        name\n      }\n      summary\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61a80e956892b5af2a46b5812c691a62";

export default node;

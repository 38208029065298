import React, { useState } from 'react';
import classnames from 'classnames';

import styles from './ShipmentReport.pcss';

import IconNew from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import ReportButton, { ReportType } from 'Atoms/ReportButton/ReportButton';
import { IconName } from 'Components/ui/types';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  campaignId: string;
  className: string;
}

const ShipmentReport: React.FC<Props> = (props) => {
  const { campaignId, className } = props;
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState<boolean | undefined>(undefined);

  const handleDownloadReportDone = () => {
    setLoading(false);
    setSucceeded(true);
  };
  const handleDownloadReportFailed = () => {
    setLoading(false);
    setSucceeded(false);
  };

  const handleDownloadReportStart = () => {
    setLoading(true);
    amplitude.sendEvent({
      id: '194',
      category: 'campaign',
      name: 'download_shipment_click',
      param: {
        campaign_id: campaignId,
      },
    });
  };

  let iconName = 'Download-upload';

  if (succeeded) {
    iconName = 'Check';
  } else if (succeeded === false) {
    iconName = 'Warning-error';
  }

  const disabled = !!succeeded;

  return (
    <ReportButton
      type={ReportType.SHIPMENT}
      reportData={{ campaignId }}
      onDownload={handleDownloadReportDone}
      onDownloadError={handleDownloadReportFailed}
    >
      <TextPreset
        className={classnames(className, {
          [styles.disabled]: loading,
        })}
        header={<Text msg="campaign.drawer.download_shipment.title" type="md" />}
        subtitle={
          <Text msg="campaign.drawer.download_shipment.description" color="grey" type="s" />
        }
        handleClick={disabled ? undefined : handleDownloadReportStart}
        rightComponent={loading ? <Spinner size="sm" /> : <IconNew name={iconName as IconName} />}
      />
    </ReportButton>
  );
};

export default ShipmentReport;

/**
 * @generated SignedSource<<4e06646413659e82d9b940e7d053dc21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CampaignObjective = "APP_INSTALLS" | "BRAND_AWARENESS" | "CATALOG_SALES" | "CONVERSIONS" | "ENGAGEMENT" | "JUST_CONTENT" | "LEAD_GENERATION" | "MESSAGES" | "REACH" | "STORE_TRAFFIC" | "TRAFFIC" | "VIDEO_VIEWS" | "%future added value";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT" | "%future added value";
export type ContentCreationPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type ContentCreationType = "VIDEO_EDITING" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
export type UpdateCampaignInput = {
  adminComment?: string | null | undefined;
  brandId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  contentCreationPlatform?: ContentCreationPlatform | null | undefined;
  contentCreationType?: ContentCreationType | null | undefined;
  coverId?: string | null | undefined;
  csmMarkupPercentage?: number | null | undefined;
  description?: string | null | undefined;
  id: string;
  managerId?: string | null | undefined;
  name?: string | null | undefined;
  objective?: CampaignObjective | null | undefined;
  paymentType?: PaymentType | null | undefined;
  plannedAmount?: number | null | undefined;
  platform?: CampaignPlatform | null | undefined;
  postingType?: CampaignPostingType | null | undefined;
  private?: boolean | null | undefined;
  promo?: boolean | null | undefined;
  type?: CampaignType | null | undefined;
  useCase?: CampaignUseCase | null | undefined;
};
export type UpdateCampaignMutation$variables = {
  input: UpdateCampaignInput;
};
export type UpdateCampaignMutation$data = {
  readonly updateCampaign: {
    readonly campaign: {
      readonly adminComment: string | null | undefined;
      readonly brand: {
        readonly id: string;
        readonly logo: {
          readonly transformations?: {
            readonly brandLogoUrl: string;
            readonly collageThumbnailUrl: string;
          } | null | undefined;
        } | null | undefined;
        readonly name: string | null | undefined;
        readonly summary: string | null | undefined;
      } | null | undefined;
      readonly contentCreationType: ContentCreationType | null | undefined;
      readonly cover: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
      } | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly manager: {
        readonly advertiserProfile: {
          readonly firstName: string | null | undefined;
          readonly lastName: string | null | undefined;
        } | null | undefined;
        readonly email: string;
        readonly id: string;
      } | null | undefined;
      readonly name: string | null | undefined;
      readonly objective: CampaignObjective | null | undefined;
      readonly paymentType: PaymentType | null | undefined;
      readonly plannedAmount: number | null | undefined;
      readonly platform: CampaignPlatform | null | undefined;
      readonly postingType: CampaignPostingType | null | undefined;
      readonly private: boolean | null | undefined;
      readonly searchQuery: {
        readonly id: string;
      } | null | undefined;
      readonly type: CampaignType | null | undefined;
      readonly useCase: CampaignUseCase | null | undefined;
    };
  } | null | undefined;
};
export type UpdateCampaignMutation = {
  response: UpdateCampaignMutation$data;
  variables: UpdateCampaignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objective",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postingType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentCreationType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useCase",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandLogoUrl",
          "storageKey": null
        },
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminComment",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedAmount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "SearchQuery",
  "kind": "LinkedField",
  "name": "searchQuery",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCampaignPayload",
        "kind": "LinkedField",
        "name": "updateCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Brand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "logo",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "cover",
                "plural": false,
                "selections": [
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "manager",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdvertiserProfile",
                    "kind": "LinkedField",
                    "name": "advertiserProfile",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCampaignPayload",
        "kind": "LinkedField",
        "name": "updateCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Brand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "logo",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      (v13/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "cover",
                "plural": false,
                "selections": [
                  (v23/*: any*/),
                  (v16/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "manager",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdvertiserProfile",
                    "kind": "LinkedField",
                    "name": "advertiserProfile",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "870f5105450b031071796c2bf8d43360",
    "id": null,
    "metadata": {},
    "name": "UpdateCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCampaignMutation(\n  $input: UpdateCampaignInput!\n) {\n  updateCampaign(input: $input) {\n    campaign {\n      id\n      private\n      type\n      objective\n      postingType\n      paymentType\n      description\n      contentCreationType\n      platform\n      useCase\n      brand {\n        id\n        logo {\n          __typename\n          ... on Image {\n            transformations {\n              brandLogoUrl\n              collageThumbnailUrl\n            }\n          }\n          id\n        }\n        name\n        summary\n      }\n      name\n      cover {\n        __typename\n        ... on Image {\n          transformations {\n            collageThumbnailUrl\n          }\n        }\n        id\n      }\n      adminComment\n      plannedAmount\n      searchQuery {\n        id\n      }\n      manager {\n        id\n        advertiserProfile {\n          firstName\n          lastName\n          id\n        }\n        email\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64b9d528e0bb63127f5f7f5b7190699f";

export default node;

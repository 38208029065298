import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AddCreatorToCampaignShortlistInput,
  AddCreatorToCampaignShortlistMutation,
  AddCreatorToCampaignShortlistMutation$data,
} from 'GraphTypes/AddCreatorToCampaignShortlistMutation.graphql';

const mutation = graphql`
  mutation AddCreatorToCampaignShortlistMutation($input: AddCreatorToCampaignShortlistInput!) {
    addCreatorToCampaignShortlist(input: $input) {
      ... on NotFoundError {
        message
      }
    }
  }
`;

type fnType = (data: object) => void;

type Data = {
  projectId?: string;
  campaignId?: string;
};

export default (
  data: AddCreatorToCampaignShortlistInput & Data,
  resolve?: (response: AddCreatorToCampaignShortlistMutation$data) => void,
  reject?: fnType
) => {
  const { creatorId, campaignId, projectId } = data;

  const id = uuid();

  const variables = {
    input: {
      id,
      creatorId,
      campaignId,
    },
  };

  commitMutation<AddCreatorToCampaignShortlistMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (projectId) {
        const currentProject = store.get(projectId);
        currentProject?.setValue(true, 'shortlisted');
      }

      if (!campaignId) return;
      const campaign = store.get(campaignId);

      if (!campaign) return;

      const mediaplan = ConnectionHandler.getConnection(campaign, 'Projects_projects');
      if (mediaplan) {
        const mediaplanItem = mediaplan
          .getLinkedRecords('edges')
          ?.find(
            (edge) =>
              edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
          );
        if (mediaplanItem) {
          const node = mediaplanItem.getLinkedRecord('node');
          node?.setValue(true, 'shortlisted');
        }
      }

      const projects = ConnectionHandler.getConnection(campaign, 'Projects_projects');
      if (projects) {
        const projectItem = projects
          ?.getLinkedRecords('edges')
          ?.find(
            (edge) =>
              edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
          );
        if (projectItem) {
          const node = projectItem.getLinkedRecord('node');
          node?.setValue(true, 'shortlisted');
        }
      }
    },
    updater: (store) => {
      if (projectId) {
        const currentProject = store.get(projectId);
        currentProject?.setValue(true, 'shortlisted');
      }

      if (!campaignId) return;
      const campaign = store.get(campaignId);

      if (!campaign) return;

      const mediaplan = ConnectionHandler.getConnection(campaign, 'Projects_projects');
      if (mediaplan) {
        const mediaplanItem = mediaplan
          .getLinkedRecords('edges')
          ?.find(
            (edge) =>
              edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
          );
        if (mediaplanItem) {
          const node = mediaplanItem.getLinkedRecord('node');
          node?.setValue(true, 'shortlisted');
        }
      }

      const projects = ConnectionHandler.getConnection(campaign, 'Projects_projects');
      if (projects) {
        const projectItem = projects
          ?.getLinkedRecords('edges')
          ?.find(
            (edge) =>
              edge?.getLinkedRecord('node')?.getLinkedRecord('creator')?.getDataID() === creatorId
          );
        if (projectItem) {
          const node = projectItem.getLinkedRecord('node');
          node?.setValue(true, 'shortlisted');
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

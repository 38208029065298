/**
 * @generated SignedSource<<b9117443cfa4d01a6357aec0e8cf7479>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type MediaType = "IMAGE" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SponsoredPosts_creator$data = {
  readonly profile: {
    readonly topSponsoredPosts: ReadonlyArray<{
      readonly commentCount: number | null | undefined;
      readonly description: string | null | undefined;
      readonly dislikeCount: number | null | undefined;
      readonly likeCount: number | null | undefined;
      readonly link: string | null | undefined;
      readonly mediaType: MediaType | null | undefined;
      readonly thumbnailUrl: string | null | undefined;
      readonly viewCount: number | null | undefined;
    }>;
  } | null | undefined;
  readonly type: CreatorType;
  readonly " $fragmentType": "SponsoredPosts_creator";
};
export type SponsoredPosts_creator$key = {
  readonly " $data"?: SponsoredPosts_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"SponsoredPosts_creator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SponsoredPosts_creator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Post",
          "kind": "LinkedField",
          "name": "topSponsoredPosts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "link",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnailUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "likeCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dislikeCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "commentCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};

(node as any).hash = "eba624bd3c2436d18fd3585dd88c9809";

export default node;

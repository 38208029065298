/**
 * @generated SignedSource<<b8c8cd13b43d61540aed6042451603e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrandsListPaginationQuery$variables = {
  brandName?: string | null | undefined;
  count: number;
  cursor?: string | null | undefined;
};
export type BrandsListPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BrandsList_brands">;
};
export type BrandsListPaginationQuery = {
  response: BrandsListPaginationQuery$data;
  variables: BrandsListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "kind": "Variable",
  "name": "brandName",
  "variableName": "brandName"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandLogoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "logo",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "creator",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandsListPaginationQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          }
        ],
        "kind": "FragmentSpread",
        "name": "BrandsList_brands"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BrandsListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BrandConnection",
        "kind": "LinkedField",
        "name": "brands",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Brand",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "summary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "websiteUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "stages",
                        "value": [
                          "ACTIVE",
                          "COMPLETED"
                        ]
                      }
                    ],
                    "concreteType": "CampaignConnection",
                    "kind": "LinkedField",
                    "name": "campaigns",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "campaigns(stages:[\"ACTIVE\",\"COMPLETED\"])"
                  },
                  {
                    "alias": "activeCampaigns",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "stages",
                        "value": [
                          "ACTIVE"
                        ]
                      }
                    ],
                    "concreteType": "CampaignConnection",
                    "kind": "LinkedField",
                    "name": "campaigns",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Campaign",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Brand",
                                "kind": "LinkedField",
                                "name": "brand",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "campaigns(stages:[\"ACTIVE\"])"
                  },
                  {
                    "alias": "completedCampaigns",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "stages",
                        "value": [
                          "COMPLETED"
                        ]
                      }
                    ],
                    "concreteType": "CampaignConnection",
                    "kind": "LinkedField",
                    "name": "campaigns",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Campaign",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Brand",
                                "kind": "LinkedField",
                                "name": "brand",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "logo",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "campaigns(stages:[\"COMPLETED\"])"
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "Brands_brands",
        "kind": "LinkedHandle",
        "name": "brands"
      }
    ]
  },
  "params": {
    "cacheID": "656ba5c856630de21dc852f65af7cb03",
    "id": null,
    "metadata": {},
    "name": "BrandsListPaginationQuery",
    "operationKind": "query",
    "text": "query BrandsListPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $brandName: String\n) {\n  ...BrandsList_brands_3vQsXq\n}\n\nfragment Brand_brand on Brand {\n  id\n  active\n  logo {\n    __typename\n    id\n    thumbnailUrl\n    ... on Image {\n      transformations {\n        brandLogoUrl\n      }\n    }\n  }\n  name\n  summary\n  websiteUrl\n  category {\n    id\n    name\n  }\n  campaigns(stages: [ACTIVE, COMPLETED]) {\n    totalCount\n  }\n  activeCampaigns: campaigns(stages: [ACTIVE]) {\n    edges {\n      node {\n        id\n        name\n        platform\n        brand {\n          logo {\n            __typename\n            id\n            thumbnailUrl\n            ... on Image {\n              transformations {\n                brandLogoUrl\n              }\n            }\n          }\n          id\n        }\n        creator {\n          id\n        }\n      }\n    }\n  }\n  completedCampaigns: campaigns(stages: [COMPLETED]) {\n    edges {\n      node {\n        id\n        name\n        platform\n        brand {\n          logo {\n            __typename\n            thumbnailUrl\n            ... on Image {\n              transformations {\n                brandLogoUrl\n              }\n            }\n            id\n          }\n          id\n        }\n        creator {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment BrandsList_brands_3vQsXq on Query {\n  brands(first: $count, after: $cursor, brandName: $brandName) {\n    edges {\n      node {\n        id\n        ...Brand_brand\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61c6216e681a7a4a84da919386e091a5";

export default node;

import { graphql } from 'react-relay';

import type {
  EngagementCountersChartQuery as EngagementCountersChartQueryType,
  EngagementCountersChartQuery$variables,
  Performance_Granularity,
  EngagementCountersChartQuery$data,
} from 'GraphTypes/EngagementCountersChartQuery.graphql';

const EngagementCountersChartQuery = graphql`
  query EngagementCountersChartQuery(
    $brandId: ID!
    $campaignIds: [ID!]
    $dateFrom: Date!
    $dateTo: Date!
    $granularity: Performance_Granularity!
    $platform: CampaignPlatform
    $campaignType: CampaignType
    $campaignPostingType: CampaignPostingType
    $useCase: CampaignUseCase
  ) {
    performance {
      engagementCounters(
        brandId: $brandId
        campaignIds: $campaignIds
        dateFrom: $dateFrom
        dateTo: $dateTo
        granularity: $granularity
        platform: $platform
        campaignType: $campaignType
        campaignPostingType: $campaignPostingType
        campaignUseCase: $useCase
      ) {
        edges {
          node {
            engagementCount
            likesCount
            commentsCount
            sharesCount
            savedCount
            dateFrom
            dateTo
          }
        }
        totalEngagementCount
        totalLikesCount
        totalCommentsCount
        totalSharesCount
        totalSavedCount
      }
    }
  }
`;

export { EngagementCountersChartQuery };

// types

export type {
  Performance_Granularity,
  EngagementCountersChartQueryType,
  EngagementCountersChartQuery$data,
  EngagementCountersChartQuery$variables,
};

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Provider as IntlProvider } from 'react-intl-redux';
import { RelayEnvironmentProvider } from 'react-relay';
import { Action, Store } from 'redux';
import * as amplitude from '@amplitude/analytics-browser';

import environment from 'Api/Environment';
import GuideTourContainer from 'Containers/GuideTour';
import AppRouter from 'Containers/AppRouter/AppRouter';
import FeatureFlagsContainer from 'Containers/FeatureFlags/FeatureFlagsContainer';
import track from 'Analytics/track';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';

interface Props {
  store: Store<unknown, Action<unknown>>;
  storyBookMode?: boolean;
}

const AppRoot: React.FC<PropsWithChildren<Props>> = (props) => {
  const { store, storyBookMode, children } = props;
  const [app, setApp] = useState<JSX.Element | null>(null);

  useEffect(() => {
    amplitude.init(process.env.AMPLITUDE_API_KEY, {
      defaultTracking: false,
    });
    firebaseClient.authClient.onAuthStateChanged(() => {
      setApp(<AppRouter />);
    });
  }, []);

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Provider store={store}>
        <IntlProvider store={store}>
          <FeatureFlagsContainer>
            <GuideTourContainer>{storyBookMode ? children : app}</GuideTourContainer>
          </FeatureFlagsContainer>
        </IntlProvider>
      </Provider>
    </RelayEnvironmentProvider>
  );
};

export const StorybookRoot: React.FC<PropsWithChildren<Props>> = (props) => {
  const { store, children } = props;

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Provider store={store}>
        <IntlProvider store={store}>{children}</IntlProvider>
      </Provider>
    </RelayEnvironmentProvider>
  );
};

export default track('frontend-app')(AppRoot);

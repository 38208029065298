/**
 * @generated SignedSource<<e68b01e505bb19df30558583df1d5e92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Collaboration_collaboration$data = {
  readonly brands: {
    readonly totalCount: number;
  } | null | undefined;
  readonly completedProjects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly campaign: {
          readonly brand: {
            readonly logo: {
              readonly transformations?: {
                readonly brandLogoUrl: string;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly platform: CampaignPlatform | null | undefined;
          readonly stage: CampaignStage;
        };
        readonly creator: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly creator: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreatorDetails_creator">;
  };
  readonly notCompletedProjects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly campaign: {
          readonly brand: {
            readonly logo: {
              readonly transformations?: {
                readonly brandLogoUrl: string;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly platform: CampaignPlatform | null | undefined;
          readonly stage: CampaignStage;
        };
        readonly creator: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Collaboration_collaboration";
};
export type Collaboration_collaboration$key = {
  readonly " $data"?: Collaboration_collaboration$data;
  readonly " $fragmentSpreads": FragmentRefs<"Collaboration_collaboration">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "platform",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Brand",
              "kind": "LinkedField",
              "name": "brand",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "logo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "brandLogoUrl",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Collaboration_collaboration",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandConnection",
      "kind": "LinkedField",
      "name": "brands",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "completedProjects",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "notCompletedProjects",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreatorDetails_creator"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Collaboration",
  "abstractKey": null
};
})();

(node as any).hash = "39940096c2cf5abebfd71afae3c1f801";

export default node;

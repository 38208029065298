import React, { useContext } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './SearchParams.pcss';
import { createData, formatSearchQuery, getParmas, createParamsKit } from './data';

import environment from 'Api/Environment';
import updateSearchQuery from 'Mutations/UpdateSearchQuery.Mutation';
import { SearchParamsQuery as QueryType } from 'GraphTypes/SearchParamsQuery.graphql';
import { INSTAGRAM, TIKTOK } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import CreatorFilter from 'AdvertiserPage/Brief/BriefTemplate/BriefTemplateContent/CreatorsDetails/CreatorFilter/CreatorFilter';
import Params from 'Components/SearchParams/Params';
import { UpdateSearchQueryInput } from 'GraphTypes/UpdateSearchQueryMutation.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

const SearchParamsQuery = graphql`
  query SearchParamsQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      platform
      type
      postingType
      private
      organization {
        currency
        customLists {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      ...CreatorsSearchWithParams_campaign
      searchQuery {
        customLists {
          id
          name
        }
        id
        ageRanges {
          id
          name
        }
        audienceCities {
          id
          name
        }
        audienceGenderPercentage
        audienceAgeRangePercentage
        audienceCountryPercentage
        audienceCityPercentage
        audienceGender {
          id
          name
        }
        categories {
          id
          name
        }
        cities {
          id
          name
        }
        countries {
          id
          name
        }
        tags {
          id
          name
        }
        creatorCountries {
          id
          name
        }
        currency
        creatorEthnicities {
          id
          name
        }
        creatorAnimals {
          id
          name
        }
        audienceEthnicities {
          id
          name
        }
        creatorBirthdateFrom
        creatorBirthdateTo
        engagementRateFrom
        engagementRateTo
        followersFrom
        followersTo
        realFollowersFrom
        realFollowersTo
        viewsPerPostFrom
        viewsPerPostTo
        viewsMedianFrom
        viewsMedianTo
        storiesReachFrom
        storiesReachTo
        # interests
        audienceInterestPercentage
        postsReachFrom
        postsReachTo
        reelsPlaysMedianFrom
        reelsPlaysMedianTo
        campaignsCompletionsCountFrom
        campaignsCompletionsCountTo
        onlyCreatorsWorkedWith
        creatorGender {
          id
          name
        }
        creatorLanguages {
          id
          name
        }
        creatorBodyTypes {
          id
          name
        }
        creatorHairTypes {
          id
          name
        }
        excludeBadges
        strictlyByPrimaryCategory
        includeAmazonStorefrontLink
        favorite
        withTiktokShop
      }
    }
    ageRanges {
      id
      name
    }
    countries {
      id
      name
    }
    genders(currency: RUB) {
      id
      name
    }
    ethnicities(visible: true) {
      id
      name
    }
    categories {
      id
      name
    }
    languages {
      id
      name
    }
    animals {
      id
      name
    }
    bodyTypes {
      id
      name
    }
    hairTypes {
      id
      name
    }
    tags(currency: USD) {
      id
      name
    }
  }
`;

interface Props {
  campaignId: string;
  isAdmin?: boolean;
  handleSuggestedFilters?: () => void;
  suggestedFilters?: Omit<UpdateSearchQueryInput, 'id'> & { analyticId?: number };
}

const SearchParamsContainer: React.FC<Props> = (props) => {
  const { campaignId, isAdmin, handleSuggestedFilters, suggestedFilters } = props;

  const params = getParmas(isAdmin);
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const hasNewCases = hasFeatureFlag('use_cases_5389');
  const ignoredParams = hasNewCases ? ['withTiktokShop'] : [];

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={SearchParamsQuery}
      variables={{ campaignId }}
      render={({ props: queryProps }) => {
        if (!queryProps) return null;

        const campaign = queryProps.campaign;

        if (!campaign) return null;

        const { searchQuery } = campaign;

        if (!searchQuery) return null;

        const { id: searchQueryId } = searchQuery;

        const handleParamChange = (data: any) => {
          const newData = createData(data);
          const filtersForAmplitude = Object.keys(newData)
            .filter((filterName) => newData[filterName])
            .reduce((filters, filterName) => {
              filters[filterName] = newData[filterName];
              return filters;
            }, {});
          amplitude.sendEvent({
            id: '225',
            category: 'campaign',
            name: 'creation_use_filters',
            param: { applied_filters: filtersForAmplitude },
          });
          updateSearchQuery({ id: searchQueryId, searchQuery: newData });
        };
        const searchQueryValue = formatSearchQuery(searchQuery);

        const platform = campaign.platform;

        const filtersKit = createParamsKit(queryProps);

        const currency = campaign.organization?.currency;

        if (platform !== TIKTOK && (searchQuery.viewsPerPostFrom || searchQuery.viewsPerPostTo)) {
          updateSearchQuery({
            id: searchQueryId,
            searchQuery: { viewsPerPostFrom: null, viewsPerPostTo: null },
          });
        }

        if (platform !== TIKTOK && (searchQuery.viewsMedianFrom || searchQuery.viewsMedianTo)) {
          updateSearchQuery({
            id: searchQueryId,
            searchQuery: { viewsMedianFrom: null, viewsMedianTo: null },
          });
        }

        if (
          platform !== INSTAGRAM &&
          (searchQuery.storiesReachFrom ||
            searchQuery.storiesReachTo ||
            searchQuery.postsReachFrom ||
            searchQuery.postsReachTo ||
            searchQuery.reelsPlaysMedianFrom ||
            searchQuery.reelsPlaysMedianTo ||
            searchQuery.audienceInterestPercentage)
          // searchQuery.interestIds
        ) {
          updateSearchQuery({
            id: searchQueryId,
            searchQuery: {
              storiesReachFrom: null,
              storiesReachTo: null,
              postsReachFrom: null,
              postsReachTo: null,
              audienceInterestPercentage: null,
              interestIds: null,
            },
          });
        }

        if (
          platform !== INSTAGRAM &&
          (searchQuery.realFollowersFrom || searchQuery.realFollowersTo)
        ) {
          updateSearchQuery({
            id: searchQueryId,
            searchQuery: {
              realFollowersFrom: null,
              realFollowersTo: null,
            },
          });
        }

        return (
          <div>
            <div data-test="searchParams:creatorFilters" className={styles.newFilterContainer}>
              <CreatorFilter
                filtersState={searchQueryValue}
                filtersKit={filtersKit}
                campaignType={campaign.type}
                platform={campaign.platform}
                isAdmin={isAdmin}
                handleParamChange={handleParamChange}
                campaign={campaign}
                currency={currency}
                handleSuggestedFilters={handleSuggestedFilters}
                suggestedFilters={suggestedFilters}
              />
            </div>
            <div className={styles.oldFilterContainer}>
              <Params
                paramsKit={params}
                searchParams={searchQueryValue}
                onChange={handleParamChange}
                ignoredParams={ignoredParams}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default SearchParamsContainer;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  ArchiveUnlaunchedCreatorProjectsInput,
  ArchiveUnlaunchedCreatorProjectsMutation,
  ArchiveUnlaunchedCreatorProjectsMutation$data,
} from 'GraphTypes/ArchiveUnlaunchedCreatorProjectsMutation.graphql';

const mutation = graphql`
  mutation ArchiveUnlaunchedCreatorProjectsMutation(
    $input: ArchiveUnlaunchedCreatorProjectsInput!
  ) {
    archiveUnlaunchedCreatorProjects(input: $input) {
      operation {
        id
        completed
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ArchiveUnlaunchedCreatorProjectsInput,
  resolve?: (response: ArchiveUnlaunchedCreatorProjectsMutation$data) => void,
  reject?: fnType
) => {
  const { creatorIds } = data;

  const variables = {
    input: {
      creatorIds,
    },
  };
  commitMutation<ArchiveUnlaunchedCreatorProjectsMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

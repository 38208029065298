import React, { useState } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './UserSettings.pcss';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import Workspace from './Workspace/Workspace';
import Team from './Team/Team';

import { amplitude } from 'Helpers/amplitude';
import Page from 'Templates/Page/Page';
import Tabs from 'Molecules/Tabs/Tabs';
import TextButton from 'Components/ui/TextButton/TextButton';
import { UserSettingsQuery as QueryType } from 'GraphTypes/UserSettingsQuery.graphql';

type sectionType = 'PERSONAL_INFO' | 'WORKSPACE' | 'TEAM';

const tabItems = [
  {
    textData: { msg: 'advertiser.settings.personal_info.tab' },
    id: 'PERSONAL_INFO',
  },
  {
    textData: { msg: 'advertiser.settings.workspace.tab' },
    id: 'WORKSPACE',
  },
  {
    textData: { msg: 'advertiser.settings.team.tab' },
    id: 'TEAM',
  },
];

const UserSettingsQuery = graphql`
  query UserSettingsQuery {
    currentUser {
      organization {
        id
      }
      ...PersonalInfo_user
      ...Workspace_user
      ...Team_user
    }
  }
`;

const UserSettings = () => {
  const { state } = useLocation();
  const defaultSection = state?.section || 'PERSONAL_INFO';
  const [section, setSection] = useState<sectionType>(defaultSection);

  const navigate = useNavigate();

  const handleSectionSelect = (id: sectionType) => {
    if (id === 'WORKSPACE') {
      amplitude.sendEvent({
        id: 219,
        category: 'advertiser_settings',
        name: 'workspace_settings_click',
      });
    } else if (id === 'TEAM') {
      amplitude.sendEvent({
        id: 220,
        category: 'advertiser_settings',
        name: 'team_click',
      });
    }
    setSection(id);
  };

  const { currentUser } = useLazyLoadQuery<QueryType>(UserSettingsQuery, {});
  const organization = currentUser?.organization;
  if (!organization) return null;

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Page className={styles.root}>
      <div className={styles.content}>
        <TextButton
          icon="Arrow-big-left"
          msg="route.back"
          onClick={handleBackClick}
          className={styles.back}
        />
        <div className={styles.tabs}>
          <Tabs currentItemId={section} items={tabItems} onChange={handleSectionSelect} />
        </div>
        {section === 'PERSONAL_INFO' && <PersonalInfo user={currentUser} />}
        {section === 'WORKSPACE' && <Workspace user={currentUser} />}
        {section === 'TEAM' && <Team user={currentUser} />}
      </div>
    </Page>
  );
};

export default UserSettings;

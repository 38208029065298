import React from 'react';
import { Element } from 'react-scroll';

import styles from './Dialog.pcss';
import ProjectEvents from './ProjectEvents/ProjectEvents';
import ProjectActions from './ProjectActions/ProjectActions';
import ProjectCreatorDetails from './ProjectCreatorDetails/ProjectCreatorDetails';

interface Props {
  projectId: string;
  isOldView: boolean;
  inPopup?: boolean;
  isAdvertiser?: boolean;
}

const DialogContent: React.FC<Props> = (props) => {
  const { projectId, isOldView, inPopup, isAdvertiser } = props;

  return (
    <div className={styles.dialog}>
      {!isOldView && <ProjectCreatorDetails projectId={projectId} />}
      <Element name="events" className={styles.projectEventsWrap} id="events">
        <div className={styles.projectEvents}>
          <ProjectEvents projectId={projectId} inPopup={inPopup} isAdvertiser={isAdvertiser} />
        </div>
      </Element>
      <div className={styles.projectActions}>
        <ProjectActions projectId={projectId} isOldView={isOldView} />
      </div>
    </div>
  );
};

export default DialogContent;

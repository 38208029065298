import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  MarkCreatorAsFailedReviewMutation,
  MarkCreatorAsFailedReviewMutation$data,
  MarkCreatorAsFailedReviewInput,
} from 'GraphTypes/MarkCreatorAsFailedReviewMutation.graphql';
import { FAILURE } from 'Constants/general';

const mutation = graphql`
  mutation MarkCreatorAsFailedReviewMutation($input: MarkCreatorAsFailedReviewInput!) {
    markCreatorAsFailedReview(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: MarkCreatorAsFailedReviewInput,
  resolve?: (response: MarkCreatorAsFailedReviewMutation$data) => void,
  reject?: fnType
) => {
  const { creatorId, reason } = data;

  const variables = {
    input: {
      creatorId,
      reason,
    },
  };

  commitMutation<MarkCreatorAsFailedReviewMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const creator = store.get(creatorId);
      if (creator) {
        const id = uuid();
        const review = store.create(id, 'CreatorReview');
        review.setValue(FAILURE, 'type');
        review.setValue(reason, 'reason');
        review.setValue('USER', 'source');
        creator.setLinkedRecord(review, 'review');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

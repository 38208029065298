import React from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { getIcon } from './data';
import styles from './CampaignData.css';

import { amplitude } from 'Helpers/amplitude';
import { setModal } from 'Actions/Modal.Actions';
import { createTextWithLinks, escapeText, getOuterLink } from 'Util/textFormatter';
import { createSum } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import NewIcon from 'Components/ui/Icon/Icon';
import { IconName } from 'Components/ui/types';
import Button from 'Atoms/Button/Button';
import TextButton from 'Atoms/TextButton/TextButton';
import Avatar from 'Atoms/Avatar/Avatar';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { barterInfoData } from 'Constants/messageModal';
import { Currency } from 'GraphTypes/ListingBriefQuery.graphql';
import { CampaignData_campaign$key } from 'GraphTypes/CampaignData_campaign.graphql';
import { modalName } from 'Types/modals';
import { TextType } from 'Types/common';
import {
  BARTER_TYPE,
  INFLUENCER,
  BCA_INFO,
  BCA_INFO_FOR_CREATORS,
  PRODUCT_SEEDING,
  LONG_TERM,
} from 'Constants/general';

type InfoData = {
  key?: string;
  iconName?: string;
  newIconName?: IconName;
  titleData: TextType;
  subtitleData: TextType;
  handleReadMoreClick?: () => void;
};

interface Props {
  campaign?: CampaignData_campaign$key;
  currency?: Currency | null;
  isCreator: boolean;
  mobileView?: boolean;
}

const CampaignData: React.FC<Props> = (props) => {
  const { campaign, isCreator, mobileView, currency: projectCurrency } = props;

  const data = useFragment(
    graphql`
      fragment CampaignData_campaign on Campaign {
        cover {
          ... on Image {
            thumbnailUrl
          }
        }
        paymentType
        name
        type
        platform
        description
        brand {
          name
          summary
          websiteUrl
          logo {
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
        brief {
          __typename
          ... on V2Brief {
            productDescription
            productDelivery
            productPrice
            productUrl
            paidSocialBca
            paidSocial
            paidSocialActivationDays
            sparkAdsActivationDays
            priceLimitMin
            priceLimitMax
            includeReferralFee
            referralFeeRate
            taskDescription
          }
        }
      }
    `,
    campaign
  );

  const dispatch = useDispatch();
  const tracking = useTracking();
  const intl = useIntl();
  const currency = projectCurrency || 'USD';

  const brief = data?.brief;

  if (!data || !brief || (brief && brief.__typename !== 'V2Brief')) {
    return null;
  }

  const {
    cover,
    paymentType,
    name: campaignName,
    type: campaignType,
    platform: campaignPlatform,
    brand,
    description,
  } = data;

  const brandName = brand?.name;
  const brandLogo = brand?.logo?.transformations?.collageThumbnailUrl;
  const brandSummary = brand?.summary;
  const brandWebsiteUrl = brand?.websiteUrl;

  const isBarter = paymentType === BARTER_TYPE;
  const isSeedingCampaign = campaignType === 'PRODUCT_SEEDING';

  const {
    productDescription,
    productUrl,
    productPrice,
    paidSocialActivationDays,
    paidSocial,
    paidSocialBca,
    sparkAdsActivationDays,
    productDelivery,
    priceLimitMin,
    priceLimitMax,
    includeReferralFee,
    referralFeeRate,
    taskDescription,
  } = brief;

  const platform = campaignPlatform?.toLowerCase();
  const platformText = platform
    ? intl.formatMessage({ id: `brief.campaign.platform.${platform}` })
    : '';

  const createInfoItem = (data: InfoData) => {
    const { iconName, newIconName, titleData, subtitleData, key, handleReadMoreClick } = data;

    return (
      <div key={key} className={`${styles.infoItem} ${styles.item}`}>
        {newIconName && <NewIcon name={newIconName} />}
        {iconName && <Icon name={iconName} color="black" />}
        <div className={styles.infoContent}>
          <Text tag="h3" weight="700" {...titleData} className={styles.infoTitle} />
          <div>
            <Text {...subtitleData} display="inline" />
            {handleReadMoreClick && !mobileView && (
              <TextButton
                msg={'general.read_more'}
                onClick={handleReadMoreClick}
                display="inline"
                className={styles.readMore}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const brandAvaData = brandLogo ? { src: brandLogo } : { letters: brandName };

  const createDeliveryInfo = () => {
    if (!productDelivery) {
      return null;
    }

    let deiveryData;

    if (productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS') {
      deiveryData = {
        iconName: 'deliveryCar',
        titleData: { msg: 'brief.campaign.delivery.shipment.title' },
        subtitleData: { msg: 'brief.campaign.delivery.shipment.descr' },
      };
    } else if (productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT') {
      deiveryData = {
        iconName: 'shoppingBag',
        titleData: { msg: 'brief.campaign.delivery.shopping.title' },
        subtitleData: { msg: 'brief.campaign.delivery.shopping.descr' },
      };
    } else if (productDelivery === 'COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT') {
      deiveryData = {
        iconName: 'noDelivery',
        titleData: { msg: 'brief.campaign.delivery.no_product.title' },
        subtitleData: { msg: 'brief.campaign.delivery.no_product.descr' },
      };
    }

    if (!deiveryData) {
      return null;
    }

    return createInfoItem(deiveryData);
  };

  const handlePsInfoClick = () => {
    const name = isCreator ? modalName.CONNECT_PS : modalName.PS_INFO;
    const data = isCreator
      ? { onlyView: true, psDays: paidSocialActivationDays, psBca: paidSocialBca }
      : {};
    dispatch(setModal(name, data));
  };

  const handleBcaInfoClick = () => {
    const link = isCreator ? BCA_INFO_FOR_CREATORS : BCA_INFO;
    window.open(link, '_blank');
  };

  const handleBarterInfoClick = () => {
    tracking.trackEvent({
      element: 'barter_read_more',
      event: 'clicked',
    });
    dispatch(setModal(modalName.MESSAGE, { ...barterInfoData }));
  };

  const handleBrandUrlClick = () => {
    if (isCreator) {
      amplitude.sendEvent({ id: 91, category: 'campaign', name: 'creator_brand_website' });
    }
  };

  const handleProductUrlClick = () => {
    if (isCreator) {
      amplitude.sendEvent({ id: 90, category: 'campaign', name: 'creator_product_website' });
    }
  };

  const isLongTermCampaign = campaignType === LONG_TERM;
  const rootStyles = mobileView ? styles.mobileView : '';

  const coverUrl = isLongTermCampaign ? brandLogo : cover?.thumbnailUrl;

  return (
    <div className={rootStyles}>
      <div style={{ backgroundImage: `url(${coverUrl})` }} className={styles.cover} />
      <div className={styles.content}>
        <Text tag="h1" weight="700" text={campaignName} className={styles.title} />
        <div>
          {platform &&
            createInfoItem({
              iconName: getIcon(platform),
              titleData: (() => {
                if (campaignType === PRODUCT_SEEDING) {
                  return {
                    msg: 'brief.product_seeding.title',
                    values: { platform: platformText },
                  };
                }
                return campaignType === INFLUENCER
                  ? { msg: `brief.campaign.platform.${platform}.campaign` }
                  : { msg: `brief.content_creation.title`, values: { platform: platformText } };
              })(),
              subtitleData: (() => {
                if (campaignType === PRODUCT_SEEDING) {
                  return {
                    msg: 'brief.product_seeding.descr',
                  };
                }
                return campaignType === INFLUENCER
                  ? {
                      msg: `brief.campaign.platform.descr`,
                      values: {
                        account: intl.formatMessage({ id: `brief.campaign.platform.${platform}` }),
                      },
                    }
                  : {
                      msg: 'brief.content_creation.descr',
                      values: { platform: platformText },
                    };
              })(),
            })}
          {priceLimitMin !== null &&
            Number(priceLimitMin) > 0 &&
            priceLimitMax !== null &&
            Number(priceLimitMax) > 0 &&
            createInfoItem({
              iconName: 'moneyMax',
              titleData: {
                msg: 'brief.money_price_range',
                values: {
                  min: createSum(priceLimitMin, currency),
                  max: createSum(priceLimitMax, currency),
                },
              },
              subtitleData: { msg: 'brief.money_price_range.descr' },
            })}
          {!priceLimitMin &&
            priceLimitMax &&
            createInfoItem({
              iconName: 'moneyMax',
              titleData: {
                msg: 'brief.money_price_range.only_mav',
                values: { max: createSum(priceLimitMax, currency) },
              },
              subtitleData: { msg: 'brief.money_price_range.descr' },
            })}
          {paidSocial &&
            !paidSocialBca &&
            paidSocialActivationDays &&
            createInfoItem({
              iconName: 'targetAd',
              titleData: { msg: 'brief.ps_days_descr', values: { days: paidSocialActivationDays } },
              subtitleData: {
                msg: 'brief.paid_social_descr',
                values: { days: paidSocialActivationDays },
              },
              handleReadMoreClick: handlePsInfoClick,
            })}
          {paidSocialBca &&
            createInfoItem({
              iconName: 'targetAd',
              titleData: { msg: 'brief.bca_title' },
              subtitleData: {
                msg: 'brief.bca_descr',
              },
              handleReadMoreClick: handleBcaInfoClick,
            })}
          {sparkAdsActivationDays &&
            createInfoItem({
              iconName: 'targetAd',
              titleData: {
                msg: 'brief.spark_ads_days.title',
                values: { days: sparkAdsActivationDays },
              },
              subtitleData: {
                msg: 'brief.spark_ads_days.descr',
                values: { days: sparkAdsActivationDays },
              },
            })}
          {createDeliveryInfo()}
          {!isBarter &&
            includeReferralFee &&
            createInfoItem({
              titleData: {
                msg: 'brief.campaign.referral.title',
                values: { rate: referralFeeRate },
              },
              subtitleData: {
                msg: 'brief.campaign.referral.description',
                values: { rate: referralFeeRate },
              },
              iconName: 'salesCommissionPercent',
            })}
          {productPrice &&
            createInfoItem({
              newIconName: 'Money',
              titleData: {
                msg: 'brief.campaign.product_price.title',
                values: { price: createSum(productPrice) },
              },
              subtitleData: {
                msg: 'brief.campaign.product_price.descr',
              },
            })}
        </div>
        {productDescription && (
          <div className={styles.section}>
            <Text>
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: createTextWithLinks(escapeText(productDescription)),
                }}
              />
            </Text>

            {productUrl && !mobileView && (
              <SimpleLink
                href={getOuterLink(productUrl)}
                target="_blank"
                display="block"
                className={styles.websiteBtn}
                onClick={handleProductUrlClick}
              >
                <Button
                  width="full"
                  size="lg"
                  color="secondary"
                  contentAlign="center"
                  msg="brief.open_product_website"
                  rightIcon={<Icon name="openLink" />}
                />
              </SimpleLink>
            )}
          </div>
        )}
        {description && (
          <div className={styles.section}>
            <Text text={description} />
          </div>
        )}
        {isSeedingCampaign && taskDescription && (
          <div className={styles.section}>
            <Text tag="h2" weight="700" msg="brief.deliverables" />
            <Text text={taskDescription} />
          </div>
        )}
        <div className={styles.section}>
          <div className={styles.brandHeader}>
            <div>
              <Text tag="h2" weight="700" text={brandName} />
              <Text msg="brief.about_brand" />
            </div>
            <Avatar size="52" {...brandAvaData} />
          </div>
          {brandSummary && <Text text={brandSummary} />}
          {brandWebsiteUrl && !mobileView && (
            <SimpleLink
              href={getOuterLink(brandWebsiteUrl)}
              target="_blank"
              display="block"
              className={styles.websiteBtn}
              onClick={handleBrandUrlClick}
            >
              <Button
                width="full"
                size="lg"
                color="secondary"
                contentAlign="center"
                msg="brief.open_brand_website"
                rightIcon={<Icon name="openLink" />}
              />
            </SimpleLink>
          )}
        </div>
      </div>
    </div>
  );
};

const CampaignDataWrap = track({ subsection: 'campaign_data' })(CampaignData);

export default CampaignDataWrap;

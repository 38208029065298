import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import map from 'lodash/map';
import { isMobile, isTablet, MobileView, BrowserView } from 'react-device-detect';

import styles from './MsgField.pcss';
import Attach from './Attach/Attach';
import AttachList from './AttachList/AttachList';
import { AttachContext, Types } from './Attach.Context';

import sendImg from 'Images/chat/send.svg';
import createMessage from 'Mutations/CreateMessage.Mutation';
import Text from 'Components/ui/Text/Text';
import createPublicationEventMutation from 'Mutations/CreatePublicationEvent.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import TextButton from 'Components/ui/TextButton/TextButton';

interface Props {
  projectId: string;
  needCheckPostUrls?: boolean | null;
  isOldView?: boolean;
}

const MsgField: FC<Props> = ({ projectId, needCheckPostUrls, isOldView = true }) => {
  const [value, setValue] = useState<string>('');
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [postingUrls, setPostingUrls] = useState<string[]>([]);
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const { state: attachState, dispatch: attachDispatch } = useContext(AttachContext);

  const files = attachState.filesList;

  const intl = useIntl();

  const handleSendClick = () => {
    if (isSendingMessage) return;

    if (needCheckPostUrls) {
      checkPostingMessage(value);
    }

    const attachmentIds = map(files, 'id');

    if (value.trim() || files.length) {
      setIsSendingMessage(true);
      createMessage(
        { projectId, text: value, attachmentIds, files },
        handleSendComplete,
        handleSendComplete
      );
    }
    setValue('');
  };

  const handleSendComplete = () => {
    attachDispatch({ type: Types.DELETE_FILES });
    setIsSendingMessage(false);
  };

  const handleKeydown = (e: React.KeyboardEvent) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSendClick();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const checkPostingMessage = useCallback<(val: string) => void>((val) => {
    const expression =
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi;
    const regex = new RegExp(expression);
    const strings = val.replace(/\n/g, ' ').split(' ');
    const urls = strings.filter(
      (item) =>
        item.match(regex) &&
        (item.toLowerCase().includes('instagram') || item.toLowerCase().includes('tiktok'))
    );

    if (urls.length !== 0) {
      setPostingUrls(urls);
    }
  }, []);

  const handleSavePostingUrls = useCallback(() => {
    createPublicationEventMutation({
      publicationUrls: postingUrls,
      projectId,
    });
    setPostingUrls([]);
    closeDrawer('chat-post-links-recognised');
  }, [createPublicationEventMutation, postingUrls]);

  useEffect(() => {
    if (postingUrls.length > 0) {
      openDrawer('chat-post-links-recognised');
    }
  }, [postingUrls]);

  const handleCancelPostingUrls = useCallback(() => {
    setPostingUrls([]);
    closeDrawer('chat-post-links-recognised');
  }, [setPostingUrls]);

  const placeholderText = intl.formatMessage({ id: 'chat.type_message' });

  const newView = !isOldView;

  return (
    <div className={classnames(styles.root, { [styles.newView]: newView })}>
      <Drawer rootKey={'chat-post-links-recognised'} className={styles.drawer}>
        <Text msg="chat.posting_links.title" type="d2" />
        <Text msg="chat.posting_links.description" type="md" />
        <div className={styles.buttonContainer}>
          <Button
            msg="chat.posting_links.cancelBtn"
            color="white"
            fluid
            onClick={handleCancelPostingUrls}
          />
          <Button
            msg="chat.posting_links.acceptBtn"
            color="black"
            fluid
            onClick={handleSavePostingUrls}
          />
        </div>
      </Drawer>
      <AttachList />
      <div className={classnames(styles.field, { [styles.isMobile]: isMobile })}>
        <Attach />
        <div className={styles.content}>
          <div>
            <Textarea
              maxRows={10}
              className={styles.text}
              placeholder={placeholderText}
              value={value}
              onKeyPress={handleKeydown}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.send} onClick={handleSendClick}>
          <MobileView>
            <Text color="grayDog" weight="500" className={styles.button} msg="chat.send" />
          </MobileView>
          {!isTablet && isOldView && (
            <BrowserView>
              <img alt="Sending img" src={sendImg} />
            </BrowserView>
          )}
          {!isOldView && (
            <BrowserView>
              <TextButton msg="chat.in_modal.message.btn" />
            </BrowserView>
          )}
        </div>
      </div>
    </div>
  );
};

export default MsgField;

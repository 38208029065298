import React, { useContext } from 'react';

import styles from './RowErrors.pcss';

import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';

const RowErrors = () => {
  const { rowErrors, setShowRowErrors } = useContext(InviteOutreachCreatorsContext);

  const handleErrorsHide = () => {
    setShowRowErrors(false);
  };

  return (
    <>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg="invite_outreach_creators_modal.errors" className={styles.title} />
        <div className={styles.content}>
          <table className={styles.errorsTable}>
            {(rowErrors || []).map((item) => {
              return (
                <tr key={item.index} className={styles.tab}>
                  <td className={styles.tableIndex}>
                    <Text type="md" text={item.index} className={styles.text} />
                  </td>
                  <td className={styles.tableUsername}>
                    <Text type="md" text={item.username} className={styles.text} />
                  </td>
                  <td className={styles.tableEmail}>
                    <Text type="md" text={item.email} className={styles.text} />
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <div className={styles.customClose} onClick={handleErrorsHide}>
        <Icon name="Close-small" />
      </div>
    </>
  );
};

export default RowErrors;

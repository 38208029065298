import React, { useCallback, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import FbAdsAction from '../FbAdsAction/FbAdsAction';
import FinishDealAction from '../FinishDealAction/FinishDealAction';

import styles from './ContentSubmittedStage.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import markContentReviewAsPassed from 'Mutations/MarkContentReviewAsPassed.Mutation';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { ContentSubmittedStage_project$key } from 'GraphTypes/ContentSubmittedStage_project.graphql';

interface Props {
  project: ContentSubmittedStage_project$key;
  canUsePaidSocial: boolean;
}

const ContentSubmittedStage: React.FC<Props> = (props) => {
  const { project, canUsePaidSocial } = props;

  const { openDrawer } = useContext(DrawerContext);

  const data = useFragment<ContentSubmittedStage_project$key>(
    graphql`
      fragment ContentSubmittedStage_project on Project {
        id
        campaign {
          type
          publishingRequired
        }
        ...FbAdsAction_project
        ...FinishDealAction_project
      }
    `,
    project
  );

  const handleApprove = useCallback(() => {
    if (publishingRequired) {
      openDrawer(`project-publication-date-${data?.id}`);
    }
    markContentReviewAsPassed({ projectId: data?.id || '' });
  }, [data?.id]);

  const handleReject = useCallback(() => {
    openDrawer(`request-revision-${data?.id}`);
  }, [data?.id]);

  if (!data) {
    return null;
  }

  const { campaign } = data;

  const publishingRequired = campaign.publishingRequired;
  const campaignType = campaign.type;
  const requestRevisionBtn =
    campaignType !== 'PRODUCT_SEEDING' ? (
      <AlterButton
        type="grey"
        icon="Edit"
        msg="chat.in_modal.action.request_review"
        onClick={handleReject}
      />
    ) : null;

  const approveBtn = publishingRequired ? (
    <AlterButton
      type="black"
      msg="chat.in_modal.action.approve_post"
      onClick={handleApprove}
      className={styles.btn}
    />
  ) : (
    <FinishDealAction
      project={data}
      finishDealMsg={
        campaignType === 'PRODUCT_SEEDING'
          ? 'chat.in_modal.action.complete'
          : 'chat.in_modal.action.approve_pay'
      }
    />
  );

  return (
    <>
      {approveBtn}
      {requestRevisionBtn}
      {canUsePaidSocial && <FbAdsAction project={data} />}
    </>
  );
};

export default ContentSubmittedStage;

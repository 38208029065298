import React, { useState, useEffect } from 'react';
import { QueryRenderer, useClientQuery, useQueryLoader } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import Skeleton from '../../components/Skeleton/Skeleton';
import SectionHeader from '../../components/SectionHeader/SectionHeader';

import styles from './CustomList.pcss';
import CustomListCreators from './CustomListCreators/CustomListCreators';
import {
  CustomListQuery,
  CustomListQueryType,
  CustomListInfoQuery,
  CustomListInfoQueryType,
} from './CustomList.Query';

import environment from 'Api/Environment';
import Input from 'Components/ui/Input/Input';
import { CREATORS_LIST_ROUTE } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';

const defaultFilters: Filters = {
  textQuery: '',
};

type Props = {
  renderStartHeaderControls?: () => React.ReactNode;
  customListId: string;
};

const CustomList: React.FC<Props> = (props) => {
  const { customListId, renderStartHeaderControls } = props;
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const navigate = useNavigate();
  const [, loadCustomListInfo] = useQueryLoader<CustomListInfoQueryType>(CustomListInfoQuery);
  const textQuery = filters.textQuery;

  const customListInfo = useClientQuery<CustomListInfoQueryType>(CustomListInfoQuery, {
    id: customListId,
  });

  const listName = customListInfo.customList?.name || '';

  useEffect(() => {
    if (listName) {
      amplitude.sendEvent<276>({
        id: '276',
        category: 'creator_lists',
        name: 'list_visit',
        param: {
          list_name: listName,
        },
      });
    }
  }, [listName]);

  useEffect(() => {
    loadCustomListInfo({ id: customListId });
  }, [customListId]);

  const handleTextQueryChange = (text: string) => {
    setFilters({ ...filters, textQuery: text });
  };

  const handleParamsClear = () => {
    setFilters({ ...defaultFilters });
  };

  const handleTextQueryFocus = () => {
    //
  };

  const handleBackButtonClick = () => {
    navigate(CREATORS_LIST_ROUTE);
  };

  return (
    <div className={styles.root}>
      {customListInfo.customList && (
        <>
          <div className={styles.headerControls}>
            <SectionHeader
              creatorsCount={customListInfo.customList?.creators?.totalCount || 0}
              headerTextProps={{ text: listName }}
              onBackButtonClick={handleBackButtonClick}
            />
            {renderStartHeaderControls && (
              <div className={styles.startHeaderControls}>{renderStartHeaderControls?.()}</div>
            )}
            <div className={styles.endHeaderControls}>
              <Input
                value={textQuery}
                placeholderMsg="campaign.creators_search"
                className={styles.textQuery}
                rightIcon="Search-loop"
                onFocus={handleTextQueryFocus}
                handleChange={handleTextQueryChange}
              />
            </div>
          </div>
          <QueryRenderer<CustomListQueryType>
            environment={environment}
            query={CustomListQuery}
            variables={{ id: customListId, textQuery }}
            render={({ props: queryProps }) => {
              if (!queryProps?.customList) return <Skeleton />;

              const customList = queryProps.customList;

              const isAdmin = queryProps.currentUser?.admin;
              const isChurned = queryProps.currentUser?.organization?.churned;
              const isNewClient = queryProps.currentUser?.organization?.newClient;
              const isPlanPaused = Boolean(
                queryProps.currentUser?.organization?.subscription?.paused
              );

              const isBlockedView = !isAdmin && (isChurned || isNewClient || isPlanPaused);

              return (
                <CustomListCreators
                  id={customListId}
                  customList={customList}
                  textQuery={textQuery}
                  onParamsClear={handleParamsClear}
                  isBlockedView={isBlockedView}
                  isPlanPaused={isPlanPaused}
                />
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default CustomList;

// types

interface Filters {
  textQuery: string;
}

import { createShortNumber } from 'Util/numberFormatter';
import { Creative_creative$data } from 'GraphTypes/Creative_creative.graphql';
import { IconName } from 'Components/ui/types';

type CreativeDataType = {
  creative: Creative_creative$data;
};

type StatsType = Partial<{
  impressions: string;
  playCount: string;
  reach: string;
  er: string;
  videoViews: string;
  tikTokER: string;
}>;

type StatData = {
  icon: IconName;
  value: string;
  tooltipMsg?: string;
}[];

type CreateStatsData = (data: CreativeDataType) => StatData;
export const createStatsData: CreateStatsData = ({ creative }) => {
  const { postPublication } = creative;
  if (!postPublication) return [];

  const { instagramInsightsMedia, tiktokInsightsMedia } = postPublication;
  if (!instagramInsightsMedia && !tiktokInsightsMedia) return [];

  const {
    likeCount,
    commentCount,
    playCount: playCountValue,
    placement,
    finalStoryInsightsCollected,
    savedCount,
    shareCount,
    reach,
    impressions,
  } = instagramInsightsMedia || {};

  const {
    videoViews: ttViews,
    comments: ttComments,
    likes: ttLikes,
    shares: ttShares,
  } = tiktokInsightsMedia || {};

  const stubValue = '—';

  const playCount = playCountValue ? createShortNumber(playCountValue) : stubValue;

  const stats: StatsType = {};

  if (instagramInsightsMedia) {
    if (placement === 'FEED') {
      return [
        {
          icon: 'Eye',
          value: impressions ? createShortNumber(impressions) : stubValue,
          tooltipMsg: 'creative.impressions',
        },
        {
          icon: 'Heart',
          value: likeCount ? createShortNumber(likeCount) : stubValue,
          tooltipMsg: 'creative.likes',
        },
        {
          icon: 'Chat',
          value: commentCount ? createShortNumber(commentCount) : stubValue,
          tooltipMsg: 'creative.comments',
        },
        {
          icon: 'Save-list',
          value: savedCount ? createShortNumber(savedCount) : stubValue,
          tooltipMsg: 'creative.saved',
        },
      ];
    } else if (placement === 'STORY') {
      if (!finalStoryInsightsCollected) return [];
      return [
        {
          icon: 'Eye',
          value: reach ? createShortNumber(reach) : stubValue,
          tooltipMsg: 'creative.impressions',
        },
        {
          icon: 'Group-users',
          value: impressions ? createShortNumber(impressions) : stubValue,
          tooltipMsg: 'creative.reach',
        },
      ];
    } else if (placement === 'REEL') {
      return [
        {
          icon: 'Eye',
          value: playCount ? createShortNumber(playCount) : stubValue,
          tooltipMsg: 'creative.plays',
        },
        {
          icon: 'Heart',
          value: likeCount ? createShortNumber(likeCount) : stubValue,
          tooltipMsg: 'creative.likes',
        },
        {
          icon: 'Chat',
          value: commentCount ? createShortNumber(commentCount) : stubValue,
          tooltipMsg: 'creative.comments',
        },
        {
          icon: 'Send',
          value: shareCount ? createShortNumber(shareCount) : stubValue,
          tooltipMsg: 'creative.shares',
        },
      ];
    }
  } else if (tiktokInsightsMedia) {
    return [
      {
        icon: 'Eye',
        value: ttViews ? createShortNumber(ttViews) : stubValue,
        tooltipMsg: 'creative.views',
      },
      {
        icon: 'Heart',
        value: ttLikes ? createShortNumber(ttLikes) : stubValue,
        tooltipMsg: 'creative.likes',
      },
      {
        icon: 'Chat',
        value: ttComments ? createShortNumber(ttComments) : stubValue,
        tooltipMsg: 'creative.comments',
      },
      {
        icon: 'Send',
        value: ttShares ? createShortNumber(ttShares) : stubValue,
        tooltipMsg: 'creative.shares',
      },
    ];
  }

  return [];
};

import React, { useState } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './CreatorsCount.pcss';

import AddAllCreatorsDrawer from '../AddAllCreatorsDrawer/AddAllCreatorsDrawer';

import { createNumber } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import TextButton from 'Components/ui/TextButton/TextButton';
import { SearchResultsContainerQuery$variables } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { CreatorsCountQuery as QueryType } from 'GraphTypes/CreatorsCountQuery.graphql';

const MAX_CREATORS_TO_BRAKE_ALL = 1000;

const CreatorsCountQuery = graphql`
  query CreatorsCountQuery($campaignId: ID!, $searchQueryInput: SearchQueryInput) {
    campaign(id: $campaignId) {
      stage
      searchCreators(searchQueryInput: $searchQueryInput) {
        totalCount
      }
    }
    currentUser {
      admin
    }
  }
`;

interface Props {
  campaignId: string;
  searchQueryInput: SearchResultsContainerQuery$variables['searchQueryInput'];
}

const CreatorsCount: React.FC<Props> = (props) => {
  const { campaignId, searchQueryInput } = props;
  const [isOpenedAddCreatorsDrawer, setCreatorsDrawerStatus] = useState(false);

  const data = useLazyLoadQuery<QueryType>(CreatorsCountQuery, { campaignId, searchQueryInput });

  const totalCount = Number(data.campaign?.searchCreators?.totalCount);
  const isAdmin = data.currentUser?.admin;

  if (!isAdmin) return null;

  const isActiveCampaign = data.campaign?.stage === 'ACTIVE';
  const needToShowBulkInviteButton = isAdmin && isActiveCampaign;
  const isCreatorsCountExceedsMax = totalCount >= MAX_CREATORS_TO_BRAKE_ALL;

  const handleAddAllCreatorsToCampaign = () => {
    if (isCreatorsCountExceedsMax) return;
    setCreatorsDrawerStatus(true);
  };

  const handleCloseCreatorsDrawer = () => {
    setCreatorsDrawerStatus(false);
  };

  return (
    <>
      <Text
        size="sm"
        color="grayDog"
        msg="browse_creators.search_results"
        values={{
          totalCount: createNumber(totalCount),
          totalCountNum: totalCount,
        }}
        className={styles.summary}
      />
      {needToShowBulkInviteButton && (
        <>
          <Tooltip
            place="top"
            id="add-all-creators"
            tooltipMsg={
              isCreatorsCountExceedsMax
                ? 'browse_creators.add_all_tooltip.disabled'
                : 'browse_creators.add_all_tooltip'
            }
            className={styles.tooltip}
          >
            <TextButton
              msg={'browse_creators.add_all_button'}
              disabled={isCreatorsCountExceedsMax}
              onClick={handleAddAllCreatorsToCampaign}
            />
          </Tooltip>
          {!isCreatorsCountExceedsMax && (
            <AddAllCreatorsDrawer
              opened={isOpenedAddCreatorsDrawer}
              searchParams={searchQueryInput}
              campaignId={campaignId}
              creatorsCount={totalCount}
              handleClose={handleCloseCreatorsDrawer}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreatorsCount;

import React, { useContext } from 'react';

import styles from './CancelOfferAcceptedSubscriptionDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import moment from 'moment';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CurrentSubscriptionInfo_organization$data } from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';
import { createNumber, createSum } from 'Util/numberFormatter';
import { periodToSubscriptionIntervalMap } from '../useApplyOfferDiscount';

interface Props {
  subscription: NonNullable<CurrentSubscriptionInfo_organization$data['subscription']>;
}

const CancelOfferAcceptedSubscriptionDrawer: React.FC<Props> = (props) => {
  const { subscription } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const handleCompleteClick = () => {
    closeDrawer('cancel-subscription-offer-accepted-drawer');
  };

  const period = periodToSubscriptionIntervalMap[subscription?.interval || 'TRIMONTHLY'];
  const price = subscription.priceAmount || 0;
  const offerDiscount = Number(subscription.lastSchedule?.discount?.percentOff) / 100 || 0;
  const priceSumDiscount = createSum(price - price * offerDiscount, 'USD');
  const date = moment(subscription.renewsAt).format('MMM DD, YYYY');

  return (
    <Drawer
      rootKey={'cancel-subscription-offer-accepted-drawer'}
      className={styles.drawerWrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      needCloseButton
      removeWhenClosed
    >
      <div className={styles.root}>
        <div className={styles.formContainer}>
          <Text type="d2" msg="billing.cancel.accepted.drawer.title" className={styles.title} />
          <Text
            type="md"
            msg="billing.cancel.accepted.drawer.subtitle"
            className={styles.subtitle}
            formatValues={{
              price: priceSumDiscount,
              period,
              date,
            }}
          />
          <div className={styles.checkContainer}>
            <div className={styles.checkIcon} />
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <Button
            color="black"
            fluid
            msg="billing.cancel.accepted.drawer.button.account"
            onClick={handleCompleteClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default CancelOfferAcceptedSubscriptionDrawer;

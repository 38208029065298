import { graphql } from 'react-relay';

import type {
  CustomListEditDrawer_customList$key,
  CustomListEditDrawer_customList$data,
} from 'GraphTypes/CustomListEditDrawer_customList.graphql';

const CustomListEditDrawerFragment = graphql`
  fragment CustomListEditDrawer_customList on CustomList {
    id
    name
    creators {
      totalCount
    }
  }
`;

export { CustomListEditDrawerFragment };

// types

export type { CustomListEditDrawer_customList$key, CustomListEditDrawer_customList$data };

import React from 'react';

import styles from './CampaignInvite.pcss';

import Brief from 'Templates/Brief/Brief';
import Header from 'Templates/Layout/Header/Header';
import Page from 'Templates/Page/Page';
import { CampaignInviteWrapperQuery as QueryType } from 'GraphTypes/CampaignInviteWrapperQuery.graphql';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { CampaignInviteWrapperQuery } from './CampaignInviteWrapper';

interface Props {
  queryRef: PreloadedQuery<QueryType, Record<string, unknown>>;
}

const CampaignInvite: React.FC = (props: Props) => {
  const { queryRef } = props;
  const data = usePreloadedQuery(CampaignInviteWrapperQuery, queryRef);

  const campaignId = data.projectForOffer?.campaign.id;

  if (!campaignId) return null;

  return (
    <Page>
      <Header showStaticHeader classes={{ wrap: styles.header }} />
      <Brief campaignId={campaignId} outreachType={'invite'} />
    </Page>
  );
};

export default CampaignInvite;

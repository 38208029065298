import { useState } from 'react';

import ls from 'Util/localStorage';

const ONBOARDING_KEY = 'shipment_onboarding';
const tooltipKeys: ShipmentOnboardingTypes[] = [
  'shipment_visited',
  'brief',
  'simple_chat',
  'campaign_tab',
  'shipment_tab',
];

export const useShopifyOnboarding = (): IUseShopifyOnboarding => {
  const [onboardingData, setOnboardingData] = useState<Partial<
    Record<ShipmentOnboardingTypes, boolean>
  > | null>(ls.get(ONBOARDING_KEY));

  const isPassed = (typeKey: ShipmentOnboardingTypes) =>
    (!!onboardingData && onboardingData[typeKey]) || false;

  const onStatePassed = (typeKey: ShipmentOnboardingTypes) => {
    if (!onboardingData) {
      const newData = { [typeKey]: true };
      ls.set(ONBOARDING_KEY, newData);
      setOnboardingData(newData);
    } else {
      onboardingData[typeKey] = true;
      ls.set(ONBOARDING_KEY, onboardingData);
      setOnboardingData(onboardingData);
    }
  };

  const completeOnboarding = () => {
    tooltipKeys.forEach((key) => onStatePassed(key));
  };

  return {
    isPassed,
    onStatePassed,
    completeOnboarding,
  };
};

interface IUseShopifyOnboarding {
  isPassed: (key: ShipmentOnboardingTypes) => boolean;
  onStatePassed: (key: ShipmentOnboardingTypes) => void;
  completeOnboarding: () => void;
}

type ShipmentOnboardingTypes =
  | 'brief'
  | 'simple_chat'
  | 'campaign_tab'
  | 'shipment_tab'
  | 'shipment_visited';

/**
 * @generated SignedSource<<cb733b306068ab23a1de6708d09c864c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShipmentInformation_project$data = {
  readonly campaign: {
    readonly id: string;
    readonly shopifyAuthorization: {
      readonly id: string;
    } | null | undefined;
    readonly showShipmentTab: boolean;
  };
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly offer: {
    readonly acceptance: {
      readonly shippingInformation: string | null | undefined;
      readonly shippingRecipientFirstName: string | null | undefined;
      readonly shippingRecipientLastName: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly shippingAddress: {
    readonly address1: string;
    readonly address2: string | null | undefined;
    readonly city: string;
    readonly country: {
      readonly iso2Code: string;
      readonly name: string;
      readonly provinces: ReadonlyArray<{
        readonly code: string;
        readonly name: string;
      }> | null | undefined;
    } | null | undefined;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly phone: string;
    readonly provinceCode: string | null | undefined;
    readonly zip: string;
  } | null | undefined;
  readonly " $fragmentType": "ShipmentInformation_project";
};
export type ShipmentInformation_project$key = {
  readonly " $data"?: ShipmentInformation_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShipmentInformation_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentInformation_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ShippingAddress",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "iso2Code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Province",
              "kind": "LinkedField",
              "name": "provinces",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provinceCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shippingInformation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shippingRecipientFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shippingRecipientLastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showShipmentTab",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopifyAuthorization",
          "kind": "LinkedField",
          "name": "shopifyAuthorization",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "96e7403f5f48a34abcf4205fa0e8df31";

export default node;

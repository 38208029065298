export const getReadableDuration = (duration: number): string => {
  if (!duration || duration < 0) return '-';
  const result = new Date(duration * 1000);

  return (
    `${result.getMinutes().toString().padStart(2, '0')}:${result
      .getSeconds()
      .toString()
      .padStart(2, '0')}` || '-'
  );
};

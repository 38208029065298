import React from 'react';

import styles from './TopupBalance.pcss';

import TopupBalanceContainer from './TopupBalanceContainer/TopupBalanceContainer';

import Drawer from 'Components/ui/Drawer/Drawer';

export interface Props {
  amount?: number | string;
  className?: string;
  successCallback?: () => void;
  purpose?:
    | 'TOP_UP'
    | 'TOP_UP_FOR_HIRING'
    | 'TOPUP_FOR_PRICE_CHANGE_REQUEST'
    | 'TOPUP_FOR_EXTRA_PAYMENT'
    | 'TOPUP_FOR_REIMBURSMENT_PAYMENT';
}

const TopupBalance: React.FC<Props> = (props) => {
  const { amount, successCallback, purpose, className } = props;

  return (
    <Drawer rootKey={`topup-balance-${purpose?.toLowerCase() || ''}`} className={styles.drawer}>
      <TopupBalanceContainer
        className={className}
        amount={amount}
        successCallback={successCallback}
        purpose={purpose}
      />
    </Drawer>
  );
};
export default TopupBalance;

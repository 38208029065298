import { graphql, useMutation } from 'react-relay';

import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';
import { PauseSubscriptionMutation as MutationType } from 'GraphTypes/PauseSubscriptionMutation.graphql';

const PauseSubscriptionMutation = graphql`
  mutation PauseSubscriptionMutation($input: PauseSubscriptionInput!) {
    pauseSubscription(input: $input) {
      ... on PauseSubscriptionPayload {
        advertiserSubscription {
          paused
          pauseEvent {
            pausedAt
            resumesAt
          }
        }
      }
    }
  }
`;

export const usePauseSubscriptionMutation: BaseMutation<MutationType> = (config) => {
  const [pauseSubscription, loading] = useMutation<MutationType>(PauseSubscriptionMutation);

  const pauseSubscriptionCaller: BaseMutationExecFn<MutationType> = (execConfig) => {
    pauseSubscription({
      ...config,
      ...execConfig,
    });
  };
  return [pauseSubscriptionCaller, loading];
};

import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './InstagramInsightsError.css';

import { createDateWithTime } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import { InstagramInsightsError_creator$key } from 'GraphTypes/InstagramInsightsError_creator.graphql';

interface Props {
  creator: InstagramInsightsError_creator$key;
}

const InstagramInsightsError: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment InstagramInsightsError_creator on Creator {
        ownership {
          owner {
            email
          }
        }
        ... on InstagramCreator {
          latestInsightsAuthorizationError {
            createdAt
            detail
            title
          }
          insightsAuthorized
          user {
            fullName
          }
        }
      }
    `,
    creator
  );
  const latestInsightsAuthorizationError = data.latestInsightsAuthorizationError;
  const ownership = data.ownership;
  const insightsAuthorized = data.insightsAuthorized;

  if (!latestInsightsAuthorizationError || !ownership || insightsAuthorized) return null;

  const { createdAt, title, detail } = latestInsightsAuthorizationError;
  const fullName = data.user?.fullName;
  const email = ownership.owner.email;

  const date = `(${createDateWithTime(createdAt)})`;

  return (
    <div className={styles.root}>
      <Text weight="500" transform="uppercase" msg="creator.ig_insights_info" />
      <div className={styles.container}>
        <div className={styles.row}>
          <div>
            <div className={styles.dateWrap}>
              <Text color="red" msg="creator.ps_failed" />
              <Text text={date} className={styles.date} />
            </div>
            <Text text={title} color="grayDog" />
          </div>
          <div className={styles.user}>
            <Text text={fullName} />
            <Text text={email} color="grayDog" />
          </div>
        </div>
      </div>
      {detail && (
        <div className={styles.detail}>
          <Text text={detail} />
        </div>
      )}
    </div>
  );
};

export default InstagramInsightsError;

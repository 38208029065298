import React, { MouseEventHandler, useMemo } from 'react';
import cn from 'classnames';

import styles from './IconToggler.pcss';

import HearthFilledIcon from 'Images/icons/heart-filled.svg';
import SaveListFilledIcon from 'Images/icons/save-list-filled.svg';
import SaveListFilledWhiteIcon from 'Images/icons/save-list-filled-white.svg';
import Image from 'Components/ui/Image/Image';
import Icon from 'Components/ui/Icon/Icon';

type Props = {
  className?: string;
  kind: IconKeys;
  active?: boolean;
  size?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const IconToggler: React.FC<Props> = (props) => {
  const { className, size = 24, active, kind, onClick } = props;

  const icons = useMemo<Icons>(() => {
    return {
      favorites: {
        active: <Image src={HearthFilledIcon} size={size} className={styles.activeIcon} />,
        turnOff: <Icon name="Heart" size={size} className={styles.turnOffIcon} />,
      },
      'custom-list': {
        active: <Image src={SaveListFilledIcon} size={size} className={styles.activeIcon} />,
        turnOff: <Icon name="Save-list" size={size} className={styles.turnOffIcon} />,
      },
      'custom-list-gray': {
        active: <Image src={SaveListFilledIcon} size={size} className={styles.activeIcon} />,
        turnOff: (
          <Icon
            name="Save-list"
            size={size}
            className={cn(styles.turnOffIcon, styles.customListGray)}
          />
        ),
      },
      'custom-list-dark': {
        active: <Image src={SaveListFilledIcon} size={size} className={styles.activeIcon} />,
        turnOff: (
          <Icon
            name="Save-list"
            size={size}
            className={cn(styles.turnOffIcon, styles.customListDark)}
          />
        ),
      },
      'custom-list-white-filled': {
        active: (
          <Image
            src={SaveListFilledWhiteIcon}
            size={size}
            className={cn(styles.activeIcon, styles.customListWhiteFilled)}
          />
        ),
        turnOff: <Icon name="Save-list" size={size} className={styles.turnOffIcon} />,
      },
      'arrow-small-up-down': {
        active: <Icon name="Arrow-small-up" size={size} className={styles.turnOffIcon} />,
        turnOff: <Icon name="Arrow-small-down" size={size} className={styles.turnOffIcon} />,
      },
    };
  }, [size]);

  return (
    <div className={cn(styles.root, className)} onClick={onClick}>
      {active ? icons[kind].active : icons[kind].turnOff}
    </div>
  );
};

export default IconToggler;
export { IconToggler };

// types

type IconKeys =
  | 'favorites'
  | 'custom-list'
  | 'custom-list-white-filled'
  | 'custom-list-gray'
  | 'custom-list-dark'
  | 'arrow-small-up-down';
type Icons = Record<
  IconKeys,
  {
    active: React.ReactNode;
    turnOff: React.ReactNode;
  }
>;

export type { Props as IconTogglerProps };

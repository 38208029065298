import { graphql } from 'relay-runtime';

export default graphql`
  query CampaignOutreachContentQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      type
      name
      platform
      stage
      description
      cover {
        ... on Image {
          transformations {
            collageThumbnailUrl
          }
        }
      }
      brand {
        name
        summary
        websiteUrl
        logo {
          ... on Image {
            transformations {
              brandLogoUrl
            }
          }
        }
      }
      paymentType
      ...CreativeList_campaign
      ...BriefInfo_campaign
      ...BriefContent_campaign
      ...CampaignData_campaign
      paidSocial
      brief {
        id
        __typename
        ... on V2Brief {
          productDescription
          priceLimitMin
          priceLimitMax
          productDelivery
          paidSocialBca
          includeReferralFee
          referralFeeRate
        }
      }
    }
  }
`;

import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import { getAvatar } from '../util';
import SimpleProjectEvent from '../SimpleProjectEvent/SimpleProjectEvent';
import Message from '../MessageEvent/Message/Message';

import PriceChangeEvent from './PriceChangeEvent/PriceChangeEvent';

import { createDate } from 'Util/dateCreator';
import { createSum } from 'Util/numberFormatter';
import { UserType } from 'GraphTypes/ProjectEventsQuery.graphql';
import {
  PROJECT_LAUNCH,
  DRAFT_DEADLINE,
  PROJECT_COMPLETION,
  PRICE_CHANGE,
  PRICE_CHANGE_REQUEST,
  PRICE_CHANGE_REQUEST_REJECTION,
  PRICE_CHANGE_REQUEST_ACCEPTANCE,
  PROJECT_ARCHIVATION,
  PRODUCT_SHIPMENT_COMPLETION,
  CONTENT_REVIEW_FAIL,
  CONTENT_REVIEW_PASS,
  PUBLICATION_EVENT,
  PUBLICATION_REQUEST,
  DEADLINE_CHANGE_REQUEST,
  DEADLINE_CHANGE_REQUEST_ACCEPTED,
  DEADLINE_CHANGE_REQUEST_REJECTED,
  CONTENT_REVIEW_REQUEST,
  CONTENT_REVIEW_REQUEST_FAILED,
  EXTRA_PAYOUT,
  DEADLINE,
  PUBLICATION_DEADLINE_CHANGED,
  REIMBURSEMENT,
  UNRESPONSIVE_CONTRACTOR_EVENT,
} from 'Types/enums';
import { CampaignType } from 'GraphTypes/BriefQuery.graphql';
import { SystemProjectEvent_event$key } from 'GraphTypes/SystemProjectEvent_event.graphql';

interface Props {
  event: SystemProjectEvent_event$key;
  userType?: UserType;
  productShipmentRequired?: boolean | null;
  draftDate?: string | unknown;
  campaignType?: CampaignType;
  isLastEvent?: boolean;
}

const SystemProjectEvent: React.FC<Props> = (props) => {
  const {
    event,
    userType,
    productShipmentRequired,
    campaignType,
    draftDate: oldDraftDate,
    isLastEvent = false,
  } = props;

  const data = useFragment(
    graphql`
      fragment SystemProjectEvent_event on ProjectEvent {
        id
        __typename
        createdAt

        actor {
          __typename
          id
          ... on Brand {
            logo {
              ... on Image {
                transformations {
                  brandLogoUrl
                }
              }
            }
          }
          ... on TiktokCreator {
            profile {
              profilePictureFile {
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
        }

        ... on Deadline {
          draftDate
        }
        ... on PublicationDeadlineChange {
          publicationDate
        }
        ... on ContentReviewFail {
          text
          readByContractor
        }
        ... on ProjectArchivation {
          side
        }
        ... on ExtraPayout {
          amount
          currency
        }
        ... on Reimbursement {
          amount
          currency
        }
        ... on DeadlineChangeRequest {
          draftDate
        }
        ...PriceChangeEvent_event
      }
    `,
    event
  );

  const userPostfix = useMemo(() => {
    if (userType === 'CONTRACTOR') {
      return '_creator';
    }
    return '';
  }, [campaignType, userType]);

  const userCompletedPostfix = useMemo(() => {
    if (userType === 'CONTRACTOR') {
      return '_creator';
    }
    if (campaignType === 'PRODUCT_SEEDING') {
      return '_without_payment';
    }
    return '';
  }, [campaignType, userType]);

  if (!data) {
    return null;
  }

  const typename = data.__typename;
  const createdAt = String(data.createdAt);
  const projectArchivationSide = data.side;

  const { actor } = data;
  const avaSrc =
    getAvatar(actor) ?? `https://www.gravatar.com/avatar/${actor?.id}?s=180&d=retro&r=x`;

  const getContent = () => {
    switch (typename) {
      case PUBLICATION_EVENT: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.publication_event${userPostfix}`}
          />
        );
      }
      case UNRESPONSIVE_CONTRACTOR_EVENT: {
        if (userType === 'CONTRACTOR') return null;
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg="chat.project.system_event.unresponsive_contractor_event"
          />
        );
      }
      case EXTRA_PAYOUT: {
        const { amount, currency } = data;
        const price = currency ? createSum(amount, currency) : '';
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.extra_payment${userPostfix}`}
            titleValues={{ price }}
          />
        );
      }
      case REIMBURSEMENT: {
        const { amount, currency } = data;
        const price = currency ? createSum(amount, currency) : '';
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.reimbursement_payment${userPostfix}`}
            titleValues={{ price }}
          />
        );
      }
      case PROJECT_LAUNCH: {
        // eslint-disable-next-line no-case-declarations
        const shipmentPrefix = productShipmentRequired ? '_with_shipment' : '';

        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project_launched${shipmentPrefix}${userPostfix}`}
          />
        );
      }
      case DRAFT_DEADLINE: {
        const { draftDate } = data;

        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.draft_date_changed${userCompletedPostfix}`}
            titleValues={{ date: createDate(draftDate) }}
          />
        );
      }

      case DEADLINE: {
        const { draftDate } = data;

        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.draft_date_changed${userCompletedPostfix}`}
            titleValues={{ date: createDate(draftDate) }}
          />
        );
      }

      case PUBLICATION_DEADLINE_CHANGED: {
        const { publicationDate } = data;
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.public_date_changed${userPostfix}`}
            titleValues={{ date: createDate(publicationDate) }}
          />
        );
      }
      case DEADLINE_CHANGE_REQUEST: {
        const { draftDate } = data;
        const oldDate = createDate(oldDraftDate);
        const newDate = createDate(draftDate);
        const msg = isLastEvent
          ? 'chat.deadline_change_requested'
          : 'chat.deadline_change_requested_old';
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={msg}
            titleValues={{ oldDate, newDate }}
          />
        );
      }
      case DEADLINE_CHANGE_REQUEST_ACCEPTED: {
        const { draftDate } = data;
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.deadline_change_request_accepted${userCompletedPostfix}`}
            titleValues={{ newDate: createDate(draftDate) }}
          />
        );
      }
      case DEADLINE_CHANGE_REQUEST_REJECTED: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.deadline_change_request_rejected${userCompletedPostfix}`}
          />
        );
      }
      case CONTENT_REVIEW_REQUEST: {
        return (
          <SimpleProjectEvent createdAt={createdAt} titleMsg={'chat.content_review_request'} />
        );
      }
      case CONTENT_REVIEW_REQUEST_FAILED: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={'chat.content_review_request_failed'}
          />
        );
      }
      case PROJECT_COMPLETION: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project_completed${userCompletedPostfix}`}
          />
        );
      }

      case CONTENT_REVIEW_FAIL: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { text, readByContractor } = data;

        return (
          <div>
            <Message
              id={data.id}
              avaSrc={avaSrc}
              createdAt={createdAt}
              comment={text}
              isOwner={userType === 'ADVERTISER'}
              isRead={!!readByContractor}
            />
            <SimpleProjectEvent
              createdAt={createdAt}
              titleMsg={`chat.project.system_event.content_review_fail${userPostfix}`}
              titleValues={{ date: createDate(createdAt) }}
            />
          </div>
        );
      }

      case CONTENT_REVIEW_PASS: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.content_review_pass${userPostfix}`}
          />
        );
      }

      case PROJECT_ARCHIVATION: {
        const titleMsg =
          projectArchivationSide === 'ADVERTISER'
            ? `chat.project.system_event.archivation.accepted${userPostfix}`
            : `chat.project.system_event.archivation_by_creator.accepted${userPostfix}`;

        return <SimpleProjectEvent createdAt={createdAt} titleMsg={titleMsg} />;
      }

      case PRODUCT_SHIPMENT_COMPLETION: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.shipment_completion${userPostfix}`}
          />
        );
      }

      case PUBLICATION_REQUEST: {
        return (
          <SimpleProjectEvent
            createdAt={createdAt}
            titleMsg={`chat.project.system_event.publication_request${userPostfix}`}
          />
        );
      }
      case PRICE_CHANGE:
      case PRICE_CHANGE_REQUEST:
      case PRICE_CHANGE_REQUEST_REJECTION:
      case PRICE_CHANGE_REQUEST_ACCEPTANCE: {
        return <PriceChangeEvent userPostfix={userPostfix} event={data} />;
      }

      default:
        return null;
    }
  };
  return getContent();
};

export default SystemProjectEvent;

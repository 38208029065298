import React, { useState } from 'react';

import styles from './UpdateStat.pcss';

import { useRefreshCreatorInfoMutation } from 'Mutations/RefreshCreatorInfo.Mutation';
import Text from 'Atoms/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  className?: string;
  creatorId: string;
}

const UpdateStat: React.FC<Props> = (props) => {
  const { creatorId, className } = props;

  const [error, setError] = useState('');

  const [refreshCreatorInfo, updating] = useRefreshCreatorInfoMutation({
    variables: {
      input: { creatorId },
    },
    onCompleted: (data) => {
      if (data.refreshCreatorInfo?.__typename === 'RefreshCreatorInfoPayload') return;

      switch (data.refreshCreatorInfo?.__typename) {
        case 'NotFoundError':
        case 'RefreshCreatorInfo_NoInsightsAuthorizationError':
        case 'ValidationError':
          setError(data.refreshCreatorInfo.message);
          break;

        default:
          setError('general.something_went_wrong');
          break;
      }
    },
    onError: () => {
      setError('general.something_went_wrong');
    },
  });

  const handleUpdateStats = () => {
    refreshCreatorInfo();
  };

  return (
    <div className={className}>
      <AlterButton
        type="black"
        msg="creator.stat_update_date.submit"
        className={styles.button}
        loading={updating}
        onClick={handleUpdateStats}
      />
      {error && <Text size="sm" color="red" msg={error} className={styles.error} />}
    </div>
  );
};

export default UpdateStat;

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  CreateLookalikeInput,
  CreateLookalikeMutation,
  CreateLookalikeMutation$data,
} from 'GraphTypes/CreateLookalikeMutation.graphql';

const mutation = graphql`
  mutation CreateLookalikeMutation($input: CreateLookalikeInput!) {
    createLookalike(input: $input) {
      newLookalikeEdge {
        node {
          id
          url
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateLookalikeInput,
  resolve?: (response: CreateLookalikeMutation$data) => void,
  reject?: fnType
) => {
  const { briefId, url } = data;

  const variables = {
    input: {
      briefId,
      url,
    },
  };
  commitMutation<CreateLookalikeMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const brief = store.get(briefId);
      const response = store.getRootField('createLookalike');

      if (!brief || !response) return;

      const lookalikes = ConnectionHandler.getConnection(brief, 'Brief_lookalikes');

      if (!lookalikes) return;

      const node = response?.getLinkedRecord('newLookalikeEdge').getLinkedRecord('node');
      const edge = ConnectionHandler.createEdge(store, brief, node, 'ProjectEdge');
      ConnectionHandler.insertEdgeAfter(lookalikes, edge);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

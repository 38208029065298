import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  UpdateAdvertiserProfileInput,
  UpdateAdvertiserProfileMutation$data,
  UpdateAdvertiserProfileMutation,
} from 'GraphTypes/UpdateAdvertiserProfileMutation.graphql';

const mutation = graphql`
  mutation UpdateAdvertiserProfileMutation($input: UpdateAdvertiserProfileInput!) {
    updateAdvertiserProfile(input: $input) {
      profile {
        id
        lastName
        firstName
        companyRole
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateAdvertiserProfileInput,
  resolve?: (response: UpdateAdvertiserProfileMutation$data) => void,
  reject?: fnType
) => {
  const { id, firstName, lastName } = data;
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<UpdateAdvertiserProfileMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const user = store.get(id);

      if (firstName) {
        user?.setValue(firstName, 'firstName');
      }

      if (lastName) {
        user?.setValue(lastName, 'lastName');
      }
    },
    optimisticUpdater: (store) => {
      const user = store.get(id);

      if (firstName) {
        user?.setValue(firstName, 'firstName');
      }

      if (lastName) {
        user?.setValue(lastName, 'lastName');
      }
    },
    onCompleted: (response) => {
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<5963e33ce78c97af4335af1b38cf17a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Billing_TransactionType = "CREATOR_EXPENSE" | "DEPOSIT" | "DEPOSIT_REFUND" | "EXTRA_PAYOUT" | "EXTRA_PAYOUT_REFUND" | "FULLY_MANAGED_ZEROING" | "REFERRAL_REWARD" | "REIMBURSEMENT" | "REIMBURSEMENT_REFUND" | "RESERVATION" | "SALES_PEAK_REWARD" | "SUBSCRIPTION" | "SUBSCRIPTION_REFUND" | "TRANSACTION_FEE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TransactionContainer_billingTransactions$data = {
  readonly billingTransactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any;
        readonly type: Billing_TransactionType;
        readonly " $fragmentSpreads": FragmentRefs<"TransactionItem_transaction">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "TransactionContainer_billingTransactions";
};
export type TransactionContainer_billingTransactions$key = {
  readonly " $data"?: TransactionContainer_billingTransactions$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionContainer_billingTransactions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "campaignIds"
    },
    {
      "defaultValue": 18,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "creatorIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateTo"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "types"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "billingTransactions"
        ]
      }
    ]
  },
  "name": "TransactionContainer_billingTransactions",
  "selections": [
    {
      "alias": "billingTransactions",
      "args": [
        {
          "kind": "Variable",
          "name": "brandIds",
          "variableName": "brandIds"
        },
        {
          "kind": "Variable",
          "name": "campaignIds",
          "variableName": "campaignIds"
        },
        {
          "kind": "Variable",
          "name": "creatorIds",
          "variableName": "creatorIds"
        },
        {
          "kind": "Variable",
          "name": "dateFrom",
          "variableName": "dateFrom"
        },
        {
          "kind": "Variable",
          "name": "dateTo",
          "variableName": "dateTo"
        },
        {
          "kind": "Variable",
          "name": "types",
          "variableName": "types"
        }
      ],
      "concreteType": "Billing_TransactionConnection",
      "kind": "LinkedField",
      "name": "__TransactionContainer_billingTransactions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Billing_TransactionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Billing_Transaction",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TransactionItem_transaction"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "48e35064296f6e3be1f99ffaec208cc4";

export default node;

/**
 * @generated SignedSource<<d76be55b53877fe8228a53a8344c7acb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatorContacts_project$data = {
  readonly creator: {
    readonly ownership: {
      readonly owner: {
        readonly contractorProfile: {
          readonly phoneNumber: string | null | undefined;
        } | null | undefined;
        readonly email: string;
      };
    } | null | undefined;
    readonly profile: {
      readonly contactEmail: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "CreatorContacts_project";
};
export type CreatorContacts_project$key = {
  readonly " $data"?: CreatorContacts_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorContacts_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorContacts_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactEmail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorOwnership",
          "kind": "LinkedField",
          "name": "ownership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContractorProfile",
                  "kind": "LinkedField",
                  "name": "contractorProfile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phoneNumber",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "e5ab3dd04d4a35ccb72e077c75653fd7";

export default node;

/**
 * @generated SignedSource<<df71bb95507c03a58d9c7e3fa10f05f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ShopifyAuthDrawerQuery$variables = Record<PropertyKey, never>;
export type ShopifyAuthDrawerQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly organization: {
      readonly shopifyAuthorization: {
        readonly reauthenticationRequired: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ShopifyAuthDrawerQuery = {
  response: ShopifyAuthDrawerQuery$data;
  variables: ShopifyAuthDrawerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reauthenticationRequired",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShopifyAuthDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopifyAuthorization",
                "kind": "LinkedField",
                "name": "shopifyAuthorization",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ShopifyAuthDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopifyAuthorization",
                "kind": "LinkedField",
                "name": "shopifyAuthorization",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "257b09708443974d284149398a512244",
    "id": null,
    "metadata": {},
    "name": "ShopifyAuthDrawerQuery",
    "operationKind": "query",
    "text": "query ShopifyAuthDrawerQuery {\n  currentUser {\n    id\n    organization {\n      shopifyAuthorization {\n        reauthenticationRequired\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75719a609dc9609547f05182c7748bd9";

export default node;

/**
 * @generated SignedSource<<2ce3cea50f0969e059507d53ac163302>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddCreatorToCampaignShortlistInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  creatorId: string;
  id?: string | null | undefined;
};
export type AddCreatorToCampaignShortlistMutation$variables = {
  input: AddCreatorToCampaignShortlistInput;
};
export type AddCreatorToCampaignShortlistMutation$data = {
  readonly addCreatorToCampaignShortlist: {
    readonly message?: string;
  } | null | undefined;
};
export type AddCreatorToCampaignShortlistMutation = {
  response: AddCreatorToCampaignShortlistMutation$data;
  variables: AddCreatorToCampaignShortlistMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "NotFoundError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCreatorToCampaignShortlistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addCreatorToCampaignShortlist",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCreatorToCampaignShortlistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addCreatorToCampaignShortlist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "74dff83094aa79f0d865f47ede4c4037",
    "id": null,
    "metadata": {},
    "name": "AddCreatorToCampaignShortlistMutation",
    "operationKind": "mutation",
    "text": "mutation AddCreatorToCampaignShortlistMutation(\n  $input: AddCreatorToCampaignShortlistInput!\n) {\n  addCreatorToCampaignShortlist(input: $input) {\n    __typename\n    ... on NotFoundError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "846d6af912a9ecb7be98b5ef435b4002";

export default node;

import React from 'react';
import classnames from 'classnames';

import styles from './ProductSeedingDetails.pcss';

import Text from 'Components/ui/Text/Text';

type Props = {
  className?: string;
};

const ProductSeedingDetails: React.FC<Props> = (props) => {
  const { className } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <Text msg="brief.product_seeding_details.description" type="md" className={styles.text} />
    </div>
  );
};

export default ProductSeedingDetails;

import React, { useEffect } from 'react';
import track from 'react-tracking';

import styles from './TiktokAuth.pcss';

import authenticateWithTiktok from 'Mutations/AuthenticateWithTiktok.Mutation';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import Spinner from 'Atoms/Spinner/Spinner';
import Page from 'Templates/Page/Page';
import { TIKTOK_OAUTH } from 'Constants/general';
import { AuthenticateWithTiktokMutation$data } from 'GraphTypes/AuthenticateWithTiktokMutation.graphql';
import ErrorHandler from 'Util/errorHandler';

const TiktokAuth = () => {
  useEffect(() => {
    if (location.pathname === TIKTOK_OAUTH) {
      const { auth_code, error } = getQueryLocationParams(location.search);
      authenticateWithTiktok(
        { code: (auth_code as string) || null, error: (error as string) || null },
        handleSuccess,
        handleFail
      );
      return;
    }
  }, []);

  const handleSuccess = (response: AuthenticateWithTiktokMutation$data) => {
    const socialIdentityId = response.authenticateWithTiktok?.socialIdentity;
    if (window.opener?.callSuccess) {
      window.opener.callSuccess(socialIdentityId);
      window.close();
    }
  };

  const handleFail = (error: Error) => {
    ErrorHandler.error('Crash while tiktok auth', { error });
    window.location.replace('/dashboard');
    if (window.opener?.callFail) {
      window.opener.callFail();
      window.close();
    }
  };

  return (
    <Page className={styles.root}>
      <Spinner />
    </Page>
  );
};

export default track(
  {
    page: 'tiktok_auth',
  },
  { dispatchOnMount: true }
)(TiktokAuth);

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  CreateSetupIntentMutation,
  CreateSetupIntentMutation$data,
  Stripe_CreateSetupIntentInput,
} from 'GraphTypes/CreateSetupIntentMutation.graphql';

const mutation = graphql`
  mutation CreateSetupIntentMutation($input: Stripe_CreateSetupIntentInput!) {
    stripe {
      createSetupIntent(input: $input) {
        setupIntent {
          clientSecret
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data?: Stripe_CreateSetupIntentInput,
  resolve?: (response: CreateSetupIntentMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<CreateSetupIntentMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }

      if (resolve && response) resolve(response);
    },
    onError: (error) => {
      if (reject) reject(error);
    },
  });
};

import React, { useEffect, useRef, useState, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import styles from './CampaignInfo.pcss';
import AdditionalActions from './AdditionalActions/AdditionalActions';

import { SubscriptionParamsType } from 'Helpers/amplitude.types';
import { amplitude } from 'Helpers/amplitude';
import {
  getOrganizationLink,
  getCampaignMediaplanLink,
  getCampaignBriefForViewingLink,
} from 'Util/links';
import NewText from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import TextButton from 'Atoms/TextButton/TextButton';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { CampaignInfo_campaign$key } from 'GraphTypes/CampaignInfo_campaign.graphql';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import Toggle from 'Components/ui/Toggle/Toggle';
import { copyText } from 'Util/copyText';
import Image from 'Components/ui/Image/Image';
import { BILLING_ROUTE } from 'Constants/general';
import Tag from 'Components/ui/Tag/Tag';
import CampaignSubscription from 'Organisms/CampaignSubscription/CampaignSubscription';

interface Props {
  className?: string;
  isAdmin: boolean;
  isAdminView: boolean;
  adminView: boolean;
  hasAcceptance: boolean;
  currentUser: CampaignQuery$data['currentUser'];
  campaign: CampaignInfo_campaign$key;
}

const CampaignInfo: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const [showCopyTooltip, setCopyTooltipStatus] = useState(false);
  const copyTooltipTimeout = useRef<NodeJS.Timeout | null>(null);

  const { className, isAdminView, isAdmin, adminView, hasAcceptance, currentUser, campaign } =
    props;

  const data = useFragment(
    graphql`
      fragment CampaignInfo_campaign on Campaign {
        id
        name
        type
        listingId
        platform
        postingType
        userSubscribed
        brand {
          name
          logo {
            ... on Image {
              transformations {
                brandLogoUrl
              }
            }
          }
        }
        activation {
          paused
        }
        organization {
          id
          name
          subscription {
            paused
          }
        }
        completion {
          id
        }
        ...AdditionalActions_campaign
      }
    `,
    campaign
  );

  const inviteButtonAlreadyVisible =
    (data.type === 'INFLUENCER' && data.postingType === 'ORGANIC_POSTING') ||
    data.type === 'PRODUCT_SEEDING';
  const isInviteDisable = inviteButtonAlreadyVisible && !isAdmin;

  const avaSrc = data.brand?.logo?.transformations?.brandLogoUrl;
  const name = data.name;
  const campaignId = data.id;
  const campaignPlatform = data.platform;
  const brandName = data.brand?.name;
  const organizationId = data.organization?.id || '';
  const organizationName = data.organization?.name;
  const isPaused = !!data.activation?.paused;
  const isPlanPaused = Boolean(data?.organization?.subscription?.paused);
  const userSubscribed = data.userSubscribed;
  const isOrganizationMember = currentUser?.organization?.id === organizationId;

  const canBrowseCreators = !!currentUser?.organization?.subscription?.canBrowseCreators;

  const canComplete = !data.completion;

  const organizationLink = getOrganizationLink({ organizationId });

  const avaData = avaSrc ? { src: avaSrc } : { letters: name };

  const handleAddCreatorsClick = () => {
    amplitude.sendEvent<314>({
      id: '314',
      category: 'campaign',
      name: 'invite_creators_open_marketplace',
      param: { campaign_id: campaignId },
    });
    const path = getCampaignMediaplanLink({ campaignId });
    navigate(path);
    amplitude.sendEvent({
      id: '18',
      category: 'campaign',
      name: 'more_action_add_creators',
      param: { admin: isAdmin },
    });
  };

  const handleOutreachClick = () => {
    amplitude.sendEvent<313>({
      id: '313',
      category: 'campaign',
      name: 'invite_creators_copy_invite_link',
      param: { campaign_id: campaignId },
    });
    const additionalData = JSON.stringify({
      referralUserId: currentUser?.id,
      listingId: data.listingId,
    });
    const url = new URL(`${location.origin}/campaigns/${campaignId}/invite`);
    url.searchParams.append('x', btoa(additionalData));
    copyText(url.toString());
    if (copyTooltipTimeout.current) return;

    copyTooltipTimeout.current = setTimeout(() => {
      setCopyTooltipStatus(false);
      copyTooltipTimeout.current = null;
    }, 3000);
    setCopyTooltipStatus(true);
  };

  useEffect(() => {
    return () => {
      if (copyTooltipTimeout.current) {
        clearInterval(copyTooltipTimeout.current);
        copyTooltipTimeout.current = null;
      }
    };
  }, []);

  const handleOrganisationClick = () => {
    amplitude.sendEvent({ id: 251, category: 'pageview', name: 'campaign_open_organization_page' });
  };

  const handleSubscriptionStateChange = (param: SubscriptionParamsType) => {
    amplitude.sendEvent<416>({
      id: '416',
      category: 'campaign',
      name: 'email_notifications_click',
      param,
    });
  };

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.details}>
        <div className={styles.avaWrap}>
          <Image
            size={48}
            type="round"
            src={avaData.src}
            platform={campaignPlatform?.toLowerCase()}
          />
        </div>
        <div className={styles.names}>
          <div className={styles.campaignInfo}>
            <div className={styles.campaignName}>
              {name && <NewText type="h2" text={name} />}
              {isPlanPaused && (
                <Link to={BILLING_ROUTE} target="_blank">
                  <Tag msg={'campaign.tag.pause_plan'} color="sun" icon="Lock" />
                </Link>
              )}
            </div>
            {adminView && isPaused && (
              <NewText type="md" msg="campaign.paused" className={styles.paused} />
            )}
          </div>
          <div className={styles.campaignInfo}>
            {brandName && <NewText type="label" text={brandName} className={styles.brandName} />}
            {adminView && (
              <SimpleLink
                href={organizationLink}
                target="_blank"
                className={styles.organization}
                onClick={handleOrganisationClick}
              >
                <TextButton text={organizationName} />
              </SimpleLink>
            )}
          </div>
        </div>
        {hasAcceptance && (
          <div className={styles.campaignActions}>
            {isOrganizationMember && (
              <div className={styles.notification}>
                <Icon
                  name={userSubscribed ? 'Bell-notifications' : 'Bell-notifications-disabled'}
                />
                <Text type="md" msg="campaign.notification.toggle" />
                <CampaignSubscription
                  campaignId={data.id}
                  userSubscribed={userSubscribed}
                  eventFn={handleSubscriptionStateChange}
                  currentUserId={currentUser?.id}
                >
                  <Toggle bgColor="sand" on={userSubscribed} />
                </CampaignSubscription>
              </div>
            )}
            {(canBrowseCreators || isAdminView) && canComplete && !isInviteDisable && (
              <Dropdown
                showArrow={false}
                className={styles.inviteMenu}
                value={
                  <Tooltip
                    id={`campaign.invite_creators.tooltip.pause_plan`}
                    place={'bottom'}
                    clickable
                    customTooltip={
                      isPlanPaused ? (
                        <>
                          <NewText type="md" msg="campaign.invite_creators.tooltip.pause_plan" />
                          <a href={BILLING_ROUTE} className={styles.resumePlanTooltipLink}>
                            <NewText
                              type="md"
                              msg="campaign.invite_creators.tooltip.pause_plan.button"
                            />
                          </a>
                        </>
                      ) : null
                    }
                  >
                    <AlterButton
                      msg="campaign.invite_creators"
                      icon="Add-plus-circle"
                      iconPosition="left"
                      fluid={true}
                      bordered={true}
                      disabled={isPlanPaused}
                    />
                  </Tooltip>
                }
                tooltipData={
                  showCopyTooltip
                    ? {
                        tooltipMsg: 'campaign.outreach_link_copy_tip',
                        id: 'campaign.outreach_link_copy_tip',
                        place: 'bottom',
                        showByDefault: true,
                      }
                    : undefined
                }
              >
                <DropdownGroup align="left" className={styles.inviteMenuGroup}>
                  <div className={styles.brandDropdownList}>
                    {isAdmin ? (
                      <DropdownGroupItem>
                        {isPaused ? (
                          <Tooltip
                            id="campaign.outreach_link_disabled_tip"
                            tooltipMsg="campaign.outreach_link_disabled_tip"
                          >
                            <AlterButton msg="campaign.outreach_link" fluid withNewLabel disabled />
                          </Tooltip>
                        ) : (
                          <AlterButton
                            msg="campaign.outreach_link"
                            fluid
                            withNewLabel
                            onClick={handleOutreachClick}
                          />
                        )}
                      </DropdownGroupItem>
                    ) : null}
                    {!inviteButtonAlreadyVisible && (
                      <DropdownGroupItem>
                        <AlterButton
                          type="white"
                          msg="campaign.add_creators"
                          onClick={handleAddCreatorsClick}
                          fluid
                        />
                      </DropdownGroupItem>
                    )}
                  </div>
                </DropdownGroup>
              </Dropdown>
            )}
            <Link to={getCampaignBriefForViewingLink({ campaignId })}>
              <AlterButton type="white" bordered={true} icon="Brief" msg="campaign.view_brief" />
            </Link>
            <AdditionalActions
              campaign={data}
              isAdmin={isAdmin}
              canBrowseCreators={canBrowseCreators}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignInfo;

import { graphql, useMutation } from 'react-relay';

import { ImportOutreachBatchQuery } from 'GraphTypes/ImportOutreachBatchQuery.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const ImportOutreachBatchQueryMutation = graphql`
  mutation ImportOutreachBatchQuery($input: ImportOutreachBatchInput!) {
    importOutreachBatch(input: $input) {
      __typename
      ... on ImportOutreachBatchPayload {
        batch {
          id
        }
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        message
      }
    }
  }
`;

export const useImportOutreachBatchQueryMutation: BaseMutation<ImportOutreachBatchQuery> = (
  config
) => {
  const [importOutreachBatchQuery, loading] = useMutation<ImportOutreachBatchQuery>(
    ImportOutreachBatchQueryMutation
  );

  const importOutreachBatchQueryCaller: BaseMutationExecFn<ImportOutreachBatchQuery> = (
    execConfig
  ) => {
    importOutreachBatchQuery({
      ...config,
      ...execConfig,
    });
  };
  return [importOutreachBatchQueryCaller, loading];
};

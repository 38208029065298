/**
 * @generated SignedSource<<0dc6da55cab91eae9ae85f55825c769c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Stripe_CardBrand = "AMEX" | "DINERS" | "DISCOVER" | "JCB" | "MASTERCARD" | "UNIONPAY" | "UNKNOWN" | "VISA" | "%future added value";
export type TopupBalanceContentQuery$variables = Record<PropertyKey, never>;
export type TopupBalanceContentQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly paymentAccount: {
        readonly balance: number;
      } | null | undefined;
      readonly stripeCustomer: {
        readonly defaultPaymentMethod: {
          readonly brand?: Stripe_CardBrand | null | undefined;
          readonly id?: string;
          readonly last4?: string | null | undefined;
          readonly stripeId?: string;
        } | null | undefined;
        readonly paymentMethods: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly brand?: Stripe_CardBrand | null | undefined;
              readonly id?: string;
              readonly last4?: string | null | undefined;
              readonly stripeId?: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly subscription: {
        readonly transactionFee: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type TopupBalanceContentQuery = {
  response: TopupBalanceContentQuery$data;
  variables: TopupBalanceContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionFee",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brand",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v6 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "type": "Stripe_Card",
    "abstractKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "type": "Stripe_Card",
    "abstractKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopupBalanceContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_Customer",
                "kind": "LinkedField",
                "name": "stripeCustomer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "defaultPaymentMethod",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stripe_PaymentMethodConnection",
                    "kind": "LinkedField",
                    "name": "paymentMethods",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stripe_PaymentMethodEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TopupBalanceContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_Customer",
                "kind": "LinkedField",
                "name": "stripeCustomer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "defaultPaymentMethod",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stripe_PaymentMethodConnection",
                    "kind": "LinkedField",
                    "name": "paymentMethods",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stripe_PaymentMethodEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b7d34c2f077cfa9c01faa928840321f",
    "id": null,
    "metadata": {},
    "name": "TopupBalanceContentQuery",
    "operationKind": "query",
    "text": "query TopupBalanceContentQuery {\n  currentUser {\n    organization {\n      paymentAccount {\n        balance\n        id\n      }\n      subscription {\n        transactionFee\n        id\n      }\n      stripeCustomer {\n        defaultPaymentMethod {\n          __typename\n          ... on Stripe_Card {\n            id\n            stripeId\n            brand\n            last4\n          }\n          id\n        }\n        paymentMethods {\n          edges {\n            node {\n              __typename\n              ... on Stripe_Card {\n                id\n                stripeId\n                brand\n                last4\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "db81fa6bd84cecd054b8b5776a12b26b";

export default node;

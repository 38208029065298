import React, { useCallback, useState, useMemo, useContext } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import parentStyles from '../CustomListEditDrawer.pcss';

import styles from './CustomListRemoveConfirmation.pcss';

import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import deleteCustomList, { DeleteCustomListMutation } from 'Mutations/DeleteCustomList.Mutation';
import { promisifyMutation } from 'Util/promisifyMutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  onDiscardButtonClick?: () => void;
  customListId: string;
  customListName: string;
  totalCount: number;
  onCustomListSuccessfulRemoved?: () => void;
}

const CustomListRemoveConfirmation: React.FC<Props> = (props) => {
  const {
    onDiscardButtonClick,
    totalCount,
    customListName,
    customListId,
    onCustomListSuccessfulRemoved,
  } = props;
  const { closeDrawer } = useContext(DrawerContext);
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const handleConfirmButtonClick = useCallback(() => {
    const mutation = promisifyMutation<DeleteCustomListMutation>(deleteCustomList);
    setIsLoading(true);
    mutation({ id: customListId })
      .then(() => {
        closeDrawer(`custom-list-edit-${customListId}`);
        onCustomListSuccessfulRemoved?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customListId, customListName, onCustomListSuccessfulRemoved]);

  const handleDiscardButtonClick = useCallback(() => {
    if (isLoading) return;
    onDiscardButtonClick?.();
  }, [onDiscardButtonClick, isLoading]);

  const descriptionFormatValues = useMemo<TextProps['formatValues']>(() => {
    return {
      count:
        totalCount >= 0
          ? intl.formatMessage(
              { id: 'custom_list_edit_modal.confirmation.description.count.plural' },
              { count: totalCount }
            )
          : intl.formatMessage(
              { id: 'custom_list_edit_modal.confirmation.description.count.singular' },
              { count: totalCount }
            ),
    };
  }, [totalCount]);

  return (
    <div className={cn(parentStyles.contentRoot, styles.root)}>
      <TextPreset
        className={styles.textPresetRoot}
        classes={{
          textContainer: styles.titleTextPresetContainer,
        }}
        header={
          <Text
            type="d2"
            msg="custom_list_edit_modal.confirmation.title"
            formatValues={{ name: customListName }}
          />
        }
        subtitle={
          <Text
            type="md"
            msg="custom_list_edit_modal.confirmation.description"
            formatValues={{ ...descriptionFormatValues }}
          />
        }
      />
      <ButtonPreset className={styles.buttonGroup}>
        <Button
          color="black"
          dataTest="button:confirmRemovingCustomList"
          msg="custom_list_edit_modal.confirmation.button.confirm_removing"
          icon={!isLoading ? 'Trash-delele' : undefined}
          loading={isLoading}
          onClick={handleConfirmButtonClick}
        />
        <Button
          color="white"
          dataTest="button:discardRemovingCustomList"
          msg="custom_list_edit_modal.confirmation.button.discard_removing"
          onClick={handleDiscardButtonClick}
        />
      </ButtonPreset>
    </div>
  );
};

export default CustomListRemoveConfirmation;
export { CustomListRemoveConfirmation };

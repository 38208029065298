import { YOUTUBE, INSTAGRAM, TIKTOK, CONTENT } from 'Constants/general';
import { compareDates } from 'Util/dateCreator';
import { instagramLink, youtubeLink, tiktokLink } from 'Util/links';

export const getCreatorData = (creator) => {
  const { type, __typename } = creator;

  const typeValue = type ? type.toLowerCase() : undefined;

  const iconColoredByType = {
    [YOUTUBE]: 'youtubeColored',
    [INSTAGRAM]: 'instagramColored',
    [TIKTOK]: 'tiktokColored',
    UNKNOWN: '',
  };
  const shippingRecipientName = `${creator?.profile?.shippingRecipientFirstName} ${creator?.profile?.shippingRecipientLastName}`;

  const creatorUsername = creator.username || '-';

  const gravatarUrl = `https://www.gravatar.com/avatar/${creator.id}?s=180&d=retro&r=x`;

  const profilePictureFileSrc =
    creator.profile?.profilePictureFile?.transformations?.collageThumbnailUrl;

  const profileAvatarUrl = profilePictureFileSrc ?? gravatarUrl;

  if (
    !creator.user &&
    !creator.__typename !== 'InstagramCreator' &&
    !creator.type !== 'INSTAGRAM' &&
    !creator.tiktokUser &&
    !creator.channel
  ) {
    return {
      username: creatorUsername,
      avaSrc: profileAvatarUrl,
      avatarUrl: profileAvatarUrl,
      link: null,
      type: typeValue,
      followedByCount: '-',
      engagementRate: '-',
      shippingRecipientName,
      iconColored: type ? iconColoredByType[type] : iconColoredByType.UNKNOWN,
    };
  }

  if (type === YOUTUBE || __typename === 'YoutubeCreator') {
    const {
      channel: { name, id: channelId, subscribers },
    } = creator;
    const link = youtubeLink({ id: channelId });

    return {
      username: name,
      avaSrc: profileAvatarUrl,
      avatarUrl: profileAvatarUrl,
      link,
      type: typeValue,
      subscribers,
      shippingRecipientName,
      iconColored: iconColoredByType[YOUTUBE],
    };
  } else if (type === CONTENT) {
    return {
      username: creatorUsername,
      avaSrc: profileAvatarUrl,
      avatarUrl: profileAvatarUrl,
      link: null,
      type: typeValue,
      followedByCount: '-',
      engagementRate: '-',
      iconColored: iconColoredByType.UNKNOWN,
    };
  } else if (type === INSTAGRAM || __typename === 'InstagramCreator') {
    const { username, profile } = creator;

    const generalStatistic = profile?.generalStatistic;

    const fullName = creator?.user?.fullName || '';
    const followedByCount = creator?.user?.followedByCount || 0;
    const estimatedLikes = generalStatistic?.likesPerPost || creator?.user?.estimatedLikes || 0;
    const igEngagementRate = creator?.user?.engagementRate || 0;

    const socialDataUpdateDate =
      profile &&
      profile.socialdataCollectionOperation &&
      profile.socialdataCollectionOperation.updatedAt
        ? profile.socialdataCollectionOperation.updatedAt
        : null;

    let generalStatisticEngagementRate = generalStatistic?.engagementRate;

    if (socialDataUpdateDate) {
      if (compareDates(socialDataUpdateDate, '2021-04-07T17:00:00+03:00', true)) {
        generalStatisticEngagementRate = Number(generalStatisticEngagementRate) * 100;
      }
    }

    const engagementRate = igEngagementRate || generalStatisticEngagementRate;

    const link = instagramLink({ username });

    return {
      username,
      avaSrc: profileAvatarUrl,
      avatarUrl: profileAvatarUrl,
      link,
      fullName,
      type: typeValue,
      followedByCount,
      engagementRate,
      estimatedLikes,
      iconColored: iconColoredByType[INSTAGRAM],
      shippingRecipientName,
    };
  } else if (type === TIKTOK || __typename === 'TiktokCreator') {
    const userData = creator.user || creator.tiktokUser;

    const engagementRate = creator?.tiktokUser?.engagementRate || creator?.user?.engagementRate;
    const averageLikes = creator?.tiktokUser?.averageLikes || creator?.user?.averageLikes;

    const { name, followedByCount } = userData;

    return {
      username: name,
      avaSrc: profileAvatarUrl,
      avatarUrl: profileAvatarUrl,
      link: tiktokLink({ username: name }),
      type: typeValue,
      followedByCount,
      engagementRate,
      shippingRecipientName,
      averageLikes,
      iconColored: iconColoredByType[TIKTOK],
    };
  }

  return {
    username: undefined,
    avaSrc: profileAvatarUrl,
    avatarUrl: profileAvatarUrl,
    link: undefined,
    type: typeValue,
    iconColored: iconColoredByType.UNKNOWN,
    shippingRecipientName,
  };
};

/**
 * @generated SignedSource<<0345dc7d120975b0f5b075875698b07a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnsubscribeFromCampaignInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
};
export type UnsubscribeFromCampaignMutation$variables = {
  input: UnsubscribeFromCampaignInput;
};
export type UnsubscribeFromCampaignMutation$data = {
  readonly unsubscribeFromCampaign: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type UnsubscribeFromCampaignMutation = {
  response: UnsubscribeFromCampaignMutation$data;
  variables: UnsubscribeFromCampaignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnsubscribeFromCampaignPayload",
    "kind": "LinkedField",
    "name": "unsubscribeFromCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnsubscribeFromCampaignMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnsubscribeFromCampaignMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e33a91a365ca6d927cb5f3b0fc1476ae",
    "id": null,
    "metadata": {},
    "name": "UnsubscribeFromCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation UnsubscribeFromCampaignMutation(\n  $input: UnsubscribeFromCampaignInput!\n) {\n  unsubscribeFromCampaign(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "17f09589e1ebf215c794c44e9afaa6b6";

export default node;

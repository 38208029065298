import React from 'react';
import { useDispatch } from 'react-redux';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './MessageMedia.pcss';
import MessageImage from './MessageImage/MessageImage';
import MessageVideo from './MessageVideo/MessageVideo';
import MessageMediaControls from './MessageMediaControls/MessageMediaControls';

import { timeChatFormat } from 'Util/dateCreator';
import { setModal } from 'Actions/Modal.Actions';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { MEDIA } from 'Constants/modals';
import { IMAGE, VIDEO } from 'Constants/general';

const PROCESSED = 'PROCESSED';

import { MessageMedia_file$key } from 'GraphTypes/MessageMedia_file.graphql';

interface Props {
  isRead: boolean;
  isOwner?: boolean;
  actionAble?: boolean;
  createdAt?: any;
  file: MessageMedia_file$key;
}

const MessageMedia: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { actionAble, file, isRead, isOwner, createdAt } = props;

  const data = useFragment(
    graphql`
      fragment MessageMedia_file on File {
        id
        type
        width
        height
        bytes
        bcaTaggedUrl
        bcaTaggingStatus
        thumbnailUrl
        secureUrl
        originalFilename
        filename
        ... on Video {
          processingStatus
          transformations {
            autoUrl
            postThumbnailUrl
          }
        }
        ... on Image {
          transformations {
            postThumbnailUrl
          }
        }
      }
    `,
    file
  );

  if (!data) {
    return null;
  }
  const type = data.type;
  const bcaTaggingStatus = data.bcaTaggingStatus;

  const fileUploaded = bcaTaggingStatus === PROCESSED;

  const hasActions = fileUploaded && actionAble;

  const classes = classnames(styles.root, {
    [styles.actionAble]: hasActions,
    [styles.video]: type === VIDEO,
    [styles.isOwner]: isOwner,
  });

  const getContent = () => {
    switch (type) {
      case IMAGE:
        return <MessageImage file={data} hasActions={hasActions} />;

      case VIDEO:
        return <MessageVideo file={data} hasActions={hasActions} />;

      default:
        return null;
    }
  };

  const handleContentClick = () => {
    const { secureUrl } = data;

    const currentThumbnailUrl = secureUrl;

    if (type === VIDEO || !fileUploaded) {
      return;
    }

    dispatch(
      setModal(MEDIA, {
        videoUrl: data.transformations?.autoUrl,
        secureUrl,
        type,
        thumbnailUrl: currentThumbnailUrl,
        canDownload: true,
        canSentToAdsManager: true,
        date: createdAt,
        file: data,
      })
    );
  };

  const content = getContent();

  if (!content) return null;

  const dateColor = type !== VIDEO ? 'white' : 'grayDog';

  return (
    <div className={classes}>
      <div className={styles.container}>
        <div className={styles.content} onClick={handleContentClick}>
          {content}
        </div>
      </div>
      {hasActions && <MessageMediaControls file={data} />}
    </div>
  );
};

export default MessageMedia;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  FailWithdrawalMutation,
  FailWithdrawalInput,
  FailWithdrawalMutation$data,
} from 'GraphTypes/FailWithdrawalMutation.graphql';

const mutation = graphql`
  mutation FailWithdrawalMutation($input: FailWithdrawalInput!) {
    failWithdrawal(input: $input) {
      withdrawal {
        id
        status
        statusDate
        paymentServiceProvider
        paymentMethod {
          w9WarningLevel
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: FailWithdrawalInput,
  resolve?: (response: FailWithdrawalMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const variables = {
    input: {
      id,
      ...data,
    },
  };

  commitMutation<FailWithdrawalMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

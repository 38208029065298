/**
 * @generated SignedSource<<62f9b93b82df611639642e0f9e5bcf2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT" | "%future added value";
export type Performance_Granularity = "DAILY" | "MONTHLY" | "QUARTERLY" | "WEEKLY" | "%future added value";
export type CpmCpeCountersChartQuery$variables = {
  brandId: string;
  campaignIds?: ReadonlyArray<string> | null | undefined;
  campaignPostingType?: CampaignPostingType | null | undefined;
  campaignType?: CampaignType | null | undefined;
  dateFrom: any;
  dateTo: any;
  granularity: Performance_Granularity;
  platform?: CampaignPlatform | null | undefined;
  useCase?: CampaignUseCase | null | undefined;
};
export type CpmCpeCountersChartQuery$data = {
  readonly performance: {
    readonly cpmCpeCounters: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly cpe: number;
          readonly cpm: number;
          readonly dateFrom: any;
          readonly dateTo: any;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCpe: number;
      readonly totalCpm: number;
    } | null | undefined;
  } | null | undefined;
};
export type CpmCpeCountersChartQuery = {
  response: CpmCpeCountersChartQuery$data;
  variables: CpmCpeCountersChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignPostingType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFrom"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTo"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granularity"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useCase"
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Performance_Query",
    "kind": "LinkedField",
    "name": "performance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "brandId",
            "variableName": "brandId"
          },
          {
            "kind": "Variable",
            "name": "campaignIds",
            "variableName": "campaignIds"
          },
          {
            "kind": "Variable",
            "name": "campaignPostingType",
            "variableName": "campaignPostingType"
          },
          {
            "kind": "Variable",
            "name": "campaignType",
            "variableName": "campaignType"
          },
          {
            "kind": "Variable",
            "name": "campaignUseCase",
            "variableName": "useCase"
          },
          {
            "kind": "Variable",
            "name": "dateFrom",
            "variableName": "dateFrom"
          },
          {
            "kind": "Variable",
            "name": "dateTo",
            "variableName": "dateTo"
          },
          {
            "kind": "Variable",
            "name": "granularity",
            "variableName": "granularity"
          },
          {
            "kind": "Variable",
            "name": "platform",
            "variableName": "platform"
          }
        ],
        "concreteType": "Performance_CpmCpeCounterConnection",
        "kind": "LinkedField",
        "name": "cpmCpeCounters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Performance_CpmCpeCounterEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Performance_CpmCpeCounter",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cpe",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cpm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateFrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateTo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCpe",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCpm",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CpmCpeCountersChartQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "CpmCpeCountersChartQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "0dc27e9691babbc45d6a8d3d996aaa91",
    "id": null,
    "metadata": {},
    "name": "CpmCpeCountersChartQuery",
    "operationKind": "query",
    "text": "query CpmCpeCountersChartQuery(\n  $brandId: ID!\n  $campaignIds: [ID!]\n  $dateFrom: Date!\n  $dateTo: Date!\n  $granularity: Performance_Granularity!\n  $platform: CampaignPlatform\n  $campaignType: CampaignType\n  $campaignPostingType: CampaignPostingType\n  $useCase: CampaignUseCase\n) {\n  performance {\n    cpmCpeCounters(brandId: $brandId, campaignIds: $campaignIds, dateFrom: $dateFrom, dateTo: $dateTo, granularity: $granularity, platform: $platform, campaignType: $campaignType, campaignPostingType: $campaignPostingType, campaignUseCase: $useCase) {\n      edges {\n        node {\n          cpe\n          cpm\n          dateFrom\n          dateTo\n        }\n      }\n      totalCpe\n      totalCpm\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb302ded71fc3c5210b17d6ede71fefd";

export default node;

import React from 'react';

import styles from './Footer.pcss';
import Actions from './Actions/Actions';

const Footer = (props) => {
  const {
    className,
    admin,
    campaign,
    canImportMediaplan,
    canManageLonglists,
    permissions,
    currency,
    hasSubmission,
  } = props;

  return (
    <div className={className} className={styles.root}>
      {campaign && (
        <Actions
          currency={currency}
          admin={admin}
          campaign={campaign}
          hasSubmission={hasSubmission}
          canManageLonglists={canManageLonglists}
          canImportMediaplan={canImportMediaplan}
          permissions={permissions}
        />
      )}
    </div>
  );
};

export default Footer;

import { graphql } from 'react-relay';

import type { PerformanceFiltersQuery as PerformanceFiltersQueryType } from 'GraphTypes/PerformanceFiltersQuery.graphql';

const PerformanceFiltersQuery = graphql`
  query PerformanceFiltersQuery($organizationId: ID!) {
    organization(id: $organizationId) {
      brands {
        edges {
          node {
            id
            name
            active
          }
        }
      }
    }
  }
`;

export { PerformanceFiltersQuery };

// types

export type { PerformanceFiltersQueryType };

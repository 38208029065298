import { graphql } from 'relay-runtime';

import type {
  ShopifyOAuthVerifyQuery,
  ShopifyOAuthVerifyQuery$data,
  ShopifyOAuthVerifyQuery$variables,
} from 'GraphTypes/ShopifyOAuthVerifyQuery.graphql';

export default graphql`
  query ShopifyOAuthVerifyQuery {
    currentUser {
      id
      type
    }
  }
`;

// types

export type {
  ShopifyOAuthVerifyQuery,
  ShopifyOAuthVerifyQuery$data,
  ShopifyOAuthVerifyQuery$variables,
};

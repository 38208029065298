import React, { useState, useEffect } from 'react';
import { graphql, fetchQuery, useLazyLoadQuery } from 'react-relay';
import { SetupIntent, PaymentIntent } from '@stripe/stripe-js';
import environment from 'Api/Environment';
import classnames from 'classnames';

import styles from './PaymentMethodSelect.pcss';

import Text from 'Components/ui/Text/Text';

import { SETUP_INTENT, SUCCEEDED } from 'Constants/general';
import attachPaymentMethodToSubscription from 'Mutations/AttachPaymentMethodToSubscription.Mutation';
import createSetupIntentMutation from 'Mutations/CreateSetupIntent.Mutation';
import StripePaymentElement from 'Organisms/StripePaymentElement/StripePaymentElement';
import Spinner from 'Atoms/Spinner/Spinner';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import Button from 'Components/ui/Button/Button';
import Icon from 'Components/ui/Icon/Icon';
import { CreateSetupIntentMutation$data } from 'GraphTypes/CreateSetupIntentMutation.graphql';
import { PaymentMethodSelectQuery as QueryType } from 'GraphTypes/PaymentMethodSelectQuery.graphql';

const NEW_METHOD = 'new';

const PaymentMethodSelectQuery = graphql`
  query PaymentMethodSelectQuery {
    currentUser {
      organization {
        subscription {
          transactionFee
        }
        stripeCustomer {
          defaultPaymentMethod {
            ... on Stripe_Card {
              id
              stripeId
            }
          }
          paymentMethods {
            edges {
              node {
                ... on Stripe_Card {
                  id
                  stripeId
                  brand
                  createdAt
                  expYear
                  expMonth
                  last4
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  onBackClick?: () => void;
  className?: string;
}

const PaymentMethodSelect: React.FC<Props> = (props) => {
  const { className, onBackClick } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [showAllMethods, setShowAllMethods] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);

  const data = useLazyLoadQuery<QueryType>(PaymentMethodSelectQuery, {});

  const stripeCustomer = data.currentUser?.organization?.stripeCustomer;

  const defaultStripeId =
    data.currentUser?.organization?.stripeCustomer?.defaultPaymentMethod?.stripeId;

  useEffect(() => {
    createSetupIntentMutation({}, createSetupIntentSuccess);

    if (defaultStripeId) setActiveMethod(defaultStripeId);
  }, [stripeCustomer]);

  const createSetupIntentSuccess = (data: CreateSetupIntentMutation$data) => {
    const token = data.stripe?.createSetupIntent?.setupIntent.clientSecret;

    if (token) setClientSecret(token);
  };

  const [activeMethod, setActiveMethod] = useState(defaultStripeId);

  const paymentMethods = data.currentUser?.organization?.stripeCustomer?.paymentMethods?.edges;

  const handleShowAllClick = () => {
    setShowAllMethods(!showAllMethods);
  };

  const handleNewMethodClick = () => {
    setShowAllMethods(false);
    setActiveMethod(NEW_METHOD);
  };

  const handleNewPaymentMethodFailed = () => {};
  const handleBackClick = () => {
    onBackClick?.();
  };

  const handlePaymentMethodSelected = () => {
    fetchQuery<QueryType>(environment, PaymentMethodSelectQuery, {}).subscribe({
      next: () => {
        setLoading(false);
        onBackClick?.();
      },
    });
  };

  const handleUpdateClick = () => {
    setLoading(true);
    if (activeMethod && activeMethod !== NEW_METHOD) {
      attachPaymentMethodToSubscription(
        { stripePaymentMethodId: activeMethod },
        handlePaymentMethodSelected
      );
    }
  };

  const handleNewMethodAdd = (intent: PaymentIntent | SetupIntent) => {
    if (intent) {
      const { status, payment_method, object } = intent;

      if (status?.toLowerCase() === SUCCEEDED && payment_method) {
        if (object === SETUP_INTENT) {
          attachPaymentMethodToSubscription(
            { stripePaymentMethodId: payment_method as string },
            handlePaymentMethodSelected
          );
        }
      }
    }
  };

  return (
    <div className={classnames(className, styles.root)}>
      <Text type="d2" msg="payment_method_select_modal.title" className={styles.title} />
      {!data && <Spinner />}
      <div className={styles.content}>
        <div className={styles.payments}>
          {paymentMethods?.map((item, index) => {
            if (!item?.node) return null;
            if (!showAllMethods && index > 2) return null;
            const { id, brand, last4, stripeId, expMonth, expYear } = item.node;
            const checked = stripeId === activeMethod;

            const handleMethodClick = () => {
              setActiveMethod(stripeId);
            };
            return (
              <div key={id} className={styles.method}>
                <div onClick={handleMethodClick}>
                  <RadioButton checked={checked} />
                </div>
                <Text type="sm" text={`${brand} •••• ${last4}`} className={styles.brand} />
                <Text
                  type="md"
                  msg="payment_method_select_modal.payment_method.expires_prefix"
                  formatValues={{ date: `${expMonth}/${expYear}` }}
                />
              </div>
            );
          })}
        </div>
        {Number(paymentMethods?.length) > 3 && (
          <div onClick={handleShowAllClick} className={styles.detailsControl}>
            <Text type="sm" msg={showAllMethods ? 'general.hide' : 'general.show_more'} />
            <Icon name={showAllMethods ? 'Arrow-small-up' : 'Arrow-small-down'} size={16} />
          </div>
        )}
        <div className={styles.newMethodWrap}>
          <div className={styles.newMethod}>
            <div onClick={handleNewMethodClick}>
              <RadioButton checked={activeMethod === NEW_METHOD} />
            </div>
            <Text type="sm" msg="payment_method_select_modal.payment_method.add" />
          </div>
          {activeMethod === NEW_METHOD && clientSecret && (
            <StripePaymentElement
              className={styles.paymentForm}
              clientSecret={clientSecret}
              confirmPayment={false}
              onBackBtnClick={handleBackClick}
              onSuccess={handleNewMethodAdd}
              onFail={handleNewPaymentMethodFailed}
            />
          )}
        </div>
      </div>
      {activeMethod !== NEW_METHOD && (
        <>
          <div className={styles.spacer} />
          <div className={styles.buttons}>
            <Button
              color="white"
              className={styles.button}
              loading={loading}
              msg="payment_method_select_modal.btn.back"
              disabled={!activeMethod}
              onClick={handleBackClick}
            />
            <Button
              color="black"
              className={styles.button}
              loading={loading}
              disabled={!activeMethod}
              msg="payment_method_select_modal.btn.update"
              onClick={handleUpdateClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentMethodSelect;

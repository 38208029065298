import React, { useContext, useState } from 'react';
import { Widget } from '@typeform/embed-react';

import styles from './CancelTypeformSubscriptionDrawer.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { amplitude } from 'Helpers/amplitude';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CurrentSubscriptionInfo_organization$data } from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';
import { useCancelSubscription } from '../useCancelSubscription';

interface Props {
  organizationId: string;
  organizationName: string;
  subscription: NonNullable<CurrentSubscriptionInfo_organization$data['subscription']>;
}

const canSkipSurveyByDefault = !process.env.API_HOST.includes('.pro');

const CancelTypeformSubscriptionDrawer: React.FC<Props> = (props) => {
  const { organizationId, organizationName, subscription } = props;
  const { user } = useContext(Auth0Context);
  const { openDrawer } = useContext(DrawerContext);
  const [isCompleted, setCompleteStatus] = useState<boolean>(canSkipSurveyByDefault);
  const [cancelSubscription, cancelLoading] = useCancelSubscription(subscription?.id);

  const handleEndingButtonClick = () => {
    setCompleteStatus(true);
  };

  const handleCompleteClick = () => {
    if (subscription.canApplyDiscount) {
      openDrawer('cancel-subscription-offer-drawer');
      amplitude.sendEvent<378>({
        id: '378',
        category: 'billing',
        name: 'presented_discount_offer',
      });
    } else {
      cancelSubscription();
    }
  };

  const handleBackButton = () => {
    openDrawer('cancel-subscription-drawer');
  };

  return (
    <Drawer
      rootKey={'cancel-subscription-typeform-drawer'}
      className={styles.drawerWrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      needCloseButton
      removeWhenClosed
    >
      <div className={styles.root}>
        <div className={styles.formContainer}>
          <div className={styles.typeformContainer}>
            <Widget
              id="e8PE3gHz"
              className={styles.typeformWidget}
              hideFooter
              tracking={{
                organization_id: organizationId,
                organization_name: organizationName,
                email: user?.email || '',
              }}
              onSubmit={handleEndingButtonClick}
            />
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <Button
            color="black"
            fluid
            loading={cancelLoading}
            disabled={!isCompleted}
            msg={
              subscription.canApplyDiscount
                ? 'billing.cancel.typeform.drawer.button.continue'
                : 'billing.current_plan.cancel_plan'
            }
            onClick={handleCompleteClick}
          />
          <Button
            color="white"
            fluid
            msg="billing.cancel.typeform.drawer.button.back"
            onClick={handleBackButton}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default CancelTypeformSubscriptionDrawer;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AuthenticateWithSnapchatMutation$data,
  AuthenticateWithSnapchatInput,
  AuthenticateWithSnapchatMutation,
} from 'GraphTypes/AuthenticateWithSnapchatMutation.graphql';

const mutation = graphql`
  mutation AuthenticateWithSnapchatMutation($input: AuthenticateWithSnapchatInput!) {
    authenticateWithSnapchat(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: AuthenticateWithSnapchatInput,
  resolve?: (response: AuthenticateWithSnapchatMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();
  const { code, error } = data;
  const variables = {
    input: {
      id,
      code,
      error,
    },
  };

  commitMutation<AuthenticateWithSnapchatMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<ccef3eb762bea126d8689fb7e612b757>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductListPaginationQuery$variables = {
  after?: string | null | undefined;
  campaignId: string;
  first?: number | null | undefined;
  organizationId: string;
  textQuery?: string | null | undefined;
};
export type ProductListPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProductList_query">;
};
export type ProductListPaginationQuery = {
  response: ProductListPaginationQuery$data;
  variables: ProductListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  },
  {
    "defaultValue": 50,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "textQuery"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v4 = {
  "kind": "Variable",
  "name": "textQuery",
  "variableName": "textQuery"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductListPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "campaignId",
            "variableName": "campaignId"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ProductList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "campaignId"
          }
        ],
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopifyAuthorization",
            "kind": "LinkedField",
            "name": "shopifyAuthorization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Shopify_Shop",
                "kind": "LinkedField",
                "name": "shop",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currencyCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "products",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": (v6/*: any*/),
                            "concreteType": "Shopify_ProductConnection",
                            "kind": "LinkedField",
                            "name": "products",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Shopify_ProductEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Shopify_Product",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "featuredImageUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Shopify_ProductVariant",
                                        "kind": "LinkedField",
                                        "name": "variants",
                                        "plural": true,
                                        "selections": [
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "price",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "imageUrl",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "displayName",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "availableForSale",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "inventoryQuantity",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v6/*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "ProductList_products",
                            "kind": "LinkedHandle",
                            "name": "products"
                          }
                        ],
                        "type": "Shopify_ProductsPayload",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "Shopify_InvalidAuthorizationError",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "ValidationError",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "680140de30dfaa45fb9e95b5c1198b93",
    "id": null,
    "metadata": {},
    "name": "ProductListPaginationQuery",
    "operationKind": "query",
    "text": "query ProductListPaginationQuery(\n  $after: String\n  $campaignId: ID!\n  $first: Int = 50\n  $organizationId: ID!\n  $textQuery: String\n) {\n  ...ProductList_query_1jmBHI\n}\n\nfragment ProductList_query_1jmBHI on Query {\n  campaign(id: $campaignId) {\n    shopifyAuthorization {\n      shop {\n        currencyCode\n        products(organizationId: $organizationId, textQuery: $textQuery) {\n          __typename\n          ... on Shopify_ProductsPayload {\n            __typename\n            products(first: $first, after: $after) {\n              pageInfo {\n                hasNextPage\n                endCursor\n              }\n              edges {\n                node {\n                  id\n                  title\n                  featuredImageUrl\n                  variants {\n                    id\n                    title\n                    price\n                    imageUrl\n                    displayName\n                    availableForSale\n                    inventoryQuantity\n                  }\n                  __typename\n                }\n                cursor\n              }\n              totalCount\n            }\n          }\n          ... on Shopify_InvalidAuthorizationError {\n            __typename\n            message\n          }\n          ... on ValidationError {\n            __typename\n            message\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a348ae2340174e5db642aa4c5240204b";

export default node;

import React from 'react';
import classnames from 'classnames';

import styles from './RadioButton.pcss';

interface Props {
  checked?: boolean;
}

const RadioButton: React.FC<Props> = (props) => {
  const { checked } = props;
  return (
    <div className={classnames(styles.round, { [styles.checked]: checked })}>
      {checked && <div className={styles.radioCheckedItem} />}
    </div>
  );
};

export default RadioButton;

import { useIntl } from 'react-intl';

import { Filters } from './AdminAdvertiserFilters';

import { USD, CAD } from 'Constants/general';

export const getFiltersData = (filters: Filters) => {
  const { paying, currencies = [] } = filters;

  const intl = useIntl();

  return {
    paying: [
      {
        id: 'all',
        isChecked: !paying,
        value: intl.formatMessage({
          id: 'search_section.form.default_value',
        }),
        labelMsg: 'search_section.form.default_value',
      },
      {
        id: 'paying',
        isChecked: !!paying,
        value: intl.formatMessage({
          id: 'search_section.form.payment_status_paid',
        }),
        labelMsg: 'search_section.form.payment_status_paid',
      },
    ],
    currencies: [
      {
        id: USD,
        isChecked: currencies.includes(USD),
        value: USD,
        label: USD,
      },
      {
        id: CAD,
        isChecked: currencies.includes(CAD),
        value: CAD,
        label: CAD,
      },
    ],
  };
};

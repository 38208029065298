import type {
  GuideToursMap,
  GuideToursState,
  GuideTourStepName,
  GuideToursStepState,
  GuideTourId,
  LocalStorageState,
  GuideTourState,
  LocalStorageStepState,
  IsAutoModeActivated,
  StepEntry,
} from './types';

import ls from 'Util/localStorage';
import ErrorHandler from 'Util/errorHandler';

const LS_KEY = 'state';

type MakeLsNamespace = (currentUserId: string) => string;
const _makeLsNamespace: MakeLsNamespace = (currentUserId) => `GuideTour.${currentUserId}`;

type InitToursState = (params: {
  guideTours: GuideToursMap;
  currentUserId: string;
}) => GuideToursState;
const initToursState: InitToursState = ({ guideTours, currentUserId }) => {
  const tourState: GuideToursState = {};
  const lsState = _readLocalStorageState(currentUserId);
  const isAutoModeActivated: IsAutoModeActivated = {};
  guideTours.forEach((guideTour, guideTourId) => {
    const entries = [...guideTour.steps.keys()].map<StepEntry>((stepName) => {
      const complete = lsState ? Boolean(lsState[guideTourId]?.steps[stepName]?.complete) : false;
      const visible =
        !complete &&
        guideTour.mode === 'auto' &&
        !isAutoModeActivated[guideTourId] &&
        (isAutoModeActivated[guideTourId] = true);

      return [stepName, { complete, visible }];
    });
    tourState[guideTourId] = {
      mode: guideTour.mode,
      steps: Object.fromEntries<GuideToursStepState>(entries),
    };
  });
  return tourState;
};

type FinishGuideTourToState = (
  params: { guideTourId: GuideTourId },
  state: GuideToursState
) => GuideToursState;
const finishGuideTourToState: FinishGuideTourToState = ({ guideTourId }, state) => {
  const clone = { ...state };
  const guideTour = clone[guideTourId];
  const steps = { ...guideTour.steps };
  Object.keys(steps).forEach((key) => {
    steps[key].complete = true;
    steps[key].visible = false;
  });
  guideTour.steps = steps;
  return { ...clone, [guideTourId]: guideTour };
};

type SetCompleteStepToState = (
  params: { guideTourId: GuideTourId; stepName: GuideTourStepName },
  state: GuideToursState
) => GuideToursState;
const setCompleteStepToState: SetCompleteStepToState = ({ guideTourId, stepName }, state) => {
  const clone = { ...state };
  clone[guideTourId].steps[stepName] = {
    ...clone[guideTourId].steps[stepName],
    complete: true,
    visible: false,
  };
  return clone;
};

type SetNextStepVisibleToState = (
  params: { guideTourId: GuideTourId },
  state: GuideToursState
) => GuideToursState;
const setNextStepVisibleToState: SetNextStepVisibleToState = ({ guideTourId }, state) => {
  const guideTour = state[guideTourId];
  const entries = Object.entries<GuideToursStepState>(guideTour.steps);
  const nextStepEntry = entries.find((step) => !step[1].complete);
  if (nextStepEntry) {
    const clone = { ...state };
    const [nextStepName, nextStepState] = nextStepEntry;
    clone[guideTourId].steps[nextStepName] = {
      ...nextStepState,
      visible: true,
    };
    return clone;
  }
  return state;
};

type HideStepsInState = (
  params: { guideTourId: GuideTourId },
  state: GuideToursState
) => GuideToursState;
const hideStepsInState: HideStepsInState = ({ guideTourId }, state) => {
  const clone = { ...state };
  /* eslint-disable-next-line guard-for-in */
  for (const key in clone[guideTourId].steps) {
    clone[guideTourId].steps[key].visible = false;
  }
  return clone;
};

type FindOutVisibleStepIndexFromEntries = (entries: StepEntry[]) => number;
const _findOutVisibleStepIndexFromEntries: FindOutVisibleStepIndexFromEntries = (entries) => {
  return entries.findIndex((stepEntry) => stepEntry[1].visible);
};

type FindOutFirstAvailableStepIndexFromEntries = (entries: StepEntry[]) => number;
const _findOutFirstAvailableStepIndexFromEntries: FindOutFirstAvailableStepIndexFromEntries = (
  entries
) => {
  return entries.findIndex((stepEntry) => !stepEntry[1].complete && !stepEntry[1].visible);
};

type GoToNextStepInState = (
  params: { guideTourId: GuideTourId },
  state: GuideToursState,
  reverse: boolean
) => [boolean, GuideToursState];
const goToNextStepInState: GoToNextStepInState = ({ guideTourId }, state, reverse = false) => {
  const clone = { ...state };
  const entries = reverse
    ? Object.entries<GuideToursStepState>(clone[guideTourId].steps).reverse()
    : Object.entries<GuideToursStepState>(clone[guideTourId].steps);
  const visibleStepIndex = _findOutVisibleStepIndexFromEntries(entries);
  const sliceIndex = visibleStepIndex === -1 ? 0 : visibleStepIndex + 1;
  const firstAvailableStepIndex =
    _findOutFirstAvailableStepIndexFromEntries(entries.slice(sliceIndex)) + sliceIndex;
  const isFinalIndex = visibleStepIndex === firstAvailableStepIndex;
  if (firstAvailableStepIndex === -1) {
    return [false, clone];
  }
  if (visibleStepIndex !== -1) {
    entries[visibleStepIndex][1].visible = false;
  }
  entries[firstAvailableStepIndex][1].visible = true;
  clone[guideTourId].steps = Object.fromEntries(reverse ? entries.reverse() : entries);
  return [!isFinalIndex, clone];
};

type GuideToursStateToLocalStorageState = (state: GuideToursState) => LocalStorageState;
const _guideToursStateToLocalStorageState: GuideToursStateToLocalStorageState = (state) => {
  const result: LocalStorageState = {};
  const entries = Object.entries<GuideTourState>(state);
  entries.forEach(([guideTourId, guideTourState]) => {
    const stepEntries = Object.entries<GuideToursStepState>(guideTourState.steps).map<
      [GuideTourStepName, LocalStorageStepState]
    >((stepEntry) => [stepEntry[0], { complete: stepEntry[1].complete }]);
    result[guideTourId] = {
      steps: Object.fromEntries(stepEntries),
    };
  });
  return result;
};

type WriteGuideToursStateToLocalStorage = (currentUserId: string, state: GuideToursState) => void;
const writeLocalStorageState: WriteGuideToursStateToLocalStorage = (currentUserId, state) => {
  const lsNamespace = _makeLsNamespace(currentUserId);
  const lsState = _guideToursStateToLocalStorageState(state);
  try {
    ls.set(LS_KEY, JSON.stringify(lsState), lsNamespace);
  } catch (e) {
    console.error(e);
    ErrorHandler.error('Wrong execution writeLocalStorageState()');
  }
};

type ReadLocalStorageState = (currentUserId: string) => LocalStorageState | null;
const _readLocalStorageState: ReadLocalStorageState = (currentUserId) => {
  const lsNamespace = _makeLsNamespace(currentUserId);
  const lsState = ls.get(LS_KEY, lsNamespace) || null;
  try {
    return typeof lsState === 'string' ? JSON.parse(lsState) : lsState;
  } catch (e) {
    ErrorHandler.error('Wrong execution _readLocalStorageState()');
    return lsState;
  }
};

type ResetGuideTourToState = (
  params: { guideTourId: GuideTourId },
  state: GuideToursState
) => GuideToursState;
const resetGuideTourToState: ResetGuideTourToState = ({ guideTourId }, state) => {
  const clone = { ...state };
  const guideTour = clone[guideTourId];
  const steps = { ...guideTour.steps };
  Object.keys(steps).forEach((key) => {
    steps[key].complete = false;
    steps[key].visible = false;
  });
  guideTour.steps = steps;
  return { ...clone, [guideTourId]: guideTour };
};

type Assertion = {
  guideTourNotFound: (
    value: Parameters<typeof console.assert>[0],
    guideTourId: GuideTourId
  ) => ReturnType<typeof console.assert>;
};
const assertion: Assertion = {
  guideTourNotFound: (value, guideTourId) =>
    console.assert(value, `Guide tour with name "${guideTourId}" not found`),
};
export {
  initToursState,
  setCompleteStepToState,
  setNextStepVisibleToState,
  hideStepsInState,
  goToNextStepInState,
  writeLocalStorageState,
  finishGuideTourToState,
  resetGuideTourToState,
  assertion,
};

/**
 * @generated SignedSource<<2c9deda5f0bb030d86dcb39766e09a6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransactionReportQuery$variables = Record<PropertyKey, never>;
export type TransactionReportQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type TransactionReportQuery = {
  response: TransactionReportQuery$data;
  variables: TransactionReportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TransactionReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43b5e1a3651e98b6ae4c2476dd6d1dcd",
    "id": null,
    "metadata": {},
    "name": "TransactionReportQuery",
    "operationKind": "query",
    "text": "query TransactionReportQuery {\n  currentUser {\n    organization {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c8158a4754576572e1ac5a4b394da60";

export default node;

import type { FragmentType } from 'relay-runtime';

type NormalizeFragmentRef = <RefKeyType extends RefKeyTypeBase>(
  fragmentRef: RefKeyType
) => NormalizeFragmentRefResult<RefKeyType>;
const normalizeFragmentRef: NormalizeFragmentRef = (fragmentRef) => {
  return {
    ' $fragmentSpreads': undefined,
    ...fragmentRef,
  };
};

export { normalizeFragmentRef };

// types

type RefKeyTypeBase = {
  readonly ' $data'?: any;
  readonly ' $fragmentRefs'?: any;
};

type NormalizeFragmentRefResult<RefKeyType extends RefKeyTypeBase> = {
  readonly ' $data'?: RefKeyType[' $data'];
  readonly ' $fragmentRefs'?: RefKeyType[' $fragmentRefs'];
  readonly ' $fragmentSpreads': FragmentType;
};

import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';
import track, { useTracking } from 'react-tracking';

import styles from './OnboardingByContractor.pcss';

import { setModal } from 'Actions/Modal.Actions';
import { createSum } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import { barterInfoData } from 'Constants/messageModal';
import { OnboardingByContractor_project$key } from 'GraphTypes/OnboardingByContractor_project.graphql';
import { modalName } from 'Types/modals';
import NewPrice from 'Modal/contractor/NewPrice/NewPrice';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  project: OnboardingByContractor_project$key;
  productPrice?: boolean;
  notProductSeeding?: boolean;
}

const OnboardingByContractor: React.FC<Props> = (props) => {
  const { project, productPrice, notProductSeeding } = props;

  const data = useFragment(
    graphql`
      fragment OnboardingByContractor_project on Project {
        id
        price
        currency
        paidSocial
        archivationState: archivation {
          id
        }
        brief {
          ... on V2Brief {
            priceCap
            paidSocialBca
            paidSocialActivationDays
          }
        }
        creator {
          username
          ... on InstagramCreator {
            paidSocialEnabled
          }
        }
      }
    `,
    project
  );
  const { price, currency, id: projectId, brief } = data;
  const priceCap = brief?.priceCap;

  const { openDrawer } = useContext(DrawerContext);

  const dispatch = useDispatch();
  const tracking = useTracking();

  const hasArchivation = data.archivationState;

  const isPs = data.paidSocial || brief?.paidSocialBca;
  const psEnable = data.creator?.paidSocialEnabled;

  const priceData = productPrice
    ? { msg: 'project.stepper.onboarding.product_price' }
    : { text: createSum(price, currency) };

  const createPsBtn = () => {
    if (psEnable) {
      return (
        <div>
          <div className={styles.psEnable}>
            <Text
              color="base"
              weight="500"
              msg="project.stepper.onboarding.contractor.ps.connected"
            />
          </div>
          <Text
            align="center"
            size="sm"
            msg="project.stepper.onboarding.contractor.ps.connected.descr"
          />
        </div>
      );
    }

    return (
      <div>
        <Button
          width="full"
          color="secondary"
          msg="project.stepper.onboarding.contractor.ps.connect"
          className={styles.psEnable}
          onClick={handleFbConnect}
        />
        <Text
          align="center"
          size="sm"
          msg="project.stepper.onboarding.contractor.ps.connect.descr"
        />
      </div>
    );
  };

  const handlePriceChange = () => {
    openDrawer(`new-project-price-${projectId}`);
  };

  const handleProduceInfoClick = () => {
    tracking.trackEvent({ element: 'barter_info', event: 'clicked' });

    dispatch(setModal(modalName.MESSAGE, { ...barterInfoData }));
  };

  const handleFbConnect = () => {
    dispatch(
      setModal(modalName.CONNECT_PS, {
        username: data.creator?.username,
        projectId,
        psDays: data.brief?.paidSocialActivationDays,
        psBca: data.brief?.paidSocialBca,
      })
    );
  };

  return (
    <div>
      {notProductSeeding && (
        <div className={styles.priceWrap}>
          <Text weight="500" msg="project.stepper.onboarding.price" className={styles.priceTitle} />
          <Text tag="h2" weight="500" {...priceData} />
        </div>
      )}
      {!productPrice && notProductSeeding && (
        <Button
          width="full"
          color="normal"
          msg="project.stepper.onboarding.contractor.change_price"
          disabled={!!hasArchivation}
          onClick={handlePriceChange}
        />
      )}
      {productPrice && (
        <Button
          width="full"
          color="normal"
          msg="project.stepper.onboarding.contractor.producr_descr"
          disabled={!!hasArchivation}
          onClick={handleProduceInfoClick}
        />
      )}
      {isPs && createPsBtn()}
      <NewPrice projectId={projectId} currency={currency} priceCap={priceCap} />
    </div>
  );
};

export default track()(OnboardingByContractor);

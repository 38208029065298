import React from 'react';
import { graphql } from 'react-relay';

import ProjectFilterContainer from '../ProjectFilterContainer/ProjectFilterContainer';

import OrganizationBrandsList from './OrganizationBrandsList/OrganizationBrandsList';

export const OrganizationBrandsQuery = graphql`
  query OrganizationBrandsQuery($textQuery: String, $organizationId: ID!) {
    organization(id: $organizationId) {
      brands(active: true, textQuery: $textQuery, withCampaigns: true) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

interface Props {
  organizationId?: string;
  selectedItems: string[];
  onChange: (items: string[]) => void;
}

const OrganizationBrands: React.FC<Props> = (props) => {
  const { selectedItems = [], organizationId, onChange } = props;

  return (
    <ProjectFilterContainer
      selectedItems={selectedItems}
      anchorMsg="projects.all_brands"
      onChange={onChange}
      query={OrganizationBrandsQuery}
      queryParams={{ organizationId }}
      listComponent={<OrganizationBrandsList />}
    ></ProjectFilterContainer>
  );
};

export default OrganizationBrands;

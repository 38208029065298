import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { createSum } from 'Util/numberFormatter';
import Text from 'Components/ui/Text/Text';
import { CreatorPrice_project$key } from 'GraphTypes/CreatorPrice_project.graphql';

interface Props {
  project: CreatorPrice_project$key;
}

const CreatorPrice: React.FC<Props> = (props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment CreatorPrice_project on Project {
        id
        price
        currency
      }
    `,
    project
  );

  if (!data) return null;

  const { price, currency } = data;

  if (!price) return null;

  const sum = createSum(price, currency);

  return <Text type="h2" text={sum} />;
};

export default CreatorPrice;

import { graphql, useMutation } from 'react-relay';

import { SubscribeToCampaignMutation } from 'GraphTypes/SubscribeToCampaignMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const SubscribeToCampaignQueryMutation = graphql`
  mutation SubscribeToCampaignMutation($input: SubscribeToCampaignInput!) {
    subscribeToCampaign(input: $input) {
      clientMutationId
    }
  }
`;

export const useSubscribeToCampaignQueryMutation: BaseMutation<SubscribeToCampaignMutation> = (
  config
) => {
  const [subscribeToCampaignQuery, loading] = useMutation<SubscribeToCampaignMutation>(
    SubscribeToCampaignQueryMutation
  );

  const subscribeToCampaignQueryCaller: BaseMutationExecFn<SubscribeToCampaignMutation> = (
    execConfig
  ) => {
    subscribeToCampaignQuery({
      ...config,
      ...execConfig,
      optimisticUpdater: (store) => {
        const campaignId = config?.variables.input.campaignId;
        if (!campaignId) return;
        const campaign = store.get(campaignId);
        campaign?.setValue(true, 'userSubscribed');
      },
      updater: (store) => {
        const campaignId = config?.variables.input.campaignId;
        if (!campaignId) return;
        const campaign = store.get(campaignId);
        campaign?.setValue(true, 'userSubscribed');
      },
    });
  };
  return [subscribeToCampaignQueryCaller, loading];
};

import React from 'react';

import type { AddonSettings } from '../data';
import rootStyles from '../Plans/Plan/Plan.pcss';

import styles from './AddonsList.pcss';

import Counter from 'Components/ui/Counter/Counter';
interface Props {
  items: AddonSettings[];
  onChange: (addons: AddonSettings[]) => void;
}

const AddonsList: React.FC<Props> = (props) => {
  const { items, onChange } = props;

  const handleChangeValue = (addon: AddonSettings, value: number) => {
    const newAddons = items.map((item) => {
      if (item.key === addon.key) {
        return {
          ...item,
          value,
        };
      }
      return item;
    });
    onChange(newAddons);
  };

  return (
    <div className={styles.root}>
      {items.map((addon) => (
        <Counter
          key={addon.key}
          className={rootStyles.addonsCounter}
          buttonClassName={rootStyles.addonButton}
          minValue={addon.minValue}
          maxValue={addon.maxValue}
          step={addon.stepCount}
          value={addon.defaultValue === null ? Infinity : addon.defaultValue}
          labelMsg={addon.label}
          onChangeValue={(value) => {
            handleChangeValue(addon, value);
          }}
        />
      ))}
    </div>
  );
};

export default AddonsList;

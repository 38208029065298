import { graphql } from 'react-relay';

import type { CustomListQuery as QueryType } from 'GraphTypes/CustomListQuery.graphql';
import type { CustomListInfoQuery as CustomListInfoQueryType } from 'GraphTypes/CustomListInfoQuery.graphql';
import type { CustomListCreators_customList$data } from 'GraphTypes/CustomListCreators_customList.graphql';

const CustomListQuery = graphql`
  query CustomListQuery($id: ID!, $textQuery: String!) {
    currentUser {
      admin
      organization {
        newClient
        churned
        subscription {
          paused
        }
      }
    }
    customList(id: $id) {
      name
      ...CustomListCreators_customList @arguments(textQuery: $textQuery)
    }
  }
`;

const CustomListInfoQuery = graphql`
  query CustomListInfoQuery($id: ID!) {
    customList(id: $id) {
      name
      creators(first: 0) {
        totalCount
      }
    }
  }
`;

export { CustomListQuery, CustomListInfoQuery };

// types

type CustomListQueryType = QueryType & {
  readonly response: QueryType['response'] & {
    readonly customList: CustomListCreators_customList$data | null;
  };
};

export type { CustomListQueryType, CustomListInfoQueryType };

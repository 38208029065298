import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './ReviewStub.pcss';

import { getBookADemoLink } from 'Util/links';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import SimpleLink from 'Components/SimpleLink';
import { DASHBOARD_ROUTE } from 'Constants/general';

const ReviewStub = (props) => {
  const { isFirstCampaign } = props;

  useEffect(() => {
    document.body.classList.add('hidden');

    return () => {
      document.body.classList.remove('hidden');
    };
  }, []);

  const title = 'campaign.review_stub_title';
  const descr = 'campaign.review_stub_descr';

  const buttons = !isFirstCampaign ? (
    <NavLink to={DASHBOARD_ROUTE} state={{ navigation: true }}>
      <Button
        size="lg"
        width="full"
        msg="campaign.review_stub_back_to_campaigns"
        dataTest="button:backToMyCampaigns"
      />
    </NavLink>
  ) : (
    <div className={styles.buttons}>
      <SimpleLink href={getBookADemoLink()} target="_blank" className={styles.button}>
        <Button width="full" color="normal" msg="campaign.review_stub_demo" />
      </SimpleLink>
      <NavLink to={DASHBOARD_ROUTE} className={styles.button} state={{ navigation: true }}>
        <Button width="full" color="primary" msg="campaign.review_stub_dashboard" />
      </NavLink>
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.blank}>
        <div className={styles.content}>
          <Text tag="h2" transform="uppercase" weight="500" msg={title} />
          <Text msg={descr} className={styles.descr} />
          {buttons}
        </div>
      </div>
    </div>
  );
};

export default ReviewStub;

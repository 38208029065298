import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import track from 'react-tracking';
import { isMobile } from 'react-device-detect';

import Stepper from '../ProjectInfo/Stepper/Stepper';

import styles from './CreatorProjectInfo.pcss';
import ProjectStatus from './ProjectStatus/ProjectStatus';
import ProjectPSStatus from './ProjectPSStatus/ProjectPSStatus';
import ProjectDetails from './ProjectDetails/ProjectDetails';

import { createDate } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Warning from 'Atoms/Warning/Warning';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { CreatorProjectInfoQuery as QueryType } from 'GraphTypes/CreatorProjectInfoQuery.graphql';
import useDashly from 'Hooks/useDashly';
import { HELLO_EMAIL } from 'Constants/general';

const CreatorProjectInfoQuery = graphql`
  query CreatorProjectInfoQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      ...ProjectPSStatus_project
      ...ProjectDetails_project
      ...ProjectStatusChat_project
      archivationRequest {
        acceptance {
          id
        }
        rejection {
          id
        }
        side
      }
      deadline {
        draftDate
        publicationDate
      }
      launch {
        id
      }
      currency
    }
  }
`;

interface Props {
  projectId: string;
}

const CreatorProjectInfo: React.FC<Props> = (props) => {
  const { projectId } = props;

  const data = useLazyLoadQuery<QueryType>(CreatorProjectInfoQuery, { projectId });

  if (!data) return <div className={styles.root} />;

  const createDateItem = (date: string, msg: string) => {
    const dateText = createDate(date);

    return (
      <div className={styles.dateItem}>
        <Text size="sm" color="grayDog" msg={msg} />
        <Text weight="500" tag="h3" text={dateText} />
      </div>
    );
  };

  const { open: openDashly } = useDashly();

  const handleDashlyClick = () => {
    openDashly();
  };

  const project = data.project;

  if (!project) return <div className={styles.root} />;

  const { launch: isLaunched, deadline, currency, archivationRequest } = project;

  const hasArchivationRequest =
    archivationRequest &&
    archivationRequest.side === 'CONTRACTOR' &&
    !archivationRequest.acceptance?.id &&
    !archivationRequest.rejection?.id;

  return (
    <div className={styles.root}>
      {hasArchivationRequest && (
        <Warning className={styles.warning}>
          <Text weight="500" size="sm" msg="chat_creator.archivation_request.title" />
          <Text size="sm" msg="chat_creator.archivation_request.descr" />
          <TextButton
            size="sm"
            display="inline"
            onClick={handleDashlyClick}
            msg="chat.request_archivation.in_process.dashly"
          />
          <Text size="sm" msg="chat_creator.archivation_request.descr_2" display="inline" />
          <SmartLink path={`mailto:${HELLO_EMAIL}`}>
            <Text
              color="base"
              weight="500"
              size="sm"
              msg="chat.request_archivation.in_process.email"
            />
          </SmartLink>
        </Warning>
      )}
      <ProjectStatus project={project} />
      {isMobile && <ProjectPSStatus project={project} />}
      {isMobile && isLaunched && currency === 'USD' && (
        <div className={styles.deadline}>
          {deadline?.draftDate &&
            createDateItem(String(deadline.draftDate), 'confirm_dates_modal.draft_date')}
          {deadline?.publicationDate &&
            createDateItem(
              String(deadline.publicationDate),
              'confirm_dates_modal.publication_date'
            )}
        </div>
      )}
      {!isMobile && (
        <div>
          <ProjectDetails project={project} />
          <div className={styles.stepper}>
            <Stepper projectId={projectId} className={styles.section} />
          </div>
        </div>
      )}
    </div>
  );
};

export default track({ subsection: 'creator_project_info' })(CreatorProjectInfo);

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from 'Constants/index';

export interface ActionsDataProps {
  id: string;
  text?: string;
  values?: object;
  btnCaption?: string;
  callback?: (preventUpdate?: boolean) => void;
}

export interface ActionProps {
  type: string;
  payload: ActionsDataProps;
}

export const addNotification = (data: ActionsDataProps): ActionProps => ({
  type: ADD_NOTIFICATION,
  payload: data,
});

export const removeNotification = (data: ActionsDataProps): ActionProps => ({
  type: REMOVE_NOTIFICATION,
  payload: data,
});

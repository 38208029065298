import { useIntl } from 'react-intl';

import { Filters } from './AdminCreativesFilters';

import { RUB, USD, CAD } from 'Constants/general';

export const getFiltersData = (filters: Filters) => {
  const { currencies = [], adminFavoriteCreatives, adminFavoriteCampaigns, paidSocial } = filters;

  const intl = useIntl();

  return {
    currencies: [
      {
        id: USD,
        isChecked: currencies.includes(USD),
        value: USD,
        label: USD,
      },
      {
        id: CAD,
        isChecked: currencies.includes(CAD),
        value: CAD,
        label: CAD,
      },
      {
        id: RUB,
        isChecked: currencies.includes(RUB),
        value: RUB,
        label: RUB,
      },
    ],
    additional: [
      {
        id: 'adminFavoriteCreatives',
        isChecked: !!adminFavoriteCreatives,
        value: intl.formatMessage({
          id: 'search_section.form.adminFavoriteCreatives',
        }),
        labelMsg: 'search_section.form.adminFavoriteCreatives',
      },
      {
        id: 'adminFavoriteCampaigns',
        isChecked: !!adminFavoriteCampaigns,
        value: intl.formatMessage({
          id: 'search_section.form.adminFavoriteCampaigns',
        }),
        labelMsg: 'search_section.form.adminFavoriteCampaigns',
      },
      {
        id: 'paidSocial',
        isChecked: !!paidSocial,
        value: intl.formatMessage({
          id: 'search_section.form.paidSocial',
        }),
        labelMsg: 'search_section.form.paidSocial',
      },
    ],
  };
};

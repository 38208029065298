import React, { FC, useEffect, useState, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { GraphQLTaggedNode } from 'relay-runtime';
import find from 'lodash/find';

import styles from './OutreachTable.pcss';
import OutreachBatch from './OutreachBatch/OutreachBatch';
import OutreachHeader from './OutreachHeader/OutreachHeader';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { OutreachBatchesQuery } from 'GraphTypes/OutreachBatchesQuery.graphql';

interface Props {
  showPercentages: boolean;
  campaignId: string;
  queryReference: any;
  query: GraphQLTaggedNode;
}

const OutreachTable: FC<Props> = ({ showPercentages, campaignId, queryReference, query }) => {
  const data = usePreloadedQuery<OutreachBatchesQuery>(query, queryReference);

  const batches = data?.campaign?.outreachBatches;
  const batchesId = batches?.edges?.map((batch) => batch?.node?.id || '') || [];

  const hasUncomplitedImports = useMemo(() => {
    return !!find(batches?.edges, (batch) => {
      return !batch?.node?.creatorImport?.completed;
    });
  }, [batches?.edges]);
  const [isTooltipVisible, setTooltipVisible] = useState(hasUncomplitedImports);
  const [canShowTooltip, setCanShowTooltip] = useState(true);

  useEffect(() => {
    setTooltipVisible(hasUncomplitedImports);
  }, [hasUncomplitedImports]);

  const handleCloseTooltip = () => {
    setCanShowTooltip(false);
    setTooltipVisible(false);
  };

  return (
    <>
      {Number(batches?.edges?.length) > 1 && (
        <OutreachHeader
          campaignId={campaignId}
          batchesId={batchesId}
          showPercentage={showPercentages}
        />
      )}
      <div className={styles.tooltipBlock}>
        <Tooltip
          id="import-in-progress"
          showByDefault={isTooltipVisible && canShowTooltip}
          place="top"
          clickable
          customTooltip={
            <div>
              <Text type="md" msg="campaign.outreach.can_close" className={styles.tooltipText} />
              <Icon
                name={'Close-small'}
                size={16}
                handleClick={handleCloseTooltip}
                className={styles.closeTooltip}
              />
            </div>
          }
        ></Tooltip>
      </div>
      {batches?.edges?.map((edge) => (
        <OutreachBatch
          key={edge?.node?.id}
          batch={edge?.node}
          campaignId={campaignId}
          showPercentage={showPercentages}
        />
      ))}
    </>
  );
};

export default OutreachTable;

/**
 * @generated SignedSource<<f0219e13bf0248abcfb6c13cccc53608>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthenticateWithShopifyInput = {
  clientMutationId?: string | null | undefined;
  code?: string | null | undefined;
  error?: string | null | undefined;
  hmac: string;
  host?: string | null | undefined;
  id?: string | null | undefined;
  scope: string;
  shopName: string;
  state?: string | null | undefined;
  timestamp: string;
};
export type AuthenticateWithShopifyMutation$variables = {
  input: AuthenticateWithShopifyInput;
};
export type AuthenticateWithShopifyMutation$data = {
  readonly authenticateWithShopify: {
    readonly __typename: "AuthenticateWithShopifyPayload";
    readonly shopifyAuthorization: {
      readonly id: string;
      readonly reauthenticationRequired: boolean;
    };
  } | {
    readonly __typename: "AuthenticateWithShopify_InvalidScopeError";
    readonly message: string;
  } | {
    readonly __typename: "NotFoundError";
    readonly message: string;
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type AuthenticateWithShopifyMutation = {
  response: AuthenticateWithShopifyMutation$data;
  variables: AuthenticateWithShopifyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "authenticateWithShopify",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopifyAuthorization",
            "kind": "LinkedField",
            "name": "shopifyAuthorization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reauthenticationRequired",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "AuthenticateWithShopifyPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "AuthenticateWithShopify_InvalidScopeError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "NotFoundError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ValidationError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticateWithShopifyMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthenticateWithShopifyMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "13200a7ad1e53749322e094e046995a0",
    "id": null,
    "metadata": {},
    "name": "AuthenticateWithShopifyMutation",
    "operationKind": "mutation",
    "text": "mutation AuthenticateWithShopifyMutation(\n  $input: AuthenticateWithShopifyInput!\n) {\n  authenticateWithShopify(input: $input) {\n    __typename\n    ... on AuthenticateWithShopifyPayload {\n      shopifyAuthorization {\n        id\n        reauthenticationRequired\n      }\n    }\n    ... on AuthenticateWithShopify_InvalidScopeError {\n      message\n    }\n    ... on NotFoundError {\n      message\n    }\n    ... on ValidationError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6bb308402ad63adf9ed1b9ef05288bf";

export default node;

import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { getAvatar } from '../util';

import Message from './Message/Message';
import MessageAttachments from './MessageAttachments/MessageAttachments';

import { UserType } from 'GraphTypes/ProjectEventsQuery.graphql';
import { escapeText } from 'Util/textFormatter';
import { MessageEvent_event$key } from 'GraphTypes/MessageEvent_event.graphql';

interface Props {
  event: MessageEvent_event$key;
  userType?: UserType;
  inPopup?: boolean;
  admin?: boolean;
}

const MessageEvent: React.FC<Props> = (props) => {
  const { event, userType, inPopup, admin } = props;

  const data = useFragment(
    graphql`
      fragment MessageEvent_event on ProjectEvent {
        id
        __typename
        createdAt
        readByAdvertiser
        readByContractor
        ...MessageAttachments_message
        ... on Message {
          text
          deletedAt
        }
        actor {
          __typename
          id
          ... on InstagramCreator {
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
          ... on YoutubeCreator {
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
          ... on TiktokCreator {
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
          ... on Brand {
            logo {
              ... on Image {
                transformations {
                  brandLogoUrl
                }
              }
            }
          }
        }
      }
    `,
    event
  );

  if (!data) {
    return null;
  }

  const id = data.id;
  const text = data.text;
  const actor = data.actor;
  const readByAdvertiser = data.readByAdvertiser;
  const readByContractor = data.readByContractor;
  const deletedAt = data.deletedAt;
  const createdAt = String(data.createdAt);

  const avaSrc =
    getAvatar(actor) ?? `https://www.gravatar.com/avatar/${actor?.id}?s=180&d=retro&r=x`;

  const isOwner =
    (userType === 'ADVERTISER' && actor?.__typename === 'Brand') ||
    (userType === 'CONTRACTOR' && actor?.__typename !== 'Brand');
  const isRead =
    (userType === 'ADVERTISER' && readByContractor) ||
    (userType === 'CONTRACTOR' && readByAdvertiser);

  const actionAble = userType === 'ADVERTISER' && !isOwner;

  const attachments = (
    <MessageAttachments
      message={data}
      isOwner={isOwner}
      createdAt={createdAt}
      isRead={isRead}
      actionAble={actionAble}
    />
  );

  return (
    <div>
      <Message
        id={id}
        inPopup={inPopup}
        avaSrc={avaSrc}
        text={escapeText(text)}
        createdAt={createdAt}
        isOwner={isOwner}
        isRead={isRead}
        attachments={attachments}
        admin={admin}
        deleted={!!deletedAt}
        type="Message"
      />
    </div>
  );
};

export default MessageEvent;

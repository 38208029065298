import React, { Context, PropsWithChildren, useState } from 'react';

import { FeatureFlagsContextType } from './FeatureFlags.Context';
import { featureFlagsToFeatureFlagsSet, getDefaultFeatureFlagsSet } from './utils';
import type { FeatureFlagsSet } from './types';

type Props = {
  context: Context<FeatureFlagsContextType>;
};

const FeatureFlagsProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, context: Context } = props;
  const [featureFlagsState, setFeatureFlagsState] = useState<FeatureFlagsSet | null>(null);
  const [currentUserId, setCurrentUserId] = useState('');

  const initFeatureFlags: FeatureFlagsContextType['initFeatureFlags'] = (featureFlags, userId) => {
    if (currentUserId === userId) return;

    const _featureFlags = new Set([
      ...featureFlagsToFeatureFlagsSet(featureFlags),
      ...getDefaultFeatureFlagsSet(),
    ]);
    setFeatureFlagsState(_featureFlags);
    setCurrentUserId(userId);
  };

  const hasFeatureFlag: FeatureFlagsContextType['hasFeatureFlag'] = (featureFlagId) => {
    if (featureFlagsState === null) return false;
    return featureFlagsState.has(featureFlagId);
  };

  const contextValue: FeatureFlagsContextType = {
    initFeatureFlags,
    hasFeatureFlag,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { FeatureFlagsProvider };

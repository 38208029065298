/**
 * @generated SignedSource<<ad81536e0ce9fae6de558b1c2bd0574b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Reference_item$data = {
  readonly file: {
    readonly originalFilename: string | null | undefined;
    readonly processingStatus?: FileProcessingStatus | null | undefined;
    readonly secureUrl: string;
    readonly thumbnailUrl: string;
    readonly transformations?: {
      readonly autoUrl?: string;
      readonly collageThumbnailUrl: string;
    } | null | undefined;
    readonly type: FileType;
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "Reference_item";
};
export type Reference_item$key = {
  readonly " $data"?: Reference_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"Reference_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Reference_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secureUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalFilename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Image",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "processingStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "autoUrl",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Video",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Reference",
  "abstractKey": null
};
})();

(node as any).hash = "d2116e5928c948c7d3233962b396b409";

export default node;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  Accounting_UpdateTransactionInput,
  UpdateTransactionMutation$data,
  UpdateTransactionMutation,
} from 'GraphTypes/UpdateTransactionMutation.graphql';

const mutation = graphql`
  mutation UpdateTransactionMutation($input: Accounting_UpdateTransactionInput!) {
    accounting {
      updateTransaction(input: $input) {
        clientMutationId
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Accounting_UpdateTransactionInput,
  resolve?: (response: UpdateTransactionMutation$data) => void,
  reject?: fnType
) => {
  const { revenueTransactionId, comment, verifiedByAdmin, type, subscriptionPlanId } = data;

  const variables = {
    input: {
      comment,
      verifiedByAdmin,
      revenueTransactionId,
      subscriptionPlanId,
      type,
    },
  };

  commitMutation<UpdateTransactionMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (!revenueTransactionId) return;
      const transaction = store.get(revenueTransactionId);
      if (!transaction) return;

      if (verifiedByAdmin !== undefined) {
        transaction?.setValue(verifiedByAdmin, 'verifiedByAdmin');
      }

      if (comment !== undefined) {
        transaction?.setValue(comment, 'comment');
      }

      if (type !== undefined) {
        transaction?.setValue(type, 'type');
      }
    },
    updater: (store) => {
      if (!revenueTransactionId) return;
      const transaction = store.get(revenueTransactionId);
      if (!transaction) return;

      if (verifiedByAdmin !== undefined) {
        transaction?.setValue(verifiedByAdmin, 'verifiedByAdmin');
      }

      if (comment !== undefined) {
        transaction?.setValue(comment, 'comment');
      }

      if (type !== undefined) {
        transaction?.setValue(type, 'type');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useState } from 'react';
import classnames from 'classnames';

import styles from './CreatorsReport.pcss';

import IconNew from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import Tag from 'Components/ui/Tag/Tag';
import Spinner from 'Atoms/Spinner/Spinner';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import ReportButton, { ReportType } from 'Atoms/ReportButton/ReportButton';
import { IconName } from 'Components/ui/types';

interface Props {
  campaignId: string;
  className: string;
}

const CreatorsReport: React.FC<Props> = (props) => {
  const { campaignId, className } = props;
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState<boolean | undefined>(undefined);

  const handleDownloadReportDone = () => {
    setLoading(false);
    setSucceeded(true);
  };
  const handleDownloadReportFailed = () => {
    setLoading(false);
    setSucceeded(false);
  };

  const handleDownloadReportStart = () => {
    setLoading(true);
  };

  let iconName = 'Download-upload';

  if (succeeded) {
    iconName = 'Check';
  } else if (succeeded === false) {
    iconName = 'Warning-error';
  }

  const disabled = !!succeeded;

  return (
    <ReportButton
      type={ReportType.MEDIAPLAN}
      reportData={{ campaignId }}
      onDownload={handleDownloadReportDone}
      onDownloadError={handleDownloadReportFailed}
    >
      <TextPreset
        className={classnames(className, {
          [styles.disabled]: loading,
        })}
        header={<Text className={styles.title} msg="campaign.drawer.download_mediaplan" />}
        rightComponent={loading ? <Spinner size="sm" /> : <IconNew name={iconName as IconName} />}
        handleClick={disabled ? undefined : handleDownloadReportStart}
      />
    </ReportButton>
  );
};

export default CreatorsReport;

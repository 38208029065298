import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { useIntl } from 'react-intl';
import compact from 'lodash/compact';
import difference from 'lodash/difference';

import styles from './CampaignList.css';

import environment from 'Api/Environment';
import { CampaignListQuery as QueryType } from 'GraphTypes/CampaignListQuery.graphql';

const CampaignListQuery = graphql`
  query CampaignListQuery($id: ID!, $textQuery: String) {
    organization(id: $id) {
      name
      campaigns(textQuery: $textQuery) {
        edges {
          node {
            id
            name
            brand {
              logo {
                ... on Image {
                  transformations {
                    brandLogoUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  id: string;
  menu?: any;
  selectedIds?: string[];
  defaultCampaignId?: string;
  textQuery?: string | null;
}

const CampaignList: React.FC<Props> = (props) => {
  const { menu, id, textQuery, defaultCampaignId, selectedIds } = props;

  const intl = useIntl();

  if (!menu) return null;

  return (
    <div id="campaignsList" className={styles.root}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={CampaignListQuery}
        variables={{ id, textQuery }}
        render={({ props: queryProps }) => {
          if (!queryProps) return null;
          const organization = queryProps.organization;
          const organizationName = organization?.name;

          const items = organization?.campaigns?.edges || [];

          const allIds: { id: string; name: string | null }[] = [];
          const ids: string[] = [];

          const itemsList = compact(
            items.map((item) => {
              if (!item?.node) return null;
              const { id, name, brand } = item.node;

              if (id === defaultCampaignId) return null;

              const avaSrc = brand?.logo?.transformations?.brandLogoUrl;

              allIds.push({ id, name });
              ids.push(id);

              return {
                node: { id, name, subName: organizationName, avaData: { size: 24, src: avaSrc } },
              };
            })
          );

          const diffArr = selectedIds ? difference(ids, selectedIds) : null;
          const allSelected = ids.length !== 0 && diffArr && diffArr.length === 0;

          const diffArrItems = compact(
            itemsList.map((item) => {
              if (diffArr?.includes(item.node.id)) {
                return { ...item.node };
              }

              return null;
            })
          );

          const defaultItems = defaultCampaignId
            ? compact([
                ids.length > 0
                  ? {
                      node: {
                        id: allSelected ? allIds : diffArrItems,
                        name: intl.formatMessage({
                          id: allSelected
                            ? 'create_campaign.search_params.exclude_campaigns.unselect_all'
                            : 'create_campaign.search_params.exclude_campaigns.select_all',
                        }),
                        className: styles.allCampaigns,
                        forceActiveState: allSelected && ids.length > 0,
                      },
                    }
                  : null,
                {
                  node: {
                    id: defaultCampaignId,
                    name: intl.formatMessage({
                      id: 'create_campaign.search_params.exclude_campaigns.this.campaign',
                    }),
                  },
                },
              ])
            : [];

          const finalItemsList = [...defaultItems, ...itemsList];

          const style = { width: '100%' };

          return React.cloneElement(menu, { items: finalItemsList, style, withLoading: true });
        }}
      />
    </div>
  );
};

export default CampaignList;

import React, { useContext } from 'react';

import styles from './CancelSubscriptionDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { amplitude } from 'Helpers/amplitude';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CurrentSubscriptionInfo_organization$data } from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';

const features = [
  'billing.cancel.drawer.feature.marketplace',
  'billing.cancel.drawer.feature.library',
  'billing.cancel.drawer.feature.ongoing',
  'billing.cancel.drawer.feature.licenses',
];

const loses = [
  'billing.cancel.drawer.lose.library',
  'billing.cancel.drawer.lose.ongoing',
  'billing.cancel.drawer.lose.licenses',
  'billing.cancel.drawer.lose.price',
];

interface Props {
  isAllowToPauseAgain: boolean;
}

const CancelSubscriptionDrawer: React.FC<Props> = ({ isAllowToPauseAgain }) => {
  const { openDrawer } = useContext(DrawerContext);

  const handlePauseClick = () => {
    openDrawer('pause-subscription-drawer');
    amplitude.sendEvent<377>({
      id: '377',
      category: 'billing',
      name: 'refused_pause_offer',
    });
  };

  const handleContinueClick = () => {
    openDrawer('cancel-subscription-typeform-drawer');
    amplitude.sendEvent<376>({
      id: '376',
      category: 'billing',
      name: 'initiated_pause_after_cancel_intent',
    });
  };

  return (
    <Drawer
      rootKey={'cancel-subscription-drawer'}
      className={styles.drawerWrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      needCloseButton
      removeWhenClosed
    >
      <div className={styles.root}>
        <div className={styles.formContainer}>
          <Text type="d2" msg="billing.cancel.drawer.title" className={styles.title} />
          <Text type="md" msg="billing.cancel.drawer.subtitle" className={styles.subtitle} />
          <div className={styles.itemContainer}>
            {features.map((item) => (
              <div className={styles.item}>
                <Icon name="Check" className={styles.itemIconFeature} />
                <Text key={item} type="md" msg={item} className={styles.itemText} />
              </div>
            ))}
          </div>
          <Text type="d2" msg="billing.cancel.drawer.ifcancel" className={styles.itemDividerText} />
          <div className={styles.itemContainer}>
            {loses.map((item) => (
              <div className={styles.item}>
                <Icon name="Close-small" className={styles.itemIconLose} />
                <Text key={item} type="md" msg={item} className={styles.itemText} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.buttonContainer}>
            <Button
              color="black"
              fluid
              disabled={!isAllowToPauseAgain}
              msg={'billing.cancel.drawer.button.pause'}
              onClick={handlePauseClick}
            />
            <Button
              color="white"
              fluid
              msg={'billing.cancel.drawer.button.back'}
              onClick={handleContinueClick}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CancelSubscriptionDrawer;

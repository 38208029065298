/**
 * @generated SignedSource<<ca2c01f05e2a91496b30312316aad402>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserType = "ADVERTISER" | "CONTRACTOR" | "EPHEMERAL" | "UNKNOWN" | "%future added value";
export type NavigationQuery$variables = Record<PropertyKey, never>;
export type NavigationQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly campaigns: {
        readonly totalCount: number;
      } | null | undefined;
      readonly projects: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
    readonly type: UserType;
  } | null | undefined;
};
export type NavigationQuery = {
  response: NavigationQuery$data;
  variables: NavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "ACTIVE",
        "IN_REVIEW",
        "COMPLETED"
      ]
    }
  ],
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": "campaigns(stages:[\"ACTIVE\",\"IN_REVIEW\",\"COMPLETED\"])"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "ACCEPTED",
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED",
        "COMPLETED"
      ]
    },
    {
      "kind": "Literal",
      "name": "withUnreadEvents",
      "value": true
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": "projects(stages:[\"ACCEPTED\",\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\",\"COMPLETED\"],withUnreadEvents:true)"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dba8cd3b2763b1eec9ff597395a0bae",
    "id": null,
    "metadata": {},
    "name": "NavigationQuery",
    "operationKind": "query",
    "text": "query NavigationQuery {\n  currentUser {\n    type\n    organization {\n      campaigns(stages: [ACTIVE, IN_REVIEW, COMPLETED]) {\n        totalCount\n      }\n      projects(withUnreadEvents: true, stages: [ACCEPTED, LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED]) {\n        totalCount\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a83833cd0a16c4de84b258f20a2ca95b";

export default node;

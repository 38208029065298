import { commitMutation, graphql } from 'react-relay';
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes';
import { ConnectionHandler } from 'relay-runtime';

import {
  RemoveCreatorFromCustomListMutation,
  RemoveCreatorFromCustomListMutation$data,
  RemoveCreatorFromCustomListInput,
  RemoveCreatorFromCustomListMutation$variables,
} from 'GraphTypes/RemoveCreatorFromCustomListMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation RemoveCreatorFromCustomListMutation($input: RemoveCreatorFromCustomListInput!) {
    removeCreatorFromCustomList(input: $input) {
      __typename
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: RemoveCreatorFromCustomListInput,
  resolve?: (response: RemoveCreatorFromCustomListMutation$data) => void,
  reject?: FnType
) => {
  const { organizationId, creatorId, customListId } = data;
  const variables: RemoveCreatorFromCustomListMutation$variables = {
    input: {
      ...data,
      organizationId: undefined,
    },
  };

  const updater: SelectorStoreUpdater<RemoveCreatorFromCustomListMutation$data> = (store) => {
    const updateCustomLists = async () => {
      if (!organizationId) return;
      const customLists = store
        ?.get(organizationId)
        ?.getLinkedRecord('customLists')
        ?.getLinkedRecords('edges')
        ?.find((item) => item?.getLinkedRecord('node')?.getValue('id') === customListId);

      if (!customLists) return;

      const creators = customLists
        ?.getLinkedRecord('node')
        ?.getLinkedRecord('creators')
        ?.getLinkedRecords('edges');

      if (!creators) return;

      const edges = creators.filter((item) => {
        const id = item.getLinkedRecord('node')?.getValue('id');
        return id !== creatorId;
      });
      customLists
        .getLinkedRecord('node')
        ?.getLinkedRecord('creators')
        ?.setLinkedRecords(edges, 'edges');
    };

    const updateCustomList = async () => {
      const customList = store.get(customListId);
      if (!customList) return;
      const customListCreators = ConnectionHandler.getConnection(customList, 'CustomList_creators');
      const filteredCustomList = customListCreators?.getLinkedRecords('edges')?.filter((edge) => {
        return edge?.getLinkedRecord('node')?.getValue('id') !== creatorId;
      });
      const totalCount = filteredCustomList?.length || 0;
      customListCreators?.setLinkedRecords(filteredCustomList, 'edges');
      customListCreators?.setValue(totalCount, 'totalCount');
    };

    const updateCustomListInfo = async () => {
      const root = store.getRoot();
      const creators = root
        .getLinkedRecord('customList', { id: customListId })
        ?.getLinkedRecord('creators', { first: 0 });
      if (!creators) return;
      const totalCount = creators?.getValue('totalCount');
      const newTotalCount =
        ((totalCount && Number(totalCount) >= 0 ? creators?.getValue('totalCount') : 0) as number) -
        1;
      creators.setValue(newTotalCount, 'totalCount');
    };

    updateCustomLists();
    updateCustomList();
    updateCustomListInfo();
  };

  commitMutation<RemoveCreatorFromCustomListMutation>(environment, {
    mutation,
    variables,
    updater,
    optimisticUpdater: updater,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

export type {
  RemoveCreatorFromCustomListMutation,
  RemoveCreatorFromCustomListMutation$data,
  RemoveCreatorFromCustomListInput,
  RemoveCreatorFromCustomListMutation$variables,
};

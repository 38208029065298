import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { useContext } from 'react';
import ErrorHandler from 'Util/errorHandler';
import { amplitude } from 'Helpers/amplitude';
import { useCancelSubscriptionMutation } from 'Api/mutations/CancelSubscription.Mutation';

export const useCancelSubscription = (subscriptionId: string): [() => void, boolean] => {
  const [cancelSubscriptionMutation, cancelLoading] = useCancelSubscriptionMutation();
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const cancelSubscription = () => {
    cancelSubscriptionMutation({
      variables: {
        input: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        openDrawer('cancel-subscription-success-drawer');
        amplitude.sendEvent<380>({
          id: '380',
          category: 'billing',
          name: 'confirmed_cancel_plan',
        });
      },
      onError: (error) => {
        ErrorHandler.error('User cannot cancel subscription', { error });
        closeDrawer('cancel-subscription-offer-drawer');
      },
    });
  };
  return [cancelSubscription, cancelLoading];
};

import React, { useState } from 'react';

import styles from '../Home.pcss';
import { videos } from '../data';
import VideoItem from '../VideoItem/VideoItem';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import { CreateCampaignInput } from 'GraphTypes/CreateCampaignMutation.graphql';
import Text from 'Components/ui/Text/Text';

const OldCases = () => {
  const [loading, setLoading] = useState(false);
  const { createNewCampaign } = useCampaignActions();

  const handleCreateBriefClick = (campaignData: CreateCampaignInput) => {
    setLoading(true);

    createNewCampaign({ campaignData });
  };

  return (
    <div>
      <Text type="d2" msg="home.title" className={styles.title} />
      <Text type="md" msg="home.descr" className={styles.descr} />
      <div className={styles.videos}>
        {videos.map((item) => {
          return (
            <VideoItem
              key={item.id}
              video={item}
              loading={loading}
              onCreateBriefClick={handleCreateBriefClick}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OldCases;

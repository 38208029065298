import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';

const subscription = graphql`
  subscription FileBcaTaggingStatusUpdatedSubscription {
    fileBcaTaggingStatusUpdated {
      id
      bcaTaggedUrl
      bcaTaggingStatus
    }
  }
`;


export default () => {
  requestSubscription(environment, {
    subscription,
    variables: {},
  });
};

/**
 * @generated SignedSource<<17dde8f375934bbe77c9380d6c129ba8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApplyOneTimeDiscountToSubscriptionInput = {
  clientMutationId?: string | null | undefined;
  discountId: string;
  subscriptionId: string;
};
export type ApplyOneTimeDiscountToSubscriptionMutation$variables = {
  input: ApplyOneTimeDiscountToSubscriptionInput;
};
export type ApplyOneTimeDiscountToSubscriptionMutation$data = {
  readonly applyOneTimeDiscountToSubscription: {
    readonly advertiserSubscription?: {
      readonly canApplyDiscount: boolean;
      readonly discountOfferAcceptedAt: any | null | undefined;
      readonly lastSchedule: {
        readonly discount: {
          readonly percentOff: number | null | undefined;
        } | null | undefined;
        readonly startDate: any;
        readonly trialEnd: any | null | undefined;
      } | null | undefined;
      readonly priceAmount: number | null | undefined;
    };
    readonly message?: string;
  } | null | undefined;
};
export type ApplyOneTimeDiscountToSubscriptionMutation = {
  response: ApplyOneTimeDiscountToSubscriptionMutation$data;
  variables: ApplyOneTimeDiscountToSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceAmount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canApplyDiscount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountOfferAcceptedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentOff",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialEnd",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": "__isError"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplyOneTimeDiscountToSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "applyOneTimeDiscountToSubscription",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionSchedule",
                    "kind": "LinkedField",
                    "name": "lastSchedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Discount",
                        "kind": "LinkedField",
                        "name": "discount",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ApplyOneTimeDiscountToSubscriptionPayload",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplyOneTimeDiscountToSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "applyOneTimeDiscountToSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionSchedule",
                    "kind": "LinkedField",
                    "name": "lastSchedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Discount",
                        "kind": "LinkedField",
                        "name": "discount",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ApplyOneTimeDiscountToSubscriptionPayload",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "062d789e9f80c152663997fb99e705f4",
    "id": null,
    "metadata": {},
    "name": "ApplyOneTimeDiscountToSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation ApplyOneTimeDiscountToSubscriptionMutation(\n  $input: ApplyOneTimeDiscountToSubscriptionInput!\n) {\n  applyOneTimeDiscountToSubscription(input: $input) {\n    __typename\n    ... on ApplyOneTimeDiscountToSubscriptionPayload {\n      advertiserSubscription {\n        priceAmount\n        canApplyDiscount\n        discountOfferAcceptedAt\n        lastSchedule {\n          discount {\n            percentOff\n            id\n          }\n          trialEnd\n          startDate\n          id\n        }\n        id\n      }\n    }\n    ... on Error {\n      __isError: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1547b7405534888c5ed9c2e36cd2b407";

export default node;

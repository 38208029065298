import React from 'react';
import classnames from 'classnames';

import styles from './Form.css';
import Content from './Content/Content';
import Dropdown from './Dropdown/Dropdown';

const FormItem = (props) => {
  const {
    className,
    data,
    searchParams,
    onChange,
    content,
    newDesign,
    afterElement,
    beforeElement,
    customElement,
  } = props;

  const createElement = (item, index) => {
    const { isDropdown, dropDownPosition } = item;
    const data = { onChange, searchParams, customElement, ...item };

    return isDropdown ? (
      <Dropdown key={index} position={dropDownPosition} {...data} newDesign={newDesign} />
    ) : (
      <Content key={index} {...data} />
    );
  };

  const createElements = (list) => list.map((item, index) => createElement(item, index));

  return (
    <div className={classnames(className, styles.root, { [styles.newDesign]: newDesign })}>
      {beforeElement}
      {data.map((list, index) => (
        <div key={index} className={styles.dropdownGroup}>
          {createElements(list)}
        </div>
      ))}
      {afterElement}
      {content}
    </div>
  );
};

export default FormItem;

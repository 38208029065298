import { commitMutation, graphql } from 'react-relay';
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes';
import { ConnectionHandler } from 'relay-runtime';

import {
  AddCreatorToCustomListMutation,
  AddCreatorToCustomListMutation$data,
  AddCreatorToCustomListInput,
  AddCreatorToCustomListMutation$variables,
} from 'GraphTypes/AddCreatorToCustomListMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation AddCreatorToCustomListMutation($input: AddCreatorToCustomListInput!) {
    addCreatorToCustomList(input: $input) {
      __typename
      ... on AddCreatorToCustomListPayload {
        creator {
          id
          username
          customLists {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: AddCreatorToCustomListInput,
  resolve?: (response: AddCreatorToCustomListMutation$data) => void,
  reject?: FnType
) => {
  const { creatorId, organizationId, customListId } = data;
  const variables: AddCreatorToCustomListMutation$variables = {
    input: {
      ...data,
      organizationId: undefined,
    },
  };

  const updater: SelectorStoreUpdater<AddCreatorToCustomListMutation$data> = (store) => {
    const updateOrganization = () => {
      if (!organizationId) return;
      const creators = store
        .get(organizationId)
        ?.getLinkedRecord('customLists')
        ?.getLinkedRecords('edges')
        ?.find((item) => item?.getLinkedRecord('node')?.getValue('id') === customListId)
        ?.getLinkedRecord('node')
        ?.getLinkedRecord('creators');
      if (creators) {
        const creatorRecord = store.get(creatorId);
        if (!creatorRecord) return;
        const edge = ConnectionHandler.createEdge(store, creators, creatorRecord, 'CreatorEdge');
        ConnectionHandler.insertEdgeAfter(creators, edge);
      }
    };

    const updateCurrentUser = () => {
      const root = store.getRoot();
      const customListEdges = root
        .getLinkedRecord('organization', { id: organizationId })
        ?.getLinkedRecord('customLists')
        ?.getLinkedRecords('edges');

      if (!customListEdges) return;

      customListEdges.forEach((customList, key) => {
        const id = customList?.getLinkedRecord('node')?.getValue('id');
        if (id && id === customListId) {
          const creatorRecord = store.get(creatorId);
          const customListNode = customList?.getLinkedRecord('node');
          if (!creatorRecord || !customListNode) return;
          const connectionId = `client:${customListId}:creators`;
          const connection = store.get(connectionId);
          const newConnection = connection || store.create(connectionId, 'CreatorConnection');
          const edge = ConnectionHandler.createEdge(
            store,
            newConnection,
            creatorRecord,
            'CreatorEdge'
          );
          ConnectionHandler.insertEdgeAfter(newConnection, edge);
          customList?.getLinkedRecord('node')?.setLinkedRecord(newConnection, 'creators');
        }
      });
    };

    updateOrganization();
    updateCurrentUser();
  };

  commitMutation<AddCreatorToCustomListMutation>(environment, {
    mutation,
    variables,
    updater,
    optimisticUpdater: updater,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

export type {
  AddCreatorToCustomListMutation,
  AddCreatorToCustomListMutation$data,
  AddCreatorToCustomListInput,
  AddCreatorToCustomListMutation$variables,
};

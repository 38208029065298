import React from 'react';

import styles from './DownloadApp.pcss';

import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import QRCodeSrc from 'Images/general/app-QR-code.svg';
import downloadAppSrc from 'Images/creators/download_app.png';
import CreatorMobileBanner from 'Molecules/CreatorMobileBanner/CreatorMobileBanner';

const DownloadApp = () => {
  return (
    <>
      <div className={styles.desktopView}>
        <div className={styles.imageWrapper}>
          <img src={downloadAppSrc} />
        </div>
        <div className={styles.textWrapper}>
          <Text tag="h1" weight="500" msg="general.download_the_app" className={styles.title} />
          <div className={styles.textList}>
            <div className={styles.textListItem}>
              <Icon name="Check" className={styles.checkIcon} />
              <Text msg="contractor.download_app_bullets.badges" />
            </div>
            <div className={styles.textListItem}>
              <Icon name="Check" className={styles.checkIcon} />
              <Text msg="contractor.download_app_bullets.withdraw" />
            </div>
            <div className={styles.textListItem}>
              <Icon name="Check" className={styles.checkIcon} />
              <Text msg="contractor.download_app_bullets.free" />
            </div>
          </div>
          <div className={styles.QRWrapper}>
            <div className={styles.QRCode}>
              <img src={QRCodeSrc} />
            </div>
            <div className={styles.QRText}>
              <Text msg="contractor.qr_code" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobileView}>
        <CreatorMobileBanner />
      </div>
    </>
  );
};

export default DownloadApp;

/**
 * @generated SignedSource<<75683649eda836d595027561cabedd69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthenticateWithFacebookInput = {
  accessToken?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  code?: string | null | undefined;
  error?: string | null | undefined;
  id?: string | null | undefined;
};
export type AuthenticateWithFacebookMutation$variables = {
  input: AuthenticateWithFacebookInput;
};
export type AuthenticateWithFacebookMutation$data = {
  readonly authenticateWithFacebook: {
    readonly socialIdentity: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type AuthenticateWithFacebookMutation = {
  response: AuthenticateWithFacebookMutation$data;
  variables: AuthenticateWithFacebookMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthenticateWithFacebookPayload",
    "kind": "LinkedField",
    "name": "authenticateWithFacebook",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SocialIdentity",
        "kind": "LinkedField",
        "name": "socialIdentity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticateWithFacebookMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthenticateWithFacebookMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e515f0143cdb37007d87dd3961f14459",
    "id": null,
    "metadata": {},
    "name": "AuthenticateWithFacebookMutation",
    "operationKind": "mutation",
    "text": "mutation AuthenticateWithFacebookMutation(\n  $input: AuthenticateWithFacebookInput!\n) {\n  authenticateWithFacebook(input: $input) {\n    socialIdentity {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a11814f7ea60c037350620fdf2cbbf42";

export default node;

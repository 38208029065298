/**
 * @generated SignedSource<<a6cbd3bac972d00e8bc87e86a9acacd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorDetails_project$data = {
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly creator: {
    readonly channel?: {
      readonly subscribers: number | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly ownership: {
      readonly owner: {
        readonly rating: {
          readonly averageScore: number;
        } | null | undefined;
      };
    } | null | undefined;
    readonly profile: {
      readonly badges: ReadonlyArray<{
        readonly name: string;
      }> | null | undefined;
      readonly generalStatistic: {
        readonly engagementRate: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly ratingVotes: {
      readonly totalCount: number;
    };
    readonly tiktokUser?: {
      readonly engagementRate: number | null | undefined;
      readonly followedByCount: number | null | undefined;
    } | null | undefined;
    readonly type: CreatorType;
    readonly user?: {
      readonly engagementRate: number | null | undefined;
      readonly followedByCount: number | null | undefined;
    } | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly id: string;
  readonly previousCollaborator: boolean;
  readonly " $fragmentType": "CreatorDetails_project";
};
export type CreatorDetails_project$key = {
  readonly " $data"?: CreatorDetails_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorDetails_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementRate",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "followedByCount",
    "storageKey": null
  },
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorDetails_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "previousCollaborator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Badge",
              "kind": "LinkedField",
              "name": "badges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GeneralCreatorStatistic",
              "kind": "LinkedField",
              "name": "generalStatistic",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "side",
              "value": "AGENCY"
            }
          ],
          "concreteType": "RatingVoteConnection",
          "kind": "LinkedField",
          "name": "ratingVotes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "ratingVotes(side:\"AGENCY\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorOwnership",
          "kind": "LinkedField",
          "name": "ownership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CreatorRating",
                  "kind": "LinkedField",
                  "name": "rating",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "averageScore",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "YoutubeChannel",
              "kind": "LinkedField",
              "name": "channel",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscribers",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "YoutubeCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "tiktokUser",
              "args": null,
              "concreteType": "TiktokUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "type": "TiktokCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "c0b22e61264d9869600246a488a277e1";

export default node;

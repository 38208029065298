import {
  ADD_OFFER_REACTION_DATA,
} from 'Constants';

const initialState = {
  offers: [],
};

const creator = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_OFFER_REACTION_DATA: {
      const { offer } = payload;
      const newOffers = [offer];

      return {
        ...state,
        offers: newOffers,
      };
    }

    default:
      return state;
  }
};

export default creator;

import React, { useEffect, useMemo, useState, MouseEvent, Suspense } from 'react';
import track from 'react-tracking';
import { useParams } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';

import styles from './Creator.css';
import CreatorStatistic from './CreatorStatistic/CreatorStatistic';
import CreatorMedias from './CreatorMedias/CreatorMedias';
import MediaplanItemInfo from './MediaplanItemInfo';
import CreatorQuery, { CreatorQueryType } from './Creator.Query';

import UnlockCreator from 'Molecules/UnlockCreator/UnlockCreator';
import Page from 'Templates/Page/Page';
import { amplitude } from 'Helpers/amplitude';
import { trackTime as custifyTrackTime } from 'Util/custify';
import { useHistoryReferrer } from 'Hooks/useHistoryReferrer';

interface MatchProp {
  id?: string;
  creatorId: string;
  section?: string;
}

interface Props {
  campaignId?: string | null;
  creatorId?: string | null;
}

const Creator: React.FC<Props> = (props) => {
  const { campaignId, creatorId: creatorValue } = props;
  const { id, creatorId, section } = useParams<MatchProp>();
  const { referrer } = useHistoryReferrer();

  const [unlockVisible, setUnlockVisible] = useState(false);

  const currentCampaignId = campaignId || id || '';

  const currentCreatorId = creatorValue || creatorId || '';

  const data = useLazyLoadQuery<CreatorQueryType>(CreatorQuery, {
    creatorId: currentCreatorId,
  });

  const isPlanPaused = Boolean(data.currentUser?.organization?.subscription?.paused);

  if (!currentCreatorId) return null;

  const tags = useMemo<string[]>(() => {
    const profile = data.creator?.profile;
    return (profile?.tags || []).map((tag) => tag.name);
  }, [data]);

  useEffect(() => {
    amplitude.sendEvent({
      id: '57',
      category: 'pageview',
      name: 'creator',
      param: {
        creatorId: currentCreatorId,
        source: referrer,
        creator_tags: tags,
      },
    });
    custifyTrackTime('Creators_profile');
    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  const handleLockClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setUnlockVisible(true);
  };

  const handleUnlockVisibilityChange = () => {
    setUnlockVisible(false);
  };

  return (
    <Page className={styles.root}>
      <div className={styles.stats} id="statistic">
        {currentCampaignId ? (
          <MediaplanItemInfo
            id={currentCampaignId}
            creatorId={currentCreatorId}
            section={section}
            onUnlockBannerShow={handleLockClick}
          />
        ) : (
          <Suspense fallback={null}>
            <CreatorStatistic
              creatorId={currentCreatorId}
              onUnlockBannerShow={handleLockClick}
              // section={section}
            />
          </Suspense>
        )}
      </div>
      <CreatorMedias
        creatorId={currentCreatorId}
        className={styles.medias}
        onUnlockBannerShow={handleLockClick}
      />
      <UnlockCreator
        visible={unlockVisible}
        onVisibilityChange={handleUnlockVisibilityChange}
        paused={isPlanPaused}
      />
    </Page>
  );
};

export default track(
  {
    page: 'creator_stats',
  },
  { dispatchOnMount: true }
)(Creator);

const composeMiddlewares = (middlewares, isDev) => {
  const predicate = item => item;
  const potentionalMiddlewares = [
    ...middlewares,
  ];

  return potentionalMiddlewares.filter(predicate);
};

export default composeMiddlewares;

import React, { useState, useEffect } from 'react';
import { QueryRenderer, useClientQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import Skeleton from '../../components/Skeleton/Skeleton';
import SectionHeader from '../../components/SectionHeader/SectionHeader';

import styles from './Favorites.pcss';
import FavoriteCreators from './FavoriteCreators/FavoriteCreators';
import {
  FavoritesQuery,
  FavoritesCreatorsCountQuery,
  FavoritesQueryType,
  FavoritesCreatorsCountQueryType,
} from './Favorites.Query';

import environment from 'Api/Environment';
import Input from 'Components/ui/Input/Input';
import { CREATORS_LIST_ROUTE } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';

const defaultFilters: Filters = {
  textQuery: '',
};

type Props = {
  renderStartHeaderControls?: () => React.ReactNode;
};

const Favorites: React.FC<Props> = (props) => {
  const { renderStartHeaderControls } = props;
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const navigate = useNavigate();
  const textQuery = filters.textQuery;

  const favoriteCreatorsQuery$data = useClientQuery<FavoritesCreatorsCountQueryType>(
    FavoritesCreatorsCountQuery,
    {}
  );
  const favoritesQueryResponse = useClientQuery<FavoritesQueryType>(FavoritesQuery, {});

  const organizationId = favoritesQueryResponse.currentUser?.organization?.id || '';
  const favoriteCreatorsTotalCount =
    favoriteCreatorsQuery$data.currentUser?.organization?.favoriteCreators?.totalCount || 0;

  const isAdmin = favoritesQueryResponse.currentUser?.admin;
  const isChurned = favoritesQueryResponse.currentUser?.organization?.churned;
  const isNewClient = favoritesQueryResponse.currentUser?.organization?.newClient;
  const isPlanPaused = Boolean(
    favoritesQueryResponse.currentUser?.organization?.subscription?.paused
  );

  const isBlockedView = !isAdmin && (isChurned || isNewClient || isPlanPaused);

  useEffect(() => {
    if (organizationId) {
      amplitude.sendEvent<263>({
        id: '263',
        category: 'pageview',
        name: 'favorites_visit',
        param: undefined,
      });
    }
  }, [organizationId]);

  const handleTextQueryChange = (text: string) => {
    setFilters({ ...filters, textQuery: text });
  };

  const handleParamsClear = () => {
    setFilters({ ...defaultFilters });
  };

  const handleTextQueryFocus = () => {
    //
  };

  const handleBackButtonClick = () => {
    navigate(CREATORS_LIST_ROUTE);
  };

  return (
    <div className={styles.root}>
      <div className={styles.headerControls}>
        <SectionHeader
          creatorsCount={favoriteCreatorsTotalCount}
          headerTextProps={{ msg: 'creators.dashboard.favorites.title' }}
          onBackButtonClick={handleBackButtonClick}
        />
        {renderStartHeaderControls && (
          <div className={styles.startHeaderControls}>{renderStartHeaderControls?.()}</div>
        )}
        <div className={styles.endHeaderControls}>
          <Input
            value={textQuery}
            placeholderMsg="campaign.creators_search"
            className={styles.textQuery}
            rightIcon="Search-loop"
            onFocus={handleTextQueryFocus}
            handleChange={handleTextQueryChange}
          />
        </div>
      </div>
      <QueryRenderer<FavoritesQueryType>
        environment={environment}
        query={FavoritesQuery}
        variables={{ textQuery }}
        render={({ props: queryProps }) => {
          if (!queryProps?.currentUser?.organization) return <Skeleton />;

          const organization = queryProps.currentUser.organization;

          return (
            <FavoriteCreators
              organization={organization}
              textQuery={textQuery}
              onParamsClear={handleParamsClear}
              isBlockedView={isBlockedView}
              isPlanPaused={isPlanPaused}
            />
          );
        }}
      />
    </div>
  );
};

export default Favorites;

// types

interface Filters {
  textQuery: string;
}

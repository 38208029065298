import React from 'react';

import Tabs from 'Molecules/Tabs/Tabs';

const tabItems: TabItems = [
  // {
  //   textData: { msg: 'creators.dashboard.favorites.title' },
  //   icon: { name: 'Heart' },
  //   id: 'FAVORITES',
  // },
  {
    textData: { msg: 'creators.dashboard.lists.title' },
    icon: { name: 'Save-list' },
    id: 'LISTS',
  },
  {
    textData: { msg: 'creators.dashboard.previous_collaborations.title' },
    id: 'PREVIOUS_COLLABORATION',
  },
  {
    textData: { msg: 'creators.dashboard.blocklist.title' },
    id: 'BLOCKLIST',
  },
];

export { tabItems };

// types

type TabsProps = React.ComponentProps<typeof Tabs>;
type TabItems = NonNullable<TabsProps['items']>;

import React from 'react';
import { useIntl } from 'react-intl';
import SelectItem, {
  components,
  SingleValueProps,
  OptionProps,
  MultiValueProps,
} from 'react-select';

import selectStyles from './styles';
import styles from './Select.css';

import TextSymbol from 'Atoms/TextSymbol/TextSymbol';
import Icon from 'Atoms/Icon/Icon';
import DropDownMenuItem from 'Atoms/DropDownMenuItem/DropDownMenuItem';
import { SelectType, SingleValueType, MultiValueType, OptionType } from 'Types/common';

// TODO: TOOLTIPS

const SingleValue: React.FC<SingleValueProps<SingleValueType['data']>> = (props) => {
  const {
    data: {
      text,
      msg,
      msgValues,
      leftIcon,
      rightIcon,
      leftIconName,
      rightIconName,
      avaData,
      additionalComponent,
      optionStyle,
    },
    isDisabled,
  } = props;

  return (
    <TextSymbol
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      leftIconName={leftIconName}
      rightIconName={rightIconName}
      textData={{
        text,
        msg,
        values: msgValues,
        align: 'left',
        color: isDisabled ? 'grayDog' : undefined,
      }}
      avaData={avaData}
      additionalComponent={additionalComponent}
      className={`${styles.singleValue} ${optionStyle}`}
      dataTest="dropdown:singleValue"
    />
  );
};

const MultiValueLabel: React.FC<MultiValueProps<MultiValueType['data']>> = (props) => {
  const { data } = props;
  const { marked } = data;

  return (
    <div className={styles.multiValue} data-test="dropdown:multiValue">
      {marked && <Icon size="sm" name="starFull" color="black" className={styles.marked} />}
      <components.MultiValueLabel {...props}></components.MultiValueLabel>
    </div>
  );
};

const Option: React.FC<OptionProps<OptionType, boolean>> = (props) => {
  const { innerProps, data, isSelected, isDisabled } = props;

  const { element, elementStyle, actionElement, value, tooltipData, disableNativeIcon } = data;

  const intl = useIntl();

  // @TODO Wrong spread. But i didn't change because it working as is.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const item = element ? element : <SingleValue {...props} />;
  const icon = isSelected && !disableNativeIcon ? <Icon color="base" name="check" /> : undefined;
  const title = data.title ? intl.formatMessage({ id: data.title }) : null;

  const additionalProps = value ? {} : { onClick: undefined };

  return (
    <div {...innerProps} {...additionalProps}>
      <DropDownMenuItem
        className={elementStyle}
        icon={icon}
        isDisabled={isDisabled}
        tooltipData={tooltipData}
        actionElement={actionElement}
      >
        {title ? <div title={title}>{item}</div> : item}
      </DropDownMenuItem>
    </div>
  );
};

const Select: React.FC<SelectType> = (props) => {
  const {
    name,
    value,
    options = [],
    hideSelectedOptions = true,
    maxHeight,
    error,
    disabled,
    placeholderMsg,
    placeholder = '',
    isMulti,
    onChange,
    filterOption,
  } = props;

  const intl = useIntl();

  const handleChange = (data: any) => {
    const value = isMulti ? data : data.value;

    if (onChange && name) {
      onChange({ [name]: value });
    }
  };

  const computedStyles = selectStyles({ error, maxHeight });

  let valueItem;

  if (isMulti) {
    valueItem =
      Array.isArray(value) &&
      value.map((valueItem) => {
        // @TODO Why there is valueItem.value if value is not an object?
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return valueItem && options ? options.find((item) => item.value === valueItem.value) : [];
      });
  } else {
    valueItem = value && options ? options.find((item) => item.value === value) : '';
  }

  const placeholderText = placeholderMsg ? intl.formatMessage({ id: placeholderMsg }) : placeholder;

  return (
    <SelectItem
      components={{ SingleValue, Option, MultiValueLabel }}
      name={name}
      classNamePrefix={name}
      value={valueItem}
      options={options}
      isMulti={isMulti}
      hideSelectedOptions={hideSelectedOptions}
      isDisabled={disabled}
      filterOption={typeof filterOption === undefined ? null : filterOption}
      placeholder={placeholderText}
      onChange={handleChange}
      styles={computedStyles}
      closeMenuOnSelect={isMulti ? false : undefined}
    />
  );
};

export default Select;

import { commitMutation, graphql } from 'react-relay';

import {
  ConnectCampaignToShopifyMutation,
  ConnectCampaignToShopifyMutation$data,
  ConnectCampaignToShopifyInput,
  ConnectCampaignToShopifyMutation$variables,
} from 'GraphTypes/ConnectCampaignToShopifyMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation ConnectCampaignToShopifyMutation($input: ConnectCampaignToShopifyInput!) {
    connectCampaignToShopify(input: $input) {
      __typename
      ... on ConnectCampaignToShopifyPayload {
        campaign {
          id
          canUpdateShopifyAuthorization
          shopifyAuthorization {
            reauthenticationRequired
            shop {
              name
              url
            }
            id
          }
          organization {
            id
            shopifyAuthorizations {
              edges {
                node {
                  id
                  reauthenticationRequired
                  shop {
                    name
                  }
                }
              }
            }
          }
        }
      }
      ... on NotFoundError {
        message
        subtype
        type
      }
      ... on ValidationError {
        message
        type
      }
    }
  }
`;

export default (
  data: ConnectCampaignToShopifyInput,
  resolve?: (response: ConnectCampaignToShopifyMutation$data) => void,
  reject?: (data: object) => void
) => {
  const variables: ConnectCampaignToShopifyMutation$variables = {
    input: {
      ...data,
    },
  };

  commitMutation<ConnectCampaignToShopifyMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

export type {
  ConnectCampaignToShopifyMutation,
  ConnectCampaignToShopifyMutation$data,
  ConnectCampaignToShopifyInput,
  ConnectCampaignToShopifyMutation$variables,
};

/**
 * @generated SignedSource<<37141205f10475a8386de357367144d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListsQuery$variables = Record<PropertyKey, never>;
export type ListsQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"CustomListFavoritesDetailsFragment" | "CustomLists_organization">;
    } | null | undefined;
  } | null | undefined;
};
export type ListsQuery = {
  response: ListsQuery$data;
  variables: ListsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 18
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ListsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomLists_organization"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomListFavoritesDetailsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ListsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CustomListConnection",
                "kind": "LinkedField",
                "name": "customLists",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomListEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomList",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorConnection",
                            "kind": "LinkedField",
                            "name": "creators",
                            "plural": false,
                            "selections": (v2/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "customLists(first:18)"
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Organization_customLists",
                "kind": "LinkedHandle",
                "name": "customLists"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorConnection",
                "kind": "LinkedField",
                "name": "favoriteCreators",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38e39eda042d911421987f57c0a062b3",
    "id": null,
    "metadata": {},
    "name": "ListsQuery",
    "operationKind": "query",
    "text": "query ListsQuery {\n  currentUser {\n    organization {\n      id\n      ...CustomLists_organization\n      ...CustomListFavoritesDetailsFragment\n    }\n    id\n  }\n}\n\nfragment CustomListDetails_item on CustomList {\n  ...CustomListEditDrawer_customList\n}\n\nfragment CustomListEditDrawer_customList on CustomList {\n  id\n  name\n  creators {\n    totalCount\n  }\n}\n\nfragment CustomListFavoritesDetailsFragment on Organization {\n  favoriteCreators {\n    totalCount\n  }\n}\n\nfragment CustomLists_organization on Organization {\n  id\n  customLists(first: 18) {\n    edges {\n      node {\n        ...CustomListDetails_item\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n  favoriteCreators {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe45ec4610d8f8d7c8da11ffb6cf3daf";

export default node;

/**
 * @generated SignedSource<<04528dbd6dc0e106e50fff82850f137b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteOutreachCreatorsQuery$variables = {
  campaignId: string;
};
export type InviteOutreachCreatorsQuery$data = {
  readonly campaign: {
    readonly " $fragmentSpreads": FragmentRefs<"GmailSettingsForOutreach_campaign">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateSelectorForOutreach_list">;
};
export type InviteOutreachCreatorsQuery = {
  response: InviteOutreachCreatorsQuery$data;
  variables: InviteOutreachCreatorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteOutreachCreatorsQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "campaignId",
            "variableName": "campaignId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "TemplateSelectorForOutreach_list"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GmailSettingsForOutreach_campaign"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteOutreachCreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OutreachTemplateConnection",
        "kind": "LinkedField",
        "name": "outreachTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locale",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "htmlBody",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateVariable",
            "kind": "LinkedField",
            "name": "sampleOutreachTemplateVariables",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GmailAuthorizationConnection",
                "kind": "LinkedField",
                "name": "gmailAuthorizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GmailAuthorizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GmailAuthorization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reauthenticationRequired",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableEmails",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableNames",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "237202bd623a053612618bab4b5bd6a3",
    "id": null,
    "metadata": {},
    "name": "InviteOutreachCreatorsQuery",
    "operationKind": "query",
    "text": "query InviteOutreachCreatorsQuery(\n  $campaignId: ID!\n) {\n  ...TemplateSelectorForOutreach_list_106Vhw\n  campaign(id: $campaignId) {\n    ...GmailSettingsForOutreach_campaign\n    id\n  }\n}\n\nfragment GmailSettingsForOutreach_campaign on Campaign {\n  organization {\n    gmailAuthorizations {\n      edges {\n        node {\n          id\n          reauthenticationRequired\n          availableEmails\n          availableNames\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment TemplateSelectorForOutreach_list_106Vhw on Query {\n  outreachTemplates {\n    edges {\n      node {\n        id\n        name\n        locale\n        htmlBody\n      }\n    }\n  }\n  campaign(id: $campaignId) {\n    sampleOutreachTemplateVariables {\n      name\n      value\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e1e0cd0d52d41b5aec315cdaa6eb64c";

export default node;

import { StatItem, createDateLabel } from '../Chart/utils';

import type { PerformanceCollaborationsChartQueryType } from './PerformanceCollaborationsChart.Query';

import type { Performance_Granularity } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';

type CharDataType = {
  stats: StatItem[];
  lines: {
    completedProjectsCount: number;
    creativeAssetsCount: number;
    creatorsCount: number;
    publicationsCount: number;
    date: string;
  }[];
};
export const chartData = (
  response: PerformanceCollaborationsChartQueryType['response'],
  granularity: Performance_Granularity
): CharDataType => {
  const stats = [
    {
      amount: response.performance?.collaborationCounters?.totalCompletedProjectsCount || 0,
      key: 'completedProjectsCount',
      description: {
        titleMsg: 'performance.dashboard.chart.collaborations.completedProjectsCount.title',
      },
    },
    {
      amount: response.performance?.collaborationCounters?.totalCreatorsCount || 0,
      key: 'creatorsCount',
      description: {
        titleMsg: 'performance.dashboard.chart.collaborations.creatorsCount.title',
        tooltipMsg: 'performance.dashboard.chart.collaborations.creatorsCount.tooltip',
      },
    },
    {
      amount: response.performance?.collaborationCounters?.totalCreativeAssetsCount || 0,
      key: 'creativeAssetsCount',
      description: {
        titleMsg: 'performance.dashboard.chart.collaborations.creativeAssetsCount.title',
        tooltipMsg: 'performance.dashboard.chart.collaborations.creativeAssetsCount.tooltip',
      },
    },
    {
      amount: response.performance?.collaborationCounters?.totalPublicationsCount || 0,
      key: 'publicationsCount',
      description: {
        titleMsg: 'performance.dashboard.chart.collaborations.publicationsCount.title',
        tooltipMsg: 'performance.dashboard.chart.collaborations.publicationsCount.tooltip',
      },
    },
  ];

  const edges = response?.performance?.collaborationCounters?.edges || [];
  const lines: {
    completedProjectsCount: number;
    creativeAssetsCount: number;
    creatorsCount: number;
    publicationsCount: number;
    date: string;
  }[] = [];

  edges.forEach((item) => {
    if (!item?.node) return;
    lines.push({
      completedProjectsCount: item.node.completedProjectsCount,
      creatorsCount: item.node.creatorsCount,
      creativeAssetsCount: item.node.creativeAssetsCount,
      publicationsCount: item.node.publicationsCount,
      date:
        createDateLabel(item.node.dateFrom as string, item.node.dateTo as string, granularity) ||
        '',
    });
  });

  return { stats, lines };
};

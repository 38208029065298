import React, { useState } from 'react';

import styles from './ResumeButton.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import ResumeSubscriptionDrawer from '../ResumeSubscriptionDrawer/ResumeSubscriptionDrawer';

interface Props {
  subscriptionId: string;
}

const ResumeButton: React.FC<Props> = (props) => {
  const { subscriptionId } = props;
  const [openedPauseDrawer, setPauseDrawerStatus] = useState(false);

  const handleResumeDrawerOpen = () => {
    setPauseDrawerStatus(true);
    amplitude.sendEvent<402>({
      id: '402',
      category: 'billing',
      name: 'pause_subsciption_resume_click',
    });
  };

  const handleResumeDrawerClose = () => {
    setPauseDrawerStatus(false);
  };

  return (
    <>
      <AlterButton
        textByCenter
        type={'pink'}
        className={styles.resumeBtn}
        msg="billing.current_plan.resume_plan"
        onClick={handleResumeDrawerOpen}
      />
      <ResumeSubscriptionDrawer
        opened={openedPauseDrawer}
        onClose={handleResumeDrawerClose}
        subscriptionId={subscriptionId}
      />
    </>
  );
};

export default ResumeButton;

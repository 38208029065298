import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './CreatorReviewItem.css';

import { getProjectsLink, getOrganizationLink } from 'Util/links';
import RatingInfo from 'Molecules/RatingInfo/RatingInfo';
import AvatarItem from 'Molecules/AvatarItem/AvatarItem';
import { CreatorReviewItem_ratingVote$key } from 'GraphTypes/CreatorReviewItem_ratingVote.graphql';

interface Props {
  admin?: boolean;
  ratingVote: CreatorReviewItem_ratingVote$key;
}

const CreatorReviewItem: React.FC<Props> = (props) => {
  const { ratingVote, admin } = props;

  const data = useFragment(
    graphql`
      fragment CreatorReviewItem_ratingVote on RatingVote {
        id
        createdAt
        score
        comment
        characteristics
        project {
          id
          brand {
            logo {
              ... on Image {
                transformations {
                  brandLogoUrl
                }
              }
            }
            name
          }
          campaign {
            id
            name
            organization {
              id
              name
            }
            stage
          }
        }
      }
    `,
    ratingVote
  );

  const { createdAt, score, comment, characteristics, project } = data;

  const createProjectInfo = () => {
    const organizationId = project.campaign.organization?.id;
    const organizationName = project.campaign.organization?.name;

    const campaignName = project.campaign.name;
    const campaignId = project.campaign.id;
    const campaignStage = project.campaign.stage;

    const projectId = project.id;

    const brandName = project.brand?.name;
    const avaSrc = project.brand?.logo?.transformations?.brandLogoUrl;
    const projectLink = getProjectsLink({
      projectId,
      campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
      organizationId,
    });
    const organizationLink = organizationId ? getOrganizationLink({ organizationId }) : undefined;

    return (
      <div className={styles.project}>
        <AvatarItem
          avaData={{ src: avaSrc }}
          titleData={{ text: campaignName }}
          titleLink={projectLink}
          subtitleData={{ text: organizationName }}
          subtitleLink={organizationLink}
          tooltipText={brandName}
        />
      </div>
    );
  };

  const createAnonymo = () => {
    return (
      <AvatarItem
        avaData={{ src: null }}
        titleData={{ msg: 'general.anonymo', color: 'grayDog' }}
      />
    );
  };

  return (
    <div className={styles.root}>
      {admin ? createProjectInfo() : createAnonymo()}
      <RatingInfo
        score={score}
        comment={comment}
        characteristics={characteristics}
        createdAt={createdAt}
        needComment={true}
        defaultStarsColor="yellow"
      />
    </div>
  );
};

export default CreatorReviewItem;

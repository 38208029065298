import React, { useContext } from 'react';
import { MobileView, BrowserView, isTablet } from 'react-device-detect';

import Avatar from 'Atoms/Avatar/Avatar';
import DropDown from 'Molecules/DropDown/DropDown';
import { DropdownType } from 'Types/common';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';

interface Props {
  label: string;
  menuContent?: JSX.Element;
  menuList: DropdownType['list'];
}

const User: React.FC<Props> = (props) => {
  const { label, menuList, menuContent } = props;
  const { user } = useContext(Auth0Context);

  const ava = (
    <Avatar
      size="40"
      letters={label}
      // src={user?.picture}
      dataTest="avatar:header"
    />
  );

  return (
    <div>
      <MobileView>{ava}</MobileView>
      {!isTablet && (
        <BrowserView>
          <DropDown
            anchor={ava}
            list={menuList}
            content={menuContent}
            selfClose={true}
            autoHeight={true}
          />
        </BrowserView>
      )}
    </div>
  );
};

export default User;

/**
 * @generated SignedSource<<684f6d543c512ca264f51a44b8ac3d8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BriefCreativeContentFormat = "HORIZONTAL_16_9" | "SQUARE" | "STATIC" | "VERTICAL_4_5" | "VERTICAL_9_16" | "%future added value";
export type BriefCreativeContentPreparedness = "FINAL" | "RAW" | "RAW_AND_FINAL" | "%future added value";
export type BriefCreativeContentType = "CUSTOM" | "HOWTO" | "PRODUCT_DEMO" | "PRODUCT_REVIEW" | "TESTIMONIAL" | "UNBOXING" | "%future added value";
export type BriefCreativeMediaType = "PHOTO" | "VIDEO" | "%future added value";
export type ContentType = "BOOMERANG" | "HORIZONTAL_VIDEO" | "IMAGE" | "SQUARE_VIDEO" | "VERTICAL_VIDEO" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
export type V2ContentPlacement = "FEED" | "MIDDLEROLL" | "NONE" | "POSTROLL" | "PREROLL" | "REEL" | "STORY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssetPreview_briefCreative$data = {
  readonly contentFormat: BriefCreativeContentFormat | null | undefined;
  readonly contentPreparedness: BriefCreativeContentPreparedness | null | undefined;
  readonly contentType: ContentType | null | undefined;
  readonly id: string;
  readonly lastReferences: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly file: {
          readonly transformations?: {
            readonly collageThumbnailUrl: string;
          } | null | undefined;
          readonly type: FileType;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly mediaType: BriefCreativeMediaType | null | undefined;
  readonly v2ContentPlacement: V2ContentPlacement | null | undefined;
  readonly v2ContentType: BriefCreativeContentType | null | undefined;
  readonly videoDurationMax: number | null | undefined;
  readonly videoDurationMin: number | null | undefined;
  readonly " $fragmentType": "AssetPreview_briefCreative";
};
export type AssetPreview_briefCreative$key = {
  readonly " $data"?: AssetPreview_briefCreative$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetPreview_briefCreative">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "collageThumbnailUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "lastReferences"
        ]
      }
    ]
  },
  "name": "AssetPreview_briefCreative",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v2ContentPlacement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoDurationMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoDurationMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentPreparedness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v2ContentType",
      "storageKey": null
    },
    {
      "alias": "lastReferences",
      "args": null,
      "concreteType": "ReferenceConnection",
      "kind": "LinkedField",
      "name": "__AssetPreview_lastReferences_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReferenceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Reference",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "file",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VideoTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "Video",
                      "abstractKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BriefCreative",
  "abstractKey": null
};
})();

(node as any).hash = "bc8961f4f1aa564c7b6ae21c55ca2325";

export default node;

import React from 'react';

import styles from './MessageImage.pcss';

import { getMediaSize } from 'Util/mediaSize';

const MAX_SIDE_SIZE = 300;

import { MessageMedia_file$data } from 'GraphTypes/MessageMedia_file.graphql';

interface Props {
  hasActions?: boolean;
  file: MessageMedia_file$data;
}

const MessageImage: React.FC<Props> = (props) => {
  const { file, hasActions } = props;
  const src = file.thumbnailUrl;

  const imgStyle = getMediaSize(file, MAX_SIDE_SIZE, MAX_SIDE_SIZE);

  const handleContextMenu = hasActions
    ? (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault();
      }
    : undefined;

  return (
    <div className={styles.root}>
      <img
        src={src}
        style={imgStyle.style}
        className={styles.image}
        onContextMenu={handleContextMenu}
      />
    </div>
  );
};

export default MessageImage;

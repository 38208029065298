import React, { useContext } from 'react';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import TextButton from 'Components/ui/TextButton/TextButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import TransactionComment from 'Modal/admin/TransactionComment/TransactionComment';

interface Props {
  id: string;
  comment?: string | null;
}

const Comment: React.FC<Props> = (props) => {
  const { id, comment } = props;
  const { openDrawer } = useContext(DrawerContext);

  const handleCommentClick = () => {
    openDrawer(`transaction-comment-${id}`);
  };

  const commentText = comment?.replace(/\n/g, '<br>');

  return (
    <>
      <Tooltip
        id={comment ? `transaction_comment_${id}` : undefined}
        place="bottom"
        tooltipText={commentText}
      >
        <TextButton icon={comment ? 'Notes-filled' : 'Notes'} onClick={handleCommentClick} />
      </Tooltip>
      <TransactionComment id={id} comment={comment} />
    </>
  );
};

export default Comment;

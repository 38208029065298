import React, { useEffect, useState } from 'react';
import { graphql, fetchQuery } from 'react-relay';

import styles from './BatchStatus.pcss';

import environment from 'Api/Environment';
import Text from 'Components/ui/Text/Text';
import { BatchStatusQuery as QueryType } from 'GraphTypes/BatchStatusQuery.graphql';

const BatchStatusQuery = graphql`
  query BatchStatusQuery($id: ID!) {
    outreachBatch(id: $id) {
      creatorImport {
        completed
        totalCreators
        importedCreators
      }
    }
  }
`;

interface Props {
  id: string;
}

const BatchStatus: React.FC<Props> = (props) => {
  const { id } = props;

  let interval: NodeJS.Timer;

  const [importedCreators, setImportedCreators] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [completed, setCompleted] = useState(0);

  const getCompletedState = () => {
    fetchQuery<QueryType>(environment, BatchStatusQuery, { id }).subscribe({
      next: (result) => {
        const completed = result.outreachBatch?.creatorImport?.completed;
        setImportedCreators(result.outreachBatch?.creatorImport?.importedCreators || 0);
        setTotalCount(Number(result.outreachBatch?.creatorImport?.totalCreators));
        setCompleted(Number(result.outreachBatch?.creatorImport?.completed));

        if (completed) {
          clearInterval(interval);
        }
      },
    });
  };

  useEffect(() => {
    interval = setInterval(() => {
      getCompletedState();
    }, 3000);

    getCompletedState();

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <Text
      type="label"
      msg={
        completed
          ? 'campaign.outreach.imported_creators'
          : 'campaign.outreach.imported_creators_in_progress'
      }
      formatValues={{ count: importedCreators, totalCount }}
      className={styles.count}
    />
  );
};

export default BatchStatus;

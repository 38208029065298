/**
 * @generated SignedSource<<df361d972b4aa08652cb546092438683>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletedStage_project$data = {
  readonly campaign: {
    readonly completion: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly organization: {
      readonly paymentAccount: {
        readonly balance: number;
      } | null | undefined;
    } | null | undefined;
  };
  readonly creator: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FbAdsAction_project">;
  readonly " $fragmentType": "CompletedStage_project";
};
export type CompletedStage_project$key = {
  readonly " $data"?: CompletedStage_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedStage_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompletedStage_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignCompletion",
          "kind": "LinkedField",
          "name": "completion",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Payment_Account",
              "kind": "LinkedField",
              "name": "paymentAccount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "balance",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FbAdsAction_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "9eb7dff1abe76f7c650be81aeb7669eb";

export default node;

import { IconName } from 'Components/ui/types';

type Data = {
  iconName: IconName;
  id: string | boolean;
  titleMsg?: string;
  descrMsg?: string;
}[];

export const formatTypesList: Data = [
  {
    id: 'VERTICAL_9_16',
    iconName: 'Format-9-16',
  },
  {
    id: 'VERTICAL_4_5',
    iconName: 'Format-4-5',
  },
  {
    id: 'SQUARE',
    iconName: 'Format-1-1',
  },
  {
    id: 'HORIZONTAL_16_9',
    iconName: 'Format-16-9',
  },
];

export const publishingTypeList: Data = [
  {
    id: true,
    iconName: 'Posting',
  },
  {
    id: false,
    iconName: 'Posting-disabled',
  },
];

export const musicTypeList: Data = [
  {
    id: true,
    iconName: 'Audio-sound',
  },
  {
    id: false,
    iconName: 'Audio-sound-disabled',
  },
];

export const preparednessTypeList: Data = [
  {
    id: 'RAW',
    iconName: 'Effects',
    titleMsg: 'brief.creative.preparedness.raw',
    descrMsg: 'brief.creative.preparedness.raw.descr',
  },
  {
    id: 'RAW_AND_FINAL',
    iconName: 'Effects',
    titleMsg: 'brief.creative.preparedness.raw_and_ready',
    descrMsg: 'brief.creative.preparedness.raw_and_ready.descr',
  },
  {
    id: 'FINAL',
    iconName: 'Effects-disabled',
    titleMsg: 'brief.creative.preparedness.final',
    descrMsg: 'brief.creative.preparedness.final.descr',
  },
];

import React, { useContext } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './InviteOutreachCreators.pcss';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { InviteOutreachCreatorsQuery as QueryType } from 'GraphTypes/InviteOutreachCreatorsQuery.graphql';

import Button from 'Components/ui/Button/Button';
import { CampaignPlatform } from 'GraphTypes/CampaignQuery.graphql';
import CreatorsCsvUploader from './CreatorsCsvUploader/CreatorsCsvUploader';
import { InviteOutreachCreatorsContext } from './InviteOutreachCreatorsContext';
import TemplateSelector from './TemplateSelector/TemplateSelector';
import ProviderSelector from './ProviderSelector/ProviderSelector';
import GmailSettings from './GmailSettings/GmailSettings';
import RowErrors from './RowErrors/RowErrors';

const InviteOutreachCreatorsQuery = graphql`
  query InviteOutreachCreatorsQuery($campaignId: ID!) {
    ...TemplateSelectorForOutreach_list @arguments(campaignId: $campaignId)
    campaign(id: $campaignId) {
      ...GmailSettingsForOutreach_campaign
    }
  }
`;

interface Props {
  campaignId: string;
  platform?: CampaignPlatform | null;
}

const InviteOutreachCreators: React.FC<Props> = (props) => {
  const { campaignId, platform } = props;
  const {
    fileError,
    isFormValid,
    rows,
    error,
    handleFileReset,
    showRowErrors,
    locale,
    transport,
    processing,
    templateData,
    templatePreview,
    handleTemplateClose,
    handleImportOutreachBatch,
    importOutreachBatchLoading,
  } = useContext(InviteOutreachCreatorsContext);

  const queryData = useLazyLoadQuery<QueryType>(InviteOutreachCreatorsQuery, { campaignId });

  const handleSendClick = () => {
    amplitude.sendEvent<412>({
      id: '412',
      category: 'campaign',
      name: 'outreach_send_click',
      param: { template_name: templateData.name },
    });
    handleImportOutreachBatch(campaignId, platform);
  };

  /*const locales = [
    { id: 'EN', msg: 'invite_outreach_creators_modal.locale.en' },
    { id: 'ES', msg: 'invite_outreach_creators_modal.locale.es' },
  ];

  const dropdownLabel = locale
    ? { msg: `invite_outreach_creators_modal.locale.${locale.toLowerCase()}` }
    : { msg: 'invite_outreach_creators_modal.locale.placeholder' };*/

  if (!queryData.campaign) return null;
  return (
    <Drawer rootKey="invite-outreach-creators" className={styles.drawer} onClose={handleFileReset}>
      {showRowErrors ? (
        <RowErrors />
      ) : (
        <>
          <div className={styles.drawerContainer}>
            <Text type="d2" msg="invite_outreach_creators_modal.title" className={styles.title} />
            <div className={styles.content}>
              <CreatorsCsvUploader />
              {/*  ____________________________________________ */}
              {/*  THIS dropdown is for future multiple locales */}
              {/*  ____________________________________________ */}

              {/* {rows.length > 0 && (
              <>
                <Text
                  type="md"
                  msg="invite_outreach_creators_modal.step_2"
                  className={styles.subtitle}
                />
                <Dropdown value={<Text type="md" {...dropdownLabel} />} className={styles.dropdown}>
                  <DropdownGroup className={styles.dropdownMenu}>
                    {locales.map((item) => {
                      const isActive = item.id === locale;

                      const handleLocaleChange = () => {
                        if (isActive) return;
                        setTemplateId('');
                        setLocale(item.id);
                      };

                      return (
                        <DropdownItem key={item.msg}>
                          <AlterButton
                            msg={item.msg}
                            onClick={handleLocaleChange}
                            fluid
                            iconPosition="right"
                            icon={isActive ? 'Check' : null}
                          />
                        </DropdownItem>
                      );
                    })}
                  </DropdownGroup>
                </Dropdown>
              </>
            )} */}
              {rows?.length > 0 && locale && !fileError && (
                <TemplateSelector campaignId={campaignId} query={queryData} />
              )}

              {templateData.id && <ProviderSelector />}
              {Boolean(templateData.id && transport === 'GMAIL') && (
                <GmailSettings query={queryData.campaign} />
              )}
              {error && (
                <Text type="md" msg="general.something_went_wrong" className={styles.commonError} />
              )}
            </div>
            <Button
              color="black"
              loading={importOutreachBatchLoading || processing}
              disabled={!isFormValid}
              msg="invite_outreach_creators_modal.send"
              onClick={handleSendClick}
            />
          </div>
          {templatePreview && (
            <div className={styles.templateDrawer}>
              <div
                dangerouslySetInnerHTML={{ __html: templatePreview }}
                className={styles.templateContent}
              />
              <AlterButton
                icon="Arrow-small-left"
                className={styles.resetTemplate}
                onClick={handleTemplateClose}
              />
            </div>
          )}
        </>
      )}
    </Drawer>
  );
};

export default InviteOutreachCreators;

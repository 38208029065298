import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateManualCreatorExpenseMutation,
  CreateManualCreatorExpenseInput,
  CreateManualCreatorExpenseMutation$data,
} from 'GraphTypes/CreateManualCreatorExpenseMutation.graphql';

const mutation = graphql`
  mutation CreateManualCreatorExpenseMutation($input: CreateManualCreatorExpenseInput!) {
    createManualCreatorExpense(input: $input) {
      organization {
        paymentAccount {
          balance
          currency
        }
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: CreateManualCreatorExpenseInput,
  resolve?: (response: CreateManualCreatorExpenseMutation$data) => void,
  reject?: FnType
) => {
  const id = uuid();

  const { amount, campaignId, comment, currency, departmentId, organizationId } = data;

  const variables = {
    input: {
      id,
      amount,
      campaignId,
      comment,
      currency,
      departmentId,
      organizationId,
    },
  };

  commitMutation<CreateManualCreatorExpenseMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import type { UseMutationConfig } from 'react-relay';

import styles from './ShopifyOrderCreationDrawer.pcss';
import { SelectProduct } from './SelectProduct/SelectProduct';
import { ConfirmOrder, ConfirmOrderProps } from './ConfirmOrder/ConfirmOrder';
import { OrderCreated } from './OrderCreated/OrderCreated';

import { CreateBulkShopifyOrderMutation } from 'Mutations/CreateBulkShopifyOrder.Mutation';
import Drawer, { DrawerProps } from 'Components/ui/Drawer/Drawer';
import { ProductListProps } from 'Components/ProductList';
import { amplitude } from 'Helpers/amplitude';
import {
  convertProductsToShopifyLineItemInput,
  filterProductsBySelectedVariants,
} from 'Components/ProductList/utils';
import { ProductList_query$key } from 'GraphTypes/ProductList_query.graphql';

interface Props {
  projectIDs: string[];
  campaignId: string;
  organizationId: string;
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
  contentVariant?: ContentVariant;
  onOrderSuccessfullyCreated?: OnOrderSuccessfullyCreated;
  onOrderCreationError?: OnOrderCreationError;
  onOrderCreationAnotherError?: OnOrderCreationAnotherError;
  onOrderLostConnectionError?: OnOrderLostConnectionError;
}

const ShopifyOrderCreationDrawer: React.FC<Props> = (props) => {
  const {
    projectIDs,
    campaignId,
    drawerProps,
    onOrderSuccessfullyCreated,
    onOrderCreationError,
    onOrderCreationAnotherError,
    onOrderLostConnectionError,
    organizationId,
  } = props;

  const [contentVariant, setContentVariant] = useState<ContentVariant>(
    props.contentVariant || 'select-product'
  );

  const [selectedProducts, setSelectedProducts] = useState<
    NonNullable<ProductListProps['products']>
  >([]);

  const [currencyCode, setCurrencyCode] = useState<string>('USD');

  const creatorsCount = projectIDs.length;

  useEffect(() => {
    if (props?.contentVariant) {
      setContentVariant(props.contentVariant);
    }
  }, [props?.contentVariant]);

  const handleOpenDrawer = useCallback(() => {
    drawerProps?.onOpen?.();
  }, [drawerProps?.onOpen]);

  const handleCloseDrawer = useCallback(() => {
    setContentVariant('select-product');
    setSelectedProducts([]);
    drawerProps?.onClose?.();
  }, [drawerProps?.onClose]);

  const handleBackToSelectProduct = useCallback(() => {
    setContentVariant('select-product');
  }, []);

  const handleProductVariantsSelectionChange = useCallback<HandleProductVariantSelectionChange>(
    (args) => {
      setSelectedProducts((prev) => {
        const prevFilteredProductIds = prev.map((product) => product.id);
        const updatedProducts = args.products;
        const updatedProductIds = updatedProducts.map((product) => product.id);
        const prevUniqProductsIds = prevFilteredProductIds.filter(
          (id) => !updatedProductIds.includes(id)
        );
        const uniqPrevProducts = prev.filter((product) => prevUniqProductsIds.includes(product.id));
        return filterProductsBySelectedVariants([...uniqPrevProducts, ...updatedProducts]);
      });
    },
    []
  );

  const handleOrderSuccessfullyCreated = useCallback<OnOrderSuccessfullyCreated>(
    (response, errors) => {
      onOrderSuccessfullyCreated?.(response, errors);
    },
    [onOrderSuccessfullyCreated]
  );

  const sendOrderCreationErrorEvent = async () => {
    const lineItems = convertProductsToShopifyLineItemInput(selectedProducts).filter(
      (item) => item.quantity > 0
    );
    amplitude.sendEvent<331>({
      id: '331',
      category: 'shipment',
      name: 'order_creation_request_sent_fail',
      param: {
        campaign_id: campaignId,
        number_of_creators: creatorsCount,
        number_of_orders_to_create: creatorsCount,
        number_of_unique_products_in_order: lineItems.length,
      },
    });
  };

  const handleOrderCreationError = useCallback<OnOrderCreationError>(
    (response, errors) => {
      sendOrderCreationErrorEvent();
      onOrderCreationError?.(response, errors);
    },
    [onOrderCreationError, campaignId, creatorsCount, selectedProducts]
  );

  const handleOrderCreationAnotherError = useCallback<OnOrderCreationAnotherError>(
    (error) => {
      sendOrderCreationErrorEvent();
      onOrderCreationAnotherError?.(error);
    },
    [onOrderCreationAnotherError, selectedProducts]
  );

  const handleProductSelectionComplete = useCallback(() => {
    amplitude.sendEvent<328>({
      id: '328',
      category: 'shipment',
      name: 'order_creation_next_click',
      param: { campaign_id: campaignId },
    });
    setContentVariant('confirm-order');
  }, [campaignId]);

  const handleCreateBulkShopifyOrderMutationCompleted =
    useCallback<OnCreateBulkShopifyOrderMutationCompleted>(
      (response, errors) => {
        if (response.createBulkShopifyOrder?.__typename === 'CreateBulkShopifyOrderPayload') {
          const lineItems = convertProductsToShopifyLineItemInput(selectedProducts).filter(
            (item) => item.quantity > 0
          );
          amplitude.sendEvent<330>({
            id: '330',
            category: 'shipment',
            name: 'order_creation_request_sent_success',
            param: {
              campaign_id: campaignId,
              number_of_creators: creatorsCount,
              number_of_orders_to_create: creatorsCount,
              number_of_unique_products_in_order: lineItems.length,
            },
          });
          setContentVariant('order-created');
          handleOrderSuccessfullyCreated(response, errors);
          return;
        }
        handleOrderCreationError?.(response, errors);
      },
      [
        handleOrderCreationError,
        handleOrderSuccessfullyCreated,
        campaignId,
        creatorsCount,
        selectedProducts,
      ]
    );

  const handleCreateOrderButtonClick = useCallback<OnCreateOrderButtonClick>(() => {
    amplitude.sendEvent<329>({
      id: '329',
      category: 'shipment',
      name: 'order_creation_create_order_click',
      param: { campaign_id: campaignId },
    });
  }, [campaignId]);

  const contents = useMemo<Contents>(() => {
    return {
      'select-product': (
        <SelectProduct
          selectedProducts={selectedProducts}
          onProductVariantSelectionChange={handleProductVariantsSelectionChange}
          onNextButtonClick={handleProductSelectionComplete}
          campaignId={campaignId}
          organizationId={organizationId}
          creatorsCount={creatorsCount}
          onOrderLostConnectionError={onOrderLostConnectionError}
          setCurrencyCode={setCurrencyCode}
        />
      ),
      'confirm-order': (
        <ConfirmOrder
          creatorsCount={creatorsCount}
          projectIDs={projectIDs}
          currencyCode={currencyCode}
          products={selectedProducts}
          onCreateBulkShopifyOrderMutationCompleted={handleCreateBulkShopifyOrderMutationCompleted}
          onCreateBulkShopifyOrderMutationError={handleOrderCreationAnotherError}
          onCreateOrderButtonClick={handleCreateOrderButtonClick}
          handleBackClick={handleBackToSelectProduct}
        />
      ),
      'order-created': <OrderCreated onOkButtonClick={handleCloseDrawer} />,
    };
  }, [
    currencyCode,
    selectedProducts,
    handleCloseDrawer,
    creatorsCount,
    handleOrderCreationError,
    handleOrderSuccessfullyCreated,
    handleOrderCreationAnotherError,
    handleProductSelectionComplete,
    handleCreateOrderButtonClick,
    handleCreateBulkShopifyOrderMutationCompleted,
  ]);

  return (
    <Drawer
      {...drawerProps}
      rootKey="shopify-order-creation"
      className={classnames(styles.drawer, drawerProps?.className)}
      onOpen={handleOpenDrawer}
      onClose={handleCloseDrawer}
    >
      {contents[contentVariant]}
    </Drawer>
  );
};

export default ShopifyOrderCreationDrawer;
export { ShopifyOrderCreationDrawer };

// types

type ContentVariant = 'select-product' | 'confirm-order' | 'order-created';
type Contents = Record<ContentVariant, React.ReactNode>;
type HandleProductVariantSelectionChange = NonNullable<
  ProductListProps['onProductVariantSelectionChange']
>;
type OnOrderSuccessfullyCreated = NonNullable<
  UseMutationConfig<CreateBulkShopifyOrderMutation>['onCompleted']
>;

export type OnOrderLostConnectionError = (response: ProductList_query$key[' $data']) => void;

type OnOrderCreationError = NonNullable<
  UseMutationConfig<CreateBulkShopifyOrderMutation>['onCompleted']
>;
type OnOrderCreationAnotherError = NonNullable<
  UseMutationConfig<CreateBulkShopifyOrderMutation>['onError']
>;

type OnCreateBulkShopifyOrderMutationCompleted = NonNullable<
  ConfirmOrderProps['onCreateBulkShopifyOrderMutationCompleted']
>;

type OnCreateOrderButtonClick = NonNullable<ConfirmOrderProps['onCreateOrderButtonClick']>;

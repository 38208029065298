import React from 'react';
import cn from 'classnames';

import styles from './UsagePerformanceList.pcss';

import Text from 'Atoms/Text/Text';
import IconNew from 'Components/ui/Icon/Icon';

interface Props {
  className?: string;
  values: UsagePerformanceListValue[];
}

const UsagePerformanceList: React.FC<Props> = (props) => {
  const { values, className } = props;

  return (
    <ul className={cn(styles.root, className)}>
      {values.map((item, key) => {
        const { iconName, msgTextProps, valueTextProps } = item;

        return (
          <li key={key} className={styles.listItem}>
            <div className={styles.iconWrapper}>
              <IconNew name={iconName} size={24} />
            </div>
            <Text
              align="left"
              {...msgTextProps}
              className={cn(styles.msg, msgTextProps?.className)}
            />
            {valueTextProps && (
              <Text {...valueTextProps} className={cn(styles.value, valueTextProps?.className)} />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default UsagePerformanceList;

export type UsagePerformanceListValue = {
  iconName: IconComponentProps['name'];
  msgTextProps: Partial<TextProps>;
  valueTextProps?: Partial<TextProps>;
};

type IconComponentProps = React.ComponentProps<typeof IconNew>;
type TextProps = React.ComponentProps<typeof Text>;

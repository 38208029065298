/**
 * @generated SignedSource<<97663cf1f5edcfbc9d1773344bd13bad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignInviteWrapperQuery$variables = {
  token: string;
};
export type CampaignInviteWrapperQuery$data = {
  readonly projectForOffer: {
    readonly campaign: {
      readonly id: string;
    };
  } | null | undefined;
};
export type CampaignInviteWrapperQuery = {
  response: CampaignInviteWrapperQuery$data;
  variables: CampaignInviteWrapperQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignInviteWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectForOffer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignInviteWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectForOffer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3db289555445996607eb7119ea09e40b",
    "id": null,
    "metadata": {},
    "name": "CampaignInviteWrapperQuery",
    "operationKind": "query",
    "text": "query CampaignInviteWrapperQuery(\n  $token: String!\n) {\n  projectForOffer(token: $token) {\n    campaign {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8dc6bc70ce6b2ea3a0bc126e8142f0e";

export default node;

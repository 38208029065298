import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ResetPasswordForm } from './ResetPasswordForm';
import { SignUpForm } from './SignUpForm';
import { SignInForm } from './SignInForm';

export enum AuthFormType {
  SIGNIN,
  SIGNUP,
  FORGOT_PASSWORD,
}

export type FormErrorType = {
  password?: string;
  email?: string;
};

export const FirebaseAuth: FC = () => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('organizationId');
  const [formType, setFormType] = useState<AuthFormType>(
    orgId ? AuthFormType.SIGNUP : AuthFormType.SIGNIN
  );

  const changeFormType = (formType: AuthFormType) => {
    setFormType(formType);
  };

  if (formType === AuthFormType.FORGOT_PASSWORD) {
    return <ResetPasswordForm changeFormType={changeFormType} />;
  } else if (formType === AuthFormType.SIGNUP) {
    return <SignUpForm changeFormType={changeFormType} />;
  }
  return <SignInForm changeFormType={changeFormType} />;
};

import React from 'react';

type Props = {
  to?: string;
  follow?: boolean;
} & JSX.IntrinsicElements['a'];

const PopupLink: React.FC<Props> = (props) => {
  const { to, follow = false, ...cleanProps } = props;
  let rel = 'noreferrer noopener';
  if (!follow) rel += ' nofollow';

  return <a {...cleanProps} href={to} rel={rel} target="_blank" />;
};

export default PopupLink;

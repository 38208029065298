import { fields, events, amplitudeFields } from '../constants';

const dataLayer = window.dataLayer || [];

export const initGoogleTagManager = (application) => {
  dataLayer.push({
    app_name: application,
  });
};

export const sendEventForAmplitude = (event) => {
  // if (process.env.DEPLOY_ENV === 'development' || process.env.DEPLOY_ENV === 'test' || process.env.DEPLOY_ENV === 'rc') {
  //   return;
  // }

  const {
    [amplitudeFields.eventId]: eventId,
    [amplitudeFields.eventCat]: eventCat,
    [amplitudeFields.eventName]: eventName,
    [amplitudeFields.eventParam]: eventParam,
  } = event;

  dataLayer.push({
    event: 'amplitude-event',
    event_id: eventId,
    event_cat: eventCat,
    event_name: eventName,
    event_param: eventParam || null,
  });
};

function sendEventGoogleTagManager(event) {
  if (event.amplitude) {
    sendEventForAmplitude(event);

    return;
  }

  const {
    [fields.app]: appValue,
    [fields.page]: pageValue,
    [fields.section]: sectionValue,
    [fields.subsection]: subsectionValue,
    [fields.element]: elementValue,
    [fields.event]: eventValue,
    [fields.goal]: goal,
    [fields.payload]: payloadValue,
  } = event;

  if (eventValue === events.identity) {
    dataLayer.push({
      event: 'set-user',
      UserId: payloadValue.id,
      UserEmail: payloadValue.email,
      UserPhoneNumber: payloadValue.phoneNumber,
    });
  } else {
    if (goal) {
      dataLayer.push({
        event: 'reach_goal',
        goal: goal,
      });
    }

    if (!eventValue && pageValue) {
      dataLayer.push({
        event: 'screen_view',
        screen_name: pageValue,
      });
    } else {
      dataLayer.push({
        event: 'js-event',
        event_name: eventValue,
        event_element: elementValue,
        event_page: pageValue,
        event_section: sectionValue,
        event_subsection: subsectionValue,
        event_element: elementValue,
        event_payload: payloadValue,
      });
    }
  }
}

export default sendEventGoogleTagManager;

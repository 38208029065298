/**
 * @generated SignedSource<<521d11a372b190e11b62b58dc056968b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type ContentReviewStatus = "FAILED" | "PENDING" | "SUCCESS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentReviewStep_project$data = {
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly campaign: {
    readonly publishingRequired: boolean;
    readonly type: CampaignType | null | undefined;
  };
  readonly contentReview: {
    readonly status: ContentReviewStatus;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DealActions_project">;
  readonly " $fragmentType": "ContentReviewStep_project";
};
export type ContentReviewStep_project$key = {
  readonly " $data"?: ContentReviewStep_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentReviewStep_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentReviewStep_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentReview",
      "kind": "LinkedField",
      "name": "contentReview",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishingRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealActions_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "a88459e984c01060d2d4c2be6794146b";

export default node;

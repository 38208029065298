import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import { isWaitingListCreatedByAdmin, getArchivedStagesList } from '../../utils';

import CreatorsListContainer from './CreatorsListContainer/CreatorsListContainer';
import CreatorsStub from './CreatorsStub/CreatorsStub';

import environment from 'Api/Environment';
import {
  CreatorsListQuery as QueryType,
  ProjectOrder,
  AdvertiserProjectStage,
  CreatorBadge,
  DateInterval,
} from 'GraphTypes/CreatorsListQuery.graphql';

const CreatorsListQuery = graphql`
  query CreatorsListQuery(
    $campaignId: ID!
    $organizationId: ID!
    $textQuery: String
    $shortlisted: Boolean
    $stages: [AdvertiserProjectStage!]
    $orderBy: ProjectOrder
    $archived: Boolean
    $badges: [CreatorBadge!]
    $createdByAdmin: Boolean
    $invitedByOrganizationMember: Boolean
    $outreach: Boolean
    $withPreviousCollaborations: Boolean
    $screeningQuestionIds: [ID!]
    $publicationDeadlineIntervals: [DateInterval!]
    $draftDeadlineIntervals: [DateInterval!]
  ) {
    campaign(id: $campaignId) {
      ...CreatorsListContainer_campaign
        @arguments(
          textQuery: $textQuery
          shortlisted: $shortlisted
          stages: $stages
          orderBy: $orderBy
          archived: $archived
          badges: $badges
          createdByAdmin: $createdByAdmin
          invitedByOrganizationMember: $invitedByOrganizationMember
          outreach: $outreach
          organizationId: $organizationId
          withPreviousCollaborations: $withPreviousCollaborations
          screeningQuestionIds: $screeningQuestionIds
          publicationDeadlineIntervals: $publicationDeadlineIntervals
          draftDeadlineIntervals: $draftDeadlineIntervals
        )
      activation {
        paused
      }
    }
    currentUser {
      admin
      organization {
        id
        subscription {
          canBrowseCreators
          canCreateMultipleDeals
        }
      }
    }
  }
`;

interface Props {
  campaignId: string;
  textQuery?: string;
  isAdminView: boolean;
  shortlisted?: boolean;
  invitedByOrganizationMember?: boolean;
  outreach?: boolean;
  isLongTermCampaign?: boolean;
  archived: boolean;
  orderBy?: ProjectOrder;
  stages?: AdvertiserProjectStage[];
  creatorsStages?: AdvertiserProjectStage[];
  defaultStages?: AdvertiserProjectStage[];
  badges?: CreatorBadge[];
  section?: string;
  portfolioTypeName: string;
  portfolioCategoryName: string;
  email?: string;
  organizationId: string;
  screeningQuestionIds?: string[];
  withPreviousCollaborations?: boolean;
  draftDeadlineIntervals: DateInterval[];
  publicationDeadlineIntervals: DateInterval[];
}

const CreatorsList: React.FC<Props> = (props) => {
  const {
    campaignId,
    isAdminView,
    shortlisted,
    orderBy,
    stages,
    badges,
    textQuery,
    archived,
    section,
    creatorsStages,
    defaultStages,
    portfolioTypeName,
    portfolioCategoryName,
    isLongTermCampaign,
    email,
    invitedByOrganizationMember,
    outreach,
    organizationId,
    withPreviousCollaborations,
    screeningQuestionIds,
    draftDeadlineIntervals,
    publicationDeadlineIntervals,
  } = props;

  const createdByAdminValue = section ? isWaitingListCreatedByAdmin(section, isAdminView) : null;

  return (
    <div>
      <QueryRenderer<QueryType>
        environment={environment}
        query={CreatorsListQuery}
        variables={{
          campaignId,
          shortlisted,
          orderBy,
          stages,
          badges,
          archived,
          textQuery,
          createdByAdmin: createdByAdminValue,
          portfolioTypeName,
          portfolioCategoryName,
          invitedByOrganizationMember,
          outreach,
          organizationId,
          withPreviousCollaborations,
          screeningQuestionIds,
          draftDeadlineIntervals,
          publicationDeadlineIntervals,
        }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <CreatorsStub />;

          const campaign = queryProps.campaign;
          const organizationId = queryProps.currentUser?.organization?.id;

          const subscription = queryProps.currentUser?.organization?.subscription;

          const canBrowseCreators = !!subscription?.canBrowseCreators;
          const canCreateMultipleDeals = !!subscription?.canCreateMultipleDeals;

          const archivedStages = getArchivedStagesList(isAdminView, canBrowseCreators);

          return (
            <CreatorsListContainer
              campaignId={campaignId}
              organizationId={organizationId}
              campaign={campaign}
              isAdminView={isAdminView}
              shortlisted={shortlisted}
              orderBy={orderBy}
              stages={stages}
              badges={badges}
              textQuery={textQuery}
              archived={archived}
              createdByAdmin={createdByAdminValue}
              creatorsStages={creatorsStages}
              defaultStages={defaultStages}
              archivedStages={archivedStages}
              portfolioTypeName={portfolioTypeName}
              portfolioCategoryName={portfolioCategoryName}
              canCreateMultipleDeals={canCreateMultipleDeals}
              isLongTermCampaign={isLongTermCampaign}
              email={email}
              invitedByOrganizationMember={invitedByOrganizationMember}
              outreach={outreach}
              withPreviousCollaborations={withPreviousCollaborations}
              screeningQuestionIds={screeningQuestionIds}
            />
          );
        }}
      />
    </div>
  );
};

export default CreatorsList;

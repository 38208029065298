import React from 'react';

import styles from './SimpleLink.css';

import modsClasses from 'Util/modsClasses.js';

const MODS = [
  'display',
  'underline',
];

const SimpleLink = props => {
  const {
    children,
    href,
    target,
    download,
    onClick,
    className,
  } = props;
  const classes = modsClasses(MODS, props, styles);

  if (!href) return React.cloneElement(children, { className: classes });

  return (
    <a
      className={`${classes} ${styles.root} ${className}`}
      href={href}
      target={target}
      onClick={onClick}
      download={download}
    >
      {children}
    </a>
  );
};

export default SimpleLink;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  UpdateInfluencerProfileFromFileMutation$data,
  UpdateInfluencerProfilesFromFileInput,
  UpdateInfluencerProfileFromFileMutation,
} from 'GraphTypes/UpdateInfluencerProfileFromFileMutation.graphql';

const mutation = graphql`
  mutation UpdateInfluencerProfileFromFileMutation($input: UpdateInfluencerProfilesFromFileInput!) {
    updateInfluencerProfilesFromFile(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateInfluencerProfilesFromFileInput,
  resolve?: (response: UpdateInfluencerProfileFromFileMutation$data) => void,
  reject?: fnType
) => {
  const { fileSecureUrl } = data;

  const variables = {
    input: {
      fileSecureUrl,
    },
  };
  commitMutation<UpdateInfluencerProfileFromFileMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

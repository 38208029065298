import { ref, uploadBytes } from 'firebase/storage';

import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';
import { FileType } from 'GraphTypes/CreateGcsFileMutation.graphql';

const directory = process.env.DIRECTORY_NAME;

const getUniqueName = () => Math.random().toString(36).substring(2, 10);

type FileData = { width: number; height: number; duration?: number };

const imageSize = (file: File) => {
  const img = new Image();
  const promise = new Promise<FileData>((resolve) => {
    img.onload = () => {
      resolve({ height: img.height, width: img.width });
    };
  });
  img.src = window.URL.createObjectURL(file);
  return promise;
};

const videoSize = (file: File) => {
  const url = URL.createObjectURL(file);
  const video = document.createElement('video');
  video.src = url;
  const promise = new Promise<FileData>((resolve) => {
    video.addEventListener('loadedmetadata', function () {
      resolve({
        height: this.videoWidth,
        width: this.videoHeight,
        duration: this.duration,
      });
    });
  });
  return promise;
};

const convertBase64ToBlob = (base64Image: string) => {
  const parts = base64Image.split(';base64,');
  const imageType = parts[0].split(':')[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);

  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: imageType });
};

export type ImageData = {
  src: string;
  width: number;
  height: number;
};

export interface UploadFileFnType {
  file?: File;
  imageData?: ImageData;
  category:
    | 'video_resume'
    | 'profile_picture'
    | 'video_scoring'
    | 'tiktok_media'
    | 'instagram_media'
    | 'portfolio_item'
    | 'message_attachment'
    | 'creative'
    | 'content_review_file'
    | 'reference'
    | 'campaign_cover'
    | 'brand_logo'
    | 'other';
}

export const uploadFile = async function (data: UploadFileFnType) {
  const { file, imageData, category } = data;
  if (!file && !imageData) {
    return;
  }

  let fileType: FileType = 'OTHER';
  let fileData: FileData;

  if (file?.type.includes('image')) {
    fileType = 'IMAGE';
    fileData = await imageSize(file);
  } else if (file?.type.includes('video')) {
    fileType = 'VIDEO';
    fileData = await videoSize(file);
  } else if (imageData) {
    fileType = 'IMAGE';
    fileData = { width: imageData.width, height: imageData.height };
  }

  const format = imageData ? 'png' : file?.name.split('.').reverse()[0];
  const fileName = getUniqueName() + getUniqueName();
  const finalName = `${directory}/${fileName}.${format}`;
  const originalFilename = file?.name;

  const storageRef = ref(firebaseClient.getStorage(), finalName);

  const metadata = {
    customMetadata: {
      category,
      source: directory || '',
    },
  };

  const finalFile = imageData ? convertBase64ToBlob(imageData.src) : file;

  if (!finalFile) return;

  return uploadBytes(storageRef, finalFile, metadata).then(() => {
    return { filename: `${fileName}.${format}`, originalFilename, type: fileType, ...fileData };
  });
};

/**
 * @generated SignedSource<<4d9ddf44236b43cd1cd92841b523e7a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignObjective = "APP_INSTALLS" | "BRAND_AWARENESS" | "CATALOG_SALES" | "CONVERSIONS" | "ENGAGEMENT" | "JUST_CONTENT" | "LEAD_GENERATION" | "MESSAGES" | "REACH" | "STORE_TRAFFIC" | "TRAFFIC" | "VIDEO_VIEWS" | "%future added value";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreativeAssets_campaign$data = {
  readonly brief: {
    readonly creatives?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"CreativeAsset_briefCreative">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    };
    readonly id: string;
    readonly taskDescription?: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly objective: CampaignObjective | null | undefined;
  readonly platform: CampaignPlatform | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly " $fragmentType": "CreativeAssets_campaign";
};
export type CreativeAssets_campaign$key = {
  readonly " $data"?: CreativeAssets_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreativeAssets_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "brief",
          "creatives"
        ]
      }
    ]
  },
  "name": "CreativeAssets_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objective",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taskDescription",
              "storageKey": null
            },
            {
              "alias": "creatives",
              "args": null,
              "concreteType": "BriefCreativeConnection",
              "kind": "LinkedField",
              "name": "__Creatives_creatives_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefCreativeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BriefCreative",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "CreativeAsset_briefCreative"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "19344742cdd7f5d99963f9e0ea2526a2";

export default node;

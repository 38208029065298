import React from 'react';
import { useDispatch } from 'react-redux';
import { graphql, useFragment } from 'react-relay';
import { isMobile } from 'react-device-detect';

import styles from './ProjectPSStatus.pcss';

import { setModal } from 'Actions/Modal.Actions';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Text from 'Atoms/Text/Text';
import { modalName } from 'Types/modals';
import { ProjectPSStatus_project$key } from 'GraphTypes/ProjectPSStatus_project.graphql';

interface Props {
  project: ProjectPSStatus_project$key;
}

const ProjectPSStatus: React.FC<Props> = (props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment ProjectPSStatus_project on Project {
        id
        paidSocial
        creator {
          username
          ... on InstagramCreator {
            paidSocialEnabled
          }
        }
        brief {
          ... on V2Brief {
            paidSocialBca
            paidSocialActivationDays
          }
        }
      }
    `,
    project
  );

  const dispatch = useDispatch();

  const projectId = data.id;
  const paidSocial = data.paidSocial;
  const paidSocialEnabled = data.creator?.paidSocialEnabled;
  const username = data.creator?.username;
  const paidSocialActivationDays = data.brief?.paidSocialActivationDays;
  const paidSocialBca = data.brief?.paidSocialBca;

  const handleConnectFbClick = () => {
    dispatch(
      setModal(modalName.CONNECT_PS, {
        username,
        projectId,
        psDays: paidSocialActivationDays,
        psBca: paidSocialBca,
      })
    );
  };

  if ((paidSocial || paidSocialBca) && !paidSocialEnabled) {
    const btn = isMobile ? (
      <TextButton
        msg="chat_creator.ps_error.submit"
        className={styles.psErrorBtn}
        onClick={handleConnectFbClick}
      />
    ) : (
      <Button
        width="full"
        size="md"
        msg="chat_creator.ps_error.submit"
        className={styles.psErrorBtn}
        onClick={handleConnectFbClick}
      />
    );

    return (
      <div className={styles.psError}>
        <Text msg="chat_creator.ps_error.descr" />
        {btn}
      </div>
    );
  }

  return null;
};

export default ProjectPSStatus;

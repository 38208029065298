import { StatItem, createDateLabel } from '../Chart/utils';

import type { CpmCpeCountersChartQueryType } from './CpmCpeCountersChart.Query';

import type { Performance_Granularity } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';

type CharDataType = {
  stats: StatItem[];
  lines: {
    cpm: number;
    cpe: number;
    date: string;
  }[];
};
export const chartData = (
  response: CpmCpeCountersChartQueryType['response'],
  granularity: Performance_Granularity
): CharDataType => {
  const stats = [
    {
      amount: response.performance?.cpmCpeCounters?.totalCpm || 0,
      key: 'cpm',
      description: {
        titleMsg: 'performance.dashboard.chart.cpm_cpe_counters.cpm.title',
        tooltipMsg: 'performance.dashboard.chart.cpm_cpe_counters.cpm.tooltip',
      },
    },
    {
      amount: response.performance?.cpmCpeCounters?.totalCpe || 0,
      key: 'cpe',
      description: {
        titleMsg: 'performance.dashboard.chart.cpm_cpe_counters.cpe.title',
        tooltipMsg: 'performance.dashboard.chart.cpm_cpe_counters.cpe.tooltip',
      },
    },
  ];

  const edges = response?.performance?.cpmCpeCounters?.edges || [];
  const lines: {
    cpm: number;
    cpe: number;
    date: string;
  }[] = [];

  edges.forEach((item) => {
    if (!item?.node) return;
    lines.push({
      cpm: item.node.cpm,
      cpe: item.node.cpe,
      date:
        createDateLabel(item.node.dateFrom as string, item.node.dateTo as string, granularity) ||
        '',
    });
  });

  return { stats, lines };
};

/**
 * @generated SignedSource<<d4f9714bceca6f5e87fb1e6b511a5ca1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlocklistQuery$variables = Record<PropertyKey, never>;
export type BlocklistQuery$data = {
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly churned: boolean;
      readonly id: string;
      readonly newClient: boolean;
      readonly subscription: {
        readonly paused: boolean;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"BlocklistItems_organization">;
    } | null | undefined;
  } | null | undefined;
};
export type BlocklistQuery = {
  response: BlocklistQuery$data;
  variables: BlocklistQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newClient",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "churned",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 18
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlocklistQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BlocklistItems_organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BlocklistQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "CreatorConnection",
                "kind": "LinkedField",
                "name": "blockedCreators",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreatorEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v1/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isCreator"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Badge",
                                "kind": "LinkedField",
                                "name": "badges",
                                "plural": true,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Country",
                                "kind": "LinkedField",
                                "name": "countries",
                                "plural": true,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "profilePictureFile",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ImageTransformation",
                                        "kind": "LinkedField",
                                        "name": "transformations",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "collageThumbnailUrl",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "Image",
                                    "abstractKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "shippingRecipientLastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "shippingRecipientFirstName",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstagramUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "profilePictureUrl",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "InstagramCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "YoutubeChannel",
                                "kind": "LinkedField",
                                "name": "channel",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "thumbnailUrl",
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "YoutubeCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatarUrl",
                                "storageKey": null
                              }
                            ],
                            "type": "ContentCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TiktokUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "secureUrl",
                                        "storageKey": null
                                      },
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v7/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "TiktokCreator",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "blockedCreators(first:18)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Organization_blockedCreators",
                "kind": "LinkedHandle",
                "name": "blockedCreators"
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e89549eb85a56db0fd7573a172758219",
    "id": null,
    "metadata": {},
    "name": "BlocklistQuery",
    "operationKind": "query",
    "text": "query BlocklistQuery {\n  currentUser {\n    admin\n    organization {\n      id\n      newClient\n      churned\n      subscription {\n        paused\n        id\n      }\n      ...BlocklistItems_organization\n    }\n    id\n  }\n}\n\nfragment BlockedCreator_creator on Creator {\n  __isCreator: __typename\n  id\n  profile {\n    badges {\n      name\n      id\n    }\n    countries {\n      name\n      id\n    }\n    profilePictureFile {\n      __typename\n      url\n      ... on Image {\n        transformations {\n          collageThumbnailUrl\n        }\n      }\n      id\n    }\n    id\n  }\n  ...CreatorDetails_creator\n  type\n  username\n  ... on YoutubeCreator {\n    channel {\n      id\n      name\n    }\n  }\n  ... on TiktokCreator {\n    user {\n      name\n      id\n    }\n  }\n}\n\nfragment BlocklistItems_organization on Organization {\n  blockedCreators(first: 18) {\n    edges {\n      node {\n        __typename\n        id\n        ...BlockedCreator_creator\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment CreatorDetails_creator on Creator {\n  __isCreator: __typename\n  id\n  profile {\n    badges {\n      name\n      id\n    }\n    countries {\n      name\n      id\n    }\n    profilePictureFile {\n      __typename\n      url\n      ... on Image {\n        transformations {\n          collageThumbnailUrl\n        }\n      }\n      id\n    }\n    shippingRecipientLastName\n    shippingRecipientFirstName\n    id\n  }\n  type\n  username\n  ... on InstagramCreator {\n    user {\n      profilePictureUrl\n      id\n    }\n  }\n  ... on YoutubeCreator {\n    channel {\n      id\n      thumbnailUrl\n      name\n    }\n  }\n  ... on ContentCreator {\n    avatarUrl\n  }\n  ... on TiktokCreator {\n    user {\n      avatar {\n        __typename\n        secureUrl\n        id\n      }\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "370907b7400d45de495d9a4fdc592440";

export default node;

import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { SmartLinkType } from 'Types/common';

const relativeLinkRegex = /^\//;

const SmartLink: React.FC<PropsWithChildren<SmartLinkType>> = (props) => {
  const { path, state, children, download, className, target, onClick } = props;

  if (!children) return null;

  if (!path) {
    return (
      <div className={className} onClick={onClick}>
        {children}
      </div>
    );
  }

  const isRelativeLink = relativeLinkRegex.test(path);

  if (isRelativeLink && !target) {
    return (
      <Link to={path} state={state} className={className} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <a
      target={target || '_blank'}
      href={path}
      download={download}
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default SmartLink;

/**
 * @generated SignedSource<<dcd9faf4ca5ce36de32449dfa3ef6cd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BriefContent_campaign$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BriefInfo_campaign" | "CampaignData_campaign" | "CreativeList_campaign">;
  readonly " $fragmentType": "BriefContent_campaign";
};
export type BriefContent_campaign$key = {
  readonly " $data"?: BriefContent_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"BriefContent_campaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BriefContent_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignData_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreativeList_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BriefInfo_campaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "cf84cb5e410686346a1b52a76be7b8dc";

export default node;

/**
 * @generated SignedSource<<26f7157aad9cf670a54d0dcc078b85aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkInsenseContentReviewAsPassedInput = {
  clientMutationId?: string | null | undefined;
  projectId: string;
};
export type MarkInsenseContentReviewAsPassedMutation$variables = {
  input: MarkInsenseContentReviewAsPassedInput;
};
export type MarkInsenseContentReviewAsPassedMutation$data = {
  readonly markInsenseContentReviewAsPassed: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type MarkInsenseContentReviewAsPassedMutation = {
  response: MarkInsenseContentReviewAsPassedMutation$data;
  variables: MarkInsenseContentReviewAsPassedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkInsenseContentReviewAsPassedPayload",
    "kind": "LinkedField",
    "name": "markInsenseContentReviewAsPassed",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkInsenseContentReviewAsPassedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkInsenseContentReviewAsPassedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a6223f7f1dc88f9d31d1ee00fcf6114f",
    "id": null,
    "metadata": {},
    "name": "MarkInsenseContentReviewAsPassedMutation",
    "operationKind": "mutation",
    "text": "mutation MarkInsenseContentReviewAsPassedMutation(\n  $input: MarkInsenseContentReviewAsPassedInput!\n) {\n  markInsenseContentReviewAsPassed(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "a461df0d3e4b76c4a286f39200c7ae46";

export default node;

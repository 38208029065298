import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';

import styles from './Tooltip.pcss';

import { amplitude } from 'Helpers/amplitude';
import { TooltipType } from 'Types/common';

const Tooltip: React.FC<PropsWithChildren<TooltipType>> = (props) => {
  const {
    className,
    tooltipClassName,
    childrenWrapClassName,
    children,
    customTooltip,
    id,
    place,
    tooltipMsg,
    tooltipValues,
    tooltipText,
    clickable,
    delayShow = 300,
    eventData,
    getContent,
    type = 'dark',
    key,
    showByDefault,
    delayHide,
    noArrow,
  } = props;

  const intl = useIntl();
  const [isOpen, setShowState] = useState(showByDefault);

  useEffect(() => {
    setShowState(showByDefault);
  }, [showByDefault]);

  const placePosition = place ? place : 'right';

  const isTooltipOff =
    (!tooltipMsg && !tooltipText && !customTooltip && !getContent) || isMobile || !id;

  if (isTooltipOff) {
    //@TODO By no reason React.FC can't return own children. May be will be fixed after update React and TS.
    return children ?? (null as any);
  }

  const tooltip = tooltipMsg ? intl.formatMessage({ id: tooltipMsg }, tooltipValues) : tooltipText;

  const hanldeAfterShow = () => {
    if (eventData) {
      amplitude.sendEvent(eventData);
    }
  };

  return (
    <div className={className} key={key}>
      <div
        data-tooltip-html={getContent ? '' : tooltip}
        data-tooltip-id={id}
        className={childrenWrapClassName}
        data-multiline
      >
        {children}
      </div>
      <ReactTooltip
        id={id}
        place={placePosition}
        variant={type}
        delayShow={delayShow}
        clickable={clickable}
        noArrow={noArrow}
        className={classnames(
          styles.tooltip,
          {
            [styles.dark]: type === 'dark',
            [styles.light]: type === 'light',
          },
          tooltipClassName
        )}
        afterShow={hanldeAfterShow}
        content={getContent}
        isOpen={isOpen}
        delayHide={delayHide}
      >
        {customTooltip}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;

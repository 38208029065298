import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import CityListContainer from './CityListContainer/CityListContainer';

import environment from 'Api/Environment';

const CityListQuery = graphql`
  query CityListQuery($query: String!) {
    ...CityListContainer_cities @arguments(query: $query)
  }
`;

const CityList = (props) => {
  const { menu, textQuery } = props;

  if (!menu || !textQuery) return null;

  return (
    <QueryRenderer
      environment={environment}
      query={CityListQuery}
      variables={{ query: textQuery }}
      render={({ props: queryProps }) => {
        if (!queryProps) return null;

        return <CityListContainer menu={menu} query={textQuery} cities={queryProps} />;
      }}
    />
  );
};

export default CityList;

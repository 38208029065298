/**
 * @generated SignedSource<<892dd4360a6173ad2003e4f040e8da10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatchStatusQuery$variables = {
  id: string;
};
export type BatchStatusQuery$data = {
  readonly outreachBatch: {
    readonly creatorImport: {
      readonly completed: boolean;
      readonly importedCreators: number;
      readonly totalCreators: number;
    } | null | undefined;
  } | null | undefined;
};
export type BatchStatusQuery = {
  response: BatchStatusQuery$data;
  variables: BatchStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCreators",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importedCreators",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatchStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OutreachBatch",
        "kind": "LinkedField",
        "name": "outreachBatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorImport",
            "kind": "LinkedField",
            "name": "creatorImport",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatchStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OutreachBatch",
        "kind": "LinkedField",
        "name": "outreachBatch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorImport",
            "kind": "LinkedField",
            "name": "creatorImport",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "380f031366b1f6a036ea18a731da73dd",
    "id": null,
    "metadata": {},
    "name": "BatchStatusQuery",
    "operationKind": "query",
    "text": "query BatchStatusQuery(\n  $id: ID!\n) {\n  outreachBatch(id: $id) {\n    creatorImport {\n      completed\n      totalCreators\n      importedCreators\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c7b7ea5b37fe359094cca5d2c585e0b";

export default node;

import { graphql, useMutation } from 'react-relay';

import { CreateInviteSuggestionListQuery } from 'GraphTypes/CreateInviteSuggestionListQuery.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const CreateInviteSuggestionListQueryMutation = graphql`
  mutation CreateInviteSuggestionListQuery($input: CreateInviteSuggestionListInput!) {
    createInviteSuggestionList(input: $input) {
      ... on CreateInviteSuggestionListPayload {
        inviteSuggestionList {
          id
          inviteSuggestionsCount: inviteSuggestions {
            totalCount
          }
        }
      }
      ... on LimitExceededError {
        message
      }
      __typename
    }
  }
`;

export const useCreateInviteSuggestionListQueryMutation: BaseMutation<
  CreateInviteSuggestionListQuery
> = (config) => {
  const [createInviteSuggestionListQuery, loading] = useMutation<CreateInviteSuggestionListQuery>(
    CreateInviteSuggestionListQueryMutation
  );

  const createInviteSuggestionListQueryCaller: BaseMutationExecFn<
    CreateInviteSuggestionListQuery
  > = (execConfig) => {
    createInviteSuggestionListQuery({
      ...config,
      ...execConfig,
    });
  };
  return [createInviteSuggestionListQueryCaller, loading];
};

import React from 'react';
import classnames from 'classnames';

import styles from './GuideTourBackdrop.pcss';

type Props = {
  className?: string;
};

const GuideTourBackdrop: React.FC<Props> = (props) => {
  const { className } = props;
  return <div className={classnames(styles.root, className)} />;
};

export { GuideTourBackdrop };

/**
 * @generated SignedSource<<c947578d96ab46b98318abd45467d065>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCreatorAmazonStorelink_creator$data = {
  readonly amazonStorefrontLink: string | null | undefined;
  readonly " $fragmentType": "useCreatorAmazonStorelink_creator";
};
export type useCreatorAmazonStorelink_creator$key = {
  readonly " $data"?: useCreatorAmazonStorelink_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCreatorAmazonStorelink_creator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCreatorAmazonStorelink_creator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amazonStorefrontLink",
      "storageKey": null
    }
  ],
  "type": "CreatorProfile",
  "abstractKey": null
};

(node as any).hash = "4238dda80ba71af2c252bc75da4aa893";

export default node;

import React from 'react';
import classnames from 'classnames';

import styles from './RadioButton.pcss';

interface Props {
  className?: string;
  active: boolean;
  disabled?: boolean;
}

const RadioButton: React.FC<Props> = (props) => {
  const { className, active, disabled } = props;

  return (
    <div
      className={classnames(className, {
        [styles.isActive]: active,
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.radioButton} />
    </div>
  );
};

export default RadioButton;

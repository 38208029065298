/**
 * @generated SignedSource<<4b78d64dd28b6a7c2eb88daa173d4cce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchResultAccounts_result$data = {
  readonly creator: {
    readonly businessAccountEnabled?: boolean;
    readonly id: string;
    readonly paidSocialEnabled?: boolean;
  } | null | undefined;
  readonly " $fragmentType": "SearchResultAccounts_result";
};
export type SearchResultAccounts_result$key = {
  readonly " $data"?: SearchResultAccounts_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResultAccounts_result">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchResultAccounts_result",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "businessAccountEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialEnabled",
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CreatorSearchResult",
  "abstractKey": null
};

(node as any).hash = "ebffd98353b9450622255b29d75331a9";

export default node;

import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import Field from '../../components/Field/Field';
import Param from '../../components/Param/Param';

import styles from './Lookalikes.css';

import { copyText } from 'Util/copyText';
import createLookalike from 'Mutations/CreateLookalike.Mutation';
import deleteLookalike from 'Mutations/DeleteLookalike.Mutation';
import { Lookalikes_campaign$key } from 'GraphTypes/Lookalikes_campaign.graphql';

interface Props {
  campaign: Lookalikes_campaign$key;
}

const Lookalikes: React.FC<Props> = (props) => {
  const { campaign } = props;

  const data = useFragment(
    graphql`
      fragment Lookalikes_campaign on Campaign {
        brief {
          id
          __typename
          ... on V2Brief {
            lookalikes(first: 2147483647) @connection(key: "Brief_lookalikes") {
              edges {
                node {
                  id
                  url
                }
              }
            }
          }
        }
      }
    `,
    campaign
  );

  if (!data.brief) return null;

  const { id: briefId, lookalikes } = data.brief;

  const [error, setError] = useState('');

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
    } catch (e) {
      setError('brief_template.field.creators.lookalikes.error');

      return false;
    }

    setError('');

    return true;
  };

  const handleLinkRemove = (id: string) => {
    deleteLookalike({ briefId, lookalikeId: id });
  };

  const handleUrlChange = (value: string) => {
    createLookalike({ briefId, url: value });
  };

  const handleUrlBlur = ({ lookalikes }: { lookalikes: string }) => {
    createLookalike({ briefId, url: lookalikes });
  };

  const handleValidateValue = (url: string) => {
    const isValid = isValidUrl(url);

    return isValid;
  };

  return (
    <div className={styles.root}>
      <Field
        title="brief_template.field.creators.lookalikes.title"
        description="brief_template.field.creators.lookalikes.descr"
        isOptional={true}
        name="lookalikes"
        element="input"
        elementData={{
          placeholderMsg: 'brief_template.field.creators.lookalikes.placeholder',
          onEnterKeyDown: handleUrlChange,
          onBlur: handleUrlBlur,
          valueValidation: handleValidateValue,
        }}
        error={!!error}
        errorData={{
          msg: error,
        }}
      />
      <div className={styles.linkList}>
        {lookalikes?.edges?.map((item) => {
          if (!item?.node) return null;
          const { id, url } = item.node;

          const handleTextClick = () => {
            copyText(url);
          };

          return (
            <Param
              id={id}
              key={id}
              titleData={{ text: url }}
              className={styles.param}
              tooltipData={{ id, tooltipMsg: 'general.copy_to_clipboard', place: 'top' }}
              onTextClick={handleTextClick}
              onRemove={handleLinkRemove}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Lookalikes;

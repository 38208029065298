import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import CheckboxWrapper from '../../components/CheckboxWrapper/CheckboxWrapper';
import Field from '../../components/Field/Field';

import CreatorExclusion from 'Organisms/CreatorExclusion/CreatorExclusion';
import { CreatorsExclusion_campaign$key } from 'GraphTypes/CreatorsExclusion_campaign.graphql';

interface Props {
  isAdmin?: boolean;
  campaign: CreatorsExclusion_campaign$key;
  onChange: (data: any) => void;
}

const CreatorsExclusion: React.FC<Props> = (props) => {
  const { campaign, isAdmin, onChange } = props;

  const data = useFragment(
    graphql`
      fragment CreatorsExclusion_campaign on Campaign {
        id
        creator {
          organization {
            id
          }
        }
        searchQuery {
          exclusions {
            campaign {
              id
              name
              organization {
                name
              }
            }
            advertiserProjectStages
          }
          excludeHiredMoreThanOnce
        }
      }
    `,
    campaign
  );

  const exclusions = data.searchQuery?.exclusions;

  const excludeHiredMoreThanOnce = data.searchQuery?.excludeHiredMoreThanOnce;
  const organizationId = data.creator?.organization?.id;

  const defaultExclusions = {
    exclusions,
    excludeHiredMoreThanOnce,
  };

  const defaultVisibility = Number(exclusions?.length) > 0 || excludeHiredMoreThanOnce;

  const [isExclusionVisible, setExclusionVisible] = useState(defaultVisibility);
  const [enabledExcludeData, setEnabledExcludeData] = useState(defaultExclusions);

  if (!organizationId) return null;

  const handleExclusionShow = (isVisible: boolean) => {
    setExclusionVisible(isVisible);
    onChange(!isVisible ? defaultExclusions : enabledExcludeData);
  };

  const handleExclusionChange = (data: any) => {
    onChange(data);
    setEnabledExcludeData(data);
  };

  return (
    <CheckboxWrapper isActive={Boolean(isExclusionVisible)} onChange={handleExclusionShow}>
      <Field
        title="brief_template.field.creators.new_creators.title"
        description="brief_template.field.creators.new_creators.descr"
        isOptional={true}
      >
        <CreatorExclusion
          admin={isAdmin}
          defaultExclusions={defaultExclusions}
          organizationId={organizationId}
          isShownByDefault={true}
          onChange={handleExclusionChange}
        />
      </Field>
    </CheckboxWrapper>
  );
};

export default CreatorsExclusion;

/**
 * @generated SignedSource<<6178ec3a0ba0786174bca29bf061ac43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAdvertiserProfileInput = {
  clientMutationId?: string | null | undefined;
  companyRole?: string | null | undefined;
  countryId?: string | null | undefined;
  firstName?: string | null | undefined;
  id: string;
  lastName?: string | null | undefined;
  phoneNumber?: string | null | undefined;
};
export type UpdateAdvertiserProfileMutation$variables = {
  input: UpdateAdvertiserProfileInput;
};
export type UpdateAdvertiserProfileMutation$data = {
  readonly updateAdvertiserProfile: {
    readonly profile: {
      readonly companyRole: string | null | undefined;
      readonly firstName: string | null | undefined;
      readonly id: string;
      readonly lastName: string | null | undefined;
    };
  } | null | undefined;
};
export type UpdateAdvertiserProfileMutation = {
  response: UpdateAdvertiserProfileMutation$data;
  variables: UpdateAdvertiserProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAdvertiserProfilePayload",
    "kind": "LinkedField",
    "name": "updateAdvertiserProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdvertiserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyRole",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdvertiserProfileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdvertiserProfileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fa1597cf5db5c89de76d2a96854acbde",
    "id": null,
    "metadata": {},
    "name": "UpdateAdvertiserProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdvertiserProfileMutation(\n  $input: UpdateAdvertiserProfileInput!\n) {\n  updateAdvertiserProfile(input: $input) {\n    profile {\n      id\n      lastName\n      firstName\n      companyRole\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c3d407acc44c3582a6d16ccfd3efd9d8";

export default node;

import { SET_MODAL, TOGGLE_MODAL } from 'Constants';

const initialState = {
  name: '',
  attach: null,
  opened: false,
  backdropActive: false,
  forceClose: false,
};

const modal = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MODAL:
      return {
        ...state,
        name: payload.name,
        attach: payload.attach,
        forceClose: payload.forceClose,
        opened: true,
      };

    case TOGGLE_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default modal;

import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { BriefContext } from '../../../../Brief.Context';
import Field from '../../components/Field/Field';

import styles from './Deliverables.pcss';

import Text from 'Components/ui/Text/Text';
import { CreativeAssets_campaign } from 'GraphTypes/CreativeAssets_campaign.graphql';
import updateBrief from 'Api/mutations/UpdateBrief.Mutation';

interface Props {
  brief: CreativeAssets_campaign['brief'];
}

const Deliverables: React.FC<Props> = (props) => {
  const { brief } = props;
  const { id: briefId, taskDescription } = brief;
  const intl = useIntl();
  const descriptionPreset = intl.formatMessage({
    id: 'brief_template.field.deliverables.description.descr.preset',
  });

  const [_, briefDispatch] = useContext(BriefContext);

  useEffect(() => {
    if (taskDescription?.length > 0 && taskDescription !== descriptionPreset) {
      briefDispatch({
        key: 'creativesInfoFilled',
        value: true,
      });
    }
  }, []);

  const handleBriefChange = (data: { taskDescription: string }) => {
    const { taskDescription } = data;
    if (taskDescription?.length > 0 && taskDescription !== descriptionPreset) {
      briefDispatch({ key: 'briefIsSaving', value: true });
      briefDispatch({
        key: 'creativesInfoFilled',
        value: true,
      });
      updateBrief({ id: briefId, ...data });
    } else if (!taskDescription?.length) {
      briefDispatch({
        key: 'creativesInfoFilled',
        value: false,
      });
    }
  };

  return (
    <div className={styles.root}>
      <Text
        type="md"
        msg="brief_template.section.creative_assets.deliverables.title"
        className={styles.title}
      />
      <Text
        type="label"
        msg="brief_template.section.creative_assets.deliverables.descr"
        className={styles.descr}
      />
      <Field
        element="input"
        name="taskDescription"
        elementData={{
          multiple: true,
          maxlength: 500,
          defaultValue: taskDescription || descriptionPreset,
          onChange: handleBriefChange,
          textAreaData: { minRows: 1, maxRows: 5 },
          dataTest: 'input:deliverablesInstruction',
        }}
      />
    </div>
  );
};

export default Deliverables;

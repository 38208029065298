import React, { useState, useRef, useMemo, useEffect } from 'react';
import track, { useTracking } from 'react-tracking';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import Menu from '../Menu/Menu';

import styles from './Select.css';

import Input from 'Atoms/Input/Input';

const Select = (props) => {
  const { name, value, items, placeholderMsg, style, isSingle, onChange } = props;

  const [open, setOpen] = useState(false);
  const [textQuery, setTextQuery] = useState('');

  const tracking = useTracking();

  const containerEl = useRef(null);

  const handleToggleOpenState = () => {
    setOpen(!open);
  };

  const handleToggleVisibility = () => {
    if (open) return;
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }
  }, [open]);

  const handleClick = (event) => {
    const container = containerEl.current;
    if (!container) return;
    const isClickInside = container.contains(event.target);
    if (!isClickInside) {
      handleToggleOpenState();
    }
  };

  const handleTextQueryChange = (value) => {
    setTextQuery(value);
  };

  const handleValueChanged = (newValue) => {
    onChange({ [name]: newValue });
  };

  const handleItemSelected = (item) => {
    tracking.trackEvent({
      event: 'selected',
      payload: {
        value: item.name,
      },
    });

    if (isSingle) {
      setTimeout(() => {
        setOpen(false);
      }, 100);
      handleValueChanged(item.id);

      return;
    }

    const index = findIndex(value, (valueItem) => {
      return valueItem.id === item.id;
    });
    const newValue = [...value];
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(item);
    }

    handleValueChanged(newValue);
  };

  const checkActiveState = (id) => {
    if (isSingle) {
      return id === value;
    }

    const index = findIndex(value, (valueItem) => {
      return valueItem.id === id;
    });

    return index !== -1;
  };

  const getCurrentValue = () => {
    const activeItem = find(items, (item) => {
      return item.id === value;
    });

    return activeItem || undefined;
  };

  const currentValue = getCurrentValue();

  let valueText = '';

  const defaultInputData = { msg: placeholderMsg, color: 'grayDog' };

  if (value) {
    if (isSingle) {
      valueText = currentValue.name;
      defaultInputData.msg = undefined;
      defaultInputData.color = undefined;
      defaultInputData.text = valueText;
    } else {
      const textList = value.map((textItem) => {
        return textItem.name;
      });
      valueText = textList.join(', ');
      if (textList.length > 0) {
        defaultInputData.msg = undefined;
        defaultInputData.color = undefined;
        defaultInputData.text = valueText;
      }
    }
  }

  const finalItems = useMemo(() => {
    return items.reduce((acc, item) => {
      const tempName = item.name.toLowerCase();
      const tempValue = textQuery ? textQuery.toLowerCase() : textQuery;
      if (tempName.includes(tempValue)) {
        acc.push(item);
      }

      return acc;
    }, []);
  }, [items, textQuery]);

  return (
    <div ref={containerEl} className={styles.root}>
      <div className={styles.panel} style={style} onClick={handleToggleVisibility}>
        <Input placeholderMsg={placeholderMsg} onChange={handleTextQueryChange} />
      </div>
      {open && (
        <Menu
          isSingle={isSingle}
          style={style}
          className={styles.menu}
          items={finalItems}
          onChange={handleItemSelected}
          checkActiveState={checkActiveState}
        />
      )}
    </div>
  );
};

export default track((props) => ({
  element: props.name,
}))(Select);

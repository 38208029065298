import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CreatorActions from '../../CreatorActions/CreatorActions';

import styles from './AdvertiserProjectActions.pcss';
import HireStage from './HireStage/HireStage';
import FbAdsAction from './FbAdsAction/FbAdsAction';
import ContentCreationStage from './ContentCreationStage/ContentCreationStage';
import ContentSubmittedStage from './ContentSubmittedStage/ContentSubmittedStage';
import PublishingStartedStage from './PublishingStartedStage/PublishingStartedStage';
import CompletedStage from './CompletedStage/CompletedStage';
import ArchivedStage from './ArchivedStage/ArchivedStage';
import AddNotes from './AddNotes/AddNotes';

import { AdvertiserProjectActions_project$key } from 'GraphTypes/AdvertiserProjectActions_project.graphql';
import { ProjectActionsQuery$data } from 'GraphTypes/ProjectActionsQuery.graphql';

interface Props {
  currentUser?: ProjectActionsQuery$data['currentUser'] | null;
  project: AdvertiserProjectActions_project$key;
}

const AdvertiserProjectActions: React.FC<Props> = (props) => {
  const { project, currentUser } = props;

  const isAdmin = !!currentUser?.admin;

  const data = useFragment<AdvertiserProjectActions_project$key>(
    graphql`
      fragment AdvertiserProjectActions_project on Project {
        id
        advertiserStage
        archivation {
          id
        }
        price
        campaign {
          type
          useCase
        }
        ...FbAdsAction_project
        ...CreatorActions_project
        ...HireStage_project
        ...ContentCreationStage_project
        ...ContentSubmittedStage_project
        ...PublishingStartedStage_project
        ...CompletedStage_project
        ...AddNotes_project
        archivationByAdvertiser: archivation(side: ADVERTISER) {
          id
        }
      }
    `,
    project
  );

  const advertiserStage = data?.advertiserStage;

  const canUsePaidSocial = !!currentUser?.organization?.subscription?.canUsePaidSocial;
  const canCreateMultipleDeals = !!currentUser?.organization?.subscription?.canCreateMultipleDeals;

  const isSeedingCampaign = data?.campaign.type === 'PRODUCT_SEEDING';
  const archivationByAdvertiser = !!data?.archivationByAdvertiser?.id;

  if (!data) return null;

  const getContent = () => {
    if (archivationByAdvertiser) {
      return <ArchivedStage projectId={data.id} isAdmin={isAdmin} />;
    }

    switch (advertiserStage) {
      case 'ACCEPTED':
        return <HireStage project={data} isAdmin={isAdmin} currentUser={currentUser} />;
      case 'LAUNCHED':
        return <ContentCreationStage project={data} canUsePaidSocial={canUsePaidSocial} />;

      case 'CONTENT_SUBMITTED':
        return <ContentSubmittedStage project={data} canUsePaidSocial={canUsePaidSocial} />;

      case 'PUBLISHING_STARTED':
        return <PublishingStartedStage project={data} />;

      case 'COMPLETED':
        return <CompletedStage project={data} />;
      default:
        return null;
    }
  };

  const showNote =
    advertiserStage === 'ACCEPTED' ||
    advertiserStage === 'PUBLISHING_STARTED' ||
    (advertiserStage === 'CONTENT_SUBMITTED' && isSeedingCampaign) ||
    advertiserStage === 'LAUNCHED';

  return (
    <div className={styles.root}>
      {getContent()}
      {showNote && <AddNotes project={data} isAdmin={isAdmin} />}
      {
        <CreatorActions
          project={data}
          isAdmin={isAdmin}
          isNewView={true}
          showNote={!showNote}
          fbAdsControl={
            canUsePaidSocial && advertiserStage === 'COMPLETED' ? (
              <FbAdsAction btnType="white" project={data} className={styles.control} />
            ) : undefined
          }
          noteControl={
            <AddNotes btnType="white" project={data} isAdmin={isAdmin} className={styles.control} />
          }
          canCreateMultipleDeals={canCreateMultipleDeals}
        />
      }
    </div>
  );
};

export default AdvertiserProjectActions;

import React, { FC } from 'react';
import Drawer from 'Components/ui/Drawer/Drawer';
import { CreatorImportRowError } from 'GraphTypes/OutreachBatch_outreachBatch.graphql';
import Accordion from 'Components/ui/Accordion/Accordion';
import Text from 'Components/ui/Text/Text';
import styles from './ErrorsDrawer.pcss';

type ErrorType = { email: string; error: CreatorImportRowError; username: string };

interface Props {
  batchId: string;
  errors: Array<ErrorType>;
}

const ErrorsDrawer: FC<Props> = ({ batchId, errors }) => {
  const emailErrors: Array<ErrorType> = [];
  const invalidUsernameErrors: Array<ErrorType> = [];
  const apiErrors: Array<ErrorType> = [];
  const unknownErrors: Array<ErrorType> = [];
  errors.forEach((error) => {
    if (error.error === 'INVALID_EMAIL') {
      emailErrors.push(error);
      return;
    }
    if (error.error === 'INVALID_USERNAME') {
      invalidUsernameErrors.push(error);
      return;
    }
    if (error.error === 'SOCIAL_NETWORK_API_ERROR') {
      apiErrors.push(error);
      return;
    }
    if (error.error === 'UNKNOWN_ERROR') {
      unknownErrors.push(error);
      return;
    }
  });
  return (
    <Drawer rootKey={`invite-outreach-errors-${batchId}`} className={styles.root}>
      <Text msg={'campaign.outreach.errors'} type={'d2'} />
      {emailErrors.length > 0 && (
        <Accordion
          items={[
            {
              content: (
                <>
                  {emailErrors.map((error) => (
                    <Text text={error.email} type="md" className={styles.error} />
                  ))}
                </>
              ),
              openedByDefault: true,
              title: <Text type="md" msg="campaign.outreach.errors.email" />,
            },
          ]}
        />
      )}
      {invalidUsernameErrors.length > 0 && (
        <Accordion
          items={[
            {
              content: (
                <>
                  {invalidUsernameErrors.map((error) => (
                    <Text text={error.username} type="md" className={styles.error} />
                  ))}
                </>
              ),
              title: <Text type="md" msg="campaign.outreach.errors.username" />,
            },
          ]}
        />
      )}
      {apiErrors.length > 0 && (
        <Accordion
          items={[
            {
              content: (
                <>
                  {apiErrors.map((error) => (
                    <Text text={error.email} type="md" className={styles.error} />
                  ))}
                </>
              ),
              title: <Text type="md" msg="campaign.outreach.errors.api" />,
            },
          ]}
        />
      )}
      {unknownErrors.length > 0 && (
        <Accordion
          items={[
            {
              content: (
                <>
                  {unknownErrors.map((error) => (
                    <Text text={error.email} type="md" className={styles.error} />
                  ))}
                </>
              ),
              title: <Text type="md" msg="campaign.outreach.errors.unknown" />,
            },
          ]}
        />
      )}
    </Drawer>
  );
};

export default ErrorsDrawer;

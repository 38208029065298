import React from 'react';

import styles from './MessageVideo.pcss';

import { getMediaSize } from 'Util/mediaSize';
import Spinner from 'Atoms/Spinner/Spinner';
import { PROCESSING } from 'Constants/general';
import { MessageMedia_file$data } from 'GraphTypes/MessageMedia_file.graphql';

const MAX_WIDTH_SIZE = 290;
const MAX_HEIGHT_SIZE = 500;

interface Props {
  hasActions?: boolean;
  file: MessageMedia_file$data;
}

const MessageVideo: React.FC<Props> = (props) => {
  const { file, hasActions } = props;
  const secureUrl = file.transformations?.autoUrl;
  const poster = file.thumbnailUrl;
  const processingStatus = file.processingStatus;

  const imgStyle = getMediaSize(file, MAX_WIDTH_SIZE, MAX_HEIGHT_SIZE);

  if (processingStatus === PROCESSING) {
    return (
      <div className={styles.stub}>
        <div
          style={{ ...imgStyle.style, backgroundImage: `url(${poster}` }}
          className={styles.posterStub}
        />
        <div className={styles.preloaderWrap}>
          <Spinner color="#ffffff" className={styles.preloader} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <video
        src={secureUrl}
        poster={poster}
        controls={true}
        className={styles.video}
        controlsList="nodownload"
        disablePictureInPicture
        style={imgStyle.style}
        onContextMenu={hasActions ? (e) => e.preventDefault() : undefined}
      />
    </div>
  );
};

export default MessageVideo;

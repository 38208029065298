import React, { Component } from 'react';

import HashtagListByQuery from './HashtagListByQuery';

import Select from 'Components/Field/Select';

class HashtagsSuggestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textQuery: '',
      options: [],
      chips: [],
      values: [],
    };

    this.setValues = this.setValues.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onInputChange(textQuery) {
    const query = textQuery.replace(/#/g, '');
    this.setState({ textQuery: query });
  }

  setValues(values) {
    const options = values.map((value) => ({
      value: value.name,
      label: `#${value.name}`,
      type: 'tags',
    }));

    this.setState({ options });
  }

  onChange(data) {
    const items = data ? data : [];
    const hashtags = items.map((item) => {
      return item.value;
    });
    const { onChange } = this.props;
    onChange({ hashtags });
  }

  createDefaultValues() {
    const { hashtags = [] } = this.props;

    return hashtags.map((item) => {
      const label = item && item.charAt(0) === '#' ? item : `#${item}`;

      return {
        value: item,
        label,
      };
    });
  }

  render() {
    const { options, textQuery } = this.state;

    const { className, disabled } = this.props;

    const defaultValue = this.createDefaultValues();

    return (
      <div className={className}>
        <HashtagListByQuery textQuery={textQuery} setValues={this.setValues} />
        <Select
          disabled={disabled}
          isMulti={true}
          onChange={this.onChange}
          options={options}
          defaultValue={defaultValue}
          isIndicatorHidden={true}
          placeholder="browse_creators.hashtags_placeholder"
          onInputChange={this.onInputChange}
        />
      </div>
    );
  }
}

export default HashtagsSuggestion;

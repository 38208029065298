import { StatItem, createDateLabel } from '../Chart/utils';

import type { ViewCountersChartQueryType } from './ViewCountersChart.Query';

import type { Performance_Granularity } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';

type CharDataType = {
  stats: StatItem[];
  lines: {
    viewCounters: number;
    date: string;
  }[];
};
export const chartData = (
  response: ViewCountersChartQueryType['response'],
  granularity: Performance_Granularity
): CharDataType => {
  const stats = [
    {
      amount: response.performance?.viewCounters?.totalViewsCount || 0,
      key: 'viewCounters',
      description: {
        titleMsg: 'performance.dashboard.chart.view_counters.views_count.title',
        tooltipMsg: 'performance.dashboard.chart.view_counters.views_count.tooltip',
      },
    },
  ];

  const edges = response?.performance?.viewCounters?.edges || [];
  const lines: {
    viewCounters: number;
    date: string;
  }[] = [];

  edges.forEach((item) => {
    if (!item?.node) return;
    lines.push({
      viewCounters: item.node.viewsCount,
      date:
        createDateLabel(item.node.dateFrom as string, item.node.dateTo as string, granularity) ||
        '',
    });
  });

  return { stats, lines };
};

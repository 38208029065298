import React, { PropsWithChildren } from 'react';

import styles from './Section.css';

import Text from 'Atoms/Text/Text';

interface Props {
  className?: string;
  titleMsg: string;
  titleValues?: {};
  titleClassName?: string;
}

const Section: React.FC<PropsWithChildren<Props>> = (props) => {
  const { titleMsg, titleValues, titleClassName, className, children } = props;

  return (
    <div className={`${styles.root} ${className}`}>
      <Text
        tag="h1"
        weight="700"
        msg={titleMsg}
        values={titleValues}
        className={`${styles.title} ${titleClassName}`}
      />
      {children}
    </div>
  );
};

export default Section;

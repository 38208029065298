import React, { useContext, useState } from 'react';
import { useTracking } from 'react-tracking';

import { FinishDealWrapperContext } from '../FinishDealWrapperContext';

import styles from './FinishDealOptions.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { USD } from 'Constants/general';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import Toggle from 'Components/ui/Toggle/Toggle';
import ErrorHandler from 'Util/errorHandler';
import { CampaignType } from 'GraphTypes/AcceptOfferMutation.graphql';
import { useProjectActions } from 'Hooks/useProjectActions';

interface Props {
  campaignType?: CampaignType | null;
  projectId: string;
  isFreeOrganic?: boolean;
}

const FinishDealOptions: React.FC<Props> = (props) => {
  const { projectId, campaignType, isFreeOrganic } = props;

  const { openDrawer } = useContext(DrawerContext);
  const finishDealContext = useContext(FinishDealWrapperContext);

  const { completeProject } = useProjectActions();

  const selectedFiles = finishDealContext?.selectedFiles || {};
  const opened = Boolean(finishDealContext?.completeProjectDrawer);
  const changeDrawerVisible = finishDealContext?.setCompleteProjectDrawerStatus;

  const completeProjectWithoutMedia = Boolean(finishDealContext?.completeProjectWithoutFiles);

  const tracking = useTracking();

  const [noMoreContentApprove, setContentApproveStatus] = useState(false);
  const [agreeToUseContent, agreeToUseContentStatus] = useState(true);

  const handleNoMoreContentToggle = (value: boolean) => {
    setContentApproveStatus(value);
  };

  const handleAgreeToUseContentToggle = (value: boolean) => {
    agreeToUseContentStatus(value);
  };

  const handleClose = () => {
    if (!changeDrawerVisible) {
      ErrorHandler.error('No handler for manual closing selection drawer');
      return;
    }
    changeDrawerVisible(false);
  };

  const handleCompleteDone = () => {
    openDrawer(`project-creator-rating-${projectId}`);
  };

  const handleCompleteProjectButton = () => {
    if (!changeDrawerVisible) {
      ErrorHandler.error('No handler for auto closing selection drawer after complete deal');
      return;
    }
    changeDrawerVisible(false);
    tracking.trackEvent({
      element: completeProjectWithoutMedia
        ? 'complete_project_without_publication'
        : 'complete_project',
      event: 'done',
      payload: {
        value: {
          withPost: false,
          currency: USD,
        },
      },
    });
    const files = Object.keys(selectedFiles);

    completeProject({
      projectId,
      showcasingPermitted: agreeToUseContent,
      fileIds: completeProjectWithoutMedia ? [] : files,
      callback: handleCompleteDone,
    });
  };

  const productSeedingPrefix =
    campaignType === 'PRODUCT_SEEDING' || isFreeOrganic ? 'product_seeding.' : '';

  return (
    <Drawer
      opened={opened}
      onClose={handleClose}
      needCloseButton
      className={styles.root}
      rootKey={`finish-deal-options-${projectId}`}
    >
      <div className={styles.content}>
        <Text type="d2" msg={`finish_deal_options_drawer.${productSeedingPrefix}title`} />
        <Text type="md" msg={`finish_deal_options_drawer.${productSeedingPrefix}description`} />
        <div className={styles.checkContainer}>
          <Text type="md" msg="finish_deal_options_drawer.option.no_more_content" />
          <Toggle bgColor="sand" on={noMoreContentApprove} onChange={handleNoMoreContentToggle} />
        </div>
        <div className={styles.checkContainer}>
          <Text type="md" msg="finish_deal_options_drawer.option.use_content" />
          <Toggle bgColor="sand" on={agreeToUseContent} onChange={handleAgreeToUseContentToggle} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          color="black"
          fluid
          disabled={!noMoreContentApprove}
          className={styles.mainBtn}
          msg={`finish_deal_options_drawer.${productSeedingPrefix}button`}
          onClick={handleCompleteProjectButton}
        />
      </div>
    </Drawer>
  );
};

export default FinishDealOptions;

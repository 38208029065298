/**
 * @generated SignedSource<<b07ee68c1edb013cf87d9a730a821706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Billing_AccountType = "CARD" | "WALLET" | "%future added value";
export type Billing_TransactionStatus = "CANCELED" | "ERROR" | "PAID" | "RESERVED" | "%future added value";
export type Billing_TransactionType = "CREATOR_EXPENSE" | "DEPOSIT" | "DEPOSIT_REFUND" | "EXTRA_PAYOUT" | "EXTRA_PAYOUT_REFUND" | "FULLY_MANAGED_ZEROING" | "REFERRAL_REWARD" | "REIMBURSEMENT" | "REIMBURSEMENT_REFUND" | "RESERVATION" | "SALES_PEAK_REWARD" | "SUBSCRIPTION" | "SUBSCRIPTION_REFUND" | "TRANSACTION_FEE" | "%future added value";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TransactionItem_transaction$data = {
  readonly accountType: Billing_AccountType;
  readonly amount: number;
  readonly brand: {
    readonly id: string;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly campaign: {
    readonly id: string;
    readonly name: string | null | undefined;
    readonly stage: CampaignStage;
  } | null | undefined;
  readonly cardBrand: string | null | undefined;
  readonly createdAt: any;
  readonly creator: {
    readonly id: string;
    readonly username: string;
  } | null | undefined;
  readonly currency: Currency;
  readonly errorMessage: string | null | undefined;
  readonly last4: string | null | undefined;
  readonly project: {
    readonly id: string;
  } | null | undefined;
  readonly status: Billing_TransactionStatus | null | undefined;
  readonly type: Billing_TransactionType;
  readonly " $fragmentType": "TransactionItem_transaction";
};
export type TransactionItem_transaction$key = {
  readonly " $data"?: TransactionItem_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionItem_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionItem_transaction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardBrand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last4",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    }
  ],
  "type": "Billing_Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "1aca80ae4508ede124e7020dd91bb3b5";

export default node;

import React, { useContext } from 'react';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  className?: string;
}

const InviteOutreachCreators: React.FC<Props> = (props) => {
  const { className } = props;
  const { openDrawer } = useContext(DrawerContext);

  const handleInviteClick = () => {
    amplitude.sendEvent<409>({
      id: '409',
      category: 'campaign',
      name: 'outreach_button_click',
      param: undefined,
    });
    openDrawer('invite-outreach-creators');
  };

  return (
    <div className={className}>
      <AlterButton msg="campaign.outreach.invite" type="black" onClick={handleInviteClick} />
    </div>
  );
};

export default InviteOutreachCreators;

import { createContext, Context } from 'react';

import type {
  GuideTourId,
  GuideTourStepName,
  GetTooltipComponent,
  GoToStep,
  IsTooltipVisible,
} from './types';

type BaseStepNameMap<IDs extends GuideTourId> = {
  [K in IDs]: GuideTourStepName;
};

type GuideTourContextType<
  IDs extends GuideTourId = GuideTourId,
  StepNamesMap extends BaseStepNameMap<IDs> = BaseStepNameMap<IDs>
> = {
  /** @desc Returns true if all steps completed */
  isGuideTourFinished: (guideTourId: IDs) => boolean;

  /** @desc Returns true if guide tour with ID exists */
  isGuideTourExists: (guideTourId: IDs) => boolean;

  /** @desc Returns true if guide tour finished or doesn't exist */
  isGuideTourFinishedOrDoesntExist: (guideTourId: IDs) => boolean;

  /** @desc Returns tooltip react component to using in react templates */
  getTooltipComponent: GetTooltipComponent<IDs, StepNamesMap>;

  /** @desc Go to step by its name */
  goToStep: GoToStep<IDs, StepNamesMap>;

  /** @desc Show first available step which was not completed */
  goToNextStep: (guideTourId: IDs) => boolean;

  /** @desc The same as goToNextStep but reverse */
  goToPrevStep: (guideTourId: IDs) => boolean;

  /** @desc Returns true if at lease one guide tour tooltip visible */
  isGuideTourRunning: (guideTourId: IDs) => boolean;

  /** @desc Reset guide tour to initial value (even if a user has completed guide tour) */
  resetGuideTour: (guideTourId: IDs) => void;

  /** @desc External method for change current user id directly */
  setUserId: (userId: string) => void;

  /** @desc Returns true is tooltip visible at the time */
  isTooltipVisible: IsTooltipVisible<IDs, StepNamesMap>;
};

type CreateGuideTourContext = <
  IDs extends GuideTourId,
  StepNamesMap extends BaseStepNameMap<IDs>
>() => Context<GuideTourContextType<IDs, StepNamesMap>>;

const createGuideTourContext: CreateGuideTourContext = () => {
  return createContext<GuideTourContextType>({} as GuideTourContextType);
};

export { createGuideTourContext };
export type { GuideTourContextType };

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  UpdateOrganizationInput,
  UpdateOrganizationMutation$data,
  UpdateOrganizationMutation,
} from 'GraphTypes/UpdateOrganizationMutation.graphql';

const mutation = graphql`
  mutation UpdateOrganizationMutation($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        test
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateOrganizationInput,
  resolve?: (response: UpdateOrganizationMutation$data) => void,
  reject?: fnType
) => {
  const { id, test } = data;

  const variables = {
    input: data,
  };
  commitMutation<UpdateOrganizationMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const organization = store.get(id);
      organization?.setValue(test, 'test');
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

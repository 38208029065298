import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { amplitude } from 'Helpers/amplitude';

import styles from './NewCampaignButton.pcss';
import NewCampaignType from './NewCampaignType/NewCampaignType';

import { LONG_TERM } from 'Constants/general';
import { useCampaignActions } from 'Hooks/useCampaignActions';
import AlterButton, { ButtonType } from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';

type Props = {
  colorType?: ButtonType;
  showIcon?: boolean;
  className?: string;
  canCreateLongTermCampaigns?: boolean;
};

const NewCampaignButton: React.FC<Props> = (props) => {
  const { className, showIcon = true, colorType = 'black', canCreateLongTermCampaigns } = props;

  const intl = useIntl();

  const { createNewCampaign } = useCampaignActions();

  const [newCampaignTypeVisible, setNewCampaignTypeVisible] = useState(false);

  const handleButtonClick = useCallback(() => {
    amplitude.sendEvent({
      id: 254,
      category: 'campaign',
      name: 'new_campaign_click',
    });
    if (canCreateLongTermCampaigns) {
      setNewCampaignTypeVisible(true);
    } else {
      handleNewCampaignClick();
    }
  }, []);

  const closeCampaignTypedModal = useCallback(() => {
    setNewCampaignTypeVisible(false);
  }, []);

  const handleNewCampaignClick = useCallback(() => {
    createNewCampaign();
  }, []);

  const handleNewLongTermCampaignClick = useCallback(() => {
    const description = intl.formatMessage({
      id: 'brief_template_for_long_term_campaign.description.example',
    });
    createNewCampaign({ campaignData: { type: LONG_TERM, description } });
  }, []);

  return (
    <>
      <AlterButton
        icon={showIcon ? 'Add-plus-circle' : undefined}
        className={className}
        onClick={handleButtonClick}
        type={colorType}
        msg="dashboard.advertiser.new_campaign"
      />
      <Drawer
        opened={newCampaignTypeVisible}
        onClose={closeCampaignTypedModal}
        rootKey={'new-campaign-type'}
        className={styles.drawer}
      >
        <NewCampaignType
          onNewCampaignClick={handleNewCampaignClick}
          onNewLongTermCampaignClick={handleNewLongTermCampaignClick}
        />
      </Drawer>
    </>
  );
};

export { NewCampaignButton };
export default NewCampaignButton;

import React from 'react';

import styles from './RatingInfo.css';

import { createCalendarTime } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import RatingStars from 'Molecules/RatingStars/RatingStars';
import { CreatorCharacteristic } from 'GraphTypes/CreatorReviewItem_ratingVote.graphql';

interface Props {
  score?: number;
  createdAt?: unknown | string;
  characteristics?: readonly CreatorCharacteristic[] | null;
  comment?: string | null;
  needComment?: boolean;
  defaultStarsColor?: string;
}

const RatingInfo: React.FC<Props> = (props) => {
  const { score, characteristics, comment, createdAt, needComment, defaultStarsColor } = props;

  const stubText = needComment && !comment && (!characteristics || characteristics.length === 0);

  return (
    <div>
      <header className={styles.header}>
        <>
          <RatingStars score={score} defaultColor={defaultStarsColor} />
          {createdAt && (
            <Text color="grayDog" text={createCalendarTime(createdAt)} className={styles.date} />
          )}
        </>
      </header>
      {characteristics && (
        <div className={styles.characteristics}>
          {characteristics.map((item) => {
            return (
              <div key={item} className={styles.characteristicItem}>
                <Text size="sm" msg={`rating.characteristic.${item.toLowerCase()}`} />
              </div>
            );
          })}
        </div>
      )}
      {comment && <Text text={comment} className={styles.comment} />}
      {stubText && <Text color="grayDog" msg="creator_statistic.no_review" />}
    </div>
  );
};

export default RatingInfo;

import { commitMutation, graphql } from 'react-relay';
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes';
import { ConnectionHandler } from 'relay-runtime';

import {
  DeleteCustomListMutation,
  DeleteCustomListMutation$data,
  DeleteCustomListInput,
  DeleteCustomListMutation$variables,
} from 'GraphTypes/DeleteCustomListMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation DeleteCustomListMutation($input: DeleteCustomListInput!) {
    deleteCustomList(input: $input) {
      __typename
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: DeleteCustomListInput,
  resolve?: (response: DeleteCustomListMutation$data) => void,
  reject?: FnType
) => {
  const customListId = data.id;
  const variables: DeleteCustomListMutation$variables = {
    input: {
      ...data,
    },
  };

  const updater: SelectorStoreUpdater<DeleteCustomListMutation$data> = (store) => {
    const currentUserCustomLists = store
      .getRoot()
      ?.getLinkedRecord('currentUser')
      ?.getLinkedRecord('organization')
      ?.getLinkedRecord('customLists');

    if (currentUserCustomLists) {
      const edges = currentUserCustomLists
        ?.getLinkedRecords('edges')
        ?.filter((item) => item?.getLinkedRecord('node')?.getValue('id') !== customListId);
      currentUserCustomLists?.setLinkedRecords(edges, 'edges');
    }

    const customList = store.get(customListId);
    if (customList) {
      store.delete(customListId);
    }
  };

  commitMutation<DeleteCustomListMutation>(environment, {
    mutation,
    variables,
    updater,
    optimisticUpdater: updater,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

// types

export type {
  DeleteCustomListMutation,
  DeleteCustomListMutation$data,
  DeleteCustomListInput,
  DeleteCustomListMutation$variables,
};

import { useState } from 'react';
import { getCookie } from 'typescript-cookie';
import { useNavigate } from 'react-router-dom';

import ErrorHandler from 'Util/errorHandler';

type HookResult = {
  detect: () => void;
  setRefParameterToUrlIfPossible: () => void;
  affiliateReferralCode: string;
};
type UseTapfiliate = () => HookResult;
const useTapfiliate: UseTapfiliate = () => {
  const navigate = useNavigate();
  const [affiliateReferralCode, setAffiliateReferralCode] = useState<string>('');

  const detect: HookResult['detect'] = () => {
    if (window?.tap?.detect) {
      window.tap.detect(
        { always_callback: true },
        (err: string, res: { id: string; vid: string }) => {
          if (res?.id) {
            setAffiliateReferralCode(res.id);
          }
        }
      );
    }
  };

  const setRefParameterToUrlIfPossible: HookResult['setRefParameterToUrlIfPossible'] = () => {
    const currentHref = new URL(window.location.href);
    const refCode = getCookie('t_ref');
    if (refCode && !currentHref.searchParams.has('ref')) {
      try {
        currentHref.searchParams.set('ref', refCode);
        navigate(`${currentHref.pathname}?${currentHref.searchParams.toString()}`);
      } catch (error) {
        ErrorHandler.error('Failed to create ref link', { error, refCode, currentHref });
      }
    }
  };

  return {
    detect,
    setRefParameterToUrlIfPossible,
    affiliateReferralCode,
  };
};

export default useTapfiliate;
export { useTapfiliate };

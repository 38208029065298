/**
 * @generated SignedSource<<79992e750d6d6e7a7b1713e46f75e481>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveCreatorFromFavoritesInput = {
  clientMutationId?: string | null | undefined;
  creatorId: string;
  id?: string | null | undefined;
  organizationId: string;
};
export type RemoveCreatorFromFavoritesMutation$variables = {
  input: RemoveCreatorFromFavoritesInput;
};
export type RemoveCreatorFromFavoritesMutation$data = {
  readonly removeCreatorFromFavorites: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type RemoveCreatorFromFavoritesMutation = {
  response: RemoveCreatorFromFavoritesMutation$data;
  variables: RemoveCreatorFromFavoritesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveCreatorFromFavoritesPayload",
    "kind": "LinkedField",
    "name": "removeCreatorFromFavorites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveCreatorFromFavoritesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveCreatorFromFavoritesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "096c72aadb952bcaabedaa0500ccc686",
    "id": null,
    "metadata": {},
    "name": "RemoveCreatorFromFavoritesMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveCreatorFromFavoritesMutation(\n  $input: RemoveCreatorFromFavoritesInput!\n) {\n  removeCreatorFromFavorites(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf27a07ac9970eb1347043b6c712a212";

export default node;

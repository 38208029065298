import { graphql, useMutation } from 'react-relay';

import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';
import { ResumeSubscriptionMutation as MutationType } from 'GraphTypes/ResumeSubscriptionMutation.graphql';

const ResumeSubscriptionMutation = graphql`
  mutation ResumeSubscriptionMutation($input: ResumeSubscriptionInput!) {
    resumeSubscription(input: $input) {
      ... on ResumeSubscriptionPayload {
        advertiserSubscription {
          paused
        }
      }
    }
  }
`;

export const useResumeSubscriptionMutation: BaseMutation<MutationType> = (config) => {
  const [resumeSubscription, loading] = useMutation<MutationType>(ResumeSubscriptionMutation);

  const resumeSubscriptionCaller: BaseMutationExecFn<MutationType> = (execConfig) => {
    resumeSubscription({
      ...config,
      ...execConfig,
    });
  };
  return [resumeSubscriptionCaller, loading];
};

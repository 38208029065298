/**
 * @generated SignedSource<<74c37d6b73fd136e95369073d790d322>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type UploadMediaplanQuery$variables = {
  archived?: boolean | null | undefined;
  campaignId: string;
  createdByAdmin?: boolean | null | undefined;
};
export type UploadMediaplanQuery$data = {
  readonly campaign: {
    readonly brief: {
      readonly paidSocial?: boolean;
      readonly paidSocialBca?: boolean;
      readonly sparkAds?: boolean;
    } | null | undefined;
    readonly creatorImport: {
      readonly completed: boolean;
      readonly id: string;
      readonly importedCreators: number;
      readonly totalCreators: number;
      readonly " $fragmentSpreads": FragmentRefs<"CreatorImportProgress_creatorImport">;
    } | null | undefined;
    readonly id: string;
    readonly platform: CampaignPlatform | null | undefined;
    readonly projects: {
      readonly totalCount: number;
    };
    readonly type: CampaignType | null | undefined;
    readonly unsentBriefs: {
      readonly totalCount: number;
    };
  } | null | undefined;
  readonly currentUser: {
    readonly organization: {
      readonly subscription: {
        readonly canImportMediaplan: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UploadMediaplanQuery = {
  response: UploadMediaplanQuery$data;
  variables: UploadMediaplanQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdByAdmin"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocialBca",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sparkAds",
      "storageKey": null
    }
  ],
  "type": "V2Brief",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importedCreators",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCreators",
  "storageKey": null
},
v11 = {
  "kind": "Variable",
  "name": "createdByAdmin",
  "variableName": "createdByAdmin"
},
v12 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "LAST_ADDED"
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "archived",
      "variableName": "archived"
    },
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": "unsentBriefs",
  "args": [
    {
      "kind": "Literal",
      "name": "archived",
      "value": false
    },
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "UNSENT"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canImportMediaplan",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadMediaplanQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorImport",
            "kind": "LinkedField",
            "name": "creatorImport",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreatorImportProgress_creatorImport"
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadMediaplanQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorImport",
            "kind": "LinkedField",
            "name": "creatorImport",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7692c2ddebdffbf95403e869aa45a46a",
    "id": null,
    "metadata": {},
    "name": "UploadMediaplanQuery",
    "operationKind": "query",
    "text": "query UploadMediaplanQuery(\n  $campaignId: ID!\n  $archived: Boolean\n  $createdByAdmin: Boolean\n) {\n  campaign(id: $campaignId) {\n    id\n    type\n    platform\n    brief {\n      __typename\n      ... on V2Brief {\n        paidSocialBca\n        paidSocial\n        sparkAds\n      }\n      id\n    }\n    creatorImport {\n      completed\n      id\n      importedCreators\n      totalCreators\n      ...CreatorImportProgress_creatorImport\n    }\n    projects(archived: $archived, orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {\n      totalCount\n    }\n    unsentBriefs: projects(archived: false, stages: [UNSENT], orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {\n      totalCount\n    }\n  }\n  currentUser {\n    organization {\n      subscription {\n        canImportMediaplan\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment CreatorImportProgress_creatorImport on CreatorImport {\n  completed\n  id\n  importedCreators\n  totalCreators\n}\n"
  }
};
})();

(node as any).hash = "bcd2fe2ce4187fbf3496a5e3219cd3d3";

export default node;

import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './MetaBanner.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import { amplitude } from 'Helpers/amplitude';
import { togglePreloader } from 'Actions/UI.Actions';
import ls from 'Util/localStorage';
import Text from 'Components/ui/Text/Text';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import metaBannerImg from 'Images/general/metaBanner.png';
import metaBanner2xImg from 'Images/general/metaBanner2x.png';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { CampaignUseCase } from 'GraphTypes/AdvertiserDashboardQuery.graphql';
import { CreateCampaignInput } from 'GraphTypes/CreateCampaignMutation.graphql';

const META_BANNER_FLAG = 'try_meta_banner';

interface Props {
  availableCampaignUseCases?: readonly CampaignUseCase[] | null;
}

const MetaBanner: React.FC<Props> = (props) => {
  const { availableCampaignUseCases } = props;
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const newUseCases = hasFeatureFlag('use_cases_5389');

  const { createNewCampaign } = useCampaignActions();

  const dispatch = useDispatch();

  const bannerVisibleDeafult = !ls.get(META_BANNER_FLAG);

  const [bannerVisible, setBannerVisible] = useState(bannerVisibleDeafult);

  useEffect(() => {
    if (bannerVisibleDeafult) {
      amplitude.sendEvent<406>({
        id: '406',
        category: 'metawl_banner',
        name: 'show',
        param: undefined,
      });
    }
  }, []);

  const campaignData: CreateCampaignInput = newUseCases
    ? {
        type: 'INFLUENCER',
        postingType: 'CREATOR_ADS',
        useCase: 'META_PARTNERSHIP_ADS',
        objective: 'REACH',
      }
    : { type: 'INFLUENCER', postingType: 'CREATOR_ADS' };

  const handleWarningClose = () => {
    amplitude.sendEvent<408>({
      id: '408',
      category: 'metawl_banner',
      name: 'close',
      param: undefined,
    });
    setBannerVisible(false);
    ls.set(META_BANNER_FLAG, true);
  };

  const handleTryClick = () => {
    amplitude.sendEvent<407>({
      id: '407',
      category: 'metawl_banner',
      name: 'click',
      param: undefined,
    });

    createNewCampaign({
      campaignData: {
        ...campaignData,
        useCase:
          campaignData.useCase && availableCampaignUseCases?.includes(campaignData.useCase)
            ? campaignData.useCase
            : undefined,
      },
    });
  };

  if (!bannerVisible) return null;

  return (
    <div className={styles.root}>
      <img srcSet={`${metaBannerImg} 1x, ${metaBanner2xImg} 2x`} className={styles.img} />
      <Text type="md" msg="campaigns.try_meta.title" />
      <ButtonPreset className={styles.controls}>
        <AlterButton
          type="white"
          msg="campaigns.try_meta.try"
          className={styles.control}
          onClick={handleTryClick}
        />
        <AlterButton
          type="white"
          icon="Close-small"
          className={styles.closeWraning}
          onClick={handleWarningClose}
        />
      </ButtonPreset>
    </div>
  );
};

export default MetaBanner;

/**
 * @generated SignedSource<<1a0be1147038d596efe269d00f3d7059>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorPrice_project$data = {
  readonly currency: Currency | null | undefined;
  readonly id: string;
  readonly price: number | null | undefined;
  readonly " $fragmentType": "CreatorPrice_project";
};
export type CreatorPrice_project$key = {
  readonly " $data"?: CreatorPrice_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorPrice_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorPrice_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "2ffb9580a7467865a40512e50b6902b5";

export default node;

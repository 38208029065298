import React from 'react';
import classnames from 'classnames';

import styles from './ScreeningQuestionAnswers.pcss';

import Text from 'Components/ui/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';

const LIMIT = 96;

interface Props {
  className?: string;
  answers: {
    id: string;
    text: string;
    booleanAnswer?: boolean | null;
    preferred?: boolean | null;
  }[];
}

const ScreeningQuestionAnswers: React.FC<Props> = (props) => {
  const { className, answers } = props;

  const limitForText = 96 / answers.length;

  return (
    <div className={classnames(styles.root, className)}>
      {answers.map((item) => {
        const { id, text, booleanAnswer, preferred } = item;
        const style = preferred ? styles.rightAnswer : styles.wrongAnswer;
        const answerValue = booleanAnswer ? 'creator_card.answer.yes' : 'creator_card.answer.no';

        const isLimited = text.length > limitForText;
        const finalText = isLimited ? text.slice(0, limitForText - 1) + '..?' : text;

        return (
          <Tooltip
            key={id}
            id={isLimited ? id : undefined}
            tooltipText={isLimited ? text : undefined}
            place="top"
            tooltipClassName={styles.tootlipContent}
          >
            <div key={id} className={classnames(styles.answer, style)}>
              <Text type="label" text={finalText} />
              <Text type="label" msg={answerValue} className={styles.answerValue} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ScreeningQuestionAnswers;

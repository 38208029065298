import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AuthenticateWithShopifyMutation$data,
  AuthenticateWithShopifyInput,
  AuthenticateWithShopifyMutation,
} from 'GraphTypes/AuthenticateWithShopifyMutation.graphql';

const mutation = graphql`
  mutation AuthenticateWithShopifyMutation($input: AuthenticateWithShopifyInput!) {
    authenticateWithShopify(input: $input) {
      __typename
      ... on AuthenticateWithShopifyPayload {
        shopifyAuthorization {
          id
          reauthenticationRequired
        }
      }
      ... on AuthenticateWithShopify_InvalidScopeError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        message
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: AuthenticateWithShopifyInput,
  resolve?: (response: AuthenticateWithShopifyMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { code, error, hmac, shopName, state, timestamp, scope, host } = data;

  const variables = {
    input: {
      id,
      code,
      error,
      hmac,
      scope,
      shopName,
      state,
      timestamp,
      host,
    },
  };

  commitMutation<AuthenticateWithShopifyMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const user = store.getRoot()?.getLinkedRecord('currentUser');
      const shopify = store.get(id);
      if (!user || !shopify) return;
      user.setLinkedRecord(shopify, 'socialIdentity(socialNetwork:"SHOPIFY")');
    },
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

// types

export type {
  AuthenticateWithShopifyMutation$data,
  AuthenticateWithShopifyInput,
  AuthenticateWithShopifyMutation,
};

import React, { useContext, useMemo, useCallback } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import BriefProvider, { BriefContext } from '../Brief.Context';
import BrandDetails from '../BriefTemplate/BriefTemplateContent/CampaignDetails/BrandDetails/BrandDetails';
import BriefHeader from '../BriefTemplate/BriefHeader/BriefHeader';
import Field from '../BriefTemplate/BriefTemplateContent/components/Field/Field';
import MediaplanDetails from '../BriefTemplate/BriefTemplateContent/CreatorsDetails/MediaplanDetails/MediaplanDetails';

import styles from './BriefForLongTermCampaign.pcss';

import { getCampaignCreatorsLink } from 'Util/links';
import activateCampaign from 'Mutations/ActivateCampaign.Mutation';
import updateCampaign from 'Mutations/UpdateCampaign.Mutation';
import { BriefForLongTermCampaignQuery as QueryType } from 'GraphTypes/BriefForLongTermCampaignQuery.graphql';
import Text from 'Components/ui/Text/Text';

const BriefForLongTermCampaignQuery = graphql`
  query BriefForLongTermCampaignQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      name
      description
      brand {
        id
      }
      brief {
        id
      }
      ...BrandDetails_campaign
      ...MediaplanDetails_campaign
      ...BriefHeader_campaign
      organization {
        id
      }
    }
    currentUser {
      admin
      organization {
        id
        subscription {
          planId
          effectiveLimits {
            maxActiveBrands
          }
        }
        counters {
          activeBrands
        }
      }
    }
  }
`;

interface Props {
  campaignId: string;
}

const BriefForLongTermCampaign: React.FC<Props> = (props) => {
  const { campaignId } = props;

  const navigate = useNavigate();
  const [, briefDispatch] = useContext(BriefContext);

  const data = useLazyLoadQuery<QueryType>(BriefForLongTermCampaignQuery, { campaignId });

  const currentUser = data.currentUser;
  const isAdmin = currentUser?.admin;
  const currentOrganizationId = currentUser?.organization?.id;
  const subscriptionData = currentUser?.organization?.subscription;
  const maxActiveBrands = subscriptionData?.effectiveLimits.maxActiveBrands;
  const planId = subscriptionData?.planId;

  const campaign = data.campaign;
  const organizationId = campaign?.organization?.id;
  const campaignName = campaign?.name;
  const brandId = campaign?.brand?.id;
  const briefId = campaign?.brief?.id;
  const description = campaign?.description;

  const isOwner = organizationId === currentOrganizationId;

  if (!data || !campaign) return null;

  const subscription = {
    maxActiveBrands: maxActiveBrands ?? null,
  };

  const counters = {
    activeBrands: currentUser?.organization?.counters?.activeBrands ?? null,
  };

  const handleCampaignNameChange = useCallback(
    (data: { name: string }) => {
      briefDispatch({ key: 'briefIsSaving', value: true });
      updateCampaign({ id: campaignId, ...data });
    },
    [campaignId]
  );

  const handleDescriptionChange = useCallback(
    (data: { description: string }) => {
      briefDispatch({ key: 'briefIsSaving', value: true });
      updateCampaign({ id: campaignId, ...data });
    },
    [campaignId]
  );

  const canPublish = useMemo(() => {
    return !!(campaignName && description && brandId);
  }, [campaignName, description, brandId]);

  const handleCampaignActivationDone = () => {
    const link = getCampaignCreatorsLink({ campaignId });
    navigate(link);
  };

  const handleSubmitCampaignData = useCallback(() => {
    activateCampaign({ campaignId }, handleCampaignActivationDone);
  }, [campaignId]);

  return (
    <BriefProvider
      planId={planId ?? null}
      counters={counters}
      subscription={subscription}
      campaignType={null}
    >
      <BriefHeader
        briefId={briefId}
        campaignId={campaignId}
        campaign={campaign}
        planId={planId}
        sendBtnMsg="brief_template_for_long_term_campaign.publish"
        canSendToReview={canPublish}
        submitCallback={handleSubmitCampaignData}
      />
      <div className={styles.root}>
        <div className={styles.content}>
          <Text
            type="d1"
            msg="brief_template_for_long_term_campaign.title"
            className={styles.title}
          />
          <BrandDetails campaign={campaign} isOwner={isOwner} isAdmin={!!isAdmin} />
          <Field
            title="brief_template.field.campaign_name.title"
            description="brief_template.field.campaign_name.descr"
            name="name"
            element="input"
            elementData={{
              maxlength: 50,
              defaultValue: campaignName,
              placeholderMsg: 'brief_template.field.campaign_name.placeholder',
              onBlur: handleCampaignNameChange,
            }}
          />
          <Field
            element="input"
            name="description"
            title="brief_template_for_long_term_campaign.description.title"
            description="brief_template_for_long_term_campaign.description.descr"
            elementData={{
              multiple: true,
              defaultValue: description,
              onBlur: handleDescriptionChange,
              textAreaData: { minRows: 6, maxRows: 6 },
            }}
          />
        </div>
      </div>
    </BriefProvider>
  );
};

export default BriefForLongTermCampaign;

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  HideListingInput,
  HideListingMutation$data,
  HideListingMutation,
} from 'GraphTypes/HideListingMutation.graphql';

const mutation = graphql`
  mutation HideListingMutation($input: HideListingInput!) {
    hideListing(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: HideListingInput,
  resolve?: (response: HideListingMutation$data) => void,
  reject?: fnType
) => {
  const { listingId } = data;
  const id = uuid();
  const variables = {
    input: {
      id,
      listingId,
    },
  };
  commitMutation<HideListingMutation>(environment, {
    mutation,
    variables,
    updater: (store, data) => {
      const root = store.getRoot();
      if (root) {
        const listings = ConnectionHandler.getConnection(root, 'ListingsList_listings');
        if (listings) {
          ConnectionHandler.deleteNode(listings, listingId);
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useEffect, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import Section from '../Section/Section';

import styles from './ProjectCreatorRating.pcss';

import { ratings } from 'Modal/advertiser/ProjectCreatorRating/data';
import TextButton from 'Atoms/TextButton/TextButton';
import Icon from 'Atoms/Icon/Icon';
import RatingInfo from 'Molecules/RatingInfo/RatingInfo';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ProjectCreatorRating_project$key } from 'GraphTypes/ProjectCreatorRating_project.graphql';

interface Props {
  admin: boolean;
  project: ProjectCreatorRating_project$key;
}

const ProjectCreatorRating: React.FC<Props> = (props) => {
  const { admin, project } = props;

  const data = useFragment(
    graphql`
      fragment ProjectCreatorRating_project on Project {
        id
        currency
        completion {
          id
        }
        ratingByAgency: creatorRatingVote(side: AGENCY) {
          comment
          score
          characteristics
        }
      }
    `,
    project
  );

  const projectId = data?.id;
  const completion = data?.completion;
  const ratingByAgency = data?.ratingByAgency;

  const { openDrawer } = useContext(DrawerContext);

  const isRatingHidden = false;

  useEffect(() => {
    if (!isRatingHidden && !admin && completion && !ratingByAgency) {
      openDrawer(`project-creator-rating-${projectId}`);
    }
  }, [isRatingHidden]);

  if (!completion || isRatingHidden || !data) return null;

  if (!ratingByAgency) {
    return (
      <Section title="chat.project_creator_rating">
        <div className={styles.scores}>
          {ratings.map((item, idx) => {
            const { score } = item;

            const handleClick = () => {
              openDrawer(`project-creator-rating-${projectId}`);
            };

            return (
              <TextButton
                key={`rate_${score}_${idx}`}
                icon={<Icon name="starFull" color="alto" className={styles.scoreIcon} />}
                onClick={handleClick}
              />
            );
          })}
        </div>
      </Section>
    );
  }

  const { score, comment, characteristics } = ratingByAgency;

  return (
    <Section title="chat.project_creator_rating">
      <RatingInfo score={score} comment={comment} characteristics={characteristics} />
    </Section>
  );
};

export default ProjectCreatorRating;

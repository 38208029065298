/**
 * @generated SignedSource<<98a6deac3548ffe7c1f8e6f75de6cffc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteCustomListInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type DeleteCustomListMutation$variables = {
  input: DeleteCustomListInput;
};
export type DeleteCustomListMutation$data = {
  readonly deleteCustomList: {
    readonly __typename: string;
  } | null | undefined;
};
export type DeleteCustomListMutation = {
  response: DeleteCustomListMutation$data;
  variables: DeleteCustomListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "deleteCustomList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCustomListMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCustomListMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "34dc9344c1a3a7f6118f93ba0749eb8d",
    "id": null,
    "metadata": {},
    "name": "DeleteCustomListMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCustomListMutation(\n  $input: DeleteCustomListInput!\n) {\n  deleteCustomList(input: $input) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "afbf309ad1d796112181358144cfca92";

export default node;

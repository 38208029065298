import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import pick from 'lodash/pick';

import { data } from './data';
import Controls from './Controls/Controls';

import { formatValue } from 'Util/dataFormatter';
import Table from 'Containers/TableContainer/TableContainer';
import LoaderHandler from 'Containers/LoaderHandler/LoaderHandler';
import UnreadCount from 'Atoms/UnreadCount/UnreadCount';
import imageTypeImg from 'Images/icons/imageType.svg';
import youtubeTypeImg from 'Images/icons/youtubeType.svg';
import instagramTypeImg from 'Images/icons/instagramType.svg';
import instagramTypePsImg from 'Images/icons/instagramTypePs.svg';

export const YOUTUBE = 'YOUTUBE';
export const INSTAGRAM = 'INSTAGRAM';

const COUNT = 25;

const campaignData = [
  'id',
  'totalCreators',
  'registered',
  'accepted',
  'launched',
  'completed',
  'rejected',
  'pending',
];

const AdminCampaignList = (props) => {
  const computeDataValue = (value, format, additional = {}) => {
    return value ? formatValue({ value, format, ...additional }) : '—';
  };

  const createType = (type, paidSocial) => {
    let url = imageTypeImg;
    if (type === YOUTUBE) {
      url = youtubeTypeImg;
    } else if (type === INSTAGRAM) {
      url = paidSocial ? instagramTypePsImg : instagramTypeImg;
    }

    return url;
  };

  const createListData = () => {
    if (!props.monitoredCampaigns) return null;
    const monitoredCampaigns = props.monitoredCampaigns.monitoredCampaigns;

    return monitoredCampaigns.edges.map((item) => {
      const {
        campaign: {
          name,
          id,
          type,
          brand,
          organization,
          activation,
          projectEvents: { unreadCount },
        },
        paidSocial,
        registered,
        onboardedAmount,
        completedAmount,
      } = item.node;
      const itemData = pick(item.node, campaignData);
      const { currency } = organization;

      const campaignLink = `/campaigns/${id}/creators`;
      const campaignType = createType(type, paidSocial);

      const advertiserName = organization.name;
      const advertiserLink = `/admin/organizations/${organization.id}`;

      const briefStatus = activation
        ? 'admin_campaigns.filter_sent'
        : 'admin_campaigns.filter_review';
      const briefLink = `/campaigns/${id}/brief`;

      const onboardedAmountValue = computeDataValue(onboardedAmount, 'currency', { currency });
      const completedAmountValue = computeDataValue(completedAmount, 'currency', { currency });

      const unreadCountEl = unreadCount ? <UnreadCount count={unreadCount} /> : null;

      const style = { paddingTop: '10px', paddingBottom: '10px' };

      const controls = <Controls campaignId={id} />;

      return {
        ...itemData,
        hoverResponsive: true,
        campaignName: name,
        campaignLink,
        campaignType,
        advertiserName,
        advertiserLink,
        briefStatus,
        briefLink,
        registered,
        onboardedAmountValue,
        completedAmountValue,
        brandName: brand && brand.name ? brand.name : '',
        unreadCount: unreadCountEl,
        currency,
        style,
        controls,
      };
    });
  };

  const { relay } = props;

  const list = createListData();

  return (
    <LoaderHandler relay={relay} count={COUNT}>
      <Table list={list} data={data} defaultMsg="admin_campaigns.list_empty" />
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  AdminCampaignList,
  {
    monitoredCampaigns: graphql`
      fragment AdminCampaignList_monitoredCampaigns on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        textQuery: { type: "String" }
      ) {
        monitoredCampaigns(first: $count, after: $cursor, textQuery: $textQuery)
          @connection(key: "Admin_monitoredCampaigns", filters: []) {
          edges {
            node {
              id
              campaign {
                id
                type
                name
                brand {
                  name
                }
                activation {
                  id
                }
                projectEvents {
                  unreadCount
                }
                organization {
                  id
                  currency
                  name
                }
                creator {
                  email
                }
              }
              totalCreators
              rejected
              accepted
              completed
              onboardedAmount
              completedAmount
              launched
              paidSocial
              pending
              registered
              rejected
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.monitoredCampaigns && props.monitoredCampaigns.monitoredCampaigns;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        textQuery: props.textQuery,
        managerIds: props.managerIds,
      };
    },
    query: graphql`
      query AdminCampaignListPaginationQuery($count: Int!, $cursor: String, $textQuery: String) {
        ...AdminCampaignList_monitoredCampaigns
          @arguments(count: $count, cursor: $cursor, textQuery: $textQuery)
      }
    `,
  }
);

import React, { useState, useContext } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import classnames from 'classnames';

import styles from './BcaRequestPermissions.pcss';

import { BcaRequestPermissionsQuery as QueryType } from 'GraphTypes/BcaRequestPermissionsQuery.graphql';
import requestBcaPermission from 'Mutations/RequestBcaPermission.Mutation';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import Spinner from 'Atoms/Spinner/Spinner';
import Drawer from 'Components/ui/Drawer/Drawer';
import { RequestBcaPermissionMutation$data } from 'GraphTypes/RequestBcaPermissionMutation.graphql';
import Image from 'Components/ui/Image/Image';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

const BcaRequestPermissionsQuery = graphql`
  query BcaRequestPermissionsQuery {
    facebook {
      instagramBusinessAccounts {
        id
        username
        profilePictureUrl
      }
    }
  }
`;

interface Props {
  projectId: string;
  onDone?: HandleRequestDone;
  onError?: HandleRequestError;
}

const BcaRequestPermissions: React.FC<Props> = (props) => {
  const { projectId, onDone, onError } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const [loading, setLoading] = useState(false);
  const [textQuery, setTextQuery] = useState('');
  const [brandInstagramId, setBrandInstagramId] = useState('');

  const data = useLazyLoadQuery<QueryType>(BcaRequestPermissionsQuery, {});

  const list = data.facebook?.instagramBusinessAccounts;

  const handleRequestDone: HandleRequestDone = (data) => {
    setLoading(false);
    closeModal();
    onDone?.(data);
  };

  const handleRequestError: HandleRequestError = (data) => {
    setLoading(false);
    closeModal();
    onError?.(data);
  };

  const closeModal = () => {
    closeDrawer(`project-bca-connection-${projectId}`);
  };

  const handleBcaActivate = () => {
    setLoading(true);
    requestBcaPermission({ brandInstagramId, projectId }, handleRequestDone, handleRequestError);
  };

  const handleTextQueryChange = (newTextQuery: string) => {
    setTextQuery(newTextQuery);
  };

  return (
    <Drawer rootKey={`project-bca-connection-${projectId}`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        {!data && <Spinner />}
        {list?.length === 0 && (
          <>
            <Text
              type="d2"
              msg="bca_request_permissions_modal.empty_list"
              className={styles.emptyTitle}
            />
            <Button color="black" msg="bca_request_permissions_modal.close" onClick={closeModal} />
          </>
        )}
        {list && list.length > 0 && (
          <>
            <Text type="d2" msg="bca_request_permissions_modal.title" className={styles.title} />
            <Input
              bordered
              placeholderMsg="bca_request_permissions_modal.placeholder"
              handleChange={handleTextQueryChange}
              rightIcon="Search-loop"
              className={styles.input}
            />
            <div className={styles.list}>
              {list.map((item) => {
                const { id, profilePictureUrl, username } = item;
                if (!username?.includes(textQuery)) return null;
                const isActive = brandInstagramId === id;
                const handleClick = () => {
                  setBrandInstagramId(id);
                };
                return (
                  <div
                    key={id}
                    className={classnames(styles.item, { [styles.active]: isActive })}
                    onClick={handleClick}
                  >
                    <Image type="round" src={profilePictureUrl || undefined} size={40} />
                    <Text type="md" text={username} className={styles.username} />
                    {isActive && <Icon name="Check" />}
                  </div>
                );
              })}
            </div>
            <Button
              color="black"
              msg="bca_request_permissions_modal.done"
              loading={loading}
              disabled={!brandInstagramId || loading}
              onClick={handleBcaActivate}
            />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default BcaRequestPermissions;

type HandleRequestDone = (response: RequestBcaPermissionMutation$data) => void;
type HandleRequestError = (data: object) => void;

/**
 * @generated SignedSource<<9ce6b80a3a60fa7fe7e95abae86a2458>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubmitBriefInput = {
  briefId: string;
  campaignId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
};
export type SubmitBriefMutation$variables = {
  input: SubmitBriefInput;
};
export type SubmitBriefMutation$data = {
  readonly submitBrief: {
    readonly campaign: {
      readonly briefSubmission: {
        readonly acceptance: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
      readonly organization: {
        readonly campaigns: {
          readonly totalCount: number;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type SubmitBriefMutation = {
  response: SubmitBriefMutation$data;
  variables: SubmitBriefMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BriefSubmissionAcceptance",
  "kind": "LinkedField",
  "name": "acceptance",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "ACTIVE",
        "IN_REVIEW",
        "COMPLETED"
      ]
    }
  ],
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "campaigns(stages:[\"ACTIVE\",\"IN_REVIEW\",\"COMPLETED\"])"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitBriefMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitBriefPayload",
        "kind": "LinkedField",
        "name": "submitBrief",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BriefSubmission",
                "kind": "LinkedField",
                "name": "briefSubmission",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitBriefMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitBriefPayload",
        "kind": "LinkedField",
        "name": "submitBrief",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BriefSubmission",
                "kind": "LinkedField",
                "name": "briefSubmission",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e891d760a6fc6a8539c0d70bd2489c02",
    "id": null,
    "metadata": {},
    "name": "SubmitBriefMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitBriefMutation(\n  $input: SubmitBriefInput!\n) {\n  submitBrief(input: $input) {\n    campaign {\n      briefSubmission {\n        acceptance {\n          id\n        }\n        id\n      }\n      organization {\n        campaigns(stages: [ACTIVE, IN_REVIEW, COMPLETED]) {\n          totalCount\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "047eb0ced237eab766a745165a411078";

export default node;

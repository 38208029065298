import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CreativeItem from './CreativeItem/CreativeItem';

import { CreativeList_campaign$key } from 'GraphTypes/CreativeList_campaign.graphql';

interface Props {
  mobileView?: boolean;
  campaign?: CreativeList_campaign$key;
  campaignPlatform?: string | null;
}

const CreativeList: React.FC<Props> = (props) => {
  const { campaign, mobileView } = props;

  const data = useFragment(
    graphql`
      fragment CreativeList_campaign on Campaign {
        platform
        brief {
          __typename
          ... on V2Brief {
            creatives(first: 2147483647) @connection(key: "Creatives_creatives") {
              edges {
                node {
                  id
                  ...CreativeItem_briefCreative
                }
              }
            }
          }
        }
      }
    `,
    campaign
  );

  if (!data) return null;

  const brief = data.brief;
  const campaignPlatform = data.platform;

  if (!brief || brief.__typename !== 'V2Brief') return null;

  const createCreativesList = () => {
    const list = brief.creatives?.edges;

    if (!list) return null;

    return list.map((item) => {
      if (!item?.node) return null;

      const { id } = item.node;

      return (
        <CreativeItem
          key={id}
          briefCreative={item.node}
          campaignPlatform={campaignPlatform}
          mobileView={mobileView}
        />
      );
    });
  };

  return <div>{createCreativesList()}</div>;
};

export default CreativeList;

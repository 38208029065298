import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import IconNew from '../../Icon/Icon';

import styles from './DropdownItem.pcss';

type Props = PropsWithChildren<{
  text?: string;
  handleClick?: () => void;
  selected?: boolean;
  dataTest?: string;
  className?: string;
}>;

const DropdownGroupItem: React.FC<Props> = (props) => {
  const { children, className, handleClick, selected = false, dataTest } = props;

  const classNameList = classnames(styles.root, className, {
    [styles.selected]: selected,
    className,
  });

  return (
    <div className={classNameList} onClick={handleClick} data-test={dataTest}>
      <div className={styles.value}>{children}</div>
      {selected && <IconNew size={16} name={'Check'} />}
    </div>
  );
};

export default DropdownGroupItem;

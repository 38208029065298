/**
 * @generated SignedSource<<237ca04fdd6d72015d61fe673257fd63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectPSStatus_project$data = {
  readonly brief: {
    readonly paidSocialActivationDays?: number | null | undefined;
    readonly paidSocialBca?: boolean;
  } | null | undefined;
  readonly creator: {
    readonly paidSocialEnabled?: boolean;
    readonly username: string;
  } | null | undefined;
  readonly id: string;
  readonly paidSocial: boolean;
  readonly " $fragmentType": "ProjectPSStatus_project";
};
export type ProjectPSStatus_project$key = {
  readonly " $data"?: ProjectPSStatus_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectPSStatus_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPSStatus_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialEnabled",
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialBca",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialActivationDays",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "6b1daf98a9a33c0e4b9061e1a11bd83c";

export default node;

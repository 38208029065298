import React from 'react';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import { TooltipType } from 'Types/common';
import { IconName } from 'Components/ui/types';

interface Props {
  color?: string;
  iconName?: IconName;
}

const InfoTooltip: React.FC<TooltipType & Props> = (props) => {
  const { iconName = 'Info', ...rest } = props;

  return (
    <Tooltip place="bottom" {...rest}>
      <Icon size={16} name={iconName} />
    </Tooltip>
  );
};

export default InfoTooltip;

/**
 * @generated SignedSource<<6048b1885d43701096f33edd283b08f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnblockCreatorInput = {
  clientMutationId?: string | null | undefined;
  creatorId?: string | null | undefined;
  id?: string | null | undefined;
  organizationId?: string | null | undefined;
};
export type UnblockCreatorMutation$variables = {
  input: UnblockCreatorInput;
};
export type UnblockCreatorMutation$data = {
  readonly unblockCreator: {
    readonly organization?: {
      readonly amount: {
        readonly totalCount: number;
      } | null | undefined;
    };
  } | null | undefined;
};
export type UnblockCreatorMutation = {
  response: UnblockCreatorMutation$data;
  variables: UnblockCreatorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": "amount",
  "args": null,
  "concreteType": "CreatorConnection",
  "kind": "LinkedField",
  "name": "blockedCreators",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnblockCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "unblockCreator",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UnblockCreatorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnblockCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "unblockCreator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UnblockCreatorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fad729d3e03961ca2d5ac813d689ae4a",
    "id": null,
    "metadata": {},
    "name": "UnblockCreatorMutation",
    "operationKind": "mutation",
    "text": "mutation UnblockCreatorMutation(\n  $input: UnblockCreatorInput!\n) {\n  unblockCreator(input: $input) {\n    __typename\n    ... on UnblockCreatorPayload {\n      organization {\n        amount: blockedCreators {\n          totalCount\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "71302087405b30027a423e7cefdfa245";

export default node;

import {
  ADVANCED_2021_10_PLAN,
  ADVANCED_2022_11_PLAN,
  ADVANCED_PLAN,
  ENTERPRISE_PLAN,
  FREE_PLAN,
  FULLY_MANAGED_1_PLAN,
  FULLY_MANAGED_2_PLAN,
  FULLY_MANAGED_PLAN,
  FULLY_MANAGED_TTCX_MMA_PLAN,
  GROW_PLAN,
  SCALE_2021_06_PLAN,
  SCALE_2021_10_PLAN,
  SCALE_PLAN,
  SMARTLY_ENTERPRISE_2023_06_PLAN,
  SMARTLY_ENTERPRISE_TRIAL_2023_06_PLAN,
  TRIAL_2022_08_PLAN,
  UGC_2022_08_PLAN,
  UGC_ADS_2022_08_PLAN,
  BRAND_2024_03_PLAN,
  AGENCY_2024_03_PLAN,
} from 'Constants/general';

import { PlanId } from 'GraphTypes/CreateManualTransactionForSubscriptionMutation.graphql';

export const plans: PlanId[] = [
  FREE_PLAN,
  ENTERPRISE_PLAN,
  GROW_PLAN,
  SCALE_PLAN,
  SCALE_2021_06_PLAN,
  SCALE_2021_10_PLAN,
  ADVANCED_PLAN,
  ADVANCED_2021_10_PLAN,
  ADVANCED_2022_11_PLAN,
  FULLY_MANAGED_PLAN,
  FULLY_MANAGED_TTCX_MMA_PLAN,
  FULLY_MANAGED_1_PLAN,
  FULLY_MANAGED_2_PLAN,
  TRIAL_2022_08_PLAN,
  UGC_2022_08_PLAN,
  UGC_ADS_2022_08_PLAN,
  SMARTLY_ENTERPRISE_2023_06_PLAN,
  SMARTLY_ENTERPRISE_TRIAL_2023_06_PLAN,
  BRAND_2024_03_PLAN,
  AGENCY_2024_03_PLAN,
];

export enum TRANSACTION_TYPE {
  TOPUP = 'topup',
  BONUS = 'bonus',
  SUBSCRIPTION = 'subscription',
  REFUND = 'refund',
  CREATOR_EXPENSE = 'creator_expense',
}

export const transactionTypes = [
  { id: TRANSACTION_TYPE.TOPUP, msg: 'transaction_type.topup' },
  { id: TRANSACTION_TYPE.BONUS, msg: 'transaction_type.bonus' },
  { id: TRANSACTION_TYPE.SUBSCRIPTION, msg: 'transaction_type.subscription' },
  { id: TRANSACTION_TYPE.REFUND, msg: 'transaction_type.refund' },
  { id: TRANSACTION_TYPE.CREATOR_EXPENSE, msg: 'transaction_type.creator_expense' },
];

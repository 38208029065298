import React, { Suspense, useRef, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import compact from 'lodash/compact';

import styles from './CreatorCard.pcss';
import CreatorInfo from './CreatorInfo/CreatorInfo';
import CreatorPrice from './CreatorPrice/CreatorPrice';
import CreatorDetails from './CreatorDetails/CreatorDetails';
import CreatorCardControls from './CreatorCardControls/CreatorCardControls';
import PortfolioContent from './PortfolioContent/PortfolioContent';

import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import updateProject from 'Mutations/UpdateProject.Mutation';
import Text from 'Components/ui/Text/Text';
import Textarea from 'Components/ui/Textarea/Textarea';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { CreatorCard_project$key } from 'GraphTypes/CreatorCard_project.graphql';
import { AdvertiserProjectStage } from 'GraphTypes/CreatorsListQuery.graphql';
import ScreeningQuestionAnswers from 'AdvertiserPage/Campaign/Creators/CreatorsList/CreatorsListContainer/CreatorCard/ScreeningQuestionAnswers/ScreeningQuestionAnswers';

interface Props extends React.HTMLProps<HTMLDivElement> {
  project: CreatorCard_project$key;
  isAdminView: boolean;
  isLongTermCampaign?: boolean;
  organizationId?: string;
  creatorId: string;
  campaignId: string;
  listType: string;
  isPortfolioOpen: boolean;
  handleChangePortfolioItem: (itemIndex: number) => void;
  portfolioItemIndex: number;
  creatorsStages?: AdvertiserProjectStage[];
  defaultStages?: AdvertiserProjectStage[];
  archivedStages?: AdvertiserProjectStage[];
  portfolioTypeName: string;
  portfolioCategoryName: string;
  email?: string;
  canCreateMultipleDeals: boolean;
}

const CreatorCard: React.FC<Props> = (props) => {
  const {
    project,
    isAdminView,
    organizationId,
    campaignId,
    isPortfolioOpen,
    portfolioItemIndex,
    onMouseEnter,
    creatorsStages,
    defaultStages,
    archivedStages,
    portfolioTypeName,
    portfolioCategoryName,
    canCreateMultipleDeals,
    isLongTermCampaign,
    creatorId,
    email,
  } = props;

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const showNote = hasFeatureFlag('campaign_creator_card_with_notes');

  const data = useFragment(
    graphql`
      fragment CreatorCard_project on Project
      @argumentDefinitions(organizationId: { type: "ID!" }) {
        id
        comment
        adminComment
        offer {
          acceptance {
            id
            screeningResponses {
              edges {
                node {
                  id
                  booleanAnswer
                  preferred
                  question {
                    text
                    type
                  }
                }
              }
            }
          }
          rejection {
            id
          }
        }
        advertiserStage
        archivation(side: ADVERTISER) {
          id
        }
        ...CreatorCardControls_project
        ...CreatorInfo_project
        ...CreatorPrice_project
        ...CreatorDetails_project @arguments(organizationId: $organizationId)
        ...CreatorCardControls_project
        creator {
          id
          username
          type
          ... on InstagramCreator {
            user {
              profilePictureUrl
              followedByCount
              engagementRate
            }
          }
          ... on YoutubeCreator {
            channel {
              subscribers
              thumbnailUrl
            }
          }
          ... on TiktokCreator {
            tiktokUser: user {
              name
              followedByCount
              avatar {
                secureUrl
              }
            }
          }
        }
      }
    `,
    project
  );
  const { advertiserStage, offer, adminComment, comment } = data;

  const cardEl = useRef(null);

  const stageLabel = advertiserStage?.toLowerCase();

  const isArchived = data.archivation?.id;

  const answers = compact(
    data.offer?.acceptance?.screeningResponses?.edges?.map((item) => {
      if (!item?.node) return null;
      const { id: answerId, booleanAnswer, preferred, question } = item.node;

      if (question.type !== 'BINARY') return null;

      return { id: answerId, text: question.text, preferred, booleanAnswer };
    })
  );

  const debounceUpdateNote = debounce(updateProject, 500);

  const handleAdminNoteChange = (value: string) => {
    debounceUpdateNote({ id: data.id, adminComment: value });
  };

  const handleNoteChange = (value: string) => {
    debounceUpdateNote({ id: data.id, comment: value });
  };

  const hideOnboardingStage = advertiserStage === 'ACCEPTED';
  return (
    <div
      className={classnames(styles.root, { [styles.isActive]: isPortfolioOpen })}
      onMouseEnter={onMouseEnter}
      ref={cardEl}
    >
      <div className={styles.content}>
        <div>
          <div className={styles.info}>
            <CreatorInfo project={data} campaignId={campaignId} />
            <CreatorPrice project={data} />
          </div>
          <CreatorDetails project={data} />
          {advertiserStage === 'ACCEPTED' && answers.length > 0 && (
            <ScreeningQuestionAnswers answers={answers} className={styles.answer} />
          )}
        </div>
        <div className={styles.row}>
          <div>
            {!(isArchived || hideOnboardingStage) && !isLongTermCampaign && (
              <Tooltip
                place="top"
                id={`chat_stage_${stageLabel}_${data.id}`}
                tooltipMsg={
                  offer?.acceptance?.id || offer?.rejection?.id
                    ? `project.stage.${stageLabel}.tooltip`
                    : undefined
                }
              >
                <div className={classnames(styles.stage, stageLabel ? styles[stageLabel] : null)}>
                  <Text type="md" msg={`project.stage.${stageLabel}`} />
                </div>
              </Tooltip>
            )}
          </div>
          <CreatorCardControls
            project={data}
            isAdminView={isAdminView}
            organizationId={organizationId}
            creatorsStages={creatorsStages}
            archivedStages={archivedStages}
            canCreateMultipleDeals={canCreateMultipleDeals}
            isLongTermCampaign={isLongTermCampaign}
            email={email}
          />
        </div>
        {isAdminView && (
          <div className={styles.note}>
            <Textarea
              bordered
              defaultValue={adminComment || undefined}
              placeholderMsg="creator_card.note.placeholder"
              handleChange={handleAdminNoteChange}
              className={styles.noteInput}
            />
          </div>
        )}
        {!isAdminView && showNote && (
          <div className={styles.note}>
            <Textarea
              bordered
              defaultValue={comment || undefined}
              placeholderMsg="creator_card.note.placeholder"
              handleChange={handleNoteChange}
              className={styles.noteInput}
            />
          </div>
        )}
      </div>
      <Suspense fallback={null}>
        <PortfolioContent
          isNewCard={true}
          parentRef={cardEl}
          creatorId={creatorId}
          projectId={data.id}
          index={portfolioItemIndex}
          portfolioTypeName={portfolioTypeName}
          portfolioCategoryName={portfolioCategoryName}
        />
      </Suspense>
    </div>
  );
};

export default CreatorCard;

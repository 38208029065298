import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import AdminCreativeLibrary from './AdminCreativeLibrary';

import { AdminCreativeLibraryContainerQuery as QueryType } from 'GraphTypes/AdminCreativeLibraryContainerQuery.graphql';

const AdminCreativeLibraryContainerQuery = graphql`
  query AdminCreativeLibraryContainerQuery {
    currentUser {
      organization {
        currency
      }
      admin
    }
  }
`;

const AdminCreativeLibraryContainer = () => {
  const data = useLazyLoadQuery<QueryType>(AdminCreativeLibraryContainerQuery, {});

  if (!data) return null;

  const currentUser = data.currentUser;
  const admin = currentUser?.admin;

  if (!admin) return null;

  return <AdminCreativeLibrary currency={currentUser?.organization?.currency} />;
};

export default AdminCreativeLibraryContainer;

import React, { ChangeEvent, FC, useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import classNames from 'classnames';

import styles from './FirebaseAuth.pcss';
import { AuthFormType, FormErrorType } from './FirebaseAuth';

import { LANDING_LINK, INSENSE_PRIVACY_POLICY } from 'Constants/general';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Logo from 'Atoms/Logo/Logo';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { emailValidate } from 'Util/validate';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';
import ErrorHandler from 'Util/errorHandler';

type Props = {
  changeFormType: (formType: AuthFormType) => void;
};

export const ResetPasswordForm: FC<Props> = (props) => {
  const { changeFormType } = props;
  const [email, setEmail] = useState('');
  const [error, setError] = useState<FormErrorType>({
    email: undefined,
  });
  const [successReset, setResetStatus] = useState(false);
  const [loading, setLoadingStatus] = useState(false);

  const handleResetPassword = async () => {
    if (loading) return;
    const emailErrors = emailValidate({}, email);
    if (emailErrors.email) {
      setError(emailErrors);
      return;
    }
    setLoadingStatus(true);
    try {
      await sendPasswordResetEmail(firebaseClient.authClient, email);
      setResetStatus(true);
    } catch (e) {
      if (e.code === 'auth/user-not-found') {
        setError({ email: 'form.error_user_not_found' });
      } else if (e.code === 'auth/invalid-email') {
        setError({ email: 'form.error_email_invalid' });
      } else {
        ErrorHandler.error('User cannot reset password', { ...e });
        setError({ email: 'general.error' });
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSignInClick = () => {
    changeFormType(AuthFormType.SIGNIN);
  };

  const handlePolicyClick = () => {
    window.open(INSENSE_PRIVACY_POLICY);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <SmartLink path={LANDING_LINK}>
              <Logo />
            </SmartLink>
          </div>
        </header>
        <Text
          type="h1"
          msg={successReset ? 'reset_password.check_email' : 'signin.forgot_password'}
        />
        {successReset ? (
          <div className={styles.form}>
            <Text type="md" className={styles.textByCenter} msg="reset_password.success_descr" />
            <ButtonPreset>
              <Button
                fluid
                color="black"
                msg={'reset_password.short_return'}
                onClick={handleSignInClick}
              />
            </ButtonPreset>
          </div>
        ) : (
          <div className={classNames(styles.form, styles.withoutContent)}>
            <Text
              type="md"
              className={classNames(styles.textByCenter, styles.subtitle)}
              msg="reset_password.check_email_descr"
            />
            <Input
              bordered
              placeholderMsg="form.email_address"
              onChange={handleEmailChange}
              type="email"
              id="email"
              autoComplete="email"
              required
              className={styles.input}
              error={!!error?.email}
            />
            {!!error?.email && <Text type="s" msg={error.email} className={styles.errorText} />}
            <ButtonPreset>
              <Button
                fluid
                color="black"
                msg={'reset_password.submit'}
                className={styles.submitButton}
                onClick={handleResetPassword}
                loading={loading}
              />
            </ButtonPreset>
            <TextButton
              classes={{
                text: styles.textByCenter,
              }}
              onClick={handleSignInClick}
              msg={'reset_password.return'}
            />
            <TextButton
              classes={{
                text: styles.textByCenter,
              }}
              msg={'auth.privacy_policy'}
              className={styles.privacy}
              onClick={handlePolicyClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

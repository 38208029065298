/**
 * @generated SignedSource<<b82ba2d8664871a89b4849dcad2de946>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ErrorType = "ALREADY_EXIST_ERROR" | "CREATOR_ALREADY_OWNED_ERROR" | "NOT_CLAIMED" | "NO_CREATOR" | "SIGNIN_ERROR" | "SIGNUP_ERROR" | "VALIDATION_ERROR" | "%future added value";
export type RefreshCreatorInfoInput = {
  clientMutationId?: string | null | undefined;
  creatorId: string;
};
export type RefreshCreatorInfoMutation$variables = {
  input: RefreshCreatorInfoInput;
};
export type RefreshCreatorInfoMutation$data = {
  readonly refreshCreatorInfo: {
    readonly __typename: "NotFoundError";
    readonly message: string;
    readonly type: ErrorType;
  } | {
    readonly __typename: "RefreshCreatorInfoPayload";
    readonly creator: {
      readonly id: string;
    };
  } | {
    readonly __typename: "RefreshCreatorInfo_NoInsightsAuthorizationError";
    readonly message: string;
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type RefreshCreatorInfoMutation = {
  response: RefreshCreatorInfoMutation$data;
  variables: RefreshCreatorInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "NotFoundError",
  "abstractKey": null
},
v5 = [
  (v3/*: any*/)
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "RefreshCreatorInfo_NoInsightsAuthorizationError",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "ValidationError",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefreshCreatorInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshCreatorInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshCreatorInfoPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefreshCreatorInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshCreatorInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshCreatorInfoPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c952ed7c01f244badc43049f45132420",
    "id": null,
    "metadata": {},
    "name": "RefreshCreatorInfoMutation",
    "operationKind": "mutation",
    "text": "mutation RefreshCreatorInfoMutation(\n  $input: RefreshCreatorInfoInput!\n) {\n  refreshCreatorInfo(input: $input) {\n    __typename\n    ... on NotFoundError {\n      message\n      type\n    }\n    ... on RefreshCreatorInfo_NoInsightsAuthorizationError {\n      message\n    }\n    ... on ValidationError {\n      message\n    }\n    ... on RefreshCreatorInfoPayload {\n      creator {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f081f139dd7c5fdd5d27d0a73e2f1df";

export default node;

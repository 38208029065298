/**
 * @generated SignedSource<<3bdff4fc0ee6c3ee9bb725eb04bd21d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PriceChangeEvent_event$data = {
  readonly __typename: string;
  readonly createdAt: any;
  readonly currency?: Currency | null | undefined;
  readonly id: string;
  readonly price?: number | null | undefined;
  readonly request?: {
    readonly currency: Currency;
    readonly price: number;
  };
  readonly requestCurrency?: Currency;
  readonly requestPrice?: number;
  readonly " $fragmentType": "PriceChangeEvent_event";
};
export type PriceChangeEvent_event$key = {
  readonly " $data"?: PriceChangeEvent_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceChangeEvent_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceChangeEvent_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "type": "PriceChange",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "requestPrice",
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": "requestCurrency",
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "type": "PriceChangeRequest",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceChangeRequest",
          "kind": "LinkedField",
          "name": "request",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "PriceChangeRequestAcceptance",
      "abstractKey": null
    }
  ],
  "type": "ProjectEvent",
  "abstractKey": "__isProjectEvent"
};
})();

(node as any).hash = "cc772d3ed253e4b74784253d7061f647";

export default node;

import {
  TOGGLE_PRELOADER,
  SHOW_HEADER,
  SHOW_FOOTER,
  TOGGLE_MOBILE_VIEW,
} from 'Constants';

export const togglePreloader = show => ({
  type: TOGGLE_PRELOADER,
  payload: show,
});

export const toggleShowHeader = show => ({
  type: SHOW_HEADER,
  payload: show !== 'undefined' ? show : true,
});

export const toggleShowFooter = show => ({
  type: SHOW_FOOTER,
  payload: show ? true : false,
});

export const toggleMobileView = show => ({
  type: TOGGLE_MOBILE_VIEW,
  payload: show ? true : false,
});

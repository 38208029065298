import React, { PropsWithChildren, useEffect } from 'react';
import classnames from 'classnames';

import styles from './Page.css';

interface Props {
  className?: string;
}

const Page: React.FC<PropsWithChildren<Props>> = (props) => {
  const { className, children } = props;

  const showHeader = true;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={styles.rootWrapper}>
      <div className={classnames(className, styles.root, { [styles.showHeader]: showHeader })}>
        {children}
      </div>
    </div>
  );
};

export default Page;

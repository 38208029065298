import React from 'react';

import { formatValue } from 'Util/dataFormatter';
import Table from 'Components/Table/Table';

const TableContainer = (props) => {
  const { list, data, children } = props;

  const combineListData = () => {
    if (!list) return [];

    return list.map((item) => {
      const { hoverResponsive, style, presetStyle, stylename, innerLink, outerLink, id } = item;
      const cells = data.map((cell) => {
        const { values } = cell;
        if (!values) return cell;
        const valuesKeys = Object.keys(values);
        const newValues = {};
        for (let index = 0; index < valuesKeys.length; index++) {
          const value = valuesKeys[index];
          const innerData = values[value];
          const itemValue = item[innerData.value];
          const keyValue = innerData.format
            ? formatValue({ ...innerData, value: itemValue })
            : itemValue;
          newValues[value] = keyValue;
        }

        return { ...cell, values: newValues };
      });

      return { id, innerLink, outerLink, style, presetStyle, stylename, hoverResponsive, cells };
    });
  };

  const newData = list ? { ...props, list: combineListData() } : { ...props };

  return <Table {...newData} children={children} />;
};

export default TableContainer;

/**
 * @generated SignedSource<<6377c47d15f4a5f99a149e9b9739fdee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type ProjectsFiltersQuery$variables = {
  archived?: boolean | null | undefined;
  campaignId: string;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
};
export type ProjectsFiltersQuery$data = {
  readonly campaign: {
    readonly highPotentialCount: {
      readonly totalCount: number;
    };
    readonly highlyResponsiveCount: {
      readonly totalCount: number;
    };
    readonly outreachedCount: {
      readonly totalCount: number;
    };
    readonly topRatedCount: {
      readonly totalCount: number;
    };
    readonly ugcExpertCount: {
      readonly totalCount: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ScreeningQuestionsFilter_campaign">;
  } | null | undefined;
};
export type ProjectsFiltersQuery = {
  response: ProjectsFiltersQuery$data;
  variables: ProjectsFiltersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v4 = {
  "kind": "Variable",
  "name": "archived",
  "variableName": "archived"
},
v5 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "LAST_ADDED"
},
v6 = {
  "kind": "Variable",
  "name": "stages",
  "variableName": "stages"
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v8 = {
  "alias": "outreachedCount",
  "args": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "outreach",
      "value": true
    },
    (v6/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": "topRatedCount",
  "args": [
    (v4/*: any*/),
    {
      "kind": "Literal",
      "name": "badges",
      "value": [
        "TOP_RATED"
      ]
    },
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": "highPotentialCount",
  "args": [
    (v4/*: any*/),
    {
      "kind": "Literal",
      "name": "badges",
      "value": [
        "HIGH_POTENTIAL"
      ]
    },
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": "ugcExpertCount",
  "args": [
    (v4/*: any*/),
    {
      "kind": "Literal",
      "name": "badges",
      "value": [
        "UGC_EXPERT"
      ]
    },
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": "highlyResponsiveCount",
  "args": [
    (v4/*: any*/),
    {
      "kind": "Literal",
      "name": "badges",
      "value": [
        "HIGHLY_RESPONSIVE"
      ]
    },
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScreeningQuestionsFilter_campaign"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectsFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BriefScreeningQuestionConnection",
                    "kind": "LinkedField",
                    "name": "screeningQuestions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BriefScreeningQuestionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BriefScreeningQuestion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "preferredValue",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "V2Brief",
                "abstractKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37de12fe40b18e51f7a47656d180d66d",
    "id": null,
    "metadata": {},
    "name": "ProjectsFiltersQuery",
    "operationKind": "query",
    "text": "query ProjectsFiltersQuery(\n  $campaignId: ID!\n  $archived: Boolean\n  $stages: [AdvertiserProjectStage!]\n) {\n  campaign(id: $campaignId) {\n    outreachedCount: projects(archived: $archived, stages: $stages, orderBy: LAST_ADDED, outreach: true) {\n      totalCount\n    }\n    topRatedCount: projects(archived: $archived, badges: [TOP_RATED], stages: $stages, orderBy: LAST_ADDED) {\n      totalCount\n    }\n    highPotentialCount: projects(archived: $archived, badges: [HIGH_POTENTIAL], stages: $stages, orderBy: LAST_ADDED) {\n      totalCount\n    }\n    ugcExpertCount: projects(archived: $archived, badges: [UGC_EXPERT], stages: $stages, orderBy: LAST_ADDED) {\n      totalCount\n    }\n    highlyResponsiveCount: projects(archived: $archived, badges: [HIGHLY_RESPONSIVE], stages: $stages, orderBy: LAST_ADDED) {\n      totalCount\n    }\n    ...ScreeningQuestionsFilter_campaign\n    id\n  }\n}\n\nfragment ScreeningQuestionsFilter_campaign on Campaign {\n  brief {\n    __typename\n    ... on V2Brief {\n      screeningQuestions {\n        edges {\n          node {\n            id\n            text\n            type\n            preferredValue\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b6e21b046a0210db652b123c3dcca86";

export default node;

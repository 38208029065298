/**
 * @generated SignedSource<<0a7f54e9ce77bd294675d607afc81989>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortfolioManager_creator$data = {
  readonly id: string;
  readonly portfolioItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly file: {
          readonly id: string;
          readonly processingStatus?: FileProcessingStatus | null | undefined;
          readonly secureUrl: string;
          readonly thumbnailUrl: string;
          readonly transformations?: {
            readonly autoUrl: string;
            readonly collageThumbnailUrl: string;
          } | null | undefined;
          readonly type: FileType;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly " $fragmentType": "PortfolioManager_creator";
};
export type PortfolioManager_creator$key = {
  readonly " $data"?: PortfolioManager_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioManager_creator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "portfolioItems"
        ]
      }
    ]
  },
  "name": "PortfolioManager_creator",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "portfolioItems",
      "args": null,
      "concreteType": "PortfolioItemConnection",
      "kind": "LinkedField",
      "name": "__Profile_portfolioItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PortfolioItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PortfolioItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "file",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "secureUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "thumbnailUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "processingStatus",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VideoTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "autoUrl",
                              "storageKey": null
                            },
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Video",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "2f398c4c1d02b25049e12f66949a7284";

export default node;

import React, { useMemo } from 'react';

import Image from 'Components/ui/Image/Image';
import instagramColoredImg from 'Images/general/instagramColored.svg';
import tiktokColoredImg from 'Images/general/tiktokColored.svg';
import youtubeColored from 'Images/general/youtubeColored.svg';

interface Props {
  platform?: 'INSTAGRAM' | 'TIKTOK' | 'YOUTUBE' | 'CONTENT' | '%future added value';
  size?: number;
  className?: string;
}

const PlatformIcon: React.FC<Props> = (props) => {
  const { platform, size, className } = props;
  if (!platform) return null;

  const platformIcon = useMemo(() => {
    switch (platform) {
      case 'INSTAGRAM':
        return instagramColoredImg;
      case 'TIKTOK':
        return tiktokColoredImg;
      case 'YOUTUBE':
        return youtubeColored;

      default:
        return null;
    }
  }, [platform]);

  return <Image size={size} src={platformIcon} className={className} />;
};

export default PlatformIcon;

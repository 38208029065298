/**
 * @generated SignedSource<<0862bebe3ba2694c90218433ee38e760>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResumeSubscriptionInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  subscriptionId: string;
};
export type ResumeSubscriptionMutation$variables = {
  input: ResumeSubscriptionInput;
};
export type ResumeSubscriptionMutation$data = {
  readonly resumeSubscription: {
    readonly advertiserSubscription?: {
      readonly paused: boolean;
    };
  } | null | undefined;
};
export type ResumeSubscriptionMutation = {
  response: ResumeSubscriptionMutation$data;
  variables: ResumeSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResumeSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "resumeSubscription",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ResumeSubscriptionPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResumeSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "resumeSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ResumeSubscriptionPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43850ce907f7e62a81ccbc1b263075c2",
    "id": null,
    "metadata": {},
    "name": "ResumeSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation ResumeSubscriptionMutation(\n  $input: ResumeSubscriptionInput!\n) {\n  resumeSubscription(input: $input) {\n    __typename\n    ... on ResumeSubscriptionPayload {\n      advertiserSubscription {\n        paused\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a2b12cfcda3b9f70d35d2f5b2fac05c9";

export default node;

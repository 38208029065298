import type { SignUpCompleteContextType, StepArrays, UserData } from '.';
import { SignUpType, StepEnum } from '.';

import updateOrganization from 'Mutations/UpdateOrganization.Mutation';
import { identifyUser } from 'Analytics/engines/Hubspot';
import { amplitude } from 'Helpers/amplitude';
import { lightWebsiteUrl } from 'Util/validate';

type GetStepArrays = () => StepArrays;
const getStepArrays: GetStepArrays = () => {
  return {
    [SignUpType.Standard]: [{ id: StepEnum.UserDetails, theme: 'sky' }],
    [SignUpType.InviteTeammate]: [{ id: StepEnum.UserDetails, theme: 'sky' }],
    [SignUpType.Survey]: [
      { id: StepEnum.CompanyType, theme: 'pink' },
      { id: StepEnum.CompanyIndustry, theme: 'sun' },
      { id: StepEnum.AnnualRevenue, theme: 'purple' },
      { id: StepEnum.HowDidYouKnow, theme: 'sky' },
    ],
  };
};

type InitActiveStepId = (signUpType: SignUpType) => StepEnum;
const initActiveStepId: InitActiveStepId = (signUpType) => {
  const steps = getStepArrays();
  return steps[signUpType][0].id;
};

const initUserData: InitUserData = (initialData) => {
  const sp = new URLSearchParams(window.location.search);
  const defaultValue: { value: string; hubSpotValue: '' } = {
    value: '',
    hubSpotValue: '',
  };

  return {
    [StepEnum.UserDetails]: {
      firstName: { ...defaultValue },
      lastName: { ...defaultValue },
      websiteUrl: { ...defaultValue },
      companyRole: { ...defaultValue },
      email: { ...defaultValue },
      organizationId: {
        value: sp.get('organizationId') ?? '',
        hubSpotValue: sp.get('organizationId') ?? '',
      },
      organizationName: {
        value: sp.get('organizationName') ?? '',
        hubSpotValue: sp.get('organizationName') ?? '',
      },
      refundPolicy: {
        value: false,
      },
      ppAndTos: {
        value: false,
      },
      ...initialData?.[StepEnum.UserDetails],
    },
    [StepEnum.CompanyType]: {
      companyType: { ...defaultValue },
      ...initialData?.[StepEnum.CompanyType],
    },
    [StepEnum.AnnualRevenue]: {
      annualRevenue: { ...defaultValue },
      ...initialData?.[StepEnum.AnnualRevenue],
    },
    [StepEnum.HowDidYouKnow]: {
      howDidYouKnow: { ...defaultValue },
      ...initialData?.[StepEnum.HowDidYouKnow],
    },
    [StepEnum.CompanyIndustry]: {
      industry: { ...defaultValue },
      ...initialData?.[StepEnum.CompanyIndustry],
    },
  };
};

type InitValidityState = () => SignUpCompleteContextType['validity'];
const initValidityState: InitValidityState = () => {
  return {
    [SignUpType.Standard]: {
      [StepEnum.UserDetails]: { isValid: false, fields: {} },
    },
    [SignUpType.Survey]: {
      [StepEnum.CompanyType]: { isValid: false, fields: {} },
      [StepEnum.AnnualRevenue]: { isValid: false, fields: {} },
      [StepEnum.HowDidYouKnow]: { isValid: false, fields: {} },
      [StepEnum.CompanyIndustry]: { isValid: false, fields: {} },
    },
    [SignUpType.InviteTeammate]: {
      [StepEnum.UserDetails]: { isValid: false, fields: {} },
    },
  };
};

type CheckValidity = (
  userData: UserData,
  signUpType: SignUpType
) => SignUpCompleteContextType['validity'];
const checkValidity: CheckValidity = (userData, signUpType) => {
  const result: SignUpCompleteContextType['validity'] = initValidityState();

  const validator: Validator = {
    [SignUpType.Standard]: {
      [StepEnum.UserDetails]: () => {
        const ud = userData[StepEnum.UserDetails];
        const fields: Record<keyof UserData[StepEnum.UserDetails], boolean> = {
          firstName: Boolean(ud.firstName.value.trim()),
          lastName: Boolean(ud.lastName.value.trim()),
          companyRole: Boolean(ud.companyRole.value.trim()),
          organizationName: Boolean(ud.organizationName.value.trim()),
          websiteUrl: lightWebsiteUrl(ud.websiteUrl.value.trim()),
          email: Boolean(ud.email.value.trim()),
          ppAndTos: Boolean(ud.ppAndTos.value),
          refundPolicy: Boolean(ud.refundPolicy.value),
          organizationId: true,
        };
        const fieldsValidity: boolean[] = [
          fields.firstName,
          fields.lastName,
          fields.companyRole,
          fields.organizationName,
          fields.websiteUrl,
          fields.email,
          fields.organizationId,
          fields.ppAndTos,
          fields.refundPolicy,
        ];
        return {
          fields,
          isValid: fieldsValidity.every((isValid) => isValid),
        };
      },
    },
    [SignUpType.Survey]: {
      [StepEnum.CompanyType]: () => {
        return {
          isValid: Boolean(userData[StepEnum.CompanyType].companyType.value),
          fields: {},
        };
      },
      [StepEnum.AnnualRevenue]: () => {
        return {
          isValid: Boolean(userData[StepEnum.AnnualRevenue].annualRevenue.value),
          fields: {},
        };
      },
      [StepEnum.HowDidYouKnow]: () => {
        return {
          isValid: Boolean(userData[StepEnum.HowDidYouKnow].howDidYouKnow.value),
          fields: {},
        };
      },
      [StepEnum.CompanyIndustry]: () => {
        return {
          isValid: Boolean(userData[StepEnum.CompanyIndustry].industry.value),
          fields: {},
        };
      },
    },
    [SignUpType.InviteTeammate]: {
      [StepEnum.UserDetails]: () => {
        const fieldsValidity: boolean[] = [
          Boolean(userData[StepEnum.UserDetails].firstName.value),
          Boolean(userData[StepEnum.UserDetails].lastName.value),
          Boolean(userData[StepEnum.UserDetails].companyRole.value),
          Boolean(userData[StepEnum.UserDetails].ppAndTos.value),
          Boolean(userData[StepEnum.UserDetails].refundPolicy.value),
        ];
        return {
          isValid: fieldsValidity.every((isValid) => isValid),
          fields: {},
        };
      },
    },
  };
  // eslint-disable-next-line guard-for-in
  for (const key in validator[signUpType]) {
    const k = key as keyof Validator[keyof Validator];
    const fn = validator[signUpType][k];
    if (fn) {
      const validationResult = fn();
      result[signUpType][k] = {
        fields: validationResult.fields,
        isValid: validationResult.isValid,
      };
    }
  }
  return result;
};

type SendHubspotData = (userData: UserData, signUpType: SignUpType) => void;
const sendHubspotData: SendHubspotData = async (userData, signUpType) => {
  const availableTypesToSendEvents = new Set([SignUpType.Survey]);
  if (!availableTypesToSendEvents.has(signUpType)) return;

  const sp = new URLSearchParams(window.location.search);
  const data: IdentifyUserData = {
    email: userData[StepEnum.UserDetails].email.hubSpotValue,
    firstName: userData[StepEnum.UserDetails].firstName.hubSpotValue,
    lastName: userData[StepEnum.UserDetails].lastName.hubSpotValue,
    company: userData[StepEnum.UserDetails].organizationName.hubSpotValue,
    website: userData[StepEnum.UserDetails].websiteUrl.hubSpotValue,
    company_role_new: userData[StepEnum.UserDetails].companyRole.hubSpotValue,
    type_of_business: userData[StepEnum.CompanyType].companyType.hubSpotValue,
    industry_custom_: userData[StepEnum.CompanyIndustry].industry.hubSpotValue,
    annual_revenue: userData[StepEnum.AnnualRevenue].annualRevenue.hubSpotValue,
    received_recommendation: userData[StepEnum.HowDidYouKnow].howDidYouKnow.hubSpotValue,
    utm_campaign: sp.get('utm_campaign') ?? '',
    utm_source: sp.get('utm_source') ?? '',
    utm_medium: sp.get('utm_medium') ?? '',
    utm_term: sp.get('utm_term') ?? '',
  };
  identifyUser(data);
};

const sendAmplitudeEvents: SendAmplitudeEvents = {
  whenClickFinish: async (signUpType) => {
    switch (signUpType) {
      case SignUpType.Standard: {
        amplitude.sendEvent({
          id: '181',
          category: 'sign_up',
          name: 'sign_up_first_page',
          param: {},
        });
        break;
      }
      case SignUpType.Survey: {
        amplitude.sendEvent({
          id: '186',
          category: 'sign_up',
          name: 'sign_up_fifth_page',
          param: {},
        });
        break;
      }
      default: {
        break;
      }
    }
  },
  whenClickGoToNextStep: async (signUpType, activeStepIndex) => {
    if (signUpType === SignUpType.Survey) {
      switch (activeStepIndex) {
        case 0: {
          amplitude.sendEvent({
            id: '182',
            category: 'sign_up',
            name: 'sign_up_second_page',
            param: {},
          });
          break;
        }
        case 1: {
          amplitude.sendEvent({
            id: '183',
            category: 'sign_up',
            name: 'sign_up_third_page',
            param: {},
          });
          break;
        }
        case 2: {
          amplitude.sendEvent({
            id: '184',
            category: 'sign_up',
            name: 'sign_up_fourth_page',
            param: {},
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  },
};

type DoUpdateOrganizationWhenStepChange = (
  signUpType: SignUpType,
  activeStepId: StepEnum,
  userData: UserData
) => Promise<void>;
const doUpdateOrganizationWhenStepChange: DoUpdateOrganizationWhenStepChange = async (
  signUpType,
  activeStepId,
  userData
) => {
  if (signUpType === SignUpType.Survey) {
    const id = userData[StepEnum.UserDetails].organizationId.value;
    switch (activeStepId) {
      case StepEnum.CompanyType: {
        const businessDescription = userData[StepEnum.CompanyType].companyType.value;
        updateOrganization({ id, businessDescription });
        break;
      }
      case StepEnum.CompanyIndustry: {
        const industry = userData[StepEnum.CompanyIndustry].industry.value;
        updateOrganization({ id, industry });
        break;
      }
      case StepEnum.AnnualRevenue: {
        const annualRevenue = userData[StepEnum.AnnualRevenue].annualRevenue.value;
        updateOrganization({ id, annualRevenue });
        break;
      }
      case StepEnum.HowDidYouKnow: {
        const source = userData[StepEnum.HowDidYouKnow].howDidYouKnow.value;
        updateOrganization({ id, source });
        break;
      }
      default: {
        break;
      }
    }
  }
};

export {
  initUserData,
  initValidityState,
  checkValidity,
  getStepArrays,
  initActiveStepId,
  sendHubspotData,
  sendAmplitudeEvents,
  doUpdateOrganizationWhenStepChange,
};

// types

type Validator = Record<
  SignUpType,
  Partial<Record<StepEnum, () => { isValid: boolean; fields: Record<string, boolean> }>>
>;
type IdentifyUserData = NonNullable<Parameters<typeof identifyUser>[0]>;
type InitialData = UserData extends { [K in infer U]: infer V }
  ? { [key in U]?: Partial<V> }
  : never;
type InitUserData = (initialData?: InitialData) => UserData;
type SendAmplitudeEvents = {
  whenClickGoToNextStep(signUpType: SignUpType, activeStepIndex: number): void;
  whenClickFinish(signUpType: SignUpType): void;
};

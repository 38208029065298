/**
 * @generated SignedSource<<194fd42847ff13e93d33caafcd0a3cb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Stripe_CreateSetupIntentInput = {
  clientMutationId?: string | null | undefined;
};
export type CreateSetupIntentMutation$variables = {
  input: Stripe_CreateSetupIntentInput;
};
export type CreateSetupIntentMutation$data = {
  readonly stripe: {
    readonly createSetupIntent: {
      readonly setupIntent: {
        readonly clientSecret: string;
      };
    } | null | undefined;
  } | null | undefined;
};
export type CreateSetupIntentMutation = {
  response: CreateSetupIntentMutation$data;
  variables: CreateSetupIntentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientSecret",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSetupIntentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Stripe_Mutation",
        "kind": "LinkedField",
        "name": "stripe",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Stripe_CreateSetupIntentPayload",
            "kind": "LinkedField",
            "name": "createSetupIntent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_SetupIntent",
                "kind": "LinkedField",
                "name": "setupIntent",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSetupIntentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Stripe_Mutation",
        "kind": "LinkedField",
        "name": "stripe",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Stripe_CreateSetupIntentPayload",
            "kind": "LinkedField",
            "name": "createSetupIntent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_SetupIntent",
                "kind": "LinkedField",
                "name": "setupIntent",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f75f8694f5caecc46a1fad2715e6ca79",
    "id": null,
    "metadata": {},
    "name": "CreateSetupIntentMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSetupIntentMutation(\n  $input: Stripe_CreateSetupIntentInput!\n) {\n  stripe {\n    createSetupIntent(input: $input) {\n      setupIntent {\n        clientSecret\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "019600d6d64739618aa2c1809ed2ec0a";

export default node;

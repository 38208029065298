/**
 * @generated SignedSource<<e84233b10648729ebdfe9e69b27a1cd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BriefCreativeContentFormat = "HORIZONTAL_16_9" | "SQUARE" | "STATIC" | "VERTICAL_4_5" | "VERTICAL_9_16" | "%future added value";
export type BriefCreativeContentPreparedness = "FINAL" | "RAW" | "RAW_AND_FINAL" | "%future added value";
export type BriefCreativeContentType = "CUSTOM" | "HOWTO" | "PRODUCT_DEMO" | "PRODUCT_REVIEW" | "TESTIMONIAL" | "UNBOXING" | "%future added value";
export type BriefCreativeMediaType = "PHOTO" | "VIDEO" | "%future added value";
export type ContentType = "BOOMERANG" | "HORIZONTAL_VIDEO" | "IMAGE" | "SQUARE_VIDEO" | "VERTICAL_VIDEO" | "%future added value";
export type V2ContentPlacement = "FEED" | "MIDDLEROLL" | "NONE" | "POSTROLL" | "PREROLL" | "REEL" | "STORY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreativeAsset_briefCreative$data = {
  readonly contentAmount: number | null | undefined;
  readonly contentFormat: BriefCreativeContentFormat | null | undefined;
  readonly contentMusic: boolean;
  readonly contentMusicDescription: string | null | undefined;
  readonly contentPreparedness: BriefCreativeContentPreparedness | null | undefined;
  readonly contentType: ContentType | null | undefined;
  readonly createdAt: any;
  readonly donts: string | null | undefined;
  readonly dos: string | null | undefined;
  readonly id: string;
  readonly mediaType: BriefCreativeMediaType | null | undefined;
  readonly postCaption: string | null | undefined;
  readonly publishingRequired: boolean | null | undefined;
  readonly v2ContentPlacement: V2ContentPlacement | null | undefined;
  readonly v2ContentType: BriefCreativeContentType | null | undefined;
  readonly videoDurationMax: number | null | undefined;
  readonly videoDurationMin: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AssetPreview_briefCreative" | "References_briefCreative">;
  readonly " $fragmentType": "CreativeAsset_briefCreative";
};
export type CreativeAsset_briefCreative$key = {
  readonly " $data"?: CreativeAsset_briefCreative$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreativeAsset_briefCreative">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreativeAsset_briefCreative",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dos",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "donts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postCaption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishingRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v2ContentPlacement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoDurationMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoDurationMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentPreparedness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentMusic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentMusicDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v2ContentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "References_briefCreative"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetPreview_briefCreative"
    }
  ],
  "type": "BriefCreative",
  "abstractKey": null
};

(node as any).hash = "8fcb0e84472d5b261abb423319c878bc";

export default node;

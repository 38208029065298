import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import {
  CreatePublicationEventMutation,
  CreatePublicationEventMutation$data,
  CreatePublicationEventInput,
} from 'GraphTypes/CreatePublicationEventMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation CreatePublicationEventMutation($input: CreatePublicationEventInput!) {
    createPublicationEvent(input: $input) {
      clientMutationId
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: CreatePublicationEventInput,
  resolve?: (response: CreatePublicationEventMutation$data) => void,
  reject?: FnType
) => {
  const id = uuid();
  const variables = {
    input: {
      id,
      ...data,
    },
  };

  commitMutation<CreatePublicationEventMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

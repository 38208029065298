const fileNameLimit = 20;
const hiddenSymbolsToken = '…';

export const getFileName = (url: string) => {
  const parts = url.split('/');
  const fileName = parts[parts.length - 1];

  if (fileName.length <= fileNameLimit) return fileName;

  const partLength = fileNameLimit / 2;
  const indexEnd = fileName.length;

  const prefix = fileName.substring(0, partLength);
  const postfix = fileName.substring(indexEnd - partLength, indexEnd);

  return `${prefix}${hiddenSymbolsToken}${postfix}`;
};
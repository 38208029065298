import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import classNames from 'classnames';

import styles from './SignUpComplete.pcss';
import { StepEnum, Steps } from './Steps';
import { Progress } from './Progress';
import { SignUpCompleteContext, SignUpType, UserData, withSignUpCompleteProvider } from './Context';

import Page from 'Templates/Page/Page';
import Header from 'Templates/Layout/Header/Header';

type Props = {
  signUpType?: SignUpType;
};

const SignUpComplete: React.FC<Props> = withSignUpCompleteProvider<Props>(() => {
  const { activeStep, totalSteps, signUpType, activeStepIndex, setUserData } =
    useContext(SignUpCompleteContext);

  const hasProgress = totalSteps > 1;

  const isBackgroundVisible = useMemo(() => {
    return (
      (signUpType === SignUpType.Standard || signUpType === SignUpType.InviteTeammate) &&
      activeStepIndex === 0
    );
  }, [signUpType, activeStepIndex]);

  return (
    <Page>
      <Header
        classes={{
          root: styles.header,
          wrap: cn(styles.wrap, {
            [styles.themePink]: activeStep.theme === 'pink',
            [styles.themeSun]: activeStep.theme === 'sun',
            [styles.themePurple]: activeStep.theme === 'purple',
            [styles.themeSky]: activeStep.theme === 'sky',
          }),
        }}
      />
      <div
        className={classNames(styles.content, {
          [styles.withProgress]: hasProgress,
          [styles.themePink]: !isBackgroundVisible && activeStep.theme === 'pink',
          [styles.themeSun]: !isBackgroundVisible && activeStep.theme === 'sun',
          [styles.themePurple]: !isBackgroundVisible && activeStep.theme === 'purple',
          [styles.themeSky]: !isBackgroundVisible && activeStep.theme === 'sky',
        })}
      >
        {hasProgress && <Progress />}
        <Steps className={styles.steps} />
      </div>
      {isBackgroundVisible && (
        <div
          className={classNames(styles.backgroundImage, {
            [styles.withProgress]: hasProgress,
            [styles.themePink]: activeStep.theme === 'pink',
            [styles.themeSun]: activeStep.theme === 'sun',
            [styles.themePurple]: activeStep.theme === 'purple',
            [styles.themeSky]: activeStep.theme === 'sky',
          })}
        />
      )}
    </Page>
  );
});

export default SignUpComplete;
export { SignUpComplete };

// types

import { ControlItem } from '../components/SegmentedControls/SegmentedControls';

import { CreatorsDetails_campaign$data } from 'GraphTypes/CreatorsDetails_campaign.graphql';
import { CampaignPostingType } from 'GraphTypes/Campaign_campaign.graphql';
import { CampaignPlatform, CampaignType } from 'GraphTypes/CampaignQuery.graphql';
import { UpdateSearchQueryInput } from 'GraphTypes/UpdateSearchQueryMutation.graphql';
import { NullableType } from 'Types/common';

export const getPrivacyList = (data: { campaignId: string }): ControlItem[] => {
  const { campaignId } = data;

  return [
    {
      id: 'public',
      value: false,
      titleMsg: 'brief_template.field.creators.privacy.public',
      tooltipMsg: 'brief_template.field.creators.privacy.public.tooltip',
      dataTest: 'button:creatorsPrivacy:public',
      tooltipEventData: {
        id: '77',
        category: 'brief',
        name: 'campaign_privacy_info_tooltip_show',
        param: { info: 'public', campaignId },
      },
    },
    {
      id: 'private',
      value: true,
      titleMsg: 'brief_template.field.creators.privacy.private',
      tooltipMsg: 'brief_template.field.creators.privacy.private.tooltip',
      dataTest: 'button:creatorsPrivacy:private',
      tooltipEventData: {
        id: '77',
        category: 'brief',
        name: 'campaign_privacy_info_tooltip_show',
        param: { info: 'private', campaignId },
      },
    },
  ];
};

export const checkValidation = (campaign: CreatorsDetails_campaign$data): boolean => {
  const { private: privateValue } = campaign;

  return !!(privateValue !== null);
};

type ConditionType = {
  campaign: CreatorsDetails_campaign$data;
};

export const getViewConditions = ({ campaign }: ConditionType) => {
  const isPublic = campaign.private === false;

  return {
    paramsSearch: isPublic,
    lookalikes: isPublic,
    campaignExclusion: isPublic,
  };
};

export const getSuggestedFiltersConfig = ({
  isPrivate,
  postingType,
  platform,
  type,
  searchQuery,
}: {
  isPrivate: NullableType<boolean>;
  postingType: NullableType<CampaignPostingType>;
  platform: NullableType<CampaignPlatform>;
  type: NullableType<CampaignType>;
  searchQuery: NonNullable<CreatorsDetails_campaign$data>['searchQuery'];
}): Omit<UpdateSearchQueryInput, 'id'> & { analyticId?: number } => {
  if (postingType === 'ORGANIC_POSTING' && platform === 'INSTAGRAM' && !isPrivate) {
    return {
      followersFrom: 3000,
      engagementRateFrom: 0.01,
      viewsMedianFrom: null,
      analyticId: 1,
    };
  }
  if (postingType === 'ORGANIC_POSTING' && platform === 'TIKTOK' && !isPrivate) {
    return {
      viewsMedianFrom: 1000,
      engagementRateFrom: 0.03,
      followersFrom: searchQuery?.followersFrom === 3000 ? null : undefined,
      analyticId: 2,
    };
  }
  if (type === 'PRODUCT_SEEDING' && platform === 'INSTAGRAM' && !isPrivate) {
    return {
      followersFrom: 1000,
      engagementRateFrom: 0.01,
      viewsMedianFrom: null,
      analyticId: 3,
    };
  }
  if (type === 'PRODUCT_SEEDING' && platform === 'TIKTOK' && !isPrivate) {
    return {
      engagementRateFrom: 0.03,
      followersFrom: null,
      viewsMedianFrom: null,
      analyticId: 4,
    };
  }

  return {};
};

import React from 'react';

import styles from './StagesFilter.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { TooltipType } from 'Types/common';

interface Props {
  className?: string;
  stages: string[];
  projectStage?: string;
  onProjectStageChange: (stage?: string[]) => void;
}

const StagesFilter: React.FC<Props> = (props) => {
  const { projectStage, stages, onProjectStageChange } = props;

  const list = stages.map((item) => {
    const onClick = () => {
      onProjectStageChange([item]);
    };

    const prefix = item === 'ACCEPTED' ? '_new' : '';

    return {
      id: item,
      onClick,
      msg: `project.stage.${item.toLowerCase()}${prefix}`,
    };
  });

  const prefixLabel = projectStage === 'ACCEPTED' ? '_new' : '';

  const label =
    typeof projectStage === 'string'
      ? `project.stage.${projectStage?.toLowerCase()}${prefixLabel}`
      : 'project.stage.default';

  const handleClear = () => {
    onProjectStageChange();
  };

  const tooltipData: TooltipType = {
    id: 'statuses',
    tooltipMsg: 'creators.filter.status',
    place: 'top',
  };

  return (
    <Dropdown value={<Text type="md" msg={label} />} tooltipData={tooltipData}>
      <DropdownGroup labelMsg="project.stage.title" className={styles.group}>
        <div>
          <DropdownGroupItem handleClick={handleClear}>
            <AlterButton
              msg="project.stage.default"
              fluid
              iconPosition="right"
              icon={!projectStage ? 'Check' : null}
            />
          </DropdownGroupItem>
          {list?.map((item) => {
            if (!item) return null;

            const isActive = item.id === projectStage ? 'Check' : null;

            return (
              <DropdownGroupItem key={item.id} handleClick={item?.onClick}>
                <AlterButton
                  msg={item.msg}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );
};

export default StagesFilter;

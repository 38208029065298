import React, { useState, useEffect, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import debounce from 'lodash/debounce';
import isDate from 'lodash/isDate';

import styles from './CreatorBirthdate.css';

import { allMonths, defineDate } from 'Util/dateCreator';
import Field from 'Molecules/Field/Field';
import Text from 'Atoms/Text/Text';
import { CreatorBirthdate_birthdate$key } from 'GraphTypes/CreatorBirthdate_birthdate.graphql';

interface Props {
  profile: CreatorBirthdate_birthdate$key;
  onChange: (data: { birthdate: string }) => void;
}

type CreatorMonthSelectValueList = {
  value: number;
  label: string;
  text: string;
}[];

type CreatorYearSelectValueList = {
  value: number;
  label: number;
  text: number;
}[];

const CreatorBirthdate: React.FC<Props> = (props) => {
  const { profile, onChange } = props;

  const data = useFragment(
    graphql`
      fragment CreatorBirthdate_birthdate on CreatorProfile {
        currency
        birthdate
      }
    `,
    profile
  );
  const birthdate = data?.birthdate;
  const currency = data?.currency;

  const date = birthdate ? new Date(Date.parse(birthdate)) : undefined;
  const defaultBirthdateYear = isDate(date) ? date.getUTCFullYear() : undefined;
  const defaultBirthdateMonth = isDate(date) ? date.getUTCMonth() + 1 : undefined;
  const defaultBirthdateDay = isDate(date) ? date.getUTCDate() : undefined;

  const [year, setYear] = useState<number | undefined>(defaultBirthdateYear);
  const [month, setMonth] = useState<number | undefined>(defaultBirthdateMonth);
  const [day, setDay] = useState<number | undefined>(defaultBirthdateDay);

  useEffect(() => {
    if (!year || !month || !day) return;
    const newDate = defineDate([year, month - 1, day].slice());
    onChange({ birthdate: newDate });
  }, [year, month, day]);

  const monthsList = useMemo<CreatorMonthSelectValueList>(
    () =>
      allMonths.map((item, i) => {
        return { value: i + 1, label: item, text: item };
      }),
    []
  );

  const yearList = useMemo<CreatorYearSelectValueList>(
    () =>
      [...Array(100)].map((item, i) => {
        const yearVal = new Date().getFullYear() - 18 - i;
        return { value: yearVal, label: yearVal, text: yearVal };
      }),
    []
  );

  const handleBirthdateChange = debounce((data) => {
    if (data.birthdateDay) {
      setDay(data.birthdateDay);
    } else if (data.birthdateYear) {
      setYear(data.birthdateYear);
    }
  }, 500);

  const handleMonthChange = (data: { birthdateMonth: number }) => {
    setMonth(data.birthdateMonth);
  };

  const currencyStyle = currency === 'USD' ? styles.isUsd : '';

  return (
    <div className={styles.root}>
      <Text size="sm" color="grayDog" msg="creator_profile.birthdate" className={styles.title} />
      <div className={`${styles.fields} ${currencyStyle}`}>
        <Field
          element="input"
          elementData={{
            defaultValue: day,
            placeholderMsg: 'creator_profile.birthdate.day',
            onChange: handleBirthdateChange,
          }}
          name="birthdateDay"
          className={`${styles.field} ${styles.day}`}
        />
        <Field
          element="select"
          elementData={{
            options: monthsList,
            value: month,
            placeholderMsg: 'creator_profile.birthdate.month',
            onChange: handleMonthChange,
            hideSelectedOptions: false,
          }}
          name="birthdateMonth"
          className={`${styles.field} ${styles.month}`}
        />
        <Field
          element="select"
          elementData={{
            options: yearList,
            value: year,
            placeholderMsg: 'creator_profile.birthdate.year',
            onChange: handleBirthdateChange,
            hideSelectedOptions: false,
          }}
          name="birthdateYear"
          className={`${styles.field} ${styles.year}`}
        />
      </div>
    </div>
  );
};

export default CreatorBirthdate;

type WaitUntil = (fn: () => boolean) => Promise<void>;
const waitUntil: WaitUntil = (fn) => {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (fn()) {
        clearInterval(intervalId);
        resolve();
      }
    }, 0);
  });
};

export { waitUntil };

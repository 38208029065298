import { CampaignCreatives_campaign } from 'GraphTypes/CampaignCreatives_campaign.graphql';
import { createShortNumber } from 'Util/numberFormatter';

export const getStatData = (data: CampaignCreatives_campaign) => {
  const { counters, creatives, platform } = data;
  const totalCount = creatives.totalCount;
  const publicationsEngagementSum = counters?.publicationsEngagementSum;
  const publicationsViewsSum = counters?.publicationsViewsSum;
  const campaignPostingBudget = counters?.campaignPostingBudget;

  const tooltipPrefix = platform === 'TIKTOK' ? '.tiktok' : '.ig';

  const cpm =
    campaignPostingBudget && publicationsViewsSum
      ? (campaignPostingBudget / publicationsViewsSum) * 1000
      : undefined;
  const cpe =
    campaignPostingBudget && publicationsEngagementSum
      ? campaignPostingBudget / publicationsEngagementSum
      : undefined;

  const createSum = (value: number) => {
    return parseFloat(value.toFixed(2));
  };

  return [
    {
      key: 'totalCount',
      value: totalCount,
      titleMsg: 'campaign.results.stats.amount',
      tooltipMsg: `campaign.results.stats.amount.tooltip${tooltipPrefix}`,
    },
    {
      key: 'views',
      value: publicationsViewsSum ? createShortNumber(publicationsViewsSum) : '-',
      titleMsg: 'campaign.results.stats.views',
      tooltipMsg: `campaign.results.stats.views.tooltip${tooltipPrefix}`,
    },
    {
      key: 'engagement',
      value: publicationsEngagementSum ? createShortNumber(publicationsEngagementSum) : '-',
      titleMsg: 'campaign.results.stats.engagement',
      tooltipMsg: `campaign.results.stats.engagement.tooltip${tooltipPrefix}`,
    },
    {
      key: 'budget',
      value: campaignPostingBudget ? createSum(campaignPostingBudget) : '-',
      titleMsg: 'campaign.results.stats.budget',
      tooltipMsg: `campaign.results.stats.budget.tooltip${tooltipPrefix}`,
    },
    {
      key: 'cpm',
      value: cpm ? createSum(cpm) : '-',
      titleMsg: 'campaign.results.stats.cpm',
      tooltipMsg: `campaign.results.stats.cpm.tooltip${tooltipPrefix}`,
    },
    {
      key: 'cpe',
      value: cpe ? createSum(cpe) : '-',
      titleMsg: 'campaign.results.stats.cpe',
      tooltipMsg: `campaign.results.stats.cpe.tooltip${tooltipPrefix}`,
    },
  ];
};

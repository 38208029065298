import React, { Component, Fragment } from 'react';

import styles from './SingleValue.css';

import Text from 'Atoms/Text/Text';

class SingleValue extends Component {
  contentTypeItem() {
    const {
      data: { label, icon },
    } = this.props;

    return (
      <Fragment>
        {icon && <img src={icon} className={styles.icon} />}
        <Text
          msg={label}
          className={styles.content}
        />
      </Fragment>
    );
  }

  getContent() {
    const { data: { type, label } } = this.props;
    switch (type) {
      case 'type':
      case 'reason':
      case 'searchable':
      case 'contentType':
      case 'influencerType':
      case 'reviewedByAdmin':
      case 'newObjective':
      case 'v2ContentPlacement': {
        return this.contentTypeItem();
      }
      default:
        return <div className={styles.label}>{label}</div>;
    }
  }

  render() {
    const {
      className,
      innerProps,
    } = this.props;

    const content = this.getContent();

    return (
      <div
        className={`${className} ${styles.root}`}
        {...innerProps}
      >
        {content}
      </div>
    );
  }
}

export default SingleValue;

/**
 * @generated SignedSource<<c03accb63143ad24dc5acf2ed456fa3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Brief_ScreeningQuestion_Type = "BINARY" | "OPEN_ENDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScreeningQuestions_brief$data = {
  readonly id?: string;
  readonly screeningQuestions?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly preferredValue: boolean | null | undefined;
        readonly text: string;
        readonly type: Brief_ScreeningQuestion_Type;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ScreeningQuestions_brief";
};
export type ScreeningQuestions_brief$key = {
  readonly " $data"?: ScreeningQuestions_brief$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScreeningQuestions_brief">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "screeningQuestions"
        ]
      }
    ]
  },
  "name": "ScreeningQuestions_brief",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "screeningQuestions",
          "args": null,
          "concreteType": "BriefScreeningQuestionConnection",
          "kind": "LinkedField",
          "name": "__ScreeningQuestions_screeningQuestions_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BriefScreeningQuestionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefScreeningQuestion",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "preferredValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "text",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "V2Brief",
      "abstractKey": null
    }
  ],
  "type": "Brief",
  "abstractKey": "__isBrief"
};
})();

(node as any).hash = "2dcec4f0cd6af1f57da4c2ad2a52eb02";

export default node;

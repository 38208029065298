const initGrowsumo = () => {
  const gs = document.createElement('script');
  gs.src = 'https://snippet.growsumo.com/growsumo.min.js';
  gs.type = 'text/javascript';
  gs.async = true;
  gs.onload = gs.onreadystatechange = function () {
    const rs = this.readyState;
    if (rs && rs !== 'complete' && rs !== 'loaded') return;
    try {
      growsumo._initialize(process.env.GROWSUMO_KEY);
      if (typeof growsumoInit === 'function') {
        growsumoInit();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(gs, s);
};

export default initGrowsumo;
export { initGrowsumo };

/**
 * @generated SignedSource<<2781da76cb277fa7ed5511cdf505cfab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Brief_ScreeningQuestion_Type = "BINARY" | "OPEN_ENDED" | "%future added value";
export type UpdateBriefScreeningQuestionInput = {
  clientMutationId?: string | null | undefined;
  screeningQuestion: BriefScreeningQuestionInput;
};
export type BriefScreeningQuestionInput = {
  id?: string | null | undefined;
  preferredValue?: boolean | null | undefined;
  text: string;
  type: Brief_ScreeningQuestion_Type;
  v2BriefId: string;
};
export type UpdateBriefScreeningQuestionMutation$variables = {
  input: UpdateBriefScreeningQuestionInput;
};
export type UpdateBriefScreeningQuestionMutation$data = {
  readonly updateBriefScreeningQuestion: {
    readonly screeningQuestion: {
      readonly id: string;
      readonly preferredValue: boolean | null | undefined;
      readonly text: string;
      readonly type: Brief_ScreeningQuestion_Type;
    };
  } | null | undefined;
};
export type UpdateBriefScreeningQuestionMutation = {
  response: UpdateBriefScreeningQuestionMutation$data;
  variables: UpdateBriefScreeningQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBriefScreeningQuestionPayload",
    "kind": "LinkedField",
    "name": "updateBriefScreeningQuestion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BriefScreeningQuestion",
        "kind": "LinkedField",
        "name": "screeningQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "preferredValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBriefScreeningQuestionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBriefScreeningQuestionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "081309b0e56830820351f7ad5d9dfdd4",
    "id": null,
    "metadata": {},
    "name": "UpdateBriefScreeningQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBriefScreeningQuestionMutation(\n  $input: UpdateBriefScreeningQuestionInput!\n) {\n  updateBriefScreeningQuestion(input: $input) {\n    screeningQuestion {\n      id\n      preferredValue\n      text\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40a475d49adc03131f176589e0ad5cf2";

export default node;

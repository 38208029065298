export const PERCENTAGE_OPTIONS = [
  { value: 0.01, id: 0.01, name: '>1%' },
  { value: 0.05, id: 0.05, name: '>5%' },
  { value: 0.1, id: 0.1, name: '>10%' },
  { value: 0.15, id: 0.15, name: '>15%' },
  { value: 0.2, id: 0.2, name: '>20%' },
  { value: 0.25, id: 0.25, name: '>25%' },
  { value: 0.3, id: 0.3, name: '>30%' },
  { value: 0.35, id: 0.35, name: '>35%' },
  { value: 0.4, id: 0.4, name: '>40%' },
  { value: 0.45, id: 0.45, name: '>45%' },
  { value: 0.5, id: 0.5, name: '>50%' },
  { value: 0.55, id: 0.55, name: '>55%' },
  { value: 0.6, id: 0.6, name: '>60%' },
  { value: 0.65, id: 0.65, name: '>65%' },
  { value: 0.7, id: 0.7, name: '>70%' },
  { value: 0.75, id: 0.75, name: '>75%' },
  { value: 0.8, id: 0.8, name: '>80%' },
  { value: 0.85, id: 0.85, name: '>85%' },
  { value: 0.9, id: 0.9, name: '>90%' },
  { value: 0.95, id: 0.95, name: '>95%' },
];

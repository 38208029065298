/* eslint-disable no-console */
import React, { useState, useCallback, useEffect, Context, PropsWithChildren } from 'react';

import { GuideTourContextType } from './GuideTour.Context';
import { GuideTourTooltip, GuideTourTooltipProps } from './GuideTourTooltip/GuideTourTooltip';
import {
  assertion,
  hideStepsInState,
  initToursState,
  setCompleteStepToState,
  setNextStepVisibleToState,
  goToNextStepInState,
  writeLocalStorageState,
  finishGuideTourToState,
  resetGuideTourToState,
} from './utils';
import type {
  GuideToursMap,
  GuideToursState,
  GuideTourStepName,
  CompleteStep,
  GoToNextStep,
} from './types';

type Props = {
  guideTours: GuideToursMap;
  context: Context<GuideTourContextType>;
};

const GuideTourProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, guideTours, context } = props;

  const [isGuideTourInited, setIsGuideTourInited] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [guideToursState, setGuideToursState] = useState<GuideToursState>({});

  useEffect(() => {
    if (userId) {
      setGuideToursState(initToursState({ guideTours, currentUserId: userId }));
      setIsGuideTourInited(true);
    }
  }, [guideTours, userId]);

  const _completeStep: CompleteStep = ({ guideTourId, stepName }) => {
    setGuideToursState((prev) => {
      const isAutoMode = prev[guideTourId].mode === 'auto';
      const result = setCompleteStepToState({ guideTourId, stepName }, prev);
      setTimeout(() => writeLocalStorageState(userId, result), 0);
      return isAutoMode ? setNextStepVisibleToState({ guideTourId }, result) : result;
    });
  };

  const _handleCloseTooltip = useCallback<NonNullable<GuideTourTooltipProps['onClose']>>(
    (e, params) => {
      const { guideTourId, stepName } = params;
      _completeStep({ guideTourId, stepName, currentUserId: userId });
    },
    [userId]
  );

  const finishGuideTour = useCallback<NonNullable<GuideTourTooltipProps['onComplete']>>(
    (e, params) => {
      setGuideToursState((prev) => {
        const { guideTourId } = params;
        const result = finishGuideTourToState({ guideTourId }, prev);
        setTimeout(() => writeLocalStorageState(userId, result), 0);
        return result;
      });
    },
    [userId]
  );

  const getTooltipComponent: GuideTourContextType['getTooltipComponent'] = (
    guideTourId,
    stepName
  ) => {
    if (!isGuideTourInited) return null;
    const guideTour = guideToursState[guideTourId];
    const step = guideTour ? guideTour.steps[stepName] : null;
    assertion.guideTourNotFound(guideTour, guideTourId);
    console.assert(
      step,
      `Step with name "${stepName}" not found in guide with id "${guideTourId}"`
    );
    if (!guideTour || !step) return null;
    const tooltipProps = guideTours.get(guideTourId)?.steps?.get(stepName)?.tooltipProps;
    return (props) => {
      return (
        <GuideTourTooltip
          {...tooltipProps}
          {...props}
          visible={guideToursState[guideTourId].steps[stepName].visible}
          guideTourId={guideTourId}
          stepName={stepName}
          onClose={(e, p) => {
            _handleCloseTooltip(e, p);
            props?.onClose?.(e, p);
          }}
          onComplete={(e, p) => {
            finishGuideTour(e, p);
          }}
        />
      );
    };
  };

  const isGuideTourFinished: GuideTourContextType['isGuideTourFinished'] = (guideTourId) => {
    const guideTour = guideToursState[guideTourId];
    assertion.guideTourNotFound(guideTour, guideTourId);
    if (!guideTour) return false;
    const stepNames = Object.keys(guideTour.steps) as GuideTourStepName[];
    return stepNames.every((stepName) => guideTour.steps[stepName].complete);
  };

  const isGuideTourExists: GuideTourContextType['isGuideTourExists'] = (guideTourId) => {
    const guideTour = guideToursState[guideTourId];
    return Boolean(guideTour);
  };

  const isGuideTourFinishedOrDoesntExist: GuideTourContextType['isGuideTourFinishedOrDoesntExist'] =
    (guideTourId) => {
      return !isGuideTourExists(guideTourId) || isGuideTourFinished(guideTourId);
    };

  const isGuideTourRunning: GuideTourContextType['isGuideTourRunning'] = (guideTourId) => {
    const guideTour = guideToursState[guideTourId];
    if (!guideTour) return false;
    const stepNames = Object.keys(guideTour.steps) as GuideTourStepName[];
    return stepNames.some((stepName) => guideTour.steps[stepName].visible);
  };

  const resetGuideTour: GuideTourContextType['resetGuideTour'] = (guideTourId) => {
    const guideTour = guideToursState[guideTourId];
    assertion.guideTourNotFound(guideTour, guideTourId);
    if (!guideTour) return;
    setGuideToursState(() => {
      const result = resetGuideTourToState({ guideTourId }, guideToursState);
      setTimeout(() => writeLocalStorageState(userId, result), 0);
      return result;
    });
  };

  const goToStep: GuideTourContextType['goToStep'] = (guideTourId, stepName) => {
    // const isManualMode = guideToursState[guideTourId]?.mode === 'manual';
    assertion.guideTourNotFound(guideToursState[guideTourId], guideTourId);
    // if (guideToursState[guideTourId]) {
    //   console.assert(isManualMode, 'Method goToStep is available in "manual" mode only');
    // }
    console.assert(
      guideToursState[guideTourId]?.steps?.[stepName],
      `Step with name "${stepName}" not found in guide with id "${guideTourId}"`
    );
    if (
      !guideToursState[guideTourId] ||
      // !isManualMode ||
      !guideToursState[guideTourId]?.steps?.[stepName]
    ) {
      return false;
    }
    const isCompletedStep = guideToursState[guideTourId].steps[stepName].complete;
    if (isCompletedStep) return false;
    setGuideToursState((prev) => {
      const clone = hideStepsInState({ guideTourId }, prev);
      clone[guideTourId].steps[stepName].visible = true;
      return clone;
    });
    return true;
  };

  const _goToNextStep: GoToNextStep = (guideTourId, reverse) => {
    const isManualMode = guideToursState[guideTourId]?.mode === 'manual';
    console.assert(guideToursState[guideTourId], `Guide your with name "${guideTourId}" not found`);
    if (guideToursState[guideTourId]) {
      console.assert(isManualMode, 'Method goToNextStep is available in "manual" mode only');
    }
    if (!guideToursState[guideTourId] || !isManualMode) return false;
    const [success, newState] = goToNextStepInState({ guideTourId }, guideToursState, reverse);
    if (success) {
      setGuideToursState(newState);
    }
    return success;
  };

  const goToNextStep: GuideTourContextType['goToNextStep'] = (guideTourId) => {
    return _goToNextStep(guideTourId, false);
  };

  const goToPrevStep: GuideTourContextType['goToPrevStep'] = (guideTourId) => {
    return _goToNextStep(guideTourId, true);
  };

  const isTooltipVisible: GuideTourContextType['isTooltipVisible'] = (guideTourId, stepName) => {
    const guideTour = guideToursState[guideTourId];
    const step = guideTour ? guideTour.steps[stepName] : null;
    return step ? step.visible : false;
  };

  const contextValue: GuideTourContextType = {
    isGuideTourFinishedOrDoesntExist,
    getTooltipComponent,
    isGuideTourFinished,
    isGuideTourRunning,
    isGuideTourExists,
    isTooltipVisible,
    resetGuideTour,
    goToNextStep,
    goToPrevStep,
    goToStep,
    setUserId,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export { GuideTourProvider };

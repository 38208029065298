import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import moment from 'moment';

import styles from './AdminAdsManager.pcss';

import { creatorFbPageLink } from 'Util/links';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import verifyPaidSocialRights from 'Mutations/VerifyPaidSocialRights.Mutation';
import activatePaidSocialForInsense from 'Mutations/ActivatePaidSocialForInsense.Mutation';
import { AdminAdsManagerQuery as QueryType } from 'GraphTypes/AdminAdsManagerQuery.graphql';
import createDate from 'Util/dateCreator';
import { CONTENT, INSTAGRAM } from 'Constants/general';
import AvatarItem from 'Molecules/AvatarItem/AvatarItem';
import stubImg from 'Images/chat/cabinetStub.png';

const AdminAdsManagerQuery = graphql`
  query AdminAdsManagerQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      creator {
        id
        type
        ... on InstagramCreator {
          paidSocialEnabled
          paidSocialRightsVerification {
            verifiedAt
            success
            project {
              id
            }
          }
          paidSocialActivationForInsense {
            createdAt
            expiresAt
            facebookPageId
            facebookPageName
            facebookPagePictureUrl
            facebookBusinessId
            facebookBusinessName
          }
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  projectId: string;
}

const AdminAdsManager: React.FC<Props> = (props) => {
  const { projectId, className } = props;

  const data = useLazyLoadQuery<QueryType>(AdminAdsManagerQuery, { projectId });

  const handleAdsChecked = (creatorId?: string) => {
    if (!creatorId) return;
    handleVerifyRights(creatorId, true);
  };

  const handleAdsRestricted = (creatorId?: string) => {
    if (!creatorId) return;
    handleVerifyRights(creatorId, false);
  };

  const handleVerifyRights = (creatorId: string, success: boolean) => {
    verifyPaidSocialRights({ creatorId, projectId, success });
  };

  const handleConnectToInsense = (creatorId?: string) => {
    if (!creatorId) return;
    activatePaidSocialForInsense({ projectId, creatorId });
  };

  if (!data || !data.project) return null;

  const project = data.project;
  const creatorId = project?.creator?.id;
  const verification = project?.creator?.paidSocialRightsVerification;
  const verificationData = project?.creator?.paidSocialActivationForInsense;
  const verifiedAt = verification?.verifiedAt;
  const success = verification?.success;
  const paidSocialEnabled = project?.creator?.paidSocialEnabled;
  const date = createDate(verifiedAt);

  const connectionExpired = moment(date).isBefore(moment(), 'day');
  const connectionExpiredDate = createDate(verificationData?.expiresAt);
  const connectionCreatedDate = createDate(verificationData?.createdAt);
  const needableCreatorVerification =
    project?.creator?.type === INSTAGRAM || project?.creator?.type === CONTENT;
  if (!needableCreatorVerification) {
    return null;
  }

  return (
    <div className={className}>
      <header className={styles.header}>
        <Text
          weight="500"
          transform="uppercase"
          msg="project.admin_ads_manager.title"
          className={styles.title}
        />
      </header>
      <Tooltip
        id="admin_ps_connection_info"
        tooltipMsg={
          connectionExpired
            ? 'tooltip.ps_able_to_connect_to_insense'
            : 'tooltip.ps_connected_to_insense'
        }
        place="top"
      >
        <Button
          width="full"
          color="secondary"
          disabled={
            !paidSocialEnabled || (!!verifiedAt && !connectionExpired) || !!verificationData
          }
          msg="project.admin_ads_manager.activate"
          onClick={() => handleConnectToInsense(creatorId)}
        />
      </Tooltip>
      {verificationData?.createdAt && (
        <Text
          size="sm"
          msg="project.admin_ads_manager.createdAt"
          className={styles.textarea}
          values={{ date: connectionCreatedDate }}
        />
      )}
      {verificationData?.expiresAt && (
        <Text
          size="sm"
          msg="project.admin_ads_manager.expiresAt"
          className={styles.textarea}
          values={{ date: connectionExpiredDate }}
        />
      )}
      {verificationData && (
        <>
          <Text size="sm" msg="project.admin_ads_manager.creatorPage" className={styles.textarea} />
          <AvatarItem
            avaData={{
              src: verificationData.facebookPagePictureUrl,
              defaultStubImg: stubImg,
            }}
            titleData={{ text: verificationData.facebookPageName }}
            subtitleData={{ msg: 'project.ads_manager.fb.page' }}
            subtitleLink={
              verificationData.facebookPageId
                ? creatorFbPageLink({
                    facebookPageId: verificationData.facebookPageId,
                  })
                : undefined
            }
            className={styles.textarea}
          />
          <Text
            size="sm"
            msg="project.admin_ads_manager.businessPage"
            className={styles.textarea}
          />
          <AvatarItem
            avaData={{ src: null, defaultStubImg: stubImg }}
            titleData={{ text: verificationData.facebookBusinessName }}
            subtitleData={{ msg: 'project.ads_manager.fb.page' }}
            subtitleLink={`https://business.facebook.com/settings/pages/${verificationData.facebookPageId}?business_id=${verificationData.facebookBusinessId}`}
            className={styles.textarea}
          />
        </>
      )}
      {verificationData && (
        <div className={styles.approve}>
          <Button
            className={styles.approve_btn_left}
            width="full"
            color={verification && success ? 'primary' : 'normal'}
            msg="project.admin_ads_manager.success"
            onClick={() => handleAdsChecked(creatorId)}
          />
          <Button
            width="full"
            color={verification && !success ? 'primary' : 'normal'}
            msg="project.admin_ads_manager.failure"
            onClick={() => handleAdsRestricted(creatorId)}
          />
        </div>
      )}
      {verifiedAt && (
        <Text
          size="sm"
          msg="project.admin_ads_manager.verifiedAt"
          className={styles.textarea}
          values={{ date }}
        />
      )}
    </div>
  );
};

export default AdminAdsManager;

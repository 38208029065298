import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  CreateGcsFileInput,
  CreateGcsFileMutation,
  CreateGcsFileMutation$data,
} from 'GraphTypes/CreateGcsFileMutation.graphql';

const isProd = process.env.DEPLOY_ENV === 'production';

const mutation = graphql`
  mutation CreateGcsFileMutation($input: CreateGcsFileInput!) {
    createGcsFile(input: $input) {
      ... on CreateGcsFilePayload {
        file {
          id
          createdAt
          filename
          originalFilename
          duration
          secureUrl
          thumbnailUrl
          width
          height
          type
          bcaTaggedUrl
          bcaTaggingStatus
          bytes
          publicId
          ... on Image {
            transformations {
              brandLogoUrl
              collageThumbnailUrl
            }
          }
          ... on Video {
            processingStatus
            transformations {
              autoUrl
              collageThumbnailUrl
            }
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateGcsFileInput,
  resolve?: (response: CreateGcsFileMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
      gcsBucketName: isProd ? 'insense-medias' : 'insense-medias-dev',
    },
  };

  commitMutation<CreateGcsFileMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject && errors[0]) {
        reject({ error: errors[0].message });

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

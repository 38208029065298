import React, { useCallback, useState, Suspense, useContext, useMemo } from 'react';
import track, { useTracking } from 'react-tracking';
import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import styles from './CreatorActions.pcss';

import { useMarkContractorAsUnresponsiveQueryMutation } from 'Mutations/MarkContractorAsUnresponsive.Mutation';
import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { setModal } from 'Actions/Modal.Actions';
import unblockCreator from 'Mutations/UnblockCreator.Mutation';
import blockCreator from 'Mutations/BlockCreator.Mutation';
import removeCreatorFromFavorites from 'Mutations/RemoveCreatorFromFavorites.Mutation';
import addCreatorToFavorites from 'Mutations/AddCreatorToFavorites.Mutation';
import CustomListSelection, {
  CustomListSelectionProps,
} from 'Organisms/CustomListSelection/CustomListSelection';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { modalName } from 'Types/modals';
import { CreatorActions_project$key } from 'GraphTypes/CreatorActions_project.graphql';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { IconToggler } from 'Components/IconToggler';

const STALE_DAYS_FOR_REPORT = 3;

interface Props {
  project: CreatorActions_project$key;
  onCustomListSelectionOpen?: () => void;
  onCustomListSelectionClose?: () => void;
  isAdmin?: boolean;
  isNewView?: boolean;
  canCreateMultipleDeals: boolean;
  showNote?: boolean;
  fbAdsControl?: JSX.Element;
  noteControl?: JSX.Element;
}

const CreatorActions: React.FC<Props> = (props) => {
  const {
    project,
    isAdmin,
    isNewView,
    onCustomListSelectionOpen,
    onCustomListSelectionClose,
    canCreateMultipleDeals,
    showNote,
    fbAdsControl,
    noteControl,
  } = props;

  const [openCustomListsKey, setOpenCustomListsKey] = useState(0);

  const tracking = useTracking();
  const dispatch = useDispatch();

  const data = useFragment(
    graphql`
      fragment CreatorActions_project on Project {
        id
        shortlisted
        favorite
        blockedCreator
        advertiserStage
        hasEmailThread
        businessDaysSinceLatestCreatorEvent
        completion {
          id
        }
        brief {
          ... on V2Brief {
            productDelivery
          }
        }
        archivation {
          id
          side
        }
        archivationRequest {
          acceptance {
            id
          }
          rejection {
            id
          }
          side
        }
        creator {
          favorite
          id
          ownership {
            owner {
              unresponsive
            }
          }
        }
        campaign {
          id
          type
          organization {
            id
          }
        }
        launch {
          id
        }
      }
    `,
    project
  );

  const favorite = data.favorite;

  const [isFavoriteCreator, setIsFavoriteCreator] = useState<boolean>(favorite === true);
  const { openDrawer } = useContext(DrawerContext);

  const projectId = data.id || '';
  const advertiserStage = data.advertiserStage;
  const blockedCreator = data.blockedCreator;
  const archivationRequest = data.archivationRequest;
  const archivation = data.archivation;
  const campaignId = data.campaign.id;
  const launch = data.launch?.id;
  const completion = data.completion?.id;
  const creatorId = data.creator?.id || '';
  const creatorFavorite = data.creator?.favorite;
  const organizationId = data.campaign.organization?.id || '';
  const campaignType = data.campaign.type;
  const productDelivery = data.brief?.productDelivery;

  const hasArchivationRequest =
    archivationRequest && !archivationRequest.rejection && !archivationRequest.acceptance;

  const canArchive = !completion && !archivation && !hasArchivationRequest;

  const isUnresponsiveReportAvailable = useMemo(() => {
    const is3daysStale = Number(data?.businessDaysSinceLatestCreatorEvent) >= STALE_DAYS_FOR_REPORT;

    return (
      data?.launch &&
      !data.completion &&
      data.creator?.ownership?.owner.unresponsive === false &&
      is3daysStale
    );
  }, [
    data?.businessDaysSinceLatestCreatorEvent,
    data?.launch,
    data?.completion,
    data?.creator?.ownership?.owner.unresponsive,
    campaignType,
  ]);

  const [markContractorAsUnresponsiveQuery, reportLoading] =
    useMarkContractorAsUnresponsiveQueryMutation({
      variables: {
        input: {
          projectId,
        },
      },
    });

  const handleOpenCustomListDropdown = useCallback(() => {
    setOpenCustomListsKey(Date.now());
  }, []);

  const handleCustomListSelectionOpen = () => {
    amplitude.sendEvent<292>({
      id: '292',
      category: 'chat',
      name: 'list_icon_click',
      param: undefined,
    });
    onCustomListSelectionOpen?.();
  };

  const handleFavoriteValueChange = useCallback<
    NonNullable<CustomListSelectionProps['onFavoriteValueChange']>
  >(
    (isFavorite) => {
      setIsFavoriteCreator(isFavorite);
      if (isFavorite) {
        amplitude.sendEvent<269>({
          id: '269',
          category: 'chat',
          name: 'creator_added_favorites',
          param: {
            project_id: projectId,
            creator_id: creatorId,
            source: isNewView ? 'popup-chat' : undefined,
          },
        });
      } else {
        amplitude.sendEvent<270>({
          id: '270',
          category: 'chat',
          name: 'creator_removed_favorites',
          param: {
            project_id: projectId,
            creator_id: creatorId,
            source: isNewView ? 'popup-chat' : undefined,
          },
        });
      }
    },
    [projectId, creatorId]
  );

  const handleAddCustomListButtonClick = () => {
    amplitude.sendEvent<293>({
      id: '293',
      category: 'chat',
      name: 'new_list_click',
      param: undefined,
    });
  };

  const handleCustomListSuccessfulCreated: NonNullable<
    CustomListSelectionProps['onCustomListSuccessfulCreated']
  > = (response) => {
    if (response.createCustomList?.__typename === 'CreateCustomListPayload') {
      const listName = response.createCustomList.customList?.name || '';
      amplitude.sendEvent<294>({
        id: '294',
        category: 'chat',
        name: 'list_created',
        param: {
          list_name: listName,
        },
      });
    }
  };

  const handleAddCreatorToCustomList: NonNullable<
    CustomListSelectionProps['onAddCreatorToCustomList']
  > = (customListItem) => {
    amplitude.sendEvent<295>({
      id: '295',
      category: 'chat',
      name: 'creator_added_lists',
      param: {
        creator_id: creatorId,
        list_name: customListItem.name,
      },
    });
  };

  const handleRemoveCreatorFromCustomList: NonNullable<
    CustomListSelectionProps['onRemoveCreatorFromCustomList']
  > = (customListItem) => {
    amplitude.sendEvent<296>({
      id: '296',
      category: 'chat',
      name: 'creator_removed_lists',
      param: {
        creator_id: creatorId,
        list_name: customListItem.name,
      },
    });
  };

  const handleBlocklistChange = () => {
    if (blockedCreator) {
      amplitude.sendEvent<160>({
        id: '160',
        category: 'blocklist',
        name: 'creator_blocklist_removed_chat',
        param: {
          creator_id: creatorId,
          project_id: projectId,
          source: isNewView ? 'popup-chat' : undefined,
        },
      });
      unblockCreator({ creatorId, projectId });
    } else {
      if (isNewView) {
        amplitude.sendEvent<159>({
          id: '159',
          category: 'blocklist',
          name: 'creator_blocklisted',
          param: {
            creator_id: creatorId,
            project_id: projectId,
            source: 'popup-chat',
          },
        });
        blockCreator({ creatorId, projectId });
      } else {
        openDrawer('add-creator-blocklist');
      }
    }
  };

  const handleFavoriteClick = () => {
    if (creatorFavorite) {
      amplitude.sendEvent<270>({
        id: '270',
        category: 'chat',
        name: 'creator_removed_favorites',
        param: {
          project_id: projectId,
          creator_id: creatorId,
          source: isNewView ? 'popup-chat' : undefined,
        },
      });
      removeCreatorFromFavorites({ creatorId, organizationId });
    } else {
      amplitude.sendEvent<269>({
        id: '269',
        category: 'chat',
        name: 'creator_added_favorites',
        param: {
          project_id: projectId,
          creator_id: creatorId,
          source: isNewView ? 'popup-chat' : undefined,
        },
      });
      addCreatorToFavorites({ creatorId, organizationId });
    }
  };

  const handleArchiveClick = () => {
    tracking.trackEvent({
      element: 'remove_button',
      event: 'clicked',
    });
    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: { admin: isAdmin, source: 'CHAT', actionName: 'archive' },
    });

    openDrawer(`archive-project-${projectId}`, isNewView ? true : undefined);
  };

  const handleStartNewDealClick = () => {
    tracking.trackEvent({
      element: 'start_new_deal',
      event: 'clicked',
    });
    dispatch(setModal(modalName.START_NEW_DEAL, { campaignId, creatorId }));
  };

  const handleReimbursementClick = () => {
    openDrawer(`project-reimburse-${projectId}`, isNewView ? true : undefined);
  };

  const handleReportUnreponsiceClick = () => {
    amplitude.sendEvent<361>({
      id: '361',
      category: 'project',
      name: 'report_unresponsive_creator',
      param: { date: new Date().toString(), creator_id: data.creator?.id || '' },
    });
    // @ts-ignore
    markContractorAsUnresponsiveQuery();
  };

  const canShowStartNewDeal = isNewView ? advertiserStage !== 'COMPLETED' : true;

  const showDeclineDeal = launch && canArchive;

  const isReimbursementPaymentAvailable =
    productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT' &&
    advertiserStage !== 'ACCEPTED' &&
    advertiserStage !== 'REJECTED' &&
    !completion &&
    isNewView;

  return (
    <Dropdown
      value={<AlterButton type={isNewView ? 'grey' : 'white'} icon="Dots-menu" />}
      showArrow={false}
      closeBySelect={false}
      className={classnames(styles.dropdown, { [styles.isNewView]: isNewView })}
    >
      <DropdownGroup
        className={classnames(styles.dropdownGroup, {
          [styles.withReimbursement]: isReimbursementPaymentAvailable,
          [styles.withUresponseReport]: isUnresponsiveReportAvailable,
        })}
        align={data.hasEmailThread ? 'left' : 'right'}
      >
        {!isNewView && (
          <DropdownGroupItem>
            <AlterButton
              msg="dropdown.add_to_lists"
              onClick={handleOpenCustomListDropdown}
              className={styles.btn}
              leftElement={
                <Suspense fallback={<div />}>
                  <div className={styles.customListSelectionContainer}>
                    <CustomListSelection
                      creatorId={creatorId}
                      organizationId={organizationId}
                      isFavoriteCreator={isFavoriteCreator}
                      iconTogglerProps={{ kind: 'custom-list-dark' }}
                      className={styles.customListSelection}
                      classes={{ dropdownGroup: styles.customListDropdownGroup }}
                      dropdownProps={{
                        openKey: openCustomListsKey,
                        onOpen: handleCustomListSelectionOpen,
                        onClose: onCustomListSelectionClose,
                      }}
                      onFavoriteValueChange={handleFavoriteValueChange}
                      onAddCustomListButtonClick={handleAddCustomListButtonClick}
                      onCustomListSuccessfulCreated={handleCustomListSuccessfulCreated}
                      onAddCreatorToCustomList={handleAddCreatorToCustomList}
                      onRemoveCreatorFromCustomList={handleRemoveCreatorFromCustomList}
                    />
                  </div>
                </Suspense>
              }
            />
          </DropdownGroupItem>
        )}
        {isNewView && (
          <DropdownGroupItem>
            <AlterButton
              className={styles.btn}
              leftElement={<IconToggler active={!!creatorFavorite} kind="favorites" />}
              msg={creatorFavorite ? 'dropdown.remove_from_favorites' : 'dropdown.add_to_favorites'}
              onClick={handleFavoriteClick}
            />
          </DropdownGroupItem>
        )}
        <DropdownGroupItem>
          <Tooltip
            id={`blockedCreator_${(!!blockedCreator).toString()}`}
            tooltipMsg={blockedCreator || isNewView ? undefined : 'chat.creator.blocklist.tooltip'}
            className={styles.btn}
          >
            <AlterButton
              msg={
                blockedCreator
                  ? 'creators.dashboard.blocklist.creator.remove_button'
                  : 'dropdown.add_to_blocklist'
              }
              className={styles.btn}
              onClick={handleBlocklistChange}
              icon="Blocklist"
              iconPosition="left"
            />
          </Tooltip>
        </DropdownGroupItem>
        {isReimbursementPaymentAvailable && (
          <DropdownGroupItem>
            <AlterButton
              className={styles.btn}
              msg="creator_card.reimbursement"
              icon="Money"
              handleClick={handleReimbursementClick}
            />
          </DropdownGroupItem>
        )}
        {showNote && isNewView && <DropdownGroupItem>{noteControl}</DropdownGroupItem>}
        {canCreateMultipleDeals && canShowStartNewDeal && (
          <DropdownGroupItem>
            <AlterButton
              className={styles.btn}
              msg="creator_card.start_new_deal"
              icon="Add-plus-circle"
              handleClick={handleStartNewDealClick}
            />
          </DropdownGroupItem>
        )}
        {!!fbAdsControl && isNewView && <DropdownGroupItem>{fbAdsControl}</DropdownGroupItem>}
        {(showDeclineDeal || isUnresponsiveReportAvailable) && (
          <>
            <div className={styles.separator} />
            {isUnresponsiveReportAvailable && (
              <DropdownGroupItem>
                <AlterButton
                  className={styles.btn}
                  msg="dropdown.report_unresponsive_creator"
                  icon="Warning-error"
                  loading={reportLoading}
                  handleClick={handleReportUnreponsiceClick}
                />
              </DropdownGroupItem>
            )}
            {showDeclineDeal && (
              <DropdownGroupItem>
                <AlterButton
                  className={styles.btn}
                  msg="dropdown.decline_deal"
                  icon="Eye-hidden"
                  handleClick={handleArchiveClick}
                />
              </DropdownGroupItem>
            )}
          </>
        )}
      </DropdownGroup>
    </Dropdown>
  );
};

export default track({
  subsection: 'blogger_info',
})(CreatorActions);

import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import QRCode from 'qrcode';

import styles from './OutreachMobileBanner.pcss';

import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import { amplitude } from 'Helpers/amplitude';
import * as amplitudeSdk from '@amplitude/analytics-browser';

interface Props {
  campaignId: string;
  outreachType?: 'invite' | 'campaign';
}

const OutreachMobileBanner: React.FC<Props> = (props) => {
  const { campaignId, outreachType } = props;
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const deviceId = amplitudeSdk.getDeviceId();
  const campaignLink =
    outreachType === 'campaign'
      ? `https://link.insense.pro/campaigns/?id=${campaignId}${deviceId ? `&uid=${deviceId}` : ''}`
      : `https://link.insense.pro/invite?code=${code}${deviceId ? `&uid=${deviceId}` : ''}`;

  const handleDownloadAppClick = () => {
    amplitude.sendEvent({
      id: '224',
      category: 'creator',
      name: 'campaign_invite_download_app',
    });
    if (isMobile) {
      const loadedAt = +new Date();
      setTimeout(() => {
        if (+new Date() - loadedAt < 2000) window.location.href = campaignLink;
      }, 25);
      if (outreachType === 'campaign') {
        window.open(
          `insense://campaigns/?id=${campaignId}${deviceId ? `&uid=${deviceId}` : ''}`,
          '_self'
        );
      } else if (outreachType === 'invite') {
        window.open(`insense://invite?code=${code}${deviceId ? `&uid=${deviceId}` : ''}`, '_self');
      }
    } else {
      window.location.href = campaignLink;
    }
  };

  useEffect(() => {
    QRCode.toCanvas(
      document.getElementById('app-qr-code'),
      campaignLink,
      {
        color: {
          light: '#F9F9F9FF',
          dark: '#333333FF',
        },
      },
      (error) => {
        if (error) console.error(error);
      }
    );
  }, [campaignId]);
  return (
    <div className={styles.mobileContainer}>
      <div className={styles.headerBanner} />
      <div className={styles.mobileContent}>
        <Text type="d1" msg="outreach_mobile_banner.title" className={styles.mobileDescription} />
        <Text
          type="md"
          msg="outreach_mobile_banner.subtitle"
          className={styles.mobileDescription}
        />
        <div className={styles.qrBlock}>
          <canvas id="app-qr-code" />
          <Text
            type="md"
            msg="outreach_mobile_banner.qr_description"
            className={styles.qrCodeDescription}
          />
        </div>
        <Button
          msg={isMobile ? 'outreach_mobile_banner.mobile_button' : 'outreach_mobile_banner.button'}
          onClick={handleDownloadAppClick}
          fluid
          color="black"
          className={styles.mobileBtn}
        />
      </div>
    </div>
  );
};

export default OutreachMobileBanner;

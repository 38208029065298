import React from 'react';

import styles from './Search.pcss';

import Input from 'Components/ui/Input/Input';

interface Props {
  onChange: (value: string) => void;
}

const Search: React.FC<Props> = (props) => {
  const { onChange } = props;

  return (
    <Input
      placeholderMsg="campaign.creators_search"
      className={styles.root}
      rightIcon="Search-loop"
      handleChange={onChange}
    />
  );
};

export default Search;

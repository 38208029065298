import React, { PropsWithChildren, Suspense, useEffect, useRef, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import ReportDownloadButton from './DownloadButton';

import createCampaignCreatorsReport from 'Mutations/CreateCampaignCreatorsReport.Mutation';
import createCampaignPublicationsReport from 'Mutations/CreateCampaignPublicationsReport.Mutation';
import createShipmentInformationReport from 'Mutations/CreateShipmentInformationReport.Mutation';
import createLonglistReport from 'Mutations/CreateLonglistReport.Mutation';
import createBillingTransactionsReport from 'Mutations/CreateBillingTransactionsReport.Mutation';
import { amplitude } from 'Helpers/amplitude';
import type {
  ReportButtonQuery,
  ReportButtonQuery$variables,
} from 'GraphTypes/ReportButtonQuery.graphql';

export enum ReportType {
  CREATORS = 'CREATORS',
  MEDIAPLAN = 'MEDIAPLAN',
  CREATORS_LIST = 'CREATORS_LIST',
  FINANCE = 'FINANCE',
  CAMPAIGN_PUBLICATIONS = 'CAMPAIGN_PUBLICATIONS',
  LONGLIST = 'LONGLIST',
  TRANSACTIONS = 'TRANSACTIONS',
  SHIPMENT = 'SHIPMENT',
}

type Props = {
  type: ReportType;
  reportData: any;
  onDownload?: () => void;
  onDownloadError?: () => void;
};

const query = graphql`
  query ReportButtonQuery($id: ID!) {
    reporting {
      report(id: $id) {
        state
        downloadUrl
        expiresAt
        filename
      }
    }
  }
`;

const ReportButton: React.FC<PropsWithChildren<Props>> = (props) => {
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);
  const [pollingData, setPollingData] = useState<ReportButtonQuery$variables | null>(null);
  const { type, reportData, children, onDownload, onDownloadError } = props;

  const [queryReference, loadQuery] = useQueryLoader<ReportButtonQuery>(query);

  const handleDownload = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }
    if (onDownload) {
      onDownload();
    }
  };

  const handleDownloadError = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }
    if (onDownloadError) {
      onDownloadError();
    }
  };

  useEffect(() => {
    if (!pollingData) return;
    loadQuery(pollingData);
    pollingInterval.current = setInterval(() => {
      loadQuery(pollingData, {
        fetchPolicy: 'network-only',
      });
    }, 5000);
  }, [pollingData]);

  const downloadReport = async (type: ReportType, data: any) => {
    switch (type) {
      case 'CAMPAIGN_PUBLICATIONS': {
        createCampaignPublicationsReport(data, (response) => {
          if (response.reporting?.createCampaignPublicationsReport?.report) {
            setPollingData({ id: response.reporting?.createCampaignPublicationsReport?.report.id });
          }
        });
        break;
      }
      case 'LONGLIST': {
        createLonglistReport(data);
        break;
      }
      case 'CREATORS': {
        const subtype = 'ADMIN';
        createCampaignCreatorsReport({ ...data, subtype }, (response) => {
          if (response.reporting?.createCampaignCreatorsReport?.report) {
            setPollingData({ id: response.reporting?.createCampaignCreatorsReport?.report.id });
          }
        });
        break;
      }
      case 'FINANCE': {
        const subtype = 'FINANCE';
        createCampaignCreatorsReport({ ...data, subtype }, (response) => {
          if (response.reporting?.createCampaignCreatorsReport?.report) {
            setPollingData({ id: response.reporting?.createCampaignCreatorsReport?.report.id });
          }
        });
        break;
      }
      case 'MEDIAPLAN': {
        const subtype = 'DEFAULT';
        amplitude.sendEvent({
          id: '274',
          category: 'campaign',
          name: 'download_creators_list_for_clients',
          param: {},
        });
        createCampaignCreatorsReport({ ...data, subtype }, (response) => {
          if (response.reporting?.createCampaignCreatorsReport?.report) {
            setPollingData({ id: response.reporting?.createCampaignCreatorsReport?.report.id });
          }
        });
        break;
      }
      case 'CREATORS_LIST': {
        const subtype = 'CONTENT_CREATION';
        createCampaignCreatorsReport({ ...data, subtype }, (response) => {
          if (response.reporting?.createCampaignCreatorsReport?.report) {
            setPollingData({ id: response.reporting?.createCampaignCreatorsReport?.report.id });
          }
        });
        break;
      }
      case 'TRANSACTIONS': {
        createBillingTransactionsReport({ ...data }, (response) => {
          if (response.reporting?.createBillingTransactionsReport?.report) {
            setPollingData({ id: response.reporting?.createBillingTransactionsReport?.report.id });
          }
        });
        break;
      }
      case 'SHIPMENT': {
        createShipmentInformationReport(data, (response) => {
          if (response.reporting?.createShipmentInformationReport?.report) {
            setPollingData({ id: response.reporting?.createShipmentInformationReport?.report.id });
          }
        });
        break;
      }
      default: {
        return;
      }
    }
  };

  const handleDownloadReportClick = () => {
    downloadReport(type, reportData);
  };

  if (!queryReference) {
    return <div onClick={handleDownloadReportClick}>{children}</div>;
  }

  return (
    <Suspense fallback={children || null}>
      <ReportDownloadButton
        query={query}
        queryReference={queryReference}
        filename={type.toLocaleLowerCase()}
        onDownload={handleDownload}
        onDownloadError={handleDownloadError}
      >
        {children}
      </ReportDownloadButton>
    </Suspense>
  );
};

export default ReportButton;

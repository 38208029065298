import { graphql, useMutation } from 'react-relay';

import { DeleteMessageMutation } from 'GraphTypes/DeleteMessageMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const deleteMessageMutation = graphql`
  mutation DeleteMessageMutation($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      ... on DeleteMessage_DeletionTimeExpired {
        message
      }
    }
  }
`;

export const useDeleteMessageMutation: BaseMutation<DeleteMessageMutation> = (config) => {
  const [deleteMessage, loading] = useMutation<DeleteMessageMutation>(deleteMessageMutation);

  const deleteMessageCaller: BaseMutationExecFn<DeleteMessageMutation> = (execConfig) => {
    deleteMessage({
      ...config,
      ...execConfig,
    });
  };
  return [deleteMessageCaller, loading];
};

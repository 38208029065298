import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';
import filter from 'lodash/filter';

import { FileType } from 'Hooks/useUpload';

type AttachContextValue = {
  filesList: FileType[];
  pendingCount: number;
};

const initialValue: AttachContextValue = {
  filesList: [],
  pendingCount: 0,
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  UPLOAD_ATTACH_START = 'UPLOAD_ATTACH_START',
  UPLOAD_ATTACH_SUCCESS = 'UPLOAD_ATTACH_SUCCESS',
  DELETE_FILE = 'DELETE_FILE',
  DELETE_FILES = 'DELETE_FILES',
}

type AttachPayload = {
  [Types.UPLOAD_ATTACH_START]: {
    pendingCount: number;
  };
  [Types.UPLOAD_ATTACH_SUCCESS]: {
    file: FileType;
  };
  [Types.DELETE_FILE]: {
    fileId: string;
  };
  [Types.DELETE_FILES]: undefined;
};

export type AttachActions = ActionMap<AttachPayload>[keyof ActionMap<AttachPayload>];

type Reducer = (state: AttachContextValue, action: AttachActions) => AttachContextValue;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.UPLOAD_ATTACH_START: {
      const count = state.pendingCount + Number(action.payload.pendingCount);

      return {
        ...state,
        pendingCount: count,
      };
    }
    case Types.UPLOAD_ATTACH_SUCCESS: {
      const file = action.payload.file;
      const count = state.pendingCount > 0 ? state.pendingCount - 1 : 0;

      return {
        ...state,
        filesList: [...state.filesList, file],
        pendingCount: count,
      };
    }
    case Types.DELETE_FILE: {
      const newList = [...state.filesList];
      const listWithoutFile = filter(newList, (item) => {
        return item?.id !== action.payload.fileId;
      });

      return {
        ...state,
        filesList: listWithoutFile,
      };
    }
    case Types.DELETE_FILES: {
      return initialValue;
    }
    default:
      return state;
  }
};

const AttachContext = createContext<{
  state: AttachContextValue;
  dispatch: Dispatch<AttachActions>;
}>({ state: initialValue, dispatch: () => null });

type AttachContextProviderType = FC<PropsWithChildren<{}>>;

const AttachContextProvider: AttachContextProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  return <AttachContext.Provider value={{ state, dispatch }}>{children}</AttachContext.Provider>;
};

export { AttachContext, AttachContextProvider };
export type { AttachContextProviderType };

import React, { useContext } from 'react';

import styles from './Attach.pcss';

import { AttachContext, Types } from '../Attach.Context';

import { useUpload, FileType } from 'Hooks/useUpload';
import IconNew from 'Components/ui/Icon/Icon';
import FileUploader from 'Atoms/FileUploader/FileUploader';

const Attach = () => {
  const { dispatch } = useContext(AttachContext);
  const { uploadFile } = useUpload();

  const handleFileUpload = (data: { file: FileType }) => {
    dispatch({
      type: Types.UPLOAD_ATTACH_SUCCESS,
      payload: { file: data.file },
    });
  };

  const handleListChange = (files: FileList | null) => {
    dispatch({ type: Types.UPLOAD_ATTACH_START, payload: { pendingCount: files?.length || 0 } });
    if (!files) return;
    [...files].forEach((file) => {
      uploadFile({ params: { file, category: 'message_attachment' }, onSuccess: handleFileUpload });
    });
  };

  return (
    <div className={styles.root}>
      <IconNew name="Attachment" className={styles.icon} />
      <FileUploader typeList="" multiple className={styles.uploader} onChange={handleListChange} />
    </div>
  );
};

export default Attach;

import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import styles from './CustomHeader.css';

import Logo from 'Atoms/Logo/Logo';
import BackButton from 'Molecules/BackButton/BackButton';
import MbpBadge from 'Molecules/MbpBadge/MbpBadge';
import TikTopBadge from 'Molecules/TikTokBadge/TikTopBadge';
import { DASHBOARD_ROUTE } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';

const CustomHeader = (props) => {
  const intl = useIntl();
  const location = useLocation();

  const routeState = location.state;

  const createBackButton = () => {
    const { backData } = props;
    if (!routeState && !backData) return null;

    let path = '';
    let withSearch;
    let backText = {};
    if (routeState) {
      const { prevRouteName, prevRoutePath } = routeState;
      if (!prevRouteName || !prevRoutePath || DASHBOARD_ROUTE === prevRoutePath) {
        return null;
      }
      const page = intl.formatMessage({ id: `route.${prevRouteName}` });
      backText = { msgValues: { page }, msg: 'custom_header.back_button' };
      path = prevRoutePath;
    }

    if (backData) {
      const { msg = 'route.back' } = backData;
      backText = { msg };
      path = backData.path;
      withSearch = backData.withSearch;
    }

    return <BackButton link={path} withSearch={withSearch} {...backText} />;
  };

  const { children, controls, element, showPartners = false, className } = props;

  const backBtn = createBackButton();

  const onLogoClick = () => {
    amplitude.sendEvent({
      id: 237,
      category: 'pageview',
      name: 'logo_insense_click',
      param: { referral_url: DASHBOARD_ROUTE },
    });
  };

  return (
    <div className={`${className} ${styles.root}`}>
      <div className={styles.container}>
        <div className={styles.controls}>
          <Link to={DASHBOARD_ROUTE} onClick={onLogoClick}>
            <Logo />
          </Link>
          {backBtn}
        </div>
        <div className={styles.spacer} />
        {children}
        <div className={styles.spacer} />
        <div className={`${styles.leftAlign} ${styles.controls}`}>{controls}</div>
        {element}
      </div>
    </div>
  );
};

export default CustomHeader;

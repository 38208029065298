/**
 * @generated SignedSource<<f56f26b05051537cec4ac7bbc5cdc373>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR" | "%future added value";
export type ArchiveProjectInput = {
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  reason?: string | null | undefined;
  side?: ProjectArchivationSide | null | undefined;
};
export type ArchiveProjectMutation$variables = {
  archivedStages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  createdByAdmin?: boolean | null | undefined;
  input: ArchiveProjectInput;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
};
export type ArchiveProjectMutation$data = {
  readonly archiveProject: {
    readonly project: {
      readonly archivation: {
        readonly id: string;
      } | null | undefined;
      readonly archivationRequest: {
        readonly acceptance: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly rejection: {
          readonly id: string;
        } | null | undefined;
        readonly side: ProjectArchivationSide;
      } | null | undefined;
      readonly campaign: {
        readonly archiveEvents: {
          readonly unreadCount: number;
        };
        readonly archived: {
          readonly totalCount: number;
        };
        readonly commonEvents: {
          readonly unreadCount: number;
        };
        readonly organization: {
          readonly paymentAccount: {
            readonly balance: number;
            readonly currency: Currency;
          } | null | undefined;
        } | null | undefined;
        readonly projects: {
          readonly summary: {
            readonly totalPrice: number | null | undefined;
          } | null | undefined;
          readonly totalCount: number;
        };
        readonly projectsSummary: {
          readonly summary: {
            readonly currency: Currency | null | undefined;
            readonly totalPrice: number | null | undefined;
          } | null | undefined;
          readonly totalCount: number;
        };
        readonly unsentProjects: {
          readonly summary: {
            readonly totalCount: number | null | undefined;
          } | null | undefined;
        };
      };
    };
  } | null | undefined;
};
export type ArchiveProjectMutation = {
  response: ArchiveProjectMutation$data;
  variables: ArchiveProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archivedStages"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdByAdmin"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "side",
      "value": "ADVERTISER"
    }
  ],
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "archivation(side:\"ADVERTISER\")"
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequest",
  "kind": "LinkedField",
  "name": "archivationRequest",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequestAcceptance",
      "kind": "LinkedField",
      "name": "acceptance",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequestRejection",
      "kind": "LinkedField",
      "name": "rejection",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "archived",
  "value": false
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unreadCount",
    "storageKey": null
  }
],
v11 = {
  "alias": "commonEvents",
  "args": [
    (v9/*: any*/)
  ],
  "concreteType": "ProjectEventConnection",
  "kind": "LinkedField",
  "name": "projectEvents",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": "projectEvents(archived:false)"
},
v12 = {
  "kind": "Literal",
  "name": "archived",
  "value": true
},
v13 = {
  "alias": "archiveEvents",
  "args": [
    (v12/*: any*/)
  ],
  "concreteType": "ProjectEventConnection",
  "kind": "LinkedField",
  "name": "projectEvents",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": "projectEvents(archived:true)"
},
v14 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "LAST_ADDED"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v16 = [
  (v15/*: any*/)
],
v17 = {
  "alias": "archived",
  "args": [
    (v12/*: any*/),
    (v14/*: any*/),
    {
      "kind": "Variable",
      "name": "stages",
      "variableName": "archivedStages"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": "unsentProjects",
  "args": [
    (v9/*: any*/),
    {
      "kind": "Variable",
      "name": "createdByAdmin",
      "variableName": "createdByAdmin"
    },
    (v14/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "UNSENT",
        "UNREAD",
        "READ"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnectionSummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": (v16/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPrice",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": [
    (v9/*: any*/),
    (v14/*: any*/),
    {
      "kind": "Variable",
      "name": "stages",
      "variableName": "stages"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnectionSummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        (v19/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v23 = {
  "alias": "projectsSummary",
  "args": [
    (v9/*: any*/),
    (v14/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnectionSummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        (v19/*: any*/),
        (v22/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projects(archived:false,orderBy:\"LAST_ADDED\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ArchiveProjectPayload",
        "kind": "LinkedField",
        "name": "archiveProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Payment_Account",
                        "kind": "LinkedField",
                        "name": "paymentAccount",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ArchiveProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ArchiveProjectPayload",
        "kind": "LinkedField",
        "name": "archiveProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Payment_Account",
                        "kind": "LinkedField",
                        "name": "paymentAccount",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v23/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4490936411f5bb6f1ea5514bf95f4e82",
    "id": null,
    "metadata": {},
    "name": "ArchiveProjectMutation",
    "operationKind": "mutation",
    "text": "mutation ArchiveProjectMutation(\n  $input: ArchiveProjectInput!\n  $stages: [AdvertiserProjectStage!]\n  $archivedStages: [AdvertiserProjectStage!]\n  $createdByAdmin: Boolean\n) {\n  archiveProject(input: $input) {\n    project {\n      archivation(side: ADVERTISER) {\n        id\n      }\n      archivationRequest {\n        id\n        side\n        acceptance {\n          id\n        }\n        rejection {\n          id\n        }\n      }\n      campaign {\n        commonEvents: projectEvents(archived: false) {\n          unreadCount\n        }\n        archiveEvents: projectEvents(archived: true) {\n          unreadCount\n        }\n        archived: projects(stages: $archivedStages, archived: true, orderBy: LAST_ADDED) {\n          totalCount\n        }\n        unsentProjects: projects(stages: [UNSENT, UNREAD, READ], archived: false, orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {\n          summary {\n            totalCount\n          }\n        }\n        projects(stages: $stages, archived: false, orderBy: LAST_ADDED) {\n          totalCount\n          summary {\n            totalPrice\n          }\n        }\n        organization {\n          paymentAccount {\n            balance\n            currency\n            id\n          }\n          id\n        }\n        projectsSummary: projects(archived: false, orderBy: LAST_ADDED) {\n          totalCount\n          summary {\n            totalPrice\n            currency\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd8226a4a203b412201d078a78caf919";

export default node;

import { graphql, useFragment } from 'react-relay';

import {
  useCreatorAmazonStorelink_creator$data,
  useCreatorAmazonStorelink_creator$key,
} from 'GraphTypes/useCreatorAmazonStorelink_creator.graphql';

interface Props {
  creatorProfile: useCreatorAmazonStorelink_creator$key | null;
}

const useCreatorAmazonStorelink = (props: Props) => {
  const data = useFragment(
    graphql`
      fragment useCreatorAmazonStorelink_creator on CreatorProfile {
        amazonStorefrontLink
      }
    `,
    props.creatorProfile as any //  Seems like @relay-types bug
  ) as useCreatorAmazonStorelink_creator$data;
  return data?.amazonStorefrontLink;
};

export default useCreatorAmazonStorelink;

/**
 * @generated SignedSource<<c7a616c1999230a0029ff6c1359240d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type MediaplanItemsPaginationQuery$variables = {
  archived?: boolean | null | undefined;
  campaignId: string;
  count: number;
  createdByAdmin?: boolean | null | undefined;
  cursor?: string | null | undefined;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
};
export type MediaplanItemsPaginationQuery$data = {
  readonly campaign: {
    readonly " $fragmentSpreads": FragmentRefs<"MediaplanItems_campaign">;
  } | null | undefined;
};
export type MediaplanItemsPaginationQuery = {
  response: MediaplanItemsPaginationQuery$data;
  variables: MediaplanItemsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdByAdmin"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v7 = {
  "kind": "Variable",
  "name": "archived",
  "variableName": "archived"
},
v8 = {
  "kind": "Variable",
  "name": "createdByAdmin",
  "variableName": "createdByAdmin"
},
v9 = {
  "kind": "Variable",
  "name": "stages",
  "variableName": "stages"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v9/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = [
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MediaplanItemsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "args": [
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v8/*: any*/),
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "MediaplanItems_campaign"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "MediaplanItemsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "creator",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "estimatedPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "profilePictureFile",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ImageTransformation",
                                        "kind": "LinkedField",
                                        "name": "transformations",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "collageThumbnailUrl",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "Image",
                                    "abstractKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "YoutubeChannel",
                                "kind": "LinkedField",
                                "name": "channel",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v14/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "YoutubeCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TiktokUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "TiktokCreator",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentApprovalPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "offer",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OfferAcceptance",
                            "kind": "LinkedField",
                            "name": "acceptance",
                            "plural": false,
                            "selections": (v15/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectLaunch",
                        "kind": "LinkedField",
                        "name": "launch",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "side",
                            "value": "ADVERTISER"
                          }
                        ],
                        "concreteType": "ProjectArchivation",
                        "kind": "LinkedField",
                        "name": "archivation",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": "archivation(side:\"ADVERTISER\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Longlists_Creator",
                        "kind": "LinkedField",
                        "name": "longlistCreator",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "interactedWith",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Mediaplan_projects",
            "kind": "LinkedHandle",
            "name": "projects"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebaddf5a751d1d1032a0e29da4803869",
    "id": null,
    "metadata": {},
    "name": "MediaplanItemsPaginationQuery",
    "operationKind": "query",
    "text": "query MediaplanItemsPaginationQuery(\n  $campaignId: ID!\n  $count: Int!\n  $cursor: String\n  $archived: Boolean\n  $createdByAdmin: Boolean\n  $stages: [AdvertiserProjectStage!]\n) {\n  campaign(id: $campaignId) {\n    ...MediaplanItems_campaign_3H1uc0\n    id\n  }\n}\n\nfragment MediaplanItem_project on Project {\n  id\n  currency\n  price\n  contentApprovalPrice\n  offer {\n    id\n    acceptance {\n      id\n    }\n  }\n  launch {\n    id\n  }\n  archivation(side: ADVERTISER) {\n    id\n  }\n  longlistCreator {\n    interactedWith\n    id\n  }\n  creator {\n    __typename\n    id\n    username\n    type\n    profile {\n      currency\n      estimatedPrice\n      profilePictureFile {\n        __typename\n        url\n        ... on Image {\n          transformations {\n            collageThumbnailUrl\n          }\n        }\n        id\n      }\n      id\n    }\n    ... on YoutubeCreator {\n      channel {\n        id\n        name\n      }\n    }\n    ... on TiktokCreator {\n      user {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment MediaplanItems_campaign_3H1uc0 on Campaign {\n  id\n  projects(first: $count, after: $cursor, archived: $archived, stages: $stages, createdByAdmin: $createdByAdmin) {\n    edges {\n      node {\n        id\n        creator {\n          __typename\n          username\n          id\n        }\n        ...MediaplanItem_project\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "44701c94436422401342875e6f7abfc0";

export default node;

/**
 * @generated SignedSource<<a2e5539a417a6ca5f08620260186b846>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatorBadge = "HIGHLY_RESPONSIVE" | "HIGH_POTENTIAL" | "TOP_RATED" | "UGC_EXPERT" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type InfluencerType = "BLOGGER" | "BRAND" | "CELEBRITY" | "COMMUNITY" | "%future added value";
export type UpdateCreatorProfileInput = {
  acceptsBarter?: boolean | null | undefined;
  adminComment?: string | null | undefined;
  amazonStorefrontLink?: string | null | undefined;
  animalIds?: ReadonlyArray<string> | null | undefined;
  badge?: CreatorBadge | null | undefined;
  badgeIds?: ReadonlyArray<string> | null | undefined;
  badgeSetAt?: any | null | undefined;
  birthdate?: any | null | undefined;
  bodyTypeIds?: ReadonlyArray<string> | null | undefined;
  categories?: ReadonlyArray<CategoryParams> | null | undefined;
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cityIds?: ReadonlyArray<string> | null | undefined;
  clientMutationId?: string | null | undefined;
  contactEmail?: string | null | undefined;
  contentCreator?: boolean | null | undefined;
  creatorEthnicityId?: string | null | undefined;
  currency?: Currency | null | undefined;
  customPrice?: number | null | undefined;
  employed?: boolean | null | undefined;
  genderId?: string | null | undefined;
  hairTypeId?: string | null | undefined;
  hairTypeIds?: ReadonlyArray<string> | null | undefined;
  hasTiktokShop?: boolean | null | undefined;
  id: string;
  influencerType?: InfluencerType | null | undefined;
  languageIds?: ReadonlyArray<string> | null | undefined;
  portfolioLink?: string | null | undefined;
  profilePictureFileId?: string | null | undefined;
  registrationIsCompleted?: boolean | null | undefined;
  reviewedByAdmin?: boolean | null | undefined;
  searchable?: boolean | null | undefined;
  tagIds?: ReadonlyArray<string> | null | undefined;
  videoResumeFileId?: string | null | undefined;
};
export type CategoryParams = {
  id: string;
  primary?: boolean | null | undefined;
};
export type UpdateCreatorProfileMutation$variables = {
  input: UpdateCreatorProfileInput;
};
export type UpdateCreatorProfileMutation$data = {
  readonly updateCreatorProfile: {
    readonly creatorProfile: {
      readonly acceptsBarter: boolean;
      readonly adminComment: string | null | undefined;
      readonly animals: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null | undefined;
      readonly badge: CreatorBadge | null | undefined;
      readonly badges: ReadonlyArray<{
        readonly name: string;
      }> | null | undefined;
      readonly birthdate: any | null | undefined;
      readonly bodyTypes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null | undefined;
      readonly categories: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly primary: boolean | null | undefined;
      }>;
      readonly cities: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly contactEmail: string | null | undefined;
      readonly currency: Currency | null | undefined;
      readonly customPrice: number | null | undefined;
      readonly employed: boolean | null | undefined;
      readonly estimatedPrice: number | null | undefined;
      readonly ethnicity: {
        readonly id: string;
      } | null | undefined;
      readonly gender: {
        readonly id: string;
      } | null | undefined;
      readonly hairTypes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null | undefined;
      readonly id: string;
      readonly influencerType: InfluencerType | null | undefined;
      readonly languages: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null | undefined;
      readonly reviewedByAdmin: boolean;
      readonly searchable: boolean;
      readonly tags: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly videoResumeFile: {
        readonly processingStatus?: FileProcessingStatus | null | undefined;
        readonly thumbnailUrl: string;
        readonly transformations?: {
          readonly autoUrl: string;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type UpdateCreatorProfileMutation = {
  response: UpdateCreatorProfileMutation$data;
  variables: UpdateCreatorProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badge",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptsBarter",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminComment",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchable",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reviewedByAdmin",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "influencerType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employed",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "processingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
},
v14 = [
  (v2/*: any*/),
  (v11/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "HairType",
  "kind": "LinkedField",
  "name": "hairTypes",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "BodyType",
  "kind": "LinkedField",
  "name": "bodyTypes",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Animal",
  "kind": "LinkedField",
  "name": "animals",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Language",
  "kind": "LinkedField",
  "name": "languages",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v20 = [
  (v2/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "Ethnicity",
  "kind": "LinkedField",
  "name": "ethnicity",
  "plural": false,
  "selections": (v20/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Gender",
  "kind": "LinkedField",
  "name": "gender",
  "plural": false,
  "selections": (v20/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "City",
  "kind": "LinkedField",
  "name": "cities",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "categories",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primary",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactEmail",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customPrice",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedPrice",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCreatorProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCreatorProfilePayload",
        "kind": "LinkedField",
        "name": "updateCreatorProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorProfile",
            "kind": "LinkedField",
            "name": "creatorProfile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "videoResumeFile",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCreatorProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCreatorProfilePayload",
        "kind": "LinkedField",
        "name": "updateCreatorProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorProfile",
            "kind": "LinkedField",
            "name": "creatorProfile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "videoResumeFile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32052655eabf243bb10e506c188b1bfb",
    "id": null,
    "metadata": {},
    "name": "UpdateCreatorProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCreatorProfileMutation(\n  $input: UpdateCreatorProfileInput!\n) {\n  updateCreatorProfile(input: $input) {\n    creatorProfile {\n      id\n      badge\n      acceptsBarter\n      adminComment\n      searchable\n      reviewedByAdmin\n      influencerType\n      birthdate\n      employed\n      badges {\n        name\n        id\n      }\n      videoResumeFile {\n        __typename\n        thumbnailUrl\n        ... on Video {\n          processingStatus\n          transformations {\n            autoUrl\n          }\n        }\n        id\n      }\n      hairTypes {\n        id\n        name\n      }\n      bodyTypes {\n        id\n        name\n      }\n      animals {\n        id\n        name\n      }\n      languages {\n        id\n        name\n      }\n      tags {\n        id\n        name\n      }\n      ethnicity {\n        id\n      }\n      gender {\n        id\n      }\n      cities {\n        id\n        name\n      }\n      categories {\n        id\n        name\n        primary\n      }\n      contactEmail\n      currency\n      customPrice\n      estimatedPrice\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06ebde89113615187218f7fab5952e65";

export default node;

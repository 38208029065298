import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';
import { ShopifyOrderStateUpdatedSubscription } from 'GraphTypes/ShopifyOrderStateUpdatedSubscription.graphql';

const subscription = graphql`
  subscription ShopifyOrderStateUpdatedSubscription {
    shopifyOrderUpdated {
      ...Shipment_project
      ...CreatorChatDrawers_project
    }
  }
`;

export default () => {
  requestSubscription<ShopifyOrderStateUpdatedSubscription>(environment, {
    subscription,
    variables: {},
  });
};

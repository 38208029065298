import type { FeatureFlags, FeatureFlagsSet } from './types';

type FeatureFlagsToFeatureFlagsSet = (featureFlags: FeatureFlags) => FeatureFlagsSet;
const featureFlagsToFeatureFlagsSet: FeatureFlagsToFeatureFlagsSet = (featureFlags) => {
  if (Array.isArray(featureFlags)) {
    const ids = featureFlags.map((item) => item.featureId);
    return new Set(ids);
  }
  return new Set();
};

type GetDefaultFeatureFlags = () => FeatureFlagsSet;
const getDefaultFeatureFlagsSet: GetDefaultFeatureFlags = () => {
  return new Set([]);
};

export { featureFlagsToFeatureFlagsSet, getDefaultFeatureFlagsSet };

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import classNames from 'classnames';

import Text from '../Text/Text';

import styles from './Counter.pcss';

interface Props {
  minValue: number;
  maxValue: number;
  onChangeValue: (value: number) => void;
  value?: number;
  labelMsg?: string;
  className?: string;
  buttonClassName?: string;
  step?: number;
}

const Counter: React.FC<Props> = (props) => {
  const {
    minValue,
    maxValue,
    onChangeValue,
    value,
    labelMsg,
    className,
    buttonClassName,
    step = 1,
  } = props;

  const [currentValue, setCurrentValue] = useState(value || 0);

  useEffect(() => {
    onChangeValue(currentValue);
  }, [currentValue]);

  const handleDecreaseValue = () => {
    const newValue = currentValue - step;
    if (newValue < minValue || currentValue === Infinity) return;
    setCurrentValue(newValue);
  };

  const handleIncreaseValue = () => {
    const newValue = currentValue + step;
    if (newValue > maxValue || currentValue === Infinity) return;
    setCurrentValue(newValue);
  };

  const classNameList = classnames(className, styles.root);

  const plusButton = (
    <div
      className={classnames(
        styles.button,
        {
          [styles.disabled]: currentValue === maxValue || currentValue === Infinity,
        },
        buttonClassName
      )}
      onClick={handleIncreaseValue}
    >
      <div className={styles.plus} />
    </div>
  );

  const minusButton = (
    <div
      className={classnames(
        styles.button,
        {
          [styles.disabled]: currentValue === minValue || currentValue === Infinity,
        },
        buttonClassName
      )}
      onClick={handleDecreaseValue}
    >
      <div className={styles.minus} />
    </div>
  );

  return (
    <div className={classNameList}>
      {minusButton}
      <Text
        type="md"
        className={classNames(styles.valueText, { [styles.infinityValue]: value === Infinity })}
        text={currentValue === Infinity ? undefined : currentValue.toString()}
      />
      {plusButton}
      {labelMsg && <Text type="md" msg={labelMsg} />}
    </div>
  );
};

export default Counter;

import React from 'react';
import classnames from 'classnames';

import styles from './DropDownMenuItem.css';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import { DropDownMenuItemType } from 'Types/common';

const DropDownMenuItem: React.FC<DropDownMenuItemType> = (props) => {
  const {
    actionElement,
    children,
    className,
    icon,
    tooltipData,
    isDisabled,
    dataTest,
    onClick,
    contentClassName,
  } = props;

  const classList = classnames(className, styles.root, {
    [styles.hasIcon]: !!icon,
    [styles.hasActionElement]: !!actionElement,
    [styles.isDisabled]: isDisabled,
  });
  const menuItem = (
    <div className={classList} onClick={isDisabled ? undefined : onClick} data-test={dataTest}>
      <div className={classnames(styles.content, contentClassName)}>
        <div className={styles.child}>{children}</div>
        <div className={styles.subElement}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {actionElement && <div className={styles.actionElement}>{actionElement}</div>}
        </div>
      </div>
    </div>
  );

  return tooltipData ? <Tooltip {...tooltipData}>{menuItem}</Tooltip> : menuItem;
};

export default DropDownMenuItem;

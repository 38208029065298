import moment from 'moment';

import { getLocale } from 'Util/localizationData';
import { RU } from 'Constants/general';

const locale = getLocale();

moment.locale(locale);

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';
const RU_DATE_FORMAT = 'DD MMM YYYY';
export const EN_DATE_FORMAT = 'MMM DD, YYYY';
const TIME_FORMAT = 'HH:mm';

const RU_DATE_MSG = 'DD.MM.YYYY';
const EN_DATE_MSG = 'MM.DD.YYYY';

const RU_DATE_SHORT = 'DD.MM';
const EN_DATE_SHORT = 'MM.DD';

export const format = locale === RU ? RU_DATE_FORMAT : EN_DATE_FORMAT;

export const DATE_FORMAT_MSG = locale === RU ? RU_DATE_MSG : EN_DATE_MSG;
export const DATE_FORMAT_SHORT = locale === RU ? RU_DATE_SHORT : EN_DATE_SHORT;

export const allMonths = moment.months();

export const getFirstSundayOfMonth = (date, monthIndex) => {
  const dateMoment = moment(date);
  dateMoment.month(monthIndex);
  dateMoment.date(1);
  const currentWeekday = dateMoment.weekday();
  dateMoment.add((7 - currentWeekday) % 7, 'day');
  return dateMoment;
};

// Start of EDS is at 2 AM at EST, and it is 7AM at UTC, second Sunday of March
//End of EDS is at 1AM at EST, and it is 6AM at UTC, first Sunday of November
export const isEDS = (date) => {
  const startEDSMoment = getFirstSundayOfMonth(date, 2)
    .add(7, 'days')
    .hour(7)
    .minute(0)
    .second(0)
    .millisecond(0);
  const endEDSMoment = getFirstSundayOfMonth(date, 10).hour(6).minute(0).second(0).millisecond(0);
  const currentMoment = moment(date);
  return currentMoment.isAfter(startEDSMoment) && currentMoment.isBefore(endEDSMoment);
};
export const stringToDate = (dateString) => {
  return moment(dateString).toDate();
};

export const createWithdrawalDate = (date) => {
  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format('DD.MM.YYYY HH:mm');
};

export const defineDate = (string) => {
  return moment(string).format('YYYY-MM-DD');
};

export const addDaysDate = (date, days = 0) => {
  let dateCopy = new Date(date.getTime());
  dateCopy.setDate(dateCopy.getDate() + days);
  return dateCopy;
};

export const createDate = (date, formatValue) => {
  if (!date) return '';

  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format(formatValue || format);
};

export const createDateV2 = (date) => {
  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format(DATE_FORMAT_MSG);
};

export const createDateV3 = (date) => {
  const newData = moment(date, DATETIME_FORMAT);
  moment.locale('en');

  return moment(newData).format('ll');
};

export const createDateWithTime = (date) => {
  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format(`${format}, ${TIME_FORMAT}`);
};

export const createEndOfDay = (date, currency) => {
  const additionTimezone = currency === 'USD' ? 'T23:59:59.999-05:00' : 'T23:59:59.999+03:00';

  return `${moment(date).format('YYYY-MM-DD')}${additionTimezone}`;
};

export const createStartOfDay = (date, currency) => {
  const additionTimezone = currency === 'USD' ? 'T00:00:00.000-05:00' : 'T00:00:00.000+03:00';

  return `${moment(date).format('YYYY-MM-DD')}${additionTimezone}`;
};

export const createEndOfDayV2 = (date) => {
  return moment(date).endOf('day');
};

export const createStartOfDayV2 = (date) => {
  return moment(date).startOf('day');
};

export const createEndOfNewYorkDay = (date, currency) => {
  const offset = isEDS(date) ? '-04:00' : '-05:00';

  const additionTimezone = currency === 'USD' ? `T23:59:59.999${offset}` : 'T23:59:59.999+03:00';

  return `${moment(date).format('YYYY-MM-DD')}${additionTimezone}`;
};

export const createStartOfNewYorkDay = (date, currency) => {
  const offset = isEDS(date) ? '-04:00' : '-05:00';
  const additionTimezone = currency === 'USD' ? `T00:00:00.000${offset}` : 'T00:00:00.000+03:00';

  return `${moment(date).format('YYYY-MM-DD')}${additionTimezone}`;
};

export const dateChatFormat = (date) => {
  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format('MMM DD, YYYY');
};

export const timeChatFormat = (date) => {
  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format(TIME_FORMAT);
};

export const dateAdvertiserFormat = (date) => {
  if (!date) return '—';
  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format('DD.MM.YYYY');
};

export const convertStringToDate = (date) => {
  if (!date) return 'N/A';
  const newData = moment(date, DATETIME_FORMAT);

  return moment(newData).format('DD.MM.YYYY');
};

export const dateStringToNewYorkDate = (date, dateFormat) => {
  if (!date) return 'N/A';
  const _format = dateFormat || 'DD.MM.YYYY';
  const offset = isEDS(date) ? '-0400' : '-0500';
  return moment(date).utc().utcOffset(offset, false).format(_format);
};

export const compareDates = (from, to, result) => {
  const dateFrom = moment(from);
  const dateTo = moment(to);
  const diff = dateTo.diff(dateFrom) >= 0;

  return diff === result;
};

export const latestDate = (date1, date2) => {
  // it seems function's work is not correct
  if (date1 && date2) {
    const dateFrom = moment(date1);
    const dateTo = moment(date2);
    const diff = dateTo.diff(dateFrom) >= 0;

    return diff ? date1 : date2; // should be: return diff ? date2 : date1;
  }

  return date1 || date2;
};

export const getCurrentTime = () => {
  return moment().format(DATETIME_FORMAT);
};

export const getTimeFromNow = (date) => {
  if (!date) return undefined;

  return moment(date).fromNow();
};

export const getAges = (date) => {
  if (!date) return undefined;
  const birthdate = moment(date);
  const now = moment();

  return birthdate.diff(now, 'years') * -1;
};

export const getBirthdateByAge = (age) => {
  if (!age) return null;

  return moment().subtract(age, 'years').format('YYYY-MM-DD');
};

export const createProjectEventDate = (date) => {
  if (!date) return null;
  const data = {
    sameDay: 'hh:mm',
    lastDay: 'ddd',
    lastWeek: 'ddd',
    sameElse: 'MM.DD.YY',
  };
  return moment(date, 'YYYY-MM-DD HH:mm:ss Z').calendar(undefined, data);
};

export const createCalendarTime = (date) => {
  const data =
    locale === RU
      ? {
          sameDay: '[Сегодня в] hh:mm',
          lastDay: '[Вчера в] hh:mm',
          nextDay: '[Вчера в] hh:mm',
          lastWeek: 'DD MMM YYYY в hh:mm',
          sameElse: 'DD MMM YYYY в hh:mm',
        }
      : {
          sameDay: '[Today at] hh:mm',
          lastDay: '[Yesterday at] hh:mm',
          nextDay: '[Yesterday at] hh:mm',
          lastWeek: 'MMM DD YYYY at hh:mm',
          sameElse: 'MMM DD YYYY at hh:mm',
        };

  return moment(date, 'YYYY-MM-DD HH:mm:ss Z').calendar(undefined, data);
};

export const getShortDatesPeriod = (startDate, endDate) => {
  const startDateValue = moment(startDate).format(DATE_FORMAT_SHORT);
  const endDateValue = moment(endDate).format(DATE_FORMAT_SHORT);

  return `${startDateValue} - ${endDateValue}`;
};

export default createDate;

export const isDateInPeriod = (currentDate, startDate, endDate) => {
  return moment(currentDate).isBetween(startDate, endDate);
};

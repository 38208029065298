import React from 'react';
import camelCase from 'lodash/camelCase';

import styles from './CreatorBadge.css';
import { getBadgeData } from './util';

import Text from 'Atoms/Text/Text';
import { TextType } from 'Types/common';
import { CreatorBadgeEnum } from 'Types/enums';

interface Props {
  className?: string;
  badge?: string;
  withText?: boolean;
  withIcon?: boolean;
  textData?: TextType;
  iconClassName?: string;
}

const CreatorBadge: React.FC<Props> = (props) => {
  const { className, badge, textData, withText = true, withIcon = true, iconClassName } = props;

  if (!badge) return null;

  const { icon, label } = getBadgeData(badge as CreatorBadgeEnum);

  return (
    <div className={`${styles[camelCase(badge)]} ${styles.root} ${className}`}>
      {withIcon && icon && <img src={icon} className={iconClassName} />}
      {withText && <Text msg={label} className={withIcon ? styles.title : ''} {...textData} />}
    </div>
  );
};

export default CreatorBadge;

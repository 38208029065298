/**
 * @generated SignedSource<<3b61b5e0cd784b8d10f3bb588d9f2374>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomListDetails_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomListEditDrawer_customList">;
  readonly " $fragmentType": "CustomListDetails_item";
};
export type CustomListDetails_item$key = {
  readonly " $data"?: CustomListDetails_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomListDetails_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomListDetails_item",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomListEditDrawer_customList"
    }
  ],
  "type": "CustomList",
  "abstractKey": null
};

(node as any).hash = "21f8cce79f26082b4a7d6fb9707d4fa8";

export default node;

import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './ContractorBanner.pcss';

import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import logo from 'Images/general/logo_white_bg.svg';
import { modalName } from 'Types/modals';
import { setModal } from 'Actions/Modal.Actions';

const ContractorBanner = () => {
  const dispatch = useDispatch();

  const onReadMoreClick = () => dispatch(setModal(modalName.DOWNLOAD_APP));

  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        <img src={logo} />
      </div>
      <Text msg="general.download_the_app" />
      <TextButton
        className={styles.link}
        msg="general.read_more"
        onClick={onReadMoreClick}
        size="lg"
      />
    </div>
  );
};

export default ContractorBanner;

/**
 * @generated SignedSource<<9d3cd1c1acca338a0007bac96bb1e956>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BcaPermissionState = "APPROVED" | "CANCELED" | "EXPIRED" | "INVALID" | "PENDING_APPROVAL" | "REVOKED" | "UNKNOWN" | "%future added value";
export type RequestBcaPermissionInput = {
  brandInstagramId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
};
export type RequestBcaPermissionMutation$variables = {
  input: RequestBcaPermissionInput;
};
export type RequestBcaPermissionMutation$data = {
  readonly requestBcaPermission: {
    readonly bcaRequest: {
      readonly permission: {
        readonly state: BcaPermissionState;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type RequestBcaPermissionMutation = {
  response: RequestBcaPermissionMutation$data;
  variables: RequestBcaPermissionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestBcaPermissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestBcaPermissionPayload",
        "kind": "LinkedField",
        "name": "requestBcaPermission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BcaRequest",
            "kind": "LinkedField",
            "name": "bcaRequest",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BcaPermission",
                "kind": "LinkedField",
                "name": "permission",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestBcaPermissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestBcaPermissionPayload",
        "kind": "LinkedField",
        "name": "requestBcaPermission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BcaRequest",
            "kind": "LinkedField",
            "name": "bcaRequest",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BcaPermission",
                "kind": "LinkedField",
                "name": "permission",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40885107821285ddfcd4c8a48f1812f5",
    "id": null,
    "metadata": {},
    "name": "RequestBcaPermissionMutation",
    "operationKind": "mutation",
    "text": "mutation RequestBcaPermissionMutation(\n  $input: RequestBcaPermissionInput!\n) {\n  requestBcaPermission(input: $input) {\n    bcaRequest {\n      permission {\n        state\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6599abc11559459d0bee26d8d7b3e883";

export default node;

import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import AdminAdvertisers from './AdminAdvertisers';

import { AdminAdvertisersContainerQuery as QueryType } from 'GraphTypes/AdminAdvertisersContainerQuery.graphql';

const AdminAdvertisersContainerQuery = graphql`
  query AdminAdvertisersContainerQuery {
    planIds
  }
`;

const AdminAdvertisersContainer: React.FC = () => {
  const data = useLazyLoadQuery<QueryType>(AdminAdvertisersContainerQuery, {});

  if (!data) return null;

  return <AdminAdvertisers planIds={data.planIds} />;
};

export default AdminAdvertisersContainer;

import React from 'react';

import styles from './FullscreenLoader.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

const FullscreenLoader: React.FC = () => {
  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};

export default FullscreenLoader;

import { createContext, Context } from 'react';

import type { DrawerId } from './types';

type DrawerContextType<IDs extends DrawerId = DrawerId> = {
  idPrefix: string;
  activeDrawerId: DrawerId | null;
  openDrawer: (drawerId: IDs, showPrevDrawer?: boolean) => void;
  closeDrawer: (drawerId: IDs) => void;
};

const getDrawerContextInstance = (() => {
  let instance: Context<DrawerContextType>;
  return <IDs extends DrawerId>(): Context<DrawerContextType<IDs>> => {
    if (!instance) {
      instance = createContext<DrawerContextType<IDs>>({} as DrawerContextType<IDs>);
    }
    return instance;
  };
})();

export { getDrawerContextInstance };
export type { DrawerContextType };

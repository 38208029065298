/**
 * @generated SignedSource<<521a0ed6a30238bc257e45d080dc96f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignPreviewCounters_campaign$data = {
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly counters: {
    readonly completedProjects: number;
    readonly launchedProjects: number;
    readonly newApplicants: number;
    readonly projectsWithSubmittedContent: number;
    readonly projectsWithUnreadEventsExcludingOfferAcceptances: number;
    readonly unreadProjectEventsExcludingOfferAcceptance: number;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "CampaignPreviewCounters_campaign";
};
export type CampaignPreviewCounters_campaign$key = {
  readonly " $data"?: CampaignPreviewCounters_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignPreviewCounters_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignPreviewCounters_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignCounters",
      "kind": "LinkedField",
      "name": "counters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "launchedProjects",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedProjects",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newApplicants",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectsWithUnreadEventsExcludingOfferAcceptances",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadProjectEventsExcludingOfferAcceptance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectsWithSubmittedContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "96a3165c6a9c1544fe2467e62681dc8d";

export default node;

import React from 'react';

import styles from './CampaignOutreachInactiveBanner.pcss';

import IconNew from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';

const CampaignOutreachInactiveBanner: React.FC = () => (
  <div className={styles.root}>
    <IconNew name="Warning-error" className={styles.warnIcon} />
    <Text msg="outreach_campaign.inactive.banner" />
  </div>
);

export default CampaignOutreachInactiveBanner;

import { IntlShape } from 'react-intl';

import { ControlItem } from '../../components/SegmentedControls/SegmentedControls';

import { PRODUCT_SEEDING } from 'Constants/general';
import {
  CreativeAsset_briefCreative,
  BriefCreativeContentType,
  BriefCreativeContentFormat,
} from 'GraphTypes/CreativeAsset_briefCreative.graphql';
import {
  CampaignType,
  CampaignPlatform,
  CampaignObjective,
} from 'GraphTypes/CreativeAssets_campaign.graphql';

export const getMediaTypesList = (): ControlItem[] => {
  return [
    {
      id: 'VIDEO',
      value: 'VIDEO',
      titleMsg: 'brief_template.creative_asset.media_type.video',
      dataTest: 'button:creativeMediaType:video',
    },
    {
      id: 'PHOTO',
      value: 'PHOTO',
      titleMsg: 'brief_template.creative_asset.media_type.photo',
      dataTest: 'button:creativeMediaType:photo',
    },
  ];
};

export const getPostingTypesList = (data: { campaignId: string }): ControlItem[] => {
  const { campaignId } = data;

  return [
    {
      id: 'required',
      value: true,
      titleMsg: 'brief_template.creative_asset.posting.required',
      tooltipMsg: 'brief_template.creative_asset.posting.required.tooltip',
      dataTest: 'button:creativePosting:posting',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'posting', campaignId },
      },
    },
    {
      id: 'not_required',
      value: false,
      titleMsg: 'brief_template.creative_asset.posting.not_required',
      tooltipMsg: 'brief_template.creative_asset.posting.not_required.tooltip',
      dataTest: 'button:creativePosting:notRequired',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'no_posting', campaignId },
      },
    },
  ];
};

export const getPlacementTypesList = (): ControlItem[] => {
  return [
    {
      id: 'STORY',
      value: 'STORY',
      titleMsg: 'brief_template.creative_asset.placement.story',
    },
    {
      id: 'FEED',
      value: 'FEED',
      titleMsg: 'brief_template.creative_asset.placement.feed',
    },
    {
      id: 'REEL',
      value: 'REEL',
      titleMsg: 'brief_template.creative_asset.placement.reel',
    },
  ];
};

export const getFormatTypesList = (): ControlItem[] => {
  return [
    {
      id: 'VERTICAL_9_16',
      value: 'VERTICAL_9_16',
      iconName: 'verticalVideo',
      titleText: '9:16',
      subtitleMsg: 'brief_template.creative_asset.format.vertical',
      dataTest: 'button:creativeFormat:9:16',
    },
    {
      id: 'VERTICAL_4_5',
      value: 'VERTICAL_4_5',
      iconName: 'verticalVideo_4_5',
      titleText: '4:5',
      subtitleMsg: 'brief_template.creative_asset.format.vertical',
      dataTest: 'button:creativeFormat:4:5',
    },
    {
      id: 'SQUARE',
      value: 'SQUARE',
      iconName: 'squareVideo',
      titleText: '1:1',
      subtitleMsg: 'brief_template.creative_asset.format.square',
      dataTest: 'button:creativeFormat:1:1',
    },
    {
      id: 'HORIZONTAL_16_9',
      value: 'HORIZONTAL_16_9',
      iconName: 'horizontalVideo',
      titleText: '16:9',
      subtitleMsg: 'brief_template.creative_asset.format.horizontal',
      dataTest: 'button:creativeFormat:16:9',
    },
  ];
};

export const getPreparednessTypesList = (data: { campaignId: string }): ControlItem[] => {
  const { campaignId } = data;

  return [
    {
      id: 'RAW',
      value: 'RAW',
      titleMsg: 'brief_template.creative_asset.preparedness.raw',
      tooltipMsg: 'brief_template.creative_asset.preparedness.raw.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'raw_footage', campaignId },
      },
    },
    {
      id: 'FINAL',
      value: 'FINAL',
      titleMsg: 'brief_template.creative_asset.preparedness.final',
      tooltipMsg: 'brief_template.creative_asset.preparedness.final.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'ready_to_use_ad', campaignId },
      },
    },
    {
      id: 'RAW_AND_FINAL',
      value: 'RAW_AND_FINAL',
      titleMsg: 'brief_template.creative_asset.preparedness.raw_and_ready',
      tooltipMsg: 'brief_template.creative_asset.preparedness.raw_and_ready.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'ready_to_use_ad_raw_footage', campaignId },
      },
    },
  ];
};

export const getMusicRequiredList = (): ControlItem[] => {
  return [
    {
      id: 'not_required',
      value: false,
      titleMsg: 'brief_template.creative_asset.music.not_required',
    },
    {
      id: 'required',
      value: true,
      titleMsg: 'brief_template.creative_asset.music.required',
    },
  ];
};

export const getContentTypesList = (data: { campaignId: string }): ControlItem[] => {
  const { campaignId } = data;

  return [
    {
      id: 'TESTIMONIAL',
      value: 'TESTIMONIAL',
      titleMsg: 'brief_template.creative_asset.content_type.testimonial',
      tooltipMsg: 'brief_template.creative_asset.content_type.testimonial.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'testimonial', campaignId },
      },
    },
    {
      id: 'UNBOXING',
      value: 'UNBOXING',
      titleMsg: 'brief_template.creative_asset.content_type.unboxing',
      tooltipMsg: 'brief_template.creative_asset.content_type.unboxing.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'unboxing', campaignId },
      },
    },
    {
      id: 'PRODUCT_DEMO',
      value: 'PRODUCT_DEMO',
      titleMsg: 'brief_template.creative_asset.content_type.product_demo',
      tooltipMsg: 'brief_template.creative_asset.content_type.product_demo.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'product_demo', campaignId },
      },
    },
    {
      id: 'PRODUCT_REVIEW',
      value: 'PRODUCT_REVIEW',
      titleMsg: 'brief_template.creative_asset.content_type.product_review',
      tooltipMsg: 'brief_template.creative_asset.content_type.product_review.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'product_review', campaignId },
      },
    },
    {
      id: 'HOWTO',
      value: 'HOWTO',
      titleMsg: 'brief_template.creative_asset.content_type.howto',
      tooltipMsg: 'brief_template.creative_asset.content_type.howto.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'howto', campaignId },
      },
    },
    {
      id: 'CUSTOM',
      value: 'CUSTOM',
      titleMsg: 'brief_template.creative_asset.content_type.custom',
      tooltipMsg: 'brief_template.creative_asset.content_type.custom.tooltip',
      tooltipEventData: {
        id: '83',
        category: 'brief',
        name: 'creative_assets_tooltip_show',
        param: { info: 'custom', campaignId },
      },
    },
  ];
};

type ConditionType = {
  creative: CreativeAsset_briefCreative;
  campaignType?: CampaignType | null;
  campaignPlatform?: CampaignPlatform | null;
};

export const getViewConditions = ({ creative, campaignType, campaignPlatform }: ConditionType) => {
  const isIGPlatform =
    campaignPlatform === 'INSTAGRAM' ||
    campaignPlatform === 'FACEBOOK' ||
    campaignPlatform === 'OTHER';

  const publishingVisible = creative.mediaType && campaignType === 'INFLUENCER';
  const amountVisible = creative.mediaType === 'PHOTO';
  const contentPlacementVisible = creative.publishingRequired && isIGPlatform;
  const formatVisible =
    (contentPlacementVisible && creative.v2ContentPlacement) ||
    (!contentPlacementVisible && creative.mediaType && isIGPlatform);
  const durationVisible = creative.mediaType === 'VIDEO';
  const preparednessVisible =
    durationVisible && creative.videoDurationMin && creative.videoDurationMax;
  const musicVisible =
    creative.contentPreparedness === 'RAW_AND_FINAL' || creative.contentPreparedness === 'FINAL';
  const musicDetailsVisible = musicVisible && creative.contentMusic;
  const contentTypeVisible = creative.mediaType === 'VIDEO' && creative.contentPreparedness;
  const dosVisible =
    (creative.mediaType === 'VIDEO' && creative.contentPreparedness) ||
    (creative.mediaType === 'PHOTO' && ((isIGPlatform && creative.contentFormat) || !isIGPlatform));
  const captionVisible = dosVisible && creative.v2ContentPlacement === 'FEED';

  return {
    publishing: !!publishingVisible,
    amount: !!amountVisible,
    contentPlacement: !!contentPlacementVisible,
    format: !!formatVisible,
    duration: !!durationVisible,
    preparedness: !!preparednessVisible,
    music: !!musicVisible,
    musicDetails: !!musicDetailsVisible,
    contentType: !!contentTypeVisible,
    dos: !!dosVisible,
    donts: !!dosVisible,
    caption: !!captionVisible,
    references: !!dosVisible,
  };
};

type DefaultDosType = {
  intl: IntlShape;
  contentType?: BriefCreativeContentType | null;
  campaignObjective?: CampaignObjective | null;
};

export const getDefaultDos = ({ contentType, intl, campaignObjective }: DefaultDosType) => {
  const objectivePostfix =
    campaignObjective === 'CONVERSIONS'
      ? intl.formatMessage({
          id: 'brief_template.creative_asset.dos.template.objective_conversions',
        })
      : '';

  if (contentType) {
    return (
      intl.formatMessage({
        id: `brief_template.creative_asset.dos.template.${contentType.toLowerCase()}`,
      }) + objectivePostfix
    );
  }

  return undefined;
};

type VideoDurationDefaultType = {
  creative: CreativeAsset_briefCreative;
  campaignPlatform?: CampaignPlatform | null;
  newContentFormat?: BriefCreativeContentFormat | null;
};

export const getDefaultVideoDuration = ({
  creative,
  campaignPlatform,
  newContentFormat: contentFormat,
}: VideoDurationDefaultType) => {
  const { publishingRequired, v2ContentPlacement, mediaType } = creative;

  if (mediaType !== 'VIDEO') return null;

  if (campaignPlatform === 'TIKTOK') {
    return { videoDurationMin: 20, videoDurationMax: 40 };
  }

  if (publishingRequired) {
    if (campaignPlatform === 'INSTAGRAM' || campaignPlatform === 'FACEBOOK') {
      if (v2ContentPlacement === 'FEED') {
        return { videoDurationMin: 30, videoDurationMax: 60 };
      } else if (v2ContentPlacement === 'STORY' || v2ContentPlacement === 'REEL') {
        return { videoDurationMin: 9, videoDurationMax: 15 };
      }
    }
  } else if (contentFormat) {
    if (campaignPlatform === 'INSTAGRAM' || campaignPlatform === 'FACEBOOK') {
      if (contentFormat === 'VERTICAL_9_16') {
        return { videoDurationMin: 9, videoDurationMax: 15 };
      }

      return { videoDurationMin: 30, videoDurationMax: 60 };
    }
  }

  return null;
};

type AssetValidationType = {
  creative: CreativeAsset_briefCreative;
  campaignPlatform?: CampaignPlatform | null;
  campaignType?: CampaignType | null;
};

export const validateAsset = ({
  creative,
  campaignPlatform,
  campaignType,
}: AssetValidationType) => {
  if (campaignType === PRODUCT_SEEDING) return true;

  const {
    mediaType,
    publishingRequired,
    v2ContentPlacement,
    contentFormat,
    videoDurationMin,
    videoDurationMax,
    contentPreparedness,
    contentMusic,
    contentMusicDescription,
    v2ContentType,
    dos,
  } = creative;

  const isIGPlatform = campaignPlatform === 'INSTAGRAM' || campaignPlatform === 'FACEBOOK';

  if (!mediaType) {
    return false;
  } else if (publishingRequired && isIGPlatform && !v2ContentPlacement) {
    return false;
  } else if (isIGPlatform && !contentFormat) {
    return false;
  } else if (mediaType === 'VIDEO' && !videoDurationMin && !videoDurationMax) {
    return false;
  } else if (mediaType === 'VIDEO' && !contentPreparedness) {
    return false;
  } else if (
    mediaType === 'VIDEO' &&
    (contentPreparedness === 'RAW_AND_FINAL' || contentPreparedness === 'FINAL') &&
    contentMusic === undefined
  ) {
    return false;
  } else if (contentMusic && !contentMusicDescription) {
    return false;
  } else if (mediaType === 'VIDEO' && !v2ContentType) {
    return false;
  } else if (!dos) {
    return false;
  }

  return true;
};

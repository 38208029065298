import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useClientQuery, useQueryLoader } from 'react-relay';

import type { FilterValuesState } from '../../types';
import { makeVariables } from '../../utils';
import Chart from '../Chart/Chart';

import styles from './TransactionsChart.pcss';
import {
  TransactionsChartQuery,
  TransactionsChartQueryType,
  TransactionsChartQuery$variables,
} from './TransactionsChart.Query';
import { chartData } from './utils';

interface Props {
  className?: string;
  filterValues: FilterValuesState;
}

const TransactionsChart: React.FC<Props> = (props) => {
  const { className, filterValues } = props;

  if (!filterValues.brand) return null;

  const variables = useMemo<TransactionsChartQuery$variables>(() => {
    return makeVariables(filterValues, 'paymentTransactionCounters');
  }, [filterValues]);

  const [, loadQuery] = useQueryLoader<TransactionsChartQueryType>(TransactionsChartQuery);

  const response = useClientQuery<TransactionsChartQueryType>(TransactionsChartQuery, variables);

  useEffect(() => {
    loadQuery(variables);
  }, [variables]);

  const isEmpty = !response.performance?.paymentTransactionCounters?.edges?.length;
  const granularity = filterValues.granularity;

  const data = useMemo(() => {
    return chartData(response, granularity);
  }, [response, granularity]);

  return (
    <div className={cn(styles.root, className)}>
      <Chart
        stats={data.stats}
        lines={data.lines}
        isCurrency={true}
        title={{ msg: 'performance.dashboard.chart.transactions.title' }}
      />
    </div>
  );
};

export default TransactionsChart;

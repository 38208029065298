import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useClientQuery, useQueryLoader } from 'react-relay';

import type { FilterValuesState } from '../../types';
import { makeVariables } from '../../utils';
import Chart from '../Chart/Chart';

import styles from './CpmCpeCountersChart.pcss';
import {
  CpmCpeCountersChartQuery,
  CpmCpeCountersChartQueryType,
  CpmCpeCountersChartQuery$variables,
} from './CpmCpeCountersChart.Query';
import { chartData } from './utils';

interface Props {
  className?: string;
  filterValues: FilterValuesState;
}

const CpmCpeCountersChart: React.FC<Props> = (props) => {
  const { className, filterValues } = props;

  if (!filterValues.brand) return null;

  const variables = useMemo<CpmCpeCountersChartQuery$variables>(() => {
    return makeVariables(filterValues, 'cpmCpeCounters');
  }, [filterValues]);

  const [, loadQuery] = useQueryLoader<CpmCpeCountersChartQueryType>(CpmCpeCountersChartQuery);

  const response = useClientQuery<CpmCpeCountersChartQueryType>(
    CpmCpeCountersChartQuery,
    variables
  );

  useEffect(() => {
    loadQuery(variables);
  }, [variables]);

  const isEmpty = !response.performance?.cpmCpeCounters?.edges?.length;
  const granularity = filterValues.granularity;

  const data = useMemo(() => {
    return chartData(response, granularity);
  }, [response, granularity]);

  return (
    <div className={cn(styles.root, className)}>
      <Chart
        stats={data.stats}
        lines={data.lines}
        isCurrency={true}
        title={{ msg: 'performance.dashboard.chart.cpm_cpe_counters.title' }}
      />
    </div>
  );
};

export default CpmCpeCountersChart;

import React, { cloneElement, PropsWithChildren, useMemo, useState } from 'react';
import classNames from 'classnames';

import IconNew from '../Icon/Icon';

import styles from './Slider.pcss';

type Slide = {
  key: number;
  component: SlideComponent;
  onClick: () => void;
};

type SlideElement = React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>;

type SlideComponent = {
  element: SlideElement;
};

type Props = {
  slides: Slide[];
  classNameWithSize?: string;
};

const Slider = (props: PropsWithChildren<Props>) => {
  const { children, slides, classNameWithSize } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const prevSlide = () => {
    setActiveIndex(activeIndex === 0 ? slides.length - 1 : activeIndex - 1);
  };

  const nextSlide = () => {
    setActiveIndex(activeIndex === slides.length - 1 ? 0 : activeIndex + 1);
  };

  const sliderControls = useMemo(() => {
    if (slides.length < 2) {
      return null;
    }
    return (
      <div className={styles.controlContainer}>
        {activeIndex > 0 && (
          <div className={classNames(styles.prevBtn, styles.controlButton)} onClick={prevSlide}>
            <IconNew name={'Arrow-small-left'} />
          </div>
        )}
        {activeIndex < slides.length - 1 && (
          <div className={classNames(styles.nextBtn, styles.controlButton)} onClick={nextSlide}>
            <IconNew name={'Arrow-small-right'} />
          </div>
        )}
        <div className={styles.pagination}>
          {slides.map((slide, idx) => (
            <div
              key={slide.key}
              className={classNames(
                styles.paginationItem,
                activeIndex === idx ? styles.activePaginationItem : ''
              )}
            />
          ))}
        </div>
      </div>
    );
  }, [slides.length, activeIndex]);

  const renderItem = (slide: Slide, index: number) => {
    if (index !== activeIndex) return null;
    return cloneElement(slide.component.element);
  };
  const rootClassList = classNames(styles.root, classNameWithSize || '');
  const containerClassList = classNames(styles.sliderContainer, classNameWithSize || '');
  return (
    <div className={rootClassList}>
      {children}
      <div
        className={containerClassList}
        style={{ transform: `translateX(${activeIndex * -100}%)` }}
      >
        {slides.map((slide, idx) => (
          <div key={slide.key} className={styles.slide}>
            {renderItem(slide, idx)}
          </div>
        ))}
      </div>
      {sliderControls}
    </div>
  );
};

export default Slider;

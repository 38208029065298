import { graphql } from 'react-relay';

import type { ListsQuery as QueryType } from 'GraphTypes/ListsQuery.graphql';
import { CustomLists_organization$data } from 'GraphTypes/CustomLists_organization.graphql';

const ListsQuery = graphql`
  query ListsQuery {
    currentUser {
      organization {
        id
        ...CustomLists_organization
        ...CustomListFavoritesDetailsFragment
      }
    }
  }
`;

export { ListsQuery };

// types

type ListsQueryType = QueryType & {
  readonly response: QueryType['response'] & {
    readonly currentUser:
      | (QueryType['response']['currentUser'] & {
          readonly organization: CustomLists_organization$data | null;
        })
      | null;
  };
};

export type { ListsQueryType };

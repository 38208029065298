import React from 'react';

import styles from './AvatarItem.css';

import Avatar from 'Atoms/Avatar/Avatar';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { TextType, AvatarType } from 'Types/common';

interface Props {
  avaData?: AvatarType,
  avaSize?: string,
  avaEl?: JSX.Element,
  className?: string,
  subtitleEl?: JSX.Element,
  subtitleData?: TextType,
  titleData?: TextType,
  titleLink?: string,
  subtitleLink?: string,
  reverse?: boolean,
  tooltipText?: string | null,
  details?: JSX.Element,
}

const AvatarItem: React.FC<Props> = props => {
  const {
    avaData,
    avaEl,
    avaSize = '40',
    className,
    subtitleData,
    titleData,
    titleLink,
    subtitleEl,
    subtitleLink,
    reverse,
    tooltipText,
    details,
  } = props;

  const reverseStyle = reverse ? styles.reverse : undefined;

  const titleColor = titleLink ? 'creamCan' : undefined;
  const subtitleColor = subtitleLink ? 'creamCan' : 'grayDog';

  return (
    <div className={`${className} ${styles.root} ${reverseStyle}`}>
      <div className={styles.avaWrap}>
        <Tooltip id="ava" tooltipText={tooltipText}>
          <Avatar
            size={avaSize}
            {...avaData}
            className={styles.ava}
          />
        </Tooltip>
        {avaEl}
      </div>
      <div className={styles.details}>
        <SimpleLink href={titleLink} display="block" target="_blank">
          <Text
            weight="500"
            color={titleColor}
            {...titleData}
            overflow
          />
        </SimpleLink>
        {subtitleData && (
          <SimpleLink href={subtitleLink} display="block" target="_blank">
            <Text
              size="sm"
              color={subtitleColor}
              {...subtitleData}
              overflow
            />
          </SimpleLink>
        )}
        {details}
        {subtitleEl}
      </div>
    </div>
  );
};

export default AvatarItem;
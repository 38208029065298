import React from 'react';
import { useParams } from 'react-router-dom';

import styles from './CampaignOutreach.pcss';

import Brief from 'Templates/Brief/Brief';
import Header from 'Templates/Layout/Header/Header';
import Page from 'Templates/Page/Page';

const CampaignOutreach: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();

  return (
    <Page>
      <Header showStaticHeader classes={{ wrap: styles.header }} />
      <Brief campaignId={campaignId} outreachType={'campaign'} />
    </Page>
  );
};

export default CampaignOutreach;

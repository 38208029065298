import React, { useState } from 'react';
import remove from 'lodash/remove';

import Post from '../Post/Post';

import { VIDEO } from 'Constants/general';
import { SponsoredPosts_creator$data } from 'GraphTypes/SponsoredPosts_creator.graphql';

interface PostsProps {
  list?: NonNullable<SponsoredPosts_creator$data['profile']>['topSponsoredPosts'];
  name: string;
  onSectionEmpty?: (name: string) => void;
  isYoutube: boolean;
  isTiktok: boolean;
}

const Posts = (props: PostsProps) => {
  const { list: defaultList, name, isYoutube, isTiktok, onSectionEmpty } = props;

  const [list, setList] = useState(defaultList);

  if (!list || list.length === 0) {
    if (onSectionEmpty) {
      onSectionEmpty(name);
    }

    return null;
  }

  const handleLoadError = (id: string) => {
    const newList = [...list];
    remove(newList, (item) => {
      item.thumbnailUrl === id;
    });
    setList(newList);
  };

  return list.map((item, index) => {
    const { thumbnailUrl, mediaType, link } = item;
    const videoUrl = mediaType === VIDEO ? thumbnailUrl : undefined;
    const id = thumbnailUrl || index.toString();

    const onPostClick =
      mediaType === VIDEO && link && !isYoutube && !isTiktok
        ? () => {
            window.open(link);
          }
        : undefined;

    return (
      <Post
        key={id}
        id={id}
        {...item}
        instagramUrl={link}
        finalVideoUrl={videoUrl}
        onLoadError={handleLoadError}
        onPostClick={onPostClick}
        isYoutube={isYoutube}
        isTiktok={isTiktok}
      />
    );
  });
};

export default Posts;

import { createContext } from 'react';
import { User } from 'firebase/auth';

import { CurrentUserQuery$data } from './CurrentUser.Query';

import { ADVERTISER, CONTRACTOR } from 'Constants/general';

const Auth0Context = createContext<FirebaseAuthContextType>({} as FirebaseAuthContextType);

export { Auth0Context };

// types

type ExtendedFirebaseUser = User & {
  emailVerified: boolean;
};

type FirebaseAuthContextType = {
  isAuthenticatedUser: boolean | null;
  isRegisteredUser: boolean | null;
  isLoading: boolean;
  user: CurrentUserQuery$data['currentUser'] | null;
  firebaseUser: ExtendedFirebaseUser | false | null;
  logout: () => void;
  signUpWithPopup: (userType?: typeof ADVERTISER | typeof CONTRACTOR) => Promise<void>;
  setIsRegisteredUserForce: (isUserRegistered: boolean) => void;
};

type AppStateType = {
  userType?: typeof ADVERTISER | typeof CONTRACTOR;
  popup?: boolean;
  redirectUriAfterRegistration?: string;
  redirectUriAfterLogin?: string;
  searchParamsKeys?: string[];
};

export type { FirebaseAuthContextType, AppStateType };

import { graphql, useMutation } from 'react-relay';
import { AuthenticateWithGmailMutation } from 'GraphTypes/AuthenticateWithGmailMutation.graphql';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation AuthenticateWithGmailMutation($input: AuthenticateWithGmailInput!) {
    authenticateWithGmail(input: $input) {
      __typename
      ... on AuthenticateWithGmailPayload {
        gmailAuthorization {
          id
          reauthenticationRequired
          availableEmails
          availableNames
        }
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        message
      }
    }
  }
`;

export const useAuthenticateWithGmailMutation: BaseMutation<AuthenticateWithGmailMutation> = (
  config
) => {
  const [authenticateWithGmail, loading] = useMutation<AuthenticateWithGmailMutation>(mutation);

  const authenticateWithGmailCaller: BaseMutationExecFn<AuthenticateWithGmailMutation> = (
    execConfig
  ) => {
    authenticateWithGmail({
      ...config,
      ...execConfig,
    });
  };
  return [authenticateWithGmailCaller, loading];
};

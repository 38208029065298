import { graphql, useMutation } from 'react-relay';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';
import { ReactivateSubscriptionMutation } from 'GraphTypes/ReactivateSubscriptionMutation.graphql';

const mutation = graphql`
  mutation ReactivateSubscriptionMutation($input: ReactivateSubscriptionInput!) {
    reactivateSubscription(input: $input) {
      ... on ReactivateSubscriptionPayload {
        advertiserSubscription {
          cancelAt
          renewsAt
          lastSchedule {
            discount {
              percentOff
            }
            trialEnd
            startDate
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const useReactivateSubscriptionMutation: BaseMutation<ReactivateSubscriptionMutation> = (
  config
) => {
  const [reactivateSubscription, loading] = useMutation<ReactivateSubscriptionMutation>(mutation);

  const reactivateSubscriptionCaller: BaseMutationExecFn<ReactivateSubscriptionMutation> = (
    execConfig
  ) => {
    reactivateSubscription({
      ...config,
      ...execConfig,
    });
  };
  return [reactivateSubscriptionCaller, loading];
};

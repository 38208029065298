/**
 * @generated SignedSource<<6c2484133c45f8580165813f995c7878>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES" | "%future added value";
export type Stripe_CardBrand = "AMEX" | "DINERS" | "DISCOVER" | "JCB" | "MASTERCARD" | "UNIONPAY" | "UNKNOWN" | "VISA" | "%future added value";
export type Stripe_PaymentMethodType = "CARD" | "%future added value";
export type ConfirmNewPlanQuery$variables = Record<PropertyKey, never>;
export type ConfirmNewPlanQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly stripeCustomer: {
        readonly defaultPaymentMethod: {
          readonly brand?: Stripe_CardBrand | null | undefined;
          readonly id?: string;
          readonly last4?: string | null | undefined;
          readonly stripeId?: string;
        } | null | undefined;
        readonly paymentMethods: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly brand?: Stripe_CardBrand | null | undefined;
              readonly createdAt?: any;
              readonly expMonth?: number | null | undefined;
              readonly expYear?: number | null | undefined;
              readonly id?: string;
              readonly last4?: string | null | undefined;
              readonly stripeId?: string;
              readonly type?: Stripe_PaymentMethodType;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly subscription: {
        readonly addonItems: ReadonlyArray<{
          readonly addon: {
            readonly id: AddonId;
          };
          readonly quantity: number;
        }>;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ConfirmNewPlanQuery = {
  response: ConfirmNewPlanQuery$data;
  variables: ConfirmNewPlanQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AddonItem",
  "kind": "LinkedField",
  "name": "addonItems",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Addon",
      "kind": "LinkedField",
      "name": "addon",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brand",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expYear",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expMonth",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmNewPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_Customer",
                "kind": "LinkedField",
                "name": "stripeCustomer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "defaultPaymentMethod",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/),
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "type": "Stripe_Card",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stripe_PaymentMethodConnection",
                    "kind": "LinkedField",
                    "name": "paymentMethods",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stripe_PaymentMethodEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v0/*: any*/),
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v3/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "type": "Stripe_Card",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConfirmNewPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_Customer",
                "kind": "LinkedField",
                "name": "stripeCustomer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "defaultPaymentMethod",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v0/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "type": "Stripe_Card",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stripe_PaymentMethodConnection",
                    "kind": "LinkedField",
                    "name": "paymentMethods",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stripe_PaymentMethodEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v0/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v3/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "type": "Stripe_Card",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8654069768115caf3feb162d24543cb",
    "id": null,
    "metadata": {},
    "name": "ConfirmNewPlanQuery",
    "operationKind": "query",
    "text": "query ConfirmNewPlanQuery {\n  currentUser {\n    organization {\n      subscription {\n        addonItems {\n          addon {\n            id\n          }\n          quantity\n        }\n        id\n      }\n      stripeCustomer {\n        defaultPaymentMethod {\n          __typename\n          ... on Stripe_Card {\n            id\n            stripeId\n            last4\n            brand\n          }\n          id\n        }\n        paymentMethods {\n          edges {\n            node {\n              __typename\n              ... on Stripe_Card {\n                id\n                stripeId\n                brand\n                createdAt\n                expYear\n                expMonth\n                last4\n                type\n              }\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "50c43760a3cb8b12710670193973f3a7";

export default node;

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import track from 'react-tracking';

import PostsSection from '../PostsSection/PostsSection';

import PortfolioGroups from './PortfolioGroups/PortfolioGroups';

import { Portfolio_creator$key } from 'GraphTypes/Portfolio_creator.graphql';

interface Props {
  creator: Portfolio_creator$key;
}

const PortfolioList: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment Portfolio_creator on Creator {
        portfolioGroups {
          type {
            name
          }
          items {
            ...PortfolioItem_portfolioItem
          }
        }
      }
    `,
    creator
  );

  if (!data.portfolioGroups || data.portfolioGroups.length === 0) return null;
  const { portfolioGroups } = data;

  return (
    <PostsSection title="creator.portfolio">
      <PortfolioGroups portfolioGroups={portfolioGroups} />
    </PostsSection>
  );
};

const Portfolio = track({
  section: 'portfolio',
})(PortfolioList);

export default Portfolio;

/**
 * @generated SignedSource<<028ce99efb95a1faf44424a916f5e830>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutreachBatchState = "ACTIVE" | "INITIAL" | "%future added value";
export type OutreachBatchesQuery$variables = {
  campaignId: string;
};
export type OutreachBatchesQuery$data = {
  readonly campaign: {
    readonly outreachBatches: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly creatorImport: {
            readonly completed: boolean;
            readonly createdAt: any;
            readonly csvFilename: string | null | undefined;
            readonly importedCreators: number;
            readonly rows: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly email: string | null | undefined;
                  readonly id: string;
                  readonly username: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            };
            readonly totalCreators: number;
          } | null | undefined;
          readonly id: string;
          readonly state: OutreachBatchState;
          readonly template: {
            readonly name: string;
          } | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"OutreachBatch_outreachBatch">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    };
  } | null | undefined;
  readonly outreachCounters: {
    readonly enqueuedCount: number;
    readonly todayCount: number;
    readonly todayLimit: number | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
};
export type OutreachBatchesQuery = {
  response: OutreachBatchesQuery$data;
  variables: OutreachBatchesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "csvFilename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importedCreators",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCreators",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "OutreachCounters",
  "kind": "LinkedField",
  "name": "outreachCounters",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enqueuedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "todayCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "todayLimit",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorImport",
                        "kind": "LinkedField",
                        "name": "creatorImport",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorImportRowConnection",
                            "kind": "LinkedField",
                            "name": "rows",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CreatorImportRowEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CreatorImportRow",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachTemplate",
                        "kind": "LinkedField",
                        "name": "template",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OutreachBatch_outreachBatch"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v13/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutreachBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorImport",
                        "kind": "LinkedField",
                        "name": "creatorImport",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorImportRowConnection",
                            "kind": "LinkedField",
                            "name": "rows",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CreatorImportRowEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CreatorImportRow",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "error",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachTemplate",
                        "kind": "LinkedField",
                        "name": "template",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transport",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GmailAuthorization",
                        "kind": "LinkedField",
                        "name": "gmailAuthorization",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reauthenticationRequired",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v13/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c394cefe35e37ba658becb355c00579e",
    "id": null,
    "metadata": {},
    "name": "OutreachBatchesQuery",
    "operationKind": "query",
    "text": "query OutreachBatchesQuery(\n  $campaignId: ID!\n) {\n  campaign(id: $campaignId) {\n    outreachBatches {\n      edges {\n        node {\n          id\n          state\n          creatorImport {\n            completed\n            csvFilename\n            createdAt\n            importedCreators\n            totalCreators\n            rows {\n              edges {\n                node {\n                  id\n                  email\n                  username\n                }\n              }\n            }\n            id\n          }\n          template {\n            name\n            id\n          }\n          ...OutreachBatch_outreachBatch\n        }\n      }\n      totalCount\n    }\n    id\n  }\n  outreachCounters {\n    totalCount\n    enqueuedCount\n    todayCount\n    todayLimit\n  }\n}\n\nfragment OutreachBatch_outreachBatch on OutreachBatch {\n  id\n  state\n  transport\n  gmailAuthorization {\n    id\n    reauthenticationRequired\n  }\n  creatorImport {\n    csvFilename\n    createdAt\n    importedCreators\n    totalCreators\n    completed\n    rows {\n      edges {\n        node {\n          id\n          email\n          username\n          error\n        }\n      }\n    }\n    id\n  }\n  template {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "013f1120d4e499d3ea2d00920ddff319";

export default node;

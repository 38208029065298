/**
 * @generated SignedSource<<e63822aa8c254f797096b0695e78ccf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthenticateWithSnapchatInput = {
  clientMutationId?: string | null | undefined;
  code?: string | null | undefined;
  error?: string | null | undefined;
  id?: string | null | undefined;
};
export type AuthenticateWithSnapchatMutation$variables = {
  input: AuthenticateWithSnapchatInput;
};
export type AuthenticateWithSnapchatMutation$data = {
  readonly authenticateWithSnapchat: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type AuthenticateWithSnapchatMutation = {
  response: AuthenticateWithSnapchatMutation$data;
  variables: AuthenticateWithSnapchatMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthenticateWithSnapchatPayload",
    "kind": "LinkedField",
    "name": "authenticateWithSnapchat",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticateWithSnapchatMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthenticateWithSnapchatMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "74e738a46ec61eb6ccf23082399d4c11",
    "id": null,
    "metadata": {},
    "name": "AuthenticateWithSnapchatMutation",
    "operationKind": "mutation",
    "text": "mutation AuthenticateWithSnapchatMutation(\n  $input: AuthenticateWithSnapchatInput!\n) {\n  authenticateWithSnapchat(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6706058b975e98f5575dd571a7ff2ce";

export default node;

import React from 'react';

import styles from './FmpBadge.css';

import SimpleLink from 'Components/SimpleLink/SimpleLink';
import mbpImg from 'Images/general/mbp_badge_black.svg';
import mbpMediumImg from 'Images/general/mbp_badge_black_md.svg';
import mbpSmallImg from 'Images/general/mbp_badge_black_sm.svg';
import { FMP_LINK } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  size?: string;
  className?: string;
}

const MbpBadge: React.FC<Props> = (props) => {
  const { className, size } = props;

  const handleClick = () => {
    amplitude.sendEvent({
      id: '238',
      category: 'pageview',
      name: 'logo_facebook_click',
      param: { referral_url: location.href },
    });
  };

  const content =
    size === 'md' ? (
      <img src={mbpMediumImg} />
    ) : size === 'sm' ? (
      <img src={mbpSmallImg} />
    ) : (
      <div className={styles.root}>
        <img src={mbpImg} />
      </div>
    );

  return (
    <SimpleLink className={className} onClick={handleClick} href={FMP_LINK} target="_blank">
      {content}
    </SimpleLink>
  );
};

export default MbpBadge;

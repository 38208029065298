import {
  PERCENT,
  TEXT,
  IMAGE_ELEMENT,
  LINK_TEXT_WITH_TOOLTIP,
  COLORIZED_TEXT,
  CUSTOM_ELEMENT,
  TWO_ROW_TEXT_WITH_LINK,
} from 'Constants/general';

const colorizedOption = {
  diffType: PERCENT,
  forwardColorDirection: true,
  element: COLORIZED_TEXT,
};

export const data = [
  {
    id: 'type',
    title: 'type',
    values: {
      src: {
        value: 'campaignType',
      },
    },
    width: '25px',
    element: IMAGE_ELEMENT,
  },
  {
    id: 'campaign',
    title: 'campaign',
    values: {
      text: {
        value: 'campaignName',
      },
      link: {
        value: 'campaignLink',
      },
    },
    width: '125px',
    element: LINK_TEXT_WITH_TOOLTIP,
  },
  {
    id: 'brand',
    title: 'brand',
    values: {
      text: {
        value: 'advertiserName',
      },
      link: {
        value: 'advertiserLink',
      },
      additionalText: {
        value: 'brandName',
      },
    },
    width: '155px',
    reverse: true,
    element: TWO_ROW_TEXT_WITH_LINK,
  },
  {
    id: 'brief',
    title: 'brief',
    values: {
      msg: {
        value: 'briefStatus',
      },
      link: {
        value: 'briefLink',
      },
    },
    width: '60px',
    element: LINK_TEXT_WITH_TOOLTIP,
  },
  {
    id: 'totalCreators',
    title: 'all',
    values: {
      text: {
        value: 'totalCreators',
      },
    },
    width: '32px',
    element: TEXT,
  },
  {
    id: 'registered',
    title: 'sign_up',
    values: {
      value: {
        value: 'registered',
      },
      total: {
        value: 'totalCreators',
      },
    },
    width: '39px',
    ...colorizedOption,
  },
  {
    id: 'accepted',
    title: 'accept',
    values: {
      value: {
        value: 'accepted',
      },
      total: {
        value: 'totalCreators',
      },
    },
    width: '38px',
    ...colorizedOption,
  },
  {
    id: 'launched',
    title: 'onboard',
    values: {
      value: {
        value: 'launched',
      },
      total: {
        value: 'totalCreators',
      },
    },
    width: '50px',
    ...colorizedOption,
  },
  {
    id: 'completed',
    title: 'finish',
    values: {
      value: {
        value: 'completed',
      },
      total: {
        value: 'totalCreators',
      },
    },
    width: '36px',
    ...colorizedOption,
  },
  {
    title: 'reject',
    width: '36px',
    values: {
      value: {
        value: 'rejected',
      },
      total: {
        value: 'totalCreators',
      },
    },
    ...colorizedOption,
    forwardColorDirection: false,
  },
  {
    id: 'pending',
    title: 'no_response',
    values: {
      value: {
        value: 'pending',
      },
      total: {
        value: 'totalCreators',
      },
    },
    width: '42px',
    ...colorizedOption,
    forwardColorDirection: false,
  },
  {
    id: 'onboardedAmount',
    title: 'onboarded',
    values: {
      text: {
        value: 'onboardedAmountValue',
      },
    },
    width: '80px',
    element: TEXT,
  },
  {
    id: 'completedAmount',
    title: 'finished',
    values: {
      text: {
        value: 'completedAmountValue',
      },
    },
    width: '80px',
    element: TEXT,
  },
  {
    id: 'unreadCount',
    values: {
      element: {
        value: 'unreadCount',
      },
    },
    width: '50px',
    style: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'actions',
    values: {
      element: {
        value: 'controls',
      },
    },
    width: '24px',
    element: CUSTOM_ELEMENT,
  },
];

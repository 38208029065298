import React from 'react';
import { graphql, useFragment } from 'react-relay';

import SubSection from '../components/SubSection/SubSection';

import styles from './StoriesStatistic.css';
import { createData } from './data';

import Text from 'Atoms/Text/Text';
import { StoriesStatistic_creator$key } from 'GraphTypes/StoriesStatistic_creator.graphql';

interface Props {
  creator?: StoriesStatistic_creator$key;
}

const StoriesStatistic: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment StoriesStatistic_creator on Creator {
        ... on InstagramCreator {
          userInsightsData {
            stories
            storiesAverageReach
            storiesAverageImpressions
          }
        }
      }
    `,
    creator
  );

  if (!data) return null;

  const creatorData = createData(data);

  return (
    <div>
      <Text tag="h1" weight="700" msg="creator_statistic.tab_stories" />
      <div className={styles.section}>
        {creatorData.map((item) => {
          const { title } = item;

          return (
            <div key={title} className={styles.item}>
              <SubSection {...item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoriesStatistic;

import ls from 'Util/localStorage';
import { RU } from 'Constants/general';
import { sendEventForAmplitude } from 'Analytics/engines/GoogleTagManager';

const PASSWORD_MIN_LENGTH = 8;
const OLD_PASSWORD_MIN_LENGTH = 6;

const passwordRe =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[ !@#$%^&*()~_+\-=[\]{};'`:"\\|,.<>/?])[a-zA-Z0-9 !@#$%^&*()~_+\-=[\]{};'`:"\\|,.<>/?]{8,}$/;

const emailRe = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const lightWebsiteUrlRe =
  /(https?: \/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const passwordValidate = (errors, password, isSignUp = true) => {
  if (!password) {
    errors.password = 'form.error_required';
  } else if (password.length < PASSWORD_MIN_LENGTH && isSignUp) {
    errors.password = 'form.error_password_length';
  } else if (password.length < OLD_PASSWORD_MIN_LENGTH && !isSignUp) {
    errors.password = 'form.error_old_password_length';
  } else if (!passwordRe.test(password) && isSignUp) {
    errors.password = 'form.error_password_form';
  }
  return errors;
};

export const lightWebsiteUrl = (url) => {
  return lightWebsiteUrlRe.test(url);
};

const deprecatedMailDomains = [];

const checkMailDeprecated = (email) => {
  let deprecated = false;

  for (let i = 0; i < deprecatedMailDomains.length; i++) {
    if (email.indexOf(deprecatedMailDomains[i]) !== -1) {
      deprecated = true;
    }
  }

  return deprecated;
};

export const checkEmailValid = (email) => {
  return emailRe.test(email.trim());
};

export const emailValidate = (errors, email) => {
  const locale = ls.get('locale');

  if (!email) {
    errors.email = 'form.error_required';
  } else if (!checkEmailValid(email)) {
    errors.email = 'form.error_email_invalid';
  } else if (locale !== RU) {
    const isMailDeprecated = checkMailDeprecated(email);
    if (isMailDeprecated) {
      errors.email = 'form.error_business_email_invalid';
    }
  }

  return errors;
};

const checkRequiredValues = (errors, values) => {
  Object.keys(values).forEach((key) =>
    !values[key] ? (errors[key] = 'form.error_required') : null
  );

  return errors;
};

export const applyProjectOfferValidate = (values) => {
  const errors = {};

  const { price, email, password } = values;

  if (!price) {
    errors.price = 'form.error_required';
  }

  if (values.priceCap && values.price && Number(values.price) > values.priceCap) {
    errors.price = 'form.error.pricecap';
    sendEventForAmplitude({
      event_id: 'd-v1-e16',
      event_cat: 'brief',
      event_name: 'price_cap_error',
    });
  }

  emailValidate(errors, email);
  passwordValidate(errors, password);

  return errors;
};

export const userNamesValidate = (values) => {
  const errors = {};
  const { firstName, lastName, email, password, companyRole } = values;

  const requiredValues = { firstName, lastName, email, password, companyRole };

  checkRequiredValues(errors, requiredValues);

  passwordValidate(errors, password);
  emailValidate(errors, email);

  return errors;
};

export const limitBrandValidate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'form.error_brand_empty_name';
  }

  return errors;
};

export const brandValidate = (values) => {
  const errors = {};
  const { name, websiteUrl, summary, categoryId } = values;

  if (!name) {
    errors.name = 'form.error_brand_empty_name';
  }

  if (!websiteUrl) {
    errors.websiteUrl = 'form.error_required';
  } else if (!lightWebsiteUrl(websiteUrl)) {
    errors.websiteUrl = 'form.error.website';
  }

  if (!summary) {
    errors.summary = 'form.error_brand_empty_summary';
  }
  if (!categoryId) {
    errors.categoryId = 'form.error_required';
  }

  return errors;
};

export const userName = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'form.error_required';
  }

  return errors;
};

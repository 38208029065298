import React, { useState } from 'react';
import compact from 'lodash/compact';
import max from 'lodash/max';

import styles from './BarChart.css';

import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';

const PREVIEW_ITEM_COUNT = 5;
const MAX_ITEM_COUNT = 15;

type BarItemType = {
  name: string,
  percentage?: number,
  count?: number,
}

interface Props {
  items: BarItemType[],
}



const BarChart: React.FC<Props> = props => {
  const { items = [] } = props;
  const defaultItems = items.slice(0, PREVIEW_ITEM_COUNT);
  const defaultShowMore = items.length > PREVIEW_ITEM_COUNT;

  const [currentItems, setItems] = useState(defaultItems);
  const [showMore, setShowMore] = useState(defaultShowMore);

  const counts = compact(items.map(item => Number(item.count)));

  const maxCountValue = counts ? max(counts) : undefined;
  const maxValue = maxCountValue || 100;

  const handleShowMore = () => {
    setShowMore(false);
    setItems(items.slice(0, MAX_ITEM_COUNT));
  };

  return (
    <div className={styles.root}>
      {currentItems.map(item => {
        const { name, percentage, count } = item;

        const percentageValue = Number(percentage) * 100;

        const valueText = count || `${Math.round(percentageValue)}%`;

        const progressWidth = (count || percentageValue) / Number(maxValue) * 100;

        const showCount = count || percentage;

        return (
          <div className={styles.item}>
            <Text text={name} className={styles.name} overflow />
            {
              showCount && (
                <Text
                  weight="500"
                  text={valueText}
                  className={styles.count}
                />
              )
            }
            {
              showCount && (
                <div className={styles.bar}>
                  <div
                    className={styles.progress}
                    style={{ width: `${progressWidth}%` }}
                  />
                </div>
              )
            }
          </div>
        );
      })}
      {showMore && <TextButton msg="general.show_more" onClick={handleShowMore} />}
    </div>
  );
};

export default BarChart;

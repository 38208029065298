import { uploadFile as uploadFileRequest, UploadFileFnType } from 'Api/uploadFile';
import createGcsFile from 'Mutations/CreateGcsFile.Mutation';
import ErrorHandler from 'Util/errorHandler';

import { CreateGcsFileMutation$data } from 'GraphTypes/CreateGcsFileMutation.graphql';

export type FileType = NonNullable<CreateGcsFileMutation$data['createGcsFile']>['file'];

type UploadFileParams = {
  params: UploadFileFnType;
  onSuccess?: (data: { file: FileType }) => void;
  onFail?: () => void;
};

interface UseUploadResult {
  uploadFile: (params: UploadFileParams) => void;
}

type UseUpload = () => UseUploadResult;
const useUpload: UseUpload = () => {
  const handleUploadFile = async (props: UploadFileParams) => {
    const { params, onSuccess, onFail } = props;
    try {
      const result = await uploadFileRequest(params);
      if (!result) return;
      const gcsFileData = await new Promise<CreateGcsFileMutation$data>((resolve, reject) => {
        createGcsFile(result, resolve, reject);
      });
      setTimeout(() => {
        if (gcsFileData.createGcsFile?.file) {
          onSuccess?.({ file: gcsFileData.createGcsFile?.file as FileType });
        } else {
          onFail?.();
        }
      }, 3000);
    } catch (error) {
      onFail?.();
      ErrorHandler.error('Crash while uploading message attachment', { error });
    }
  };

  const uploadFile = async (params: UploadFileParams) => {
    handleUploadFile(params);
  };

  return { uploadFile };
};

export { useUpload };

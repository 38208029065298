/**
 * @generated SignedSource<<3e4b60e194755f438b5ce74d165a9778>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT" | "%future added value";
export type ContentReviewStatus = "FAILED" | "PENDING" | "SUCCESS" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorChatDrawers_project$data = {
  readonly adminComment: string | null | undefined;
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly bcaRequest: {
    readonly permission: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly campaign: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
      readonly paymentAccount: {
        readonly balance: number;
        readonly currency: Currency;
      } | null | undefined;
      readonly subscription: {
        readonly transactionFee: number;
      } | null | undefined;
    } | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
    readonly platform: CampaignPlatform | null | undefined;
    readonly publishingRequired: boolean;
    readonly stage: CampaignStage;
    readonly type: CampaignType | null | undefined;
    readonly useCase: CampaignUseCase | null | undefined;
  };
  readonly comment: string | null | undefined;
  readonly contentReview: {
    readonly status: ContentReviewStatus;
  } | null | undefined;
  readonly creator: {
    readonly id: string;
    readonly insightsAuthorized?: boolean;
    readonly type: CreatorType;
    readonly user?: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly price: number | null | undefined;
  readonly publicationEventExists: boolean;
  readonly " $fragmentType": "CreatorChatDrawers_project";
};
export type CreatorChatDrawers_project$key = {
  readonly " $data"?: CreatorChatDrawers_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorChatDrawers_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorChatDrawers_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicationEventExists",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminComment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentReview",
      "kind": "LinkedField",
      "name": "contentReview",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useCase",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishingRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Payment_Account",
              "kind": "LinkedField",
              "name": "paymentAccount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "balance",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AdvertiserSubscription",
              "kind": "LinkedField",
              "name": "subscription",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "transactionFee",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BcaRequest",
      "kind": "LinkedField",
      "name": "bcaRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BcaPermission",
          "kind": "LinkedField",
          "name": "permission",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "insightsAuthorized",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "1ef0b4bf17e743f528b3efe8738df19b";

export default node;

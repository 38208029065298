import { ActionsDataProps, ActionProps } from 'Actions/Notifications.Actions';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from 'Constants/index';

interface NotificationsStateProps {
  items: ActionsDataProps[]
}

export interface StateProps {
  root: {
    notifications: NotificationsStateProps,
  }
}

const initialState: NotificationsStateProps = {
  items: []
};

const notifications = (state: NotificationsStateProps = initialState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_NOTIFICATION: {
      const candidate = state.items.filter(item => item.id === payload.id); // If the item already in a state => do nothing and return unchanged state

      if (candidate && candidate.length > 0) return state;

      return { ...state, items: [...state.items, payload] };
    }
    case REMOVE_NOTIFICATION: {
      return { ...state, items: state.items.filter(item => item.id !== payload.id) };
    }
    default:
      return state;
  }
};

export default notifications;
import { graphql, useMutation } from 'react-relay';
import { CancelSubscriptionMutation } from 'GraphTypes/CancelSubscriptionMutation.graphql';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation CancelSubscriptionMutation($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      ... on CancelSubscriptionPayload {
        advertiserSubscription {
          cancelAt
          lastSchedule {
            discount {
              percentOff
            }
            trialEnd
            startDate
          }
        }
      }
    }
  }
`;

export const useCancelSubscriptionMutation: BaseMutation<CancelSubscriptionMutation> = (config) => {
  const [cancelSubscription, loading] = useMutation<CancelSubscriptionMutation>(mutation);

  const cancelSubscriptionCaller: BaseMutationExecFn<CancelSubscriptionMutation> = (execConfig) => {
    cancelSubscription({
      ...config,
      ...execConfig,
      updater: (store) => {
        if (!config) return;
        const subscription = store.get(config.variables.input.subscriptionId);
        subscription?.setValue(null, 'endsAt');
        subscription?.setValue(null, 'planId');
      },
    });
  };
  return [cancelSubscriptionCaller, loading];
};

/**
 * @generated SignedSource<<776114d6b6cc1b1d25347f2dcfc88fc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrganizationCampaignsQuery$variables = {
  brandIds?: ReadonlyArray<string> | null | undefined;
  organizationId: string;
  textQuery?: string | null | undefined;
};
export type OrganizationCampaignsQuery$data = {
  readonly organization: {
    readonly campaigns: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type OrganizationCampaignsQuery = {
  response: OrganizationCampaignsQuery$data;
  variables: OrganizationCampaignsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "textQuery"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "brandIds",
      "variableName": "brandIds"
    },
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "ACTIVE"
      ]
    },
    {
      "kind": "Variable",
      "name": "textQuery",
      "variableName": "textQuery"
    }
  ],
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationCampaignsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationCampaignsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42af3bd46f325a5bd9cc9fa1cd2987c1",
    "id": null,
    "metadata": {},
    "name": "OrganizationCampaignsQuery",
    "operationKind": "query",
    "text": "query OrganizationCampaignsQuery(\n  $textQuery: String\n  $brandIds: [ID!]\n  $organizationId: ID!\n) {\n  organization(id: $organizationId) {\n    campaigns(stages: [ACTIVE], textQuery: $textQuery, brandIds: $brandIds) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b1eb92d39ab47fde72b0fc1b1089756";

export default node;

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import rootStyles from '../TransactionContainer.pcss';

import styles from './TransactionItem.pcss';

import { amplitude } from 'Helpers/amplitude';
import ls from 'Util/localStorage';
import { getCampaignCreatorsLink, getProjectsLink } from 'Util/links';
import { createDateV2 } from 'Util/dateCreator';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { TransactionItem_transaction$key } from 'GraphTypes/TransactionItem_transaction.graphql';
import { createSum } from 'Util/numberFormatter';
import { BillingTransactionStatus } from 'Types/enums';
import { DASHBOARD_ROUTE } from 'Constants/general';

interface Props {
  transaction: TransactionItem_transaction$key;
}

const TransactionItem: React.FC<Props> = (props) => {
  const { transaction } = props;

  const data = useFragment(
    graphql`
      fragment TransactionItem_transaction on Billing_Transaction {
        type
        createdAt
        amount
        accountType
        cardBrand
        last4
        currency
        status
        brand {
          id
          name
        }
        campaign {
          id
          name
          stage
        }
        creator {
          id
          username
        }
        project {
          id
        }
        errorMessage
      }
    `,
    transaction
  );

  const {
    type,
    createdAt,
    brand,
    campaign,
    creator,
    project,
    amount,
    currency,
    accountType,
    status,
    cardBrand = '',
    last4 = '',
    errorMessage,
  } = data;

  const brandName = brand?.name;
  const brandId = brand?.id;
  const campaignName = campaign?.name;
  const campaignStage = campaign?.stage;
  const campaignId = campaign?.id;
  const creatorUsername = creator?.username;
  const creatorId = creator?.id;
  const projectId = project?.id;

  const transactionId = `${campaignId}${creatorId}`;

  const amountValue = amount && currency ? createSum(amount, currency, 0, '0,0.[00]') : null;

  const date = createDateV2(createdAt) || '';

  let accountTypeLabel;

  if (accountType === 'CARD') {
    if (last4 && cardBrand) {
      accountTypeLabel = { text: `${last4 || ''} ${cardBrand || ''}` };
    } else {
      accountTypeLabel = { msg: 'billing_history.transaction.account_type.card' };
    }
  } else if (accountType === 'WALLET') {
    accountTypeLabel = { msg: 'billing_history.transaction.account_type.wallet' };
  }

  const isErrorStatus = status === BillingTransactionStatus.ERROR;
  const isCanceledStatus = status === BillingTransactionStatus.CANCELED;

  const isIncrease = amount && amount > 0;

  const finalAmountValue = isIncrease ? `+${amountValue}` : amountValue;

  const handleBrandClick = () => {
    if (!brandId) return;
    amplitude.sendEvent({
      id: 169,
      category: 'transaction_page',
      name: 'brand_name_click',
    });
    ls.set('filtered_brands', JSON.stringify([{ [brandId]: brandName }]));
    window.open(DASHBOARD_ROUTE);
  };

  const handleCreatorClick = () => {
    amplitude.sendEvent({
      id: 170,
      category: 'transaction_page',
      name: 'creator_name_click',
    });
  };

  const campaignLink = campaignId ? getCampaignCreatorsLink({ campaignId }) : undefined;
  const creatorLink = getProjectsLink({
    projectId,
    campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
  });

  return (
    <div className={styles.root}>
      <div className={`${rootStyles.dateItem} ${rootStyles.item}`}>
        <Text type="md" text={date} />
      </div>
      <div className={`${rootStyles.typeItem} ${rootStyles.item}`}>
        {type && <Text type="md" msg={`billing_history.transaction.type.${type.toLowerCase()}`} />}
      </div>
      <div className={`${rootStyles.brandItem} ${rootStyles.item}`}>
        {brandName && (
          <Text type="md" text={brandName} handleClick={handleBrandClick} className={styles.link} />
        )}
      </div>
      <div className={`${rootStyles.campaignType} ${rootStyles.item} ${styles.multupleItem}`}>
        {campaignName && campaignLink && (
          <Link to={campaignLink} target="_blank">
            <Text type="label" text={campaignName} className={styles.link} />
          </Link>
        )}
        {creatorUsername && (
          <Link to={creatorLink} target="_blank">
            <Text
              type="label"
              text={`@${creatorUsername}`}
              className={styles.link}
              handleClick={handleCreatorClick}
            />
          </Link>
        )}
      </div>
      <div className={`${rootStyles.amountItem} ${rootStyles.item} ${styles.multupleItem}`}>
        {amountValue && (
          <div
            className={classnames(styles.amountWrap, {
              [styles.errorStatus]: isErrorStatus,
              [styles.canceledStatus]: isCanceledStatus,
              [styles.isIncrease]: isIncrease,
            })}
          >
            <Text type="md" text={finalAmountValue} className={styles.amount} />
            {isErrorStatus && (
              <Tooltip id={`${transactionId}_error`} tooltipText={errorMessage} place="top">
                <Icon name="Warning-error" size={16} />
              </Tooltip>
            )}
            {status === BillingTransactionStatus.CANCELED && (
              <Tooltip
                id={`${transactionId}_canceled`}
                tooltipMsg="billing_history.transaction.type.reservation.canceled.tooltip"
                place="top"
              >
                <Icon name="Top_up" size={16} />
              </Tooltip>
            )}
            {status === BillingTransactionStatus.RESERVED && (
              <Tooltip
                id={`${transactionId}_reserved`}
                tooltipMsg="billing_history.transaction.type.reservation.reserved.tooltip"
                place="top"
              >
                <Icon name="Lock" size={16} />
              </Tooltip>
            )}
          </div>
        )}
        {accountTypeLabel && (
          <Text type="label" {...accountTypeLabel} className={styles.accountTypeLabel} />
        )}
      </div>
    </div>
  );
};

export default TransactionItem;

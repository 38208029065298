import { useIntl } from 'react-intl';

import { Filters } from './WithdrawalFilters';

import { RUB, USD, CAD } from 'Constants/general';

export const getFiltersData = (filters: Filters) => {
  const { currencies = [], statuses, paymentMethodTypes, w9WarningLevels } = filters;

  const intl = useIntl();

  return {
    currencies: [
      {
        id: USD,
        isChecked: currencies.includes(USD),
        value: USD,
        label: USD,
      },
      {
        id: CAD,
        isChecked: currencies.includes(CAD),
        value: CAD,
        label: CAD,
      },
      {
        id: RUB,
        isChecked: currencies.includes(RUB),
        value: RUB,
        label: RUB,
      },
    ],
    statuses: [
      {
        id: 'CONFIRMED',
        isChecked: statuses.includes('CONFIRMED'),
        value: intl.formatMessage({
          id: 'search_section.form.withdrawal.statuses.confirmed',
        }),
        labelMsg: 'search_section.form.withdrawal.statuses.confirmed',
      },
      {
        id: 'PROCESSING',
        isChecked: statuses.includes('PROCESSING'),
        value: intl.formatMessage({
          id: 'search_section.form.withdrawal.statuses.processing',
        }),
        labelMsg: 'search_section.form.withdrawal.statuses.processing',
      },
      {
        id: 'CANCELED',
        isChecked: statuses.includes('CANCELED'),
        value: intl.formatMessage({
          id: 'search_section.form.withdrawal.statuses.canceled',
        }),
        labelMsg: 'search_section.form.withdrawal.statuses.canceled',
      },
      {
        id: 'FAILED',
        isChecked: statuses.includes('FAILED'),
        value: intl.formatMessage({
          id: 'search_section.form.withdrawal.statuses.failed',
        }),
        labelMsg: 'search_section.form.withdrawal.statuses.failed',
      },
      {
        id: 'UNSENT',
        isChecked: statuses.includes('UNSENT'),
        value: intl.formatMessage({
          id: 'search_section.form.withdrawal.statuses.unsent',
        }),
        labelMsg: 'search_section.form.withdrawal.statuses.unsent',
      },
    ],
    paymentMethodTypes: [
      {
        id: 'CARD',
        isChecked: paymentMethodTypes.includes('CARD'),
        value: intl.formatMessage({
          id: 'payuot_method.any_card',
        }),
        labelMsg: 'payuot_method.any_card',
      },
      {
        id: 'PAYPAL',
        isChecked: paymentMethodTypes.includes('PAYPAL'),
        value: intl.formatMessage({
          id: 'payuot_method.paypal',
        }),
        labelMsg: 'payuot_method.paypal',
      },
      {
        id: 'WIRE',
        isChecked: paymentMethodTypes.includes('WIRE'),
        value: intl.formatMessage({
          id: 'payuot_method.wire',
        }),
        labelMsg: 'payuot_method.wire',
      },
      {
        id: 'GAMEMONEY',
        isChecked: paymentMethodTypes.includes('GAMEMONEY'),
        value: intl.formatMessage({
          id: 'payuot_method.gamemoney',
        }),
        labelMsg: 'payuot_method.gamemoney',
      },
      {
        id: 'ZELLE',
        isChecked: paymentMethodTypes.includes('ZELLE'),
        value: intl.formatMessage({
          id: 'payuot_method.zelle',
        }),
        labelMsg: 'payuot_method.zelle',
      },
    ],
    w9WarningLevels: [
      {
        id: 'WITH_FORMS',
        isChecked: w9WarningLevels === 'WITH_FORMS',
        value: intl.formatMessage({
          id: 'w9WarningLevels.all_with_forms',
        }),
        labelMsg: 'w9WarningLevels.all_with_forms',
      },
      {
        id: 'NO_FORMS',
        isChecked: w9WarningLevels === 'NO_FORMS',
        value: intl.formatMessage({
          id: 'w9WarningLevels.no_forms',
        }),
        labelMsg: 'w9WarningLevels.no_forms',
      },
      {
        id: 'NEED_TO_CHECK',
        isChecked: w9WarningLevels === 'NEED_TO_CHECK',
        value: intl.formatMessage({
          id: 'w9WarningLevels.need_check',
        }),
        labelMsg: 'w9WarningLevels.need_check',
      },
      {
        id: 'CHECKED_ONLY',
        isChecked: w9WarningLevels === 'CHECKED_ONLY',
        value: intl.formatMessage({
          id: 'w9WarningLevels.checked_only',
        }),
        labelMsg: 'w9WarningLevels.checked_only',
      },
    ],
  };
};

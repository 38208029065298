import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  UpdatePaymentTransactionInput,
  UpdatePaymentTransactionMutation$data,
  UpdatePaymentTransactionMutation,
} from 'GraphTypes/UpdatePaymentTransactionMutation.graphql';

const mutation = graphql`
  mutation UpdatePaymentTransactionMutation($input: UpdatePaymentTransactionInput!) {
    updatePaymentTransaction(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdatePaymentTransactionInput,
  resolve?: (response: UpdatePaymentTransactionMutation$data) => void,
  reject?: fnType
) => {
  const { id, adminComment } = data;

  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<UpdatePaymentTransactionMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const root = store.get('client:root:payment');
      if (!root) return;
      const list = ConnectionHandler.getConnection(root, 'Admin_withdrawals');
      if (list) {
        const withdarawal = list
          ?.getLinkedRecords('edges')
          ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === id);
        if (withdarawal) {
          withdarawal.getLinkedRecord('node')?.setValue(adminComment, 'adminComment');
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<f83400be04e2280d73b98a4a0e0a2332>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomListInfoQuery$variables = {
  id: string;
};
export type CustomListInfoQuery$data = {
  readonly customList: {
    readonly creators: {
      readonly totalCount: number;
    } | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
};
export type CustomListInfoQuery = {
  response: CustomListInfoQuery$data;
  variables: CustomListInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    }
  ],
  "concreteType": "CreatorConnection",
  "kind": "LinkedField",
  "name": "creators",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "creators(first:0)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomListInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomList",
        "kind": "LinkedField",
        "name": "customList",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomListInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomList",
        "kind": "LinkedField",
        "name": "customList",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e44da34fd2f6098a8442a23e1ca2d1e",
    "id": null,
    "metadata": {},
    "name": "CustomListInfoQuery",
    "operationKind": "query",
    "text": "query CustomListInfoQuery(\n  $id: ID!\n) {\n  customList(id: $id) {\n    name\n    creators(first: 0) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed866bd3fc716d5a73a01cc37198ae0e";

export default node;

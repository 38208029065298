import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ImportCreatorsMutation,
  ImportCreatorsMutation$data,
  ImportCreatorsInput,
} from 'GraphTypes/ImportCreatorsMutation.graphql';

const mutation = graphql`
  mutation ImportCreatorsMutation($input: ImportCreatorsInput!) {
    importCreators(input: $input) {
      campaign {
        id
        creatorImport {
          id
          importedCreators
          totalCreators
          completed
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ImportCreatorsInput,
  resolve?: (response: ImportCreatorsMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { campaignId, usernames, platform } = data;

  const variables = {
    input: {
      id,
      campaignId,
      usernames,
      platform,
    },
  };

  commitMutation<ImportCreatorsMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  DeleteLookalikeInput,
  DeleteLookalikeMutation,
  DeleteLookalikeMutation$data,
} from 'GraphTypes/DeleteLookalikeMutation.graphql';

const mutation = graphql`
  mutation DeleteLookalikeMutation($input: DeleteLookalikeInput!) {
    deleteLookalike(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

interface DataInput {
  briefId?: string;
}

export default (
  data: DeleteLookalikeInput & DataInput,
  resolve?: (response: DeleteLookalikeMutation$data) => void,
  reject?: fnType
) => {
  const { briefId, lookalikeId } = data;

  const variables = {
    input: {
      lookalikeId,
    },
  };

  commitMutation<DeleteLookalikeMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (!briefId || !lookalikeId) return;
      const brief = store.get(briefId);
      if (!brief) return;
      const list = ConnectionHandler.getConnection(brief, 'Brief_lookalikes');
      if (!list) return;
      ConnectionHandler.deleteNode(list, lookalikeId);
    },
    updater: (store) => {
      if (!briefId || !lookalikeId) return;
      const brief = store.get(briefId);
      if (!brief) return;
      const list = ConnectionHandler.getConnection(brief, 'Brief_lookalikes');
      if (!list) return;
      ConnectionHandler.deleteNode(list, lookalikeId);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

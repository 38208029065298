/**
 * @generated SignedSource<<9546c98ab60275d9bf6786eaa73991fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserCampaignStageFilterQuery$variables = {
  id: string;
};
export type AdvertiserCampaignStageFilterQuery$data = {
  readonly organization: {
    readonly active: {
      readonly totalCount: number;
    } | null | undefined;
    readonly completed: {
      readonly totalCount: number;
    } | null | undefined;
    readonly draft: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type AdvertiserCampaignStageFilterQuery = {
  response: AdvertiserCampaignStageFilterQuery$data;
  variables: AdvertiserCampaignStageFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": "active",
  "args": [
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "ACTIVE",
        "IN_REVIEW"
      ]
    }
  ],
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "campaigns(stages:[\"ACTIVE\",\"IN_REVIEW\"])"
},
v4 = {
  "alias": "draft",
  "args": [
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "DRAFT"
      ]
    }
  ],
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "campaigns(stages:[\"DRAFT\"])"
},
v5 = {
  "alias": "completed",
  "args": [
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "COMPLETED"
      ]
    }
  ],
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "campaigns(stages:[\"COMPLETED\"])"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdvertiserCampaignStageFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdvertiserCampaignStageFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f36407ece4bde86a3cbb331473369ea",
    "id": null,
    "metadata": {},
    "name": "AdvertiserCampaignStageFilterQuery",
    "operationKind": "query",
    "text": "query AdvertiserCampaignStageFilterQuery(\n  $id: ID!\n) {\n  organization(id: $id) {\n    active: campaigns(stages: [ACTIVE, IN_REVIEW]) {\n      totalCount\n    }\n    draft: campaigns(stages: [DRAFT]) {\n      totalCount\n    }\n    completed: campaigns(stages: [COMPLETED]) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9a2a7afaea279f535399c13de680499";

export default node;

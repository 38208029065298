import React from 'react';
import classnames from 'classnames';

import styles from './Icon.css';

import modsClasses from 'Util/modsClasses.js';
import icons from 'Icons';
import { IconType } from 'Types/common';

const MODS = ['color', 'size'];

const Icon: React.FC<IconType> = (props) => {
  const { name, hasHover, withBg } = props;

  const icon = icons[name];

  const classes = modsClasses(MODS, props, styles);

  return (
    <div
      className={classnames(classes, styles.root, {
        [styles.hasHover]: hasHover,
        [styles.withBg]: withBg,
      })}
    >
      {icon}
    </div>
  );
};

export default Icon;

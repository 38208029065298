import { graphql, useMutation } from 'react-relay';

import {
  CreateBulkShopifyOrderMutation,
  CreateBulkShopifyOrderInput,
  CreateBulkShopifyOrderMutation$data,
  CreateBulkShopifyOrderMutation$variables,
  Shopify_LineItemInput,
} from 'GraphTypes/CreateBulkShopifyOrderMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const createBulkShopifyOrderMutation = graphql`
  mutation CreateBulkShopifyOrderMutation($input: CreateBulkShopifyOrderInput!) {
    createBulkShopifyOrder(input: $input) {
      __typename
      ... on CreateBulkShopifyOrderPayload {
        bulkShopifyOrder {
          id
          campaign {
            canUpdateShopifyAuthorization
            shopifyAuthorization {
              reauthenticationRequired
              shop {
                name
                url
              }
              id
            }
            organization {
              id
              shopifyAuthorizations {
                edges {
                  node {
                    id
                    reauthenticationRequired
                    shop {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      ... on ValidationError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

const useCreateBulkShopifyOrderMutation: BaseMutation<CreateBulkShopifyOrderMutation> = (
  config
) => {
  const [createBulkShopifyOrder, loading] = useMutation<CreateBulkShopifyOrderMutation>(
    createBulkShopifyOrderMutation
  );

  const exec: BaseMutationExecFn<CreateBulkShopifyOrderMutation> = (execConfig) => {
    createBulkShopifyOrder({ ...config, ...execConfig });
  };
  return [exec, loading];
};

export { useCreateBulkShopifyOrderMutation };

// types

export type {
  CreateBulkShopifyOrderMutation,
  CreateBulkShopifyOrderInput,
  CreateBulkShopifyOrderMutation$data,
  CreateBulkShopifyOrderMutation$variables,
  Shopify_LineItemInput,
};

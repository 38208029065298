import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './CreatorReviewsList.css';
import CreatorReviewItem from './CreatorReviewItem/CreatorReviewItem';

import Button from 'Atoms/Button/Button';
import { CreatorReviewsList_ratingVotes } from 'GraphTypes/CreatorReviewsList_ratingVotes.graphql';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';

interface Props {
  relay: RelayPaginationProp,
  creatorId: string,
  admin?: boolean,
  ratingVotes: CreatorReviewsList_ratingVotes,
}

const CreatorReviewsList: React.FC<Props> = props => {
  const { relay, ratingVotes, admin } = props;

  const list = ratingVotes.ratingVotes.edges;

  if (!list || list.length === 0) {
    return null;
  }

  const loadButton = (
    <Button
      size="lg"
      width="full"
      msg="general.show_more"
      color="normal"
      className={styles.loadButton}
    />
  );

  return (
    <LoaderHandler
      count={5}
      relay={relay}
      loadButton={loadButton}
    >
      <div>
        {list.map(item => {
          if (!item?.node) return null;

          const { id } = item.node;

          return (
            <CreatorReviewItem key={id} admin={admin} ratingVote={item.node} />
          );
        })}
      </div>
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  CreatorReviewsList,
  {
    ratingVotes: graphql`
      fragment CreatorReviewsList_ratingVotes on Creator
      @argumentDefinitions(
        count: {type: "Int", defaultValue: 5}
        cursor: {type: "String"}
        side: {type: "RatingVoteSide", defaultValue: AGENCY}
      ) {
        ratingVotes(
          first: $count
          after: $cursor
          side: $side
        ) @connection(key: "CreatorReviewsList_ratingVotes", filters: []) {
          edges {
            node {
              id
              ...CreatorReviewItem_ratingVote
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.ratingVotes.ratingVotes;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,

      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        creatorId: props.creatorId,
      };
    },
    query: graphql`
      query CreatorReviewsListPaginationQuery(
        $count: Int!
        $cursor: String
        $creatorId: ID!
      ) {
        creator (id: $creatorId) {
          ...CreatorReviewsList_ratingVotes @arguments(
            count: $count,
            cursor: $cursor,
            side: AGENCY,
          )
        }
      }
    `
  }
);
/**
 * @generated SignedSource<<5b50a0e9672c1a4ecd62ef015338fe2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentSubmittedStage_project$data = {
  readonly campaign: {
    readonly publishingRequired: boolean;
    readonly type: CampaignType | null | undefined;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FbAdsAction_project" | "FinishDealAction_project">;
  readonly " $fragmentType": "ContentSubmittedStage_project";
};
export type ContentSubmittedStage_project$key = {
  readonly " $data"?: ContentSubmittedStage_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmittedStage_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmittedStage_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishingRequired",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FbAdsAction_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinishDealAction_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "546d579ac548a6a52205c3750164a3bc";

export default node;

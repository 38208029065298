import React from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { ProjectEventsQuery } from '../ProjectEvents';

import ProjectEventsList from './ProjectEventsList';

import { ProjectEventsQuery as QueryType } from 'GraphTypes/ProjectEventsQuery.graphql';

interface Props {
  projectId: string;
  queryReference: PreloadedQuery<QueryType>;
  inPopup?: boolean;
  includeDeleted?: boolean;
}

const ProjectEventsListProvider: React.FC<Props> = (props) => {
  const { projectId, queryReference, inPopup, includeDeleted } = props;

  const queryData = usePreloadedQuery<QueryType>(ProjectEventsQuery, queryReference);

  const { project } = queryData;

  if (!project) return null;

  const userType = queryData?.currentUser?.type;
  const admin = queryData?.currentUser?.admin;
  const productShipmentRequired = queryData?.project?.brief?.productShipmentRequired;
  const screeningQuestion = queryData?.project?.brief?.screeningQuestion;
  const campaignId = queryData?.project?.campaign?.id;
  const currentUserId = queryData?.currentUser?.id;

  return (
    <ProjectEventsList
      projectId={projectId}
      campaignId={campaignId}
      currentUserId={currentUserId}
      project={queryData}
      userType={userType}
      screeningQuestion={screeningQuestion}
      productShipmentRequired={productShipmentRequired}
      inPopup={inPopup}
      admin={admin}
      includeDeleted={includeDeleted}
    />
  );
};

export default ProjectEventsListProvider;

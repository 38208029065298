import React, { FC } from 'react';

import styles from './AddGmailAccountButton.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { useGmail } from 'Hooks/useGmail';
import { GmailAuthBackendError, GmailAuthError } from './utils';

interface Props {
  callback?: (authData: unknown) => void;
  buttonProps?: Record<string, unknown>;
  authIdForReauth?: string;
}

const AddGmailAccountButton: FC<Props> = (props) => {
  const { callback, buttonProps, authIdForReauth } = props;
  const { authorize } = useGmail();
  const handleAddGoogleAccount = async () => {
    try {
      const authData = await authorize(authIdForReauth);
      if (authData?.error) {
        throw new GmailAuthError();
      } else {
        if (authData?.data?.__typename === 'AuthenticateWithGmailPayload') {
          if (callback) {
            callback(authData?.data);
          }
        } else {
          throw new GmailAuthBackendError();
        }
      }
    } catch (e) {
      if (e instanceof GmailAuthError) {
        //
      } else if (e instanceof GmailAuthBackendError) {
        //
      }
    }
  };
  return (
    <AlterButton
      msg="campaign.outreach.add_gmail"
      type="black"
      icon={'Add-plus'}
      {...buttonProps}
      onClick={handleAddGoogleAccount}
    />
  );
};

export default AddGmailAccountButton;

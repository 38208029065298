/**
 * @generated SignedSource<<3a8196500f1f36964805d03f9b74a1aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatorCharacteristic = "BAD_COMMUNICATION" | "BAD_PRICE_QUALITY_RATIO" | "FAST_WORK" | "FOLLOWING_BRIEF" | "GOOD_COMMUNICATION" | "GOOD_PRICE_QUALITY_RATIO" | "HIGH_CREATIVITY" | "HIGH_TECHNICAL_QUALITY" | "LOW_CREATIVITY" | "LOW_TECHNICAL_QUALITY" | "NOT_FOLLOWING_BRIEF" | "SLOW_WORK" | "%future added value";
export type RatingVoteSide = "AGENCY" | "BRAND" | "%future added value";
export type RateCreatorInput = {
  characteristics?: ReadonlyArray<CreatorCharacteristic> | null | undefined;
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  contentApprovalCreativeId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  score: number;
  side: RatingVoteSide;
};
export type RateCreatorMutation$variables = {
  input: RateCreatorInput;
};
export type RateCreatorMutation$data = {
  readonly rateCreator: {
    readonly ratingVote: {
      readonly author: {
        readonly advertiserProfile: {
          readonly firstName: string | null | undefined;
        } | null | undefined;
        readonly email: string;
      };
      readonly characteristics: ReadonlyArray<CreatorCharacteristic> | null | undefined;
      readonly comment: string | null | undefined;
      readonly createdAt: any;
      readonly id: string;
      readonly project: {
        readonly ratingByAgency: {
          readonly author: {
            readonly email: string;
          };
          readonly characteristics: ReadonlyArray<CreatorCharacteristic> | null | undefined;
          readonly comment: string | null | undefined;
          readonly createdAt: any;
          readonly score: number;
        } | null | undefined;
        readonly ratingByBrand: {
          readonly author: {
            readonly email: string;
          };
          readonly characteristics: ReadonlyArray<CreatorCharacteristic> | null | undefined;
          readonly comment: string | null | undefined;
          readonly createdAt: any;
          readonly score: number;
        } | null | undefined;
      };
      readonly score: number;
    };
  } | null | undefined;
};
export type RateCreatorMutation = {
  response: RateCreatorMutation$data;
  variables: RateCreatorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "characteristics",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "side",
    "value": "BRAND"
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "author",
    "plural": false,
    "selections": [
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v11 = [
  {
    "kind": "Literal",
    "name": "side",
    "value": "AGENCY"
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "author",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RateCreatorPayload",
        "kind": "LinkedField",
        "name": "rateCreator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RatingVote",
            "kind": "LinkedField",
            "name": "ratingVote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdvertiserProfile",
                    "kind": "LinkedField",
                    "name": "advertiserProfile",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": "ratingByBrand",
                    "args": (v9/*: any*/),
                    "concreteType": "RatingVote",
                    "kind": "LinkedField",
                    "name": "creatorRatingVote",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": "creatorRatingVote(side:\"BRAND\")"
                  },
                  {
                    "alias": "ratingByAgency",
                    "args": (v11/*: any*/),
                    "concreteType": "RatingVote",
                    "kind": "LinkedField",
                    "name": "creatorRatingVote",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": "creatorRatingVote(side:\"AGENCY\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RateCreatorPayload",
        "kind": "LinkedField",
        "name": "rateCreator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RatingVote",
            "kind": "LinkedField",
            "name": "ratingVote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdvertiserProfile",
                    "kind": "LinkedField",
                    "name": "advertiserProfile",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": "ratingByBrand",
                    "args": (v9/*: any*/),
                    "concreteType": "RatingVote",
                    "kind": "LinkedField",
                    "name": "creatorRatingVote",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": "creatorRatingVote(side:\"BRAND\")"
                  },
                  {
                    "alias": "ratingByAgency",
                    "args": (v11/*: any*/),
                    "concreteType": "RatingVote",
                    "kind": "LinkedField",
                    "name": "creatorRatingVote",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": "creatorRatingVote(side:\"AGENCY\")"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6fdb7c2171d9c23b38e32b3860e999e6",
    "id": null,
    "metadata": {},
    "name": "RateCreatorMutation",
    "operationKind": "mutation",
    "text": "mutation RateCreatorMutation(\n  $input: RateCreatorInput!\n) {\n  rateCreator(input: $input) {\n    ratingVote {\n      id\n      author {\n        email\n        advertiserProfile {\n          firstName\n          id\n        }\n        id\n      }\n      createdAt\n      comment\n      score\n      characteristics\n      project {\n        ratingByBrand: creatorRatingVote(side: BRAND) {\n          author {\n            email\n            id\n          }\n          createdAt\n          comment\n          score\n          characteristics\n          id\n        }\n        ratingByAgency: creatorRatingVote(side: AGENCY) {\n          author {\n            email\n            id\n          }\n          createdAt\n          comment\n          score\n          characteristics\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cb7c197d7ac4f04f2493906b3519eca";

export default node;

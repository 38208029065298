/**
 * @generated SignedSource<<ac30564dcd9c731d7f88b15a5ae40331>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type UserType = "ADVERTISER" | "CONTRACTOR" | "EPHEMERAL" | "UNKNOWN" | "%future added value";
export type ContractorHeaderQuery$variables = Record<PropertyKey, never>;
export type ContractorHeaderQuery$data = {
  readonly currentUser: {
    readonly contractorProfile: {
      readonly creatorAgent: boolean;
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    } | null | undefined;
    readonly email: string;
    readonly paymentAccount: {
      readonly balance: number;
      readonly currency: Currency;
    } | null | undefined;
    readonly rating: {
      readonly averageScore: number;
    } | null | undefined;
    readonly type: UserType;
  } | null | undefined;
};
export type ContractorHeaderQuery = {
  response: ContractorHeaderQuery$data;
  variables: ContractorHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageScore",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorAgent",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractorHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorRating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractorProfile",
            "kind": "LinkedField",
            "name": "contractorProfile",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment_Account",
            "kind": "LinkedField",
            "name": "paymentAccount",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContractorHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorRating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractorProfile",
            "kind": "LinkedField",
            "name": "contractorProfile",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment_Account",
            "kind": "LinkedField",
            "name": "paymentAccount",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af63aac27ed9858b72ec6bd9374cd11c",
    "id": null,
    "metadata": {},
    "name": "ContractorHeaderQuery",
    "operationKind": "query",
    "text": "query ContractorHeaderQuery {\n  currentUser {\n    email\n    type\n    rating {\n      averageScore\n      id\n    }\n    contractorProfile {\n      creatorAgent\n      firstName\n      lastName\n      id\n    }\n    paymentAccount {\n      balance\n      currency\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac2fed6648ce7756b259948e729c670c";

export default node;

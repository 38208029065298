import React, { useContext } from 'react';

import styles from './CancelSuccessSubscriptionDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import moment from 'moment';
import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CurrentSubscriptionInfo_organization$data } from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';

interface Props {
  subscription: NonNullable<CurrentSubscriptionInfo_organization$data['subscription']>;
}

const CancelSuccessSubscriptionDrawer: React.FC<Props> = (props) => {
  const { subscription } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const date = moment(subscription?.cancelAt).format('MMM DD, YYYY');

  const handleCompleteClick = () => {
    closeDrawer('cancel-subscription-success-drawer');
  };

  return (
    <Drawer
      rootKey={'cancel-subscription-success-drawer'}
      className={styles.drawerWrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      needCloseButton
      removeWhenClosed
    >
      <div className={styles.root}>
        <div className={styles.formContainer}>
          <Text type="d2" msg="billing.cancel.success.drawer.title" className={styles.title} />
          <Text
            type="md"
            msg="billing.cancel.success.drawer.subtitle"
            className={styles.subtitle}
            formatValues={{
              date,
            }}
          />
          <div className={styles.checkContainer}>
            <div className={styles.checkIcon} />
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <Button
            color="black"
            fluid
            msg="billing.cancel.success.drawer.button.account"
            onClick={handleCompleteClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default CancelSuccessSubscriptionDrawer;

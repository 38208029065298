import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import type { AmountsDataValues } from '../../data';
import { amountsData, CUSTOM_OPTION } from '../../data';

import styles from './AmountSelect.pcss';

import { createSum } from 'Util/numberFormatter';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import Tabs from 'Molecules/Tabs/Tabs';
import { MIN_AMOUNT_FOR_TOPUP, MAX_AMOUNT_FOR_TOPUP, USD } from 'Constants/general';

interface Props {
  className?: string;
  currentAmount: number;
  minAmount?: number;
  maxAmount?: number;
  onChange: (value: number) => void;
  onSelectedOptionChange?: (value: AmountsDataValues) => void;
}

const AmountSelect: React.FC<Props> = (props) => {
  const {
    className,
    currentAmount,
    minAmount = MIN_AMOUNT_FOR_TOPUP,
    maxAmount = MAX_AMOUNT_FOR_TOPUP,
    onChange,
  } = props;

  const defaultSelectedOption = useMemo<AmountsDataValues>(() => {
    const index = amountsData.findIndex((item) => Number(item.id) === currentAmount);

    return index === -1 ? CUSTOM_OPTION : (amountsData[index].id as AmountsDataValues);
  }, [currentAmount]);

  const [selectedOption, setSelectedOption] = useState<AmountsDataValues>(defaultSelectedOption);

  const handleAmountChange: HandleInputValueChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9.]/g, '');
    onChange(Number(newValue));
  };

  const handleAmountValueChange = (value: string) => {
    setSelectedOption(value as AmountsDataValues);
    onChange(MIN_AMOUNT_FOR_TOPUP);
    if (value !== 'Custom') {
      onChange(Number(value));
    }
  };

  return (
    <div className={classnames(styles.root, className)}>
      <Text type="h2" msg="topup_balance_modal.amount" className={styles.caption} />

      <Tabs
        currentItemId={selectedOption ? (selectedOption as string) : undefined}
        items={amountsData}
        onChange={handleAmountValueChange}
        className={styles.tabs}
      />

      {selectedOption === CUSTOM_OPTION && (
        <div className={styles.customAmount}>
          <Input
            placeholderMsg="topup_balance_modal.amount.input.placeholder"
            value={currentAmount ? createSum(currentAmount, USD, undefined, '0') : ''}
            onChange={handleAmountChange}
            bordered
            className={styles.input}
          />
          {currentAmount > maxAmount ? (
            <Text
              type="md"
              msg="topup_balance_modal.amount.input.maximum.text"
              formatValues={{ limit: maxAmount }}
              className={classnames(styles.minimumAmount, styles.error)}
            />
          ) : (
            <Text
              type="md"
              msg="topup_balance_modal.amount.input.minimum.text"
              formatValues={{ limit: minAmount }}
              className={classnames(styles.minimumAmount, {
                [styles.error]: currentAmount < minAmount,
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

type AmountSelectProps = React.ComponentProps<typeof AmountSelect>;

export default AmountSelect;
export type { AmountSelectProps };

// types

type InputProps = React.ComponentProps<typeof Input>;
type HandleInputValueChange = NonNullable<InputProps['onChange']>;

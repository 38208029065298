import React from 'react';
import classnames from 'classnames';

import styles from './MaxLaunchedCampaignsExceeded.pcss';

import Text from 'Atoms/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import { MaxLaunchedCampaignsExceededType } from 'Types/modals';
import { HELLO_EMAIL } from 'Constants/general';

type DrawerProps = React.ComponentProps<typeof Drawer>;

interface Props {
  attach: MaxLaunchedCampaignsExceededType;
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
}

const MaxLaunchedCampaignsExceededDrawer: React.FC<Props> = (props) => {
  const { attach, drawerProps } = props;

  return (
    <Drawer
      {...drawerProps}
      className={classnames(styles.drawer, drawerProps?.className)}
      rootKey="max-launched-campaigns-exceeded"
    >
      <div className={styles.contentRoot}>
        <div>
          <Text
            weight="400"
            tag="h2"
            msg="max_launched_campaigns_exceeded_modal.title"
            align="left"
            className={styles.title}
          />
          <Text
            msg="max_launched_campaigns_exceeded_modal.descr"
            values={{
              maxLaunchedCampaigns: attach.maxLaunchedCampaigns,
              planId: attach.planId,
              email: (
                <a href={`mailto:${HELLO_EMAIL}`} className={styles.link}>
                  {HELLO_EMAIL}
                </a>
              ),
            }}
            className={styles.description}
            align="left"
          />
        </div>
      </div>
    </Drawer>
  );
};

export default MaxLaunchedCampaignsExceededDrawer;

import React from 'react';

import styles from './TextSymbol.css';

import Text from 'Atoms/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import { TextSymbolType } from 'Types/common';
import Icon from 'Atoms/Icon/Icon';

const TextSymbol: React.FC<TextSymbolType> = (props) => {
  const {
    className,
    textData,
    width,
    leftIcon,
    rightIcon,
    leftIconName,
    rightIconName,
    additionalComponent,
    avaData,
    dataTest,
  } = props;

  const style = { width: width ? `${width}px` : undefined };

  return (
    <div className={`${className} ${styles.root}`} style={style} data-test={dataTest}>
      {leftIcon}
      {leftIconName && <Icon name={leftIconName} />}
      {avaData && <Avatar {...avaData} />}
      <Text overflow {...textData} className={styles.text} />
      {additionalComponent}
      {rightIcon}
      {rightIconName && <Icon name={rightIconName} />}
    </div>
  );
};

export default TextSymbol;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';

import BriefContent from '../BriefContent/BriefContent';

import styles from './CampaignOutreachContent.pcss';
import CampaignOutreachContentQuery from './CampaignOutreachContent.Query';
import CampaignOutreachInactiveBanner from './CampaignOutreachInactiveBanner/CampaignOutreachInactiveBanner';

import Spinner from 'Atoms/Spinner/Spinner';
import { CampaignOutreachContentQuery as QueryType } from 'GraphTypes/CampaignOutreachContentQuery.graphql';
import Button from 'Components/ui/Button/Button';
import Drawer from 'Components/ui/Drawer/Drawer';
import OutreachMobileBanner from 'Molecules/OutreachMobileBanner/OutreachMobileBanner';
import Error404 from 'Page/common/Error404/Error404';
import { amplitude } from 'Helpers/amplitude';
import * as amplitudeSdk from '@amplitude/analytics-browser';

interface Props {
  campaignId: string;
  outreachType?: 'invite' | 'campaign';
}

const CampaignOutreachContent: React.FC<Props> = (props) => {
  const { campaignId, outreachType } = props;
  const [searchParams] = useSearchParams();
  const deviceId = amplitudeSdk.getDeviceId();
  const code = searchParams.get('code');
  const data = useLazyLoadQuery<QueryType>(CampaignOutreachContentQuery, {
    campaignId,
  });

  const [showMobileDrawer, setMobileDrawerStatus] = useState(false);

  const handleApplyClick = useCallback(() => {
    const loadedAt = +new Date();
    amplitude.sendEvent({
      id: '223',
      category: 'creator',
      name: 'campaign_invite_apply_web',
    });
    setTimeout(() => {
      if (+new Date() - loadedAt < 2000) {
        setMobileDrawerStatus(true);
      }
    }, 25);
    if (outreachType === 'campaign') {
      window.open(`insense://campaigns/?id=${campaignId}&uid=${deviceId}`, '_self');
    } else if (outreachType === 'invite') {
      window.open(`insense://invite?code=${code}&uid=${deviceId}`, '_self');
    }
  }, []);

  const handleCloseMobileDrawer = useCallback(() => {
    setMobileDrawerStatus(false);
  }, []);

  const handleSeeOtherClick = useCallback(() => {
    setMobileDrawerStatus(true);
  }, []);

  if (!data) {
    return <Spinner style={{ margin: '40px auto' }} />;
  }

  const campaign = data?.campaign;
  if (!campaign) {
    return <Error404 />;
  }

  const isCampaignActive = data?.campaign?.stage === 'ACTIVE';

  return (
    <div className={styles.root}>
      {!isCampaignActive && <CampaignOutreachInactiveBanner />}
      <BriefContent campaign={campaign} isCreator={true} />
      <div className={styles.actionsContainer}>
        {isCampaignActive ? (
          <Button
            onClick={handleApplyClick}
            color="black"
            fluid
            msg="outreach_campaign.active.button"
            className={styles.actionsContainerButton}
          />
        ) : (
          <Button
            onClick={handleSeeOtherClick}
            color="black"
            fluid
            msg="outreach_campaign.inactive.button"
            className={styles.actionsContainerButton}
          />
        )}
      </div>
      <Drawer
        rootKey="outreach-mobile"
        opened={showMobileDrawer}
        onClose={handleCloseMobileDrawer}
        className={styles.mobileDrawer}
        needCloseButton
      >
        <OutreachMobileBanner campaignId={campaignId} outreachType={outreachType} />
      </Drawer>
    </div>
  );
};

export default CampaignOutreachContent;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import styles from './SubscriptonBought.pcss';

import { getIntervalValue } from 'Page/advertiser/BillingPlans/data';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';

import { PlanId, SubscriptionInterval } from 'GraphTypes/BillingPlansQuery.graphql';

interface Props {
  planId?: PlanId | null;
  interval?: SubscriptionInterval | null;
  submitMsg?: string;
  onSubmitClick: () => void;
}

const SubscriptonBought: React.FC<Props> = (props) => {
  const {
    planId,
    interval,
    submitMsg = 'subscription_been_bought_modal.ok',
    onSubmitClick,
  } = props;

  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const planNameText = intl.formatMessage({
    id: `billing_plans.plan.${planId?.toLowerCase()}.title`,
  });
  const intervalVal = interval ? getIntervalValue(interval) : '';
  const intervalName = interval
    ? intl.formatMessage({ id: `billing_plans.interval.${interval.toLowerCase()}` }).toLowerCase()
    : '';

  const paymentDescr = intl.formatMessage(
    {
      id:
        planId === 'TRIAL_2024_03'
          ? 'subscription_been_bought_modal.trial_payment_descr'
          : 'subscription_been_bought_modal.payment_descr',
    },
    { plan: planNameText, interval: intervalName, monthAmount: intervalVal }
  );

  const handleSubmitClick = () => {
    setLoading(true);
    onSubmitClick();
  };

  return (
    <div className={styles.root}>
      <Text type="d2" msg="subscription_been_bought_modal.title" className={styles.title} />
      <Text
        type="md"
        msg="subscription_been_bought_modal.descr"
        formatValues={{ plan: planNameText }}
        className={styles.title}
      />
      {paymentDescr && <Text type="md" text={paymentDescr} />}
      <div className={styles.resultContainer}>
        <div className={styles.greenIcon} />
      </div>
      <Button color="black" loading={loading} msg={submitMsg} onClick={handleSubmitClick} />
    </div>
  );
};

export default SubscriptonBought;

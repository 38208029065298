/**
 * @generated SignedSource<<a82f8e4ee51dc9e4c3ce341d4980fb98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CancelCreatorImportInput = {
  clientMutationId?: string | null | undefined;
  creatorImportId: string;
};
export type CancelCreatorImportMutation$variables = {
  input: CancelCreatorImportInput;
};
export type CancelCreatorImportMutation$data = {
  readonly cancelCreatorImport: {
    readonly creatorImport: {
      readonly completed: boolean;
      readonly id: string;
      readonly importedCreators: number;
      readonly totalCreators: number;
    } | null | undefined;
  } | null | undefined;
};
export type CancelCreatorImportMutation = {
  response: CancelCreatorImportMutation$data;
  variables: CancelCreatorImportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CancelCreatorImportPayload",
    "kind": "LinkedField",
    "name": "cancelCreatorImport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreatorImport",
        "kind": "LinkedField",
        "name": "creatorImport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "importedCreators",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCreators",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelCreatorImportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelCreatorImportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9e7e4d4895718c51e8eb8921c13fe78c",
    "id": null,
    "metadata": {},
    "name": "CancelCreatorImportMutation",
    "operationKind": "mutation",
    "text": "mutation CancelCreatorImportMutation(\n  $input: CancelCreatorImportInput!\n) {\n  cancelCreatorImport(input: $input) {\n    creatorImport {\n      id\n      completed\n      importedCreators\n      totalCreators\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b07f2a6ec914bac7969759b75ec878e";

export default node;

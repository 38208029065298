/**
 * @generated SignedSource<<1741abb6c737367a1c7bff320c17be4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Reporting_ReportState = "EXPIRED" | "FAILED" | "PENDING" | "READY" | "%future added value";
export type Reporting_CreateLonglistReportInput = {
  approvalToolToken: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
};
export type CreateLonglistReportMutation$variables = {
  input: Reporting_CreateLonglistReportInput;
};
export type CreateLonglistReportMutation$data = {
  readonly reporting: {
    readonly createLonglistReport: {
      readonly report: {
        readonly id: string;
        readonly state: Reporting_ReportState;
      };
    } | null | undefined;
  } | null | undefined;
};
export type CreateLonglistReportMutation = {
  response: CreateLonglistReportMutation$data;
  variables: CreateLonglistReportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Reporting_Mutation",
    "kind": "LinkedField",
    "name": "reporting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Reporting_CreateLonglistReportPayload",
        "kind": "LinkedField",
        "name": "createLonglistReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reporting_LonglistReport",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateLonglistReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateLonglistReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e2ac0ae5c2d9897f131e79cacdd21fd5",
    "id": null,
    "metadata": {},
    "name": "CreateLonglistReportMutation",
    "operationKind": "mutation",
    "text": "mutation CreateLonglistReportMutation(\n  $input: Reporting_CreateLonglistReportInput!\n) {\n  reporting {\n    createLonglistReport(input: $input) {\n      report {\n        id\n        state\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b90f3b91ed5c0d774561c7a37fd825b";

export default node;

import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SignUpCompleteContext, SignUpType, StepEnum, UserData } from './SignUpComplete.Context';
import {
  checkValidity,
  getStepArrays,
  initActiveStepId,
  initUserData,
  initValidityState,
  sendHubspotData,
  sendAmplitudeEvents,
  doUpdateOrganizationWhenStepChange,
} from './utils';

import type { SignUpCompleteContextType, StepArrays, StepsArrayItem } from '.';

import { initGrowsumo } from 'Analytics/growsumo/initGrowsumo';
import { useTapfiliate } from 'Hooks/useTapfiliate';
import { advertiserSignup } from 'Actions/Sessions.Actions';
import { Auth0Context, useAdvertiserSignUpFlow } from 'Containers/Auth0/Auth0Context';
import { amplitude } from 'Helpers/amplitude';
import { SIGNUP_COMPLETE_ADVERTISER_SURVEY_ROUTE } from 'Constants/general';

type Props = {
  signUpType?: SignUpType;
};

const SignUpCompleteProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, signUpType: signUpTypeProp = SignUpType.Standard } = props;
  const { user } = useContext(Auth0Context);
  const { runSignUpAdvertiserFlow, onSignUpAdvertiserComplete } = useAdvertiserSignUpFlow();

  const tapfiliate = useTapfiliate();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _initUserData = useCallback<typeof initUserData>(() => {
    return initUserData({
      [StepEnum.UserDetails]: {
        email: {
          value: user?.email || '',
          hubSpotValue: user?.email || '',
        },
      },
    });
  }, [user?.email]);

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [signUpType, setSignUpType] = useState<SignUpType>(signUpTypeProp);
  const [activeStepId, setActiveStepId] = useState<StepEnum>(initActiveStepId(signUpTypeProp));
  const [validity, setValidity] = useState<ContextType['validity']>(initValidityState());
  const [userDataState, setUserDataState] = useState<ContextType['userData']>(_initUserData);

  useEffect(() => {
    if (user?.email) {
      setUserDataState(_initUserData());
    }
  }, [user?.email]);

  useEffect(() => {
    if (signUpType === SignUpType.Standard) {
      amplitude.sendEvent({ id: '38', category: 'sign_up', name: 'as_a_brand', param: {} });
      initGrowsumo();
      tapfiliate.detect();
      tapfiliate.setRefParameterToUrlIfPossible();
    }
  }, []);

  useEffect(() => {
    if (signUpTypeProp !== signUpType) {
      setIsSubmitLoading(false);
      setSignUpType(signUpTypeProp);
      setActiveStepId(initActiveStepId(signUpTypeProp));
      setValidity(initValidityState());
      setUserDataState((prev) => {
        const result: ContextType['userData'] =
          signUpTypeProp === SignUpType.Survey ? { ...prev } : _initUserData();
        setTimeout(() => {
          sendHubspotData(result, signUpTypeProp);
        }, 0);
        return result;
      });
    }
  }, [signUpTypeProp, signUpType, userDataState, _initUserData]);

  const steps = useMemo<StepArrays>(() => {
    return getStepArrays();
  }, []);

  const activeStep = useMemo<StepsArrayItem>(() => {
    return steps[signUpType].find((item) => item.id === activeStepId) as StepsArrayItem;
  }, [steps, signUpType, activeStepId]);

  const activeStepIndex = useMemo<number>(() => {
    return steps[signUpType].findIndex((item) => item.id === activeStepId);
  }, [steps, signUpType, activeStepId]);

  const isFinalStep = useMemo(() => {
    return steps[signUpType][steps[signUpType].length - 1].id === activeStepId;
  }, [steps, signUpType, activeStepId]);

  const isFirstStep = useMemo(() => {
    return steps[signUpType][0].id === activeStepId;
  }, [steps, signUpType, activeStepId]);

  const isCurrentStepValid = useMemo<boolean>(() => {
    const val = validity[signUpType][activeStepId];
    if (val) {
      return val.isValid;
    }
    console.error(`Validator for current step doesn't exist`);
    return false;
  }, [validity[signUpType], userDataState[activeStepId]]);

  const totalSteps: ContextType['totalSteps'] = steps[signUpType].length;

  const goToNextStep: ContextType['goToNextStep'] = () => {
    const nextStep = steps[signUpType][activeStepIndex + 1];
    if (!nextStep) return false;
    doUpdateOrganizationWhenStepChange(signUpType, activeStepId, userDataState);
    sendAmplitudeEvents.whenClickGoToNextStep(signUpType, activeStepIndex);
    sendHubspotData(userDataState, signUpType);
    setActiveStepId(nextStep.id);
    return true;
  };

  const goToPrevStep: ContextType['goToPrevStep'] = () => {
    const prevStep = steps[signUpType][activeStepIndex - 1];
    if (!prevStep) return false;
    setActiveStepId(prevStep.id);
    return true;
  };

  const setUserData: ContextType['setUserData'] = (stepEnum, data) => {
    setUserDataState((prev) => {
      const result: UserData = { ...prev, [stepEnum]: { ...prev[stepEnum], ...data } };
      setTimeout(() => setValidity(checkValidity(result, signUpType)), 0);
      return result;
    });
  };

  const handleFinishSignUp: ContextType['handleFinishSignUp'] = async () => {
    if (isSubmitLoading) {
      return;
    }
    doUpdateOrganizationWhenStepChange(signUpType, activeStepId, userDataState);
    setIsSubmitLoading(true);
    sendHubspotData(userDataState, signUpType);
    sendAmplitudeEvents.whenClickFinish(signUpType);

    if (signUpType === SignUpType.Survey) {
      onSignUpAdvertiserComplete();
      return;
    }

    if (new Set([SignUpType.Standard, SignUpType.InviteTeammate]).has(signUpType)) {
      const result = await runSignUpAdvertiserFlow({
        companyRole: userDataState[StepEnum.UserDetails].companyRole.value,
        firstName: userDataState[StepEnum.UserDetails].firstName.value,
        lastName: userDataState[StepEnum.UserDetails].lastName.value,
        phoneNumber: '',
      });

      if (result) {
        const { createAdvertiserProfileMutation$data, currentUserQuery$data } = result;
        const { affiliateReferralCode } = tapfiliate;
        const advertiserProfileId =
          createAdvertiserProfileMutation$data.createAdvertiserProfile?.profile.id;
        const userId = currentUserQuery$data.currentUser?.id;

        dispatch(
          advertiserSignup({
            userId,
            navigate,
            advertiserProfileId,
            affiliateReferralCode,
            source: userDataState[StepEnum.HowDidYouKnow].howDidYouKnow.value,
            websiteUrl: userDataState[StepEnum.UserDetails].websiteUrl.value,
            name: userDataState[StepEnum.UserDetails].organizationName.value,
            companyRole: userDataState[StepEnum.UserDetails].companyRole.value,
            industry: userDataState[StepEnum.CompanyIndustry].industry.value,
            description: userDataState[StepEnum.CompanyType].companyType.value,
            organizationId: userDataState[StepEnum.UserDetails].organizationId.value,
            countryId: '',
            size: undefined,
            verticals: [],
            phoneNumber: '',
            requestedDemo: undefined,
            userExist: false,
            email: user?.email,
            country: null,
            onOrganizationIdGenerated: (organizationId: string) => {
              setUserData(StepEnum.UserDetails, {
                organizationId: { value: organizationId, hubSpotValue: '' },
              });
            },
            onSuccess: () => {
              switch (signUpType) {
                case SignUpType.Standard: {
                  onSignUpAdvertiserComplete({
                    setRegisteredUserForce: false,
                    preventRedirect: true,
                  });
                  navigate(SIGNUP_COMPLETE_ADVERTISER_SURVEY_ROUTE);
                  break;
                }
                case SignUpType.InviteTeammate: {
                  onSignUpAdvertiserComplete();
                  break;
                }
                default: {
                  break;
                }
              }
            },
          })
        );
      }
    }
  };

  const value: ContextType = {
    steps,
    signUpType,
    activeStep,
    isFinalStep,
    isFirstStep,
    activeStepId,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    activeStepIndex,
    setUserData,
    validity,
    handleFinishSignUp,
    isCurrentStepValid,
    isSubmitLoading,
    userData: userDataState,
  };

  return <SignUpCompleteContext.Provider value={value}>{children}</SignUpCompleteContext.Provider>;
};

type WithSignUpCompleteProvider = <P extends Props = object>(
  component: React.ComponentType<P>
) => React.FC<P>;
const withSignUpCompleteProvider: WithSignUpCompleteProvider = (Component) => {
  return (props) => (
    <SignUpCompleteProvider signUpType={props.signUpType}>
      <Component {...props} />
    </SignUpCompleteProvider>
  );
};

export { SignUpCompleteProvider, withSignUpCompleteProvider };

// types

type ContextType = SignUpCompleteContextType;

import { graphql, useMutation } from 'react-relay';

import { BaseMutation, BaseMutationExecFn } from 'Types/utils';
import { RevokeBcaPermissionMutation } from 'GraphTypes/RevokeBcaPermissionMutation.graphql';

const revokeBcaPermissionMutation = graphql`
  mutation RevokeBcaPermissionMutation($input: RevokeBcaPermissionInput!) {
    revokeBcaPermission(input: $input) {
      __typename
      ... on RevokeBcaPermissionPayload {
        bcaPermission {
          id
          state
        }
      }
      ... on NotFoundError {
        message
        subtype
        type
      }
    }
  }
`;

export const useRevokeBcaPermissionMutation: BaseMutation<RevokeBcaPermissionMutation> = (
  config
) => {
  const [revokeBcaPermission, loading] = useMutation<RevokeBcaPermissionMutation>(
    revokeBcaPermissionMutation
  );
  const updateRevokeBcaPermissionCaller: BaseMutationExecFn<RevokeBcaPermissionMutation> = (
    execConfig
  ) => {
    revokeBcaPermission({ ...config, ...execConfig });
  };
  return [updateRevokeBcaPermissionCaller, loading];
};

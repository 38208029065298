import {
  TEXT,
  LINK_TEXT_WITH_TOOLTIP,
} from 'Constants/general';

export const items = [
  {
    id: 'name',
    title: "advertiser",
    values: {
      text: {
        value: "name",
      },
      link: {
        value: "advertiserLink"
      },
    },
    width: '247px',
    element: LINK_TEXT_WITH_TOOLTIP,
  },
  {
    id: 'subscriptionName',
    title: "subscription",
    values: {
      msg: {
        value: "subscriptionName",
      },
    },
    width: '161px',
    element: TEXT,
  },
  {
    id: 'activeTo',
    title: "active_to",
    values: {
      text: {
        value: "subscriptionEndsAt",
      },
    },
    width: '580px',
    element: TEXT,
  },
  {
    id: 'balance',
    title: "balance",
    values: {
      text: {
        value: "balance",
      },
    },
    width: '80px',
    element: TEXT,
  },
];
import React, { useCallback, useContext, useState } from 'react';
import { QueryRenderer } from 'react-relay';

import Skeleton from '../../components/Skeleton/Skeleton';

import CustomLists from './CustomLists/CustomLists';
import styles from './Lists.pcss';
import { ListsQuery, ListsQueryType } from './Lists.Query';

import {
  CustomListCreateDrawer,
  CustomListCreateDrawerProps,
} from 'Modal/advertiser/CustomListCreate';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import environment from 'Api/Environment';
import { amplitude } from 'Helpers/amplitude';

type Props = {
  renderStartHeaderControls?: () => React.ReactNode;
};

const Lists: React.FC<Props> = (props) => {
  const { renderStartHeaderControls } = props;
  const [organizationId, setOrganizationId] = useState<string>('');
  const { openDrawer } = useContext(DrawerContext);

  const handleNewListButtonClick = useCallback(() => {
    amplitude.sendEvent<282>({
      id: '282',
      category: 'creator_lists',
      name: 'new_list_click',
      param: undefined,
    });
    openDrawer('custom-list-create');
  }, []);

  const handleCustomListSuccessfulCreated = useCallback<HandleCustomListSuccessfulCreated>(
    (response) => {
      if (response.createCustomList?.__typename === 'CreateCustomListPayload') {
        const listName = response.createCustomList.customList?.name || '';
        amplitude.sendEvent<283>({
          id: '283',
          category: 'creator_lists',
          name: 'list_created',
          param: {
            list_name: listName,
          },
        });
      }
    },
    []
  );

  return (
    <>
      <div className={styles.root}>
        <div className={styles.headerControls}>
          {renderStartHeaderControls && (
            <div className={styles.startHeaderControls}>{renderStartHeaderControls?.()}</div>
          )}
          <div className={styles.endHeaderControls}>
            <AlterButton
              type="black"
              msg="creators.dashboard.lists.button.new_list"
              icon="Add-plus-circle"
              className={styles.newListButton}
              onClick={handleNewListButtonClick}
            />
          </div>
        </div>
        <QueryRenderer<ListsQueryType>
          environment={environment}
          query={ListsQuery}
          variables={{}}
          render={({ props: queryProps }) => {
            if (!queryProps?.currentUser?.organization) return <Skeleton />;

            const organization = queryProps.currentUser.organization;

            setOrganizationId(organization.id);

            return <CustomLists organization={organization} />;
          }}
        />
      </div>
      <CustomListCreateDrawer
        organizationId={organizationId}
        drawerProps={{ removeWhenClosed: true }}
        onCustomListSuccessfulCreated={handleCustomListSuccessfulCreated}
      />
    </>
  );
};

export default Lists;

// types

type HandleCustomListSuccessfulCreated = NonNullable<
  CustomListCreateDrawerProps['onCustomListSuccessfulCreated']
>;

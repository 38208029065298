const MESSAGE = 'Message';
const DEADLINE = 'Deadline';
const PRICE_CHANGE = 'PriceChange';
const OFFER_ACCEPTANCE = 'OfferAcceptance';
const PROJECT_COMPLETION = 'ProjectCompletion';
const PUBLICATION_DEADLINE_CHANGED = 'PublicationDeadlineChange';
const LAUNCH = 'ProjectLaunch';
const EXTRA_PAYOUT = 'ExtraPayout';
const REIMBURSEMENT = 'Reimbursement';
const PRICE_CHANGE_REQUEST = 'PriceChangeRequest';
const PRICE_CHANGE_REQUEST_ACCEPTANCE = 'PriceChangeRequestAcceptance';
const PRICE_CHANGE_REQUEST_REJECTION = 'PriceChangeRequestRejection';
const CONTENT_REVIEW_FAIL = 'ContentReviewFail';
const CONTENT_REVIEW_PASS = 'ContentReviewPass';
const PUBLICATION_EVENT = 'PublicationEvent';
const PUBLICATION_REQUEST = 'PublicationRequest';
const DEADLINE_CHANGE_REQUEST = 'DeadlineChangeRequest';
const ARCHIVATION = 'ProjectArchivation';
const CONTENT_REVIEW_REQUEST = 'ContentReviewSubmission';
const PRODUCT_SEEDING_CONTENT_SUBMISSION = 'ProductSeedingContentSubmission';

export const getSecondaryText = (edges) => {
  if (!edges || edges.length === 0) return null;
  const item = edges[0].node;
  const { __typename, actor, comment } = item;
  let textData;

  const isOwner = actor.__typename === 'Brand';

  switch (__typename) {
    case MESSAGE: {
      const { text, attachments } = item;
      if (attachments.edges.length > 0) {
        const list = attachments.edges;
        const { type } = list[list.length - 1].node.file;
        textData = { text: type };
      } else {
        textData = isOwner
          ? {
              msg: 'chat.project_message_owner',
              values: { message: text },
              formatValues: { message: text },
            }
          : { text };
      }
      break;
    }
    case OFFER_ACCEPTANCE: {
      const { comment } = item;
      textData = isOwner
        ? {
            msg: 'chat.project_message_owner',
            values: { message: comment },
            formatValues: { message: comment },
          }
        : { text: comment };
      break;
    }

    case LAUNCH:
      textData = { text: comment };
      break;

    case DEADLINE:
      textData = { msg: 'chat.project_deadline_changed' };
      break;

    case PUBLICATION_DEADLINE_CHANGED:
      textData = { msg: 'chat.project_publication_date_changed' };
      break;

    case PRICE_CHANGE:
      textData = { msg: 'chat.project_price_changed' };
      break;

    case PROJECT_COMPLETION:
      textData = { msg: 'chat.project_completed_short' };
      break;

    case PUBLICATION_EVENT:
      textData = { msg: 'chat.publication_event' };
      break;

    case EXTRA_PAYOUT:
      textData = { msg: 'chat.project.system_event.extra_payment' };
      break;

    case REIMBURSEMENT:
      textData = { msg: 'chat.project.system_event.reimbursement_payment' };
      break;

    case PRICE_CHANGE_REQUEST:
      textData = { msg: 'chat.project.system_event.price_change_request.short' };
      break;

    case PRICE_CHANGE_REQUEST_REJECTION:
      textData = { msg: 'chat.project.system_event.price_change_reject' };
      break;

    case CONTENT_REVIEW_REQUEST:
      textData = { msg: 'chat.project.system_event.content_review_submission' };
      break;

    case PRODUCT_SEEDING_CONTENT_SUBMISSION:
      textData = { msg: 'chat.project.system_event.product_seeding_content_submission' };
      break;

    case PRICE_CHANGE_REQUEST_ACCEPTANCE:
      textData = { msg: 'chat.project.system_event.price_change_accept' };
      break;

    case CONTENT_REVIEW_FAIL:
      textData = { msg: 'chat.project.system_event.content_review_fail.short' };
      break;

    case CONTENT_REVIEW_PASS:
      textData = { msg: 'chat.project.system_event.content_review_pass' };
      break;

    case PUBLICATION_REQUEST:
      textData = { msg: 'chat.project.system_event.publication_request' };
      break;

    case DEADLINE_CHANGE_REQUEST:
      textData = { msg: 'chat.deadline_change_request.short' };
      break;

    case ARCHIVATION:
      textData = { msg: 'chat.project.archivation' };
      break;

    default:
      break;
  }

  return textData;
};

import { StatItem, createDateLabel } from '../Chart/utils';

import type { EngagementCountersChartQueryType } from './EngagementCountersChart.Query';

import { amplitude } from 'Helpers/amplitude';
import type { Performance_Granularity } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';

type CharDataType = {
  stats: StatItem[];
  lines: {
    engagementCount: number;
    likesCount: number;
    commentsCount: number;
    sharesCount: number;
    savedCount: number;
    date: string;
  }[];
};
export const chartData = (
  response: EngagementCountersChartQueryType['response'],
  granularity: Performance_Granularity
): CharDataType => {
  const stats = [
    {
      amount: response.performance?.engagementCounters?.totalEngagementCount || 0,
      key: 'engagementCount',
      description: {
        titleMsg: 'performance.dashboard.chart.engagement_counters.engagement_count.title',
        tooltipMsg: 'performance.dashboard.chart.engagement_counters.engagement_count.tooltip',
      },
    },
    {
      amount: response.performance?.engagementCounters?.totalLikesCount || 0,
      key: 'likesCount',
      description: {
        titleMsg: 'performance.dashboard.chart.engagement_counters.likes_count.title',
      },
    },
    {
      amount: response.performance?.engagementCounters?.totalCommentsCount || 0,
      key: 'commentsCount',
      description: {
        titleMsg: 'performance.dashboard.chart.engagement_counters.comments_count.title',
      },
    },
    {
      amount: response.performance?.engagementCounters?.totalSharesCount || 0,
      key: 'sharesCount',
      description: {
        titleMsg: 'performance.dashboard.chart.engagement_counters.shared_count.title',
      },
    },
    {
      amount: response.performance?.engagementCounters?.totalSavedCount || 0,
      key: 'savedCount',
      description: {
        titleMsg: 'performance.dashboard.chart.engagement_counters.saved_count.title',
      },
    },
  ];

  const edges = response?.performance?.engagementCounters?.edges || [];
  const lines: {
    engagementCount: number;
    likesCount: number;
    commentsCount: number;
    sharesCount: number;
    savedCount: number;
    date: string;
  }[] = [];

  edges.forEach((item) => {
    if (!item?.node) return;

    const minValue = Math.min(
      item.node.engagementCount,
      item.node.likesCount,
      item.node.commentsCount,
      item.node.sharesCount,
      item.node.savedCount
    );

    if (minValue < 0) {
      amplitude.sendEvent<355>({
        id: '355',
        category: 'perfomance',
        name: 'negative_value_shown',
        param: undefined,
      });
    }

    lines.push({
      engagementCount: item.node.engagementCount,
      likesCount: item.node.likesCount,
      commentsCount: item.node.commentsCount,
      sharesCount: item.node.sharesCount,
      savedCount: item.node.savedCount,
      date:
        createDateLabel(item.node.dateFrom as string, item.node.dateTo as string, granularity) ||
        '',
    });
  });

  return { stats, lines };
};

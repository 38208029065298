/**
 * @generated SignedSource<<aa300fef632d668062fcec6ee6760273>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatorReviewReason = "ADULT_CONTENT" | "BRAND_OR_COMMUNITY" | "CELEBRITY" | "COMMUNITY" | "CONTENT_QUALITY" | "ENGAGEMENT_RATE" | "LOCATION" | "NUMBER_OF_FOLLOWERS" | "NUMBER_OF_VIEWS" | "POOR_AUDIENCE_QUALITY" | "PORTFOLIO_QUALITY" | "PRIVATE_ACCOUNT" | "PROHIBITED_CONTENT" | "REAL_FOLLOWERS" | "REMODERATION" | "SPECIAL_CATEGORY" | "WRONG_HANDLE" | "%future added value";
export type MarkCreatorAsFailedReviewInput = {
  clientMutationId?: string | null | undefined;
  creatorId: string;
  id?: string | null | undefined;
  reason?: CreatorReviewReason | null | undefined;
};
export type MarkCreatorAsFailedReviewMutation$variables = {
  input: MarkCreatorAsFailedReviewInput;
};
export type MarkCreatorAsFailedReviewMutation$data = {
  readonly markCreatorAsFailedReview: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type MarkCreatorAsFailedReviewMutation = {
  response: MarkCreatorAsFailedReviewMutation$data;
  variables: MarkCreatorAsFailedReviewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkCreatorAsFailedReviewPayload",
    "kind": "LinkedField",
    "name": "markCreatorAsFailedReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkCreatorAsFailedReviewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkCreatorAsFailedReviewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a8519436d9e05a191866aa1634d8bd05",
    "id": null,
    "metadata": {},
    "name": "MarkCreatorAsFailedReviewMutation",
    "operationKind": "mutation",
    "text": "mutation MarkCreatorAsFailedReviewMutation(\n  $input: MarkCreatorAsFailedReviewInput!\n) {\n  markCreatorAsFailedReview(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ee46d60003590a13f62b49f9673a8f7";

export default node;

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import find from 'lodash/find';

import { getFormatTypesList } from '../util';

import styles from './AssetPreview.css';

import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Icon from 'Atoms/Icon/Icon';
import { AssetPreview_briefCreative$key } from 'GraphTypes/AssetPreview_briefCreative.graphql';

interface Props {
  briefCreative: AssetPreview_briefCreative$key;
  isValid: boolean;
  onAssetDelete: () => void;
  onAssetCopy: () => void;
  onAssetOpen: () => void;
}

const AssetPreview: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { briefCreative, isValid, onAssetDelete, onAssetCopy, onAssetOpen } = props;

  const data = useFragment(
    graphql`
      fragment AssetPreview_briefCreative on BriefCreative {
        id
        mediaType
        v2ContentPlacement
        contentFormat
        videoDurationMin
        videoDurationMax
        contentPreparedness
        contentType
        v2ContentType
        lastReferences: references(first: 3)
          @connection(key: "AssetPreview_lastReferences", filters: []) {
          edges {
            node {
              id
              file {
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
                ... on Video {
                  transformations {
                    collageThumbnailUrl
                  }
                }
                type
              }
            }
          }
        }
      }
    `,
    briefCreative
  );

  if (!data) return null;

  const {
    mediaType,
    v2ContentPlacement,
    contentFormat,
    videoDurationMin,
    videoDurationMax,
    contentPreparedness,
    v2ContentType,
    lastReferences,
  } = data;

  const formatTypesList = getFormatTypesList();

  const mediaText = mediaType
    ? intl.formatMessage({
        id: `brief_template.creative_asset.media_type.${mediaType.toLowerCase()}`,
      })
    : '';
  const contentText = v2ContentType
    ? intl.formatMessage({
        id: `brief_template.creative_asset.content_type.${v2ContentType.toLowerCase()}`,
      })
    : '';
  const placementText = v2ContentPlacement
    ? intl.formatMessage({
        id: `brief_template.creative_asset.placement.${v2ContentPlacement.toLowerCase()}`,
      })
    : '';

  const currentFormatText = contentFormat
    ? find(formatTypesList, (item) => item.id === contentFormat)?.titleText
    : '';
  const preparednessText = contentPreparedness
    ? intl.formatMessage({
        id: `brief_template.creative_asset.preparedness.${contentPreparedness.toLowerCase()}`,
      })
    : '';

  let videoDurationText = '';

  if (videoDurationMin && videoDurationMax) {
    videoDurationText = `${videoDurationMin}-${videoDurationMax}sec`;
  } else if (videoDurationMin) {
    videoDurationText = `${videoDurationMin}sec`;
  } else if (videoDurationMax) {
    videoDurationText = `${videoDurationMax}sec`;
  }

  const titleText = `${mediaText} ${contentText} ${placementText}`;

  const createReferences = () => {
    return (
      <div className={styles.references}>
        {lastReferences?.edges?.slice(0, 3).map((item) => {
          if (!item?.node) return null;

          const { id, file } = item.node;
          const type = file?.type;

          const mediaUrl = file?.transformations?.collageThumbnailUrl;

          return (
            <div key={id} className={styles.referencesPreview}>
              {type === 'OTHER' && <Icon name="document" color="black" size="sm" />}
              {type !== 'OTHER' && (
                <div
                  style={{ backgroundImage: `url(${mediaUrl}` }}
                  className={styles.imagePreview}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classnames(styles.preview, { [styles.notValid]: !isValid })}>
      <div className={styles.previewContent}>
        <Text weight="700" text={titleText} className={styles.previewTitle} />
        {!isValid && <Text color="red" msg="brief_template.creative_asset.not_completed" />}
        {isValid && (
          <div className={styles.previewDetails}>
            {createReferences()}
            <div className={styles.subtitleWrap}>
              {currentFormatText && (
                <Text color="grayDog" text={currentFormatText} className={styles.subtitleItem} />
              )}
              {videoDurationText && (
                <Text color="grayDog" text={videoDurationText} className={styles.subtitleItem} />
              )}
              {preparednessText && (
                <Text
                  color="grayDog"
                  text={preparednessText}
                  className={styles.subtitleItem}
                  overflow
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.previewControls}>
        <TextButton color="normal" iconName="deleteIcon" size="lg" onClick={onAssetDelete} />
        <TextButton color="normal" iconName="copy2" onClick={onAssetCopy} />
        <Button color="normal" msg="brief_template.creative_asset.edit" onClick={onAssetOpen} />
      </div>
    </div>
  );
};

export default AssetPreview;

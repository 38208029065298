/**
 * @generated SignedSource<<8fa7ca6c4df48a8daccecf2094f96963>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdvertiserProfile",
        "kind": "LinkedField",
        "name": "advertiserProfile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionsSagaQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionsSagaQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f422fd074b43dc1ef0c65c8785092366",
    "id": null,
    "metadata": {},
    "name": "SessionsSagaQuery",
    "operationKind": "query",
    "text": "query SessionsSagaQuery {\n  currentUser {\n    id\n    type\n    advertiserProfile {\n      id\n      firstName\n    }\n  }\n}\n"
  }
};
})();

node.hash = "57876a25143942c4c49c9e64217045e9";

module.exports = node;

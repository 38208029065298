import React, { FC, useCallback, useState } from 'react';
import classnames from 'classnames';

import styles from './SegmentedControls.pcss';

import NewIcon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import modsClasses from 'Util/modsClasses.js';
import Text from 'Components/ui/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import InfoTooltip from 'Molecules/InfoTooltip/InfoTooltip';
import { IconType, TextType } from 'Types/common';
import type { EventData } from 'Helpers/amplitude.types';
import Tag from 'Components/ui/Tag/Tag';
import { useShopifyOnboarding } from 'Hooks/useShopifyOnboarding';
import { useIntl } from 'react-intl';
import { IconName } from 'Components/ui/types';

const MODS = ['radius'];

type outerLinkPositionType = 'default' | 'bottom';

export type ControlItem<T extends string = string> = {
  id: string | number | T;
  value: string | boolean | number | null | T;
  titleMsg?: string;
  titleText?: string;
  titleValues?: TextType['values'];
  subtitleMsg?: string;
  iconName?: string;
  tooltipMsg?: string;
  tooltipEventData?: EventData;
  disabled?: boolean;
  activeColor?: 'red';
  dataTest?: string;
  outerLink?: string;
  outerLinkMsg?: string;
  outerLinkCb?: () => void;
  buttonTooltipMsg?: string;
  plane?: string;
  tooltipIcon?: IconName;
  tooltipGetContent?: FC;
};

interface Props {
  id: string;
  className?: string;
  currentValue?: string | boolean | number | null;
  items: ControlItem[];
  radius?: 'bottom';
  itemClassName?: string;
  onChange: (data: Record<string, unknown>) => void;
  outerLinkPosition?: outerLinkPositionType;
}

const SegmentedControls: React.FC<Props> = (props) => {
  const { id, currentValue, items, itemClassName, outerLinkPosition = 'default', onChange } = props;

  const { isPassed, onStatePassed } = useShopifyOnboarding();

  const [isButtonTooltipOpen, setIsButtonTooltipOpen] = useState<boolean>(!isPassed('brief'));
  const { formatMessage } = useIntl();

  const onTooltipClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onStatePassed('brief');
    setIsButtonTooltipOpen(false);
  };

  const createItem = (data: ControlItem) => {
    const {
      id: controlId,
      titleMsg,
      titleText,
      titleValues,
      value,
      iconName,
      subtitleMsg,
      disabled,
      tooltipEventData,
      activeColor,
      dataTest,
      outerLink,
      outerLinkMsg,
      outerLinkCb,
      buttonTooltipMsg,
      plane,
      tooltipIcon,
      tooltipGetContent,
    } = data;

    const isActive = currentValue === value;

    const tooltipMsg = disabled
      ? 'brief_template.field.campaign_type.unavailable'
      : data.tooltipMsg;

    const handleClick = () => {
      onChange({ [id]: value });
    };

    const handleOuterLinkClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      outerLinkCb?.();
      window.open(outerLink, '_blank');
    };

    const key = String(controlId);

    const specialActiveColor = activeColor ? styles[`color-${activeColor}`] : '';

    return (
      <div
        key={key}
        data-test={dataTest}
        className={classnames(styles.control, itemClassName, specialActiveColor, {
          [styles.active]: isActive,
          [styles.disabled]: disabled,
        })}
        onClick={disabled ? undefined : handleClick}
      >
        {tooltipMsg && (
          <InfoTooltip
            id={key}
            tooltipMsg={tooltipMsg}
            tooltipValues={plane ? { plane } : {}}
            eventData={tooltipEventData}
            iconName={tooltipIcon}
            place="top"
            color="black"
            delayShow={0}
            className={styles.tooltip}
            getContent={tooltipGetContent}
            clickable
          />
        )}
        {iconName && <Icon name={iconName as IconType['name']} className={styles.icon} />}
        {buttonTooltipMsg && isButtonTooltipOpen ? (
          <Tooltip
            id={buttonTooltipMsg}
            place={'top'}
            tooltipClassName={styles.buttonTooltip}
            showByDefault={isButtonTooltipOpen}
            clickable
            customTooltip={
              <div className={styles.tooltipContainer}>
                <Text msg={buttonTooltipMsg} type="md" />
                <div className={styles.closeIcon} onClick={onTooltipClose}>
                  <NewIcon name={'Close-small'} className={styles.closeIcon} />
                </div>
              </div>
            }
          >
            <Text type="md" msg={titleMsg} text={titleText} formatValues={titleValues} />
          </Tooltip>
        ) : (
          <Text type="md" msg={titleMsg} text={titleText} formatValues={titleValues} />
        )}
        {subtitleMsg && <Text type="md" msg={subtitleMsg} className={styles.subtitle} />}
        {outerLink && !tooltipMsg && (
          <div
            onClick={handleOuterLinkClick}
            className={classnames(styles.tagWrap, {
              [styles.bottom]: outerLinkPosition === 'bottom',
            })}
          >
            <Tag
              needMargin={false}
              msg={outerLinkMsg}
              icon="Arrow-big-right"
              color="grey"
              iconPosition="right"
              className={styles.tag}
            />
          </div>
        )}
      </div>
    );
  };

  const classes = modsClasses(MODS, props, styles);

  return <div className={`${styles.root} ${classes}`}>{items.map((item) => createItem(item))}</div>;
};

export default SegmentedControls;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  VerifyPaidSocialRightsInput,
  VerifyPaidSocialRightsMutation$data,
  VerifyPaidSocialRightsMutation,
} from 'GraphTypes/VerifyPaidSocialRightsMutation.graphql';

type VerifyPaidSocialRightsInputAdditionalData = {
  creatorId: string;
};

const mutation = graphql`
  mutation VerifyPaidSocialRightsMutation($input: VerifyPaidSocialRightsInput!) {
    verifyPaidSocialRights(input: $input) {
      paidSocialRightsVerification {
        id
        verifiedAt
        success
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: VerifyPaidSocialRightsInput & VerifyPaidSocialRightsInputAdditionalData,
  resolve?: (response: VerifyPaidSocialRightsMutation$data) => void,
  reject?: fnType
) => {
  const { projectId, success, creatorId } = data;
  const id = uuid();
  const variables = {
    input: {
      id,
      projectId,
      success,
    },
  };
  commitMutation<VerifyPaidSocialRightsMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const activation = store.get(id);
      if (activation) {
        const creator = store.get(creatorId);
        if (!creator) return;
        creator.setLinkedRecord(activation, 'paidSocialRightsVerification');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { objectEntries } from 'Util/objectEntries';
import type { GuideToursMap, GuideTour, GuideTourStep } from 'Components/ui/GuideTour/types';

type GuideToursIDs =
  | 'navigation'
  | 'navigationRestart'
  | 'campaigns'
  | 'creators'
  | 'licenses'
  | 'licensesEmpty'
  | 'exploreDashboard'
  | 'productsOver50'
  | 'chatPostPublication'
  | 'chat'
  | 'chatHistory';

type StepNamesMap = {
  navigation: 'campaigns' | 'marketplace' | 'brands' | 'licenses' | 'creators' | 'content';
  navigationRestart: 'navigationRestart';
  campaigns: 'campaignActions';
  creators: 'portfolioContent';
  licensesEmpty: 'empty';
  licenses: 'intro' | 'daysLeft' | 'fbPage' | 'moreInfo';
  exploreDashboard: 'newCampaign' | 'marketplace' | 'licenses' | 'brands';
  chatPostPublication: 'publicationEvent';
  chat: 'panelTop' | 'panelBottom' | 'chatInput';
  productsOver50: 'input';
  chatHistory: 'creativeReview';
};

const navigationGuideTour: GuideTour<StepNamesMap['navigation']> = {
  mode: 'auto',
  steps: new Map<StepNamesMap['navigation'], GuideTourStep>(
    objectEntries<StepNamesMap['navigation'], GuideTourStep>({
      campaigns: {
        tooltipProps: {
          msg: 'guide_tours.navigation.campaigns',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      marketplace: {
        tooltipProps: {
          msg: 'guide_tours.navigation.marketplace',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      brands: {
        tooltipProps: {
          msg: 'guide_tours.navigation.brands',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      licenses: {
        tooltipProps: {
          msg: 'guide_tours.navigation.licenses',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      creators: {
        tooltipProps: {
          msg: 'guide_tours.navigation.creators',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      content: {
        tooltipProps: {
          msg: 'guide_tours.navigation.content',
          btnMsg: 'guide_tours.buttons.done',
        },
      },
    })
  ),
};

const navigationRestartTour: GuideTour<StepNamesMap['navigationRestart']> = {
  mode: 'manual',
  steps: new Map<StepNamesMap['navigationRestart'], GuideTourStep>(
    objectEntries<StepNamesMap['navigationRestart'], GuideTourStep>({
      navigationRestart: {
        tooltipProps: {
          msg: 'guide_tours.navigation_restart',
          btnMsg: 'guide_tours.navigation_restart.start',
        },
      },
    })
  ),
};

const productsOver50Tour: GuideTour<StepNamesMap['productsOver50']> = {
  mode: 'manual',
  steps: new Map<StepNamesMap['productsOver50'], GuideTourStep>(
    objectEntries<StepNamesMap['productsOver50'], GuideTourStep>({
      input: {
        tooltipProps: {
          msg: 'guide_tours.products.input',
        },
      },
    })
  ),
};

const campaignsGuideTour: GuideTour<StepNamesMap['campaigns']> = {
  mode: 'auto',
  steps: new Map<StepNamesMap['campaigns'], GuideTourStep>(
    objectEntries<StepNamesMap['campaigns'], GuideTourStep>({
      campaignActions: {
        tooltipProps: { msg: 'guide_tours.campaigns.campaign_actions' },
      },
    })
  ),
};

const creatorsGuideTour: GuideTour<StepNamesMap['creators']> = {
  mode: 'auto',
  steps: new Map<StepNamesMap['creators'], GuideTourStep>(
    objectEntries<StepNamesMap['creators'], GuideTourStep>({
      portfolioContent: {
        tooltipProps: { msg: 'guide_tours.creators.portfolio_content' },
      },
    })
  ),
};

const licensesEmptyGuideTour: GuideTour<StepNamesMap['licensesEmpty']> = {
  mode: 'auto',
  steps: new Map<StepNamesMap['licensesEmpty'], GuideTourStep>(
    objectEntries<StepNamesMap['licensesEmpty'], GuideTourStep>({
      empty: {
        tooltipProps: { msg: 'guide_tours.licenses.empty' },
      },
    })
  ),
};

const licensesGuideTour: GuideTour<StepNamesMap['licenses']> = {
  mode: 'auto',
  steps: new Map<StepNamesMap['licenses'], GuideTourStep>(
    objectEntries<StepNamesMap['licenses'], GuideTourStep>({
      intro: {
        tooltipProps: {
          msg: 'guide_tours.licenses.intro',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      daysLeft: {
        tooltipProps: {
          msg: 'guide_tours.licenses.daysLeft',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      fbPage: {
        tooltipProps: {
          msg: 'guide_tours.licenses.fbPage',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      moreInfo: {
        tooltipProps: {
          msg: 'guide_tours.licenses.moreInfo',
          btnMsg: 'guide_tours.buttons.ok',
        },
      },
    })
  ),
};

const exploreDashboardGuideTour: GuideTour<StepNamesMap['exploreDashboard']> = {
  mode: 'manual',
  steps: new Map<StepNamesMap['exploreDashboard'], GuideTourStep>(
    objectEntries<StepNamesMap['exploreDashboard'], GuideTourStep>({
      newCampaign: {
        tooltipProps: {
          msg: 'guide_tours.explore_dashboard.new_campaign',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      marketplace: {
        tooltipProps: {
          msg: 'guide_tours.explore_dashboard.marketplace',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      licenses: {
        tooltipProps: {
          msg: 'guide_tours.explore_dashboard.licenses',
          btnMsg: 'guide_tours.buttons.next',
        },
      },
      brands: {
        tooltipProps: {
          msg: 'guide_tours.explore_dashboard.brands',
          btnMsg: 'guide_tours.buttons.done',
        },
      },
    })
  ),
};

const chatPostPublication: GuideTour<StepNamesMap['chatPostPublication']> = {
  mode: 'manual',
  steps: new Map<StepNamesMap['chatPostPublication'], GuideTourStep>(
    objectEntries<StepNamesMap['chatPostPublication'], GuideTourStep>({
      publicationEvent: {
        tooltipProps: {
          msg: 'guide_tours.chat_post_publication.publication_event',
        },
      },
    })
  ),
};

const chatGuideTour: GuideTour<StepNamesMap['chat']> = {
  mode: 'auto',
  steps: new Map<StepNamesMap['chat'], GuideTourStep>(
    objectEntries<StepNamesMap['chat'], GuideTourStep>({
      panelTop: {
        tooltipProps: { msg: 'guide_tours.chat.panel_top', backdrop: true },
      },
      panelBottom: {
        tooltipProps: { msg: 'guide_tours.chat.panel_bottom', backdrop: true },
      },
      chatInput: {
        tooltipProps: { msg: 'guide_tours.chat.chat_input', backdrop: true },
      },
    })
  ),
};

const chatHistoryGuideTour: GuideTour<StepNamesMap['chatHistory']> = {
  mode: 'manual',
  steps: new Map<StepNamesMap['chatHistory'], GuideTourStep>(
    objectEntries<StepNamesMap['chatHistory'], GuideTourStep>({
      creativeReview: {
        tooltipProps: {
          msg: 'guide_tours.chat_history.creative_preview',
          btnMsg: 'guide_tours.buttons.lets_see',
          backdrop: true,
        },
      },
    })
  ),
};

const guideTours: GuideToursMap<GuideToursIDs> = new Map();
guideTours.set('campaigns', campaignsGuideTour);
guideTours.set('creators', creatorsGuideTour);
guideTours.set('licensesEmpty', licensesEmptyGuideTour);
guideTours.set('licenses', licensesGuideTour);
guideTours.set('exploreDashboard', exploreDashboardGuideTour);
guideTours.set('chat', chatGuideTour);
guideTours.set('chatPostPublication', chatPostPublication);
guideTours.set('chatHistory', chatHistoryGuideTour);
guideTours.set('navigation', navigationGuideTour);
guideTours.set('navigationRestart', navigationRestartTour);
guideTours.set('productsOver50', productsOver50Tour);

export { guideTours };
export type { GuideToursIDs, StepNamesMap };

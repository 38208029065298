import React, { useState } from 'react';

import styles from './MailingDeletion.css';

import unsubscribeFromEmailNotifications from 'Mutations/UnsubscribeFromEmailNotifications.Mutation';
import Input from 'Atoms/Input/Input';
import Button from 'Atoms/Button/Button';
import Text from 'Atoms/Text/Text';
import { TextType } from 'Types/common';


const MailingDeletion = () => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState<null | TextType>(null);

  const handleChange = (newEmail: string) => {
    setEmail(newEmail);
  };

  const handleClick = () => {
    if (result) {
      setResult(null);
    }
    unsubscribeFromEmailNotifications({ email }, handleComplete, handleReject);
  };

  const handleComplete = () => {
    setResult({ text: 'Succeess!', color: 'green' });
  };

  const handleReject = () => {
    setResult({ text: 'Fail!', color: 'red' });
  };

  return (
    <div className={styles.root}>
      <Text tag="h2" weight="700" text="Unsubscribe user from email notifications" className={styles.title} />
      <div className={styles.content}>
        <div className={styles.input}>
          <Input placeholder="Email" onChange={handleChange} />
        </div>
        <Button text="Submit" className={styles.submit} onClick={handleClick} />
      </div>
      {result && <Text tag="h3" weight="500" {...result} className={styles.result} />}
    </div>
  );
};

export default MailingDeletion;
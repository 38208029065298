import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import classNames from 'classnames';
import classnames from 'classnames';

import styles from './CreatorDetails.pcss';

import { getCreatorData } from 'Util/creatorData';
import { createCountriesList } from 'Util/dataFormatter';
import Avatar from 'Atoms/Avatar/Avatar';
import Text from 'Components/ui/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';
import { CreatorBadgeEnum } from 'Types/enums';
import { IconType } from 'Types/common';
import {
  CreatorDetails_creator$key,
  CreatorDetails_creator$data,
} from 'GraphTypes/CreatorDetails_creator.graphql';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';

interface Props {
  creator: CreatorDetails_creator$key;
  shipment?: boolean;
  disableBadge?: boolean;
  isBlockedView?: boolean;
  shipmentReceiverName?: string;
  showUnlockCreatorBanner?: () => void;
}

const CreatorDetails: React.FC<Props> = (props) => {
  const {
    creator,
    shipment,
    disableBadge,
    shipmentReceiverName,
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const data = useFragment<KeyType<CreatorDetails_creator$data>>(
    graphql`
      fragment CreatorDetails_creator on Creator {
        id
        profile {
          badges {
            name
          }
          countries {
            name
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
          shippingRecipientLastName
          shippingRecipientFirstName
        }
        type
        username
        ... on InstagramCreator {
          user {
            profilePictureUrl
          }
        }
        ... on YoutubeCreator {
          channel {
            id
            thumbnailUrl
            name
          }
        }
        ... on ContentCreator {
          avatarUrl
        }
        ... on TiktokCreator {
          user {
            avatar {
              secureUrl
            }
            name
          }
        }
      }
    `,
    normalizeFragmentRef<CreatorDetails_creator$key>(creator)
  );

  if (!data) return null;

  const { avatarUrl, username, iconColored } = useMemo(() => {
    return getCreatorData(data);
  }, [data]);

  const countries = data?.profile?.countries;

  const countriesList = useMemo(() => {
    return createCountriesList(countries);
  }, [countries]);

  if (!data) return null;

  const badges = data?.profile?.badges;

  return (
    <div className={styles.creatorInfo}>
      <div className={styles.avatarWrap}>
        <Avatar src={avatarUrl} size={shipment ? 40 : 48} />
        <div className={styles.platform}>
          <Icon name={iconColored as IconType['name']} size="sm" />
        </div>
      </div>
      <div>
        <div className={styles.details}>
          {isBlockedView && showUnlockCreatorBanner ? (
            <BlockedUsername
              creatorId={data.id}
              onLockClick={showUnlockCreatorBanner}
              textClassName={styles.blockedUsername}
            />
          ) : (
            <Text
              type={shipment ? 'md' : 'h2'}
              text={shipment ? shipmentReceiverName : username}
              className={classnames(styles.username, { [styles.shipment]: shipment })}
            />
          )}
          {!disableBadge && (
            <div className={styles.badges}>
              {badges?.map((badge) => {
                if (!badge.name) return null;
                return (
                  <CreatorBadge
                    key={badge.name}
                    badge={badge.name as CreatorBadgeEnum}
                    withText={false}
                    className={styles.badge}
                    iconClassName={styles.badgeIcon}
                  />
                );
              })}
            </div>
          )}
        </div>
        <Text
          type={shipment ? 'label' : 'md'}
          text={shipment ? username : countriesList}
          className={styles.countries}
        />
      </div>
    </div>
  );
};

export default CreatorDetails;

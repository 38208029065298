import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ActivateCampaignInput,
  ActivateCampaignMutation$data,
  ActivateCampaignMutation,
} from 'GraphTypes/ActivateCampaignMutation.graphql';

const mutation = graphql`
  mutation ActivateCampaignMutation($input: ActivateCampaignInput!) {
    activateCampaign(input: $input) {
      campaign {
        id
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ActivateCampaignInput,
  resolve?: (response: ActivateCampaignMutation$data) => void,
  reject?: fnType
) => {
  const { campaignId } = data;
  const id = uuid();
  const variables = {
    input: {
      id,
      campaignId,
    },
  };
  commitMutation<ActivateCampaignMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const campaign = store.get(campaignId);
      if (!campaign) return;
      const mediaplan = ConnectionHandler.getConnection(campaign, 'Projects_projects');
      if (mediaplan) {
        const mediaplanItems = mediaplan
          ?.getLinkedRecords('edges')
          ?.filter((edge) => !edge?.getLinkedRecord('node')?.getLinkedRecord('project'));
        if (!mediaplanItems) return;
        for (let i = 0; i < mediaplanItems.length; i++) {
          const mediaplanItemNode = mediaplanItems[i].getLinkedRecord('node');
          const project = store.create(uuid(), 'Project');
          if (!mediaplanItemNode) return;
          mediaplanItemNode.setLinkedRecord(project, 'project');
        }
      }
    },
    optimisticUpdater: (store) => {
      const campaign = store.get(campaignId);
      if (!campaign) return;
      const mediaplan = ConnectionHandler.getConnection(campaign, 'Projects_projects');
      if (mediaplan) {
        const mediaplanItems = mediaplan
          ?.getLinkedRecords('edges')
          ?.filter((edge) => !edge?.getLinkedRecord('node')?.getLinkedRecord('project'));
        if (!mediaplanItems) return;
        for (let i = 0; i < mediaplanItems.length; i++) {
          const mediaplanItemNode = mediaplanItems[i].getLinkedRecord('node');
          const project = store.create(uuid(), 'Project');
          if (!mediaplanItemNode) return;
          mediaplanItemNode.setLinkedRecord(project, 'project');
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

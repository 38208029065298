import React from 'react';

import markInvoiceAsIssued from 'Mutations/accounting/MarkInvoiceAsIssued.Mutation';
import IconMenu from 'Molecules/IconMenu/IconMenu';

interface Props {
  transactionId: string;
}

const NotIssuedStatus: React.FC<Props> = (props) => {
  const { transactionId } = props;

  const handleChangeStatusClick = () => {
    markInvoiceAsIssued({ revenueTransactionId: transactionId });
  };

  const createDropDownItems = () => {
    return [
      {
        id: 'yes',
        textData: {
          textData: {
            msg: 'general.yes',
          },
        },
        onClick: handleChangeStatusClick,
      },
    ];
  };

  const dropDownItems = createDropDownItems();

  return (
    <div>
      <IconMenu
        textBtnData={{
          msg: 'general.no',
        }}
        dropDownData={{ list: dropDownItems }}
      />
    </div>
  );
};

export default NotIssuedStatus;

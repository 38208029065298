/**
 * @generated SignedSource<<bf42fffbcdc0bea6e5a6c46bda499d2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Profiles_paymentAccount$data = {
  readonly owner: {
    readonly allCreators: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly username: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly creators: {
      readonly totalCount: number;
    } | null | undefined;
    readonly firstCreator: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly username: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Profiles_paymentAccount";
};
export type Profiles_paymentAccount$key = {
  readonly " $data"?: Profiles_paymentAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"Profiles_paymentAccount">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreatorEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Profiles_paymentAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorConnection",
          "kind": "LinkedField",
          "name": "creators",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "firstCreator",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "CreatorConnection",
          "kind": "LinkedField",
          "name": "creators",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": "creators(first:1)"
        },
        {
          "alias": "allCreators",
          "args": null,
          "concreteType": "CreatorConnection",
          "kind": "LinkedField",
          "name": "creators",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Payment_Account",
  "abstractKey": null
};
})();

(node as any).hash = "5b7ac6d57ad14077310b0f7b7059939b";

export default node;

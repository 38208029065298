import React from 'react';
import { useDispatch } from 'react-redux';
import track from 'react-tracking';

import ListingBrief from './ListingBrief/ListingBrief';
import ProjectBrief from './ProjectBrief/ProjectBrief';
import ProjectForOffer from './ProjectForOffer/ProjectForOffer';
import MobileBrief from './MobileBrief/MobileBrief';
import CampaignBrief from './CampaignBrief/CampaignBrief';
import CampaignOutreachContent from './CampaignOutreachContent/CampaignOutreachContent';

import { toggleModal, setModal } from 'Actions/Modal.Actions';

interface Props {
  campaignId?: string;
  projectId?: string;
  isOwnership?: boolean;
  hasOwnership?: boolean;
  listingId?: string;
  projectForOfferId?: string;
  hasActions?: boolean;
  mobileView?: boolean;
  rejectCallback?: () => void;
  outreachType?: 'invite' | 'campaign';
}

const Brief: React.FC<Props> = (props) => {
  const {
    campaignId,
    projectId,
    isOwnership,
    hasOwnership,
    listingId,
    projectForOfferId,
    hasActions,
    rejectCallback,
    mobileView,
    outreachType,
  } = props;

  const dispatch = useDispatch();

  const handleCloseModal = rejectCallback
    ? rejectCallback
    : () => {
        dispatch(toggleModal());
      };

  const handleSetModal = (name: string, attach?: any) => {
    dispatch(setModal(name, attach));
  };

  if (listingId) {
    return (
      <ListingBrief
        listingId={listingId}
        onCloseModal={handleCloseModal}
        onSetModal={handleSetModal}
        hasActions={hasActions}
      />
    );
  }

  if (outreachType && campaignId) {
    return <CampaignOutreachContent campaignId={campaignId} outreachType={outreachType} />;
  }

  if (projectForOfferId) {
    return (
      <ProjectForOffer
        isOwnership={isOwnership}
        hasOwnership={hasOwnership}
        projectForOfferId={projectForOfferId}
      />
    );
  }

  if (projectId) {
    return <ProjectBrief projectId={projectId} />;
  }

  if (campaignId && mobileView) {
    return <MobileBrief campaignId={campaignId} />;
  }

  if (campaignId) {
    return <CampaignBrief campaignId={campaignId} />;
  }

  return null;
};

export default track({ section: 'brief_preview' })(Brief);

import compact from 'lodash/compact';

import {
  ADMIN_CAMPAIGNS_ROUTE,
  ADMIN_CREATIVES_ROUTE,
  ADMIN_ORGANIZATIONS_ROUTE,
  ADMIN_TRANSACTIONS_ROUTE,
  ADMIN_WHITHDRAWLS_ROUTE,
  TERMS_LINK,
  PLANS_ROUTE,
  USER_SETTINGS,
} from 'Constants/general';
import { refundPolicyLink } from 'Util/links';
import { amplitude } from 'Helpers/amplitude';
import { AdvertiserHeaderQuery$data, Currency } from 'GraphTypes/AdvertiserHeaderQuery.graphql';

const isProduction = process.env.DEPLOY_ENV === 'production';

const adminMenu = (
  permissions: NonNullable<AdvertiserHeaderQuery$data['currentUser']>['permissions']
) => {
  const canViewWithdrawls = permissions.includes('PAYMENT__VIEW_TRANSACTIONS');

  return compact([
    {
      id: 'admin_campaigns',
      textData: {
        textData: { msg: 'menu.admin_campaigns' },
      },
      innerLink: ADMIN_CAMPAIGNS_ROUTE,
    },
    {
      id: 'admin_creative_library',
      textData: {
        textData: { msg: 'menu.admin_creative_library' },
      },
      innerLink: ADMIN_CREATIVES_ROUTE,
    },
    {
      id: 'advertisers',
      textData: {
        textData: { msg: 'menu.advertisers' },
      },
      innerLink: ADMIN_ORGANIZATIONS_ROUTE,
    },
    {
      id: 'admin_transactions',
      textData: {
        textData: { msg: 'menu.admin_transactions' },
      },
      innerLink: ADMIN_TRANSACTIONS_ROUTE,
      callback: 'transactions',
    },
    canViewWithdrawls
      ? {
          id: 'admin_withdrawls',
          textData: {
            textData: { msg: 'menu.admin_withdrawls' },
          },
          innerLink: ADMIN_WHITHDRAWLS_ROUTE,
        }
      : null,
  ]);
};

const limitMenu = [
  {
    id: 'terms_of_use',
    textData: {
      textData: { msg: 'menu.terms_of_use' },
    },
    link: TERMS_LINK,
    callback: 'terms',
  },
  {
    id: 'logout',
    textData: {
      textData: { msg: 'menu.sign_out' },
    },
    callback: 'logout',
  },
];

type MainMenuData = {
  admin: boolean;
  currency?: Currency;
  organization: NonNullable<AdvertiserHeaderQuery$data['currentUser']>['organization'];
};

const createMainMenu = (data: MainMenuData) => {
  const { organization, admin: isAdmin } = data;

  let showBilling = false;
  let showInvite = true;

  if (organization && organization.subscription) {
    const {
      subscription: { availablePlanIds, planId, effectiveLimits },
    } = organization;

    const maxSeats = effectiveLimits?.maxSeats;
    const organizationSize = organization.members?.totalCount;
    if (typeof maxSeats === 'number' && typeof organizationSize === 'number') {
      if (organizationSize >= maxSeats) {
        showInvite = false;
      }
    }
    if (availablePlanIds && planId) {
      if (availablePlanIds.length > 0) {
        if (availablePlanIds.length === 1) {
          if (availablePlanIds[0] !== planId) {
            showBilling = true;
          }
        } else {
          showBilling = true;
        }
      }
    }
  }

  return compact([
    {
      id: 'settings',
      textData: {
        textData: { msg: 'menu.settings' },
      },
      innerLink: USER_SETTINGS,
      onClick: () => {
        amplitude.sendEvent({
          id: 215,
          category: 'advertiser_settings',
          name: 'settings_click',
        });
      },
    },
    !isAdmin && showInvite
      ? {
          id: 'invite',
          textData: {
            textData: { msg: 'header.add_teammates' },
          },
          callback: 'invite',
        }
      : null,
    showBilling
      ? {
          id: 'billing',
          textData: {
            textData: { msg: 'menu.billing' },
          },
          innerLink: PLANS_ROUTE,
          callback: 'billing',
        }
      : null,
    {
      id: 'refund',
      textData: {
        textData: { msg: 'menu.refund_policy' },
      },
      link: refundPolicyLink,
      callback: 'refund',
    },
  ]);
};

export const testingMenu = compact([
  !isProduction
    ? {
        id: 'menu.switch_lang',
        textData: {
          textData: { msg: 'menu.switch_lang' },
        },
        callback: 'lang',
      }
    : null,
]);

type Data = {
  admin: boolean;
  currency?: Currency;
  organization: NonNullable<AdvertiserHeaderQuery$data['currentUser']>['organization'];
  permissions: NonNullable<AdvertiserHeaderQuery$data['currentUser']>['permissions'];
};

export const createMenuList = (data: Data) => {
  const { admin, currency, organization, permissions } = data;

  const adminList = admin ? adminMenu(permissions) : [];
  const mainMenu = createMainMenu({ admin, organization, currency });

  return [...adminList, ...mainMenu, ...limitMenu, ...testingMenu];
};

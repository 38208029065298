import React from 'react';
import classnames from 'classnames';
import { PrimitiveType } from 'react-intl';

import styles from './Switch.css';

import modsClasses from 'Util/modsClasses.js';
import TextSymbol from 'Atoms/TextSymbol/TextSymbol';
import Icon from 'Atoms/Icon/Icon';

const MODS = ['type', 'activeColor'];

type IconProps = React.ComponentProps<typeof Icon>;

type Item = {
  id: string;
  msg?: string;
  msgValues?: Record<string, PrimitiveType>;
  text?: string;
  activeColor?: string;
  leftIconName?: IconProps['name'];
};

interface Props {
  items: Item[];
  className?: string;
  itemClassName?: string;
  currentItem?: string;
  disabled?: boolean;
  onChange?: (id: string | null) => void;
}

const Switch: React.FC<Props> = (props) => {
  const { items, disabled, onChange, className, itemClassName, currentItem } = props;

  const createItems = () => {
    return items.map((item) => {
      const { id, msg, msgValues, text, activeColor, leftIconName } = item;

      const isActive = currentItem === id;

      const onClick = !disabled
        ? () => {
            const value = isActive ? null : id;
            if (onChange) {
              onChange(value);
            }
          }
        : undefined;

      const colorStyles = activeColor ? styles[activeColor] : false;

      return (
        <div
          key={id}
          onClick={onClick}
          className={classnames(styles.item, colorStyles, itemClassName, {
            [styles.isActive]: isActive,
            [styles.disabled]: disabled,
          })}
        >
          <TextSymbol
            leftIconName={leftIconName}
            textData={{
              weight: '500',
              msg,
              text,
              values: msgValues,
              className: styles.title,
            }}
          />
        </div>
      );
    });
  };

  const rootClassList = classnames(modsClasses(MODS, props, styles), styles.root, className);

  return <div className={rootClassList}>{createItems()}</div>;
};

export default Switch;

import React, { FC } from 'react';

import styles from './TitleBase.pcss';

import { IntlTextType } from 'Types/common';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  title: IntlTextType;
  description?: IntlTextType;
  tooltip?: IntlTextType;
  className?: string;
}

const TitleBase: FC<Props> = ({ title, description, tooltip, className }) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <Text {...title} type="md" />
      <div className={styles.description}>
        {description && <Text {...description} type="label" color={'grey'} />}
        {tooltip && (
          <Tooltip
            tooltipMsg={tooltip.msg}
            tooltipText={tooltip.text}
            id={`Title base tooltip ${title.text}`}
            place="top"
          >
            <Icon size={16} name={'Info'} className={styles.tooltipIcon} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default TitleBase;

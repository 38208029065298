import React, { useMemo, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import styles from './PaidSocialLicenseListItem.pcss';

import { getCreatorData } from 'Util/creatorData';
import { createDate } from 'Util/dateCreator';
import { copyText } from 'Util/copyText';
import { amplitude } from 'Helpers/amplitude';
import Avatar from 'Atoms/Avatar/Avatar';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { PaidSocialLicenseState } from 'Types/enums';
import { PaidSocialLicenseListItem_license$key } from 'GraphTypes/PaidSocialLicenseListItem_license.graphql';

interface Props {
  index: number;
  license?: PaidSocialLicenseListItem_license$key;
  onDrawerShow: (value: string | null, projectId: string) => void;
}

const PaidSocialLicenseListItem: React.FC<Props> = (props) => {
  const { license, onDrawerShow } = props;

  const data = useFragment(
    graphql`
      fragment PaidSocialLicenseListItem_license on PaidSocialLicense {
        id
        facebookPageName
        createdAt
        expiresAt
        daysLeft
        state
        creator {
          type
          id
          username
          profile {
            profilePictureFile {
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
          projects(first: 1) {
            edges {
              node {
                id
              }
            }
          }
          ... on InstagramCreator {
            user {
              profilePictureUrl
            }
          }
          ... on TiktokCreator {
            tiktokUser: user {
              avatar {
                secureUrl
              }
              name
            }
          }
          ownership {
            owner {
              contractorProfile {
                firstName
                lastName
              }
            }
          }
        }
      }
    `,
    license
  );

  if (!data) return null;

  const { id, creator } = data;
  const firstProjectId = (creator?.projects?.edges && creator?.projects?.edges[0]?.node?.id) || '';

  const { avaSrc, username } = useMemo(() => getCreatorData(creator), [creator]);

  const contractor = creator?.ownership?.owner.contractorProfile;
  const ownerName = compact([contractor?.firstName, contractor?.lastName]).join(' ');

  const { facebookPageName, createdAt, expiresAt, daysLeft, state } = data;

  const datesInfo = useMemo(() => {
    const startDate = createDate(createdAt);
    const endDate = createDate(expiresAt);

    return startDate && endDate ? compact([startDate, endDate]).join(' - ') : null;
  }, [createdAt, expiresAt]);

  let stateInfo;

  if (
    state === PaidSocialLicenseState.EXPIRED ||
    state === PaidSocialLicenseState.FAILED_TO_EXPIRE
  ) {
    stateInfo = (
      <div className={styles.state}>
        <Icon size={16} name="Close-small" className={styles.closeIcon} />
        <Text
          msg="dashboard.advertiser.licenses_section.license_item.state.disconnected"
          type="s"
          color="sun"
          className={styles.disconnectedLabel}
        />
      </div>
    );
  } else if (daysLeft) {
    stateInfo = (
      <div className={styles.state}>
        <Icon size={16} name="Check" className={styles.closeCheck} />
        <Text
          msg="dashboard.advertiser.licenses_section.license_item.days_left"
          formatValues={daysLeft ? { daysLeft } : undefined}
          type="s"
        />
      </div>
    );
  }

  const handleMoreInfoClick = () => {
    amplitude.sendEvent({
      id: 112,
      category: 'creator_licensing_dashboard',
      name: 'more_information_click',
    });
    onDrawerShow(id, firstProjectId);
  };

  const handleUsernameCopy = useCallback(() => {
    amplitude.sendEvent({
      id: 133,
      category: 'creator_licensing_dashboard',
      name: 'ig_handle_copy',
    });
    copyText(`@${username}`);
  }, [username]);

  const handleFbPageNameCopy = useCallback(() => {
    amplitude.sendEvent({
      id: 134,
      category: 'creator_licensing_dashboard',
      name: 'creator_fb_page_copy',
    });
    copyText(facebookPageName);
  }, [facebookPageName]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Avatar size={40} src={avaSrc} />
          <Text className={styles.name} text={ownerName} type="md" />
        </div>
        <div className={styles.label}>
          <Tooltip
            id={`${id}_username`}
            tooltipMsg="dashboard.advertiser.licenses_section.license_item.copy"
            place="top"
          >
            <Text
              className={styles.username}
              text={`@${username}`}
              color="grey"
              type="md"
              handleClick={handleUsernameCopy}
            />
          </Tooltip>
        </div>
        <div className={styles.label}>
          <Tooltip
            id={`${id}_pageName`}
            tooltipMsg="dashboard.advertiser.licenses_section.license_item.copy"
            place="top"
          >
            <Text
              className={styles.pageName}
              text={facebookPageName}
              color="grey"
              type="md"
              handleClick={handleFbPageNameCopy}
            />
          </Tooltip>
        </div>
        <div className={`${styles.label} ${styles.dates}`}>
          {datesInfo && <Text text={datesInfo} color="grey" type="s" />}
          {stateInfo}
        </div>
        <div className={styles.moreInfoWrap}>
          <AlterButton icon="Info" type="white" hover={false} onClick={handleMoreInfoClick} />
        </div>
      </div>
    </div>
  );
};

export default PaidSocialLicenseListItem;

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import classnames from 'classnames';

import styles from './Select.css';
import Item from './Item';
import SingleValue from './SingleValue';

import Text from 'Atoms/Text/Text';
import colors from 'Styles/vars/colors.css';

const customStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 44,
  }),
  noOptionsMessage: (base) => ({
    ...base,
    font: "14px 'Aeroport'",
  }),
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? colors.colorBase : `${colors.colorGrey50} !important`,
    backgroundColor: colors.colorWhite,
    font: "14px 'Aeroport'",
    minHeight: '40px',
    boxShadow: state.isFocused ? `0 0 0 1px ${colors.colorBase}` : 'none !important',
    '&:hover': '',
  }),
  placeholder: (base) => ({
    ...base,
    color: colors.colorGrayDog,
    font: "14px 'Aeroport'",
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      fontFamily: "Aeroport",
      fontWeight: 500,
      backgroundColor: colors.colorConcrete,
      height: '24px',
      display: 'flex',
      alignItems: 'center',
    };
  },
  multiValueRemove: (styles) => ({
    ...styles,
    ':hover': {
      backgroundColor: 'none',
    },
  }),
};

const hideIndicatorStyle = {
  dropdownIndicator: () => ({
    display: 'none',
  }),
};

@injectIntl
class SelectItem extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  onChange(data) {
    const { isMulti, onChange } = this.props;
    if (isMulti) {
      onChange(data);
    } else {
      const { type, value } = data;
      onChange({ [type]: value });
    }
  }

  createGroupHeading({ children }) {
    return <Text
      className={styles.heading}
      size="sm"
      color="grayDog"
      transform="uppercase"
      msg={children}
    />;
  }

  render() {
    const {
      className,
      name,
      intl,
      options,
      placeholder,
      value,
      disabled,
      input,
      defaultValue,
      onInputChange = null,
      isMulti = false,
      isIndicatorHidden = false,
    } = this.props;

    const placeholderText = placeholder ? intl.formatMessage({ id: placeholder }) : null;
    const menuProps = options.length === 0 ? { menuIsOpen: false } : {};
    const indicatorStyles = isIndicatorHidden ? hideIndicatorStyle : {};

    return (
      <Select
        className={classnames(className, { [styles.disabled]: disabled })}
        name={name}
        options={options}
        value={value}
        isMulti={isMulti}
        placeholder={placeholderText}
        components={makeAnimated({ SingleValue, Option: Item, GroupHeading: this.createGroupHeading })}
        styles={{ ...customStyles, ...indicatorStyles }}
        onChange={this.onChange}
        onInputChange={onInputChange}
        isDisabled={disabled}
        defaultValue={defaultValue}
        {...menuProps}
        {...input}
        onBlur={null}
      />
    );
  }
}

export default SelectItem;

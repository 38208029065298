import React from 'react';

import styles from './Group.css';

import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import TextButton from 'Atoms/TextButton/TextButton';

const typeData = {
  audience: {
    iconName: 'audience',
    label: 'search_section.form.group_labels.audience',
  },
  creator: {
    iconName: 'creator',
    label: 'search_section.form.group_labels.creator',
  },
};

const Group = (props) => {
  const { className, children, group, toggleList, currentItem, onChangeCurrentItem } = props;
  const { iconName, label } = typeData[group];

  const createToogleList = () => {
    return (
      <div className={styles.swicth}>
        {toggleList.map((item) => {
          const active = currentItem === item;
          const onClick = () => {
            onChangeCurrentItem(item);
          };

          const msg = `search_section.form.toggle.${item}`;

          return (
            <TextButton
              key={item}
              disabled={active}
              mainColor={active ? 'grayDog' : undefined}
              msg={msg}
              onClick={onClick}
              className={styles.btn}
            />
          );
        })}
      </div>
    );
  };

  const withToggle = toggleList ? styles.withToggle : '';

  return (
    <div className={`${className} ${styles.root} ${withToggle}`}>
      <header className={styles.header}>
        <div className={styles.labelWrap}>
          <Icon name={iconName} color="black" />
          <Text weight="500" msg={label} className={styles.label} />
        </div>
        {toggleList && toggleList.length > 1 && createToogleList()}
      </header>
      <div>{children}</div>
    </div>
  );
};

export default Group;

import React from 'react';
import cn from 'classnames';

import styles from './DropDownMenu.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
  autoHeight?: boolean;
}

const DropDownMenu: React.FC<Props> = (props) => {
  const { autoHeight, children, className } = props;

  return (
    <div className={cn(className, styles.root, { [styles.autoHeight]: autoHeight })}>
      {children}
    </div>
  );
};

export default DropDownMenu;

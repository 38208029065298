/**
 * @generated SignedSource<<a02452203b72ec3bc9f60dc26a235ba3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompleteCampaignInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
};
export type CompleteCampaignMutation$variables = {
  input: CompleteCampaignInput;
};
export type CompleteCampaignMutation$data = {
  readonly completeCampaign: {
    readonly campaignCompletion: {
      readonly campaign: {
        readonly canBeCompleted: boolean | null | undefined;
        readonly completion: {
          readonly id: string;
        } | null | undefined;
      };
    };
  } | null | undefined;
};
export type CompleteCampaignMutation = {
  response: CompleteCampaignMutation$data;
  variables: CompleteCampaignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CampaignCompletion",
  "kind": "LinkedField",
  "name": "completion",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeCompleted",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteCampaignPayload",
        "kind": "LinkedField",
        "name": "completeCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignCompletion",
            "kind": "LinkedField",
            "name": "campaignCompletion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompleteCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteCampaignPayload",
        "kind": "LinkedField",
        "name": "completeCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignCompletion",
            "kind": "LinkedField",
            "name": "campaignCompletion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd2f4ff8586faa97b6bee935cc243b01",
    "id": null,
    "metadata": {},
    "name": "CompleteCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteCampaignMutation(\n  $input: CompleteCampaignInput!\n) {\n  completeCampaign(input: $input) {\n    campaignCompletion {\n      campaign {\n        completion {\n          id\n        }\n        canBeCompleted\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "12d7fb7397d30d84c942921ba4bd9583";

export default node;

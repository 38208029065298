import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';

import styles from '../ShopifyOrderCreationDrawer.pcss';

import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Text from 'Components/ui/Text/Text';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

type Props = {
  className?: string;
  onOkButtonClick?: () => void;
};

const OrderCreated: React.FC<Props> = (props) => {
  const { className, onOkButtonClick } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const handleOkButtonClick = useCallback(() => {
    closeDrawer('shopify-order-creation');
    onOkButtonClick?.();
  }, [onOkButtonClick]);

  return (
    <div className={classnames(styles.contentRoot, styles.confirmOrderContentRoot, className)}>
      <TextPreset
        header={<Text type="d2" msg="shopify_order_creation_drawer.order_created.title" />}
      />
      <div className={classnames(styles.content, styles.orderCreatedContent)}>
        <div className={styles.textWrapper}>
          <Text type="md" msg="shopify_order_creation_drawer.order_created.message" />
        </div>
      </div>
      <ButtonPreset className={styles.buttonWrapper}>
        <div className={styles.clicker} onClick={handleOkButtonClick}>
          <Button
            fluid
            color="black"
            dataTest="button:orderCreation:ok"
            className={styles.nextButton}
            msg="shopify_order_creation_drawer.ok_button"
          />
        </div>
      </ButtonPreset>
    </div>
  );
};

export { OrderCreated };

// types

import React, { useContext } from 'react';

import Button from 'Atoms/Button/Button';
import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  projectId: string;
  balance?: number;
}

const ReimbursementPayment: React.FC<Props> = (props) => {
  const { projectId } = props;
  const { openDrawer } = useContext(DrawerContext);

  const handleReimbursementPaymentClick = () => {
    amplitude.sendEvent<311>({
      id: '311',
      category: 'chat',
      name: 'product_reimbursement_click',
      param: { project_id: projectId },
    });
    openDrawer(`project-reimburse-${projectId}`);
  };

  return (
    <Button
      color="normal"
      msg="chat.project.make_reimbursement_payment"
      onClick={handleReimbursementPaymentClick}
    />
  );
};

export default ReimbursementPayment;

import React, { useEffect } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import Skeleton from '../../../components/Skeleton/Skeleton';

import styles from './ShipmentList.pcss';

import Shipment from 'AdvertiserPage/Creators/tabs/Shipment/Shipments/Shipment/Shipment';
import Text from 'Components/ui/Text/Text';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { ConnectionStatusType } from 'AdvertiserPage/Creators/tabs/Shipment/ShipmentTab';
import { ShipmentList_campaign$data } from 'GraphTypes/ShipmentList_campaign.graphql';
import { ShopifyOrderState } from 'GraphTypes/Shipment_project.graphql';

const ITEMS_COUNT = 30;
const FOOTER_OFFSET = 279;

interface Props {
  relay: RelayPaginationProp;
  campaignId?: string;
  orderState?: ShopifyOrderState | '';
  withShippingAddress?: boolean;
  connectionStatus: ConnectionStatusType;
  campaign: ShipmentList_campaign$data;
  selectedProjectIds: Record<string, boolean>;
  handleSelectProject: (projectId: string) => void;
  setAllProjectIds: (projectIds: string[]) => void;
  handleCreateOrder: (projectId: string) => void;
  isAdmin: boolean;
}

const ShipmentList: React.FC<Props> = (props) => {
  const {
    connectionStatus,
    relay,
    campaignId,
    selectedProjectIds,
    handleSelectProject,
    campaign,
    setAllProjectIds,
    handleCreateOrder,
    isAdmin,
  } = props;

  const projects: ProjectEdges = campaign?.projects?.edges || [];
  if (connectionStatus === 'NotConnected') {
    return (
      <div className={styles.emptyWrap}>
        <Text msg="shipment.no_connection" type="md" />
      </div>
    );
  }
  if (!projects?.length) {
    return (
      <div className={styles.emptyWrap}>
        <Text msg="shipment.empty_list" type="md" />
      </div>
    );
  }
  useEffect(() => {
    const projectsReadyToOrder = projects?.filter(
      (item) => item?.node?.shopifyOrder === null || item?.node?.shopifyOrder?.state !== 'SUCCESS'
    ) as ProjectEdgesWithNonNullableNodes[];
    const projectIds = projectsReadyToOrder.map((item) => item?.node?.id);
    setAllProjectIds(projectIds);
  }, [projects]);

  const list = projects.map((item) => {
    if (!item?.node) return null;
    return (
      <Shipment
        project={item.node}
        campaignId={campaignId}
        disabledShipment={connectionStatus === 'ConnectionLost'}
        key={item?.node?.id}
        selected={selectedProjectIds[item?.node?.id]}
        onSelectProject={handleSelectProject}
        onCreateOrder={handleCreateOrder}
        isAdmin={isAdmin}
      />
    );
  });
  return (
    <>
      <LoaderHandler
        relay={relay}
        count={ITEMS_COUNT}
        offset={FOOTER_OFFSET}
        preloader={<Skeleton elementClassname={styles.elementSkeleton} />}
        containerId={'shipment-order-list'}
      >
        <div className={styles.list} id={'shipment-order-list'}>
          {list}
        </div>
      </LoaderHandler>
    </>
  );
};

export default createPaginationContainer(
  ShipmentList,
  {
    campaign: graphql`
      fragment ShipmentList_campaign on Campaign
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 30 }
        cursor: { type: "String" }
        textQuery: { type: "String" }
        orderState: { type: "ShopifyOrderState" }
        withoutShopifyOrder: { type: "Boolean" }
      ) {
        projects(
          first: $count
          after: $cursor
          withShippingAddress: true
          textQuery: $textQuery
          shopifyOrderState: $orderState
          withoutShopifyOrder: $withoutShopifyOrder
        ) @connection(key: "Campaign_projects", filters: []) {
          edges {
            node {
              id
              creator {
                id
              }
              ...CreatorChatDrawers_project
              ...Shipment_project
              shopifyOrder {
                state
                id
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.campaign?.projects;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
      };
    },
    query: graphql`
      query ShipmentListPaginationQuery(
        $count: Int!
        $cursor: String
        $campaignId: ID!
        $textQuery: String
        $orderState: ShopifyOrderState
        $withoutShopifyOrder: Boolean
      ) {
        campaign(id: $campaignId) {
          ...ShipmentList_campaign
            @arguments(
              count: $count
              cursor: $cursor
              textQuery: $textQuery
              orderState: $orderState
              withoutShopifyOrder: $withoutShopifyOrder
            )
        }
      }
    `,
  }
);

// types

type Projects = NonNullable<ShipmentList_campaign$data['projects']>;
type ProjectEdges = NonNullable<Projects['edges']>;
export type ProjectEdgesWithNonNullableNodes = NonNullable<ProjectEdges[0]> & {
  node: NonNullable<NonNullable<ProjectEdges[0]>['node']>;
};

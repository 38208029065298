/**
 * @generated SignedSource<<17ecd17bd806b95ce79a67df000ecb06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContractorProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "NEW" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "REJECTED" | "UNSENT" | "%future added value";
export type DialogQuery$variables = {
  projectId: string;
};
export type DialogQuery$data = {
  readonly project: {
    readonly archivedByAdvertiser: {
      readonly id: string;
    } | null | undefined;
    readonly archivedByContractor: {
      readonly id: string;
    } | null | undefined;
    readonly campaign: {
      readonly id: string;
      readonly name: string | null | undefined;
      readonly organization: {
        readonly id: string;
      } | null | undefined;
    };
    readonly completion: {
      readonly id: string;
    } | null | undefined;
    readonly contractorStage: ContractorProjectStage | null | undefined;
    readonly deadline: {
      readonly draftDate: any | null | undefined;
      readonly publicationDate: any | null | undefined;
    } | null | undefined;
    readonly launch: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"MobileBloggerInfo_project">;
  } | null | undefined;
};
export type DialogQuery = {
  response: DialogQuery$data;
  variables: DialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractorStage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectLaunch",
  "kind": "LinkedField",
  "name": "launch",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectCompletion",
  "kind": "LinkedField",
  "name": "completion",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": "archivedByContractor",
  "args": [
    {
      "kind": "Literal",
      "name": "side",
      "value": "CONTRACTOR"
    }
  ],
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": "archivation(side:\"CONTRACTOR\")"
},
v10 = {
  "alias": "archivedByAdvertiser",
  "args": [
    {
      "kind": "Literal",
      "name": "side",
      "value": "ADVERTISER"
    }
  ],
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": "archivation(side:\"ADVERTISER\")"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draftDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Deadline",
            "kind": "LinkedField",
            "name": "deadline",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MobileBloggerInfo_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brief",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priceCap",
                        "storageKey": null
                      }
                    ],
                    "type": "V2Brief",
                    "abstractKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Deadline",
            "kind": "LinkedField",
            "name": "deadline",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstagramUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureUrl",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "InstagramCreator",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "YoutubeChannel",
                    "kind": "LinkedField",
                    "name": "channel",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailUrl",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "YoutubeCreator",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatarUrl",
                    "storageKey": null
                  }
                ],
                "type": "ContentCreator",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TiktokUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "secureUrl",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "TiktokCreator",
                "abstractKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10139c2a2436eb4eb96d15a1430b1dde",
    "id": null,
    "metadata": {},
    "name": "DialogQuery",
    "operationKind": "query",
    "text": "query DialogQuery(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    contractorStage\n    campaign {\n      id\n      name\n      organization {\n        id\n      }\n    }\n    launch {\n      id\n    }\n    completion {\n      id\n    }\n    archivedByContractor: archivation(side: CONTRACTOR) {\n      id\n    }\n    archivedByAdvertiser: archivation(side: ADVERTISER) {\n      id\n    }\n    deadline {\n      draftDate\n      publicationDate\n      id\n    }\n    ...MobileBloggerInfo_project\n    id\n  }\n}\n\nfragment MobileBloggerInfo_project on Project {\n  id\n  currency\n  price\n  launch {\n    id\n  }\n  creator {\n    __typename\n    id\n    username\n    ... on InstagramCreator {\n      user {\n        profilePictureUrl\n        id\n      }\n    }\n    ... on YoutubeCreator {\n      channel {\n        name\n        thumbnailUrl\n        id\n      }\n    }\n    ... on ContentCreator {\n      avatarUrl\n    }\n    ... on TiktokCreator {\n      user {\n        name\n        avatar {\n          __typename\n          secureUrl\n          id\n        }\n        id\n      }\n    }\n    type\n  }\n  campaign {\n    id\n    paymentType\n    brief {\n      __typename\n      ... on V2Brief {\n        priceCap\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b3b26f62cc72456582a4b877e728566";

export default node;

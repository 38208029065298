import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import environment from 'Api/Environment';

const HashtagListByQueryQuery = graphql`
  query HashtagListByQueryQuery (
      $textQuery: String!
    ){
    instagramHashtags(textQuery: $textQuery) {
      name
    }
  }
`;


class HashtagListByQuery extends PureComponent {
  render() {
    const { textQuery, setValues } = this.props;

    return (
      <QueryRenderer
        environment={environment}
        query={HashtagListByQueryQuery}
        variables={{ textQuery }}
        render={({ props }) => {
          if (props) {
            setValues(props.instagramHashtags);
          }

          return null;
        }}
      />
    );
  }
}

export default HashtagListByQuery;
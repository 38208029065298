import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  RejectPriceChangeRequestInput,
  RejectPriceChangeRequestMutation,
  RejectPriceChangeRequestMutation$data,
} from 'GraphTypes/RejectPriceChangeRequestMutation.graphql';

const mutation = graphql`
  mutation RejectPriceChangeRequestMutation($input: RejectPriceChangeRequestInput!) {
    rejectPriceChangeRequest(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

interface DataInput {
  projectId?: string;
}

export default (
  data: RejectPriceChangeRequestInput & DataInput,
  resolve?: (response: RejectPriceChangeRequestMutation$data) => void,
  reject?: fnType
) => {
  const { priceChangeRequestId } = data;

  const id = uuid();
  const variables = {
    input: {
      id,
      priceChangeRequestId,
    },
  };
  commitMutation<RejectPriceChangeRequestMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      // console.log(store);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useEffect, useMemo, useState, useRef, useContext } from 'react';
import classnames from 'classnames';
import {
  graphql,
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { RefetchFnDynamic } from 'react-relay/relay-hooks/useRefetchableFragmentNode';

import styles from './ProductList.pcss';
import type {
  Product,
  ProductVariant,
  ProductVariantsVisibilityState,
  ProductKey,
  VariantKey,
} from './types';
import {
  hasProductVariants,
  flattenSelectedProductVariants,
  initProductVariantsVisibilityState,
  getSelectedProductVariantsSelectionCount,
  getProductsWithChangedProductVariantSelection,
} from './utils';
import { Products } from './types';

import { GuideTourContext } from 'Containers/GuideTour';
import { Checkbox } from 'Components/ui/Checkbox';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { formatCurrency } from 'Util/numberFormatter';
import IconToggler from 'Components/IconToggler/IconToggler';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { ProductList_query$data } from 'GraphTypes/ProductList_query.graphql';
import { OnOrderLostConnectionError } from 'Modal/advertiser/ShopifyOrderCreation/ShopifyOrderCreationDrawer';
import { ProductListPaginationQuery } from 'GraphTypes/ProductListPaginationQuery.graphql';
import { ProductListQuery } from 'GraphTypes/ProductListQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';

export const ShopifyProductPaginationQuery = graphql`
  fragment ProductList_query on Query
  @argumentDefinitions(
    organizationId: { type: "ID!" }
    campaignId: { type: "ID!" }
    textQuery: { type: "String" }
    first: { type: "Int", defaultValue: 50 }
    after: { type: "String" }
  )
  @refetchable(queryName: "ProductListPaginationQuery") {
    campaign(id: $campaignId) {
      shopifyAuthorization {
        shop {
          currencyCode
          products(organizationId: $organizationId, textQuery: $textQuery) {
            ... on Shopify_ProductsPayload {
              __typename
              products(first: $first, after: $after) @connection(key: "ProductList_products") {
                pageInfo {
                  hasNextPage
                }
                edges {
                  node {
                    id
                    title
                    featuredImageUrl
                    variants {
                      id
                      title
                      price
                      imageUrl
                      displayName
                      availableForSale
                      inventoryQuantity
                    }
                  }
                }
                totalCount
              }
            }
            ... on Shopify_InvalidAuthorizationError {
              __typename
              message
            }
            ... on ValidationError {
              __typename
              message
            }
          }
        }
      }
    }
  }
`;

export const ShopifyProductsQuery = graphql`
  query ProductListQuery(
    $organizationId: ID!
    $campaignId: ID!
    $textQuery: String
    $first: Int
    $after: String
  ) {
    ...ProductList_query
      @arguments(
        organizationId: $organizationId
        campaignId: $campaignId
        textQuery: $textQuery
        first: $first
        after: $after
      )
  }
`;

type Props = {
  className?: string;
  products?: Products;
  selectedProducts: Products;
  hideSearch?: boolean;
  hideProducts?: boolean;
  onProductVariantSelectionChange?: (args: {
    productVariants: ProductVariant[];
    products: Products;
  }) => void;
  organizationId: string;
  campaignId: string;
  textQuery: string;
  queryReference: PreloadedQuery<ProductListQuery, Record<string, unknown>>;
  onOrderLostConnectionError?: OnOrderLostConnectionError;
  projectsCount: number;
  setCurrencyCode: (code: string) => void;
  setClientWidth: (width: number) => void;
  rootRef: React.RefObject<HTMLDivElement>;
  scrollRef: React.RefObject<HTMLDivElement>;
};

const ProductListWrap: React.FC<Omit<Props, 'queryReference'>> = (props) => {
  const { textQuery, organizationId, campaignId } = props;
  const controller = useRef<AbortController>();

  const [queryReference, loadQuery] = useQueryLoader<ProductListQuery>(ShopifyProductsQuery);

  useEffect(() => {
    loadQuery({
      textQuery,
      organizationId,
      campaignId,
    });
  }, [textQuery, organizationId, campaignId, controller?.current]);

  const clearAbortController = () => {
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = new AbortController();
  };

  // useEffect(() => {
  //   clearAbortController();
  // }, [textQuery]);

  if (!queryReference) {
    return null;
  }
  return <ProductList {...props} queryReference={queryReference} />;
};

const ProductList: React.FC<Omit<Props, 'setTextQuery'>> = (props) => {
  const {
    hideSearch,
    hideProducts,
    onProductVariantSelectionChange,
    queryReference,
    onOrderLostConnectionError,
    selectedProducts,
    projectsCount,
    setCurrencyCode,
    setClientWidth,
    rootRef,
    textQuery,
    scrollRef,
  } = props;

  const { goToNextStep } = useContext(GuideTourContext);

  const queryData = usePreloadedQuery<ProductListQuery>(ShopifyProductsQuery, queryReference);

  const {
    data: productListResponse,
    loadNext,
    isLoadingNext,
    hasNext,
  }: // refetch,
  {
    data: ProductList_query$data;
    isLoadingNext: boolean;
    loadNext: (next: number) => void;
    hasNext: boolean;
    refetch: RefetchFnDynamic<
      ProductListQuery,
      // @ts-expect-error: No type generation for fragment response
      _
    >;
  } = usePaginationFragment<
    ProductListPaginationQuery,
    // @ts-expect-error: No type generation for fragment response
    _
  >(ShopifyProductPaginationQuery, queryData);

  const currencyCode = productListResponse?.campaign?.shopifyAuthorization?.shop.currencyCode || '';

  useEffect(() => {
    setCurrencyCode(currencyCode);
  }, [currencyCode]);

  // useEffect(() => {
  //   refetch({ textQuery });
  // }, [textQuery]);

  useEffect(() => {
    if (!scrollRef?.current) {
      return;
    }
    const containerEl = scrollRef.current;
    const scrollHandler = (e: Event) => {
      const target = e?.target as HTMLDivElement;
      if (!target) {
        return;
      }
      if (target.scrollHeight - target.offsetHeight - target.scrollTop < 10) {
        if (!isLoadingNext && hasNext) {
          loadNext(50);
        }
      }
    };
    if (containerEl) {
      containerEl.addEventListener('scroll', scrollHandler);
    }
    return () => {
      containerEl?.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollRef?.current, isLoadingNext, loadNext, queryData, hasNext]);

  const products = useMemo<NonNullable<Products>>(() => {
    const shop = productListResponse?.campaign?.shopifyAuthorization?.shop;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const products = shop?.products?.products?.edges?.map((productEdge) => productEdge?.node);

    if (products && Array.isArray(products)) {
      return products.map((product) => ({
        id: product.id,
        title: product.title,
        thumbnailUrl: product.featuredImageUrl,
        hideVariantSelectionCount: false,
        variants: [...product.variants].map((variant) => {
          const hasPrevCheck = !!selectedProducts
            .find((checkingProduct) => checkingProduct.id === product.id)
            ?.variants?.find((checkingVariant) => checkingVariant.id === variant.id)?.checked;
          return {
            id: variant.id,
            checked: hasPrevCheck,
            title: variant.title || product.title,
            price: typeof variant.price === 'string' ? parseFloat(variant.price) : variant.price,
            availableQuantity: variant.inventoryQuantity >= 0 ? variant.inventoryQuantity : 0,
            thumbnailUrl: variant.imageUrl || product?.thumbnailUrl,
            selectedQuantity: hasPrevCheck ? 1 : 0,
            currency: currencyCode,
            notEnough: variant.inventoryQuantity < projectsCount,
            subtitle: variant.title || product?.title,
            disabled:
              !variant.availableForSale ||
              !variant.inventoryQuantity ||
              variant.inventoryQuantity <= 0 ||
              variant.inventoryQuantity < projectsCount,
          };
        }),
      }));
    }
    return [];
  }, [productListResponse, currencyCode, selectedProducts]);

  useEffect(() => {
    if (
      productListResponse.campaign?.shopifyAuthorization?.shop.products?.__typename ===
        'Shopify_ProductsPayload' &&
      productListResponse.campaign?.shopifyAuthorization?.shop.products?.products?.pageInfo
        ?.hasNextPage
    ) {
      goToNextStep('productsOver50');
    }
  }, [productListResponse]);

  useEffect(() => {
    const typeName = productListResponse?.campaign?.shopifyAuthorization?.shop.products?.__typename;
    const hasError =
      typeName === 'ValidationError' || typeName === 'Shopify_InvalidAuthorizationError';
    if (hasError && onOrderLostConnectionError && productListResponse) {
      onOrderLostConnectionError(productListResponse);
    }
  }, [productListResponse]);

  const [productVariantsVisibility, setProductVariantsVisibility] =
    useState<ProductVariantsVisibilityState>(initProductVariantsVisibilityState(products));

  useEffect(() => {
    const state = initProductVariantsVisibilityState(products);
    setProductVariantsVisibility({ ...state, ...productVariantsVisibility });
  }, [products]);

  useEffect(() => {
    if (rootRef.current && rootRef.current.clientWidth > 0) {
      setClientWidth(rootRef.current.clientWidth);
    }
  }, [rootRef?.current?.clientWidth, productVariantsVisibility]);

  const toggleProductVariantVisibility: ToggleProductVariantVisibility = (index) => {
    setProductVariantsVisibility((prev) => ({
      ...prev,
      [index]: !productVariantsVisibility[index],
    }));
  };

  const invokeOnProductVariantSelectionChangeEvent: InvokeOnProductVariantSelectionChangeEvent =
    async (products) => {
      const productVariants = flattenSelectedProductVariants(products);
      onProductVariantSelectionChange?.({ productVariants, products });
    };

  const getArrowIcon: GetArrowIcon = (product, isOpen, hideVariants) => {
    const hasVariants = hasProductVariants(product);
    return (
      <IconToggler
        kind="arrow-small-up-down"
        active={isOpen}
        className={classnames(styles.arrowIcon, { [styles.hidden]: !hasVariants || hideVariants })}
      />
    );
  };

  const handleCheckboxValueChange: HandleCheckboxValueChange = (args) => {
    const { checked, products, productKey, variantKey } = args;
    const result = getProductsWithChangedProductVariantSelection(
      products,
      productKey,
      variantKey,
      checked,
      checked ? 1 : 0
    );
    invokeOnProductVariantSelectionChangeEvent(result);
  };

  return (
    <>
      {!products.length && (
        <Text
          type="md"
          msg="shopify_order_creation_drawer.select_product.nothing_found"
          className={styles.noResultsMessage}
        />
      )}
      {Boolean(products.length) && !hideProducts && (
        <ul className={classnames(styles.productList, { [styles.withSearch]: !hideSearch })}>
          {products.map((product, productKey) => {
            const productVariantsSelectionCount = getSelectedProductVariantsSelectionCount(
              products,
              productKey
            );

            return (
              <li key={productKey}>
                <div className={styles.productCard}>
                  <button
                    className={classnames(styles.productCardButton, {
                      [styles.cursorDefault]: !hasProductVariants(product) || product.hideVariants,
                      [styles.threeColumns]: product.hideVariantSelectionCount,
                    })}
                    onClick={() => toggleProductVariantVisibility(productKey)}
                  >
                    <div className={styles.productImg}>
                      {product.thumbnailUrl ? (
                        <img src={product.thumbnailUrl} alt={product.title} />
                      ) : null}
                      <Icon name="Photo" size={24} className={styles.noImageIcon} />
                    </div>
                    <Text type="h2" text={product.title} className={styles.productTitle} />
                    {!product.hideVariantSelectionCount && (
                      <Text
                        type="h2"
                        text={productVariantsSelectionCount.toString()}
                        className={classnames(styles.productVariantsSelectionCount, {
                          [styles.hidden]: productVariantsSelectionCount === 0,
                        })}
                      />
                    )}
                    {getArrowIcon(
                      product,
                      productVariantsVisibility[productKey],
                      Boolean(product.hideVariants)
                    )}
                  </button>
                  {!product.hideVariants &&
                    hasProductVariants(product) &&
                    productVariantsVisibility[productKey] && (
                      <ul className={styles.variantsList}>
                        {product.variants?.map((productVariant, variantKey) => (
                          <li key={variantKey}>
                            <div className={styles.checkboxWrapper}>
                              <Tooltip
                                id={productVariant.notEnough ? 'shopify_not_enough_products' : ''}
                                tooltipMsg={'shipment.create_order.not_enough.tooltip'}
                                place={'top'}
                                tooltipClassName={styles.tooltip}
                                className={styles.tooltipContainer}
                              >
                                <Checkbox
                                  className={classnames(styles.checkbox, {
                                    [styles.disabled]: Boolean(productVariant.disabled),
                                  })}
                                  disableRootPadding
                                  checked={Boolean(productVariant.checked)}
                                  disabled={Boolean(productVariant.disabled)}
                                  onChange={(e) => {
                                    handleCheckboxValueChange({
                                      checked: e.target.checked,
                                      products,
                                      productKey,
                                      variantKey,
                                    });
                                  }}
                                />
                              </Tooltip>
                            </div>
                            <Text
                              type="md"
                              text={productVariant.title}
                              className={classnames(styles.variantTitle, {
                                [styles.disabled]: Boolean(productVariant.disabled),
                              })}
                            />
                            <Text
                              type="md"
                              msg="product_list.availability"
                              formatValues={{ quantity: productVariant.availableQuantity }}
                              className={classnames(styles.variantAvailability, {
                                [styles.disabled]: Boolean(productVariant.disabled),
                              })}
                            />
                            <Text
                              type="md"
                              text={formatCurrency(productVariant.price, productVariant.currency)}
                              className={classnames(styles.variantPrice, {
                                [styles.disabled]: Boolean(productVariant.disabled),
                              })}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {isLoadingNext && (
        <div className={styles.spinnerContainer}>
          <Spinner className={styles.spinner} />
        </div>
      )}
    </>
  );
};

export default ProductListWrap;

// types

type ToggleProductVariantVisibility = (index: number) => void;
type GetArrowIcon = (product: Product, isOpen: boolean, hideVariants: boolean) => React.ReactNode;
type InvokeOnProductVariantSelectionChangeEvent = (products: Products) => void;
type HandleCheckboxValueChange = (args: {
  checked: boolean;
  products: Products;
  productKey: ProductKey;
  variantKey: VariantKey;
}) => void;

export { Props as ProductListProps };

import React, { useContext, useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { BriefContext } from '../../../Brief.Context';
import SegmentedControls from '../components/SegmentedControls/SegmentedControls';
import Section from '../components/Section/Section';
import Field from '../components/Field/Field';
import With from '../components/With/With';

import styles from './ProductDetails.pcss';
import ProductImage from './ProductImage/ProductImage';
import { getDeliveryTypesList, checkValidation, getViewConditions } from './util';

import { lightWebsiteUrl } from 'Util/validate';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import { amplitude } from 'Helpers/amplitude';
import Input from 'Components/ui/Input/Input';
import { ProductDetails_campaign$key } from 'GraphTypes/ProductDetails_campaign.graphql';

interface Props {
  campaign: ProductDetails_campaign$key;
}

const ProductDetails: React.FC<Props> = (props) => {
  const { campaign } = props;

  const data = useFragment(
    graphql`
      fragment ProductDetails_campaign on Campaign {
        id
        type
        cover {
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
        brief {
          id
          submitted
          __typename
          ... on V2Brief {
            productUrl
            productDelivery
            productTitle
            productDescription
            includeReferralFee
            productPrice
          }
        }
      }
    `,
    campaign
  );
  const [briefState, briefDispatch] = useContext(BriefContext);
  const [productTitleDefaultValue, setProductTitleDefaultValue] = useState<
    string | null | undefined
  >('');

  const { id: campaignId, cover, type, brief } = data;
  const coverUrl = cover?.transformations?.collageThumbnailUrl;
  const deliveryTypesList = getDeliveryTypesList({ campaignId, campaignType: type });
  const viewConditions = getViewConditions({ campaign: data });
  const {
    id: briefId,
    productUrl,
    productTitle,
    productDelivery,
    productDescription,
    includeReferralFee,
    productPrice,
  } = brief || {};

  const handleBriefChange = (data: any) => {
    if (data.productUrl) {
      amplitude.sendEvent({
        id: '75',
        category: 'brief',
        name: 'product_url_fill',
        param: { campaignId },
      });
    }
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateBrief({ id: briefId, ...data });
  };

  const handleProductPriceChanged = (event) => {
    const newValue = event?.target?.value;
    if (newValue.length === 0) {
      handleBriefChange({ productPrice: null });
    } else {
      handleBriefChange({ productPrice: +newValue });
    }
  };

  useEffect(() => {
    briefDispatch({ key: 'productInfoFilled', value: checkValidation(data) });
  }, [data]);

  const productDescriptionShort =
    productDescription && productDescription?.length > 50
      ? `${productDescription.slice(0, 47)}...`
      : productDescription;

  const productTitleInitialValue =
    !productTitle && productDescription ? productDescriptionShort : '';

  // setting Product title value as first 50 chars of Product description value
  // in case if title is not filled initially but description is filled (e.g. while duplicating)
  useEffect(() => {
    if (!productTitle && productDescription) {
      handleBriefChange({ productTitle: productTitleInitialValue });
      setProductTitleDefaultValue(productTitleInitialValue);
    }
  }, []);

  useEffect(() => {
    if (productTitle) {
      setProductTitleDefaultValue(productTitle);
    }
  }, [productTitle]);

  if (!brief || !briefState.campaignInfoFilled) return null;

  const productUrlInvalid = productUrl && !lightWebsiteUrl(productUrl);

  let productPriceDescr = 'brief_template.field.product.price.descr';

  if (type === 'PRODUCT_SEEDING') {
    productPriceDescr = 'brief_template.field.product.price.product_seeding.descr';
  } else if (includeReferralFee) {
    productPriceDescr = 'brief_template.field.product.price.comission.descr';
  }

  const productPriceMandatory = type === 'PRODUCT_SEEDING' || includeReferralFee;

  return (
    <Section titleMsg="brief_template.section.product">
      <ProductImage campaignId={campaignId} coverUrl={coverUrl} />
      <With condition={viewConditions.delivery}>
        <Field
          title="brief_template.field.product.delivery.title"
          description="brief_template.field.product.delivery.descr"
        >
          <SegmentedControls
            id="productDelivery"
            currentValue={productDelivery}
            items={deliveryTypesList}
            onChange={handleBriefChange}
          />
        </Field>
      </With>

      <With condition={viewConditions.productName}>
        <Field
          element="input"
          name="productTitle"
          title="brief_template.field.product.name.title"
          description="brief_template.field.product.name.descr"
          elementData={{
            defaultValue: productTitleDefaultValue,
            placeholderMsg: 'brief_template.field.product.name.placeholder',
            onBlur: handleBriefChange,
            dataTest: 'input:productTitle',
            maxlength: 50,
          }}
        />
      </With>

      <With condition={viewConditions.description}>
        <Field
          element="input"
          name="productDescription"
          title={'brief_template.field.product.description.title'}
          description={'brief_template.field.product.description.descr'}
          elementData={{
            multiple: true,
            defaultValue: productDescription,
            placeholderMsg: 'brief_template.field.product.description.placeholder',
            onBlur: handleBriefChange,
            textAreaData: { minRows: 1, maxRows: 5 },
            dataTest: 'input:describeProduct',
          }}
        />
      </With>
      <With condition={viewConditions.productUrl}>
        <Field
          element="input"
          name="productUrl"
          title="brief_template.field.product.url.title"
          description="brief_template.field.product.url.descr"
          elementData={{
            defaultValue: productUrl,
            onBlur: handleBriefChange,
            placeholderMsg: 'brief_template.field.product.url.placeholder',
            dataTest: 'input:productUrl',
          }}
          error={productUrlInvalid}
          errorData={{ msg: 'form.error.website' }}
        />
      </With>
      <With condition={viewConditions.productPrice}>
        <Field
          title="brief_template.field.product.price.title"
          description={productPriceDescr}
          name="referralFeeRate"
          isOptional={!productPriceMandatory}
        >
          <Input
            bordered
            value={productPrice || undefined}
            className={styles.productPriceInput}
            onBlur={handleProductPriceChanged}
            type="number"
            rightSymbol="$"
          />
        </Field>
      </With>
    </Section>
  );
};

export default ProductDetails;

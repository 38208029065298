import { CampaignPlatform } from 'GraphTypes/CampaignInfo_campaign.graphql';
import { OutreachTransport } from 'GraphTypes/ImportOutreachBatchQuery.graphql';
import { ParseResult } from 'papaparse';
import { createContext } from 'react';

type InviteOutreachCreatorsContextType = {
  fileName: string;
  fileSize: number;
  fileError: string;
  locale: string;
  rows: InviteOutreachImportCsvDataRow[];
  templateData: InviteOutreachTemplateData;
  transport: OutreachTransport;
  senderName: string;
  senderEmail: string;
  batchId: string;
  error: boolean;
  templatePreview: string;
  handleTemplateClose: () => void;
  handleFileReset: () => void;
  handleListChange: (files: FileList | null) => void;
  handleParseComplete: (result: ParseResult<string>) => void;
  handleImportOutreachBatch: (campaignId: string, platform?: CampaignPlatform | null) => void;
  isFormValid: boolean;
  importOutreachBatchLoading: boolean;
  processing: boolean;
  showRowErrors: boolean;
  rowMetrics?: InviteOutreachImportCsvDataRowsMetrics;
  rowErrors?: InviteOutreachImportCsvDataErrorRow[];
  setTemplateData: (data: InviteOutreachTemplateData) => void;
  setTemplatePreview: (html: string) => void;
  setTransportProvider: (provider: OutreachTransport) => void;
  setSenderName: (name: string) => void;
  setSenderActiveAuth: (authId: string) => void;
  setSenderEmail: (email: string) => void;
  setError: (error: boolean) => void;
  setShowRowErrors: (error: boolean) => void;
};

export type InviteOutreachTemplateData = {
  id: string;
  name: string;
};

export type InviteOutreachImportCsvDataRow = {
  username: string;
  email: string;
};

export type InviteOutreachImportCsvDataErrorRow = {
  index: number;
  username?: string;
  email?: string;
};

export type InviteOutreachImportCsvDataRowsMetrics = {
  totalCount: number;
  parsedCount: number;
};

const InviteOutreachCreatorsContext = createContext<InviteOutreachCreatorsContextType>(
  {} as InviteOutreachCreatorsContextType
);

export { InviteOutreachCreatorsContext };
export type { InviteOutreachCreatorsContextType };

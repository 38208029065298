import type { CampaignType } from 'GraphTypes/BriefQuery.graphql';

type PrepareCampaignValue = <C extends { readonly type: CampaignType | null }>
(campaign: C, availableCampaignTypes: ReadonlyArray<CampaignType>) => C;

const prepareCampaignValue: PrepareCampaignValue = (campaign, availableCampaignTypes) => {
  if (!campaign || !campaign.type || !availableCampaignTypes) return campaign;
  const isSelectedCampaignTypeAvailable = availableCampaignTypes.includes(campaign.type);
  return {
    ...campaign,
    type: isSelectedCampaignTypeAvailable ? campaign.type : null,
  };
};

export { prepareCampaignValue };

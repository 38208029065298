import React, {
  JSXElementConstructor,
  PropsWithChildren,
  ReactElement,
  Suspense,
  cloneElement,
  createContext,
  useState,
} from 'react';
import { useTracking } from 'react-tracking';

import FinishDealOptions from './FinishDealOptions/FinishDealOptions';
import PublicationSelectionDrawer from './PublicationSelection/PublicationSelection';
import PublicationLinkRequestDrawer from './PublicationLinkRequest/PublicationLinkRequest';
import { AttachmentFileType } from './PublicationSelection/ProjectAttachments/ProjectAttachments';

import { amplitude } from 'Helpers/amplitude';
import { CONTENT_CREATION, INFLUENCER, INSTAGRAM } from 'Constants/general';
import { CampaignType } from 'GraphTypes/AcceptOfferMutation.graphql';
import { CreatorType } from 'GraphTypes/CreatorsSearchWithParamsQuery.graphql';
import { CampaignPlatform } from 'GraphTypes/Actions_campaign.graphql';

export const FinishDealWrapperContext = createContext<FinishDealContextType | null>(null);

interface Props {
  projectId: string;
  finishDealCallback?: () => void;
  needRequestLink: boolean;
  campaignType?: CampaignType | null;
  campaignPlatform?: CampaignPlatform | null;
  creatorType?: CreatorType;
  insightsAuthorized?: boolean;
  selectSubmissionFiles?: boolean;
  isBarter: boolean;
  price?: number | null;
  isFreeOrganic?: boolean;
}

const FinishDealWrapperProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    projectId,
    finishDealCallback,
    needRequestLink,
    campaignType,
    campaignPlatform,
    creatorType,
    insightsAuthorized,
    selectSubmissionFiles,
    isFreeOrganic,
  } = props;
  const tracking = useTracking();

  const [selectPublicationDrawer, setSelectPublicationDrawerStatus] = useState(false);
  const [completeProjectDrawer, setCompleteProjectDrawerStatus] = useState(false);
  const [needPublicationDrawer, setNeedPublicationDrawerStatus] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [
    needToTryAnotherCampaignDrawerIfPossible,
    setNeedToOpenTryAnotherCampaignDrawerIfPossibleStatus,
  ] = useState(false);
  const [completeProjectWithoutFiles, setCompleteProjectWithoutStatus] = useState(false);

  const sendAnalyticsData = () => {
    tracking.trackEvent({ element: 'finish_deal', event: 'click' });
    amplitude.sendEvent({ id: '23', category: 'results', name: 'finish_deal_click', param: {} });
  };

  const handleFlowFinishDeal = () => {
    sendAnalyticsData();
    if (needRequestLink) {
      setNeedPublicationDrawerStatus(true);
      return;
    }

    if (finishDealCallback) {
      finishDealCallback();
    }

    if (campaignType === INFLUENCER && campaignPlatform === INSTAGRAM) {
      setCompleteProjectDrawerStatus(true);
      return;
    }

    if (
      !selectSubmissionFiles &&
      creatorType === INSTAGRAM &&
      insightsAuthorized &&
      campaignPlatform === INSTAGRAM &&
      campaignType === INFLUENCER
    ) {
      setCompleteProjectDrawerStatus(true);
    } else if (campaignType === CONTENT_CREATION || selectSubmissionFiles) {
      setSelectPublicationDrawerStatus(true);
    } else {
      setCompleteProjectDrawerStatus(true);
    }
  };

  const contextValue = {
    setSelectPublicationDrawerStatus,
    setCompleteProjectDrawerStatus,
    setNeedPublicationDrawerStatus,
    selectPublicationDrawer,
    completeProjectDrawer,
    needPublicationDrawer,
    setSelectedFiles,
    selectedFiles,
    setNeedToOpenTryAnotherCampaignDrawerIfPossibleStatus,
    needToTryAnotherCampaignDrawerIfPossible,
    handleFlowFinishDeal,
    setCompleteProjectWithoutStatus,
    completeProjectWithoutFiles,
    isFreeOrganic: !!isFreeOrganic,
  };

  return (
    <FinishDealWrapperContext.Provider value={contextValue}>
      {cloneElement(children as ReactElement<any, string | JSXElementConstructor<any>>, {
        onClick: handleFlowFinishDeal,
      })}
      <FinishDealOptions
        campaignType={campaignType}
        projectId={projectId}
        isFreeOrganic={isFreeOrganic}
      />
      <Suspense fallback={null}>
        <PublicationSelectionDrawer
          projectId={projectId}
          selectSubmissionFiles={Boolean(selectSubmissionFiles)}
        />
      </Suspense>

      <PublicationLinkRequestDrawer projectId={projectId} />
    </FinishDealWrapperContext.Provider>
  );
};

export default FinishDealWrapperProvider;

type FinishDealContextType = {
  setSelectPublicationDrawerStatus: (visible: boolean) => void;
  setCompleteProjectDrawerStatus: (visible: boolean) => void;
  setNeedPublicationDrawerStatus: (visible: boolean) => void;
  selectPublicationDrawer: boolean;
  completeProjectDrawer: boolean;
  needPublicationDrawer: boolean;
  setSelectedFiles: (files: Record<string, AttachmentFileType>) => void;
  selectedFiles: Record<string, AttachmentFileType>;
  handleFlowFinishDeal: () => void;
  setNeedToOpenTryAnotherCampaignDrawerIfPossibleStatus: (value: boolean) => void;
  needToTryAnotherCampaignDrawerIfPossible: boolean;
  setCompleteProjectWithoutStatus: (value: boolean) => void;
  completeProjectWithoutFiles: boolean;
  isFreeOrganic: boolean;
};

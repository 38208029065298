import { IconName } from 'Components/ui/types';
import {
  CONTENT_CREATION_ONBOARDING_VIDEO,
  CONTENT_CREATION_ONBOARDING_VIDEO_POSTER,
  ORGANIC_POSTING_ONBOARDING_VIDEO,
  ORGANIC_POSTING_ONBOARDING_VIDEO_POSTER,
  CREATOR_ADS_ONBOARDING_VIDEO,
  CREATOR_ADS_ONBOARDING_VIDEO_POSTER,
  PRODUCT_SEEDING_ONBOARDING_VIDEO,
  PRODUCT_SEEDING_ONBOARDING_VIDEO_POSTER,
  UGC_ONBOARDING_VIDEO,
  INFLUENCER_ONBOARDING_VIDEO,
  SEEDING_ONBOARDING_VIDEO,
  CONVERSIONS_SPARK_ADS_ONBOARDING_VIDEO,
  REACH_SPARK_ADS_ONBOARDING_VIDEO,
  CONVERSIONS_META_ADS_ONBOARDING_VIDEO,
  REACH_META_ADS_ONBOARDING_VIDEO,
  AFFILATE_ONBOARDING_VIDEO,
  TIKTOK_SHOP_ONBOARDING_VIDEO,
} from 'Constants/general';
import { CampaignObjective, CreateCampaignInput } from 'GraphTypes/CreateCampaignMutation.graphql';
import ugcPoster from 'Images/onboarding/useCases/ugc.png';
import ugcPoster2x from 'Images/onboarding/useCases/ugc_2x.png';
import infPoster from 'Images/onboarding/useCases/influencerPosts.png';
import infPoster2x from 'Images/onboarding/useCases/influencerPosts_2x.png';
import sparkAdsPoster from 'Images/onboarding/useCases/sparkAds.png';
import sparkAdsPoster2x from 'Images/onboarding/useCases/sparkAds_2x.png';
import psPoster from 'Images/onboarding/useCases/ps.png';
import psPoster2x from 'Images/onboarding/useCases/ps_2x.png';
import metaAdsPoster from 'Images/onboarding/useCases/metaAds.png';
import metaAdsPoster2x from 'Images/onboarding/useCases/metaAds_2x.png';
import affiliatePoster from 'Images/onboarding/useCases/affiliate.png';
import affiliatePoster2x from 'Images/onboarding/useCases/affiliate_2x.png';
import ttShopPoster from 'Images/onboarding/useCases/ttShop.png';
import ttShopPoster2x from 'Images/onboarding/useCases/ttShop_2x.png';

export type VideoType = {
  id: string;
  title: string;
  descr: string;
  icon: IconName;
  color: string;
  campaignData: CreateCampaignInput;
  link: string;
  campaignType: string;
  poster: string;
};

export const videos: VideoType[] = [
  {
    id: 'content_creation',
    icon: 'Format-1-1',
    title: 'home.videos.content_creation.title',
    descr: 'home.videos.content_creation.descr',
    color: 'sky',
    campaignType: 'CONTENT_CREATION',
    campaignData: { type: 'CONTENT_CREATION' },
    link: CONTENT_CREATION_ONBOARDING_VIDEO,
    poster: `${CONTENT_CREATION_ONBOARDING_VIDEO_POSTER} 1x`,
  },
  {
    id: 'organic',
    icon: 'Picture-image-stars',
    title: 'home.videos.organic.title',
    descr: 'home.videos.organic.descr',
    color: 'pink',
    campaignData: { type: 'INFLUENCER', postingType: 'ORGANIC_POSTING' },
    campaignType: 'ORGANIC_POSTING',
    link: ORGANIC_POSTING_ONBOARDING_VIDEO,
    poster: `${ORGANIC_POSTING_ONBOARDING_VIDEO_POSTER} 1x`,
  },
  {
    id: 'ads',
    icon: 'Paid-ads',
    title: 'home.videos.ads.title',
    descr: 'home.videos.ads.descr',
    color: 'successfully',
    campaignData: { type: 'INFLUENCER', postingType: 'CREATOR_ADS' },
    campaignType: 'CREATOR_ADS',
    link: CREATOR_ADS_ONBOARDING_VIDEO,
    poster: `${CREATOR_ADS_ONBOARDING_VIDEO_POSTER} 1x`,
  },
  {
    id: 'product_seeding',
    icon: 'Seeding',
    title: 'home.videos.product_seeding.title',
    descr: 'home.videos.product_seeding.descr',
    color: 'purple',
    campaignData: { type: 'PRODUCT_SEEDING' },
    campaignType: 'PRODUCT_SEEDING',
    link: PRODUCT_SEEDING_ONBOARDING_VIDEO,
    poster: `${PRODUCT_SEEDING_ONBOARDING_VIDEO_POSTER} 1x`,
  },
];

export type NewCaseType = Partial<Record<CampaignObjective, VideoType[]>>;

export const newCases: NewCaseType = {
  JUST_CONTENT: [
    {
      id: 'ugc',
      icon: 'Format-1-1',
      title: 'home.videos.ugc_content.title',
      descr: 'home.videos.ugc_content.descr',
      color: 'sky',
      campaignType: 'CONTENT_CREATION',
      campaignData: {
        type: 'CONTENT_CREATION',
        useCase: 'USER_GENERATED_CONTENT',
        objective: 'JUST_CONTENT',
      },
      link: UGC_ONBOARDING_VIDEO,
      poster: `${ugcPoster} 1x, ${ugcPoster2x} 2x`,
    },
  ],
  REACH: [
    {
      id: 'influencer',
      icon: 'Photo',
      title: 'home.videos.influencer.title',
      descr: 'home.videos.influencer.descr',
      color: 'pink',
      campaignType: 'INFLUENCER',
      campaignData: {
        type: 'INFLUENCER',
        postingType: 'ORGANIC_POSTING',
        useCase: 'INFLUENCER_POSTS',
        objective: 'REACH',
      },
      link: INFLUENCER_ONBOARDING_VIDEO,
      poster: `${infPoster} 1x, ${infPoster2x} 2x`,
    },
    {
      id: 'seeding',
      icon: 'Seeding',
      title: 'home.videos.seeding.title',
      descr: 'home.videos.seeding.descr',
      color: 'purple',
      campaignType: 'PRODUCT_SEEDING',
      campaignData: {
        type: 'PRODUCT_SEEDING',
        useCase: 'PRODUCT_SEEDING',
        objective: 'REACH',
      },
      link: SEEDING_ONBOARDING_VIDEO,
      poster: `${psPoster} 1x, ${psPoster2x} 2x`,
    },
    {
      id: 'tiktok_spark_ads',
      icon: 'Paid-ads',
      title: 'home.videos.tiktok_spark_ads.title',
      descr: 'home.videos.tiktok_spark_ads.descr',
      color: 'successfully',
      campaignType: 'TIKTOK_SPARK_ADS',
      campaignData: {
        type: 'INFLUENCER',
        postingType: 'CREATOR_ADS',
        useCase: 'TIKTOK_SPARK_ADS',
        objective: 'REACH',
      },
      link: REACH_SPARK_ADS_ONBOARDING_VIDEO,
      poster: `${sparkAdsPoster} 1x, ${sparkAdsPoster2x} 2x`,
    },
    {
      id: 'whitelisted_ads',
      icon: 'Paid-ads',
      title: 'home.videos.whitelisted_ads.title',
      descr: 'home.videos.whitelisted_ads.descr',
      color: 'successfully',
      campaignType: 'WHITELISTED_ADS',
      campaignData: {
        type: 'INFLUENCER',
        postingType: 'CREATOR_ADS',
        useCase: 'META_PARTNERSHIP_ADS',
        objective: 'REACH',
      },
      link: REACH_META_ADS_ONBOARDING_VIDEO,
      poster: `${metaAdsPoster} 1x, ${metaAdsPoster2x} 2x`,
    },
  ],
  CONVERSIONS: [
    {
      id: 'affiliate',
      icon: 'Photo',
      title: 'home.videos.affiliate.title',
      descr: 'home.videos.affiliate.descr',
      color: 'pink',
      campaignType: 'AFFILATE',
      campaignData: {
        type: 'INFLUENCER',
        postingType: 'ORGANIC_POSTING',
        useCase: 'AFFILIATE_CAMPAIGN',
        objective: 'CONVERSIONS',
      },
      link: AFFILATE_ONBOARDING_VIDEO,
      poster: `${affiliatePoster} 1x, ${affiliatePoster2x} 2x`,
    },
    {
      id: 'tiktok_shop',
      icon: 'Photo',
      title: 'home.videos.tiktok_shop.title',
      descr: 'home.videos.tiktok_shop.descr',
      color: 'pink',
      campaignType: 'TIKTOK_SHOP',
      campaignData: {
        type: 'INFLUENCER',
        postingType: 'ORGANIC_POSTING',
        useCase: 'TIKTOK_SHOP',
        objective: 'CONVERSIONS',
      },
      link: TIKTOK_SHOP_ONBOARDING_VIDEO,
      poster: `${ttShopPoster} 1x, ${ttShopPoster2x} 2x`,
    },
    {
      id: 'tiktok_spark_ads',
      icon: 'Paid-ads',
      title: 'home.videos.tiktok_spark_ads.title',
      descr: 'home.videos.tiktok_spark_ads.descr',
      color: 'successfully',
      campaignType: 'TIKTOK_SPARK_ADS',
      campaignData: {
        type: 'INFLUENCER',
        postingType: 'CREATOR_ADS',
        useCase: 'TIKTOK_SPARK_ADS',
        objective: 'CONVERSIONS',
      },
      link: CONVERSIONS_SPARK_ADS_ONBOARDING_VIDEO,
      poster: `${sparkAdsPoster} 1x, ${sparkAdsPoster2x} 2x`,
    },
    {
      id: 'whitelisted_ads',
      icon: 'Paid-ads',
      title: 'home.videos.whitelisted_ads.title',
      descr: 'home.videos.whitelisted_ads.descr',
      color: 'successfully',
      campaignType: 'WHITELISTED_ADS',
      campaignData: {
        type: 'INFLUENCER',
        postingType: 'CREATOR_ADS',
        useCase: 'META_PARTNERSHIP_ADS',
        objective: 'CONVERSIONS',
      },
      link: CONVERSIONS_META_ADS_ONBOARDING_VIDEO,
      poster: `${metaAdsPoster} 1x, ${metaAdsPoster2x} 2x`,
    },
  ],
};

import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';

import styles from './InviteCreatorCard.pcss';

import { amplitude } from 'Helpers/amplitude';
import sendOffer from 'Mutations/SendOffer.Mutation';
import { getCreatorStatLink } from 'Util/links';
import { getCreatorData } from 'Util/creatorData';
import { createPercent } from 'Util/numberFormatter';
import { InviteCreatorCard_inviteSuggestion$key } from 'GraphTypes/InviteCreatorCard_inviteSuggestion.graphql';
import Image from 'Components/ui/Image/Image';
import Text from 'Components/ui/Text/Text';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import { CreatorBadgeEnum } from 'Types/enums';
import Tag from 'Components/ui/Tag/Tag';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { createShortNumber } from 'Util/numberFormatter';
import Icon from 'Components/ui/Icon/Icon';
import Spinner from 'Atoms/Spinner/Spinner';
import SmartLink from 'Atoms/SmartLink/SmartLink';

interface Props {
  campaignId: string;
  inviteSuggestion: InviteCreatorCard_inviteSuggestion$key;
  onInvitationDone?: () => void;
}

const InviteCreatorCard: React.FC<Props> = (props) => {
  const { campaignId, inviteSuggestion, onInvitationDone } = props;

  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const data = useFragment(
    graphql`
      fragment InviteCreatorCard_inviteSuggestion on InviteSuggestion {
        invited
        creator {
          id
          type
          ownership {
            owner {
              rating {
                averageScore
              }
            }
          }
          completedProjectsCount
          ratingVotes(side: AGENCY) {
            totalCount
          }
          profile {
            badges {
              name
            }
            generalStatistic {
              engagementRate
            }
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
          ... on YoutubeCreator {
            channel {
              name
            }
          }
          ... on TiktokCreator {
            tiktokUser: user {
              name
              engagementRate
              followedByCount
            }
          }
          ... on InstagramCreator {
            username
            user {
              engagementRate
              followedByCount
            }
          }
        }
      }
    `,
    inviteSuggestion
  );

  const [invited, setInvited] = useState(false);

  const creator = data.creator;

  const { avaSrc, type, username, engagementRate, followedByCount } = getCreatorData(creator);
  const badges = creator?.profile?.badges;

  const creatorId = creator?.id;

  const averageScore = creator?.ownership?.owner.rating?.averageScore;
  const averageScoreText = averageScore ? averageScore.toFixed(2) : '—';
  const voteCount = creator?.ratingVotes.totalCount;
  const voteCountText = voteCount ? ` (${voteCount})` : '';

  const deals = creator?.completedProjectsCount;
  const dealsText = deals
    ? intl.formatMessage({ id: 'invite_creators_modal.deals_count' }, { count: deals })
    : undefined;

  const followedByCountText = createShortNumber(followedByCount);

  const erText = createPercent(engagementRate);

  const handleInviteDone = () => {
    setLoading(false);
    setInvited(true);
    onInvitationDone?.();
  };

  const handleInviteClick = () => {
    if (!creatorId) return;
    setLoading(true);
    amplitude.sendEvent<389>({
      id: '389',
      category: 'campaign',
      name: 'invite_creator_click',
      param: undefined,
    });
    sendOffer({ campaignId, creatorId, source: 'INVITE_SUGGESTION' }, handleInviteDone);
  };

  const handleProfileClick = () => {
    amplitude.sendEvent<391>({
      id: '391',
      category: 'campaign',
      name: 'invite_creator_profile_click',
      param: undefined,
    });
  };

  const statLink = creatorId ? getCreatorStatLink({ campaignId, creatorId }) : undefined;

  return (
    <div className={styles.root}>
      <div className={styles.avaWrap}>
        <Image
          src={avaSrc}
          size={40}
          type="round"
          platform={type}
          platformClassName={styles.platform}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.usernameWrap}>
          <Text type="md" text={username} />
          <div className={styles.badges}>
            {badges?.map((badge) => {
              if (!badge.name) return null;
              return (
                <CreatorBadge
                  key={badge.name}
                  badge={badge.name as CreatorBadgeEnum}
                  withText={false}
                  className={styles.badge}
                  iconClassName={styles.badgeIcon}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.stats}>
          {averageScoreText && (
            <Tag
              icon="Star"
              iconPosition="left"
              color="white"
              needMargin={false}
              text={`${averageScoreText}${voteCountText}`}
            />
          )}
          {followedByCount > 0 && (
            <Tag
              icon={creator?.type === 'INSTAGRAM' ? 'Platform-instagram' : 'Platform-tiktok'}
              iconPosition="left"
              color="white"
              needMargin={false}
              text={followedByCountText}
            />
          )}
          <Tag iconPosition="left" color="white" needMargin={false} text={`${erText} ER`} />
          {!!deals && <Tag iconPosition="left" color="white" needMargin={false} text={dealsText} />}
        </div>
        <SmartLink target="_blank" path={statLink} onClick={handleProfileClick}>
          <div className={styles.link} />
        </SmartLink>
      </div>
      {!invited && !loading && (
        <AlterButton type="black" msg="invite_creators_modal.invite" onClick={handleInviteClick} />
      )}
      {loading && <Spinner size="sm" className={styles.spinner} />}
      {invited && <Icon color="green" name="Check" className={styles.invited} />}
    </div>
  );
};

export default InviteCreatorCard;

/* eslint no-redeclare: 0 */

import { FilterCampaignType, FilterPlatformType, FilterValuesState } from './types';

import type {
  CampaignPostingType,
  CampaignPlatform,
  CampaignType,
} from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';
import type { ExcludeFutureAddedValue } from 'Types/utils';
import { FACEBOOK, INSTAGRAM, OTHER, TIKTOK } from 'Constants/general';
import type { TransactionsChartQuery$variables } from 'GraphTypes/TransactionsChartQuery.graphql';
import type { PerformanceCollaborationsChartQuery$variables } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';
import type { ViewCountersChartQuery$variables } from 'GraphTypes/ViewCountersChartQuery.graphql';
import type { CpmCpeCountersChartQuery$variables } from 'GraphTypes/CpmCpeCountersChartQuery.graphql';
import type { EngagementCountersChartQuery$variables } from 'GraphTypes/EngagementCountersChartQuery.graphql';

type FilterCampaignTypeToRequestData = (filterCampaignType: FilterCampaignType) => {
  campaignPostingType: ExcludeFutureAddedValue<CampaignPostingType> | null;
  campaignType: ExcludeFutureAddedValue<CampaignType> | null;
};
const filterCampaignTypeToRequestData: FilterCampaignTypeToRequestData = (filterCampaignType) => {
  switch (filterCampaignType) {
    case FilterCampaignType.ALL_TYPES: {
      return {
        campaignType: null,
        campaignPostingType: null,
      };
    }
    case FilterCampaignType.JUST_CONTENT: {
      return {
        campaignType: 'CONTENT_CREATION',
        campaignPostingType: null,
      };
    }
    case FilterCampaignType.ORGANIC_POSTING: {
      return {
        campaignType: 'INFLUENCER',
        campaignPostingType: 'ORGANIC_POSTING',
      };
    }
    case FilterCampaignType.CREATOR_ADS: {
      return {
        campaignType: 'INFLUENCER',
        campaignPostingType: 'CREATOR_ADS',
      };
    }
    case FilterCampaignType.PRODUCT_SEEDING: {
      return {
        campaignType: 'PRODUCT_SEEDING',
        campaignPostingType: null,
      };
    }
    default: {
      return {
        campaignType: null,
        campaignPostingType: null,
      };
    }
  }
};

type FilterPlatformToRequestData = (
  platform: FilterPlatformType
) => ExcludeFutureAddedValue<CampaignPlatform> | null;
const filterPlatformToRequestData: FilterPlatformToRequestData = (platform) => {
  switch (platform) {
    case FilterPlatformType.ALL_PLATFORMS: {
      return null;
    }
    case FilterPlatformType.INSTAGRAM: {
      return INSTAGRAM;
    }
    case FilterPlatformType.TIK_TOK: {
      return TIKTOK;
    }
    case FilterPlatformType.FACEBOOK: {
      return FACEBOOK;
    }
    case FilterPlatformType.OTHER: {
      return OTHER;
    }
    default: {
      return null;
    }
  }
};

function makeVariables(
  filterValuesState: FilterValuesState,
  chart: 'paymentTransactionCounters'
): TransactionsChartQuery$variables;
function makeVariables(
  filterValuesState: FilterValuesState,
  chart: 'collaborationCounters'
): PerformanceCollaborationsChartQuery$variables;
function makeVariables(
  filterValuesState: FilterValuesState,
  chart: 'viewCounters'
): ViewCountersChartQuery$variables;
function makeVariables(
  filterValuesState: FilterValuesState,
  chart: 'cpmCpeCounters'
): CpmCpeCountersChartQuery$variables;
function makeVariables(
  filterValuesState: FilterValuesState,
  chart: 'engagementCounters'
): EngagementCountersChartQuery$variables;
function makeVariables(
  filterValues: FilterValuesState,
  chart:
    | 'viewCounters'
    | 'cpmCpeCounters'
    | 'engagementCounters'
    | 'collaborationCounters'
    | 'paymentTransactionCounters'
): TransactionsChartQuery$variables | void {
  switch (chart) {
    case 'viewCounters':
    case 'cpmCpeCounters':
    case 'engagementCounters':
    case 'collaborationCounters':
    case 'paymentTransactionCounters': {
      const result: TransactionsChartQuery$variables = {
        brandId: filterValues.brand,
        campaignIds: filterValues.campaigns,
        granularity: filterValues.granularity,
        dateFrom: filterValues.dateRange[0],
        dateTo: filterValues.dateRange[1],
        useCase: filterValues.useCase !== 'ALL_TYPES' ? filterValues.useCase : undefined,
      };

      const campaignTypeData = filterCampaignTypeToRequestData(filterValues.campaignType);

      if (filterValues.platform) {
        result.platform = filterPlatformToRequestData(filterValues.platform);
      }
      if (campaignTypeData) {
        result.campaignType = campaignTypeData.campaignType;
        result.campaignPostingType = campaignTypeData.campaignPostingType;
      }
      return result;
    }
    default: {
      break;
    }
  }
}

export { filterCampaignTypeToRequestData, filterPlatformToRequestData, makeVariables };

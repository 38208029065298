import React, { useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import TextButton from 'Atoms/TextButton/TextButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import { AdminComment_comment$data } from 'GraphTypes/AdminComment_comment.graphql';
import TransactionComment from 'Modal/admin/TransactionComment/TransactionComment';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  comment: AdminComment_comment$data;
}

const AdminComment: React.FC<Props> = (props) => {
  const comment = props.comment.adminComment;
  const id = props.comment.id;

  const { openDrawer } = useContext(DrawerContext);

  const handleCommentClick = () => {
    openDrawer(`transaction-comment-${id}`);
  };

  const commentText = comment?.replace(/\n/g, '<br>');

  return (
    <>
      <Tooltip id={comment ? `admin-comment-${id}` : undefined} tooltipText={commentText}>
        <TextButton
          color="normal"
          icon={<Icon name={comment ? 'Notes-filled' : 'Notes'} />}
          onClick={handleCommentClick}
        />
      </Tooltip>
      <TransactionComment id={id} isWithdarwal comment={comment} />
    </>
  );
};

export default createFragmentContainer(AdminComment, {
  comment: graphql`
    fragment AdminComment_comment on Payment_Withdrawal {
      id
      adminComment
    }
  `,
});

import React, { Suspense } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './SearchResults.pcss';
import List from './List/List';
import AdminAdditionalControls from './AdminAdditionalControls/AdminAdditionalControls';
import CreatorsCount from './CreatorsCount/CreatorsCount';

import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { SearchResultsContainerQuery$variables } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { SearchResults_campaign$data } from 'GraphTypes/SearchResults_campaign.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  isAdmin: boolean;
  campaignId: string;
  searchQueryInput: SearchResultsContainerQuery$variables['searchQueryInput'];
  hasContentCreation: boolean;
  canUsePaidSocial?: boolean;
  isBlockedView?: boolean;
  relay: RelayPaginationProp;
  campaign?: SearchResults_campaign$data;
  preloader: JSX.Element;
  organizationId?: string;
  showUnlockCreatorBanner: () => void;
}

const COUNT = 24;
const SCROLL_OFFSET = 600;

const SearchResults: React.FC<Props> = (props) => {
  const {
    relay,
    campaignId,
    campaign,
    searchQueryInput,
    isAdmin,
    canUsePaidSocial,
    hasContentCreation,
    preloader,
    organizationId,
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const creatorTypes = searchQueryInput?.creatorTypes;
  const creatorType = creatorTypes?.length === 1 ? creatorTypes[0] : null;
  const searchCreators = campaign?.searchCreators;

  const handleShowMoreClick = () => {
    showUnlockCreatorBanner();
    amplitude.sendEvent<362>({
      id: '362',
      category: 'marketplace',
      name: 'show_more_click',
      param: undefined,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Suspense fallback={null}>
          <CreatorsCount campaignId={campaignId} searchQueryInput={searchQueryInput} />
        </Suspense>
      </div>
      <LoaderHandler
        relay={relay}
        count={COUNT}
        offset={SCROLL_OFFSET}
        containerId="resultsContainer"
        preloader={preloader}
        blocked={isBlockedView}
        additionalControls={
          isAdmin ? (
            <AdminAdditionalControls list={searchCreators?.edges} campaignId={campaignId} />
          ) : undefined
        }
      >
        <List
          isAdmin={isAdmin}
          searchCreators={searchCreators}
          campaignId={campaignId}
          hasContentCreation={hasContentCreation}
          className={styles.list}
          creatorType={creatorType}
          canUsePaidSocial={canUsePaidSocial}
          organizationId={organizationId}
          isBlockedView={isBlockedView}
          showUnlockCreatorBanner={showUnlockCreatorBanner}
        />
      </LoaderHandler>
      {isBlockedView && relay.hasMore() && (
        <div className={styles.showMore} onClick={handleShowMoreClick}>
          <AlterButton
            type="black"
            icon="Lock"
            iconPosition="left"
            msg="browse_creators.blocked.show_more"
          />
        </div>
      )}
    </div>
  );
};

export default createPaginationContainer(
  SearchResults,
  {
    campaign: graphql`
      fragment SearchResults_campaign on Campaign
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 24 }
        cursor: { type: "String" }
        searchQueryInput: { type: "SearchQueryInput" }
      ) {
        id
        stage
        searchCreators(first: $count, after: $cursor, searchQueryInput: $searchQueryInput)
          @connection(key: "SearchResults_searchCreators", filters: []) {
          edges {
            node {
              creator {
                id
              }
              project {
                id
              }
              ...SearchResult_result
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.campaign && props.campaign.searchCreators;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        searchQueryInput: props.searchQueryInput,
        campaignId: props.campaignId,
      };
    },
    query: graphql`
      query SearchResultsPaginationQuery(
        $campaignId: ID!
        $count: Int!
        $cursor: String
        $searchQueryInput: SearchQueryInput
      ) {
        campaign(id: $campaignId) {
          ...SearchResults_campaign
            @arguments(count: $count, cursor: $cursor, searchQueryInput: $searchQueryInput)
        }
      }
    `,
  }
);

import React, { useState, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { KeyType } from 'react-relay/relay-hooks/helpers';
import { useDispatch } from 'react-redux';

import CreatorDetails from '../../../../components/CreatorDetails/CreatorDetails';

import styles from './Creator.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { setModal, toggleModal } from 'Actions/Modal.Actions';
import { getCreatorProfileLink } from 'Util/links';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { modalName } from 'Types/modals';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import type { Creator_creator$key, Creator_creator$data } from 'GraphTypes/Creator_creator.graphql';
import removeCreatorFromFavorites, {
  RemoveCreatorFromFavoritesMutation,
} from 'Mutations/RemoveCreatorFromFavorites.Mutation';
import { promisifyMutation } from 'Util/promisifyMutation';
import Icon from 'Components/ui/Icon/Icon';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  creator: Creator_creator$key;
  organizationId?: string;
  isBlockedView?: boolean;
  showUnlockCreatorBanner: () => void;
}

const Creator: React.FC<Props> = (props) => {
  const { creator, organizationId = '', isBlockedView, showUnlockCreatorBanner } = props;
  const dispatch = useDispatch();

  const { createNewCampaign } = useCampaignActions();

  const [
    isRemoveCreatorFromFavoritesMutationPending,
    setIsRemoveCreatorFromFavoritesMutationPending,
  ] = useState<boolean>(false);

  const data = useFragment<KeyType<Creator_creator$data>>(
    graphql`
      fragment Creator_creator on Creator {
        id
        ...CreatorDetails_creator
      }
    `,
    normalizeFragmentRef<Creator_creator$key>(creator)
  );
  if (!data) return null;

  const creatorId = data.id;

  const profileLink = getCreatorProfileLink({ creatorId });

  const handleNewCampaignCreated = () => {
    dispatch(toggleModal());
  };

  const handleStartNewDeal = useCallback(() => {
    amplitude.sendEvent<265>({
      id: '265',
      category: 'favorites',
      name: 'start_deal_click',
      param: {
        creator_id: creatorId,
      },
    });
    dispatch(
      setModal(modalName.START_NEW_DEAL, {
        creatorId,
        campaignId: null,
        source: 'creators_space',
        onStartNewCampaignButtonClick: () => {
          amplitude.sendEvent<266>({
            id: '266',
            category: 'favorites',
            name: 'start_deal_new_campaign_click',
            param: {
              creator_id: creatorId,
            },
          });
          createNewCampaign({ onSuccess: handleNewCampaignCreated });
        },
        onAddToCampaignButtonClick: () => {
          amplitude.sendEvent<267>({
            id: '267',
            category: 'favorites',
            name: 'start_deal_existing_campaign_click',
            param: {
              creator_id: creatorId,
            },
          });
        },
      })
    );
  }, [organizationId, creatorId]);

  const handleProfileClick = useCallback(() => {
    amplitude.sendEvent<264>({
      id: '264',
      category: 'favorites',
      name: 'view_profile_click',
      param: {
        creator_id: creatorId,
      },
    });
  }, [organizationId, creatorId]);

  const handleFavoriteClick = useCallback(() => {
    if (isRemoveCreatorFromFavoritesMutationPending) return;
    amplitude.sendEvent<268>({
      id: '268',
      category: 'favorites',
      name: 'creator_removed_favorites',
      param: {
        creator_id: creatorId,
      },
    });
    const mutation = promisifyMutation<RemoveCreatorFromFavoritesMutation>(
      removeCreatorFromFavorites
    );
    setIsRemoveCreatorFromFavoritesMutationPending(true);
    mutation({ creatorId, organizationId }).finally(() => {
      setIsRemoveCreatorFromFavoritesMutationPending(false);
    });
  }, [isRemoveCreatorFromFavoritesMutationPending, creatorId, organizationId]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.creator}>
          <CreatorDetails
            creator={data}
            isBlockedView={isBlockedView}
            showUnlockCreatorBanner={showUnlockCreatorBanner}
          />
        </div>
      </div>
      <div className={styles.controls}>
        <SmartLink path={profileLink} target="_blank">
          <AlterButton
            icon="Profile"
            type="grey"
            msg="creators.dashboard.favorites.view_profile"
            onClick={handleProfileClick}
          />
        </SmartLink>
        <AlterButton
          icon="Add-plus-circle"
          type="grey"
          msg="creators.dashboard.favorites.start_new_deal"
          onClick={handleStartNewDeal}
        />
        <Tooltip
          id="favorite_button_tooltip"
          tooltipMsg="creators.dashboard.favorites.favorite_button_tooltip"
          place="top-start"
          className={styles.tooltip}
        >
          <AlterButton
            type="grey"
            rightElement={<Icon name="Trash-delele" size={24} />}
            onClick={handleFavoriteClick}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default Creator;

import React from 'react';

import styles from './TransactionFailed.pcss';

import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';

interface Props {
  onRetryClick?: () => void;
}

const TransactionFailed: React.FC<Props> = (props) => {
  const { onRetryClick } = props;
  return (
    <div className={styles.root}>
      <Text type="d2" msg="transaction_failed_modal.title" className={styles.title} />
      <Text type="md" msg="transaction_failed_modal.descr" />
      <div className={styles.resultContainer}>
        <div className={styles.errorIcon} />
      </div>
      {onRetryClick && (
        <Button color="black" msg="transaction_failed_modal.try_again" onClick={onRetryClick} />
      )}
    </div>
  );
};

export default TransactionFailed;

/**
 * @generated SignedSource<<3770118b1d9944a04a127fdafc25df3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectPrice_project$data = {
  readonly campaign: {
    readonly paymentType: PaymentType | null | undefined;
  };
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly currency: Currency | null | undefined;
  readonly id: string;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly price: number | null | undefined;
  readonly priceChangeRequest: {
    readonly id: string;
    readonly price: number;
  } | null | undefined;
  readonly " $fragmentType": "ProjectPrice_project";
};
export type ProjectPrice_project$key = {
  readonly " $data"?: ProjectPrice_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectPrice_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPrice_project",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceChangeRequest",
      "kind": "LinkedField",
      "name": "priceChangeRequest",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "f152b88e463c3f6fb302f8d6c1d114a6";

export default node;

import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import { useIntl } from 'react-intl';

import { items } from '../data';

import styles from './CreatorPastProjectsList.css';

import { getProjectsLink, getCampaignCreatorsLink, getOrganizationLink } from 'Util/links';
import { formatValue } from 'Util/dataFormatter';
import { createDateV2 } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import ProjectStatus from 'Atoms/ProjectStatus/ProjectStatus';
import Table from 'Containers/TableContainer/TableContainer';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { CreatorPastProjectsList_projects } from 'GraphTypes/CreatorPastProjectsList_projects.graphql';

interface Props {
  relay: RelayPaginationProp;
  creatorId: string;
  stages?: string[];
  projects: CreatorPastProjectsList_projects;
}

const CreatorPastProjectsList: React.FC<Props> = (props) => {
  const { projects, relay } = props;

  const intl = useIntl().formatMessage;

  const createListData = () => {
    return projects?.projects?.edges
      ?.map((item) => {
        if (!item?.node) return null;
        const {
          id: projectId,
          price,
          currency,
          archivation,
          archivationRequest,
          offer,
          campaign: { id, name: campaignName, brand, stage: campaignStage },
        } = item.node;

        const organizationName = item.node.campaign.organization?.name;
        const organizationId = item.node.campaign.organization?.id;
        const email = item.node.campaign.creator?.email;

        const offerDate = offer?.createdAt ? createDateV2(offer.createdAt) : '';

        const campaignLink = getCampaignCreatorsLink({ campaignId: id });

        const status = <ProjectStatus project={item.node} />;
        const priceText = formatValue({ value: price, format: 'currency', currency });

        let archivedReason;

        if (archivation) {
          if (archivationRequest && archivationRequest.reason) {
            archivedReason = intl(
              { id: 'creator.project.archived.reason.tooltip' },
              { reason: archivationRequest.reason }
            );
          } else {
            archivedReason = intl({ id: 'creator.project.archived.tooltip' });
          }
        }

        const archived = !archivation ? undefined : (
          <Tooltip id="archived" place="bottom" tooltipText={archivedReason}>
            <TextButton color="normal" iconName="archive" />
          </Tooltip>
        );

        const campaign = (
          <Tooltip
            id="campaign"
            place="right"
            tooltipMsg="creator.project.campaign.tooltip"
            tooltipValues={{ name: campaignName, date: offerDate }}
          >
            <SimpleLink href={campaignLink} target="_blank" className={styles.overflowBlock}>
              <Text color="creamCan" text={campaignName} overflow />
            </SimpleLink>
            <Text size="sm" color="grayDog" text={offerDate} />
          </Tooltip>
        );

        return {
          campaign,
          offerDate,
          organizationName,
          organizationLink: organizationId ? getOrganizationLink({ organizationId }) : undefined,
          email,
          status,
          archived,
          price: priceText,
          outerLink: getProjectsLink({
            projectId,
            campaignId: campaignStage === 'ACTIVE' ? id : undefined,
            organizationId,
          }),
        };
      })
      .filter((project) => Boolean(project));
  };

  const list = createListData();

  const loadButton = (
    <Button
      size="lg"
      width="full"
      msg="general.show_more"
      color="normal"
      className={styles.loadButton}
    />
  );

  return (
    <LoaderHandler relay={relay} loadButton={loadButton} count={10}>
      <Table data={items} list={list} />
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  CreatorPastProjectsList,
  {
    projects: graphql`
      fragment CreatorPastProjectsList_projects on Creator
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        stages: { type: "[AdvertiserProjectStage!]" }
      ) {
        projects(first: $count, after: $cursor, stages: $stages)
          @connection(key: "CreatorPast_projects", filters: []) {
          edges {
            node {
              id
              advertiserStage
              archivation(side: ADVERTISER) {
                id
              }
              archivationRequest {
                reason
              }
              campaign {
                id
                name
                brand {
                  name
                }
                organization {
                  id
                  name
                }
                creator {
                  email
                }
                stage
              }
              price
              currency
              brief {
                ... on V2Brief {
                  productShipmentRequired
                }
              }
              productShipmentCompletion {
                id
              }
              offer {
                createdAt
                acceptance {
                  id
                }
                rejection {
                  id
                  comment
                }
                readMark {
                  id
                }
              }
              launch {
                id
              }
              completion {
                id
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.projects.projects;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        creatorId: props.creatorId,
        stages: props.stages,
      };
    },
    query: graphql`
      query CreatorPastProjectsListPaginationQuery(
        $count: Int!
        $cursor: String
        $creatorId: ID!
        $stages: [AdvertiserProjectStage!]
      ) {
        creator(id: $creatorId) {
          ...CreatorPastProjectsList_projects
            @arguments(count: $count, cursor: $cursor, stages: $stages)
        }
      }
    `,
  }
);

import React, { useMemo } from 'react';
import classnames from 'classnames';

import styles from './BcaConnectionStateAdsIcon.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { BcaPermissionStateEnum } from 'Types/enums';
import paidAdsConnectedImg from 'Images/icons/paid-ads-connected.svg';
import paidAdsErrordImg from 'Images/icons/paid-ads-error.svg';
import paidAdsPendingImg from 'Images/icons/paid-ads-pending.svg';

type Props = {
  className?: string;
  permissionState: BcaPermissionStateEnum;
  titleMessage?: { [K in BcaPermissionStateEnum]?: string };
  subtitleMessage?: { [K in BcaPermissionStateEnum]?: string };
};

const BcaConnectionStateAdsIcon: React.FC<Props> = (props) => {
  const { className, permissionState } = props;

  const titleMessage = useMemo<string>(() => {
    return (
      {
        [BcaPermissionStateEnum.APPROVED]: 'bca_connection_state_ads_icon.title.approved',
        [BcaPermissionStateEnum.PENDING_APPROVAL]:
          'bca_connection_state_ads_icon.title.pending_approval',
        [BcaPermissionStateEnum.EXPIRED]: 'bca_connection_state_ads_icon.title.expired',
        [BcaPermissionStateEnum.REVOKED]: 'bca_connection_state_ads_icon.title.revoked',
        [BcaPermissionStateEnum.CANCELED]: 'bca_connection_state_ads_icon.title.canceled',
        [BcaPermissionStateEnum.INVALID]: 'bca_connection_state_ads_icon.title.invalid',
        [BcaPermissionStateEnum.UNKNOWN]: 'bca_connection_state_ads_icon.title.unknown',
        ...props?.titleMessage,
      }[permissionState] || ''
    );
  }, [props?.titleMessage, permissionState]);

  const subtitleMessage = useMemo<string>(() => {
    return (
      {
        [BcaPermissionStateEnum.PENDING_APPROVAL]:
          'bca_connection_state_ads_icon.subtitle.pending_approval',
        [BcaPermissionStateEnum.EXPIRED]: 'bca_connection_state_ads_icon.subtitle.expired',
        [BcaPermissionStateEnum.REVOKED]: 'bca_connection_state_ads_icon.subtitle.revoked',
        [BcaPermissionStateEnum.CANCELED]: 'bca_connection_state_ads_icon.subtitle.canceled',
        [BcaPermissionStateEnum.INVALID]: 'bca_connection_state_ads_icon.subtitle.invalid',
        ...props?.subtitleMessage,
      }[permissionState] || ''
    );
  }, [props?.subtitleMessage, permissionState]);

  const iconName = useMemo(() => {
    const iconNamesMapping: IconNamesMapping = {
      [BcaPermissionStateEnum.APPROVED]: paidAdsConnectedImg,
      [BcaPermissionStateEnum.PENDING_APPROVAL]: paidAdsPendingImg,
      [BcaPermissionStateEnum.EXPIRED]: paidAdsErrordImg,
      [BcaPermissionStateEnum.REVOKED]: paidAdsErrordImg,
      [BcaPermissionStateEnum.CANCELED]: paidAdsErrordImg,
      [BcaPermissionStateEnum.INVALID]: paidAdsErrordImg,
      [BcaPermissionStateEnum.UNKNOWN]: paidAdsErrordImg,
    };
    return iconNamesMapping[permissionState];
  }, [permissionState]);

  return (
    <div className={classnames(styles.root, className)}>
      {iconName ? <img src={iconName} /> : <Icon name="Paid-ads" />}
      <div className={styles.text}>
        <Text type="md" msg={titleMessage} />
        {subtitleMessage && <Text type="md" msg={subtitleMessage} className={styles.subtitle} />}
      </div>
    </div>
  );
};

export default BcaConnectionStateAdsIcon;

type IconProps = React.ComponentProps<typeof Icon>;
type IconNamesMapping = { [K in BcaPermissionStateEnum]: IconProps['name'] };

/**
 * @generated SignedSource<<6d9fb66ea5338e9d04b95095923040b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type ContentReviewStatus = "FAILED" | "PENDING" | "SUCCESS" | "%future added value";
export type MarkContentReviewAsPassedInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
};
export type MarkContentReviewAsPassedMutation$variables = {
  input: MarkContentReviewAsPassedInput;
};
export type MarkContentReviewAsPassedMutation$data = {
  readonly markContentReviewAsPassed: {
    readonly project: {
      readonly advertiserStage: AdvertiserProjectStage | null | undefined;
      readonly campaign: {
        readonly publishingRequired: boolean;
      };
      readonly contentReview: {
        readonly status: ContentReviewStatus;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MarkContentReviewAsPassedMutation = {
  response: MarkContentReviewAsPassedMutation$data;
  variables: MarkContentReviewAsPassedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "advertiserStage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishingRequired",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkContentReviewAsPassedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkContentReviewAsPassedPayload",
        "kind": "LinkedField",
        "name": "markContentReviewAsPassed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentReview",
                "kind": "LinkedField",
                "name": "contentReview",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkContentReviewAsPassedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkContentReviewAsPassedPayload",
        "kind": "LinkedField",
        "name": "markContentReviewAsPassed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentReview",
                "kind": "LinkedField",
                "name": "contentReview",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "131f7faf307c3fc16084467eebb9c938",
    "id": null,
    "metadata": {},
    "name": "MarkContentReviewAsPassedMutation",
    "operationKind": "mutation",
    "text": "mutation MarkContentReviewAsPassedMutation(\n  $input: MarkContentReviewAsPassedInput!\n) {\n  markContentReviewAsPassed(input: $input) {\n    project {\n      advertiserStage\n      campaign {\n        publishingRequired\n        id\n      }\n      contentReview {\n        status\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "601f20bec94faec7a7a2d489e8aece4a";

export default node;

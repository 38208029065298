import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateMessageInput,
  CreateMessageMutation$data,
  CreateMessageMutation,
} from 'GraphTypes/CreateMessageMutation.graphql';

const mutation = graphql`
  mutation CreateMessageMutation($input: CreateMessageInput!) {
    createMessage(input: $input) {
      newEventEdge {
        node {
          id
          createdAt
          __typename
          actor {
            name
            avatarUrl
          }
          ...MessageAttachments_message
          ... on Message {
            text
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateMessageInput & { files: any },
  resolve?: (response: CreateMessageMutation$data) => void,
  reject?: fnType
) => {
  const { projectId, text, attachmentIds } = data;

  const id = uuid();
  const variables = {
    input: {
      id,
      projectId,
      text,
      attachmentIds,
    },
  };
  commitMutation<CreateMessageMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const project = store.get(projectId);
      if (!project) return;
      const events = ConnectionHandler.getConnection(project, 'ProjectEventsList_events');
      if (!events) return;
      const eventExists = events
        .getLinkedRecords('edges')
        ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === id);
      if (eventExists) return;

      const event = store
        .getRootField('createMessage')
        .getLinkedRecord('newEventEdge')
        .getLinkedRecord('node');
      const edge = ConnectionHandler.createEdge(store, events, event, 'ProjectEventEdge');
      ConnectionHandler.insertEdgeAfter(events, edge);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  MarkProjectEventsAsReadInput,
  MarkProjectEventsAsReadMutation,
  MarkProjectEventsAsReadMutation$data,
} from 'GraphTypes/MarkProjectEventsAsReadMutation.graphql';

const mutation = graphql`
  mutation MarkProjectEventsAsReadMutation($input: MarkProjectEventsAsReadInput!) {
    markProjectEventsAsRead(input: $input) {
      clientMutationId
    }
  }
`;

interface MarkProjectEventsAsReadInputAdditional {
  projectId: string;
  ignoreCounterUpdate?: boolean;
}

type fnType = (data: object) => void;

export const chatListFilters = {
  last: 1,
  types: [
    'MESSAGE',
    'OFFER_ACCEPTANCE',
    'PRICE_CHANGE',
    'PRICE_CHANGE_REQUEST',
    'PRICE_CHANGE_REQUEST_REJECTION',
    'PRICE_CHANGE_REQUEST_ACCEPTANCE',
    'PRODUCT_SHIPMENT_COMPLETION',
    'CONTENT_REVIEW_FAIL',
    'CONTENT_REVIEW_PASS',
    'PUBLICATION_EVENT',
    'PUBLICATION_REQUEST',
    'DEADLINE_CHANGE_REQUEST',
    'EXTRA_PAYOUT',
    'DEADLINE',
    'PUBLICATION_DEADLINE_CHANGE',
    'LAUNCH',
    'ARCHIVATION',
    'DEADLINE_CHANGE_REQUEST_ACCEPTANCE',
    'CONTENT_SUBMISSION',
    'CONTENT_REVIEW_FAIL',
  ],
};

export default (
  data: MarkProjectEventsAsReadInput & MarkProjectEventsAsReadInputAdditional,
  resolve?: (response: MarkProjectEventsAsReadMutation$data) => void,
  reject?: fnType
) => {
  const { uptoEventId, projectId, ignoreCounterUpdate } = data;

  const variables = {
    input: {
      uptoEventId,
    },
  };

  commitMutation<MarkProjectEventsAsReadMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
    updater: (store) => {
      if (projectId) {
        const project = store.get(projectId);
        const chatUnreadEventsCount = project
          ?.getLinkedRecord('events', chatListFilters)
          ?.getValue('unreadCount');
        const campaignId = project?.getLinkedRecord('campaign')?.getDataID();
        if (campaignId) {
          const campaign = store.get(campaignId);
          if (!campaign) return;
          if (typeof chatUnreadEventsCount === 'number' && chatUnreadEventsCount !== 0) {
            const organizationId = project
              ?.getLinkedRecord('campaign')
              ?.getLinkedRecord('organization')
              ?.getDataID();
            campaign
              ?.getLinkedRecord('counters')
              ?.setValue(0, 'unreadProjectEventsExcludingOfferAcceptance');
            if (!organizationId) return;
            const organization = store.get(organizationId);
            if (!organization) return;
            const projectConnection = organization?.getLinkedRecord('projects', {
              stages: [
                'ACCEPTED',
                'LAUNCHED',
                'CONTENT_SUBMITTED',
                'PUBLISHING_STARTED',
                'COMPLETED',
              ],
              withUnreadEvents: true,
            });
            if (projectConnection && !ignoreCounterUpdate) {
              const counter = Number(projectConnection.getValue('totalCount')) || 0;
              if (counter > 0) {
                projectConnection.setValue(counter - 1, 'totalCount');
              }
            }
          }
        }
        if (typeof chatUnreadEventsCount === 'number' && chatUnreadEventsCount > 0) {
          project?.getLinkedRecord('events', chatListFilters)?.setValue(0, 'unreadCount');
        }
      }
    },
  });
};

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import { ISSUED } from 'Constants/general';
import {
  Accounting_MarkInvoiceAsIssuedInput,
  MarkInvoiceAsIssuedMutation$data,
  MarkInvoiceAsIssuedMutation,
} from 'GraphTypes/MarkInvoiceAsIssuedMutation.graphql';

const mutation = graphql`
  mutation MarkInvoiceAsIssuedMutation($input: Accounting_MarkInvoiceAsIssuedInput!) {
    accounting {
      markInvoiceAsIssued(input: $input) {
        clientMutationId
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Accounting_MarkInvoiceAsIssuedInput,
  resolve?: (response: MarkInvoiceAsIssuedMutation$data) => void,
  reject?: fnType
) => {
  const { revenueTransactionId } = data;

  const variables = {
    input: {
      revenueTransactionId,
    },
  };

  commitMutation<MarkInvoiceAsIssuedMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const root = store.get('client:root');
      const accounting = root?.getLinkedRecord('accounting');
      if (!accounting) return;
      const transactions = ConnectionHandler.getConnection(accounting, 'Admin_revenueTransactions');
      if (!transactions) return;
      const transactionItem = transactions
        .getLinkedRecords('edges')
        ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === revenueTransactionId);
      if (transactionItem) {
        const transactionItemNode = transactionItem.getLinkedRecord('node');
        if (!transactionItemNode) return;
        transactionItemNode.setValue(ISSUED, 'invoiceStatus');
      }
    },
    updater: (store) => {
      const root = store.get('client:root');
      const accounting = root?.getLinkedRecord('accounting');
      if (!accounting) return;
      const transactions = ConnectionHandler.getConnection(accounting, 'Admin_revenueTransactions');
      if (!transactions) return;
      const transactionItem = transactions
        .getLinkedRecords('edges')
        ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === revenueTransactionId);
      if (transactionItem) {
        const transactionItemNode = transactionItem.getLinkedRecord('node');
        if (!transactionItemNode) return;
        transactionItemNode.setValue(ISSUED, 'invoiceStatus');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from '../ShopifyOrderCreationDrawer.pcss';
import { OnOrderLostConnectionError } from '../ShopifyOrderCreationDrawer';

import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Text from 'Components/ui/Text/Text';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Button from 'Components/ui/Button/Button';
import { ProductListProps } from 'Components/ProductList';
import { flattenSelectedProductVariants } from 'Components/ProductList/utils';
import { ProductListContainer } from 'Components/ProductList/ProductListContainer';

type Props = {
  selectedProducts: NonNullable<ProductListProps['products']>;
  className?: string;
  onNextButtonClick?: () => void;
  onProductVariantSelectionChange?: NonNullable<
    ProductListProps['onProductVariantSelectionChange']
  >;
  campaignId: string;
  organizationId: string;
  creatorsCount: number;
  onOrderLostConnectionError?: OnOrderLostConnectionError;
  setCurrencyCode: (code: string) => void;
};

const SelectProduct: React.FC<Props> = (props) => {
  const {
    className,
    onProductVariantSelectionChange,
    onNextButtonClick,
    organizationId,
    campaignId,
    creatorsCount,
    selectedProducts,
    onOrderLostConnectionError,
    setCurrencyCode,
  } = props;
  const [productCount, setProductVariantsCount] = useState<number>(0);

  const isFormValid = productCount > 0;

  useEffect(() => {
    const count = flattenSelectedProductVariants(selectedProducts).length;
    setProductVariantsCount(count);
  }, [selectedProducts]);

  const scrollRef = useRef(null);

  const handleNextButtonClick = useCallback(() => {
    if (!isFormValid) return;
    onNextButtonClick?.();
  }, [onNextButtonClick, isFormValid]);

  const handleProductVariantSelectionChange = useCallback<HandleProductVariantSelectionChange>(
    (args) => {
      const count = args.productVariants.length;
      setProductVariantsCount(count);
      onProductVariantSelectionChange?.(args);
    },
    [onProductVariantSelectionChange]
  );

  return (
    <div className={classnames(styles.contentRoot, className)}>
      <TextPreset
        header={
          <Text
            type="d2"
            msg={
              creatorsCount === 1
                ? 'shopify_order_creation_drawer.select_product.title'
                : 'shopify_order_creation_drawer.select_product.title.plural'
            }
            formatValues={{ count: creatorsCount }}
          />
        }
        subtitle={
          <Text
            type="md"
            className={styles.textColorGrey100}
            formatValues={{ count: productCount }}
            msg={`shopify_order_creation_drawer.select_product.product_selection${
              productCount > 1 ? '.plural' : ''
            }`}
          />
        }
      />
      <div className={styles.content} ref={scrollRef}>
        <ProductListContainer
          campaignId={campaignId}
          organizationId={organizationId}
          onProductVariantSelectionChange={handleProductVariantSelectionChange}
          selectedProducts={selectedProducts}
          projectsCount={creatorsCount}
          onOrderLostConnectionError={onOrderLostConnectionError}
          setCurrencyCode={setCurrencyCode}
          scrollRef={scrollRef}
        />
      </div>
      <ButtonPreset className={styles.buttonWrapper}>
        <div className={styles.clicker} onClick={handleNextButtonClick}>
          <Button
            fluid
            color="black"
            disabled={!isFormValid}
            dataTest="button:orderCreation:next"
            className={styles.nextButton}
            msg="shopify_order_creation_drawer.next_button"
          />
        </div>
      </ButtonPreset>
    </div>
  );
};

export { SelectProduct };

// types

type HandleProductVariantSelectionChange = NonNullable<
  ProductListProps['onProductVariantSelectionChange']
>;

export type { Props as SelectProductProps };

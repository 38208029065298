import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AdminAdditionalControls.css';

import {
  startCreatorsBulkAdding,
  addCreatorsToBulk,
  setCreatorsBulkCount,
} from 'Actions/CreatorSearch.Actions';
import { StateProps } from 'Reducers/CreatorsSearch.Reducer';
import Button from 'Atoms/Button/Button';
import { SearchResults_campaign } from 'GraphTypes/SearchResults_campaign.graphql';

interface Props {
  campaignId: string;
  hasMore?: boolean;
  onClickNext?: () => void;
  list?: NonNullable<SearchResults_campaign['searchCreators']>['edges'] | null;
}

const AdminAdditionalControls: React.FC<Props> = (props) => {
  const { onClickNext, hasMore, list, campaignId } = props;

  const creatorIdsForExclusion = useSelector(
    (state: StateProps) => state.root.creatorSearch.creatorIdsForExclusion
  );
  const creatorIdsForAdding = useSelector(
    (state: StateProps) => state.root.creatorSearch.creatorIdsForAdding
  );

  const dispatch = useDispatch();

  const creatorIdsForExclusionList = creatorIdsForExclusion.map((item) => item.creatorId);

  const formattedList = useMemo(
    () =>
      list?.reduce((acc: string[], item, index) => {
        if (index > 199) return acc;
        const creatorId = item?.node?.creator?.id;
        if (
          !item?.node?.project?.id &&
          creatorId &&
          !creatorIdsForExclusionList.includes(creatorId) &&
          !creatorIdsForAdding.includes(creatorId)
        ) {
          acc.push(creatorId);
        }
        return acc;
      }, []),
    [list]
  );

  const count = formattedList?.length || 0;

  const handleStartBulkAdd = () => {
    if (!formattedList) return;
    dispatch(setCreatorsBulkCount(formattedList.length));
    dispatch(addCreatorsToBulk({ creatorIds: formattedList }));
    dispatch(startCreatorsBulkAdding({ campaignId }));
  };

  return (
    <div className={styles.root}>
      {hasMore && (
        <Button
          color="normal"
          msg="general.show_more"
          size="lg"
          className={styles.button}
          onClick={onClickNext}
        />
      )}
      {count > 0 && (
        <Button
          msg="browse_creators.bulk_add.count"
          msgValues={{ count: count > 200 ? 200 : count }}
          size="lg"
          className={styles.button}
          onClick={handleStartBulkAdd}
        />
      )}
    </div>
  );
};

export default AdminAdditionalControls;

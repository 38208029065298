import { graphql } from 'react-relay';

import type { ShopifyAuthDrawerQuery as ShopifyAuthDrawerQueryType } from 'GraphTypes/ShopifyAuthDrawerQuery.graphql';

const ShopifyAuthDrawerQuery = graphql`
  query ShopifyAuthDrawerQuery {
    currentUser {
      id
      organization {
        shopifyAuthorization {
          reauthenticationRequired
        }
      }
    }
  }
`;

export { ShopifyAuthDrawerQuery };

// types

export type { ShopifyAuthDrawerQueryType };

import React, { useMemo } from 'react';

import styles from '../CreatorProfile.pcss';

import Checkbox from 'Atoms/Checkbox/Checkbox';
import Field from 'Molecules/Field/Field';

interface Props {
  allCategories: {
    id: string;
    name: string;
    description: string | null;
  }[];
  creatorCategories: {
    id: string;
    name: string;
  }[];
  maxCategoriesToChoose: number;
  onChange: (data: any) => void;
}

const CreatorCategories: React.FC<Props> = (props) => {
  const { allCategories, creatorCategories, maxCategoriesToChoose, onChange } = props;
  const categoriesList = useMemo(() => {
    return allCategories.map((item) => {
      const isActive = creatorCategories.find((selectedItem) => selectedItem.id === item.id);
      const isDisabled = !isActive && creatorCategories.length >= maxCategoriesToChoose;
      const optionText = (
        <div className={styles.option}>
          <div className={styles.optionName}>{item.name}</div>
          {item.description && <div className={styles.optionDescr}>{item.description}</div>}
        </div>
      );

      return {
        value: item.id,
        id: item.id,
        text: optionText,
        label: item.name,
        leftIcon: <Checkbox active={!!isActive} />,
        disableNativeIcon: true,
        isDisabled: isDisabled,
      };
    });
  }, [allCategories, creatorCategories]);

  const disabled = useMemo(() => {
    return creatorCategories.length > 0;
  }, []);

  const creatorCategoriesList = creatorCategories.map((item) => {
    return { value: item.id, label: item.name };
  });

  return (
    <Field
      name="categories"
      element="select"
      elementData={{
        isMulti: true,
        hideSelectedOptions: false,
        options: categoriesList,
        value: creatorCategoriesList,
        placeholderMsg: 'creator_profile.categories.placeholder',
        onChange: onChange,
      }}
      disabled={disabled}
      title="creator_profile.categories"
      titleValues={{
        amount: creatorCategoriesList.length,
        total: maxCategoriesToChoose,
      }}
      className={styles.field}
    />
  );
};

export default CreatorCategories;

import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import styles from './Brands.pcss';
import BrandsList from './BrandsList/BrandsList';
import BrandsLimit from './BrandsLimit/BrandsLimit';

import colors from 'Styles/vars/colors.css';
import environment from 'Api/Environment';
import { BILLING_ROUTE, HELLO_EMAIL } from 'Constants/general';
import Spinner from 'Atoms/Spinner/Spinner';
import { BrandsQuery as QueryType } from 'GraphTypes/BrandsQuery.graphql';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { amplitude } from 'Helpers/amplitude';

const BrandsQuery = graphql`
  query BrandsQuery($brandName: String) {
    ...BrandsList_brands @arguments(brandName: $brandName)
    brandCount: brands {
      totalCount
    }
    currentUser {
      admin
      organization {
        counters {
          activeBrands
        }
        subscription {
          effectiveLimits {
            maxActiveBrands
          }
          planId
        }
      }
    }
    brandCategories {
      id
      name
    }
  }
`;

const BrandsContainer: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [brandName, setTextQuery] = useState('');

  const [limitModal, setLimitModalStatus] = useState(false);
  const [activeCampaignsModal, setActiveCampaignsModalStatus] = useState(false);
  const [changePlanModal, setChangePlanModalStatus] = useState(false);
  const [filterLoading, setFilterLoading] = useState(true);

  useEffect(() => {
    amplitude.sendEvent({
      id: '179',
      category: 'pageview',
      name: 'brands_dashboard',
    });
  }, []);

  const openLimitModal = useCallback(() => {
    setLimitModalStatus(true);
  }, []);
  const closeLimitModal = useCallback(() => {
    setLimitModalStatus(false);
  }, []);
  const openActiveCampaignModal = useCallback(() => {
    setActiveCampaignsModalStatus(true);
  }, []);
  const closeActiveCampaignModal = useCallback(() => {
    setActiveCampaignsModalStatus(false);
  }, []);
  const openChangePlanModal = useCallback(() => {
    setChangePlanModalStatus(true);
    amplitude.sendEvent({
      id: '139',
      category: 'brand_management_dashboard',
      name: 'change_plan',
    });
  }, []);
  const closeChangePlanModal = useCallback(() => {
    setChangePlanModalStatus(false);
  }, []);

  const handleFilterLoading = useCallback(() => {
    setFilterLoading(false);
  }, []);

  let brandsEl: JSX.Element;

  const preloader = (
    <div className={styles.preloaderContainer}>
      <Spinner color={colors.colorDark} className={styles.preloader} />
    </div>
  );

  const handleQueryChange = (newTextQuery: string) => {
    setTextQuery(newTextQuery);
  };

  const openDashboard = () => {
    navigate('/dashboard');
  };

  const openBillingPlans = () => {
    navigate('/billing/plans');
  };

  const openBillingAddons = () => {
    navigate(BILLING_ROUTE, { state: { addon: 'maxActiveBrands' } });
  };

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <Text type="d2" msg="brands.dashboard.title" />
        </header>
        <aside className={styles.params}>
          <Suspense fallback={preloader}>
            <BrandsLimit
              brandName={brandName}
              onQueryChange={handleQueryChange}
              increaseLimit={openBillingAddons}
              changePlan={openChangePlanModal}
              onLoadCompleted={handleFilterLoading}
            />
          </Suspense>
        </aside>
        <QueryRenderer<QueryType>
          environment={environment}
          query={BrandsQuery}
          variables={{ brandName }}
          render={({ props: queryProps }) => {
            if (!queryProps) return filterLoading ? null : preloader;
            const isAdmin = queryProps.currentUser?.admin;
            const activeCount = queryProps.currentUser?.organization?.counters?.activeBrands || 0;
            const totalCount =
              queryProps.currentUser?.organization?.subscription?.effectiveLimits.maxActiveBrands ||
              null;
            const planId = queryProps.currentUser?.organization?.subscription?.planId || 'your';

            const brandCategories = queryProps.brandCategories;

            const isFreeUser = planId === 'FREE';
            const planNameVal = intl.formatMessage({
              id: `plan.name.${planId.toLowerCase()}`,
            });

            return (
              <div className={styles.content}>
                <BrandsList
                  key={totalCount}
                  preloader={preloader}
                  brands={queryProps}
                  isAdmin={isAdmin}
                  brandName={brandName}
                  openActiveCampaignModal={openActiveCampaignModal}
                  openLimitModal={openLimitModal}
                  canAddNewBrand={totalCount === null || activeCount < totalCount}
                  disabled={isFreeUser}
                  brandCategories={brandCategories}
                />
                <Drawer
                  opened={limitModal}
                  onClose={closeLimitModal}
                  rootKey="limit-modal"
                  className={styles.drawer}
                >
                  <div className={styles.drawerContainer}>
                    <div className={styles.drawerTitle}>
                      <Text
                        type="d2"
                        msg="brands.dashboard.limits.modal.title"
                        className={styles.drawerTitleText}
                      />
                      {totalCount !== null && (
                        <Text
                          type="md"
                          msg="brands.dashboard.limits.modal.description"
                          formatValues={{
                            email: <a href={`mailto: ${HELLO_EMAIL}`}>{HELLO_EMAIL}</a>,
                            activeCount: totalCount,
                            planId: planNameVal,
                          }}
                        />
                      )}
                    </div>
                    <Button
                      color="black"
                      msg="brands.dashboard.limits.modal.button"
                      onClick={openBillingPlans}
                    />
                  </div>
                </Drawer>
              </div>
            );
          }}
        />
        <Drawer
          opened={activeCampaignsModal}
          onClose={closeActiveCampaignModal}
          rootKey={'active-campaign-modal'}
          className={styles.drawer}
        >
          <div className={styles.drawerContainer}>
            <div className={styles.drawerTitle}>
              <Text
                type="d2"
                msg="brands.dashboard.activate.modal.title"
                className={styles.drawerTitleText}
              />
              <Text type="md" msg="brands.dashboard.activate.modal.description" />
            </div>
            <Button
              color="black"
              msg="brands.dashboard.activate.modal.button"
              onClick={openDashboard}
            />
          </div>
        </Drawer>
        <Drawer
          opened={changePlanModal}
          onClose={closeChangePlanModal}
          rootKey={'change-plan-modal'}
          className={styles.drawer}
        >
          <div className={styles.drawerContainer}>
            <div className={styles.drawerTitle}>
              <Text
                type="d2"
                msg="brands.dashboard.changePlan.modal.title"
                className={styles.drawerTitleText}
              />
              <Text type="md" msg="brands.dashboard.changePlan.modal.description" />
            </div>
            <Button
              color="black"
              msg="brands.dashboard.changePlan.modal.button"
              onClick={openBillingPlans}
            />
          </div>
        </Drawer>
      </div>
    </Page>
  );
};

export default track({
  section: 'brands',
})(BrandsContainer);

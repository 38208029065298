import React from 'react';

import Button from 'Atoms/Button/Button';
import TextButton from 'Atoms/TextButton/TextButton';
import DropDown from 'Molecules/DropDown/DropDown';
import { IconMenuType, ButtonType } from 'Types/common';

const defaultBtnProps: Pick<ButtonType, 'color'> = {
  color: 'normal',
};

interface Props {
  className?: string;
}

const IconMenu: React.FC<IconMenuType & Props> = (props) => {
  const { className, textBtnData, btnData, dropDownData } = props;

  let anchor;

  if (btnData) {
    anchor = <Button {...defaultBtnProps} {...btnData} />;
  } else {
    anchor = <TextButton {...defaultBtnProps} {...textBtnData} />;
  }

  return <DropDown className={className} anchor={anchor} {...dropDownData} />;
};

export default IconMenu;

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './CreatorAdminPanel.css';
import CreatorPastProjects from './CreatorPastProjects/CreatorPastProjects';
import PaidSocialError from './PaidSocialError/PaidSocialError';
import InstagramInsightsError from './InstagramInsightsError/InstagramInsightsError';
import ProfileSettings from './ProfileSettings/ProfileSettings';
import ModerationSettings from './ModerationSettings/ModerationSettings';

import Text from 'Atoms/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import environment from 'Api/Environment';
import { CreatorAdminPanelQuery as QueryType } from 'GraphTypes/CreatorAdminPanelQuery.graphql';
import { CreatorStatisticQuery$data } from 'GraphTypes/CreatorStatisticQuery.graphql';

const CreatorAdminPanelQuery = graphql`
  query CreatorAdminPanelQuery($creatorId: ID!, $currency: Currency) {
    creator(id: $creatorId) {
      ...PaidSocialError_creator
      ...InstagramInsightsError_creator
      ...ProfileSettings_creator
      ...ModerationSettings_creator
      ... on InstagramCreator {
        paidSocialRightsVerification {
          verifiedAt
        }
      }
    }
    ethnicities {
      id
      name
    }
    tags(currency: $currency) {
      id
      name
    }
    genders(currency: $currency) {
      id
      name
    }
    animals {
      id
      name
    }
    hairTypes {
      id
      name
    }
    bodyTypes {
      id
      name
    }
    languages {
      id
      name
    }
    categories(currency: USD) {
      id
      name
      description
    }
    settings {
      maxMandatoryCategoriesCount
    }
  }
`;

interface Props {
  creatorId: string;
  currency: NonNullable<NonNullable<CreatorStatisticQuery$data['creator']>['profile']>['currency'];
}

const CreatorAdminPanel: React.FC<Props> = (props) => {
  const { creatorId, currency } = props;

  return (
    <div>
      <QueryRenderer<QueryType>
        environment={environment}
        query={CreatorAdminPanelQuery}
        variables={{ creatorId, currency }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner className={styles.preloader} />;

          const creator = queryProps.creator;

          if (!creator) return null;

          const ethnicities = queryProps.ethnicities;
          const genders = queryProps.genders;
          const tags = queryProps.tags;
          const animals = queryProps.animals;
          const hairTypes = queryProps.hairTypes;
          const bodyTypes = queryProps.bodyTypes;
          const languages = queryProps.languages;
          const settings = queryProps.settings;
          const categories = queryProps.categories;

          return (
            <div className={styles.root}>
              <Text
                tag="h1"
                weight="700"
                msg="creator_statistic.settings.title"
                className={styles.title}
              />
              <ProfileSettings
                creator={creator}
                categories={categories}
                settings={settings}
                genders={genders}
              />
              <ModerationSettings
                creator={creator}
                ethnicities={ethnicities}
                tags={tags}
                animals={animals}
                hairTypes={hairTypes}
                bodyTypes={bodyTypes}
                languages={languages}
              />
              <PaidSocialError creator={creator} />
              <InstagramInsightsError creator={creator} />
            </div>
          );
        }}
      />
      <CreatorPastProjects creatorId={creatorId} />
    </div>
  );
};

export default CreatorAdminPanel;

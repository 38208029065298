import React, { useEffect } from 'react';
import track from 'react-tracking';

import styles from './SnapchatAuth.pcss';

import authenticateWithSnapchat from 'Mutations/AuthenticateWithSnapchat.Mutation';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import Page from 'Templates/Page/Page';
import Spinner from 'Atoms/Spinner/Spinner';
import ErrorHandler from 'Util/errorHandler';

const SnapchatAuth = () => {
  useEffect(() => {
    const { code, error } = getQueryLocationParams(location.search);
    authenticateWithSnapchat(
      { code: (code as string) || null, error: (error as string) || null },
      handleSuccess,
      handleFail
    );
  }, []);

  const handleSuccess = () => {
    if (window.opener?.callSuccess) {
      window.opener.callSuccess();
      window.close();
    }
  };

  const handleFail = (error: Error) => {
    ErrorHandler.error('Crash while snapchat login', { error });
    window.close();
  };

  return (
    <Page className={styles.root}>
      <Spinner />
    </Page>
  );
};

export default track({ page: 'snapchat_auth' }, { dispatchOnMount: true })(SnapchatAuth);

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import styles from './ChangeAddonsDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import Icon from 'Components/ui/Icon/Icon';
import { IconName } from 'Components/ui/types';
import { SubscriptionParams } from 'Page/advertiser/BillingPlans/BillingPlans';
import { AddonSettings, addonsKeyMap } from 'Page/advertiser/BillingPlans/data';
import { SubscriptionLimits_organization$data } from 'GraphTypes/SubscriptionLimits_organization.graphql';
import { AddonItemInput } from 'GraphTypes/BuySubscriptionViaStripeMutation.graphql';
import { AddonId } from 'GraphTypes/PreviewProrationMutation.graphql';
import { amplitude } from 'Helpers/amplitude';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  icon: IconName;
  type: 'maxActiveBrands' | 'maxSeats' | 'maxBcaConnections';
  disabledManage?: boolean;
  defaultValue?: number;
  addons: AddonSettings[];
  subscriptionData: SubscriptionLimits_organization$data['subscription'];
  defaultLimit?: number | null;
  onParamsChange: (params: SubscriptionParams) => void;
}

const typeToMsgMap = {
  maxActiveBrands: 'brands',
  maxSeats: 'seats',
  maxBcaConnections: 'licenses',
};

const ChangeAddonsDrawer: React.FC<Props> = (props) => {
  const {
    type,
    icon,
    disabledManage = false,
    defaultValue = 1,
    addons,
    subscriptionData,
    defaultLimit,
    onParamsChange,
  } = props;

  const addon = addons.find((addon) => addonsKeyMap[addon.key] === type) as AddonSettings;
  const minValue = typeof defaultLimit === 'number' ? defaultLimit : addon.minValue;
  const { state } = useLocation() as {
    state: {
      addon?: 'maxActiveBrands' | 'maxSeats' | 'maxBcaConnections';
    };
  };

  useEffect(() => {
    if (state?.addon && !disabledManage) {
      if (state.addon === type) {
        setDrawerStatus(true);
      }
    }
  }, [state, disabledManage]);
  const price = addon.price;
  const stepCount = addon.stepCount || 1;
  const [openedDrawer, setDrawerStatus] = useState(false);
  const [count, setCount] = useState(stepCount);

  if (!subscriptionData) {
    return null;
  }

  const {
    planId: currentPlanId,
    interval,
    availableAddonIds,
    addonItems,
    paused,
  } = subscriptionData;
  const userAddonData = (addonKey: AddonId) => {
    return addonItems.find((item) => {
      return addonKey === item?.addon.id;
    });
  };

  const handleDrawerOpen = () => {
    setDrawerStatus(true);
    amplitude.sendEvent<367>({
      id: '367',
      category: 'billing',
      name: 'add_more_addons_click',
      param: {
        addon_name: addon.key,
        addon_count: defaultValue / stepCount,
      },
    });
  };

  const handleDrawerClose = () => {
    setDrawerStatus(false);
  };

  const handleBuyClick = () => {
    const userChangedAddons: [AddonItemInput] | [] = availableAddonIds.reduce((list, addonId) => {
      if (addonId === addon.key) {
        const userAddonStripeData = userAddonData(addon.key);
        let purchasedAddonCount = 0;
        if (userAddonStripeData) {
          purchasedAddonCount = userAddonStripeData.quantity;
        }
        return [
          ...list,
          {
            addonId,
            quantity: count / stepCount + purchasedAddonCount,
          },
        ];
      }
      const unchangedAddon = addons.find((addon) => addon.key === addonId) as AddonSettings;
      if (!unchangedAddon) return list;
      let unchangedAddonQuantity = 0;
      const userAddonStripeData = userAddonData(unchangedAddon.key);
      if (userAddonStripeData) {
        unchangedAddonQuantity = userAddonStripeData.quantity;
        if (unchangedAddonQuantity) {
          return [
            ...list,
            {
              addonId,
              quantity: unchangedAddonQuantity,
            },
          ];
        }
      }
      return list;
    }, []);

    if (!interval) return;

    const params = {
      planId: currentPlanId,
      interval,
      addonItems: userChangedAddons.length > 0 ? userChangedAddons : undefined,
    };

    onParamsChange(params);
    setDrawerStatus(false);
  };

  const handleDecreaseClick = () => {
    const newValue = count - stepCount;
    if (newValue === 0) {
      if (minValue === newValue + defaultValue) {
        return;
      }
      setCount(-1 * stepCount);
    } else if (minValue <= newValue + defaultValue) {
      setCount(newValue);
    }
  };

  const handleIncreaseClick = () => {
    const newValue = count + stepCount;
    if (newValue === 0) {
      setCount(stepCount);
    } else {
      setCount(count + stepCount);
    }
  };

  const disabled = disabledManage || paused;

  return (
    <>
      <Tooltip
        id={`billing.limits.${type}`}
        tooltipMsg={paused ? `billing.limits.${typeToMsgMap[type]}.pause_tooltip` : undefined}
        place={'top'}
      >
        <AlterButton
          type="black"
          icon={disabled ? 'Lock' : 'Add-plus-circle'}
          msg={'billing.limits.add_more'}
          disabled={disabled}
          onClick={handleDrawerOpen}
          className={styles.addButton}
          fluid
        />
      </Tooltip>
      <Drawer
        rootKey={`${type}_addon`}
        className={styles.drawerWrapper}
        containerClassName={styles.drawerContainer}
        backdropClassName={styles.drawerBackdrop}
        opened={openedDrawer}
        needCloseButton
        removeWhenClosed
        onClose={handleDrawerClose}
      >
        <div className={styles.root}>
          <div className={styles.formContainer}>
            <Text type="d2" msg="billing.addons.drawer.title" className={styles.title} />
            <Icon name={icon} size={36} />
            <Text
              type="d2"
              msg={`billing.addons.drawer.${typeToMsgMap[type]}`}
              className={styles.addonType}
            />
            <Text
              type="md"
              msg={`billing.addons.drawer.${typeToMsgMap[type]}.description`}
              className={styles.addonDescription}
            />
            <Text
              type="md"
              msg={`billing.addons.drawer.${typeToMsgMap[type]}.price`}
              formatValues={{ price }}
              className={styles.addonPrice}
            />
            <div className={styles.addonCounter}>
              <div className={styles.addonCounterButtonContainer}>
                <div
                  className={classNames(styles.addonCounterButton, styles.buttonMinus)}
                  onClick={handleDecreaseClick}
                />
                <Text type="d1" className={styles.addonCounterValue} text={count} />
                <div
                  className={classNames(styles.addonCounterButton, styles.buttonPlus)}
                  onClick={handleIncreaseClick}
                />
              </div>
            </div>
            <div className={styles.addonPriceContainer}>
              <Text
                type="d2"
                text={`${
                  price * count > 0 ? `+$${price * count}` : `-$${Math.abs(price * count)}`
                }`}
              />
              <Text type="md" msg="billing.addons.drawer.price.per_month" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Text
              type="sm"
              formatValues={{ count: count + defaultValue }}
              msg={`billing.addons.drawer.${typeToMsgMap[type]}.counter`}
            />
            <AlterButton
              type="black"
              fluid
              msg={`billing.addons.drawer.${typeToMsgMap[type]}.button${
                count < 0 ? '.remove' : ''
              }`}
              formatValues={{ count: Math.abs(count) }}
              classes={{ buttonText: styles.buttonText }}
              onClick={handleBuyClick}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ChangeAddonsDrawer;

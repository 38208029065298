import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import { useQueryLoader } from 'react-relay';

import styles from './Layout.pcss';
import Modal from './Modal/Modal';
import Header from './Header/Header';
import Content from './Content/Content';
import Preloader from './Preloader/Preloader';
import Notifications from './Notifications/Notifications';
import Footer from './Footer/Footer';
import Navigation from './Navigation';
import { query } from './Navigation/Navigation.Query';

import UserProvider from 'Containers/UserProvider/UserProvider';
import DrawerContainer from 'Containers/Drawer/DrawerContainer';
import useDashly from 'Hooks/useDashly';

interface Props {
  className?: string;
}

const Layout: React.FC<Props> = (props) => {
  const { className } = props;
  const { connect: connectDashly } = useDashly();

  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query);

  useEffect(() => {
    loadQuery({});
  }, []);

  useEffect(() => {
    connectDashly(process.env.DASHLY_API_KEY);
  }, [connectDashly]);

  return (
    <DrawerContainer>
      <div className={cn(className, styles.root)}>
        <Header className={styles.header} />
        <div className={styles.content}>
          {!queryReference ? null : (
            <Suspense fallback={null}>
              <Navigation queryReference={queryReference} />
            </Suspense>
          )}

          <Content>
            <Outlet />
          </Content>
        </div>
        <Footer />
        <Modal />
        <Preloader />
        <Notifications removeDelay={7000} />
        <UserProvider />
      </div>
    </DrawerContainer>
  );
};

export default Layout;

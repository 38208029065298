/**
 * @generated SignedSource<<303bde5e8fd45f75724b968a25aad949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorImportRowError = "INVALID_EMAIL" | "INVALID_USERNAME" | "SOCIAL_NETWORK_API_ERROR" | "UNKNOWN_ERROR" | "%future added value";
export type OutreachBatchState = "ACTIVE" | "INITIAL" | "%future added value";
export type OutreachTransport = "GMAIL" | "SENDGRID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OutreachBatch_outreachBatch$data = {
  readonly creatorImport: {
    readonly completed: boolean;
    readonly createdAt: any;
    readonly csvFilename: string | null | undefined;
    readonly importedCreators: number;
    readonly rows: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email: string | null | undefined;
          readonly error: CreatorImportRowError | null | undefined;
          readonly id: string;
          readonly username: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
    readonly totalCreators: number;
  } | null | undefined;
  readonly gmailAuthorization: {
    readonly id: string;
    readonly reauthenticationRequired: boolean;
  } | null | undefined;
  readonly id: string;
  readonly state: OutreachBatchState;
  readonly template: {
    readonly name: string;
  } | null | undefined;
  readonly transport: OutreachTransport;
  readonly " $fragmentType": "OutreachBatch_outreachBatch";
};
export type OutreachBatch_outreachBatch$key = {
  readonly " $data"?: OutreachBatch_outreachBatch$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutreachBatch_outreachBatch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutreachBatch_outreachBatch",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GmailAuthorization",
      "kind": "LinkedField",
      "name": "gmailAuthorization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reauthenticationRequired",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorImport",
      "kind": "LinkedField",
      "name": "creatorImport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "csvFilename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "importedCreators",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCreators",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorImportRowConnection",
          "kind": "LinkedField",
          "name": "rows",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreatorImportRowEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CreatorImportRow",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "error",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutreachBatch",
  "abstractKey": null
};
})();

(node as any).hash = "20d1cbef0692c395fac4dd2bab53fe6c";

export default node;

/**
 * @generated SignedSource<<3b765ebc9b7af7ae08a48d4e5609a7bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortfolioItem_portfolioItem$data = {
  readonly caption: string | null | undefined;
  readonly file: {
    readonly filename: string | null | undefined;
    readonly id: string;
    readonly originalFilename: string | null | undefined;
    readonly processingStatus?: FileProcessingStatus | null | undefined;
    readonly secureUrl: string;
    readonly thumbnailUrl: string;
    readonly transformations?: {
      readonly autoUrl: string;
      readonly collageThumbnailUrl: string;
    } | null | undefined;
    readonly type: FileType;
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly portfolioCategories: ReadonlyArray<{
    readonly name: string;
  }> | null | undefined;
  readonly portfolioTypes: ReadonlyArray<{
    readonly name: string;
  }> | null | undefined;
  readonly " $fragmentType": "PortfolioItem_portfolioItem";
};
export type PortfolioItem_portfolioItem$key = {
  readonly " $data"?: PortfolioItem_portfolioItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioItem_portfolioItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioItem_portfolioItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalFilename",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secureUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "processingStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "autoUrl",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Video",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Image",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PortfolioType",
      "kind": "LinkedField",
      "name": "portfolioTypes",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PortfolioCategory",
      "kind": "LinkedField",
      "name": "portfolioCategories",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "PortfolioItem",
  "abstractKey": null
};
})();

(node as any).hash = "677adc9a1734ee41360ee7d07e258247";

export default node;

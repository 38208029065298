import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';
import { OrganizationBalanceUpdatedSubscription } from 'GraphTypes/OrganizationBalanceUpdatedSubscription.graphql';

const subscription = graphql`
  subscription OrganizationBalanceUpdatedSubscription {
    organizationBalanceUpdated {
      paymentAccount {
        balance
      }
    }
  }
`;


export default () => {
  requestSubscription<OrganizationBalanceUpdatedSubscription>(environment, {
    subscription,
    variables: {},
  });
};

/**
 * @generated SignedSource<<33eae3bc00f2df979a32ead686753bab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BlockCreatorInput = {
  clientMutationId?: string | null | undefined;
  creatorId: string;
  id?: string | null | undefined;
  organizationId?: string | null | undefined;
};
export type BlockCreatorMutation$variables = {
  input: BlockCreatorInput;
};
export type BlockCreatorMutation$data = {
  readonly blockCreator: {
    readonly organization?: {
      readonly amount: {
        readonly totalCount: number;
      } | null | undefined;
    };
  } | null | undefined;
};
export type BlockCreatorMutation = {
  response: BlockCreatorMutation$data;
  variables: BlockCreatorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": "amount",
  "args": null,
  "concreteType": "CreatorConnection",
  "kind": "LinkedField",
  "name": "blockedCreators",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "blockCreator",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "BlockCreatorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "blockCreator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "BlockCreatorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bbe624f914409018a955f2e562a25a95",
    "id": null,
    "metadata": {},
    "name": "BlockCreatorMutation",
    "operationKind": "mutation",
    "text": "mutation BlockCreatorMutation(\n  $input: BlockCreatorInput!\n) {\n  blockCreator(input: $input) {\n    __typename\n    ... on BlockCreatorPayload {\n      organization {\n        amount: blockedCreators {\n          totalCount\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb84132a8a244f9f58b03423deee9f42";

export default node;

import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './Text.css';

import modsClasses from 'Util/modsClasses.js';
import { TextType } from 'Types/common';

const MODS = ['align', 'color', 'display', 'size', 'transform', 'type', 'weight', 'overflow'];

const Text: React.FC<PropsWithChildren<TextType>> = (props) => {
  const { children, msg, text, values, style, tag = 'div', overflow } = props;

  const getInnerContent = () => {
    if (children) {
      return children;
    } else if (msg) {
      if (values) {
        return <FormattedMessage id={msg} values={values} />;
      }

      return (
        <FormattedMessage id={msg} values={values}>
          {(txt) => <span>{txt}</span>}
        </FormattedMessage>
      );
    } else if (text !== undefined && text !== null) {
      return text;
    }

    return null;
  };

  const classes = modsClasses(MODS, props, styles);
  const classList = classnames(classes, styles.root, styles[tag], { [styles.overflow]: overflow });
  const content = getInnerContent();
  const Tag = tag;

  return (
    <Tag className={classList} style={style}>
      {content}
    </Tag>
  );
};

Text.defaultProps = {
  tag: 'div',
} as Partial<TextType>;

export default Text;

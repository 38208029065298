/**
 * @generated SignedSource<<18ae908f609e17629e2b80f245c49fb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HideListingInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  listingId: string;
};
export type HideListingMutation$variables = {
  input: HideListingInput;
};
export type HideListingMutation$data = {
  readonly hideListing: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type HideListingMutation = {
  response: HideListingMutation$data;
  variables: HideListingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HideListingPayload",
    "kind": "LinkedField",
    "name": "hideListing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HideListingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HideListingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0553b33ba221d87392908d2b771f7d02",
    "id": null,
    "metadata": {},
    "name": "HideListingMutation",
    "operationKind": "mutation",
    "text": "mutation HideListingMutation(\n  $input: HideListingInput!\n) {\n  hideListing(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "83ef1e4a36cc5594f03bc71139e847d5";

export default node;

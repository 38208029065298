/**
 * @generated SignedSource<<1cf2c92c3f2340f24426612c1a85a9c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Creator_creator$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorDetails_creator">;
  readonly " $fragmentType": "Creator_creator";
};
export type Creator_creator$key = {
  readonly " $data"?: Creator_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"Creator_creator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Creator_creator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorDetails_creator"
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};

(node as any).hash = "cfc448ccbfae6b4f0b827f35b377877b";

export default node;

import React, { CSSProperties, MouseEvent, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import styles from './OptionsGroup.pcss';
import {
  initButtonsState,
  setSelectedItemToState,
  countSelectedOptions,
  filterStateBySelectedOptions,
} from './utils';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';

type Props = {
  className?: string;
  options?: Option[];
  selectable?: boolean;
  multipleSelection?: boolean;
  style?: CSSProperties;
  columnsMarkup?: 'one-column' | 'two-columns';
  onSelectionChange?: OnSelectionChange;
};

const OptionsGroup: React.FC<Props> = (props) => {
  const {
    className,
    options,
    style,
    selectable = true,
    multipleSelection = false,
    columnsMarkup = 'one-column',
    onSelectionChange,
  } = props;
  const [buttonsState, setButtonsState] = useState<ButtonsState>(initButtonsState(options));

  useEffect(() => {
    if (!selectable) {
      setButtonsState({});
      return;
    }
    if (selectable && !multipleSelection) {
      const count = countSelectedOptions(buttonsState);
      if (count > 1) {
        setButtonsState({});
      }
    }
  }, [selectable, multipleSelection]);

  const handleButtonClick = useCallback<HandleButtonClick>(
    (buttonIndex, e) => {
      if (selectable) {
        setButtonsState((prev) => {
          const result = setSelectedItemToState(prev, buttonIndex, multipleSelection);
          const selectedOptions = filterStateBySelectedOptions(result);
          setTimeout(() => onSelectionChange?.(selectedOptions), 0);
          return result;
        });
      }

      options?.[buttonIndex]?.button?.onClick?.(e);
    },
    [options, selectable, multipleSelection, buttonsState, onSelectionChange]
  );

  return (
    <div className={classnames(styles.root, className)} style={style}>
      <div
        className={classnames(styles.buttonsContainer, {
          [styles.oneColumn]: columnsMarkup === 'one-column',
          [styles.twoColumns]: columnsMarkup === 'two-columns',
        })}
      >
        {options?.map((option, key) => (
          <ButtonPreset key={key} type="alterbutton" className={styles.buttonPreset}>
            <AlterButton
              {...option.button}
              fluid
              dataTest="button:optionsGroup"
              onClick={(e) => {
                handleButtonClick(key, e);
              }}
              className={classnames(styles.alterButton, option.button?.className, {
                [styles.selected]: Boolean(buttonsState[key]?.selected),
              })}
            />
          </ButtonPreset>
        ))}
      </div>
    </div>
  );
};

export default OptionsGroup;
export { OptionsGroup };

// types

type AlterButtonProps = React.ComponentProps<typeof AlterButton>;
type ButtonIndex = number;
type HandleButtonClick = (buttonIndex: number, e: MouseEvent<HTMLButtonElement>) => void;
type ButtonsState = Record<ButtonIndex, { selected: boolean }>;
type OnSelectionChange<
  D extends Record<string, string> = {
    /**/
  }
> = (state: Record<ButtonIndex, { selected: boolean; data?: D }>) => void;
type Option<
  D extends Record<string, string> = {
    /**/
  }
> = {
  button?: Omit<Partial<AlterButtonProps>, 'fluid'>;
  selected?: boolean;
  data?: D;
};

export type { ButtonsState, Option, OnSelectionChange };

import React from 'react';
import cn from 'classnames';

import styles from './OutcomeStatus.pcss';

import outcomeStatusSuccess from 'Images/general/outcome_status_success.png';
import outcomeStatusErrorWithClock from 'Images/general/outcome_status_error_with_clock.png';
import outcomeStatusError from 'Images/general/outcome_status_error.png';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';

interface Props {
  className?: string;
  status?: 'success' | 'error-with-clock' | 'error';
  titleTextProps?: Partial<Pick<TextProps, 'text' | 'msg' | 'className'>>;
  subtitleTextProps?: Partial<Pick<TextProps, 'text' | 'msg' | 'className'>>;
  buttonProps?: Partial<Omit<ButtonProps, 'type'>>;
}

const OutcomeStatus: React.FC<Props> = (props) => {
  const { className, titleTextProps, subtitleTextProps, buttonProps, status = 'success' } = props;

  const imgSrc: ImgSrc = {
    success: outcomeStatusSuccess,
    'error-with-clock': outcomeStatusErrorWithClock,
    error: outcomeStatusError,
  };

  const defaultTitleText: DefaultTitleText = {
    success: 'Success',
    'error-with-clock': 'Error',
    error: 'Error',
  };

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.imgWrapper}>
        <img src={imgSrc[status]} className={styles.image} />
      </div>
      <TextPreset
        className={styles.textPreset}
        classes={{
          textContainer: styles.textContainer,
        }}
        header={
          <Text
            {...titleTextProps}
            text={titleTextProps?.text || defaultTitleText[status]}
            type="d1"
            className={cn(styles.title, titleTextProps?.className)}
          />
        }
        subtitle={
          subtitleTextProps && (
            <Text
              {...subtitleTextProps}
              type="h1"
              className={cn(styles.subtitle, subtitleTextProps?.className)}
            />
          )
        }
      />
      {buttonProps && (
        <ButtonPreset className={styles.buttonPreset}>
          <Button
            {...buttonProps}
            color="black"
            className={cn(styles.button, buttonProps?.className)}
          />
        </ButtonPreset>
      )}
    </div>
  );
};

export default OutcomeStatus;
export { OutcomeStatus };

// types

type TextProps = React.ComponentProps<typeof Text>;
type ButtonProps = React.ComponentProps<typeof Button>;
type ImgSrc = { [K in NonNullable<Props['status']>]: string };
type DefaultTitleText = { [K in NonNullable<Props['status']>]: string };

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import TransactionContainer from './TransactionContainer/TransactionContainer';

import environment from 'Api/Environment';
import Spinner from 'Atoms/Spinner/Spinner';
import { TransactionsListQuery as QueryType } from 'GraphTypes/TransactionsListQuery.graphql';
import {
  Billing_TransactionType,
  TransactionContainer_billingTransactions$key,
} from 'GraphTypes/TransactionContainer_billingTransactions.graphql';

export const TransactionsListQuery = graphql`
  query TransactionsListQuery(
    $dateFrom: DateTime
    $dateTo: DateTime
    $types: [Billing_TransactionType!]
    $campaignIds: [ID!]
    $brandIds: [ID!]
    $creatorIds: [ID!]
  ) {
    currentUser {
      organization {
        ...TransactionContainer_billingTransactions
          @arguments(
            dateFrom: $dateFrom
            dateTo: $dateTo
            types: $types
            campaignIds: $campaignIds
            brandIds: $brandIds
            creatorIds: $creatorIds
          )
      }
    }
  }
`;

interface Props {
  dateFrom?: Date | null;
  dateTo?: Date | null;
  types?: Billing_TransactionType[];
  campaignIds?: string[];
  brandIds?: string[];
  creatorIds?: string[];
  onParamsClear: () => void;
}

const TransactionsList: React.FC<Props> = (props) => {
  const { dateFrom, dateTo, types, campaignIds, brandIds, creatorIds, onParamsClear } = props;

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={TransactionsListQuery}
      variables={{
        dateFrom,
        dateTo,
        types,
        campaignIds,
        brandIds,
        creatorIds,
      }}
      render={({ props: queryProps }) => {
        if (!queryProps) return <Spinner style={{ margin: '40px auto' }} />;

        const billingTransactions = queryProps?.currentUser?.organization as
          | TransactionContainer_billingTransactions$key
          | null
          | undefined;

        return (
          <TransactionContainer
            dateFrom={dateFrom}
            dateTo={dateTo}
            types={types}
            campaignIds={campaignIds}
            creatorIds={creatorIds}
            brandIds={brandIds}
            billingTransactions={billingTransactions}
            onParamsClear={onParamsClear}
          />
        );
      }}
    />
  );
};

export default TransactionsList;

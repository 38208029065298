import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { fetchQuery } from 'react-relay';

import { Auth0Context, authSessionState } from '..';
import currentUserQuery from '../CurrentUser.Query';
import { isAlreadyRegisteredUser } from '../utils';
import { Auth0PostMessageType } from '../types';

import { getQueryLocationParams } from 'Util/encodeQueryData';
import { DASHBOARD_ROUTE, SIGNIN_ADVERTISER_ROUTE } from 'Constants/general';
import createAdvertiserProfile from 'Mutations/CreateAdvertiserProfile.Mutation';
import type { CreateAdvertiserProfileInput } from 'GraphTypes/CreateAdvertiserProfileMutation.graphql';
import environment from 'Api/Environment';
import type { CurrentUserQuery, CurrentUserQuery$data } from 'GraphTypes/CurrentUserQuery.graphql';
import { CreateAdvertiserProfileMutation$data } from 'GraphTypes/CreateAdvertiserProfileMutation.graphql';

type UseSignUpAdvertiserFlow = () => HookResult;
const useAdvertiserSignUpFlow: UseSignUpAdvertiserFlow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticatedUser, setIsRegisteredUserForce } = useContext(Auth0Context);
  const navigate = useNavigate();
  const tracking = useTracking();

  const runSignUpAdvertiserFlow: HookResult['runSignUpAdvertiserFlow'] = async (
    advertiserProfileInput
  ) => {
    const { organizationId } = getQueryLocationParams();
    tracking.trackEvent({ event: 'complete', payload: { organizationId } });
    if (!isAuthenticatedUser) {
      navigate(SIGNIN_ADVERTISER_ROUTE);
      return;
    }

    setIsLoading(true);

    const currentUserQuery$data = await new Promise<CurrentUserQuery$data>((resolve, reject) => {
      fetchQuery<CurrentUserQuery>(environment, currentUserQuery, {}).subscribe({
        next: (result) => resolve(result),
        error: () => reject(),
      });
    });

    const currentUser = currentUserQuery$data?.currentUser;

    if (!currentUser) {
      navigate(SIGNIN_ADVERTISER_ROUTE);
      return;
    }
    if (isAlreadyRegisteredUser(currentUser)) {
      setIsRegisteredUserForce(true);
      navigate(DASHBOARD_ROUTE);
      return;
    }

    const createAdvertiserProfileMutation$data =
      await new Promise<CreateAdvertiserProfileMutation$data>((resolve, reject) => {
        createAdvertiserProfile(
          {
            ...advertiserProfileInput,
            userId: currentUser.id,
          },
          resolve,
          reject
        );
      }).finally(() => {
        setIsLoading(false);
      });

    return {
      createAdvertiserProfileMutation$data,
      currentUserQuery$data,
    };
  };

  const onSignUpAdvertiserComplete: HookResult['onSignUpAdvertiserComplete'] = (params) => {
    const { setRegisteredUserForce = true, preventRedirect } = params || {};
    const state = authSessionState.get();
    if (state?.popup) {
      if (window.opener) {
        window.opener.postMessage(
          {
            type: Auth0PostMessageType.SignUpComplete,
          },
          window.location.origin
        );
      }
      setTimeout(() => {
        window.close();
      }, 500);

      return;
    }
    if (state?.redirectUriAfterRegistration) {
      window.location.href = state?.redirectUriAfterRegistration;
      return;
    }

    if (setRegisteredUserForce) {
      setIsRegisteredUserForce(true);
    }

    if (!preventRedirect) {
      navigate(DASHBOARD_ROUTE);
    }
  };

  return {
    isAdvertiserSignUpFlowLoading: isLoading,
    onSignUpAdvertiserComplete,
    runSignUpAdvertiserFlow,
  };
};

export { useAdvertiserSignUpFlow };

type RunSignUpAdvertiserFlowResult = {
  currentUserQuery$data: CurrentUserQuery$data;
  createAdvertiserProfileMutation$data: CreateAdvertiserProfileMutation$data;
};

type HookResult = {
  isAdvertiserSignUpFlowLoading: boolean;
  runSignUpAdvertiserFlow: (
    advertiserProfileInput: Omit<CreateAdvertiserProfileInput, 'userId'>
  ) => Promise<RunSignUpAdvertiserFlowResult | void>;
  onSignUpAdvertiserComplete: (params?: OnSignUpAdvertiserCompleteParams) => void;
};

type OnSignUpAdvertiserCompleteParams = {
  setRegisteredUserForce?: boolean;
  preventRedirect?: boolean;
};

import { createDate } from 'Util/dateCreator';
import type { Performance_Granularity } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';
import { createShortNumber } from 'Util/numberFormatter';

export const colorConfig: Array<{ lineColor: string; tooltipColor: string }> = [
  { lineColor: '#F740C4', tooltipColor: '#FEAAFE' },
  { lineColor: '#01CAE5', tooltipColor: '#77F7FF' },
  { lineColor: '#5962FF', tooltipColor: '#B9BAFF' },
  { lineColor: '#FF5900', tooltipColor: '#FF9C5E' },
  { lineColor: '#189912', tooltipColor: '#70EF9A' },
];

export type StatItem = {
  amount: number;
  key: string;
  description: {
    titleMsg: string;
    tooltipMsg?: string;
  };
  color?: string;
  colorOffset?: number;
};

export const replaceValueWithPostfix = (value: number, isCurrency = false): string => {
  const newValue = createShortNumber(value, 1000);

  return isCurrency ? `$${newValue}` : newValue;
};

export const replaceDatesWithLabel = (dates?: string[]) => {
  if (!dates || dates.length === 0) return '';
  if (dates.length === 1) {
    return createDate(dates[0], 'MMM DD') || '';
  }

  return `${createDate(dates[0], 'MMM DD')}-${createDate(dates[1], 'MMM DD')}`;
};

export const hideDate = (
  totalCount: number,
  currentIndex: number,
  granularity: Performance_Granularity
) => {
  if ((granularity === 'DAILY' || granularity === 'MONTHLY') && totalCount > 7) {
    if (totalCount < 15 && (currentIndex + 1) % 2 === 0) {
      return true;
    }
    if (totalCount > 14 && totalCount < 25 && currentIndex % 3 !== 0) {
      return true;
    }
    if (totalCount > 24) {
      const point = currentIndex / 14;
      if (currentIndex % point !== 0) return true;
    }
  }
  if ((granularity === 'WEEKLY' || granularity === 'QUARTERLY') && totalCount > 7) {
    if (totalCount < 10 && (currentIndex + 1) % 2 === 0) {
      return true;
    }
    if (totalCount > 9) {
      const point = currentIndex / 6;
      if (currentIndex % point !== 0) return true;
    }
  }
  return false;
};

export const createDateLabel = (
  dateFrom: string,
  dateTo: string,
  granularity: Performance_Granularity
) => {
  if (granularity === 'DAILY') {
    return createDate(dateFrom, 'MMM DD');
  } else if (granularity === 'WEEKLY') {
    return `${createDate(dateFrom, 'MMM DD')} - ${createDate(dateTo, 'MMM DD')}`;
  } else if (granularity === 'MONTHLY') {
    return createDate(dateFrom, "MMM 'YY");
  } else if (granularity === 'QUARTERLY') {
    return `${createDate(dateFrom, "MMM 'YY")} - ${createDate(dateTo, "MMM 'YY")}`;
  }
  return createDate(dateFrom, 'MMM DD');
};

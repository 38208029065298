import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import styles from './AmazonStoreLink.pcss';

import useCreatorAmazonStorelink from 'Hooks/useCreatorAmazonStorelink';
import { useCreatorAmazonStorelink_creator$key } from 'GraphTypes/useCreatorAmazonStorelink_creator.graphql';

type Props = {
  creatorProfile: useCreatorAmazonStorelink_creator$key | null;
  className?: string;
  onClick?: () => void;
};

const AmazonStoreLink: React.FC<Props> = (props) => {
  const { className, creatorProfile, onClick } = props;
  const intl = useIntl();
  const linkData = useCreatorAmazonStorelink({ creatorProfile: creatorProfile });
  if (!linkData) {
    return null;
  }
  const handleLinkClick = () => {
    window.open(linkData, '_blank');
    if (onClick) {
      onClick();
    }
  };
  return (
    <div onClick={handleLinkClick} className={classNames(styles.link, className)}>
      {intl.formatMessage({ id: 'creator.storefront.link' })}
    </div>
  );
};

export default AmazonStoreLink;

import icons from './index';

const keys = Object.keys(icons) as (keyof typeof icons | string)[];

type IsValidIconKey = (key: string) => boolean;
const isValidIconKey: IsValidIconKey = (key) => {
  return keys.includes(key);
};

export {
  isValidIconKey
};

import { CreatorBadgeEnum } from 'Types/enums';
import badgeResponsiveImg from 'Images/icons/badge-responsive.svg';
import badgeRisingImg from 'Images/icons/badge-rising.svg';
import badgeTopRatedImg from 'Images/icons/badge-top-rated.svg';
import badgeUGCExpertImg from 'Images/icons/badge-UGC-premium.svg';

export const getBadgeIcon = (badge: CreatorBadgeEnum) => {
  switch (badge) {
    case CreatorBadgeEnum.HIGHLY_RESPONSIVE:
      return badgeResponsiveImg;
    case CreatorBadgeEnum.HIGH_POTENTIAL:
      return badgeRisingImg;
    case CreatorBadgeEnum.TOP_RATED:
      return badgeTopRatedImg;
    case CreatorBadgeEnum.UGC_EXPERT:
      return badgeUGCExpertImg;
    default:
      return undefined;
  }
};

type BadgeDataType = {
  icon?: string;
  label: string;
  tooltip: string;
};

export const getBadgeData = (badge: CreatorBadgeEnum): BadgeDataType => {
  const icon = getBadgeIcon(badge);
  const label = `creator.badge.${badge.toLowerCase()}`;
  const tooltip = `creator.badge.${badge.toLowerCase()}.tooltip`;

  return { icon, label, tooltip };
};

import React from 'react';

import WithdrawalContainer from './WithdrawalContainer/WithdrawalContainer';

import Page from 'Templates/Page/Page';


const AdminWithdrawls = () => {
  return (
    <Page>
      <WithdrawalContainer />
    </Page>
  );
};

export default AdminWithdrawls;
/**
 * @generated SignedSource<<2293db05c2d2feac8313800bebc6b566>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type BrandsQuery$variables = {
  brandName?: string | null | undefined;
};
export type BrandsQuery$data = {
  readonly brandCategories: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null | undefined;
  readonly brandCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly counters: {
        readonly activeBrands: number;
      } | null | undefined;
      readonly subscription: {
        readonly effectiveLimits: {
          readonly maxActiveBrands: number | null | undefined;
        };
        readonly planId: PlanId;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"BrandsList_brands">;
};
export type BrandsQuery = {
  response: BrandsQuery$data;
  variables: BrandsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandName"
  }
],
v1 = {
  "kind": "Variable",
  "name": "brandName",
  "variableName": "brandName"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": "brandCount",
  "args": null,
  "concreteType": "BrandConnection",
  "kind": "LinkedField",
  "name": "brands",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationCounters",
  "kind": "LinkedField",
  "name": "counters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeBrands",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "effectiveLimits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxActiveBrands",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandCategory",
  "kind": "LinkedField",
  "name": "brandCategories",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 18
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandLogoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "logo",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v8/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "creator",
  "plural": false,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandsQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "BrandsList_brands"
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "BrandConnection",
        "kind": "LinkedField",
        "name": "brands",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Brand",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "summary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "websiteUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "stages",
                        "value": [
                          "ACTIVE",
                          "COMPLETED"
                        ]
                      }
                    ],
                    "concreteType": "CampaignConnection",
                    "kind": "LinkedField",
                    "name": "campaigns",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": "campaigns(stages:[\"ACTIVE\",\"COMPLETED\"])"
                  },
                  {
                    "alias": "activeCampaigns",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "stages",
                        "value": [
                          "ACTIVE"
                        ]
                      }
                    ],
                    "concreteType": "CampaignConnection",
                    "kind": "LinkedField",
                    "name": "campaigns",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Campaign",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Brand",
                                "kind": "LinkedField",
                                "name": "brand",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "campaigns(stages:[\"ACTIVE\"])"
                  },
                  {
                    "alias": "completedCampaigns",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "stages",
                        "value": [
                          "COMPLETED"
                        ]
                      }
                    ],
                    "concreteType": "CampaignConnection",
                    "kind": "LinkedField",
                    "name": "campaigns",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Campaign",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Brand",
                                "kind": "LinkedField",
                                "name": "brand",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "logo",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v14/*: any*/),
                                      (v15/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "campaigns(stages:[\"COMPLETED\"])"
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "Brands_brands",
        "kind": "LinkedHandle",
        "name": "brands"
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ]
  },
  "params": {
    "cacheID": "592856fc815b1beac7099e48de354c86",
    "id": null,
    "metadata": {},
    "name": "BrandsQuery",
    "operationKind": "query",
    "text": "query BrandsQuery(\n  $brandName: String\n) {\n  ...BrandsList_brands_35BC3f\n  brandCount: brands {\n    totalCount\n  }\n  currentUser {\n    admin\n    organization {\n      counters {\n        activeBrands\n      }\n      subscription {\n        effectiveLimits {\n          maxActiveBrands\n        }\n        planId\n        id\n      }\n      id\n    }\n    id\n  }\n  brandCategories {\n    id\n    name\n  }\n}\n\nfragment Brand_brand on Brand {\n  id\n  active\n  logo {\n    __typename\n    id\n    thumbnailUrl\n    ... on Image {\n      transformations {\n        brandLogoUrl\n      }\n    }\n  }\n  name\n  summary\n  websiteUrl\n  category {\n    id\n    name\n  }\n  campaigns(stages: [ACTIVE, COMPLETED]) {\n    totalCount\n  }\n  activeCampaigns: campaigns(stages: [ACTIVE]) {\n    edges {\n      node {\n        id\n        name\n        platform\n        brand {\n          logo {\n            __typename\n            id\n            thumbnailUrl\n            ... on Image {\n              transformations {\n                brandLogoUrl\n              }\n            }\n          }\n          id\n        }\n        creator {\n          id\n        }\n      }\n    }\n  }\n  completedCampaigns: campaigns(stages: [COMPLETED]) {\n    edges {\n      node {\n        id\n        name\n        platform\n        brand {\n          logo {\n            __typename\n            thumbnailUrl\n            ... on Image {\n              transformations {\n                brandLogoUrl\n              }\n            }\n            id\n          }\n          id\n        }\n        creator {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment BrandsList_brands_35BC3f on Query {\n  brands(first: 18, brandName: $brandName) {\n    edges {\n      node {\n        id\n        ...Brand_brand\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3737999a78bc60b7d06a0b22b60305e9";

export default node;

import React, { useContext, useState } from 'react';

import styles from './PauseButton.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import PauseSubscriptionDrawer from '../PauseSubscriptionDrawer/PauseSubscriptionDrawer';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  disabled: boolean;
}

const PauseButton: React.FC<Props> = (props) => {
  const { disabled } = props;
  const { openDrawer } = useContext(DrawerContext);

  const handlePauseDrawerOpen = () => {
    openDrawer('pause-subscription-drawer');
    amplitude.sendEvent<381>({
      id: '381',
      category: 'billing',
      name: 'initiated_pause_plan',
    });
  };

  return (
    <AlterButton
      textByCenter
      type={'grey'}
      msg="billing.current_plan.pause_plan"
      className={styles.pauseBtn}
      disabled={disabled}
      onClick={handlePauseDrawerOpen}
    />
  );
};

export default PauseButton;

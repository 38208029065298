import React, { useEffect } from 'react';

import styles from './Marketplace.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import Spinner from 'Atoms/Spinner/Spinner';

const Marketplace: React.FC = () => {
  const { createNewCampaign } = useCampaignActions();

  useEffect(() => {
    createNewCampaign({ showMediaplan: true });
  }, []);

  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};

export default Marketplace;

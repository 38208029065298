import React from 'react';
import { useParams } from 'react-router-dom';

import Page from 'Templates/Page/Page';
import Brief from 'Templates/Brief/MobileBrief/MobileBrief';



const CampaignBriefForViewing: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) return null;

  return (
    <Page>
      <Brief campaignId={id} mobileView={false} />
    </Page>
  );
};

export default CampaignBriefForViewing;
import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';
import { OrganizationSubscriptionUpdatedSubscription } from 'GraphTypes/OrganizationSubscriptionUpdatedSubscription.graphql';

const query = graphql`
  subscription OrganizationSubscriptionUpdatedSubscription {
    organizationSubscriptionUpdated {
      paying
      newClient
      subscription {
        activeDiscount {
          name
        }
        availablePlanIds
        planId
        paused
        priceAmount
        interval
        renewsAt
        addonItems {
          addon {
            id
            resourcesPerUnit
          }
          quantity
        }
        effectiveLimits {
          maxPaidSocialActivationDays
          maxLicensedCreators
          maxActiveBrands
          maxBcaConnections
          maxSeats
        }
        baseLimits {
          maxActiveBrands
          maxLicensedCreators
          maxBcaConnections
          maxSeats
        }
        canApplyDiscount
        canUsePaidSocial
        availablePaymentTypes
        cancelAt
        priceAmount
        paused
        discountOfferAcceptedAt
        pauseEvent {
          pausedAt
          resumesAt
        }
        lastSchedule {
          discount {
            percentOff
          }
          trialEnd
          startDate
        }
      }
    }
  }
`;

export default () => {
  requestSubscription<OrganizationSubscriptionUpdatedSubscription>(environment, {
    subscription: query,
    variables: {},
  });
};

/**
 * @generated SignedSource<<89b0d057f4686763b4ab4a3193c4e403>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteBriefScreeningQuestionInput = {
  clientMutationId?: string | null | undefined;
  screeningQuestionId: string;
};
export type DeleteBriefScreeningQuestionMutation$variables = {
  input: DeleteBriefScreeningQuestionInput;
};
export type DeleteBriefScreeningQuestionMutation$data = {
  readonly deleteBriefScreeningQuestion: {
    readonly brief: {
      readonly id: string;
    };
  } | null | undefined;
};
export type DeleteBriefScreeningQuestionMutation = {
  response: DeleteBriefScreeningQuestionMutation$data;
  variables: DeleteBriefScreeningQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteBriefScreeningQuestionPayload",
    "kind": "LinkedField",
    "name": "deleteBriefScreeningQuestion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "V2Brief",
        "kind": "LinkedField",
        "name": "brief",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteBriefScreeningQuestionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteBriefScreeningQuestionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "49f174b077238b096f0bf91bf3429e12",
    "id": null,
    "metadata": {},
    "name": "DeleteBriefScreeningQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteBriefScreeningQuestionMutation(\n  $input: DeleteBriefScreeningQuestionInput!\n) {\n  deleteBriefScreeningQuestion(input: $input) {\n    brief {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61ece2d0a337e7032bca5512b58794fa";

export default node;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  PreviewProrationMutation,
  PreviewProrationMutation$data,
  Stripe_PreviewProrationInput,
} from 'GraphTypes/PreviewProrationMutation.graphql';
import { addonsKeyMap } from 'Page/advertiser/BillingPlans/data';

const mutation = graphql`
  mutation PreviewProrationMutation($input: Stripe_PreviewProrationInput!) {
    stripe {
      previewProration(input: $input) {
        prorationPreview {
          id
          createdAt
          currency
          amountDue
          nextInvoiceAt
          nextInvoiceAmount
          lines {
            amountExcludingTax
            description
            taxAmount
            taxPercentage
            taxState
            taxType
          }
          currentPlanId
          planId
          interval
          appliedBalanceCredit
          subtotal
          total
          discount {
            percentOff
            amountOffInCents
            durationInMonths
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Stripe_PreviewProrationInput,
  resolve?: (response: PreviewProrationMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<PreviewProrationMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }

      if (resolve && response) resolve(response);
    },
    onError: (error) => {
      if (reject) reject(error);
    },
  });
};

import classNames from 'classnames';
import React from 'react';

import IconNew from '../Icon/Icon';
import { IconName } from '../types';

import styles from './TextPreset.pcss';

type TextPresetHeader = JSX.Element | JSX.Element[] | string;
type TextPresetSubtitle = JSX.Element | JSX.Element[] | string;
type TextPresetRightComponent = JSX.Element | JSX.Element[] | string;
type TextPresetClickHandle = () => void;

type Props = {
  header: TextPresetHeader;
  subtitle?: TextPresetSubtitle;
  icon?: IconName;
  rightComponent?: TextPresetRightComponent;
  handleClick?: TextPresetClickHandle;
  className?: string;
  classes?: {
    textContainer?: string;
  };
};

const TextPreset: React.FC<Props> = (props) => {
  const { header, subtitle, icon, rightComponent, handleClick, className, classes } = props;

  const classList = classNames(styles.root, className);

  return (
    <div className={classList} {...(handleClick ? { onClick: handleClick } : {})}>
      <div className={classNames(styles.textContainer, classes?.textContainer)}>
        <div className={styles.header}>
          {header}
          {icon && <IconNew name={icon} />}
        </div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      {rightComponent && <div className={styles.rightComponent}>{rightComponent}</div>}
    </div>
  );
};

export default TextPreset;

/**
 * @generated SignedSource<<f8a10c9de3b9fad9c785367dff0034aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddNotes_project$data = {
  readonly adminComment: string | null | undefined;
  readonly comment: string | null | undefined;
  readonly creatorDeclinedToParticipate: boolean;
  readonly id: string;
  readonly " $fragmentType": "AddNotes_project";
};
export type AddNotes_project$key = {
  readonly " $data"?: AddNotes_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddNotes_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddNotes_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminComment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorDeclinedToParticipate",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "c66ef53ce45916c43a26680f83ebf440";

export default node;

import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import unarchiveProject from 'Mutations/UnarchiveProject.Mutation';
import Warning from 'Atoms/Warning/Warning';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { HELLO_EMAIL } from 'Constants/general';
import { ProjectArchivationStateQuery as QueryType } from 'GraphTypes/ProjectArchivationStateQuery.graphql';
import useDashly from 'Hooks/useDashly';

const ProjectArchivationStateQuery = graphql`
  query ProjectArchivationStateQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      archivation(side: ADVERTISER) {
        id
      }
      completion {
        id
      }
      campaign {
        completion {
          id
        }
      }
      archivationRequest {
        acceptance {
          id
        }
        rejection {
          id
        }
        side
      }
    }
  }
`;

interface Props {
  id: string;
  className?: string;
}

const ProjectArchivationState: React.FC<Props> = (props) => {
  const { id, className } = props;

  const { open: openDashly } = useDashly();

  const handleDashlyClick = () => {
    openDashly();
  };

  const handleRestore = () => {
    unarchiveProject({ projectId: id });
  };

  const requestInProcessContent = (
    <>
      <Text
        weight="500"
        size="sm"
        display="inline"
        msg="chat.request_archivation.in_process.title"
      />
      <Text size="sm" display="inline" msg="chat.request_archivation.in_process.deacr_1" />
      <TextButton
        size="sm"
        display="inline"
        onClick={handleDashlyClick}
        msg="chat.request_archivation.in_process.dashly"
      />
      <Text size="sm" display="inline" msg="chat.request_archivation.in_process.deacr_2" />
      <SimpleLink href={`mailto:${HELLO_EMAIL}`}>
        <Text color="base" weight="500" size="sm" msg="chat.request_archivation.in_process.email" />
      </SimpleLink>
    </>
  );

  const requestAcceptedContent = (
    <>
      <Text
        weight="500"
        color="red"
        size="sm"
        display="inline"
        msg="chat.request_archivation.accepted.title"
      />
      <Text size="sm" display="inline" msg="chat.request_archivation.accepted.descr" />
      <TextButton
        size="sm"
        onClick={handleRestore}
        msg="chat.request_archivation.accepted.restore"
      />
    </>
  );

  const data = useLazyLoadQuery<QueryType>(ProjectArchivationStateQuery, { projectId: id });

  const project = data.project;

  const completion = project?.completion;

  if (!data || !project || completion) return null;

  const archivationRequest = project?.archivationRequest;
  const archivation = project?.archivation;

  const requestProcced =
    archivationRequest &&
    archivationRequest.side === 'ADVERTISER' &&
    !archivationRequest.acceptance &&
    !archivationRequest.rejection;

  if (!archivation && !requestProcced) return null;

  const color = archivation ? 'red' : undefined;
  const content = archivation ? requestAcceptedContent : requestInProcessContent;

  return (
    <div className={className}>
      <Warning color={color}>{content}</Warning>
    </div>
  );
};

export default ProjectArchivationState;

import React, { MutableRefObject } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './SearchResults.pcss';
import SearchResults from './SearchResults';
import ResultStub from './ResultStub/ResultStub';

import environment from 'Api/Environment';
import {
  SearchResultsContainerQuery as QueryType,
  SearchResultsContainerQuery$variables,
} from 'GraphTypes/SearchResultsContainerQuery.graphql';

const SearchResultsContainerQuery = graphql`
  query SearchResultsContainerQuery($campaignId: ID!, $searchQueryInput: SearchQueryInput) {
    campaign(id: $campaignId) {
      ...SearchResults_campaign @arguments(searchQueryInput: $searchQueryInput)
      type
    }
    currentUser {
      admin
      organization {
        id
        newClient
        churned
        subscription {
          canUsePaidSocial
          paused
        }
      }
    }
  }
`;

interface Props {
  isAdmin: boolean;
  campaignId: string;
  searchParams: SearchResultsContainerQuery$variables;
  hasContentCreation: boolean;
  controller: MutableRefObject<AbortController | undefined>;
  showUnlockCreatorBanner: () => void;
}

const SearchResultsContainer: React.FC<Props> = (props) => {
  const { campaignId, searchParams, hasContentCreation, controller, showUnlockCreatorBanner } =
    props;

  const preloader = (
    <div className={styles.preloader}>
      <ResultStub />
      <ResultStub />
      <ResultStub />
      <ResultStub />
    </div>
  );

  return (
    <div>
      {
        <QueryRenderer<QueryType>
          environment={environment}
          query={SearchResultsContainerQuery}
          variables={{ ...searchParams, campaignId }}
          cacheConfig={{
            metadata: {
              signal: controller?.current?.signal,
            },
          }}
          render={({ props: queryProps }) => {
            if (!queryProps) return preloader;

            const campaign = queryProps.campaign;

            if (!campaign) return null;

            const currentUser = queryProps.currentUser;
            const isAdmin = currentUser?.admin;
            const canUsePaidSocial = currentUser?.organization?.subscription?.canUsePaidSocial;
            const organizationId = currentUser?.organization?.id;
            const isChurned = currentUser?.organization?.churned;
            const isNewClient = currentUser?.organization?.newClient;
            const isPlanPaused = Boolean(currentUser?.organization?.subscription?.paused);

            const isBlockedView = !isAdmin && (isChurned || isNewClient || isPlanPaused);

            return (
              <SearchResults
                isAdmin={!!isAdmin}
                campaign={campaign}
                campaignId={campaignId}
                canUsePaidSocial={canUsePaidSocial}
                searchParams={searchParams}
                searchQueryInput={searchParams.searchQueryInput}
                hasContentCreation={hasContentCreation}
                preloader={preloader}
                organizationId={organizationId}
                isBlockedView={isBlockedView}
                showUnlockCreatorBanner={showUnlockCreatorBanner}
              />
            );
          }}
        />
      }
    </div>
  );
};

export default SearchResultsContainer;

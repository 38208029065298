import React, { PropsWithChildren, useContext } from 'react';
import { ProductFruits } from 'react-product-fruits';

import { withAuth0Provider, Auth0Context } from '../Auth0Context';

import { PRODUCT_FRUITS_WORKSPACE_CODE } from 'Constants/general';

const Auth0Container: React.FC = withAuth0Provider((props: PropsWithChildren) => {
  const { children } = props;
  const { isLoading, user } = useContext(Auth0Context);

  const userInfo = {
    username: user?.email || '',
    email: user?.email,
    signUpAt: user?.createdAt as string,
    props: user?.type ? { type: user.type as string } : undefined,
  };

  const isEmptyUserData = Object.entries(userInfo).filter(([, v]) => v).length === 0;

  if (isLoading) {
    return null;
  }
  return (
    <>
      {children}
      {!isEmptyUserData && (
        <ProductFruits
          workspaceCode={PRODUCT_FRUITS_WORKSPACE_CODE}
          language="en"
          user={userInfo}
        />
      )}
    </>
  );
});

export { Auth0Container };

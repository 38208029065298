import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  ResumeCampaignActivationMutation,
  ResumeCampaignActivationMutation$data,
  ResumeCampaignActivationInput,
} from 'GraphTypes/ResumeCampaignActivationMutation.graphql';

const mutation = graphql`
  mutation ResumeCampaignActivationMutation($input: ResumeCampaignActivationInput!) {
    resumeCampaignActivation(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ResumeCampaignActivationInput,
  resolve?: (response: ResumeCampaignActivationMutation$data) => void,
  reject?: fnType
) => {
  const { campaignId } = data;

  const variables = {
    input: {
      campaignId,
    },
  };

  commitMutation<ResumeCampaignActivationMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const campaign = store.get(campaignId);
      const activation = campaign?.getLinkedRecord('activation');
      if (activation) {
        activation.setValue(false, 'paused');
      }
    },
    updater: (store) => {
      const campaign = store.get(campaignId);
      const activation = campaign?.getLinkedRecord('activation');
      if (activation) {
        activation.setValue(false, 'paused');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

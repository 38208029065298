import React from 'react';

import styles from './EmptySearchRequest.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  onParamsClear: () => void;
}

const EmptySearchRequest: React.FC<Props> = (props) => {
  const { onParamsClear } = props;
  return (
    <div className={styles.emptyList}>
      <Icon name="Eye" />
      <Text type="sm" msg="billing_history.transactions.empty_list_request" />
      <AlterButton
        msg="billing_history.transactions.clear_params"
        icon="Close-small"
        iconPosition="left"
        className={styles.clearBtn}
        onClick={onParamsClear}
      />
    </div>
  );
};

export default EmptySearchRequest;

import React, { useMemo } from 'react';

import Text from 'Atoms/Text/Text';
import { SubscriptionStatusTypeEnum } from 'Types/enums';

type Props = {
  subscriptionStatusType: SubscriptionStatusTypeEnum;
};

const SubscriptionStatusType: React.FC<Props> = (props) => {
  const { subscriptionStatusType } = props;

  const txt = useMemo(() => {
    if (!subscriptionStatusType) {
      return <>{'—'}</>;
    }
    return (
      <Text
        msg={`admin_transactions.subscription_status_type.${subscriptionStatusType.toLowerCase()}`}
      />
    );
  }, [subscriptionStatusType]);

  return txt;
};

export default SubscriptionStatusType;

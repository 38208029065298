import React, { useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import styles from './ProjectEvents.pcss';
import ProjectEventsListProvider from './ProjectEventsList/ProjectEventsListProvider';

import Spinner from 'Atoms/Spinner/Spinner';
import { ProjectEventsQuery as QueryType } from 'GraphTypes/ProjectEventsQuery.graphql';

export const ProjectEventsQuery = graphql`
  query ProjectEventsQuery($id: ID!, $before: String, $last: Int, $includeDeleted: Boolean) {
    currentUser {
      id
      type
      admin
    }
    project(id: $id) {
      campaign {
        id
        postingType
      }
      brief {
        ... on V2Brief {
          screeningQuestion
          productShipmentRequired
        }
      }
    }
    ...ProjectEventsList_project
      @arguments(projectId: $id, before: $before, last: $last, includeDeleted: $includeDeleted)
  }
`;

interface Props {
  projectId: string;
  inPopup?: boolean;
  isAdvertiser?: boolean;
}

const ProjectEvents: React.FC<Props> = (props) => {
  const { projectId, inPopup, isAdvertiser } = props;
  const [queryReference, loadQuery] = useQueryLoader<QueryType>(ProjectEventsQuery);

  const includeDeleted = isAdvertiser ? true : undefined;

  useEffect(() => {
    loadQuery({ id: projectId, last: 20, includeDeleted });
  }, [projectId]);

  if (!queryReference) return <Spinner className={styles.preloader} />;

  return (
    <React.Suspense fallback={<Spinner className={styles.preloader} />}>
      {queryReference !== null && (
        <ProjectEventsListProvider
          projectId={projectId}
          queryReference={queryReference}
          inPopup={inPopup}
          includeDeleted={includeDeleted}
        />
      )}
    </React.Suspense>
  );
};

export default ProjectEvents;

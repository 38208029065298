/**
 * @generated SignedSource<<39ebf830514b57d104f752a158a31e27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR" | "%future added value";
export type ProjectArchivationStateQuery$variables = {
  projectId: string;
};
export type ProjectArchivationStateQuery$data = {
  readonly project: {
    readonly archivation: {
      readonly id: string;
    } | null | undefined;
    readonly archivationRequest: {
      readonly acceptance: {
        readonly id: string;
      } | null | undefined;
      readonly rejection: {
        readonly id: string;
      } | null | undefined;
      readonly side: ProjectArchivationSide;
    } | null | undefined;
    readonly campaign: {
      readonly completion: {
        readonly id: string;
      } | null | undefined;
    };
    readonly completion: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
};
export type ProjectArchivationStateQuery = {
  response: ProjectArchivationStateQuery$data;
  variables: ProjectArchivationStateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "side",
      "value": "ADVERTISER"
    }
  ],
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "archivation(side:\"ADVERTISER\")"
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectCompletion",
  "kind": "LinkedField",
  "name": "completion",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CampaignCompletion",
  "kind": "LinkedField",
  "name": "completion",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequestAcceptance",
  "kind": "LinkedField",
  "name": "acceptance",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequestRejection",
  "kind": "LinkedField",
  "name": "rejection",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "side",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectArchivationStateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectArchivationRequest",
            "kind": "LinkedField",
            "name": "archivationRequest",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectArchivationStateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectArchivationRequest",
            "kind": "LinkedField",
            "name": "archivationRequest",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be5606e741b5ed4cf8c63b24aaf8245d",
    "id": null,
    "metadata": {},
    "name": "ProjectArchivationStateQuery",
    "operationKind": "query",
    "text": "query ProjectArchivationStateQuery(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    id\n    archivation(side: ADVERTISER) {\n      id\n    }\n    completion {\n      id\n    }\n    campaign {\n      completion {\n        id\n      }\n      id\n    }\n    archivationRequest {\n      acceptance {\n        id\n      }\n      rejection {\n        id\n      }\n      side\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "35719900d1fb4be0fa779b8de67a2c91";

export default node;

import colors from 'Styles/vars/colors.css';

export default ({ error, maxHeight }) => {
  const noActiveBorderColor = error ? colors.colorRed : colors.colorGrey50;
  const activeBorderColor = error ? colors.colorRed : colors.colorGrayDog;

  const maxHeightValue = maxHeight ? `${maxHeight}px` : '200px';

  return {
    container: (base) => ({
      ...base,
      font: '14px "Aeroport"',
    }),
    placeholder: (base) => ({
      ...base,
      color: colors.colorGrayDog,
    }),
    control: (base, state) => ({
      ...base,
      paddingLeft: '4px',
      minHeight: '40px',
      boxShadow: state.isFocused ? `0 0 0 1px ${colors.colorBase}` : '',
      borderColor: state.isFocused ? colors.colorBase : noActiveBorderColor,
      '&:hover': {
        borderColor: state.isFocused ? colors.colorBase : activeBorderColor,
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: maxHeightValue,
      borderRadius: '0 0 4px 4px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 89,
    }),
  };
};
import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import CreativesList from './AdvertiserCreativesList/AdvertiserCreativesList';

import environment from 'Api/Environment';
import Spinner from 'Atoms/Spinner/Spinner';
import { CreativesContainerQuery as QueryType } from 'GraphTypes/CreativesContainerQuery.graphql';


const CreativesContainerQuery = graphql`
  query CreativesContainerQuery {
    ...AdvertiserCreativesList_creatives
    currentUser {
      admin
      organization {
        subscription {
          canCreateFacebookAds
          canChatWithCreators
        }
      }
    }
  }
`;

const CreativesContainer = () => {
  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={CreativesContainerQuery}
      variables={{}}
      render={({ props: queryProps }) => {
        if (!queryProps) return <Spinner style={{ margin: '40px auto' }} />;

        const currentUser = queryProps.currentUser;
        const admin = currentUser?.admin;
        const canCreateFacebookAds = currentUser?.organization?.subscription?.canCreateFacebookAds;
        const canChatWithCreators = currentUser?.organization?.subscription?.canChatWithCreators;

        return <CreativesList
          admin={admin}
          creatives={queryProps}
          canCreateFacebookAds={canCreateFacebookAds}
          canChatWithCreators={canChatWithCreators}
        />;
      }}
    />
  );
};

export default CreativesContainer;
import React, { Suspense, useEffect } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useDispatch } from 'react-redux';

import styles from './ProjectInfo.pcss';
import BloggerInfo from './BloggerInfo/BloggerInfo';
import CreatorShippingDetails from './CreatorShippingDetails/CreatorShippingDetails';
import Brief from './Brief/Brief';
import Stepper from './Stepper/Stepper';
import Comment from './Comment/Comment';
import ProjectResults from './ProjectResults/ProjectResults';
import ProjectArchivationState from './ProjectArchivationState/ProjectArchivationState';
import ProjectCreatorRating from './ProjectCreatorRating/ProjectCreatorRating';
import PsActivation from './PsActivation/PsActivation';
import BcaActivation from './BcaActivation/BcaActivation';
import AdminAdsManager from './AdminAdsManager/AdminAdsManager';
import CampaignInfo from './ProjectCampaignInfo/ProjectCampaignInfo';
import CreatorContacts from './CreatorContacts/CreatorContacts';
import { ProjectInfoContextProvider } from './ProjectInfo.Context';
import ContentModeration from './ContentModeration/ContentModeration';

import ExtraPaymentDrawer from 'Modal/advertiser/ExtraPayment/ExtraPayment';
import PriceChangeRequestDrawer from 'Modal/advertiser/PriceChangeRequest/PriceChangeRequest';
import SetDraftDeadline from 'Modal/advertiser/SetDraftDeadline/SetDraftDeadline';
import SetPublicationDate from 'Modal/advertiser/SetPublicationDate/SetPublicationDate';
import ProjectCreatorRatingDrawer from 'Modal/advertiser/ProjectCreatorRating/ProjectCreatorRating';
import ReimbursementPayment from 'Modal/advertiser/ReimbursementPayment/ReimbursementPayment';
import { togglePreloader } from 'Actions/UI.Actions';
import archiveProject from 'Api/mutations/ArchiveProject.Mutation';
import { LONG_TERM } from 'Constants/general';
import { ProjectInfoQuery as QueryType } from 'GraphTypes/ProjectInfoQuery.graphql';

const ProjectInfoQuery = graphql`
  query ProjectInfoQuery($id: ID!) {
    project(id: $id) {
      id
      price
      currency
      brief {
        ... on V2Brief {
          productDelivery
          paidSocialBca
          paidSocial
        }
      }
      advertiserStage
      completion {
        id
      }
      launch {
        id
      }
      offer {
        acceptance {
          id
        }
      }
      campaign {
        id
        type
        platform
        organization {
          paymentAccount {
            balance
            currency
          }
          subscription {
            transactionFee
          }
        }
      }
      ...Brief_project
      ...BloggerInfo_project
      ...Comment_project
      ...ProjectCreatorRating_project
      ...ProjectCampaignInfo_project
      ...CreatorContacts_project
    }
    currentUser {
      admin
      organization {
        subscription {
          planId
          canUsePaidSocial
          canCreateDeposits
          canCreateMultipleDeals
          effectiveLimits {
            maxHiredCreators
            maxLicensedCreators
            maxBcaConnections
          }
        }
        counters {
          hiredCreators
          bcaLicensedCreators
          psLicensedCreators
        }
        paymentAccount {
          currency
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  projectId?: string;
  onCustomListSelectionOpen: () => void;
  onCustomListSelectionClose: () => void;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
}

const ProjectInfo: React.FC<Props> = (props) => {
  const {
    className,
    projectId = '',
    onCustomListSelectionOpen,
    onCustomListSelectionClose,
    onScroll,
    style,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(togglePreloader(false));
    };
  }, []);

  const data = useLazyLoadQuery<QueryType>(ProjectInfoQuery, { id: projectId });

  if (!data || !data.currentUser || !data.project) return null;

  const project = data.project;
  const launch = !!project?.launch?.id;

  const currentUser = data.currentUser;
  const admin = currentUser.admin;
  const planId = currentUser.organization?.subscription?.planId;
  const canCreateMultipleDeals = currentUser.organization?.subscription?.canCreateMultipleDeals;
  const maxHiredCreators = currentUser.organization?.subscription?.effectiveLimits.maxHiredCreators;
  const maxBcaConnections =
    currentUser.organization?.subscription?.effectiveLimits.maxBcaConnections;
  const maxLicensedCreators =
    currentUser.organization?.subscription?.effectiveLimits.maxLicensedCreators;
  const hiredCreators = currentUser.organization?.counters?.hiredCreators;
  const psLicensedCreators = currentUser.organization?.counters?.psLicensedCreators;
  const bcaLicensedCreators = currentUser.organization?.counters?.bcaLicensedCreators;
  const canCreateMultipleDealsValue = canCreateMultipleDeals || admin;

  const campaign = project?.campaign;
  const price = project?.price;
  const currency = project?.currency;
  const balance = campaign?.organization?.paymentAccount?.balance;
  const transactionFee = campaign?.organization?.subscription?.transactionFee;
  const campaignType = campaign?.type;
  const brief = project?.brief;
  const productDelivery = brief?.productDelivery;
  const hasShipment = productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS';
  const bcaFlow = brief?.paidSocialBca;
  const paidSocial = brief?.paidSocial;

  const handleProjectCancelDone = () => {
    dispatch(togglePreloader(false));
  };

  const handleArchiveClick = () => {
    const deleteImmediately = !launch;

    if (deleteImmediately) {
      dispatch(togglePreloader(true));

      archiveProject({ projectId }, handleProjectCancelDone);
    }
  };

  return (
    <div className={`${className} ${styles.root}`} onScroll={onScroll} style={style}>
      <ProjectInfoContextProvider
        subscription={{ maxHiredCreators, maxLicensedCreators, maxBcaConnections, planId }}
        counters={{ hiredCreators, psLicensedCreators, bcaLicensedCreators }}
      >
        <div className={styles.section}>
          <CampaignInfo admin={admin} project={project} />
        </div>
        <ProjectArchivationState id={projectId} className={styles.item} />
        {campaignType !== LONG_TERM && <Stepper projectId={projectId} className={styles.section} />}
        <BloggerInfo
          isAdmin={admin}
          project={project}
          className={styles.section}
          canCreateMultipleDeals={canCreateMultipleDealsValue}
          onCustomListSelectionOpen={onCustomListSelectionOpen}
          onCustomListSelectionClose={onCustomListSelectionClose}
          onRemoveClick={handleArchiveClick}
        />
        {hasShipment && <CreatorShippingDetails projectId={projectId} />}
        {admin && <CreatorContacts project={project} />}
        {campaignType !== LONG_TERM && <ProjectCreatorRating admin={admin} project={project} />}
        <Comment key={project.id} className={styles.section} isAdmin={admin} project={project} />
        <Brief project={project} className={styles.brief} />
        {admin && <AdminAdsManager className={styles.section} projectId={projectId} />}
        {bcaFlow && <BcaActivation className={styles.section} projectId={projectId} />}
        {paidSocial && !bcaFlow && (
          <PsActivation className={styles.section} projectId={projectId} />
        )}
        {launch && <ProjectResults className={styles.section} projectId={project.id} />}
        {admin && (
          <Suspense fallback={<div />}>
            <ContentModeration className={styles.section} projectId={projectId} />
          </Suspense>
        )}
        <Suspense fallback={null}>
          <SetDraftDeadline projectId={projectId} campaignType={campaignType} />
          <SetPublicationDate projectId={projectId} />
          <ProjectCreatorRatingDrawer projectId={projectId} />
          <ReimbursementPayment projectId={projectId} balance={balance} />
          <PriceChangeRequestDrawer
            projectId={projectId}
            projectPrice={price}
            currency={currency}
            balance={balance}
            transactionFee={transactionFee}
          />
          <ExtraPaymentDrawer projectId={projectId} balance={balance} />
        </Suspense>
      </ProjectInfoContextProvider>
    </div>
  );
};

export default ProjectInfo;

/**
 * @generated SignedSource<<a208a52fe7df416e3de39eeea88cf94c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostsStatistic_creator$data = {
  readonly userInsightsData?: {
    readonly media: number | null | undefined;
    readonly mediaAverageImpressions: number | null | undefined;
    readonly mediaAverageReach: number | null | undefined;
    readonly mediaEngagementRate: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "PostsStatistic_creator";
};
export type PostsStatistic_creator$key = {
  readonly " $data"?: PostsStatistic_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostsStatistic_creator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostsStatistic_creator",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstagramUserInsightsData",
          "kind": "LinkedField",
          "name": "userInsightsData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "media",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaAverageReach",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaAverageImpressions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaEngagementRate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InstagramCreator",
      "abstractKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};

(node as any).hash = "7ac54e59281032644bb7be94ad0ef18c";

export default node;

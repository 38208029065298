import React from 'react';
import { graphql, useFragment } from 'react-relay';

import MessageMedia from './MessageMedia/MessageMedia';
import MessageFile from './MessageFile/MessageFile';

import {
  MessageAttachments_message$data,
  MessageAttachments_message$key,
} from 'GraphTypes/MessageAttachments_message.graphql';

interface Props {
  message: MessageAttachments_message$key;
  isOwner: boolean;
  isRead: boolean;
  actionAble: boolean;
  createdAt: string;
}
const MessageAttachments: React.FC<Props> = (props) => {
  const { message, isOwner, actionAble, createdAt, isRead } = props;
  const data = useFragment(
    graphql`
      fragment MessageAttachments_message on ProjectEvent {
        ... on Message {
          id
          attachments(first: 1000) @connection(key: "Message_attachments") {
            edges {
              node {
                id
                createdAt
                file {
                  type
                  ...MessageMedia_file
                }
                ...MessageFile_attachment
              }
            }
          }
        }
      }
    `,
    message
  );
  if (!data) return null;

  const { attachments } = data;

  return (
    <>
      {attachments?.edges?.map((item) => {
        if (!item?.node) return null;

        const attachmentId = item.node.id;
        const fileType = item.node.file.type;

        if (fileType === 'VIDEO' || fileType === 'IMAGE') {
          return (
            <MessageMedia
              key={attachmentId}
              file={item.node.file}
              isRead={isRead}
              isOwner={isOwner}
              createdAt={createdAt}
              actionAble={actionAble}
            />
          );
        } else if (fileType === 'OTHER') {
          return (
            <MessageFile
              key={attachmentId}
              attachment={item.node}
              isOwner={isOwner}
              createdAt={createdAt}
              isRead={isRead}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default MessageAttachments;

export type MessageAttachmentType = NonNullable<
  NonNullable<NonNullable<MessageAttachments_message$data['attachments']>['edges']>[0]
>['node'];

export type MessageAttachmentFileType = NonNullable<MessageAttachmentType>['file'];

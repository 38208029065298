import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AcceptOfferMutation,
  AcceptOfferMutation$data,
  AcceptOfferInput,
} from 'GraphTypes/AcceptOfferMutation.graphql';

const mutation = graphql`
  mutation AcceptOfferMutation($input: AcceptOfferInput!) {
    acceptOffer(input: $input) {
      project {
        id
        campaign {
          type
        }
        paidSocial
        offer {
          acceptance {
            id
          }
        }
        brief {
          ... on V2Brief {
            paidSocialBca
            paidSocialActivationDays
          }
        }
        creator {
          ... on InstagramCreator {
            paidSocialEnabled
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: AcceptOfferInput,
  resolve?: (response: AcceptOfferMutation$data) => void,
  reject?: fnType
) => {
  const {
    offerId,
    price,
    comment,
    shippingAddressId,
    shippingInformation,
    shippingRecipientPhone,
    shippingRecipientFirstName,
    shippingRecipientLastName,
    screeningQuestionAnswer,
    screeningResponses,
    source,
  } = data;
  const id = uuid();
  const variables = {
    input: {
      id,
      offerId,
      price,
      comment,
      shippingAddressId,
      shippingInformation,
      shippingRecipientPhone,
      shippingRecipientFirstName,
      shippingRecipientLastName,
      screeningQuestionAnswer,
      screeningResponses,
      source,
    },
  };

  commitMutation<AcceptOfferMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const newProjects = store.get('client:root:projects(archivedByAdvertiser:false,stage:"NEW")');
      const acceptedProjects = store.get(
        'client:root:projects(archivedByAdvertiser:false,stage:"ACCEPTED")'
      );

      if (newProjects) {
        const count = newProjects.getValue('totalCount');
        const newCount = Number(count) - 1;
        newProjects.setValue(newCount, 'totalCount');
      }

      if (acceptedProjects) {
        const count = acceptedProjects.getValue('totalCount');
        const newCount = Number(count) + 1;
        acceptedProjects.setValue(newCount, 'totalCount');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

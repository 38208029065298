import React from 'react';

import styles from './Bar.css';
import SortableBarItem from './SortableBarItem.tsx';

import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';


const Bar = props => {
  const {
    className,
    items,
    sortable,
    sortableData,
  } = props;

  const list = items.map(item => {
    const {
      id,
      title,
      width,
      style,
      titleTooltip,
      disabled,
      sortableData: itemSortableData,
    } = item;

    if (!title) {
      const itemStyle = width ? { width } : { flexGrow: 1 };

      return <div
        key={id}
        style={itemStyle}
        className={styles.item}
      />;
    }

    const el = (
      <Tooltip id={`bar-${id}`} tooltipMsg={titleTooltip} place="bottom" className={styles.title}>
        <Text
          size="sm"
          display="inline"
          msg={`bar.${title}`}
          className={styles.title}
          color={disabled ? 'grayDog' : undefined}
        />
      </Tooltip>
    );

    return (
      <div
        key={id}
        style={{ ...style, width }}
        className={styles.item}
      >
        {
          sortable && !disabled ?
            <SortableBarItem {...sortableData} item={{ id, title, titleTooltip, ...itemSortableData }} />
            :
            el
        }
      </div>
    );
  });

  return (
    <div className={`${className} ${styles.root}`}>
      <div className={styles.list}>
        {list}
      </div>
    </div>
  );
};

export default Bar;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ShopifyAuth.pcss';
import { postMessage } from './utils';

import Spinner from 'Atoms/Spinner/Spinner';
import { PostMessageTypeEnum, useShopify } from 'Hooks/useShopify';
import { ButtonHandlerTypeEnum } from 'PublicPage/OutcomePage/OutcomePage';
import { OUTCOME_PAGE_ROUTE } from 'Constants/general';

const ShopifyAuth = () => {
  const navigate = useNavigate();
  const { getShopNameFromUrl } = useShopify();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const host = searchParams.get('host') || '';
    const code = searchParams.get('code') || '';
    const hmac = searchParams.get('hmac') || '';
    const timestamp = searchParams.get('timestamp') || '';
    const state = searchParams.get('state') || '';
    const shop = searchParams.get('shop') || '';
    const shopName = getShopNameFromUrl(shop);

    const hasError = !host || !code || !hmac || !timestamp || !state || !shop || !shopName;

    const sendPostMessage = () => {
      postMessage({
        error: hasError,
        type: PostMessageTypeEnum.ConnectToShopify,
        params: {
          host,
          code,
          hmac,
          timestamp,
          state,
          shop,
        },
      });
    };

    if (hasError) {
      const sp = new URLSearchParams();
      sp.set('status', 'error');
      sp.set('handler', ButtonHandlerTypeEnum.CloseWindow);
      sendPostMessage();
      navigate(`${OUTCOME_PAGE_ROUTE}?${sp.toString()}`);
      return;
    }

    sendPostMessage();
  }, []);

  return (
    <div className={styles.root}>
      <Spinner style={{ margin: '40px auto' }} />
    </div>
  );
};

export default ShopifyAuth;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ChangePublicationDeadlineInput,
  ChangePublicationDeadlineMutation,
  ChangePublicationDeadlineMutation$data,
} from 'GraphTypes/ChangePublicationDeadlineMutation.graphql';

const mutation = graphql`
  mutation ChangePublicationDeadlineMutation($input: ChangePublicationDeadlineInput!) {
    changePublicationDeadline(input: $input) {
      project {
        deadline {
          publicationDate
          draftDate
        }
      }
      eventsConnection {
        edges {
          node {
            __typename
            id
            ... on PublicationDeadlineChange {
              publicationDate
            }
          }
        }
      }
      project {
        deadline {
          publicationDate
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ChangePublicationDeadlineInput,
  resolve?: (response: ChangePublicationDeadlineMutation$data) => void,
  reject?: fnType
) => {
  const { publicationDate, projectId } = data;

  const id = uuid();
  const variables = {
    input: {
      id,
      publicationDate,
      projectId,
    },
  };

  commitMutation<ChangePublicationDeadlineMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

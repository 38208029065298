import React from 'react';
import { useLocation } from 'react-router-dom';
import track from 'react-tracking';

import DownloadAppSignIn from '../DownloadAppSignIn/DownloadAppSignIn';

import Page from 'Templates/Page/Page';
import { LANDING_LINK, SIGNUP_COMPLETE_CREATOR_ROUTE } from 'Constants/general';

const SignUp: React.FC = () => {
  const { pathname } = useLocation();

  if (pathname === SIGNUP_COMPLETE_CREATOR_ROUTE) {
    return (
      <Page>
        <DownloadAppSignIn />
      </Page>
    );
  }

  return null;
};

export default track(
  { page: 'signup' },
  {
    dispatchOnMount: () =>
      (document as any).refferer === LANDING_LINK ? { event: 'landing_signup' } : {},
  }
)(SignUp);

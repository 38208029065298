import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ActivatePaidSocialForInsenseInput,
  ActivatePaidSocialForInsenseMutation$data,
  ActivatePaidSocialForInsenseMutation,
} from 'GraphTypes/ActivatePaidSocialForInsenseMutation.graphql';

type ActivatePaidSocialForInsenseArgs = ActivatePaidSocialForInsenseInput & { creatorId: string };

const mutation = graphql`
  mutation ActivatePaidSocialForInsenseMutation($input: ActivatePaidSocialForInsenseInput!) {
    activatePaidSocialForInsense(input: $input) {
      clientMutationId
      paidSocialActivationForInsense {
        createdAt
        expiresAt
        facebookPageId
        facebookPageName
        facebookPagePictureUrl
        facebookBusinessId
        facebookBusinessName
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ActivatePaidSocialForInsenseArgs,
  resolve?: (response: ActivatePaidSocialForInsenseMutation$data) => void,
  reject?: fnType
) => {
  const { projectId, creatorId } = data;
  const id = uuid();
  const variables = {
    input: {
      id,
      projectId,
    },
  };
  commitMutation<ActivatePaidSocialForInsenseMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const activation = store.get(id);
      if (activation) {
        const creator = store.get(creatorId);
        if (!creator) return;
        creator.setLinkedRecord(activation, 'paidSocialActivationForInsense');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

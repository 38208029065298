import React, { useMemo, useCallback, useContext, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';

import { createDate } from 'Util/dateCreator';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ProjectDraftDate_project$key } from 'GraphTypes/ProjectDraftDate_project.graphql';

interface Props {
  project: ProjectDraftDate_project$key;
}

const ProjectDraftDate: React.FC<Props> = (props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment ProjectDraftDate_project on Project {
        id
        launch {
          id
        }
        archivedByAdvertiser: archivation(side: ADVERTISER) {
          id
        }
        deadline {
          draftDate
        }
        completion {
          id
        }
        campaign {
          type
        }
      }
    `,
    project
  );

  const { openDrawer } = useContext(DrawerContext);

  useEffect(() => {
    if (
      data?.launch &&
      !data?.completion &&
      !data?.archivedByAdvertiser &&
      !(data?.deadline && data.deadline.draftDate)
    ) {
      openDrawer(`project-draft-deadline-${data?.id}`);
    }
  }, []);

  const draftDate = data?.deadline?.draftDate;
  const completionId = data?.completion?.id;

  const dateText = useMemo(() => {
    return createDate(draftDate) || undefined;
  }, [draftDate]);

  const handleClick = useCallback(() => {
    openDrawer(`project-draft-deadline-${data?.id}`, true);
  }, [data?.id]);

  if (!data) {
    return null;
  }

  if (!draftDate) return null;

  return (
    <AlterButton
      text={dateText}
      type="grey"
      leftElement={!completionId ? <Icon name="Edit" /> : undefined}
      onClick={!completionId ? handleClick : undefined}
    />
  );
};

export default ProjectDraftDate;

export const copyText = (text) => {
  setTimeout(() => {
    navigator.clipboard.writeText(text).then(
      function () {
        //
      },
      function (e) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    );
  }, 0);
};

/**
 * @generated SignedSource<<b8e575f115f551c758dabf21d520b880>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePortfolioItemInput = {
  caption?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  creatorId: string;
  fileId: string;
  id?: string | null | undefined;
  portfolioCategoryIds?: ReadonlyArray<string> | null | undefined;
  portfolioTypeIds?: ReadonlyArray<string> | null | undefined;
};
export type CreatePortfolioItemMutation$variables = {
  input: CreatePortfolioItemInput;
};
export type CreatePortfolioItemMutation$data = {
  readonly createPortfolioItem: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type CreatePortfolioItemMutation = {
  response: CreatePortfolioItemMutation$data;
  variables: CreatePortfolioItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePortfolioItemPayload",
    "kind": "LinkedField",
    "name": "createPortfolioItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePortfolioItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePortfolioItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e712cbde2155a35a38831dda3f7cc78",
    "id": null,
    "metadata": {},
    "name": "CreatePortfolioItemMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePortfolioItemMutation(\n  $input: CreatePortfolioItemInput!\n) {\n  createPortfolioItem(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2e1b2e7c39a42d0734f6658c7672185";

export default node;

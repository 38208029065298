import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './Tooltip.css';

const Tooltip = (props) => {
  const {
    className,
    id,
    place,
    children,
    delayShow,
    delayHide,
    customType = 'light',
    color,
    selfStyle = false,
  } = props;

  if (children) {
    const placePosition = place ? place : 'right';

    return (
      <ReactTooltip
        className={`${className} ${selfStyle ? '' : styles.tooltip} ${
          color ? styles[`color-${color}`] : ''
        }`}
        id={id}
        place={placePosition}
        effect="solid"
        delayShow={delayShow !== undefined ? delayShow : 600}
        delayShow={delayHide !== undefined ? delayHide : 300}
        type={customType}
      >
        <div className={selfStyle ? styles['tooltip-content'] : ''}>{children}</div>
      </ReactTooltip>
    );
  }

  return (
    <ReactTooltip
      id={id}
      place={place ? place : 'bottom'}
      type="dark"
      effect="solid"
      delayShow={delayShow !== undefined ? delayShow : 600}
    />
  );
};

export default Tooltip;

Tooltip.defaultProps = {
  delayShow: 0,
};

export const fields = {
  app: 'app',
  page: 'page',
  section: 'section',
  subsection: 'subsection',
  element: 'element',
  event: 'event',
  goal: 'goal',
  payload: 'payload',
};

export const events = {
  identity: 'identity',
  inviteSent: 'invite_sent',
  inviteSuccess: 'invite_success',
};

export const amplitudeFields = {
  event: 'amplitudeEvent',
  eventId: 'event_id',
  eventCat: 'event_cat',
  eventName: 'event_name',
  eventParam: 'event_param',
};
import { takeLatest, select, put } from 'redux-saga/effects';

import {
  StartCreatorsBulkAddingType,
  removeCreatorsBulkAdding,
  toggleCreatorsBulkAdding,
  startCreatorsBulkAdding,
} from 'Actions/CreatorSearch.Actions';
import { StateProps } from 'Reducers/CreatorsSearch.Reducer';
import createProject from 'Mutations/CreateProject.Mutation';
import markCreatorSearchResultAsViewed from 'Mutations/MarkCreatorSearchResultAsViewed.Mutation';
import { START_CREATORS_BULK_ADDING } from 'Constants/index';
import ErrorHandler from 'Util/errorHandler';

type StartCreatorsBulkAddingSaga = (
  action: StartCreatorsBulkAddingType
) => Generator<any, void, any>;
export const startCreatorsBulkAddingSaga: StartCreatorsBulkAddingSaga = function* (action) {
  const { campaignId } = action.payload;

  const creatorIds = yield select((state: StateProps) => {
    return state.root.creatorSearch.creatorIdsForAdding;
  });

  try {
    yield put(toggleCreatorsBulkAdding(true));
    for (let index = 0; index < creatorIds.length; index++) {
      const startProcess = yield select((state: StateProps) => {
        return state.root.creatorSearch.startProcess;
      });
      if (!startProcess) {
        break;
      }
      const creatorId = creatorIds[index];
      yield new Promise((resolve, reject) => {
        createProject({ creatorId, campaignId }, resolve, reject);
      });
      yield new Promise((resolve, reject) => {
        markCreatorSearchResultAsViewed({ creatorId, campaignId }, resolve, reject);
      });
      yield put(removeCreatorsBulkAdding(creatorId));
    }

    const newCreatorIds = yield select((state: StateProps) => {
      return state.root.creatorSearch.creatorIdsForAdding;
    });

    const newStartProcess = yield select((state: StateProps) => {
      return state.root.creatorSearch.startProcess;
    });

    if (newCreatorIds.length > 0 && newStartProcess) {
      yield put(startCreatorsBulkAdding({ campaignId }));
    } else {
      yield put(toggleCreatorsBulkAdding(false));
    }
  } catch (error) {
    ErrorHandler.error('Crash while bulk creators add', { error });
  }
};

export default function* watchCreatorSearch() {
  yield takeLatest(START_CREATORS_BULK_ADDING, startCreatorsBulkAddingSaga);
}

import React, {
  useEffect,
  useCallback,
  MouseEventHandler,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import classnames from 'classnames';

import type { GuideTourId, GuideTourStepName } from '../types';
import { GuideTourBackdrop } from '../GuideTourBackdrop/GuideTourBackdrop';

import styles from './GuideTourTooltip.pcss';

import { amplitude } from 'Helpers/amplitude';
import TooltipBase from 'Components/ui/TooltipBase';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import TextButton from 'Components/ui/TextButton/TextButton';
import { TooltipArrowPosition } from 'Components/ui/types';

type Props = {
  guideTourId: GuideTourId;
  stepName: GuideTourStepName;
  visible: boolean;
  msg?: string;
  renderText?: (props: Props) => React.ReactNode;
  btnMsg?: string;
  className?: string;
  hiddenArrow?: boolean;
  hideCloseButton?: boolean;
  hideNextButton?: boolean;
  arrowPosition?: TooltipArrowPosition;
  backdrop?: boolean;
  size?: TooltipBaseProps['size'];
  onClose?: (
    e: MouseEvent<HTMLButtonElement>,
    params: { guideTourId: GuideTourId; stepName: GuideTourStepName }
  ) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onComplete?: (e: MouseEvent<HTMLButtonElement>, params: { guideTourId: GuideTourId }) => void;
};

const GuideTourTooltip: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    visible,
    className,
    guideTourId,
    msg,
    btnMsg,
    stepName,
    hiddenArrow,
    hideCloseButton,
    hideNextButton,
    backdrop,
    arrowPosition = 'right-top',
    size = 'm',
    onClose,
    onClick,
    onComplete,
    renderText,
  } = props;

  useEffect(() => {
    if (visible) {
      sendEvent('show');
    }
  }, [visible]);

  const sendEvent = (event: string) => {
    amplitude.sendEvent({
      id: '132',
      category: 'guide_tip_tour',
      name: guideTourId,
      param: {
        step: stepName,
        event,
      },
    });
  };

  const handleClose = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onClose?.(e, { guideTourId, stepName });
      sendEvent('close');
    },
    [onClose, guideTourId, stepName]
  );

  const handleComplete = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onComplete?.(e, { guideTourId });
      sendEvent('completeTour');
    },
    [onComplete, guideTourId]
  );

  return (
    <>
      {visible && backdrop && <GuideTourBackdrop className={styles.backdrop} />}
      {children}
      {visible && (
        <TooltipBase
          className={classnames(styles.root, className, {
            [styles.canComplete]: !!onComplete,
            [styles.withCloseButton]: !hideCloseButton,
          })}
          arrowPosition={arrowPosition}
          size={size}
          hiddenArrow={hiddenArrow}
          onClick={onClick}
        >
          {!hideCloseButton && (
            <Icon
              name="Close-small"
              size={16}
              className={styles.close}
              handleClick={handleComplete}
            />
          )}
          <div className={styles.contentWrapper}>
            {renderText ? (
              renderText(props)
            ) : (
              <Text msg={msg} type="sm" className={styles.text} color="white" />
            )}
            {!hideNextButton && (
              <TextButton
                msg={btnMsg || 'guide_tours.buttons.got_it'}
                className={styles.textButton}
                onClick={handleClose}
                color="pink"
              />
            )}
          </div>
        </TooltipBase>
      )}
    </>
  );
};

type GuideTourTooltipProps = React.ComponentProps<typeof GuideTourTooltip>;
type TooltipBaseProps = React.ComponentProps<typeof TooltipBase>;

export { GuideTourTooltip };
export type { GuideTourTooltipProps };

import React from 'react';

import { amplitude } from 'Helpers/amplitude';
import DropDown from 'Molecules/DropDown/DropDown';
import { GUIDES_AND_FAQ_URL_BRANDS, GUIDES_AND_FAQ_URL_CREATORS } from 'Constants/general';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import useDashly from 'Hooks/useDashly';

type Props = {
  classes?: {
    button?: string;
  };
  isContractor?: boolean;
};

const Help: React.FC<Props> = (props) => {
  const { classes, isContractor } = props;
  const { open: openDashly } = useDashly();

  const handleDashlyClick = () => {
    openDashly();
  };

  const handleOnboardingClick = () => {
    isContractor
      ? window.open(GUIDES_AND_FAQ_URL_CREATORS, '_blank')
      : window.open(GUIDES_AND_FAQ_URL_BRANDS, '_blank');
  };

  const handleHelpClick = () => {
    amplitude.sendEvent({ id: '35', category: 'header', name: 'help_click', param: {} });
  };

  const button = (
    <AlterButton
      icon="Support-help"
      onClick={handleHelpClick}
      msg="header.help"
      className={classes?.button}
    />
  );

  const menuList = [
    {
      id: 'chat',
      textData: {
        textData: { msg: 'header.chat_dashly' },
      },
      onClick: () => {
        amplitude.sendEvent({ id: '36', category: 'header', name: 'help_chat_click', param: {} });
        handleDashlyClick();
      },
    },
    {
      id: 'how_it_works',
      textData: {
        textData: { msg: 'header.how_insense_works' },
      },
      onClick: () => {
        amplitude.sendEvent({
          id: '37',
          category: 'header',
          name: 'how_insense_works_click',
          param: {},
        });
        handleOnboardingClick();
      },
    },
  ];

  return <DropDown anchor={button} list={menuList} selfClose={true} autoHeight={true} />;
};

export default Help;

/**
 * @generated SignedSource<<6b855d5592ea4edac702bdb5dbe504a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ErrorType = "ALREADY_EXIST_ERROR" | "CREATOR_ALREADY_OWNED_ERROR" | "NOT_CLAIMED" | "NO_CREATOR" | "SIGNIN_ERROR" | "SIGNUP_ERROR" | "VALIDATION_ERROR" | "%future added value";
export type NotFoundErrorType = "NO_ACCOUNT" | "NO_CAMPAIGN" | "NO_CAMPAIGN_SUMMARY" | "NO_CREATOR" | "NO_CREATORS" | "NO_CREATOR_BLOCKING" | "NO_CUSTOM_LIST" | "NO_DEADLINE_CHANGE_REQUEST" | "NO_MEDIAPLAN" | "NO_MESSAGE" | "NO_OFFER" | "NO_ORGANIZATION" | "NO_PAYMENT_ACCOUNT" | "NO_PAYOUT_PRICE" | "NO_PROJECT" | "NO_PROVINCE" | "NO_SHOPIFY_AUTHORIZATION" | "NO_USER" | "%future added value";
export type ConnectCampaignToShopifyInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  shopifyAuthorizationId: string;
};
export type ConnectCampaignToShopifyMutation$variables = {
  input: ConnectCampaignToShopifyInput;
};
export type ConnectCampaignToShopifyMutation$data = {
  readonly connectCampaignToShopify: {
    readonly __typename: "ConnectCampaignToShopifyPayload";
    readonly campaign: {
      readonly canUpdateShopifyAuthorization: boolean;
      readonly id: string;
      readonly organization: {
        readonly id: string;
        readonly shopifyAuthorizations: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly reauthenticationRequired: boolean;
              readonly shop: {
                readonly name: string;
              };
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly shopifyAuthorization: {
        readonly id: string;
        readonly reauthenticationRequired: boolean;
        readonly shop: {
          readonly name: string;
          readonly url: string;
        };
      } | null | undefined;
    };
  } | {
    readonly __typename: "NotFoundError";
    readonly message: string;
    readonly subtype: NotFoundErrorType;
    readonly type: ErrorType;
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
    readonly type: ErrorType;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type ConnectCampaignToShopifyMutation = {
  response: ConnectCampaignToShopifyMutation$data;
  variables: ConnectCampaignToShopifyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdateShopifyAuthorization",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reauthenticationRequired",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtype",
      "storageKey": null
    },
    (v9/*: any*/)
  ],
  "type": "NotFoundError",
  "abstractKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "type": "ValidationError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectCampaignToShopifyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "connectCampaignToShopify",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShopifyAuthorization",
                    "kind": "LinkedField",
                    "name": "shopifyAuthorization",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Shopify_Shop",
                        "kind": "LinkedField",
                        "name": "shop",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopifyAuthorizationConnection",
                        "kind": "LinkedField",
                        "name": "shopifyAuthorizations",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopifyAuthorizationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShopifyAuthorization",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Shopify_Shop",
                                    "kind": "LinkedField",
                                    "name": "shop",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ConnectCampaignToShopifyPayload",
            "abstractKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConnectCampaignToShopifyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "connectCampaignToShopify",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShopifyAuthorization",
                    "kind": "LinkedField",
                    "name": "shopifyAuthorization",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Shopify_Shop",
                        "kind": "LinkedField",
                        "name": "shop",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopifyAuthorizationConnection",
                        "kind": "LinkedField",
                        "name": "shopifyAuthorizations",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopifyAuthorizationEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShopifyAuthorization",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Shopify_Shop",
                                    "kind": "LinkedField",
                                    "name": "shop",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ConnectCampaignToShopifyPayload",
            "abstractKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e41bf4d803d2fc2e81bcc5bfac37cfb",
    "id": null,
    "metadata": {},
    "name": "ConnectCampaignToShopifyMutation",
    "operationKind": "mutation",
    "text": "mutation ConnectCampaignToShopifyMutation(\n  $input: ConnectCampaignToShopifyInput!\n) {\n  connectCampaignToShopify(input: $input) {\n    __typename\n    ... on ConnectCampaignToShopifyPayload {\n      campaign {\n        id\n        canUpdateShopifyAuthorization\n        shopifyAuthorization {\n          reauthenticationRequired\n          shop {\n            name\n            url\n            id\n          }\n          id\n        }\n        organization {\n          id\n          shopifyAuthorizations {\n            edges {\n              node {\n                id\n                reauthenticationRequired\n                shop {\n                  name\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    ... on NotFoundError {\n      message\n      subtype\n      type\n    }\n    ... on ValidationError {\n      message\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "07acce03e16ab63c7a6f8ce62ff292a0";

export default node;

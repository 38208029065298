/**
 * @generated SignedSource<<bd98ed37e0ca76f818b153bc7d63d01c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentCreationStep_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ContentCreationAdvertiserSide_project" | "ContentCreationContractorSide_project">;
  readonly " $fragmentType": "ContentCreationStep_project";
};
export type ContentCreationStep_project$key = {
  readonly " $data"?: ContentCreationStep_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCreationStep_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCreationStep_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentCreationAdvertiserSide_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentCreationContractorSide_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "d0398e2df586e5e52c590e0d9b9b062e";

export default node;

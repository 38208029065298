import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ArchiveProjectInput,
  ArchiveProjectMutation,
  ArchiveProjectMutation$data,
  ArchiveProjectMutation$variables,
} from 'GraphTypes/ArchiveProjectMutation.graphql';
import { ADVERTISER } from 'Constants/general';

const mutation = graphql`
  mutation ArchiveProjectMutation(
    $input: ArchiveProjectInput!
    $stages: [AdvertiserProjectStage!]
    $archivedStages: [AdvertiserProjectStage!]
    $createdByAdmin: Boolean
  ) {
    archiveProject(input: $input) {
      project {
        archivation(side: ADVERTISER) {
          id
        }
        archivationRequest {
          id
          side
          acceptance {
            id
          }
          rejection {
            id
          }
        }
        campaign {
          commonEvents: projectEvents(archived: false) {
            unreadCount
          }
          archiveEvents: projectEvents(archived: true) {
            unreadCount
          }
          archived: projects(stages: $archivedStages, archived: true, orderBy: LAST_ADDED) {
            totalCount
          }
          unsentProjects: projects(
            stages: [UNSENT, UNREAD, READ]
            archived: false
            orderBy: LAST_ADDED
            createdByAdmin: $createdByAdmin
          ) {
            summary {
              totalCount
            }
          }
          projects(stages: $stages, archived: false, orderBy: LAST_ADDED) {
            totalCount
            summary {
              totalPrice
            }
          }
          organization {
            paymentAccount {
              balance
              currency
            }
          }
          projectsSummary: projects(archived: false, orderBy: LAST_ADDED) {
            totalCount
            summary {
              totalPrice
              currency
            }
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface Data {
  campaignId?: string;
  creatorsStages?: ArchiveProjectMutation$variables['stages'];
  archivedStages?: ArchiveProjectMutation$variables['archivedStages'];
  isAdmin?: boolean;
}

export default (
  data: ArchiveProjectInput & Data,
  resolve?: (response: ArchiveProjectMutation$data) => void,
  reject?: fnType
) => {
  const { projectId, campaignId, creatorsStages, archivedStages, comment, reason, isAdmin } = data;

  const additionalData = isAdmin ? { createdByAdmin: null } : { createdByAdmin: false };

  const id = uuid();
  const variables: ArchiveProjectMutation$variables = {
    input: {
      id,
      projectId,
      side: ADVERTISER,
      comment,
      reason,
    },
    stages: creatorsStages,
    archivedStages,
    ...additionalData,
  };

  commitMutation<ArchiveProjectMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (campaignId) {
        const campaign = store.get(campaignId);

        if (!campaign) return;

        const creatorsForPayoutProjects = ConnectionHandler.getConnection(
          campaign,
          'CreatorsForPaymentList_projects'
        );

        if (creatorsForPayoutProjects) {
          ConnectionHandler.deleteNode(creatorsForPayoutProjects, projectId);
        }

        const payoutProjects = ConnectionHandler.getConnection(
          campaign,
          'PayoutProjectsList_projects'
        );

        if (payoutProjects) {
          ConnectionHandler.deleteNode(payoutProjects, projectId);
        }

        const projects = ConnectionHandler.getConnection(campaign, 'Projects_projects');

        if (projects) {
          ConnectionHandler.deleteNode(projects, projectId);
        }
      }
    },
    updater: (store) => {
      if (campaignId) {
        const campaign = store.get(campaignId);

        if (!campaign) return;

        const creatorsForPayoutProjects = ConnectionHandler.getConnection(
          campaign,
          'CreatorsForPaymentList_projects'
        );

        if (creatorsForPayoutProjects) {
          ConnectionHandler.deleteNode(creatorsForPayoutProjects, projectId);
        }

        const payoutProjects = ConnectionHandler.getConnection(
          campaign,
          'PayoutProjectsList_projects'
        );

        if (payoutProjects) {
          ConnectionHandler.deleteNode(payoutProjects, projectId);
        }

        const projects = ConnectionHandler.getConnection(campaign, 'Projects_projects');

        if (projects) {
          ConnectionHandler.deleteNode(projects, projectId);
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { takeLatest, put, select, delay, call } from 'redux-saga/effects';
import find from 'lodash/find';

import {
  TOGGLE_CREATOR_SHORTLISTED_STATUS,
  SIGNUP_AND_APPLY_PROJECT,
  ACCEPT_OFFER,
} from 'Constants';
import { PAYOUT } from 'Constants/general';
import { getProjectLink } from 'Util/links';
import { statusNotHiredData, applicationSentData } from 'Constants/messageModal';
import { sendEventForAmplitude } from 'Analytics/engines/GoogleTagManager';
import { setModal, toggleModal } from 'Actions/Modal.Actions';
import { addOfferReactionData } from 'Actions/Creator.Actions';
import { toggleShowHeader } from 'Actions/UI.Actions';
import addCreatorToCampaignShortlist from 'Mutations/AddCreatorToCampaignShortlist.Mutation';
import removeCreatorFromCampaignShortlist from 'Mutations/RemoveCreatorFromCampaignShortlist.Mutation';
import updateContractorProfile from 'Mutations/UpdateContractorProfile.Mutation';
import acceptOffer from 'Mutations/AcceptOffer.Mutation';
import rejectOffer from 'Mutations/RejectOffer.Mutation';
import { modalName } from 'Types/modals';
import { CreatorSource } from 'Types/enums';
import { waitUntil } from 'Util/waitUntil';
import ErrorHandler from 'Util/errorHandler';

export function* toggleCreatorShortlistedStatusSaga(action) {
  const { campaignId, creatorId, shortlisted, projectId } = action.payload;
  try {
    if (shortlisted) {
      yield new Promise((resolve, reject) => {
        removeCreatorFromCampaignShortlist({ campaignId, creatorId, projectId }, resolve, reject);
      });
    } else {
      yield new Promise((resolve, reject) => {
        addCreatorToCampaignShortlist({ campaignId, creatorId, projectId }, resolve, reject);
      });
    }
  } catch (error) {
    ErrorHandler.error('Crash while setting creator`s shortlisted status', { shortlisted, error });
  }
}

export function* signupAndApplyOfferSaga(action) {
  const {
    email,
    password,
    contractorId,
    countryId,
    price,
    comment,
    offerId,
    creator,
    acceptOffer: needAccept = true,
  } = action.payload;

  try {
    //const signupData = yield new Promise((resolve, reject) => {
    //@TODO Auth0
    //signup({ email, password, userType: CONTRACTOR }, resolve, reject);
    //});
    //const signinData = yield new Promise((resolve, reject) => {
    //@TODO Auth0
    //signin({ email, password, userType: CONTRACTOR }, resolve, reject);
    //});
    //const { jwt } = signinData.signin.session;
    //ls.set('jwt', jwt);
    //if (!(signupData.signup.user && signupData.signup.user.contractorProfile)) return;
    if (!contractorId) return;
    sendEventForAmplitude({
      event_id: 'd-v1-e4',
      event_cat: 'pageview',
      event_name: 'creator_sign_up_success',
      event_param: { type: 'public_brief' },
    });

    //const contractorId = signupData.signup.user.contractorProfile.id;
    yield new Promise((resolve, reject) => {
      updateContractorProfile({ id: contractorId, countryId }, resolve, reject);
    });
    yield put(
      addOfferReactionData({ offer: { offerId, comment, price, acceptOffer: needAccept } })
    );
    yield put(toggleShowHeader(true));
    sendEventForAmplitude({
      event_id: 'd-v1-e13',
      event_cat: 'brief',
      event_name: 'offer_reaction_modal_complete',
      event_param: { type: 'signup' },
    });
    yield delay(500);
    yield put(setModal(modalName.CREATOR_PROFILE_CONFIRMATION, { creator, offerId }));
  } catch (error) {
    ErrorHandler.error('Crash while signup contractor and apply offer', { error });
  }
}

export function* acceptOfferSaga(action) {
  const { offerId, username, source, creatorId, navigate } = action.payload;

  try {
    const offers = yield select((state) => {
      return state.root.creator.offers;
    });
    const offerData = find(offers, (item) => item.offerId === offerId);
    if (offerData) {
      const { price, comment, acceptOffer: needAccept } = offerData;
      if (needAccept === false) {
        yield new Promise((resolve, reject) => {
          rejectOffer({ offerId, comment }, resolve, reject);
        });
        yield put(toggleModal());

        return;
      }
      const data = yield new Promise((resolve, reject) => {
        acceptOffer({ offerId, price: Number(price), comment, source }, resolve, reject);
      });
      const needPs = data.acceptOffer.project.paidSocial;
      const projectId = data.acceptOffer.project.id;
      const paidSocialBca = data.acceptOffer.project.brief.paidSocialBca;
      const psDays = data.acceptOffer.project.brief.paidSocialActivationDays;
      const paidSocialEnabled = data.acceptOffer.project.creator.paidSocialEnabled;
      const campaignType = data.acceptOffer.project.campaign.type;

      if (campaignType === PAYOUT) {
        return;
      }

      if (source === CreatorSource.OUTREACH) {
        let isModalClosed = false;
        yield put(
          setModal(modalName.CREATOR_PROFILE_EDITOR, {
            id: creatorId,
            disableRedirect: true,
            onDoneClick: ({ onCloseModal }) => {
              onCloseModal?.();
              isModalClosed = true;
            },
          })
        );
        yield waitUntil(() => {
          return isModalClosed === true;
        });
      }

      const link = getProjectLink({ projectId });
      yield call(navigate, link);

      yield delay(2000);

      if (needPs && !paidSocialEnabled) {
        yield put(
          setModal(modalName.CONNECT_PS, { username, projectId, psDays, psBca: paidSocialBca })
        );
      } else {
        yield put(
          setModal(modalName.MESSAGE, {
            ...applicationSentData,
            closeDisable: true,
            nextModal: {
              modalName: modalName.MESSAGE,
              modalDtata: { ...statusNotHiredData, closeDisable: true, closeOnClick: true },
            },
          })
        );
      }
    }
  } catch (error) {
    ErrorHandler.error('Crash while apply offer', { error });
  }
}

export default function* watchCreator() {
  yield takeLatest(TOGGLE_CREATOR_SHORTLISTED_STATUS, toggleCreatorShortlistedStatusSaga);
  yield takeLatest(SIGNUP_AND_APPLY_PROJECT, signupAndApplyOfferSaga);
  yield takeLatest(ACCEPT_OFFER, acceptOfferSaga);
}

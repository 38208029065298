import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './Item.css';

import Text from 'Atoms/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import Tooltip from 'Atoms/Tooltip/Tooltip';

class Item extends Component {
  constructor(props) {
    super(props);

    this.brandItem = this.brandItem.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
  }

  onEditClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const {
      data: { edit, logo, name, id, summary, websiteUrl },
    } = this.props;
    edit({ logo, name, id, summary, websiteUrl, isEdit: true });
  }

  brandItem() {
    const {
      label,
      data: { logo, value, addNew, additionalLabel, additionalLabelValues },
    } = this.props;

    if (!value) {
      return (
        <div className={styles.content} onClick={addNew}>
          <Text msg={label} />
          {additionalLabel && (
            <Text
              color="grayDoog"
              msg={additionalLabel}
              values={additionalLabelValues}
              className={styles.additionalLabel}
            />
          )}
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <Avatar size="24" src={logo === 'blank' ? '' : logo} className={styles.logo} />
          <Text className={styles.label} text={label} />
        </div>
      </div>
    );
  }

  baItem() {
    const { data } = this.props;
    const { logo, name } = data;
    const logoSrc = logo === 'blank' ? '' : logo;

    return (
      <div className={styles.baContainer}>
        <div className={styles.innerContainer}>
          <Avatar size="24" src={logoSrc} className={styles.logo} />
          <Text className={styles.label} text={name} align="left" />
        </div>
      </div>
    );
  }

  contentTypeItem() {
    const {
      label,
      data: { icon, value, type, tooltip, onMouseOver, onMouseLeave },
    } = this.props;

    const onItemMouseOver = () => {
      if (onMouseOver) {
        onMouseOver(type, value);
      }
    };

    const onItemMouseLeave = () => {
      if (onMouseLeave) {
        onMouseLeave(type, value);
      }
    };

    return (
      <Tooltip id={label} tooltipMsg={tooltip} place="right">
        <div
          className={styles.content}
          onMouseOver={onItemMouseOver}
          onMouseLeave={onItemMouseLeave}
        >
          {icon && <img src={icon} className={styles.icon} />}
          <Text msg={label} className={styles.content} />
        </div>
      </Tooltip>
    );
  }

  simpleItem(type = null) {
    const { label } = this.props;

    let data = {};

    if (type === 'text') {
      data = { text: label };
    } else {
      data = { msg: label };
    }

    return (
      <div className={styles.content}>
        <Text {...data} className={styles.content} />
      </div>
    );
  }

  getContent() {
    const {
      data: { type },
    } = this.props;
    switch (type) {
      case 'brandId': {
        return this.brandItem();
      }
      case 'baId': {
        return this.baItem();
      }
      case 'type':
      case 'contentType':
      case 'v2ContentPlacement':
      case 'contentPlacement': {
        return this.contentTypeItem();
      }
      case 'country':
      case 'planId':
      case 'currency':
      case 'categories':
      case 'tags':
        return this.simpleItem('text');
      default:
        return this.simpleItem();
    }
  }

  render() {
    const {
      className,
      innerProps,
      isSelected,
      data: { type },
    } = this.props;

    const content = this.getContent();
    const style = type && styles[type] ? type : '';

    return (
      <div
        className={classnames(className, styles.root, style, { [styles.isSelected]: isSelected })}
        {...innerProps}
      >
        {content}
      </div>
    );
  }
}

export default Item;

import React, { FC } from 'react';

import styles from './Chart.pcss';

import Text from 'Components/ui/Text/Text';

interface Props {
  titleMsg?: string;
}

const ChartLoading: FC<Props> = ({ titleMsg }) => (
  <div>
    <div className={styles.root}>
      <div className={styles.content}>
        {titleMsg && <Text msg={titleMsg} />}
        <div className={styles.headerLoading}>
          <div className={styles.titleBase}>
            <div className={styles.titlePlaceholder} />
            <div className={styles.descriptionPlaceholder} />
          </div>
          <div className={styles.titleBase}>
            <div className={styles.titlePlaceholder} />
            <div className={styles.descriptionPlaceholder} />
          </div>
          <div className={styles.titleBase}>
            <div className={styles.titlePlaceholder} />
            <div className={styles.descriptionPlaceholder} />
          </div>
          <div className={styles.titleBase}>
            <div className={styles.titlePlaceholder} />
            <div className={styles.descriptionPlaceholder} />
          </div>
        </div>
      </div>
      <div className={styles.graphContainerStub}>
        <div className={styles.lineContainer}>
          <div className={styles.yAxisLabel} />
          <div className={styles.line} />
        </div>
        <div className={styles.lineContainer}>
          <div className={styles.yAxisLabel} />
          <div className={styles.line} />
        </div>
        <div className={styles.footer}>
          <div className={styles.line} />
          <div className={styles.xLabelContainer}>
            <div className={styles.xAxisLabel} />
            <div className={styles.xAxisLabel} />
            <div className={styles.xAxisLabel} />
            <div className={styles.xAxisLabel} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ChartLoading;

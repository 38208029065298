import React, { useContext, useMemo } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import classnames from 'classnames';
import findIndex from 'lodash/findIndex';
import compact from 'lodash/compact';

import styles from './Stepper.pcss';
import OnboardingStep from './OnboardingStep/OnboardingStep';
import ContentCreationStep from './ContentCreationStep/ContentCreationStep';
import PostingStep from './PostingStep/PostingStep';
import ContentReviewStep from './ContentReviewStep/ContentReviewStep';

import TextButton from 'Components/ui/TextButton/TextButton';
import Spinner from 'Atoms/Spinner/Spinner';
import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import { useMarkContractorAsUnresponsiveQueryMutation } from 'Mutations/MarkContractorAsUnresponsive.Mutation';
import { StepperQuery as QueryType, StepperQuery$data } from 'GraphTypes/StepperQuery.graphql';
import { AddPostingToThisCollaborationDrawer } from 'Modal/advertiser/AddPostingToThisCollaborationDrawer';
import { ConnectToAdsManagerForThisCollaboration } from 'Modal/advertiser/ConnectToAdsManagerForThisCollaboration';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CONTENT_CREATION, ORGANIC_POSTING, PRODUCT_SEEDING } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

const STALE_DAYS_FOR_REPORT = 3;

const StepperQuery = graphql`
  query StepperQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      advertiserStage
      creator {
        id
        ownership {
          owner {
            unresponsive
          }
        }
      }
      businessDaysSinceLatestCreatorEvent
      campaign {
        type
        postingType
        publishingRequired
      }
      contentReview {
        status
      }
      archivationState: archivation {
        id
      }
      offer {
        acceptance {
          id
        }
      }
      productShipmentCompletion {
        id
      }
      launch {
        id
      }
      completion {
        id
      }
      ...OnboardingStep_project
      ...ContentCreationStep_project
      ...PostingStep_project
      ...ContentReviewStep_project
    }
    currentUser {
      type
      organization {
        subscription {
          canCreateDeposits
          canUsePaidSocial
        }
      }
    }
  }
`;

interface Props {
  projectId: string;
  className?: string;
}

const Stepper: React.FC<Props> = (props) => {
  const { projectId, className } = props;

  const data = useLazyLoadQuery<QueryType>(StepperQuery, { projectId });

  return data ? (
    <StepperContent {...data} className={className} />
  ) : (
    <div className={className}>
      <Spinner className={styles.preloader} />
    </div>
  );
};

const StepperContent: React.FC<StepperQuery$data & Pick<Props, 'className'>> = (props) => {
  const { className, project } = props;
  const { openDrawer } = useContext(DrawerContext);

  const currentUser = props.currentUser;
  const userType = currentUser?.type;
  const archivation = project?.archivationState;

  const isContractor = userType === 'CONTRACTOR';

  const stage = project?.advertiserStage;

  const acceptance = !!project?.offer?.acceptance?.id;

  const projectId = project?.id;
  const postingRequired = project?.campaign.publishingRequired;
  const contentReviewStatus = project?.contentReview?.status;
  const campaignType = project?.campaign.type;
  const postingType = project?.campaign.postingType;
  const isExperimentButtonsVisible =
    campaignType === CONTENT_CREATION || postingType === ORGANIC_POSTING;

  const [markContractorAsUnresponsiveQuery, reportLoading] =
    useMarkContractorAsUnresponsiveQueryMutation();

  const isUnresponsiveReportAvailable = useMemo(() => {
    const is3daysStale =
      Number(project?.businessDaysSinceLatestCreatorEvent) >= STALE_DAYS_FOR_REPORT;

    return (
      project?.launch &&
      !project.completion &&
      project.creator?.ownership?.owner.unresponsive === false &&
      is3daysStale
    );
  }, [
    project?.businessDaysSinceLatestCreatorEvent,
    project?.launch,
    project?.completion,
    project?.creator?.ownership?.owner.unresponsive,
    campaignType,
  ]);

  if (!project) return null;

  if (!acceptance || archivation) {
    return null;
  }

  const isNeedableReviewStep =
    contentReviewStatus === 'PENDING' ||
    ((stage === 'PUBLISHING_STARTED' || stage === 'COMPLETED') &&
      contentReviewStatus === 'SUCCESS') ||
    (contentReviewStatus === 'SUCCESS' && stage !== 'COMPLETED' && !postingRequired) ||
    campaignType === PRODUCT_SEEDING;

  const isNeedablePostingStep =
    stage === 'PUBLISHING_STARTED' || (stage === 'COMPLETED' && postingRequired);

  const steps = compact([
    'ACCEPTED',
    'LAUNCHED',
    isNeedableReviewStep ? 'CONTENT_SUBMITTED' : false,
    isNeedablePostingStep ? 'PUBLISHING_STARTED' : false,
  ]);

  const getContent = (step: string, isActive: boolean, pastStep: boolean) => {
    switch (step) {
      case 'ACCEPTED':
        return (
          <OnboardingStep
            isActive={isActive}
            isPastStep={pastStep}
            userType={userType}
            project={project}
            currentUser={currentUser}
          />
        );

      case 'LAUNCHED':
        return (
          <ContentCreationStep
            isActive={isActive}
            userType={userType}
            isPastStep={pastStep}
            project={project}
          />
        );

      case 'CONTENT_SUBMITTED':
        return (
          <ContentReviewStep
            isActive={isActive}
            project={project}
            isPastStep={pastStep}
            isContractor={isContractor}
            postingRequired={postingRequired}
          />
        );

      case 'PUBLISHING_STARTED':
        return (
          <PostingStep
            isActive={isActive}
            isPastStep={pastStep}
            isContractor={isContractor}
            project={project}
          />
        );

      default:
        return null;
    }
  };

  const currentStepIndex = () => {
    const stepIndex = findIndex(steps, (item) => item === stage);
    return stepIndex == -1 ? steps.length : stepIndex;
  };

  const handleAddPostingButtonClick = () => {
    openDrawer('add-posting-to-this-collaboration');
    amplitude.sendEvent({
      id: '304',
      category: 'chat',
      name: 'posting_request',
      param: { campaign_type: postingType || campaignType },
    });
  };

  const handleAddCreatorAdsButtonClick = () => {
    openDrawer('connect-to-ads-manager-for-this-collaboration');
    amplitude.sendEvent({
      id: '306',
      category: 'chat',
      name: 'creator_ads_request',
      param: { campaign_type: postingType || campaignType },
    });
  };

  const handleAddPostingToThisCollaborationDrawerButtonClick = () => {
    amplitude.sendEvent({
      id: '305',
      category: 'chat',
      name: 'posting_request_new_campaign',
      param: { campaign_type: postingType || campaignType },
    });
  };

  const handleConnectToAdsManagerForThisCollaborationButtonClick = () => {
    amplitude.sendEvent({
      id: '307',
      category: 'chat',
      name: 'creator_ads_request_new_campaign',
      param: { campaign_type: postingType || campaignType },
    });
  };

  const handleReportUnreponsiceClick = () => {
    amplitude.sendEvent<361>({
      id: '361',
      category: 'project',
      name: 'report_unresponsive_creator',
      param: { date: new Date().toString(), creator_id: project.creator?.id || '' },
    });
    markContractorAsUnresponsiveQuery({
      variables: {
        input: {
          projectId,
        },
      },
    });
  };

  return (
    <div className={className}>
      {steps.map((item, index) => {
        const pastStep = index < currentStepIndex();
        const isActive = stage === item;
        const isDisabled = currentStepIndex() < index;

        return (
          <div
            key={item}
            className={classnames(styles.item, {
              [styles.isActive]: isActive,
              [styles.isDisabled]: isDisabled,
            })}
          >
            <div className={styles.countWrap}>
              {pastStep && <Icon name="addedColored" />}
              {!pastStep && (
                <div className={styles.count}>
                  <Text color="white" weight="700" text={index + 1} />
                </div>
              )}
            </div>
            <div className={styles.content}>{getContent(item, isActive, pastStep)}</div>
          </div>
        );
      })}

      {isExperimentButtonsVisible && (
        <div className={styles.experimentButtonsGroup}>
          {campaignType === CONTENT_CREATION && !postingType && (
            <TextButton
              className={styles.textButton}
              classes={{ text: styles.text }}
              msg="project.stepper.button.add_posting"
              onClick={handleAddPostingButtonClick}
            />
          )}
          <TextButton
            className={styles.textButton}
            classes={{ text: styles.text }}
            msg="project.stepper.button.add_creator_ads"
            onClick={handleAddCreatorAdsButtonClick}
          />
        </div>
      )}

      {isUnresponsiveReportAvailable && (
        <AlterButton
          type="grey"
          icon="Warning-error"
          loading={reportLoading}
          msg="project.stepper.button.report_creator_unresponsive"
          onClick={handleReportUnreponsiceClick}
          className={styles.reportBtn}
        />
      )}

      <AddPostingToThisCollaborationDrawer
        onCreateNewCampaignButtonClick={handleAddPostingToThisCollaborationDrawerButtonClick}
      />
      <ConnectToAdsManagerForThisCollaboration
        onCreateNewCampaignButtonClick={handleConnectToAdsManagerForThisCollaborationButtonClick}
      />
    </div>
  );
};

export default Stepper;

/**
 * @generated SignedSource<<5c1df5264dc480287bd0b5b11baa88e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CreatorPastProjectsListPaginationQuery$variables = {
  count: number;
  creatorId: string;
  cursor?: string | null | undefined;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
};
export type CreatorPastProjectsListPaginationQuery$data = {
  readonly creator: {
    readonly " $fragmentSpreads": FragmentRefs<"CreatorPastProjectsList_projects">;
  } | null | undefined;
};
export type CreatorPastProjectsListPaginationQuery = {
  response: CreatorPastProjectsListPaginationQuery$data;
  variables: CreatorPastProjectsListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creatorId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "creatorId"
  }
],
v5 = {
  "kind": "Variable",
  "name": "stages",
  "variableName": "stages"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorPastProjectsListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "CreatorPastProjectsList_projects"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatorPastProjectsListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isCreator"
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "advertiserStage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "side",
                            "value": "ADVERTISER"
                          }
                        ],
                        "concreteType": "ProjectArchivation",
                        "kind": "LinkedField",
                        "name": "archivation",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": "archivation(side:\"ADVERTISER\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectArchivationRequest",
                        "kind": "LinkedField",
                        "name": "archivationRequest",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reason",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Campaign",
                        "kind": "LinkedField",
                        "name": "campaign",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Brand",
                            "kind": "LinkedField",
                            "name": "brand",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "creator",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "stage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "brief",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "productShipmentRequired",
                                "storageKey": null
                              }
                            ],
                            "type": "V2Brief",
                            "abstractKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductShipmentCompletion",
                        "kind": "LinkedField",
                        "name": "productShipmentCompletion",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "offer",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OfferAcceptance",
                            "kind": "LinkedField",
                            "name": "acceptance",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OfferRejection",
                            "kind": "LinkedField",
                            "name": "rejection",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "comment",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OfferReadMark",
                            "kind": "LinkedField",
                            "name": "readMark",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectLaunch",
                        "kind": "LinkedField",
                        "name": "launch",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectCompletion",
                        "kind": "LinkedField",
                        "name": "completion",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "CreatorPast_projects",
            "kind": "LinkedHandle",
            "name": "projects"
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c8e4a9af2413efdee70eef38b408f0a",
    "id": null,
    "metadata": {},
    "name": "CreatorPastProjectsListPaginationQuery",
    "operationKind": "query",
    "text": "query CreatorPastProjectsListPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $creatorId: ID!\n  $stages: [AdvertiserProjectStage!]\n) {\n  creator(id: $creatorId) {\n    __typename\n    ...CreatorPastProjectsList_projects_40JRYL\n    id\n  }\n}\n\nfragment CreatorPastProjectsList_projects_40JRYL on Creator {\n  __isCreator: __typename\n  projects(first: $count, after: $cursor, stages: $stages) {\n    edges {\n      node {\n        id\n        advertiserStage\n        archivation(side: ADVERTISER) {\n          id\n        }\n        archivationRequest {\n          reason\n          id\n        }\n        campaign {\n          id\n          name\n          brand {\n            name\n            id\n          }\n          organization {\n            id\n            name\n          }\n          creator {\n            email\n            id\n          }\n          stage\n        }\n        price\n        currency\n        brief {\n          __typename\n          ... on V2Brief {\n            productShipmentRequired\n          }\n          id\n        }\n        productShipmentCompletion {\n          id\n        }\n        offer {\n          createdAt\n          acceptance {\n            id\n          }\n          rejection {\n            id\n            comment\n          }\n          readMark {\n            id\n          }\n          id\n        }\n        launch {\n          id\n        }\n        completion {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "45e936eaff6eeaaf799db16e1d079d60";

export default node;

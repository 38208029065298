import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './ContentCreationContractorSide.pcss';

import { createDate } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import { ContentCreationContractorSide_project$key } from 'GraphTypes/ContentCreationContractorSide_project.graphql';

interface Props {
  project: ContentCreationContractorSide_project$key;
  hasShipment: boolean;
}

const ContentCreationContractorSide: React.FC<Props> = (props) => {
  const { project, hasShipment } = props;

  const data = useFragment(
    graphql`
      fragment ContentCreationContractorSide_project on Project {
        id
        deadline {
          draftDate
          publicationDate
        }
      }
    `,
    project
  );

  const publicationDate = data.deadline?.publicationDate;
  const draftDate = data.deadline?.draftDate;

  const shipmentPrefix = hasShipment ? '_with_shipment' : '';
  const descr = `project.stepper.content_creation${shipmentPrefix}.contractor.descr`;

  const createDateItem = (dateMsg: string, value: string | null) => {
    return (
      <div className={styles.date}>
        <Text size="sm" color="grayDog" msg={dateMsg} />
        <Text size="sm" text={value} />
      </div>
    );
  };

  return (
    <div>
      <Text size="sm" msg={descr} />
      <div className={styles.dates}>
        {draftDate && createDateItem('confirm_dates_modal.draft_date', createDate(draftDate))}
        {publicationDate &&
          createDateItem('confirm_dates_modal.publication_date', createDate(publicationDate))}
      </div>
    </div>
  );
};

export default ContentCreationContractorSide;

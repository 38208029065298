import { graphql, useMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { DeleteBriefScreeningQuestionMutation } from 'GraphTypes/DeleteBriefScreeningQuestionMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const deleteBriefScreeningQuestionMutation = graphql`
  mutation DeleteBriefScreeningQuestionMutation($input: DeleteBriefScreeningQuestionInput!) {
    deleteBriefScreeningQuestion(input: $input) {
      ... on DeleteBriefScreeningQuestionPayload {
        brief {
          id
        }
      }
    }
  }
`;

export const useDeleteBriefScreeningQuestionMutation: BaseMutation<
  DeleteBriefScreeningQuestionMutation
> = (config) => {
  const [deleteBriefScreeningQuestion, loading] = useMutation<DeleteBriefScreeningQuestionMutation>(
    deleteBriefScreeningQuestionMutation
  );

  const updateBriefScreeningQuestionCaller: BaseMutationExecFn<
    DeleteBriefScreeningQuestionMutation
  > = (execConfig) => {
    deleteBriefScreeningQuestion({
      ...config,
      updater: (store, data) => {
        const briefId = data?.deleteBriefScreeningQuestion?.brief.id;
        const id = config?.variables.input.screeningQuestionId;
        if (!briefId || !id) return;
        const brief = store.get(briefId);
        if (!brief) return;
        const questions = ConnectionHandler.getConnection(
          brief,
          'ScreeningQuestions_screeningQuestions'
        );
        if (!questions) return;
        ConnectionHandler.deleteNode(questions, id);
      },
      ...execConfig,
    });
  };
  return [updateBriefScreeningQuestionCaller, loading];
};

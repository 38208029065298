/**
 * @generated SignedSource<<109f07eca9c88e1d20c2b8699ef30e62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageFile_attachment$data = {
  readonly createdAt: any;
  readonly file: {
    readonly bytes: number | null | undefined;
    readonly filename: string | null | undefined;
    readonly originalFilename: string | null | undefined;
    readonly secureUrl: string;
  };
  readonly " $fragmentType": "MessageFile_attachment";
};
export type MessageFile_attachment$key = {
  readonly " $data"?: MessageFile_attachment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageFile_attachment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageFile_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secureUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalFilename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bytes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageAttachment",
  "abstractKey": null
};

(node as any).hash = "349ed5b7ca5d25585bc9053c3317b239";

export default node;

import React, { Suspense, useContext, useEffect, useState } from 'react';

import styles from './Outreach.pcss';
import InviteOutreachCreators from './InviteOutreachCreators/InviteOutreachCreators';
import { graphql, useLazyLoadQuery, useQueryLoader } from 'react-relay';

import OutreachBatchesControls from './OutreachBatchesControls/OutreachBatchesControls';
import OutreachTable from './OutreachTable/OutreachTable';

import { amplitude } from 'Helpers/amplitude';
import { CampaignPlatform } from 'GraphTypes/CampaignQuery.graphql';
import InviteOutreachCreatorsDrawer from 'Modal/admin/InviteOutreachCreators/InviteOutreachCreators';
import CreatorsStub from 'AdvertiserPage/Campaign/Creators/CreatorsList/CreatorsStub/CreatorsStub';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { OutreachBatchesQuery } from 'GraphTypes/OutreachBatchesQuery.graphql';
import { OutreachPreviewQuery } from 'GraphTypes/OutreachPreviewQuery.graphql';
import { InviteOutreachCreatorsProvider } from 'Modal/admin/InviteOutreachCreators/InviteOutreachCreatorsProvider';

interface Props {
  campaignId: string;
  platform?: CampaignPlatform | null;
}

const outreachBatchQuery = graphql`
  query OutreachBatchesQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      outreachBatches {
        edges {
          node {
            id
            state
            creatorImport {
              completed
              csvFilename
              createdAt
              importedCreators
              totalCreators
              rows {
                edges {
                  node {
                    id
                    email
                    username
                  }
                }
              }
            }
            template {
              name
            }
            ...OutreachBatch_outreachBatch
          }
        }
        totalCount
      }
    }
    outreachCounters {
      totalCount
      enqueuedCount
      todayCount
      todayLimit
    }
  }
`;

const outreachPreviewQuery = graphql`
  query OutreachPreviewQuery($id: ID!) {
    campaign(id: $id) {
      outreachBatches {
        totalCount
      }
    }
    outreachCounters {
      totalLimit
      todayCount
      todayLimit
    }
  }
`;

const Outreach: React.FC<Props> = (props) => {
  const { campaignId, platform } = props;

  const [showPercentages, setShowPercentages] = useState(false);
  const handleShowPercentagesClick = () => {
    amplitude.sendEvent<414>({
      id: '414',
      category: 'campaign',
      name: 'outreach_percentage_click',
      param: undefined,
    });
    setShowPercentages((prev) => !prev);
  };

  const outreachPreviewData = useLazyLoadQuery<OutreachPreviewQuery>(outreachPreviewQuery, {
    id: campaignId,
  });

  const [queryReference, loadQuery] = useQueryLoader<OutreachBatchesQuery>(outreachBatchQuery);

  useEffect(() => {
    if (!queryReference) {
      loadQuery({ campaignId });
    }
  }, []);

  const handleUploadCsv = () => loadQuery({ campaignId }, { fetchPolicy: 'network-only' });

  const hasInvitedCreators = Number(outreachPreviewData?.campaign?.outreachBatches.totalCount) > 0;

  const { openDrawer } = useContext(DrawerContext);
  const handleInviteClick = () => {
    amplitude.sendEvent<409>({
      id: '409',
      category: 'campaign',
      name: 'outreach_button_click',
      param: undefined,
    });
    openDrawer('invite-outreach-creators');
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {hasInvitedCreators ? (
          <div className={styles.outreach}>
            <OutreachBatchesControls
              handleInvite={handleInviteClick}
              totalLimit={outreachPreviewData?.outreachCounters?.todayLimit || 1}
              todayLimit={outreachPreviewData?.outreachCounters?.todayCount || 0}
              onShowPercentagesClick={handleShowPercentagesClick}
              showPercentages={showPercentages}
            />
            {queryReference ? (
              <Suspense fallback={<CreatorsStub />}>
                <OutreachTable
                  campaignId={campaignId}
                  showPercentages={showPercentages}
                  queryReference={queryReference}
                  query={outreachBatchQuery}
                />
              </Suspense>
            ) : (
              <CreatorsStub />
            )}
          </div>
        ) : (
          <InviteOutreachCreators className={styles.invite} />
        )}
      </div>
      <InviteOutreachCreatorsProvider handleUploadCsv={handleUploadCsv}>
        <InviteOutreachCreatorsDrawer campaignId={campaignId} platform={platform} />
      </InviteOutreachCreatorsProvider>
    </div>
  );
};

export default Outreach;

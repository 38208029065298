/**
 * @generated SignedSource<<c6e7b113f396279cc6c716db8c90c484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileBcaTaggingStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MessageMedia_file$data = {
  readonly bcaTaggedUrl: string | null | undefined;
  readonly bcaTaggingStatus: FileBcaTaggingStatus | null | undefined;
  readonly bytes: number | null | undefined;
  readonly filename: string | null | undefined;
  readonly height: number | null | undefined;
  readonly id: string;
  readonly originalFilename: string | null | undefined;
  readonly processingStatus?: FileProcessingStatus | null | undefined;
  readonly secureUrl: string;
  readonly thumbnailUrl: string;
  readonly transformations?: {
    readonly autoUrl: string;
    readonly postThumbnailUrl: string;
  } | null | undefined;
  readonly type: FileType;
  readonly width: number | null | undefined;
  readonly " $fragmentType": "MessageMedia_file";
};
export type MessageMedia_file$key = {
  readonly " $data"?: MessageMedia_file$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageMedia_file">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postThumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageMedia_file",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bytes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bcaTaggedUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bcaTaggingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secureUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalFilename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "processingStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoTransformation",
          "kind": "LinkedField",
          "name": "transformations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "autoUrl",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "Video",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImageTransformation",
          "kind": "LinkedField",
          "name": "transformations",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "Image",
      "abstractKey": null
    }
  ],
  "type": "File",
  "abstractKey": "__isFile"
};
})();

(node as any).hash = "46303900c7b9fbc41a852757e2b4a132";

export default node;

import React from 'react';

import styles from './Param.css';

import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Icon from 'Atoms/Icon/Icon';
import { RANGE } from 'Constants/general';

const SearchParam = (props) => {
  const { className, group, text, primaryMsg, msg, msgValues, icon, removeDisabled } = props;

  const handleRequestDelete = () => {
    const { id, param, paramFrom, paramTo, type, onDelete } = props;

    if (type === RANGE) {
      onDelete(paramFrom, paramTo);
    } else {
      onDelete(param, id);
    }
  };

  const finalMsg = primaryMsg || msg;

  return (
    <div className={`${className} ${styles.root}`}>
      {group && group !== 'creator' && <Icon name={group} size="sm" color="white" />}
      {icon}
      <Text
        size="sm"
        text={text}
        transform="capitalizeFirstLetter"
        color="white"
        msg={finalMsg}
        values={msgValues}
      />
      {!removeDisabled && (
        <TextButton
          color="normal"
          icon={<Icon size="sm" name="close" color="white" className={styles.cross} />}
          onClick={handleRequestDelete}
          className={styles.delete}
        />
      )}
    </div>
  );
};

export default SearchParam;

import React, { createContext, useMemo, useReducer, PropsWithChildren } from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import { PlanId, CampaignType } from 'GraphTypes/BriefQuery.graphql';
import { CURRENT_PLANS, PRODUCT_SEEDING } from 'Constants/general';
import { CampaignUseCase } from 'Types/common';

interface BriefAction {
  key: keyof BriefValue;
  value: BriefValue[keyof BriefValue];
}

const initialContext: BriefValue = {
  campaignInfoFilled: false,
  productInfoFilled: false,
  creatorsInfoFilled: false,
  creativesInfoFilled: false,
  optionalInfoFilled: true,
  briefIsSaving: false,
  planId: null,
  isOutdatedPlan: null,
  useCase: null,
  subscription: {
    maxLaunchedCampaigns: null,
    maxActiveBrands: null,
  },
  availableCampaignTypes: [],
  availableCampaignUseCases: [],
  counters: {
    launchedCampaigns: null,
    activeBrands: null,
  },
  creativeAssets: {},
  isStepAvailable: {
    campaignInfo: false,
    productInfo: false,
    creatorsInfo: false,
    creativesInfo: false,
    optionalInfo: false,
  },
  campaignType: null,
  screeningQuestions: {},
  showErrors: false,
};

const getComputedValues = (state: BriefValue): BriefValue => {
  const { maxLaunchedCampaigns, maxActiveBrands } = state.subscription;
  const { launchedCampaigns, activeBrands } = state.counters;
  const { campaignType } = state;

  return {
    ...state,
    campaignType,
    campaignInfoStatus: !state.campaignInfoFilled ? 'active' : 'filled',
    productInfoStatus: state.campaignInfoFilled
      ? state.productInfoFilled
        ? 'filled'
        : 'active'
      : 'block',
    creatorsInfoStatus:
      state.campaignInfoFilled && state.productInfoFilled
        ? state.creatorsInfoFilled
          ? 'filled'
          : 'active'
        : 'block',
    creativesInfoStatus:
      state.campaignInfoFilled && state.productInfoFilled && state.creatorsInfoFilled
        ? state.creativesInfoFilled
          ? 'filled'
          : 'active'
        : 'block',
    optionalInfoStatus: (() => {
      return state.campaignInfoFilled &&
        state.productInfoFilled &&
        state.creatorsInfoFilled &&
        state.creativesInfoFilled
        ? 'filled'
        : 'block';
    })(),
    isMaxLaunchedCampaignsExceeded:
      typeof maxLaunchedCampaigns === 'number' && typeof launchedCampaigns === 'number'
        ? launchedCampaigns >= maxLaunchedCampaigns
        : false,
    isMaxActiveBrandsExceeded:
      typeof maxActiveBrands === 'number' && typeof activeBrands === 'number'
        ? activeBrands >= maxActiveBrands
        : false,
    isStepAvailable: {
      campaignInfo: true,
      productInfo: true,
      creatorsInfo: true,
      creativesInfo: true,
      optionalInfo: true,
    },
  };
};

type blockStatus = 'filled' | 'active' | 'block';

export type BriefValue = {
  campaignInfoFilled: boolean;
  campaignInfoStatus?: blockStatus;
  productInfoFilled: boolean;
  productInfoStatus?: blockStatus;
  creatorsInfoFilled: boolean;
  creatorsInfoStatus?: blockStatus;
  creativesInfoFilled: boolean;
  creativesInfoStatus?: blockStatus;
  optionalInfoFilled: boolean;
  optionalInfoStatus?: blockStatus;
  isMaxLaunchedCampaignsExceeded?: boolean;
  isMaxActiveBrandsExceeded?: boolean;
  briefIsSaving: boolean | null;
  planId: PlanId | null;
  isOutdatedPlan: boolean | null;
  useCase: CampaignUseCase | null;
  subscription: {
    maxLaunchedCampaigns: number | null;
    maxActiveBrands: number | null;
  };
  availableCampaignTypes: CampaignType[];
  availableCampaignUseCases: CampaignUseCase[];
  counters: {
    launchedCampaigns: number | null;
    activeBrands: number | null;
  };
  creativeAssets: {
    [key: string]: boolean;
  };
  isStepAvailable: {
    campaignInfo: boolean;
    productInfo: boolean;
    creatorsInfo: boolean;
    creativesInfo: boolean;
    optionalInfo: boolean;
  };
  campaignType: CampaignType | null;
  screeningQuestions: { [key: string]: boolean };
  showErrors: boolean;
};

const reducer = (state: BriefValue, action: BriefAction): BriefValue => {
  if (isEqual(action.value, state[action.key])) {
    return state;
  }

  if (action.key === 'creativeAssets') {
    return { ...state, creativeAssets: { ...state.creativeAssets, ...action.value } };
  }

  if (action.key === 'screeningQuestions') {
    return { ...state, screeningQuestions: { ...state.screeningQuestions, ...action.value } };
  }

  return getComputedValues({ ...state, [action.key]: action.value });
};

export const BriefContext = createContext<[BriefValue, React.Dispatch<BriefAction>]>([
  getComputedValues(initialContext),
  noop,
]);

type BriefProviderComponent = React.FC<
  PropsWithChildren<{
    useCase?: CampaignUseCase;
    planId: PlanId | null;
    subscription: {
      maxLaunchedCampaigns?: number | null;
      maxActiveBrands?: number | null;
    };
    availableCampaignTypes: CampaignType[];
    availableCampaignUseCases: CampaignUseCase[];
    counters: {
      launchedCampaigns?: number | null;
      activeBrands?: number | null;
    };
    campaignType: CampaignType | null;
  }>
>;

export const BriefProvider: BriefProviderComponent = (props) => {
  const {
    children,
    subscription,
    counters,
    planId,
    campaignType,
    availableCampaignTypes,
    useCase,
    availableCampaignUseCases,
  } = props;

  const initialValue: BriefValue = {
    ...initialContext,
    planId,
    campaignType,
    useCase: useCase || null,
    isOutdatedPlan: !CURRENT_PLANS.includes(planId || ''),
    counters: {
      ...initialContext.counters,
      ...counters,
    },
    availableCampaignTypes,
    availableCampaignUseCases,
    subscription: {
      ...initialContext.subscription,
      ...subscription,
    },
    isStepAvailable: {
      campaignInfo: true,
      productInfo: true,
      creatorsInfo: true,
      creativesInfo: true,
      optionalInfo: true,
    },
  };

  const [state, dispatch] = useReducer(reducer, {
    ...getComputedValues(initialValue),
  });

  const canSendToReview =
    (!state.isStepAvailable.campaignInfo || state.campaignInfoFilled) &&
    (!state.isStepAvailable.productInfo || state.productInfoFilled) &&
    (!state.isStepAvailable.creativesInfo || state.creatorsInfoFilled) &&
    (!state.isStepAvailable.creativesInfo || state.creativesInfoFilled) &&
    (!state.isStepAvailable.optionalInfo || state.optionalInfoFilled);

  const values = useMemo(() => {
    return [state, dispatch];
  }, [state, dispatch]);

  return <BriefContext.Provider value={values}>{children}</BriefContext.Provider>;
};

export default BriefProvider;

import { encodeQueryData } from 'Util/encodeQueryData';
import { OAUTH_PROXY_URL, FB_OAUTH_API } from 'Constants/general';

const path = process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL : location.origin;

export const fbAuth = ({ successCallback, failCallback, additionalPermissions }) => {
  const additionalPermissionsValue = additionalPermissions ? `,${additionalPermissions.join(',')}` : '';

  const params = {
    redirect_uri: `${OAUTH_PROXY_URL}`,
    client_id: process.env.FB_APP_ID,
    response_type: 'token',
    scope: `email,pages_read_engagement,pages_show_list,business_management,ads_management,instagram_basic,instagram_manage_insights,pages_manage_metadata${additionalPermissionsValue}`,
    state: `origin=${location.origin}&route=/auth`,
  };
  const url = `${FB_OAUTH_API}${encodeQueryData(params)}`;
  window.callSuccess = (socialIdentityId) => {
    if (successCallback) {
      successCallback(socialIdentityId);
    }
  };
  window.callFail = (error) => {
    if (failCallback) {
      failCallback(error);
    }
  };
  window.open(url, 'auth', 'width=500,height=500');
};
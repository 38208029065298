/**
 * @generated SignedSource<<2248edd954eda320f5475ca550bc47e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ShopifyOrderState = "ATTEMPTED_DELIVERY" | "CANCELLED" | "CONFIRMED" | "DELIVERED" | "ERROR" | "FAILURE" | "IN_TRANSIT" | "LABEL_PRINTED" | "LABEL_PURCHASED" | "OPEN" | "OUT_FOR_DELIVERY" | "PENDING" | "READY_FOR_PICKUP" | "SUCCESS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Shipment_project$data = {
  readonly creator: {
    readonly id: string;
    readonly ownership: {
      readonly owner: {
        readonly email: string;
      };
    } | null | undefined;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreatorDetails_creator">;
  } | null | undefined;
  readonly id: string;
  readonly shippingAddress: {
    readonly address1: string;
    readonly address2: string | null | undefined;
    readonly city: string;
    readonly country: {
      readonly name: string;
    } | null | undefined;
    readonly firstName: string;
    readonly lastName: string;
    readonly phone: string;
    readonly province: {
      readonly name: string;
    } | null | undefined;
    readonly zip: string;
  } | null | undefined;
  readonly shopifyOrder: {
    readonly id: string;
    readonly state: ShopifyOrderState;
  } | null | undefined;
  readonly " $fragmentType": "Shipment_project";
};
export type Shipment_project$key = {
  readonly " $data"?: Shipment_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"Shipment_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Shipment_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopifyOrder",
      "kind": "LinkedField",
      "name": "shopifyOrder",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShippingAddress",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Province",
          "kind": "LinkedField",
          "name": "province",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorOwnership",
          "kind": "LinkedField",
          "name": "ownership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreatorDetails_creator"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "2a9d3e7f7ae0bfb2959a0f671dcc0c32";

export default node;

/**
 * @generated SignedSource<<373d507bf2471485da68402aa390825e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkListingAsReadInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  listingId: string;
};
export type MarkListingAsReadMutation$variables = {
  input: MarkListingAsReadInput;
};
export type MarkListingAsReadMutation$data = {
  readonly markListingAsRead: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type MarkListingAsReadMutation = {
  response: MarkListingAsReadMutation$data;
  variables: MarkListingAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkListingAsReadPayload",
    "kind": "LinkedField",
    "name": "markListingAsRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkListingAsReadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkListingAsReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "68b146e1fc9799d195d86e633123d75e",
    "id": null,
    "metadata": {},
    "name": "MarkListingAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation MarkListingAsReadMutation(\n  $input: MarkListingAsReadInput!\n) {\n  markListingAsRead(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c4d49734646cb6596d3b1f4d8444cbc";

export default node;

import React from 'react';

import styles from './Performance.pcss';

import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import NewCampaignButton from 'CommonPage/Dashboard/AdvertiserDashboard/NewCampaignButton/NewCampaignButton';
import graph1Img from 'Images/performance/graph1.png';
import graph1_2xImg from 'Images/performance/graph1_2x.png';
import graph2Img from 'Images/performance/graph2.png';
import graph2_2xImg from 'Images/performance/graph2_2x.png';
import graph3Img from 'Images/performance/graph3.png';
import graph3_2xImg from 'Images/performance/graph3_2x.png';
import graph4Img from 'Images/performance/graph4.png';
import graph4_2xImg from 'Images/performance/graph4_2x.png';
import graph5Img from 'Images/performance/graph5.png';
import graph5_2xImg from 'Images/performance/graph5_2x.png';

interface Props {
  filters: JSX.Element | null;
}

const PerformanceStub: React.FC<Props> = (props) => {
  const { filters } = props;

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text type="d2" msg="performance.dashboard.title.stub" className={styles.titleStub} />
        <Text type="md" msg="performance.dashboard.descr.stub" className={styles.descr} />
        <div className={styles.newCampaign}>
          <NewCampaignButton />
        </div>
        <div className={styles.filters}>
          {filters}
          <div className={styles.filtersStub} />
        </div>
        <div className={styles.chartsContainer}>
          <div>
            <div className={styles.chartStub}>
              <Text
                type="md"
                msg="performance.dashboard.chart.collaborations.title"
                className={styles.chartTitleStub}
              />
              <div className={styles.values}>
                <Text
                  type="label"
                  msg="performance.dashboard.chart.collaborations.completedProjectsCount.title"
                />
                <Text
                  type="label"
                  msg="performance.dashboard.chart.collaborations.creatorsCount.title"
                />
                <Text
                  type="label"
                  msg="performance.dashboard.chart.collaborations.creativeAssetsCount.title"
                />
                <Text
                  type="label"
                  msg="performance.dashboard.chart.collaborations.publicationsCount.title"
                />
              </div>
              <img srcSet={`${graph1Img} 1x, ${graph1_2xImg} 2x`} />
            </div>
          </div>
          <div>
            <div className={styles.chartStub}>
              <Text
                type="md"
                msg="performance.dashboard.chart.view_counters.title"
                className={styles.chartTitleStub}
              />
              <div className={styles.values}>
                <Text
                  type="label"
                  msg="performance.dashboard.chart.view_counters.views_count.title"
                />
              </div>
              <img srcSet={`${graph2Img} 1x, ${graph2_2xImg} 2x`} />
            </div>
          </div>
          <div>
            <div className={styles.chartStub}>
              <Text
                type="md"
                msg="performance.dashboard.chart.engagement_counters.title"
                className={styles.chartTitleStub}
              />
              <div className={styles.values}>
                <Text
                  type="label"
                  msg="performance.dashboard.chart.engagement_counters.engagement_count.title"
                />
                <Text
                  type="label"
                  msg="performance.dashboard.chart.engagement_counters.likes_count.title"
                />
                <Text
                  type="label"
                  msg="performance.dashboard.chart.engagement_counters.comments_count.title"
                />
                <Text
                  type="label"
                  msg="performance.dashboard.chart.engagement_counters.shared_count.title"
                />
                <Text
                  type="label"
                  msg="performance.dashboard.chart.engagement_counters.saved_count.title"
                />
              </div>
              <img srcSet={`${graph3Img} 1x, ${graph3_2xImg} 2x`} />
            </div>
          </div>
          <div>
            <div className={styles.chartStub}>
              <Text
                type="md"
                msg="performance.dashboard.chart.cpm_cpe_counters.title"
                className={styles.chartTitleStub}
              />
              <div className={styles.values}>
                <Text type="label" msg="performance.dashboard.chart.cpm_cpe_counters.cpm.title" />
                <Text type="label" msg="performance.dashboard.chart.cpm_cpe_counters.cpe.title" />
              </div>
              <img srcSet={`${graph4Img} 1x, ${graph4_2xImg} 2x`} />
            </div>
          </div>
          <div>
            <div className={styles.chartStub}>
              <Text
                type="md"
                msg="performance.dashboard.chart.transactions.title"
                className={styles.chartTitleStub}
              />
              <div className={styles.values}>
                <Text type="label" msg="performance.dashboard.chart.tooltip.totalExpenses" />
                <Text type="label" msg="performance.dashboard.chart.tooltip.totalPayouts" />
                <Text type="label" msg="performance.dashboard.chart.tooltip.totalReimbursements" />
                <Text type="label" msg="performance.dashboard.chart.tooltip.totalExtraPayouts" />
                <Text type="label" msg="performance.dashboard.chart.tooltip.totalExpencesFee" />
                <Text type="label" msg="performance.dashboard.chart.tooltip.averagePrice" />
              </div>
              <img srcSet={`${graph5Img} 1x, ${graph5_2xImg} 2x`} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PerformanceStub;

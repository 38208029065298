import {
  TEXT,
  TWO_ROW_TEXT,
  CUSTOM_ELEMENT,
  TWO_ROW_TEXT_WITH_LINK,
} from 'Constants/general';

export const items = [
  {
    id: 'campaign',
    title: 'campaign',
    values: {
      element: {
        value: 'campaign',
      },
    },
    width: '140px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'advertiser',
    title: 'advertiser',
    values: {
      text: {
        value: 'organizationName',
      },
      link: {
        value: 'organizationLink'
      },
      additionalText: {
        value: 'email',
      },
    },
    width: '120px',
    element: TWO_ROW_TEXT,
  },
  {
    id: 'status',
    title: 'status',
    values: {
      element: {
        value: 'status',
      },
    },
    width: '136px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'price',
    title: 'price',
    values: {
      text: {
        value: 'price',
      },
    },
    width: '80px',
    element: TEXT,
  },
  {
    id: 'archived',
    width: '24px',
    values: {
      element: {
        value: 'archived',
      },
    },
    element: CUSTOM_ELEMENT,
  },
];
import React from 'react';
import classnames from 'classnames';

import styles from './TooltipPreset.pcss';

import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';

interface Props {
  className?: string;
  imgSrc?: string;
  imgSrcSet?: string;
  link?: string;
  titleMsg?: string;
  descrMsg?: string;
  descrData?: TextProps;
  btnMsg?: string;
  onButtonClick?: () => void;
  onCloseClick?: () => void;
}

const TooltipPreset: React.FC<Props> = (props) => {
  const {
    className,
    imgSrc,
    imgSrcSet,
    link,
    titleMsg,
    descrMsg,
    btnMsg,
    descrData,
    onCloseClick,
    onButtonClick,
  } = props;
  return (
    <div className={classnames(styles.root, className)}>
      <Text type="h1" msg={titleMsg} className={styles.title} />
      <button type="button" className={styles.closeButton} onClick={onCloseClick}>
        <Icon name="Close-small" className={styles.closeButtonIcon} size={20} />
      </button>
      {(imgSrc || imgSrcSet) && <img src={imgSrc} srcSet={imgSrcSet} className={styles.image} />}
      <Text type="md" msg={descrMsg} {...descrData} className={styles.descr} />
      <SmartLink path={link} onClick={onButtonClick} className={styles.btn}>
        <AlterButton msg={btnMsg} className={styles.btn} />
      </SmartLink>
    </div>
  );
};

export default TooltipPreset;

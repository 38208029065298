import { isCypress } from 'Util/isCypress';

const identify = (userData: CustyfyIdentifyOptions) => {
  if (isCypress()) return;
  const identifyEvent = new Event('custifyUserIdentify');
  _ctrack.identify(userData);
  triggerPendingAction();
  window.dispatchEvent(identifyEvent);
};

const trackTime = (module: string) => {
  if (isCypress()) return;
  _ctrack.trackTime(true, { module });
  window.addEventListener(
    'custifyUserIdentify',
    () => {
      _ctrack.trackTime(true, { module });
    },
    { once: true }
  );
};

type TriggerPendingAction = () => void;
const triggerPendingAction: TriggerPendingAction = () => {
  _ctrack.triggerPendingAction();
};

type ClosePendingAction = () => void;
const closePendingAction: ClosePendingAction = () => {
  _ctrack.closePendingAction();
};

export { identify, trackTime, triggerPendingAction, closePendingAction };

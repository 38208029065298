/**
 * @generated SignedSource<<e5037d8a66bd74ee10ea7e78f48a16d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivatePaidSocialForInsenseInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
};
export type ActivatePaidSocialForInsenseMutation$variables = {
  input: ActivatePaidSocialForInsenseInput;
};
export type ActivatePaidSocialForInsenseMutation$data = {
  readonly activatePaidSocialForInsense: {
    readonly clientMutationId: string | null | undefined;
    readonly paidSocialActivationForInsense: {
      readonly createdAt: any;
      readonly expiresAt: any | null | undefined;
      readonly facebookBusinessId: string;
      readonly facebookBusinessName: string;
      readonly facebookPageId: string | null | undefined;
      readonly facebookPageName: string | null | undefined;
      readonly facebookPagePictureUrl: string | null | undefined;
    };
  } | null | undefined;
};
export type ActivatePaidSocialForInsenseMutation = {
  response: ActivatePaidSocialForInsenseMutation$data;
  variables: ActivatePaidSocialForInsenseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookPageId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookPageName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookPagePictureUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookBusinessId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookBusinessName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivatePaidSocialForInsenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivatePaidSocialForInsensePayload",
        "kind": "LinkedField",
        "name": "activatePaidSocialForInsense",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaidSocialActivationForInsense",
            "kind": "LinkedField",
            "name": "paidSocialActivationForInsense",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivatePaidSocialForInsenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivatePaidSocialForInsensePayload",
        "kind": "LinkedField",
        "name": "activatePaidSocialForInsense",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaidSocialActivationForInsense",
            "kind": "LinkedField",
            "name": "paidSocialActivationForInsense",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de7af754ff58792222c6a0fdf8aa1472",
    "id": null,
    "metadata": {},
    "name": "ActivatePaidSocialForInsenseMutation",
    "operationKind": "mutation",
    "text": "mutation ActivatePaidSocialForInsenseMutation(\n  $input: ActivatePaidSocialForInsenseInput!\n) {\n  activatePaidSocialForInsense(input: $input) {\n    clientMutationId\n    paidSocialActivationForInsense {\n      createdAt\n      expiresAt\n      facebookPageId\n      facebookPageName\n      facebookPagePictureUrl\n      facebookBusinessId\n      facebookBusinessName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cdc1005af685c610795dcf4a948cc2ca";

export default node;

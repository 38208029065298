import React, { useState } from 'react';

import styles from './Avatar.css';

import modsClasses from 'Util/modsClasses.js';
import { createGradientByName } from 'Util/colorizeValue';
import Text from 'Atoms/Text/Text';
import { AvatarType } from 'Types/common';
import stubImg from 'Images/general/ava_stub.svg';

const MODS = ['defaultColor'];

const Avatar: React.FC<AvatarType> = (props) => {
  const { size = '40', src, letters, icon, defaultStubImg, dataTest } = props;
  const classes = modsClasses(MODS, props, styles);

  const [showStub, setShowStub] = useState(!src && !letters && !icon);

  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  const handleImageErrorLoad = () => {
    setShowStub(true);
  };

  const createLetters = () => {
    if (!letters) return null;
    const initials = letters.slice(0, 2);

    return <Text weight="500" color="white" text={initials} transform="uppercase" />;
  };

  const background = letters ? { background: createGradientByName([letters]) } : null;

  return (
    <div
      className={`${classes} ${styles.root}`}
      style={{ ...sizeStyle, ...background }}
      data-test={dataTest}
    >
      {src && !showStub && (
        <img className={styles.image} src={src} onError={handleImageErrorLoad} />
      )}
      {showStub && !letters && <img src={defaultStubImg || stubImg} className={styles.stub} />}
      {icon && <div className={styles.icon}>{icon}</div>}
      {letters && !src && createLetters()}
    </div>
  );
};

export default Avatar;

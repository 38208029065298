/**
 * @generated SignedSource<<24349fed45423e1b375f6a72e534cf72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyPaidSocialRightsInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  success: boolean;
};
export type VerifyPaidSocialRightsMutation$variables = {
  input: VerifyPaidSocialRightsInput;
};
export type VerifyPaidSocialRightsMutation$data = {
  readonly verifyPaidSocialRights: {
    readonly paidSocialRightsVerification: {
      readonly id: string;
      readonly success: boolean;
      readonly verifiedAt: any;
    };
  } | null | undefined;
};
export type VerifyPaidSocialRightsMutation = {
  response: VerifyPaidSocialRightsMutation$data;
  variables: VerifyPaidSocialRightsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VerifyPaidSocialRightsPayload",
    "kind": "LinkedField",
    "name": "verifyPaidSocialRights",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaidSocialRightsVerification",
        "kind": "LinkedField",
        "name": "paidSocialRightsVerification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verifiedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPaidSocialRightsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyPaidSocialRightsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dee58bde2060fe82b6cd4e9fd042025b",
    "id": null,
    "metadata": {},
    "name": "VerifyPaidSocialRightsMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyPaidSocialRightsMutation(\n  $input: VerifyPaidSocialRightsInput!\n) {\n  verifyPaidSocialRights(input: $input) {\n    paidSocialRightsVerification {\n      id\n      verifiedAt\n      success\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d15a284240849a0859811310143521a1";

export default node;

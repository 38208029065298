/**
 * @generated SignedSource<<ef46816612ebf21c493264670a22ee26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PaidSocialLicensesFiltersQuery$variables = Record<PropertyKey, never>;
export type PaidSocialLicensesFiltersQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly counters: {
        readonly psLicensedCreators: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type PaidSocialLicensesFiltersQuery = {
  response: PaidSocialLicensesFiltersQuery$data;
  variables: PaidSocialLicensesFiltersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationCounters",
  "kind": "LinkedField",
  "name": "counters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "psLicensedCreators",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaidSocialLicensesFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PaidSocialLicensesFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3512c9abc5abb05c01ecb875442d3e33",
    "id": null,
    "metadata": {},
    "name": "PaidSocialLicensesFiltersQuery",
    "operationKind": "query",
    "text": "query PaidSocialLicensesFiltersQuery {\n  currentUser {\n    organization {\n      counters {\n        psLicensedCreators\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff9790282edfa3330aa232aa0c2f9cd4";

export default node;

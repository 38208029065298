import { useIntl } from 'react-intl';

import { SubscriptionStatusTypeEnum } from 'Types/enums';
import {
  PSP_PAYMENT_METHOD,
  PSP_INVOICE_PAYMENT_METHOD,
  MANUAL_PAYMENT_METHOD,
  DEPOSIT,
  SUBSCRIPTION,
  CUSTOM_SERVICE,
  SALES_PEAK_REWARD,
  REFERRAL_REWARD,
  REFUND,
  FULLY_MANAGED_ZEROING,
  CREATOR_EXPENSE,
  PAID,
  NOT_PAID,
  REFUNDED,
  ARRAY,
  RANGE,
  SINGLE_VALUE,
  DATE,
  RADIO,
  CHECKBOX,
  AUTOCOMPLETE,
} from 'Constants/general';

type Filters = {
  paymentMethodFilters: string[];
  type: string | null;
  subscriptionStatusType: string | null;
  paymentStatus?: string | null;
};

export const getFiltersData = (filters: Filters) => {
  const { paymentMethodFilters = [], type, paymentStatus, subscriptionStatusType } = filters;

  const intl = useIntl();

  return {
    paymentMethodFilters: [
      {
        id: PSP_INVOICE_PAYMENT_METHOD,
        isChecked: paymentMethodFilters.includes(PSP_INVOICE_PAYMENT_METHOD),
        value: intl.formatMessage({
          id: 'search_section.form.payment_method_psp_invoice',
        }),
        labelMsg: 'search_section.form.payment_method_psp_invoice',
      },
      {
        id: PSP_PAYMENT_METHOD,
        isChecked: paymentMethodFilters.includes(PSP_PAYMENT_METHOD),
        value: intl.formatMessage({
          id: 'search_section.form.payment_method_psp',
        }),
        labelMsg: 'search_section.form.payment_method_psp',
      },
      {
        id: MANUAL_PAYMENT_METHOD,
        isChecked: paymentMethodFilters.includes(MANUAL_PAYMENT_METHOD),
        value: intl.formatMessage({
          id: 'search_section.form.payment_method_manual',
        }),
        labelMsg: 'search_section.form.payment_method_manual',
      },
    ],
    type: [
      {
        id: DEPOSIT,
        isChecked: type === DEPOSIT,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type_deposit',
        }),
        labelMsg: 'search_section.form.payment_type_deposit',
      },
      {
        id: CUSTOM_SERVICE,
        isChecked: type === CUSTOM_SERVICE,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type_custom_services',
        }),
        labelMsg: 'search_section.form.payment_type_custom_services',
      },
      {
        id: SUBSCRIPTION,
        isChecked: type === SUBSCRIPTION,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type_subscription',
        }),
        labelMsg: 'search_section.form.payment_type_subscription',
      },
      {
        id: SALES_PEAK_REWARD,
        isChecked: type === SALES_PEAK_REWARD,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type.bonus',
        }),
        labelMsg: 'search_section.form.payment_type.bonus',
      },
      {
        id: REFERRAL_REWARD,
        isChecked: type === REFERRAL_REWARD,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type.referral',
        }),
        labelMsg: 'search_section.form.payment_type.referral',
      },
      {
        id: REFUND,
        isChecked: type === REFUND,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type.refund',
        }),
        labelMsg: 'search_section.form.payment_type.refund',
      },
      {
        id: CREATOR_EXPENSE,
        isChecked: type === CREATOR_EXPENSE,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type.creator_expense',
        }),
        labelMsg: 'search_section.form.payment_type.creator_expense',
      },
      {
        id: FULLY_MANAGED_ZEROING,
        isChecked: type === FULLY_MANAGED_ZEROING,
        value: intl.formatMessage({
          id: 'search_section.form.payment_type.fully_managed_zeroing',
        }),
        labelMsg: 'search_section.form.payment_type.fully_managed_zeroing',
      },
    ],
    paymentStatus: [
      {
        id: PAID,
        isChecked: paymentStatus === PAID,
        value: intl.formatMessage({
          id: 'search_section.form.payment_status_paid',
        }),
        labelMsg: 'search_section.form.payment_status_paid',
      },
      {
        id: NOT_PAID,
        isChecked: paymentStatus === NOT_PAID,
        value: intl.formatMessage({
          id: 'search_section.form.payment_status_not_paid',
        }),
        labelMsg: 'search_section.form.payment_status_not_paid',
      },
      {
        id: REFUNDED,
        isChecked: paymentStatus === REFUNDED,
        value: intl.formatMessage({
          id: 'search_section.form.payment_status_refund',
        }),
        labelMsg: 'search_section.form.payment_status_refund',
      },
    ],
    subscriptionStatusType: [
      {
        id: SubscriptionStatusTypeEnum.INITIAL,
        isChecked: subscriptionStatusType === SubscriptionStatusTypeEnum.INITIAL,
        value: intl.formatMessage({
          id: 'search_section.form.subscription_status_type.initial',
        }),
        labelMsg: 'search_section.form.subscription_status_type.initial',
      },
      {
        id: SubscriptionStatusTypeEnum.DOWNGRADE,
        isChecked: subscriptionStatusType === SubscriptionStatusTypeEnum.DOWNGRADE,
        value: intl.formatMessage({
          id: 'search_section.form.subscription_status_type.downgrade',
        }),
        labelMsg: 'search_section.form.subscription_status_type.downgrade',
      },
      {
        id: SubscriptionStatusTypeEnum.RENEW,
        isChecked: subscriptionStatusType === SubscriptionStatusTypeEnum.RENEW,
        value: intl.formatMessage({
          id: 'search_section.form.subscription_status_type.renew',
        }),
        labelMsg: 'search_section.form.subscription_status_type.renew',
      },
      {
        id: SubscriptionStatusTypeEnum.UPGRADE,
        isChecked: subscriptionStatusType === SubscriptionStatusTypeEnum.UPGRADE,
        value: intl.formatMessage({
          id: 'search_section.form.subscription_status_type.upgrade',
        }),
        labelMsg: 'search_section.form.subscription_status_type.upgrade',
      },
      {
        id: SubscriptionStatusTypeEnum.OTHER,
        isChecked: subscriptionStatusType === SubscriptionStatusTypeEnum.OTHER,
        value: intl.formatMessage({
          id: 'search_section.form.subscription_status_type.other',
        }),
        labelMsg: 'search_section.form.subscription_status_type.other',
      },
    ],
  };
};

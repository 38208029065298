import React, { PropsWithChildren, useContext, useState } from 'react';
import { parse, ParseResult } from 'papaparse';

import {
  InviteOutreachCreatorsContextType,
  InviteOutreachCreatorsContext,
  InviteOutreachImportCsvDataRow,
  InviteOutreachTemplateData,
  InviteOutreachImportCsvDataErrorRow,
  InviteOutreachImportCsvDataRowsMetrics,
} from './InviteOutreachCreatorsContext';
import {
  CreatorImportPlatform,
  OutreachTransport,
} from 'GraphTypes/ImportOutreachBatchQuery.graphql';
import { checkEmailValid } from 'Util/validate';
import { useImportOutreachBatchQueryMutation } from 'Api/mutations/ImportOutreachBatch.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

type Props = {
  handleUploadCsv: () => void;
};

const InviteOutreachCreatorsProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, handleUploadCsv } = props;
  const [importOutreachBatch, importOutreachBatchLoading] = useImportOutreachBatchQueryMutation();

  const { closeDrawer } = useContext(DrawerContext);

  const [rows, setRows] = useState<InviteOutreachImportCsvDataRow[]>([]);
  const [fileError, setFileError] = useState('');
  const [fileName, setFilename] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [locale, setLocale] = useState('EN');
  const [templateData, setTemplateData] = useState<InviteOutreachTemplateData>({
    id: '',
    name: '',
  });
  const [templatePreview, setTemplatePreview] = useState('');
  const [transport, setTransportProvider] = useState<OutreachTransport>('SENDGRID');
  const [batchId, setBatchId] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);

  const [senderName, setSenderName] = useState('');
  const [senderActiveAuth, setSenderActiveAuth] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [showRowErrors, setShowRowErrors] = useState(false);
  const [rowMetrics, setRowMetrics] = useState<InviteOutreachImportCsvDataRowsMetrics | undefined>(
    undefined
  );

  const [rowErrors, setRowErrors] = useState<InviteOutreachImportCsvDataErrorRow[]>([]);

  const handleParseComplete = ({ data, errors }: ParseResult<string>) => {
    if (errors.length !== 0 || data.length === 0) {
      return;
    }

    const rows: InviteOutreachImportCsvDataRow[] = [];
    const rowErrors: InviteOutreachImportCsvDataErrorRow[] = [];

    let totalCount = 0;
    let parsedCount = 0;

    data.forEach((item, index) => {
      if (item.length === 1 && item[0] === '' && data.length < 2) {
        setFileError('invite_outreach_creators_modal.file.invalid');
        return;
      }
      const itemData = item[0].split(/[,;| ]/);
      const username = itemData[0]?.trim();
      const email = itemData[1]?.trim();
      if (username && email && checkEmailValid(email)) {
        parsedCount++;
        rows.push({ username, email });
      } else {
        rowErrors.push({ index: index + 1, email, username });
      }
      totalCount++;
    });

    setRowMetrics({ totalCount, parsedCount });

    setRowErrors(rowErrors);
    setRows(rows);
  };

  const handleListChange = (files: FileList | null) => {
    if (!files) return;

    setFileError('');
    setFilename(files[0].name);
    setFileSize(files[0].size);

    parse(files[0], {
      delimiter: 'tab',
      complete: handleParseComplete,
    });
  };

  const handleFileReset = () => {
    setFilename('');
    setFileSize(0);
    setFileError('');
    setRows([]);
    setRowMetrics(undefined);
    setRowErrors([]);
    setTemplateData({
      id: '',
      name: '',
    });
    setTemplatePreview('');
    setBatchId('');
    setProcessing(false);
    setError(false);
  };

  const handleTemplateClose = () => {
    setTemplatePreview('');
  };

  const handleImportOutreachBatch = (
    campaignId: string,
    platform?: CreatorImportPlatform | null
  ) => {
    setError(false);
    setProcessing(true);
    importOutreachBatch({
      variables: {
        input: {
          campaignId,
          platform: platform || 'INSTAGRAM',
          rows,
          templateId: templateData.id,
          csvFilename: fileName || 'customlist',
          transport,
          activateAfterCompletion: true,
          ...(transport === 'GMAIL'
            ? {
                gmailAuthorizationId: senderActiveAuth,
                senderEmail,
                senderName,
              }
            : {}),
        },
      },
      onError: () => {
        setProcessing(false);
        setError(true);
      },
      onCompleted: (response) => {
        if (response.importOutreachBatch?.__typename === 'ImportOutreachBatchPayload') {
          handleFileReset();
          handleUploadCsv();
          closeDrawer('invite-outreach-creators');
        } else if (response.importOutreachBatch?.__typename === 'ValidationError') {
          setProcessing(false);
          setFileError('general.something_went_wrong');
        } else if (response.importOutreachBatch?.__typename === 'NotFoundError') {
          setProcessing(false);
          setError(true);
        }
      },
    });
  };

  const isFormValid = Boolean(
    (locale && rows.length > 0 && templateData.id && !fileError && transport === 'SENDGRID') ||
      (locale &&
        rows.length > 0 &&
        templateData.id &&
        !fileError &&
        senderEmail &&
        senderName &&
        transport === 'GMAIL')
  );

  const contextValue: InviteOutreachCreatorsContextType = {
    fileName,
    fileSize,
    fileError,
    locale,
    rows,
    templateData,
    transport,
    batchId,
    error,
    senderName,
    senderEmail,
    templatePreview,
    setTransportProvider,
    handleFileReset,
    handleListChange,
    handleTemplateClose,
    handleImportOutreachBatch,
    handleParseComplete,
    setTemplatePreview,
    setTemplateData,
    setSenderName,
    setSenderActiveAuth,
    setSenderEmail,
    setShowRowErrors,
    setError,
    isFormValid,
    importOutreachBatchLoading,
    processing,
    showRowErrors,
    rowMetrics,
    rowErrors,
  };
  return (
    <InviteOutreachCreatorsContext.Provider value={contextValue}>
      {children}
    </InviteOutreachCreatorsContext.Provider>
  );
};

export { InviteOutreachCreatorsProvider };

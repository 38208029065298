/**
 * @generated SignedSource<<e00314c729b073eb718a0753c49c4eb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UseOrganizationShopifyQuery$variables = Record<PropertyKey, never>;
export type UseOrganizationShopifyQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
      readonly shopifyAuthorization: {
        readonly reauthenticationRequired: boolean;
        readonly shop: {
          readonly id: string;
          readonly name: string;
        };
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UseOrganizationShopifyQuery = {
  response: UseOrganizationShopifyQuery$data;
  variables: UseOrganizationShopifyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Shopify_Shop",
  "kind": "LinkedField",
  "name": "shop",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reauthenticationRequired",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UseOrganizationShopifyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopifyAuthorization",
                "kind": "LinkedField",
                "name": "shopifyAuthorization",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UseOrganizationShopifyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopifyAuthorization",
                "kind": "LinkedField",
                "name": "shopifyAuthorization",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45d005725a453713d4d2adb1586e07f0",
    "id": null,
    "metadata": {},
    "name": "UseOrganizationShopifyQuery",
    "operationKind": "query",
    "text": "query UseOrganizationShopifyQuery {\n  currentUser {\n    organization {\n      id\n      shopifyAuthorization {\n        shop {\n          id\n          name\n        }\n        reauthenticationRequired\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0270f34ca8b52acaa41b6d04d03cbd60";

export default node;

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import { DeletePortfolioItemMutation, DeletePortfolioItemInput } from 'GraphTypes/DeletePortfolioItemMutation.graphql';

const mutation = graphql`
  mutation DeletePortfolioItemMutation($input: DeletePortfolioItemInput!) {
    deletePortfolioItem(input: $input) {
      clientMutationId
    }
  }
`;

interface DataInput {
  creatorId: string,
}

export default (data: DeletePortfolioItemInput & DataInput, resolve?: () => void, reject?: (errors: object) => void) => {
  const { id, creatorId } = data;
  const variables = {
    input: {
      id,
    }
  };

  commitMutation<DeletePortfolioItemMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: store => {
      if (creatorId) {
        const creator = store.get(creatorId);
        if (creator) {
          const items = ConnectionHandler.getConnection(creator, 'Profile_portfolioItems');
          if (items && id) {
            ConnectionHandler.deleteNode(items, id);
          }
        }
      }
    },
    updater: store => {
      if (creatorId) {
        const creator = store.get(creatorId);
        if (creator) {
          const items = ConnectionHandler.getConnection(creator, 'Profile_portfolioItems');
          if (items && id) {
            ConnectionHandler.deleteNode(items, id);
          }
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);
      }
      if (response && resolve) {
        resolve();
      }
    },
  });
};
/**
 * @generated SignedSource<<391d5ec577ba6fba60d4a444cf411716>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateProjectAnalyticsEventInput = {
  chatViewed?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  heartAdded?: boolean | null | undefined;
  portfolioClicked?: boolean | null | undefined;
  profileViewed?: boolean | null | undefined;
  projectId: string;
};
export type CreateProjectAnalyticsEventMutation$variables = {
  input: CreateProjectAnalyticsEventInput;
};
export type CreateProjectAnalyticsEventMutation$data = {
  readonly createProjectAnalyticsEvent: {
    readonly __typename: "NotFoundError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CreateProjectAnalyticsEventMutation = {
  response: CreateProjectAnalyticsEventMutation$data;
  variables: CreateProjectAnalyticsEventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createProjectAnalyticsEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "NotFoundError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateProjectAnalyticsEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateProjectAnalyticsEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce1b42292da9577196c3e0df87ec3181",
    "id": null,
    "metadata": {},
    "name": "CreateProjectAnalyticsEventMutation",
    "operationKind": "mutation",
    "text": "mutation CreateProjectAnalyticsEventMutation(\n  $input: CreateProjectAnalyticsEventInput!\n) {\n  createProjectAnalyticsEvent(input: $input) {\n    __typename\n    ... on NotFoundError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "184fd5c57af2f6027bfd4f9c159dfb1c";

export default node;

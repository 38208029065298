import React from 'react';

import styles from './PublicationsReportButton.pcss';

import { Reporting_ReportState } from 'GraphTypes/CreateCampaignCreatorsReportMutation.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

type Props = {
  state?: Reporting_ReportState;
  error?: boolean;
};

const PublicationsReportButton = (props: Props) => {
  const { state, error } = props;

  const isError = state === 'FAILED' || error;
  const getCurrentIcon = () => {
    if (isError) {
      return 'Warning-error';
    }
    if (state === 'READY') {
      return 'Check';
    }
    return undefined;
  };

  return (
    <AlterButton
      type="grey"
      disabled={state === 'READY'}
      loading={state && state !== 'READY' && !isError}
      icon={getCurrentIcon()}
      className={styles.authorStatisticBtn}
      msg="campaign.author_statistic_get"
    />
  );
};

export default PublicationsReportButton;

import React, { Component } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';

import styles from './RadioSelect.css';

import Text from 'Atoms/Text/Text';
import RadioButton from 'Atoms/RadioButton/RadioButton';



@track((props) => ({
  element: props.name
}))
class RadioSelect extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  @track((props, state, [value]) => ({
    event: "selected",
    payload: {
      value: value ? value.id : 'Any'
    }
  }))
  onChange(value) {
    const {
      name,
      onChange,
    } = this.props;

    onChange({ [name]: value });
  }

  createItem({ key, onClick, isChecked, name, msg }) {
    return (
      <div key={key} className={styles.radioItem} onClick={onClick}>
        <RadioButton active={isChecked} className={styles.icon} />
        <Text text={name} msg={msg} overflow />
      </div>
    );
  }

  creatreRadioItems() {
    const { items, value } = this.props;

    return items.map(item => {
      const { id, name, msg } = item;
      const valueId = value ? value.id : null;
      const isChecked = valueId === id;

      const onClick = () => {
        this.onChange({ name, msg, id });
      };

      return this.createItem({ key: id, onClick, isChecked, name, msg });
    });
  }

  createDefaultItem() {
    const { value } = this.props;
    const onClick = () => {
      this.onChange(null);
    };

    return this.createItem({ key: 'default', onClick, isChecked: !(value), msg: 'search_section.form.default_value' });
  }

  render() {
    const items = this.creatreRadioItems();
    const { inGroup } = this.props;

    return (
      <div className={classnames(styles.root, { [styles.inGroup]: inGroup })}>
        {this.createDefaultItem()}
        {items}
      </div>
    );
  }
}

export default RadioSelect;

import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import type { UseMutationConfig } from 'react-relay';

import styles from '../ShopifyOrderCreationDrawer.pcss';
import {
  ProductVariantsList,
  ProductVariantsListProps,
} from '../components/ProductVariantsList/ProductVariantsList';

import { formatCurrency } from 'Util/numberFormatter';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Text from 'Components/ui/Text/Text';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Button from 'Components/ui/Button/Button';
import Icon from 'Components/ui/Icon/Icon';
import { ProductListProps } from 'Components/ProductList';
import {
  flattenSelectedProductVariants,
  filterProductsBySelectedVariants,
  convertProductsToShopifyLineItemInput,
  calculateTotalSumBySelectedProductVariants,
} from 'Components/ProductList/utils';
import {
  useCreateBulkShopifyOrderMutation,
  CreateBulkShopifyOrderMutation,
} from 'Mutations/CreateBulkShopifyOrder.Mutation';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

type Props = {
  creatorsCount: number;
  projectIDs: string[];
  products: NonNullable<ProductListProps['products']>;
  currencyCode: string;
  className?: string;
  onCreateOrderButtonClick?: () => void;
  handleBackClick?: () => void;
  onCreateBulkShopifyOrderMutationCompleted?: OnCreateBulkShopifyOrderMutationCompleted;
  onCreateBulkShopifyOrderMutationError?: OnCreateBulkShopifyOrderMutationError;
};

const ConfirmOrder: React.FC<Props> = (props) => {
  const {
    className,
    creatorsCount,
    projectIDs,
    currencyCode,
    products,
    onCreateOrderButtonClick,
    onCreateBulkShopifyOrderMutationCompleted,
    onCreateBulkShopifyOrderMutationError,
    handleBackClick,
  } = props;

  const [createBulkShopifyOrderMutation, isLoading] = useCreateBulkShopifyOrderMutation();

  const isFormValid = Boolean(creatorsCount);

  const productsWithSelectedVariants = useMemo<NonNullable<ProductListProps['products']>>(() => {
    const result = products ? filterProductsBySelectedVariants(products) : [];
    return result.map((product) => ({ ...product, hideVariants: true }));
  }, [products]);

  const productVariants = useMemo<ProductVariantsListProps['variants']>(() => {
    return flattenSelectedProductVariants(products);
  }, [products]);

  const totalOrder = useMemo<number>(() => {
    return calculateTotalSumBySelectedProductVariants(products);
  }, [products]);

  const normalizedCurrencyCode = useMemo(() => {
    return typeof props.currencyCode === 'string' ? props.currencyCode.toUpperCase() : '';
  }, [currencyCode]);

  const totals = useMemo(() => {
    return {
      totalSum: formatCurrency(totalOrder * creatorsCount, normalizedCurrencyCode),
      totalPerCreator: creatorsCount ? formatCurrency(totalOrder, normalizedCurrencyCode) : 0,
      discount: '100%',
    };
  }, [totalOrder, normalizedCurrencyCode, creatorsCount]);

  const handleCreateOrderButtonClick = useCallback(() => {
    if (!isFormValid || isLoading) return;
    const lineItems = convertProductsToShopifyLineItemInput(productsWithSelectedVariants).filter(
      (item) => item.quantity > 0
    );

    createBulkShopifyOrderMutation({
      variables: { input: { lineItems, projectIds: projectIDs } },
      onCompleted: onCreateBulkShopifyOrderMutationCompleted,
      onError: onCreateBulkShopifyOrderMutationError,
    });
    onCreateOrderButtonClick?.();
  }, [
    onCreateOrderButtonClick,
    createBulkShopifyOrderMutation,
    projectIDs,
    isLoading,
    isFormValid,
    onCreateBulkShopifyOrderMutationCompleted,
    onCreateBulkShopifyOrderMutationError,
    productsWithSelectedVariants,
  ]);

  return (
    <div className={classnames(styles.contentRoot, styles.confirmOrderContentRoot, className)}>
      <TextPreset
        header={<Text type="d2" msg="shopify_order_creation_drawer.confirm_order.title" />}
      />
      <div className={classnames(styles.content, styles.confirmOrderContent)}>
        <div className={styles.counterContainer}>
          <div className={styles.creatorsCounter}>
            <div className={styles.iconWrapper}>
              <Icon name="Group-users" size={24} />
            </div>
            <div>
              <Text
                type="s"
                className={styles.textColorGrey100}
                msg="shopify_order_creation_drawer.confirm_order.creators_counter.creator"
              />
              <Text
                type="md"
                formatValues={{ count: creatorsCount }}
                msg={`shopify_order_creation_drawer.confirm_order.creators_selection${
                  creatorsCount > 1 ? '.plural' : ''
                }`}
                className={classnames({
                  [styles.textColorError100]: !creatorsCount,
                })}
              />
            </div>
          </div>
          <div className={styles.creatorsCounter}>
            <div className={styles.iconWrapper}>
              <Icon name="Delivery" size={24} />
            </div>
            <div>
              <Text
                type="s"
                className={styles.textColorGrey100}
                msg="shopify_order_creation_drawer.confirm_order.creators_counter.product"
              />
              <Text
                type="md"
                formatValues={{ count: productVariants.length * creatorsCount }}
                msg={`shopify_order_creation_drawer.confirm_order.creators_selection.product${
                  productVariants.length > 1 ? '.plural' : ''
                }`}
                className={classnames({
                  [styles.textColorError100]: !productVariants,
                })}
              />
            </div>
          </div>
        </div>
        <div className={styles.textWrapper}>
          <Text type="md" msg="shopify_order_creation_drawer.confirm_order.products" />
        </div>
        <div className={styles.titleWrapper}>
          <Text
            msg={'shopify_order_creation_drawer.confirm_order.name'}
            type={'label'}
            color={'grey'}
          />
          <Text
            msg={'shopify_order_creation_drawer.confirm_order.type'}
            type={'label'}
            color={'grey'}
          />
          <Text
            msg={'shopify_order_creation_drawer.confirm_order.price'}
            type={'label'}
            color={'grey'}
          />
          <Text
            msg={'shopify_order_creation_drawer.confirm_order.quantity'}
            type={'label'}
            color={'grey'}
          />
        </div>
        <ProductVariantsList
          variants={productVariants}
          currencyCode={normalizedCurrencyCode}
          className={styles.productVariantsList}
          creatorsCount={creatorsCount}
        />
        <div className={styles.textWrapper}>
          <Text
            type="md"
            formatValues={{ sum: totals.totalSum }}
            msg="shopify_order_creation_drawer.confirm_order.total_order"
          />
          <Text
            type="s"
            className={styles.textColorGrey100}
            formatValues={{
              sum: totals.totalPerCreator,
            }}
            msg="shopify_order_creation_drawer.confirm_order.total_order_per_creator"
          />
        </div>
        <div className={styles.textWrapper}>
          <Text
            type="md"
            formatValues={{ sum: totals.discount }}
            msg="shopify_order_creation_drawer.confirm_order.discount"
          />
          <Text
            type="s"
            className={styles.textColorGrey100}
            msg="shopify_order_creation_drawer.confirm_order.discount_description"
          />
        </div>
      </div>
      <ButtonPreset className={styles.buttonWrapper}>
        <AlterButton
          icon={'Arrow-small-left'}
          onClick={handleBackClick}
          bordered
          className={styles.backButton}
        />
        <div className={styles.clicker} onClick={handleCreateOrderButtonClick}>
          <Button
            fluid
            color="black"
            disabled={!isFormValid}
            loading={isLoading}
            dataTest="button:orderCreation:createOrder"
            className={styles.nextButton}
            msg="shopify_order_creation_drawer.create_order"
          />
        </div>
      </ButtonPreset>
    </div>
  );
};

export { ConfirmOrder };

// types

type OnCreateBulkShopifyOrderMutationCompleted = NonNullable<
  UseMutationConfig<CreateBulkShopifyOrderMutation>['onCompleted']
>;
type OnCreateBulkShopifyOrderMutationError = NonNullable<
  UseMutationConfig<CreateBulkShopifyOrderMutation>['onError']
>;

export type { Props as ConfirmOrderProps };

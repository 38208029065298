import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './With.css';

interface Props {
  condition: boolean;
  forceRerender?: boolean;
}

const With: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, condition, forceRerender } = props;

  if (forceRerender && !condition) return null;

  const conditionalStyles = condition ? undefined : styles.hidden;
  const classes = classnames(
    (children as React.ReactElement<any>).props.className,
    conditionalStyles
  );

  return React.cloneElement(children as React.ReactElement<any>, { className: classes });
};

export default With;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ChangeDraftDeadlineInput,
  ChangeDraftDeadlineMutation,
  ChangeDraftDeadlineMutation$data,
} from 'GraphTypes/ChangeDraftDeadlineMutation.graphql';

const mutation = graphql`
  mutation ChangeDraftDeadlineMutation($input: ChangeDraftDeadlineInput!) {
    changeDraftDeadline(input: $input) {
      project {
        deadline {
          draftDate
        }
      }
      eventsConnection {
        edges {
          node {
            __typename
            id
            ... on Deadline {
              draftDate
            }
          }
        }
      }
      project {
        advertiserStage
        deadline {
          draftDate
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ChangeDraftDeadlineInput,
  resolve?: (response: ChangeDraftDeadlineMutation$data) => void,
  reject?: fnType
) => {
  const { draftDate, projectId } = data;

  const id = uuid();
  const variables = {
    input: {
      id,
      draftDate,
      projectId,
    },
  };

  commitMutation<ChangeDraftDeadlineMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

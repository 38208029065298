import React from 'react';

import { createDateV2 } from 'Util/dateCreator';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Atoms/Icon/Icon';

interface Props {
  lastActivityAt?: any,
  active?: boolean,
  size?: string,
}


const CreatorActivityStatus: React.FC<Props> = props => {
  const { active, lastActivityAt, size } = props;

  const iconName = active ? 'activeState' : 'noactiveState';

  const noActiveLastActivityMsg = lastActivityAt ? 'creator.noactive.last_activity_at' : 'creator.noactive.no_date';

  const tooltipMsg = active ? 'creator.active.last_activity_at' : noActiveLastActivityMsg;

  const lastActivityAtDate = lastActivityAt ? createDateV2(lastActivityAt) : '-';

  return (
    <div>
      <Tooltip place="bottom" id="creatorActivity" tooltipMsg={tooltipMsg} tooltipValues={{ date: lastActivityAtDate }}>
        <Icon name={iconName} size={size} />
      </Tooltip>
    </div>
  );
};

export default CreatorActivityStatus;
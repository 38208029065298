import { commitMutation, graphql } from 'react-relay';
import includes from 'lodash/includes';
import { Primitive } from 'relay-runtime/lib/store/RelayStoreTypes';

import environment from 'Api/Environment';
import {
  UpdateCreatorProfileInput,
  UpdateCreatorProfileMutation$data,
  UpdateCreatorProfileMutation,
} from 'GraphTypes/UpdateCreatorProfileMutation.graphql';
import ErrorHandler from 'Util/errorHandler';

const mutation = graphql`
  mutation UpdateCreatorProfileMutation($input: UpdateCreatorProfileInput!) {
    updateCreatorProfile(input: $input) {
      creatorProfile {
        id
        badge
        acceptsBarter
        adminComment
        searchable
        reviewedByAdmin
        influencerType
        birthdate
        employed
        badges {
          name
        }
        videoResumeFile {
          thumbnailUrl
          ... on Video {
            processingStatus
            transformations {
              autoUrl
            }
          }
        }
        hairTypes {
          id
          name
        }
        bodyTypes {
          id
          name
        }
        animals {
          id
          name
        }
        languages {
          id
          name
        }
        tags {
          id
          name
        }
        ethnicity {
          id
        }
        gender {
          id
        }
        cities {
          id
          name
        }
        categories {
          id
          name
          primary
        }
        tags {
          id
          name
        }
        contactEmail
        currency
        customPrice
        estimatedPrice
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateCreatorProfileInput,
  resolve?: (response: UpdateCreatorProfileMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };
  commitMutation<UpdateCreatorProfileMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const profile = store.get(data.id);
      if (!profile) return;

      const keys = Object.keys(data);

      if (includes(keys, 'cityIds')) {
        const cities = (data.cityIds || [])
          .map((cityId) => store.get(cityId) || null)
          .filter((item) => Boolean(item));
        profile.setLinkedRecords(cities, 'cities');
      }

      if (includes(keys, 'categoryIds')) {
        const categories = (data.categoryIds || [])
          .map((categoryId) => store.get(categoryId) || null)
          .filter((item) => Boolean(item));
        profile.setLinkedRecords(categories, 'categories');
      }

      if (includes(keys, 'creatorEthnicityId')) {
        const ethnicity = store.get(data.creatorEthnicityId || '');
        if (ethnicity) {
          profile.setLinkedRecord(ethnicity, 'ethnicity');
        }
      }

      if (includes(keys, 'employed')) {
        profile.setValue(data.employed, 'employed');
      }

      if (includes(keys, 'acceptsBarter')) {
        profile.setValue(data.acceptsBarter, 'acceptsBarter');
      }

      if (includes(keys, 'badge')) {
        profile.setValue(data.badge, 'badge');
      }

      if (includes(keys, 'influencerType')) {
        profile.setValue(data.influencerType, 'influencerType');
      }

      if (includes(keys, 'genderId')) {
        const gender = store.get(data.genderId || '');
        if (gender) {
          profile.setLinkedRecord(gender, 'gender');
        }
      }

      if (includes(keys, 'acceptsBarter')) {
        profile.setValue(data.acceptsBarter, 'acceptsBarter');
      }

      if (includes(keys, 'currency')) {
        profile.setValue(data.currency, 'currency');
      }

      if (includes(keys, 'searchable')) {
        profile.setValue(data.searchable, 'searchable');
      }

      if (includes(keys, 'languageIds')) {
        const languages = (data.languageIds || [])
          .map((languageId) => store.get(languageId) || null)
          .filter((item) => Boolean(item));
        profile.setLinkedRecords(languages, 'languages');
      }

      if (includes(keys, 'animalIds')) {
        profile.setValue(data.animalIds as Primitive[], 'animalIds');
      }

      if (includes(keys, 'birthdate')) {
        if (typeof data.birthdate === 'string') {
          profile.setValue(data.birthdate, 'birthdate');
        } else {
          ErrorHandler.error('UpdateCreatorProfile.Mutation unknown type', {
            birthdate: data.birthdate,
          });
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

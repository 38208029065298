/**
 * @generated SignedSource<<48aa4a18c43ebd20a0d5c78f04e75c7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CancelSubscriptionInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  subscriptionId: string;
};
export type CancelSubscriptionMutation$variables = {
  input: CancelSubscriptionInput;
};
export type CancelSubscriptionMutation$data = {
  readonly cancelSubscription: {
    readonly advertiserSubscription?: {
      readonly cancelAt: any | null | undefined;
      readonly lastSchedule: {
        readonly discount: {
          readonly percentOff: number | null | undefined;
        } | null | undefined;
        readonly startDate: any;
        readonly trialEnd: any | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type CancelSubscriptionMutation = {
  response: CancelSubscriptionMutation$data;
  variables: CancelSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentOff",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialEnd",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cancelSubscription",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionSchedule",
                    "kind": "LinkedField",
                    "name": "lastSchedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Discount",
                        "kind": "LinkedField",
                        "name": "discount",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CancelSubscriptionPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cancelSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "advertiserSubscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionSchedule",
                    "kind": "LinkedField",
                    "name": "lastSchedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Discount",
                        "kind": "LinkedField",
                        "name": "discount",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CancelSubscriptionPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "416c2dbf6afccd8b493c85212424ef8a",
    "id": null,
    "metadata": {},
    "name": "CancelSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation CancelSubscriptionMutation(\n  $input: CancelSubscriptionInput!\n) {\n  cancelSubscription(input: $input) {\n    __typename\n    ... on CancelSubscriptionPayload {\n      advertiserSubscription {\n        cancelAt\n        lastSchedule {\n          discount {\n            percentOff\n            id\n          }\n          trialEnd\n          startDate\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc30e4de7701e2b16d24e01bcd0bb852";

export default node;

/**
 * @generated SignedSource<<74f83238b6205d058f0e1d89ec301590>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type FileBcaTaggingStatus = "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type FileBcaTaggingStatusUpdatedSubscription$variables = Record<PropertyKey, never>;
export type FileBcaTaggingStatusUpdatedSubscription$data = {
  readonly fileBcaTaggingStatusUpdated: {
    readonly bcaTaggedUrl: string | null | undefined;
    readonly bcaTaggingStatus: FileBcaTaggingStatus | null | undefined;
    readonly id: string;
  };
};
export type FileBcaTaggingStatusUpdatedSubscription = {
  response: FileBcaTaggingStatusUpdatedSubscription$data;
  variables: FileBcaTaggingStatusUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bcaTaggedUrl",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bcaTaggingStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FileBcaTaggingStatusUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "fileBcaTaggingStatusUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FileBcaTaggingStatusUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "fileBcaTaggingStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bfa63dcd97b6010fbf1f1b313f61d871",
    "id": null,
    "metadata": {},
    "name": "FileBcaTaggingStatusUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription FileBcaTaggingStatusUpdatedSubscription {\n  fileBcaTaggingStatusUpdated {\n    __typename\n    id\n    bcaTaggedUrl\n    bcaTaggingStatus\n  }\n}\n"
  }
};
})();

(node as any).hash = "252ad3902b4104d2815366b89237d243";

export default node;

export const reasons = [
  { msg: 'archive_project_request_by_contactor_modal.product_issues' },
  { msg: 'archive_project_request_by_contactor_modal.seeding' },
  { msg: 'archive_project_request_by_contactor_modal.price' },
  { msg: 'archive_project_request_by_contactor_modal.deadline' },
  { msg: 'archive_project_request_by_contactor_modal.no_purchase' },
  { msg: 'archive_project_request_by_contactor_modal.personal' },
  { msg: 'archive_project_request_by_contactor_modal.product' },
  { msg: 'archive_project_request_by_contactor_modal.unresponsive' },
  { msg: 'archive_project_request_by_contactor_modal.brand_issues' },
  { msg: 'archive_project_request_by_contactor_modal.other' },
];

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AuthorizePaidSocialMutation,
  AuthorizePaidSocialInput,
  AuthorizePaidSocialMutation$data,
} from 'GraphTypes/AuthorizePaidSocialMutation.graphql';

const SUCCESS = 'SUCCESS';

const mutation = graphql`
  mutation AuthorizePaidSocialMutation($input: AuthorizePaidSocialInput!) {
    authorizePaidSocial(input: $input) {
      result
      facebookPageId
      facebookPageName
    }
  }
`;

type fnType = (data: object) => void;

type Data = {
  projectId?: string;
};

export default (
  data: AuthorizePaidSocialInput & Data,
  resolve?: (response: AuthorizePaidSocialMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();
  const { instagramUsername, projectId } = data;

  const variables = {
    input: {
      id,
      instagramUsername,
    },
  };

  commitMutation<AuthorizePaidSocialMutation>(environment, {
    mutation,
    variables,
    updater: (store, data) => {
      if (!projectId) return;
      const project = store.get(projectId);
      const success = data?.authorizePaidSocial?.result === SUCCESS;
      if (project && success) {
        const creator = project.getLinkedRecord('creator');
        if (!creator) return;
        creator.setValue(true, 'paidSocialEnabled');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useContext, useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { v4 as uuid } from 'uuid';

import { BriefContext } from '../../../Brief.Context';
import Section from '../components/Section/Section';

import styles from './CreativeAssets.css';
import CreativeAsset from './CreativeAsset/CreativeAsset';
import Deliverables from './Deliverables/Deliverables';

import createBriefCreative from 'Mutations/CreateBriefCreative.Mutation';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import { CreativeAssets_campaign$key } from 'GraphTypes/CreativeAssets_campaign.graphql';
import { PRODUCT_SEEDING } from 'Constants/general';

interface Props {
  campaign: CreativeAssets_campaign$key;
}

const CreativeAssets: React.FC<Props> = (props) => {
  const [newCreativeId, setNewCreativeId] = useState('');

  const [briefState, briefDispatch] = useContext(BriefContext);

  const { campaign } = props;

  const data = useFragment(
    graphql`
      fragment CreativeAssets_campaign on Campaign {
        id
        type
        platform
        objective
        brief {
          id
          ... on V2Brief {
            taskDescription
            creatives(first: 2147483647) @connection(key: "Creatives_creatives") {
              edges {
                node {
                  id
                  ...CreativeAsset_briefCreative
                }
              }
              totalCount
            }
          }
        }
      }
    `,
    campaign
  );

  const { id: campaignId, brief, type, platform, objective } = data;

  const totalCount = brief?.creatives?.totalCount;

  const completeTotalCount = Object.keys(briefState.creativeAssets).reduce(
    (prevItem, currentItem) => {
      return briefState.creativeAssets[currentItem] === true ? prevItem + 1 : prevItem;
    },
    0
  );

  useEffect(() => {
    briefDispatch({
      key: 'creativesInfoFilled',
      value: completeTotalCount === totalCount && completeTotalCount > 0,
    });
  }, [briefState.creativeAssets, briefState.campaignType]);

  useEffect(() => {
    const creativeAssets = { ...briefState.creativeAssets };
    for (let key of Object.keys(creativeAssets)) {
      creativeAssets[key] = null;
    }
    briefDispatch({
      key: 'creativeAssets',
      value: creativeAssets,
    });
  }, [briefState.campaignType]);

  if (!brief || !briefState.creatorsInfoFilled) return null;

  const { id: briefId, creatives } = brief;

  const handleNewCreativeAdd = () => {
    const newId = uuid();
    setNewCreativeId(newId);
    createBriefCreative({ id: newId, briefId, contentAmount: 1 });
  };

  return (
    <Section
      titleMsg="brief_template.section.creative_assets"
      titleValues={{ count: completeTotalCount ? `(${completeTotalCount})` : '' }}
      className={styles.section}
      titleClassName={styles.title}
    >
      {data.type === PRODUCT_SEEDING ? (
        <Deliverables brief={brief} />
      ) : (
        <>
          <Text msg="brief_template.section.creative_assets.descr" className={styles.descr} />
          {creatives?.edges?.map((item) => {
            if (!item?.node) return null;

            const { id } = item.node;

            return (
              <CreativeAsset
                key={id}
                campaignId={campaignId}
                campaignType={type}
                campaignPlatform={platform}
                campaignObjective={objective}
                briefId={briefId}
                briefCreative={item.node}
                defaultOpened={newCreativeId === id}
              />
            );
          })}
          <Button
            width="full"
            color="normal"
            border="dashed"
            leftIconName="plus"
            msg="brief_template.creative_asset.add_new"
            className={styles.addBtn}
            dataTest="button:addCreative"
            onClick={handleNewCreativeAdd}
          />
        </>
      )}
    </Section>
  );
};

export default CreativeAssets;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  MarkInsenseContentReviewAsPassedInput,
  MarkInsenseContentReviewAsPassedMutation,
  MarkInsenseContentReviewAsPassedMutation$data,
} from 'GraphTypes/MarkInsenseContentReviewAsPassedMutation.graphql';

const mutation = graphql`
  mutation MarkInsenseContentReviewAsPassedMutation(
    $input: MarkInsenseContentReviewAsPassedInput!
  ) {
    markInsenseContentReviewAsPassed(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: MarkInsenseContentReviewAsPassedInput,
  resolve?: (response: MarkInsenseContentReviewAsPassedMutation$data) => void,
  reject?: fnType
) => {
  const { projectId } = data;

  const variables = {
    input: {
      projectId,
    },
  };
  commitMutation<MarkInsenseContentReviewAsPassedMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const project = store.get(projectId);
      if (project) {
        project?.setValue(null, 'insenseContentReview');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response.markInsenseContentReviewAsPassed?.clientMutationId && resolve) {
        resolve(response);
      } else if (reject) {
        reject({
          error: 'Unknown error',
        });
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React from 'react';
import classNames from 'classnames';

import styles from './MobileBanner.pcss';

import Logo from 'Atoms/Logo/Logo';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Text from 'Components/ui/Text/Text';
import { BOOK_A_DEMO_LINK_EN } from 'Constants/general';

const MobileBanner = () => {
  const bookDemo = () => {
    window.location.href = BOOK_A_DEMO_LINK_EN;
  };

  return (
    <div className={styles.mobileContainer}>
      <header className={styles.mobileHeader}>
        <Logo className={styles.mobileLogo} />
      </header>
      <div className={styles.mobileContent}>
        <Text type="md" msg="mobile_banner.title" size={15} className={styles.mobileDescription} />
        <Text
          type="md"
          msg="mobile_banner.subtitle"
          size={15}
          className={styles.mobileDescription}
        />
        <div className={styles.textBlock}>
          <div>
            <Text
              type="md"
              msg="mobile_banner.current_title"
              size={15}
              inline
              className={styles.textBlockTitle}
            />
            <Text type="md" msg="mobile_banner.current_description" size={15} inline />
          </div>
        </div>
        <div className={styles.textBlock}>
          <div>
            <Text
              type="md"
              msg="mobile_banner.new_title"
              size={15}
              inline
              className={styles.textBlockTitle}
            />
            <Text type="md" msg="mobile_banner.new_description" size={15} inline />
          </div>
          <AlterButton
            msg="mobile_banner.button"
            onClick={bookDemo}
            fluid
            type={'black'}
            className={styles.mobileBtn}
          />
        </div>
        <div className={styles.textBlock}>
          <div>
            <Text
              type="md"
              msg="mobile_banner.creator_title"
              size={15}
              inline
              className={styles.textBlockTitle}
            />
            <Text type="md" msg="mobile_banner.creator_description" size={15} inline />
          </div>
          <div className={styles.marketBtnContainer}>
            <a
              href="https://apps.apple.com/us/app/insense-brand-collabs/id1155596188?itsct=apps_box_badge&amp;itscg=30200"
              className={classNames(styles.marketBtn, styles.appleBtn)}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1474588800"
                alt="Download on the App Store"
                className={classNames(styles.marketBtn, styles.appleBtn)}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.insense_mobile&utm_source=app.insense.pro&utm_campaign=mobile_banner&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              className={styles.marketBtn}
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                className={styles.marketBtn}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBanner;

/**
 * @generated SignedSource<<c5ca28d9f8c20c2f0e6d8849eddc15d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ErrorType = "ALREADY_EXIST_ERROR" | "CREATOR_ALREADY_OWNED_ERROR" | "NOT_CLAIMED" | "NO_CREATOR" | "SIGNIN_ERROR" | "SIGNUP_ERROR" | "VALIDATION_ERROR" | "%future added value";
export type AcceptDeadlineChangeRequestInput = {
  clientMutationId?: string | null | undefined;
  deadlineChangeRequestId: string;
  id?: string | null | undefined;
};
export type AcceptDeadlineChangeRequestMutation$variables = {
  input: AcceptDeadlineChangeRequestInput;
};
export type AcceptDeadlineChangeRequestMutation$data = {
  readonly acceptDeadlineChangeRequest: {
    readonly message?: string;
    readonly project?: {
      readonly deadline: {
        readonly draftDate: any | null | undefined;
      } | null | undefined;
    };
    readonly type?: ErrorType;
  } | null | undefined;
};
export type AcceptDeadlineChangeRequestMutation = {
  response: AcceptDeadlineChangeRequestMutation$data;
  variables: AcceptDeadlineChangeRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Error",
  "abstractKey": "__isError"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draftDate",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "ValidationError",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptDeadlineChangeRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "acceptDeadlineChangeRequest",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Deadline",
                    "kind": "LinkedField",
                    "name": "deadline",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AcceptDeadlineChangeRequestPayload",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptDeadlineChangeRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "acceptDeadlineChangeRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Deadline",
                    "kind": "LinkedField",
                    "name": "deadline",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "AcceptDeadlineChangeRequestPayload",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c3269d60f5fcb30fc0e0e17870a63c3",
    "id": null,
    "metadata": {},
    "name": "AcceptDeadlineChangeRequestMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptDeadlineChangeRequestMutation(\n  $input: AcceptDeadlineChangeRequestInput!\n) {\n  acceptDeadlineChangeRequest(input: $input) {\n    __typename\n    ... on Error {\n      __isError: __typename\n      message\n      type\n    }\n    ... on AcceptDeadlineChangeRequestPayload {\n      project {\n        deadline {\n          draftDate\n          id\n        }\n        id\n      }\n    }\n    ... on ValidationError {\n      message\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "849a49bee55dbdf523f1d9089fdd20e3";

export default node;

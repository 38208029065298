import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  CreateShipmentInformationReportMutation,
  CreateShipmentInformationReportMutation$data,
  Reporting_CreateShipmentInformationReportInput,
} from 'GraphTypes/CreateShipmentInformationReportMutation.graphql';

const mutation = graphql`
  mutation CreateShipmentInformationReportMutation(
    $input: Reporting_CreateShipmentInformationReportInput!
  ) {
    reporting {
      createShipmentInformationReport(input: $input) {
        report {
          id
          state
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Reporting_CreateShipmentInformationReportInput,
  resolve?: (response: CreateShipmentInformationReportMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<CreateShipmentInformationReportMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<8dc176540e68eb7726cd3caf4fc97939>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkProjectEventsAsReadInput = {
  clientMutationId?: string | null | undefined;
  uptoEventId: string;
};
export type MarkProjectEventsAsReadMutation$variables = {
  input: MarkProjectEventsAsReadInput;
};
export type MarkProjectEventsAsReadMutation$data = {
  readonly markProjectEventsAsRead: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type MarkProjectEventsAsReadMutation = {
  response: MarkProjectEventsAsReadMutation$data;
  variables: MarkProjectEventsAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkProjectEventsAsReadPayload",
    "kind": "LinkedField",
    "name": "markProjectEventsAsRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkProjectEventsAsReadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkProjectEventsAsReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4b8e9fd143145b3525e1929f372281bc",
    "id": null,
    "metadata": {},
    "name": "MarkProjectEventsAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation MarkProjectEventsAsReadMutation(\n  $input: MarkProjectEventsAsReadInput!\n) {\n  markProjectEventsAsRead(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "5bacc56a8163d5c824e435799816c18a";

export default node;

import { combineReducers } from 'redux';

import notifications from 'Reducers/Notifications.Reducer';
import references from 'Reducers/References.Reducer';
import creator from 'Reducers/Creator.Reducer';
import creatorSearch from 'Reducers/CreatorsSearch.Reducer';
import general from 'Reducers/General.Reducer';
import modal from 'Reducers/Modal.Reducer';
import sessions from 'Reducers/Sessions.Reducer';
import ui from 'Reducers/UI.Reducer';
import currentUserData from 'Reducers/CurrentUserData.Reducer';

const rootReducer = combineReducers({
  references,
  notifications,
  creator,
  creatorSearch,
  general,
  modal,
  sessions,
  ui,
  currentUserData,
});

export default rootReducer;

import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';

const subscription = graphql`
  subscription FileProcessingStatusUpdatedSubscription {
    fileProcessingStatusUpdated {
      id
      ... on Video {
        processingStatus
      }
    }
  }
`;


export default () => {
  requestSubscription(environment, {
    subscription,
    variables: {},
  });
};

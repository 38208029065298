import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import { BrowserView, MobileView, isMobile, isTablet } from 'react-device-detect';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useLocation } from 'react-router-dom';

import styles from './Dialog.pcss';
import CreatorProjectInfo from './CreatorProjectInfo/CreatorProjectInfo';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import DialogContent from './DialogContent';
import MobileBloggerInfo from './MobileBloggerInfo/MobileBloggerInfo';

import BackButton from 'Molecules/BackButton/BackButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ShopifyChatTooltip } from 'Components/ShopifyChatTooltip/ShopifyChatTooltip';
import { DialogQuery as QueryType } from 'GraphTypes/DialogQuery.graphql';
import DialogStub from './DialogStub';

const DialogQuery = graphql`
  query DialogQuery($projectId: ID!) {
    project(id: $projectId) {
      contractorStage
      campaign {
        id
        name
      }
      launch {
        id
      }
      completion {
        id
      }
      archivedByContractor: archivation(side: CONTRACTOR) {
        id
      }
      archivedByAdvertiser: archivation(side: ADVERTISER) {
        id
      }
      deadline {
        draftDate
        publicationDate
      }
      campaign {
        organization {
          id
        }
      }
      ...MobileBloggerInfo_project
    }
  }
`;

interface Props {
  className?: string;
  projectId?: string;
  organizationId?: string;
  isAdvertiser?: boolean;
  isOldView?: boolean;
  shipmentEnabled?: boolean;
}

const Dialog: React.FC<Props> = (props) => {
  const {
    className,
    projectId = '',
    organizationId,
    isAdvertiser,
    isOldView = true,
    shipmentEnabled,
  } = props;

  const [isCustomListSelectionOpen, setIsCustomListSelectionOpen] = useState(false);
  const [browserViewScrollTop, setBrowserViewScrollTop] = useState(0);

  const { state: locationState } = useLocation();

  const { openDrawer } = useContext(DrawerContext);

  const data = useLazyLoadQuery<QueryType>(DialogQuery, { projectId });

  if (!data) {
    return <DialogStub />;
  }

  const project = data.project;
  const launch = !!project?.launch?.id;
  const completion = !!project?.completion?.id;
  const contractorStage = project?.contractorStage;
  const archivedByAdvertiser = !!project?.archivedByAdvertiser?.id;
  const draftDate = project?.deadline?.draftDate;
  const publicationDate = project?.deadline?.publicationDate;
  const isOwner = organizationId === project?.campaign.organization?.id;
  const isActiveProject = launch && !completion && !archivedByAdvertiser;
  const campaignId = project?.campaign.id || '';
  const campaignName = project?.campaign.name || '';

  const handleBrowserViewScroll = (e) => {
    setBrowserViewScrollTop(e.currentTarget.scrollTop);
  };

  if (isActiveProject && !draftDate && isOwner) {
    openDrawer(`project-draft-deadline-${projectId}`);
  }
  if (isActiveProject && !publicationDate && isOwner && contractorStage === 'PUBLISHING_STARTED') {
    openDrawer(`project-publication-date-${projectId}`);
  }

  const backLink = locationState?.tab
    ? `/campaigns/${campaignId}/${locationState.tab}`
    : `/campaigns/${campaignId}`;

  const handleCustomListOpen = () => {
    setIsCustomListSelectionOpen(true);
  };

  const handleCustomListClose = () => {
    setIsCustomListSelectionOpen(false);
  };

  return (
    <div
      className={classnames(className, styles.root, {
        [styles.isMobile]: isMobile,
        [styles.newView]: !isOldView,
        [styles.contractorView]: !isAdvertiser,
      })}
    >
      <div className={styles.content}>
        {!isTablet && isOldView && isAdvertiser && (
          <BrowserView
            className={classnames(styles.projectWrap, {
              [styles.overflowVisible]: isCustomListSelectionOpen,
            })}
          >
            {isAdvertiser && (
              <ProjectInfo
                onScroll={handleBrowserViewScroll}
                style={isCustomListSelectionOpen ? { top: `-${browserViewScrollTop}px` } : {}}
                projectId={projectId}
                className={styles.project}
                onCustomListSelectionOpen={handleCustomListOpen}
                onCustomListSelectionClose={handleCustomListClose}
              />
            )}
          </BrowserView>
        )}
        <MobileView>
          {project && <MobileBloggerInfo project={project} isAdvertiser={isAdvertiser} />}
        </MobileView>
        {!isAdvertiser && (
          <div className={styles.projectWrap}>
            <CreatorProjectInfo projectId={projectId} />
          </div>
        )}
        <DialogContent isOldView={isOldView} projectId={projectId} isAdvertiser={isAdvertiser} />
        <ShopifyChatTooltip
          isAdvertiser={isAdvertiser}
          campaignId={campaignId}
          launched={launch}
          tooltipClassName={styles.onboardingTooltip}
          shipmentEnabled={shipmentEnabled}
        />
      </div>
      {!!(isAdvertiser && !isTablet && isOldView) && (
        <BrowserView>
          <BackButton text={campaignName} link={backLink} className={styles.back} />
        </BrowserView>
      )}
    </div>
  );
};

export default Dialog;

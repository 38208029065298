import React, { useCallback, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';

import styles from '../ShopifyAuthDrawer.pcss';

import type { AuthenticateWithShopifyMutation$data } from 'Mutations/AuthenticateWithShopify.Mutation';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Text from 'Components/ui/Text/Text';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Button from 'Components/ui/Button/Button';
import { useShopify } from 'Hooks/useShopify';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { amplitude } from 'Helpers/amplitude';
import { SHOPIFY_APP_STORE_URL } from 'Constants/general';

type Props = {
  campaignId: string;
  className?: string;
  currentUserId: string;
  onConnectionFailed?: (response?: AuthenticateWithShopifyMutation$data) => void;
  onConnectionSucceeded?: (shopName: string) => void;
};

const ConnectToShopify: React.FC<Props> = (props) => {
  const { className, campaignId, currentUserId } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const [, setIsFormValid] = useState<boolean>(false);
  const [inputValue] = useState<string>('insense-ev-test.myshopify.com');

  const { isShopUrlValid, writeCacheCampaigns } = useShopify({ campaignId });

  useEffect(() => {
    const value = inputValue.trim();
    setIsFormValid(isShopUrlValid(value));
  }, [inputValue, isShopUrlValid]);

  const handleInstallShopifyButtonClick = useCallback(() => {
    amplitude.sendEvent<318>({
      id: '318',
      category: 'shipment',
      name: 'connection_connect_click',
      param: { campaign_id: campaignId },
    });

    writeCacheCampaigns({ currentUserId, campaignIds: [campaignId] });
    window.open(SHOPIFY_APP_STORE_URL, '_blank');
    closeDrawer('shopify-auth');
  }, [currentUserId, campaignId]);

  return (
    <div className={classnames(styles.contentRoot, className)}>
      <TextPreset header={<Text type="d2" msg="shopify_auth_drawer.connect_to_shopify.title" />} />
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <Text type="md" msg="shopify_auth_drawer.connect_to_shopify.info_content.1" />
        </div>
        <div className={styles.textWrapper}>
          <Text type="md" msg="shopify_auth_drawer.connect_to_shopify.info_content.2" />
        </div>
      </div>
      <ButtonPreset className={styles.buttonWrapper}>
        <div className={styles.clicker} onClick={handleInstallShopifyButtonClick} />
        <Button
          fluid
          color="black"
          dataTest="button:installShopifyApp"
          className={styles.nextButton}
          msg="shopify_auth_drawer.install_app_button"
        />
      </ButtonPreset>
    </div>
  );
};

export { ConnectToShopify };

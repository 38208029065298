import { commitMutation, graphql } from 'react-relay';

import {
  CreateProjectAnalyticsEventInput,
  CreateProjectAnalyticsEventMutation,
  CreateProjectAnalyticsEventMutation$data,
} from 'GraphTypes/CreateProjectAnalyticsEventMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation CreateProjectAnalyticsEventMutation($input: CreateProjectAnalyticsEventInput!) {
    createProjectAnalyticsEvent(input: $input) {
      __typename
      ... on NotFoundError {
        message
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateProjectAnalyticsEventInput,
  resolve?: (response: CreateProjectAnalyticsEventMutation$data) => void,
  reject?: fnType
) => {
  const { chatViewed, heartAdded, portfolioClicked, profileViewed, projectId } = data;

  const variables = {
    input: {
      chatViewed,
      heartAdded,
      portfolioClicked,
      profileViewed,
      projectId,
    },
  };

  commitMutation<CreateProjectAnalyticsEventMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<7bf8d40708c7a19258764e9c9c65171b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeCreatorUsernameResult = "EQUAL_USERNAME" | "NO_CREATOR" | "NO_INFLUENCER" | "NO_TIKTOK_USER" | "SUCCESS" | "UNSUPPORTED_PLATFORM" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type ChangeCreatorUsernameInput = {
  clientMutationId?: string | null | undefined;
  creatorType: CreatorType;
  newUsername: string;
  oldUsername?: string | null | undefined;
};
export type ChangeCreatorUsernameMutation$variables = {
  input: ChangeCreatorUsernameInput;
};
export type ChangeCreatorUsernameMutation$data = {
  readonly changeCreatorUsername: {
    readonly result: ChangeCreatorUsernameResult;
  } | null | undefined;
};
export type ChangeCreatorUsernameMutation = {
  response: ChangeCreatorUsernameMutation$data;
  variables: ChangeCreatorUsernameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeCreatorUsernamePayload",
    "kind": "LinkedField",
    "name": "changeCreatorUsername",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeCreatorUsernameMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeCreatorUsernameMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fa30f1adc18eb590a6351cd4660422c6",
    "id": null,
    "metadata": {},
    "name": "ChangeCreatorUsernameMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeCreatorUsernameMutation(\n  $input: ChangeCreatorUsernameInput!\n) {\n  changeCreatorUsername(input: $input) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "1bb044e8baf337a5965637cd15c6e3a7";

export default node;

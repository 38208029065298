/**
 * @generated SignedSource<<6d412b46d6b046d636432948a67c4335>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BulkProjectsArchivationQuery$variables = Record<PropertyKey, never>;
export type BulkProjectsArchivationQuery$data = {
  readonly currentUser: {
    readonly archiveUnlaunchedCreatorProjectsOperation: {
      readonly completed: boolean;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type BulkProjectsArchivationQuery = {
  response: BulkProjectsArchivationQuery$data;
  variables: BulkProjectsArchivationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ArchiveUnlaunchedCreatorProjectOperation",
  "kind": "LinkedField",
  "name": "archiveUnlaunchedCreatorProjectsOperation",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkProjectsArchivationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BulkProjectsArchivationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "11ffffa73e52bb444ab83da5bfd3513e",
    "id": null,
    "metadata": {},
    "name": "BulkProjectsArchivationQuery",
    "operationKind": "query",
    "text": "query BulkProjectsArchivationQuery {\n  currentUser {\n    archiveUnlaunchedCreatorProjectsOperation {\n      id\n      completed\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0566d813e803b0fa67239838d4bb9cec";

export default node;

import React from 'react';
import { useSelector } from 'react-redux';

import styles from './Preloader.css';

import { StoreState } from 'Types/reducers';

const PreloaderMain = () => {
  const showPreloader = useSelector((state: StoreState) => state.root.ui.showPreloader);
  const showHeader = useSelector((state: StoreState) => state.root.ui.showHeader);
  const showModal = useSelector((state: StoreState) => state.root.modal.opened);

  const isShown = showPreloader ? styles.show : '';
  const position = showModal || !showHeader ? styles.top : '';

  return (
    <div className={`${styles.root} ${isShown} ${position}`}>
      <div className={styles.barContainer}>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </div>
    </div>
  );
};

export default PreloaderMain;

import { AuthenticateWithGmailMutation$data } from 'GraphTypes/AuthenticateWithGmailMutation.graphql';

export class GmailAuthError extends Error {
  constructor(...args: string[]) {
    super(...args);
  }
}

export class GmailAuthBackendError extends Error {
  constructor(...args: string[]) {
    super(...args);
  }
}

export enum GmailPostMessageTypeEnum {
  Authenticate = 'Authenticate',
}

export type PostMessageEventHandler = Parameters<typeof window.addEventListener<'message'>>[1];
export type GmailAuthDataResponse = {
  error: boolean;
  data?: AuthenticateWithGmailMutation$data['authenticateWithGmail'] | null;
};
export type GmailPostMessageData = {
  error: boolean;
  type: GmailPostMessageTypeEnum;
  params: {
    code: string;
  };
};

/**
 * @generated SignedSource<<22e017bed0ff4b8838e9d764722def66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type AdminAdvertisersContainerQuery$variables = Record<PropertyKey, never>;
export type AdminAdvertisersContainerQuery$data = {
  readonly planIds: ReadonlyArray<PlanId>;
};
export type AdminAdvertisersContainerQuery = {
  response: AdminAdvertisersContainerQuery$data;
  variables: AdminAdvertisersContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "planIds",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminAdvertisersContainerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminAdvertisersContainerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8d5784a13d6e5cd58b96b500806f9e2b",
    "id": null,
    "metadata": {},
    "name": "AdminAdvertisersContainerQuery",
    "operationKind": "query",
    "text": "query AdminAdvertisersContainerQuery {\n  planIds\n}\n"
  }
};
})();

(node as any).hash = "fa3340ee8372e35d689a6445c07d515b";

export default node;

/**
 * @generated SignedSource<<8902c92bb5644c11ae667a89fbecaad8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthorizeTiktokAdsInput = {
  clientMutationId?: string | null | undefined;
  code?: string | null | undefined;
};
export type AuthorizeTiktokAdsMutation$variables = {
  input: AuthorizeTiktokAdsInput;
};
export type AuthorizeTiktokAdsMutation$data = {
  readonly authorizeTiktokAds: {
    readonly creatorOwnership: {
      readonly creator: {
        readonly id: string;
        readonly username: string;
      };
    } | null | undefined;
  } | null | undefined;
};
export type AuthorizeTiktokAdsMutation = {
  response: AuthorizeTiktokAdsMutation$data;
  variables: AuthorizeTiktokAdsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorizeTiktokAdsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthorizeTiktokAdsPayload",
        "kind": "LinkedField",
        "name": "authorizeTiktokAds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorOwnership",
            "kind": "LinkedField",
            "name": "creatorOwnership",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorizeTiktokAdsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthorizeTiktokAdsPayload",
        "kind": "LinkedField",
        "name": "authorizeTiktokAds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorOwnership",
            "kind": "LinkedField",
            "name": "creatorOwnership",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ae1e598a82a6d84491bb0464ccc76f2",
    "id": null,
    "metadata": {},
    "name": "AuthorizeTiktokAdsMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorizeTiktokAdsMutation(\n  $input: AuthorizeTiktokAdsInput!\n) {\n  authorizeTiktokAds(input: $input) {\n    creatorOwnership {\n      creator {\n        __typename\n        id\n        username\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9294e8165effa8c603a42cada45446e1";

export default node;

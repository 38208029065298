import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './ProjectCreatorDetails.pcss';
import ProjectDraftDate from './ProjectDraftDate/ProjectDraftDate';
import ProjectPrice from './ProjectPrice/ProjectPrice';
import ShipmentInformation from './ShipmentInformation/ShipmentInformation';

import { amplitude } from 'Helpers/amplitude';
import environment from 'Api/Environment';
import addCreatorToCampaignShortlist from 'Mutations/AddCreatorToCampaignShortlist.Mutation';
import removeCreatorFromCampaignShortlist from 'Mutations/RemoveCreatorFromCampaignShortlist.Mutation';
import { getCreatorData } from 'Util/creatorData';
import Image from 'Components/ui/Image/Image';
import Avatar from 'Atoms/Avatar/Avatar';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import thumbsUpFilledImg from 'Images/icons/thumbs-up-filled.svg';
import thumbsUpImg from 'Images/icons/thumbs-up-accept.svg';
import { HELLO_EMAIL } from 'Constants/general';
import { ProjectCreatorDetailsQuery as QueryType } from 'GraphTypes/ProjectCreatorDetailsQuery.graphql';

const ProjectCreatorDetailsQuery = graphql`
  query ProjectCreatorDetailsQuery($id: ID!) {
    project(id: $id) {
      shortlisted
      archivation {
        id
      }
      archivationRequest {
        acceptance {
          id
        }
        rejection {
          id
        }
        side
      }
      campaign {
        id
        type
      }
      launch {
        id
      }
      advertiserStage
      ...ProjectDraftDate_project
      ...ProjectPrice_project
      ...ShipmentInformation_project
      creator {
        id
        username
        type
        profile {
          badge
          profilePictureFile {
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
        __typename
        ... on InstagramCreator {
          user {
            profilePictureUrl
          }
        }
        ... on YoutubeCreator {
          channel {
            id
            thumbnailUrl
            name
          }
        }
        ... on ContentCreator {
          avatarUrl
        }
        ... on TiktokCreator {
          tiktokUser: user {
            avatar {
              secureUrl
            }
            name
          }
        }
      }
    }
  }
`;

interface Props {
  projectId: string;
}

const ProjectCreatorDetails: React.FC<Props> = (props) => {
  const { projectId } = props;

  const data = useLazyLoadQuery<QueryType>(ProjectCreatorDetailsQuery, { id: projectId });

  const project = data.project;

  if (!data || !project) return null;

  const creator = project.creator;
  const shortlisted = project.shortlisted;
  const campaign = project.campaign;
  const archivationRequest = project.archivationRequest;
  const archivation = project.archivation;
  const advertiserStage = project.advertiserStage;

  const creatorId = creator?.id;
  const { avaSrc, username, link } = getCreatorData(creator);

  const campaignId = campaign.id;
  const campaignType = campaign.type;

  const isArchived = !!archivation?.id;

  const stageTitle = isArchived ? 'archived' : advertiserStage?.toLowerCase();

  const requestProcced =
    archivationRequest &&
    archivationRequest.side === 'ADVERTISER' &&
    !archivationRequest.acceptance &&
    !archivationRequest.rejection;

  const handleToggleFavoriteState = () => {
    if (!creatorId || !campaignId) return;
    if (shortlisted) {
      removeCreatorFromCampaignShortlist({ creatorId, campaignId });
    } else {
      amplitude.sendEvent<105>({
        id: '105',
        category: 'campaign',
        name: 'campaign_creator_add_shortlist',
        param: {
          source: 'popup-chat',
          creator_id: creatorId,
        },
      });
      addCreatorToCampaignShortlist({ creatorId, campaignId });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.items}>
          <SimpleLink href={link} target="_blank">
            <Avatar size={40} src={avaSrc} />
          </SimpleLink>
          <div className={styles.username}>
            <SimpleLink href={link} target="_blank">
              <Text type="md" text={username} />
            </SimpleLink>
            <Text
              type="sm"
              msg={advertiserStage ? `chat.in_modal.stage.${stageTitle}` : ''}
              className={styles.stage}
            />
          </div>
        </div>
        <div className={styles.controls}>
          {!requestProcced && <ProjectDraftDate project={project} />}
          {!requestProcced && !(campaignType === 'PRODUCT_SEEDING') && (
            <ProjectPrice project={project} />
          )}
          <AlterButton
            rightElement={<Image src={shortlisted ? thumbsUpFilledImg : thumbsUpImg} size={24} />}
            type="grey"
            handleClick={handleToggleFavoriteState}
          />
        </div>
      </div>
      {requestProcced && (
        <div className={styles.decline}>
          <Text
            msg="chat.request_archivation.in_process.descr"
            type="sm"
            formatValues={{
              email: (
                <SimpleLink href={`mailto:${HELLO_EMAIL}`} target="_blank">
                  <Text
                    type="sm"
                    msg="chat.request_archivation.in_process.email"
                    className={styles.archivationLink}
                  />
                </SimpleLink>
              ),
            }}
          />
        </div>
      )}
      <ShipmentInformation project={project} />
    </div>
  );
};

export default ProjectCreatorDetails;

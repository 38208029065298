import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './GmailAuth.pcss';
import { postMessage } from './utils';

import Spinner from 'Atoms/Spinner/Spinner';
import { PostMessageTypeEnum, useShopify } from 'Hooks/useShopify';
import { ButtonHandlerTypeEnum } from 'PublicPage/OutcomePage/OutcomePage';
import { OUTCOME_PAGE_ROUTE } from 'Constants/general';
import { GmailPostMessageTypeEnum } from 'Hooks/useGmail';

const GmailAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code') || '';

    const hasError = !code;

    const sendPostMessage = () => {
      postMessage({
        error: hasError,
        type: GmailPostMessageTypeEnum.Authenticate,
        params: {
          code,
        },
      });
    };

    if (hasError) {
      const sp = new URLSearchParams();
      sp.set('status', 'error');
      sp.set('handler', ButtonHandlerTypeEnum.CloseWindow);
      sendPostMessage();
      navigate(`${OUTCOME_PAGE_ROUTE}?${sp.toString()}`);
      return;
    }

    sendPostMessage();
  }, []);

  return (
    <div className={styles.root}>
      <Spinner style={{ margin: '40px auto' }} />
    </div>
  );
};

export default GmailAuth;

import React, { useMemo } from 'react';
import find from 'lodash/find';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import styles from '../BillingHistoryFilters.pcss';
import { newAvailableTransactionTypes } from '../../data';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { Billing_TransactionType } from 'GraphTypes/TransactionContainer_billingTransactions.graphql';

interface Props {
  selectedItems?: Billing_TransactionType[];
  onTypeSelect: (list: Billing_TransactionType[] | undefined) => void;
}

const TypesFilter: React.FC<Props> = (props) => {
  const { selectedItems = [], onTypeSelect } = props;
  const intl = useIntl();

  const list = useMemo(() => {
    return newAvailableTransactionTypes
      .sort((a, b) => {
        const aMsg = intl.formatMessage({ id: a.msg });
        const bMsg = intl.formatMessage({ id: b.msg });
        return aMsg.toLowerCase().localeCompare(bMsg.toLowerCase());
      })
      .map((item) => {
        const { id, msg } = item;

        const onClick = () => {
          onTypeSelect(id ? [id] : undefined);
        };

        return {
          id,
          onClick,
          msg,
        };
      });
  }, []);

  const label = useMemo(() => {
    if (selectedItems.length > 0) {
      const currentType = find(list, (item) => {
        return item.id === selectedItems[0];
      });
      return { msg: currentType?.msg };
    }

    return { msg: 'campaigns.filter_types.title.all' };
  }, [selectedItems]);

  const isDirty = selectedItems && selectedItems.length > 0;

  return (
    <Dropdown
      value={<Text type="md" {...label} className={styles.selectorLabel} />}
      className={classnames(styles.selector, { [styles.dirty]: isDirty })}
    >
      <DropdownGroup labelMsg="campaigns.filter_types.title" className={styles.group}>
        <div className={styles.dropdownList}>
          {list?.map((item) => {
            if (!item) return null;

            const isActive =
              (!item.id && !selectedItems.length) || (item.id && selectedItems.includes(item.id))
                ? 'Check'
                : null;

            return (
              <DropdownGroupItem key={item.id} handleClick={item?.onClick}>
                <AlterButton
                  msg={item.msg}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );
};

export default TypesFilter;

import { graphql, useMutation } from 'react-relay';
import { ApplyOneTimeDiscountToSubscriptionMutation } from 'GraphTypes/ApplyOneTimeDiscountToSubscriptionMutation.graphql';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation ApplyOneTimeDiscountToSubscriptionMutation(
    $input: ApplyOneTimeDiscountToSubscriptionInput!
  ) {
    applyOneTimeDiscountToSubscription(input: $input) {
      ... on ApplyOneTimeDiscountToSubscriptionPayload {
        advertiserSubscription {
          priceAmount
          canApplyDiscount
          discountOfferAcceptedAt
          lastSchedule {
            discount {
              percentOff
            }
            trialEnd
            startDate
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const useApplyOneTimeDiscountToSubscriptionMutation: BaseMutation<
  ApplyOneTimeDiscountToSubscriptionMutation
> = (config) => {
  const [applyOneTimeDiscountToSubscription, loading] =
    useMutation<ApplyOneTimeDiscountToSubscriptionMutation>(mutation);

  const applyOneTimeDiscountToSubscriptionCaller: BaseMutationExecFn<
    ApplyOneTimeDiscountToSubscriptionMutation
  > = (execConfig) => {
    applyOneTimeDiscountToSubscription({
      ...config,
      ...execConfig,
    });
  };
  return [applyOneTimeDiscountToSubscriptionCaller, loading];
};

/**
 * @generated SignedSource<<e8e9b30a5ac7401ad93e7813a9f2d727>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "instagramPostUrl",
        "variableName": "url"
      }
    ],
    "concreteType": "InstagramOembed",
    "kind": "LinkedField",
    "name": "instagramOembed",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImagesWithFallbackQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImagesWithFallbackQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "52be602e139fbdd82b09411d054f7f52",
    "id": null,
    "metadata": {},
    "name": "ImagesWithFallbackQuery",
    "operationKind": "query",
    "text": "query ImagesWithFallbackQuery(\n  $url: String!\n) {\n  instagramOembed(instagramPostUrl: $url) {\n    thumbnailUrl\n  }\n}\n"
  }
};
})();

node.hash = "2cc04336267bd5afe2db6a993d24ddee";

module.exports = node;

import React from 'react';

import styles from './SubSection.css';

import Text from 'Atoms/Text/Text';
import InfoTooltip from 'Molecules/InfoTooltip/InfoTooltip';

interface Props {
  title: string,
  tooltipMsg?: string,
  value?: string,
  additionalValue?: string,
}

const SubSection: React.FC<Props> = props => {
  const { children, title, value, additionalValue, tooltipMsg } = props;

  const style = value ? '' : styles.container;

  return (
    <div>
      <header className={`${styles.header} ${style}`}>
        <Text
          color="grayDog"
          msg={title}
          className={styles.title}
        />
        {
          tooltipMsg && (
            <InfoTooltip
              id={title}
              tooltipMsg={tooltipMsg}
              place="bottom"
            />
          )
        }
      </header>
      <div className={styles.content}>
        {value && <Text weight="700" tag="h3" text={value} />}
        {additionalValue && <Text size="sm" weight="500" color="green" text={additionalValue} className={styles.additional} />}
        {children}
      </div>
    </div>
  );
};

export default SubSection;
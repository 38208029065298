import React, { useCallback } from 'react';
import classnames from 'classnames';

import styles from './AddPostingToThisCollaborationDrawer.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import TextPreset from 'Components/ui/TextPreset/TextPreset';

interface Props {
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
  onCreateNewCampaignButtonClick?: () => void;
}

const AddPostingToThisCollaborationDrawer: React.FC<Props> = (props) => {
  const { drawerProps, onCreateNewCampaignButtonClick } = props;

  const { createNewCampaign } = useCampaignActions();

  const handleButtonClick = useCallback(() => {
    createNewCampaign();
    onCreateNewCampaignButtonClick?.();
  }, [onCreateNewCampaignButtonClick]);

  return (
    <Drawer
      {...drawerProps}
      className={classnames(styles.drawer, drawerProps?.className)}
      rootKey="add-posting-to-this-collaboration"
    >
      <div className={styles.contentRoot}>
        <div>
          <TextPreset
            classes={{
              textContainer: styles.textContainer,
            }}
            header={
              <Text
                type="d2"
                msg="add_posting_to_this_collaboration_drawer.title"
                dataTest="drawer:addPostingToThisCollaborationDrawer:title"
              />
            }
            subtitle={
              <Text
                type="md"
                msg="add_posting_to_this_collaboration_drawer.descr"
                dataTest="drawer:addPostingToThisCollaborationDrawer:subtitle"
              />
            }
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            fluid
            color="black"
            className={styles.submitButton}
            dataTest="button:addPostingToThisCollaborationDrawer"
            msg="add_posting_to_this_collaboration_drawer.button"
            onClick={handleButtonClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default AddPostingToThisCollaborationDrawer;
export { AddPostingToThisCollaborationDrawer };

// types

type DrawerProps = React.ComponentProps<typeof Drawer>;

export type { Props as AddPostingToThisCollaborationDrawerProps };

import React from 'react';

import Icon from 'Atoms/Icon/Icon';

type IconProps = React.ComponentProps<typeof Icon>;
type GetPlatformIcon = (label?: string) => IconProps['name'] | '';
export const getPlatformIcon: GetPlatformIcon = (label) => {
  if (label === 'instagram') {
    return 'instagramColored';
  } else if (label === 'tiktok') {
    return 'tiktokColored';
  } else if (label === 'youtube') {
    return 'youtubeColored';
  } else if (label === 'snapchat') {
    return 'snapchatColored';
  } else if (label === 'facebook') {
    return 'facebookColored';
  } else if (label === 'other') {
    return 'more';
  }

  return '';
};

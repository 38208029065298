/**
 * @generated SignedSource<<2c8819b3b3cd3183cfe934b9898fb3cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CreatorSource = "OUTREACH" | "%future added value";
export type AcceptOfferInput = {
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  id?: string | null | undefined;
  offerId: string;
  price?: number | null | undefined;
  screeningQuestionAnswer?: string | null | undefined;
  screeningResponses?: ReadonlyArray<BriefScreeningQuestionResponseInput> | null | undefined;
  shippingAddressId?: string | null | undefined;
  shippingInformation?: string | null | undefined;
  shippingRecipientFirstName?: string | null | undefined;
  shippingRecipientLastName?: string | null | undefined;
  shippingRecipientPhone?: string | null | undefined;
  source?: CreatorSource | null | undefined;
};
export type BriefScreeningQuestionResponseInput = {
  answer: BriefScreeningQuestionAnswerInput;
  questionId: string;
};
export type BriefScreeningQuestionAnswerInput = {
  booleanValue?: boolean | null | undefined;
  stringValue?: string | null | undefined;
};
export type AcceptOfferMutation$variables = {
  input: AcceptOfferInput;
};
export type AcceptOfferMutation$data = {
  readonly acceptOffer: {
    readonly project: {
      readonly brief: {
        readonly paidSocialActivationDays?: number | null | undefined;
        readonly paidSocialBca?: boolean;
      } | null | undefined;
      readonly campaign: {
        readonly type: CampaignType | null | undefined;
      };
      readonly creator: {
        readonly paidSocialEnabled?: boolean;
      } | null | undefined;
      readonly id: string;
      readonly offer: {
        readonly acceptance: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
      readonly paidSocial: boolean;
    };
  } | null | undefined;
};
export type AcceptOfferMutation = {
  response: AcceptOfferMutation$data;
  variables: AcceptOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paidSocial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferAcceptance",
  "kind": "LinkedField",
  "name": "acceptance",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocialBca",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocialActivationDays",
      "storageKey": null
    }
  ],
  "type": "V2Brief",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocialEnabled",
      "storageKey": null
    }
  ],
  "type": "InstagramCreator",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptOfferPayload",
        "kind": "LinkedField",
        "name": "acceptOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Offer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brief",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptOfferPayload",
        "kind": "LinkedField",
        "name": "acceptOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Offer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brief",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a17952ba1868f9f7ca6b32b3eeeb903f",
    "id": null,
    "metadata": {},
    "name": "AcceptOfferMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptOfferMutation(\n  $input: AcceptOfferInput!\n) {\n  acceptOffer(input: $input) {\n    project {\n      id\n      campaign {\n        type\n        id\n      }\n      paidSocial\n      offer {\n        acceptance {\n          id\n        }\n        id\n      }\n      brief {\n        __typename\n        ... on V2Brief {\n          paidSocialBca\n          paidSocialActivationDays\n        }\n        id\n      }\n      creator {\n        __typename\n        ... on InstagramCreator {\n          paidSocialEnabled\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "979cd4ba69ea0a973dabe7551cc80867";

export default node;

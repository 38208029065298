import React from 'react';

import styles from './Stats.css';

import { formatValue } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { TooltipType, formatType } from 'Types/common';

interface Props {
  items?: {
    tooltipData?: TooltipType,
    value?: string | number,
    format?: formatType,
    formatData?: any,
    subtitleMsg?: string,
    width?: string,
    id: string,
  }[],
}




const Stats: React.FC<Props> = props => {
  const { items } = props;

  if (!items || items.length == 0) return null;

  return (
    <div className={styles.root}>
      {items.map(item => {
        const { id, value, format, formatData = {}, subtitleMsg, tooltipData = {}, width } = item;

        const valueText = format ? formatValue({ value, format, ...formatData }) : value;

        return (
          <div key={id} style={{ width }} className={styles.statItem}>
            <Tooltip id={id} {...tooltipData}>
              <div key={id} className={styles.statItem}>
                <Text tag="h2" weight="500" text={valueText} />
                <Text color="grayDog" size="xs" transform="uppercase" msg={subtitleMsg} />
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default Stats;
import React, { Fragment } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './UploadMediaplan.pcss';
import CreatorImportProgress from './CreatorImportProgress/CreatorImportProgress';
import UploadInput from './UploadInput/UploadInput';

import Text from 'Atoms/Text/Text';
import environment from 'Api/Environment';
import { UploadMediaplanQuery as QueryType } from 'GraphTypes/UploadMediaplanQuery.graphql';

const UploadMediaplanQuery = graphql`
  query UploadMediaplanQuery($campaignId: ID!, $archived: Boolean, $createdByAdmin: Boolean) {
    campaign(id: $campaignId) {
      id
      type
      platform
      brief {
        ... on V2Brief {
          paidSocialBca
          paidSocial
          sparkAds
        }
      }
      creatorImport {
        completed
        id
        importedCreators
        totalCreators
        ...CreatorImportProgress_creatorImport
      }
      projects(archived: $archived, orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {
        totalCount
      }
      unsentBriefs: projects(
        archived: false
        stages: [UNSENT]
        orderBy: LAST_ADDED
        createdByAdmin: $createdByAdmin
      ) {
        totalCount
      }
    }
    currentUser {
      organization {
        subscription {
          canImportMediaplan
        }
      }
    }
  }
`;

interface Props {
  campaignId: string;
  className?: string;
  disabled?: boolean;
  admin?: boolean;
  hasSubmission?: boolean;
}

const UploadMediaplan: React.FC<Props> = (props) => {
  const { className, campaignId, disabled, admin, hasSubmission } = props;

  const showCreatedByAdmin = hasSubmission ? !!admin : undefined;

  return (
    <div className={`${styles.root} ${className}`}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={UploadMediaplanQuery}
        variables={{ campaignId, archived: false, createdByAdmin: showCreatedByAdmin }}
        render={({ props: queryProps }) => {
          if (!queryProps) return null;

          const campaign = queryProps?.campaign;
          const projects = campaign?.projects;
          const creatorImport = campaign?.creatorImport;
          const creatorImportId = creatorImport?.id;
          const campaignPlatform = campaign?.platform;
          const brief = campaign?.brief;

          const ps = brief?.paidSocial;
          const psBca = brief?.paidSocialBca;
          const sparkAds = brief?.sparkAds;

          const withPartnership = ps || psBca || sparkAds;

          const totalCount = projects?.totalCount;
          const unsentTotalCount = campaign?.unsentBriefs.totalCount;

          const canImportMediaplan =
            queryProps.currentUser?.organization?.subscription?.canImportMediaplan;

          return (
            <Fragment>
              {creatorImportId && (
                <CreatorImportProgress
                  key={creatorImportId}
                  campaignId={campaignId}
                  showCreatedByAdmin={showCreatedByAdmin}
                  creatorImport={creatorImport}
                  stages={hasSubmission ? ['UNSENT'] : undefined}
                />
              )}
              <div className={styles.header}>
                <div className={styles.title}>
                  <Text tag="h3" weight="500" msg="mediaplan.header.list_title" />
                  <Text
                    tag="h3"
                    weight="500"
                    color="grayDog"
                    className={styles.count}
                    text={hasSubmission ? unsentTotalCount : totalCount}
                  />
                </div>
                <UploadInput
                  campaign={campaign}
                  campaignId={campaignId}
                  campaignPlatform={campaignPlatform}
                  disabled={disabled}
                  withPartnership={withPartnership}
                  canImportMediaplan={canImportMediaplan}
                />
              </div>
            </Fragment>
          );
        }}
      />
    </div>
  );
};

export default UploadMediaplan;

import React from 'react';

import Spinner from 'Atoms/Spinner/Spinner';
import errorImg from 'Images/icons/error.svg';
import successImg from 'Images/icons/success.svg';
import finishImg from 'Images/modals/finish.svg';
import barterImg from 'Images/modals/barter.svg';
import noMoneyImg from 'Images/modals/no_money_payment.svg';
import mailSentImg from 'Images/modals/mail_sent.svg';
import notHiredImg from 'Images/modals/not_hired.svg';
import hiredImg from 'Images/modals/hired.svg';
import productPurchaseImg from 'Images/modals/product_purchase.svg';

export const transactionFailed = {
  title: 'transaction_failed_modal.title',
  msg: 'transaction_failed_modal.descr',
  img: errorImg,
};

export const successData = {
  title: 'upload_success_modal.title',
  style: { width: '420px' },
};

export const failData = {
  title: 'fail_process_modal.title',
  msg: 'fail_process_modal.descr',
  img: errorImg,
  style: { width: '420px' },
};

export const loadingData = {
  children: <Spinner />,
  style: {
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
  },
};

export const completeCampaignData = {
  title: 'campaign_completion_modal.title',
  msg: 'campaign_completion_modal.descr',
  btnMsg: 'campaign_completion_modal.submit',
  caution: 'general.actions_cant_be_undone',
  img: finishImg,
};

export const offerRejectionData = {
  title: 'offer_rejection_modal.title',
  style: { width: '420px' },
};

export const barterInfoData = {
  title: 'barter_info_modal.title',
  msg: 'barter_info_modal.descr',
  img: barterImg,
};

export const barterAcceptionData = {
  title: 'barter_accept_modal.title',
  msg: 'barter_accept_modal.descr',
  img: noMoneyImg,
};

export const applicationSentData = {
  title: 'application_sent_modal.title',
  msg: 'application_sent_modal.descr',
  btnMsg: 'application_sent_modal.submit',
  img: mailSentImg,
};

export const statusNotHiredData = {
  title: 'project_status_not_hired_modal.title',
  msg: 'project_status_not_hired_modal.descr',
  btnMsg: 'project_status_not_hired_modal.submit',
  img: notHiredImg,
};

export const statusHiredData = {
  title: 'project_status_hired_modal.title',
  msg: 'project_status_hired_modal.descr',
  btnMsg: 'project_status_hired_modal.submit',
  img: hiredImg,
};

export const productPurchaseData = {
  title: 'product_purchase_modal.title',
  msg: 'product_purchase_modal.descr',
  img: productPurchaseImg,
};

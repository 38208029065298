import React, { useCallback, useState } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import CreatorsStub from '../CreatorsStub/CreatorsStub';

import styles from './CreatorsListContainer.pcss';
import CreatorCard from './CreatorCard/CreatorCard';

import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Text from 'Components/ui/Text/Text';
import { CreatorsListContainer_campaign$data } from 'GraphTypes/CreatorsListContainer_campaign.graphql';
import {
  ProjectOrder,
  AdvertiserProjectStage,
  CreatorBadge,
} from 'GraphTypes/CreatorsListQuery.graphql';

const COUNT = 10;

interface Props {
  relay: RelayPaginationProp;
  campaignId: string;
  organizationId?: string;
  campaign: CreatorsListContainer_campaign$data;
  isAdminView: boolean;
  shortlisted?: boolean;
  invitedByOrganizationMember?: boolean;
  outreach?: boolean;
  isLongTermCampaign?: boolean;
  orderBy?: ProjectOrder;
  stages?: AdvertiserProjectStage[];
  badges?: CreatorBadge[];
  textQuery?: string;
  archived: boolean;
  canCreateMultipleDeals: boolean;
  createdByAdmin?: boolean | null;
  creatorsStages?: AdvertiserProjectStage[];
  defaultStages?: AdvertiserProjectStage[];
  archivedStages?: AdvertiserProjectStage[];
  portfolioTypeName: string;
  portfolioCategoryName: string;
  email?: string;
  screeningQuestionIds?: string[];
  withPreviousCollaborations?: boolean;
}

const CreatorsListContainer: React.FC<Props> = (props) => {
  const {
    relay,
    campaign,
    organizationId,
    campaignId,
    isAdminView,
    creatorsStages,
    defaultStages,
    archivedStages,
    portfolioTypeName,
    portfolioCategoryName,
    canCreateMultipleDeals,
    isLongTermCampaign,
    email,
  } = props;
  const [portfolioIndex, setPortfolioIndex] = useState<number>(0);
  const projects = campaign.projects;
  const edges = projects.edges;
  const totalCount = projects.totalCount;
  const [portfolioItemState, setPortfolioItemState] = useState<number[]>(
    new Array(edges?.length).fill(0)
  );

  const handleChangePortfolioItem = useCallback<
    (portfolioIndex: number, itemIndex: number) => void
  >((portfolioIndex, itemIndex) => {
    const newState = [...portfolioItemState];
    newState[portfolioIndex] = itemIndex;
    setPortfolioItemState(newState);
  }, []);

  if (edges?.length === 0) {
    return <Text msg="new_campaign.no_creators" />;
  }

  const cards = edges?.map((item, index) => {
    if (!item?.node) return null;

    const { id } = item.node;
    const onCardHover: (cardIndex: number) => void = (cardIndex) => setPortfolioIndex(cardIndex);
    const creatorId = item?.node?.creator?.id || '';

    return (
      <CreatorCard
        project={item.node}
        isAdminView={isAdminView}
        organizationId={organizationId}
        portfolioItemIndex={portfolioItemState[index]}
        campaignId={campaignId}
        handleChangePortfolioItem={(itemIndex) => handleChangePortfolioItem(index, itemIndex)}
        key={id}
        isPortfolioOpen={index === portfolioIndex}
        onMouseEnter={() => onCardHover(index)}
        creatorId={creatorId}
        creatorsStages={creatorsStages}
        defaultStages={defaultStages}
        archivedStages={archivedStages}
        portfolioTypeName={portfolioTypeName}
        portfolioCategoryName={portfolioCategoryName}
        canCreateMultipleDeals={canCreateMultipleDeals}
        isLongTermCampaign={isLongTermCampaign}
        email={email}
      />
    );
  });

  return (
    <div>
      <div className={styles.creatorsCount}>
        <Text type="label" formatValues={{ count: totalCount }} msg="campaign.creators_count" />
      </div>
      <LoaderHandler relay={relay} count={COUNT} preloader={<CreatorsStub />}>
        <div className={styles.cards}>{cards}</div>
      </LoaderHandler>
    </div>
  );
};

export default createPaginationContainer(
  CreatorsListContainer,
  {
    campaign: graphql`
      fragment CreatorsListContainer_campaign on Campaign
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        stages: { type: "[AdvertiserProjectStage!]" }
        shortlisted: { type: "Boolean" }
        textQuery: { type: "String" }
        orderBy: { type: "ProjectOrder" }
        archived: { type: "Boolean", defaultValue: false }
        badges: { type: "[CreatorBadge!]" }
        createdByAdmin: { type: "Boolean" }
        invitedByOrganizationMember: { type: "Boolean" }
        outreach: { type: "Boolean" }
        organizationId: { type: "ID!" }
        screeningQuestionIds: { type: "[ID!]" }
        withPreviousCollaborations: { type: "Boolean" }
        draftDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
        publicationDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
      ) {
        projects(
          first: $count
          after: $cursor
          shortlisted: $shortlisted
          textQuery: $textQuery
          stages: $stages
          orderBy: $orderBy
          archived: $archived
          badges: $badges
          createdByAdmin: $createdByAdmin
          invitedByOrganizationMember: $invitedByOrganizationMember
          outreach: $outreach
          withPreviousCollaborations: $withPreviousCollaborations
          draftDeadlineIntervals: $draftDeadlineIntervals
          publicationDeadlineIntervals: $publicationDeadlineIntervals
          screeningQuestionIds: $screeningQuestionIds
        ) @connection(key: "Projects_projects", filters: []) {
          totalCount
          edges {
            node {
              id
              creator {
                id
              }
              ...CreatorCard_project @arguments(organizationId: $organizationId)
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.campaign && props.campaign.projects;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        campaignId: props.campaignId,
        orderBy: props.orderBy,
        shortlisted: props.shortlisted,
        textQuery: props.textQuery,
        stages: props.stages,
        badges: props.badges,
        archived: props.archived,
        createdByAdmin: props.createdByAdmin,
        portfolioTypeName: props.portfolioTypeName,
        portfolioCategoryName: props.portfolioCategoryName,
        invitedByOrganizationMember: props.invitedByOrganizationMember,
        outreach: props.outreach,
        organizationId: props.organizationId,
        withPreviousCollaborations: props.withPreviousCollaborations,
        screeningQuestionIds: props.screeningQuestionIds,
        publicationDeadlineIntervals: props.publicationDeadlineIntervals,
        draftDeadlineIntervals: props.draftDeadlineIntervals,
      };
    },
    query: graphql`
      query CreatorsListContainerPaginationQuery(
        $campaignId: ID!
        $organizationId: ID!
        $count: Int!
        $cursor: String
        $stages: [AdvertiserProjectStage!]
        $shortlisted: Boolean
        $orderBy: ProjectOrder
        $textQuery: String
        $archived: Boolean
        $invitedByOrganizationMember: Boolean
        $outreach: Boolean
        $withPreviousCollaborations: Boolean
        $badges: [CreatorBadge!]
        $screeningQuestionIds: [ID!]
        $publicationDeadlineIntervals: [DateInterval!]
        $draftDeadlineIntervals: [DateInterval!]
      ) {
        campaign(id: $campaignId) {
          ...CreatorsListContainer_campaign
            @arguments(
              count: $count
              cursor: $cursor
              stages: $stages
              orderBy: $orderBy
              shortlisted: $shortlisted
              textQuery: $textQuery
              archived: $archived
              badges: $badges
              organizationId: $organizationId
              invitedByOrganizationMember: $invitedByOrganizationMember
              outreach: $outreach
              withPreviousCollaborations: $withPreviousCollaborations
              screeningQuestionIds: $screeningQuestionIds
              publicationDeadlineIntervals: $publicationDeadlineIntervals
              draftDeadlineIntervals: $draftDeadlineIntervals
            )
        }
      }
    `,
  }
);

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ProcessWithdrawalInput,
  ProcessWithdrawalMutation$data,
  ProcessWithdrawalMutation,
} from 'GraphTypes/ProcessWithdrawalMutation.graphql';

const mutation = graphql`
  mutation ProcessWithdrawalMutation($input: ProcessWithdrawalInput!) {
    processWithdrawal(input: $input) {
      withdrawal {
        id
        status
        statusDate
        paymentServiceProvider
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ProcessWithdrawalInput,
  resolve?: (response: ProcessWithdrawalMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const variables = {
    input: {
      id,
      ...data,
    },
  };

  commitMutation<ProcessWithdrawalMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

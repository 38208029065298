import styles from './TransactionContainer.pcss';

export const headerItems = [
  {
    title: 'billing_history.table.history.date',
    className: styles.dateItem,
  },
  {
    title: 'billing_history.table.history.type',
    className: styles.typeItem,
  },
  {
    title: 'billing_history.table.history.brand',
    className: styles.brandItem,
  },
  {
    title: 'billing_history.table.history.campaign',
    className: styles.campaignType,
  },
  {
    title: 'billing_history.table.history.amount',
    className: styles.amountItem,
  },
]
/**
 * @generated SignedSource<<b8a374af669fc6f791348da5fd0a34f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type CreateReimbursementInput = {
  amount: number;
  clientMutationId?: string | null | undefined;
  currency: Currency;
  id?: string | null | undefined;
  projectId: string;
};
export type CreateReimbursementMutation$variables = {
  input: CreateReimbursementInput;
};
export type CreateReimbursementMutation$data = {
  readonly createReimbursement: {
    readonly __typename: string;
  } | null | undefined;
};
export type CreateReimbursementMutation = {
  response: CreateReimbursementMutation$data;
  variables: CreateReimbursementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createReimbursement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateReimbursementMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateReimbursementMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d02ab00632b19a56ff2dc16f15ba2369",
    "id": null,
    "metadata": {},
    "name": "CreateReimbursementMutation",
    "operationKind": "mutation",
    "text": "mutation CreateReimbursementMutation(\n  $input: CreateReimbursementInput!\n) {\n  createReimbursement(input: $input) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd2b5f6303d0497d38e3e4e20af0f068";

export default node;

import { createSum } from 'Util/numberFormatter';
import { RUB } from 'Constants/general';
import { Currency } from 'GraphTypes/OnboardingByAdvertiser_project.graphql';

export const getOnboardingData = (price: number, balance: number, currency: Currency | null) => {
  const diff = price - balance;
  const diffText = createSum(diff, currency);
  const minAmount = currency === RUB ? 3000 : 50;
  const minAmountPrice = createSum(minAmount, currency);
  const finalAmount = diff < minAmount ? minAmount : diff;
  const finalAmountText = createSum(finalAmount, currency);

  return {
    diff,
    diffText,
    minAmount,
    minAmountPrice,
    finalAmount,
    finalAmountText,
  };
};

import React, { useEffect, useMemo, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import styles from './ContentModeration.pcss';

import { ContentModerationQuery as QueryType } from 'GraphTypes/ContentModerationQuery.graphql';
import markInsenseContentReviewAsPassed from 'Mutations/MarkInsenseContentReviewAsPassed.Mutation';
import markInsenseContentReviewAsFailed from 'Mutations/MarkInsenseContentReviewAsFailed.Mutation';
import Text from 'Components/ui/Text/Text';
import ImagesWithFallback from 'Components/ImagesWithFallback/ImagesWithFallback';
import Button from 'Atoms/Button/Button';
import Drawer from 'Components/ui/Drawer/Drawer';
import NewButton from 'Components/ui/Button/Button';
import ErrorHandler from 'Util/errorHandler';
import Textarea from 'Components/ui/Textarea/Textarea';

const ContentModerationQuery = graphql`
  query ContentModerationQuery($projectId: ID!) {
    project(id: $projectId) {
      insenseContentReview {
        id
        status
        attachments {
          edges {
            node {
              id
              file {
                id
                type
                height
                width
                secureUrl
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
                ... on Video {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  projectId: string;
}

const ContentModeration: React.FC<Props> = (props) => {
  const { className, projectId } = props;

  const contentResult = useLazyLoadQuery<QueryType>(ContentModerationQuery, { projectId });

  const items = contentResult.project?.insenseContentReview?.attachments;

  const sendModerationResult = () => {
    setLoadingStatus(false);
    markInsenseContentReviewAsFailed(
      { projectId, text: feedback },
      () => {
        setDeclineDrawerStatus(false);
      },
      (e) => {
        setDeclineDrawerStatus(false);
        ErrorHandler.error('Mutation markInsenseContentReviewAsFailed got error', { ...e });
      }
    );
  };

  const [openedDeclineDrawer, setDeclineDrawerStatus] = useState(false);
  const [loading, setLoadingStatus] = useState(false);
  const [feedback, setFeedbackText] = useState('');

  const closeDeclineDrawer = () => {
    setDeclineDrawerStatus(false);
  };

  const declineContent = () => {
    setDeclineDrawerStatus(true);
  };

  const approveContent = () => {
    setLoadingStatus(true);
    markInsenseContentReviewAsPassed(
      { projectId },
      () => {
        setDeclineDrawerStatus(false);
      },
      (e) => {
        setDeclineDrawerStatus(false);
        ErrorHandler.error('Mutation markInsenseContentReviewAsPassed got error', { ...e });
      }
    );
  };

  useEffect(() => {
    setLoadingStatus(openedDeclineDrawer);
  }, [openedDeclineDrawer]);

  const medias = useMemo(() => {
    if (items?.edges?.length) {
      return items?.edges.map((item) => {
        if (item?.node?.file.type === 'VIDEO') {
          return {
            ...item.node.file,
            thumbnailUrl: item.node.file.transformations?.collageThumbnailUrl,
            videoUrl: item.node.file.secureUrl,
          };
        }
        return {
          ...item?.node?.file,
          thumbnailUrl: item?.node?.file?.transformations?.collageThumbnailUrl,
        };
      });
    }
    return [];
  }, [items?.edges]);

  if (!contentResult || contentResult.project?.insenseContentReview?.status === 'SUCCESS') {
    return null;
  }

  return (
    <div className={className}>
      <Text msg="project.content_moderation.title" className={styles.title} />
      {medias.length > 0 && contentResult.project?.insenseContentReview?.status === 'PENDING' ? (
        <>
          <div className={styles.attachmentsContainer}>
            <ImagesWithFallback className={styles.media} items={medias} fullScreen videoIcon />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              width="full"
              color="normal"
              msg="project.content_moderation.decline"
              onClick={declineContent}
              loading={loading}
            />
            <Button
              width="full"
              color="primary"
              msg="project.content_moderation.approve"
              onClick={approveContent}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <Text color="grey" type="sm" msg="project.content_moderation.empty" />
      )}
      <Drawer
        opened={openedDeclineDrawer}
        onClose={closeDeclineDrawer}
        rootKey={'decline-content'}
        className={styles.drawer}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.drawerTitle}>
            <Text
              type={'d2'}
              msg={'project.content_moderation.decline_modal.title'}
              className={styles.drawerTitleText}
            />
            <Text
              type="md"
              className={styles.description}
              msg={'project.content_moderation.decline_modal.description'}
            />
            <Textarea
              className={styles.feedbackInput}
              handleChange={setFeedbackText}
              bordered
              placeholderMsg="project.content_moderation.decline_modal.placeholder"
            />
          </div>
          <NewButton
            color="black"
            disabled={!feedback?.trim()?.length}
            msg={'project.content_moderation.decline_modal.button'}
            onClick={sendModerationResult}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default ContentModeration;

import { createContext } from 'react';

import type { HubSpotProperties } from 'Analytics/engines/Hubspot';

const SignUpCompleteContext = createContext<SignUpCompleteContextType>(
  {} as SignUpCompleteContextType
);

enum StepEnum {
  UserDetails = 'UserDetails',
  CompanyType = 'CompanyType',
  CompanyIndustry = 'CompanyIndustry',
  AnnualRevenue = 'AnnualRevenue',
  HowDidYouKnow = 'HowDidYouKnow',
}

enum SignUpType {
  Standard = 'Standard',
  InviteTeammate = 'InviteTeammate',
  Survey = 'Survey',
}

export { SignUpCompleteContext, StepEnum, SignUpType };

// types

type SignUpCompleteContextType = {
  signUpType: SignUpType;
  activeStepId: StepEnum;
  activeStepIndex: number;
  totalSteps: number;
  activeStep: StepsArrayItem;
  steps: StepArrays;
  isFinalStep: boolean;
  isFirstStep: boolean;
  goToNextStep: () => boolean;
  goToPrevStep: () => boolean;
  setUserData: SetUserData;
  userData: UserData;
  validity: Record<
    SignUpType,
    Partial<Record<StepEnum, { isValid: boolean; fields: Partial<Record<string, boolean>> }>>
  >;
  handleFinishSignUp: () => Promise<void>;
  isCurrentStepValid: boolean;
  isSubmitLoading: boolean;
};

type UserData = {
  [StepEnum.UserDetails]: {
    firstName: {
      value: string;
      hubSpotValue: HubSpotProperties['firstName'];
    };
    lastName: {
      value: string;
      hubSpotValue: HubSpotProperties['lastName'];
    };
    organizationName: {
      value: string;
      hubSpotValue: HubSpotProperties['company'];
    };
    websiteUrl: {
      value: string;
      hubSpotValue: HubSpotProperties['website'];
    };
    companyRole: {
      value: string;
      hubSpotValue: HubSpotProperties['company_role_new'];
    };
    organizationId: {
      value: string;
      hubSpotValue: string;
    };
    email: {
      value: string;
      hubSpotValue: HubSpotProperties['email'];
    };
    ppAndTos: {
      value: boolean;
    };
    refundPolicy: {
      value: boolean;
    };
  };
  [StepEnum.CompanyType]: {
    companyType: {
      value: string;
      hubSpotValue: HubSpotProperties['type_of_business'];
    };
  };
  [StepEnum.AnnualRevenue]: {
    annualRevenue: {
      value: string;
      hubSpotValue: HubSpotProperties['annual_revenue'];
    };
  };
  [StepEnum.HowDidYouKnow]: {
    howDidYouKnow: {
      value: string;
      hubSpotValue: HubSpotProperties['received_recommendation'];
    };
  };
  [StepEnum.CompanyIndustry]: {
    industry: {
      value: string;
      hubSpotValue: HubSpotProperties['industry_custom_'];
    };
  };
};

type SetUserData = (
  ...args: [StepEnum] extends [infer K]
    ? K extends StepEnum
      ? [K, Partial<SignUpCompleteContextType['userData'][K]>]
      : never
    : never
) => void;

type StepsArrayItem = {
  id: StepEnum;
  theme: PageTheme;
};

type StepArrays = {
  [SignUpType.Standard]: StepsArrayItem[];
  [SignUpType.InviteTeammate]: StepsArrayItem[];
  [SignUpType.Survey]: StepsArrayItem[];
};

type PageTheme = 'pink' | 'sun' | 'purple' | 'sky';

export type { SignUpCompleteContextType, StepArrays, StepsArrayItem, UserData };

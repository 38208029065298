import React from 'react';

import styles from './PaidSocialLicenses.pcss';
import PaidSocialLicenseList from './PaidSocialLicenseList/PaidSocialLicenseList';

import Page from 'Templates/Page/Page';

const PaidSocialLicenses = () => {
  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <PaidSocialLicenseList />
      </div>
    </Page>
  );
};

export default PaidSocialLicenses;

import React, { Component } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';

import { items } from './data.js';

import { formatValue } from 'Util/dataFormatter';
import Table from 'Containers/TableContainer/TableContainer';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Stats from 'Atoms/Stats/Stats';

const COUNT = 20;

class AdvertisersList extends Component {
  computeDataValue(value, format, additional = {}) {
    return value ? formatValue({ value, format, ...additional }) : '—';
  }

  createListData() {
    const { organizations: { organizations } } = this.props;

    return organizations.edges.map(item => {
      const {
        id,
        name,
        subscription,
        currency,
        paymentAccount,
      } = item.node;
      const balance = paymentAccount?.balance || 0;
      const subscriptionData = subscription ? subscription : { planId: null, endsAt: null };
      const { planId, endsAt } = subscriptionData;
      const advertiserLink = `/admin/organizations/${id}`;

      return {
        name,
        advertiserLink,
        subscriptionName: planId ? `plan.admin_name.${planId.toLowerCase()}` : '—',
        subscriptionEndsAt: this.computeDataValue(endsAt, 'date'),
        balance: this.computeDataValue(balance, 'currency', { currency }),
      };
    });
  }

  render() {
    const { relay, organizations: { organizations: { totalCount } } } = this.props;
    const list = this.createListData();

    return (
      <LoaderHandler
        relay={relay}
        count={COUNT}
      >
        <div style={{ margin: '20px' }}>
          <Stats
            items={[
              {
                subtitleMsg: 'summary.total_advertisers',
                id: 'totalCount',
                format: 'number',
                value: totalCount,
              }
            ]}
          />
        </div>
        <Table
          list={list}
          data={items}
        />
      </LoaderHandler>
    );
  }
}

export default createPaginationContainer(
  AdvertisersList,
  {
    organizations: graphql`
      fragment AdvertisersList_organizations on Query
      @argumentDefinitions(
        count: {type: "Int", defaultValue: 20}
        cursor: {type: "String"}
        textQuery: {type: "String"}
        paying: {type: "Boolean"}
        planIds: {type: "[PlanId!]"}
        currencies: {type: "[Currency!]"}
      ) {
        organizations(
          first: $count
          after: $cursor
          textQuery: $textQuery
          paying: $paying
          planIds: $planIds
          currencies: $currencies
        ) @connection(key: "Advertiser_organizations", filters: []) {
          edges {
            node {
              id
              name
              subscription {
                planId
                endsAt
              }
              currency
              paymentAccount {
                balance
              }
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organizations && props.organizations.organizations;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,

      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        textQuery: props.textQuery,
        paying: props.paying,
        planIds: props.planIds,
        currencies: props.currencies,
      };
    },
    query: graphql`
      query AdvertisersListPaginationQuery(
        $count: Int!
        $cursor: String
        $textQuery: String
        $paying: Boolean
        $planIds: [PlanId!]
        $currencies: [Currency!]
      ) {
        ...AdvertisersList_organizations @arguments(
          count: $count,
          cursor: $cursor,
          textQuery: $textQuery,
          paying: $paying,
          planIds: $planIds,
          currencies: $currencies,
        )
      }
    `
  }
);
// MODAL NAMES

export const CREATIVE_PREVIEW = 'creativePreview';
export const DUPLICATE_CAMPAIGN = 'duplicateCampaign';
export const FAILED_REVIEW_REASON = 'failedReviewReason';
export const MEDIA = 'media';
export const MESSAGE = 'message';
export const PAYMENT = 'payment';
export const SNAPCHAT = 'snapchat';
export const UPLOAD_MEDIAPLAN_FAIL = 'uploadMediaplanFail';
export const YOUTUBE_CHANNELS = 'youtubeChannels';

export default {
  CREATIVE_PREVIEW,
  DUPLICATE_CAMPAIGN,
  FAILED_REVIEW_REASON,
  MEDIA,
  MESSAGE,
  PAYMENT,
  SNAPCHAT,
  UPLOAD_MEDIAPLAN_FAIL,
  YOUTUBE_CHANNELS,
};

import remove from 'lodash/remove';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';

import { createNumber, createSum, createShortNumber, createPercent } from 'Util/numberFormatter';
import { convertStringToDate, dateChatFormat, dateStringToNewYorkDate } from 'Util/dateCreator';
import { createPlanName, createPlanMsg } from 'Util/textFormatter.ts';

export const computeCPE = (likes, price) => {
  return likes ? price / likes : 0;
};

const COUNTRIES = ['United States', 'United Kingdom', 'Canada'];
const COUNTRIES_FOR_RU = [...COUNTRIES, 'Russia'];

export const sortCountries = (list, withGrouping = false) => {
  const newList = [...list];
  const locale = ls.get('locale');
  const countries = locale === 'ru' ? [...COUNTRIES_FOR_RU] : [...COUNTRIES];
  const sampleCountries = [...countries];
  const filteredList = remove(newList, (item) => {
    const name = item.attributes ? item.attributes.name : item.name;
    for (let i = 0; i < sampleCountries.length; i++) {
      if (name === sampleCountries[i]) {
        sampleCountries.splice(i, 1);

        return true;
      }
    }

    return false;
  });
  const topCountries = [];
  for (let y = 0; y < countries.length; y++) {
    for (let z = 0; z < filteredList.length; z++) {
      const item = filteredList[z];
      const name = item.attributes ? item.attributes.name : item.name;
      if (name === countries[y]) {
        topCountries.push(filteredList[z]);
      }
    }
  }
  if (withGrouping) {
    return [[...topCountries], [...newList]];
  }

  return [...topCountries, ...newList];
};

export const createContriesList = (list) => {
  const sortedList = sortCountries(list);
  const newList = [];
  for (let index = 0; index < sortedList.length; index++) {
    const { name, id, iso2Code } = sortedList[index];
    newList.push({ label: name, value: id, iso: iso2Code, type: 'country' });
  }

  return newList;
};

export const createList = (list, type) => {
  const newList = [];
  for (let index = 0; index < list.length; index++) {
    const { name, id } = list[index];
    newList.push({ label: name, value: id, type });
  }

  return newList;
};

export const formatValue = (data) => {
  const { format, value, place = 0 } = data;

  switch (format) {
    case 'number':
      return createNumber(value);
    case 'shortNumber':
      return createShortNumber(value);
    case 'currency':
      return createSum(value, data.currency, place, '0,0.[00]');
    case 'percent':
      return createPercent(value);
    case 'simplePercent':
      return `${value}%`;
    case 'date':
      return convertStringToDate(value);
    case 'new-york-date':
      return dateStringToNewYorkDate(value);
    case 'list':
      return value.join(', ');
    case 'plan':
      return createPlanName(value);
    case 'planMsg':
      return createPlanMsg(value);
    default:
      return value;
  }
};

export const createMediasList = (medias) => {
  if (!medias) return [];

  return medias.edges.map((item) => {
    const { id, shortcode, thumbnailUrl, standardResUrl, instagramUrl, videoUrl } = item.node;

    return { id, thumbnailUrl, videoUrl, url: standardResUrl, instagramUrl, type: 'IMAGE' };
  });
};

export const createPortfolioList = (portfolioItems) => {
  if (!portfolioItems) return [];

  return portfolioItems.edges.map((item) => {
    const { id, transformations, thumbnailUrl, type } = item.node.file;

    const isVideo = type === 'VIDEO';
    const name = isVideo ? 'videoUrl' : 'url';
    const finalUrl = isVideo ? transformations.autoUrl : thumbnailUrl;

    return {
      id,
      thumbnailUrl,
      [name]: finalUrl,
      type,
    };
  });
};

export const createGroupByDate = (items, types) => {
  const list = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i].node;
    const showState = types ? includes(types, item.type) : true;
    if (showState) {
      const date = dateChatFormat(item.createdAt);
      const index = findIndex(list, (item) => {
        return item[date];
      });
      if (index !== -1) {
        list[index][date].push(item);
      } else {
        list.push({ [date]: [item] });
      }
    }
  }

  return list;
};

const fileNameLimit = 20;
const hiddenSymbolsToken = '…';

export const getFileName = (url, length = fileNameLimit) => {
  const parts = url.split('/');
  const fileName = parts[parts.length - 1];

  if (fileName.length <= length) return fileName;

  const partLength = length / 2;
  const indexEnd = fileName.length;

  const prefix = fileName.substring(0, partLength);
  const postfix = fileName.substring(indexEnd - partLength, indexEnd);

  return `${prefix}${hiddenSymbolsToken}${postfix}`;
};

export const getAvgRateText = (rate) => {
  const locale = ls.get('locale');
  const isRu = locale === 'ru';
  if (!rate) return '-';

  if (rate < 1.8) {
    return isRu ? 'Ужасно' : 'Terrible';
  } else if (rate < 2.6) {
    return isRu ? 'Плохо' : 'Bad';
  } else if (rate < 3.8) {
    return isRu ? 'Нормально' : 'Okay';
  } else if (rate < 4.6) {
    return isRu ? 'Хорошо' : 'Good';
  }

  return isRu ? 'Отлично' : 'Great';
};

export const xorObjectById = (list, id) => {
  const newList = [...list];
  const index = findIndex(newList, (listItem) => {
    return listItem.id === id;
  });
  if (index !== -1) {
    newList.splice(index, 1);
  } else {
    newList.push(item);
  }

  return newList;
};

export const createCountriesList = (countries) => {
  if (!countries || countries.length === 0) return null;

  const list = countries.map((item) => {
    return item.name;
  });

  return list.join(', ');
};

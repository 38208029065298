/**
 * @generated SignedSource<<9868cea346c9ee0c82f105e482ce2bb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type CampaignSummaryUpdatedSubscription$variables = Record<PropertyKey, never>;
export type CampaignSummaryUpdatedSubscription$data = {
  readonly campaignSummaryUpdated: {
    readonly projectsSummary: {
      readonly summary: {
        readonly currency: Currency | null | undefined;
        readonly totalPrice: number | null | undefined;
      } | null | undefined;
      readonly totalCount: number;
    };
  };
};
export type CampaignSummaryUpdatedSubscription = {
  response: CampaignSummaryUpdatedSubscription$data;
  variables: CampaignSummaryUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "projectsSummary",
  "args": [
    {
      "kind": "Literal",
      "name": "archived",
      "value": false
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "LAST_ADDED"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnectionSummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projects(archived:false,orderBy:\"LAST_ADDED\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignSummaryUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaignSummaryUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CampaignSummaryUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaignSummaryUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a7c64676b2e5d73af25e7192df11ce6",
    "id": null,
    "metadata": {},
    "name": "CampaignSummaryUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription CampaignSummaryUpdatedSubscription {\n  campaignSummaryUpdated {\n    projectsSummary: projects(archived: false, orderBy: LAST_ADDED) {\n      totalCount\n      summary {\n        currency\n        totalPrice\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8e0622efda7ee8f08d24611e9dae573";

export default node;

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  MarkCreatorSearchResultAsViewedInput,
  MarkCreatorSearchResultAsViewedMutation$data,
  MarkCreatorSearchResultAsViewedMutation,
} from 'GraphTypes/MarkCreatorSearchResultAsViewedMutation.graphql';

const mutation = graphql`
  mutation MarkCreatorSearchResultAsViewedMutation($input: MarkCreatorSearchResultAsViewedInput!) {
    markCreatorSearchResultAsViewed(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: MarkCreatorSearchResultAsViewedInput,
  resolve?: (response: MarkCreatorSearchResultAsViewedMutation$data) => void,
  reject?: fnType
) => {
  const { campaignId, creatorId } = data;

  const variables = {
    input: {
      creatorId,
      campaignId,
    },
  };

  commitMutation<MarkCreatorSearchResultAsViewedMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const campaign = store.get(campaignId);
      if (!campaign) return;
      const results = ConnectionHandler.getConnection(campaign, 'SearchResults_searchCreators');
      if (results) {
        const edges = results.getLinkedRecords('edges');
        const nodes = edges?.map((edge) => edge.getLinkedRecord('node'));
        const node = nodes?.find(
          (node) => node?.getLinkedRecord('creator')?.getValue('id') === creatorId
        );
        if (node) {
          node.setValue(true, 'viewed');
        }
      }
      const contentCreators = ConnectionHandler.getConnection(
        campaign,
        'ContentCreators_searchCreators'
      );
      if (contentCreators) {
        const edges = contentCreators.getLinkedRecords('edges');
        const nodes = edges?.map((edge) => edge.getLinkedRecord('node'));
        const node = nodes?.find(
          (node) => node?.getLinkedRecord('creator')?.getValue('id') === creatorId
        );
        if (node) {
          node.setValue(true, 'viewed');
        }
      }
    },
    updater: (store) => {
      const campaign = store.get(campaignId);
      if (!campaign) return;
      const results = ConnectionHandler.getConnection(campaign, 'SearchResults_searchCreators');
      if (results) {
        const edges = results.getLinkedRecords('edges');
        const nodes = edges?.map((edge) => edge.getLinkedRecord('node'));
        const node = nodes?.find(
          (node) => node?.getLinkedRecord('creator')?.getValue('id') === creatorId
        );
        if (node) {
          node.setValue(true, 'viewed');
        }
      }
      const contentCreators = ConnectionHandler.getConnection(
        campaign,
        'ContentCreators_searchCreators'
      );
      if (contentCreators) {
        const edges = contentCreators.getLinkedRecords('edges');
        const nodes = edges?.map((edge) => edge.getLinkedRecord('node'));
        const node = nodes?.find(
          (node) => node?.getLinkedRecord('creator')?.getValue('id') === creatorId
        );
        if (node) {
          node.setValue(true, 'viewed');
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

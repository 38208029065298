import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { togglePreloader } from 'Actions/UI.Actions';
import { toggleModal } from 'Actions/Modal.Actions';
import { identifyUser, trackPage } from 'Analytics/engines/Hubspot';
import ls from 'Util/localStorage';
import { StoreState } from 'Types/reducers';
import { ADVERTISER } from 'Constants/general';

const LocationHandler: React.FC = () => {
  const dispatch = useDispatch();

  const modal = useSelector((state: StoreState) => state.root.modal);

  useEffect(() => {
    const email = ls.get('email');
    const userType = ls.get('userType');

    if (email && userType === ADVERTISER) {
      identifyUser({ email });
      trackPage(location.pathname);
    }

    if (!window.unblock) {
      dispatch(togglePreloader(false));
      if (modal.opened && !modal.forceClose) {
        dispatch(toggleModal());
      }
    }
  }, []);

  return null;
};

export default LocationHandler;

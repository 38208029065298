import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateOrganizationInput,
  CreateOrganizationMutation$data,
  CreateOrganizationMutation,
} from 'GraphTypes/CreateOrganizationMutation.graphql';

const mutation = graphql`
  mutation CreateOrganizationMutation($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

interface Data {
  currency?: string;
}

export default (
  data: CreateOrganizationInput & Data,
  resolve?: (response: CreateOrganizationMutation$data) => void,
  reject?: fnType
) => {
  const {
    name,
    currency,
    advertisingCountryId,
    websiteUrl,
    businessDescription,
    verticals,
    source,
    requestedDemo,
    industry,
    size,
    referralToken,
    tapfiliateReferralCode,
  } = data;

  const id = data.id || uuid();
  const variables = {
    input: {
      id,
      name,
      advertisingCountryId,
      websiteUrl,
      businessDescription,
      verticals,
      source,
      requestedDemo,
      size,
      industry,
      referralToken,
      tapfiliateReferralCode,
    },
  };

  commitMutation<CreateOrganizationMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      // const root = store.get('client:root');
      // const user = root?.getLinkedRecord('currentUser');
      //
      // if (user) {
      //   const organization = store.create(id, 'Organization');
      //   organization.setValue(id, 'id');
      //   organization.setValue(currency, 'currency');
      //   user.setLinkedRecord(organization, 'organization');
      // }
    },
    onCompleted: (response, errors) => {
      if (errors) {
        reject?.(errors);
        return;
      }
      resolve?.(response);
    },
    onError: (error) => {
      reject?.(error);
    },
  });
};

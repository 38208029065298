import React, { useState, useEffect, useCallback } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';
import debounce from 'lodash/debounce';

import styles from './AdminCampaigns.pcss';
import AdminCampaignList from './AdminCampaignList/AdminCampaignList';

import { amplitude } from 'Helpers/amplitude';
import environment from 'Api/Environment';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import Spinner from 'Atoms/Spinner/Spinner';

const AdminCampaignsQuery = graphql`
  query AdminCampaignsQuery($textQuery: String) {
    ...AdminCampaignList_monitoredCampaigns @arguments(textQuery: $textQuery)
  }
`;

const AdminCampaigns = () => {
  const [textQuery, setTextQuery] = useState('');

  useEffect(() => {
    amplitude.sendEvent({
      id: '384',
      category: 'admin_campaigns',
      name: 'pageview',
      param: undefined,
    });
  }, []);

  const handleSearchInputChange = useCallback(
    debounce((value) => {
      setTextQuery(value);
    }, 500),
    []
  );

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text type="d2" msg="admin_campaigns.title" className={styles.title} />
        <ProgressiveInput
          type="input"
          inputProps={{
            value: textQuery,
            placeholderMsg: 'admin_campaigns.search_placeholder',
            handleChange: handleSearchInputChange,
            rightIcon: 'Search-loop',
          }}
          className={styles.input}
          isDirty={!!textQuery}
        />
        <QueryRenderer
          environment={environment}
          query={AdminCampaignsQuery}
          variables={{ textQuery }}
          render={({ props: queryProps }) => {
            if (!queryProps) {
              return (
                <div className={styles.preloaderWrap}>
                  <Spinner className={styles.preloader} />
                </div>
              );
            }
            return <AdminCampaignList textQuery={textQuery} monitoredCampaigns={queryProps} />;
          }}
        />
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'admin_campaigns',
  },
  { dispatchOnMount: true }
)(AdminCampaigns);

import React, { useState, useEffect, useRef, cloneElement } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './DropDown.css';

import modsClasses from 'Util/modsClasses.js';
import MenuItem from 'Atoms/DropDownMenuItem/DropDownMenuItem';
import Menu from 'Atoms/DropDownMenu/DropDownMenu';
import TextSymbol from 'Atoms/TextSymbol/TextSymbol';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { DropdownType } from 'Types/common';
const MODS = ['position'];

type TextSymbolProps = React.ComponentProps<typeof TextSymbol>;

interface Props {
  className?: string;
}

const DropDown: React.FC<Props & DropdownType> = (props) => {
  const {
    selfClose,
    autoHeight,
    anchor,
    content,
    list = [],
    customContainer,
    menuClassName,
    opened,
    canControlOpenStatus,
    disabled,
  } = props;

  const [open, setOpenStatus] = useState(false);
  const dropdownEl = useRef<HTMLInputElement>(null);

  const hanldleDocumentClick = (event: MouseEvent) => {
    const dropdown = dropdownEl.current;
    if (!dropdown) return;
    const element = event.target as HTMLElement;
    const isClickInside = dropdown.contains(element);
    if ((!isClickInside || selfClose) && !canControlOpenStatus) {
      setOpenStatus(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', hanldleDocumentClick);
    } else {
      document.removeEventListener('click', hanldleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', hanldleDocumentClick);
    };
  });

  useEffect(() => {
    if (opened === false) {
      setOpenStatus(false);
    }
  }, [opened]);

  const createList = list.map((item) => {
    const {
      id,
      link,
      className,
      linkData,
      innerLink,
      textData,
      tooltipData,
      isDisabled,
      wrap,
      onClick,
      component,
      icon,
      contentClassName,
    } = item;

    const textParams: TextSymbolProps = {
      ...textData,
      textData: { ...textData.textData, color: isDisabled ? 'grayDog' : undefined },
    };

    const menuItem = (
      <MenuItem
        key={id}
        className={className}
        tooltipData={tooltipData}
        onClick={onClick}
        isDisabled={isDisabled}
        icon={icon}
        contentClassName={contentClassName}
      >
        <TextSymbol {...textParams} />
      </MenuItem>
    );

    if (component) {
      return cloneElement(component as any, {}, menuItem);
    }

    if (innerLink) {
      return (
        <Link to={innerLink} key={id} className={styles.menuItem}>
          {menuItem}
        </Link>
      );
    } else if (link) {
      return (
        <SimpleLink key={id} href={link} display="block" target="_blank" {...linkData}>
          {menuItem}
        </SimpleLink>
      );
    } else if (wrap) {
      return React.cloneElement(wrap, { children: menuItem, key: id });
    }

    return menuItem;
  });

  const containerEl = customContainer ? (
    customContainer
  ) : (
    <Menu autoHeight={autoHeight} className={menuClassName}>
      {content}
      {createList}
    </Menu>
  );

  const onToggleOpenStatus = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    setOpenStatus(!open);
  };

  const classes = modsClasses(MODS, props, styles);

  return (
    <div ref={dropdownEl} className={`${classes} ${styles.root}`}>
      <div className={styles.anchor} onClick={onToggleOpenStatus}>
        {typeof anchor === 'function' ? anchor({ open }) : anchor}
      </div>
      <div className={classnames(styles.menu, { [styles.open]: open })}>{containerEl}</div>
    </div>
  );
};

DropDown.defaultProps = {
  position: 'bottomRight',
} as Partial<DropdownType>;

export default DropDown;

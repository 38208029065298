import { UseMutationConfig, graphql, useMutation } from 'react-relay';

import { RefreshCreatorInfoMutation } from 'GraphTypes/RefreshCreatorInfoMutation.graphql';

const refreshCreatorInfoMutation = graphql`
  mutation RefreshCreatorInfoMutation($input: RefreshCreatorInfoInput!) {
    refreshCreatorInfo(input: $input) {
      __typename
      ... on NotFoundError {
        message
        type
      }
      ... on RefreshCreatorInfo_NoInsightsAuthorizationError {
        message
      }
      ... on ValidationError {
        message
      }
      ... on RefreshCreatorInfoPayload {
        creator {
          id
        }
      }
    }
  }
`;

export const useRefreshCreatorInfoMutation = (
  config: UseMutationConfig<RefreshCreatorInfoMutation>
): [() => void, boolean] => {
  const [refreshCreatorInfo, loading] = useMutation<RefreshCreatorInfoMutation>(
    refreshCreatorInfoMutation
  );

  const refreshCreatorInfoCaller = () => {
    refreshCreatorInfo({
      ...config,
    });
  };
  return [refreshCreatorInfoCaller, loading];
};

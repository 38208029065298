import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateCampaignInput,
  CreateCampaignMutation$data,
  CreateCampaignMutation,
} from 'GraphTypes/CreateCampaignMutation.graphql';

const mutation = graphql`
  mutation CreateCampaignMutation($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      campaign {
        id
        private
        description
        brief {
          id
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateCampaignInput,
  resolve?: (response: CreateCampaignMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const {
    paymentType,
    type,
    private: privateValue,
    platform,
    postingType,
    useCase,
    objective,
    description,
  } = data;

  const variables = {
    input: {
      id,
      paymentType,
      type,
      private: privateValue,
      platform,
      postingType,
      useCase,
      objective,
      description,
    },
  };

  commitMutation<CreateCampaignMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

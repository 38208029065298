import React, { MouseEventHandler, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { TooltipArrowPosition } from '../types';

import styles from './TooltipBase.pcss';

type Props = {
  className?: string;
  size?: 'm' | 's';
  hiddenArrow?: boolean;
  arrowPosition?: TooltipArrowPosition;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const TooltipBase: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    className,
    size = 'm',
    arrowPosition = 'center-bottom',
    hiddenArrow,
    onClick,
  } = props;

  return (
    <div
      className={classnames(
        styles.root,
        className,
        styles[`size-${size}`],
        styles[`arrow-position-${arrowPosition}`],
        {
          [styles.hiddenArrow]: hiddenArrow,
        }
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default TooltipBase;

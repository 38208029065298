import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { setModal } from 'Actions/Modal.Actions';
import { encodeQueryData } from 'Util/encodeQueryData';
import { MESSAGE } from 'Constants/modals';
import { FB_OAUTH_API, OAUTH_PROXY_URL } from 'Constants/general';
import { failData } from 'Constants/messageModal';
import { modalName } from 'Types/modals';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  authType?: string;
}

const VerifyByFacebook: React.FC<Props> = (props) => {
  const { authType, children } = props;

  const dispatch = useDispatch();
  const { closeDrawer } = useContext(DrawerContext);

  const handleClick = () => {
    const authTypeData = authType ? { auth_type: authType } : {};
    const params = {
      redirect_uri: `${OAUTH_PROXY_URL}`,
      client_id: process.env.FB_APP_ID,
      response_type: 'token',
      scope:
        'email,instagram_basic,business_management,pages_show_list,pages_read_engagement,ads_management,instagram_manage_insights,pages_manage_metadata',
      state: `origin=${location.origin}&route=/auth`,
    };
    const url = `${FB_OAUTH_API}${encodeQueryData({ ...params, ...authTypeData })}`;

    window.callSuccess = () => {
      dispatch(setModal(modalName.INSTAGRAM_BUSINESS_ACCOUNTS));
      closeDrawer('add-profile');
    };
    window.callFail = () => {
      closeDrawer('add-profile');
      dispatch(setModal(MESSAGE, { ...failData }));
    };
    window.open(url, 'auth', 'width=500,height=500');
  };

  return children ? React.cloneElement(children, { onClick: handleClick }) : null;
};

export default VerifyByFacebook;

import React, { useState, useContext } from 'react';

import styles from './Information.pcss';

import { amplitude } from 'Helpers/amplitude';
import TooltipBase from 'Components/ui/TooltipBase/TooltipBase';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { GuideTourContext } from 'Containers/GuideTour';

const Information = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const { resetGuideTour, goToStep } = useContext(GuideTourContext);

  const handleInfoClick = () => {
    amplitude.sendEvent<348>({
      id: '348',
      category: 'home',
      name: 'information_guide_click',
      param: { url: '' },
    });
    setShowTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleStartTourClick = () => {
    amplitude.sendEvent<349>({
      id: '349',
      category: 'home',
      name: 'information_guide_start',
      param: { url: '' },
    });
    setShowTooltip(false);
    resetGuideTour('navigation');
    goToStep('navigation', 'campaigns');
  };

  return (
    <div className={styles.info}>
      <AlterButton type="grey" icon="Info" className={styles.infoBtn} onClick={handleInfoClick} />
      {showTooltip && (
        <div className={styles.guideTooltip}>
          <TooltipBase className={styles.tooltip} arrowPosition="center-left">
            <Icon
              name="Close-small"
              size={16}
              className={styles.close}
              handleClick={handleTooltipClose}
            />
            <div className={styles.contentWrapper}>
              <Text
                msg="guide_tours.navigation_restart"
                type="sm"
                className={styles.text}
                color="white"
              />
              <TextButton
                msg="guide_tours.navigation_restart.start"
                className={styles.textButton}
                onClick={handleStartTourClick}
                color="pink"
              />
            </div>
          </TooltipBase>
        </div>
      )}
    </div>
  );
};

export default Information;

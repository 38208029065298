/**
 * @generated SignedSource<<2885fd08118a3bbe87fbe972ce5a5260>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Locale = "EN" | "RU" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TemplateSelectorForOutreach_list$data = {
  readonly campaign: {
    readonly sampleOutreachTemplateVariables: ReadonlyArray<{
      readonly name: string;
      readonly value: string | null | undefined;
    }>;
  } | null | undefined;
  readonly outreachTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly htmlBody: string;
        readonly id: string;
        readonly locale: Locale;
        readonly name: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "TemplateSelectorForOutreach_list";
};
export type TemplateSelectorForOutreach_list$key = {
  readonly " $data"?: TemplateSelectorForOutreach_list$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateSelectorForOutreach_list">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "campaignId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateSelectorForOutreach_list",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachTemplateConnection",
      "kind": "LinkedField",
      "name": "outreachTemplates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OutreachTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OutreachTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "locale",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "htmlBody",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "campaignId"
        }
      ],
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TemplateVariable",
          "kind": "LinkedField",
          "name": "sampleOutreachTemplateVariables",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "d28be3df1c1f1ed3c2a6ff1fe7e79601";

export default node;

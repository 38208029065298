/**
 * @generated SignedSource<<e6df2835c5208476022ea9e7e20b7ae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Reporting_ReportState = "EXPIRED" | "FAILED" | "PENDING" | "READY" | "%future added value";
export type Reporting_CreateCampaignPublicationsReportInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
};
export type CreateCampaignPublicationsReportMutation$variables = {
  input: Reporting_CreateCampaignPublicationsReportInput;
};
export type CreateCampaignPublicationsReportMutation$data = {
  readonly reporting: {
    readonly createCampaignPublicationsReport: {
      readonly report: {
        readonly id: string;
        readonly state: Reporting_ReportState;
      };
    } | null | undefined;
  } | null | undefined;
};
export type CreateCampaignPublicationsReportMutation = {
  response: CreateCampaignPublicationsReportMutation$data;
  variables: CreateCampaignPublicationsReportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Reporting_Mutation",
    "kind": "LinkedField",
    "name": "reporting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Reporting_CreateCampaignPublicationsReportPayload",
        "kind": "LinkedField",
        "name": "createCampaignPublicationsReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reporting_CampaignPublicationsReport",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCampaignPublicationsReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCampaignPublicationsReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f4d4aefbfb67bf9c63276baa40c182d",
    "id": null,
    "metadata": {},
    "name": "CreateCampaignPublicationsReportMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCampaignPublicationsReportMutation(\n  $input: Reporting_CreateCampaignPublicationsReportInput!\n) {\n  reporting {\n    createCampaignPublicationsReport(input: $input) {\n      report {\n        id\n        state\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "72ab4dcd787b790769b3d7eca83c40d0";

export default node;

import React, { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

import { setModal } from 'Actions/Modal.Actions';
import ConnectFb from 'Molecules/ConnectFb/ConnectFb';
import { modalName } from 'Types/modals';

interface Props {
  projectId: string;
  onCheckingConnectionPossibility?: () => boolean;
  disabled?: boolean;
}

const ActivatePs: React.FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    onCheckingConnectionPossibility,
    projectId,
    disabled
  } = props;

  const dispatch = useDispatch();

  const handleConnectDone = (socialIdentityId: string) => {
    if (disabled) return;
    dispatch(setModal(modalName.ACTIVATE_PS, { socialIdentityId, projectId }));
  };

  return (
    <ConnectFb
      callback={handleConnectDone}
      onCheckingConnectionPossibility={onCheckingConnectionPossibility}
    >
      {children}
    </ConnectFb>
  );
};

export default ActivatePs;

import React, { Component } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import findIndex from 'lodash/findIndex';

import styles from './CheckboxSelect.css';

import Text from 'Atoms/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import Checkbox from 'Atoms/Checkbox/Checkbox';

@track((props) => ({
  element: props.name,
}))
class CheckboxSelect extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  @track((props, state, [item]) => ({
    event: 'selected',
    payload: {
      value: item.name,
    },
  }))
  onChange(item) {
    const { name, value, onChange } = this.props;
    const index = findIndex(value, (valueItem) => {
      return valueItem.id === item.id;
    });
    const newValue = [...value];
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(item);
    }
    onChange({ [name]: newValue });
  }

  createCheckboxes() {
    const { items, value } = this.props;

    return items.map((item) => {
      const { id, name, msg, imageData, icon, itemClassName } = item;
      const isChecked = findIndex(value, (el) => {
        return el.id === id;
      });

      const onClick = () => {
        this.onChange(item);
      };

      return (
        <div key={id} className={classnames(styles.checkbox, itemClassName)} onClick={onClick}>
          <Checkbox active={isChecked !== -1} className={styles.icon} />
          {imageData && <Avatar size="24" {...imageData} className={styles.image} />}
          <Text
            text={name}
            transform="capitalizeFirstLetter"
            msg={msg}
            overflow
            className={styles.label}
          />
          {icon}
        </div>
      );
    });
  }

  render() {
    const checkBoxes = this.createCheckboxes();
    const { inGroup } = this.props;

    return (
      <div className={classnames(styles.root, { [styles.inGroup]: inGroup })}>{checkBoxes}</div>
    );
  }
}

export default CheckboxSelect;

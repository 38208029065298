/**
 * @generated SignedSource<<67f42e97afb0e2bdbc109a43f236fab2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateLookalikeInput = {
  briefId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  url: string;
};
export type CreateLookalikeMutation$variables = {
  input: CreateLookalikeInput;
};
export type CreateLookalikeMutation$data = {
  readonly createLookalike: {
    readonly newLookalikeEdge: {
      readonly node: {
        readonly id: string;
        readonly url: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CreateLookalikeMutation = {
  response: CreateLookalikeMutation$data;
  variables: CreateLookalikeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateLookalikePayload",
    "kind": "LinkedField",
    "name": "createLookalike",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LookalikeEdge",
        "kind": "LinkedField",
        "name": "newLookalikeEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lookalike",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateLookalikeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateLookalikeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09ee6b9c2e5faa2079d4dc0f0f854722",
    "id": null,
    "metadata": {},
    "name": "CreateLookalikeMutation",
    "operationKind": "mutation",
    "text": "mutation CreateLookalikeMutation(\n  $input: CreateLookalikeInput!\n) {\n  createLookalike(input: $input) {\n    newLookalikeEdge {\n      node {\n        id\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4dde3870ddf6a0ffa24d4b660cbce3b9";

export default node;

import React, { useContext } from 'react';

import styles from './MediaFile.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  id: string;
  youtubeLink?: string;
}

const MediaFile: React.FC<Props> = (props) => {
  const { youtubeLink, id } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const handleCloseDrawer = () => {
    closeDrawer(`media-file-${id}`);
  };

  return (
    <Drawer rootKey={`media-file-${id}`} className={styles.drawer} needCloseButton={false}>
      <div className={styles.root}>
        <div className={styles.content}>
          {youtubeLink && (
            <iframe src={youtubeLink} width={1080} height={610} className={styles.iframe} />
          )}
        </div>
        <div className={styles.closeBtn} onClick={handleCloseDrawer}>
          <Icon name={'Close-small'} />
        </div>
      </div>
    </Drawer>
  );
};

export default MediaFile;

import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './InputPreset.pcss';

type Props = {
  className?: string;
};

const InputPreset: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, className } = props;
  return <div className={classnames(styles.root, className)}>{children}</div>;
};

export default InputPreset;
export { InputPreset };

const initialState = {
  sessions: false,
  isFetching: false,
  error: null,
  'user-id': null,
  jwt: null,
  links: '',
};

const session = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    default:
      return state;
  }
};

export default session;

/**
 * @generated SignedSource<<2cb492da2e5c8f2b9a451ad2b4c778ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Brief_ScreeningQuestion_Type = "BINARY" | "OPEN_ENDED" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OfferAcceptanceEvent_event$data = {
  readonly actor?: {
    readonly __typename: string;
    readonly id: string;
    readonly logo?: {
      readonly transformations?: {
        readonly brandLogoUrl: string;
      } | null | undefined;
    } | null | undefined;
    readonly profile?: {
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly comment?: string | null | undefined;
  readonly createdAt: any;
  readonly currency?: Currency | null | undefined;
  readonly id: string;
  readonly price?: number | null | undefined;
  readonly readByAdvertiser?: boolean;
  readonly readByContractor?: boolean;
  readonly screeningResponses?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly booleanAnswer: boolean | null | undefined;
        readonly id: string;
        readonly preferred: boolean | null | undefined;
        readonly question: {
          readonly preferredValue: boolean | null | undefined;
          readonly text: string;
          readonly type: Brief_ScreeningQuestion_Type;
        };
        readonly stringAnswer: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "OfferAcceptanceEvent_event";
};
export type OfferAcceptanceEvent_event$key = {
  readonly " $data"?: OfferAcceptanceEvent_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferAcceptanceEvent_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreatorProfile",
    "kind": "LinkedField",
    "name": "profile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profilePictureFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageTransformation",
                "kind": "LinkedField",
                "name": "transformations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collageThumbnailUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Image",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferAcceptanceEvent_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "comment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readByAdvertiser",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readByContractor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BriefScreeningQuestionResponseConnection",
          "kind": "LinkedField",
          "name": "screeningResponses",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BriefScreeningQuestionResponseEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefScreeningQuestionResponse",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "booleanAnswer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "preferred",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BriefScreeningQuestion",
                      "kind": "LinkedField",
                      "name": "question",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "text",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "preferredValue",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stringAnswer",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "actor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "InstagramCreator",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "YoutubeCreator",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "TiktokCreator",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "logo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "brandLogoUrl",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Brand",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "OfferAcceptance",
      "abstractKey": null
    }
  ],
  "type": "ProjectEvent",
  "abstractKey": "__isProjectEvent"
};
})();

(node as any).hash = "b936db7b086fabb37811ad44bcc9ed90";

export default node;

import React, { useMemo } from 'react';
import classnames from 'classnames';

import styles from './BlockedUsername.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  textClassName?: string;
  creatorId?: string;
  onLockClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const BlockedUsername: React.FC<Props> = (props) => {
  const { onLockClick, creatorId = '', textClassName } = props;

  const hiddenUsername = useMemo(() => {
    return Math.random().toString(36).slice(2, 11);
  }, []);

  const handleLockClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onLockClick(e);
    amplitude.sendEvent<356>({
      id: '356',
      category: 'marketplace',
      name: 'creator_link_click',
      param: { creator_id: creatorId },
    });
  };

  return (
    <div className={styles.blockedName} onClick={handleLockClick}>
      <div className={styles.lockIcon}>
        <Icon name="Lock" size={16} />
      </div>
      <Text type="md" className={classnames(styles.blured, textClassName)} text={hiddenUsername} />
    </div>
  );
};

export default BlockedUsername;

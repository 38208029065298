import React, { useEffect, useState, useMemo } from 'react';
import track from 'react-tracking';

import styles from './BillingHistory.pcss';
import TransactionsList from './TransactionsList/TransactionsList';
import BillingHistoryFilters from './BillingHistoryFilters/BillingHistoryFilters';
import TransactionReport from './TransactionReport/TransactionReport';
import { Filters, defaultFilters } from './data';

import Page from 'Templates/Page/Page';
import { trackTime as custifyTrackTime } from 'Util/custify';

const BillingHistory = () => {
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  const handleFiltersUpdate = (newFilters: Filters) => {
    setFilters({ ...filters, ...newFilters });
  };

  useEffect(() => {
    custifyTrackTime('Transactions_history');
    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  const formattedFilters = useMemo(() => {
    const campaignIds = filters.campaigns?.map((item) => item.id);
    const brandIds = filters.brands?.map((item) => item.id);
    const creatorIds = filters.creator ? [filters.creator.id] : undefined;
    return { ...filters, campaignIds, brandIds, creatorIds };
  }, [filters]);

  const handleParamsClear = () => {
    setFilters(defaultFilters);
  };

  return (
    <Page className={styles.root}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <TransactionReport />
        </header>
        <div className={styles.container}>
          <BillingHistoryFilters filters={filters} onFiltersUpdate={handleFiltersUpdate} />
          <TransactionsList {...formattedFilters} onParamsClear={handleParamsClear} />
        </div>
      </div>
    </Page>
  );
};

export default track({
  page: 'transaction_history',
})(BillingHistory);

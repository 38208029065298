import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  RejectOfferMutation,
  RejectOfferMutation$data,
  RejectOfferInput,
} from 'GraphTypes/RejectOfferMutation.graphql';

const mutation = graphql`
  mutation RejectOfferMutation($input: RejectOfferInput!) {
    rejectOffer(input: $input) {
      project {
        id
        offer {
          rejection {
            id
            comment
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface Data {
  projectId?: string;
}

export default (
  data: RejectOfferInput & Data,
  resolve?: (response: RejectOfferMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { offerId, comment, projectId } = data;

  const variables = {
    input: {
      id,
      offerId,
      comment,
    },
  };

  commitMutation<RejectOfferMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const root = store.get('client:root');
      if (!root || !projectId) return;
      const list = ConnectionHandler.getConnection(root, 'List_projects');
      if (list) {
        ConnectionHandler.deleteNode(list, projectId);
      }
    },
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

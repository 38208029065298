import React, { useMemo, useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';

import styles from './ShopifyOAuthVerify.pcss';
import ShopifyOAuthVerifyQuery, {
  ShopifyOAuthVerifyQuery as ShopifyOAuthVerifyQueryType,
} from './ShopifyOAuthVerify.Query';

import { DASHBOARD_ROUTE } from 'Constants/general';
import Page from 'Templates/Page/Page';
import { OutcomeStatus } from 'Components/OutcomeStatus';
import Spinner from 'Atoms/Spinner/Spinner';
import Header from 'Templates/Layout/Header/Header';
import { useShopify } from 'Hooks/useShopify';

type Props = {
  className?: string;
};

const ShopifyOAuthVerify: React.FC<Props> = (props) => {
  const { className } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [error, setError] = useState<ErrorState>({
    hasError: false,
    errorMsg: 'page.shopify_oauth_verify.outcome.error.subtitle',
  });

  const { search } = useLocation();
  const navigate = useNavigate();
  const lastCampaignId = localStorage.getItem('shopify-campaign-id');
  useEffect(() => {
    let redirectCallback: NodeJS.Timeout | null = null;
    const lastCampaignId = localStorage.getItem('shopify-campaign-id');
    localStorage.removeItem('shopify-campaign-id');
    if (showSuccess && lastCampaignId) {
      redirectCallback = setTimeout(() => navigate(`/campaigns/${lastCampaignId}/shipment`), 1000);
    }
    return () => {
      if (redirectCallback) {
        clearTimeout(redirectCallback);
      }
    };
  }, [showSuccess]);
  const {
    defaultScope,
    shopifyVerify,
    readCacheCampaigns,
    getShopNameFromUrl,
    connectCampaignToShopify,
    clearCacheCampaigns,
  } = useShopify({ campaignId: lastCampaignId || '' });
  const { currentUser } = useLazyLoadQuery<ShopifyOAuthVerifyQueryType>(
    ShopifyOAuthVerifyQuery,
    {}
  );

  const searchParams = useMemo<SearchParams>(() => {
    const sp = new URLSearchParams(search);
    return Object.fromEntries(sp.entries()) as SearchParams;
  }, [search]);

  useEffect(() => {
    if (!currentUser?.id || localStorage.getItem('block_shopify_verify')) return;
    if (currentUser?.type !== 'ADVERTISER') {
      setError({
        hasError: true,
        errorMsg: 'page.shopify_oauth_verify.outcome.error.subtitle.invalid_user_type',
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const currentUserId = currentUser.id;
    const campaignIds = readCacheCampaigns({ currentUserId });

    const runShopifyVerify: RunShopifyVerify = () => {
      return new Promise((resolve, reject) => {
        const shopName = getShopNameFromUrl(searchParams.shop) || '';

        const params: ShopifyVerifyParams[0] = {
          shopName,
          campaignId: '',
          scope: defaultScope,
          timestamp: searchParams.timestamp,
          state: searchParams.state,
          host: searchParams.host,
          code: searchParams.code,
          hmac: searchParams.hmac,
        };

        const options: ShopifyVerifyParams[1] = {
          skipConnectCampaignToShopify: true,
        };
        shopifyVerify(params, options)
          .then((res) => {
            const hasError = res?.error === true;
            if (hasError) {
              setError({
                hasError: true,
                errorMsg: 'page.shopify_oauth_verify.outcome.error.subtitle',
              });
            }
            resolve(res);
          })
          .catch((reason) => {
            setError({
              hasError: true,
              errorMsg: 'page.shopify_oauth_verify.outcome.error.subtitle',
            });
            reject(reason);
          });
      });
    };

    runShopifyVerify()
      .then((res) => {
        const hasError = res?.error === true;
        let shopifyAuthorizationId = '';
        if (
          res?.response?.authenticateWithShopify?.__typename === 'AuthenticateWithShopifyPayload'
        ) {
          shopifyAuthorizationId = res?.response?.authenticateWithShopify?.shopifyAuthorization?.id;
        }

        if (hasError || !shopifyAuthorizationId) {
          setError({
            hasError: true,
            errorMsg: 'page.shopify_oauth_verify.outcome.error.subtitle',
          });
          return res;
        }

        Promise.all(
          [...campaignIds].map((campaignId) => {
            return connectCampaignToShopify({ shopifyAuthorizationId, campaignId });
          })
        )
          .then((value) => {
            if (campaignIds.length) {
              navigate(`/campaigns/${campaignIds[campaignIds.length - 1]}/shipment`);
            } else {
              setShowSuccess(true);
            }
          })
          .catch(() => {
            setError({
              hasError: true,
              errorMsg: 'page.shopify_oauth_verify.outcome.error.subtitle',
            });
          })
          .finally(() => {
            setIsLoading(false);
            clearCacheCampaigns({ currentUserId });
          });
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [currentUser?.id]);

  const handleGoToDashboard = useCallback(() => {
    navigate(DASHBOARD_ROUTE);
  }, []);

  return (
    <Page className={classnames(styles.root, className)}>
      <Header showStaticHeader />
      <div className={styles.content}>
        {isLoading && <Spinner />}
        {!isLoading && error.hasError && (
          <OutcomeStatus
            className={styles.outcomeComponentRoot}
            status="error"
            titleTextProps={{
              msg: 'page.shopify_oauth_verify.outcome.error.title',
              className: styles.titleText,
            }}
            subtitleTextProps={{
              msg: error.errorMsg,
            }}
            buttonProps={{
              msg: 'page.shopify_oauth_verify.outcome.button.go_to_dashboard',
              onClick: handleGoToDashboard,
            }}
          />
        )}
        {!isLoading && !error.hasError && showSuccess && (
          <OutcomeStatus
            className={styles.outcomeComponentRoot}
            status="success"
            titleTextProps={{
              msg: 'page.shopify_oauth_verify.outcome.success.title',
              className: styles.titleText,
            }}
            subtitleTextProps={{
              msg: 'page.shopify_oauth_verify.outcome.success.subtitle',
              className: styles.subtitleTextProps,
            }}
            buttonProps={{
              msg: 'page.shopify_oauth_verify.outcome.button.go_to_dashboard',
              onClick: handleGoToDashboard,
            }}
          />
        )}
      </div>
    </Page>
  );
};

export default ShopifyOAuthVerify;

// types

type SearchParams = {
  code: string;
  hmac: string;
  host: string;
  shop: string;
  state: string;
  timestamp: string;
};

type ShopifyVerifyParams = Parameters<ReturnType<typeof useShopify>['shopifyVerify']>;
type ErrorState = {
  hasError: boolean;
  errorMsg: string;
};

type RunShopifyVerify = () => ReturnType<ReturnType<typeof useShopify>['shopifyVerify']>;

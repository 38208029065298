import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useQueryLoader, useClientQuery } from 'react-relay';

import styles from './ShopifyAuthDrawer.pcss';
import { CheckYourPermissions } from './CheckYourPermissions/CheckYourPermissions';
import { ConnectToShopify } from './ConnectToShopify/ConnectToShopify';
import { ShopifyAuthDrawerQuery, ShopifyAuthDrawerQueryType } from './ShopifyAuthDrawer.Query';

import CurrentUserQuery, {
  CurrentUserQuery as CurrentUserQueryType,
} from 'Containers/Auth0/Auth0Context/CurrentUser.Query';
import Drawer from 'Components/ui/Drawer/Drawer';
import { amplitude } from 'Helpers/amplitude';
import { useShopify } from 'Hooks/useShopify';

interface Props {
  campaignId: string;
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
  contentVariant?: ContentVariant;
  onConnectionSucceeded?: (shopName: string) => void;
}

const ShopifyAuthDrawer: React.FC<Props> = (props) => {
  const { drawerProps, campaignId, onConnectionSucceeded } = props;
  const [contentVariant, setContentVariant] = useState<ContentVariant>(
    props?.contentVariant || 'check-permissions'
  );

  const { hasEverConnected } = useShopify({ campaignId });

  useEffect(() => {
    if (props?.contentVariant) {
      setContentVariant(props.contentVariant);
    }
  }, [props?.contentVariant]);

  const [, loadShopifyAuthDrawerQuery] =
    useQueryLoader<ShopifyAuthDrawerQueryType>(ShopifyAuthDrawerQuery);

  const { currentUser } = useClientQuery<CurrentUserQueryType>(CurrentUserQuery, {});
  const currentUserId = currentUser?.id || '';

  const handleOpenDrawer = useCallback(() => {
    /*@TODO events*/
    loadShopifyAuthDrawerQuery({}, { fetchPolicy: 'network-only' });
    drawerProps?.onOpen?.();
  }, [drawerProps?.onOpen, loadShopifyAuthDrawerQuery]);

  const handleCloseDrawer = useCallback(() => {
    /*@TODO events*/
    drawerProps?.onClose?.();
  }, [drawerProps?.onClose]);

  const handleNextButtonClick = useCallback(() => {
    amplitude.sendEvent<317>({
      id: '317',
      category: 'shipment',
      name: 'connection_next_click',
      param: { campaign_id: campaignId },
    });
    setContentVariant('connect-to-shopify');
  }, [campaignId]);

  const handleConnectionFailed = useCallback<HandleConnectionFailed>((response) => {
    const isPermissionsError =
      response?.authenticateWithShopify?.__typename === 'AuthenticateWithShopify_InvalidScopeError';

    if (isPermissionsError) {
      setContentVariant('check-permissions-with-error');
    }

    const sendEvent = async () => {
      const hasConnected = await hasEverConnected();
      if (hasConnected) {
        amplitude.sendEvent<324>({
          id: '324',
          category: 'shipment',
          name: 'restore_connection_failed',
          param: { campaign_id: campaignId },
        });
      } else {
        amplitude.sendEvent<320>({
          id: '320',
          category: 'shipment',
          name: 'initial_connection_fail',
          param: { campaign_id: campaignId },
        });
      }
    };

    sendEvent();
  }, []);

  const handleConnectionSucceeded = useCallback<HandleConnectionSucceeded>(
    (shopName) => {
      const sendEvent = async () => {
        const hasConnected = await hasEverConnected();
        if (hasConnected) {
          amplitude.sendEvent<323>({
            id: '323',
            category: 'shipment',
            name: 'restore_connection_success',
            param: { campaign_id: campaignId },
          });
        } else {
          amplitude.sendEvent<319>({
            id: '319',
            category: 'shipment',
            name: 'initial_connection_success',
            param: { campaign_id: campaignId },
          });
        }
      };

      sendEvent();

      onConnectionSucceeded?.(shopName);
    },
    [onConnectionSucceeded]
  );

  const contents: Contents = {
    'check-permissions': (
      <CheckYourPermissions
        titleMsg="shopify_auth_drawer.check_your_permissions.title"
        onNextButtonClick={handleNextButtonClick}
      />
    ),
    'check-permissions-with-error': (
      <CheckYourPermissions
        titleMsg="shopify_auth_drawer.check_your_permissions.title.error_permissions"
        onNextButtonClick={handleNextButtonClick}
      />
    ),
    'connect-to-shopify': (
      <ConnectToShopify
        campaignId={campaignId}
        currentUserId={currentUserId}
        onConnectionFailed={handleConnectionFailed}
        onConnectionSucceeded={handleConnectionSucceeded}
      />
    ),
  };

  return (
    <Drawer
      {...drawerProps}
      rootKey="shopify-auth"
      className={classnames(styles.drawer, drawerProps?.className)}
      onOpen={handleOpenDrawer}
      onClose={handleCloseDrawer}
    >
      {contents[contentVariant]}
    </Drawer>
  );
};

export default ShopifyAuthDrawer;
export { ShopifyAuthDrawer };

// types

type DrawerProps = React.ComponentProps<typeof Drawer>;
type ContentVariant = 'check-permissions' | 'connect-to-shopify' | 'check-permissions-with-error';
type Contents = Record<ContentVariant, React.ReactNode>;
type ConnectToShopifyProps = React.ComponentProps<typeof ConnectToShopify>;
type HandleConnectionFailed = NonNullable<ConnectToShopifyProps['onConnectionFailed']>;
type HandleConnectionSucceeded = NonNullable<ConnectToShopifyProps['onConnectionSucceeded']>;

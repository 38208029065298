import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  MarkContentReviewAsPassedMutation,
  MarkContentReviewAsPassedMutation$data,
  MarkContentReviewAsPassedInput,
} from 'GraphTypes/MarkContentReviewAsPassedMutation.graphql';

const mutation = graphql`
  mutation MarkContentReviewAsPassedMutation($input: MarkContentReviewAsPassedInput!) {
    markContentReviewAsPassed(input: $input) {
      project {
        advertiserStage
        campaign {
          publishingRequired
        }
        contentReview {
          status
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: MarkContentReviewAsPassedInput,
  resolve?: (response: MarkContentReviewAsPassedMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<MarkContentReviewAsPassedMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import xor from 'lodash/xor';

import styles from './OrganizationsFilter.pcss';

import environment from 'Api/Environment';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import {
  OrganizationsFilterQuery as QueryType,
  OrganizationsFilterQueryResponse,
} from 'GraphTypes/OrganizationsFilterQuery.graphql';
import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';

const OrganizationsFilterQuery = graphql`
  query OrganizationsFilterQuery($textQuery: String!) {
    organizations(textQuery: $textQuery, first: 10) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

interface Props {
  selectedOrganizations: string[];
  onOrganizationChange: (organizations: string[]) => void;
}

const OrganizationsFilter: React.FC<Props> = (props) => {
  const { selectedOrganizations = [], onOrganizationChange } = props;
  const [organizations, setOrganizations] = useState<
    OrganizationsFilterQueryResponse['organizations'] | undefined
  >(undefined);
  const [organizationSearch, setOrganizationSearch] = useState<string>('');

  const organizationsList = useMemo(() => {
    return organizations?.edges?.map((item) => {
      const organizationId = item?.node?.id;
      return {
        id: organizationId,
        label: item?.node?.name,
        isChecked: organizationId ? selectedOrganizations.includes(organizationId) : undefined,
      };
    });
  }, [organizations, selectedOrganizations]);

  useEffect(() => {
    fetchQuery<QueryType>(environment, OrganizationsFilterQuery, {
      textQuery: organizationSearch,
    }).subscribe({
      next: (response) => {
        const newList = response?.organizations;
        setOrganizations(newList);
      },
    });
  }, [organizationSearch]);

  const handleOrganizationSelect = (value: string) => {
    const newOrganizationsValue = xor(selectedOrganizations, [value]);
    onOrganizationChange(newOrganizationsValue);
  };

  const hasSelectedOrganization = selectedOrganizations.length > 0;

  const handleSerachChange = useCallback((value: string) => {
    setOrganizationSearch(value);
  }, []);

  const handleClear = useCallback(() => {
    onOrganizationChange([]);
  }, []);

  return (
    <div className={styles.root}>
      <ProgressiveInput
        type="checkboxSelect"
        className={styles.selectCheckbox}
        inputProps={{
          placeholderMsg: 'search_section.form.advertiser',
          items: organizationsList ? (organizationsList as CheckboxItemType[]) : [],
          bordered: true,
          handleSearch: handleSerachChange,
          withSearch: true,
          handleClick: handleOrganizationSelect,
          hideByAlphabet: true,
          handleClear,
        }}
        isDirty={hasSelectedOrganization}
      />
    </div>
  );
};

export default OrganizationsFilter;

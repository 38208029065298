/**
 * @generated SignedSource<<09e72616b44a939de7d745cf728da9e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateInviteSuggestionListInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
};
export type CreateInviteSuggestionListQuery$variables = {
  input: CreateInviteSuggestionListInput;
};
export type CreateInviteSuggestionListQuery$data = {
  readonly createInviteSuggestionList: {
    readonly __typename: "CreateInviteSuggestionListPayload";
    readonly inviteSuggestionList: {
      readonly id: string;
      readonly inviteSuggestionsCount: {
        readonly totalCount: number;
      };
    } | null | undefined;
  } | {
    readonly __typename: "LimitExceededError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CreateInviteSuggestionListQuery = {
  response: CreateInviteSuggestionListQuery$data;
  variables: CreateInviteSuggestionListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createInviteSuggestionList",
    "plural": false,
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InviteSuggestionList",
            "kind": "LinkedField",
            "name": "inviteSuggestionList",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "inviteSuggestionsCount",
                "args": null,
                "concreteType": "InviteSuggestionConnection",
                "kind": "LinkedField",
                "name": "inviteSuggestions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CreateInviteSuggestionListPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "LimitExceededError",
        "abstractKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInviteSuggestionListQuery",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInviteSuggestionListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9a8dc9b319c932541b2e9dc0bdc7eefd",
    "id": null,
    "metadata": {},
    "name": "CreateInviteSuggestionListQuery",
    "operationKind": "mutation",
    "text": "mutation CreateInviteSuggestionListQuery(\n  $input: CreateInviteSuggestionListInput!\n) {\n  createInviteSuggestionList(input: $input) {\n    ... on CreateInviteSuggestionListPayload {\n      inviteSuggestionList {\n        id\n        inviteSuggestionsCount: inviteSuggestions {\n          totalCount\n        }\n      }\n    }\n    ... on LimitExceededError {\n      message\n    }\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "df7988d160f7df6410299cf6457d6e62";

export default node;

import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import ImageWithFallbackQuery from './ImageWithFallback.Query';

import { ImageWithFallbackQuery as QueryType } from 'GraphTypes/ImageWithFallbackQuery.graphql';

type Props = {
  url: string;
  className?: string;
};

const ImageWithFallback = (props: Props) => {
  const { url, className } = props;
  const { instagramOembed } = useLazyLoadQuery<QueryType>(ImageWithFallbackQuery, { url });
  if (!instagramOembed?.thumbnailUrl) {
    throw Error(`Incorrect image URL: ${instagramOembed?.thumbnailUrl}`);
  }
  return (
    <img
      className={className || ''}
      src={instagramOembed?.thumbnailUrl}
    />
  );
};

export default React.memo(ImageWithFallback);
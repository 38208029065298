import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  UnblockCreatorInput,
  UnblockCreatorMutation,
  UnblockCreatorMutation$data,
} from 'GraphTypes/UnblockCreatorMutation.graphql';

const mutation = graphql`
  mutation UnblockCreatorMutation($input: UnblockCreatorInput!) {
    unblockCreator(input: $input) {
      ... on UnblockCreatorPayload {
        organization {
          amount: blockedCreators {
            totalCount
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface Data {
  projectId?: string;
}

export default (
  data: UnblockCreatorInput & Data,
  resolve?: (response: UnblockCreatorMutation$data) => void,
  reject?: fnType
) => {
  const { creatorId, organizationId, projectId } = data;

  const variables = {
    input: {
      creatorId,
      organizationId,
    },
  };
  commitMutation<UnblockCreatorMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (projectId) {
        const project = store.get(projectId);
        if (project) {
          project.setValue(false, 'blockedCreator');
        }
      }
    },
    updater: (store) => {
      if (projectId) {
        const project = store.get(projectId);
        if (project) {
          project.setValue(false, 'blockedCreator');
        }
      }

      if (!organizationId || !creatorId) return;
      const organization = store.get(organizationId);
      if (!organization) return;
      const blockedCreators = ConnectionHandler.getConnection(
        organization,
        'Organization_blockedCreators'
      );
      if (!blockedCreators) return;
      ConnectionHandler.deleteNode(blockedCreators, creatorId);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

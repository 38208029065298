import React, { useCallback } from 'react';
import xor from 'lodash/xor';
import { useIntl } from 'react-intl';

import { defaultState } from '../AdminTransactions';

import styles from './AdminTransactionsFilters.pcss';
import { getFiltersData } from './utils';

import { MANUAL_PAYMENT_METHOD } from 'Constants/general';
import DatePicker from 'Components/ui/DatePicker/DatePicker';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import OrganizationsFilter from 'Organisms/OrganizationsFilter/OrganizationsFilter';
import TextButton from 'Components/ui/TextButton/TextButton';

type Filters = {
  type: string | null;
  subscriptionStatusType: string | null;
  paymentStatus?: string | null;
  paymentMethodFilters: string[];
  organizationIds: string[];
  departments: string[];
  dateTo: Date | null;
  dateFrom: Date | null;
  paidDateTo: Date | null;
  paidDateFrom: Date | null;
};

interface Props {
  departments: { id: string; name: string }[];
  filters: Filters;
  onFiltersChange: (filters: Filters) => void;
}

const AdminTransactionsFilters: React.FC<Props> = (props) => {
  const { departments, filters, onFiltersChange } = props;

  const intl = useIntl();

  const filtersData = getFiltersData(filters);

  const handleMethodChange = useCallback(
    (id: string) => {
      const newMethodsValue = xor(filters.paymentMethodFilters || [], [id]);
      onFiltersChange({ ...filters, paymentMethodFilters: newMethodsValue });
    },
    [filters]
  );

  const handleOrganizationsChange = useCallback(
    (value: string[]) => {
      onFiltersChange({ ...filters, organizationIds: value });
    },
    [filters]
  );

  const handleTypeChange = useCallback(
    (id: string) => {
      const typeValue = filters.type === id ? null : id;
      onFiltersChange({ ...filters, type: typeValue });
    },
    [filters]
  );

  const handlePaymentStatusChange = useCallback(
    (id: string) => {
      const statusValue = filters.paymentStatus === id ? null : id;
      onFiltersChange({ ...filters, paymentStatus: statusValue });
    },
    [filters]
  );

  const handleSubscriptionStatusTypeChange = useCallback(
    (id: string) => {
      const statusValue = filters.subscriptionStatusType === id ? null : id;
      onFiltersChange({ ...filters, subscriptionStatusType: statusValue });
    },
    [filters]
  );

  const handleDepartmentsChange = useCallback(
    (id: string) => {
      const newDepartmentsValue = xor(filters.departments || [], [id]);
      const newPaymentMethodFilters =
        filters.paymentMethodFilters.length === 0 && newDepartmentsValue.length > 0
          ? [MANUAL_PAYMENT_METHOD]
          : filters.paymentMethodFilters;
      onFiltersChange({
        ...filters,
        departments: newDepartmentsValue,
        paymentMethodFilters: newPaymentMethodFilters,
      });
    },
    [filters]
  );

  const handleDateFromChange = useCallback(
    (date: Date | null) => {
      onFiltersChange({ ...filters, dateFrom: date });
    },
    [filters]
  );

  const handleDateToChange = useCallback(
    (date: Date | null) => {
      onFiltersChange({ ...filters, dateTo: date });
    },
    [filters]
  );

  const handleDateFromClear = useCallback(() => {
    onFiltersChange({ ...filters, dateFrom: null });
  }, [filters]);

  const handleDateToClear = useCallback(() => {
    onFiltersChange({ ...filters, dateTo: null });
  }, [filters]);

  const handlePaidDateFromChange = useCallback(
    (date: Date | null) => {
      onFiltersChange({ ...filters, paidDateFrom: date });
    },
    [filters]
  );

  const handlePaidDateToChange = useCallback(
    (date: Date | null) => {
      onFiltersChange({ ...filters, paidDateTo: date });
    },
    [filters]
  );

  const handlePaidDateFromClear = useCallback(() => {
    onFiltersChange({ ...filters, paidDateFrom: null });
  }, [filters]);

  const handlePaidDateToClear = useCallback(() => {
    onFiltersChange({ ...filters, paidDateTo: null });
  }, [filters]);

  const handleParamsClear = () => {
    onFiltersChange(defaultState);
  };

  const handlePaymentMethodsClear = useCallback(() => {
    onFiltersChange({ ...filters, paymentMethodFilters: [] });
  }, [filters]);

  const handleTypeClear = useCallback(() => {
    onFiltersChange({ ...filters, type: null });
  }, [filters]);

  const handlePaymentStatusClear = useCallback(() => {
    onFiltersChange({ ...filters, paymentStatus: null });
  }, [filters]);

  const handleDepartmentsClear = useCallback(() => {
    onFiltersChange({ ...filters, departments: [] });
  }, [filters]);

  const handleSubscriptionStatusClear = useCallback(() => {
    onFiltersChange({ ...filters, subscriptionStatusType: null });
  }, [filters]);

  const departmentItems = departments.map((item) => {
    return {
      id: item.id,
      isChecked: filters.departments.includes(item.id),
      value: item.name,
      label: item.name,
    };
  });

  return (
    <div className={styles.root}>
      <TextButton
        color="grey"
        msg="general.clear_all"
        onClick={handleParamsClear}
        className={styles.clearParams}
      />
      <div className={styles.group}>
        <DatePicker
          inputClassName={styles.date}
          customInputProps={{
            rightIcon: 'Calendar-schedule',
          }}
          onResetValue={handleDateFromClear}
          reactDatePickerProps={{
            selected: filters.dateFrom,
            placeholderText: intl.formatMessage({ id: 'search_section.form.dateFrom' }),
            onChange: handleDateFromChange,
          }}
        />
        <DatePicker
          inputClassName={styles.date}
          onResetValue={handleDateToClear}
          customInputProps={{
            rightIcon: 'Calendar-schedule',
          }}
          reactDatePickerProps={{
            selected: filters.dateTo,
            placeholderText: intl.formatMessage({ id: 'search_section.form.dateTo' }),
            onChange: handleDateToChange,
          }}
        />
        <DatePicker
          inputClassName={styles.date}
          customInputProps={{
            rightIcon: 'Calendar-schedule',
          }}
          onResetValue={handlePaidDateFromClear}
          reactDatePickerProps={{
            selected: filters.paidDateFrom,
            placeholderText: intl.formatMessage({
              id: 'search_section.form.paidDateFrom',
            }),
            onChange: handlePaidDateFromChange,
          }}
        />
        <DatePicker
          inputClassName={styles.date}
          onResetValue={handlePaidDateToClear}
          customInputProps={{
            rightIcon: 'Calendar-schedule',
          }}
          reactDatePickerProps={{
            selected: filters.paidDateTo,
            placeholderText: intl.formatMessage({ id: 'search_section.form.paidDateTo' }),
            onChange: handlePaidDateToChange,
          }}
        />
      </div>
      <div className={styles.group}>
        <ProgressiveInput
          type="checkboxSelect"
          className={styles.selectCheckbox}
          inputProps={{
            items: filtersData.paymentMethodFilters,
            bordered: true,
            placeholderMsg: 'search_section.form.payment_method',
            handleClick: handleMethodChange,
            hideByAlphabet: true,
            handleClear: handlePaymentMethodsClear,
          }}
          isDirty={filters.paymentMethodFilters.length > 0}
        />
        <OrganizationsFilter
          selectedOrganizations={filters.organizationIds}
          onOrganizationChange={handleOrganizationsChange}
        />
        <ProgressiveInput
          type="radio"
          className={styles.selectCheckbox}
          inputProps={{
            items: filtersData.type,
            bordered: true,
            placeholderMsg: 'search_section.form.payment_type',
            handleClick: handleTypeChange,
            hideByAlphabet: true,
            withArrow: true,
            handleClear: handleTypeClear,
          }}
          isDirty={!!filters.type}
        />
        <ProgressiveInput
          type="radio"
          className={styles.selectCheckbox}
          inputProps={{
            items: filtersData.paymentStatus,
            bordered: true,
            placeholderMsg: 'search_section.form.payment_status',
            handleClick: handlePaymentStatusChange,
            hideByAlphabet: true,
            withArrow: true,
            handleClear: handlePaymentStatusClear,
          }}
          isDirty={!!filters.paymentStatus}
        />
        <ProgressiveInput
          type="checkboxSelect"
          className={styles.selectCheckbox}
          inputProps={{
            items: departmentItems,
            bordered: true,
            placeholderMsg: 'search_section.form.departments',
            handleClick: handleDepartmentsChange,
            hideByAlphabet: true,
            handleClear: handleDepartmentsClear,
          }}
          isDirty={filters.departments.length > 0}
        />
        <ProgressiveInput
          type="radio"
          className={styles.selectCheckbox}
          inputProps={{
            items: filtersData.subscriptionStatusType,
            bordered: true,
            placeholderMsg: 'search_section.form.subscription_status_type',
            handleClick: handleSubscriptionStatusTypeChange,
            hideByAlphabet: true,
            withArrow: true,
            handleClear: handleSubscriptionStatusClear,
          }}
          isDirty={!!filters.subscriptionStatusType}
        />
      </div>
    </div>
  );
};

export default AdminTransactionsFilters;

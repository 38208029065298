import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  SendOfferInput,
  SendOfferMutation,
  SendOfferMutation$data,
} from 'GraphTypes/SendOfferMutation.graphql';
import { COMMON_ERROR_MESSAGE } from 'Constants/general';

const mutation = graphql`
  mutation SendOfferMutation($input: SendOfferInput!) {
    sendOffer(input: $input) {
      ... on SendOfferPayload {
        project {
          advertiserStage
          offer {
            id
          }
          campaign {
            maxInvitationCount
            usedInvitationCount
          }
        }
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export default (
  data: SendOfferInput,
  resolve?: (response: SendOfferMutation$data) => void,
  reject?: (errorMsg: string) => void
) => {
  const id = uuid();

  const { creatorId, campaignId, source } = data;

  const variables = {
    input: {
      campaignId,
      creatorId,
      id,
      source,
    },
  };

  commitMutation<SendOfferMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.sendOffer?.message && reject) {
        reject(response.sendOffer?.message);

        return;
      }

      if (response && resolve) {
        resolve(response);
      }
    },
    onError: () => {
      if (reject) {
        reject(COMMON_ERROR_MESSAGE);
      }
    },
  });
};

import React, { FC } from 'react';
import classnames from 'classnames';

import styles from './Skeleton.pcss';

interface Props {
  elementClassname?: string;
}

const Skeleton: FC<Props> = ({ elementClassname }) => {
  return (
    <div className={styles.root}>
      <div className={classnames(styles.stub, elementClassname)} />
      <div className={classnames(styles.stub, elementClassname)} />
      <div className={classnames(styles.stub, elementClassname)} />
      <div className={classnames(styles.stub, elementClassname)} />
      <div className={classnames(styles.stub, elementClassname)} />
      <div className={classnames(styles.stub, elementClassname)} />
    </div>
  );
};

export default Skeleton;

/**
 * @generated SignedSource<<f716f5aa27d563a7e398494443e264de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserType = "ADVERTISER" | "CONTRACTOR" | "EPHEMERAL" | "UNKNOWN" | "%future added value";
export type ShopifyOAuthVerifyQuery$variables = Record<PropertyKey, never>;
export type ShopifyOAuthVerifyQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly type: UserType;
  } | null | undefined;
};
export type ShopifyOAuthVerifyQuery = {
  response: ShopifyOAuthVerifyQuery$data;
  variables: ShopifyOAuthVerifyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShopifyOAuthVerifyQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ShopifyOAuthVerifyQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "88fe71d6aff0f311b1c8bb5244b824ea",
    "id": null,
    "metadata": {},
    "name": "ShopifyOAuthVerifyQuery",
    "operationKind": "query",
    "text": "query ShopifyOAuthVerifyQuery {\n  currentUser {\n    id\n    type\n  }\n}\n"
  }
};
})();

(node as any).hash = "de3d0c6893b9ab8b0c907177c9074011";

export default node;

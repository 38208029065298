import {
  BLOGGER,
  COMMUNITY,
  CELEBRITY,
  BRAND,
  RUB,
  USD,
  CAD,
} from 'Constants/general.ts';

export const accountTypeOpt = [
  { msg: 'creator.blogger', value: BLOGGER },
  { msg: 'creator.community', value: COMMUNITY },
  { msg: 'creator.celebrity', value: CELEBRITY },
  { msg: 'creator.brand', value: BRAND },
];


export const currencyOpt = [
  { text: USD, value: USD },
  { text: RUB, value: RUB },
  { text: CAD, value: CAD },
];
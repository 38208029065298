import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import styles from './Checkbox.pcss';

interface Props {
  className?: string;
  active?: boolean;
  disabled?: boolean;
  onChange?: (active: boolean) => void;
  dataTest?: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const { className, active, disabled, dataTest, onChange } = props;

  const [isActive, setActive] = useState(!!active);

  useEffect(() => {
    setActive(!!active);
  }, [active]);

  const handleChange = onChange
    ? () => {
        setActive(!isActive);
        onChange(!isActive);
      }
    : undefined;

  return (
    <div
      className={classnames(className, {
        [styles.isActive]: isActive,
        [styles.disabled]: disabled,
      })}
      onClick={handleChange}
      data-test={dataTest}
    >
      <div className={styles.checkbox} />
    </div>
  );
};

export default Checkbox;

import React, { useMemo } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import compact from 'lodash/compact';
import find from 'lodash/find';
import classnames from 'classnames';

import styles from 'AdvertiserPage/BillingHistory/BillingHistoryFilters/BillingHistoryFilters.pcss';
import { CampaignType } from 'AdvertiserPage/BillingHistory/data';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { CampaignsFilterQuery as QueryType } from 'GraphTypes/CampaignsFilterQuery.graphql';

const CampaignsFilterQuery = graphql`
  query CampaignsFilterQuery {
    campaigns {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  selectedItems?: CampaignType[];
  onCampaignSelect: (list?: CampaignType[]) => void;
}

type ListItem = {
  id: string;
  onClick: () => void;
  label?: string | null;
  msg?: string;
  active: boolean;
};

const CampaignsFilter: React.FC<Props> = (props) => {
  const { className, selectedItems = [], onCampaignSelect } = props;

  const label = useMemo(() => {
    const tempList = selectedItems.map((item) => item.name);

    return tempList.length
      ? { text: tempList.join(', ') }
      : { msg: 'campaigns.filter_campaigns.title.all' };
  }, [selectedItems]);

  const campaigns = useLazyLoadQuery<QueryType>(CampaignsFilterQuery, {});

  if (!campaigns) return null;

  const edges = campaigns.campaigns?.edges;

  const list: ListItem[] = compact(
    edges?.map((edge) => {
      if (!edge?.node) return null;

      const { id, name } = edge.node;
      const active = find(selectedItems, { id, name });

      const onClick = () => {
        onCampaignSelect([{ id, name }]);
      };

      return {
        id,
        onClick,
        label: name,
        active: !!active,
      };
    })
  );

  if (list?.length > 0) {
    list?.unshift({
      id: 'all',
      onClick: () => {
        onCampaignSelect(undefined);
      },
      msg: 'campaigns.filter_campaigns.title.all',
      active: selectedItems.length === 0,
    });
  }

  const isDirty = selectedItems && selectedItems.length > 0;

  return (
    <Dropdown
      value={<Text type="md" {...label} className={styles.selectorLabel} />}
      className={classnames(styles.selector, className, { [styles.dirty]: isDirty })}
    >
      <DropdownGroup labelMsg="campaigns.filter_campaigns.title" className={styles.group}>
        <div className={styles.dropdownList}>
          {list?.map((item) => {
            if (!item) return null;
            return (
              <DropdownGroupItem key={item.id} handleClick={item?.onClick}>
                <AlterButton
                  text={item.label}
                  msg={item.msg}
                  fluid
                  iconPosition="right"
                  icon={item.active ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );
};

export default CampaignsFilter;

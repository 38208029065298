import React from 'react';
import classnames from 'classnames';

import styles from './RangeRadio.pcss';

import Text from 'Atoms/Text/Text';
import { IRadioDropdownProps, RadioDropdown } from 'Components/ui/RadioDropdown/RadioDropdown';

export interface IRangeRadioProps {
  className?: string;
  first: Omit<IRadioDropdownProps, 'isDirty'>;
  second: Omit<IRadioDropdownProps, 'isDirty'>;
  isDirty?: boolean;
}

const RangeRadio: React.FC<IRangeRadioProps> = ({ className = '', first, second, isDirty }) => {
  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.range}>
        <RadioDropdown {...first} isDirty={isDirty} />
        <Text text="—" className={styles.seperator} />
        <RadioDropdown {...second} isDirty={isDirty} />
      </div>
    </div>
  );
};

export default RangeRadio;

import { graphql, useMutation } from 'react-relay';

import { ChangeSubscriptionPlanMutation } from 'GraphTypes/ChangeSubscriptionPlanMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const ChangeSubscriptionPlanMutation = graphql`
  mutation ChangeSubscriptionPlanMutation($input: Stripe_ChangeSubscriptionPlanInput!) {
    stripe {
      changeSubscriptionPlan(input: $input) {
        clientSecret
        result
      }
    }
  }
`;

export const useChangeSubscriptionPlanMutation: BaseMutation<ChangeSubscriptionPlanMutation> = (
  config
) => {
  const [changeSubscriptionPlan, loading] = useMutation<ChangeSubscriptionPlanMutation>(
    ChangeSubscriptionPlanMutation
  );

  const changeSubscriptionPlanCaller: BaseMutationExecFn<ChangeSubscriptionPlanMutation> = (
    execConfig
  ) => {
    changeSubscriptionPlan({
      ...config,
      ...execConfig,
    });
  };
  return [changeSubscriptionPlanCaller, loading];
};

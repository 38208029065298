import React, { Suspense } from 'react';

import styles from './SelectedProject.pcss';

import DialogStub from 'CommonPage/Chat/Dialog/DialogStub';
import Dialog from 'CommonPage/Chat/Dialog/Dialog';
import IconNew from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';

interface Props {
  projectId?: string;
  shipmentEnabled?: boolean;
}

const SelectedProject: React.FC<Props> = (props) => {
  const { projectId, shipmentEnabled } = props;
  return (
    <div className={styles.root}>
      {projectId && (
        <Suspense fallback={<DialogStub />}>
          <Dialog projectId={projectId} isAdvertiser shipmentEnabled={shipmentEnabled} />
        </Suspense>
      )}
      {!projectId && (
        <div className={styles.stub}>
          <IconNew name="Hand-Tap-Click" />
          <Text type="label" msg="projects.select" />
        </div>
      )}
    </div>
  );
};

export default SelectedProject;

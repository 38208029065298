import type { EntriesWithKey } from 'Types/utils';

/**
 * Correct keys typing for object entries
 */
const objectEntries = <K extends string, V>(obj: { [key in K]: V }) => {
  return Object.entries(obj) as EntriesWithKey<K, V>;
};

export { objectEntries };

import React from 'react';
import cn from 'classnames';

import styles from './EmptyListMessage.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';

interface Props {
  className?: string;
  iconProps?: Pick<IconProps, 'name'>;
  textProps?: Pick<TextProps, 'msg'>;
}

const EmptyListMessage: React.FC<Props> = (props) => {
  const { className, iconProps = { name: 'Save-list' }, textProps } = props;
  return (
    <div className={cn(className, styles.root)}>
      <Icon {...iconProps} />
      <Text {...textProps} type="md" />
    </div>
  );
};

export default EmptyListMessage;
export { EmptyListMessage };

// types

type IconProps = React.ComponentProps<typeof Icon>;
type TextProps = React.ComponentProps<typeof Text>;

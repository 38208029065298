import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import { RejectDeadlineChangeRequestInput } from 'GraphTypes/RejectDeadlineChangeRequestMutation.graphql';
import { RejectDeadlineChangeRequestMutation } from 'GraphTypes/RejectDeadlineChangeRequestMutation.graphql';
import { RejectDeadlineChangeRequestMutation$data } from 'GraphTypes/RejectDeadlineChangeRequestMutation.graphql';

const mutation = graphql`
  mutation RejectDeadlineChangeRequestMutation($input: RejectDeadlineChangeRequestInput!) {
    rejectDeadlineChangeRequest(input: $input) {
      ... on Error {
        message
        type
      }
      ... on RejectDeadlineChangeRequestPayload {
        newEventEdge {
          node {
            id
          }
        }
      }
      ... on ValidationError {
        message
        type
      }
    }
  }
`;

type fnType = (data: object) => void;

interface ProjectData {
  projectId: string;
}

export default (
  data: RejectDeadlineChangeRequestInput & ProjectData,
  resolve?: (response: RejectDeadlineChangeRequestMutation$data) => void,
  reject?: fnType
) => {
  const { deadlineChangeRequestId, projectId } = data;

  const id = uuid();
  const variables = {
    input: {
      id,
      deadlineChangeRequestId,
    },
  };
  commitMutation<RejectDeadlineChangeRequestMutation>(environment, {
    mutation,
    variables,
    updater: (store, data) => {
      const project = store.get(projectId);
      const result = data?.rejectDeadlineChangeRequest?.newEventEdge;

      if (result) {
        project?.setValue(null, 'deadlineChangeRequest');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response.rejectDeadlineChangeRequest?.newEventEdge && resolve) {
        resolve(response);
      } else if (response.rejectDeadlineChangeRequest?.message && reject) {
        reject(response.rejectDeadlineChangeRequest);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

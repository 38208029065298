import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';
import compact from 'lodash/compact';
import { useLocation } from 'react-router-dom';

import styles from './CampaignTabs.pcss';

import shopifyTutorialVideo from 'Videos/ShopifyTutorial/tutorial.mp4';
import { amplitude } from 'Helpers/amplitude';
import environment from 'Api/Environment';
import Tabs from 'Molecules/Tabs/Tabs';
import { CampaignTabsQuery as QueryType } from 'GraphTypes/CampaignTabsQuery.graphql';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';
import {
  RESULTS,
  CREATORS,
  AWAITING_LIST,
  ARCHIVED,
  INSTAGRAM,
  FACEBOOK,
  TIKTOK,
  APPLICANTS,
  SHIPMENT,
} from 'Constants/general';
import Text from 'Components/ui/Text/Text';
import { useShopifyOnboarding } from 'Hooks/useShopifyOnboarding';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import NewIcon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

const CampaignTabsQuery = graphql`
  query CampaignTabsQuery($id: ID!) {
    campaign(id: $id) {
      id
      private
      completion {
        id
      }
      type
      shopifyAuthorization {
        id
      }
      showShipmentTab
      organization {
        paymentAccount {
          currency
        }
        shopifyAuthorization {
          id
        }
        shopifyAuthorizations {
          edges {
            node {
              id
            }
          }
        }
      }
      brief {
        ... on V2Brief {
          productDelivery
        }
      }
      platform
      publications: creatives {
        totalCount
      }
      applicants: projects(
        stages: [ACCEPTED]
        archived: false
        withUnreadEvents: true
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
      hired: projects(
        stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED]
        archived: false
        withUnreadEvents: true
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
    }
  }
`;

interface Props {
  className?: string;
  section: string;
  campaignId: string;
  isAdminView: boolean;
  hasAcceptance: boolean;
  isCompleted: boolean;
  publishingRequired: boolean;
  currentUser: CampaignQuery$data['currentUser'];
  onSectionChange: (section: string) => void;
}

let tabs: JSX.Element;

const CampaignTabs: React.FC<Props> = (props) => {
  const { className, section, campaignId, currentUser, isAdminView, onSectionChange } = props;

  const canBrowseCreators = !!currentUser?.organization?.subscription?.canBrowseCreators;

  const { pathname } = useLocation();
  const { openDrawer } = useContext(DrawerContext);
  const isShipmentTab = useMemo(() => {
    return !!pathname.match(/\/campaigns\/[\w\d-]*\/shipment/);
  }, [pathname]);

  useEffect(() => {
    if (isShipmentTab) {
      onStatePassed('campaign_tab');
      setShowShipmentTooltip(false);
      setShowShipmentTutorialTooltip(!isPassed('shipment_tab'));
    } else {
      setShowShipmentTutorialTooltip(false);
    }
  }, [isShipmentTab]);

  useEffect(() => {
    if (isShipmentTab) {
      amplitude.sendEvent<315>({
        id: '315',
        category: 'campaign',
        name: 'shipment_click',
        param: { campaign_id: `${campaignId}` },
      });
      onSectionChange(SHIPMENT);
    }
  }, [pathname]);

  const { isPassed, onStatePassed } = useShopifyOnboarding();
  const [showShipmentTooltip, setShowShipmentTooltip] = useState<boolean>(
    !isPassed('campaign_tab') && !isShipmentTab
  );

  const [showShipmentTutorialTooltip, setShowShipmentTutorialTooltip] = useState<boolean>(
    !isPassed('shipment_tab') && isShipmentTab
  );

  const handleTooltipExploreClick = useCallback(() => {
    onSectionChange(SHIPMENT);
    setShowShipmentTutorialTooltip(!isPassed('shipment_tab'));
    setShowShipmentTooltip(false);
    onStatePassed('campaign_tab');
  }, []);

  const handleCloseTutorialClick = useCallback(() => {
    onStatePassed('shipment_tab');
    setShowShipmentTutorialTooltip(false);
  }, []);

  const handleCloseTooltip = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e?.stopPropagation();
    setShowShipmentTooltip(false);
    onStatePassed('campaign_tab');
  }, []);

  const handleConnectClick = () => {
    amplitude.sendEvent<316>({
      id: '316',
      category: 'shipment',
      name: 'connect_to_shopify_click',
      param: { campaign_id: campaignId },
    });
    localStorage.setItem('shopify-campaign-id', campaignId);
    openDrawer('shopify-auth');
  };

  return (
    <div className={`${className} ${styles.root}`}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={CampaignTabsQuery}
        variables={{
          id: campaignId,
        }}
        render={({ props: queryProps }) => {
          if (!queryProps) return tabs ? React.cloneElement(tabs, { currentId: section }) : null;

          const campaign = queryProps.campaign;
          const campaignId = campaign?.id;
          const platform = campaign?.platform;
          const campaignType = campaign?.type;
          const publicationsCount = campaign?.publications.totalCount;
          const productDelivery = campaign?.brief?.productDelivery;
          const showShipmentTab = campaign?.showShipmentTab;
          const hasShopifyAuth = campaign?.organization?.shopifyAuthorizations?.edges?.length > 0;
          const hasShopifyCampaignAuth = !!campaign?.shopifyAuthorization?.id;

          const resultTabPrefix = campaignType === 'CONTENT_CREATION' ? '.content' : '.performance';

          const items = compact([
            canBrowseCreators
              ? {
                  textData: { msg: 'filter_mark.awaiting_list' },
                  id: AWAITING_LIST,
                }
              : null,
            {
              textData: { msg: 'filter_mark.applicants' },
              id: APPLICANTS,
              count: campaign?.applicants.totalCount,
              notificationActive: true,
            },
            {
              textData: { msg: 'filter_mark.creator' },
              id: CREATORS,
              count: campaign?.hired.totalCount,
              notificationActive: true,
            },
            {
              textData: { msg: 'filter_mark.archived_list' },
              id: ARCHIVED,
            },
            { isDivider: true },
            productDelivery &&
            productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS' &&
            showShipmentTab
              ? {
                  textData: { msg: 'filter_mark.shipment' },
                  tooltip: !hasShopifyCampaignAuth ? (
                    <Tooltip
                      showByDefault={showShipmentTooltip}
                      id={'shipment_onboarding_tabs_tooltip'}
                      clickable
                      place={'top'}
                      tooltipClassName={styles.tooltipContainer}
                      customTooltip={
                        <div className={styles.shipmentTooltip}>
                          <Text msg="filter_mark.shipment.onboarding.proposal" type="md" />
                          <div
                            onClick={handleTooltipExploreClick}
                            className={styles.actionContainer}
                          >
                            <Text msg="filter_mark.shipment.onboarding.action" type="md" />
                            <div className={styles.closeIcon} onClick={handleCloseTooltip}>
                              <NewIcon name={'Close-small'} />
                            </div>
                          </div>
                        </div>
                      }
                    />
                  ) : null,
                  id: SHIPMENT,
                }
              : null,
            Number(publicationsCount) > 0 ||
            platform === FACEBOOK ||
            platform === INSTAGRAM ||
            platform === TIKTOK
              ? {
                  textData: { msg: `filter_mark${resultTabPrefix}` },
                  id: RESULTS,
                }
              : null,
          ]);

          const handleSectionSelect = (id: string) => {
            if (id === RESULTS) {
              amplitude.sendEvent<336>({
                id: '336',
                category: 'campaign',
                name: 'perfomance_click',
                param: { campaign_id: campaignId || '' },
              });
            }
            if (id === ARCHIVED) {
              amplitude.sendEvent({
                id: 249,
                category: 'pageview',
                name: 'campaign_archived',
              });
            }
            if (id === SHIPMENT) {
              amplitude.sendEvent<315>({
                id: '315',
                category: 'campaign',
                name: 'shipment_click',
                param: { campaign_id: `${campaignId}` },
              });
            }

            onSectionChange(id);
          };

          tabs = (
            <Tabs
              className={styles.tabs}
              currentItemId={section}
              items={items}
              onChange={handleSectionSelect}
            />
          );

          return (
            <>
              {tabs}
              {!hasShopifyAuth && (
                <Tooltip
                  id={'onboarding_shipment_tutorial'}
                  place={'left'}
                  tooltipClassName={styles.tutorialTooltip}
                  noArrow
                  clickable
                  customTooltip={
                    <div className={styles.tutorialTooltipContent}>
                      <Text
                        type={'h1'}
                        msg={'shipment.tutorial.title'}
                        className={styles.tooltipTitle}
                      />
                      <Text type="md" msg={'shipment.tutorial.description'} />
                      <video
                        src={shopifyTutorialVideo}
                        className={styles.gif}
                        autoPlay
                        muted
                        loop
                      />
                      <div onClick={handleConnectClick} className={styles.link}>
                        <Text type="md" msg={'shipment.tutorial.action'} />
                      </div>
                      <div className={styles.closeIcon} onClick={handleCloseTutorialClick}>
                        <NewIcon name={'Close-small'} />
                      </div>
                    </div>
                  }
                  showByDefault={showShipmentTutorialTooltip}
                />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default track({
  section: 'campaign_tabs',
})(CampaignTabs);

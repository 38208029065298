import React from 'react';
import { useIntl } from 'react-intl';
import track, { useTracking } from 'react-tracking';

import styles from './Date.css';

import DatePicker from 'Components/ui/DatePicker/DatePicker';

const Date = (props) => {
  const { onChange, name, value } = props;

  const intl = useIntl();
  const tracking = useTracking();

  const handleChange = (date) => {
    tracking.trackEvent({
      event: 'selected',
      payload: {
        value: date,
      },
    });
    onChange({ [name]: date });
  };

  return (
    <DatePicker
      inputClassName={styles.input}
      customInputProps={{
        rightIcon: 'Calendar-schedule',
        hideCloseIcon: true,
      }}
      reactDatePickerProps={{
        selected: value,
        dateFormat: 'MMM dd, yyyy',
        placeholderText: intl.formatMessage({ id: `search_section.form.${name}` }),
        onChange: handleChange,
      }}
    />
  );
};

export default track((props) => ({
  element: props.name,
}))(Date);

import React, { useRef } from 'react';
import track from 'react-tracking';

import styles from './SearchSection.css';
import Header from './Header/Header';
import SearchContent from './SearchContent/SearchContent';

import UpButton from 'Molecules/UpButton/UpButton';

const SearchSection = (props) => {
  const mediaplanEl = useRef(null);

  const handleUpClick = () => {
    mediaplanEl.current.scrollTop = 0;
  };

  const {
    admin,
    className,
    campaignId,
    currency,
    campaign,
    isYoutube,
    hasContentCreation,
    organizationId,
    showUnlockCreatorBanner,
  } = props;
  return (
    <div className={className} ref={mediaplanEl} id="resultsContainer">
      <UpButton className={styles.up} containerId="resultsContainer" onClick={handleUpClick} />
      <div className={styles.root}>
        <Header campaign={campaign} className={styles.header} />
        <SearchContent
          campaignId={campaignId}
          campaignPlatform={campaign?.platform}
          campaignType={campaign?.type}
          currency={currency}
          admin={admin}
          isYoutube={isYoutube}
          organizationId={organizationId}
          hasContentCreation={hasContentCreation}
          showUnlockCreatorBanner={showUnlockCreatorBanner}
          searchQuery={campaign?.searchQuery}
        />
      </div>
    </div>
  );
};

export default track({
  section: 'search',
})(SearchSection);

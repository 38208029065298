/**
 * @generated SignedSource<<963fa98f7dad3d8420c33ced73c157dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkInsenseContentReviewAsFailedInput = {
  clientMutationId?: string | null | undefined;
  projectId: string;
  text: string;
};
export type MarkInsenseContentReviewAsFailedMutation$variables = {
  input: MarkInsenseContentReviewAsFailedInput;
};
export type MarkInsenseContentReviewAsFailedMutation$data = {
  readonly markInsenseContentReviewAsFailed: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type MarkInsenseContentReviewAsFailedMutation = {
  response: MarkInsenseContentReviewAsFailedMutation$data;
  variables: MarkInsenseContentReviewAsFailedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkInsenseContentReviewAsFailedPayload",
    "kind": "LinkedField",
    "name": "markInsenseContentReviewAsFailed",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkInsenseContentReviewAsFailedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkInsenseContentReviewAsFailedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dfb4f7b39cbd4c3561657e265c92d280",
    "id": null,
    "metadata": {},
    "name": "MarkInsenseContentReviewAsFailedMutation",
    "operationKind": "mutation",
    "text": "mutation MarkInsenseContentReviewAsFailedMutation(\n  $input: MarkInsenseContentReviewAsFailedInput!\n) {\n  markInsenseContentReviewAsFailed(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "0dd32d1916a92eb3c260015f013dcc17";

export default node;

import stringHash from 'string-hash';

export const colors = [
  '#EB5757',
  '#F5744A',
  '#FF923D',
  '#FFAD1E',
  '#FFC700',
  '#CBCE32',
  '#92BF49',
  '#4DB856',
  '#22B164',
];

const COLOR_STEP = 12.5;

export const createColor = (value, direction) => {

  const colorPosition = Math.round(value / COLOR_STEP);
  const colorLength = colors.length - 1;
  const colorIndex = direction ? colorPosition : colorLength - colorPosition;
  const color = colors[colorIndex];

  return color;
};


const gradients = [
  'linear-gradient(135deg, #FA709A 0%, #FEE140 100%)',
  'linear-gradient(135deg, #F2F47E 0%, #68E9A3 100%)',
  'linear-gradient(135deg, #85F1EA 0%, #F7C2D3 100%)',
  'linear-gradient(135deg, #21D4FD 0%, #B721FF 100%)',
  'linear-gradient(135deg, #4236DD 0%, #E26DFF 100%)',
  'linear-gradient(135deg, #B236DD 0%, #FF6DCD 100%)',
  'linear-gradient(135deg, #FF3FB2 0%, #FFA077 100%)',
  'linear-gradient(135deg, #73FFEE 0%, #4068CD 51.93%, #DD63FC 100%)',
  'linear-gradient(135deg, #D1FFF8 0%, #63D67C 54.14%, #CDEE6D 100%)',
  'linear-gradient(135deg, #AFF1FF 0%, #5899E5 50.28%, #4FF4FF 100%)',
  'linear-gradient(135deg, #BFEAFF 0%, #262EFF 52.49%, #51EAFF 100%)',
  'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)',
  'linear-gradient(135deg, #4158D0 0%, #C850C0 52.49%, #FFCC70 100%)',
  'linear-gradient(135deg, #FFCFCF 0%, #FA709A 51.38%, #FEE140 100%)',
  'linear-gradient(135deg, #4CE9FF 0%, #FF7474 100%)',
  'linear-gradient(135deg, #63D6A6 0%, #6DEEE7 100%)',
  'linear-gradient(135deg, #5877E5 0%, #6DFFE5 100%)',
  'linear-gradient(135deg, #268AFF 0%, #51EAFF 100%)',
  'linear-gradient(135deg, #8785F1 0%, #F7C2D3 100%)',
  'linear-gradient(135deg, #E03BDA 0%, #6DEDFF 100%)',
  'linear-gradient(135deg, #FF5353 0%, #C465FF 100%)',
];

export const createGradientByName = (words) => {
  const sentence = words.join(' ');
  const sentenceInteger = stringHash(sentence);
  const colorIndex = sentenceInteger % gradients.length;

  return gradients[colorIndex];
};

export const getCreativeStateColor = (state) => {
  switch (state) {
    case 'DRAFT':
      return { textColor: 'grayDog', btnStyle: { color: 'normal' } };
    case 'PENDING_APPROVAL':
      return { textColor: 'yellow', btnStyle: { mainColor: 'yellow' } };
    case 'REVISION_REQUESTED':
      return { textColor: 'base', btnStyle: { color: 'primary' } };
    case 'APPROVED':
      return { textColor: 'green', btnStyle: { mainColor: 'green' } };
    default:
      return { textColor: 'grayDog', btnStyle: { color: 'normal' } };
  }
};

export const getAvgRateColor = (rate) => {
  if (!rate) return 'grayDog';

  if (rate < 1.8) {
    return 'red';
  } else if (rate < 2.6) {
    return 'orange';
  } else if (rate < 3.8) {
    return 'yellow';
  } else if (rate < 4.5) {
    return 'olive';
  }

  return 'green';

};

export const getRateColor = (rate) => {
  if (!rate) return 'grayDog';

  const list = ['red', 'orange', 'yellow', 'olive', 'green'];

  return list[rate - 1];
};
import React, { useState, useMemo, useEffect, useContext } from 'react';
import omit from 'lodash/omit';

import { BriefContext } from '../../../../../Brief.Context';

import styles from './ScreeningQuestion.pcss';

import { useUpdateBriefScreeningQuestionMutation } from 'Mutations/UpdateBriefScreeningQuestion.Mutation';
import { useDeleteBriefScreeningQuestionMutation } from 'Mutations/DeleteBriefScreeningQuestion.Mutation';
import Textarea from 'Components/ui/Textarea/Textarea';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Button from 'Components/ui/Button/Button';
import TextButton from 'Components/ui/TextButton/TextButton';
import { Brief_ScreeningQuestion_Type } from 'GraphTypes/ScreeningQuestions_brief.graphql';

const types: Brief_ScreeningQuestion_Type[] = ['OPEN_ENDED', 'BINARY'];
const preferredValues: boolean[] = [true, false];

interface Props {
  id?: string;
  briefId: string;
  index?: number;
  text?: string;
  editMode?: boolean;
  isNew?: boolean;
  preferredValue?: boolean | null;
  type?: Brief_ScreeningQuestion_Type;
  onQuestionUpdate?: () => void;
  onQuestionDelete?: () => void;
}

const ScreeningQuestion: React.FC<Props> = (props) => {
  const {
    id,
    index,
    briefId,
    isNew,
    text: defaultText,
    type: defaultType,
    preferredValue: defaultPreferredValue,
    editMode: defaultEditMode,
    onQuestionUpdate,
  } = props;

  const [editMode, setEditMode] = useState(defaultEditMode || false);
  const [text, setText] = useState(defaultText || '');
  const [type, setType] = useState(defaultType);
  const [preferredValue, setPreferredValue] = useState(defaultPreferredValue);

  const [briefState, briefDispatch] = useContext(BriefContext);

  const { showErrors } = briefState;

  useEffect(() => {
    const name = isNew ? 'new' : id;
    const newUpdated = text || type || typeof preferredValue === 'boolean';

    briefDispatch({
      key: 'screeningQuestions',
      value: { ...briefState.screeningQuestions, [name || '']: isNew ? !newUpdated : !editMode },
    });
  }, [editMode, text, type, preferredValue]);

  useEffect(() => {
    if (Object.values(briefState.screeningQuestions).includes(false)) {
      briefDispatch({ key: 'showErrors', value: false });
    }
  }, [briefState.screeningQuestions]);

  const prefferedValueValid = useMemo(() => {
    return type === 'BINARY' ? typeof preferredValue === 'boolean' : true;
  }, [type, preferredValue]);

  const isValid = useMemo(() => {
    return text && type && prefferedValueValid;
  }, [text, type, preferredValue]);

  const [updateBriefScreeningQuestion, loading] = useUpdateBriefScreeningQuestionMutation({
    variables: {
      input: {
        screeningQuestion: {
          v2BriefId: briefId,
          type: type || 'OPEN_ENDED',
          text,
          preferredValue,
          id,
        },
      },
    },
    onCompleted: () => {
      briefDispatch({
        key: 'screeningQuestions',
        value: { ...briefState.screeningQuestions, ['new']: true },
      });
      onQuestionUpdate?.();
      setEditMode(false);
    },
  });

  const [deleteBriefScreeningQuestionMutation, deleteLoading] =
    useDeleteBriefScreeningQuestionMutation({
      variables: {
        input: {
          screeningQuestionId: id || '',
        },
      },
      onCompleted: () => {
        if (id) {
          briefDispatch({
            key: 'screeningQuestions',
            value: omit({ ...briefState.screeningQuestions, ['new']: true }, [id]),
          });
        }
        setEditMode(false);
      },
    });

  const handleSaveClick = () => {
    updateBriefScreeningQuestion();
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleDeleteClick = () => {
    deleteBriefScreeningQuestionMutation();
  };

  const handleClearParams = () => {
    setText('');
    setType(undefined);
    setPreferredValue(null);
    briefDispatch({
      key: 'screeningQuestions',
      value: { ...briefState.screeningQuestions, ['new']: true },
    });
  };

  if (!editMode) {
    const descr =
      type === 'BINARY' && preferredValue !== undefined && preferredValue !== null
        ? `brief_template.screening_questions.question.type.select.preferred.descr.${preferredValue.toString()}`
        : 'brief_template.screening_questions.question.type.open_ended';
    return (
      <div className={styles.question}>
        <div className={styles.details}>
          <Text type="md" text={`${index}. ${text}`} className={styles.finalText} />
          <Text type="md" msg={descr} className={styles.defaultType} />
        </div>
        <div className={styles.editControls}>
          <TextButton icon="Edit" onClick={handleEditClick} className={styles.editControl} />
          <TextButton
            icon="Close-small"
            onClick={handleDeleteClick}
            className={styles.editControl}
          />
        </div>
      </div>
    );
  }

  const answerTypeLabel = type ? (
    <>
      <Text
        type="s"
        msg="brief_template.screening_questions.question.type"
        className={styles.label}
      />
      <Text
        type="md"
        msg={`brief_template.screening_questions.question.type.${type.toLowerCase()}`}
        className={styles.selectedType}
      />
    </>
  ) : (
    <Text
      type="md"
      msg="brief_template.screening_questions.question.type"
      className={styles.defaultType}
    />
  );

  const answerPrefferedValueLabel =
    preferredValue === false || preferredValue === true ? (
      <>
        <Text
          type="s"
          msg="brief_template.screening_questions.question.type.select.preferred"
          className={styles.label}
        />
        <Text
          type="md"
          msg={`brief_template.screening_questions.question.type.select.preferred.${preferredValue.toString()}`}
          className={styles.selectedType}
        />
      </>
    ) : (
      <Text
        type="md"
        msg="brief_template.screening_questions.question.type.select.preferred"
        className={styles.defaultType}
      />
    );

  return (
    <div>
      <Textarea
        labelMsg="brief_template.screening_questions.question.placeholder"
        placeholderMsg="brief_template.screening_questions.question.example"
        maxLength={100}
        withCounter={true}
        value={text}
        bordered
        forceLabelShow
        className={styles.text}
        handleChange={setText}
        error={showErrors && !text}
      />
      {showErrors && !text && (
        <Text
          type="md"
          msg="brief_template.screening_questions.question.question.error"
          className={styles.error}
        />
      )}
      <div className={styles.controls}>
        <Dropdown
          bordered
          value={answerTypeLabel}
          hasValue={!!type}
          className={styles.typeSelect}
          error={showErrors && !type}
        >
          <DropdownGroup className={styles.dropdownGroup}>
            {types.map((item) => {
              const handleTypeClick = () => {
                setType(item);
                if (item === 'OPEN_ENDED') {
                  setPreferredValue(null);
                }
              };
              return (
                <DropdownGroupItem handleClick={handleTypeClick} key={item}>
                  <AlterButton
                    msg={`brief_template.screening_questions.question.type.${item.toLowerCase()}`}
                    fluid
                    iconPosition="right"
                    icon={type === item ? 'Check' : null}
                  />
                </DropdownGroupItem>
              );
            })}
          </DropdownGroup>
        </Dropdown>
        {type === 'BINARY' && (
          <Dropdown
            bordered
            value={answerPrefferedValueLabel}
            hasValue={typeof preferredValue === 'boolean'}
            className={styles.typeSelect}
            error={showErrors && !prefferedValueValid}
          >
            <DropdownGroup className={styles.dropdownGroup}>
              {preferredValues.map((item) => {
                const handlePrefferedValueClick = () => {
                  setPreferredValue(item);
                };
                return (
                  <DropdownGroupItem handleClick={handlePrefferedValueClick} key={item.toString()}>
                    <AlterButton
                      msg={`brief_template.screening_questions.question.type.select.preferred.${item.toString()}`}
                      fluid
                      iconPosition="right"
                      icon={preferredValue === item ? 'Check' : null}
                    />
                  </DropdownGroupItem>
                );
              })}
            </DropdownGroup>
          </Dropdown>
        )}
        <Button
          color="black"
          msg="brief_template.screening_questions.question.save"
          onClick={handleSaveClick}
          loading={loading || deleteLoading}
          disabled={!isValid}
          className={styles.control}
        />
        <Button
          color="white"
          icon="Trash-delele"
          loading={loading || deleteLoading}
          onClick={isNew ? handleClearParams : handleDeleteClick}
          className={styles.control}
        />
      </div>
      {showErrors && (!type || (type === 'BINARY' && !prefferedValueValid)) && (
        <Text
          type="md"
          msg="brief_template.screening_questions.question.type.error"
          className={styles.error}
        />
      )}
      {showErrors && editMode && isValid && (
        <Text
          type="md"
          msg="brief_template.screening_questions.question.error"
          className={styles.error}
        />
      )}
    </div>
  );
};

export default ScreeningQuestion;

import { useMemo } from 'react';
import { graphql, useSubscription } from 'react-relay';

const useDownloadReportSubscription = (): void => {
  const config = useMemo(
    () => ({
      subscription: graphql`
        subscription useDownloadReportSubscription {
          reportingReportUpdated {
            state
            downloadUrl
            expiresAt
            filename
          }
        }
      `,
      variables: {},
    }),
    []
  );

  return useSubscription(config);
};

export default useDownloadReportSubscription;

import React, {
  FormEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  ChangeEventHandler,
} from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import commonStyles from '../Common.pcss';
import { getCompanyRoleType, GetCompanyRoleTypeResultItem } from '../utils';
import { SignUpCompleteContext, SignUpType, StepEnum } from '../../Context';

import styles from './UserDetailsStep.pcss';

import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import InputPreset from 'Components/ui/InputPreset';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import type { HubSpotProperties } from 'Analytics/engines/Hubspot';
import { DOWNLOAD_APP_ROUTE } from 'Constants/general';

type Props = {
  className?: string;
  validateKey?: number;
};

const UserDetailsStep: React.FC<Props> = (props) => {
  const { className, validateKey = 0 } = props;

  const { userData, signUpType, setUserData, validity } = useContext(SignUpCompleteContext);

  const [validityState, setValidityState] = useState<ValidityState>({
    firstName: null,
    lastName: null,
    organizationName: null,
    companyRole: null,
    websiteUrl: null,
  });

  useEffect(() => {
    if (validateKey > 0) {
      const fields = validity[signUpType][StepEnum.UserDetails]?.fields;
      setValidityState({
        firstName: fields?.firstName === true,
        lastName: fields?.lastName === true,
        organizationName: fields?.organizationName === true,
        companyRole: fields?.companyRole === true,
        websiteUrl: fields?.websiteUrl === true,
      });
    }
  }, [validateKey]);

  const intl = useIntl();

  const formMsgPrefix = 'signup_complete.advertiser.steps.user_details.form';
  const isInvitedTeammateSigningUp = signUpType === SignUpType.InviteTeammate;

  const companyRoles = useMemo<CompanyRoleTypes>(() => {
    const result = getCompanyRoleType(intl);
    return {
      map: result,
      entries: [...result.entries()],
    };
  }, []);

  const companyRoleDropdownValue = useMemo<JSX.Element>(() => {
    const isSelected = Boolean(
      companyRoles.map.get(userData[StepEnum.UserDetails].companyRole.value)?.label
    );
    return (
      <Text
        className={cn(styles.dropdownValue, {
          [styles.selected]: isSelected,
        })}
        dataTest="input:companyRole"
        type="sm"
        text={
          companyRoles.map.get(userData[StepEnum.UserDetails].companyRole.value)?.label ||
          intl.formatMessage({ id: `${formMsgPrefix}.label.job_role` })
        }
      />
    );
  }, [userData[StepEnum.UserDetails].companyRole]);

  const handleFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();
  }, []);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData(StepEnum.UserDetails, {
      [name]: { value, hubSpotValue: value },
    });
    setValidityState((prev) => ({
      ...prev,
      [name]: null,
    }));
  }, []);

  const handleInputBlur = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    setUserData(StepEnum.UserDetails, {
      [name]: { value, hubSpotValue: value },
    });
  }, []);

  const handleResetInputValue = useCallback<HandleResetInputValue>((name) => {
    setUserData(StepEnum.UserDetails, {
      [name]: { value: '', hubSpotValue: '' },
    });
    setValidityState((prev) => ({
      ...prev,
      [name]: null,
    }));
  }, []);

  return (
    <div className={cn(styles.root, className)}>
      <div className={commonStyles.header}>
        <Text
          type="h1"
          msg="signup_complete.advertiser.steps.user_details.title"
          className={commonStyles.title}
          dataTest="text:title"
        />
        {signUpType === SignUpType.Standard && (
          <Text
            type="md"
            msg="signup_complete.advertiser.steps.user_details.subtitle"
            className={commonStyles.subtitle}
            dataTest="text:subtitle"
            formatValues={{
              link: (
                <Link to={DOWNLOAD_APP_ROUTE}>
                  <Text
                    type="md"
                    msg="signup_complete.advertiser.steps.user_details.subtitle.link"
                    className={commonStyles.subtitleLink}
                  />
                </Link>
              ),
            }}
          />
        )}
      </div>
      <div className={styles.content}>
        <form action="#" onSubmit={handleFormSubmit}>
          <div className={styles.twoColRow}>
            <InputPreset className={styles.inputPreset}>
              <Input
                bordered
                name="firstName"
                dataTest="input:firstName"
                error={validityState.firstName === false}
                value={userData[StepEnum.UserDetails].firstName.value}
                className={styles.formInput}
                autoComplete="given-name"
                labelMsg={`${formMsgPrefix}.label.first_name`}
                placeholderMsg={`${formMsgPrefix}.label.first_name`}
                onInput={handleInputChange}
                onBlur={handleInputBlur}
                onResetValue={() => {
                  handleResetInputValue('firstName');
                }}
              />
            </InputPreset>
            <InputPreset className={styles.inputPreset}>
              <Input
                bordered
                name="lastName"
                dataTest="input:lastName"
                error={validityState.lastName === false}
                value={userData[StepEnum.UserDetails].lastName.value}
                className={styles.formInput}
                autoComplete="family-name"
                labelMsg={`${formMsgPrefix}.label.last_name`}
                placeholderMsg={`${formMsgPrefix}.label.last_name`}
                onInput={handleInputChange}
                onBlur={handleInputBlur}
                onResetValue={() => {
                  handleResetInputValue('lastName');
                }}
              />
            </InputPreset>
          </div>
          <InputPreset>
            <Dropdown
              bordered
              error={validityState.companyRole === false}
              className={styles.dropdown}
              value={companyRoleDropdownValue}
            >
              <DropdownGroup className={styles.dropdownGroup}>
                {companyRoles.entries.map(([companyRoleId, item]) => (
                  <DropdownItem
                    key={companyRoleId}
                    handleClick={() => {
                      const hubSpotValue = companyRoles.map.get(companyRoleId)
                        ?.hubSpotValue as HubSpotProperties['company_role_new'];
                      setUserData(StepEnum.UserDetails, {
                        companyRole: { value: companyRoleId, hubSpotValue },
                      });
                      setValidityState((prev) => ({ ...prev, companyRole: null }));
                    }}
                  >
                    <AlterButton fluid msg={item.msg} dataTest="button:dropdownItemValue" />
                  </DropdownItem>
                ))}
              </DropdownGroup>
            </Dropdown>
          </InputPreset>
          {!isInvitedTeammateSigningUp && (
            <InputPreset>
              <Input
                bordered
                name="organizationName"
                dataTest="input:organizationName"
                error={validityState.organizationName === false}
                value={userData[StepEnum.UserDetails].organizationName.value}
                className={styles.formInput}
                autoComplete="organization"
                labelMsg={`${formMsgPrefix}.label.company_name`}
                placeholderMsg={`${formMsgPrefix}.label.company_name`}
                onInput={handleInputChange}
                onBlur={handleInputBlur}
                onResetValue={() => {
                  handleResetInputValue('organizationName');
                }}
              />
            </InputPreset>
          )}
          {!isInvitedTeammateSigningUp && (
            <InputPreset>
              <Input
                bordered
                name="websiteUrl"
                dataTest="input:websiteUrl"
                error={validityState.websiteUrl === false}
                value={userData[StepEnum.UserDetails].websiteUrl.value}
                className={styles.formInput}
                autoComplete="url"
                labelMsg={`${formMsgPrefix}.label.company_website`}
                placeholderMsg={`${formMsgPrefix}.label.company_website`}
                onInput={handleInputChange}
                onBlur={handleInputBlur}
                onResetValue={() => {
                  handleResetInputValue('websiteUrl');
                }}
              />
              {validityState.websiteUrl === false && (
                <Text
                  type="label"
                  className={styles.errorMsg}
                  msg={`${formMsgPrefix}.error.company_website`}
                />
              )}
            </InputPreset>
          )}
        </form>
      </div>
    </div>
  );
};

export default UserDetailsStep;
export { UserDetailsStep };

// types

type CompanyRoleTypes = {
  map: ReturnType<typeof getCompanyRoleType>;
  entries: [string, GetCompanyRoleTypeResultItem][];
};

type ValidityState = {
  firstName: boolean | null;
  lastName: boolean | null;
  organizationName: boolean | null;
  companyRole: boolean | null;
  websiteUrl: boolean | null;
};

type HandleResetInputValue = (
  name: 'firstName' | 'lastName' | 'organizationName' | 'websiteUrl'
) => void;

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import { CreatePortfolioItemMutation, CreatePortfolioItemInput } from 'GraphTypes/CreatePortfolioItemMutation.graphql';

const mutation = graphql`
  mutation CreatePortfolioItemMutation($input: CreatePortfolioItemInput!) {
    createPortfolioItem(input: $input) {
      clientMutationId
    }
  }
`;

interface DataInput {
  creatorId?: string,
}

export default (data: CreatePortfolioItemInput & DataInput, resolve?: () => void, reject?: (error: object) => void) => {
  const { creatorId, fileId } = data;
  const id = uuid();
  const variables = {
    input: {
      id,
      creatorId,
      fileId,
    }
  };

  commitMutation<CreatePortfolioItemMutation>(environment, {
    mutation,
    variables,
    updater: store => {
      if (creatorId) {
        const creator = store.get(creatorId);
        const fileItem = store.get(fileId);
        if (creator) {
          const items = ConnectionHandler.getConnection(creator, 'Profile_portfolioItems');
          if (items && fileItem) {
            const date = new Date();
            const portfolioItem = store.create(id, 'PortfolioItem');
            portfolioItem.setValue(id, 'id');
            portfolioItem.setLinkedRecord(fileItem, 'file');
            portfolioItem.setValue(date.toISOString(), 'createdAt');

            const edge = ConnectionHandler.createEdge(
              store,
              items,
              portfolioItem,
              'PortfolioItemEdge'
            );
            ConnectionHandler.insertEdgeBefore(items, edge);
          }
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);
      }
      if (response && resolve) {
        resolve();
      }
    },
  });
};

import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => ({
  border: isDragging ? '1px solid #F2F2F2' : '',
  borderRadius: isDragging ? '4px' : '',
  ...draggableStyle,
});

class DraggingHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const newEdges = nextProps.items;
    this.setState({ items: newEdges });
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { draggableId, destination: { index } } = result;
    const { dragCallback } = this.props;
    dragCallback({ id: draggableId, position: index });

    const { items } = this.state;

    const list = this.reorder(
      items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items: list,
    });
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  render() {
    const { children } = this.props;
    const { items } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      key={`draggable_${item.id}`}
                    >
                      {React.cloneElement(children, { key: `row_${item.id}`, ...item })}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext >
    );
  }
}

export default DraggingHandler;
import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  DeleteBriefCreativeInput,
  DeleteBriefCreativeMutation$data,
  DeleteBriefCreativeMutation,
} from 'GraphTypes/DeleteBriefCreativeMutation.graphql';

const mutation = graphql`
  mutation DeleteBriefCreativeMutation($input: DeleteBriefCreativeInput!) {
    deleteBriefCreative(input: $input) {
      clientMutationId
      brief {
        ... on V2Brief {
          creatives(first: 2147483647) @connection(key: "Creatives_creatives") {
            edges {
              node {
                id
                ...CreativeAsset_briefCreative
              }
            }
            totalCount
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

type Data = {
  briefId?: string;
};

export default (
  data: DeleteBriefCreativeInput & Data,
  resolve?: (response: DeleteBriefCreativeMutation$data) => void,
  reject?: fnType
) => {
  const { id, briefId } = data;

  const variables = {
    input: {
      id,
    },
  };

  commitMutation<DeleteBriefCreativeMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      if (!briefId || !id) return;
      const brief = store.get(briefId);
      if (!brief) return;
      const creatives = ConnectionHandler.getConnection(brief, 'Creatives_creatives');
      if (!creatives) return;
      ConnectionHandler.deleteNode(creatives, id);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

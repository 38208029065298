import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';
import { CampaignSummaryUpdatedSubscription } from 'GraphTypes/CampaignSummaryUpdatedSubscription.graphql';

const subscription = graphql`
  subscription CampaignSummaryUpdatedSubscription {
    campaignSummaryUpdated {
      projectsSummary: projects(archived: false, orderBy: LAST_ADDED) {
        totalCount
        summary {
          currency
          totalPrice
        }
      }
    }
  }
`;

export default () => {
  requestSubscription<CampaignSummaryUpdatedSubscription>(environment, {
    subscription,
    variables: {},
  });
};

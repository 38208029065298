import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  RequestPriceChangeMutation,
  RequestPriceChangeInput,
  RequestPriceChangeMutation$data,
} from 'GraphTypes/RequestPriceChangeMutation.graphql';

const mutation = graphql`
  mutation RequestPriceChangeMutation($input: RequestPriceChangeInput!) {
    requestPriceChange(input: $input) {
      project {
        priceChangeRequest {
          id
          price
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: RequestPriceChangeInput,
  resolve?: (response: RequestPriceChangeMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const variables = {
    input: {
      id,
      ...data,
    },
  };
  commitMutation<RequestPriceChangeMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<954fb7fc600d2557d22516f0ba47da99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatorImportPlatform = "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type OutreachTransport = "GMAIL" | "SENDGRID" | "%future added value";
export type ImportOutreachBatchInput = {
  activateAfterCompletion?: boolean | null | undefined;
  campaignId: string;
  clientMutationId?: string | null | undefined;
  csvFilename: string;
  gmailAuthorizationId?: string | null | undefined;
  id?: string | null | undefined;
  platform: CreatorImportPlatform;
  rows: ReadonlyArray<OutreachBatchRowInput>;
  senderEmail?: string | null | undefined;
  senderName?: string | null | undefined;
  templateId: string;
  transport?: OutreachTransport | null | undefined;
};
export type OutreachBatchRowInput = {
  email: string;
  username: string;
};
export type ImportOutreachBatchQuery$variables = {
  input: ImportOutreachBatchInput;
};
export type ImportOutreachBatchQuery$data = {
  readonly importOutreachBatch: {
    readonly __typename: "ImportOutreachBatchPayload";
    readonly batch: {
      readonly id: string;
    };
  } | {
    readonly __typename: "NotFoundError";
    readonly message: string;
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type ImportOutreachBatchQuery = {
  response: ImportOutreachBatchQuery$data;
  variables: ImportOutreachBatchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "importOutreachBatch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatch",
            "kind": "LinkedField",
            "name": "batch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ImportOutreachBatchPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "NotFoundError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ValidationError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportOutreachBatchQuery",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportOutreachBatchQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0b91816ddafd62bbe7219f8df52cf490",
    "id": null,
    "metadata": {},
    "name": "ImportOutreachBatchQuery",
    "operationKind": "mutation",
    "text": "mutation ImportOutreachBatchQuery(\n  $input: ImportOutreachBatchInput!\n) {\n  importOutreachBatch(input: $input) {\n    __typename\n    ... on ImportOutreachBatchPayload {\n      batch {\n        id\n      }\n    }\n    ... on NotFoundError {\n      message\n    }\n    ... on ValidationError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37b007a518a44ab1aa9f8fc025172cd8";

export default node;

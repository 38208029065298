import React, { PropsWithChildren } from 'react';

import styles from './PrimaryCategoryCheckbox.pcss';

import Text from 'Atoms/Text/Text';
import Checkbox from 'Atoms/Checkbox/Checkbox';

type Props = PropsWithChildren<{
  name: string;
  value: boolean;
  onChange: (data: Record<string, boolean>) => void;
}>;

const PrimaryCategoryCheckbox: React.FC<Props> = ({ name, value, onChange }) => {
  const handleChange = () => {
    const newValue = !value;
    onChange({ [name]: newValue });
  };

  return (
    <div className={styles.root}>
      <div className={styles.checkboxWrapper}>
        <Checkbox active={value} className={styles.icon} onChange={handleChange} />
        <Text msg="search_section.seatch_params.primaryCategoryTitle" overflow />
      </div>

      <Text msg="search_section.seatch_params.primaryCategoryText" className={styles.label} />
    </div>
  );
};

export default PrimaryCategoryCheckbox;

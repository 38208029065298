import React from 'react';

import styles from './UploadProgress.css';

import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';

interface Props {
  progressWidth?: number,
  restCount: number,
  totalCount: number,
  title: string,
  onClose?: () => void,
}



const UploadProgress: React.FC<Props> = props => {
  const { progressWidth, restCount, totalCount, title, onClose } = props;

  return (
    <div className={styles.root}>
      {!totalCount && (
        <div>
          <div
            className={styles.progress}
            style={{ width: progressWidth ? `${progressWidth}px` : '100%' }}
          />
          <Text
            color="white"
            weight="700"
            msg="general.uploading"
            className={styles.progressText}
          />
        </div>
      )}
      {
        totalCount > 0 && (
          <div>
            <div
              className={styles.progress}
              style={{ width: progressWidth ? `${progressWidth * (restCount / totalCount)}px` : `${100 * (restCount / totalCount)}%` }}
            />
            <Text
              color="white"
              weight="700"
              msg={title}
              values={{ count: `${restCount}/${totalCount}` }}
              className={styles.progressText}
            />
          </div>
        )
      }
      {onClose && <div className={styles.closeBtn}><TextButton mainColor="white" iconName="close" onClick={onClose} /></div>}
    </div>
  );
};

export default UploadProgress;
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { PreloadedQuery, graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import styles from './ProjectAttachments.pcss';
import { PublicationSelectionAttachmentsQuery } from './ProjectAttachmentsWrapper';

import emptyListImg from 'Images/modals/empty_files.svg';
import { setModal } from 'Actions/Modal.Actions';
import { MEDIA } from 'Constants/modals';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { getReadableDuration } from 'Util/mediaTime';
import { FinishDealWrapperContext } from 'Molecules/FinishDealWrapper/FinishDealWrapperContext';
import {
  ProjectAttachmentsCampaign_files$data,
  ProjectAttachmentsCampaign_files$key,
} from 'GraphTypes/ProjectAttachmentsCampaign_files.graphql';
import { PublicationSelectionAttachmentsQuery as QueryType } from 'GraphTypes/PublicationSelectionAttachmentsQuery.graphql';

interface Props {
  queryReference: PreloadedQuery<QueryType>;
}

const ProjectAttachments: React.FC<Props> = (props) => {
  const { queryReference } = props;

  const scrollContainer = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const finishDealContext = useContext(FinishDealWrapperContext);

  const selectedFiles = finishDealContext?.selectedFiles || {};
  const setSelectedFiles = finishDealContext?.setSelectedFiles;

  const queryData = usePreloadedQuery<QueryType>(
    PublicationSelectionAttachmentsQuery,
    queryReference
  );

  const { data, loadPrevious, isLoadingPrevious, hasPrevious } = usePaginationFragment<
    QueryType,
    ProjectAttachmentsCampaign_files$key
  >(
    graphql`
      fragment ProjectAttachmentsCampaign_files on Query
      @argumentDefinitions(
        last: { type: "Int", defaultValue: 20 }
        organizationId: { type: "ID!" }
        creatorId: { type: "ID!" }
        campaignId: { type: "ID!" }
        fileTypes: { type: "[FileType!]" }
        fileAttachmentType: { type: "FileAttachmentType" }
        orderBy: { type: "AdvertiserFilesOrderBy" }
        before: { type: "String" }
      )
      @refetchable(queryName: "PublicationSelectionAttachmentsQuery") {
        organization(id: $organizationId) {
          files(
            last: $last
            before: $before
            creatorId: $creatorId
            campaignId: $campaignId
            fileTypes: $fileTypes
            fileAttachmentType: $fileAttachmentType
            orderBy: $orderBy
          ) @connection(key: "ProjectAttachmentsList_files", filters: []) {
            edges {
              node {
                __typename
                id
                thumbnailUrl
                secureUrl
                ... on Video {
                  duration
                  transformations {
                    autoUrl
                  }
                }
              }
            }
            totalCount
          }
        }
      }
    `,
    queryData
  );

  useEffect(() => {
    const containerEl = scrollContainer.current;
    const scrollHandler = (e: Event) => {
      if (!containerEl) return;
      const containerHeight = containerEl.scrollHeight - containerEl.clientHeight;
      const scrollOffset = containerHeight - (e.target as HTMLDivElement).scrollTop;
      if (!isLoadingPrevious && scrollOffset < 50 && hasPrevious) {
        loadPrevious(20);
      }
    };
    if (containerEl) {
      containerEl.addEventListener('scroll', scrollHandler);
    }
    return () => {
      containerEl?.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollContainer.current, isLoadingPrevious, loadPrevious, queryData, hasPrevious]);

  const fileList = useMemo(
    () => [...(data?.organization?.files?.edges ?? [])].reverse(),
    [data?.organization?.files?.edges]
  );

  const handleFilePreviewClick = (isVideo: boolean, url: string) => {
    const key = isVideo ? 'videoUrl' : 'thumbnailUrl';
    dispatch(setModal(MEDIA, { [key]: url }));
  };

  const handleFileSelectClick = (file: AttachmentFileType) => {
    if (!setSelectedFiles) {
      console.error('No handler for file selection');
      return;
    }
    if (!file) {
      console.error('Incorrect file data');
      return;
    }
    const newFileList = { ...selectedFiles };
    if (selectedFiles[file.id]) {
      delete newFileList[file.id];
      setSelectedFiles(newFileList);
    } else {
      setSelectedFiles({ ...newFileList, [file.id]: file });
    }
  };

  if (!fileList.length) {
    return (
      <div className={styles.root}>
        <div className={styles.emptyList}>
          <img src={emptyListImg} />
          <Text
            type="h1"
            msg="publication_selection_modal_drawer.empty_files.title"
            className={styles.emptyTitle}
          />
          <Text type="sm" msg="publication_selection_modal_drawer.empty_files.descr" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root} ref={scrollContainer}>
      <div className={styles.listContainer}>
        {fileList.map((item) => {
          const file = item?.node;
          if (!file) return null;
          const { id: fileId, secureUrl, transformations } = file;
          const isFileSelected = Boolean(selectedFiles[fileId]);
          const isVideoFile = file.__typename === 'Video';

          const transformationsUrl = transformations?.autoUrl || secureUrl;

          const fileUrl = isVideoFile ? transformationsUrl : secureUrl;

          return (
            <div
              className={classNames(styles.mediaWrapper, { [styles.active]: isFileSelected })}
              key={fileId}
              onClick={() => handleFilePreviewClick(isVideoFile, fileUrl)}
            >
              <img src={file.thumbnailUrl} />
              <div
                className={styles.checkboxWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFileSelectClick(file);
                }}
              >
                {isFileSelected ? (
                  <Icon name="Check" size={16} />
                ) : (
                  <Icon name="Add-plus" size={16} />
                )}
              </div>
              {isVideoFile && (
                <div className={styles.durationContainer}>
                  <Icon name="Play" size={16} className={styles.playIcon} />
                  <Text type="sm" text={getReadableDuration(file?.duration || 0)} color="white" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectAttachments;

export type AttachmentFileType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<ProjectAttachmentsCampaign_files$data['organization']>['files']
    >['edges']
  >[0]
>['node'];

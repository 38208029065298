/**
 * @generated SignedSource<<3491f3bdc230bc5db9fc90104edc715d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangePublicationDeadlineInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  publicationDate: any;
};
export type ChangePublicationDeadlineMutation$variables = {
  input: ChangePublicationDeadlineInput;
};
export type ChangePublicationDeadlineMutation$data = {
  readonly changePublicationDeadline: {
    readonly eventsConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: string;
          readonly id: string;
          readonly publicationDate?: any | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly project: {
      readonly deadline: {
        readonly draftDate: any | null | undefined;
        readonly publicationDate: any | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type ChangePublicationDeadlineMutation = {
  response: ChangePublicationDeadlineMutation$data;
  variables: ChangePublicationDeadlineMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draftDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectEventConnection",
  "kind": "LinkedField",
  "name": "eventsConnection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectEventEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/)
              ],
              "type": "PublicationDeadlineChange",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePublicationDeadlineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangePublicationDeadlinePayload",
        "kind": "LinkedField",
        "name": "changePublicationDeadline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Deadline",
                "kind": "LinkedField",
                "name": "deadline",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePublicationDeadlineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangePublicationDeadlinePayload",
        "kind": "LinkedField",
        "name": "changePublicationDeadline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Deadline",
                "kind": "LinkedField",
                "name": "deadline",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3801963b6f7a6b9c05398a6d8d7b7d9c",
    "id": null,
    "metadata": {},
    "name": "ChangePublicationDeadlineMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePublicationDeadlineMutation(\n  $input: ChangePublicationDeadlineInput!\n) {\n  changePublicationDeadline(input: $input) {\n    project {\n      deadline {\n        publicationDate\n        draftDate\n        id\n      }\n      id\n    }\n    eventsConnection {\n      edges {\n        node {\n          __typename\n          id\n          ... on PublicationDeadlineChange {\n            publicationDate\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a6d96463b300ad2ed0cadef3d1ea60f";

export default node;

import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import styles from './MediasTabs.css';

import Tabs from 'Components/Tabs/Tabs';
import { MediasTabs_creator$key } from 'GraphTypes/MediasTabs_creator.graphql';

interface TabsDataProps {
  portfolio: string;
  stories: string;
  posts: string;
  tabsContainer: string;
}

interface Props {
  creator?: MediasTabs_creator$key;
  tabsData: TabsDataProps;
}

const MediasTabs: React.FC<Props> = (props) => {
  const { creator, tabsData } = props;

  const data = useFragment(
    graphql`
      fragment MediasTabs_creator on Creator {
        profile {
          currency

          topSponsoredPosts {
            link
          }
          topPosts {
            link
          }
        }
        portfolioItems {
          totalCount
        }
        ... on InstagramCreator {
          medias(first: 30) {
            edges {
              node {
                id
              }
            }
          }
          posts: insightsMedias(placements: [FEED, REEL], first: 30) {
            totalCount
          }
          stories: insightsMedias(placements: [STORY], first: 30) {
            totalCount
          }
        }
      }
    `,
    creator
  );

  if (!data || !tabsData) return null;

  const portfolioItemsCount = data.portfolioItems.totalCount;
  const topPostCount = data.profile?.topPosts ? data.profile?.topPosts.length > 0 : null;
  const topSponsoredPostsCount = data.profile?.topSponsoredPosts
    ? data.profile?.topSponsoredPosts.length > 0
    : null;
  const mediasCount = data.medias?.edges ? data.medias?.edges.length > 0 : null;
  const postsCount = data.posts?.totalCount;
  const storiesCount = data.stories?.totalCount;

  const portfolio = tabsData.portfolio;
  const stories = tabsData.stories;
  const posts = tabsData.posts;
  const tabsContainer = tabsData.tabsContainer;

  const hasPosts = topPostCount || topSponsoredPostsCount || mediasCount || postsCount;

  const [currentTab, setCurrentTab] = useState(tabsData.portfolio);

  const handleTabChange = (newCurrentTab: string) => setCurrentTab(newCurrentTab);

  const tabs = compact([
    Number(portfolioItemsCount) > 0
      ? {
          id: portfolio,
          msg: `creator_medias.tab_portfolio`,
          linkTo: portfolio,
          linkContainerId: tabsContainer,
          linkOffset: -80,
        }
      : null,
    Number(storiesCount) > 0
      ? {
          id: stories,
          msg: `creator_medias.tab_stories`,
          linkTo: stories,
          linkContainerId: tabsContainer,
          linkOffset: -80,
        }
      : null,
    hasPosts
      ? {
          id: posts,
          msg: `creator_medias.tab_posts`,
          linkTo: posts,
          linkContainerId: tabsContainer,
          linkOffset: -80,
        }
      : null,
  ]);

  if (tabs.length < 2) return null;

  return (
    <div className={styles.root}>
      <Tabs
        items={tabs}
        className={styles.tabs}
        currentId={currentTab}
        onSelected={handleTabChange}
      />
    </div>
  );
};

export default MediasTabs;

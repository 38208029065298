import React, { useEffect } from 'react';
import classnames from 'classnames';

import styles from './UnlockCreator.pcss';

import { amplitude } from 'Helpers/amplitude';
import decorationImg from 'Images/marketplace/unlock_creators.png';
import decoration2xImg from 'Images/marketplace/unlock_creators_2x.png';
import Text from 'Components/ui/Text/Text';
import { PLANS_ROUTE, BOOK_A_DEMO_LINK_EN, BILLING_ROUTE } from 'Constants/general';
import TooltipPreset from 'Molecules/TooltipPreset/TooltipPreset';

interface Props {
  visible: boolean;
  className?: string;
  onVisibilityChange: (value: boolean) => void;
  paused?: boolean;
}

const UnlockCreator: React.FC<Props> = (props) => {
  const { className, paused, visible, onVisibilityChange } = props;

  useEffect(() => {
    if (!visible) return;
    amplitude.sendEvent<364>({
      id: '364',
      category: 'pop_up',
      name: 'limiting_creator_information_shows',
      param: {
        page_url: window.location.href,
      },
    });
  }, [visible]);

  const handleClose = () => {
    onVisibilityChange(false);
  };

  const handleResumePlanClick = () => {
    amplitude.sendEvent<404>({
      id: '404',
      category: 'marketplace',
      name: 'pause_subsciption_resume_click',
    });
  };

  if (!visible) return null;

  return (
    <TooltipPreset
      titleMsg={paused ? 'tooltip.creator_unlock.pause_title' : 'tooltip.creator_unlock.title'}
      descrData={{
        msg: paused ? 'tooltip.creator_unlock.pause_descr' : 'tooltip.creator_unlock.descr',
        formatValues: paused
          ? undefined
          : {
              link: (
                <a
                  href={BOOK_A_DEMO_LINK_EN}
                  target="_blank"
                  className={styles.demo}
                  onClick={handleClose}
                >
                  <Text type="md" msg="tooltip.creator_unlock.book_demo" />
                </a>
              ),
            },
      }}
      btnMsg={paused ? 'tooltip.creator_unlock.resume_button' : 'tooltip.creator_unlock.button'}
      imgSrcSet={`${decorationImg} 1x, ${decoration2xImg} 2x`}
      link={paused ? BILLING_ROUTE : PLANS_ROUTE}
      onCloseClick={handleClose}
      className={classnames(styles.tooltip, className)}
      {...(paused
        ? {
            onButtonClick: handleResumePlanClick,
          }
        : {})}
    />
  );
};

export default UnlockCreator;

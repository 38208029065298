import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  UpdatePaymentIntentMutation,
  UpdatePaymentIntentMutation$data,
  Stripe_UpdatePaymentIntentInput,
} from 'GraphTypes/UpdatePaymentIntentMutation.graphql';

const mutation = graphql`
  mutation UpdatePaymentIntentMutation($input: Stripe_UpdatePaymentIntentInput!) {
    stripe {
      updatePaymentIntent(input: $input) {
        paymentIntent {
          clientSecret
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Stripe_UpdatePaymentIntentInput,
  resolve?: (response: UpdatePaymentIntentMutation$data) => void,
  reject?: fnType,
  signal?: AbortSignal
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<UpdatePaymentIntentMutation>(environment, {
    mutation,
    variables,
    cacheConfig: {
      metadata: {
        signal,
      },
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }

      if (resolve && response) resolve(response);
    },
    onError: (error) => {
      if (reject) reject(error);
    },
  });
};

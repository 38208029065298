import React, { useState, useCallback, useContext } from 'react';
import classnames from 'classnames';

import styles from './CustomListCreateDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import InputPreset from 'Components/ui/InputPreset/InputPreset';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import { promisifyMutation } from 'Util/promisifyMutation';
import createCustomListMutation, {
  CreateCustomListMutation,
  CreateCustomListMutation$data,
} from 'Mutations/CreateCustomList.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
  organizationId: string;
  onCustomListSuccessfulCreated?: (response: CreateCustomListMutation$data) => void;
}

const CustomListCreateDrawer: React.FC<Props> = (props) => {
  const { drawerProps, organizationId, onCustomListSuccessfulCreated } = props;
  const { closeDrawer } = useContext(DrawerContext);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const isFormValid = Boolean(inputValue);

  const handleInputValueChange = useCallback<HandleInputValueChange>((e) => {
    const value = e.target.value;
    setInputValue(value);
    setHasError(false);
  }, []);

  const handleInputValueReset = useCallback<HandleInputValueReset>(() => {
    setInputValue('');
    setHasError(false);
  }, []);

  const handleButtonClick = useCallback(() => {
    if (isSubmitLoading) return;
    const customListName = inputValue.trim();
    if (!customListName) {
      setHasError(true);
      setInputValue(customListName);
      return;
    }
    setIsSubmitLoading(true);
    const mutation = promisifyMutation<CreateCustomListMutation>(createCustomListMutation);
    mutation({ organizationId, name: customListName })
      .then((response) => {
        const typename = response.createCustomList?.__typename;
        switch (typename) {
          case 'CreateCustomListPayload': {
            closeDrawer('custom-list-create');
            onCustomListSuccessfulCreated?.(response);
            break;
          }
          default: {
            setHasError(true);
            break;
          }
        }
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  }, [inputValue, organizationId, isSubmitLoading]);

  const handleOpenDrawer = useCallback<NonNullable<DrawerProps['onOpen']>>(() => {
    setInputValue('');
    drawerProps?.onOpen?.();
  }, [drawerProps]);

  return (
    <Drawer
      {...drawerProps}
      className={classnames(styles.drawer, drawerProps?.className)}
      rootKey="custom-list-create"
      onOpen={handleOpenDrawer}
    >
      <div className={styles.contentRoot}>
        <TextPreset
          className={styles.titleTextPreset}
          header={<Text type="d2" msg="custom_list_create_modal.title" />}
        />
        <InputPreset>
          <Input
            bordered
            forceLabelShow
            name="customListName"
            dataTest="input:customListName"
            error={hasError}
            value={inputValue}
            className={styles.input}
            labelMsg="custom_list_create_modal.input.label"
            placeholderMsg="custom_list_create_modal.input.placeholder"
            onChange={handleInputValueChange}
            onResetValue={handleInputValueReset}
          />
        </InputPreset>
        <ButtonPreset className={styles.submitButtonWrapper}>
          <div className={styles.clicker} onClick={handleButtonClick} />
          <Button
            fluid
            color="black"
            dataTest="button:createCustomList"
            className={styles.submitButton}
            disabled={!isFormValid}
            loading={isSubmitLoading}
            msg="custom_list_create_modal.submit_button"
          />
        </ButtonPreset>
      </div>
    </Drawer>
  );
};

export default CustomListCreateDrawer;
export { CustomListCreateDrawer };

// types

export type { Props as CustomListCreateDrawerProps };

type DrawerProps = React.ComponentProps<typeof Drawer>;
type InputProps = React.ComponentProps<typeof Input>;
type HandleInputValueChange = NonNullable<InputProps['onChange']>;
type HandleInputValueReset = NonNullable<InputProps['onResetValue']>;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  Accounting_CreateManualTransactionForSubscriptionInput,
  CreateManualTransactionForSubscriptionMutation$data,
  CreateManualTransactionForSubscriptionMutation,
} from 'GraphTypes/CreateManualTransactionForSubscriptionMutation.graphql';

const mutation = graphql`
  mutation CreateManualTransactionForSubscriptionMutation(
    $input: Accounting_CreateManualTransactionForSubscriptionInput!
  ) {
    accounting {
      createManualTransactionForSubscription(input: $input) {
        clientMutationId
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Accounting_CreateManualTransactionForSubscriptionInput,
  resolve?: (response: CreateManualTransactionForSubscriptionMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const {
    amount,
    campaignId,
    comment,
    currency,
    organizationId,
    departmentId,
    planId,
    billingPeriodEndsAt,
    billingPeriodStartsAt,
  } = data;

  const variables = {
    input: {
      id,
      amount,
      campaignId,
      comment,
      currency,
      organizationId,
      departmentId,
      planId,
      billingPeriodEndsAt,
      billingPeriodStartsAt,
    },
  };

  commitMutation<CreateManualTransactionForSubscriptionMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

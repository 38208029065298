import { commitMutation, graphql } from 'react-relay';

import { amplitude } from 'Helpers/amplitude';
import {
  AuthorizeTiktokAdsMutation,
  AuthorizeTiktokAdsMutation$data,
  AuthorizeTiktokAdsInput,
} from 'GraphTypes/AuthorizeTiktokAdsMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation AuthorizeTiktokAdsMutation($input: AuthorizeTiktokAdsInput!) {
    authorizeTiktokAds(input: $input) {
      creatorOwnership {
        creator {
          id
          username
        }
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: AuthorizeTiktokAdsInput,
  resolve?: (response: AuthorizeTiktokAdsMutation$data) => void,
  reject?: FnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  amplitude.sendEvent({
    id: '149',
    category: 'creator',
    name: 'web_tt_permissions_granted',
    param: {},
  });

  commitMutation<AuthorizeTiktokAdsMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

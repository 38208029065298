import React, {useCallback, useEffect, useState} from 'react';

import styles from './IncreaseCreatorLicensingDuration.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Atoms/Text/Text';
import { HELLO_EMAIL } from 'Constants/general';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const IncreaseCreatorLicensingDurationDrawer: React.FC<Props> = (props) => {
  const { open = false, onClose } = props;
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const handleCloseModal = useCallback(() => {
    onClose?.();
    setOpenState(false);
  }, [onClose]);

  return (
    <Drawer
      opened={openState}
      onClose={handleCloseModal}
      rootKey={'increase-creator-licensing-duration'}
      className={styles.drawer}
    >
      <Text
        msg="brief.drawer.increase_creator_licensing_duration"
        values={{
          email: <a href={`mailto:${HELLO_EMAIL}`} className={styles.link}>{HELLO_EMAIL}</a>
        }}
        align="left"
      />
    </Drawer>
  );
};

export {IncreaseCreatorLicensingDurationDrawer};

import React, { FC } from 'react';

import styles from './Tabs.pcss';
import Tab from './Tab/Tab';

interface Props {
  className?: string;
  currentId?: string;
  items:
    | ({ id?: string; el: JSX.Element } & unknown)[]
    | ({ id: string; el?: JSX.Element } & unknown)[];
  onSelected: (variable: unknown) => void;
  size?: string;
}

const Tabs: FC<Props> = (props) => {
  const { className, size, currentId, items, onSelected } = props;

  const list = items.map((item) => {
    const { id, el } = item;
    const isActive = currentId === id;

    if (el) return el;
    return (
      <Tab
        key={id}
        {...item}
        size={size}
        isActive={isActive}
        onClick={onSelected}
        currentId={currentId}
      />
    );
  });

  const sizeStyle = size ? styles[size] : undefined;

  return <div className={`${className} ${styles.root} ${sizeStyle}`}>{list}</div>;
};

export default Tabs;

import React, { useContext } from 'react';
import cn from 'classnames';

import { SignUpCompleteContext } from '../Context';

import styles from './Progress.pcss';

import ProgressBar from 'Components/ui/ProgressBar/ProgressBar';
import Text from 'Components/ui/Text/Text';

type Props = {
  className?: string;
  step?: number;
  total?: number;
};

const Progress: React.FC<Props> = (props) => {
  const { className } = props;
  const { activeStep, activeStepIndex, totalSteps } = useContext(SignUpCompleteContext);

  return (
    <div className={cn(styles.root, className)}>
      <div
        className={cn(styles.fixedInner, {
          [styles.themePink]: activeStep.theme === 'pink',
          [styles.themeSun]: activeStep.theme === 'sun',
          [styles.themePurple]: activeStep.theme === 'purple',
          [styles.themeSky]: activeStep.theme === 'sky',
        })}
      >
        <div className={styles.content}>
          <ProgressBar
            value={(100 / totalSteps) * (activeStepIndex + 1)}
            classes={{
              root: styles.progressBar,
              bar: cn(styles.progressBarWrap, {
                [styles.themePink]: activeStep.theme === 'pink',
                [styles.themeSun]: activeStep.theme === 'sun',
                [styles.themePurple]: activeStep.theme === 'purple',
                [styles.themeSky]: activeStep.theme === 'sky',
              }),
            }}
          />
          <Text
            type="md"
            msg="signup_complete.advertiser.progress.number"
            formatValues={{ step: activeStepIndex + 1, total: totalSteps }}
          />
        </div>
      </div>
    </div>
  );
};

export default Progress;
export { Progress };

// types

/**
 * @generated SignedSource<<1e491ca6e75d5d5706fe8225c593908c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentCreationStage_project$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FbAdsAction_project" | "FinishDealAction_project">;
  readonly " $fragmentType": "ContentCreationStage_project";
};
export type ContentCreationStage_project$key = {
  readonly " $data"?: ContentCreationStage_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCreationStage_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCreationStage_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinishDealAction_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FbAdsAction_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "697b43d3b9427124bed9e5c87da2d6e1";

export default node;

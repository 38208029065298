import { commitMutation, graphql } from 'react-relay';

import {
  CreateContractorProfileMutation,
  CreateContractorProfileMutation$data,
  CreateContractorProfileInput,
} from 'GraphTypes/CreateContractorProfileMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation CreateContractorProfileMutation($input: CreateContractorProfileInput!) {
    createContractorProfile(input: $input) {
      profile {
        id
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: CreateContractorProfileInput,
  resolve?: (response: CreateContractorProfileMutation$data) => void,
  reject?: FnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<CreateContractorProfileMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

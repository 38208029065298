import { useRef, useEffect, Ref } from 'react';

type HandleClick = (e: DocumentEventMap['click']) => void;
const useOutsideClick = <T extends HTMLElement>(callback: () => void): Ref<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClick: HandleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener<'click'>('click', handleClick, true);

    return () => {
      document.removeEventListener<'click'>('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

export default useOutsideClick;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import { getCurrentTime } from 'Util/dateCreator';
import {
  LaunchProjectMutation,
  LaunchProjectInput,
  LaunchProjectMutation$data,
} from 'GraphTypes/LaunchProjectMutation.graphql';

const mutation = graphql`
  mutation LaunchProjectMutation($input: LaunchProjectInput!) {
    launchProject(input: $input) {
      project {
        launch {
          id
          createdAt
        }
        advertiserStage
        campaign {
          creator {
            organization {
              paymentAccount {
                balance
                currency
              }
              subscription {
                minDepositAmount
              }
            }
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: LaunchProjectInput,
  resolve?: (response: LaunchProjectMutation$data) => void,
  reject?: fnType
) => {
  const { projectId } = data;

  const id = uuid();

  const variables = {
    input: {
      projectId,
      id,
    },
  };

  commitMutation<LaunchProjectMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const project = store.get(projectId);

      const launchFromStore = store.get(id);

      if (launchFromStore) return;

      const launch = store.create(id, 'ProjectLaunch');
      launch.setValue(id, 'id');
      launch.setValue(getCurrentTime(), 'createdAt');

      project?.setLinkedRecord(launch, 'launch');
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

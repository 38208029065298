import React, { Component, Fragment } from 'react';

import styles from './Cell.css';

import { createColor } from 'Util/colorizeValue';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Components/Tooltip';
import SimpleLink from 'Components/SimpleLink';
import {
  TEXT,
  PERCENT,
  TWO_ROW_TEXT,
  IMAGE_ELEMENT,
  CUSTOM_ELEMENT,
  COLORIZED_TEXT,
  LINK_TEXT_WITH_TOOLTIP,
  TWO_ROW_TEXT_WITH_LINK,
} from 'Constants/general';

class Cell extends Component {
  createTooltip(el, text) {
    return (
      <div
        data-tip={text}
        data-for={text}
        className={styles.tooltipWrap}
      >
        {el}
        <Tooltip id={text} />
      </div>
    );
  }

  createColorizedText() {
    const { forwardColorDirection, values, diffType } = this.props;
    const { total, value } = values;
    const hasValue = !!value;

    if (!hasValue) {
      return <Text text='—' />;
    }

    let percentValue = Math.round(value / total * 100);
    const color = value ? createColor(percentValue, forwardColorDirection) : null;
    if (diffType === PERCENT) percentValue = `${percentValue}%`;

    return this.createTooltip(
      <Text
        style={{ color }}
        text={value}
        overflow
      />,
      percentValue,
    );
  }

  createTwoRowText() {
    const { text, msg, additionalText } = this.props.values;

    return (
      <Fragment>
        <Text text={text} msg={msg} overflow />
        <Text
          text={additionalText}
          overflow
          size="sm"
          color="grayDog"
        />
      </Fragment>
    );
  }

  createText() {
    const { text, msg } = this.props.values;
    const textValue = !text && !msg ? '—' : text;

    return (
      <Text
        text={textValue}
        msg={msg}
        overflow
      />
    );
  }

  createImage() {
    const { src } = this.props.values;

    return <img src={src} />;
  }

  createTwoRowTextWithLink() {
    const { values, reverse } = this.props;
    const { additionalText } = values;

    if (reverse) {
      return (
        <Fragment>
          <Text text={additionalText} overflow />
          {this.createLinkTextWithTooltip({ size: '12', lineHeight: '16' })}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {this.createLinkTextWithTooltip()}
        <Text
          text={values.additionalText}
          size="sm"
          color="grayDog"
          overflow
        />
      </Fragment>
    );
  }

  createLinkTextWithTooltip(textProps) {
    const { text, msg, link } = this.props.values;

    if ((!text && !msg) || !link) return this.createText();

    return (
      <SimpleLink
        display="block"
        href={link}
        target="_blank"
        className={styles.overflowBlock}
      >
        {
          this.createTooltip(
            <Text
              color="creamCan"
              text={text}
              msg={msg}
              overflow
              {...textProps}
            />,
            text
          )
        }
      </SimpleLink>
    );
  }

  createCustomElement() {
    const { hover } = this.props;
    const { element } = this.props.values;

    if (!element) return <div />;

    if (hover !== null && element) return React.cloneElement(element, { hover });

    return element;
  }

  render() {
    const { element } = this.props;

    switch (element) {
      case TEXT:
        return this.createText();
      case TWO_ROW_TEXT:
        return this.createTwoRowText();
      case TWO_ROW_TEXT_WITH_LINK:
        return this.createTwoRowTextWithLink();
      case LINK_TEXT_WITH_TOOLTIP:
        return this.createLinkTextWithTooltip();
      case CUSTOM_ELEMENT:
        return this.createCustomElement();
      case IMAGE_ELEMENT:
        return this.createImage();
      case COLORIZED_TEXT:
        return this.createColorizedText();
      default:
        return null;
    }
  }
}

export default Cell;

import React, { useState, useContext } from 'react';

import styles from './ProjectNotesDrawer.pcss';

import updateProject from 'Mutations/UpdateProject.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import Text from 'Components/ui/Text/Text';
import Textarea from 'Components/ui/Textarea/Textarea';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  id: string;
  isAdmin: boolean;
  adminComment?: string | null;
  comment?: string | null;
  showPrev?: boolean;
}

const ProjectNotesDrawer: React.FC<Props> = (props) => {
  const { isAdmin, id, adminComment, comment } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const defaultComment = isAdmin ? adminComment : comment;

  const [loading, setLoading] = useState(false);
  const [commentValue, setComment] = useState(defaultComment);

  const handleSaveClick = () => {
    setLoading(true);
    const commentData = isAdmin ? { adminComment: commentValue } : { comment: commentValue };
    updateProject({ id, ...commentData }, handleNoteUpdate, handleNoteUpdate);
  };

  const handleNoteUpdate = () => {
    setLoading(false);
    closeDrawer(`project-notes-${id}`);
  };

  return (
    <Drawer rootKey={`project-notes-${id}`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg="chat.project.notes.drawer.title" className={styles.drawerTitleText} />
        <Text type="md" msg="chat.project.notes.drawer.descr" />
        <div className={styles.drawerInputWrap}>
          <Textarea
            value={commentValue || undefined}
            bordered={true}
            handleChange={setComment}
            className={styles.input}
          />
        </div>
        <Button
          color="black"
          msg="general.save"
          disabled={!commentValue || loading}
          onClick={handleSaveClick}
        />
      </div>
    </Drawer>
  );
};

export default ProjectNotesDrawer;

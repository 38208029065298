import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';

import styles from './DueDateFilter.pcss';

import { TooltipType } from 'Types/common';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Checkbox from 'Components/ui/Checkbox/Checkbox';
import { addDaysDate, defineDate } from 'Util/dateCreator';
import { amplitude } from 'Helpers/amplitude';
import { DateInterval } from 'GraphTypes/ProjectsListQuery.graphql';

export type DueDates = {
  draftDueIntervals?: DateInterval[];
  publicationDueIntervals?: DateInterval[];
  deadlineDate?: Partial<Record<DropdownOptionType<DueDateRangeType>['id'], boolean>>;
  deadlineInclude?: DropdownOptionType<DueDateFilterType>['id'] | null;
};

export type DueDateFilterType = 'draft_deadline' | 'posting_date';

type PlaceType = 'chat' | 'campaign';

interface Props {
  dropdownClassName?: string;
  dropdownContainerClassName?: string;
  hideArrow?: boolean;
  tooltipData?: TooltipType;
  place: PlaceType;
  onChange: (data: DueDates) => void;
  publishingRequired: boolean;
  withDefaultValues?: boolean;
  defaultDeadlineInclude?: DropdownOptionType<DueDateFilterType>['id'] | null;
  defaultDeadlineDate?: Partial<Record<DropdownOptionType<DueDateRangeType>['id'], boolean>>;
}

const tooltipData: TooltipType = {
  id: 'due_date',
  place: 'top',
  tooltipMsg: 'creators.filter.deadline',
};

export type DropdownOptionType<T> = {
  msg: string;
  id: T;
  variables?: Record<string, string | number>;
};

export type DueDateRangeType = 'today' | '2_days' | 'overdue' | '3_days' | '7_days';

const deadlineOptions: DropdownOptionType<DueDateRangeType>[] = [
  { id: 'overdue', msg: 'creators.filter.deadline.date.overdue' },
  { id: 'today', msg: 'creators.filter.deadline.date.today' },
  { id: '2_days', msg: 'creators.filter.deadline.date.tomorrow' },
  { id: '3_days', msg: 'creators.filter.deadline.date.nDays', variables: { count: 3 } },
  { id: '7_days', msg: 'creators.filter.deadline.date.nDays', variables: { count: '4-7' } },
];

const includeOptions: DropdownOptionType<DueDateFilterType>[] = [
  { id: 'draft_deadline', msg: 'creators.filter.deadline.date.include.draft' },
  { id: 'posting_date', msg: 'creators.filter.deadline.date.include.post' },
];

const dateRangeMap: Record<DueDateRangeType, () => Partial<{ after?: string; before?: string }>> = {
  overdue: () => ({
    before: defineDate(),
  }),
  today: () => ({
    after: defineDate(),
    before: defineDate(addDaysDate(new Date(), 1)),
  }),
  '2_days': () => ({
    after: defineDate(addDaysDate(new Date(), 1)),
    before: defineDate(addDaysDate(new Date(), 2)),
  }),
  '3_days': () => ({
    after: defineDate(addDaysDate(new Date(), 2)),
    before: defineDate(addDaysDate(new Date(), 3)),
  }),
  '7_days': () => ({
    after: defineDate(addDaysDate(new Date(), 3)),
    before: defineDate(addDaysDate(new Date(), 7)),
  }),
};

const DropdownOption: FC<{
  option: DropdownOptionType<string>;
  checked?: boolean;
  onChange: (option: DropdownOptionType<string>) => void;
  type?: 'radio' | 'checkbox';
}> = ({ option, checked, onChange, type = 'radio' }) => {
  return (
    <div className={styles.option} onClick={() => onChange(option)}>
      <Text msg={option.msg} formatValues={option.variables} type="md" />
      {type === 'radio' && <RadioButton checked={checked} />}
      {type === 'checkbox' && <Checkbox checked={checked} />}
    </div>
  );
};

const DueDateFilter: FC<Props> = ({
  onChange,
  publishingRequired,
  hideArrow,
  place,
  dropdownClassName,
  dropdownContainerClassName,
  tooltipData: defaultTooltipData,
  defaultDeadlineInclude = 'draft_deadline',
  defaultDeadlineDate,
  withDefaultValues,
}) => {
  const [deadlineDate, setDeadlineDate] = useState<
    Partial<Record<DropdownOptionType<DueDateRangeType>['id'], boolean>>
  >(defaultDeadlineDate || {});
  const [deadlineInclude, setDeadlineInclude] = useState<
    DropdownOptionType<DueDateFilterType>['id'] | null
  >(defaultDeadlineInclude);

  useEffect(() => {
    if (!withDefaultValues) return;

    if (defaultDeadlineInclude !== deadlineInclude) {
      setDeadlineInclude(defaultDeadlineInclude);
    }

    if (!isEqual(defaultDeadlineDate, deadlineDate)) {
      setDeadlineDate(defaultDeadlineDate || {});
    }
  }, [defaultDeadlineInclude, defaultDeadlineDate]);

  useEffect(() => {
    const hasSelection = Object.values(deadlineDate).some((val) => val);
    if (!hasSelection) {
      onChange({
        draftDueIntervals: [],
        publicationDueIntervals: [],
        deadlineInclude: deadlineInclude || 'draft_deadline',
        deadlineDate: {},
      });
      return;
    }
    const intervals = [];
    for (const [key, val] of Object.entries(deadlineDate)) {
      if (val) {
        intervals.push(dateRangeMap[key]());
      }
    }

    if (intervals.length > 0) {
      amplitude.sendEvent<370>({
        id: '370',
        category: 'campaign',
        name: 'deadline_filter',
        param: {
          ...deadlineDate,
          place,
          draft_deadline: deadlineInclude === 'draft_deadline',
          posting_date: deadlineInclude === 'posting_date',
        },
      });
    }

    if (deadlineInclude === 'draft_deadline') {
      onChange({
        draftDueIntervals: intervals,
        publicationDueIntervals: [],
        deadlineInclude,
        deadlineDate,
      });
    } else {
      onChange({
        draftDueIntervals: [],
        publicationDueIntervals: intervals,
        deadlineInclude,
        deadlineDate,
      });
    }
    return () =>
      onChange({
        draftDueIntervals: [],
        publicationDueIntervals: [],
        deadlineInclude: 'draft_deadline',
        deadlineDate: {},
      });
  }, [deadlineDate, deadlineInclude]);

  const handleDateChange = (option: DropdownOptionType<DueDateRangeType>) => {
    setDeadlineDate((prev) => {
      return {
        ...prev,
        [option.id]: !prev[option.id],
      };
    });
  };

  const handleIncludeChange = (option: DropdownOptionType<DueDateFilterType>) => {
    setDeadlineInclude(option.id);
  };

  const handleClearClick = () => {
    setDeadlineInclude('draft_deadline');
    setDeadlineDate({});
    onChange({
      draftDueIntervals: [],
      publicationDueIntervals: [],
      deadlineInclude: 'draft_deadline',
      deadlineDate: {},
    });
  };

  const isClearEnabled = Object.values(deadlineDate).some((val) => val);

  return (
    <Dropdown
      showArrow={!hideArrow}
      value={<Icon name={'timer-hourglass'} />}
      closeBySelect={false}
      tooltipData={{ ...tooltipData, ...defaultTooltipData }}
      className={classnames(styles.button, dropdownClassName, { [styles.active]: isClearEnabled })}
    >
      <div className={classnames(styles.root, dropdownContainerClassName)}>
        <div className={styles.label}>
          <Text type={'labelSm'} color={'grey'} msg={'creators.filter.deadline.date'} />
        </div>
        {deadlineOptions.map((option) => (
          <DropdownOption
            option={option}
            onChange={handleDateChange}
            checked={!!deadlineDate[option.id]}
            type={'checkbox'}
            key={option.id}
          />
        ))}
        {publishingRequired && (
          <>
            <div className={styles.rowContainer}>
              <div className={styles.row} />
            </div>
            <div className={styles.label}>
              <Text type={'labelSm'} color={'grey'} msg={'creators.filter.deadline.date.include'} />
            </div>
            {includeOptions.map((option) => (
              <DropdownOption
                option={option}
                onChange={handleIncludeChange}
                checked={deadlineInclude === option.id}
                key={option.id}
              />
            ))}
          </>
        )}

        {isClearEnabled && (
          <div className={styles.buttonContainer}>
            <AlterButton
              msg={'creators.filter.deadline.clear_filter'}
              className={styles.button}
              onClick={handleClearClick}
            />
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default DueDateFilter;

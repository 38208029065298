import { TEXT, TWO_ROW_TEXT, CUSTOM_ELEMENT, LINK_TEXT_WITH_TOOLTIP } from 'Constants/general';

export const items = [
  {
    id: 'date',
    values: {
      text: {
        value: 'createdAt',
        format: 'new-york-date',
      },
    },
    title: 'date',
    width: '72px',
    element: TEXT,
  },
  {
    id: 'method',
    values: {
      msg: {
        value: 'paymentMethodType',
      },
      additionalText: {
        value: 'authorName',
      },
    },
    title: 'method',
    width: '100px',
    element: TWO_ROW_TEXT,
  },
  {
    id: 'advertiser',
    values: {
      text: {
        value: 'advertiserName',
      },
      link: {
        value: 'advertiserLink',
      },
    },
    title: 'advertiser',
    width: '80px',
    element: LINK_TEXT_WITH_TOOLTIP,
  },
  // {
  //   id: 'campaign',
  //   values: {
  //     text: {
  //       value: 'campaignName',
  //     },
  //     link: {
  //       value: 'campaignLink'
  //     },
  //   },
  //   title: 'campaign',
  //   width: '70px',
  //   element: LINK_TEXT_WITH_TOOLTIP,
  // },
  {
    id: 'type',
    values: {
      element: {
        value: 'transactionTypeElement',
      },
    },
    title: 'type',
    width: '150px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'currency',
    values: {
      text: {
        value: 'currency',
      },
    },
    title: 'currency',
    width: '44px',
    element: TEXT,
  },
  {
    id: 'netCost',
    values: {
      text: {
        value: 'netCost',
        format: 'number',
      },
    },
    title: 'net_cost',
    width: '70px',
    element: TEXT,
  },
  {
    id: 'transactionFee',
    values: {
      text: {
        value: 'transactionFee',
      },
    },
    title: 'transacion_fee',
    width: '70px',
    element: TEXT,
  },
  {
    id: 'profit',
    values: {
      text: {
        value: 'profit',
        format: 'number',
      },
    },
    title: 'profit',
    width: '80px',
    element: TEXT,
  },
  {
    id: 'revenue',
    values: {
      text: {
        value: 'revenue',
        format: 'number',
      },
    },
    title: 'revenue',
    width: '80px',
    element: TEXT,
  },
  {
    id: 'paymentStatus',
    values: {
      element: {
        value: 'paymentStatus',
      },
    },
    title: 'payment_status',
    width: '64px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'subscriptionStatusType',
    values: {
      element: {
        value: 'subscriptionStatusType',
      },
    },
    title: 'subscriptionStatusType',
    width: '80px',
    element: CUSTOM_ELEMENT,
  },
  {
    id: 'verifiedStatus',
    values: {
      element: {
        value: 'verifiedStatus',
      },
    },
    width: '24px',
    element: CUSTOM_ELEMENT,
  },
];

import { graphql } from 'react-relay';

import type { UseOrganizationShopifyQuery as UseOrganizationShopifyQueryType } from 'GraphTypes/UseOrganizationShopifyQuery.graphql';

const UseOrganizationShopifyQuery = graphql`
  query UseOrganizationShopifyQuery {
    currentUser {
      organization {
        id
        shopifyAuthorization {
          shop {
            id
            name
          }
          reauthenticationRequired
        }
      }
    }
  }
`;

export { UseOrganizationShopifyQuery };

// types

export { UseOrganizationShopifyQueryType };

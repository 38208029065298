import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CompleteCampaignMutation$data,
  CompleteCampaignInput,
  CompleteCampaignMutation,
} from 'GraphTypes/CompleteCampaignMutation.graphql';

const mutation = graphql`
  mutation CompleteCampaignMutation($input: CompleteCampaignInput!) {
    completeCampaign(input: $input) {
      campaignCompletion {
        campaign {
          completion {
            id
          }
          canBeCompleted
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CompleteCampaignInput,
  resolve?: (response: CompleteCampaignMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { campaignId } = data;

  const variables = {
    input: {
      id,
      campaignId,
    },
  };

  commitMutation<CompleteCampaignMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const root = store.get('client:root');
      const campaign = store.get(campaignId);

      if (campaign) {
        const completion = store.create(id, 'CampaignCompletion');
        completion.setValue(id, 'id');
        campaign.setLinkedRecord(completion, 'completion');
      }

      if (!root) return;
      const list = ConnectionHandler.getConnection(root, 'CampaignList_campaigns');
      if (!list) return;
      ConnectionHandler.deleteNode(list, campaignId);
    },
    updater: (store) => {
      const root = store.get('client:root');
      if (!root) return;
      const list = ConnectionHandler.getConnection(root, 'CampaignList_campaigns');
      if (!list) return;
      ConnectionHandler.deleteNode(list, campaignId);

      const activeCampaigns = root.getLinkedRecord('campaigns(stages:["ACTIVE"])');
      const completedCampaigns = root.getLinkedRecord('campaigns(stages:["COMPLETED"])');

      if (activeCampaigns) {
        const activeCount = activeCampaigns.getValue('totalCount');
        if (!activeCount) return;
        activeCampaigns.setValue(Number(activeCount) - 1, 'totalCount');
      }

      if (completedCampaigns) {
        const completedCount = completedCampaigns.getValue('totalCount');
        if (!completedCount) return;
        completedCampaigns.setValue(Number(completedCount) + 1, 'totalCount');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

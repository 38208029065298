/**
 * @generated SignedSource<<f2bd5377181b7543e444c224f897c18c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type AdminCreativeLibraryContainerQuery$variables = Record<PropertyKey, never>;
export type AdminCreativeLibraryContainerQuery$data = {
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly currency: Currency;
    } | null | undefined;
  } | null | undefined;
};
export type AdminCreativeLibraryContainerQuery = {
  response: AdminCreativeLibraryContainerQuery$data;
  variables: AdminCreativeLibraryContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminCreativeLibraryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminCreativeLibraryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1891081c4e876f288ed7b02d5b402480",
    "id": null,
    "metadata": {},
    "name": "AdminCreativeLibraryContainerQuery",
    "operationKind": "query",
    "text": "query AdminCreativeLibraryContainerQuery {\n  currentUser {\n    organization {\n      currency\n      id\n    }\n    admin\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e12af119d7024a45c88c91c02bf0eacc";

export default node;

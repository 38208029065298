import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import SlickSlider, { CustomArrowProps } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './ProjectResults.pcss';

import Text from 'Atoms/Text/Text';
import Creative from 'Molecules/Creative/Creative';
import Icon from 'Components/ui/Icon/Icon';
import { ProjectResultsQuery as QueryType } from 'GraphTypes/ProjectResultsQuery.graphql';

const ProjectResultsQuery = graphql`
  query ProjectResultsQuery($projectId: ID!) {
    project(id: $projectId) {
      creatives(first: 2147483647) @connection(key: "Project_creatives") {
        edges {
          node {
            id
            ...Creative_creative
          }
        }
      }
      creator {
        type
        id
      }
      campaign {
        id
        organization {
          id
        }
      }
    }
  }
`;

const SampleNextArrow: React.FC<CustomArrowProps> = (props) => {
  const { onClick } = props;

  return (
    <div className={styles.nextArrow} onClick={onClick}>
      <Icon name="Arrow-big-right" />
    </div>
  );
};

const SamplePrevArrow: React.FC<CustomArrowProps> = (props) => {
  const { onClick } = props;

  return (
    <div className={styles.prevArrow} onClick={onClick}>
      <Icon name="Arrow-big-left" />
    </div>
  );
};

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

interface Props {
  className?: string;
  projectId: string;
}

const ProjectResults: React.FC<Props> = (props) => {
  const { className, projectId } = props;

  const data = useLazyLoadQuery<QueryType>(ProjectResultsQuery, { projectId });

  const project = data.project;

  if (!data || !project) return null;

  const edges = project?.creatives?.edges;

  if (!edges || edges.length === 0) {
    return null;
  }

  const creativesList = edges?.map((item) => {
    if (!item?.node) return null;

    const { id } = item.node;

    return (
      <div key={id} className={styles.creative}>
        <Creative creative={item.node} hasHeader={false} />
      </div>
    );
  });

  return (
    <div className={className}>
      <Text weight="500" msg="chat.results_title" transform="uppercase" className={styles.title} />
      {edges.length > 1 && <SlickSlider {...settings}>{creativesList}</SlickSlider>}
      {edges.length === 1 && creativesList}
    </div>
  );
};

export default ProjectResults;

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CloneBriefCreativeMutation,
  CloneBriefCreativeMutation$data,
  CloneBriefCreativeInput,
} from 'GraphTypes/CloneBriefCreativeMutation.graphql';

const mutation = graphql`
  mutation CloneBriefCreativeMutation($input: CloneBriefCreativeInput!) {
    cloneBriefCreative(input: $input) {
      brief {
        ... on V2Brief {
          creatives(first: 2147483647) @connection(key: "Creatives_creatives") {
            edges {
              node {
                id
                ...CreativeAsset_briefCreative
              }
            }
            totalCount
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface DataInput {
  briefId: string;
}

export default (
  data: CloneBriefCreativeInput & DataInput,
  resolve?: (response: CloneBriefCreativeMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { briefCreativeId } = data;

  const variables = {
    input: {
      id,
      briefCreativeId,
    },
  };

  commitMutation<CloneBriefCreativeMutation>(environment, {
    mutation,
    variables,
    // updater: store => {
    //   const brief = store.get(data.briefId);
    //   if (!brief) return;
    //   const creatives = ConnectionHandler.getConnection(brief, 'Creatives_creatives');
    //   if (!creatives) return;
    //   const creativeExists = creatives?.getLinkedRecords('edges')?.find(edge => edge?.getLinkedRecord('node')?.getDataID() === data.id);
    //   if (creativeExists) return;

    //   const creative = store?.getRootField('cloneBriefCreative')?.getLinkedRecord('newBriefCreativeEdge')?.getLinkedRecord('node');
    //   if (!creative) return;
    //   const edge = ConnectionHandler.createEdge(
    //     store,
    //     creatives,
    //     creative,
    //     'BriefCreativeConnectionEx'
    //   );
    //   ConnectionHandler.insertEdgeAfter(creatives, edge);
    // },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

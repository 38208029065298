/**
 * @generated SignedSource<<016b5f060308875588c3742642f8265b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ErrorType = "ALREADY_EXIST_ERROR" | "CREATOR_ALREADY_OWNED_ERROR" | "NOT_CLAIMED" | "NO_CREATOR" | "SIGNIN_ERROR" | "SIGNUP_ERROR" | "VALIDATION_ERROR" | "%future added value";
export type MarkContractorAsUnresponsiveInput = {
  clientMutationId?: string | null | undefined;
  projectId?: string | null | undefined;
};
export type MarkContractorAsUnresponsiveQuery$variables = {
  input: MarkContractorAsUnresponsiveInput;
};
export type MarkContractorAsUnresponsiveQuery$data = {
  readonly markContractorAsUnresponsive: {
    readonly __typename: "MarkContractorAsUnresponsivePayload";
    readonly user: {
      readonly unresponsive: boolean;
    };
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
    readonly type: ErrorType;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type MarkContractorAsUnresponsiveQuery = {
  response: MarkContractorAsUnresponsiveQuery$data;
  variables: MarkContractorAsUnresponsiveQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "ValidationError",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unresponsive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkContractorAsUnresponsiveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "markContractorAsUnresponsive",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MarkContractorAsUnresponsivePayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkContractorAsUnresponsiveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "markContractorAsUnresponsive",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MarkContractorAsUnresponsivePayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9eefd09d7afc70dceddeb483e7a44e9",
    "id": null,
    "metadata": {},
    "name": "MarkContractorAsUnresponsiveQuery",
    "operationKind": "mutation",
    "text": "mutation MarkContractorAsUnresponsiveQuery(\n  $input: MarkContractorAsUnresponsiveInput!\n) {\n  markContractorAsUnresponsive(input: $input) {\n    __typename\n    ... on ValidationError {\n      type\n      message\n    }\n    ... on MarkContractorAsUnresponsivePayload {\n      user {\n        unresponsive\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5907f71c0b19b289d7bd865df5877099";

export default node;

import { graphql, useMutation } from 'react-relay';

import { MarkContractorAsUnresponsiveQuery } from 'GraphTypes/MarkContractorAsUnresponsiveQuery.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const markContractorAsUnresponsiveQueryMutation = graphql`
  mutation MarkContractorAsUnresponsiveQuery($input: MarkContractorAsUnresponsiveInput!) {
    markContractorAsUnresponsive(input: $input) {
      __typename
      ... on ValidationError {
        type
        message
      }
      ... on MarkContractorAsUnresponsivePayload {
        user {
          unresponsive
        }
      }
    }
  }
`;

export const useMarkContractorAsUnresponsiveQueryMutation: BaseMutation<
  MarkContractorAsUnresponsiveQuery
> = (config) => {
  const [markContractorAsUnresponsiveQuery, loading] =
    useMutation<MarkContractorAsUnresponsiveQuery>(markContractorAsUnresponsiveQueryMutation);

  const markContractorAsUnresponsiveQueryCaller: BaseMutationExecFn<
    MarkContractorAsUnresponsiveQuery
  > = (execConfig) => {
    markContractorAsUnresponsiveQuery({
      ...config,
      ...execConfig,
    });
  };
  return [markContractorAsUnresponsiveQueryCaller, loading];
};

import React, { useContext, useMemo, useRef } from 'react';
import cn from 'classnames';
import find from 'lodash/find';

import { getPlanFeatures, getPlans, getIntervalValue } from '../data';
import TooltipInfo from '../TooltipInfo/TooltipInfo';
import TextWithTaxInfoTooltip from '../TextWithTaxInfoTooltip/TextWithTaxInfoTooltip';
import type { AvailableDiscountType } from '../data';
import { BillingPlansPropList } from '../BillingPlans';

import styles from './Comparison.pcss';

import { amplitude } from 'Helpers/amplitude';
import { createSum } from 'Util/numberFormatter';
import Button from 'Atoms/NewButton/Button';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { BOOK_A_DEMO_LINK_RU } from 'Constants/general';
import { SubscriptionInterval as SubscriptionIntervalType } from 'Types/enums';
import { PlanId, Currency } from 'GraphTypes/BillingPlansQuery.graphql';
import Text from 'Components/ui/Text/Text';
import Accordion from 'Components/ui/Accordion/Accordion';
import { buttonColor } from 'Types/common';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

interface Props {
  planId?: PlanId;
  currency?: Currency;
  availablePlanIds?: PlanId[];
  trialMonthPlanAvailable?: boolean;
  plansProps: BillingPlansPropList;
  defaultInterval?: SubscriptionIntervalType | null;
  onPlanBuyClick: (data: object) => void;
  availableDiscount?: AvailableDiscountType;
}

const Comparison: React.FC<Props> = (props) => {
  const {
    currency,
    availablePlanIds,
    planId: currentPlanId,
    onPlanBuyClick,
    plansProps,
    defaultInterval,
    trialMonthPlanAvailable,
    availableDiscount,
  } = props;

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const hasUpdatedCases = hasFeatureFlag('use_cases_5389');

  const plansEl = useRef<HTMLDivElement>(null);

  if (!currency || !availablePlanIds) return null;

  const interval = undefined;

  const plans = getPlans({
    currency,
    plansProps,
    trialMonthPlanAvailable,
    availableDiscount,
    hasNewCases: hasUpdatedCases,
  });
  const planFeatures = getPlanFeatures(hasUpdatedCases);
  const planFeatureKeys = Object.keys(planFeatures) as (keyof typeof planFeatures)[];
  return (
    <div className={styles.root}>
      <Text msg="billing_plans.plan_comparison.title" type="d2" className={styles.title} />
      <Text msg="billing_plans.plan_comparison.subtitle" type="md" className={styles.subtitle} />
      <div className={styles.planFeatures}>
        <div className={styles.planFeaturesGroup}>
          <div className={styles.planFeaturesGroupFirstRow}>
            <div className={styles.planFeatureGroupName}></div>
            {availablePlanIds?.map((planId) => {
              const plan = find(plans, (item) => {
                return item.planId === planId;
              });
              if (!plan) return <div key={planId} />;

              return (
                <div key={planId}>
                  <Text
                    msg={`billing_plans.plan.${planId.toLocaleLowerCase()}.title`}
                    className={cn(styles.planTitle, {
                      [styles.pinkColor]: plan.colorTheme === 'pink',
                      [styles.skyColor]: plan.colorTheme === 'sky',
                    })}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {planFeatureKeys.map((planFeature, key) => {
          return (
            <Accordion
              key={`${planFeature}+${key}`}
              items={[
                {
                  arrowPosition: 'left',
                  openedByDefault: key === 0,
                  title: (
                    <div
                      className={cn(styles.planFeaturesGroupRow, styles.planFeaturesGroupRowTitle)}
                    >
                      <div className={styles.planFeatureGroupName}>
                        <Text
                          msg={planFeatures[planFeature]?.title}
                          className={styles.planFeatureGroupNameTitle}
                        />
                        <TooltipInfo
                          id={`${planFeatures[planFeature]?.title}.tooltip`}
                          tooltipMsg={planFeatures[planFeature]?.tooltipMsg}
                        />
                      </div>
                    </div>
                  ),
                  content: (
                    <>
                      {Object.keys(planFeatures[planFeature]?.features || []).map((featureKey) => {
                        // @ts-expect-error: Difficult to merge features keys with keys of PlanFeatureKeys
                        const feature = planFeatures[planFeature]?.features[featureKey];
                        if (!feature) return null;
                        return (
                          <div
                            key={`${planFeature}+${featureKey}`}
                            className={styles.planFeaturesGroupRow}
                          >
                            <div className={styles.featureName}>
                              <Text
                                type="md"
                                className={styles.featureNameText}
                                msg={feature.title}
                              />
                              <TooltipInfo {...feature.tooltipProps} />
                              {feature.additionalInfo && (
                                <Text
                                  type="md"
                                  msg={feature.additionalInfo}
                                  className={styles.featureNameInfo}
                                />
                              )}
                            </div>
                            {availablePlanIds?.map((planId) => {
                              const plan = find(plans, (item) => {
                                return item.planId === planId;
                              });
                              if (!plan) return <div key={planId} />;
                              const featureContent =
                                // @ts-expect-error: Same problem with featureKey as key for unexpected feature type
                                plan?.planFeatures?.[planFeature]?.[featureKey];

                              return (
                                <div key={planId} className={styles.planFeatureValue}>
                                  {featureContent}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </>
                  ),
                },
              ]}
            />
          );
        })}
      </div>
      <div ref={plansEl} className={styles.plansWrapper}>
        <div className={styles.benefits} />
        <div className={styles.plans}>
          {availablePlanIds.map((planName) => {
            const currentPlan = find(plans, (item) => {
              return item.planName === planName;
            });

            if (!currentPlan) return null;
            const {
              planId,
              planName: name,
              title,
              price,
              pricePer,
              btnMsg: btnMsgValue,
              btnValues,
              disabledData,
            } = currentPlan;

            const currentPlanProps = plansProps[planId];

            if (!planId || !currentPlanProps) {
              return null;
            }

            const isAnnually = currentPlanProps.interval === SubscriptionIntervalType.YEARLY;

            const addons = currentPlanProps.addons;

            const addonsPrice = addons?.reduce((sum, addon) => {
              if (
                addon.value !== addon.defaultValue &&
                addon.value &&
                addon.value > addon.defaultValue &&
                addon.value !== Infinity
              ) {
                return (sum += addon.price * (addon.value - addon.defaultValue));
              }
              return sum;
            }, 0);

            const actualPrice =
              price !== undefined ? createSum(price + addonsPrice, currency) : undefined;

            const priceData = actualPrice ? { text: actualPrice } : { msg: 'billing_plans.custom' };

            const isActive =
              name === currentPlanId &&
              (price ? defaultInterval === currentPlanProps.interval : true);
            const isButtonDisabled = isActive;

            const btnMsg = useMemo(() => {
              const isUpgarde =
                name === currentPlanId &&
                getIntervalValue(interval) > getIntervalValue(defaultInterval);
              const defaultChooseBtnMsg =
                btnMsgValue ||
                (isUpgarde ? 'billing_plans.plans.upgrade' : 'billing_plans.plans.choose');
              if (isActive) {
                return 'billing_plans.your_plan';
              }
              if (actualPrice) {
                return defaultChooseBtnMsg;
              }

              return 'billing_plans.pro_btn';
            }, [currentPlanId, interval, defaultInterval, btnMsgValue, isActive]);

            const handlePlanBuyClick = () => {
              amplitude.sendEvent({
                id: '60',
                category: 'billing',
                name: 'compare_plan',
                param: { planId, interval },
              });
              amplitude.sendEvent({
                id: '59',
                category: 'billing',
                name: 'choose_your_plan',
                param: { planId, interval, place: 'table' },
              });
              if (actualPrice) {
                onPlanBuyClick({
                  choosenPlanId: planId,
                });
              } else {
                window.open(BOOK_A_DEMO_LINK_RU);
              }
            };

            const summaryPrice = useMemo(() => {
              if (!price) return null;
              const key = isAnnually ? 'annually' : 'quarterly';

              const values = {
                quarterly: (
                  <Text
                    msg="billing_plans.plans.price_per_quarter"
                    type="md"
                    formatValues={{ price: createSum((price + addonsPrice) * 3, currency) }}
                    className={styles.summaryPrice}
                  />
                ),
                annually: (
                  <Text
                    msg="billing_plans.plans.price_per_year"
                    type="md"
                    formatValues={{ price: createSum((price + addonsPrice) * 12, currency) }}
                    className={styles.summaryPrice}
                  />
                ),
              };

              return values[key];
            }, [price, isAnnually, currency, currentPlan, addonsPrice]);

            return (
              <div key={planName} className={styles.plan}>
                <div className={styles.headerWrap}>
                  <Text
                    msg={title}
                    className={cn(styles.planTitle, {
                      [styles.pinkColor]: currentPlan.colorTheme === 'pink',
                      [styles.skyColor]: currentPlan.colorTheme === 'sky',
                    })}
                  />
                </div>
                <div className={styles.price}>
                  <Text {...priceData} className={styles.priceText} />
                  {actualPrice && (
                    <TextWithTaxInfoTooltip tooltipInfoProps={{ id: `${planName}_tax_info` }}>
                      <Text
                        className={styles.periodText}
                        msg={pricePer ? pricePer : 'billing_plans.plans.for.month'}
                      />
                    </TextWithTaxInfoTooltip>
                  )}
                  {currentPlan.summaryPriceRemarkMsg && (
                    <Text
                      className={styles.summaryPriceRemark}
                      msg={currentPlan.summaryPriceRemarkMsg}
                    />
                  )}
                </div>
                {disabledData && (
                  <Tooltip {...disabledData.tooltipData}>
                    <Button
                      size="lg"
                      radius="20"
                      disabled={true}
                      className={styles.button}
                      theme={currentPlan.colorTheme === 'dark' ? 'dark' : 'light'}
                      color={
                        {
                          default: 'normal',
                          sky: 'normal',
                          pink: 'primary',
                          dark: 'normal',
                          ocean: 'normal',
                          sun: 'normal',
                        }[currentPlan.colorTheme || 'default'] as buttonColor
                      }
                      msg={btnMsg}
                      {...disabledData.buttonData}
                    />
                  </Tooltip>
                )}
                {!disabledData && (
                  <Button
                    size="lg"
                    radius="20"
                    theme={currentPlan.colorTheme === 'dark' ? 'dark' : 'light'}
                    color={
                      {
                        default: 'normal',
                        sky: 'normal',
                        pink: 'primary',
                        dark: 'normal',
                        ocean: 'normal',
                        sun: 'normal',
                      }[currentPlan.colorTheme || 'default'] as buttonColor
                    }
                    msg={btnMsg}
                    msgValues={btnValues}
                    className={cn(styles.button, {
                      [styles.disabledButton]: isButtonDisabled,
                      [styles.disabledButtonDark]:
                        isButtonDisabled && currentPlan.colorTheme === 'dark',
                    })}
                    disabled={isButtonDisabled}
                    onClick={handlePlanBuyClick}
                  />
                )}
                <div
                  className={cn(styles.summaryPrice, {
                    [styles.hidden]: !currentPlan.showSummaryPrice,
                  })}
                >
                  {summaryPrice}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Comparison;

import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import styles from './OrganizationData.pcss';

import { OrganizationDataQuery as QueryType } from 'GraphTypes/OrganizationDataQuery.graphql';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';

const OrganizationDataQuery = graphql`
  query OrganizationDataQuery($organizationId: ID!) {
    organization(id: $organizationId) {
      name
    }
    currentUser {
      admin
      organization {
        id
      }
    }
  }
`;

interface Props {
  organizationId?: string;
  onOrganizationChange: (id: string) => void;
}

const OrganizationData: React.FC<Props> = (props) => {
  const { organizationId = '', onOrganizationChange } = props;

  const response = useLazyLoadQuery<QueryType>(OrganizationDataQuery, {
    organizationId,
  });

  if (!response.organization) return null;

  const currentUserOrgId = response.currentUser?.organization?.id || '';

  const isOwner = currentUserOrgId === organizationId;
  const isAdmin = response.currentUser?.admin;

  if (isOwner || !isAdmin) return null;

  const name = response.organization.name;

  const handleClear = () => {
    onOrganizationChange(currentUserOrgId);
  };

  return (
    <TextPreset
      header={<Text type="label" msg="general.organization_name" color="grey" />}
      subtitle={<Text text={name} />}
      rightComponent={<TextButton icon="Close-small" onClick={handleClear} />}
    />
  );
};

export default OrganizationData;

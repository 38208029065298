/**
 * @generated SignedSource<<760f7514ffcc202dfbe488436b2ff9ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Billing_TransactionType = "CREATOR_EXPENSE" | "DEPOSIT" | "DEPOSIT_REFUND" | "EXTRA_PAYOUT" | "EXTRA_PAYOUT_REFUND" | "FULLY_MANAGED_ZEROING" | "REFERRAL_REWARD" | "REIMBURSEMENT" | "REIMBURSEMENT_REFUND" | "RESERVATION" | "SALES_PEAK_REWARD" | "SUBSCRIPTION" | "SUBSCRIPTION_REFUND" | "TRANSACTION_FEE" | "%future added value";
export type TransactionContainerPaginationQuery$variables = {
  brandIds?: ReadonlyArray<string> | null | undefined;
  campaignIds?: ReadonlyArray<string> | null | undefined;
  count: number;
  creatorIds?: ReadonlyArray<string> | null | undefined;
  cursor?: string | null | undefined;
  dateFrom?: any | null | undefined;
  dateTo?: any | null | undefined;
  types?: ReadonlyArray<Billing_TransactionType> | null | undefined;
};
export type TransactionContainerPaginationQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"TransactionContainer_billingTransactions">;
    } | null | undefined;
  } | null | undefined;
};
export type TransactionContainerPaginationQuery = {
  response: TransactionContainerPaginationQuery$data;
  variables: TransactionContainerPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creatorIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFrom"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTo"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "types"
},
v8 = {
  "kind": "Variable",
  "name": "brandIds",
  "variableName": "brandIds"
},
v9 = {
  "kind": "Variable",
  "name": "campaignIds",
  "variableName": "campaignIds"
},
v10 = {
  "kind": "Variable",
  "name": "creatorIds",
  "variableName": "creatorIds"
},
v11 = {
  "kind": "Variable",
  "name": "dateFrom",
  "variableName": "dateFrom"
},
v12 = {
  "kind": "Variable",
  "name": "dateTo",
  "variableName": "dateTo"
},
v13 = {
  "kind": "Variable",
  "name": "types",
  "variableName": "types"
},
v14 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionContainerPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "count",
                    "variableName": "count"
                  },
                  (v10/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "cursor",
                    "variableName": "cursor"
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "TransactionContainer_billingTransactions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "TransactionContainerPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "Billing_TransactionConnection",
                "kind": "LinkedField",
                "name": "billingTransactions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Billing_TransactionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Billing_Transaction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accountType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cardBrand",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "last4",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Brand",
                            "kind": "LinkedField",
                            "name": "brand",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Campaign",
                            "kind": "LinkedField",
                            "name": "campaign",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "stage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "creator",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "errorMessage",
                            "storageKey": null
                          },
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": [
                  "dateFrom",
                  "dateTo",
                  "types",
                  "campaignIds",
                  "brandIds",
                  "creatorIds"
                ],
                "handle": "connection",
                "key": "TransactionContainer_billingTransactions",
                "kind": "LinkedHandle",
                "name": "billingTransactions"
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e5e43a36a7d4c1fae4fb88c88cc7ae6",
    "id": null,
    "metadata": {},
    "name": "TransactionContainerPaginationQuery",
    "operationKind": "query",
    "text": "query TransactionContainerPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $dateFrom: DateTime\n  $dateTo: DateTime\n  $types: [Billing_TransactionType!]\n  $campaignIds: [ID!]\n  $brandIds: [ID!]\n  $creatorIds: [ID!]\n) {\n  currentUser {\n    organization {\n      ...TransactionContainer_billingTransactions_3f3Ki0\n      id\n    }\n    id\n  }\n}\n\nfragment TransactionContainer_billingTransactions_3f3Ki0 on Organization {\n  billingTransactions(first: $count, after: $cursor, dateFrom: $dateFrom, dateTo: $dateTo, types: $types, campaignIds: $campaignIds, brandIds: $brandIds, creatorIds: $creatorIds) {\n    edges {\n      node {\n        type\n        createdAt\n        ...TransactionItem_transaction\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TransactionItem_transaction on Billing_Transaction {\n  type\n  createdAt\n  amount\n  accountType\n  cardBrand\n  last4\n  currency\n  status\n  brand {\n    id\n    name\n  }\n  campaign {\n    id\n    name\n    stage\n  }\n  creator {\n    __typename\n    id\n    username\n  }\n  project {\n    id\n  }\n  errorMessage\n}\n"
  }
};
})();

(node as any).hash = "5a1e41b202700655a1a79376d90df0e8";

export default node;

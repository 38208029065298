import React, { useContext } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Element } from 'react-scroll';
import { useLocation } from 'react-router-dom';

import { BriefContext } from '../../Brief.Context';

import CampaignDetails from './CampaignDetails/CampaignDetails';
import ProductDetails from './ProductDetails/ProductDetails';
import CreatorsDetails from './CreatorsDetails/CreatorsDetails';
import CreativeAssets from './CreativeAssets/CreativeAssets';
import OptionalExtras from './OptionalExtras/OptionalExtras';
import ChoosePlanBanner from './ChoosePlanBanner/ChoosePlanBanner';

import Spinner from 'Atoms/Spinner/Spinner';
import { BriefTemplateContentQuery as QueryType } from 'GraphTypes/BriefTemplateContentQuery.graphql';

const BriefTemplateContentQuery = graphql`
  query BriefTemplateContentQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...CampaignDetails_campaign
      ...ProductDetails_campaign
      ...CreatorsDetails_campaign
      ...CreativeAssets_campaign
      ...OptionalExtras_campaign
      brief {
        __typename
      }
      organization {
        id
        currency
        paying
        trialMonthPlanAvailable
        newClient
        subscription {
          planId
          paused
          effectiveLimits {
            maxPaidSocialActivationDays
          }
          canUsePaidSocial
          availablePaymentTypes
        }
        counters {
          psLicensedCreators
        }
      }
    }
    currentUser {
      id
      admin
      organization {
        id
      }
    }
  }
`;

interface Props {
  campaignId: string;
}

const BriefTemplateContent: React.FC<Props> = (props) => {
  const { campaignId } = props;
  const [briefState] = useContext(BriefContext);
  const location = useLocation();

  const routeData = location.state;

  const data = useLazyLoadQuery<QueryType>(BriefTemplateContentQuery, { campaignId });

  if (!data) {
    return <Spinner style={{ margin: '40px auto' }} />;
  }

  const campaign = data.campaign;

  if (!campaign) return null;

  const brief = campaign.brief;
  const briefType = brief?.__typename;

  if (!brief || briefType !== 'V2Brief') return null;

  const organization = campaign.organization;

  const currency = organization?.currency;
  const paying = organization?.paying;
  const maxPaidSocialActivationDays =
    organization?.subscription?.effectiveLimits.maxPaidSocialActivationDays;
  const canUsePaidSocial = organization?.subscription?.canUsePaidSocial;
  const trialMonthPlanAvailable = organization?.trialMonthPlanAvailable;
  const availablePaymentTypes = organization?.subscription?.availablePaymentTypes;
  const authorId = organization?.id;
  const psLicensedCreators = organization?.counters?.psLicensedCreators;
  const isPlanPaused = Boolean(organization?.subscription?.paused);

  const isAdmin = data.currentUser?.admin;
  const isOwner = authorId === data.currentUser?.organization?.id;

  const showChoosePlanBanner = organization?.newClient || isPlanPaused;

  return (
    <div>
      {briefState.isStepAvailable.campaignInfo && (
        <Element name="campaignInfo">
          <CampaignDetails
            isAdmin={!!isAdmin}
            isOwner={!!isOwner}
            campaign={campaign}
            currency={currency}
            paying={paying}
            psLicensedCreators={psLicensedCreators}
            maxPaidSocialActivationDays={maxPaidSocialActivationDays}
            canUsePaidSocial={canUsePaidSocial}
            availablePaymentTypes={availablePaymentTypes}
            trialMonthPlanAvailable={trialMonthPlanAvailable}
            showSparkAdsAwareness={routeData?.showSparkAdsAwareness}
          />
        </Element>
      )}
      {briefState.isStepAvailable.productInfo && (
        <Element name="productInfo">
          <ProductDetails campaign={campaign} />
        </Element>
      )}
      {briefState.isStepAvailable.creatorsInfo && (
        <Element name="creatorsInfo">
          <CreatorsDetails isAdmin={isAdmin} campaign={campaign} />
        </Element>
      )}
      {briefState.isStepAvailable.creativesInfo && (
        <Element name="creativesInfo">
          <CreativeAssets campaign={campaign} />
        </Element>
      )}
      {briefState.isStepAvailable.optionalInfo && (
        <Element name="optionalInfo">
          <OptionalExtras campaign={campaign} />
        </Element>
      )}
      {showChoosePlanBanner && <ChoosePlanBanner campaignId={campaignId} paused={isPlanPaused} />}
    </div>
  );
};

export default BriefTemplateContent;

import React, { useMemo, useCallback, useContext } from 'react';
import classnames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';

import styles from './OutcomePage.pcss';

import { DASHBOARD_ROUTE } from 'Constants/general';
import Page from 'Templates/Page/Page';
import { OutcomeStatus } from 'Components/OutcomeStatus';
import Header from 'Templates/Layout/Header/Header';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';

type Props = {
  className?: string;
};

const OutcomePage: React.FC<Props> = (props) => {
  const { className } = props;
  const { logout } = useContext(Auth0Context);

  const navigate = useNavigate();
  const { search } = useLocation();

  const searchParams = useMemo<SearchParams>(() => {
    const sp = new URLSearchParams(search);
    return Object.fromEntries(sp.entries()) as SearchParams;
  }, [search]);

  const buttonMsg: ButtonMsg = {
    [ButtonHandlerTypeEnum.BackToSignIn]: 'outcome_page.button_msg.back_to_sign_in',
    [ButtonHandlerTypeEnum.GoToDashboard]: 'outcome_page.button_msg.go_to_dashboard',
    [ButtonHandlerTypeEnum.SignOut]: 'outcome_page.button_msg.sign_out',
    [ButtonHandlerTypeEnum.CloseWindow]: 'outcome_page.button_msg.close_window',
  };

  const handleButtonClick = useCallback(() => {
    switch (searchParams?.handler) {
      case ButtonHandlerTypeEnum.BackToSignIn: {
        logout();
        break;
      }
      case ButtonHandlerTypeEnum.GoToDashboard: {
        navigate(DASHBOARD_ROUTE);
        break;
      }
      case ButtonHandlerTypeEnum.SignOut: {
        logout();
        break;
      }
      case ButtonHandlerTypeEnum.CloseWindow: {
        window.close();
        break;
      }
      default: {
        alert('No handler');
        break;
      }
    }
  }, [searchParams?.handler]);

  const buttonProps: OutcomeStatusProps['buttonProps'] = {
    msg: searchParams.handler ? buttonMsg[searchParams.handler] : '',
    onClick: handleButtonClick,
  };

  return (
    <Page className={classnames(styles.root, className)}>
      <Header showStaticHeader />
      <div className={styles.outcomeComponentContainer}>
        <OutcomeStatus
          className={styles.outcomeComponentRoot}
          status={searchParams.status}
          titleTextProps={{ text: searchParams.error, className: styles.titleText }}
          subtitleTextProps={{
            text: searchParams.error_description,
            className: styles.subtitleTextProps,
          }}
          buttonProps={buttonProps}
        />
      </div>
    </Page>
  );
};

export default OutcomePage;

// types

type OutcomeStatusProps = React.ComponentProps<typeof OutcomeStatus>;
type SearchParams = {
  status: OutcomeStatusProps['status'];
  error?: string;
  error_description?: string;
  handler?: ButtonHandlerTypeEnum;
  returnToAfterLogout?: string;
};
type ButtonMsg = { [K in ButtonHandlerTypeEnum]: string };

enum ButtonHandlerTypeEnum {
  BackToSignIn = 'BackToSignIn',
  GoToDashboard = 'GoToDashboard',
  SignOut = 'SignOut',
  CloseWindow = 'CloseWindow',
}

export { ButtonHandlerTypeEnum };

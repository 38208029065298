import { StatItem, createDateLabel } from '../Chart/utils';

import type { TransactionsChartQueryType } from './TransactionsChart.Query';

import type { Performance_Granularity } from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';

type CharDataType = {
  stats: StatItem[];
  lines: {
    // totalExpenses: number;
    totalPayouts: number;
    totalReimbursements: number;
    totalExtraPayouts: number;
    // totalExpencesFee: number;
    averagePrice: number;
    date: string;
  }[];
};
export const chartData = (
  response: TransactionsChartQueryType['response'],
  granularity: Performance_Granularity
): CharDataType => {
  const stats = [
    {
      amount: response.performance?.paymentTransactionCounters?.totalExpenses || 0,
      key: 'totalExpenses',
      description: {
        titleMsg: 'performance.dashboard.chart.transactions.total_spent.title',
        tooltipMsg: 'performance.dashboard.chart.transactions.total_spent.tooltip',
      },
      color: 'dark',
      colorOffset: -1,
    },
    {
      amount: response.performance?.paymentTransactionCounters?.totalPayouts || 0,
      key: 'totalPayouts',
      description: {
        titleMsg: 'performance.dashboard.chart.transactions.completed_deals.title',
        tooltipMsg: 'performance.dashboard.chart.transactions.completed_deals.tooltip',
      },
      colorOffset: -1,
    },
    {
      amount: response.performance?.paymentTransactionCounters?.totalReimbursements || 0,
      key: 'totalReimbursements',
      description: {
        titleMsg: 'performance.dashboard.chart.transactions.reimbursements.title',
        tooltipMsg: 'performance.dashboard.chart.transactions.reimbursements.tooltip',
      },
      colorOffset: -1,
    },
    {
      amount: response.performance?.paymentTransactionCounters?.totalExtraPayouts || 0,
      key: 'totalExtraPayouts',
      description: {
        titleMsg: 'performance.dashboard.chart.transactions.extra_payments.title',
        tooltipMsg: 'performance.dashboard.chart.transactions.extra_payments.tooltip',
      },
      colorOffset: -1,
    },
    // {
    //   amount: 0,
    //   key: 'totalExpencesFee',
    //   description: {
    //     titleMsg: 'performance.dashboard.chart.transactions.transaction_fee.title',
    //     tooltipMsg: 'performance.dashboard.chart.transactions.transaction_fee.tooltip',
    //   },
    // },
    {
      amount: response.performance?.paymentTransactionCounters?.totalAveragePrice || 0,
      key: 'averagePrice',
      description: {
        titleMsg: 'performance.dashboard.chart.transactions.average_deal_price.title',
        tooltipMsg: 'performance.dashboard.chart.transactions.average_deal_price.tooltip',
      },
      colorOffset: -1,
    },
  ];

  const edges = response?.performance?.paymentTransactionCounters?.edges || [];
  const lines: {
    // totalExpenses: number;
    totalPayouts: number;
    totalReimbursements: number;
    totalExtraPayouts: number;
    // totalExpencesFee: number;
    averagePrice: number;

    date: string;
  }[] = [];

  edges.forEach((item) => {
    if (!item?.node) return;
    lines.push({
      // totalExpenses: item.node.totalExpenses,
      totalPayouts: item.node.totalPayouts,
      totalReimbursements: item.node.totalReimbursements,
      totalExtraPayouts: item.node.totalExtraPayouts,
      // totalExpencesFee: item.node.totalExpencesFee,
      averagePrice: item.node.averagePrice,
      date:
        createDateLabel(item.node.dateFrom as string, item.node.dateTo as string, granularity) ||
        '',
    });
  });

  return { stats, lines };
};

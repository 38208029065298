/**
 * @generated SignedSource<<0d219dbec952d2c29afd98572352ce25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteMessageInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type DeleteMessageMutation$variables = {
  input: DeleteMessageInput;
};
export type DeleteMessageMutation$data = {
  readonly deleteMessage: {
    readonly message?: string;
  } | null | undefined;
};
export type DeleteMessageMutation = {
  response: DeleteMessageMutation$data;
  variables: DeleteMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "DeleteMessage_DeletionTimeExpired",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteMessage",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70b000282dd50239694b197fbf8881ed",
    "id": null,
    "metadata": {},
    "name": "DeleteMessageMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteMessageMutation(\n  $input: DeleteMessageInput!\n) {\n  deleteMessage(input: $input) {\n    __typename\n    ... on DeleteMessage_DeletionTimeExpired {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f66850da2e7bede9f34c3a47c546d4f";

export default node;

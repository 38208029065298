import React, { useState, MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';
import remove from 'lodash/remove';
import classnames from 'classnames';

import PostsSection from '../PostsSection/PostsSection';
import PopularPosts from '../PopularPosts/PopularPosts';
import SponsoredPosts from '../SponsoredPosts/SponsoredPosts';
import Medias from '../Medias/Medias';
import TiktokMedias from '../TiktokMedias/TiktokMedias';

import styles from './Posts.css';

import { amplitude } from 'Helpers/amplitude';
import Switch from 'Molecules/Switch/Switch';
import { INSTAGRAM, TIKTOK } from 'Constants/general';
import { Posts_creator$key } from 'GraphTypes/Posts_creator.graphql';

interface Props {
  isBlockedView?: boolean;
  creator: Posts_creator$key;
  onUnlockBannerShow: (e: MouseEvent<HTMLDivElement>) => void;
}

const Posts: React.FC<Props> = (props) => {
  const { creator, isBlockedView, onUnlockBannerShow } = props;

  const data = useFragment(
    graphql`
      fragment Posts_creator on Creator {
        id
        type
        ...PopularPosts_creator
        ...SponsoredPosts_creator
        ...Medias_creator
        ...TiktokMedias_creator
      }
    `,
    creator
  );

  const type = data.type;

  const defaultSwitchItems = compact([
    type === INSTAGRAM || type === TIKTOK
      ? {
          id: 'last_posts',
          msg: 'creator_medias.tab_medias',
        }
      : null,
    {
      id: 'top_posts',
      msg: 'creator_medias.tab_top_posts',
    },
    type === TIKTOK
      ? null
      : {
          id: 'sponsored_posts',
          msg: 'creator_medias.tab_top_sponsored_posts',
        },
  ]);

  const [switchItems, setSwitchItems] = useState(defaultSwitchItems);
  const [currentItem, setCurrentItem] = useState(switchItems[0].id);

  const handleItemChange = (id: string) => {
    amplitude.sendEvent({ id: '27', category: 'profile', name: `${id}_click`, param: {} });

    setCurrentItem(id);
  };

  const handleTabHide = (value: string) => {
    const newSwitchItems = [...switchItems];

    remove(newSwitchItems, (item) => {
      return item.id === value;
    });

    setSwitchItems(newSwitchItems);
  };

  const handleContentClick = () => {
    amplitude.sendEvent<357>({
      id: '357',
      category: 'marketplace',
      name: 'creator_post_click',
      param: { creator_id: data.id },
    });
  };

  return (
    <PostsSection title="creator_statistic.subtitles.posts" isBlockedView={isBlockedView}>
      <div onClick={isBlockedView ? onUnlockBannerShow : undefined}>
        <div className={classnames(styles.switch, { [styles.disabled]: isBlockedView })}>
          <Switch
            items={switchItems}
            currentItem={currentItem}
            onChange={isBlockedView ? undefined : handleItemChange}
          />
        </div>
        <div onClick={isBlockedView ? handleContentClick : undefined}>
          <div className={classnames({ [styles.disabled]: isBlockedView })}>
            {type === INSTAGRAM && (
              <div className={currentItem !== 'last_posts' ? styles.hidden : ''}>
                <Medias creator={data} onHideTab={handleTabHide} />
              </div>
            )}
            {type === TIKTOK && (
              <div className={currentItem !== 'last_posts' ? styles.hidden : ''}>
                <TiktokMedias creator={data} hideTab={handleTabHide} />
              </div>
            )}
            <div className={currentItem !== 'top_posts' ? styles.hidden : ''}>
              <PopularPosts creator={data} onHideTab={handleTabHide} />
            </div>
            {type !== TIKTOK && (
              <div className={currentItem !== 'sponsored_posts' ? styles.hidden : ''}>
                <SponsoredPosts creator={data} onHideTab={handleTabHide} />
              </div>
            )}
          </div>
        </div>
      </div>
    </PostsSection>
  );
};

export default Posts;

import React, { useContext, useMemo } from 'react';
import Handlebars from 'handlebars';
import { graphql, useFragment } from 'react-relay';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { TemplateSelectorForOutreach_list$key } from 'GraphTypes/TemplateSelectorForOutreach_list.graphql';

interface Props {
  campaignId: string;
  query: TemplateSelectorForOutreach_list$key;
}

const TemplateSelector: React.FC<Props> = (props) => {
  const { query } = props;
  const { locale, templateData, setTemplateData, setTemplatePreview } = useContext(
    InviteOutreachCreatorsContext
  );
  const data = useFragment(
    graphql`
      fragment TemplateSelectorForOutreach_list on Query
      @argumentDefinitions(campaignId: { type: "ID!" }) {
        outreachTemplates {
          edges {
            node {
              id
              name
              locale
              htmlBody
            }
          }
        }
        campaign(id: $campaignId) {
          sampleOutreachTemplateVariables {
            name
            value
          }
        }
      }
    `,
    query
  );

  const templates = data.outreachTemplates?.edges;
  const sampleOutreachTemplateVariables = data.campaign?.sampleOutreachTemplateVariables;

  const templateVars = useMemo(() => {
    return sampleOutreachTemplateVariables?.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {});
  }, [sampleOutreachTemplateVariables]);

  return (
    <>
      <Text type="md" msg="invite_outreach_creators_modal.step_2" className={styles.subtitle} />
      <div className={styles.templates}>
        {templates?.map((item) => {
          if (!item?.node || item.node.locale !== locale) return null;

          const { id, name, htmlBody } = item.node;

          const template = Handlebars.compile(htmlBody);
          const templateBody = template(templateVars);

          const isActive = templateData.id === id;

          const handleTemplateSelect = () => {
            amplitude.sendEvent<411>({
              id: '411',
              category: 'campaign',
              name: 'outreach_template_click',
              param: { template_name: name },
            });
            setTemplateData({
              id,
              name,
            });
          };

          const handleTemplateShow = () => {
            setTemplatePreview(templateBody);
          };

          return (
            <div className={styles.template} key={id}>
              <div className={styles.templateDetails}>
                <div onClick={handleTemplateSelect}>
                  <RadioButton checked={isActive} />
                </div>
                <Text type="md" text={name} />
              </div>
              <AlterButton type="grey" icon="Eye" onClick={handleTemplateShow} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TemplateSelector;

import React, { useState, useCallback, useContext } from 'react';

import styles from '../CustomListEditDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import InputPreset from 'Components/ui/InputPreset/InputPreset';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { promisifyMutation } from 'Util/promisifyMutation';
import updateCustomList, { UpdateCustomListMutation } from 'Mutations/UpdateCustomList.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  customListId: string;
  customListName: string;
  onRemoveButtonClick?: () => void;
  onCustomListSuccessfulEdited?: () => void;
}

const CustomListEditor: React.FC<Props> = (props) => {
  const { onRemoveButtonClick, customListName, customListId, onCustomListSuccessfulEdited } = props;
  const { closeDrawer } = useContext(DrawerContext);
  const [inputValue, setInputValue] = useState<string>(customListName);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const isFormValid = Boolean(inputValue);

  const handleInputValueChange = useCallback<HandleInputValueChange>((e) => {
    const value = e.target.value;
    setInputValue(value);
    setHasError(false);
  }, []);

  const handleInputValueReset = useCallback<HandleInputValueReset>(() => {
    setInputValue('');
    setHasError(false);
  }, []);

  const handleSaveButtonClick = useCallback(() => {
    if (isSubmitLoading) return;
    const value = inputValue.trim();
    if (value === customListName) {
      closeDrawer(`custom-list-edit-${customListId}`);
      return;
    }
    if (!value) {
      setHasError(true);
      setInputValue(value);
      return;
    }
    const mutation = promisifyMutation<UpdateCustomListMutation>(updateCustomList);
    setIsSubmitLoading(true);
    mutation({ id: customListId, name: inputValue })
      .then(() => {
        onCustomListSuccessfulEdited?.();
        closeDrawer(`custom-list-edit-${customListId}`);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
    setInputValue(value);
  }, [inputValue, customListId, isSubmitLoading, onCustomListSuccessfulEdited]);

  const handleRemoveButtonClick = useCallback(() => {
    if (isSubmitLoading) return;
    onRemoveButtonClick?.();
  }, [onRemoveButtonClick, isSubmitLoading]);

  return (
    <div className={styles.contentRoot}>
      <TextPreset
        className={styles.titleTextPreset}
        header={<Text type="d2" msg="custom_list_edit_modal.title" />}
      />
      <InputPreset>
        <Input
          bordered
          forceLabelShow
          name="customListName"
          dataTest="input:customListName"
          error={hasError}
          value={inputValue}
          className={styles.input}
          labelMsg="custom_list_edit_modal.input.label"
          placeholderMsg="custom_list_edit_modal.input.placeholder"
          onChange={handleInputValueChange}
          onResetValue={handleInputValueReset}
        />
      </InputPreset>
      <ButtonPreset className={styles.buttonsGroup}>
        <Tooltip
          place="top"
          id="custom-creator-list-remove"
          tooltipMsg="custom_list_edit_modal.remove_button.tooltip"
          className={styles.tooltip}
        >
          <Button
            color="white"
            icon="Trash-delele"
            onClick={handleRemoveButtonClick}
            dataTest="button:removeCustomList"
          />
        </Tooltip>
        <div className={styles.submitButtonWrapper}>
          <div className={styles.clicker} onClick={handleSaveButtonClick} />
          <Button
            color="black"
            dataTest="button:createCustomList"
            className={styles.submitButton}
            disabled={!isFormValid}
            loading={isSubmitLoading}
            msg="custom_list_edit_modal.submit_button"
          />
        </div>
      </ButtonPreset>
    </div>
  );
};

export default CustomListEditor;
export { CustomListEditor };

// types

type InputProps = React.ComponentProps<typeof Input>;
type HandleInputValueChange = NonNullable<InputProps['onChange']>;
type HandleInputValueReset = NonNullable<InputProps['onResetValue']>;

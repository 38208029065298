import tracking from 'react-tracking';

import { sendEventsFns, initFns } from './engines';

import ls from 'Util/localStorage';

let application = '';

const track = (app) => {
  application = app;

  initFns.map((initFn) => initFn(application));

  return tracking(
    { app: application },
    {
      dispatch: (data) => sendEventInternal(data),
    }
  );
};

export const sendEvent = (event) => {
  event.app = application;
  sendEventInternal(event);
};

function sendEventInternal(event) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    // console.log(event);
  }

  if (isTrackingAvaiable) {
    sendEventsFns.forEach((sendEventFn) => {
      sendEventFn(event);
    });
  }
}

const isTrackingAvaiable = () => {
  const lsEmail = ls.get('email');
  const isProhibit = lsEmail ? lsEmail.indexOf('@insense.pro') !== -1 : null;

  return !(process.env.DEPLOY_ENV === 'production' && isProhibit);
};

export default track;

/**
 * @generated SignedSource<<461e418ae29e374b69ebbcd3e696fdde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BrandQuery$variables = {
  brandIds?: ReadonlyArray<string> | null | undefined;
};
export type BrandQuery$data = {
  readonly campaigns: {
    readonly totalCount: number;
  } | null | undefined;
};
export type BrandQuery = {
  response: BrandQuery$data;
  variables: BrandQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brandIds",
        "variableName": "brandIds"
      },
      {
        "kind": "Literal",
        "name": "stages",
        "value": [
          "ACTIVE"
        ]
      }
    ],
    "concreteType": "CampaignConnection",
    "kind": "LinkedField",
    "name": "campaigns",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e79198281a420dd68877166e8febcd3",
    "id": null,
    "metadata": {},
    "name": "BrandQuery",
    "operationKind": "query",
    "text": "query BrandQuery(\n  $brandIds: [ID!]\n) {\n  campaigns(brandIds: $brandIds, stages: [ACTIVE]) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "1589c3237187a1571102f76a98b72044";

export default node;

import { ActionsDataProps, ActionProps } from 'Actions/References.Actions';
import { ADD_REFERENCE_TO_QUEUE, REMOVE_REFERENCE_TO_QUEUE, CLEAR_ALL_REFERENCE_FROM_QUEUE } from 'Constants/index';

interface ReferencesStateProps {
  queue: ActionsDataProps[]
}

export interface StateProps {
  root: {
    references: ReferencesStateProps,
  }
}

const initialState: ReferencesStateProps = {
  queue: []
};



const references = (state: ReferencesStateProps = initialState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_REFERENCE_TO_QUEUE: {
      return { ...state, queue: [...state.queue, payload] };
    }
    case REMOVE_REFERENCE_TO_QUEUE: {
      return { ...state, queue: state.queue.filter(item => item.file !== payload.file) };
    }
    case CLEAR_ALL_REFERENCE_FROM_QUEUE: {
      return { ...state, queue: [] };
    }
    default:
      return state;
  }
};

export default references;
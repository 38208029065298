import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import AdminTransactions from './AdminTransactions';

import { AdminTransactionsContainerQuery as QueryType } from 'GraphTypes/AdminTransactionsContainerQuery.graphql';

const AdminTransactionsContainerQuery = graphql`
  query AdminTransactionsContainerQuery {
    departments {
      id
      name
    }
  }
`;

const AdminTransactionsContainer = () => {
  const data = useLazyLoadQuery<QueryType>(AdminTransactionsContainerQuery, {});

  if (!data) return null;

  const departments = data.departments.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  return <AdminTransactions departments={departments} />;
};

export default AdminTransactionsContainer;

import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ShopifyChatTooltip.pcss';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { useShopifyOnboarding } from 'Hooks/useShopifyOnboarding';
import { getCampaignShipmentLink } from 'Util/links';

interface Props {
  isAdvertiser?: boolean;
  shipmentEnabled?: boolean;
  campaignId: string;
  launched: boolean;
  onPassed?: () => void;
  tooltipClassName?: string;
}

export const ShopifyChatTooltip: FC<Props> = ({
  isAdvertiser,
  launched,
  campaignId,
  shipmentEnabled,
  onPassed,
  tooltipClassName,
}) => {
  const { isPassed, onStatePassed } = useShopifyOnboarding();
  const [isShipmentTooltipOpen, setIsShipmentTooltipOpen] = useState<boolean>(
    !isPassed('simple_chat')
  );
  const navigate = useNavigate();

  const handleCloseTooltip = useCallback(() => {
    setIsShipmentTooltipOpen(false);
    onStatePassed('simple_chat');
  }, []);

  const shopifyProjectLink = getCampaignShipmentLink(campaignId);

  const onShipmentClick = () => {
    onPassed?.();
    navigate(shopifyProjectLink);
  };

  if (!(isAdvertiser && shipmentEnabled && campaignId && launched)) {
    return null;
  }
  return (
    <Tooltip
      id={'shipment_dialog_drawer_onboarding'}
      showByDefault={isShipmentTooltipOpen}
      noArrow
      tooltipClassName={tooltipClassName}
      clickable
      customTooltip={
        <div className={styles.onboardingTooltipContainer}>
          <Text msg={'onboarding.shipment.proposal'} type="md" />
          <div onClick={handleCloseTooltip} className={styles.tooltipClose}>
            <Icon name={'Close-small'} />
          </div>
          <div onClick={onShipmentClick}>
            <Text
              msg={'onboarding.shipment.action'}
              type="md"
              color={'pink'}
              className={styles.link}
            />
          </div>
        </div>
      }
    />
  );
};

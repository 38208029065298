import React, { useEffect, useRef, useState } from 'react';

import styles from './HoverVideoPlayer.pcss';

import ErrorHandler from 'Util/errorHandler';

type Props = {
  parentRef: React.RefObject<HTMLElement>;
} & Partial<HTMLVideoElement>;

const HoverVideoPlayer = (props: Props) => {
  const { src, poster, className = '', parentRef } = props;
  const videoEl = useRef<HTMLVideoElement | null>(null);
  const [play, setPlayState] = useState(false);
  const [errorCounter, setErrorCounter] = useState(0);
  const playPromise = useRef<Promise<void>>();
  const onMouseLeave = () => {
    setPlayState(false);
  };
  const onMouseEnter = () => {
    setPlayState(true);
  };

  useEffect(() => {
    if (parentRef?.current) {
      parentRef?.current.addEventListener('mouseenter', onMouseEnter);
      parentRef?.current.addEventListener('mouseleave', onMouseLeave);
    }
    return () => {
      if (parentRef?.current) {
        parentRef?.current.removeEventListener('mouseenter', onMouseEnter);
        parentRef?.current.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (!videoEl.current) return;
    if (play) {
      playPromise.current = videoEl.current.play().catch((e) => {
        ErrorHandler.warn('User cannot start to play video in hover player', {
          error: e,
          url: src,
        });
      });
    } else {
      if (playPromise.current) {
        playPromise.current
          .then((_) => {
            videoEl.current?.pause();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [play]);
  return (
    <>
      {!play && <div className={styles.play} />}
      <video
        ref={videoEl}
        src={src}
        poster={poster}
        loop
        muted
        key={`${src}_${errorCounter}`}
        className={className}
        {...(!parentRef?.current
          ? {
              onMouseEnter,
              onMouseLeave,
            }
          : {})}
        onEnded={onMouseEnter}
        onError={() => {
          if (errorCounter < 5) {
            setErrorCounter(errorCounter + 1);
          }
        }}
      />
    </>
  );
};

export default HoverVideoPlayer;

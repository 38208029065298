/**
 * @generated SignedSource<<282579a16c606c52bbc377a89675e1f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomListCreatorItem_creator$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorDetails_creator">;
  readonly " $fragmentType": "CustomListCreatorItem_creator";
};
export type CustomListCreatorItem_creator$key = {
  readonly " $data"?: CustomListCreatorItem_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomListCreatorItem_creator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomListCreatorItem_creator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorDetails_creator"
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};

(node as any).hash = "1681ab52a5b47b6824178c2662da83d8";

export default node;

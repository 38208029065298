import React, { useCallback, useContext, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import styles from './SubscriptionLimits.pcss';

import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Limit from 'Molecules/Limit/Limit';
import {
  SubscriptionLimits_organization$key,
  SubscriptionLimits_organization$data,
  AddonId,
} from 'GraphTypes/SubscriptionLimits_organization.graphql';
import { BRANDS_ROUTE, PLANS_ROUTE, PS_LICENSES_ROUTE, USER_SETTINGS } from 'Constants/general';
import { ExtendedPlanId, PlanType, getPlans } from 'Page/advertiser/BillingPlans/data';

interface Props {
  organization: SubscriptionLimits_organization$key;
}

const SubscriptionLimits: React.FC<Props> = (props) => {
  const { organization } = props;
  const navigate = useNavigate();
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const isWhitelistingAvailable = hasFeatureFlag('whitelisting_available');

  const data = useFragment(
    graphql`
      fragment SubscriptionLimits_organization on Organization {
        counters {
          activeBrands
          psLicensedCreators
          bcaLicensedCreators
        }
        subscription {
          planId
          availableAddonIds
          addonItems {
            addon {
              id
              resourcesPerUnit
            }
            quantity
          }
          paused
          interval
          effectiveLimits {
            maxActiveBrands
            maxLicensedCreators
            maxBcaConnections
            maxSeats
          }
          baseLimits {
            maxActiveBrands
            maxLicensedCreators
            maxBcaConnections
            maxSeats
          }
        }
        members {
          totalCount
        }
      }
    `,
    organization as any
  ) as SubscriptionLimits_organization$data;

  if (!data || !organization) return null;

  const subscription = data.subscription;
  const counters = data.counters;
  const teamCount = data.members?.totalCount || 0;

  if (!subscription || !counters) return null;

  const { activeBrands, bcaLicensedCreators } = counters;
  const { planId, interval, availableAddonIds } = subscription;
  const { maxActiveBrands, maxBcaConnections, maxSeats } = subscription.effectiveLimits;
  const {
    maxActiveBrands: defaultMaxActiveBrands,
    maxBcaConnections: defaultMaxBcaConnections,
    maxSeats: defaultMaxSeats,
  } = subscription.baseLimits;

  const isTrial = planId === 'TRIAL_2022_08' || planId === 'TRIAL_2024_03';
  const isFreePlan = planId === 'FREE' || planId === 'FREE_TRIAL';
  const isAddonAvailable = (addonKey: AddonId) => availableAddonIds?.includes(addonKey);

  const currentPlanData = useMemo<PlanType | undefined>(() => {
    const plans = getPlans({
      currency: 'USD',
      plansProps: {
        [planId as ExtendedPlanId]: {
          interval,
          addons: [],
        },
      },
    });
    return plans.find((plan) => plan.planId === planId);
  }, [planId]);

  const handleManageBrands = () => {
    navigate(BRANDS_ROUTE);
  };

  const handleManageSeats = () => {
    navigate(USER_SETTINGS, { state: { section: 'TEAM' } });
  };

  const handleManageLicenses = () => {
    navigate(PS_LICENSES_ROUTE);
  };

  if (!currentPlanData?.availableAddons) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Text type="h1" msg="billing.limits.title" className={styles.title} />
        <Limit
          type={'maxActiveBrands'}
          titleMsg="billing.limits.brands.title"
          totalCount={activeBrands}
          maxCount={maxActiveBrands}
          addons={currentPlanData?.availableAddons}
          defaultLimit={defaultMaxActiveBrands}
          disabledManage={
            isTrial || isFreePlan || !isAddonAvailable('BRANDS') || maxActiveBrands === null
          }
          onManageClick={handleManageBrands}
          subscriptionData={subscription}
        />
        <Limit
          type={'maxSeats'}
          titleMsg="billing.limits.seats.title"
          totalCount={teamCount}
          maxCount={maxSeats}
          addons={currentPlanData?.availableAddons}
          defaultLimit={defaultMaxSeats}
          disabledManage={isTrial || isFreePlan || !isAddonAvailable('SEATS') || maxSeats === null}
          onManageClick={handleManageSeats}
          subscriptionData={subscription}
        />
        <Limit
          type={'maxBcaConnections'}
          titleMsg="billing.limits.bca_licenses.title"
          totalCount={bcaLicensedCreators}
          maxCount={maxBcaConnections}
          addons={currentPlanData?.availableAddons}
          defaultLimit={defaultMaxBcaConnections}
          disabledManage={
            isTrial ||
            isFreePlan ||
            maxBcaConnections === null ||
            !isAddonAvailable('WHITELISTING_LICENSES')
          }
          onManageClick={handleManageLicenses}
          subscriptionData={subscription}
        />
      </div>
    </div>
  );
};

export default SubscriptionLimits;

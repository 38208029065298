/**
 * @generated SignedSource<<a49c484bd17d91a6df8a705f70fd6237>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PerformanceQuery$variables = Record<PropertyKey, never>;
export type PerformanceQuery$data = {
  readonly campaigns: {
    readonly totalCount: number;
  } | null | undefined;
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type PerformanceQuery = {
  response: PerformanceQuery$data;
  variables: PerformanceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PerformanceQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19cf12e7d4687cd002e6f101b0c5c402",
    "id": null,
    "metadata": {},
    "name": "PerformanceQuery",
    "operationKind": "query",
    "text": "query PerformanceQuery {\n  campaigns {\n    totalCount\n  }\n  currentUser {\n    organization {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c30471aeeffa0d2767302bf9c0d44a11";

export default node;

import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import styles from './UpButton.css';

import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

const SCROLL_OFFSET = 60;

interface Props {
  className: string;
  containerId: string;
  onClick: () => void;
}

const UpButton: React.FC<Props> = (props) => {
  const { className, containerId, onClick } = props;

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const container = containerId ? document.getElementById(containerId) : document;
    container?.addEventListener('scroll', handleContainerScroll);

    return () => {
      container?.removeEventListener('scroll', handleContainerScroll);
    };
  }, []);

  const handleContainerScroll = (e: any) => {
    const { target } = e;

    const scrollTop = containerId ? target.scrollTop : Number(document.documentElement.scrollTop);
    if (scrollTop > SCROLL_OFFSET) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  };

  const handleButtonClick = () => {
    if (containerId) {
      onClick();
    } else {
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <div
      className={classnames(className, styles.root, { [styles.show]: isShown })}
      onClick={handleButtonClick}
    >
      <Icon name="Arrow-big-left" className={styles.icon} />
      <Text size="sm" color="grayDog" className={styles.text} msg="browse_creators.up" />
    </div>
  );
};

export default UpButton;

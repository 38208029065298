import { SystemProjectEvent_event$data } from 'GraphTypes/SystemProjectEvent_event.graphql';
import {
  OFFER_ACCEPTANCE,
  PROJECT_LAUNCH,
  DRAFT_DEADLINE,
  PROJECT_COMPLETION,
  PRICE_CHANGE,
  PRICE_CHANGE_REQUEST,
  PRICE_CHANGE_REQUEST_REJECTION,
  PRICE_CHANGE_REQUEST_ACCEPTANCE,
  PROJECT_ARCHIVATION,
  PROJECT_ARCHIVATION_CANCELLATION,
  PRODUCT_SHIPMENT_COMPLETION,
  MESSAGE,
  CONTENT_REVIEW_SUBMISSION,
  CONTENT_REVIEW_FAIL,
  CONTENT_REVIEW_PASS,
  PUBLICATION_EVENT,
  PUBLICATION_DEADLINE_CHANGED,
  DEADLINE,
  PUBLICATION_REQUEST,
  DEADLINE_CHANGE_REQUEST,
  DEADLINE_CHANGE_REQUEST_ACCEPTED,
  DEADLINE_CHANGE_REQUEST_REJECTED,
  CONTENT_REVIEW_REQUEST,
  CONTENT_REVIEW_REQUEST_FAILED,
  EXTRA_PAYOUT,
  POST_PUBLICATION,
  REIMBURSEMENT,
  PRODUCT_SEEDING_CONTENT_SUBMISSION,
  UNRESPONSIVE_CONTRACTOR_EVENT,
} from 'Types/enums';

export const getAvatar = (actor: SystemProjectEvent_event$data['actor'] | undefined) => {
  const typename = actor?.__typename;

  switch (typename) {
    case 'Brand':
      return actor?.logo?.transformations?.brandLogoUrl;
    case 'InstagramCreator':
    case 'YoutubeCreator':
    case 'TiktokCreator':
      return actor?.profile?.profilePictureFile?.transformations?.collageThumbnailUrl;

    default:
      return '';
  }
};

export const visibleProjectEventsType = [
  OFFER_ACCEPTANCE,
  PROJECT_LAUNCH,
  DEADLINE,
  DEADLINE_CHANGE_REQUEST,
  DEADLINE_CHANGE_REQUEST_ACCEPTED,
  DEADLINE_CHANGE_REQUEST_REJECTED,
  DRAFT_DEADLINE,
  PROJECT_COMPLETION,
  PRICE_CHANGE,
  PRICE_CHANGE_REQUEST,
  PRICE_CHANGE_REQUEST_REJECTION,
  PRICE_CHANGE_REQUEST_ACCEPTANCE,
  PROJECT_ARCHIVATION,
  PROJECT_ARCHIVATION_CANCELLATION,
  PRODUCT_SHIPMENT_COMPLETION,
  MESSAGE,
  CONTENT_REVIEW_SUBMISSION,
  CONTENT_REVIEW_FAIL,
  CONTENT_REVIEW_PASS,
  PUBLICATION_EVENT,
  CONTENT_REVIEW_REQUEST,
  CONTENT_REVIEW_REQUEST_FAILED,
  PUBLICATION_REQUEST,
  EXTRA_PAYOUT,
  PUBLICATION_DEADLINE_CHANGED,
  POST_PUBLICATION,
  DEADLINE,
  REIMBURSEMENT,
  PRODUCT_SEEDING_CONTENT_SUBMISSION,
  UNRESPONSIVE_CONTRACTOR_EVENT,
];

import { UPDATE_CURRENT_USER_DATA, CLEAR_CURRENT_USER_DATA } from 'Constants';
import type { CurrentUserData } from 'Types/reducers';

export const updateCurrentUserData = (payload: Partial<CurrentUserData>) => ({
  payload,
  type: UPDATE_CURRENT_USER_DATA,
});

export const clearCurrentUserData = () => ({
  type: CLEAR_CURRENT_USER_DATA,
});

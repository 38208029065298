import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  ChangeProjectPriceInput,
  ChangeProjectPriceMutation$data,
  ChangeProjectPriceMutation,
} from 'GraphTypes/ChangeProjectPriceMutation.graphql';

const mutation = graphql`
  mutation ChangeProjectPriceMutation($input: ChangeProjectPriceInput!) {
    changeProjectPrice(input: $input) {
      project {
        id
        price
        currency
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: ChangeProjectPriceInput,
  resolve?: (response: ChangeProjectPriceMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();
  const { price, projectId } = data;
  const variables = {
    input: {
      projectId,
      price,
      id,
    },
  };

  commitMutation<ChangeProjectPriceMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  AuthenticateWithFacebookMutation,
  AuthenticateWithFacebookMutation$data,
  AuthenticateWithFacebookInput,
} from 'GraphTypes/AuthenticateWithFacebookMutation.graphql';
import ErrorHandler from 'Util/errorHandler';

const mutation = graphql`
  mutation AuthenticateWithFacebookMutation($input: AuthenticateWithFacebookInput!) {
    authenticateWithFacebook(input: $input) {
      socialIdentity {
        id
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: AuthenticateWithFacebookInput,
  resolve?: (response: AuthenticateWithFacebookMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { accessToken, code } = data;

  const variables = {
    input: {
      id,
      code,
      accessToken,
    },
  };

  commitMutation<AuthenticateWithFacebookMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        ErrorHandler.error('Crash while fb auth', { errors });
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      ErrorHandler.error('Crash while fb auth', { error });
      if (reject) {
        reject(error);
      }
    },
  });
};

/**
 * @generated SignedSource<<a511ef7c71be059a7ba3b9353ad57d38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentReviewSubmission_event$data = {
  readonly __typename: string;
  readonly createdAt: any;
  readonly creatives?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly file: {
          readonly " $fragmentSpreads": FragmentRefs<"MessageMedia_file">;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly reviewFiles?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly file: {
          readonly " $fragmentSpreads": FragmentRefs<"MessageMedia_file">;
        };
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly " $fragmentType": "ContentReviewSubmission_event";
};
export type ContentReviewSubmission_event$key = {
  readonly " $data"?: ContentReviewSubmission_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentReviewSubmission_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "file",
    "plural": false,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MessageMedia_file"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentReviewSubmission_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "reviewFiles",
          "args": null,
          "concreteType": "ContentReviewAttachmentConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentReviewAttachmentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentReviewAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ContentReviewSubmission",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreativeConnection",
          "kind": "LinkedField",
          "name": "creatives",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreativeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Creative",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ProductSeedingContentSubmission",
      "abstractKey": null
    }
  ],
  "type": "ProjectEvent",
  "abstractKey": "__isProjectEvent"
};
})();

(node as any).hash = "81cad5486d49561517a674cb9c7cca8a";

export default node;

import React from 'react';
import { useSelector } from 'react-redux';

import styles from './Footer.css';

import Text from 'Atoms/Text/Text';
import { StoreState } from 'Types/reducers';

const Footer: React.FC = () => {
  const showFooter = useSelector((state: StoreState) => state.root.ui.showFooter);

  if (!showFooter) return null;

  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root}>
      <Text msg="footer.with_love" />
      <Text
        color="grayDog"
        text={`Insense © ${currentYear}.`}
        className={styles.title}
      />
    </footer>
  );
};

export default Footer;

import React, { useContext } from 'react';
import track from 'react-tracking';

import styles from './Error404.css';

import Text from 'Atoms/Text/Text';
import Page from 'Templates/Page/Page';

const Error404 = () => {
  return (
    <Page className={styles.root}>
      <Text weight="500" color="white" text="404" className={styles.errorText} />
      <div className={styles.details}>
        <Text weight="500" msg="error_page.title" className={styles.title} />
        <Text msg="error_page.descr" className={styles.descr} />
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'error_404',
  },
  { dispatchOnMount: true }
)(Error404);

import { createContext } from 'react';

import type { FeatureFlagId } from './types';

import type { UserProviderQuery$data } from 'GraphTypes/UserProviderQuery.graphql';

type FeatureFlagsContextType = {
  initFeatureFlags: (
    featureFlags: NonNullable<UserProviderQuery$data['currentUser']>['featureFlags'],
    userId: string
  ) => void;
  hasFeatureFlag: (featureFlagId: FeatureFlagId) => boolean;
};

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({} as FeatureFlagsContextType);

export { FeatureFlagsContext };
export type { FeatureFlagsContextType };

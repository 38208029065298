import React, { useState, useMemo, useContext } from 'react';

import styles from './NewPrice.css';

import { getCurrencySymbol } from 'Util/textFormatter';
import { createNumber, createSum } from 'Util/numberFormatter';
import changeProjectPrice from 'Mutations/ChangeProjectPrice.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import { Currency } from 'GraphTypes/ChangeProjectPriceMutation.graphql';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  projectId: string;
  priceCap?: number | null;
  currency?: Currency | null;
}

const NewPrice: React.FC<Props> = (props) => {
  const { projectId, priceCap, currency = 'USD' } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const [price, setPrice] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleChange = (price: string) => {
    if (!touched) {
      setTouched(true);
    }
    if (price) {
      const newValue = price.replace(/[^0-9]/g, '');
      const newPrice = parseInt(newValue, 10) || undefined;
      setPrice(newPrice);
    } else {
      setPrice(undefined);
    }
  };

  const handleNewPriceChange = () => {
    setLoading(true);
    changeProjectPrice({ projectId, price: Number(price) }, handleClose);
  };

  const handleClose = () => {
    setPrice(undefined);
    setLoading(false);
    setTouched(false);
    closeDrawer(`new-project-price-${projectId}`);
  };

  const errorText = useMemo(() => {
    if (!touched) return undefined;
    if (priceCap && price) {
      return Number(price) > Number(priceCap) ? 'form.error.pricecap' : '';
    }
    return price ? undefined : 'form.error_required';
  }, [price, touched]);

  const priceValue = price ? createNumber(price) : '';
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <Drawer rootKey={`new-project-price-${projectId}`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <Text type="d2" msg="price_change_modal.title" className={styles.title} />
          <Input
            bordered
            value={priceValue}
            labelMsg="price_change_modal.new_price"
            placeholderMsg="price_change_modal.new_price"
            className={styles.input}
            rightSymbol={<Text type="sm" text={currencySymbol} />}
            handleChange={handleChange}
            error={!!errorText}
          />
          {errorText && (
            <Text
              type="sm"
              msg={errorText}
              className={styles.error}
              formatValues={{ priceCap: createSum(priceCap, currency) }}
            />
          )}
        </div>
        <Button
          color="black"
          loading={loading}
          disabled={!price}
          msg="price_change_modal.apply"
          onClick={handleNewPriceChange}
        />
      </div>
    </Drawer>
  );
};

export default NewPrice;

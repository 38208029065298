import { graphql } from 'react-relay';

import type { CreatorQuery as CreatorQueryType } from 'GraphTypes/CreatorQuery.graphql';

export default graphql`
  query CreatorQuery($creatorId: ID!) {
    creator(id: $creatorId) {
      profile {
        tags {
          name
        }
      }
    }
    currentUser {
      organization {
        subscription {
          paused
        }
      }
    }
  }
`;

export type { CreatorQueryType };

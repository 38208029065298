/**
 * @generated SignedSource<<b1503ab68a4615b47ac8edc917242629>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type Payment_PaymentMethodType = "CARD" | "CARDPAY" | "GAMEMONEY" | "PAYPAL" | "QIWI" | "WIRE" | "ZELLE" | "%future added value";
export type Payment_PaymentServiceProvider = "QIWI" | "UNLIMINT" | "%future added value";
export type Payment_WithdrawalStatus = "CANCELED" | "CONFIRMED" | "FAILED" | "PROCESSING" | "UNSENT" | "%future added value";
export type W9WarningLevel = "HIGH" | "LOW" | "MEDIUM" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WithdrawalList_withdrawals$data = {
  readonly payment: {
    readonly withdrawals: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly amount: number;
          readonly createdAt: any;
          readonly currency: Currency;
          readonly id: string;
          readonly paymentAccount: {
            readonly owner: {
              readonly contractorProfile: {
                readonly firstName: string | null | undefined;
                readonly lastName: string | null | undefined;
              } | null | undefined;
              readonly email: string;
            } | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"Profiles_paymentAccount">;
          };
          readonly paymentMethod: {
            readonly email?: string | null | undefined;
            readonly type: Payment_PaymentMethodType | null | undefined;
            readonly w9WarningLevel: W9WarningLevel;
          } | null | undefined;
          readonly paymentServiceProvider: Payment_PaymentServiceProvider | null | undefined;
          readonly status: Payment_WithdrawalStatus;
          readonly " $fragmentSpreads": FragmentRefs<"AdminComment_comment" | "PaymentServiceProviders_psp" | "TransferStatus_status">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly summary: {
        readonly rubTotal: number | null | undefined;
        readonly usdTotal: number | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WithdrawalList_withdrawals";
};
export type WithdrawalList_withdrawals$key = {
  readonly " $data"?: WithdrawalList_withdrawals$data;
  readonly " $fragmentSpreads": FragmentRefs<"WithdrawalList_withdrawals">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "accountQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "campaignQuery"
    },
    {
      "defaultValue": 15,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currencies"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateTo"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentDataQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentMethodTypes"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "profileQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "statuses"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "w9WarningLevels"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "payment",
          "withdrawals"
        ]
      }
    ]
  },
  "name": "WithdrawalList_withdrawals",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Payment_Query",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "alias": "withdrawals",
          "args": null,
          "concreteType": "Payment_WithdrawalConnection",
          "kind": "LinkedField",
          "name": "__Admin_withdrawals_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Payment_WithdrawalEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Payment_Withdrawal",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currency",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "paymentServiceProvider",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "paymentMethod",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "type": "Payment_Paypal",
                          "abstractKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "w9WarningLevel",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "PaymentServiceProviders_psp"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AdminComment_comment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "TransferStatus_status"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Payment_Account",
                      "kind": "LinkedField",
                      "name": "paymentAccount",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "Profiles_paymentAccount"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "owner",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ContractorProfile",
                              "kind": "LinkedField",
                              "name": "contractorProfile",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "firstName",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "lastName",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Payment_WithdrawalConnectionSummary",
              "kind": "LinkedField",
              "name": "summary",
              "plural": false,
              "selections": [
                {
                  "alias": "rubTotal",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "currency",
                      "value": "RUB"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "totalAmount",
                  "storageKey": "totalAmount(currency:\"RUB\")"
                },
                {
                  "alias": "usdTotal",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "currency",
                      "value": "USD"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "totalAmount",
                  "storageKey": "totalAmount(currency:\"USD\")"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "5a819e9dd298e4008d88607fe3ea15d5";

export default node;

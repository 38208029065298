import React, { Suspense } from 'react';

import BriefProvider from '../Brief.Context';

import styles from './BriefTemplate.css';
import BriefHeader from './BriefHeader/BriefHeader';
import BriefTemplateMenu from './BriefTemplateMenu/BriefTemplateMenu';
import BriefTemplateContent from './BriefTemplateContent/BriefTemplateContent';
import BriefTemplateMobilePreview from './BriefTemplateMobilePreview/BriefTemplateMobilePreview';

import Spinner from 'Atoms/Spinner/Spinner';
import { BriefQuery$data, CampaignUseCase } from 'GraphTypes/BriefQuery.graphql';

interface Props {
  campaignId: string;
  briefId: string;
  campaign: BriefQuery$data['campaign'] | null;
  currentUser: BriefQuery$data['currentUser'];
  useCase?: CampaignUseCase;
}

const BriefTemplate: React.FC<Props> = (props) => {
  const { campaignId, campaign, briefId, currentUser, useCase } = props;

  if (!campaign) return null;

  const counters = {
    launchedCampaigns: currentUser?.organization?.counters?.launchedCampaigns ?? null,
    activeBrands: currentUser?.organization?.counters?.activeBrands ?? null,
  };

  const availableCampaignTypes = campaign?.organization?.subscription?.availableCampaignTypes
    ? Array.from(campaign?.organization?.subscription?.availableCampaignTypes)
    : [];

  const availableCampaignUseCases =
    campaign?.organization?.subscription?.availableCampaignUseCases || [];

  const subscription = {
    maxLaunchedCampaigns:
      currentUser?.organization?.subscription?.effectiveLimits.maxLaunchedCampaigns ?? null,
    maxActiveBrands:
      currentUser?.organization?.subscription?.effectiveLimits.maxActiveBrands ?? null,
    availableCampaignTypes,
    availableCampaignUseCases,
  };

  const planId = currentUser?.organization?.subscription?.planId ?? null;

  return (
    <BriefProvider
      planId={planId}
      counters={counters}
      subscription={subscription}
      campaignType={campaign.type}
      availableCampaignTypes={availableCampaignTypes}
      useCase={useCase}
      availableCampaignUseCases={availableCampaignUseCases}
    >
      <div className={styles.root}>
        <BriefHeader
          briefId={briefId}
          campaignId={campaignId}
          campaign={campaign}
          planId={planId}
        />
        <div className={styles.container}>
          <div className={styles.menu}>
            <BriefTemplateMenu />
          </div>
          <div className={styles.content}>
            <Suspense fallback={<Spinner className={styles.loader} />}>
              <BriefTemplateContent campaignId={campaignId} />
            </Suspense>
          </div>
          <div className={styles.mobilePreview}>
            <BriefTemplateMobilePreview campaignId={campaignId} />
          </div>
        </div>
      </div>
    </BriefProvider>
  );
};

export default BriefTemplate;

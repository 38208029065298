import { createSelector } from 'reselect';

export const getHeaderState = state => {
  return state.root.ui.showHeader;
};

export const getGeneralState = state => {
  return state.root.general;
};

export const getSearchParamsData = createSelector(
  [getGeneralState],
  (general) => {
    return general && general.searchParamsData;
  }
);
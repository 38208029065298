import React from 'react';
import { graphql, useFragment } from 'react-relay';

import FinishDealAction from '../FinishDealAction/FinishDealAction';
import FbAdsAction from '../FbAdsAction/FbAdsAction';

import { ContentCreationStage_project$key } from 'GraphTypes/ContentCreationStage_project.graphql';

interface Props {
  project: ContentCreationStage_project$key | null;
  canUsePaidSocial: boolean;
}

const ContentCreationStage: React.FC<Props> = (props) => {
  const { project, canUsePaidSocial } = props;

  const data = useFragment(
    graphql`
      fragment ContentCreationStage_project on Project {
        id
        ...FinishDealAction_project
        ...FbAdsAction_project
      }
    `,
    project
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <FinishDealAction project={data} />
      {canUsePaidSocial && <FbAdsAction project={data} />}
    </>
  );
};

export default ContentCreationStage;

import {
  SET_MODAL, TOGGLE_MODAL
} from 'Constants';

export const setModal = (name: string, attach: { [x: string]: unknown } | null = null, forceClose?: boolean) => ({
  type: SET_MODAL,
  payload: { name, attach, forceClose },
});

export const toggleModal = () => ({
  type: TOGGLE_MODAL,
});
import React, { useCallback, useEffect, useState, useContext } from 'react';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { graphql, fetchQuery } from 'relay-runtime';

import styles from './ContinueProfileCreation.pcss';

import Text from 'Atoms/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { CONTRACTOR_PROFILES_ROUTE, SUCCESS, GRAPHIQL_ROUTE } from 'Constants/general';
import type { ContinueProfileCreationDrawerQuery } from 'GraphTypes/ContinueProfileCreationDrawerQuery.graphql';
import environment from 'Api/Environment';
import ls from 'Util/localStorage';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';

type DrawerProps = React.ComponentProps<typeof Drawer>;

const query = graphql`
  query ContinueProfileCreationDrawerQuery {
    currentUser {
      id
      creators {
        edges {
          node {
            profile {
              registrationIsCompleted
            }
            review {
              type
            }
          }
        }
      }
    }
  }
`;

type GetLsKeyNamespace = (currentUserId: string) => string;
const getLsNamespace: GetLsKeyNamespace = (currentUserId) =>
  `ContinueProfileCreationDrawer.${currentUserId}`;

const LS_KEY = 'state';

interface Props {
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
}

const ContinueProfileCreationDrawer: React.FC<Props> = (props) => {
  const { drawerProps } = props;
  const [userId, setUserId] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticatedUser, isRegisteredUser, user } = useContext(Auth0Context);

  useEffect(() => {
    const isGraphiqlRoute = location.pathname.startsWith(GRAPHIQL_ROUTE);
    if (
      !(isAuthenticatedUser && isRegisteredUser) ||
      isGraphiqlRoute ||
      user?.type !== 'CONTRACTOR'
    ) {
      return;
    }

    fetchQuery<ContinueProfileCreationDrawerQuery>(environment, query, {}).subscribe({
      next: (res) => {
        if (!res) return;

        const { currentUser } = res;
        if (currentUser) {
          const userId = currentUser.id;
          const lsNamespace = getLsNamespace(userId);
          const isAlreadyShown = Boolean(ls.get(LS_KEY, lsNamespace));
          if (isAlreadyShown) return;

          const hasIsNotCompleted = currentUser.creators?.edges?.some((item) => {
            return (
              item?.node?.profile?.registrationIsCompleted !== true &&
              item?.node?.review?.type !== SUCCESS
            );
          });

          setUserId(userId);
          if (hasIsNotCompleted) {
            setOpen(true);
          }
        }
      },
    });
  }, [isAuthenticatedUser, isRegisteredUser, location.pathname]);

  useEffect(() => {
    if (drawerProps?.opened !== undefined) {
      setOpen(drawerProps?.opened);
    }
  }, [drawerProps?.opened]);

  const handleButtonClick = useCallback(() => {
    const lsNamespace = getLsNamespace(userId);
    ls.set(LS_KEY, 1, lsNamespace);
    navigate(CONTRACTOR_PROFILES_ROUTE);
    setOpen(false);
  }, [navigate, userId]);

  const handleOpenDrawer = useCallback(() => {
    setOpen(true);
    drawerProps?.onOpen?.();
  }, [drawerProps?.onOpen, userId]);

  const handleCloseDrawer = useCallback(() => {
    const lsNamespace = getLsNamespace(userId);
    ls.set(LS_KEY, 1, lsNamespace);
    setOpen(false);
  }, [userId]);

  return (
    <Drawer
      {...drawerProps}
      opened={open}
      onOpen={handleOpenDrawer}
      onClose={handleCloseDrawer}
      className={classnames(styles.root, drawerProps?.className)}
      rootKey="continue-profile-creation"
    >
      <div className={styles.contentRoot}>
        <div>
          <Text
            weight="400"
            tag="h2"
            msg="continue_profile_creation_drawer.title"
            align="left"
            className={styles.title}
          />
          <Text
            msg="continue_profile_creation_drawer.description"
            className={styles.description}
            align="left"
          />
        </div>
        <AlterButton
          type="black"
          msg="continue_profile_creation_drawer.button"
          onClick={handleButtonClick}
          className={styles.btn}
        />
      </div>
    </Drawer>
  );
};

export default ContinueProfileCreationDrawer;
export { LS_KEY, getLsNamespace };

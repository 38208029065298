import React, { useRef, useState } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import { useDispatch } from 'react-redux';
import compact from 'lodash/compact';

import { ParamsType } from '../data';

import styles from './WithdrawalList.pcss';
import { items } from './data';
import TransferStatus from './TransferStatus/TransferStatus';
import AdminComment from './AdminComment/AdminComment';
import Profiles from './Profiles/Profiles';
import PaymentServiceProviders from './PaymentServiceProviders/PaymentServiceProviders';
import TooltipForCopyText from './TooltipForCopyText/TooltipForCopyText';

import { setModal } from 'Actions/Modal.Actions';
import { modalName } from 'Types/modals';
import { createWithdrawalDate } from 'Util/dateCreator';
import { createNumber, createSum } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import TextButton from 'Atoms/TextButton/TextButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Table from 'Containers/TableContainer/TableContainer';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { WithdrawalList_withdrawals } from 'GraphTypes/WithdrawalList_withdrawals.graphql';

const COUNT = 15;
const FAILED = 'FAILED';

interface Props {
  relay: RelayPaginationProp;
  withdrawals?: WithdrawalList_withdrawals | null;
}

const WithdrawalList: React.FC<Props & ParamsType> = (props) => {
  const { relay, withdrawals } = props;

  const withdrawalsList = withdrawals?.payment?.withdrawals?.edges;
  const totalRUB = withdrawals?.payment?.withdrawals?.summary.rubTotal;
  const totalUSD = withdrawals?.payment?.withdrawals?.summary.usdTotal;

  const dispatch = useDispatch();
  const [currentTransactionId, setCurrentTransactionId] = useState<string>();
  const [currentTransactionProcess, setCurrentTransactionProcess] = useState<boolean>(false);
  const currentTransactionProvider = useRef();

  const currentTransactionData = {
    currentTransactionId,
    setCurrentTransactionId,
    currentTransactionProcess,
    setCurrentTransactionProcess,
    currentTransactionProvider,
  };

  const list = withdrawalsList?.map((item) => {
    if (!item?.node) return null;

    const {
      id,
      createdAt,
      paymentAccount,
      currency,
      amount,
      paymentMethod,
      status: statusValue,
    } = item.node;

    const email = paymentAccount?.owner?.email || '';
    const name = compact([
      paymentAccount.owner?.contractorProfile?.firstName,
      paymentAccount.owner?.contractorProfile?.lastName,
    ]).join(' ');

    const handleDetailsClick = () => {
      setCurrentTransactionId(id);

      dispatch(setModal(modalName.WITHDRAWAL_REQUEST, { id, setCurrentTransactionProcess }));
    };

    const requestId = (
      <TooltipForCopyText items={[id]} position="left" place="bottom">
        <div>
          <Text text={id} overflow />
          <Text size="sm" color="grayDog" text={createWithdrawalDate(createdAt)} />
        </div>
      </TooltipForCopyText>
    );

    const account = (
      <TooltipForCopyText items={[name, email]} position="left" place="bottom">
        <div>
          <Text text={email} overflow />
          <Text size="sm" color="grayDog" overflow text={name} />
        </div>
      </TooltipForCopyText>
    );

    // const method = paymentMethod?.type === 'PAYPAL' ? (
    //   <TooltipForCopyText items={compact([paymentMethod.email])}>
    //     <Text text={paymentMethod.email} overflow />
    //   </TooltipForCopyText>
    // ) : (
    //   <TextButton msg="admin_withdrawals.payment_details" onClick={handleDetailsClick} />
    // );

    const method = (
      <TextButton msg="admin_withdrawals.payment_details" onClick={handleDetailsClick} />
    );

    const psp = <PaymentServiceProviders psp={item.node} {...currentTransactionData} />;

    const status = <TransferStatus status={item.node} {...currentTransactionData} />;

    const comment = <AdminComment comment={item.node} />;

    const profile = <Profiles paymentAccount={paymentAccount} />;

    const presetStyle = statusValue === FAILED ? { background: 'red' } : null;

    let w9Data = { tooltipMsg: '', color: '' };

    if (paymentMethod?.w9WarningLevel === 'HIGH') {
      w9Data = { color: 'red', tooltipMsg: 'admin_withdrawals.payout_limit.failed' };
    } else if (paymentMethod?.w9WarningLevel === 'MEDIUM') {
      w9Data = { color: 'yellow', tooltipMsg: 'admin_withdrawals.payout_limit.warning' };
    } else if (paymentMethod?.w9WarningLevel === 'LOW') {
      w9Data = { color: 'green', tooltipMsg: 'admin_withdrawals.payout_limit.accepted' };
    }

    const amountEl = (
      <Tooltip id={`amoint-for-${id}`} place="bottom" tooltipMsg={w9Data.tooltipMsg}>
        <div className={styles.amount}>
          <Text text={createNumber(Math.abs(amount))} />
          {w9Data.color && (
            <Icon size="sm" name="w9" color={w9Data.color} className={styles.w9Icon} />
          )}
        </div>
      </Tooltip>
    );

    return {
      requestId,
      account,
      profile,
      currency,
      psp,
      amount: amountEl,
      country: '—',
      method,
      status,
      comment,
      presetStyle,
    };
  });

  const createTotal = (amount: number, currency: string) => {
    return (
      <div className={styles.totalItem}>
        <Text text={`Total ${currency}: `} />
        <Text weight="700" text={createSum(amount, currency)} />
      </div>
    );
  };

  return (
    <div>
      <LoaderHandler relay={relay} count={COUNT} className={styles.list}>
        <Table list={list} data={items} />
      </LoaderHandler>
      <div className={styles.totalsWrap}>
        <div className={styles.totals}>
          {Number(totalUSD) > 0 && createTotal(Number(totalUSD), 'USD')}
          {Number(totalRUB) > 0 && createTotal(Number(totalRUB), 'RUB')}
        </div>
      </div>
    </div>
  );
};

export default createPaginationContainer(
  WithdrawalList,
  {
    withdrawals: graphql`
      fragment WithdrawalList_withdrawals on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 15 }
        cursor: { type: "String" }
        dateFrom: { type: "DateTime" }
        dateTo: { type: "DateTime" }
        currencies: { type: "[Currency!]" }
        statuses: { type: "[Payment_WithdrawalStatus!]" }
        paymentMethodTypes: { type: "[Payment_PaymentMethodType!]" }
        accountQuery: { type: "String" }
        profileQuery: { type: "String" }
        paymentDataQuery: { type: "String" }
        campaignQuery: { type: "String" }
        w9WarningLevels: { type: "[W9WarningLevel!]" }
      ) {
        payment {
          withdrawals(
            first: $count
            after: $cursor
            dateFrom: $dateFrom
            dateTo: $dateTo
            currencies: $currencies
            statuses: $statuses
            paymentMethodTypes: $paymentMethodTypes
            accountQuery: $accountQuery
            profileQuery: $profileQuery
            paymentDataQuery: $paymentDataQuery
            campaignQuery: $campaignQuery
            w9WarningLevels: $w9WarningLevels
          ) @connection(key: "Admin_withdrawals", filters: []) {
            edges {
              node {
                id
                createdAt
                currency
                amount
                status
                paymentServiceProvider
                paymentMethod {
                  type
                  ... on Payment_Paypal {
                    email
                  }
                  w9WarningLevel
                }
                ...PaymentServiceProviders_psp
                ...AdminComment_comment
                ...TransferStatus_status
                paymentAccount {
                  ...Profiles_paymentAccount
                  owner {
                    email
                    contractorProfile {
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
            summary {
              rubTotal: totalAmount(currency: RUB)
              usdTotal: totalAmount(currency: USD)
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.withdrawals.payment?.withdrawals;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
        currencies: props.currencies,
        statuses: props.statuses,
        paymentMethodTypes: props.paymentMethodTypes,
        accountQuery: props.accountQuery,
        profileQuery: props.profileQuery,
        paymentDataQuery: props.paymentDataQuery,
        campaignQuery: props.campaignQuery,
        w9WarningLevels: props.w9WarningLevels,
      };
    },
    query: graphql`
      query WithdrawalListQuery(
        $count: Int!
        $cursor: String
        $dateFrom: DateTime
        $dateTo: DateTime
        $currencies: [Currency!]
        $statuses: [Payment_WithdrawalStatus!]
        $paymentMethodTypes: [Payment_PaymentMethodType!]
        $accountQuery: String
        $profileQuery: String
        $paymentDataQuery: String
        $campaignQuery: String
        $w9WarningLevels: [W9WarningLevel!]
      ) {
        ...WithdrawalList_withdrawals
          @arguments(
            count: $count
            cursor: $cursor
            dateFrom: $dateFrom
            dateTo: $dateTo
            currencies: $currencies
            statuses: $statuses
            paymentMethodTypes: $paymentMethodTypes
            accountQuery: $accountQuery
            profileQuery: $profileQuery
            paymentDataQuery: $paymentDataQuery
            campaignQuery: $campaignQuery
            w9WarningLevels: $w9WarningLevels
          )
      }
    `,
  }
);

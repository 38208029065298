import React, { useContext, useMemo, useCallback, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useNavigate, useLocation } from 'react-router-dom';

import User from '../User/User';

import styles from './UnknownUserHeader.pcss';

import { DASHBOARD_ROUTE, SIGNUP_COMPLETE_ADVERTISER_SURVEY_ROUTE } from 'Constants/general';
import Logo from 'Atoms/Logo/Logo';
import type { UnknownUserHeaderQuery } from 'GraphTypes/UnknownUserHeaderQuery.graphql';
import Text from 'Atoms/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import { amplitude } from 'Helpers/amplitude';

type Props = {
  className?: string;
  hideUserMenu?: boolean;
  profile?: {
    firstName: string;
    lastName?: string;
  };
};

const query = graphql`
  query UnknownUserHeaderQuery {
    currentUser {
      advertiserProfile {
        firstName
        lastName
      }
      contractorProfile {
        firstName
        lastName
      }
    }
  }
`;

const UnknownUserHeader: React.FC<Props> = (props) => {
  const { className, hideUserMenu } = props;
  const { user, logout } = useContext(Auth0Context);
  const navigate = useNavigate();
  const location = useLocation();

  const response = useLazyLoadQuery<UnknownUserHeaderQuery>(
    query,
    {},
    { fetchPolicy: 'store-only' }
  );
  const currentUser = response?.currentUser;
  const profile = currentUser?.advertiserProfile || currentUser?.contractorProfile;

  const menuList = useMemo<UserComponentProps['menuList']>(() => {
    return [
      {
        id: 'logout',
        textData: {
          textData: { msg: 'menu.sign_out' },
        },
        onClick: logout,
      },
    ];
  }, [logout]);

  const label = useMemo<string>(() => {
    if (profile?.firstName && profile.lastName) {
      return `${profile.firstName} ${profile.lastName}`;
    }
    if (user?.email) {
      return user?.email;
    }
    return '';
  }, [profile?.firstName, profile?.firstName, user?.email]);

  const menuContent = useMemo<JSX.Element>(() => {
    const hideName = !profile?.firstName || !profile?.lastName;
    return (
      <div className={styles.userContent}>
        <div className={styles.userInfoWrap}>
          <div className={styles.userInfo}>
            <Avatar size="40" letters={user?.email} dataTest="avatar:header:contextMenu" />
            <div className={styles.names}>
              {!hideName && (
                <Text
                  weight="500"
                  text={`${profile?.firstName || ''} ${profile?.lastName || ''}`}
                />
              )}
              <Text size="sm" color="grayDog" text={user?.email} />
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    // user?.picture,
    user?.email,
    profile?.firstName,
    profile?.lastName,
    label,
  ]);

  const handleLinkLogoClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();
      if (location.pathname.startsWith(SIGNUP_COMPLETE_ADVERTISER_SURVEY_ROUTE)) {
        window.location.href = DASHBOARD_ROUTE;
        return;
      }
      amplitude.sendEvent({
        id: 237,
        category: 'pageview',
        name: 'logo_insense_click',
        param: {
          referral_url: DASHBOARD_ROUTE,
        },
      });
      navigate(DASHBOARD_ROUTE);
    },
    [location.pathname]
  );

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.logo}>
        <a href={DASHBOARD_ROUTE} tabIndex={-1} onClick={handleLinkLogoClick}>
          <Logo />
        </a>
      </div>
      {!hideUserMenu && <User label={label} menuContent={menuContent} menuList={menuList} />}
    </div>
  );
};

export default UnknownUserHeader;

// types

type UserComponentProps = React.ComponentProps<typeof User>;

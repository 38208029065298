import React, { useState } from 'react';

import { BlockerFunction, useBlocker } from 'react-router-dom';

import styles from './LeaveBanner.pcss';

import { amplitude } from 'Helpers/amplitude';
import ls from 'Util/localStorage';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { PLANS_ROUTE, BILLING_ROUTE, BOOK_A_DEMO_LINK_EN } from 'Constants/general';

const LS_CHOOSE_PLAN_BANNER_ON_MARKETPLAVE_PAGE_CLOSE = 'marketplace.plan_banner.close';

interface Props {
  visible?: boolean;
  onLeaveBannerShow: (value: boolean) => void;
}

const LeaveBanner: React.FC<Props> = (props) => {
  const { visible, onLeaveBannerShow } = props;
  const closedForever = !!ls.get(LS_CHOOSE_PLAN_BANNER_ON_MARKETPLAVE_PAGE_CLOSE);

  const [canLeave, setCanLeave] = useState(closedForever);

  const blockerFn: BlockerFunction = () => {
    if (!canLeave) {
      setCanLeave(true);
      onLeaveBannerShow(true);
      return true;
    }
    return false;
  };

  useBlocker(blockerFn);

  if (closedForever || !visible) return null;

  const handleCloseButtonClick = () => {
    onLeaveBannerShow(false);
    setCanLeave(true);
    ls.set(LS_CHOOSE_PLAN_BANNER_ON_MARKETPLAVE_PAGE_CLOSE, true);
  };

  const handlePlansClick = () => {
    amplitude.sendEvent<354>({
      id: '354',
      category: 'marketplace',
      name: 'book_a_demo_click',
      param: undefined,
    });
    handleCloseButtonClick();
  };

  return (
    <div className={styles.content}>
      <Text type="h1" msg="tooltip.marketplace.title" className={styles.title} />
      <button type="button" className={styles.closeButton} onClick={handleCloseButtonClick}>
        <Icon name="Close-small" className={styles.closeButtonIcon} size={20} />
      </button>
      <Text type="md" msg="tooltip.marketplace.descr" className={styles.descr} />
      <a
        href={BOOK_A_DEMO_LINK_EN}
        target="_blank"
        className={styles.btn}
        onClick={handlePlansClick}
      >
        <AlterButton msg="tooltip.marketplace.button" className={styles.btn} />
      </a>
    </div>
  );
};

export default LeaveBanner;

/**
 * @generated SignedSource<<8aac534c3e116c1c9fee695218de0fb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type SearchQueryInput = {
  acceptsBarter?: boolean | null | undefined;
  active?: boolean | null | undefined;
  ageRangeIds?: ReadonlyArray<string> | null | undefined;
  audienceAgeRangePercentage?: number | null | undefined;
  audienceCitiesIds?: ReadonlyArray<string> | null | undefined;
  audienceCityPercentage?: number | null | undefined;
  audienceCountryPercentage?: number | null | undefined;
  audienceEthnicityIds?: ReadonlyArray<string> | null | undefined;
  audienceGenderId?: string | null | undefined;
  audienceGenderPercentage?: number | null | undefined;
  audienceInterestPercentage?: number | null | undefined;
  businessAccountEnabled?: boolean | null | undefined;
  campaignsCompletionsCountFrom?: number | null | undefined;
  campaignsCompletionsCountTo?: number | null | undefined;
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cityIds?: ReadonlyArray<string> | null | undefined;
  contentCreator?: boolean | null | undefined;
  countryIds?: ReadonlyArray<string> | null | undefined;
  creatorAnimalIds?: ReadonlyArray<string> | null | undefined;
  creatorBadgeIds?: ReadonlyArray<string> | null | undefined;
  creatorBirthdateFrom?: any | null | undefined;
  creatorBirthdateTo?: any | null | undefined;
  creatorBodyTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorCountryIds?: ReadonlyArray<string> | null | undefined;
  creatorEthnicityIds?: ReadonlyArray<string> | null | undefined;
  creatorGenderId?: string | null | undefined;
  creatorHairTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorIdsToExclude?: ReadonlyArray<string> | null | undefined;
  creatorLanguageIds?: ReadonlyArray<string> | null | undefined;
  creatorTagIds?: ReadonlyArray<string> | null | undefined;
  creatorTypes?: ReadonlyArray<CreatorType> | null | undefined;
  currency?: Currency | null | undefined;
  customListIds?: ReadonlyArray<string> | null | undefined;
  employed?: boolean | null | undefined;
  engagementRateFrom?: number | null | undefined;
  engagementRateTo?: number | null | undefined;
  excludeBadges?: boolean | null | undefined;
  excludeCreatorsWorkedWith?: boolean | null | undefined;
  excludeHiredMoreThanOnce?: boolean | null | undefined;
  exclusions?: ReadonlyArray<SearchQueryExclusionParams> | null | undefined;
  favorite?: boolean | null | undefined;
  followersFrom?: number | null | undefined;
  followersTo?: number | null | undefined;
  hashtags?: ReadonlyArray<string> | null | undefined;
  includeAmazonStorefrontLink?: boolean | null | undefined;
  includeHidden?: boolean | null | undefined;
  interestIds?: ReadonlyArray<string> | null | undefined;
  onlyCreatorsWorkedWith?: boolean | null | undefined;
  owned?: boolean | null | undefined;
  paidSocialEnabled?: boolean | null | undefined;
  postPriceFrom?: number | null | undefined;
  postPriceTo?: number | null | undefined;
  postsReachFrom?: number | null | undefined;
  postsReachTo?: number | null | undefined;
  realFollowersFrom?: number | null | undefined;
  realFollowersTo?: number | null | undefined;
  reelsPlaysMedianFrom?: number | null | undefined;
  reelsPlaysMedianTo?: number | null | undefined;
  reviewedAtFrom?: any | null | undefined;
  reviewedAtTo?: any | null | undefined;
  storiesReachFrom?: number | null | undefined;
  storiesReachTo?: number | null | undefined;
  strictlyByPrimaryCategory?: boolean | null | undefined;
  textQuery?: string | null | undefined;
  viewsMedianFrom?: number | null | undefined;
  viewsMedianTo?: number | null | undefined;
  viewsPerPostFrom?: number | null | undefined;
  viewsPerPostTo?: number | null | undefined;
  withTiktokShop?: boolean | null | undefined;
};
export type SearchQueryExclusionParams = {
  advertiserProjectStages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  campaignId: string;
};
export type CreatorsSearchWithParamsQuery$variables = {
  campaignId: string;
  currency?: Currency | null | undefined;
  searchQueryActiveInput?: SearchQueryInput | null | undefined;
  searchQueryInput?: SearchQueryInput | null | undefined;
  searchQueryNotOwnerInput?: SearchQueryInput | null | undefined;
  searchQueryOwnerInput?: SearchQueryInput | null | undefined;
};
export type CreatorsSearchWithParamsQuery$data = {
  readonly campaign: {
    readonly activeUsers: {
      readonly totalCount: number;
    } | null | undefined;
    readonly notOwned: {
      readonly totalCount: number;
    } | null | undefined;
    readonly owned: {
      readonly totalCount: number;
    } | null | undefined;
    readonly searchCreators: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly creator: {
            readonly __typename: string;
            readonly avatarUrl?: string | null | undefined;
            readonly channel?: {
              readonly thumbnailUrl: string | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly tiktokUser?: {
              readonly avatar: {
                readonly secureUrl: string;
              } | null | undefined;
            } | null | undefined;
            readonly user?: {
              readonly profilePictureUrl: string | null | undefined;
            } | null | undefined;
            readonly username: string;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type CreatorsSearchWithParamsQuery = {
  response: CreatorsSearchWithParamsQuery$data;
  variables: CreatorsSearchWithParamsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currency"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQueryActiveInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQueryInput"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQueryNotOwnerInput"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQueryOwnerInput"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v7 = {
  "kind": "Variable",
  "name": "currency",
  "variableName": "currency"
},
v8 = [
  (v7/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  {
    "kind": "Variable",
    "name": "searchQueryInput",
    "variableName": "searchQueryInput"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureUrl",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    }
  ],
  "type": "ContentCreator",
  "abstractKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secureUrl",
  "storageKey": null
},
v17 = [
  (v9/*: any*/)
],
v18 = {
  "alias": "owned",
  "args": [
    (v7/*: any*/),
    {
      "kind": "Variable",
      "name": "searchQueryInput",
      "variableName": "searchQueryOwnerInput"
    }
  ],
  "concreteType": "CreatorSearchResultConnection",
  "kind": "LinkedField",
  "name": "searchCreators",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": "activeUsers",
  "args": [
    {
      "kind": "Literal",
      "name": "active",
      "value": true
    },
    (v7/*: any*/),
    {
      "kind": "Literal",
      "name": "owned",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "searchQueryInput",
      "variableName": "searchQueryActiveInput"
    }
  ],
  "concreteType": "CreatorSearchResultConnection",
  "kind": "LinkedField",
  "name": "searchCreators",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": "notOwned",
  "args": [
    (v7/*: any*/),
    {
      "kind": "Literal",
      "name": "owned",
      "value": false
    },
    {
      "kind": "Variable",
      "name": "searchQueryInput",
      "variableName": "searchQueryNotOwnerInput"
    }
  ],
  "concreteType": "CreatorSearchResultConnection",
  "kind": "LinkedField",
  "name": "searchCreators",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorsSearchWithParamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "CreatorSearchResultConnection",
            "kind": "LinkedField",
            "name": "searchCreators",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorSearchResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreatorSearchResult",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "creator",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstagramUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "InstagramCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "YoutubeChannel",
                                "kind": "LinkedField",
                                "name": "channel",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "YoutubeCreator",
                            "abstractKey": null
                          },
                          (v15/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "tiktokUser",
                                "args": null,
                                "concreteType": "TiktokUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      (v16/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "TiktokCreator",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatorsSearchWithParamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "CreatorSearchResultConnection",
            "kind": "LinkedField",
            "name": "searchCreators",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorSearchResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreatorSearchResult",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "creator",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstagramUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "InstagramCreator",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "YoutubeChannel",
                                "kind": "LinkedField",
                                "name": "channel",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "YoutubeCreator",
                            "abstractKey": null
                          },
                          (v15/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "tiktokUser",
                                "args": null,
                                "concreteType": "TiktokUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v16/*: any*/),
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "TiktokCreator",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd3a3fcd01c031ce2b5bbc8ef4c6a1c5",
    "id": null,
    "metadata": {},
    "name": "CreatorsSearchWithParamsQuery",
    "operationKind": "query",
    "text": "query CreatorsSearchWithParamsQuery(\n  $searchQueryInput: SearchQueryInput\n  $searchQueryOwnerInput: SearchQueryInput\n  $searchQueryActiveInput: SearchQueryInput\n  $searchQueryNotOwnerInput: SearchQueryInput\n  $campaignId: ID!\n  $currency: Currency\n) {\n  campaign(id: $campaignId) {\n    searchCreators(first: 3, currency: $currency, searchQueryInput: $searchQueryInput) {\n      totalCount\n      edges {\n        node {\n          creator {\n            id\n            username\n            __typename\n            ... on InstagramCreator {\n              user {\n                profilePictureUrl\n                id\n              }\n            }\n            ... on YoutubeCreator {\n              channel {\n                thumbnailUrl\n                id\n              }\n            }\n            ... on ContentCreator {\n              avatarUrl\n            }\n            ... on TiktokCreator {\n              tiktokUser: user {\n                avatar {\n                  __typename\n                  secureUrl\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    owned: searchCreators(currency: $currency, searchQueryInput: $searchQueryOwnerInput) {\n      totalCount\n    }\n    activeUsers: searchCreators(currency: $currency, searchQueryInput: $searchQueryActiveInput, owned: true, active: true) {\n      totalCount\n    }\n    notOwned: searchCreators(currency: $currency, searchQueryInput: $searchQueryNotOwnerInput, owned: false) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3deb99a35bb3375b6f721d99791ecc5";

export default node;

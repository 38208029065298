/**
 * @generated SignedSource<<3a1de45f07e7749c99b02b2b1e665e8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MediasTabs_creator$data = {
  readonly medias?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly portfolioItems: {
    readonly totalCount: number;
  };
  readonly posts?: {
    readonly totalCount: number;
  } | null | undefined;
  readonly profile: {
    readonly currency: Currency | null | undefined;
    readonly topPosts: ReadonlyArray<{
      readonly link: string | null | undefined;
    }>;
    readonly topSponsoredPosts: ReadonlyArray<{
      readonly link: string | null | undefined;
    }>;
  } | null | undefined;
  readonly stories?: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "MediasTabs_creator";
};
export type MediasTabs_creator$key = {
  readonly " $data"?: MediasTabs_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediasTabs_creator">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 30
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediasTabs_creator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Post",
          "kind": "LinkedField",
          "name": "topSponsoredPosts",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Post",
          "kind": "LinkedField",
          "name": "topPosts",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PortfolioItemConnection",
      "kind": "LinkedField",
      "name": "portfolioItems",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            (v2/*: any*/)
          ],
          "concreteType": "InstagramMediaConnection",
          "kind": "LinkedField",
          "name": "medias",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramMediaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramMedia",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "medias(first:30)"
        },
        {
          "alias": "posts",
          "args": [
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "placements",
              "value": [
                "FEED",
                "REEL"
              ]
            }
          ],
          "concreteType": "InstagramInsightsMediaConnection",
          "kind": "LinkedField",
          "name": "insightsMedias",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "insightsMedias(first:30,placements:[\"FEED\",\"REEL\"])"
        },
        {
          "alias": "stories",
          "args": [
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "placements",
              "value": [
                "STORY"
              ]
            }
          ],
          "concreteType": "InstagramInsightsMediaConnection",
          "kind": "LinkedField",
          "name": "insightsMedias",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "insightsMedias(first:30,placements:[\"STORY\"])"
        }
      ],
      "type": "InstagramCreator",
      "abstractKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "d5e6d0df9653ef040972194b4271e01d";

export default node;

import { BcaPermissionStateEnum } from 'Types/enums';

type FilterId = BcaPermissionStateEnum | 'default' | 'connected' | 'disconnected';

type FilterItem = {
  id: FilterId;
  active?: boolean;
  msg: string;
  states: BcaPermissionStateEnum[];
};

type GetFilters = () => FilterItem[];
const getFilters: GetFilters = () => {
  return [
    {
      id: 'default',
      active: undefined,
      states: [],
      msg: 'dashboard.advertiser.licenses_section.filters.state.default',
    },
    {
      id: 'connected',
      active: true,
      states: [BcaPermissionStateEnum.APPROVED],
      msg: 'dashboard.advertiser.licenses_section.filters.state.connected',
    },
    {
      id: 'disconnected',
      active: false,
      states: [BcaPermissionStateEnum.CANCELED],
      msg: 'dashboard.advertiser.licenses_section.filters.state.disconnected',
    },
    {
      id: BcaPermissionStateEnum.PENDING_APPROVAL,
      active: false,
      states: [BcaPermissionStateEnum.PENDING_APPROVAL],
      msg: 'dashboard.advertiser.licenses_section.filters.state.pending_approval',
    },
    {
      id: BcaPermissionStateEnum.EXPIRED,
      active: false,
      states: [BcaPermissionStateEnum.EXPIRED],
      msg: 'dashboard.advertiser.licenses_section.filters.state.expired',
    },
    {
      id: BcaPermissionStateEnum.REVOKED,
      active: false,
      states: [BcaPermissionStateEnum.REVOKED],
      msg: 'dashboard.advertiser.licenses_section.filters.state.revoked',
    },
    {
      id: BcaPermissionStateEnum.CANCELED,
      active: false,
      states: [BcaPermissionStateEnum.CANCELED],
      msg: 'dashboard.advertiser.licenses_section.filters.state.canceled',
    },
    {
      id: BcaPermissionStateEnum.INVALID,
      active: false,
      states: [BcaPermissionStateEnum.INVALID],
      msg: 'dashboard.advertiser.licenses_section.filters.state.invalid',
    },
    {
      id: BcaPermissionStateEnum.UNKNOWN,
      active: false,
      states: [BcaPermissionStateEnum.UNKNOWN],
      msg: 'dashboard.advertiser.licenses_section.filters.state.unknown',
    },
  ];
};

export { getFilters };
export type { FilterItem, FilterId };

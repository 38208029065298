import React, { useState, useEffect, useContext, useMemo } from 'react';
import cn from 'classnames';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { matchRoutes, useLocation, NavLink } from 'react-router-dom';

import { query } from './Navigation.Query';
import { createNavigationItems } from './utils';
import styles from './Navigation.pcss';
import { MarketplaceNavigationItem } from './MarketplaceNavigationItem';
import Information from './Information/Information';

import TooltipBase from 'Components/ui/TooltipBase';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import NewNotification from 'Atoms/NewNotification/NewNotification';
import Tag from 'Components/ui/Tag/Tag';
import { ADVERTISER, HOME_ROUTE } from 'Constants/general';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { type NavigationQuery } from 'GraphTypes/NavigationQuery.graphql';
import { GuideTourContext } from 'Containers/GuideTour';

type Props = {
  className?: string;
  queryReference: PreloadedQuery<NavigationQuery, Record<string, unknown>>;
};

const Navigation: React.FC<Props> = (props) => {
  const { className, queryReference } = props;
  const [isNavigationVisible, setIsNavigationVisible] = useState<boolean>(false);

  const { getTooltipComponent } = useContext(GuideTourContext);

  const location = useLocation();
  const response = usePreloadedQuery<NavigationQuery>(query, queryReference);

  const launchedCampaignsCount = response.currentUser?.organization?.campaigns?.totalCount || 0;
  const isNewUser = launchedCampaignsCount < 2;

  useEffect(() => {
    if (!response?.currentUser?.type) return;
    setIsNavigationVisible(response.currentUser?.type === ADVERTISER);
  }, [response?.currentUser?.type]);

  const GuideTourCampaignsTooltip = useMemo(() => {
    return getTooltipComponent('navigation', 'campaigns');
  }, [getTooltipComponent]);

  const GuideTourMarketplaceTooltip = useMemo(() => {
    return getTooltipComponent('navigation', 'marketplace');
  }, [getTooltipComponent]);

  const GuideTourBrandsTooltip = useMemo(() => {
    return getTooltipComponent('navigation', 'brands');
  }, [getTooltipComponent]);

  const GuideTourLicensesTooltip = useMemo(() => {
    return getTooltipComponent('navigation', 'licenses');
  }, [getTooltipComponent]);

  const GuideTourCreatorsTooltip = useMemo(() => {
    return getTooltipComponent('navigation', 'creators');
  }, [getTooltipComponent]);

  const GuideTourContentTooltip = useMemo(() => {
    return getTooltipComponent('navigation', 'content');
  }, [getTooltipComponent]);

  if (!isNavigationVisible) return null;

  const projectsWithUnreadMessageCount =
    response?.currentUser?.organization?.projects?.totalCount || 0;

  const chatIcon = (
    <div className={styles.chat}>
      <Icon name="Chat" />
      {!!projectsWithUnreadMessageCount && (
        <NewNotification
          count={projectsWithUnreadMessageCount}
          isActive={true}
          isLimited={true}
          className={styles.unreadCount}
        />
      )}
    </div>
  );

  const navigationItems = createNavigationItems({
    chatIcon,
  });

  const getTourItem = (id: string) => {
    if (!isNewUser) return null;
    switch (id) {
      case 'campaigns':
        return (
          GuideTourCampaignsTooltip && (
            <GuideTourCampaignsTooltip arrowPosition="center-left" className={styles.guideTooltip}>
              <div className={styles.tourWrap} />
            </GuideTourCampaignsTooltip>
          )
        );
      case 'marketplace':
        return (
          GuideTourMarketplaceTooltip && (
            <GuideTourMarketplaceTooltip
              arrowPosition="center-left"
              className={styles.guideTooltip}
            >
              <div className={styles.tourWrap} />
            </GuideTourMarketplaceTooltip>
          )
        );
      case 'brands':
        return (
          GuideTourBrandsTooltip && (
            <GuideTourBrandsTooltip arrowPosition="center-left" className={styles.guideTooltip}>
              <div className={styles.tourWrap} />
            </GuideTourBrandsTooltip>
          )
        );
      case 'licenses':
        return (
          GuideTourLicensesTooltip && (
            <GuideTourLicensesTooltip arrowPosition="center-left" className={styles.guideTooltip}>
              <div className={styles.tourWrap} />
            </GuideTourLicensesTooltip>
          )
        );
      case 'creators':
        return (
          GuideTourCreatorsTooltip && (
            <GuideTourCreatorsTooltip arrowPosition="center-left" className={styles.guideTooltip}>
              <div className={styles.tourWrap} />
            </GuideTourCreatorsTooltip>
          )
        );
      case 'content':
        return (
          GuideTourContentTooltip && (
            <GuideTourContentTooltip arrowPosition="center-left" className={styles.guideTooltip}>
              <div className={styles.tourWrap} />
            </GuideTourContentTooltip>
          )
        );

      default:
        return null;
    }
  };

  const homeIcon = (
    <li key="home">
      <NavLink to={HOME_ROUTE} className={styles.navLink} state={{ navigation: true }}>
        <div
          className={cn(styles.startHere, {
            [styles.active]: Boolean(matchRoutes([{ path: HOME_ROUTE }], location)),
          })}
        >
          <div className={styles.mark} />
          <Text msg="navigation.start_here" type="labelSm" className={styles.startHereLabel} />
          <Text className={styles.startHereText} type="sm" msg="navigation.start_here.label" />
        </div>
      </NavLink>
    </li>
  );

  return (
    <div className={cn(styles.root, className)}>
      <nav className={styles.nav}>
        <ul className={styles.list}>
          {homeIcon}
          {navigationItems.map((item) => {
            const tourItem = getTourItem(item.id);
            switch (item.id) {
              case 'marketplace': {
                return (
                  <li key={item.id}>
                    <MarketplaceNavigationItem
                      className={styles.button}
                      classes={{ buttonText: styles.buttonText }}
                      icon={item.icon}
                      msg={item.msg}
                      dataTest={item.dataTest}
                    />
                    {tourItem}
                    <TooltipBase className={styles.tooltip} size="s" arrowPosition="center-left">
                      <Text className={styles.tooltipText} type="sm" msg={item.msg} />
                    </TooltipBase>
                  </li>
                );
              }
              default: {
                return (
                  <li key={item.id}>
                    <NavLink to={item.path} className={styles.navLink} state={{ navigation: true }}>
                      <AlterButton
                        className={cn(styles.button, {
                          [styles.active]: Boolean(matchRoutes([{ path: item.path }], location)),
                        })}
                        classes={{ buttonText: styles.buttonText }}
                        leftElement={item.iconElement}
                        icon={item.icon}
                        msg={item.msg}
                        dataTest={item.dataTest}
                      />
                    </NavLink>
                    {tourItem}
                    <TooltipBase className={styles.tooltip} size="s" arrowPosition="center-left">
                      <Text className={styles.tooltipText} type="sm" msg={item.msg} />
                    </TooltipBase>
                    {item.isBeta && (
                      <Tag
                        msg="badge.beta"
                        type="badge"
                        needMargin={false}
                        className={styles.beta}
                      />
                    )}
                  </li>
                );
              }
            }
          })}
        </ul>
        {Boolean(matchRoutes([{ path: HOME_ROUTE }], location)) && <Information />}
      </nav>
    </div>
  );
};

export { Navigation };

export default Navigation;

/**
 * @generated SignedSource<<0f637514c0d98c9011309e6addba76a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingQuery$variables = Record<PropertyKey, never>;
export type BillingQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"BalanceInfo_organization" | "CurrentSubscriptionInfo_organization" | "SubscriptionLimits_organization">;
    } | null | undefined;
  } | null | undefined;
};
export type BillingQuery = {
  response: BillingQuery$data;
  variables: BillingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxActiveBrands",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxLicensedCreators",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxBcaConnections",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxSeats",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CurrentSubscriptionInfo_organization"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubscriptionLimits_organization"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BalanceInfo_organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BillingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "planId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "interval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renewsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cancelAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paused",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canApplyDiscount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discountOfferAcceptedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionPauseEvent",
                    "kind": "LinkedField",
                    "name": "pauseEvent",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pausedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "resumesAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionSchedule",
                    "kind": "LinkedField",
                    "name": "lastSchedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Discount",
                        "kind": "LinkedField",
                        "name": "discount",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentOff",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "trialEnd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableAddonIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddonItem",
                    "kind": "LinkedField",
                    "name": "addonItems",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Addon",
                        "kind": "LinkedField",
                        "name": "addon",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "resourcesPerUnit",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionLimits",
                    "kind": "LinkedField",
                    "name": "effectiveLimits",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionLimits",
                    "kind": "LinkedField",
                    "name": "baseLimits",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canCreateDeposits",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "balance",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCounters",
                "kind": "LinkedField",
                "name": "counters",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activeBrands",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "psLicensedCreators",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bcaLicensedCreators",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f1b46c803a9b23b3bfea910dd1641a01",
    "id": null,
    "metadata": {},
    "name": "BillingQuery",
    "operationKind": "query",
    "text": "query BillingQuery {\n  currentUser {\n    organization {\n      id\n      ...CurrentSubscriptionInfo_organization\n      ...SubscriptionLimits_organization\n      ...BalanceInfo_organization\n    }\n    id\n  }\n}\n\nfragment BalanceInfo_organization on Organization {\n  paymentAccount {\n    balance\n    currency\n    id\n  }\n  subscription {\n    canCreateDeposits\n    id\n  }\n}\n\nfragment CurrentSubscriptionInfo_organization on Organization {\n  id\n  name\n  subscription {\n    id\n    planId\n    interval\n    renewsAt\n    cancelAt\n    priceAmount\n    paused\n    canApplyDiscount\n    discountOfferAcceptedAt\n    pauseEvent {\n      pausedAt\n      resumesAt\n    }\n    lastSchedule {\n      discount {\n        percentOff\n        id\n      }\n      trialEnd\n      startDate\n      id\n    }\n  }\n  paymentAccount {\n    currency\n    id\n  }\n}\n\nfragment SubscriptionLimits_organization on Organization {\n  counters {\n    activeBrands\n    psLicensedCreators\n    bcaLicensedCreators\n  }\n  subscription {\n    planId\n    availableAddonIds\n    addonItems {\n      addon {\n        id\n        resourcesPerUnit\n      }\n      quantity\n    }\n    paused\n    interval\n    effectiveLimits {\n      maxActiveBrands\n      maxLicensedCreators\n      maxBcaConnections\n      maxSeats\n    }\n    baseLimits {\n      maxActiveBrands\n      maxLicensedCreators\n      maxBcaConnections\n      maxSeats\n    }\n    id\n  }\n  members {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "19b28934a921747e45cf94656ffbcea0";

export default node;

import { graphql } from 'react-relay';

import { ShipmentQuery as QueryType } from 'GraphTypes/ShipmentQuery.graphql';
import { Shipment_project$data } from 'GraphTypes/Shipment_project.graphql';

const ShipmentQuery = graphql`
  query ShipmentQuery(
    $campaignId: ID!
    $textQuery: String!
    $orderState: ShopifyOrderState
    $withoutShopifyOrder: Boolean
  ) {
    campaign(id: $campaignId) {
      shopifyAuthorization {
        id
        shop {
          id
          name
        }
      }
      ...ShipmentList_campaign
        @arguments(
          textQuery: $textQuery
          orderState: $orderState
          withoutShopifyOrder: $withoutShopifyOrder
        )
    }
  }
`;

export { ShipmentQuery };

// types

type ShipmentQueryType = QueryType & {
  readonly response: QueryType['response'] & {
    readonly currentUser:
      | (QueryType['response']['campaign'] & {
          readonly projects: Shipment_project$data | null;
        })
      | null;
  };
};

export type { ShipmentQueryType };

import React, { PropsWithChildren } from 'react';

import { guideTours, GuideToursIDs, StepNamesMap } from './data';

import { GuideTourProvider, createGuideTourContext } from 'Components/ui/GuideTour';

const GuideTourContext = createGuideTourContext<GuideToursIDs, StepNamesMap>();

const GuideTourContainer: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <GuideTourProvider guideTours={guideTours} context={GuideTourContext}>
      {children}
    </GuideTourProvider>
  );
};

export default GuideTourContainer;
export { GuideTourContext };

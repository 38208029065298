import React, { PropsWithChildren } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import classnames from 'classnames';

import styles from './TextButton.css';

import modsClasses from 'Util/modsClasses.js';
import Icon from 'Atoms/Icon/Icon';
import { TextButtonType } from 'Types/common';

const MODS = ['color', 'size', 'weight', 'display', 'theme', 'overflow', 'mainColor', 'flex'];

// TODO: replace text content and side icons with text symbol

const TextButton: React.FC<PropsWithChildren<TextButtonType & WrappedComponentProps>> = (props) => {
  const {
    intl,
    msg,
    size,
    color,
    children,
    mainColor,
    msgValues,
    text,
    icon,
    iconName,
    leftIcon,
    leftIconName,
    rightIcon,
    rightIconName,
    loading,
    disabled,
    onClick,
    tabIndex,
    dataTest,
  } = props;

  const getTextContent = () => {
    let textValue = '';

    if (msg) {
      textValue = intl.formatMessage({ id: msg }, msgValues);
    } else if (text) {
      textValue = text;
    }

    return textValue ? <span className={styles.text}>{textValue}</span> : children;
  };

  const getDefaultValue = () => {
    const data: { size?: string; color?: string } = {};
    if (!size) {
      data.size = 'md';
    }
    if (!color && !mainColor) {
      data.color = 'primary';
    }

    return data;
  };

  const classes = modsClasses(MODS, { ...props, ...getDefaultValue() }, styles);
  const classNameList = classnames(classes, styles.root, {
    [styles.loading]: loading,
    [styles.disabled]: disabled,
  });

  return (
    <button
      className={classNameList}
      type="button"
      onClick={disabled ? undefined : onClick}
      tabIndex={tabIndex}
      data-test={dataTest}
    >
      {leftIcon}
      {leftIconName && <Icon color={mainColor} name={leftIconName} />}
      {iconName && <Icon color={mainColor} name={iconName} />}
      {icon}
      {getTextContent()}
      {rightIcon}
      {rightIconName && <Icon color={mainColor} name={rightIconName} />}
    </button>
  );
};

export default injectIntl(TextButton);

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  AssignUserToOrganizationMutation,
  AssignUserToOrganizationMutation$data,
  AssignUserToOrganizationInput,
} from 'GraphTypes/AssignUserToOrganizationMutation.graphql';

const mutation = graphql`
  mutation AssignUserToOrganizationMutation($input: AssignUserToOrganizationInput!) {
    assignUserToOrganization(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: AssignUserToOrganizationInput,
  resolve?: (response: AssignUserToOrganizationMutation$data) => void,
  reject?: fnType
) => {
  const { organizationId, userId } = data;

  const variables = {
    input: {
      organizationId,
      userId,
    },
  };

  commitMutation<AssignUserToOrganizationMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

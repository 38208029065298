import React, { ChangeEvent, useEffect, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Text from '../Text/Text';

import styles from './Textarea.pcss';

type TextareaPlaceholder = string;
type TextareaValue = string;
type TextareaErrorState = boolean;
type TextareaHandleChange = (text: string) => void;

export type Props = {
  placeholder?: TextareaPlaceholder;
  placeholderMsg?: TextareaPlaceholder;
  value?: TextareaValue;
  defaultValue?: TextareaValue;
  error?: TextareaErrorState;
  handleChange?: TextareaHandleChange;
  label?: string;
  labelMsg?: string;
  bordered?: boolean;
  disabled?: boolean;
  maxLength?: number;
  withCounter?: boolean;
  className?: string;
  forceLabelShow?: boolean;
};

const Textarea: React.FC<Props> = (props) => {
  const {
    placeholder,
    placeholderMsg,
    handleChange,
    value,
    defaultValue,
    error,
    label,
    labelMsg,
    bordered,
    maxLength,
    withCounter,
    disabled,
    className,
    forceLabelShow,
  } = props;

  const intl = useIntl();
  const [currentValue, setCurrentValue] = useState(defaultValue || value);
  const [hasError, setErrorStatus] = useState(error);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const usedPlaceholder = useMemo<string>(() => {
    if (placeholderMsg) {
      const result = intl.formatMessage({ id: placeholderMsg });
      if (result) {
        return result;
      }
    } else if (placeholder) {
      return placeholder;
    }

    return '';
  }, [placeholder, placeholderMsg]);

  useEffect(() => {
    setCurrentValue(value);
    if (hasError) {
      setErrorStatus(false);
    }
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      if (!value) return;
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [value, textAreaRef]);

  useEffect(() => {
    setErrorStatus(error);
  }, [error]);

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentValue(e.target.value);
    if (handleChange) {
      handleChange(e.target.value);
    }
  };

  const valueLengthCounter =
    typeof maxLength === 'number'
      ? `${currentValue?.length || 0}/${maxLength}`
      : currentValue?.length;

  const classList = classNames(
    styles.root,
    {
      [styles.withLabel]: label || labelMsg,
      [styles.bordered]: bordered,
      [styles.withValue]: value,
      [styles.error]: hasError,
      [styles.withCounter]: withCounter,
      [styles.forceLabelShow]: forceLabelShow,
    },
    className
  );

  return (
    <div className={classList}>
      {(((label || labelMsg) && !!value) || forceLabelShow) && (
        <Text type="md" className={styles.label} text={label} msg={labelMsg} />
      )}
      <textarea
        ref={textAreaRef}
        rows={1}
        value={currentValue}
        onChange={handleTextareaChange}
        placeholder={usedPlaceholder}
        className={classList}
        maxLength={maxLength}
        disabled={disabled}
      />
      {withCounter && <div className={styles.lengthCounter}>{valueLengthCounter}</div>}
    </div>
  );
};

export default Textarea;

import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';

import styles from './MediaplanItem.pcss';

import archiveProject from 'Api/mutations/ArchiveProject.Mutation';
import { getCreatorData } from 'Util/creatorData';
import { getCreatorStatLink } from 'Util/links';
import { createSum } from 'Util/numberFormatter';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Atoms/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import Avatar from 'Atoms/Avatar/Avatar';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import TextButton from 'Atoms/TextButton/TextButton';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import icons from 'Icons';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';
import { MediaplanItem_project$key } from 'GraphTypes/MediaplanItem_project.graphql';

interface Props {
  className: string;
  campaignId: string;
  project: MediaplanItem_project$key;
  canViewCreatorPrices: boolean;
  admin: string;
  isBlockedView?: boolean;
  showUnlockCreatorBanner: () => void;
}

const MediaplanItem: React.FC<Props> = (props) => {
  const {
    className,
    campaignId,
    project,
    canViewCreatorPrices,
    admin,
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const data = useFragment(
    graphql`
      fragment MediaplanItem_project on Project {
        id
        currency
        price
        contentApprovalPrice
        offer {
          id
          acceptance {
            id
          }
        }
        launch {
          id
        }
        archivation(side: ADVERTISER) {
          id
        }
        longlistCreator {
          interactedWith
        }
        creator {
          id
          username
          type
          profile {
            currency
            estimatedPrice
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
          ... on YoutubeCreator {
            channel {
              id
              name
            }
          }
          ... on TiktokCreator {
            user {
              name
            }
          }
        }
      }
    `,
    project
  );

  const {
    id: projectId,
    currency,
    price,
    creator,
    archivation,
    launch,
    contentApprovalPrice,
    longlistCreator,
  } = data;

  const interactedWith = longlistCreator && longlistCreator.interactedWith;

  const [archivationProcessing, setArchivationProcessing] = useState(false);

  const dispatch = useDispatch();

  if (!creator || archivation) return null;

  const { id: creatorId } = creator;

  const estimatedPrice = creator.profile ? creator.profile.estimatedPrice : 0;
  const creatorCurrency = creator.profile ? creator.profile.currency : currency;

  const { link, avatarUrl, username } = getCreatorData(creator);

  const isEstimatedPrice = !data.offer ? '~' : '';
  const projectPrice = !canViewCreatorPrices && contentApprovalPrice ? contentApprovalPrice : price;
  const priceValue = data.offer ? projectPrice : estimatedPrice;
  const currencyValue = data.offer ? currency : creatorCurrency;
  const sum = `${isEstimatedPrice}${createSum(priceValue, currencyValue)}`;

  const hasAcceptance = data.offer && data.offer.acceptance;
  const isArchivable = !archivation && !launch;
  const isOnboarded = hasAcceptance && data?.launch;

  const handleProjectArchivationDone = () => setArchivationProcessing(false);

  const handleProjectArchivation = () => {
    setArchivationProcessing(true);

    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: { admin, source: 'MEDIAPLAN', actionName: 'archive' },
    });

    archiveProject({ projectId }, handleProjectArchivationDone);
  };

  const renderArchivationBtn = () => {
    if (archivationProcessing) return <Spinner size="sm" className={styles.control} />;

    return !interactedWith && !(window as any).isApproval && isArchivable && !isOnboarded ? (
      <Tooltip
        id={`${username}_archive`}
        place="top"
        tooltipMsg="creator_card.mediaplan.remove"
        className={styles.archiveBtn}
      >
        <TextButton icon={icons.deleteIcon} color="normal" onClick={handleProjectArchivation} />
      </Tooltip>
    ) : null;
  };

  return (
    <div className={`${className} ${styles.root}`}>
      <Avatar size={40} src={avatarUrl} className={styles.ava} />
      <div className={styles.info}>
        <div className={styles.username}>
          {isBlockedView ? (
            <BlockedUsername creatorId={creatorId} onLockClick={showUnlockCreatorBanner} />
          ) : (
            <SimpleLink href={link} className={styles.usernameLink} target="_blank" display="block">
              <Text weight="500" color="creamCan" text={username} overflow />
            </SimpleLink>
          )}
          {archivation && <Text size="sm" weight="500" color="grayDog" msg="general.archived" />}
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.price}>
          <Text weight="500" text={sum} />
          <Text size="sm" color="grayDog" msg="creator.per_creative" />
        </div>
        <div className={styles.actions}>
          <SimpleLink
            href={getCreatorStatLink({ campaignId: campaignId, creatorId })}
            target="_blank"
          >
            <TextButton icon={icons.stats} color="normal" />
          </SimpleLink>
          {renderArchivationBtn()}
        </div>
      </div>
    </div>
  );
};

export default MediaplanItem;

/**
 * @generated SignedSource<<5e388b1c9b99c4cd3bdbec488e275831>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminComment_comment$data = {
  readonly adminComment: string | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "AdminComment_comment";
};
export type AdminComment_comment$key = {
  readonly " $data"?: AdminComment_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminComment_comment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminComment_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminComment",
      "storageKey": null
    }
  ],
  "type": "Payment_Withdrawal",
  "abstractKey": null
};

(node as any).hash = "a4367bd5cceb0b328ce78787d40104b5";

export default node;

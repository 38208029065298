import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  RemoveMonitoredCampaignMutation,
  RemoveMonitoredCampaignInput,
  RemoveMonitoredCampaignMutation$data,
} from 'GraphTypes/RemoveMonitoredCampaignMutation.graphql';

const mutation = graphql`
  mutation RemoveMonitoredCampaignMutation($input: RemoveMonitoredCampaignInput!) {
    removeMonitoredCampaign(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: RemoveMonitoredCampaignInput,
  resolve?: (response: RemoveMonitoredCampaignMutation$data) => void,
  reject?: fnType
) => {
  const { campaignId } = data;
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<RemoveMonitoredCampaignMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const storeRoot = store.getRoot();
      const list = ConnectionHandler.getConnection(storeRoot, 'Admin_monitoredCampaigns');

      const edges = list?.getLinkedRecords('edges');
      const edgeCampaign = edges?.find(
        (edge) =>
          edge?.getLinkedRecord('node')?.getLinkedRecord('campaign')?.getValue('id') === campaignId
      );

      if (edgeCampaign && list) {
        const nodeId = edgeCampaign.getLinkedRecord('node')?.getValue('id');
        if (!nodeId) return;
        ConnectionHandler.deleteNode(list, String(nodeId));
      }
    },
    optimisticUpdater: (store) => {
      const storeRoot = store.getRoot();
      const list = ConnectionHandler.getConnection(storeRoot, 'Admin_monitoredCampaigns');

      const edges = list?.getLinkedRecords('edges');
      const edgeCampaign = edges?.find(
        (edge) =>
          edge?.getLinkedRecord('node')?.getLinkedRecord('campaign')?.getValue('id') === campaignId
      );

      if (edgeCampaign && list) {
        const nodeId = edgeCampaign.getLinkedRecord('node')?.getValue('id');
        if (!nodeId) return;
        ConnectionHandler.deleteNode(list, String(nodeId));
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import sendEventGoogleTagManager, { initGoogleTagManager } from './GoogleTagManager';
// import sendEventGoogleAnalytics, { initGoogleAnalytics } from './GoogleAnalytics';
// import sendEventYandexMetrika from './YandexMetrika';

export const initFns = [
  // initGoogleAnalytics
  initGoogleTagManager
];

export const sendEventsFns = [
  sendEventGoogleTagManager,
  // sendEventGoogleAnalytics,
  // sendEventYandexMetrika,
];

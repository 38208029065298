import { Country } from 'Components/ShipmentInformation/CountryDropdown/ShipmentCountryDropdown';
import { Province } from 'Components/ShipmentInformation/ProvinceDropdown/ShipmentProvinceDropdown';

type ShippingAddress = {
  address1: string;
  address2?: string | null;
  city: string;
  country?: Country | null;
  firstName: string;
  lastName: string;
  phone: string;
  provinceCode?: string | null;
  province?: Province | null;
  zip: string;
} | null;
export const shipmentAddressFormatter = (shippingAddress?: ShippingAddress) => {
  if (!shippingAddress) return '';
  const {
    firstName,
    lastName,
    phone,
    address1,
    address2,
    city,
    province,
    provinceCode,
    country,
    zip,
  } = shippingAddress;
  if (!firstName || !lastName || !phone || !address1 || !city || !country || !zip) return '';
  const address2Str = address2 ? `${address2},` : ',';
  let provinceStr = '';
  if (province) {
    provinceStr = province.code;
  } else {
    provinceStr = provinceCode || '';
  }
  const countryStr = country?.name ? `, ${country.name} ` : '';
  // eslint-disable-next-line max-len
  return `${firstName} ${lastName}, ${phone}, ${address1} ${address2Str} ${city} ${provinceStr} ${zip}${countryStr}`;
};

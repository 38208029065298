/**
 * @generated SignedSource<<d3722addb25a43973d9e92caa07ce697>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivateCampaignInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
};
export type ActivateCampaignMutation$variables = {
  input: ActivateCampaignInput;
};
export type ActivateCampaignMutation$data = {
  readonly activateCampaign: {
    readonly campaign: {
      readonly id: string;
    };
  } | null | undefined;
};
export type ActivateCampaignMutation = {
  response: ActivateCampaignMutation$data;
  variables: ActivateCampaignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ActivateCampaignPayload",
    "kind": "LinkedField",
    "name": "activateCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivateCampaignMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivateCampaignMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2e72d330c9e9ffa901cf6c4164318f76",
    "id": null,
    "metadata": {},
    "name": "ActivateCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation ActivateCampaignMutation(\n  $input: ActivateCampaignInput!\n) {\n  activateCampaign(input: $input) {\n    campaign {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b35a767f30b5757fbe2676dd27a11271";

export default node;

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  DeleteReferenceInput,
  DeleteReferenceMutation$data,
  DeleteReferenceMutation,
} from 'GraphTypes/DeleteReferenceMutation.graphql';

const mutation = graphql`
  mutation DeleteReferenceMutation($input: DeleteReferenceInput!) {
    deleteReference(input: $input) {
      briefCreative {
        id
      }
    }
  }
`;

type fnType = (data: object) => void;

type Data = {
  briefCreativeId?: string;
};

export default (
  data: DeleteReferenceInput & Data,
  resolve?: (response: DeleteReferenceMutation$data) => void,
  reject?: fnType
) => {
  const { id, briefCreativeId } = data;

  const variables = {
    input: {
      id,
    },
  };

  commitMutation<DeleteReferenceMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      if (!briefCreativeId || !id) return;
      const creative = store.get(briefCreativeId);
      if (!creative) return;
      const references = ConnectionHandler.getConnection(creative, 'Creative_references');

      if (references) {
        ConnectionHandler.deleteNode(references, id);
      }

      const lastReferences = ConnectionHandler.getConnection(
        creative,
        'AssetPreview_lastReferences'
      );

      if (lastReferences) {
        ConnectionHandler.deleteNode(lastReferences, id);
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

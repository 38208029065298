import React, { useMemo } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './CampaignCreatives.pcss';
import PublicationsReportButton from './PublicationsReportButton/PublicationsReportButton';
import { getStatData } from './utils';

import TitleBase from 'Components/ui/TitleBase/TitleBase';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Creative from 'Molecules/Creative/Creative';
import OldText from 'Atoms/Text/Text';
import Text from 'Components/ui/Text/Text';
import postStub from 'Images/general/postStub.svg';
import { getTimeFromNow } from 'Util/dateCreator';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import ReportButton, { ReportType } from 'Atoms/ReportButton/ReportButton';
import { CampaignCreatives_campaign } from 'GraphTypes/CampaignCreatives_campaign.graphql';
import { amplitude } from 'Helpers/amplitude';
import { INSTAGRAM, TIKTOK, FACEBOOK } from 'Constants/general';

const CREATIVES_COUNT = 12;
const FOOTER_OFFSET = 279;

interface Props {
  admin: boolean;
  campaignId: string;
  canCreateFacebookAds: boolean;
  canChatWithCreators: boolean;
  relay: RelayPaginationProp;
  campaign: CampaignCreatives_campaign | null;
}

const CampaignCreatives: React.FC<Props> = (props) => {
  const { admin, relay, canCreateFacebookAds, canChatWithCreators, campaign, campaignId } = props;

  const edges = campaign?.creatives.edges || [];
  const totalCount = edges.length;
  const platform = campaign?.platform;
  const campaignType = campaign?.type;
  const publishingRequired = campaign?.publishingRequired;

  const isShownStatsBtn = (platform === INSTAGRAM || platform === TIKTOK) && publishingRequired;

  const lastUpdatedTime = useMemo(
    () => getTimeFromNow(campaign?.creativeInsightsLastCollectedAt),
    [campaign?.creativeInsightsLastCollectedAt]
  );

  const createCreatives = () => {
    return edges.map((item) => {
      if (!item?.node) return null;

      const { id } = item.node;

      return (
        <Creative
          key={id}
          creative={item.node}
          admin={admin}
          canCreateFacebookAds={canCreateFacebookAds}
          canChatWithCreators={canChatWithCreators}
        />
      );
    });
  };

  const handleSaveStats = () => {
    amplitude.sendEvent({
      id: 147,
      name: 'download_results_click',
      category: 'results',
      param: {
        campaign_id: campaignId,
      },
    });
  };

  const lastUpdatedText = (
    <div className={styles.lastUpdated}>
      <Tooltip id="lastUpdated" tooltipMsg="campaign.update_time" place="top">
        <Text
          type="label"
          msg="campaign.last_updated"
          className={styles.lastUpdatedText}
          formatValues={{ count: lastUpdatedTime }}
        />
      </Tooltip>
    </div>
  );

  const createStub = (
    <div className={styles.stubWrap}>
      <img src={postStub} />
      <OldText msg="campaign.results.stub.descr" className={styles.stubDescr} />
    </div>
  );

  const statsData = useMemo(() => {
    return campaign ? getStatData(campaign) : [];
  }, [campaign]);

  return (
    <div className={styles.root}>
      {(platform === INSTAGRAM || platform === FACEBOOK || platform === TIKTOK) &&
      campaignType !== 'CONTENT_CREATION' &&
      totalCount === 0
        ? createStub
        : lastUpdatedTime && lastUpdatedText}
      {totalCount !== 0 && campaignType !== 'CONTENT_CREATION' && (
        <div className={styles.header}>
          <div className={styles.stats}>
            {statsData.map((item) => {
              const { value, titleMsg, tooltipMsg } = item;
              return (
                <TitleBase
                  key={titleMsg}
                  title={{ text: value }}
                  description={{ msg: titleMsg }}
                  tooltip={{ msg: tooltipMsg }}
                  className={styles.statItem}
                />
              );
            })}
          </div>
          <aside className={styles.action}>
            {(admin || isShownStatsBtn) && (
              <ReportButton
                type={ReportType.CAMPAIGN_PUBLICATIONS}
                onDownload={handleSaveStats}
                reportData={{ campaignId }}
              >
                <PublicationsReportButton />
              </ReportButton>
            )}
          </aside>
        </div>
      )}
      <LoaderHandler relay={relay} count={CREATIVES_COUNT} offset={FOOTER_OFFSET}>
        <div className={styles.list}>{createCreatives()}</div>
      </LoaderHandler>
    </div>
  );
};

export default createPaginationContainer(
  CampaignCreatives,
  {
    campaign: graphql`
      fragment CampaignCreatives_campaign on Campaign
      @argumentDefinitions(count: { type: "Int", defaultValue: 12 }, cursor: { type: "String" }) {
        creatives(first: $count, after: $cursor)
          @connection(key: "Campaign_creatives", filters: []) {
          edges {
            node {
              id
              ...Creative_creative
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
        type
        platform
        publishingRequired
        creativeInsightsLastCollectedAt
        counters {
          campaignPostingBudget
          publicationsEngagementSum
          publicationsViewsSum
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.campaign && props.campaign.creatives;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        campaignId: props.campaignId,
      };
    },
    query: graphql`
      query CampaignCreativesPaginationQuery($campaignId: ID!, $count: Int!, $cursor: String) {
        campaign(id: $campaignId) {
          ...CampaignCreatives_campaign @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  }
);

import React from 'react';

import styles from './ResultStub.pcss';

const ResultStub = () => {
  const statItem = (
    <div className={styles.statItem}>
      <div className={styles.line} style={{ height: '14px', margin: '5px 0', width: '45px' }} />
      <div className={styles.line} style={{ height: '8px', margin: '4px 0', width: '31px' }} />
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.medias} />
      <div className={styles.info}>
        <div className={styles.ava} />
        <div>
          <div
            className={styles.line}
            style={{ height: '16px', margin: '4px 0 8px', width: '164px' }}
          />
          <div className={styles.line} style={{ height: '8px', margin: '4px 0', width: '109px' }} />
        </div>
      </div>
      <div className={styles.statistic}>
        {statItem}
        {statItem}
        {statItem}
        {statItem}
      </div>
    </div>
  );
};

export default ResultStub;

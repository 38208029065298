import React, { Component } from 'react';
import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import flattenDeep from 'lodash/flattenDeep';

import styles from './Params.css';
import Param from './Param/Param';

import { createNumber } from 'Util/numberFormatter';
import { createDate, getAges } from 'Util/dateCreator';
import Button from 'Atoms/Button/Button';
import { BOOLEAN, ARRAY, RANGE, SINGLE_VALUE, TOGGLE_BOOLEAN, TEXT } from 'Constants/general';

class Params extends Component {
  constructor(props) {
    super(props);

    this.onResetParam = this.onResetParam.bind(this);
    this.onClearParams = this.onClearParams.bind(this);
    this.onRemoveFromArray = this.onRemoveFromArray.bind(this);
    this.onRemoveFromRange = this.onRemoveFromRange.bind(this);
  }

  onRemoveFromArray(param, id) {
    const { searchParams, onChange } = this.props;
    const items = searchParams[param];
    onChange({
      [param]: items.filter((i) => {
        return typeof i === 'string' ? i !== id : i.id !== id;
      }),
    });
  }

  onResetParam(param) {
    const { onChange } = this.props;

    onChange({ [param]: null });
  }

  onRemoveFromRange(paramFrom, paramTo) {
    const { onChange } = this.props;
    onChange({ [paramFrom]: null, [paramTo]: null });
  }

  onClearParams() {
    const { onClearParams } = this.props;
    onClearParams({});
  }

  renderArray({ key, additionalKey, group }) {
    const { searchParams } = this.props;
    const items = searchParams[key];

    if (!items) return [];

    const isPrimaryCategory = searchParams.strictlyByPrimaryCategory === true;
    if (key === 'categories' && isPrimaryCategory) group = 'starFull';

    return items.map((item) => {
      let id, name, msg, icon, primaryMsg;

      const additionalValue = searchParams[additionalKey];
      const finalAdditional = additionalValue ? `, ${Math.round(additionalValue * 100)}%` : '';

      if (typeof item === 'string') {
        id = item;
        name = `${name}${finalAdditional}`;
      } else {
        id = item.id;
        name = `${item.name}${finalAdditional}`;
        msg = item.msg;
        primaryMsg = item.primaryMsg;
        icon = item.icon;
      }

      const handleRemove = (param, id) => {
        const { searchParams, onChange } = this.props;
        const items = searchParams[param];
        const additionalData = items.length === 1 ? { [additionalKey]: null } : {};
        onChange({
          [param]: items.filter((i) => {
            return typeof i === 'string' ? i !== id : i.id !== id;
          }),
          ...additionalData,
        });
      };

      return (
        <Param
          className={styles.searchParam}
          key={`${key}-${id}`}
          group={group}
          text={name}
          msg={msg}
          primaryMsg={primaryMsg}
          icon={icon}
          id={id}
          param={key}
          onDelete={handleRemove}
        />
      );
    });
  }

  renderSingle({ key, additionalKey, group }) {
    const { searchParams } = this.props;
    const value = searchParams[key];

    const isPrimaryCategory = searchParams.strictlyByPrimaryCategory === true;
    if (key === 'categories' && isPrimaryCategory) group = 'starFull';

    if (value === null || value === undefined) return [];

    const { name, msg, id, removeDisabled } = value;
    const additionalValue = searchParams[additionalKey];
    const finalAdditional = additionalValue ? `, ${Math.round(additionalValue * 100)}%` : '';
    const textData = msg ? { msg } : { text: `${name}${finalAdditional}` };

    const handleResetParam = additionalValue
      ? () => {
          const { onChange } = this.props;
          onChange({ [key]: null, [additionalKey]: null });
        }
      : this.onResetParam;

    return [
      <Param
        className={styles.searchParam}
        key={id}
        group={group}
        {...textData}
        param={key}
        additionalParam={additionalKey}
        removeDisabled={removeDisabled}
        onDelete={handleResetParam}
      />,
    ];
  }

  renderRange({ key, group, format = 'number', reverse }) {
    const { searchParams } = this.props;
    const fromKey = `${key}From`;
    const toKey = `${key}To`;
    const from = searchParams[fromKey];
    const to = searchParams[toKey];

    let formatFn;

    switch (format) {
      case 'number':
        formatFn = createNumber;
        break;
      case 'date':
        formatFn = createDate;
        break;
      case 'age':
        formatFn = getAges;
        break;
      default:
        formatFn = createNumber;
        break;
    }

    const fromValue = from ? formatFn(from) : undefined;
    const toValue = to ? formatFn(to) : undefined;

    if ((from === null && to === null) || (from === undefined && to === undefined)) return [];

    let textData;
    const prefix = 'search_section.search_params.';
    if (from !== null && to !== null) {
      textData = {
        msg: `${prefix}${key}.from_and_to`,
        msgValues: { from: reverse ? toValue : fromValue, to: reverse ? fromValue : toValue },
      };
    } else if (from !== null) {
      textData = { msg: `${prefix}${key}.from`, msgValues: { from: fromValue } };
    } else {
      textData = { msg: `${prefix}${key}.to`, msgValues: { to: toValue } };
    }

    return [
      <Param
        className={styles.searchParam}
        key={key}
        group={group}
        {...textData}
        paramFrom={fromKey}
        paramTo={toKey}
        type="range"
        onDelete={this.onRemoveFromRange}
      />,
    ];
  }

  renderBooleanItem({ key, group }) {
    const { searchParams } = this.props;

    if (key === 'strictlyByPrimaryCategory') return null;

    const value = searchParams[key];
    if (!value) return [];

    return [
      <Param
        className={styles.searchParam}
        key={key}
        group={group}
        msg={`search_section.form.${key}`}
        param={key}
        onDelete={this.onResetParam}
      />,
    ];
  }

  renderToggleBooleanItem({ key, group }) {
    const { searchParams } = this.props;

    const value = searchParams[key];
    if (value === null || value === undefined) return [];

    return [
      <Param
        className={styles.searchParam}
        key={key}
        group={group}
        msg={`search_section.form.${key}.${value}`}
        param={key}
        onDelete={this.onResetParam}
      />,
    ];
  }

  renderText({ key }) {
    const { searchParams } = this.props;

    const value = searchParams[key];
    if (!value) return [];

    return [
      <Param
        className={styles.searchParam}
        key={key}
        text={capitalize(value)}
        param={key}
        onDelete={this.onResetParam}
      />,
    ];
  }

  renderParams() {
    const { paramsKit, ignoredParams } = this.props;
    if (!paramsKit) return null;

    const updatedParamsKit = ignoredParams
      ? paramsKit.filter((param) => !ignoredParams.includes(param.key))
      : paramsKit;
    const paramList = updatedParamsKit.map((param) => {
      const { type } = param;
      switch (type) {
        case BOOLEAN:
          return this.renderBooleanItem({ ...param });
        case TOGGLE_BOOLEAN:
          return this.renderToggleBooleanItem({ ...param });
        case ARRAY:
          return this.renderArray({ ...param });
        case RANGE:
          return this.renderRange({ ...param });
        case SINGLE_VALUE:
          return this.renderSingle({ ...param });
        case TEXT:
          return this.renderText({ ...param });
        default:
          return null;
      }
    });

    return compact(paramList);
  }

  render() {
    const { className, showClearButton } = this.props;
    const paramsList = this.renderParams();

    const count = flattenDeep(paramsList).length;

    return (
      <div className={`${className} ${styles.root}`}>
        {paramsList}
        {count > 0 && showClearButton && (
          <Button
            color="normal"
            msg="browse_creators.clear_filters"
            onClick={this.onClearParams}
            className={styles.clearBtn}
          />
        )}
      </div>
    );
  }
}

export default Params;

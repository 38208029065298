import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './ProgressBar.pcss';

type Props = {
  value: number;
  className?: string;
  classes?: {
    root?: string;
    bar?: string;
  };
};

const ProgressBar: React.FC<Props> = (props) => {
  const { value, className, classes } = props;

  const validateValue = useMemo(() => {
    if (isNaN(value) || typeof value !== 'number') {
      return 0;
    }
    let result = Math.abs(value);
    if (result > 100) {
      result = 100;
    }

    return result;
  }, [value]);

  return (
    <div className={classNames(styles.root, className, classes?.root)}>
      <div
        className={classNames(styles.bar, classes?.bar)}
        style={{ width: `${validateValue}%` }}
      />
    </div>
  );
};

export default ProgressBar;

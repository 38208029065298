import { graphql, useMutation } from 'react-relay';

import { ImportCreatorsBySearchQuery } from 'GraphTypes/ImportCreatorsBySearchQuery.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const importCreatorsBySearchQueryMutation = graphql`
  mutation ImportCreatorsBySearchQuery($input: ImportCreatorsBySearchQueryInput!) {
    importCreatorsBySearchQuery(input: $input) {
      __typename
      ... on ValidationError {
        type
        message
      }

      ... on ImportCreatorsBySearchQueryPayload {
        importCreatorsBySearchQueryOperation {
          state
          error
        }
      }
    }
  }
`;

export const useImportCreatorsBySearchQueryMutation: BaseMutation<ImportCreatorsBySearchQuery> = (
  config
) => {
  const [importCreatorsBySearchQuery, loading] = useMutation<ImportCreatorsBySearchQuery>(
    importCreatorsBySearchQueryMutation
  );

  const importCreatorsBySearchQueryCaller: BaseMutationExecFn<ImportCreatorsBySearchQuery> = (
    execConfig
  ) => {
    importCreatorsBySearchQuery({
      ...config,
      ...execConfig,
    });
  };
  return [importCreatorsBySearchQueryCaller, loading];
};

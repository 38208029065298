import React, { Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import styles from './ProjectAttachments.pcss';
import ProjectAttachments from './ProjectAttachments';

import Spinner from 'Atoms/Spinner/Spinner';

interface Props {
  last: number;
  organizationId: string;
  campaignId: string;
  creatorId: string;
  fileAttachmentType?: string;
}

export const PublicationSelectionAttachmentsQuery = graphql`
  query ProjectAttachmentsWrapperQuery(
    $last: Int
    $before: String
    $orderBy: AdvertiserFilesOrderBy
    $organizationId: ID!
    $creatorId: ID!
    $campaignId: ID!
    $fileTypes: [FileType!]
    $fileAttachmentType: FileAttachmentType
  ) {
    ...ProjectAttachmentsCampaign_files
      @arguments(
        last: $last
        before: $before
        organizationId: $organizationId
        orderBy: $orderBy
        creatorId: $creatorId
        campaignId: $campaignId
        fileTypes: $fileTypes
        fileAttachmentType: $fileAttachmentType
      )
  }
`;

const ProjectAttachmentsWrapper: React.FC<Props> = (props) => {
  const { last, organizationId, campaignId, creatorId, fileAttachmentType } = props;
  const [queryReference, loadQuery] = useQueryLoader(PublicationSelectionAttachmentsQuery);

  useEffect(() => {
    loadQuery({
      last,
      organizationId,
      campaignId,
      creatorId,
      fileAttachmentType,
      orderBy: 'createdAt_ASC',
    });
  }, [last, organizationId, campaignId, creatorId, fileAttachmentType]);

  return (
    <Suspense
      fallback={
        <div className={styles.emptyList}>
          <Spinner />
        </div>
      }
    >
      {queryReference ? (
        <ProjectAttachments
          // @ts-expect-error: TS like drug dealer - not working, but take everything from your brain
          queryReference={queryReference}
        />
      ) : null}
    </Suspense>
  );
};

export default ProjectAttachmentsWrapper;

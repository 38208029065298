import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useLocation } from 'react-router-dom';

import styles from './ProjectCampaignInfo.pcss';

import { getPlatformIcon } from 'Util/icons';
import { getOrganizationLink } from 'Util/links';
import AvatarItem from 'Molecules/AvatarItem/AvatarItem';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { ProjectCampaignInfo_project$key } from 'GraphTypes/ProjectCampaignInfo_project.graphql';

interface Props {
  admin: boolean;
  project: ProjectCampaignInfo_project$key;
}

const ProjectCampaignInfo = (props: Props) => {
  const { admin, project } = props;

  const { state: locationState } = useLocation();

  const data = useFragment(
    graphql`
      fragment ProjectCampaignInfo_project on Project {
        id
        campaign {
          id
          name
          platform
          brand {
            name
            logo {
              ... on Image {
                transformations {
                  brandLogoUrl
                }
              }
            }
          }
          organization {
            id
            name
          }
        }
      }
    `,
    project
  );

  const campaign = data?.campaign;
  const organization = campaign?.organization;

  const campaignId = campaign?.id;
  const campaignName = campaign?.name;
  const campaignPlatform = campaign?.platform;
  const brandLogo = campaign?.brand?.logo?.transformations?.brandLogoUrl;
  const brandName = campaign?.brand?.name || '';

  const organizationId = organization?.id || '';
  const organizationName = organization?.name || '';

  const orgStyles =
    brandName.length + organizationName.length > 38
      ? styles.organization
      : styles.organizationBefore;

  const organizationLink = getOrganizationLink({ organizationId });

  const subtitleEl = (
    <div className={styles.campaignInfo}>
      <Text size="sm" text={brandName} />
      {admin && (
        <SimpleLink href={organizationLink} target="_blank" className={orgStyles}>
          <TextButton className={styles.inlineTextBtn} size="sm" text={organizationName} />
        </SimpleLink>
      )}
    </div>
  );

  const platformIcon = getPlatformIcon(campaignPlatform?.toLowerCase());

  const avaEl = (
    <div className={styles.platform}>
      {platformIcon && <Icon name={platformIcon} className={styles.platformIcon} />}
    </div>
  );

  const getBackLink = () => {
    return locationState?.tab
      ? `/campaigns/${campaignId}/${locationState.tab}`
      : `/campaigns/${campaignId}`;
  };

  return (
    <div className={styles.root}>
      <AvatarItem
        avaData={{ src: brandLogo, size: 40 }}
        avaEl={campaignPlatform ? avaEl : undefined}
        titleData={{ text: campaignName, weight: '500', overflow: true }}
        subtitleEl={subtitleEl}
        className={styles.details}
      />
      <SmartLink path={getBackLink()}>
        <Button color="normal" msg="chat.campaign_open" className={styles.openBtn} />
      </SmartLink>
    </div>
  );
};

export default ProjectCampaignInfo;

import type { RadioDropdownItemType } from 'Components/ui/RadioDropdown/RadioDropdown';
import type { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';
import type {
  CampaignType,
  CampaignPlatform,
  Performance_Granularity,
} from 'GraphTypes/PerformanceCollaborationsChartQuery.graphql';
import { CampaignUseCase } from 'GraphTypes/CreateCampaignMutation.graphql';

type FiltersData = {
  brand: RadioDropdownItemType[];
  platform: RadioDropdownItemType[];
  granularity: RadioDropdownItemType[];
  campaignType: RadioDropdownItemType[];
  campaigns: CheckboxItemType[];
  useCase: RadioDropdownItemType<CampaignUseCase | 'ALL_TYPES'>[];
};

type FilterValuesState = Record<
  keyof Omit<FiltersData, 'dateRange' | 'platform' | 'campaigns'>,
  string
> & {
  dateRange: [from: string, to: string];
  granularity: Performance_Granularity;
  platform: FilterPlatformType;
  campaignType: FilterCampaignType;
  useCase: CampaignUseCase | 'ALL_TYPES';
  campaigns?: string[];
};

enum FilterCampaignType {
  ALL_TYPES = 'ALL_TYPES',
  JUST_CONTENT = 'JUST_CONTENT',
  ORGANIC_POSTING = 'ORGANIC_POSTING',
  CREATOR_ADS = 'CREATOR_ADS',
  PRODUCT_SEEDING = 'PRODUCT_SEEDING',
}

enum FilterPlatformType {
  ALL_PLATFORMS = 'ALL_PLATFORMS',
  INSTAGRAM = 'INSTAGRAM',
  TIK_TOK = 'TIK_TOK',
  FACEBOOK = 'FACEBOOK',
  OTHER = 'OTHER',
}

export { FilterCampaignType, FilterPlatformType };

export type { FiltersData, FilterValuesState };

import React, { FC } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link } from 'react-router-dom';

import styles from './OutreachHeader.pcss';

import Text from 'Components/ui/Text/Text';
import { OutreachHeaderQuery as QueryType } from 'GraphTypes/OutreachHeaderQuery.graphql';

interface Props {
  campaignId: string;
  batchesId: string[];
  showPercentage: boolean;
}

const OutreachHeader: FC<Props> = ({ batchesId = [], campaignId, showPercentage }) => {
  const data = useLazyLoadQuery<QueryType>(
    graphql`
      query OutreachHeaderQuery($campaignId: ID!, $batchIds: [ID!]) {
        campaign(id: $campaignId) {
          enqueuedCount: projects(outreachBatchIds: $batchIds, outreachStages: [ENQUEUED]) {
            totalCount
          }
          reachedCount: projects(
            outreachBatchIds: $batchIds
            outreachStages: [OUTREACHED, OPENED, CLICKED, APPLIED, HIRED, REPLIED]
          ) {
            totalCount
          }
          openedCount: projects(
            outreachBatchIds: $batchIds
            outreachStages: [OPENED, CLICKED, APPLIED, HIRED, REPLIED]
          ) {
            totalCount
          }
          clickedCount: projects(
            outreachBatchIds: $batchIds
            outreachStages: [CLICKED, APPLIED, HIRED, REPLIED]
          ) {
            totalCount
          }
          repliedCount: projects(outreachBatchIds: $batchIds, outreachStages: [REPLIED]) {
            totalCount
          }
          appliedCount: projects(outreachBatchIds: $batchIds, outreachStages: [APPLIED, HIRED]) {
            totalCount
          }
          hiredCount: projects(outreachBatchIds: $batchIds, outreachStages: [HIRED]) {
            totalCount
          }
          creatorsCount: projects(outreachBatchIds: $batchIds) {
            totalCount
          }
        }
      }
    `,
    { campaignId, batchIds: batchesId }
  );

  if (!data.campaign) return null;

  const totalCreators = data?.campaign?.creatorsCount.totalCount || 1;

  const calcMetricValue = (value: number | undefined) => {
    if (value === undefined) {
      return showPercentage ? '0%' : '0';
    }
    return showPercentage ? Number((value / totalCreators).toFixed(2)) * 100 + '%' : value;
  };

  const hiredCount = Number(data?.campaign?.hiredCount.totalCount);
  const appliedCount = Number(data?.campaign?.appliedCount.totalCount);

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <Text type="md" text={data?.campaign?.creatorsCount.totalCount} />
        <Text msg="campaign.outreach.column_title.all" type="label" className={styles.counter} />
      </div>
      <div className={styles.titleContainer}>
        <Text
          type="md"
          text={`${
            showPercentage
              ? calcMetricValue(data?.campaign?.enqueuedCount.totalCount)
              : data?.campaign?.enqueuedCount.totalCount
          }`}
        />
        <Text
          msg="campaign.outreach.column_title.enqueued"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        <Text
          type="md"
          text={`${
            showPercentage
              ? calcMetricValue(data?.campaign?.reachedCount.totalCount)
              : data?.campaign?.reachedCount.totalCount
          }`}
        />
        <Text
          msg="campaign.outreach.column_title.reached"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        <Text
          type="md"
          text={`${
            showPercentage
              ? calcMetricValue(data?.campaign?.openedCount.totalCount)
              : data?.campaign?.openedCount.totalCount
          }`}
        />
        <Text msg="campaign.outreach.column_title.opened" type="label" className={styles.counter} />
      </div>
      <div className={styles.titleContainer}>
        <Text
          type="md"
          text={`${
            showPercentage
              ? calcMetricValue(data?.campaign?.clickedCount.totalCount)
              : data?.campaign?.clickedCount.totalCount
          }`}
        />
        <Text
          msg="campaign.outreach.column_title.clicked"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        <Text
          type="md"
          text={`${
            showPercentage
              ? calcMetricValue(data?.campaign?.repliedCount.totalCount)
              : data?.campaign?.repliedCount.totalCount
          }`}
        />
        <Text
          msg="campaign.outreach.column_title.replied"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        {appliedCount > 0 ? (
          <Link to={`/campaigns/${campaignId}/applicants`}>
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
              className={styles.purple}
            />
          </Link>
        ) : (
          <Text
            type="md"
            text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
          />
        )}
        <Text
          msg="campaign.outreach.column_title.applied"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        {hiredCount > 0 ? (
          <Link to={`/campaigns/${campaignId}/creators`}>
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`}
              className={styles.purple}
            />
          </Link>
        ) : (
          <Text type="md" text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`} />
        )}
        <Text msg="campaign.outreach.column_title.hired" type="label" className={styles.counter} />
      </div>
    </div>
  );
};

export default OutreachHeader;

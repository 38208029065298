import React, { useState, useContext } from 'react';
import { Blocker, useNavigate } from 'react-router-dom';
import track, { useTracking } from 'react-tracking';

import styles from './SaveMediaplan.pcss';

import { getCampaignTypeLink } from 'Util/links';
import updateCampaign from 'Mutations/UpdateCampaign.Mutation';
import { BRIEF } from 'Constants/general';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  campaignId: string;
  isExit?: boolean;
  closeCallback?: () => void;
  navigationBlocker: Blocker;
}

const SaveMediaplan: React.FC<Props> = (props) => {
  const { campaignId, isExit, closeCallback, navigationBlocker } = props;
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const { closeDrawer } = useContext(DrawerContext);

  const tracking = useTracking();
  const navigate = useNavigate();

  const handleNameChange = (newName: string) => {
    setName(newName.trim());
  };

  const handleNameSave = () => {
    tracking.trackEvent({
      element: 'save_button',
      event: 'clicked',
    });

    amplitude.sendEvent({
      id: 230,
      category: 'marketplace',
      name: 'create_campaign_with_name',
      param: undefined,
    });
    setLoading(true);
    updateCampaign({ id: campaignId, name }, handleCampaignSaveDone);
  };

  const handleCampaignSaveDone = () => {
    closeDrawer('save-mediaplan');
    const link = getCampaignTypeLink({ campaignId, path: BRIEF });
    navigate(link);
  };

  const handleCloseWithoutSaving = () => {
    tracking.trackEvent({
      element: 'quite_no_save_button',
      event: 'clicked',
    });
    closeCallback?.();
    amplitude.sendEvent<232>({
      id: '232',
      category: 'marketplace',
      name: 'quit_without_saving',
      param: undefined,
    });
    if (navigationBlocker.state === 'blocked') {
      navigationBlocker.proceed();
    }
    closeDrawer('save-mediaplan');
  };

  return (
    <Drawer rootKey="save-mediaplan" className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <Text type="d2" msg="save_mediaplan_modal.title" className={styles.title} />
          {isExit && <Text type="md" msg="save_mediaplan_modal.descr" className={styles.descr} />}
          <Input
            bordered
            labelMsg="save_mediaplan_modal.campaign_name"
            placeholderMsg="save_mediaplan_modal.campaign_name"
            className={styles.input}
            maxLength={50}
            withCounter
            handleChange={handleNameChange}
          />
        </div>
        <div className={styles.buttons}>
          {isExit && (
            <Button
              color="white"
              msg="save_mediaplan_modal.quit_without_saving"
              loading={loading}
              onClick={handleCloseWithoutSaving}
            />
          )}
          <Button
            color="black"
            msg="save_mediaplan_modal.save"
            disabled={!name}
            loading={loading}
            onClick={handleNameSave}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default track(
  {
    page: 'save_mediaplan_modal',
  },
  { dispatchOnMount: true }
)(SaveMediaplan);

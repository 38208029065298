import { graphql } from 'relay-runtime';

export default graphql`
  query PortfolioContentQuery(
    $id: ID!
    $portfolioCategoryName: String
    $portfolioTypeName: String
  ) {
    creator(id: $id) {
      portfolioItems(
        first: 9
        portfolioCategoryName: $portfolioCategoryName
        portfolioTypeName: $portfolioTypeName
      ) {
        edges {
          node {
            file {
              id
              createdAt
              type
              secureUrl
              thumbnailUrl
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
              ... on Video {
                transformations {
                  collageThumbnailUrl
                  autoUrl
                  portfolioSliderUrl
                  portfolioSliderThumbnailUrl
                }
              }
            }
          }
        }
      }
      profile {
        # topPosts {
        #   thumbnailUrl
        #   mediaType
        # }
        videoResumeFile {
          createdAt
          thumbnailUrl
          ... on Video {
            transformations {
              autoUrl
              portfolioSliderUrl
              portfolioSliderThumbnailUrl
            }
          }
          secureUrl
        }
      }
      # If needed add media from social network
      ... on InstagramCreator {
        medias(first: 8) {
          edges {
            node {
              id
              createdAt
              videoUrl
              shortcode
              standardResUrl
              thumbnailUrl
              instagramUrl
            }
          }
        }
      }
    }
  }
`;

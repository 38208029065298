import React from 'react';
import classnames from 'classnames';

import styles from './TooltipInfo.pcss';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import IconNew from 'Components/ui/Icon/Icon';

interface Props {
  tooltipMsg?: string;
  id?: TooltipProps['id'];
  tooltipValues?: TooltipProps['tooltipValues'];
  getContent?: TooltipProps['getContent'];
  clickable?: TooltipProps['clickable'];
  type?: TooltipProps['type'];
  className?: string;
  color?: 'grey100' | 'grey50';
}

const TooltipInfo: React.FC<Props> = (props) => {
  const {
    id,
    tooltipMsg,
    tooltipValues,
    getContent,
    clickable,
    className,
    type,
    color = 'grey100',
  } = props;

  if (!tooltipMsg && !getContent) return null;

  return (
    <Tooltip
      place="top"
      id={id}
      tooltipMsg={tooltipMsg}
      tooltipValues={tooltipValues}
      className={classnames(styles.root, className)}
      tooltipClassName={styles.featureTooltipClassName}
      childrenWrapClassName={styles.featureTooltipChildrenWrapClassName}
      clickable={clickable}
      getContent={getContent}
      type={type}
    >
      <IconNew
        name="Info"
        className={classnames(styles.infoIcon, styles[`color-${color}`])}
        size={18}
      />
    </Tooltip>
  );
};

export default TooltipInfo;
export type { Props as TooltipInfoProps };

type TooltipProps = React.ComponentProps<typeof Tooltip>;

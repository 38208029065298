import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  CreateCampaignPublicationsReportMutation,
  CreateCampaignPublicationsReportMutation$data,
  Reporting_CreateCampaignPublicationsReportInput,
} from 'GraphTypes/CreateCampaignPublicationsReportMutation.graphql';

const mutation = graphql`
  mutation CreateCampaignPublicationsReportMutation(
    $input: Reporting_CreateCampaignPublicationsReportInput!
  ) {
    reporting {
      createCampaignPublicationsReport(input: $input) {
        report {
          id
          state
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: Reporting_CreateCampaignPublicationsReportInput,
  resolve?: (response: CreateCampaignPublicationsReportMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<CreateCampaignPublicationsReportMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

import styles from './CustomDayContent.pcss';

type Props = {
  dayOfMonth: RenderDayContentsArgs[0];
  date?: RenderDayContentsArgs[1];
};

const CustomDayContent: React.FC<Props> = (props) => {
  const { dayOfMonth } = props;
  return <div className={styles.root}>{dayOfMonth}</div>;
};

export default CustomDayContent;

type RenderDayContentsArgs = Parameters<NonNullable<ReactDatePickerProps['renderDayContents']>>;

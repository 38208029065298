import { commitMutation, graphql } from 'react-relay';
import includes from 'lodash/includes';
import { RecordSourceSelectorProxy } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  UpdateBrandMutation,
  UpdateBrandInput,
  UpdateBrandMutation$data,
} from 'GraphTypes/UpdateBrandMutation.graphql';
import { CreateBrandMutation$data } from 'GraphTypes/CreateBrandMutation.graphql';

const mutation = graphql`
  mutation UpdateBrandMutation($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      brand {
        id
        name
        websiteUrl
        logo {
          ... on Image {
            transformations {
              brandLogoUrl
            }
          }
        }
        category {
          id
          name
        }
        summary
      }
    }
  }
`;

type FnType = (data: object) => void;

export const updateActiveBrandsCounters = (
  store: RecordSourceSelectorProxy<UpdateBrandMutation$data | CreateBrandMutation$data>,
  isActive: boolean
) => {
  const user = store.getRoot().getLinkedRecord('currentUser');
  if (user) {
    const organization = user?.getLinkedRecord('organization');
    if (organization) {
      const counters = organization?.getLinkedRecord('counters');
      if (counters) {
        const activeBrands = counters?.getValue('activeBrands');
        const newValue =
          typeof activeBrands === 'number'
            ? isActive
              ? activeBrands + 1
              : activeBrands - 1
            : null;
        store
          .getRoot()
          ?.getLinkedRecord('currentUser')
          ?.getLinkedRecord('organization')
          ?.getLinkedRecord('counters')
          ?.setValue(newValue, 'activeBrands');
      }
    }
  }
};

export default (
  data: UpdateBrandInput,
  resolve?: (response: UpdateBrandMutation$data) => void,
  reject?: FnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<UpdateBrandMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const brand = store.get(data.id);
      if (!brand) return;

      const keys = Object.keys(data);

      if (brand.getValue('active') !== data.active) {
        if (includes(keys, 'active')) {
          brand.setValue(data.active, 'active');
        }
        updateActiveBrandsCounters(store, !!data.active);
      }

      keys.forEach((key) => {
        if (includes(keys, key)) {
          brand.setValue(data[key as keyof UpdateBrandInput], key);
        }
      });
    },
    updater: (store) => {
      const brand = store.get(data.id);
      if (!brand) return;

      const keys = Object.keys(data);

      if (brand.getValue('active') !== data.active) {
        if (includes(keys, 'active')) {
          brand.setValue(data.active, 'active');
        }
        updateActiveBrandsCounters(store, !!data.active);
      }

      keys.forEach((key) => {
        if (includes(keys, key)) {
          brand.setValue(data[key as keyof UpdateBrandInput], key);
        }
      });
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useState } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './AdvertiserCampaigns.css';
import Campaigns from './Campaigns/Campaigns';
import AdvertiserCampaignStageFilter from './AdvertiserCampaignStageFilter/AdvertiserCampaignStageFilter';

import SearchInput from 'Components/SearchInput/SearchInput';
import {
  AdvertiserCampaignsQuery as QueryType,
  CampaignStage,
} from 'GraphTypes/AdvertiserCampaignsQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';

const AdvertiserCampaignsQuery = graphql`
  query AdvertiserCampaignsQuery($id: ID!, $stages: [CampaignStage!], $textQuery: String) {
    organization(id: $id) {
      ...Campaigns_organization @arguments(stages: $stages, textQuery: $textQuery)
    }
  }
`;

interface Props {
  id: string;
  userOrganizationId: string;
}

const AdvertiserCampaigns: React.FC<Props> = (props) => {
  const { id, userOrganizationId } = props;

  const [stage, setStage] = useState('ACTIVE');
  const [textQuery, setTextQuery] = useState('');

  const stagesForRequest = stage === 'ACTIVE' ? ['ACTIVE', 'IN_REVIEW'] : [stage];

  const data = useLazyLoadQuery<QueryType>(AdvertiserCampaignsQuery, {
    id,
    stages: stagesForRequest as CampaignStage[],
    textQuery,
  });

  const handleQueryChange = (newTextQuery: string) => {
    setTextQuery(newTextQuery);
  };

  const organization = data.organization;

  return (
    <div>
      <header className={styles.header}>
        <AdvertiserCampaignStageFilter id={id} currentStage={stage} onStageChange={setStage} />
        <div className={styles.searchInput}>
          <SearchInput
            placeholder="campaigns.serach_input.placeholder"
            onChange={handleQueryChange}
          />
        </div>
      </header>
      {organization ? (
        <Campaigns
          stages={stagesForRequest}
          organizationId={id}
          userOrganizationId={userOrganizationId}
          textQuery={textQuery}
          organization={organization}
        />
      ) : (
        <Spinner className={styles.preloader} />
      )}
    </div>
  );
};

export default AdvertiserCampaigns;

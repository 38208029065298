/**
 * @generated SignedSource<<2f086b0ace8181c16a8e994f2d13d8d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OutreachBatchQuery$variables = {
  batchId: string;
  campaignId: string;
};
export type OutreachBatchQuery$data = {
  readonly campaign: {
    readonly appliedCount: {
      readonly totalCount: number;
    };
    readonly clickedCount: {
      readonly totalCount: number;
    };
    readonly enqueuedCount: {
      readonly totalCount: number;
    };
    readonly hiredCount: {
      readonly totalCount: number;
    };
    readonly openedCount: {
      readonly totalCount: number;
    };
    readonly reachedCount: {
      readonly totalCount: number;
    };
    readonly repliedCount: {
      readonly totalCount: number;
    };
  } | null | undefined;
};
export type OutreachBatchQuery = {
  response: OutreachBatchQuery$data;
  variables: OutreachBatchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "batchId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v3 = {
  "items": [
    {
      "kind": "Variable",
      "name": "outreachBatchIds.0",
      "variableName": "batchId"
    }
  ],
  "kind": "ListValue",
  "name": "outreachBatchIds"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v5 = {
  "alias": "enqueuedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "ENQUEUED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": "reachedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "OUTREACHED",
        "OPENED",
        "CLICKED",
        "APPLIED",
        "HIRED",
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": "openedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "OPENED",
        "CLICKED",
        "APPLIED",
        "HIRED",
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": "clickedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "CLICKED",
        "APPLIED",
        "HIRED",
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": "repliedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "REPLIED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": "appliedCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "APPLIED",
        "HIRED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": "hiredCount",
  "args": [
    (v3/*: any*/),
    {
      "kind": "Literal",
      "name": "outreachStages",
      "value": [
        "HIRED"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachBatchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OutreachBatchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4701ffedc684de7d6c7df3fbb9d39485",
    "id": null,
    "metadata": {},
    "name": "OutreachBatchQuery",
    "operationKind": "query",
    "text": "query OutreachBatchQuery(\n  $campaignId: ID!\n  $batchId: ID!\n) {\n  campaign(id: $campaignId) {\n    enqueuedCount: projects(outreachBatchIds: [$batchId], outreachStages: [ENQUEUED]) {\n      totalCount\n    }\n    reachedCount: projects(outreachBatchIds: [$batchId], outreachStages: [OUTREACHED, OPENED, CLICKED, APPLIED, HIRED, REPLIED]) {\n      totalCount\n    }\n    openedCount: projects(outreachBatchIds: [$batchId], outreachStages: [OPENED, CLICKED, APPLIED, HIRED, REPLIED]) {\n      totalCount\n    }\n    clickedCount: projects(outreachBatchIds: [$batchId], outreachStages: [CLICKED, APPLIED, HIRED, REPLIED]) {\n      totalCount\n    }\n    repliedCount: projects(outreachBatchIds: [$batchId], outreachStages: [REPLIED]) {\n      totalCount\n    }\n    appliedCount: projects(outreachBatchIds: [$batchId], outreachStages: [APPLIED, HIRED]) {\n      totalCount\n    }\n    hiredCount: projects(outreachBatchIds: [$batchId], outreachStages: [HIRED]) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b79c8bdfa10ea9f36db13de3e8203f45";

export default node;

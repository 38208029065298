import { graphql } from 'react-relay';

import type { UseShopifyQuery as UseShopifyQueryType } from 'GraphTypes/UseShopifyQuery.graphql';

const UseShopifyQuery = graphql`
  query UseShopifyQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      organization {
        id
        shopifyAuthorization {
          shop {
            id
            name
          }
          reauthenticationRequired
        }
      }
    }
  }
`;

export { UseShopifyQuery };

// types

export type { UseShopifyQueryType };

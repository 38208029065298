import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  ChangeCreatorUsernameMutation,
  ChangeCreatorUsernameMutation$data,
  ChangeCreatorUsernameInput,
} from 'GraphTypes/ChangeCreatorUsernameMutation.graphql';

const mutation = graphql`
  mutation ChangeCreatorUsernameMutation($input: ChangeCreatorUsernameInput!) {
    changeCreatorUsername(input: $input) {
      result
    }
  }
`;

type fnType = (data: object) => void;

type Data = {
  creatorId: string;
};

export default (
  data: ChangeCreatorUsernameInput & Data,
  resolve?: (response: ChangeCreatorUsernameMutation$data) => void,
  reject?: fnType
) => {
  const { creatorId, creatorType, newUsername, oldUsername } = data;

  const variables = {
    input: {
      creatorType,
      newUsername,
      oldUsername,
    },
  };
  commitMutation<ChangeCreatorUsernameMutation>(environment, {
    mutation,
    variables,
    updater: (store, data) => {
      const creator = store.get(creatorId);
      const result = data?.changeCreatorUsername?.result;

      if (result === 'SUCCESS') {
        if (creatorType === 'TIKTOK') {
          creator?.getLinkedRecord('user')?.setValue(newUsername, 'name');
        } else if (creatorType === 'INSTAGRAM') {
          creator?.getLinkedRecord('user')?.setValue(newUsername, 'username');
        }
        creator?.setValue(newUsername, 'username');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import compact from 'lodash/compact';

import styles from './SearchResultMedias.pcss';

import { createPortfolioList } from 'Util/dataFormatter';
import Text from 'Atoms/Text/Text';
import ImagesWithFallback from 'Components/ImagesWithFallback/ImagesWithFallback';
import { INSTAGRAM } from 'Constants/general';
import { SearchResultMedias_creator$key } from 'GraphTypes/SearchResultMedias_creator.graphql';

const ITEMS_LIMIT = 9;

interface Props {
  className?: string;
  creator?: SearchResultMedias_creator$key;
}

const SearchResultMedias: React.FC<Props> = (props) => {
  const { className, creator } = props;

  const data = useFragment(
    graphql`
      fragment SearchResultMedias_creator on Creator {
        type
        portfolioItems(last: 9) {
          edges {
            node {
              file {
                id
                type
                thumbnailUrl
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
                ... on Video {
                  transformations {
                    autoUrl
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
        }
        ... on TiktokCreator {
          insightsMedias(last: 9) {
            edges {
              node {
                file {
                  id
                  type
                  thumbnailUrl
                }
              }
            }
          }
        }
        ... on InstagramCreator {
          insightsMedias(last: 9) {
            edges {
              node {
                file {
                  id
                  type
                  thumbnailUrl
                }
              }
            }
          }
        }
      }
    `,
    creator
  );

  if (!data) return null;

  const type = data.type;
  const portfolioItems = data.portfolioItems;
  const insightsMedias = data.insightsMedias;

  const medias = useMemo(() => {
    return (
      compact(
        insightsMedias?.edges?.map((item) => {
          if (!item?.node?.file?.id) return null;
          const { id, thumbnailUrl, type } = item.node.file;
          return { id, thumbnailUrl, type };
        })
      ) || []
    );
  }, [insightsMedias]);

  const items = [...createPortfolioList(portfolioItems), ...medias];

  if (items.length === 0 && type === INSTAGRAM) {
    return (
      <div className={classnames(className, styles.medias)}>
        <Text align="center" className={styles.stub} msg="browse_creators_card.medias_empty" />
      </div>
    );
  }

  return (
    <div className={classnames(className, styles.medias)}>
      <ImagesWithFallback
        items={items}
        limit={ITEMS_LIMIT}
        imageStyle={styles.imageStyle}
        className={styles.media}
        type={type}
      />
    </div>
  );
};

export default SearchResultMedias;

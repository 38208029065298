import {
  SHOW_HEADER,
  SHOW_FOOTER,
  TOGGLE_PRELOADER,
  TOGGLE_MOBILE_VIEW,
} from 'Constants';

const initialState = {
  showHeader: false,
  showFooter: false,
  showPreloader: false,
  mobileView: false,
};

const ui = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SHOW_HEADER:
      return {
        ...state,
        showHeader: payload,
      };

    case SHOW_FOOTER:
      return {
        ...state,
        showFooter: payload,
      };

    case TOGGLE_PRELOADER:
      return {
        ...state,
        showPreloader: payload,
      };

    case TOGGLE_MOBILE_VIEW:
      return {
        ...state,
        mobileView: payload,
      };

    default:
      return state;
  }
};

export default ui;

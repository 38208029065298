import warningImg from 'Images/modals/warning.svg';
import lockImg from 'Images/modals/lock.svg';
import mailSentImg from 'Images/modals/mail_sent.svg';

export const campaignPausedInfo = {
  iconSrc: lockImg,
  titleMsg: 'campaign_paused_modal.title',
  descrMsg: 'campaign_paused_modal.descr',
  buttonData: {
    msg: 'campaign_paused_modal.submit',
  },
};

export const closeRequestSentFailed = {
  iconSrc: mailSentImg,
  titleMsg: 'close_campaign_request_done_modal.title',
  descrMsg: 'close_campaign_request_done_modal.descr',
};

export const igInsightsFailed = {
  iconSrc: warningImg,
  descrMsg: 'connect_fb_modal.error.not_ig_insights_permission.descr',
};

import includes from 'lodash/includes';

import ls from 'Util/localStorage';
import ruMessages from 'Locales/en.json';
import enMessages from 'Locales/en.json';
import { RU, EN } from 'Constants/general';

const locales = [RU, EN];

// TODO: remove en localization stub

export const getLocale = () => {
  let locale = ls.get('locale');
  if (!locale) {
    locale = navigator.language.slice(0, 2).toLowerCase();
    if (!(includes(locales, locale))) {
      locale = EN;
    }
    ls.set('locale', locale);
  } else {
    if (!(includes(locales, locale))) {
      locale = EN;
      ls.set('locale', locale);
    }
  }

  return locale;
};

export const getLocalizationData = () => {
  const locale = getLocale();

  return {
    locale,
    messages: locale === RU ? ruMessages : enMessages,
  };
};

export const changeLocalizationData = () => {
  let locale = '';
  let tmpLocale = ls.get('locale');
  locale = (tmpLocale === RU) ? EN : RU;
  ls.set('locale', locale);

  return {
    locale,
    messages: locale === RU ? ruMessages : enMessages,
  };
};

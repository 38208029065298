import React, { useContext } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './CreatorChat.pcss';

import { ProjectInfoContextProvider } from 'CommonPage/Chat/Dialog/ProjectInfo/ProjectInfo.Context';
import DialogContent from 'CommonPage/Chat/Dialog/DialogContent';
import { CreatorChatQuery as QueryType } from 'GraphTypes/CreatorChatQuery.graphql';
import { PlanId } from 'GraphTypes/BriefQuery.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ShopifyChatTooltip } from 'Components/ShopifyChatTooltip/ShopifyChatTooltip';

const CreatorChatQuery = graphql`
  query CreatorChatQuery($projectId: ID!) {
    currentUser {
      advertiserProfile {
        id
      }
      organization {
        subscription {
          effectiveLimits {
            maxHiredCreators
            maxLicensedCreators
            maxBcaConnections
          }
          planId
        }
        counters {
          hiredCreators
          psLicensedCreators
          bcaLicensedCreators
        }
      }
    }
    project(id: $projectId) {
      campaign {
        showShipmentTab
        id
        shopifyAuthorization {
          id
        }
      }
      launch {
        id
      }
    }
  }
`;

interface Props {
  projectId: string;
}

const CreatorChat: React.FC<Props> = (props) => {
  const { projectId } = props;

  const data = useLazyLoadQuery<QueryType>(CreatorChatQuery, { projectId });

  if (!data) return null;

  const subscription = data.currentUser?.organization?.subscription;
  const maxLicensedCreators = subscription?.effectiveLimits.maxLicensedCreators;
  const maxHiredCreators = subscription?.effectiveLimits.maxHiredCreators;
  const maxBcaConnections = subscription?.effectiveLimits.maxBcaConnections;
  const planId = subscription?.planId as PlanId;
  const counters = data.currentUser?.organization?.counters;
  const hiredCreators = counters?.hiredCreators;
  const psLicensedCreators = counters?.psLicensedCreators;
  const bcaLicensedCreators = counters?.bcaLicensedCreators;
  const isAdvertiser = !!data?.currentUser?.advertiserProfile;
  const shipmentEnabled = data?.project?.campaign?.showShipmentTab;
  const campaignId = data?.project?.campaign?.id;
  const launched = !!data?.project?.launch?.id;
  const { closeDrawer } = useContext(DrawerContext);

  const onShipmentClick = () => {
    closeDrawer(`chat-modal-${projectId}`);
  };

  return (
    <ProjectInfoContextProvider
      subscription={{ maxHiredCreators, maxLicensedCreators, maxBcaConnections, planId }}
      counters={{ hiredCreators, psLicensedCreators, bcaLicensedCreators }}
    >
      <div className={styles.drawerContainer}>
        <DialogContent
          projectId={projectId}
          isOldView={false}
          inPopup={true}
          isAdvertiser={isAdvertiser}
        />
        <ShopifyChatTooltip
          isAdvertiser={isAdvertiser}
          shipmentEnabled={shipmentEnabled}
          campaignId={campaignId || ''}
          launched={launched}
          tooltipClassName={styles.tooltip}
          onPassed={onShipmentClick}
        />
      </div>
    </ProjectInfoContextProvider>
  );
};

export default CreatorChat;

import React, { useCallback, useState } from 'react';
import { getDate } from 'date-fns';
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker';
import classnames from 'classnames';

import styles from './DatePicker.pcss';
import CustomInput from './CustomInput/CustomInput';
import CustomHeader from './CustomHeader/CustomHeader';
import CustomContainer from './CustomContainer/CustomContainer';
import CustomDayContent from './CustomDayContent/CustomDayContent';

import { Props as InputProps } from 'Components/ui/Input/Input';

type Props = {
  className?: string;
  dateFormat?: string;
  customInputProps?: Partial<InputProps>;
  inputClassName?: string;
  onResetValue?: () => void;
  reactDatePickerProps?: Omit<Partial<ReactDatePickerProps>, 'renderCustomHeader' | 'customInput'>;
};

const DatePicker: React.FC<Props> = (props) => {
  const {
    className,
    inputClassName,
    customInputProps,
    reactDatePickerProps,
    onResetValue,
    dateFormat = 'dd.MM.yyyy',
  } = props;
  const [startDate, setStartDate] = useState<Date | null>(reactDatePickerProps?.selected || null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleChange = useCallback<ReactDatePickerProps['onChange']>(
    (dates, e) => {
      const isRange = Array.isArray(dates);
      if (isRange) {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
      } else {
        setStartDate(dates);
      }
      reactDatePickerProps?.onChange?.(dates, e);
    },
    [reactDatePickerProps?.onChange]
  );

  return (
    <div className={classnames(styles.root, className)}>
      <ReactDatePicker
        dateFormat={dateFormat}
        showMonthDropdown
        showYearDropdown
        calendarStartDay={1}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        {...reactDatePickerProps}
        onChange={handleChange}
        customInput={
          <CustomInput
            className={inputClassName}
            {...customInputProps}
            onResetValue={onResetValue}
          />
        }
        renderCustomHeader={(p) => <CustomHeader {...p} />}
        calendarContainer={(p) => <CustomContainer {...p} />}
        useWeekdaysShort={true}
        weekDayClassName={(date) => {
          return styles.weekDayClassName;
        }}
        renderDayContents={(dayOfMonth, date) => (
          <CustomDayContent dayOfMonth={dayOfMonth} date={date} />
        )}
        dayClassName={(date) => (getDate(date) ? styles.customDay : null)}
      />
    </div>
  );
};

export default DatePicker;
export type { Props as DatePickerProps };

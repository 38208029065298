/**
 * @generated SignedSource<<e76ed5ab0de32bbfefc2aace4f803320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatorImportProgress_creatorImport$data = {
  readonly completed: boolean;
  readonly id: string;
  readonly importedCreators: number;
  readonly totalCreators: number;
  readonly " $fragmentType": "CreatorImportProgress_creatorImport";
};
export type CreatorImportProgress_creatorImport$key = {
  readonly " $data"?: CreatorImportProgress_creatorImport$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorImportProgress_creatorImport">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorImportProgress_creatorImport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "importedCreators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCreators",
      "storageKey": null
    }
  ],
  "type": "CreatorImport",
  "abstractKey": null
};

(node as any).hash = "b0080785e566ec6cda79c7dff9b70ca9";

export default node;

/**
 * @generated SignedSource<<147aa902be13f8233129512e44f840ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrganizationInput = {
  advertisingCountryId?: string | null | undefined;
  businessDescription?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  industry?: string | null | undefined;
  name: string;
  partnerName?: string | null | undefined;
  referralToken?: string | null | undefined;
  requestedDemo?: boolean | null | undefined;
  size?: string | null | undefined;
  source?: string | null | undefined;
  tapfiliateReferralCode?: string | null | undefined;
  verticals?: ReadonlyArray<string> | null | undefined;
  websiteUrl?: string | null | undefined;
};
export type CreateOrganizationMutation$variables = {
  input: CreateOrganizationInput;
};
export type CreateOrganizationMutation$data = {
  readonly createOrganization: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type CreateOrganizationMutation = {
  response: CreateOrganizationMutation$data;
  variables: CreateOrganizationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOrganizationPayload",
    "kind": "LinkedField",
    "name": "createOrganization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrganizationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOrganizationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e865ac484e11ed23b818180dbd2e291e",
    "id": null,
    "metadata": {},
    "name": "CreateOrganizationMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOrganizationMutation(\n  $input: CreateOrganizationInput!\n) {\n  createOrganization(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "8cbf4f3abba552bd1e271f6d3862b8ab";

export default node;

import { graphql, useMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { UpdateBriefScreeningQuestionMutation } from 'GraphTypes/UpdateBriefScreeningQuestionMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const updateBriefScreeningQuestionMutation = graphql`
  mutation UpdateBriefScreeningQuestionMutation($input: UpdateBriefScreeningQuestionInput!) {
    updateBriefScreeningQuestion(input: $input) {
      ... on UpdateBriefScreeningQuestionPayload {
        screeningQuestion {
          id
          preferredValue
          text
          type
        }
      }
    }
  }
`;

export const useUpdateBriefScreeningQuestionMutation: BaseMutation<
  UpdateBriefScreeningQuestionMutation
> = (config) => {
  const [updateBriefScreeningQuestion, loading] = useMutation<UpdateBriefScreeningQuestionMutation>(
    updateBriefScreeningQuestionMutation
  );

  const updateBriefScreeningQuestionCaller: BaseMutationExecFn<
    UpdateBriefScreeningQuestionMutation
  > = (execConfig) => {
    updateBriefScreeningQuestion({
      ...config,
      ...execConfig,
      updater: (store, data) => {
        const briefId = config?.variables.input.screeningQuestion.v2BriefId;
        const id = config?.variables.input.screeningQuestion.id;
        if (!briefId || id) return;
        const brief = store.get(briefId);
        if (!brief) return;
        const questions = ConnectionHandler.getConnection(
          brief,
          'ScreeningQuestions_screeningQuestions'
        );
        if (!questions) return;
        const newScreeningQuestion = data?.updateBriefScreeningQuestion?.screeningQuestion;

        if (!newScreeningQuestion) return;
        const newQuestion = store.get(newScreeningQuestion.id);
        if (!newQuestion) return;
        const edge = ConnectionHandler.createEdge(
          store,
          questions,
          newQuestion,
          'BriefScreeningQuestionEdge'
        );
        ConnectionHandler.insertEdgeAfter(questions, edge);
      },
    });
  };
  return [updateBriefScreeningQuestionCaller, loading];
};

import { isCypress as _isCypress } from 'Util/isCypress';

const isCypress = _isCypress();

type UseDashly = {
  connect: (apiKey: string) => void;
  auth: (userId: string, hash: string) => void;
  identify: (data: { key: string; value: unknown }[]) => void;
  open: () => void;
  track: (eventName: string, params?: DashlyParams) => void;
};

const useDashly = (): UseDashly => {
  const connect = (apiKey: string) => {
    if (window.dashly) window.dashly.connect(apiKey);
  };

  const auth = (userId: string, hash: string) => {
    if (window.dashly) window.dashly.auth(userId, hash);
  };

  const identify = (data: { key: string; value: unknown }[]) => {
    if (window.dashly) window.dashly.identify(data);
  };

  const open = () => {
    if (window.dashly) window.dashly.open();
  };

  const track = (eventName: string, params?: DashlyParams) => {
    if (isCypress) return;
    if (window.dashly) window.dashly.track(eventName, params || {});
  };

  return {
    connect,
    auth,
    identify,
    open,
    track,
  };
};

export default useDashly;

/**
 * @generated SignedSource<<2fae3c6f2bbbdc81fa050532bed3662b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OutreachPreviewQuery$variables = {
  id: string;
};
export type OutreachPreviewQuery$data = {
  readonly campaign: {
    readonly outreachBatches: {
      readonly totalCount: number;
    };
  } | null | undefined;
  readonly outreachCounters: {
    readonly todayCount: number;
    readonly todayLimit: number | null | undefined;
    readonly totalLimit: number | null | undefined;
  } | null | undefined;
};
export type OutreachPreviewQuery = {
  response: OutreachPreviewQuery$data;
  variables: OutreachPreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OutreachBatchConnection",
  "kind": "LinkedField",
  "name": "outreachBatches",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "OutreachCounters",
  "kind": "LinkedField",
  "name": "outreachCounters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "todayCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "todayLimit",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutreachPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "f4834eec8a81881d335bd20b58d6d6a1",
    "id": null,
    "metadata": {},
    "name": "OutreachPreviewQuery",
    "operationKind": "query",
    "text": "query OutreachPreviewQuery(\n  $id: ID!\n) {\n  campaign(id: $id) {\n    outreachBatches {\n      totalCount\n    }\n    id\n  }\n  outreachCounters {\n    totalLimit\n    todayCount\n    todayLimit\n  }\n}\n"
  }
};
})();

(node as any).hash = "78409775e3fb656ff3763d6bd98ea507";

export default node;

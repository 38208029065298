import React from 'react';
import classnames from 'classnames';

import styles from './Warning.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text, { Props as TextProps } from 'Components/ui/Text/Text';

interface Props {
  titleMsg: string;
  titleValues?: TextProps['formatValues'];
  className?: string;
  error?: boolean;
}

const Warning: React.FC<Props> = (props) => {
  const { className, titleMsg, titleValues, error } = props;
  return (
    <div className={classnames(styles.root, className, { [styles.error]: error })}>
      <Icon name={error ? 'Support-sad' : 'Support-help'} className={styles.icon} />
      <Text type="sm" msg={titleMsg} formatValues={titleValues} />
    </div>
  );
};

export default Warning;

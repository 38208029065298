import React from 'react';
import { useDispatch } from 'react-redux';

import { amplitude } from 'Helpers/amplitude';
import { setModal } from 'Actions/Modal.Actions';
import { modalName } from 'Types/modals';
import Button from 'Atoms/Button/Button';

interface Props {
  campaignId: string;
  creatorId: string;
  projectId: string;
}

const StartNewDealAction: React.FC<Props> = (props) => {
  const { campaignId, creatorId, projectId } = props;

  const dispatch = useDispatch();

  const handleStarNewDealClick = () => {
    amplitude.sendEvent<153>({
      id: '153',
      category: 'brand',
      name: 'start_new_deal_chat',
      param: { creator_id: creatorId, project_id: projectId },
    });
    dispatch(setModal(modalName.START_NEW_DEAL, { campaignId, creatorId, projectId }));
  };

  return (
    <Button msg="chat.project_creator_rating.start_new_deal" onClick={handleStarNewDealClick} />
  );
};

export default StartNewDealAction;

import React from 'react';
import classnames from 'classnames';

import styles from './RangeInput.pcss';

import Text from 'Atoms/Text/Text';
import Input, { Props as IInputProps } from 'Components/ui/Input/Input';

export interface IRangeInputProps {
  className?: string;
  first: IInputProps;
  second: IInputProps;
  isDirty?: boolean;
  shrinkIfDirty?: boolean;
}

const RangeField: React.FC<IRangeInputProps> = ({
  className = '',
  first,
  second,
  isDirty,
  shrinkIfDirty,
}) => {
  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.range}>
        <Input
          {...first}
          bordered
          inputClassName={classnames(first.className, {
            [styles.dirty]: isDirty,
            [styles.shrink]: shrinkIfDirty,
          })}
          type="number"
          hideCloseIcon
        />
        <Text text="—" className={styles.seperator} />
        <Input
          {...second}
          bordered
          type="number"
          inputClassName={classnames(second.className, {
            [styles.dirty]: isDirty,
            [styles.shrink]: shrinkIfDirty,
          })}
          hideCloseIcon
        />
      </div>
    </div>
  );
};

export default RangeField;

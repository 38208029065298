import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './CustomListDropdown.pcss';

import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import { IconToggler } from 'Components/IconToggler';

type Props = {
  className?: string;
  items?: Item[];
  dropdownProps?: Partial<DropdownProps>;
  isFavoriteCreator?: boolean;
  classes?: {
    dropdownGroup?: string;
  };
  onFavoriteButtonClick?: AlterButtonProps['onClick'];
  onCustomListItemClick: (item: Item) => void;
  onAddCustomListButtonClick?: () => void;
};

const CustomListDropdown: React.FC<Props> = (props) => {
  const {
    items,
    className,
    dropdownProps,
    isFavoriteCreator,
    onFavoriteButtonClick,
    classes,
    onCustomListItemClick,
    onAddCustomListButtonClick,
  } = props;

  const handleFavoriteButtonClick = useCallback<NonNullable<AlterButtonProps['onClick']>>(
    (e) => {
      e.stopPropagation();
      onFavoriteButtonClick?.(e);
    },
    [onFavoriteButtonClick]
  );

  return (
    <Dropdown
      value={<AlterButton icon="Save-list" />}
      showArrow={false}
      {...dropdownProps}
      className={cn(styles.root, className, dropdownProps?.className)}
      tooltipData={{
        delayHide: 1000,
        ...dropdownProps?.tooltipData,
      }}
    >
      <DropdownGroup className={cn(styles.dropdownGroup, classes?.dropdownGroup)}>
        <DropdownItem key="favorites">
          <AlterButton
            fluid
            onClick={handleFavoriteButtonClick}
            rightElement={
              <IconToggler kind="favorites" active={Boolean(isFavoriteCreator)} size={24} />
            }
            msg="custom_list_dropdown.items.favorites"
            className={styles.alterButton}
          />
        </DropdownItem>
        {items?.map((item) => (
          <DropdownItem key={item.id}>
            <AlterButton
              fluid
              text={item.name}
              className={styles.alterButton}
              rightElement={<IconToggler kind="custom-list" active={item.active} size={24} />}
              onClick={(e) => {
                e.stopPropagation();
                onCustomListItemClick(item);
              }}
            />
          </DropdownItem>
        ))}
        <DropdownItem key="add" className={styles.marginTop8}>
          <ButtonPreset className={styles.fullWidth}>
            <AlterButton
              fluid
              type="grey"
              msg="custom_list_dropdown.buttons.add"
              className={cn(styles.alterButton, styles.justifyCenter)}
              onClick={onAddCustomListButtonClick}
            />
          </ButtonPreset>
        </DropdownItem>
      </DropdownGroup>
    </Dropdown>
  );
};

export default CustomListDropdown;
export { CustomListDropdown };

// types

type Item = {
  id: string;
  name: string;
  active: boolean;
};

type DropdownProps = React.ComponentProps<typeof Dropdown>;
type AlterButtonProps = React.ComponentProps<typeof AlterButton>;

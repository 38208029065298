import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import styles from './Header.css';

import { getCampaignCreatorsLink } from 'Util/links';
import BackButton from 'Molecules/BackButton/BackButton';

const Header = (props) => {
  const { className, campaign } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const hideBack = location.state?.hideBack;

  if (hideBack || !campaign) return null;

  const { id: campaignId, name, briefSubmission } = campaign;

  const handleClick = () => {
    navigate('/dashboard');
  };

  const title = briefSubmission
    ? { text: name }
    : { msg: 'browse_creators.back', onClick: handleClick };

  const el = !window.isApproval ? <BackButton onClick={handleClick} {...title} /> : null;

  return (
    <div className={`${className} ${styles.root}`}>
      {briefSubmission ? <Link to={getCampaignCreatorsLink({ campaignId })}>{el}</Link> : el}
    </div>
  );
};

export default Header;

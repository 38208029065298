import compact from 'lodash/compact';

import { TRIAL_MONTH_PLAN, SCALE_2021_10_PLAN, ADVANCED_2021_10_PLAN } from 'Constants/general';

const DEFAULT_DAYS = 15;
const MAX_DAYS = 90;

type PsDayItem = {
  id: number;
  value: number;
  titleMsg: string;
  titleValues?: {
    [key: string]: string | number | JSX.Element;
  };
};

type GetPsDaysList = (maxPaidSocialActivationDays?: number | null) => PsDayItem[];
export const getPsDaysList: GetPsDaysList = (maxPaidSocialActivationDays) => {
  const maxDays = maxPaidSocialActivationDays
    ? Math.max(MAX_DAYS, maxPaidSocialActivationDays)
    : MAX_DAYS;
  return [
    {
      id: DEFAULT_DAYS,
      value: DEFAULT_DAYS,
      titleMsg: 'brief.paid_social.up_to',
      titleValues: { days: DEFAULT_DAYS },
    },
    {
      id: 60,
      value: 60,
      titleMsg: 'brief.paid_social.up_to',
      titleValues: { days: 60 },
    },
    {
      id: maxDays,
      value: maxDays,
      titleMsg: 'brief.paid_social.up_to',
      titleValues: { days: maxDays },
    },
  ];
};

type PlanItem = {
  name: string;
};

type DayItem = {
  id: number;
  msg: string;
  msgValues?: {
    [key: string]: string | number | JSX.Element;
  };
  planIds: PlanItem[];
};

type GetDaysList = (
  trialMonthPlanAvailable?: boolean,
  maxPaidSocialActivationDays?: number | null
) => DayItem[];

export const getDays: GetDaysList = (trialMonthPlanAvailable, maxPaidSocialActivationDays) => {
  const maxDays = maxPaidSocialActivationDays
    ? Math.max(MAX_DAYS, maxPaidSocialActivationDays)
    : MAX_DAYS;

  return [
    {
      id: DEFAULT_DAYS,
      msg: 'brief.paid_social.up_to',
      msgValues: { days: DEFAULT_DAYS },
      planIds: compact([
        trialMonthPlanAvailable ? { name: TRIAL_MONTH_PLAN } : null,
        { name: SCALE_2021_10_PLAN },
        { name: ADVANCED_2021_10_PLAN },
      ]),
    },
    {
      id: 60,
      msg: 'brief.paid_social.up_to',
      msgValues: { days: 60 },
      planIds: [{ name: SCALE_2021_10_PLAN }, { name: ADVANCED_2021_10_PLAN }],
    },
    {
      id: maxDays,
      msg: 'brief.paid_social.up_to',
      msgValues: { days: maxDays },
      planIds: [{ name: ADVANCED_2021_10_PLAN }],
    },
  ];
};

/**
 * @generated SignedSource<<3323f0ab81973e9004bcb1cf8b875c35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Result = "FAILURE" | "PENDING" | "SUCCESS" | "%future added value";
export type ResendOfferNotificationsInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
};
export type ResendOfferNotificationsMutation$variables = {
  input: ResendOfferNotificationsInput;
};
export type ResendOfferNotificationsMutation$data = {
  readonly resendOfferNotifications: {
    readonly result: Result;
  } | null | undefined;
};
export type ResendOfferNotificationsMutation = {
  response: ResendOfferNotificationsMutation$data;
  variables: ResendOfferNotificationsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResendOfferNotificationsPayload",
    "kind": "LinkedField",
    "name": "resendOfferNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResendOfferNotificationsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResendOfferNotificationsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "550b1edb0c7dd5934332927959cc1fab",
    "id": null,
    "metadata": {},
    "name": "ResendOfferNotificationsMutation",
    "operationKind": "mutation",
    "text": "mutation ResendOfferNotificationsMutation(\n  $input: ResendOfferNotificationsInput!\n) {\n  resendOfferNotifications(input: $input) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "80cccbd90a5e7c772389726dfe8e0194";

export default node;

import React from 'react';

import styles from './CreatorsStub.pcss';

const CreatorsStub = () => {
  return (
    <div className={styles.root}>
      <div className={styles.items}>
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
      </div>
    </div>
  );
};

export default CreatorsStub;

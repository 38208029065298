import React from 'react';
import track from 'react-tracking';

import styles from './DownloadAppSignIn.pcss';

import DownloadApp from 'Molecules/DownloadApp/DownloadApp';
import Page from 'Templates/Page/Page';

const DownloadAppSignIn = () => {
  return (
    <Page className={styles.root}>
      <DownloadApp />
    </Page>
  );
};

export default track(
  {
    page: 'download_app',
  },
  { dispatchOnMount: true }
)(DownloadAppSignIn);

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import BriefContent from '../BriefContent/BriefContent';

import environment from 'Api/Environment';
import Spinner from 'Atoms/Spinner/Spinner';
import { MobileBriefQuery as QueryItem } from 'GraphTypes/MobileBriefQuery.graphql';

const MobileBriefQuery = graphql`
  query MobileBriefQuery(
    $campaignId: ID!
  ) {
    campaign(id: $campaignId) {
      ...BriefContent_campaign
    }
  }
`;

interface Props {
  mobileView?: boolean,
  campaignId: string,
}



const MobileBrief: React.FC<Props> = props => {
  const { campaignId, mobileView = true } = props;

  return (
    <QueryRenderer<QueryItem>
      environment={environment}
      query={MobileBriefQuery}
      variables={{ campaignId }}
      render={({ props: queryProps }) => {
        if (!queryProps) return <Spinner style={{ margin: '40px auto' }} />;

        const campaign = queryProps.campaign;

        return (
          <div>
            <BriefContent campaign={campaign} mobileView={mobileView} isCreator={mobileView} />
          </div>
        );
      }}
    />
  );
};

export default MobileBrief;
import React, { useState, useMemo } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './WithdrawalContainer.pcss';
import { getDataForSearch } from './data';
import Withdrawals from './WithdrawalList/WithdrawalList';
import WithdrawalFilters, { Filters } from './WithdrawalFilters/WithdrawalFilters';

import environment from 'Api/Environment';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import {
  WithdrawalContainerQuery as QueryType,
  WithdrawalContainerQuery$variables,
} from 'GraphTypes/WithdrawalContainerQuery.graphql';

const WithdrawalContainerQuery = graphql`
  query WithdrawalContainerQuery(
    $dateFrom: DateTime
    $dateTo: DateTime
    $currencies: [Currency!]
    $statuses: [Payment_WithdrawalStatus!]
    $paymentMethodTypes: [Payment_PaymentMethodType!]
    $accountQuery: String
    $profileQuery: String
    $paymentDataQuery: String
    $campaignQuery: String
    $w9WarningLevels: [W9WarningLevel!]
  ) {
    ...WithdrawalList_withdrawals
      @arguments(
        dateFrom: $dateFrom
        dateTo: $dateTo
        currencies: $currencies
        statuses: $statuses
        paymentMethodTypes: $paymentMethodTypes
        accountQuery: $accountQuery
        profileQuery: $profileQuery
        paymentDataQuery: $paymentDataQuery
        campaignQuery: $campaignQuery
        w9WarningLevels: $w9WarningLevels
      )
  }
`;

const defaultParams = {
  dateFrom: null,
  dateTo: null,
  currencies: [],
  statuses: [],
  paymentMethodTypes: [],
  w9WarningLevels: null,
  accountQuery: '',
  profileQuery: '',
  paymentDataQuery: '',
  campaignQuery: '',
};

const WithdrawalContainer = () => {
  const [filters, setFilters] = useState<Filters>(defaultParams);

  const handleFiltersChange = (filters: any) => {
    setFilters(filters);
  };

  const handleParamsClear = () => {
    setFilters(defaultParams);
  };

  const dataForSearch = useMemo(() => {
    return getDataForSearch(filters);
  }, [filters]);

  return (
    <div className={styles.root}>
      <Text type="d2" msg="admin_withdrawals.title" className={styles.title} />
      <WithdrawalFilters
        filters={filters}
        onFiltersChange={handleFiltersChange}
        onFiltersClear={handleParamsClear}
      />
      <QueryRenderer<QueryType>
        environment={environment}
        query={WithdrawalContainerQuery}
        variables={{ ...(dataForSearch as WithdrawalContainerQuery$variables) }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner className={styles.spinner} />;

          return <Withdrawals {...dataForSearch} withdrawals={queryProps} />;
        }}
      />
    </div>
  );
};

export default WithdrawalContainer;

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import styles from './Profiles.css';

import { getCreatorProfileLink } from 'Util/links';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import DropDown from 'Molecules/DropDown/DropDown';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { Profiles_paymentAccount$key } from 'GraphTypes/Profiles_paymentAccount.graphql';

interface Props {
  paymentAccount: Profiles_paymentAccount$key;
}

const Profiles: React.FC<Props> = (props) => {
  const { paymentAccount } = props;

  const data = useFragment(
    graphql`
      fragment Profiles_paymentAccount on Payment_Account {
        owner {
          creators {
            totalCount
          }
          firstCreator: creators(first: 1) {
            edges {
              node {
                id
                username
              }
            }
          }
          allCreators: creators {
            edges {
              node {
                id
                username
              }
            }
          }
        }
      }
    `,
    paymentAccount
  );

  const count = data.owner?.creators?.totalCount;
  const list = data.owner?.firstCreator?.edges;

  if (count === 1 && list && list[0]) {
    const firstCreator = list[0]?.node;
    const id = firstCreator?.id;
    const username = firstCreator?.username;

    return (
      <SimpleLink target="_blank" href={getCreatorProfileLink({ creatorId: id })}>
        <Text color="creamCan" text={username} />
      </SimpleLink>
    );
  }

  const allCreators = data.owner?.allCreators?.edges;

  const profilesAnchor = (
    <div className={styles.link}>
      <Text msg="admin_withdrawals.profiles_count" values={{ count }} />
      <Icon name="Arrow-small-down" />
    </div>
  );

  const creatorsList = compact(
    allCreators?.map((item) => {
      if (!item?.node) return null;

      const { id, username } = item.node;

      return {
        id,
        textData: {
          textData: { text: username },
        },
        link: getCreatorProfileLink({ creatorId: id }),
      };
    })
  );

  return <DropDown anchor={profilesAnchor} list={creatorsList} position="bottomLeft" />;
};

export default Profiles;

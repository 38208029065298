/**
 * @generated SignedSource<<92c9affe0e27f98441304feee5cd80de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type StartProjectPublishingInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
};
export type StartProjectPublishingMutation$variables = {
  input: StartProjectPublishingInput;
};
export type StartProjectPublishingMutation$data = {
  readonly startProjectPublishing: {
    readonly project?: {
      readonly advertiserStage: AdvertiserProjectStage | null | undefined;
    };
  } | null | undefined;
};
export type StartProjectPublishingMutation = {
  response: StartProjectPublishingMutation$data;
  variables: StartProjectPublishingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "advertiserStage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StartProjectPublishingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "startProjectPublishing",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "StartProjectPublishingPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StartProjectPublishingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "startProjectPublishing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "StartProjectPublishingPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "109fe1cf624c6d31c2ffdcad633a8072",
    "id": null,
    "metadata": {},
    "name": "StartProjectPublishingMutation",
    "operationKind": "mutation",
    "text": "mutation StartProjectPublishingMutation(\n  $input: StartProjectPublishingInput!\n) {\n  startProjectPublishing(input: $input) {\n    __typename\n    ... on StartProjectPublishingPayload {\n      project {\n        advertiserStage\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f01174c5c1dc7e6b8de5ba20db1e5f0";

export default node;

import React, { useMemo } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import cn from 'classnames';

import styles from './MarketplaceNavigationItem.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { MARKETPLACE_ROUTE } from 'Constants/general';

type Props = AlterButtonProps;

const MarketplaceNavigationItem: React.FC<Props> = (props) => {
  const { onClick, className, ...rest } = props;
  const location = useLocation();

  const isActive = useMemo<boolean>(() => {
    return /^\/campaigns\/[^/]+\/mediaplan/.test(location.pathname);
  }, [location.pathname]);

  return (
    <NavLink to={MARKETPLACE_ROUTE} className={styles.navLink}>
      <AlterButton
        {...rest}
        className={cn(className, {
          [styles.active]: isActive,
        })}
      />
    </NavLink>
  );
};

export { MarketplaceNavigationItem };
export default MarketplaceNavigationItem;

// types

type AlterButtonProps = React.ComponentProps<typeof AlterButton>;

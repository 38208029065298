import React from 'react';
import classnames from 'classnames';

import TooltipInfo from '../TooltipInfo/TooltipInfo';

import styles from './BenefitList.pcss';

import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  className?: string;
  items?: BenefitItem[];
  tooltipInfoProps?: Pick<Partial<TooltipInfoProps>, 'color'>;
  colorTheme?: 'default' | 'sky' | 'pink' | 'dark' | 'sun' | 'ocean';
}

const BenefitList: React.FC<Props> = (props) => {
  const { className, items, tooltipInfoProps, colorTheme = 'default' } = props;

  if (!items?.length) return null;

  return (
    <ul className={classnames(styles.root, className)}>
      {items.map((item, index) => {
        const { el, title, values, additionalTitle } = item;

        return (
          <li key={`${item?.tooltipProps?.tooltipMsg}+${index}`} className={styles.item}>
            <Icon className={classnames(styles.checkIcon, styles[colorTheme])} name="Check" />
            <div>
              {el}
              {title && <Text msg={title} values={values} display="inline" color="inherit" />}
              {additionalTitle && <Text msg={additionalTitle} display="inline" />}
              {(item?.tooltipProps?.tooltipMsg || item?.tooltipProps?.getContent) && (
                <TooltipInfo
                  color={tooltipInfoProps?.color}
                  className={styles.tooltipInfo}
                  id={item?.tooltipProps?.id}
                  tooltipMsg={item?.tooltipProps?.tooltipMsg}
                  tooltipValues={item?.tooltipProps?.tooltipValues}
                  getContent={item?.tooltipProps?.getContent}
                  clickable={item?.tooltipProps?.clickable}
                  type={colorTheme === 'dark' ? 'light' : undefined}
                />
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default BenefitList;
export type { BenefitListProps };

type BenefitItem = {
  title?: string;
  values?: any;
  additionalTitle?: string;
  el?: JSX.Element;
  tooltipProps?: {
    tooltipMsg?: string;
    id?: TooltipInfoProps['id'];
    tooltipValues?: TooltipInfoProps['tooltipValues'];
    getContent?: TooltipInfoProps['getContent'];
    clickable?: TooltipInfoProps['clickable'];
  };
};

type BenefitListProps = React.ComponentProps<typeof BenefitList>;
type TooltipInfoProps = React.ComponentProps<typeof TooltipInfo>;

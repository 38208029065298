import React from 'react';

import styles from './List.pcss';
import SearchResult from './SearchResult/SearchResult';

import Text from 'Atoms/Text/Text';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { SearchResults_campaign } from 'GraphTypes/SearchResults_campaign.graphql';

interface Props {
  isAdmin: boolean;
  className: string;
  campaignId: string;
  canUsePaidSocial?: boolean;
  isBlockedView?: boolean;
  creatorType?: CreatorType | null;
  searchCreators?: SearchResults_campaign['searchCreators'] | null;
  hasContentCreation: boolean;
  organizationId?: string;
  showUnlockCreatorBanner: () => void;
}

const List: React.FC<Props> = (props) => {
  const {
    isAdmin,
    className,
    creatorType,
    campaignId,
    searchCreators,
    canUsePaidSocial,
    hasContentCreation,
    organizationId,
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const list = searchCreators?.edges;

  return (
    <div className={`${className} ${styles.root}`}>
      {!list || (list.length === 0 && <Text className={styles.noResult} msg="general.no_result" />)}
      {list?.map((item) => {
        if (!item?.node || !item.node.creator) return null;

        const id = item?.node.creator.id;

        return (
          <SearchResult
            key={id}
            isAdmin={isAdmin}
            result={item.node}
            campaignId={campaignId}
            creatorType={creatorType}
            canUsePaidSocial={canUsePaidSocial}
            hasContentCreation={hasContentCreation}
            className={styles.item}
            organizationId={organizationId}
            isBlockedView={isBlockedView}
            showUnlockCreatorBanner={showUnlockCreatorBanner}
          />
        );
      })}
    </div>
  );
};

export default List;

import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import environment from 'Api/Environment';
import {
  CreateBriefCreativeMutation,
  CreateBriefCreativeMutation$data,
  CreateBriefCreativeInput,
} from 'GraphTypes/CreateBriefCreativeMutation.graphql';

const mutation = graphql`
  mutation CreateBriefCreativeMutation($input: CreateBriefCreativeInput!) {
    createBriefCreative(input: $input) {
      brief {
        ... on V2Brief {
          creatives(first: 2147483647) @connection(key: "Creatives_creatives") {
            edges {
              node {
                id
                ...CreativeAsset_briefCreative
              }
            }
            totalCount
          }
        }
      }
      newBriefCreativeEdge {
        node {
          id
          dos
          donts
          postCaption
          mediaType
          publishingRequired
          v2ContentPlacement
          contentFormat
          videoDurationMin
          videoDurationMax
          contentPreparedness
          contentMusic
          contentMusicDescription
          contentType
          v2ContentType
          references(first: 2147483647) @connection(key: "Creative_references") {
            edges {
              node {
                id
                ...Reference_item
              }
            }
          }
          lastReferences: references(first: 3)
            @connection(key: "AssetPreview_lastReferences", filters: []) {
            edges {
              node {
                id
                file {
                  ... on Image {
                    transformations {
                      collageThumbnailUrl
                    }
                  }
                  ... on Video {
                    transformations {
                      collageThumbnailUrl
                    }
                  }
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: CreateBriefCreativeInput,
  resolve?: (response: CreateBriefCreativeMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<CreateBriefCreativeMutation>(environment, {
    mutation,
    variables,
    updater: (store) => {
      const brief = store.get(data.briefId);
      if (!brief) return;
      const creatives = ConnectionHandler.getConnection(brief, 'Creatives_creatives');
      if (!creatives) return;
      const creativeExists = creatives
        ?.getLinkedRecords('edges')
        ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === data.id);
      if (creativeExists) return;

      const creative = store
        .getRootField('createBriefCreative')
        .getLinkedRecord('newBriefCreativeEdge')
        .getLinkedRecord('node');
      const edge = ConnectionHandler.createEdge(
        store,
        creatives,
        creative,
        'BriefCreativeConnection'
      );
      ConnectionHandler.insertEdgeAfter(creatives, edge);
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import compact from 'lodash/compact';

import {
  CONTRACTOR_TERMS_LINK,
  TERMS_LINK,
  DASHBOARD_ROUTE,
  CONTRACTOR_PROFILES_ROUTE,
} from 'Constants/general';

const isProduction = process.env.DEPLOY_ENV === 'production';

export const createMenuList = () => {
  return compact([
    {
      id: 'contractor_campaigns',
      textData: {
        textData: { msg: 'menu.campaigns' },
      },
      innerLink: DASHBOARD_ROUTE,
    },
    {
      id: 'contractor_profiles',
      textData: {
        textData: { msg: 'menu.profiles' },
      },
      innerLink: CONTRACTOR_PROFILES_ROUTE,
    },
    {
      id: 'contractor_transactions',
      textData: {
        textData: { msg: 'menu.transactions' },
      },
      callback: 'download_app_popup',
    },
    {
      id: 'terms_of_use',
      textData: {
        textData: { msg: 'menu.terms_of_use' },
      },
      link: CONTRACTOR_TERMS_LINK,
    },
    !isProduction
      ? {
          id: 'menu.switch_lang',
          textData: {
            textData: { msg: 'menu.switch_lang' },
          },
          callback: 'lang',
        }
      : null,
    {
      id: 'logout',
      textData: {
        textData: { msg: 'menu.sign_out' },
      },
      callback: 'logout',
    },
  ]);
};

/**
 * @generated SignedSource<<3c7a64d773fadb3fae3a087096fc0a20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrganzationsListContainer_organizations$data = {
  readonly organizations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly subscription: {
          readonly endsAt: any | null | undefined;
          readonly planId: PlanId;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly hasNextPage: boolean;
    };
  } | null | undefined;
  readonly " $fragmentType": "OrganzationsListContainer_organizations";
};
export type OrganzationsListContainer_organizations$key = {
  readonly " $data"?: OrganzationsListContainer_organizations$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganzationsListContainer_organizations">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "textQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "organizations"
        ]
      }
    ]
  },
  "name": "OrganzationsListContainer_organizations",
  "selections": [
    {
      "alias": "organizations",
      "args": null,
      "concreteType": "OrganizationConnection",
      "kind": "LinkedField",
      "name": "__Advertiser_organizations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AdvertiserSubscription",
                  "kind": "LinkedField",
                  "name": "subscription",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endsAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0d051a303a19ed30da4dab52c569790a";

export default node;

import type { ButtonsState } from './OptionsGroup';
import type { Option } from './OptionsGroup';

type SetSelectedItemToState = (
  buttonsState: ButtonsState,
  buttonIndex: number,
  isMultipleSelectionEnabled: boolean
) => ButtonsState;
const setSelectedItemToState: SetSelectedItemToState = (
  buttonsState,
  buttonIndex,
  isMultipleSelectionEnabled
) => {
  if (isMultipleSelectionEnabled) {
    return { ...buttonsState, [buttonIndex]: { selected: !buttonsState[buttonIndex]?.selected } };
  }
  const result = { ...buttonsState };
  // eslint-disable-next-line guard-for-in
  for (const key in result) {
    if (+key !== buttonIndex) {
      result[key] = { ...result[key], selected: false };
    }
  }
  result[buttonIndex] = { ...result[buttonIndex], selected: !result[buttonIndex]?.selected };
  return result;
};

type CountSelectedOptions = (buttonsState: ButtonsState) => number;
const countSelectedOptions: CountSelectedOptions = (buttonsState) => {
  let result = 0;
  for (const key in buttonsState) {
    if (buttonsState[key].selected) {
      result += 1;
    }
  }
  return result;
};

type FilterStateBySelectedOptions = (buttonsState: ButtonsState) => ButtonsState;
const filterStateBySelectedOptions: FilterStateBySelectedOptions = (buttonsState) => {
  const result: ButtonsState = {};
  for (const key in buttonsState) {
    if (buttonsState[key].selected) {
      result[key] = { ...buttonsState[key] };
    }
  }
  return result;
};

type InitButtonsState = (options?: Option[]) => ButtonsState;
const initButtonsState: InitButtonsState = (options) => {
  if (!options) return {};
  const result: ButtonsState = {};
  // eslint-disable-next-line guard-for-in
  for (const key in options) {
    result[key] = { selected: options[key]?.selected ?? false };
  }
  return result;
};

export {
  setSelectedItemToState,
  countSelectedOptions,
  filterStateBySelectedOptions,
  initButtonsState,
};

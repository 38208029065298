import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './BulkAddingStatus.pcss';

import { stopCreatorsBulkAdding } from 'Actions/CreatorSearch.Actions';
import { StateProps } from 'Reducers/CreatorsSearch.Reducer';
import Text from 'Atoms/Text/Text';
import UploadProgress from 'Molecules/UploadProgress/UploadProgress';

let tempLoading = false;

interface Props {
  bulkLoading?: boolean;
}

const BulkAddingStatus: React.FC<Props> = (props) => {
  const { bulkLoading } = props;

  const [showSuccess, setShowSuccess] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (bulkLoading) {
      setShowSuccess(false);
    }
    if (!bulkLoading && tempLoading) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    }
    tempLoading = !!bulkLoading;
  }, [bulkLoading]);

  const totalCount = useSelector((state: StateProps) => state.root.creatorSearch.count);
  const creatorIdsForAdding = useSelector(
    (state: StateProps) => state.root.creatorSearch.creatorIdsForAdding
  );

  if (!bulkLoading && !showSuccess) return null;

  const restCount = totalCount - (creatorIdsForAdding.length || 0);

  const handleUploadCancel = () => {
    dispatch(stopCreatorsBulkAdding());
  };

  return (
    <div>
      {showSuccess && !bulkLoading && (
        <div className={styles.success}>
          <Text color="white" weight="500" msg="mediaplan.bulk_uploading_succeess.title" />
        </div>
      )}
      {bulkLoading && (
        <UploadProgress
          progressWidth={320}
          totalCount={totalCount}
          restCount={restCount}
          title="mediaplan.bulk_uploading.title"
          onClose={handleUploadCancel}
        />
      )}
    </div>
  );
};

export default BulkAddingStatus;

import React from 'react';

import unarchiveProject from 'Api/mutations/UnarchiveProject.Mutation';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  isAdmin: boolean;
  projectId: string;
}

const ArchivedStage: React.FC<Props> = (props) => {
  const { isAdmin, projectId } = props;

  const handleUarchiveClick = () => {
    if (projectId) {
      unarchiveProject({ projectId, isAdmin });
    }
  };

  return (
    <AlterButton
      type="grey"
      icon="Arrows-left-right"
      msg="chat.project.unarchive"
      onClick={handleUarchiveClick}
    />
  );
};

export default ArchivedStage;

/**
 * @generated SignedSource<<3c18f511acc273917b5cbf2fb8a51a6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateBulkShopifyOrderInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  lineItems: ReadonlyArray<Shopify_LineItemInput>;
  projectIds: ReadonlyArray<string>;
};
export type Shopify_LineItemInput = {
  productVariantId: string;
  quantity: number;
};
export type CreateBulkShopifyOrderMutation$variables = {
  input: CreateBulkShopifyOrderInput;
};
export type CreateBulkShopifyOrderMutation$data = {
  readonly createBulkShopifyOrder: {
    readonly __typename: "CreateBulkShopifyOrderPayload";
    readonly bulkShopifyOrder: {
      readonly campaign: {
        readonly canUpdateShopifyAuthorization: boolean;
        readonly organization: {
          readonly id: string;
          readonly shopifyAuthorizations: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly reauthenticationRequired: boolean;
                readonly shop: {
                  readonly name: string;
                };
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly shopifyAuthorization: {
          readonly id: string;
          readonly reauthenticationRequired: boolean;
          readonly shop: {
            readonly name: string;
            readonly url: string;
          };
        } | null | undefined;
      };
      readonly id: string;
    };
  } | {
    readonly __typename: "NotFoundError";
    readonly message: string;
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CreateBulkShopifyOrderMutation = {
  response: CreateBulkShopifyOrderMutation$data;
  variables: CreateBulkShopifyOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdateShopifyAuthorization",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reauthenticationRequired",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v9 = {
  "kind": "InlineFragment",
  "selections": (v8/*: any*/),
  "type": "ValidationError",
  "abstractKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": (v8/*: any*/),
  "type": "NotFoundError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateBulkShopifyOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createBulkShopifyOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BulkShopifyOrder",
                "kind": "LinkedField",
                "name": "bulkShopifyOrder",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopifyAuthorization",
                        "kind": "LinkedField",
                        "name": "shopifyAuthorization",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Shopify_Shop",
                            "kind": "LinkedField",
                            "name": "shop",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopifyAuthorizationConnection",
                            "kind": "LinkedField",
                            "name": "shopifyAuthorizations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShopifyAuthorizationEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ShopifyAuthorization",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Shopify_Shop",
                                        "kind": "LinkedField",
                                        "name": "shop",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CreateBulkShopifyOrderPayload",
            "abstractKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateBulkShopifyOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createBulkShopifyOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BulkShopifyOrder",
                "kind": "LinkedField",
                "name": "bulkShopifyOrder",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopifyAuthorization",
                        "kind": "LinkedField",
                        "name": "shopifyAuthorization",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Shopify_Shop",
                            "kind": "LinkedField",
                            "name": "shop",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopifyAuthorizationConnection",
                            "kind": "LinkedField",
                            "name": "shopifyAuthorizations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShopifyAuthorizationEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ShopifyAuthorization",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Shopify_Shop",
                                        "kind": "LinkedField",
                                        "name": "shop",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CreateBulkShopifyOrderPayload",
            "abstractKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "800b0295513173c02111f0e73f57b138",
    "id": null,
    "metadata": {},
    "name": "CreateBulkShopifyOrderMutation",
    "operationKind": "mutation",
    "text": "mutation CreateBulkShopifyOrderMutation(\n  $input: CreateBulkShopifyOrderInput!\n) {\n  createBulkShopifyOrder(input: $input) {\n    __typename\n    ... on CreateBulkShopifyOrderPayload {\n      bulkShopifyOrder {\n        id\n        campaign {\n          canUpdateShopifyAuthorization\n          shopifyAuthorization {\n            reauthenticationRequired\n            shop {\n              name\n              url\n              id\n            }\n            id\n          }\n          organization {\n            id\n            shopifyAuthorizations {\n              edges {\n                node {\n                  id\n                  reauthenticationRequired\n                  shop {\n                    name\n                    id\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    ... on ValidationError {\n      message\n    }\n    ... on NotFoundError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "354658d3f9214b80d891924c546f15df";

export default node;

export const reasons = [
  { msg: 'archive_project_request_modal.reason.product_issues' },
  { msg: 'archive_project_request_modal.reason.not_seeding' },
  { msg: 'archive_project_request_modal.reason.price' },
  { msg: 'archive_project_request_modal.reason.deadline' },
  { msg: 'archive_project_request_modal.reason.no_purchase' },
  { msg: 'archive_project_request_modal.reason.personal' },
  { msg: 'archive_project_request_modal.reason.unresponsive' },
  { msg: 'archive_project_request_modal.reason.not_follow_brief' },
  { msg: 'archive_project_request_modal.reason.canceled' },
  { msg: 'archive_project_request_modal.reason.shipment_issues' },
  { msg: 'archive_project_request_modal.reason.mistake' },
  { msg: 'archive_project_request_modal.reason.hired_another_campaign' },
  { msg: 'archive_project_request_modal.reason.low_quality' },
  { msg: 'archive_project_request_modal.reason.other' },
];

/**
 * @generated SignedSource<<6cdae9e89ff0b2f800a3b84a92670638>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Brief_ScreeningQuestion_Type = "BINARY" | "OPEN_ENDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScreeningQuestionsFilter_campaign$data = {
  readonly brief: {
    readonly screeningQuestions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly preferredValue: boolean | null | undefined;
          readonly text: string;
          readonly type: Brief_ScreeningQuestion_Type;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ScreeningQuestionsFilter_campaign";
};
export type ScreeningQuestionsFilter_campaign$key = {
  readonly " $data"?: ScreeningQuestionsFilter_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScreeningQuestionsFilter_campaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScreeningQuestionsFilter_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BriefScreeningQuestionConnection",
              "kind": "LinkedField",
              "name": "screeningQuestions",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefScreeningQuestionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BriefScreeningQuestion",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "text",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "preferredValue",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "4c50a957ac3d3a06ec7bbce8528fa482";

export default node;

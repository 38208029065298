import {
  YOUTUBE,
  INSTAGRAM,
  TIKTOK,
} from 'Constants/general';


export const getMarks = (type) => {
  if (type === INSTAGRAM) {
    return [
      {
        id: 'followedByCount',
        format: 'shortNumber',
        msg: 'followers',
      },
      {
        id: 'engagementRate',
        format: 'percent',
        msg: 'er_short',
      },
      {
        id: 'estimatedLikes',
        format: 'shortNumber',
        msg: 'estimatedLikes',
      },
    ];
  } else if (type === TIKTOK) {
    return [
      {
        id: 'followedByCount',
        format: 'shortNumber',
        msg: 'followers',
      },
      {
        id: 'engagementRate',
        format: 'percent',
        msg: 'er_short',
      },
    ];
  } else if (type === YOUTUBE) {
    return [
      {
        id: 'subscribers',
        format: 'shortNumber',
        msg: 'subscribers',
      },
    ];
  }

  return [];
};
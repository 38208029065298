import { commitMutation, graphql } from 'react-relay';
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes';
import { ConnectionHandler } from 'relay-runtime';

import {
  CreateCustomListMutation,
  CreateCustomListMutation$data,
  CreateCustomListInput,
  CreateCustomListMutation$variables,
} from 'GraphTypes/CreateCustomListMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation CreateCustomListMutation($input: CreateCustomListInput!) {
    createCustomList(input: $input) {
      __typename
      ... on CreateCustomListPayload {
        customList {
          name
          id
        }
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: CreateCustomListInput,
  resolve?: (response: CreateCustomListMutation$data) => void,
  reject?: FnType
) => {
  const { organizationId } = data;
  const variables: CreateCustomListMutation$variables = {
    input: {
      ...data,
    },
  };

  const updater: SelectorStoreUpdater<CreateCustomListMutation$data> = (store, response) => {
    const organization = store.get(organizationId);
    const currentUserCustomLists = store
      .getRoot()
      ?.getLinkedRecord('currentUser')
      ?.getLinkedRecord('organization')
      ?.getLinkedRecord('customLists');

    const responseData =
      response?.createCustomList?.__typename === 'CreateCustomListPayload' &&
      response?.createCustomList?.customList
        ? response.createCustomList.customList
        : null;

    if (currentUserCustomLists && responseData) {
      const record = responseData.id && store.get(responseData.id);
      if (record) {
        const edge = ConnectionHandler.createEdge(
          store,
          currentUserCustomLists,
          record,
          'CustomListEdge'
        );
        ConnectionHandler.insertEdgeBefore(currentUserCustomLists, edge);
      }
    }

    if (organization && responseData) {
      const customListsConnection = ConnectionHandler.getConnection(
        organization,
        'Organization_customLists'
      );

      if (customListsConnection) {
        const record = store.get(responseData.id || '');

        if (record) {
          const edge = ConnectionHandler.createEdge(
            store,
            customListsConnection,
            record,
            'CustomListEdge'
          );
          ConnectionHandler.insertEdgeBefore(customListsConnection, edge);
        }
      }
    }
  };

  commitMutation<CreateCustomListMutation>(environment, {
    mutation,
    variables,
    updater,
    optimisticUpdater: updater,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

export type {
  CreateCustomListMutation,
  CreateCustomListMutation$data,
  CreateCustomListInput,
  CreateCustomListMutation$variables,
};

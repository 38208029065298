import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import { useIntl } from 'react-intl';

import Spinner from 'Atoms/Spinner/Spinner';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { OrganzationsListContainer_organizations } from 'GraphTypes/OrganzationsListContainer_organizations.graphql';

const COUNT = 10;

interface Props {
  relay: RelayPaginationProp,
  menu: any,
  textQuery?: string,
  organizations: OrganzationsListContainer_organizations,
}

const OrganzationsListContainer: React.FC<Props> = props => {
  const { relay, menu, organizations } = props;

  const intl = useIntl();

  if (!organizations) return null;

  const items = organizations.organizations?.edges;

  if (!items || items.length === 0) return null;

  const style = { width: '100%' };

  const itemsList = items.map(item => {
    if (!item?.node) return null;
    const { id, name } = item.node;
    const planId = item.node.subscription?.planId.toLowerCase();
    const planValue = intl.formatMessage({ id: `plan.admin_name.${planId}` });

    return { node: { id, name, fullName: planValue } };
  });


  return (
    <LoaderHandler
      relay={relay}
      count={COUNT}
      offset={50}
      preloader={<Spinner size="sm" style={{ margin: '12px auto' }} />}
      containerId="organizationsMenu"
    >
      {React.cloneElement(menu, { items: itemsList, style, withLoading: true })}
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  OrganzationsListContainer,
  {
    organizations: graphql`
      fragment OrganzationsListContainer_organizations on Query
      @argumentDefinitions(
        count: {type: "Int", defaultValue: 20}
        cursor: {type: "String"}
        textQuery: {type: "String"}
      ) {
        organizations(
          first: $count
          after: $cursor
          textQuery: $textQuery
        ) @connection(key: "Advertiser_organizations", filters: []) {
          edges {
            node {
              id
              name
              subscription {
                planId
                endsAt
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organizations && props.organizations.organizations;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,

      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        textQuery: props.textQuery,
      };
    },
    query: graphql`
      query OrganzationsListContainerPaginationQuery(
        $count: Int!
        $cursor: String
        $textQuery: String!
      ) {
        ...OrganzationsListContainer_organizations @arguments(
          count: $count,
          cursor: $cursor,
          textQuery: $textQuery,
        )
      }
    `
  }
);

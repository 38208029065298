import React from 'react';
import compact from 'lodash/compact';

import { createShortNumber, createPercent, createSum } from 'Util/numberFormatter';
import { compareDates } from 'Util/dateCreator';
import { INSTAGRAM, YOUTUBE, TIKTOK } from 'Constants/general';
import { CreatorInfo_creator } from 'GraphTypes/CreatorInfo_creator.graphql';
import { Currency } from 'GraphTypes/MediaplanItemInfoQuery.graphql';

type DataType = {
  creator: CreatorInfo_creator;
  price?: number | null;
  currency?: Currency | null;
  admin?: boolean;
};

const enumerationList = (list: { name: string }[] = []) => {
  const names = list.map((item) => {
    return item.name;
  });

  return names.join(', ');
};

const createlinkShortcut = (link: string) => {
  try {
    const url = new URL(link);

    return (
      <a href={link} target="_blank">
        {url.hostname}
      </a>
    );
  } catch {
    return (
      <a href={link} target="_blank">
        Link
      </a>
    );
  }
};

export const generateCreatorData = (data: DataType) => {
  const price = data.price;
  const currency = data.currency;

  const admin = data.admin;

  const creator = data.creator;
  const type = creator.type;
  const profile = creator.profile;

  const countries = profile?.countries || [];
  const cities = profile?.cities || [];
  const portfolioUrl = profile?.portfolioLink;

  const location = enumerationList([...cities, ...countries]);

  const user = creator.user || creator.tiktokUser;
  const generalStatistic = profile?.generalStatistic;

  let er = user?.engagementRate || generalStatistic?.engagementRate;

  const statUpdateDate =
    data.creator.profile?.socialdataCollectionOperation?.updatedAt ||
    data.creator.profile?.influenceyeCollectionOperation?.updatedAt;

  const userInsightsData = data.creator.userInsightsData;

  const followers = userInsightsData?.followers;
  const followersDelta = userInsightsData?.followersDelta;
  const impressions = userInsightsData?.impressions;
  const reach = userInsightsData?.reach;
  const websiteClicks = userInsightsData?.websiteClicks;

  const delta = Number(followersDelta) / (Number(followers) - Number(followersDelta));

  if (type === 'YOUTUBE' || type === 'INSTAGRAM') {
    if (statUpdateDate) {
      if (compareDates(statUpdateDate, '2021-04-07T17:00:00+03:00', true)) {
        er = Number(er) / 100;
      }
    }
  }
  if (type === 'TIKTOK' && creator.tiktokUser?.engagementRate) {
    er = creator.tiktokUser?.engagementRate;
  }

  const avgComments = user?.averageComments || generalStatistic?.commentsPerPost;
  const avgLikes = generalStatistic?.likesPerPost || user?.averageLikes;
  const avgViews = generalStatistic?.viewsPerPost || user?.averageViews;
  const avgDislikes = generalStatistic?.dislikesPerPost;
  const viewsMedian = generalStatistic?.viewsMedian;

  const priceValue = price || profile?.estimatedPrice;

  const cpe = avgLikes && priceValue ? priceValue / avgLikes : null;
  const cpv = avgViews && priceValue ? priceValue / avgViews : null;

  if (type === INSTAGRAM) {
    const followedByCount = user?.followedByCount;

    const finalEr = er;

    return compact([
      !followers && followedByCount
        ? {
            value: createShortNumber(followedByCount),
            title: 'creator_statistic.subtitles_followers',
            tooltipMsg: 'creator_statistic.subtitles_followers_tooltip',
          }
        : undefined,
      followers
        ? {
            title: 'creator_statistic.subtitles.creator.followers',
            tooltipMsg: 'creator_statistic.subtitles.creator.followers.tooltip',
            value: createShortNumber(followers),
            additionalValue: followersDelta ? createPercent(delta) : undefined,
          }
        : null,
      reach
        ? {
            title: 'creator_statistic.subtitles.creator.reach',
            tooltipMsg: 'creator_statistic.subtitles.creator.reach.tooltip',
            value: createShortNumber(reach),
          }
        : null,
      impressions
        ? {
            title: 'creator_statistic.subtitles.creator.impressions',
            tooltipMsg: 'creator_statistic.subtitles.creator.impressions.tooltip',
            value: createShortNumber(impressions),
          }
        : null,
      location
        ? {
            value: location,
            title: 'creator_statistic.subtitles_location',
            tooltipMsg: 'creator_statistic.subtitles_location_tooltip',
          }
        : null,
      finalEr
        ? {
            title: 'creator_statistic.subtitles_er',
            tooltipMsg: 'creator_statistic.subtitles_er_tooltip',
            value: createPercent(finalEr),
          }
        : null,
      avgComments
        ? {
            title: 'creator_statistic.subtitles_averageComments',
            tooltipMsg: 'creator_statistic.subtitles_averageComments_tooltip',
            value: createShortNumber(avgComments),
          }
        : null,
      websiteClicks
        ? {
            title: 'creator_statistic.subtitles.creator.website_clicks',
            tooltipMsg: 'creator_statistic.subtitles.creator.website_clicks.tooltip',
            value: createShortNumber(websiteClicks),
          }
        : null,
      avgLikes
        ? {
            title: 'creator_statistic.subtitles_averageLikes',
            tooltipMsg: 'creator_statistic.subtitles_averageLikes_tooltip',
            value: createShortNumber(avgLikes),
          }
        : null,
      cpe && currency
        ? {
            title: 'creator_statistic.subtitles_cpe',
            tooltipMsg: 'creator_statistic.subtitles_cpe_tooltip',
            value: createSum(cpe, currency, 0, '0,0.[00]'),
          }
        : null,
      portfolioUrl
        ? {
            title: 'creator_statistic.subtitles_portfolio',
            tooltipMsg: 'creator_statistic.subtitles_portfolio_tooltip',
            value: createlinkShortcut(portfolioUrl),
          }
        : null,
    ]);
  } else if (type === YOUTUBE) {
    const subscribers = creator.channel?.subscribers;

    return compact([
      subscribers
        ? {
            value: createShortNumber(subscribers),
            title: 'creator_statistic.subtitles_subscribers',
            tooltipMsg: 'creator_statistic.subtitles_subscribers_tooltip',
          }
        : null,
      location
        ? {
            value: location,
            title: 'creator_statistic.subtitles_location',
            tooltipMsg: 'creator_statistic.subtitles_location_tooltip',
          }
        : null,
      er
        ? {
            title: 'creator_statistic.subtitles_er',
            tooltipMsg: 'creator_statistic.subtitles_er_tooltip',
            value: createPercent(er),
          }
        : null,
      avgViews
        ? {
            title: 'creator_statistic.subtitles_averageViews',
            tooltipMsg: 'creator_statistic.subtitles_averageViews_tooltip',
            value: createShortNumber(avgViews),
          }
        : null,
      avgComments
        ? {
            title: 'creator_statistic.subtitles_averageComments',
            tooltipMsg: 'creator_statistic.subtitles_averageComments_tooltip',
            value: createShortNumber(avgComments),
          }
        : null,
      avgLikes
        ? {
            title: 'creator_statistic.subtitles_averageLikes',
            tooltipMsg: 'creator_statistic.subtitles_averageLikes_tooltip',
            value: createShortNumber(avgLikes),
          }
        : null,
      avgDislikes
        ? {
            title: 'creator_statistic.subtitles_averageDisikes_video',
            tooltipMsg: 'creator_statistic.subtitles_averageDisikes_video_tooltip',
            value: createShortNumber(avgDislikes),
          }
        : null,
      portfolioUrl
        ? {
            title: 'creator_statistic.subtitles_portfolio',
            tooltipMsg: 'creator_statistic.subtitles_portfolio_tooltip',
            value: createlinkShortcut(portfolioUrl),
          }
        : null,
    ]);
  } else if (type === TIKTOK) {
    const tiktokUser = creator.tiktokUser;
    const followedByCount = tiktokUser?.followedByCount;
    const avgCommentsValue = tiktokUser?.averageComments || avgComments;
    const avgLikesValue = tiktokUser?.averageLikes || avgLikes;
    const avgViewsValue = tiktokUser?.averageViews || avgViews;
    const medianViewsValue = tiktokUser?.medianViews || viewsMedian;

    return compact([
      followedByCount
        ? {
            value: createShortNumber(followedByCount),
            title: 'creator_statistic.subtitles_followers',
            tooltipMsg: 'creator_statistic.subtitles_followers_tooltip',
          }
        : undefined,
      location
        ? {
            value: location,
            title: 'creator_statistic.subtitles_location',
            tooltipMsg: 'creator_statistic.subtitles_location_tooltip',
          }
        : null,
      er
        ? {
            title: 'creator_statistic.subtitles_er',
            tooltipMsg: 'creator_statistic.subtitles_er_tooltip',
            value: createPercent(er),
          }
        : null,
      avgCommentsValue
        ? {
            title: 'creator_statistic.subtitles_averageComments',
            tooltipMsg: 'creator_statistic.subtitles_averageComments_tooltip',
            value: createShortNumber(avgCommentsValue),
          }
        : null,
      avgLikesValue
        ? {
            title: 'creator_statistic.subtitles_averageLikes',
            tooltipMsg: 'creator_statistic.subtitles_averageLikes_tooltip',
            value: createShortNumber(avgLikesValue),
          }
        : null,
      avgViewsValue
        ? {
            title: 'creator_statistic.subtitles_averageViews',
            tooltipMsg: 'creator_statistic.subtitles_averageViews_tooltip',
            value: createShortNumber(avgViewsValue),
          }
        : null,
      cpe && currency
        ? {
            title: 'creator_statistic.subtitles_cpe',
            tooltipMsg: 'creator_statistic.subtitles_cpe_tooltip',
            value: createSum(cpe, currency, 0, '0,0.[00]'),
          }
        : null,
      cpv && currency
        ? {
            title: 'creator_statistic.subtitles_cpv',
            tooltipMsg: 'creator_statistic.subtitles_cpv_tooltip',
            value: createSum(cpe, currency, 0, '0,0.[00]'),
          }
        : null,
      medianViewsValue
        ? {
            title: 'creator_statistic.subtitles_median_views',
            tooltipMsg: 'creator_statistic.subtitles_median_views_tooltip',
            value: createShortNumber(medianViewsValue),
          }
        : null,
      portfolioUrl
        ? {
            title: 'creator_statistic.subtitles_portfolio',
            tooltipMsg: 'creator_statistic.subtitles_portfolio_tooltip',
            value: createlinkShortcut(portfolioUrl),
          }
        : null,
    ]);
  }

  return [];
};

type BarChartsType = {
  creator: CreatorInfo_creator;
};

export const generateBarCharts = (data: BarChartsType) => {
  const type = data.creator.type;
  const profile = data.creator.profile;

  if (type === INSTAGRAM) {
    const mentions = profile?.mentions.map((item) => {
      return { ...item, name: `@${item.name}` };
    });

    const brands = profile?.postBrands.map((item) => {
      return { ...item, name: item.name };
    });

    const hashtags = profile?.hashtags.map((item) => {
      return { ...item, name: `#${item.name}` };
    });

    return compact([
      hashtags
        ? {
            title: 'creator_statistic.section_top_hashtags',
            tooltipMsg: 'creator_statistic.section_top_hashtags_tooltip',
            items: hashtags,
          }
        : null,
      mentions
        ? {
            title: 'creator_statistic.section_brand_mentions',
            tooltipMsg: 'creator_statistic.section_brand_mentions_tooltip',
            items: mentions,
          }
        : null,
      brands
        ? {
            title: 'creator_statistic.section_top_brands',
            // tooltipMsg: 'creator_statistic.section_brand_mentions_tooltip',
            items: brands,
          }
        : null,
    ]);
  } else if (type === TIKTOK) {
    const hashtags = profile?.hashtags.map((item) => {
      return { ...item, name: `#${item.name}` };
    });

    return compact([
      hashtags
        ? {
            title: 'creator_statistic.section_top_hashtags',
            tooltipMsg: 'creator_statistic.section_top_hashtags_tooltip',
            items: hashtags,
          }
        : null,
    ]);
  }

  return [];
};

import React from 'react';

import Tag from 'Components/ui/Tag/Tag';
import TextButton from 'Components/ui/TextButton/TextButton';

interface Props {
  id: string;
  title?: string;
  onDelete: (id: string) => void;
}

const SelectedOption: React.FC<Props> = (props) => {
  const { id, title, onDelete } = props;

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <Tag
      text={title}
      color="dark"
      buttonPosition="right"
      needMargin={false}
      button={<TextButton color="white" size="xs" icon="Close-small" onClick={handleDelete} />}
    />
  );
};

export default SelectedOption;

import React from 'react';
import classnames from 'classnames';

import styles from './NewNotification.pcss';

import modsClasses from 'Util/modsClasses';
import Text from 'Atoms/Text/Text';

const MODS = ['size'];
const MAX_COUNT = 99;

interface Props {
  count?: number;
  isActive?: boolean;
  isLimited?: boolean;
  isDisabled?: boolean;
  className?: string;
  size?: string;
}

const NewNotification: React.FC<Props> = (props) => {
  const { count, isActive, isDisabled, isLimited, size } = props;

  const classes = modsClasses(MODS, props, styles);

  const textColor = isDisabled ? 'grey50' : isActive ? 'white' : 'dark40';

  const countText = isLimited && count && count > MAX_COUNT ? `${MAX_COUNT}+` : count;

  const textTag = size === 'md' ? 'h5' : 'h6';

  return (
    <div className={classnames(`${classes} ${styles.root}`, { [styles.isActive]: isActive })}>
      <Text text={countText} color={textColor} tag={textTag} />
    </div>
  );
};

export default NewNotification;

/**
 * @generated SignedSource<<b738900b51b9aaf7ea27c406c4ef2839>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currency"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AgeRange",
  "kind": "LinkedField",
  "name": "ageRanges",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Interest",
  "kind": "LinkedField",
  "name": "interests",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "countries",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "currency",
      "value": "RUB"
    }
  ],
  "concreteType": "Gender",
  "kind": "LinkedField",
  "name": "genders",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": "genders(currency:\"RUB\")"
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "visible",
      "value": true
    }
  ],
  "concreteType": "Ethnicity",
  "kind": "LinkedField",
  "name": "ethnicities",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": "ethnicities(visible:true)"
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "categories",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "currency",
      "variableName": "currency"
    }
  ],
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Animal",
  "kind": "LinkedField",
  "name": "animals",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "HairType",
  "kind": "LinkedField",
  "name": "hairTypes",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Language",
  "kind": "LinkedField",
  "name": "languages",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "BodyType",
  "kind": "LinkedField",
  "name": "bodyTypes",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badges",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomListConnection",
  "kind": "LinkedField",
  "name": "customLists",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomListEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomList",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchFormQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchFormQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3d2b7a858a1e0b5e32788e71840f1eb",
    "id": null,
    "metadata": {},
    "name": "SearchFormQuery",
    "operationKind": "query",
    "text": "query SearchFormQuery(\n  $currency: Currency\n) {\n  ageRanges {\n    id\n    name\n  }\n  interests {\n    id\n    name\n  }\n  countries {\n    id\n    name\n  }\n  genders(currency: RUB) {\n    id\n    name\n  }\n  ethnicities(visible: true) {\n    id\n    name\n  }\n  categories {\n    id\n    name\n  }\n  tags(currency: $currency) {\n    id\n    name\n  }\n  animals {\n    id\n    name\n  }\n  hairTypes {\n    id\n    name\n  }\n  languages {\n    id\n    name\n  }\n  bodyTypes {\n    id\n    name\n  }\n  badges {\n    id\n    name\n  }\n  currentUser {\n    organization {\n      customLists {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "be189528103e2a56b385cd163f97dda3";

module.exports = node;

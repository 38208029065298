/**
 * @generated SignedSource<<7c50c05576a60b29f0033ad89a7e05d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectEventsList_project$data = {
  readonly project: {
    readonly campaign: {
      readonly type: CampaignType | null | undefined;
    };
    readonly deadline: {
      readonly draftDate: any | null | undefined;
    } | null | undefined;
    readonly events: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: string;
          readonly createdAt: any;
          readonly id: string;
          readonly readByAdvertiser: boolean;
          readonly " $fragmentSpreads": FragmentRefs<"ContentReviewSubmission_event" | "MessageEvent_event" | "OfferAcceptanceEvent_event" | "PostPublication_event" | "SystemProjectEvent_event">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "ProjectEventsList_project";
};
export type ProjectEventsList_project$key = {
  readonly " $data"?: ProjectEventsList_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectEventsList_project">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project",
  "events"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeDeleted"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "last",
        "cursor": "before",
        "direction": "backward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": null,
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ProjectEventsPaginationList.graphql')
    }
  },
  "name": "ProjectEventsList_project",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Deadline",
          "kind": "LinkedField",
          "name": "deadline",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "draftDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "events",
          "args": null,
          "concreteType": "ProjectEventConnection",
          "kind": "LinkedField",
          "name": "__ProjectEventsList_events_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectEventEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "readByAdvertiser",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "SystemProjectEvent_event"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "MessageEvent_event"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ContentReviewSubmission_event"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "PostPublication_event"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "OfferAcceptanceEvent_event"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "988b424f7f2da6c6f419c97c3a1d71fe";

export default node;

import React, { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';

import PostsSection from '../PostsSection/PostsSection';
import PostsList from '../PostsList/PostsList';

import { YOUTUBE, TIKTOK } from 'Constants/general';
import { SponsoredPosts_creator$key } from 'GraphTypes/SponsoredPosts_creator.graphql';

interface Props {
  creator?: SponsoredPosts_creator$key;
  onHideTab: (tab: string) => void;
}

const SponsoredPosts: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment SponsoredPosts_creator on Creator {
        type
        profile {
          topSponsoredPosts {
            link
            thumbnailUrl
            viewCount
            mediaType
            likeCount
            dislikeCount
            commentCount
            description
          }
        }
      }
    `,
    creator
  );

  const topPosts = data?.profile?.topSponsoredPosts;
  const onHideTab = props.onHideTab;

  useEffect(() => {
    if (!topPosts || topPosts.length === 0) {
      onHideTab('sponsored_posts');
    }
  }, []);

  if (!topPosts || topPosts.length === 0) return null;

  const isYoutube = data?.type === YOUTUBE;
  const isTiktok = data?.type === TIKTOK;

  return (
    <PostsSection title="creator.top_sponsored_posts" scrollName="topSponsoredPosts">
      <PostsList list={topPosts} isYoutube={isYoutube} isTiktok={isTiktok} />
    </PostsSection>
  );
};

export default SponsoredPosts;

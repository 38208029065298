import remove from 'lodash/remove';

import {
  ADD_CREATORS_TO_BULK,
  ADD_CREATOR_FOR_EXLUSION,
  REMOVE_CREATOR_FOR_EXLUSION,
  REMOVE_CREATOR_FROM_BULK,
  TOGGLE_CREATORS_BULK_ADDING,
  CLEAR_BULK_CREATORS_DATA,
  SET_CREATORS_BULK_COUNT,
  STOP_CREATORS_BULK_ADDING,
} from 'Constants/index';

export interface StateProps {
  root: {
    creatorSearch: CreatorSearchStateProps,
  }
}

interface CreatorSearchStateProps {
  creatorIdsForExclusion: { creatorId: string, username: string }[],
  creatorIdsForAdding: string[],
  startProcess: boolean,
  count: number,
}

const initialState: CreatorSearchStateProps = {
  creatorIdsForExclusion: [],
  creatorIdsForAdding: [],
  startProcess: false,
  count: 0,
};

interface ActionProps {
  type: string,
  payload: any,
}

const creatorSearch = (state = initialState, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_CREATORS_TO_BULK: {
      const { creatorIds } = payload;
      const newList = new Set([...state.creatorIdsForAdding, ...creatorIds]);

      return {
        ...state,
        creatorIdsForAdding: [...newList],
      };
    }

    case REMOVE_CREATOR_FROM_BULK: {
      const creatorId = payload;
      const newList = [...state.creatorIdsForAdding];
      remove(newList, item => item === creatorId);

      return {
        ...state,
        creatorIdsForAdding: newList,
      };
    }

    case ADD_CREATOR_FOR_EXLUSION: {
      const { creatorId, username } = payload;
      const newList = [...state.creatorIdsForExclusion, { creatorId, username }];

      return {
        ...state,
        creatorIdsForExclusion: newList,
      };
    }

    case REMOVE_CREATOR_FOR_EXLUSION: {
      const { creatorId } = payload;
      const newList = [...state.creatorIdsForExclusion];
      remove(newList, item => item.creatorId === creatorId);

      return {
        ...state,
        creatorIdsForExclusion: newList,
      };
    }

    case STOP_CREATORS_BULK_ADDING: {
      return {
        ...state,
        startProcess: false,
        creatorIdsForAdding: [],
        count: 0,
      };
    }

    case TOGGLE_CREATORS_BULK_ADDING: {
      return {
        ...state,
        startProcess: !!payload,
      };
    }

    case SET_CREATORS_BULK_COUNT: {
      const newCount = payload === 0 ? 0 : state.count + payload;

      return {
        ...state,
        count: newCount,
      };
    }

    case CLEAR_BULK_CREATORS_DATA: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default creatorSearch;
import React from 'react';

import styles from './Spinner.pcss';

import modsClasses from 'Util/modsClasses.js';
import colors from 'Styles/vars/colors.css';

const MODS = ['size'];

interface Props {
  className?: string;
  style?: object;
  color?: string;
  size?: string;
}

const Spinner: React.FC<Props> = (props) => {
  const { style, color = colors.colorDark100 } = props;

  const classes = modsClasses(MODS, props, styles);

  return (
    <div style={style} className={`${classes} ${styles.root}`}>
      <svg className={styles.rolling} viewBox="22 22 44 44">
        <circle cx="44" cy="44" r="20.2" fill="none" stroke={color} strokeWidth="3.6" />
      </svg>
    </div>
  );
};

export default Spinner;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTracking } from 'react-tracking';

import PortfolioItem from '../PortfolioItem/PortfolioItem';

import styles from './PortfolioGroup.pcss';

import { setModal } from 'Actions/Modal.Actions';
import { MEDIA } from 'Constants/modals';
import { MediaType } from 'Types/modals';
import { PortfolioItem_portfolioItem } from 'GraphTypes/PortfolioItem_portfolioItem.graphql';
import Text from 'Components/ui/Text/Text';

type Props = {
  group: {
    items: PortfolioItem_portfolioItem[];
    type: {
      name: string | null;
    };
  };
};

const PortfolioGroup = (props: Props) => {
  const { group } = props;

  const dispatch = useDispatch();
  const tracking = useTracking();

  if (!group.items?.length) return null;

  const handleClickMedia = (attach: MediaType) => {
    tracking.trackEvent({
      element: 'portfolio_item_preview',
      event: 'clicked',
    });

    dispatch(setModal(MEDIA, attach));
  };
  return (
    <div className={styles.root}>
      {group.type?.name ? (
        <div className={styles.titleContainer}>
          <Text type="label" className={styles.groupLabel} text={group.type.name} />
        </div>
      ) : null}
      <div className={styles.portfolioContainer}>
        {group.items.map((item) => (
          <PortfolioItem
            key={item.id}
            onClick={handleClickMedia}
            portfolioItem={item}
          />
        ))}
      </div>
    </div>
  );
};

export default PortfolioGroup;

/**
 * @generated SignedSource<<2e7900a25a7950d368b897f1beed118d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT" | "%future added value";
export type Performance_Granularity = "DAILY" | "MONTHLY" | "QUARTERLY" | "WEEKLY" | "%future added value";
export type TransactionsChartQuery$variables = {
  brandId: string;
  campaignIds?: ReadonlyArray<string> | null | undefined;
  campaignPostingType?: CampaignPostingType | null | undefined;
  campaignType?: CampaignType | null | undefined;
  dateFrom: any;
  dateTo: any;
  granularity: Performance_Granularity;
  platform?: CampaignPlatform | null | undefined;
  useCase?: CampaignUseCase | null | undefined;
};
export type TransactionsChartQuery$data = {
  readonly performance: {
    readonly paymentTransactionCounters: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly averagePrice: number;
          readonly dateFrom: any;
          readonly dateTo: any;
          readonly totalExpenses: number;
          readonly totalExtraPayouts: number;
          readonly totalPayouts: number;
          readonly totalReimbursements: number;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalAveragePrice: number;
      readonly totalExpenses: number;
      readonly totalExtraPayouts: number;
      readonly totalPayouts: number;
      readonly totalReimbursements: number;
    } | null | undefined;
  } | null | undefined;
};
export type TransactionsChartQuery = {
  response: TransactionsChartQuery$data;
  variables: TransactionsChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignPostingType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFrom"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTo"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granularity"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useCase"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalExpenses",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPayouts",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalReimbursements",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalExtraPayouts",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Performance_Query",
    "kind": "LinkedField",
    "name": "performance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "brandId",
            "variableName": "brandId"
          },
          {
            "kind": "Variable",
            "name": "campaignIds",
            "variableName": "campaignIds"
          },
          {
            "kind": "Variable",
            "name": "campaignPostingType",
            "variableName": "campaignPostingType"
          },
          {
            "kind": "Variable",
            "name": "campaignType",
            "variableName": "campaignType"
          },
          {
            "kind": "Variable",
            "name": "campaignUseCase",
            "variableName": "useCase"
          },
          {
            "kind": "Variable",
            "name": "dateFrom",
            "variableName": "dateFrom"
          },
          {
            "kind": "Variable",
            "name": "dateTo",
            "variableName": "dateTo"
          },
          {
            "kind": "Variable",
            "name": "granularity",
            "variableName": "granularity"
          },
          {
            "kind": "Variable",
            "name": "platform",
            "variableName": "platform"
          }
        ],
        "concreteType": "Performance_PaymentTransactionCounterConnection",
        "kind": "LinkedField",
        "name": "paymentTransactionCounters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Performance_PaymentTransactionCounterEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Performance_PaymentTransactionCounter",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateFrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateTo",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "averagePrice",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalAveragePrice",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionsChartQuery",
    "selections": (v13/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "TransactionsChartQuery",
    "selections": (v13/*: any*/)
  },
  "params": {
    "cacheID": "eceda91493cdd5760eab116991a81367",
    "id": null,
    "metadata": {},
    "name": "TransactionsChartQuery",
    "operationKind": "query",
    "text": "query TransactionsChartQuery(\n  $brandId: ID!\n  $campaignIds: [ID!]\n  $dateFrom: Date!\n  $dateTo: Date!\n  $granularity: Performance_Granularity!\n  $platform: CampaignPlatform\n  $campaignType: CampaignType\n  $campaignPostingType: CampaignPostingType\n  $useCase: CampaignUseCase\n) {\n  performance {\n    paymentTransactionCounters(brandId: $brandId, campaignIds: $campaignIds, dateFrom: $dateFrom, dateTo: $dateTo, granularity: $granularity, platform: $platform, campaignType: $campaignType, campaignPostingType: $campaignPostingType, campaignUseCase: $useCase) {\n      edges {\n        node {\n          dateFrom\n          dateTo\n          totalExpenses\n          totalPayouts\n          totalReimbursements\n          totalExtraPayouts\n          averagePrice\n        }\n      }\n      totalExpenses\n      totalPayouts\n      totalReimbursements\n      totalExtraPayouts\n      totalAveragePrice\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b2c666f5c9611fb0c7612e4da5570aa";

export default node;

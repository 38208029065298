import cloneDeep from 'lodash/cloneDeep';

import type {
  Product,
  Products,
  ProductVariant,
  ProductVariantsVisibilityState,
  ProductKey,
  VariantKey,
} from './types';

import type { Shopify_LineItemInput } from 'GraphTypes/CreateBulkShopifyOrderMutation.graphql';

type InitProductVariantsVisibilityState = (products: Product[]) => ProductVariantsVisibilityState;
const initProductVariantsVisibilityState: InitProductVariantsVisibilityState = (products) => {
  const entries = products.map((product, index) => [index, false]);
  return Object.fromEntries(entries);
};

type HasProductVariants = (product?: Product) => boolean;
const hasProductVariants: HasProductVariants = (product) => {
  return Boolean(
    product?.variants && Array.isArray(product?.variants) && product.variants.length > 0
  );
};

type HasSelectedVariants = (product?: Product) => boolean;
const hasSelectedVariants: HasSelectedVariants = (product) => {
  return Boolean(
    product?.variants &&
      Array.isArray(product?.variants) &&
      product.variants.some((productVariant) => productVariant.checked)
  );
};

type GetSelectedProductVariantsSelectionCount = (
  products: Products,
  productKey: ProductKey
) => number;
const getSelectedProductVariantsSelectionCount: GetSelectedProductVariantsSelectionCount = (
  products,
  productKey
) => {
  if (products[productKey] && products[productKey].variants) {
    const result = products[productKey].variants?.filter(
      (productVariant) => productVariant.checked
    );
    return Array.isArray(result) ? result.length : 0;
  }
  return 0;
};

/**
 * Returns flat array of selected product variants
 */
type FlattenSelectedProductVariants = (products: Products) => ProductVariant[];
const flattenSelectedProductVariants: FlattenSelectedProductVariants = (products) => {
  const clone = cloneDeep<Products>(products);
  const result = clone.reduce<ProductVariant[]>((accumulator, product) => {
    if (!product.variants) {
      return accumulator;
    }
    const flattenVariants = product.variants
      .filter((productVariant) => productVariant.checked)
      .map((variant) => {
        return {
          ...variant,
          thumbnailUrl: variant?.thumbnailUrl || product?.thumbnailUrl,
          title: product?.title,
          subtitle: variant?.title,
        };
      });
    return [...accumulator, ...flattenVariants];
  }, []);
  return result;
};

type GetProductsWithChangedProductVariantSelection = (
  products: Products,
  productKey: ProductKey,
  variantKey: VariantKey,
  checked: boolean,
  selectedQuantity: number
) => Products;
const getProductsWithChangedProductVariantSelection: GetProductsWithChangedProductVariantSelection =
  (products, productKey, variantKey, checked, selectedQuantity) => {
    const result = cloneDeep<Products>(products);
    (result[productKey]?.variants?.[variantKey] as ProductVariant).checked = checked;
    (result[productKey]?.variants?.[variantKey] as ProductVariant).selectedQuantity =
      selectedQuantity;
    return result;
  };

/**
 * Returns a list of products that have the selected product variants.
 */
type FilterProductsBySelectedVariants = (products: Products) => Products;
const filterProductsBySelectedVariants: FilterProductsBySelectedVariants = (products) => {
  return products.filter((product) => hasSelectedVariants(product));
};

/**
 * Function calculates the total sum of selected product variants.
 */
type CalculateTotalSumBySelectedProductVariants = (products: Products) => number;
const calculateTotalSumBySelectedProductVariants: CalculateTotalSumBySelectedProductVariants = (
  products
) => {
  return products.reduce<number>((acc, item) => {
    if (item.variants) {
      const variantsSum = item.variants.reduce<number>((acc1, item1) => {
        return item1.checked ? acc1 + item1.price : acc1;
      }, 0);
      return variantsSum + acc;
    }
    return acc;
  }, 0);
};

type ConvertProductsToShopifyLineItemInput = (products: Products) => Shopify_LineItemInput[];
const convertProductsToShopifyLineItemInput: ConvertProductsToShopifyLineItemInput = (products) => {
  return products.reduce<Shopify_LineItemInput[]>((acc, product) => {
    if (product.variants) {
      const variants = product.variants.map<Shopify_LineItemInput>((variant) => ({
        productVariantId: variant.id,
        quantity: variant.selectedQuantity,
      }));
      return [...acc, ...variants];
    }
    return acc;
  }, []);
};

export {
  getProductsWithChangedProductVariantSelection,
  calculateTotalSumBySelectedProductVariants,
  getSelectedProductVariantsSelectionCount,
  convertProductsToShopifyLineItemInput,
  initProductVariantsVisibilityState,
  filterProductsBySelectedVariants,
  flattenSelectedProductVariants,
  hasSelectedVariants,
  hasProductVariants,
};

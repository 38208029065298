import React, { useCallback } from 'react';
import xor from 'lodash/xor';
import debounce from 'lodash/debounce';

import styles from './AdminAdvertiserFilters.pcss';
import { getFiltersData } from './utils';

import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import TextButton from 'Components/ui/TextButton/TextButton';
import { PlanId } from 'GraphTypes/AdminAdvertisersContainerQuery.graphql';

export type Filters = {
  paying: boolean | null;
  planIds: string[];
  currencies: string[];
  textQuery: string;
};

interface Props {
  planIds: PlanId[];
  filters: Filters;
  onFiltersChange: (filters: Filters) => void;
  onFiltersClear: () => void;
}

const AdminAdvertiserFilters: React.FC<Props> = (props) => {
  const { planIds, filters, onFiltersChange, onFiltersClear } = props;

  const filtersData = getFiltersData(filters);

  const handleSearchInputChange = useCallback(
    debounce((value) => {
      onFiltersChange({ ...filters, textQuery: value });
    }, 500),
    [filters]
  );

  const handlePayingChange = (newValue: string) => {
    const payingValue = newValue === 'paying' ? true : null;
    onFiltersChange({ ...filters, paying: payingValue });
  };

  const handlePlansChange = (id: string) => {
    const newPlanIdsValue = xor(filters.planIds || [], [id]);
    onFiltersChange({ ...filters, planIds: newPlanIdsValue });
  };

  const handleCurrencyChange = (id: string) => {
    const newCurrenciesValue = xor(filters.currencies || [], [id]);
    onFiltersChange({ ...filters, currencies: newCurrenciesValue });
  };

  const planItems = planIds.map((item) => {
    return {
      id: item,
      isChecked: filters.planIds.includes(item),
      value: item,
      label: item,
    };
  });

  return (
    <div className={styles.root}>
      <TextButton
        color="grey"
        msg="general.clear_all"
        onClick={onFiltersClear}
        className={styles.clearParams}
      />
      <ProgressiveInput
        type="input"
        inputProps={{
          value: filters.textQuery,
          placeholderMsg: 'advertisers.search_placeholder',
          handleChange: handleSearchInputChange,
          rightIcon: 'Search-loop',
        }}
        className={styles.input}
        isDirty={!!filters.textQuery}
      />
      <ProgressiveInput
        type="radio"
        inputProps={{
          items: filtersData.paying,
          bordered: true,
          ignoreValue: 'all',
          placeholderMsg: 'search_section.form.payment_status',
          handleClick: handlePayingChange,
          hideByAlphabet: true,
          withArrow: true,
        }}
        className={styles.filter}
        isDirty={!!filters.paying}
      />
      <ProgressiveInput
        type="checkboxSelect"
        inputProps={{
          items: planItems,
          bordered: true,
          placeholderMsg: 'search_section.form.plan',
          handleClick: handlePlansChange,
          hideByAlphabet: true,
          handleClear:
            filters.planIds.length > 0
              ? () => {
                  onFiltersChange({ ...filters, planIds: [] });
                }
              : undefined,
        }}
        className={styles.filter}
        isDirty={filters.planIds.length > 0}
      />
      <ProgressiveInput
        type="checkboxSelect"
        inputProps={{
          items: filtersData.currencies,
          bordered: true,
          placeholderMsg: 'search_section.form.currency',
          handleClick: handleCurrencyChange,
          hideByAlphabet: true,
          handleClear:
            filters.currencies.length > 0
              ? () => {
                  onFiltersChange({ ...filters, currencies: [] });
                }
              : undefined,
        }}
        className={styles.filter}
        isDirty={filters.currencies.length > 0}
      />
    </div>
  );
};

export default AdminAdvertiserFilters;

import React, { useState } from 'react';

import Addition from '../Addition/Addition';
import Autocomplete from '../Autocomplete/Autocomplete';
import CheckboxSelect from '../CheckboxSelect/CheckboxSelect';
import Date from '../Date/Date';
import Group from '../Group/Group';
import RadioSelect from '../RadioSelect/RadioSelect';
import Select from '../Select/Select';
import TextInput from '../TextInput/TextInput';

import styles from './Content.css';

import {
  ADDITION,
  AUTOCOMPLETE,
  CHECKBOX,
  DATE,
  RADIO,
  SELECT,
  TEXT_INPUT,
  CUSTOM,
} from 'Constants/general';
import Text from 'Atoms/Text/Text';

const createContent = (item, props) => {
  const { items, isVisible, searchParams, onChange } = props;

  const { type, name, group, customElement } = item;
  const value = name ? searchParams[name] : searchParams;
  let element;

  switch (type) {
    case DATE:
      element = <Date />;
      break;
    case RADIO:
      element = <RadioSelect />;
      break;
    case SELECT:
      element = <Select />;
      break;
    case CHECKBOX:
      element = <CheckboxSelect />;
      break;
    case TEXT_INPUT:
      element = <TextInput />;
      break;
    case AUTOCOMPLETE:
      element = <Autocomplete isVisible={isVisible} />;
      break;
    case ADDITION:
      element = <Addition />;
      break;
    case CUSTOM:
      element = customElement;
      break;
    default:
      return null;
  }

  const key = name ? name : ADDITION;

  return React.cloneElement(element, {
    key,
    ...item,
    items: items && items[name] ? items[name] : null,
    value,
    onChange,
    inGroup: !!group,
  });
};

const ToggleContent = (props) => {
  const { items, group, outerProps, accentMsg, accent } = props;

  const names = items.map((item) => item.name);

  const [currentName, setCurrentName] = useState(names[0]);

  return (
    <Group
      group={group}
      toggleList={names}
      currentItem={currentName}
      onChangeCurrentItem={setCurrentName}
    >
      {items.map((item, index) => {
        const { name, percentageItem } = item;
        const className = name !== currentName ? styles.hidden : '';

        return (
          <div key={name} className={className}>
            {createContent(item, outerProps)}

            {percentageItem &&
              createContent(percentageItem, { ...outerProps, items: percentageItem.items })}
            {accent && accentMsg && <Text color="pink" size="md" msg={accentMsg} />}
          </div>
        );
      })}
    </Group>
  );
};

const Content = (props) => {
  const { contents, accentMsg, accent } = props;

  const createContents = () => {
    return contents.map((item, index) => {
      const { view, group } = item;
      const content = createContent(item, props);
      const isLast = index === contents.length - 1;
      if (view === 'toggle') {
        return (
          <ToggleContent
            key={group}
            outerProps={props}
            accent={accent && isLast}
            accentMsg={accentMsg}
            {...item}
          />
        );
      }

      return group ? (
        <Group key={index} group={group}>
          {content}
          {accent && accentMsg && isLast && <Text color="pink" size="md" msg={accentMsg} />}
        </Group>
      ) : (
        <>
          {content}
          {accent && accentMsg && isLast && <Text color="pink" size="md" msg={accentMsg} />}
        </>
      );
    });
  };

  return createContents();
};

export default Content;

import React, {
  CSSProperties,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import debounce from 'lodash/debounce';
import classnames from 'classnames';

import ProductListWrap from './ProductList';

import styles from 'Components/ProductList/ProductList.pcss';
import Icon from 'Components/ui/Icon/Icon';
import Input from 'Components/ui/Input/Input';
import Spinner from 'Atoms/Spinner/Spinner';
import { Products, ProductVariant } from 'Components/ProductList/types';
import { OnOrderLostConnectionError } from 'Modal/advertiser/ShopifyOrderCreation/ShopifyOrderCreationDrawer';
import { GuideTourContext } from 'Containers/GuideTour';

type Props = {
  className?: string;
  style?: CSSProperties;
  products?: Products;
  selectedProducts: Products;
  hideSearch?: boolean;
  hideProducts?: boolean;
  onProductVariantSelectionChange?: (args: {
    productVariants: ProductVariant[];
    products: Products;
  }) => void;
  organizationId: string;
  campaignId: string;
  onOrderLostConnectionError?: OnOrderLostConnectionError;
  projectsCount: number;
  setCurrencyCode: (code: string) => void;
  scrollRef: React.RefObject<HTMLDivElement>;
};

export const ProductListContainer: React.FC<Props> = (props) => {
  const [textQuery, setTextQuery] = useState<string>('');
  const { hideSearch, className, style } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  const { getTooltipComponent } = useContext(GuideTourContext);

  const searchWrapperStyle = useMemo<CSSProperties>(() => {
    return clientWidth > 0 ? { width: clientWidth } : {};
  }, [clientWidth]);

  const handleSearchInputChange = useCallback(
    debounce((value) => {
      setTextQuery(value);
    }, 500),
    []
  );

  const GuideTourInputTooltip = useMemo(() => {
    return getTooltipComponent('productsOver50', 'input');
  }, [getTooltipComponent]);

  return (
    <div
      className={classnames(styles.root, className)}
      style={style}
      ref={rootRef}
      id="productListContainer"
    >
      {!hideSearch && (
        <div className={styles.searchWrapper} style={searchWrapperStyle}>
          {GuideTourInputTooltip && (
            <GuideTourInputTooltip
              arrowPosition="center-right"
              hideNextButton
              className={styles.guideTooltip}
            >
              <div className={styles.tourWrap} />
            </GuideTourInputTooltip>
          )}
          <Icon name="Search-loop" size={24} className={styles.searchIcon} />
          <Input
            bordered
            hideCloseIcon
            className={classnames(styles.searchInput, styles.searchInput)}
            placeholderMsg="product_list.search_input.placeholder"
            handleChange={handleSearchInputChange}
          />
        </div>
      )}
      <Suspense fallback={<Spinner className={styles.spinner} />}>
        <ProductListWrap
          {...props}
          setClientWidth={setClientWidth}
          rootRef={rootRef}
          textQuery={textQuery}
        />
      </Suspense>
    </div>
  );
};

import React from 'react';

import styles from './CreatorMobileBanner.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Text from 'Atoms/Text/Text';
import { DOWNLOAD_INSENSE_APP } from 'Constants/general';
import imgSrc from 'Images/creators/download_app_mobile.png';

const CreatorMobileBanner = () => {
  const downloadApp = () => {
    window.location.href = DOWNLOAD_INSENSE_APP;
  };

  return (
    <div className={styles.mobileContainer}>
      <div className={styles.mobileContent}>
        <div className={styles.imgWrapper}>
          <img src={imgSrc} />
        </div>
        <div className={styles.textContent}>
          <Text tag="h1" weight="500" msg="general.portable_mode" className={styles.title} />
          <Text msg="contractor.download_app_long" className={styles.text} />

          <AlterButton
            msg="outreach_mobile_banner.button"
            onClick={downloadApp}
            fluid
            type={'black'}
            className={styles.mobileBtn}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatorMobileBanner;

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  MarkOfferAsReadInput,
  MarkOfferAsReadMutation,
  MarkOfferAsReadMutation$data,
} from 'GraphTypes/MarkOfferAsReadMutation.graphql';

const mutation = graphql`
  mutation MarkOfferAsReadMutation($input: MarkOfferAsReadInput!) {
    markOfferAsRead(input: $input) {
      clientMutationId
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: MarkOfferAsReadInput,
  resolve?: (response: MarkOfferAsReadMutation$data) => void,
  reject?: fnType
) => {
  const { offerId } = data;

  const variables = {
    input: {
      offerId,
    },
  };

  commitMutation<MarkOfferAsReadMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import { commitMutation, graphql } from 'react-relay';

import environment from 'Api/Environment';
import {
  MarkContentReviewAsFailedMutation,
  MarkContentReviewAsFailedMutation$data,
  MarkContentReviewAsFailedInput,
} from 'GraphTypes/MarkContentReviewAsFailedMutation.graphql';

const mutation = graphql`
  mutation MarkContentReviewAsFailedMutation($input: MarkContentReviewAsFailedInput!) {
    markContentReviewAsFailed(input: $input) {
      project {
        advertiserStage
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: MarkContentReviewAsFailedInput,
  resolve?: (response: MarkContentReviewAsFailedMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<MarkContentReviewAsFailedMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './ButtonPreset.pcss';

type Props = PropsWithChildren<{
  type?: 'alterbutton' | 'button';
  className?: string;
}>;

const ButtonPreset: React.FC<Props> = (props) => {
  const { children, className, type = 'button' } = props;

  const classList = classNames(styles.root, className, { [styles.alter]: type === 'alterbutton' });

  return <div className={classList}>{children}</div>;
};

export default ButtonPreset;

import useDashly from 'Hooks/useDashly';
import createCreative from 'Mutations/CreateCreative.Mutation';
import updateProject from 'Mutations/UpdateProject.Mutation';
import completeProjectMutation from 'Mutations/CompleteProject.Mutation';
import { CreateCreativeMutation$data } from 'GraphTypes/CreateCreativeMutation.graphql';
import ErrorHandler from 'Util/errorHandler';

const { track: trackEvent } = useDashly();

interface ProjectActionsResult {
  completeProject: (params: CompleteProjectParamsProps) => void;
}

interface CompleteProjectParamsProps {
  fileIds: string[];
  projectId: string;
  showcasingPermitted?: boolean;
  callback: () => void;
}

type UseProjectActions = () => ProjectActionsResult;
const useProjectActions: UseProjectActions = () => {
  const completeProject = async (params: CompleteProjectParamsProps) => {
    const { fileIds = [], projectId, showcasingPermitted, callback } = params;

    try {
      const handleCreateCreative = async (fileId: string) => {
        await new Promise<CreateCreativeMutation$data>((resolve, reject) => {
          createCreative({ fileId, projectId }, resolve, reject);
        });
      };

      fileIds.forEach((fileId) => {
        handleCreateCreative(fileId);
      });

      if (showcasingPermitted !== undefined) {
        updateProject({ id: projectId, showcasingPermitted });
      }

      const handleCompleteDone = () => {
        trackEvent('deal_is_completed');
        callback?.();
      };

      completeProjectMutation({ projectId }, handleCompleteDone);
    } catch (error) {
      ErrorHandler.error('Crash while project completion', { error });
    }
  };

  return { completeProject };
};

export { useProjectActions };

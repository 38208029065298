import React from 'react';
import classnames from 'classnames';

import styles from './InvitedFilter.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import RadioButton from 'Components/ui/RadioButton/RadioButton';

interface Props {
  invitedByOrganizationMember?: boolean | null;
  outreach?: boolean | null;
  onInvitedParamsChange: (data: {
    invitedByOrganizationMember?: boolean | null;
    outreach?: boolean | null;
  }) => void;
}

const InvitedFilter: React.FC<Props> = (props) => {
  const { invitedByOrganizationMember, outreach, onInvitedParamsChange } = props;

  const handleCreatedByOrganizationMemberChange = () => {
    onInvitedParamsChange({
      outreach: false,
      invitedByOrganizationMember: invitedByOrganizationMember ? null : true,
    });
  };

  const handleOutreachChange = () => {
    onInvitedParamsChange({
      invitedByOrganizationMember: null,
      outreach: outreach ? null : true,
    });
  };

  const handleReset = () => {
    onInvitedParamsChange({
      outreach: null,
      invitedByOrganizationMember: null,
    });
  };

  return (
    <Dropdown
      bordered
      value={<Icon name="target-bullseye" />}
      closeBySelect={false}
      className={classnames({ [styles.active]: invitedByOrganizationMember || outreach })}
    >
      <DropdownGroup className={styles.dropdownGroup}>
        <DropdownItem>
          <div onClick={handleCreatedByOrganizationMemberChange} className={styles.item}>
            <Text type="md" msg="creators.filter.invited.by_member" className={styles.title} />
            <RadioButton checked={!!invitedByOrganizationMember} />
          </div>
        </DropdownItem>
        <DropdownItem>
          <div onClick={handleOutreachChange} className={styles.item}>
            <Text type="md" msg="creators.filter.invited.outreach" className={styles.title} />
            <RadioButton checked={!!outreach} />
          </div>
        </DropdownItem>
        {(invitedByOrganizationMember || outreach) && (
          <AlterButton
            bordered
            msg="creators.filter.invited.clear"
            className={styles.clear}
            onClick={handleReset}
          />
        )}
      </DropdownGroup>
    </Dropdown>
  );
};

export default InvitedFilter;
